import Close from "@mui/icons-material/Close";
import { useState, useEffect } from "react";
import sendHttpRequest, { search } from "../../hooks/sendHttpRequest";
import { useAuth } from "../../hooks/useAuth";
import ProviderSearchItem from "./ProviderSearchItem";
import classes from "./SearchProviderChips.module.css";
import { X } from "react-bootstrap-icons";

const SearchProviderChip = (props) => {
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [provider, setProvider] = useState("");
  const { user } = useAuth();
  const searchProvider = async (providerName) => {
    setIsLoading(true);
    setIsError(false);
    let apiUrl = `/provider/search/tenant/${user?.tenantId}`;
    if (props?.inMessage) {
      apiUrl = `/provider/search/tenant/${user?.tenantId}`;
    }
    const httpResponse = await search({
      url: apiUrl,
      method: "GET",
      params: {
        query: providerName,
      },
    });
    if (!httpResponse?.error) {
      setSuggestions(httpResponse?.data);
      // console.log(httpResponse);
    } else {
      setSuggestions(httpResponse?.data);
      setIsError(true);
    }
    setIsLoading(false);
  };
  const handleProviderSearch = (provider) => {
    // console.log("Patient ID: ", patient.id);
    props?.handleProvidersAdd(provider);
    setSearchInputValue("");

    // setPrescriberList(unique);
    addTags(provider);
  };

  const handleOnChange = (inputValue) => {
    if (inputValue !== "") {
      setProvider(inputValue);
      searchProvider(inputValue);
      setSearchInputValue(inputValue);
    } else {
      setProvider("");
      setSuggestions([]);
      setIsLoading(false);
      setIsError(false);
      setSearchInputValue("");
    }
  };

  const [tags, setTags] = useState([]);
  const removeTags = (indexToRemove) => {
    let result = [...tags.filter((_, index) => index !== indexToRemove)];
    setTags(result);
    props?.setUserList(result);
  };
  const addTags = (user) => {
    // alert(event.target.value);

    const tempList = [...tags];
    tempList.push(user);
    const unique = [
      ...new Map(tempList.map((item) => [item.userId, item])).values(),
    ];
    setTags(unique);
    // props.selectedTags([...tags, user]);
  };


  useEffect(() => {
    setTags(props?.defaultUserList);
  }, [])
  return (
    <>
      <div id="cc">
        <div
          className={`border--default br-10 font-12 ${classes["tags__input"]}`}
        >

          <input
            className={`form-control shadow-none border-0  font-14 ${classes["input__text"]}`}
            type="text"
            // onKeyUp={(event) => (event.key === "Enter" ? addTags(event) : null)}
            disabled={props?.disable === true ? true : false}
            // required={props?.required === true ? true : false}
            placeholder={
              props?.placeHolder ? props?.placeHolder : "Search provider"
            }
            // style={{backgroundColor:'white'}}
            style={
              props?.disable === true
                ? { background: "#f2f7f8", height: "40px" }
                : { background: "#fff", height: "40px" }
            }
            onChange={(event) => handleOnChange(event.target.value)}
            // defaultValue = {searchInputValue}
            value={searchInputValue}
            onBlur={() => {
              setTimeout(() => {
                setSuggestions([]);
                setIsError(false);
                setIsLoading(false);
              }, 5);
            }}
          />
        </div>
        <ul className={`${classes["tags"]}`}>
          {console.log(tags)}
          {tags?.map((tag, index) => (
            <li key={index} className={`${classes["tag"]}`}>
              <span className={`${classes["tag__title"]}`}>
                {tag?.name[0]?.firstName + " " + tag?.name[0]?.lastName}
              </span>
              <span
                className={`${classes["tag__close__icon"]}`}
                onClick={() => removeTags(index)}
                id={tag.userId}
              >
                <X
                  color="#336383"
                  style={{ marginBottom: '2px' }}
                  size={16}
                />
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="d-flex flex-column " style={{ zIndex: "99999999" }}>
        <div
          className={suggestions.length > 0 ? `overflowy-auto` : undefined}
          style={{ position: "absolute", height: "60vh", zIndex: "99999999" }}
        >
          <ul
            className="list-group w-100"
            style={{
              listStyleType: "none",
              cursor: "pointer",
              paddingLeft: "0",
              zIndex: "99999999",
              border: "1px solid #d9e8ec",
              borderTop: 0,
              backgroundColor: "#FFF",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
            }}
          >
            {isError ? (
              <ProviderSearchItem
                ProviderName={"No result found"}
                inMessage={props?.inMessage}
                key={0}
              />
            ) : isLoading ? (
              <ProviderSearchItem
                ProviderName={"Loading..."}
                inMessage={props?.inMessage}
                key={0}
              />
            ) : (
              suggestions.length > 0 &&
              suggestions.map((suggestion) => {
                return (
                  <ProviderSearchItem
                    ProviderName={
                      suggestion?.name[0]?.firstName + " " + suggestion?.name[0]?.lastName
                    }
                    key={suggestion?.userId}
                    Provider={suggestion}
                    OnMouseDown={handleProviderSearch}
                    width={props.width}
                    inMessage={props?.inMessage}
                  />
                );
              })
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default SearchProviderChip;

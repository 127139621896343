// const schema = yup.object().shape({
//     name: yup.array().of(yup.object().shape({
//       firstName: yup.string().required().min(2).max(35),
//       middleName: yup.string().required().min(2).max(35),
//       lastName: yup.string().required().min(2).max(35),
//     })),
//     // email: yup.string().email('Invalid email format').required(),
//   });

import * as yup from 'yup';


export const ERROR_MESSAGES = {
    user: {
        name: {
            firstNameMin: 'First name should contain at least one character',
            firstNameMax: 'First name should not exceed 35 characters',
            lastNameMin: 'Last name should contain at least one character',
            lastNameMax: 'Last name should not exceed 35 characters',
            middleNameMin: 'Middle name should contain at least one character',
            middleNameMax: 'Middle name should not exceed 35 characters',
            suffixNameMin: 'Suffix should contain at least one character',
            suffixNameMax: 'Suffix should not exceed 7 characters',
        }
    },
    facility: {
        businessName: 'Facility name should not exceed 70 characters',
        addressLine1: 'Address line 1 should not exceed 40 characters',
        addressLine2: 'Address line 2 should not exceed 40 characters',
        city: 'Name of city should not exceed 35 characters',

    },
    patient: {
        addressLine1: 'Address line 1 should not exceed 40 characters',
        addressLine2: 'Address line 2 should not exceed 40 characters',
        city: 'City name should not exceed 35 characters',
    },
    medication: {
        quantityMax: "Quantity should not exceed 11 digits",
        quantityPositive: "Quantity value should be positive",
        daysSupplyMax: 'Days Supply value should not exceed 3 digits',
        sigTextMax: 'Sig text should not exceed 1000 characters',
        noteMax: 'Pharmacy note should not exceed 210 characters',
        numberOfRefillsMax: 'Refill value should not exceed 2 digits'
    },
    vitals: {
        temperature: "Temperature  90-105",
        systolic: "Systolic BP  60-340",
        diastolic: "Diastolic BP 30-150",
        pulse: "Pulse 50-115",
        SPO: "SPO 80-100",
        spo2: "SpO<sub>2</sub> 80-100",
        height: "Height 108 inches",
        weight: "Weight 1-800 lbs",
    },
    tenantSettings: {
        businessName: 'Business name should not exceed 70 characters',
        addressLine1: 'Address line 1 should not exceed 40 characters',
        npi: 'NPI length should not exceed 10 digits',
        consultMints: 'Consult length should not exceed 200 minutes',
        followUp: 'Follow-up length should not exceed 100 minutes',
        numberOfPrescribingUsers: 'Prescribing users should not exceed 10000',
    },
}

export const FIELD_NAMES = {
    user: {
        name: {
            firstNameMax: 'firstNameMax',
            lastNameMax: 'lastNameMax',
            middleNameMax: 'middleNameMax',
            suffixNameMax: 'suffixNameMax',
        }
    },
    facility: {
        businessName: 'businessName',
        addressLine1: 'addressLine1',
        addressLine2: 'addressLine2',
        city: 'city'
    },
    patient: {
        addressLine1: 'addressLine1',
        addressLine2: 'addressLine2',
        city: 'city'
    },
    medication: {
        quantityMax: 'quantityMax',
        quantityPositive: 'quantityPositive',
        daysSupplyMax: 'daysSupplyMax',
        sigTextMax: 'sigTextMax',
        noteMax: 'noteMax',
        numberOfRefillsMax: 'numberOfRefillsMax',
    },
    vitals: {
        temperature: "temperature",
        systolic: "systolic",
        diastolic: "diastolic",
        pulse: "pulse",
        SPO: "SPO",
        height: "height",
        weight: "weight",
        spo2: "spo2",
    },
    tenantSettings: {
        businessName: 'businessName',
        addressLine1: 'addressLine1',
        npi: 'npi',
        consultMints: 'consultMints',
        followUp: 'followUp',
        numberOfPrescribingUsers: 'numberOfPrescribingUsers',
    },
}


export const MIN_MAX_VALUES = {
    name: {
        min: 1,
        max: 35,
        suffix:{
            min:1,
            max:7
        }
    },
    facility: {
        businessName: {
            min: 1,
            max: 70
        },
        addressLine1: {
            min: 1,
            max: 40,
        },
        addressLine2: {
            min: 1,
            max: 40,
        },
        city: {
            min: 1,
            max: 35
        }
    },

    medication: {
        quantityMax: 11,
        daysSupplyMax: 3,
        sigTextMax: 1000,
        noteMax: 210,
        numberOfRefillsMax: 2,
    },
    patient: {
        addressLine1: {
            min: 1,
            max: 40,
        },
        addressLine2: {
            min: 1,
            max: 40,
        },
        city: {
            min: 1,
            max: 35
        }
    },
    vitals: {
        temperature: {
            min: 90,
            max: 105
        },
        systolic: {
            min: 60,
            max: 340
        },
        diastolic: {
            min: 30,
            max: 150
        },
        pulse: {
            min: 50,
            max: 115
        },
        SPO: {
            min: 80,
            max: 100
        },
        spo2: {
            min: 80,
            max: 100
        },
        weight: {
            min: 1,
            max: 800
        },
        height: {
            max: 108
        },
    }
    ,
    tenantSettings: {
        businessName: {
            max:70,
        },
        addressLine1: {
            max:40
        },
        npi: {
            max:10
        },
        consultMints: {
            max:200
        },
        followUp: {
            max:100
        },
        numberOfPrescribingUsers: {
            max:10000
        },
    },
}

export const validationSchemas = {
    user: {
        userSchema: yup.object().shape({
            name: yup.array().of(yup.object().shape({
                firstName: yup.string().required().min(MIN_MAX_VALUES.name.min, ERROR_MESSAGES.user.name.firstNameMin).max(MIN_MAX_VALUES.name.max, ERROR_MESSAGES.user.name.firstNameMax),
                lastName: yup.string().required().min(MIN_MAX_VALUES.name.min, ERROR_MESSAGES.user.name.lastNameMin).max(MIN_MAX_VALUES.name.max, ERROR_MESSAGES.user.name.lastNameMax),
            })),
        }),
    },

}

export const removeValidationError = (errorsObject, key) => {
    const modifiedObject = structuredClone(errorsObject);
    delete modifiedObject[key];
    return modifiedObject;
}
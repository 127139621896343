import { useState } from "react";
import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import { useUnsavedWorkPrompt } from "../../components/hooks/useUnsavedWorkPrompt";
import { currencyFormatter, unsavedWorkHandler } from "../../utils/helpers/HelperFunctions";
import CptCodeSearch from "../../components/cptcodes-search/CptCodeSearch";
import useCptCodeSearch from "../../components/cptcodes-search/useCptCodeSearch";
import CurrencyInput from "react-currency-input-field";
import sendHttpRequest from "../../components/hooks/sendHttpRequest";
import { useAuth } from "../../components/hooks/useAuth";
function AddFeeScheduleModal(props) {
    const [isDirty, onDirty, onPristine] = useUnsavedWorkPrompt();
    const { user } = useAuth();
    const { cptSuggestions, setCptSuggestions, isCptLoading, setIsCptLoading, isCptError, setIsCptError, cptSearchInputValue, setCptSearchInputValue, cptCodeName, setCptCodeName, selectedCptCode, setSelectedCptCode, searchCptCode, handleCptCodeSearchInput, handleAddCptCode, handleRemoveCptCode } = useCptCodeSearch();
    const [billCharge, setBillCharge] = useState('');
    const [description, setDescription] = useState('');
    const [charge, setCharge] = useState('');
    const [amount, setAmount] = useState('');
    const [discount, setDiscount] = useState('');
    const [disableDiscountField, setDisableDiscountField] = useState(false);
    const [disableAmountField, setDisableAmountField] = useState(false);
    const [category, setCategory] = useState('');
    const [feeScheduleAlreadyExistsError, setFeeScheduleAlreadyExistsError] = useState(false);



    const addCptCodeToList = (addedCptCode) => {
        const isCodeAlreadyExist = props?.feeSchedules?.some(feeSchedule => feeSchedule?.code?.code == addedCptCode?.code)
        if (isCodeAlreadyExist) {
            setFeeScheduleAlreadyExistsError(true);
            return;
        }
        else {
            setFeeScheduleAlreadyExistsError(false);
        }
        handleAddCptCode(addedCptCode);
        setDescription(addedCptCode?.description);
        // setCptCodesList(prevList => [...prevList, addedCptCode]);
    }


    const [isSaving, setIsSaving] = useState(false);

    // console.log(selectedCptCode);

    // const removeCptCodeFromList = (removedCptCode) => {
    //     handleRemoveCptCode(removedCptCode);
    //     const tempList = cptCodesList.filter(cpt => cpt.code !== removedCptCode);
    //     setCptCodesList(prevList => [...tempList]);

    // }

    function handleClearCptCode() {
        setSelectedCptCode(undefined);
        setCptSuggestions([]);
        setCptCodeName("");
        setDescription("");
        setBillCharge('');
        setCharge('');
        setDiscount('');
        setAmount('');
        setCategory('');
    }

    function handleDescription(event) {
        const modifiedDescription = event.target.value;
        // setSelectedCptCode(previousObject => ({ ...previousObject, "description": modifiedDescription }));
        setDescription(modifiedDescription);
    }

    function handleCharge(value, name) {
        setCharge(value);
        calculateBillCharge(value, 0, 'charge');
    }

    function handleBillChargeForAmount(chargeAmount, discountVal) {
        const billChargeValue = chargeAmount - discountVal;
        setBillCharge(billChargeValue);
    }

    function handleBillChargeForDiscountPercentage(chargeAmount, discountVal) {
        const billChargeValue = chargeAmount - (chargeAmount * (discountVal / 100));
        setBillCharge(billChargeValue);
    }

    function calculateBillCharge(chargeAmount, discountVal, mode = 'percentage') {
        console.log(chargeAmount, discountVal, mode);
        if (mode === 'percentage') {
            if (discountVal === undefined) {
                setBillCharge(chargeAmount);
            }
            else {
                handleBillChargeForDiscountPercentage(chargeAmount, discountVal);
            }
        }
        else if (mode === 'amount') {
            if (discountVal === undefined) {
                setBillCharge(chargeAmount);
            }
            else {
                handleBillChargeForAmount(chargeAmount, discountVal);
            }
        }
        else if (mode === 'charge') {

            if (discount !== undefined && discount !== '') {
                handleBillChargeForDiscountPercentage(amount, discountVal);
            }
            else if (amount !== undefined && amount !== '') {
                handleBillChargeForAmount(amount, discountVal);
            }
            else {
                setBillCharge(chargeAmount);
            }
        }
    }

    function handleDiscount(value, name) {
        if (name === "percentage") {
            if (value === undefined) {
                setDisableAmountField(false);
                setDiscount('');
                calculateBillCharge(charge, value, name);
            }
            else {
                if (value >= 0 && value <= 100) {
                    setDisableAmountField(true);
                    setDiscount(value);
                    calculateBillCharge(charge, value, name);
                }
            }

        }
        else if (name === "amount") {
            if (value === undefined) {
                setDisableDiscountField(false);
                setAmount('');
                calculateBillCharge(charge, value, 'amount');
            }
            else {
                console.log('Here is the value:', value, charge);
                if (value >= 0 && parseFloat(value) <= parseFloat(charge)) {
                    setDisableDiscountField(true);
                    setAmount(value);
                    calculateBillCharge(charge, value, 'amount');
                }
            }
        }
    }

    async function saveFeeSchedule(selectedCptCode, billCharge) {
        setIsSaving(true);
        const options = {
            method: 'POST',
            url: `/claims/tenants/${user?.tenantId}/fee-schedule`,
            data: {
                description: description?.length > 0 ? description : selectedCptCode?.description,
                price: Number(billCharge),
                code: {
                    code: selectedCptCode?.code
                },
                category: category,
                charge: Number(charge),
                amount: isNaN(amount) ? 0 : Number(amount),
                discount: isNaN(discount) ? 0 : Number(discount)
            }
        }
        const httpResponse = await sendHttpRequest(options);
        if (!httpResponse.error) {
            props?.setReloadSchedule(p => !p);
            props?.handleModal();
        }
        else {
            console.log(httpResponse.error);
            console.log(httpResponse);

        }
        setIsSaving(false);
    }




    return (
        <Modal
            show={props.show}
            onHide={() => {
                unsavedWorkHandler(
                    "modal",
                    "There are unsaved changes, do you wish to continue?",
                    isDirty,
                    props.handleModal,
                    onPristine,
                    props.handleModal
                );
                // props.handleModal();
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            backdropClassName="modal-backdrop"
            contentClassName={`modal-border modal-shadow `}
            scrollable={false}
            centered
        >
            <Modal.Header>
                <span
                    style={{
                        lineHeight: "12px",
                        width: "18px",
                        fontSize: "8pt",
                        color: "#336383",
                        position: "absolute",
                        top: 15,
                        right: 15,
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        unsavedWorkHandler(
                            "modal",
                            "There are unsaved changes, do you wish to continue?",
                            isDirty,
                            props.handleModal,
                            onPristine,
                            props.handleModal
                        );
                        // props.handleModal();
                    }}
                >
                    {<CloseIcon />}
                </span>
                <Modal.Title className="font-20 fw-sb">Add Fee Schedule</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container-fluid">
                    <div className="row ">
                        <div className="col-sm-4">
                            <label className="font-12 fw-sb text--secondary ms-1">Billing Code <span>&#42;</span></label>
                            <CptCodeSearch
                                suggestions={cptSuggestions}
                                setSuggestions={setCptSuggestions}
                                isLoading={isCptLoading}
                                setIsLoading={setIsCptLoading}
                                isError={isCptError}
                                setIsError={setIsCptError}
                                searchInputValue={cptSearchInputValue}
                                setSearchInputValue={setCptSearchInputValue}
                                cptCodeName={cptCodeName}
                                setCptCodeName={setCptCodeName}
                                selectedCptCode={selectedCptCode}
                                setSelectedCptCode={setSelectedCptCode}
                                searchCptcode={searchCptCode}
                                handleSearchInput={handleCptCodeSearchInput}
                                handleAddCptCode={addCptCodeToList}
                                handleClearCptCode={handleClearCptCode}
                            />
                        </div>
                        <div className="col-sm-8">

                            <label className="font-12 fw-sb text--secondary ms-1"></label>
                            <div className="font-12 ms-1 text--terciary">
                                {selectedCptCode?.description}
                            </div>
                            {feeScheduleAlreadyExistsError === true ? <span className="font-12 fw-sb text--danger d-block py-1">Medical code already exists. Please choose another code</span> : null}
                        </div>

                    </div>

                    {selectedCptCode !== null && selectedCptCode !== undefined ? <div className="row mt-1">
                        <div className="col-sm-12">
                            <label className="font-12 fw-sb text--secondary ms-1">Description <span>&#42;</span></label>
                            <textarea
                                className="form-control shadow-none input br-10 border--default font-12 text--terciary"
                                value={description}
                                onChange={handleDescription}
                                placeholder="Description" ></textarea>


                        </div>
                    </div> : null}

                    {selectedCptCode !== null && selectedCptCode !== undefined ? <div className="row mt-1">
                        <div className="col-sm-3">
                            <label className="font-12 fw-sb text--secondary ms-1">Category <span>&#42;</span></label>
                            <select
                                className={`form-select font-12 shadow-none input br-10 border--default`}
                                value={category}
                                onChange={e => setCategory(e.target.value)}
                            >
                                <option value=""  >
                                    Select Category
                                </option>
                                <option value="procedure">
                                    Procedure
                                </option>
                            </select>
                        </div>
                        <div className="col-sm-2">
                            <label className="font-12 fw-sb text--secondary ms-1">Charge <span>&#42;</span></label>
                            <CurrencyInput
                                id="charge"
                                name="cost"
                                placeholder="$0.00"
                                className="text--terciary simple-placeholder text-center font-12 input form-control shadow-none border--default br-10"
                                prefix="$"
                                decimalSeparator="."
                                allowNegativeValue={false}
                                groupSeparator=","
                                intlConfig={{ locale: 'en-US', currency: 'USD' }}
                                onValueChange={(value, name) => handleCharge(value, name)}
                                value={charge}
                            // disabled = {true}
                            />
                        </div>

                        <div className="col-sm-3">
                            <div className="row justify-content-center">
                                <div className="col-sm-6">
                                    <label className="font-12 fw-sb text--secondary ms-1">Discount</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <CurrencyInput
                                        id="percentage"
                                        name="percentage"
                                        placeholder="0%"
                                        allowNegativeValue={false}
                                        className="text--terciary simple-placeholder text-center font-12 input form-control shadow-none border--default br-10"
                                        suffix="%"
                                        decimalSeparator="."
                                        groupSeparator=","
                                        onValueChange={(value, name) => handleDiscount(value, name)}
                                        value={discount}
                                        disabled={disableDiscountField || charge === '' || charge === undefined}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <CurrencyInput
                                        id="amount"
                                        name="amount"
                                        placeholder="Amount"
                                        className="text--terciary input simple-placeholder text-center font-12 input form-control shadow-none border--default br-10"
                                        decimalSeparator="."
                                        groupSeparator=","
                                        allowNegativeValue={false}
                                        onValueChange={(value, name) => handleDiscount(value, name)}
                                        value={amount}
                                        disabled={disableAmountField || charge === '' || charge === undefined}
                                    />

                                </div>
                            </div>
                        </div>

                        <div className="col-sm-3">
                            <label className="font-12 fw-sb text--secondary ms-1">Bill Charge</label>
                            <input type="text" disabled className="form-control font-12 br-10 shadow-none input border--default disabled"
                                defaultValue={charge !== undefined && charge !== '' && billCharge !== '' ? currencyFormatter('USD', billCharge) : ''}
                            />
                        </div>
                    </div> : null}

                    {/* {selectedCptCode !== null && selectedCptCode !== undefined ? <div className="row my-2">
                        <div className="col-sm-12">
                            <div>
                                <span className="fw-sb font-12 text--secondary">Selected Billing Code: </span>
                                <span className="font-12 text--terciary">{selectedCptCode?.code} - {selectedCptCode?.description}</span>
                            </div>
                        </div>
                    </div> : null} */}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="row justify-content-end">
                    <button
                        disabled={isSaving || selectedCptCode == null || selectedCptCode == undefined || billCharge === '' || category === ''}
                        onClick={() => { saveFeeSchedule(selectedCptCode, billCharge) }} className="btn blue_task_button br-10 shadow-none">Save</button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default AddFeeScheduleModal
export const BILLING_AND_CLAIMS = "Billing & Claims";
export const ENCOUNTERS = "Encounters";
export const TASKS = "Tasks";
export const ACCOUNT_SETTINGS = "Account Settings";
export const APPOINTMENTS = "Appointments";
export const PRACTICE_MANAGEMENT = "Practice Management";
export const MESSAGES = "Messages";
export const PATIENTS = "Patients";
export const CLAIM_ONLY = "ClaimsOnly";
export const EHR_ONLY = "EHROnly";
export const HOME = "Home";
export const FACILITIES = "Facilities";
export const SETTINGS = "Settings";
export const USER_ROLES = "User Roles";
export const PRACTICE_USERS = "Practice Users";
export const REPORTS = "Reports";

import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import sendHttpRequest from '../components/hooks/sendHttpRequest';
import { Backdrop, CircularProgress } from '@mui/material';
import { useAuth } from '../components/hooks/useAuth';
import { useDispatch } from 'react-redux';
import { setUserPermissionsData } from '../redux/actions/providerActions';

const VeeOneDashboardWrapper = (props) => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const [loading, setLoading] = useState(true);
    const { login } = useAuth();
    const dispatch = useDispatch();

    console.log(params);
    console.log(token);


    async function getUserDetails() {
        const httpResponse = await sendHttpRequest({
            method: 'GET',
            url: '/auth/userinfo'
        });
        if (!httpResponse.error) {
            let response = httpResponse.data;
            dispatch(setUserPermissionsData(response.permissions));
            login({
                loggedIn: true,
                role: response?.role ?? "",
                userId: response?.userId ?? "",
                selectedUserId: response?.userId ?? "",
                timezone: response?.timezoneOffset ?? "",
                timezoneText: response?.timezone ?? "",
                name: response?.name,
                firstName: response?.name[0]?.firstName ?? "",
                lastName: response?.name[0]?.lastName ?? "",
                prefix: response?.name[0]?.prefix ?? "",
                prescription: response?.Identification?.Prescription,
                ePrescription: response?.identification?.status,
                spi: response?.providerTenantPracticeLocation?.spi,
                tenantId: response?.role === "patient" ? response?.tenant?.tenantId : response?.providerTenantPracticeLocation?.tenant?.tenantId,
                tenant: response?.role === "patient" ? response?.tenant : response?.providerTenantPracticeLocation?.tenant,
                facility: response?.providerTenantPracticeLocation?.facility,
                practiceLocationId: response?.providerTenantPracticeLocation?.providerTenantPracticeLocationId,
                isFromIframe: true
            });

        }
        setLoading(false);
    }

    useEffect(() => {
        localStorage.setItem('tkn', token);
        getUserDetails();
    }, [token]);
    return (
        <Backdrop
            sx={{
                color: "#EC609B",
                zIndex: (theme) => theme.zIndex.drawer + 1000,
            }}
            open={loading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export default VeeOneDashboardWrapper
import { useCallback, useState } from "react";

function useSnackbar(initialState = false, initialMessage = "", initialSeverity = "") {
    const [open, setOpen] = useState(initialState);
    const [message, setMessage] = useState(initialMessage);
    const [severity, setSeverity] = useState(initialSeverity);

    const handleClose = useCallback((event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(prevState => !prevState);

    }, [setOpen]);

    return [open, message, severity, setOpen, setMessage, setSeverity, handleClose];
}

export default useSnackbar;
import React, { useEffect, useState } from "react";
import PatientNavbar from "../../components/UI/PatientNavbar";
import {
  Avatar,
  Box,
  Divider,
  Skeleton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import ChangePasswordModal from "../../components/UI/Modals/ChangePasswordModal";
import moment from "moment-timezone";
import "./myAccount.module.css";
import { styled } from "@mui/material/styles";

import TwoFAModal from "../../components/UI/Modals/ProviderModals/TwoFAModal";
import sendHttpRequest from "../../components/hooks/sendHttpRequest";
import CustomizedSnackbars from "../../components/UI/General/CustomizedSnackbars";
import { RolesTextConversion } from "../../utils/helpers/RolesMap";
import { changeDateTimeFormat } from "../../utils/dateTimeHelper";
import DefaultAvatarIcon from "../../assets/icons/Icon-Default-Avatar.svg";
import { useAuth } from "../../components/hooks/useAuth";
import { setDirtyBitInStore } from "../../redux/actions/providerActions";
import { useNavigate } from "react-router";
import { ChevronLeft } from "@mui/icons-material";
import classes from "./MyPatientAccountPage.module.css";
import PageHeader from "../generic/PageHeader";
import {
  fieldValidation,
  formatPhoneNumber,
  isValidZipCode,
  navigateOnDirtyBit,
} from "../../utils/helpers/HelperFunctions";
import PhoneNumberInput from "../../components/UI/Reusable/PhoneNumberInput";
import { relationsListUrl } from "../../res/endpointurls";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "../../components/UI/Reusable/PhoneNumber";
import { Typeahead } from "react-bootstrap-typeahead";
import options from "../../utils/data";
import { FamilyRelation, PatientMethodOfCommunication } from "../../components/UI/Modals/PatientModals/NewPatientModal";
// import 'antd/dist/antd.css';

const MyPatientAccountPage = (props) => {
  const dirtyBitStatus = useSelector((state) => state.dirtyStatus.isDirty);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTZ, setIsLoadingTZ] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [profileInfo, setProfileInfo] = useState("");
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [isAccountUpdate, setIsAccountUpdate] = useState(false);
  const prescriberInfo = useSelector((state) => state.PrecriberData.prescriber);
  const [twoFactorState, setTwoFactorState] = useState(false);
  const [openTwoFAModal, setOpenTwoFAModal] = useState(false);
  const [timexone, setTimexone] = useState("");
  const [offset, setOffset] = useState("");
  const [timexoneArr, setTimexoneArr] = useState([]);
  const { user, updateUser } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [emergencyContact, setEmergencyContact] = useState();
  const [relationsList, setRelationsList] = useState([]);
  const [updateProfileObj, setUpdateProfileObj] = useState();
  const [cellPhone, setCellPhone] = useState();
  const [isUserProfileLoading, setIsUserProfileLoading] = useState(false);

  // Validation States
  const [regexErrors, setRegexErrors] = useState({
    landline: false,
    primary: false,
    emergency: false,
    zipcode: false,
  });
  const [isFamilyMemberValid,setIsFamilyMemberValid] = useState(false)

  const label = { inputProps: { "aria-label": "TwoFactorSwitch" } };
  const TwoFactorSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-switchBase": {
      "&.Mui-checked": {
        "& + .MuiSwitch-track": {
          backgroundColor: "#004FD4",
          opacity: 1,
        },
      },
    },
    "& .MuiSwitch-track": {
      borderRadius: 11 / 2,
      "&:before, &:after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
    },
    "& .MuiSwitch-thumb": {
      borderRadius: 11 / 2,
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
      color: "#fff",
    },
  }));

  const changePassword = () => {
    setOpenChangePasswordModal(true);
  };

  const handleChangePasswordModal = () => {
    setOpenChangePasswordModal(!openChangePasswordModal);
  };

  const handleTwoFAModal = () => {
    setTwoFactorState(!twoFactorState);
  };

  const handlePasswordChangeToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsPasswordChanged(false);
  };

  const handleAccountUpdateToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsAccountUpdate(false);
  };

  const fetchUserProfile = async () => {
    setIsUserProfileLoading(true);
    dispatch(setDirtyBitInStore(false));
    const httpResponse = await sendHttpRequest({
      url: "/auth/currentuser",
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
      getHumanReadableTimeZone();
      setProfileInfo(httpResponse.data);
      let updatePatientData = httpResponse.data;
      setTwoFactorState(httpResponse.data.twoFactorAuth);
      setTimexone(httpResponse.data.timezone);
      setOffset(httpResponse.data.timezoneOffset);
      saveTimezoneLocal(httpResponse.data.timezoneOffset);
      let emergencyContactObj =
        httpResponse.data.communicationNumbers?.emergencyContact;
      if (emergencyContactObj?.length > 0 && emergencyContactObj[0]?.relationship !== undefined) {
        setEmergencyContact(emergencyContactObj[0]?.phoneNumber);
      } else {
        updatePatientData = {
          ...updatePatientData,
          communicationNumbers: {
            ...updatePatientData.communicationNumbers,
            emergencyContact: [
              {
                phoneNumber: "",
                name: "",
                relationship: "",
              },
            ],
          },
        };

        setEmergencyContact("");
      }
      setUpdateProfileObj(updatePatientData);

      setCellPhone(
        httpResponse.data.communicationNumbers?.numbers?.length > 0
          ? httpResponse.data.communicationNumbers?.numbers[0]?.number
          : ""
      );

      if (user?.timezone !== httpResponse.data.timezoneOffset) {
        let newUser = user;
        newUser.timezone = httpResponse.data.timezoneOffset;
        newUser.timezoneText = httpResponse.data.timezone;

        updateUser(newUser);
      }
    } else {
      console.log(httpResponse.error);
    }
    setIsUserProfileLoading(false);
  };

  const fetchTimeZone = async () => {
    setIsLoadingTZ(true);
    const httpResponse = await sendHttpRequest({
      url: "/timezone",
      method: "GET",
    });
    if (!httpResponse.error) {
      setTimeZoneList(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
    setIsLoadingTZ(false);
  };
  const fetchRelations = async () => {
    const httpResponse = await sendHttpRequest({
      method: "GET",
      url: relationsListUrl(),
    });
    if (!httpResponse.error) {
      if (typeof httpResponse.data === "object") {
        setRelationsList(["Select",...httpResponse.data]);
      }
    }
  };

  const updatePatientInformation = async () => {
    let emergencyContactObj = updateProfileObj?.communicationNumbers?.emergencyContact
    let dataObj = updateProfileObj;
    if(emergencyContactObj === undefined || emergencyContact?.length === 0 || emergencyContactObj[0]?.relationship?.length === 0 || emergencyContactObj[0]?.phoneNumber?.length === 0){
      dataObj = {...dataObj,communicationNumbers:{...dataObj?.communicationNumbers,emergencyContact:null}}

    }
    const httpResponse = await sendHttpRequest({
      url: "/patient",
      method: "PUT",
      data: {
        userId: user.userId,
        ...dataObj,
        patientInsurance: dataObj?.patientInsurance,
      },
    });
    // if(!httpResponse.error){
    //   fetchUserProfile();
    // }
  };
  const saveTimezoneLocal = (timezoneOffset) => {
    localStorage.setItem("timezone", JSON.stringify(timezoneOffset));
  };

  const getHumanReadableTimeZone = () => {
    let tzArray = moment.tz.names().map((zone) => {
      return moment.tz(zone).format("zz Z");
    });

    setTimexoneArr([...new Set(tzArray)]);
  };

  const submitAccountChanges = async () => {
    setDisableButton(true);
    dispatch(setDirtyBitInStore(false));
    await updatePatientInformation();
    const httpResponse = await sendHttpRequest({
      url: "/auth/user/update",
      method: "PUT",
      data: {
        userId: profileInfo.userId,
        twoFactorAuth: twoFactorState,
        timezone: timexone,
        timezoneOffset: offset,
      },
    });
    if (!httpResponse.error) {
      setIsAccountUpdate(true);
      fetchTimeZone();
      fetchUserProfile();
    } else {
      console.log(httpResponse.error);
    }
    setDisableButton(false);
  };

  const timezoneHandler = (event) => {
    setOffset(
      event.target[event.target.selectedIndex].getAttribute("data-offset")
    );
    setTimexone(event.target.value);
  };

  function twoFactorSwitch(event) {
    if (event.target.checked) {
      if (!profileInfo?.TwoFactorAuth) {
        handleTwoFAModal();
      } else {
        setTwoFactorState(event.target.checked);
      }
    } else {
      setTwoFactorState(event.target.checked);
    }
    // setProfileInfo({...profileInfo, TwoFactorAuth:checked});
  }

  const getEmergencyRelationship = (relationship) => {
    if (fieldValidation(relationship, "all")) {
      return " (" + relationship + "): ";
    } else {
      return "";
    }
  };

  const emergencyContactHandler = (phoneValue) => {
    let emergencyContactObj =
      updateProfileObj?.communicationNumbers?.emergencyContact;
    if (emergencyContactObj?.length > 0) {
      if (
        phoneValue !== undefined &&
        isValidPhoneNumber(phoneValue?.toString()) &&
        phoneValue?.length === 12
      ) {
        let emergencyContactNumber = formatPhoneNumber(
          phoneValue?.replace("+1", "")
        );
        setUpdateProfileObj({
          ...updateProfileObj,
          communicationNumbers: {
            ...updateProfileObj?.communicationNumbers,
            emergencyContact: [
              {
                ...emergencyContactObj[0],
                phoneNumber: emergencyContactNumber,
              },
            ],
          },
        });
        setEmergencyContact(emergencyContactNumber);
      } else if (
        phoneValue !== undefined &&
        !isValidPhoneNumber(phoneValue.toString()) &&
        phoneValue?.length > 2
      ) {
        console.log("Called else if");
        setUpdateProfileObj({
          ...updateProfileObj,
          communicationNumbers: {
            ...updateProfileObj?.communicationNumbers,
            emergencyContact: [{ ...emergencyContactObj[0], phoneNumber: "" }],
          },
        });
        setEmergencyContact("");
      } else if (phoneValue === undefined || phoneValue?.length === 2) {
        console.log("Called second if");
        setUpdateProfileObj({
          ...updateProfileObj,
          communicationNumbers: {
            ...updateProfileObj?.communicationNumbers,
            emergencyContact: [{ ...emergencyContactObj[0], phoneNumber: "" }],
          },
        });
        setEmergencyContact("");
      }
    }
  };
  const cellPhoneHandler = (phoneValue) => {
    let communicationNumbersObj = updateProfileObj?.communicationNumbers;
    let primaryTelephone = updateProfileObj?.communicationNumbers?.numbers.find(
      (item) => {
        return item.type === "PT";
      }
    );
    if (
      phoneValue !== undefined &&
      isValidPhoneNumber(phoneValue?.toString()) &&
      phoneValue?.length == 12
    ) {
      let mobileNumber = formatPhoneNumber(phoneValue?.replace("+1", ""));
      setUpdateProfileObj({
        ...updateProfileObj,
        communicationNumbers: {
          ...communicationNumbersObj,
          numbers: [{ ...primaryTelephone, number: mobileNumber }],
        },
      });
      setCellPhone(mobileNumber);
    } else if (
      phoneValue !== undefined &&
      !isValidPhoneNumber(phoneValue?.toString()) &&
      phoneValue?.length > 2
    ) {
      setUpdateProfileObj({
        ...updateProfileObj,
        communicationNumbers: {
          ...communicationNumbersObj,
          numbers: [{ ...primaryTelephone, number: "" }],
        },
      });
      setCellPhone("");
    } else if (phoneValue?.length === 2 || phoneValue === undefined) {
      setUpdateProfileObj({
        ...updateProfileObj,
        communicationNumbers: {
          ...communicationNumbersObj,
          numbers: [{ ...primaryTelephone, number: "" }],
        },
      });
      setCellPhone("");
    }
  };

  const zipCodeHandler = (value) => {
    setUpdateProfileObj({
      ...updateProfileObj,
      address: [{ ...updateProfileObj?.address[0], postalCode: value }],
    });
    if (isValidZipCode(value)) {
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        zipcode: false,
      }));
      setDisableButton(false);
    } else {
      if (value === "") {
        setRegexErrors((previousSnapshot) => ({
          ...previousSnapshot,
          zipcode: false,
        }));
        setDisableButton(false);
      } else {
        setRegexErrors((previousSnapshot) => ({
          ...previousSnapshot,
          zipcode: true,
        }));
        setDisableButton(true);
      }
    }
  };
  const handleInputs = (event) => {
    event.preventDefault();
    let id = event.target.id;
    let value = event.target.value;
    let communicationNumbersObj = updateProfileObj?.communicationNumbers;
    if (id === "firstName") {
      setUpdateProfileObj({
        ...updateProfileObj,
        name: [{ ...updateProfileObj?.name[0], firstName: value }],
      });
    } else if (id === "lastName") {
      setUpdateProfileObj({
        ...updateProfileObj,
        name: [{ ...updateProfileObj?.name[0], lastName: value }],
      });
    } else if (id === "addressLine1") {
      setUpdateProfileObj({
        ...updateProfileObj,
        address: [{ ...updateProfileObj?.address[0], addressLine1: value }],
      });
    } else if (id === "addressLine2") {
      setUpdateProfileObj({
        ...updateProfileObj,
        address: [{ ...updateProfileObj?.address[0], addressLine2: value }],
      });
    } else if (id === "city") {
      setUpdateProfileObj({
        ...updateProfileObj,
        address: [{ ...updateProfileObj?.address[0], city: value }],
      });
    } else if (id === "zipCode") {
      zipCodeHandler(value);
    } else if (id === "emergencyContactName") {
      setUpdateProfileObj({
        ...updateProfileObj,
        communicationNumbers: {
          ...updateProfileObj?.communicationNumbers,
          emergencyContact: [
            {
              ...updateProfileObj?.communicationNumbers?.emergencyContact[0],
              name: value,
            },
          ],
        },
      });
    } else if (id === "relation") {
      setUpdateProfileObj({
        ...updateProfileObj,
        communicationNumbers: {
          ...updateProfileObj?.communicationNumbers,
          emergencyContact: [
            {
              ...updateProfileObj?.communicationNumbers?.emergencyContact[0],
              relationship: value,
            },
          ],
        },
      });
    }
  };
  const handlePreset = (s) => {
    
    if (s.length > 0) {
      setUpdateProfileObj((pj) => {
        return {
          ...pj,
          address: [{ ...pj?.address[0], stateProvince: s[0]?.code }],
        };
      });
    } else {
      setUpdateProfileObj((pj) => {
        return { ...pj, address: [{ ...pj?.address[0], stateProvince: "" }] };
      });
    }
  };
  const showValidationMessages = (form) => {
    const invalidElements = form.querySelectorAll(':invalid');
    invalidElements.forEach((element) => {
      element.nextElementSibling.textContent = element.validationMessage;
    });
  
    // Scroll to the first invalid element
    if (invalidElements.length > 0) {
      invalidElements[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
      invalidElements[0].focus();
    }
  };
  
  useEffect(() => {
    fetchRelations();
    fetchTimeZone();
    fetchUserProfile();
  }, []);

  return (
    <>
      {/* <PatientNavbar /> */}
      <ChangePasswordModal
        show={openChangePasswordModal}
        handleModal={handleChangePasswordModal}
        setIsPasswordChanged={setIsPasswordChanged}
      />
      {twoFactorState ? (
        <TwoFAModal
          show={twoFactorState}
          handleModal={handleTwoFAModal}
          headerTitle={"Two-Step Verification"}
          setTwoFactorState={setTwoFactorState}
          fetchUserProfile={fetchUserProfile}
          mobilePhone={
            profileInfo?.communicationNumbers?.numbers.length > 0
              ? profileInfo?.communicationNumbers?.numbers[0]
              : null
          }
        />
      ) : null}
      <PageHeader title="My Account" />

      <div className="row justify-content-center">
        <div className="col-8">
          <Stack
            direction="row"
            className="py-2"
            alignItems="center"
            spacing={2}
          >
            <Avatar
              alt={profileInfo?.Name?.Prefix?.LastName}
              src={
                profileInfo?.profilePicture
                  ? profileInfo?.profilePicture
                  : DefaultAvatarIcon
              }
            />
            <Typography component="div">
              <Box
                sx={{
                  textAlign: "center",
                  m: 1,
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "16px",
                  color: "#323232",
                }}
              >
                {/* {prescriberInfo?.Name?.Prefix} {" "} {prescriberInfo?.Name?.FirstName}{" "}{prescriberInfo?.Name?.LastName} */}
                {profileInfo?.name?.length > 0
                  ? profileInfo?.name[0]?.prefix
                  : ""}
                {""}
                {profileInfo?.name?.length > 0
                  ? profileInfo?.name[0]?.firstName
                  : ""}{" "}
                {profileInfo?.name?.length > 0
                  ? profileInfo?.name[0]?.lastName
                  : ""}
              </Box>
              <Box
                sx={{
                  // textAlign: "center",
                  ml: 1,
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "#323232",
                }}
              >
                {"PID: " + profileInfo?.patientTenantIdentification}
              </Box>
            </Typography>
          </Stack>

          <Divider />
        </div>
      </div>
      {isUserProfileLoading &&
      profileInfo !== undefined &&
      profileInfo?.address?.length > 0 ? (
        <Skeleton />
      ) : (
        <form
          id="patientForm"
          onSubmit={(e) => {
            e.preventDefault();
            const form = e.target;
            if (!form.checkValidity()) {
              showValidationMessages(form);
              return;
            }
            submitAccountChanges();
          }}
        >
          <div className="row justify-content-center">
            <div className="col-8">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="mt-3 mb-2 font-16 fw-sb">
                    {"Personal Information"}
                  </h3>
                </div>
                {/* Name */}
                <div className={`col-4 col-sm-4 form-group ${classes.formGroup}`}>
                  <label
                    htmlFor="firstName"
                    className="form-label m-0 font-12 text--secondary fw-sb"
                  >
                    First Name<span>&#42;</span>
                  </label>
                  <input
                    type="text"
                    defaultValue={
                      profileInfo?.name?.length > 0
                        ? profileInfo?.name[0]?.firstName
                        : ""
                    }
                    className={`form-control ${classes.formControl} font-14 m-0 border--default br-10 shadow-none input`}
                    id="firstName"
                    onChange={(e) => handleInputs(e)}
                    onInvalid={(e) => {
                      e.target.setCustomValidity(
                        "Enter first name"
                      );
                    }}
                    onInput={(e) => {
                      e.target.setCustomValidity("");
                    }}
                    required
                  />
                  {/* {serverErrors.length > 0 && serverErrors?.map((error) => { return error.attributeName === "FirstName" && <span className="text--danger font-10 ms-2 mt-0 lh-0"> {error.message} </span> })} */}
                </div>
                <div className="col-4 col-md-4">
                  <label
                    htmlFor="lastName"
                    className="form-label m-0 font-12 text--secondary fw-sb"
                  >
                    Last Name<span>&#42;</span>
                  </label>
                  <input
                    type="text"
                    defaultValue={
                      profileInfo?.name?.length > 0
                        ? profileInfo?.name[0]?.lastName
                        : ""
                    }
                    className={`form-control font-14 m-0 border--default br-10 shadow-none input`}
                    id={"lastName"}
                    onChange={(e) => {
                      handleInputs(e);
                    }}
                    required
                  />
                  {/* {serverErrors.length > 0 && serverErrors?.map((error) => { return error.attributeName === "FirstName" && <span className="text--danger font-10 ms-2 mt-0 lh-0"> {error.message} </span> })} */}
                </div>
                {/* Patient Id + DOB */}
                <div className="col-4 col-md-4">
                  <label
                    htmlFor="patietnID"
                    className="form-label m-0 font-12 text--secondary fw-sb"
                  >
                    Patient ID
                  </label>
                  <input
                    type="text"
                    disabled={true}
                    value={profileInfo?.patientTenantIdentification}
                    className={`form-control font-14 m-0 border--default br-10 shadow-none input`}
                    style={{ backgroundColor: "#F2F7F8" }}
                  />
                </div>
                <div className="col-4 col-md-4">
                  <label
                    htmlFor="dateofbirth"
                    className="form-label m-0 font-12 text--secondary fw-sb"
                  >
                    Date of Birth
                  </label>
                  <input
                    type="text"
                    disabled={true}
                    value={
                      profileInfo?.dateOfBirth
                        ? changeDateTimeFormat(
                            profileInfo?.dateOfBirth,
                            "MMM D, YYYY"
                          )
                        : ""
                    }
                    className={`form-control font-14 m-0 border--default br-10 shadow-none input`}
                    style={{ backgroundColor: "#F2F7F8" }}
                  />
                </div>
                <div className="col-8"></div>
                {/* Address */}

                <div className="col-6 mt-2">
                  <label
                    htmlFor="AddressLine1"
                    className="form-label fw-sb m-0 font-12 text--secondary"
                  >
                    Address line 1<span>&#42;</span>
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      handleInputs(e);
                    }}
                    defaultValue={
                      profileInfo?.address?.length > 0
                        ? profileInfo?.address[0]?.addressLine1
                        : ""
                    }
                    // onBlur={() => { handleStandardAddress() }}
                    // onFocus={() => { handleStandardAddress() }}
                    autoComplete="off"
                    className="form-control font-14 border--default br-10 shadow-none input"
                    id="addressLine1"
                    name="addressLine1"
                    min={40}
                    required
                  />
                </div>
                {/* Address line 2 */}
                <div className="col-6 mt-2">
                  <label
                    htmlFor="AddressLine2"
                    className="form-label fw-sb m-0 font-12 text--secondary"
                  >
                    Address line 2
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      handleInputs(e);
                    }}
                    defaultValue={
                      profileInfo?.address?.length > 0
                        ? profileInfo?.address[0]?.addressLine2
                        : ""
                    }
                    // onBlur={() => { handleStandardAddress() }}
                    // onFocus={() => { handleStandardAddress() }}
                    autoComplete="off"
                    className="form-control font-14 border--default br-10 shadow-none input"
                    id="addressLine2"
                    name="addressLine2"
                  />
                </div>
                {/* City */}
                <div className="col-4 col-md-4 mt-2">
                  <label
                    htmlFor="City"
                    className="form-label fw-sb m-0 font-12 text--secondary"
                  >
                    City <span>&#42;</span>
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      handleInputs(e);
                    }}
                    defaultValue={
                      profileInfo?.address?.length > 0
                        ? profileInfo?.address[0]?.city
                        : ""
                    }
                    // onBlur={() => handleStandardAddress()}
                    // onFocus={() => handleStandardAddress()}
                    autoComplete="off"
                    className="form-control font-14 border--default br-10 shadow-none input"
                    id="city"
                    name="city"
                    required
                  />
                </div>
                {/* State */}
                <div className="col-4 col-md-4 mt-2">
                  <label
                    htmlFor="State"
                    className="form-label fw-sb m-0 font-12 text--secondary"
                  >
                    State <span>&#42;</span>
                  </label>

                  <Typeahead
                    id="state"
                    name="state"
                    labelKey="name"
                    onChange={handlePreset}
                    // onBlur={handleStandardAddress}
                    // onFocus={handleStandardAddress}
                    options={options}
                    className="rbt-menu my-select font-12"
                    selected={
                      updateProfileObj?.address?.length > 0
                        ? options.filter((item) => {
                            return (
                              item.code ===
                              updateProfileObj?.address[0]?.stateProvince
                            );
                          })
                        : null
                    }
                    // defaultInputValue={standardizedAddress !== undefined && standardizedAddress !== '' ? options.find(s => s.code === standardizedAddress.stateProvince)?.name : props?.patientJson?.state[0]?.name}
                    style={{ borderRadius: "10px" }}
                    autoComplete="off"
                    onInvalid={(e) => {
                      e.target.setCustomValidity("Please select a state");
                    }}
                    onInput={(e) => {
                      e.target.setCustomValidity("");
                    }}
                    inputProps={{ required: true, className: "font-14" }}
                  />
                </div>
                {/* Zip code */}
                <div className="col-md-4 mt-2">
                  <label
                    htmlFor="ZipCode"
                    className="form-label fw-sb m-0 font-12 text--secondary"
                  >
                    Zip Code <span>&#42;</span>
                  </label>
                  <input
                    type="text"
                    defaultValue={
                      profileInfo?.address?.length > 0
                        ? profileInfo?.address[0]?.postalCode
                        : ""
                    }
                    onChange={(e) => handleInputs(e)}
                    // onBlur={handleStandardAddress}
                    // onFocus={handleStandardAddress}
                    className="form-control font-14 border--default br-10 shadow-none input"
                    id="zipCode"
                    name="zipCode"
                    required
                  />
                  <span className="text--danger py-1 font-10 d-block">
                  {regexErrors?.zipcode === true
                    ? "Incorrect Zip Code format"
                    : " "}
                </span>
                </div>
                <div className="col-sm-4">
                  <label
                    htmlFor="Email"
                    className="form-label m-0 font-12 text--secondary fw-sb"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    disabled={true}
                    value={
                      profileInfo?.communicationNumbers?.electronicMail.length >
                      0
                        ? profileInfo?.communicationNumbers.electronicMail[0]
                        : ""
                    }
                    className={`form-control font-14 m-0 border--default br-10 input`}
                    style={{ backgroundColor: "#F2F7F8" }}
                  />
                </div>
                <div className="col-sm-4">
                  <label
                    htmlFor="primary-contact"
                    className="form-label m-0 font-12 text--secondary fw-sb"
                  >
                    Primary Phone<span>&#42;</span>
                  </label>
                  <PhoneNumberInput
                    value={cellPhone || ""}
                    name="CellPhone"
                    onChange={cellPhoneHandler}
                    id="CellPhone"
                    required={true}
                  />
                </div>
                <div className="pt-3">
                  <Divider />
                </div>
                <FamilyRelation
                  relationsList={relationsList ?? []}
                  setPatientJson={setUpdateProfileObj}
                  patientJson={updateProfileObj}
                  patientMyAccount={true}
                  setDisableButton={setIsFamilyMemberValid}
                />
                <div className="pt-3">
                  <Divider />
                </div>
                {/* Emergency Contact Information */}
                <Stack>
                  <div className="col-md-12">
                    <h3 className="mt-3 mb-2 font-16 fw-sb">
                      {"Emergency Contact Information"}
                    </h3>
                  </div>

                  <div className="row">
                    <div className="col-4 col-md-4">
                      <label
                        htmlFor="EmergencyContactName"
                        className="form-label m-0 fw-sb font-12 text--secondary"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        onChange={(e) => {
                          handleInputs(e);
                        }}
                        defaultValue={
                          updateProfileObj?.communicationNumbers
                            ?.emergencyContact?.length > 0
                            ? updateProfileObj?.communicationNumbers
                                ?.emergencyContact[0]?.name
                            : ""
                        }
                        autoComplete="off"
                        className="form-control font-14 border--default br-10 shadow-none input"
                        id="emergencyContactName"
                        name="emergencyContactName"
                      />
                    </div>
                    {/* Emergency Contact Number */}
                    <div className="col-4 col-md-4 ">
                      <label
                        htmlFor="EmergencyPhone"
                        className="form-label m-0 fw-sb font-12 text--secondary"
                      >
                        Phone
                      </label>
                      <div>
                        <PhoneNumberInput
                          value={
                            emergencyContact?.length > 0 ? emergencyContact : ""
                          }
                          name="EmergencyPhone"
                          onChange={emergencyContactHandler}
                          id="EmergencyPhone"
                          required={false}
                        />
                      </div>

                      {/* <span className="text--danger py-1 font-10 d-block">
                            {regexErrors?.emergency === true
                              ? "Incorrect number format"
                              : <ErrorMessage
                                serverErrorsArray={props?.serverErrors}
                                fieldName={"number"}
                              />}
                          </span> */}
                    </div>
                    {/* Relation */}
                    <div className="col-4 col-md-4">
                      <label
                        htmlFor="Relationship"
                        className="form-label m-0 fw-sb font-12 text--secondary"
                      >
                        Relationship
                      </label>
                      <select
                        onChange={(e) => {
                          handleInputs(e);
                        }}
                        // onChange={emergencyContactRelationshipHandler}
                        className="form-select font-14 border--default br-10 shadow-none input"
                        aria-label="relation"
                        id="relation"
                        name="relation"
                        style={{ borderRadius: "10px" }}
                        value={
                          updateProfileObj?.communicationNumbers
                            ?.emergencyContact?.length > 0
                            ? updateProfileObj?.communicationNumbers
                                ?.emergencyContact[0]?.relationship
                            : ""
                        }
                      >
                        {/* <option value="Select">Select</option> */}
                        {relationsList?.length > 0
                          ? relationsList?.map((relation, index) => {
                              return (
                                <option value={relation} key={index}>
                                  {relation
                                    .match(/([A-Z]?[^A-Z]*)/g)
                                    .slice(0, -1)
                                    .join(" ")}
                                </option>
                              );
                            })
                          : null}
                      </select>
                    </div>
                  </div>
                </Stack>
                <div className="pt-3">
                  <Divider />
                </div>
                <div className="col-12">
                    <PatientMethodOfCommunication setPatientJson={setUpdateProfileObj}
                  patientJson={updateProfileObj}/>
                </div>
                <div className="pt-3">
                  <Divider />
                </div>
                {/* Change password */}
                <div className="col-4 col-sm-4 mt-3">
                  <span
                    className=" text--blue fw-sb font-14 shadow-none"
                    onClick={handleChangePasswordModal}
                  >
                    Change Password
                  </span>
                </div>
                <div className="col-12">
                  <Divider />
                </div>
                {/* Two step verification */}
                <div className="col-md-12">
                  <h3 className="mt-3 mb-2 font-16 fw-sb">
                    {"Two-Step Verification"}
                  </h3>
                </div>
                <Stack
                  direction="row"
                  className="py-2"
                  justifyContent={"between"}
                  spacing={2}
                >
                  <div
                    className="col-sm-4"
                    style={{
                      backgroundColor: "#ffffff",
                      border: "1px solid #D9E8EC",
                      borderRadius: "10px",
                    }}
                  >
                    <div className="row">
                      <div className="col-sm-8 justify-content-start align-self-center">
                        <Typography component="span">
                          <Box
                            sx={{
                              m: 1,
                              fontFamily: "Montserrat",
                              fontWeight: "600",
                              fontSize: "12px",
                              color: "#323232",
                            }}
                          >
                            {twoFactorState === true ? "Enabled" : "Disabled"}
                          </Box>
                        </Typography>
                      </div>
                      <div className="col-4 justify-content-end align-self-center">
                        <TwoFactorSwitch
                          {...label}
                          checked={twoFactorState}
                          onChange={twoFactorSwitch}
                        />
                        {/* <Switch style={{ borderRadius: '5px' }} checked={twoFactorState} onChange={twoFactorSwitch} /> */}
                      </div>
                    </div>
                  </div>
                </Stack>

                <div className="pt-4">
                  <Divider />
                </div>

                <Stack
                  direction="row"
                  className="py-1"
                  justifyContent={"between"}
                  alignItems="center"
                  spacing={2}
                >
                  <div className="col-md-12">
                  <h3 className="mt-3 mb-2 font-16 fw-sb">
                    {"Timezone"}
                  </h3>
                </div>
                </Stack>

                <Stack
                  direction="row"
                  className="py-2"
                  alignItems="center"
                  spacing={2}
                >
                  <div className="col-sm-4">
                    <select
                      onChange={timezoneHandler}
                      className="form-select font-14 fw-sb border--default br-10 shadow-none input"
                      aria-label="timezone"
                      id="timezone"
                      style={{ borderRadius: "10px" }}
                      placeholder="Select Timezone"
                    >
                      <option
                        className="fw-sb"
                        disabled
                        selected
                        hidden
                        value=""
                      >
                        Select Timezone
                      </option>
                      {isLoading ? (
                        <option className="fw-sb">Loading...</option>
                      ) : timeZoneList?.length > 0 ? (
                        timeZoneList?.map((item, index) => {
                          return (
                            <option
                              key={item + index}
                              value={item?.name}
                              data-offset={item?.offset}
                              selected={item?.name === profileInfo?.timezone}
                              className="fw-sb"
                              // selected={ === item.Id}
                            >
                              {item?.name} &nbsp; {item?.offset}
                              {/* {moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z')} */}
                              {/* {Date().slice(Date().indexOf("(") + 1, Date().lastIndexOf(")"))} */}
                            </option>
                          );
                        })
                      ) : (
                        ""
                      )}
                    </select>
                  </div>
                </Stack>

                <div className="col-12 pt-4">
                  <Divider />
                </div>

                <div className="col-12 text-end mt-3 mb-3">
                  <button
                    className="btn blue_task_button"
                    disabled={disableButton || isFamilyMemberValid}
                    // onClick={}
                    type="submit"
                    form="patientForm"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}

      <CustomizedSnackbars
        message={"Changes saved successfully"}
        severity="success"
        open={isPasswordChanged}
        handleClose={handlePasswordChangeToastClose}
      />
      <CustomizedSnackbars
        message={"Changes saved successfully"}
        severity="success"
        open={isAccountUpdate}
        handleClose={handleAccountUpdateToastClose}
      />
      {/* {console.log("updateProfileObj: ", updateProfileObj)}
      {console.log("emergencyContact[0]: ", emergencyContact)}
      {console.log("cellPhone: ", cellPhone)} */}
      {/* {console.log(options.find((item)=> {return item.code === profileInfo?.address[0]?.stateProvince}))} */}
    </>
  );
};

export default MyPatientAccountPage;

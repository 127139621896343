import React from "react";
import { Modal } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import sendHttpRequest from "../../../hooks/sendHttpRequest";
import CloseIcon from "@mui/icons-material/Close";
import classes from "./PatientNewMessageModal.module.css";
import HospitalIcon from "../../../../assets/icons/hospital.svg";
import DiscussionIcon from "../../../../assets/icons/discussion.svg";
import DropIcon from "../../../../assets/icons/Icon-Drop.svg";
import { ChevronLeft, Label } from "@mui/icons-material";
import { FileUploader } from "react-drag-drop-files";
import TrashIcon from "../../../../assets/icons/TrashIcon";
import TextArea from "../../../forms/TextArea";


const PatientNewMessageModal = (props) => {
  const [openMedicalQuestion, setOpenMedicalQuestion] = useState(false);
  const [openCustomeService, setOpenCustomeService] = useState(false);
  const [selectedMessageTypeMap, setSelectedMessageTypeMap] = useState(
    new Map([["selected", "Care Team"]])
  );
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState([]);
  const fileTypes = ["JPEG", "PNG", "GIF", "JPG", "PDF"];
  const [isMessageSending, setIsMessageSending] = useState(false);

  const handleMedicalQuestion = () => {
    setOpenCustomeService(false);
    setOpenMedicalQuestion(!openMedicalQuestion);
  };
  const handleCustomService = () => {
    setOpenMedicalQuestion(false);
    setOpenCustomeService(!openCustomeService);
  };
  const handleHomePage = () => {
    setOpenMedicalQuestion(false);
    setOpenCustomeService(false);
  };
  const handleMessageType = (event) => {
    event.preventDefault();
    setSelectedMessageTypeMap(new Map([["selected", event.target.id]]));
    console.log(event.target.id);
  };

  const handleFileInput = (file) => {
    const temp = [];
    temp.push(file);
    setFileName(temp);
    sendFileAndType(file);
    // console.log(temp);
  };
  const removeFile = (event) => {
    const dummy = fileName.filter((file, index) => index != event.target.id);
    setFileName(dummy);
    setFile("");
  };
  const sendFileAndType = (file) => {
    let reader = new FileReader();
    // if (selectedFile !== '') {
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      let bsfe = e.target.result.split(",")[1];
      setFile(bsfe);
    };
    // }
  };

  return (
    <>
      <Modal
        show={props.show}
        backdropClassName="modal-backdrop"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        contentClassName={`modal-border modal-shadow`}
        centered
        keyboard={false}
      >
        <Modal.Header className="modal-header">
          <span
            // className={`${classes["close__button"]}`}
            style={{
              lineHeight: "12px",
              width: "18px",
              fontSize: "8pt",
              color: "#336383",
              position: "absolute",
              top: 15,
              right: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              props?.handleClose();
            }}
          >
            {<CloseIcon />}
          </span>

          <Modal.Title className="font-20 fw-sb text--terciary">
            {openMedicalQuestion ? (
              <>
                <div className="d-flex flex-row">
                  <div
                    className={`bg--background ${classes["chevron__left__div"]} br-10 me-3 border--default  text-center my-auto cursor--pointer`}
                    onClick={() => {
                      handleHomePage();
                    }}
                  >
                    <ChevronLeft
                      style={{ color: "#EC609B" }}
                      className="my-auto mt-2"
                    />
                  </div>
                  <div className="align-self-center">
                    <span classsName="">Medical Question</span>
                  </div>
                </div>
              </>
            ) : openCustomeService ? (
              <>
                <div className="d-flex flex-row">
                  <div
                    className={`bg--background ${classes["chevron__left__div"]} br-10 me-3 border--default  text-center my-auto cursor--pointer`}
                    onClick={() => {
                      handleHomePage();
                    }}
                  >
                    <ChevronLeft
                      style={{ color: "#EC609B" }}
                      className="my-auto mt-2"
                    />
                  </div>
                  <div className="align-self-center">
                    <span classsName="">Customer Service</span>
                  </div>
                </div>
              </>
            ) : (
              <>{"New Message"}</>
            )}
          </Modal.Title>
        </Modal.Header>
        <div className="bg--disabled text--secondary font-12  py-2 px-3 g-0">
          <span>Please call 911 in case of an emergency</span>
        </div>
        <Modal.Body className="font-14">
          <div className="container-fluid">
            {!(openMedicalQuestion || openCustomeService) ? (
              <>
                {/* Main Body */}
                <div className="row">
                  <div className="col-sm-12 text--terciary  fw-sb  p-0">
                    <span className="">What’s your message about?</span>
                  </div>
                  <div className="col-sm-12 mt-1 mx-auto text--terciary border--default br-10 ">
                    <div
                      className="d-flex flex-row justify-content-between p-1 cursor--pointer"
                      onClick={() => {
                        handleMedicalQuestion();
                      }}
                    >
                      <div className="d-flex flex-row">
                        <div className="ms-2 align-self-center">
                          <img src={HospitalIcon} alt="hospital icon"></img>
                        </div>
                        <div className="ms-4 text--secondary  ">
                          <div className="fw-sb">
                            <span>Medical Question</span>
                          </div>
                          <div>
                            <span>Non-urgent medical question</span>
                          </div>
                        </div>
                      </div>
                      <div className="align-self-center">
                        <img src={DropIcon} className="cursor--pointer" />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 mt-1 mb-3 mx-auto text--terciary border--default br-10 ">
                    <div
                      className="d-flex flex-row justify-content-between p-1 cursor--pointer"
                      onClick={() => {
                        handleCustomService();
                      }}
                    >
                      <div className="d-flex flex-row">
                        <div className="ms-2 align-self-center">
                          <img src={DiscussionIcon} alt="hospital icon"></img>
                        </div>
                        <div className="ms-4 text--secondary  ">
                          <div className="fw-sb">
                            <span>Customer Service</span>
                          </div>
                          <div>
                            <span>Billing, Insurance and other matters</span>
                          </div>
                        </div>
                      </div>
                      <div className="align-self-center">
                        <img
                          src={DropIcon}
                          className="cursor--pointer"
                          onClick={() => {
                            handleCustomService();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {/* Message Body */}
                <div className="row font-14">
                  <form
                    id="messageSubmitForm"
                    onSubmit={(e) => {
                        e.preventDefault();
                        // mesasgeHandler();
                      }}
                  >
                    <div className="text--secondary">
                      <label className="font-12">
                        Send to <span className="text--danger">&#42;</span>
                      </label>
                      <div className="">
                        <button
                          className={`border--default br-7 font-12 fw-sb ${classes["message__type__button"]} 
                                        ${
                                          selectedMessageTypeMap.get(
                                            "selected"
                                          ) === "Billing"
                                            ? "bg--secondary text--white"
                                            : "bg--disabled text--terciary"
                                        }
                                    `}
                          onClick={(e) => {
                            handleMessageType(e);
                          }}
                          id={"Billing"}
                          
                        >
                          Billing
                        </button>
                        <button
                          className={`border--default br-7 font-12 fw-sb ${classes["message__type__button"]} ms-3
                                    ${
                                      selectedMessageTypeMap.get("selected") ===
                                      "Care Team"
                                        ? "bg--secondary text--white"
                                        : "bg--disabled text--terciary"
                                    }
                                `}
                          id={"Care Team"}
                          onClick={(e) => {
                            handleMessageType(e);
                          }}
                        >
                          Care Team
                        </button>
                      </div>
                    </div>
                    {/* Subject */}
                    <div className="col-md-12 mt-2 mb-2">
                      <label
                        htmlFor="Subject"
                        className="form-label m-0 font-12 text--secondary"
                      >
                        Subject <span className="text--danger">&#42;</span>
                      </label>
                      <input
                        type="text"
                        className={
                          "form-control text--terciary msg-input font-12 border--default br-10 shadow-none"
                        }
                        id="Subject"
                        required
                        // onChange={handleSubject}
                      />
                    </div>

                    {/* Body */}
                    <div className="col-12 mt-2">
                      <label
                        htmlFor="note"
                        className="form-label m-0 font-12 text--secondary"
                      >
                        Message
                        <span className="text--danger">&#42;</span>
                      </label>
                      <TextArea
                        // type="text"
                        className={`form-control font-12 m-0 border--default text--terciary br-10 shadow-none msg-input`}
                        id="message"
                        maxLength="2048"
                        // gettext={handleMessage}
                        rows={4}
                        required = {true}
                      />
                    </div>

                    <div className="col-12 mt-3">
                      <FileUploader
                        handleChange={handleFileInput}
                        name="file"
                        label="Drop a file or click here"
                        types={fileTypes}
                      >
                        <div className="border--dashed bg--white br-10 d-flex justify-content-center font-12 py-3">
                          <div>
                            Drag and drop files or{" "}
                            <u className="file-link">select one</u>
                          </div>
                        </div>
                      </FileUploader>
                    </div>
                    <div className="col-12 mt-5 ">
                      <div className="d-flex flex-row  justify-content-between">
                        <div className="ms-4">
                          <button
                            type="button"
                            className={`btn fw-sb font-12 d9e8ec_button text--blue  w-10 br-10  `}
                            id={""}
                            value={false}
                            disabled={isMessageSending}
                            onClick={() => {
                              props?.handleClose();
                            }}
                          >
                            {"Cancel"}
                          </button>
                        </div>
                        <div className=" me-5">
                          {/* <Stack direction="row" component="div"> */}
                          <button
                            type="submit"
                            className={`btn fw-sb font-12 send_button text--white  br-10 `}
                            form="messageSubmitForm"
                            disabled={isMessageSending}
                            value={true}
                            // onClick={(e) => { props.refillRequestStatusHandler(e) }}
                          >
                            {"Send"}
                          </button>
                          {/* </Stack> */}
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PatientNewMessageModal;

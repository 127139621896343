import { useState, useEffect } from 'react';
import ClaimsManagementService from '../Services/ClaimManagment.service';

const usePlaceOfServiceCodes = (patientId) => {
    // console.log(patientId);
    const [placeOfServiceCodes, setPlaceOfServiceCodes] = useState([]);
    const [isPlaceOfServiceCodeLoading, setIsPlaceOfServiceCodeLoading] = useState(false);
    const [isPlaceOfServiceCodeError, setIsPlaceOfServiceCodeError] = useState(false);

    const fetchPlaceOfServiceCodes = async () => {
        setIsPlaceOfServiceCodeLoading(true);
        const response = await ClaimsManagementService.fetchPlaceOfServiceCodes();
        if (response !== null) {
            setPlaceOfServiceCodes(response);
        }
        else {
            setIsPlaceOfServiceCodeError(true);
        }
        setIsPlaceOfServiceCodeLoading(false);
    }
    useEffect(() => {
        fetchPlaceOfServiceCodes();
    }, [])

    return { placeOfServiceCodes, isPlaceOfServiceCodeLoading, isPlaceOfServiceCodeError };
}

export default usePlaceOfServiceCodes;
import { Modal, ModalFooter } from "react-bootstrap";
import classes from "./EnrollmentStatusModal.module.css";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import CalenderDatePicker from "../../General/CalenderDatePicker";
import sendHttpRequest from "../../../hooks/sendHttpRequest";
import { useAuth } from "../../../hooks/useAuth";
import { DateTime } from "luxon";

const EnrollmentStatusModal = (props) => {
  const [enrollmentDate, setEnrollmentDate] = useState();
  const [enrollmentStatus,setEnrollmentStatus] = useState();
  const [isRequestSending,setIsRequestSending] = useState(false);
  const {user} = useAuth();
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const todayDate = [year, month, day].join("-");
  
  const updateEnrollmentStatus = async()=>{
    console.log("Called");
    setIsRequestSending(true);
    console.log("user.tenantId: ",user.tenantId);
    let url = `/tenants/${user.tenantId}/enrollment-status`
    if(props?.providerPage){
        url = `/providers/${user?.userId}/tenants/${user.tenantId}/enrollment-status`
    } 
    const httpResponse = await sendHttpRequest({
        url:url,
        method:"PATCH",
        data:{
            "status": enrollmentStatus,
            "date": enrollmentDate
        }

    })
    if(!httpResponse.error){
        if(props?.providerPage){
            props?.setEnrollmentStatus({
                displayValue: enrollmentStatus.charAt(0).toUpperCase() + enrollmentStatus.slice(1).toLowerCase(),
                internalValue: enrollmentStatus?.toLowerCase()
            })
        }
        if(props?.fetchTenantSettings !== undefined){
            props?.fetchTenantSettings();
        }
        props.handleModal();
    }
    setIsRequestSending(false);
  }
  const handleEnrollmentDate = (dateValue) => {
    setEnrollmentDate(dateValue);
  };
  const handleEnrollmentStatus = (statusValue)=>{
    setEnrollmentStatus(statusValue?.toLowerCase())
  }
  
  return (
    <Modal
      show={props.show}
      backdropClassName="modal-backdrop"
      contentClassName="modal-border modal-shadow"
      onHide={() => {
        props.handleModal();
      }}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter "
          className="font-20 fw-sb text--terciary"
        >
          Update Enrollment Status
        </Modal.Title>
      </Modal.Header>
      <form
        id="enrollmentForm "
        onSubmit={(e) => {
            // console.log("called+++++++++++++++++++++++++++++");
            e.preventDefault(); // Prevent the default form submission
            updateEnrollmentStatus();
            
        }}
        >
      <Modal.Body>
       
          
          <div className={`container ${classes["modal__container"]} `}>
            <div className="row ">
              <div className="col-4">
                <label
                  htmlFor="startDate"
                  className="form-label font-12 text--secondary  me-2 fw-sb text-nowrap align-self-center"
                >
                  {"Status Update Date: "}
                </label>
              </div>
              <div className="col-2 form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="enrollment"
                  id={"approve"}
                  value={1}
                  required={true}
                  onChange={()=>{handleEnrollmentStatus("Completed")}}
                  //   checked={orderType === _str?.ePrescription}
                />
                <label className="form-check-label font-12" htmlFor={"approve"}>
                  {"Complete"}
                </label>
              </div>
              <div className="col-2 form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="enrollment"
                  id={"reject"}
                  value={0}
                  required={true}
                  onChange={()=>{handleEnrollmentStatus("Rejected")}}
                  //   checked={orderType === _str?.ePrescription}
                />
                <label className="form-check-label font-12" htmlFor={"reject"}>
                  {"Reject"}
                </label>
              </div>
              <div className="col-4"></div>
              <div className="col-5 g-0">
                <CalenderDatePicker
                  key={"startDate"}
                  name="startDate"
                  id={"startDate"}
                  f13={true}
                  // date={calenderDateTimeHelper(
                  //     tenantSettingsData?.tenantConfiguration?.length > 0
                  //     ? tenantSettingsData?.tenantConfiguration[0]
                  //         ?.contractStartDate
                  //     : new Date(),
                  //     "YYYY-MM-DD"
                  // )}
                  dobHandler={(e) => {
                    handleEnrollmentDate(e);
                  }}
                  dateFormat="MMM d, yyyy"
                  maxDate={todayDate}
                //   minDate = {DateTime.now()}
                  // disabled={true}
                  required={true}
                />
              </div>
            </div>
          </div>
        
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <button
          type="submit"
          id="enrollmentForm"
          className={`btn   blue_task_button px-4 `}
          disabled={isRequestSending}
        >
          Submit
        </button>
      </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EnrollmentStatusModal;

import Select from 'react-select';
import { colors } from './design-tokens';

// Custom styles for the React Select component
const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderRadius: '10px',
        borderColor: '#d9e8ec',
        boxShadow: 'none',
        fontSize: '12px',
        // minHeight: '32px',
        boxShadow: state.isFocused ? '0 0 0 0px #d9e8ec' : provided.boxShadow,
        '&:hover': {
            borderColor: state.isFocused ? '#d9e8ec' : provided.borderColor,
        },


    }),

    placeholder: (provided) => ({
        ...provided,
        color: '#B5BABE',
    }),

    option: (provided) => ({
        ...provided,
        fontSize: '12px',
    }),

    menu: (provided) => ({
        ...provided,
        zIndex: 1000000,
    }),

    dropdownIndicator: (provided) => ({
        ...provided,
        color: colors.primary,
        padding: '6px',
        '& svg': {
            stroke: colors.primary, // Change the color of the chevron
            strokeWidth: '0.1', // Adjust the thickness of the chevron
        },
        '&:hover': {
            color: colors.primary
        }
    }),

    clearIndicator: (provided, state) => ({
        ...provided,
        color: colors.grey,
        '&:hover': {
            color: colors.grey,
        },
        onClick: (e) => {
            e.stopPropagation();
            state.clearValue();
        },
    }),
};







// Example usage of the React Select component with custom styles
export function PnSelect(props) {
    const { isMulti, value, onMenuClose, onChange } = props;
    const handleMenuClose = () => {
        if (!value) {
            onChange(null);
        }
        onMenuClose?.();
    };
    return (<>
        <Select
            styles={customStyles}
            onMenuClose={handleMenuClose}
            {...props}
        />

    </>
    );
}
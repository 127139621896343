import MessageTabs from "../UI/Tabs/MessageTabs";
import "./TaskPage.css";
const MessagesPage = () => {
  return (
    <>
      {/* <Navbar /> */}
      <MessageTabs />
    </>
  )
}

export default MessagesPage;

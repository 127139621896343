import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import sendHttpRequest from "../../../hooks/sendHttpRequest";
import CloseIcon from "@mui/icons-material/Close";
import { sleepSync } from "../../../../utils/helpers/HelperFunctions";

const FacesheatModal = (props) => {
  const [selectedFile, setSelectedFile] = useState();
  const [uploadingFile, setUploadingFile] = useState(false);
  const [uploadingFileError, setUploadingFileError] = useState("");
  const [file, setFile] = useState("");

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const convertToMultipart = (fileV, trimmedDataURL) => {
    let mimeType = trimmedDataURL.split(",")[0].split(":")[1].split(";")[0];
    console.log("mimeType: " + mimeType);
    fetch(trimmedDataURL)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], fileV?.name, {
          type: 'text/csv',
        });
        setFile(file);
        //form data
        const formData = new FormData();
        formData.append("file", file);
        uploadFile(formData);
      });
  };

  const sendFileAndType = () => {
    let reader = new FileReader();
    if (selectedFile !== "") {
      reader.readAsDataURL(selectedFile);
      reader.onload = (e) => {
        convertToMultipart(selectedFile, e.target.result);
        // let bsfe = e.target.result.split(',')[1];
      };
    }
  };

  const uploadFile = async (fileData) => {
    setUploadingFile(true);
    const httpResponse = await sendHttpRequest({
      url: "/faceSheet/csv",
      method: "POST",
      data: fileData,
      headers: {
        "Content-Type": "multipart/form-data; boundary=",
      },
    });
    if (!httpResponse.error) {
      console.log(await httpResponse.data);
      await props?.setFacesheetContent(httpResponse.data);
      props?.setFacesheetUploaded(true);

      props?.handlePatientFacesheetModal();
      props?.handleModal();
      // emptyStates();
    } else {
      props?.setErrorMessage(
        "There was an error while uploading the facesheet"
      );
      props?.setFacesheetUploadedFail(true);
      console.log(httpResponse);
      // setUploadingFileError(httpResponse)
    }
    setUploadingFile(false);
  };

  useEffect(() => {
    return () => {
      setSelectedFile(null);
      setUploadingFile(null);
    };
  }, []);

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props?.handleModal();
      }}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      backdropClassName="modal-backdrop"
      contentClassName="modal-border modal-shadow"
    // style={{style}}
    >
      <Modal.Header className="modal-header">
        <span
          style={{
            lineHeight: "12px",
            width: "18px",
            fontSize: "8pt",
            color: "#336383",
            position: "absolute",
            top: 15,
            right: 15,
            cursor: "pointer",
          }}
          onClick={() => {
            props?.handleModal();
          }}
        >
          {<CloseIcon />}
        </span>
        <Modal.Title
          className="font-18 fw-sb"
          id="contained-modal-title-vcenter"
        >
          Upload Facesheet
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <p className="m-0 font-12">
                Please select a CSV file containing information of the patients that need to be added to the system.
              </p>
              <div className=" p-4">
                <form
                  id="facesheetForm"
                  encType="multipart/form-data"
                  onSubmit={(e) => {
                    e?.preventDefault();
                    sendFileAndType();
                  }}
                >
                  <input
                    className="form-control font-13 m-0 shadow-none br-10 border--default input"
                    type="file"
                    required
                    accept={".csv"}
                    id="formReportFile"
                    onChange={handleFileChange}
                  />
                </form>
              </div>
            </div>
            <span className={`font-13 m-0`} style={{ color: "red" }}>{uploadingFileError}</span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="submit"
          form="facesheetForm"
          className="btn blue_task_button"
        >
          {uploadingFile ? "Uploading..." : "Upload"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default FacesheatModal;

import { useEffect, useRef, useState } from "react";
import options from "../../../../utils/data";
import PhoneInput from "react-phone-number-input/input";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";

import { useDispatch, useSelector } from "react-redux";
import { setDirtyBitInStore } from "../../../../redux/actions/providerActions";
import { setIsPatientEdited } from "../../../../redux/actions/providerActions";
import { addressType, numbersEnum } from "../../../../res/strings";

import MaskInput, { formatPhoneNumber, isValidZipCode, navigateOnDirtyBit } from "../../../../utils/helpers/HelperFunctions";
import { getStandardizedAddress } from "../../../../utils/helpers/DataLoaders";
// import PhoneNumberInput from "../../Reusable/PhoneNumberInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import { isValidSSN, unsavedWorkHandler } from "../../../../utils/helpers/HelperFunctions";
import CustomDatePicker from "../../General/CustomDatePicker";
import { ERROR_MESSAGES, FIELD_NAMES, MIN_MAX_VALUES } from "../../../validations/validationschemas";
import { ValidationErrorMessage } from "../../../validations/ValidationErrorMessage";
import zIndex from "@mui/material/styles/zIndex";
import { useLocation, useNavigate } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import CalenderDatePicker from "../../General/CalenderDatePicker";
import ErrorMessage from "../../Reusable/ErrorMessage";
import { fieldNames } from "../../../../utils/helpers/ErrorFields";
import { FamilyRelation, PatientMethodOfCommunication } from "./NewPatientModal";

const EditPatientModal = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dirtyBitStatus = useSelector((state) => state.dirtyStatus.isDirty);
  const field_Names = { ...FIELD_NAMES.user.name, ...FIELD_NAMES.patient };
  const PATIENT_VALIDATION = { ...MIN_MAX_VALUES.name, ...MIN_MAX_VALUES.patient };
  const validationErrorMsgs = { ...ERROR_MESSAGES.user.name, ...ERROR_MESSAGES.patient };
  const [validationErrors, setValidationErrors] = useState({});

  let regex = /\([0-9]{3}\) [0-9]{3}-[0-9]{4}/i;
  const __str = numbersEnum.en;
  const _numberTypes = numbersEnum["en"];
  const _addressTypes = addressType["en"];
  const [firstName, setFirstName] = useState(
    props.patientJson?.firstName || null
  );
  const [lastName, setLastName] = useState(
    props.patientJson?.lastName || null
  );

  const [middleName, setMiddleName] = useState(props.patientJson?.middleName || null);

  const [gender, setGender] = useState(props.patientJson?.gender);
  const [dob, setDob] = useState(props.patientJson?.dateOfBirth);
  const [pharmacy, setPharmacy] = useState(
    props.patientProfileData?.PharmacyId || null
  );
  const [pharmacies, setPharmacies] = useState("");
  const [insured, setInsured] = useState(props.patientJson?.insured || false);
  const [emergencyContactName, setEmergencyContactName] = useState(props.patientJson?.emergencyContactName);
  const [emergencyContactPhone, setEmergencyContactPhone] = useState(
    props.patientJson?.emergencyContact
      ? props.patientJson.emergencyContact.includes("+1")
        ? props.patientJson.emergencyContact
        : "+1" + props.patientJson.emergencyContact
      : null
  );
  const [emergencyContactRelation, setEmergencyContactRelation] = useState(props.patientJson?.emergencyContactRelationship);
  const [addressLine1, setAddressLine1] = useState(
    props.patientJson?.addressLine1 || ""
  );
  const [addressLine2, setAddressLine2] = useState(
    props.patientJson?.addressLine2 || ""
  );
  const [city, setCity] = useState(props.patientJson?.city || "");
  const [state, setState] = useState([]);
  const [zipCode, setZipCode] = useState(props.patientJson?.postalCode || "");
  const [landline, setLandline] = useState(
    props.patientJson?.homeTelephone ? props.patientJson?.homeTelephone?.includes("+1") ? props.patientJson?.homeTelephone : "+1" + props.patientJson?.homeTelephone : null);

  const [cellPhone, setCellPhone] = useState(props.patientJson?.cellPhone
    ? props.patientJson.cellPhone.includes("+1")
      ? props.patientJson.cellPhone
      : "+1" + props.patientJson.cellPhone
    : null);
  const [email, setEmail] = useState(props.patientJson?.email || "");

  const [emergencyContact, setEmergencyContact] = useState(
    props.patientJson?.otherTelephone?.length > 0 ? '+1' + props.patientJson?.otherTelephone : null || null
  );
  const [consent, setConsent] = useState(
    props.patientJson?.emailConsent || false
  );
  const [regexErrors, setRegexErrors] = useState({
    landline: false,
    primary: false,
    emergency: false,
    zipcode: false,
  });
  const [serverErrors, setServerErrors] = useState(null);

  const firstNameRef = useRef();

  const dispatch = useDispatch();

  const [isStandardAddress, setIsStandardAddress] = useState(false);
  const [standardizedAddress, setStandardizedAddress] = useState("");
  const [showAddress, setShowAddress] = useState(false);
  const [updateDisabled, setUpdateDisabled] = useState(false);

  const [validSSN, setValidSSN] = useState(props.patientJson?.validSSN ?? "");
  const [ssn, setSsn] = useState(props.patientJson?.socialSecurity);
  const [isFamilyMemberValid,setIsFamilyMemberValid] = useState(false)


  const clearState = () => {
    setFirstName("");
    setLastName("");
    setEmergencyContactName("");
    setEmergencyContactPhone("");
    setEmergencyContactRelation("");
    setPharmacy("");
    setCity("");
    setState("");
    setEmail("");
    setCellPhone("");
    setLandline("");
    setZipCode("");
    setConsent(null);
  };

  const firstNameHandler = (event) => {

    let firstName = event.target.value;
    setFirstName(firstName);

    if (firstName?.length > PATIENT_VALIDATION.max) {
      setValidationErrors({ ...validationErrors, [field_Names.firstNameMax]: validationErrorMsgs.firstNameMax })
    }
    else {
      let temp = validationErrors;
      delete temp.firstNameMax;
      setValidationErrors(temp)
    }
    setUpdateDisabled(true);
    dispatch(setDirtyBitInStore(true));
    props.setPatientJson((pj) => {
      return { ...pj, firstName: event.target.value };
    });
  };

  const middleNameHandler = (event) => {
    setUpdateDisabled(true);

    let middleName = event.target.value;
    setMiddleName(event.target.value);

    if (middleName?.length > PATIENT_VALIDATION.max) {
      setValidationErrors({ ...validationErrors, [field_Names.middleNameMax]: validationErrorMsgs.middleNameMax })
    }
    else {
      let temp = validationErrors;
      delete temp.middleNameMax;
      setValidationErrors(temp)
    }
    dispatch(setDirtyBitInStore(true));
    props.setPatientJson((pj) => {
      return { ...pj, middleName: middleName };
    });
  };

  const lastNameHandler = (event) => {
    setUpdateDisabled(true);
    let lastName = event.target.value;
    setLastName(event.target.value);

    if (lastName?.length > PATIENT_VALIDATION.max) {
      setValidationErrors({ ...validationErrors, [field_Names.lastNameMax]: validationErrorMsgs.lastNameMax })
    }
    else {
      let temp = validationErrors;
      delete temp.lastNameMax;
      setValidationErrors(temp)
    }
    dispatch(setDirtyBitInStore(true));
    props.setPatientJson((pj) => {
      return { ...pj, lastName: lastName };
    });
  };
  const pharmacyHandler = (event) => {
    setPharmacy(event.target.value);
    props.setPatientJson((pj) => {
      return { ...pj, preferredPharmacy: event.target.value };
    });
  };
  const zipCodeHandler = (event) => {
    // handleStandardAddress();
    setUpdateDisabled(true);
    setZipCode(event.target.value);
    props.setPatientJson((pj) => {
      return { ...pj, postalCode: event.target.value };
    });
    if (isValidZipCode(event.target.value)) {
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        zipcode: false,
      }));
      dispatch(setDirtyBitInStore(true));
      setZipCode(event.target.value);
      props.setPatientJson((pj) => {
        return { ...pj, postalCode: event.target.value };
      });
    } else {
      if (event.target.value === "") {
        setRegexErrors((previousSnapshot) => ({
          ...previousSnapshot,
          zipcode: false,
        }));
      } else {
        dispatch(setDirtyBitInStore(true));
        setRegexErrors((previousSnapshot) => ({
          ...previousSnapshot,
          zipcode: true,
        }));
      }
    }
  };
  const cityHandler = (event) => {
    setUpdateDisabled(true);
    let cityName = event.target.value;
    setCity(cityName);

    if (cityName?.length > PATIENT_VALIDATION.city.max) {
      setValidationErrors({ ...validationErrors, [field_Names.city]: validationErrorMsgs.city })
    }
    else {
      let temp = validationErrors;
      delete temp.city;
      setValidationErrors(temp);
    }
    dispatch(setDirtyBitInStore(true));
    props.setPatientJson((pj) => {
      return { ...pj, city: event.target.value };
    });
  };
  const addressLine1Handler = (event) => {
    setUpdateDisabled(true);
    let address1 = event.target.value;
    setAddressLine1(address1);
    if (address1?.length > PATIENT_VALIDATION.addressLine1.max) {
      setValidationErrors({ ...validationErrors, [field_Names.addressLine1]: validationErrorMsgs.addressLine1 })
    } else {
      let temp = validationErrors;
      delete temp.addressLine1;
      setValidationErrors(temp);
    }
    dispatch(setDirtyBitInStore(true));
    props.setPatientJson((pj) => {
      return { ...pj, addressLine1: address1 };
    });
  };
  const addressLine2Handler = (event) => {
    setUpdateDisabled(true);
    let address2 = event.target.value;
    setAddressLine2(address2);

    if (address2?.length > PATIENT_VALIDATION.addressLine2.max) {
      setValidationErrors({ ...validationErrors, [field_Names.addressLine2]: validationErrorMsgs.addressLine2 })
    }
    else {
      let temp = validationErrors;
      delete validationErrors.addressLine2;
      setValidationErrors(temp)
    }
    dispatch(setDirtyBitInStore(true));
    props.setPatientJson((pj) => {
      return { ...pj, addressLine2: event.target.value };
    });
  };

  const landlineHanlder = (phoneValue) => {
    setUpdateDisabled(true);
    // console.log("phoneValue : ", phoneValue, phoneValue?.length);
    if (phoneValue !== undefined && isValidPhoneNumber(phoneValue.toString()) && phoneValue?.length === 12) {
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        landline: false,
      }));
      dispatch(setDirtyBitInStore(true));
      setLandline(phoneValue);
      props.setPatientJson((pj) => {
        return { ...pj, homeTelephone: phoneValue };
      });
    } else if (phoneValue !== undefined && !isValidPhoneNumber(phoneValue?.toString()) && phoneValue?.length > 2) {
      dispatch(setDirtyBitInStore(true));
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        landline: true,
      }));
      setLandline(phoneValue);
      props.setPatientJson((pj) => {
        return { ...pj, homeTelephone: phoneValue };
      });
    } else if (phoneValue === undefined || phoneValue?.length === 2) {
      dispatch(setDirtyBitInStore(true));
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        landline: false,
      }));
      setLandline("");
      props.setPatientJson((pj) => {
        return { ...pj, homeTelephone: "" };
      });
    }
  };

  const cellPhoneHandler = (phoneValue) => {

    setUpdateDisabled(true);
    if (phoneValue !== undefined && isValidPhoneNumber(phoneValue.toString()) && phoneValue?.length === 12) {
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: false,
      }));
      dispatch(setDirtyBitInStore(true));
      setCellPhone(phoneValue);
      props.setPatientJson((pj) => {
        return { ...pj, cellPhone: phoneValue };
      });
    } else if (phoneValue !== undefined && !isValidPhoneNumber(phoneValue.toString()) && phoneValue?.length > 2) {
      dispatch(setDirtyBitInStore(true));
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: true,
      }));
      setCellPhone(phoneValue);
      props.setPatientJson((pj) => {
        return { ...pj, cellPhone: phoneValue };
      });
    } else if (phoneValue?.length === undefined || phoneValue?.length === 2) {
      dispatch(setDirtyBitInStore(true));
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: true,
      }));
      setCellPhone("");
      props.setPatientJson((pj) => {
        return { ...pj, cellPhone: "" };
      });
    }
  };

  const emailHandler = (event) => {
    setUpdateDisabled(true);
    setEmail(event.target.value);
    dispatch(setDirtyBitInStore(true));
    props.setPatientJson((pj) => {
      return { ...pj, email: event.target.value };
    });
  };

  const consentHandler = (event) => {
    setUpdateDisabled(true);
    setConsent(!consent);
    dispatch(setDirtyBitInStore(true));
    props.setPatientJson((pj) => {
      return { ...pj, emailConsent: event.target.checked };
    });
  };

  const dobHandler = (d) => {
    setUpdateDisabled(true);
    setDob(d);
    props.setPatientJson((pj) => {
      return { ...pj, dateOfBirth: d };
    });
    // onDirty();
  };

  function BottomNavigation(props) {
    return (
      <div className="position-fixed row bg-white w-100 bottom-0 mx-auto bottom__nav--shadow">

        <LinearProgress variant="determinate" style={{
          backgroundColor: "#D9E8EC",
          height: "1px"
        }} value={props.value} />


        <div className="col-12 p-3">
          <div className="row justify-content-end">
            <div className="col-4 me-5">
              <button className="btn  br-10 font-14 ms-2 fw-sb text--blue d9e8ec--button" style={{
                paddingLeft: "24px",
                paddingRight: "24px"
              }}
                onClick={() => {
                  navigateOnDirtyBit(
                    dirtyBitStatus,
                    navigate,
                    -1,
                    dispatch,
                    setDirtyBitInStore
                  );
                }
                }>
                Cancel
              </button>
              <button
                className={`btn btn-lg blue_task_button b2 px-4 br-10 py-1 ${props.isSending ? "bg--disabled" : ""}`}
                disabled={
                  !updateDisabled ||
                    props.isSending ||
                    validSSN === false ||
                    regexErrors?.zipcode === true ||
                    regexErrors?.emergency === true ||
                    regexErrors?.landline === true ||
                    regexErrors?.primary === true ||
                    isFamilyMemberValid === true
                    ? true
                    : Object?.keys(validationErrors)?.length !== 0 ? true : false
                }
                type="submit"
                form="patientEditForm"
                // onClick={() => { props.handleChange(); }}
              >
                {location.pathname.search("edit") !== -1 ? "Update" : ""}
              </button>
            </div>

          </div>
        </div>



      </div>
    );
  }

  const ssnHandler = (event) => {
    setUpdateDisabled(true);
    if (event.target.value === '___-__-____' || event.target.value === '' || event.target.value === null) {
      setValidSSN(true);
      props.setIsValidSSN(true);
      setSsn(null);
      props.setPatientJson((pj) => {
        return { ...pj, socialSecurity: null };
      });
    }
    else if (isValidSSN(event.target.value)) {
      setSsn(event.target.value);
      props.setIsValidSSN(true);
      props.setPatientJson((pj) => {
        return { ...pj, socialSecurity: event.target.value };
      });
      setValidSSN(true);
    }
    else {
      setValidSSN(false);
      props.setIsValidSSN(false);
      setSsn(event.target.value);
      props.setPatientJson((pj) => {
        return { ...pj, socialSecurity: event.target.value, validSSN: false };
      });
    }
  };

  const genderHandler = (event) => {
    setUpdateDisabled(true);
    setGender(event.target.value);
    dispatch(setDirtyBitInStore(true));
    props.setPatientJson((pj) => {
      return { ...pj, gender: event.target.value };
    });
  };

  const handleStandardAddress = async () => {
    // console.log(props.patientJson);
    if (
      props.patientJson?.addressLine1 !== "" &&
      props.patientJson?.city !== "" &&
      props.patientJson?.postalCode !== ""
    ) {
      let state = options.find((s) => s.code === props.patientJson?.state);
      if (standardizedAddress == undefined || standardizedAddress == "") {
        setIsStandardAddress(false);
        const stdAddress = await getStandardizedAddress(
          props.patientJson?.addressLine1,
          props.patientJson?.addressLine2,
          props.patientJson?.city,
          state,
          props.patientJson?.postalCode
        );
        await setStandardizedAddress(stdAddress);
      } else if (
        props.patientJson?.addressLine1 !==
        standardizedAddress?.addressLine1 ||
        props.patientJson?.city !== standardizedAddress?.city ||
        props.patientJson?.state[0]?.code !==
        standardizedAddress?.stateProvince ||
        props.patientJson?.postalCode !== standardizedAddress?.postalCode
      ) {
        setIsStandardAddress(false);
        const stdAddress = await getStandardizedAddress(
          props.patientJson?.addressLine1,
          props.patientJson?.addressLine2,
          props.patientJson?.city,
          state,
          props.patientJson?.postalCode
        );
        await setStandardizedAddress(stdAddress);
        setShowAddress(true);
      }
    }
  };

  const updatePatientAddress = (e) => {
    setShowAddress(false);
    e.preventDefault();
    dispatch(setDirtyBitInStore(true));
    setIsStandardAddress(true);
    setAddressLine1(standardizedAddress?.addressLine1);
    props.setPatientJson((pj) => {
      return { ...pj, addressLine1: standardizedAddress?.addressLine1 };
    });

    setAddressLine2(standardizedAddress?.addressLine2);
    props.setPatientJson((pj) => {
      return { ...pj, addressLine2: standardizedAddress?.addressLine2 };
    });

    setCity(standardizedAddress.city);
    props.setPatientJson((pj) => {
      return { ...pj, city: standardizedAddress?.city };
    });
    handlePreset([
      options.find(
        (s) =>
          s?.code?.toLowerCase() ===
          standardizedAddress?.stateProvince?.toLowerCase()
      ),
    ]);

    setZipCode(standardizedAddress?.postalCode);
    props.setPatientJson((pj) => {
      return { ...pj, postalCode: standardizedAddress?.postalCode };
    });
  };


  const insuranceHandler = (event) => {
    setInsured(event.target.checked);
    dispatch(setDirtyBitInStore(true));

    props.setPatientJson((pj) => { return { ...pj, insured: event.target.checked } });

  }

  const emergencyContactHandler = (phoneValue) => {
    // console.log("phoneValue: ",phoneValue);
    setUpdateDisabled(true);
    if (phoneValue !== undefined && isValidPhoneNumber(phoneValue?.toString()) && phoneValue?.length === 12) {
      // let emergencyContactNumber = formatPhoneNumber(phoneValue?.replace("+1", ""));
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        emergency: false,
      }));
      dispatch(setDirtyBitInStore(true));
      setEmergencyContactPhone(phoneValue);
      props.setPatientJson((pj) => {
        return { ...pj, emergencyContact: phoneValue };
      });
    } else if (phoneValue !== undefined && !isValidPhoneNumber(phoneValue.toString()) && phoneValue?.length > 2) {
      dispatch(setDirtyBitInStore(true));
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        emergency: true,
      }));
      setEmergencyContactPhone(phoneValue);
      props.setPatientJson((pj) => {
        return { ...pj, emergencyContact: phoneValue };
      });

    } else if (phoneValue === undefined || phoneValue?.length === 2) {
      // dispatch(setDirtyBitInStore(true));
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        emergency: false,
      }));
      setEmergencyContactPhone("")
      props.setPatientJson((pj) => {
        return { ...pj, emergencyContact: "" };
      });
    }
  };

  const emergencyContactNameHandler = (event) => {
    setUpdateDisabled(true);
    setEmergencyContactName(event.target.value);
    dispatch(setDirtyBitInStore(true));
    props.setPatientJson((pj) => {
      return { ...pj, emergencyContactName: event.target.value };
    });
  };

  const emergencyContactRelationshipHandler = (event) => {
    setUpdateDisabled(true);
    setEmergencyContactRelation(event.target.value);
    dispatch(setDirtyBitInStore(true));
    props.setPatientJson((pj) => {
      return { ...pj, emergencyContactRelationship: event.target.value };
    });
  };


  const handlePreset = (s) => {
    setUpdateDisabled(true);
    setState(s);
    dispatch(setDirtyBitInStore(true));
    props.setPatientJson((pj) => {
      return { ...pj, state: s[0]?.code };
    });
    // if (standardizedAddress !== undefined && standardizedAddress !== '' && s[0].code !== standardizedAddress.stateProvince) {
    //   console.log("Called by state", s, standardizedAddress?.postalCode)
    //   handleStandardAddress();
    // }
  };

  const suffixHandler = (event) => {
    dispatch(setDirtyBitInStore(true));
    setUpdateDisabled(true);
    props.setPatientJson((pj) => {
      return { ...pj, suffix: event.target.value };
    });
  };

  useEffect(() => {
    // firstNameRef.current.focus();
    // fetchAllPharmacies();
    setUpdateDisabled(false);
    props.setPatientForm(document.getElementById("patientEditForm"));
    return () => {
      clearState(); // flush the states
      dispatch(setIsPatientEdited(false)); // update the dependencies
    };
  }, []);

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-sm-7 col-md-7">
          <form
            autocomplete="off"
            id="patientEditForm"
            onSubmit={(e) => {
              e.preventDefault();
              props.handleSubmit()
            }}
          >
            <input type="hidden" autocomplete="false" />
            <div className="row mt-3  ">
              <div className="col-md-4">
                <label
                  htmlFor="FirstName"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  First Name <span>&#42;</span>
                </label>
                <input
                  type="text"
                  onChange={firstNameHandler}
                  ref={firstNameRef}
                  className={`form-control font-14 m-0 border--default br-10 shadow-none input`}
                  id="FirstName"
                  defaultValue={firstName}
                  required
                // onBlur={firstNameBlur}
                />
                <ValidationErrorMessage
                  fieldName={field_Names.firstNameMax}
                  errors={validationErrors}
                />
                {/* {serverErrors.length > 0 && serverErrors?.map((error) => { return error.attributeName === "FirstName" && <span className="text--danger font-10 ms-2 mt-0 lh-0"> {error.message} </span> })} */}
              </div>


              <div className="col-md-4">
                <label
                  htmlFor="MiddleName"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Middle Name
                </label>
                <input
                  type="text"
                  onChange={middleNameHandler}
                  className={`form-control font-14 m-0 border--default br-10 shadow-none input`}
                  id="MiddleName"
                  defaultValue={middleName}

                // onBlur={firstNameBlur}
                />
                <ValidationErrorMessage
                  fieldName={field_Names.middleNameMax}
                  errors={validationErrors}
                />
                {/* {serverErrors.length > 0 && serverErrors?.map((error) => { return error.attributeName === "FirstName" && <span className="text--danger font-10 ms-2 mt-0 lh-0"> {error.message} </span> })} */}
              </div>


              <div className="col-md-4">
                <label
                  htmlFor="LastName"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Last Name <span>&#42;</span>
                </label>
                <input
                  type="text"
                  onChange={lastNameHandler}
                  autoComplete={false}
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="LastName"
                  defaultValue={lastName}
                  required
                />
                <ValidationErrorMessage
                  fieldName={field_Names.lastNameMax}
                  errors={validationErrors}
                />
                {/* {serverErrors.length > 0 && serverErrors?.map((error) => { return error.attributeName === "LastName" && <span className="text--danger font-10 ms-2 mt-0 lh-0"> {error.message} </span> })} */}
              </div>

              <div className="col-md-3 mt-3">
                <label
                  htmlFor="patientSuffix"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Suffix
                </label>
                <input
                  type="text"
                  onChange={suffixHandler}
                  defaultValue={props.patientJson.suffix}
                  autoComplete="off"
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="Suffix"
                  name="Suffix"

                />
              </div>

              <div className="col-md-3 mt-3">
                <label
                  htmlFor="BiologicalSex"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Biological Sex <span>&#42;</span>
                </label>
                <select
                  onChange={genderHandler}
                  className="form-select font-14 border--default br-10 shadow-none input"
                  aria-label="biologicalSex"
                  id="biologicalSex"
                  name="biologicalSex"
                  required
                  style={{ borderRadius: "10px" }}
                >
                  <option
                    selected={
                      gender === "" ? true : false
                    }
                    value=""
                  >
                    Select
                  </option>
                  <option
                    selected={
                      gender === "Male" ? true : false
                    }
                    value="Male"
                  >
                    Male
                  </option>
                  <option
                    selected={
                      gender === "Female" ? true : false
                    }
                    value="Female"
                  >
                    Female
                  </option>
                  <option
                    selected={
                      gender === "Unknown" ? true : false
                    }
                    value="Unknown"
                  >
                    Unknown
                  </option>
                </select>
                {/* <ErrorMessage
              serverErrorsArray={props.serverErrors}
              fieldName={fieldNames.gender}
            /> */}
              </div>

              <div className="col-md-3 mt-3 ">
                <label
                  htmlFor="dob"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Date of Birth <span>&#42;</span>
                </label>
                <CalenderDatePicker
                  name="dob"
                  date={
                    dob !== ""
                      ? dob
                      : ""
                  }
                  required
                  dobHandler={dobHandler}
                  dateFormat="MMM d, yyyy"
                  maxDate={new Date()}
                />
                {/* <ErrorMessage
              serverErrorsArray={props.serverErrors}
              fieldName={"dateOfBirth"}
            /> */}
              </div>

              <div className="col-md-3 mt-3">
                <label
                  htmlFor="SSN"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  SSN
                </label>
                <MaskInput
                  mask={"999-99-9999"}
                  onChange={ssnHandler}
                  defaultValue={props.patientJson.socialSecurity}
                  autoComplete="false"
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="SSN"
                  name="SSN"
                  minLength="9"
                />
                {props.isValidSSN === false ? <span className="text--danger font-10 ms-2 mt-0">Invalid SSN</span> : null}
              </div>

              <div className="col-md-12 mt-2">
                <label
                  htmlFor="AddressLine1"
                  className="form-label fw-sb m-0 font-12 text--secondary"
                >
                  Address line 1<span>&#42;</span>
                </label>
                <input
                  type="text"
                  onChange={addressLine1Handler}
                  value={props.patientJson.addressLine1}
                  onBlur={() => {
                    handleStandardAddress();
                  }}
                  // onFocus={() => { handleStandardAddress() }}
                  autoComplete={false}
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="AddressLine1"
                  defaultValue={addressLine1}
                  required
                />
                <ValidationErrorMessage
                  fieldName={field_Names.addressLine1}
                  errors={validationErrors}
                />
              </div>
              <div className="col-md-12 mt-2">
                <label
                  htmlFor="AddressLine2"
                  className="form-label fw-sb m-0 font-12 text--secondary"
                >
                  Address line 2
                </label>
                <input
                  type="text"
                  onChange={addressLine2Handler}
                  value={props.patientJson.addressLine2}
                  onBlur={() => {
                    handleStandardAddress();
                  }}
                  // onFocus={() => { handleStandardAddress() }}
                  autoComplete={false}
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="AddressLine2"
                  defaultValue={addressLine2}

                />
                <ValidationErrorMessage
                  fieldName={field_Names.addressLine2}
                  errors={validationErrors}
                />
              </div>


              <div className="col-md-6 mt-2">
                <label
                  htmlFor="City"
                  className="form-label fw-sb m-0 font-12 text--secondary"
                >
                  City <span>&#42;</span>
                </label>
                <input
                  type="text"
                  onChange={cityHandler}
                  autoComplete={false}
                  value={props.patientJson.city}
                  onBlur={() => handleStandardAddress()}
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="City"
                  name="City"
                  required
                />
                <ValidationErrorMessage
                  fieldName={field_Names.city}
                  errors={validationErrors}
                />
                {/* {serverErrors.length > 0 && serverErrors?.map((error) => { return error.attributeName === "City" && <span className="text--danger font-10 ms-2 mt-0 lh-0"> {error.message} </span> })} */}
              </div>

              <div className="col-md-3 mt-2">
                <label
                  htmlFor="State"
                  className="form-label fw-sb m-0 font-12 text--secondary"
                >
                  State <span>&#42;</span>
                </label>
                <Typeahead
                  id="state"
                  name="state"
                  labelKey="name"
                  onChange={handlePreset}
                  onBlur={handleStandardAddress}
                  options={options}
                  className="rbt-menu my-select"
                  selected={
                    props.patientJson?.state !== "" &&
                      props.patientJson?.state !== undefined
                      ? [options.find((s) => s.code === props.patientJson?.state)]
                      : null
                  }
                  // defaultInputValue={standardizedAddress !== undefined && standardizedAddress !== '' ? options.find(s => s.code === standardizedAddress.stateProvince)?.name : props.patientJson?.state[0]?.name}
                  style={{ borderRadius: "10px" }}
                  autoComplete={false}
                  onInvalid={(e) => {
                    e.target.setCustomValidity("Please fill out this field");
                  }}
                  onInput={(e) => {
                    e.target.setCustomValidity("");
                  }}
                  inputProps={{ required: true, className: "font-14" }}
                  required
                />
              </div>

              <div className="col-md-3 mt-2">
                <label
                  htmlFor="ZipCode"
                  className="form-label fw-sb m-0 font-12 text--secondary"
                >
                  Zip Code <span>&#42;</span>
                </label>
                <input
                  type="text"
                  value={props.patientJson?.postalCode}
                  onChange={(e) => zipCodeHandler(e)}
                  onBlur={handleStandardAddress}
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="ZipCode"
                  defaultValue={zipCode || ""}
                  required
                />

                <span className="text--danger py-1 font-10 d-block">
                  {regexErrors?.zipcode === true
                    ? "Incorrect Zip Code format"
                    : " "}
                </span>
              </div>
              {showAddress && (
                <div className="col-md-12  mt-2">
                  {standardizedAddress !== "" &&
                    standardizedAddress !== undefined ? (
                    <span className="d-block text-decoration-underline br-10 text--danger font-14 fw-sb">
                      USPS Standard Address
                    </span>
                  ) : null}
                  {standardizedAddress !== "" &&
                    standardizedAddress !== undefined ? (
                    <div className=" row ">
                      <div className="col-sm-3">
                        <span className="d-block font-12 text--terciary ">
                          {standardizedAddress?.addressLine1}
                        </span>
                        <span className="d-block font-12 text--terciary ">
                          {standardizedAddress?.addressLine2}
                        </span>
                        <span className="d-block font-12 text--terciary ">
                          {standardizedAddress?.city},{" "}
                          {standardizedAddress?.stateProvince},{" "}
                          {standardizedAddress?.postalCode}
                        </span>
                      </div>
                      <div className="col-sm-4 align-self-center">
                        <button
                          className="btn btn-outline-primary btn-outline-blue shadow-none font-12"
                          onClick={(event) => updatePatientAddress(event)}
                        >
                          Use Standard Address
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
              )}

              <div className="col-md-12 mt-2">
                <input
                  key={"false"}
                  className="form-check-input cursor--pointer align-self-center shadow-none mb-1"
                  type="checkbox"
                  name="Insured"
                  id={"Insured"}
                  onChange={(e) => {
                    insuranceHandler(e);
                  }}
                  defaultChecked={props.patientJson.insured ?? false}
                />
                <label
                  className="form-check-label fw-sb text--secondary font-12 align-self-center ms-2 me-2"
                  htmlFor={"Insured"}
                >
                  Insured Patient
                </label>

                {/* {serverErrors !== null && serverErrors?.map((error) => { return error.attributeName === "AddressLine1" && <span className="text--danger font-10 ms-2 mt-0 lh-0"> {error.message} </span> })} */}
              </div>

              <div className="col-md-12 " style={{ marginBottom: "-10px" }}>
                <h3 className="mt-2 font-14 fw-sb">Contact Information</h3>
              </div>

              <div className="row">
                <div className="col-md-6 ">
                  <label
                    htmlFor="CellPhone"
                    className="form-label m-0 fw-sb font-12 text--secondary"
                  >
                    Mobile Phone <span>&#42;</span>
                  </label>
                  {/* <PhoneInput
                onChange={cellPhoneHandler}
                id="CellPhone"
                defaultValue={cellPhone}
                onFocus={() => console.log(cellPhone)}
                required
              ></PhoneInput> */}
                  <div>
                    <PhoneInput
                      defaultCountry="US"
                      className={`border--default br-10 font-14 form-control shadow-none outline-none input`}
                      value={cellPhone?.length > 0 ? cellPhone : ""}
                      name="CellPhone"
                      id="CellPhone"
                      onChange={(v) => cellPhoneHandler(v)}
                      required={true}
                      onInvalid={(e) => {
                        e.target.setCustomValidity("Please fill out this field");
                      }}
                      onInput={(e) => {
                        e.target.setCustomValidity("");
                      }}
                    />
                  </div>

                  <span className="text--danger py-1 font-10 d-block">
                    {regexErrors?.primary === true
                      ? "Incorrect number format"
                      : " "}
                  </span>
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="Email"
                    className="form-label m-0 fw-sb font-12 text--secondary"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    required
                    disabled={true}
                    onChange={emailHandler}
                    autoComplete={false}
                    defaultValue={email}
                    className="form-control font-14 bg--disabled border--default br-10 shadow-none input"
                    id="Email"
                  />
                </div>
                <div className="col-md-6 ">
                  <label
                    htmlFor="Landline"
                    className="form-label m-0 fw-sb font-12 text--secondary"
                  >
                    Home Phone
                  </label>

                  <div>

                    <PhoneInput
                      defaultCountry="US"
                      className={`border--default font-14 br-10 form-control shadow-none outline-none input`}
                      onChange={(e) => landlineHanlder(e)}
                      value={landline?.length > 0 ? landline : null || ""}
                      // onFocus={() => console.log(landline)}
                      id="Landline"
                      required={false}
                    />
                  </div>
                  <span className="text--danger py-1 font-10 d-block">
                    {regexErrors?.landline === true
                      ? "Incorrect number format"
                      : " "}
                  </span>
                </div>
              </div>
               {/* Family Relation */}
               <FamilyRelation relationsList={props?.relationsList ?? []} setPatientJson={props?.setPatientJson} patientJson={props?.patientJson} editMode = {true} setUpdateDisabled={setUpdateDisabled}
                setDisableButton={setIsFamilyMemberValid}/>

              <div className="col-md-12" >
                <h3 className="mt-3 mb-2 font-16 fw-sb">Emergency Contact Information</h3>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label
                    htmlFor="EmergencyContactName"
                    className="form-label m-0 fw-sb font-12 text--secondary"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    onChange={emergencyContactNameHandler}
                    defaultValue={emergencyContactName}
                    autoComplete="off"
                    className="form-control font-14 border--default br-10 shadow-none input"
                    id="EmergencyContactName"
                    name="EmergencyContactName"
                  />
                </div>

                <div className="col-md-6 ">
                  <label
                    htmlFor="EmergencyPhone"
                    className="form-label m-0 fw-sb font-12 text--secondary"
                  >
                    Phone
                  </label>
                  <div>
                    <PhoneInput
                      defaultCountry="US"
                      name="EmergencyPhone"
                      className={`border--default font-14 br-10 form-control shadow-none outline-none input`}
                      value={emergencyContactPhone?.length > 0 ? emergencyContactPhone : ""}
                      onChange={(e) => emergencyContactHandler(e)}
                      id="EmergencyPhone"
                      required={false}
                    />
                  </div>
                  {/* {emergencyContactPhone !== "" ? (
                <ErrorMessage
                  serverErrorsArray={props.serverErrors}
                  fieldName={"number"}
                />
              ) : (
                <span className="d-block"></span>
              )}
              <span className="text--danger py-1 font-10 d-block">
                {regexErrors?.emergency === true
                  ? "Incorrect number format"
                  : " "}
              </span> */}
                  <span className="text--danger py-1 font-10 d-block">
                    {regexErrors?.emergency === true
                      ? "Incorrect number format"
                      : null
                      // <ErrorMessage
                      //   serverErrorsArray={props.serverErrors}
                      //   fieldName={"number"}
                      // />
                    }
                  </span>
                </div>

                <div className="col-md-6">
                  <label
                    htmlFor="Relationship"
                    className="form-label m-0 fw-sb font-12 text--secondary"
                  >
                    Relationship
                  </label>
                  <select
                    onChange={emergencyContactRelationshipHandler}
                    className="form-select font-14 border--default br-10 shadow-none input"
                    aria-label="relation"
                    id="relation"
                    name="relation"
                    style={{ borderRadius: "10px" }}
                  >
                    <option
                      value=""
                    >
                      Select
                    </option>
                    {props.relationsList?.length > 0 ?
                      props.relationsList?.map((relation, index) => {
                        return (
                          <option
                            value={relation}
                            key={index}
                            selected={
                              emergencyContactRelation?.match(/([A-Z]?[^A-Z]*)/g).slice(0, -1).join(" ").toLowerCase() === relation?.match(/([A-Z]?[^A-Z]*)/g).slice(0, -1).join(" ")?.toLowerCase()
                            }
                          >
                            {relation.match(/([A-Z]?[^A-Z]*)/g).slice(0, -1).join(" ")}
                          </option>
                        )
                      }) : null}
                  </select>

                </div>
              </div>
              <PatientMethodOfCommunication key = {"EditPatient"} setPatientJson={props?.setPatientJson} patientJson={props?.patientJson} editMode = {true} setUpdateDisabled={setUpdateDisabled}/>

            </div>


          </form>
        </div>

                      
      </div>
      <BottomNavigation value={100} isSending={props.isSending} />
    </>
  );
};

export default EditPatientModal;

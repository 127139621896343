import React from "react";
import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import { Avatar, AvatarGroup } from "@mui/material";
import { strings } from "../../../res/strings";
import sendHttpRequest from "../../hooks/sendHttpRequest";
import { useContext } from "react";
import { VideoCallContext } from "../AgoraHooks/VideoCallContext";
import { useState } from "react";

function stringAvatar(name) {
  if (name !== null) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }
}

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

const WaitingRoomParticipant = ({ id, name, accept, deny, idRef }) => {
  const [isOperationInProgress, setIsOperationInProgress] = useState(false);
  const handleAcceptParticipant = async () => {
    setIsOperationInProgress(true);
    await accept(id);
    setIsOperationInProgress(false);
  };

  const handleRejectParticipant = async () => {
    setIsOperationInProgress(true);
    await deny(id);
    setIsOperationInProgress(false);
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <Avatar {...stringAvatar(name)} />
          <div className="ps-1 font-14 fw-sb text--terciary">{name}</div>
        </div>

        <div
          className="btn-group"
          role="group"
          aria-label="Button group with separator"
        >
          <button
            className="btn text--blue fw-sb font-14 border-end shadow-none waiting-room-controls"
            disabled={isOperationInProgress}
            onClick={() => {
              handleRejectParticipant();
            }}
          >
            {strings.en.deny}
          </button>
          <button
            className="btn text--blue fw-sb font-14 shadow-none"
            disabled={isOperationInProgress}
            onClick={() => {
              handleAcceptParticipant();
            }}
          >
            {strings.en.allow}
          </button>
        </div>
      </div>
      <div className="horizontal-rule mt-1 mb-2" />
    </>
  );
};

const AgoraWaitingRoomModal = ({
  show,
  handleModalClose,
  waitingList,
  removeFromWaitingList,
  setWaitingRoomList,
  startPolling,
}) => {


  const videoCallContext = useContext(VideoCallContext);

  const acceptParticipantRequest = async (participantId) => {
    const httpResponse = await sendHttpRequest({
      url: `/video/acceptrequest/${participantId}`,
      method: "GET",
    });
    if (!httpResponse.error) {
      if (waitingList?.length === 1) {
        const user = waitingList.filter(u => u.id === participantId);
        removeFromWaitingList(participantId);
        setWaitingRoomList([]);
        // if(appointment === null){
        //   setAppointment(appId)
        // }
        videoCallContext.addRemoteUser(user);
        handleModalClose();
        // clearInterval(idRef);
      }
    }
  };

  const rejectParticipantRequest = async (participantId) => {
    const httpResponse = await sendHttpRequest({
      url: `/video/rejectrequest/${participantId}`,
      method: "GET",
    });
    if (!httpResponse.error) {
      console.log("Rejec==>", httpResponse);
      if (waitingList?.length === 1) {
        removeFromWaitingList(participantId);
        // startPolling();
        handleModalClose();
      }
    }
  };

  return (
    <Modal
      show={show}
      size="md"
      centered
      backdrop="static"
      backdropClassName="modal-backdrop"
      contentClassName="modal-border modal-shadow"
    >
      <Modal.Header style={{ border: "none" }}>
        <div className="row ">
          <div className="col-12 ">
            <span
              style={{
                lineHeight: "12px",
                width: "18px",
                fontSize: "8px",
                color: "#336383",
                position: "absolute",
                top: 15,
                right: 15,
                cursor: "pointer",
              }}
              onClick={() => {
                handleModalClose();
              }}
            >
              {<CloseIcon />}
            </span>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row justify-content-center py-2">
            <div className="col-sm-6">
              <AvatarGroup max={3} sx={{ justifyContent: "center" }}>
                {waitingList?.map((user) => {

                  return <Avatar {...stringAvatar(user?.name)} />;
                })}
              </AvatarGroup>
            </div>
            <div className="col-sm-12 justify-content-center mt-3">
              <h2 className="font-16 fw-sb text--terciary text-center">
                {strings.en.waiting_room_header_text}
              </h2>
            </div>
          </div>
          <div className="row justify-content-center py-3">
            <div className="col-sm-10">
              {waitingList?.map((user) => {
                return (
                  <WaitingRoomParticipant
                    key={user?.requestUuid}
                    id={user?.requestUuid}
                    name={user?.name}
                    accept={acceptParticipantRequest}
                    deny={rejectParticipantRequest}
                  />
                );
              })}
            </div>
          </div>
          <div className="row justify-content-center py-4">
            <div className="col-sm-12 text-center">
              <div
                className="btn-group"
                role="group"
                aria-label="Button group with separator"
              >
                <button className="btn disabled text--blue fw-sb font-14 shadow-none border-end  waiting-room-controls">
                  {strings.en.deny_all}
                </button>
                <button className="btn disabled text--blue fw-sb font-14 shadow-none ">
                  {strings.en.allow_all}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AgoraWaitingRoomModal;

import * as React from "react";

import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function PatientCreateDropdown(props) {
  const navigate = useNavigate();
  const handleManualPatientAdd = () => {
    if(props?.claimBilling){
      navigate("/claim-add-new-patient",{state:{claimBilling:props?.claimBilling}});
    }else{
      navigate("/add-new-patient");
    }

    // props?.handleNewPatientModal();
  };
  const handleFacesheetPatientAdd = () => {
    props?.handleFacesheetModal();
  };
  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle
          //  id="dropdown-basic"
          id="demo-customized-button"
          style={{
            border: "#004FD4",
            color: "white",
            background: "#004FD4",
            fontSize: "14px",
            fontWeight: "600",
            borderRadius: "10px",
            height: "44px",
            width: "172px",
            fontFamily: "Montserrat",
            textTransform: "capitalize",
            borderBottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          title="Add Patient"
          className="font-14 shadow-none"
        >
          Add Patient
        </Dropdown.Toggle>

        <Dropdown.Menu
          style={{
            background: "#fff",
            textColor: "#004FD4",
            borderRadius: "10px",
            boxShadow: "0px 13px 26px #0000000F",
            borderColor: "#D9E8EC",
            minWidth: "172px",
          }}
          className="font-14 "
        >
          <Dropdown.Item
            style={{
              color: "#323232",
              fontWeight: "400",
              hover: {
                color: "#323232",
              },
            }}
            className="font-14 "
            onClick={handleFacesheetPatientAdd}
          >
            Add with Facesheet
          </Dropdown.Item>
          <Dropdown.Item
            style={{
              color: "#323232",
              fontWeight: "400",
              hover: {
                color: "#323232",
              },
            }}
            className="font-14 "
            onClick={handleManualPatientAdd}
          >
            Add Manually
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

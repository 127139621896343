export const ActionTypes = {
    SET_APPOINTMENTS: "SET_APPOINTMENTS",
    SET_REFILL_REQUSTES: "SET_PRESCRIPTION_REQUESTS",
    SET_MESSAGES: "SET_MESSAGES",
    SET_UNREAD_MESSAGE_COUNT: "SET_UNREAD_MESSAGE_COUNT",
    SET_DIAGNOSIS: "SET_DIAGNOSIS",
    SET_PRESCRIBED_MEDICATION: "SET_PRESCRIBED_MEDICATION",
    UPDATE_PRESCRIBED_MEDICATION: "UPDATE_PRESCRIBED_MEDICATION",
    SET_PRESCRIBER_INFO: "SET_PRESCRIBER_INFO",
    SET_DRAWER_OPEN: "SET_DRAWER_OPEN",
    SET_PATIENT_ENCOUNTER_DATA: "SET_PATIENT_ENCOUNTER_DATA",
    SET_UPDATE_ENCOUNTER_DATA: "SET_UPDATE_ENCOUNTER_DATA",
    SET_DISABLE_SEND_RX_BUTTON: "SET_DISABLE_SEND_RX_BUTTON",
    SET_PATIENT_INTAKE_FORM_READ_STATUS: "SET_PATIENT_INTAKE_FORM_READ_STATUS",
    SET_ALL_PHYSICAL_SYSTEMS_STATUS: "SET_ALL_PHYSICAL_SYSTEMS_STATUS",
    SET_VIDEO_CALL: "SET_VIDEO_CALL",
    SET_PATIENT_ID: "SET_PATIENT_ID",
    SET_IS_VIDEO_CALL_END: "SET_IS_VIDEO_CALL_END",
    SET_PROVIDER_LIST: "SET_PROVIDER_LIST",
    SET_MIC_STATUS: "SET_MIC_STATUS",
    SET_FORCE_SHUTDOWN: "SET_FORCE_SHUTDOWN",
    SET_IS_PATIENT_EDITED: "SET_IS_PATIENT_EDITED",
    SET_PRESCRIPTION_NOTE_TEXT: "SET_PRESCRIPTION_NOTE_TEXT",
    SET_DIRTY_BIT: "SET_DIRTY_BIT",
    SET_REQUIRED_DATA_CHECK: "SET_REQUIRED_DATA_CHECK",
    SET_VIDEO_OPEN: "SET_VIDEO_OPEN",
    SET_CONSULTATION_IN_PROGRESS: "SET_CONSULTATION",
    SET_JOIN_PAGE: "SET_JOIN_PAGE",
    SET_USER_PERMISSIONS: "SET_USER_PERMISSIONS",
    SET_USER_TIMEZONE: "SET_USER_TIMEZONE",
    SET_PATIENT_INSURANCE_DATA: "SET_PATIENT_INSURANCE_DATA",
    SET_TENANT_SUBSCRIPTIONS: "SET_TENANT_SUBSCRIPTIONS",
    SET_SUBSCRIPTION_PRIVILIGES: "SET_SUBSCRIPTION_PRIVILIGES",
}

import {
  Box,
  Grid,
  Typography,
  Skeleton,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import EncounterItem from "../patient/EncounterItem";
import tabelclasses from "../patient/PatientItem.module.css";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import SummaryModal from "../UI/Modals/ProviderModals/SummaryModal";
import AddendumModal from "../UI/Modals/ProviderModals/AddendumModal";
import AddendumShowModal from "../UI/Modals/ProviderModals/AddendumShowModal";
import sendHttpRequest from "../hooks/sendHttpRequest";
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";
import { useSelector } from "react-redux";
import { RolesMap, RolesTextConversion } from "../../utils/helpers/RolesMap";
import { useAuth } from "../hooks/useAuth";
import {
  addendumUrl,
  fetchEncountersUrl,
  providerWithNpiUrl,
} from "../../res/endpointurls";
import { summaryGeneration } from "../../utils/helpers/DataLoaders";
import { strings } from "../../res/strings";
import { RefreshDataComponent } from "../UI/General/RefreshDataComponent";
import { useContext } from "react";
import { VideoCallContext } from "../UI/AgoraHooks/VideoCallContext";
import { ChevronLeft } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { ENCOUNTERS } from "../../utils/helpers/subscriptionsConstants";
import { PracticeAdminHeader } from "../pages/InPatientDashboard";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { PnToolTip } from "../UI/Reusable/PnComponents";
import classes from "./EncountersTablePage.module.css";
import CalenderDatePicker from "../UI/General/CalenderDatePicker";


function EncounterPageHeader(props) {
  const { user } = useAuth();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // =========Facility===========================
  const [selectedFacility, setSelectedFacility] = useState(0);
  const [isFacilityLoading, setIsFacilityLoading] = useState(false);
  const [facilitiesData, setFacilitiesData] = useState([]);

  // =========Providers===========================
  const [prescribersList, setPrescribersList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [providerID, setProviderID] = useState();
  const [encounterStatus, setEncounterStatus] = useState();

  const fetchProviderFacilities = async () => {
    setIsFacilityLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/facilities/tenant/${user?.tenantId}`,
      method: "GET",
    });
    if (!httpResponse.error) {
      setFacilitiesData(httpResponse.data);
    }
    setIsFacilityLoading(false);
  };

  const fetchAllPrescribers = async () => {
    setIsLoading(true);
    setProviderID("");
    const httpResponse = await sendHttpRequest({
      url: providerWithNpiUrl(user?.tenantId),
      method: "GET",
    });
    if (!httpResponse.error) {
      setPrescribersList(httpResponse.data);
    }
    setIsLoading(false);
  };

  const handleStartDate = (dateVal) => {
    if (dateVal !== undefined) {
      setStartDate(dateVal);
      props?.setStartDate(dateVal);
    }
  };

  const handleEndDate = (dateVal) => {
    if (dateVal !== undefined) {
      setEndDate(dateVal);
      props?.setEndDate(dateVal);
    }
  };

  const handleProviderId = (e) => {
    setProviderID(e.target.value);
    props?.setProviderId(e.target.value);
  };

  const handleFacilityId = (e) => {
    setSelectedFacility(e.target.value);
    props?.setSelectedFacility(e.target.value);
  };

  const handleEncounterStatus = (status) => {
    setEncounterStatus(status);
    props?.setEncounterStatus(status);
  };

  const handleClearFilter = () => {
    setStartDate("");
    setEndDate("");
    setSelectedFacility(0);
    setProviderID("");
    setEncounterStatus("");

    // Reset the props if they are passed down
    props?.setStartDate("");
    props?.setEndDate("");
    props?.setProviderId("");
    props?.setSelectedFacility(0);
    props?.setEncounterStatus("");
    props?.fetchEncounters(1,"","","","","");
  };


  const handleFilter = () => {
     props?.fetchEncounters(1,startDate,endDate,providerID,selectedFacility,encounterStatus);  
  };

  useEffect(() => {
    setStartDate(props?.startDate);
    setEndDate(props?.endDate);
  }, [props?.startDate, props?.endDate]);

  useEffect(() => {
    if (facilitiesData?.length === 0) {
      fetchProviderFacilities();
    }
    if (prescribersList?.length === 0) {
      fetchAllPrescribers();
    }
  }, []);

  return (
    <>
      <div className={`row ${props?.marginY === false ? "" : "my-3"}`}>
        <div className="col-2">
          <label className="text--terciary font-12 fw-sb">Start Date:</label>
          <CalenderDatePicker
            f12={true}
            dobHandler={handleStartDate}
            dateFormat="MMM d, yyyy"
            date={startDate !== undefined ? startDate : ""}
            key={`startDate${startDate}`}
          />
        </div>

        <div className="col-2">
          <label className="text--terciary font-12 fw-sb">End Date:</label>
          <CalenderDatePicker
            f12={true}
            dobHandler={handleEndDate}
            dateFormat="MMM d, yyyy"
            date={endDate !== undefined ? endDate : ""}
            key={`EndDate${endDate}`}
          />
        </div>

        <div className="col-2">
          <label className="text--terciary font-12 fw-sb">Facility:</label>
          <select
            className={`form-select br-10 border--default font-12 shadow-none`}
            aria-label="facility select"
            onChange={(e) => handleFacilityId(e)}
            value={selectedFacility}  // Ensure the select box is controlled
          >
            <option value="0" key={"facilityAll"}>
              {"All Facilities"}
            </option>
            {facilitiesData.map((facilityItem) => {
              return (
                <option
                  value={facilityItem?.facilityId}
                  id={facilityItem?.facilityId}
                  key={facilityItem?.facilityId}
                >
                  {facilityItem?.businessName}
                </option>
              );
            })}
          </select>
        </div>

        <div className="col-2">
          <label className="text--terciary font-12 fw-sb">Provider:</label>
          <select
            className={`form-select br-10 border--default font-12 shadow-none`}
            aria-label="Provider select"
            onChange={(e) => handleProviderId(e)}
            value={providerID}  // Ensure the select box is controlled
          >
            <option value="">Select provider</option>
            {prescribersList?.length > 0
              ? prescribersList?.map((prescriberItem) => {
                  return (
                    <option
                      value={prescriberItem?.userId ?? ""}
                      key={prescriberItem?.userId ?? ""}
                    >
                      {prescriberItem?.name[0]?.prefix}{" "}
                      {prescriberItem?.name[0]?.firstName}{" "}
                      {prescriberItem?.name[0]?.lastName}{" "}
                      {RolesTextConversion-(prescriberItem?.role) !== undefined
                        ? "(" + RolesTextConversion(prescriberItem?.role) + ")"
                        : ""}
                    </option>
                  );
                })
              : "No prescribers available"}
          </select>
        </div>

        {props?.encounterTable === true ? (
          <div className="col-2">
            <label className="text--terciary font-12 fw-sb">Status:</label>
            <select
              className={`form-select br-10 border--default font-12 shadow-none`}
              aria-label="Status select"
              onChange={(e) => handleEncounterStatus(e.target.value)}
              value={encounterStatus}  // Ensure the select box is controlled
            >
              <option value="">Select status</option>
              {[{key:"CLOSED",value:"Signed"},{key:"STARTED",value:"Unsigned"}].map((item) => {
                return (
                  <option value={item?.key} key={item?.key}>
                    {item?.value}
                  </option>
                );
              })}
            </select>
          </div>
        ) : null}

        <div className="col-2 align-self-center mt-3">
          <label htmlFor="filter">&nbsp;</label>
          <button
            id="filter"
            className="btn blue_task_button br-10 shadow-none"
            onClick={()=>{handleFilter()}}
          >
            Filter
          </button>
        </div>

        <div className="col-12 align-self-center mt-3 text-end">
          <label htmlFor="clearfilter">&nbsp;</label>
          <span
            id="clearfilter"
            className="pn-link-button d-inline-block text-decoration-underline fw-sb font-12 text--secondary cursor--pointer"
            onClick={handleClearFilter}  // Call the clear filter function
          >
            Clear Filter
          </span>
        </div>
      </div>
    </>
  );
}


const EncountersTablePage = (props) => {
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } =
    useSelector((state) => state.subscriptionPrivilegeGroups);

  const {
    user: { userId },
    user,
    getSecondaryUser,
    selectUser,
  } = useAuth();

  const location = useLocation();

  let uId = null;
  try {
    uId = getSecondaryUser();
  } catch (e) {
    console.error("PN_CLIENT_ERR: Cannot get secondary user");
  }
  const videoCallContext = useContext(VideoCallContext);
  const isVideoCallActive = videoCallContext.isVideoCallActive.current;
  const navigate = useNavigate();

  const [dummyState, setDummyState] = useState(false);
  // console.log("Is Video Call Active", isVideoCallActive);
  const __str = strings.en;
  const [isEncounterLoading, setIsEncounterLoading] = useState(true);

  const [isLoadingEnc, setIsLoadingEnc] = useState(true);
  const [isLoadingAddend, setIsLoadingAddend] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [addendumTitle, setAddendumTitle] = useState("");
  const [addendumBody, setAddendumBody] = useState("");
  const [openAddenModal, setOpenAddenModal] = useState(false);
  const [openAddenShowModal, setOpenAddenShowModal] = useState(false);
  const [summary, setSummary] = useState({});
  const [addendums, setAddendums] = useState("");
  const [addEncounterId, setAddEncounterId] = useState("");

  const [prescriptionReference, setEncounters] = useState([]);
  const [isSent, setIsSent] = useState(false);
  const [statusCodeEncounters, setStatusCodeEncounters] = useState(null);
  const [open, setOpen] = useState(false);

  const [prescribersList, setPrescribersList] = useState([]);
  const [selectedProviderId, setSelectedProviderId] = useState();
  const [isSessionUpdated, setIsSessionUpdated] = useState(false);
  const [isPrescribersFetched, setisPrescribersFetched] = useState(false);
  const prescriberInfo = useSelector((state) => state.PrecriberData.prescriber);

  // ================Page Header Information============================
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedFacility, setSelectedFacility] = useState(0);
  const [providerId, setProviderId] = useState();
  const [encounterStatus,setEncounterStatus] = useState();

  // =================Pagination Information=============================
  const [pages, setPages] = useState([]);
  const [pageNumberLimit, setPageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [maxPageLimit, setMaxPageLimit] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(undefined);

  const fetchAllPrescribers = async () => {
    setisPrescribersFetched(true);
    const httpResponse = await sendHttpRequest({
      url: providerWithNpiUrl(user?.tenantId),
      method: "GET",
    });
    if (!httpResponse.error) {
      setPrescribersList(httpResponse.data);
    } else {
      setPrescribersList([]);
      console.log(httpResponse.error);
    }
    setisPrescribersFetched(false);
  };

  const handleBackdropClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const handleProviderId = (event) => {
    if (event.target.value !== getSecondaryUser()) {
      setSelectedProviderId(event.target.value);
      selectUser(event.target.value);
      fetchEncounters(event.target.value);
    }
  };

  const fetchEncounters = async (currentPage,fromDate,toDate,providerId,selectedFacility,encounterStatus) => {
    setIsEncounterLoading(true);
    const httpResponse = await sendHttpRequest({
      // url: fetchEncountersUrl(userId),
      url: `/tenants/${user?.tenantId}/encounters`,
      method: "GET",
      params: {
        page:currentPage-1,
        size:10,
        ...(fromDate?.length > 0 && {from:fromDate}),
        ...(toDate?.length > 0 && {to:toDate}),
        ...(providerId && {providerIds:providerId}),
        ...(selectedFacility && {facilityIds:selectedFacility}),
        ...(encounterStatus && {status:encounterStatus}),
      },
    });
    if (!httpResponse.error) {
      let noOfPages = httpResponse?.data?.totalPages;
      setTotalResults(noOfPages);
      setMaxPageLimit(noOfPages);
      let pagesList = [];
      for (let i = 1; i <= noOfPages; i++) {
        pagesList.push(i);
      }
      // console.log("pagesList : ",pagesList);
      setPages(pagesList);

      setCurrentItems(httpResponse?.data?.content);
    } else {
      console.log(
        `Error in ${fetchEncountersUrl(userId)}`,
        httpResponse?.error,
        " m = ",
        httpResponse?.errorMessage?.error
      );
    }
    setStatusCodeEncounters(httpResponse.status);
    setIsEncounterLoading(false);
  };

  const fetchAddendums = async (encId) => {
    setIsLoadingAddend(true);
    const httpResponse = await sendHttpRequest({
      url: addendumUrl(encId),
      method: "GET",
    });
    if (!httpResponse.error) {
      setAddendums(httpResponse.data);
      setIsLoadingAddend(false);
      handleBackdropClose();
      setOpenAddenShowModal(!openAddenShowModal);
    } else {
      console.log(
        `Error in ${addendumUrl(encId)}`,
        httpResponse?.error,
        " m = ",
        httpResponse?.errorMessage?.error
      );
    }
    handleBackdropClose();
    setIsLoadingAddend(false);
  };

  const fetchPatientSummary = async (encId) => {
    const data = await summaryGeneration(encId);
    await setSummary(data);
    handleBackdropClose();
    setOpenModal(!openModal);
    setIsLoadingEnc(false);
  };

  function handleModal(encId) {
    if (encId !== "") {
      fetchPatientSummary(encId);
    }
  }

  function handleSumModal() {
    setOpenModal(!openModal);
  }

  function handleAddenModal(encId) {
    // if (encId !== "") {
    // setAddEncounterId(encId);
    setOpenModal(false);
    setOpenAddenModal(true);
    setOpenAddenShowModal(false);
    // }
  }

  function handleAddAddendumCloseModal() {
    setOpenAddenModal(false);
  }

  function handleAddenShowModal(encId) {
    if (encId !== "") {
      handleBackdropClose();
      fetchAddendums(encId);
    }
  }

  function handleAddenModalClose(encId) {
    setOpenAddenShowModal(!openAddenShowModal);
  }

  const addAddendum = async () => {
    const httpResponse = await sendHttpRequest({
      url: addendumUrl(addEncounterId?.encounter?.encounter?.encounterId),
      method: "POST",
      data: {
        addendum: addendumBody,
        title: addendumTitle,
      },
    });
    if (!httpResponse.error) {
      emptyStates();
      setIsSent(true);
      setOpenAddenModal(!openAddenModal);
      if (addEncounterId?.isSummary) {
        handleModal(addEncounterId?.encounter?.prescriptionReferenceId);
      } else {
        handleAddenShowModal(addEncounterId?.encounter?.encounter?.encounterId);
      }
    } else {
      console.log(
        `Error in ${addendumUrl(addEncounterId?.encounter?.encounterId)}`,
        httpResponse?.error,
        " m = ",
        httpResponse?.errorMessage?.error
      );
    }
  };

  function emptyStates() {
    setAddendumBody("");
    setAddendumTitle("");
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSent(false);
  };

  // ============================Pagination Handlers============================
  // Pagination Handlers
  const RenderPages = () => {
    return (
      <ul className={`${classes["pageNumbers"]}`}>
        {pages?.length > 1 &&
          pages?.map((number) => {
            // if(number > maxPageNumberLimit){
            //   console.log(number,maxPageNumberLimit + 1,minPageNumberLimit,number < maxPageNumberLimit + 1 ,
            //     number > minPageNumberLimit);
            // }
            if (
              number < maxPageNumberLimit + 1 &&
              number > minPageNumberLimit
            ) {
              return (
                <li
                  key={number}
                  id={number}
                  className={`${classes["list__item"]} ${
                    currentPage === number ? classes["active__list__item"] : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleCurrentPage(number);
                  }}
                >
                  {/* {number >= 5 && pages.length > 5 && number !== pages.length ? `${number}   ...${pages.length}` : number} */}
                  {number}
                </li>
              );
            } else {
              return null;
            }
          })}
      </ul>
    );
  };

  const handleNextButton = (event) => {
    event.preventDefault();
    setCurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
    fetchEncounters(currentPage+1,startDate,endDate,providerId,selectedFacility,encounterStatus)
  };
  const handlePreButton = (event) => {
    event.preventDefault();
    // setPageNumber(pageNumber - 1);
    // console.log("currentPage: ",currentPage-1);
    if (currentPage - 1 >= 1) {
      setCurrentPage((p) => currentPage - 1);
      fetchEncounters(currentPage-1,startDate,endDate,providerId,selectedFacility,encounterStatus)
    }
    if ((currentPage - 1) % pageNumberLimit == 0) {
      if (minPageNumberLimit - pageNumberLimit < 0) {
        setMaxPageNumberLimit(5);
        setMinPageNumberLimit(0);
      } else {
        setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
        setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
      }
    }
    // searchPharmacy(inputValue, "Retail,MailOrder", currentPage - 1);
  };
  const handleFirstPage = (event) => {
    event.preventDefault();
    setMaxPageNumberLimit(5);
    setMinPageNumberLimit(0);

    handleCurrentPage(1);
  };
  const handleLastPage = (event) => {
    event.preventDefault();
    let lastPage = Math.ceil(totalResults);
    let maxPageNumberLimitValue = lastPage;
    if (maxPageNumberLimitValue % 10 > 0) {
      maxPageNumberLimitValue = maxPageNumberLimitValue - 5;
    }
    setCurrentPage(totalResults);
    setMaxPageNumberLimit(lastPage);
    setMinPageNumberLimit(lastPage - pageNumberLimit);

    handleCurrentPage(lastPage);
  };
  const handleCurrentPage = (pNumber) => {
    setCurrentPage(pNumber);
    fetchEncounters(pNumber,startDate,endDate,providerId,selectedFacility,encounterStatus)
  };

  if (isSessionUpdated === true) {
    // fetchEncounters(userId);
    fetchEncounters(currentPage,location?.state?.fromDate,location?.startDate?.toDate,userId,selectedFacility,encounterStatus);
    setIsSessionUpdated(false);
  }
  console.log("getSecondaryUser(): ", getSecondaryUser());
  useEffect(() => {
    // fetchAllPrescribers();

    let uId = null;
    try {
      uId = getSecondaryUser();
    } catch (e) {
      console.error("PN_CLIENT_ERR: Cannot get secondary user");
    }
    setStartDate(location?.state?.startDate);
    setEndDate(location?.state?.endDate);
    fetchEncounters(currentPage,location?.state?.startDate,location?.state?.endDate,providerId,selectedFacility,encounterStatus);
  }, []);
  
  useEffect(()=>{
    setCurrentPage(1);
    if(startDate?.length > 0 && endDate?.length > 0){
      // fetchEncounters(1,startDate,endDate,providerId,selectedFacility,encounterStatus,encounterStatus);
    }
  },[startDate,endDate,providerId,selectedFacility,encounterStatus])

  return (
    <>
      {!isLoadingEnc && (
        <SummaryModal
          summary={summary}
          handleAddenModal={handleAddenModal}
          show={openModal}
          handleModal={handleSumModal}
        />
      )}
      {openAddenModal ? (
        <AddendumModal
          addAddendum={addAddendum}
          setAddendumBody={setAddendumBody}
          setAddendumTitle={setAddendumTitle}
          show={openAddenModal}
          handleAddAddendumCloseModal={handleAddAddendumCloseModal}
          handleAddenModal={handleAddenModal}
        />
      ) : null}
      {!isLoadingAddend && (
        <AddendumShowModal
          addendums={addendums}
          show={openAddenShowModal}
          handleAddenModal={handleAddenModal}
          handleAddenModalClose={handleAddenModalClose}
        />
      )}
      <div className="container-fluid">
        <div className="row p-1 py-3 bg-white border-bottom justify-content-between">
          <div className="col-sm-3 align-self-center">
            <div className="d-flex flex-row">
              <div
                style={{ width: "32px", height: "32px" }}
                className={`bg--background  br-10 me-3 border--default border align-self-center text-center my-auto `}
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ChevronLeft
                  style={{ color: "#EC609B", height: "20px", weight: "20px" }}
                  className="cursor-pointer my-auto mt-1"
                />
              </div>
              <div className="font-22 fw-sb text--terciary align-self-center">
                Encounters
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {/* <Button onClick={handleToggle}>Show backdrop</Button> */}
        <Backdrop
          sx={{
            color: "#EC609B",
            zIndex: (theme) => theme.zIndex.drawer + 1000,
          }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <div className="row justify-content-center mb-5">
        <div className="col-11 mb-1">
          <EncounterPageHeader
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setSelectedFacility={setSelectedFacility}
            setProviderId={setProviderId}
            marginY={false}
            encounterTable = {true}
            setEncounterStatus={setEncounterStatus}
            fetchEncounters={fetchEncounters}
          />
        </div>
        {/* <div className="col-12">
          <div className="d-flex justify-content-between">
            <div></div>
            <div className="pe-2">
              <RefreshDataComponent
                isComponentDataLoading={isEncounterLoading}
                variant="without-bar"
                callback={() => fetchEncounters(uId ?? userId)}
              />
            </div>
          </div>
        </div> */}
        <div className="col-11">
          <Grid
            container
            sx={{ marginBottom: "-8px" }}
            p={1}
            className={` bg-white ${tabelclasses["row__head"]} ${
              currentItems?.length === 0
                ? tabelclasses["border--bottom"]
                : ""
            }`}
          >
            <Grid item xs={1.1}>
              {/* <Stack direction="row" alignItems="center" spacing={2}> */}
              <Typography component="div">
                <Box
                  sx={{
                    textAlign: "left",
                    m: 1,
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "12px",
                    color: "#336383",
                  }}
                >
                  Date & Time
                </Box>
              </Typography>
              {/* </Stack> */}
            </Grid>
            <Grid item xs={1.5}>
              <Typography component="div">
                <Box
                  sx={{
                    textAlign: "left",
                    mt: 1,
                    ml: 1,
                    // mr: 4,
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "12px",
                    color: "#336383",
                  }}
                >
                  Name
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography component="div">
                <Box
                  sx={{
                    textAlign: "left",
                    mt: 1,
                    // ml: 2,
                    fontFamily: "Montserrat",
                    fontSize: "12px",
                    color: "#336383",
                  }}
                >
                  Date of Birth
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography component="div">
                <Box
                  sx={{
                    textAlign: "left",
                    marginTop: "8px",
                    // ml: 4,
                    fontFamily: "Montserrat",
                    fontSize: "12px",
                    color: "#336383",
                  }}
                >
                  Gender
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography component="div">
                <Box
                  sx={{
                    textAlign: "left",
                    mt: 1,
                    // ml: 4,
                    fontFamily: "Montserrat",
                    fontSize: "12px",
                    color: "#336383",
                  }}
                >
                  Chief Complaint
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={1.5}>
              <Typography component="div">
                <Box
                  sx={{
                    textAlign: "left",
                    mt: 1,
                    // ml: 4,
                    fontFamily: "Montserrat",
                    fontSize: "12px",
                    color: "#336383",
                  }}
                >
                  Type
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={1.5}>
              <Typography component="div">
                <Box
                  sx={{
                    textAlign: "left",
                    mt: 1,
                    // ml: 4,
                    fontFamily: "Montserrat",
                    fontSize: "12px",
                    color: "#336383",
                  }}
                >
                  Status
                </Box>
              </Typography>
            </Grid>
          </Grid>

          {isEncounterLoading ? (
            <Skeleton animation="wave" />
          ) :currentItems?.length > 0 ? (
              currentItems?.map((prescriptionReferenceItem, index) => {
                let encounterStatus =
                  prescriptionReferenceItem?.prescriptionReference?.encounter?.status?.toLowerCase();
                let providerNote = prescriptionReferenceItem?.encounterNoteType;
                let showEncounterButtons = true;
                let encounterCreatedDate =
                  prescriptionReferenceItem?.prescriptionReference?.encounter?.encounterCreatedDate;
                // if((providerNote !== undefined && providerNote !== null) )
                // {
                //   showEncounterButtons = false;
                // }
                if (
                  encounterStatus === "closed" ||
                  encounterStatus === "started" ||
                  encounterStatus === "dispatched" ||
                  (providerNote !== undefined && providerNote !== null)
                ) {
                  if (index === currentItems?.length - 1) {
                    return (
                      <EncounterItem
                        key={index}
                        createdDateTime={encounterCreatedDate}
                        showEncounterActionButtons={showEncounterButtons}
                        handleBackdropClose={handleBackdropClose}
                        handleToggle={handleToggle}
                        prescriptionReference={prescriptionReferenceItem?.prescriptionReference}
                        isEven={index % 2 === 0 ? true : false}
                        isLast={true}
                        handleAddenShowModal={handleAddenShowModal}
                        handleAddenModal={handleAddenModal}
                        setAddEncounterId={setAddEncounterId}
                        handleModal={handleModal}
                        disabled={
                          isVideoCallActive &&
                          videoCallContext.appointmentData !== null
                        }
                        dummyState={dummyState}
                        hidden={
                          encounterStatus === "started"
                            ? subscriptionPrivilegeGroups[ENCOUNTERS]?.vpe
                                ?.active === false
                              ? true
                              : false
                            : false
                        }
                      />
                    );
                  } else {
                    return (
                      <EncounterItem
                        createdDateTime={encounterCreatedDate}
                        showEncounterActionButtons={showEncounterButtons}
                        handleBackdropClose={handleBackdropClose}
                        handleToggle={handleToggle}
                        key={index}
                        dummyState={dummyState}
                        prescriptionReference={prescriptionReferenceItem?.prescriptionReference}
                        isEven={index % 2 === 0 ? true : false}
                        handleAddenShowModal={handleAddenShowModal}
                        handleAddenModal={handleAddenModal}
                        handleModal={handleModal}
                        setAddEncounterId={setAddEncounterId}
                        disabled={
                          isVideoCallActive &&
                          videoCallContext.appointmentData !== null
                        }
                        hidden={
                          encounterStatus === "started"
                            ? subscriptionPrivilegeGroups[ENCOUNTERS]?.vpe
                                ?.active === false
                              ? true
                              : false
                            : false
                        }
                      />
                    );
                  }
                }
              })
            ) : (
              <div className="font-14 text--terciary p-2">
                {__str.noDataAvailable}
              </div>
            )
          }
        </div>
        <div
          className={` mt-4 align-self-center col-sm-6 col-6 justify-content-center`}
        >
          {isEncounterLoading ? (
            <Skeleton />
          ) : (
            <div className={" d-flex flex-row justify-content-between"}>
              {pages.length > 1 ? (
                <div className="ps-2 mt-2">
                  <PnToolTip title="First" placement="top">
                    <button
                      disabled={currentPage === 1}
                      onClick={(e) => {
                        handleFirstPage(e);
                      }}
                      className={`${classes["prev__btn"]} me-2 bg--fafcfc border--default br-10`}
                    >
                      {/* <img src={IconNext} alt="icon-previous"></img> */}
                      <FirstPageIcon style={{ color: "#ec609b" }} />
                    </button>
                  </PnToolTip>
                  <PnToolTip title="Previous" placement="top">
                    <button
                      disabled={currentPage === 1}
                      onClick={(e) => {
                        handlePreButton(e);
                      }}
                      className={`${classes["prev__btn"]} bg--fafcfc border--default br-10`}
                    >
                      <NavigateBeforeIcon style={{ color: "#ec609b" }} />
                      {/* <img src={IconNext} alt="icon-previous"></img> */}
                    </button>
                  </PnToolTip>
                </div>
              ) : (
                ""
              )}

              <RenderPages />

              {pages.length > 1 ? (
                <div className="mt-2">
                  <PnToolTip title="Next" placement="top">
                    <button
                      disabled={currentPage === pages.length}
                      onClick={(e) => {
                        handleNextButton(e);
                      }}
                      className={`${classes["next__btn"]} me-2 bg--fafcfc border--default br-10`}
                    >
                      {/* <img src={IconNext} alt="icon-next"></img> */}
                      <NavigateNextIcon style={{ color: "#ec609b" }} />
                    </button>
                  </PnToolTip>
                  <PnToolTip title="Last" placement="top">
                    <button
                      disabled={currentPage === pages.length}
                      onClick={(e) => {
                        handleLastPage(e);
                      }}
                      className={`${classes["next__btn"]} bg--fafcfc border--default br-10`}
                    >
                      {/* <img src={IconNext} alt="icon-next"></img> */}
                      <LastPageIcon style={{ color: "#ec609b" }} />
                    </button>
                  </PnToolTip>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </div>
      <CustomizedSnackbars
        message={"Addendum saved!"}
        severity="success"
        open={isSent}
        handleClose={handleClose}
      />
      {console.log("currentItems: ",currentItems)}
      
    </>
  );
};

export default EncountersTablePage;

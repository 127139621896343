import { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import CalenderDatePicker from "../UI/General/CalenderDatePicker";
import { useNavigate } from "react-router-dom";

const ClaimPieChart = ({ claimsData }) => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(""); // Start date filter
  const [endDate, setEndDate] = useState(""); // End date filter
  const chartRef = useRef(null); // Reference to the canvas
  const chartInstance = useRef(null); // To store the Chart.js instance

  const processClaimsData = (data) => {
    const statusCounts = {
      CLAIM_APPROVED: 0,
      CLAIM_SUBMITTED: 0,
      CLAIM_DENIED: 0,
      CLAIM_SUBMISSION_ERROR: 0,
      Not_Submitted: 0,
      CLAIM_READY_FOR_SUBMISSION: 0,
    };

    data.forEach((claim) => {
      const { status } = claim;

      if (statusCounts[status] !== undefined) {
        statusCounts[status] += 1;
      } else {
        // statusCounts["Not_Submitted"] += 1; // Count for claims not submitted
      }
    });

    return statusCounts;
  };

  const handleStartDate = (dateVal) => {
    setStartDate(dateVal);
  };

  const handleEndDate = (dateVal) => {
    setEndDate(dateVal);
  };

  function navigateToClaimsPage(routeVal, status) {
    navigate(routeVal, {
      state: { claimType: status, startDate: startDate, endDate: endDate },
    });
  }

  useEffect(() => {
    const claimsStats = processClaimsData(claimsData);

    const data = {
      labels: [
        "Ready for Submission",
        "Approved",
        "Pending",
        "Denied",
        "Submission Error",
      ],
      datasets: [
        {
          label: "Claim Status",
          data: [
            claimsStats?.CLAIM_READY_FOR_SUBMISSION ?? 0,
            claimsStats?.CLAIM_APPROVED ?? 0,
            claimsStats?.CLAIM_SUBMITTED ?? 0,
            claimsStats?.CLAIM_DENIED ?? 0,
            claimsStats?.CLAIM_SUBMISSION_ERROR ?? 0,
          ],
          backgroundColor: [
            "#336383", // Ready for Submission - Grey
            "#18c383", // Approved - Green
            "#f8a02e", // Pending - Orange
            "#fd0d1b", // Rejected - Red
            "#FFEB3B", // Submission Error - Yellow
          ],
          hoverOffset: 4,
        },
      ],
    };

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: "bottom",
        },
        title: {
          display: true,
          text: "Claim Pie Chart",
        },
      },
    };

    // Destroy the existing chart instance if it exists to avoid memory leaks
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    // Create the new chart instance
    chartInstance.current = new Chart(chartRef.current, {
      type: "pie",
      data: data,
      options: options,
    });

    // Cleanup the chart when the component unmounts
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [claimsData, startDate, endDate]); // Recreate chart when claimsData, startDate, or endDate changes

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => navigateToClaimsPage("/claims-all", "all")}
      >
        <canvas ref={chartRef} />
      </div>
    </>
  );
};

export default ClaimPieChart;

import { useEffect, useState } from "react";
import sendHttpRequest from "../../../components/hooks/sendHttpRequest";
import { useAuth } from "../../../components/hooks/useAuth";
import { Skeleton } from "@mui/material";
import { calenderDateTimeHelper } from "../../../utils/dateTimeHelper";
import { currencyFormatter } from "../../../utils/helpers/HelperFunctions";

const PatientPaidInvoices = () => {
  const { user } = useAuth();
  const [paidInvoices, setPaidInvoices] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const fetchPaidPayments = async () => {
    setIsDataLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/patients/${user?.userId}/payment?page=0&size=3&sort=transactionDate,desc`,
      method: "GET",
    });
    if (!httpResponse.error) {
      setPaidInvoices(httpResponse.data.content);
    }
    setIsDataLoading(false);
  };

  useEffect(() => {
    fetchPaidPayments();
  }, []);

  return (
    <>
      {isDataLoading ? (
        <Skeleton />
      ) : paidInvoices?.length > 0 ? (
        paidInvoices.map((invoiceItem) => {
          return (
            <div className="row ps-1 mt-1 border--bottom pb-1" key={invoiceItem?.transactionHistoryId}>
              <div className="col-3 font-12 fw-sb g-0">
                {calenderDateTimeHelper(
                  invoiceItem?.transactionDate,
                  "MMM DD, YYYY"
                )}
              </div>
              <div className="col-9 font-12">
                <span className="d-block font-14 text--secondary fw-sb">{invoiceItem?.transactionType}</span>
                <span className="d-block font-10 text--terciary">{currencyFormatter("USD", invoiceItem?.transactionAmount)}</span>
              </div>
            </div>
          );
        })
      ) : (
        <span className="font-12 text--terciary">{"No data available"}</span>
      )}
    </>
  );
};

export default PatientPaidInvoices;

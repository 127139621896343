import { Grid } from '@mui/material';
import Footer from '../../Footer/Footer';
import Layout from "../../layout/Layout";

import "../../form.css"
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import PatientRegistrationHeader from '../../../../patient-registration/header/PatientRegistrationHeader';
import MyPatientAccountPage from '../../../../../patientportal/patientpages/MyPatientAccountPage';
import PatientAccountPageRegistrationFlow from '../../../../../patientportal/patientpages/PatientAccountPageRegistrationFlow';


function PatientAccountComponent(props) {
    const location = useLocation();

    return (
        <div className="App">
            <Layout>
                <PatientRegistrationHeader />
                <Grid container component="main" sx={{ minHeight: "100vh", backgroundColor: "#d9e8ec" }}>
                    <PatientAccountPageRegistrationFlow patientRegistrationHeader={true} />
                </Grid>
                {/* <Footer ishidden={true} hideSignin={true} isLast={false} isFirst={true} /> */}
            </Layout>
        </div>
    );
}

export default PatientAccountComponent;

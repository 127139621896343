import { Modal } from "react-bootstrap";
import AddButtonIcon from "../../../../assets/icons/Icon-Add.svg";
import TextArea from "../../../forms/TextArea";
import CloseIcon from "@mui/icons-material/Close";
import classes from "./AllergyModal.module.css";
import { useState } from "react";

const AllergyEditModal = (props) => {
  console.log(props);
  const [severityActive, setSeverityActive] = useState(props?.editAllergy?.severityCodedText);
  const handleSubstanceInput = (e) => {
    e.preventDefault()
    props.setSubstance(e.target.value);
  };
  const handleSeverityLow = (e) => {
    e.preventDefault();
    props.setSeverity("Mild");
    setSeverityActive("Mild");
  };
  const handleSeverityModerate = (e) => {
    e.preventDefault();
    props.setSeverity("Moderate");
    setSeverityActive("Moderate");
  };
  const handleSeveritySevere = (e) => {
    e.preventDefault();
    props.setSeverity("Severe");
    setSeverityActive("Severe");
  };
  const handleCategory = (e) => {
    e.preventDefault();
    // console.log("Modal Category",e.target.value);
    props.setCategory(e.target.value);
  }

  const setAllergyReactions = (event) => {
    // event.preventDefault()
    console.log(event)
    props.setReactions(event);
  };

  return (

    <Modal
      show={props.show}
      onHide={() => {
        props.handleModal();
      }}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      backdropClassName="modal-backdrop"
      contentClassName="modal-border modal-shadow"
      backdrop="static"

    // style={{style}}
    >
      {/* <Modal.Header closeButton style={{ border: "none" }}></Modal.Header> */}
      <Modal.Body>
        <div className="container-fluid px-3 ">
          <div className="row ">
            <div className="col-12 ">
              <span
                style={{
                  lineHeight: "12px",
                  width: "18px",
                  fontSize: "8pt",
                  color: "#336383",
                  position: "absolute",
                  top: 15,
                  right: 15,
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.handleModal();
                  setSeverityActive("");
                }}
              >
                {<CloseIcon />}
              </span>
            </div>
          </div>
          <div className="row ">
            <div className="col-12">
              <div className="d-flex ">
                {/* <div>
                  <img src={AddButtonIcon} alt=""></img>
                </div> */}
                <div className="mt-2">
                  <p className="h18 fw-sb " style={{ fontWeight: "600" }}>
                    Edit Allergy
                  </p>
                </div>
              </div>
            </div>
          </div>
          <form
            id="allergyEditForm"
            onSubmit={(e) => {
              e?.preventDefault();
              props?.editAllergyRequestHandler();
              setSeverityActive("");
              props?.handleModal();
            }}
          >
            <div className="row mt-4">
              <div className="col-sm-6">
                <label className="b2 text--secondary ">Substance</label>
                <input
                  type="text"
                  pattern=".*\S+.*"
                  required
                  className="form-control br-10 font-14 shadow-none"
                  onChange={(e) => handleSubstanceInput(e)}
                  defaultValue={props.editAllergy.drugProductCodedText}
                  style={{
                    border: "1px solid #D9E8EC",
                  }}
                >
                  {/* {console.log(props.editAllergy.DrugProductCoded.Text)} */}
                </input>
              </div>
              <div className="col-sm-6 ">
                <label className="b2 text--secondary">Category</label>
                <select
                  className={`form-select br-10 font-14 shadow-none ${classes["custom-select"]}`}
                  aria-label="Default select example"
                  defaultValue={props?.editAllergy?.allergyType ? props?.editAllergy?.allergyType : "Select"}
                  style={{
                    border: "1px solid #D9E8EC",
                  }}
                  onChange={(e) => handleCategory(e)}
                >
                  {/* <option selected>select</option> */}
                  <option value="Drug" className="font-14" selected={props?.editAllergy?.allergyType?.toLowerCase() === 'drug'}>Drug</option>
                  <option value="Food" className="font-14" selected={props?.editAllergy?.allergyType?.toLowerCase() === 'food'}>Food</option>
                  <option value="Common" className="font-14" selected={props?.editAllergy?.allergyType?.toLowerCase() === 'common'}>Common</option>
                </select>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-sm-8">
                <label className="b2 text--secondary">Severity</label>
                <div className="d-flex">
                  <button
                    style={{ color: "var(--secondary)" }}
                    className={
                      severityActive === "Mild"
                        ? "btn var(--primary) btn--border font-14 shadow-none border--primary"
                        : "btn var(--primary) btn--border font-14 shadow-none"
                    }
                    id="Low"
                    onClick={(e) => handleSeverityLow(e)}
                  // style={{ width: "40px", height: "40px" }}
                  >
                    Mild
                  </button>
                  <button
                    style={{ color: "var(--secondary)" }}
                    className={
                      severityActive === "Moderate"
                        ? "btn var(--primary) btn--border font-14 shadow-none border--primary ms-2"
                        : "btn var(--primary) btn--border font-14 shadow-none ms-2"
                    }
                    id="Moderate"
                    onClick={(e) => handleSeverityModerate(e)}
                  // style={{ width: "40px", height: "40px" }}
                  >
                    Moderate
                  </button>
                  <button
                    style={{ color: "var(--secondary)" }}
                    className={
                      severityActive === "Severe"
                        ? "btn var(--primary) btn--border font-14 shadow-none border--primary ms-2"
                        : "btn var(--primary) btn--border font-14 shadow-none ms-2"
                    }
                    // style={{ width: "40px", height: "40px" }}
                    id="Severe"
                    onClick={(e) => handleSeveritySevere(e)}
                  >
                    Severe
                  </button>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-sm-12 ">
                <label className="b2 text--secondary">Reactions</label>
                <TextArea
                  pattern=".*\S+.*"
                  className="form-control br-10 font-14 shadow-none "
                  style={{ border: "1px solid #D9E8EC" }}
                  gettext={setAllergyReactions}
                  defaultValue={props.editAllergy.reactionCodedText}
                />
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>

      <Modal.Footer style={{ border: "none" }}>
        <button
          className="btn bg--blue text-white b2 px-4 br-10 py-1 shadow-none"
          form="allergyEditForm"
          type="submit"
        // onClick={() => {
        //   props.editAllergyRequestHandler(props.encounter_id);
        //   setSeverityActive("");
        //   props.handleModal();
        // }}
        >
          Update
        </button>
      </Modal.Footer>
      {console.log("severityActive", severityActive)}
    </Modal>
  );
};
export default AllergyEditModal;

import SearchPatient from "../UI/Header/SearchPatient";
import SearchMessage from "./SearchMessage";

const InboxMessageBar = (props) => {
    const handleFilterValue = (e) => {
        props.setFilterValue(e.target.value);
    }
    return (
        <div className="d-flex flex-row justify-content-between px-3 bg--background " style={{ height: "10vh", borderBottom: "1px solid #d9e8ec" }}>

            <div className="align-self-center"><span className="font-20 text--terciary fw-sb">{props?.barText}</span> <span className="font-20 text--terciary">{"("}{props?.messageCount}{")"}</span></div>
            <div className="d-flex flex-row align-self-center">
                <div >
                    <SearchMessage
                        setThreadId={props?.setThreadId}
                        setMessagesData = {props?.setMessagesData}
                        type = {props?.type}
                        setIsStartPooling = {props?.setIsStartPooling}
                        setIsMsgLoading = {props?.setIsMsgLoading}
                    />
                </div>
                <div className="align-self-center ms-2">
                    <select className="form-select font-14 abc bg--disabled border--default br-10 shadow-none input text--secondary"
                        value={props.filterValue}
                        onChange={handleFilterValue}
                    >
                        <option value="all" selected>Show All</option>
                        <option value="unread">Unread</option>
                        <option value="flagged">Flagged</option>
                        <option value="attachment">Attachment</option>
                        <option value="archived">Archived</option>
                    </select>
                </div>
            </div>


        </div>
    )
}


export default InboxMessageBar;
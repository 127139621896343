const TrashIcon = (props) => {
  return (
    <svg
      id={props.id}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="22"
      viewBox="0 0 20 22"
      data-day={props.day}
      {...props}
      fill={props?.style?.color}
    >
      {/* <path
          id="Icon-Appointments-2"
          data-name="Icon-Appointments"
          d="M5.834,28A5.84,5.84,0,0,1,0,22.167v-14A5.841,5.841,0,0,1,5.834,2.333H7V1.167a1.167,1.167,0,0,1,2.333,0V2.333h9.334V1.167a1.167,1.167,0,1,1,2.333,0V2.333h1.167A5.841,5.841,0,0,1,28,8.167v14A5.84,5.84,0,0,1,22.167,28Zm-3.5-5.833a3.5,3.5,0,0,0,3.5,3.5H22.167a3.5,3.5,0,0,0,3.5-3.5v-10.5H2.333Zm0-14V9.334H25.668V8.167a3.5,3.5,0,0,0-3.5-3.5H5.834A3.5,3.5,0,0,0,2.333,8.167ZM18.084,17.5a1.75,1.75,0,1,1,1.75,1.75A1.75,1.75,0,0,1,18.084,17.5Zm-5.833,0A1.75,1.75,0,1,1,14,19.25,1.749,1.749,0,0,1,12.251,17.5Zm-5.834,0a1.75,1.75,0,1,1,1.75,1.75A1.75,1.75,0,0,1,6.417,17.5Z"
  
          stroke=""
        /> */}
      <g id="Trash" transform="translate(1 1)">
        <path
          id={props.id}
          data-day={props.day}
          data-name="Caminho 70"
          d="M4.5,9h18"
          transform="translate(-4.5 -5)"
          fill="none"
          stroke={props?.style?.color}
          strokeLinecap="round"
          strokeLineJoin="round"
          strokeWidth="2"
        />
        <path
          id={props.id}
          data-day={props.day}
          data-name="Caminho 71"
          d="M21.5,7V21a2,2,0,0,1-2,2H9.5a2,2,0,0,1-2-2V7m3,0V5a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2V7"
          transform="translate(-5.5 -3)"
          fill="none"
          stroke={props?.style?.color}
          strokeLinecap="round"
          strokeLineJoin="round"
          strokeWidth="2"
        />
        <path
          id={props.id}
          data-day={props.day}
          data-name="Caminho 72"
          d="M15,16.5v6"
          transform="translate(-8 -7.5)"
          fill="none"
          stroke={props?.style?.color}
          strokeLinecap="round"
          strokeLineJoin="round"
          strokeWidth="2"
        />
        <path
          id={props.id}
          data-day={props.day}
          data-name="Caminho 73"
          d="M21,16.5v6"
          transform="translate(-10 -7.5)"
          fill="none"
          stroke={props?.style?.color}
          strokeLinecap="round"
          strokeLineJoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default TrashIcon;

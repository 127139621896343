import React from 'react'
import PageHeader from '../../generic/PageHeader'
import PatientBillingSummaryCard from './PatientBillingSummaryCard'

const PatientBillingIndex = () => {
    return (
        <>
            <PageHeader title={"Billing Summary"} />
            <div className='container-fluid'>
                <div className='row h-100'>
                    <div style={{ minHeight: '100vh' }} className={`py-3 col-sm-3 patient-billing-left`}>
                        <div>
                            <span className='font-16 fw-sb text--terciary d-block'>Pay Your Bill</span>
                            <p className='font-12'>To pay your bill for services recevied before [Month Day, Year], click here. For payment plans established before [Month Day, Year], please call 888-999-9999</p>
                        </div>

                        <div>
                            <span className='font-16 fw-sb text--terciary d-block'>What if I can't pay the full amount?</span>
                            <p className='font-12'>If you're unable to pay your bill in full, you may qualify for a payment plan. This option allows you to automatically pay a smaller amount each month.</p>

                        </div>

                        <div>
                            <span className='font-16 fw-sb text--terciary d-block'>Payment Plan with Access One</span>
                            <p className='font-12'>Set up a payment plan with Access One to manage your payments easily.</p>

                        </div>

                        <div>
                            <span className='font-16 fw-sb text--terciary d-block'>Financial Assistance</span>
                            <p className='font-12'>Apply for financial assistance if you need help covering your medical expenses.</p>

                        </div>

                        <div>
                            <span className='font-16 fw-sb text--terciary d-block'>Paying on Behalf of Someone Else</span>
                            <p className='font-12'>To make a payment on another account (e.g., your teen child's account), use our Guest Pay feature.</p>

                        </div>

                        <div>
                            <span className='font-16 fw-sb text--terciary d-block'>Other Frequently Asked Questions</span>
                            <p className='font-12'>Find answers to common billing questions here.</p>

                        </div>
                    </div>
                    <div className='col-sm-8 py-3'>
                        <div className='row justify-content-center'>
                            <div className='col-sm-7'>
                                <PatientBillingSummaryCard />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PatientBillingIndex
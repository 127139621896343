import React, { useState } from 'react';
import Logo from "../../../../assets/icons/PrescribeNow-Horizontal-Logo-Intake.svg";
import LeaveAssesment from '../Modals/LeaveAssesment';
import ThanksModal from '../Modals/ThanksModal';
import CloseIcon from '@mui/icons-material/Close';
import sendHttpRequest from '../../../hooks/sendHttpRequest';
import { useAuth } from '../../../hooks/useAuth';

function Header(props) {

    const [leaveModal, setLeaveModal] = useState(false);
    const [thanksModal, setThanksModal] = useState(false);
    const {signout} = useAuth();

    const logout = async () => {
        const httpResponse = await sendHttpRequest({
            url: "/auth/logout",
            method: "POST",
            data: {}
        });
        if (!httpResponse.error) {

            // removeCookie("providerId");
            localStorage.clear();
            signout();

            // console.log(httpResponse.data);
        } else {
            // console.log(httpResponse.error);
        }
        // setIsLoading(false);
    };

    function closeAssesment() {
        let eqPos = document.cookie.indexOf("=");
        let name = eqPos > -1 ? document.cookie.substr(0, eqPos) : document.cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        setLeaveModal(!leaveModal);
        setThanksModal(!thanksModal);
        logout();
        setTimeout(() => {
            window.location.replace("https://www.prescribenow.com");
        }, 50)
    }

    function continueAssessment() {
        setLeaveModal(!leaveModal);
    }

    return (
        <>
            {/* {leaveModal ? */}
            <LeaveAssesment show={leaveModal} continueAssessment={continueAssessment} closeAssesment={closeAssesment} patientDemographics = {props?.patientDemographics ?? false}/>
            {/* : <ThanksModal show={thanksModal} /> */}
            {/* } */}
            <div className="bg-white w-100 fixed-top py-4 px-2">
                <div className='d-flex justify-content-between'>
                    <img src={Logo} className="mt-2" width={260} height={40} alt={"PrescribeNow-Logo"} />
                    <div style={{ marginRight: "50px", marginTop: "12px" }}>
                        <CloseIcon fontSize='large' onClick={continueAssessment} sx={{ cursor: 'pointer', color: '#336383' }} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header
import { Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import IconArchive from "../../assets/icons/Icon-archive.svg";
import IconFile from "../../assets/icons/Icon-file.svg";
import { dateTimeHelper } from "../../utils/dateTimeHelper";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { fieldValidation, truncate } from "../../utils/helpers/HelperFunctions";
import classes from "./InboxMessageItem.module.css";
import { useDispatch } from "react-redux";
import { setUnreadMessageCount } from "../../redux/actions/providerActions";
import { RolesTextConversion } from "../../utils/helpers/RolesMap";
import { useAuth } from "../hooks/useAuth";
import { ExclamationCircle } from "react-bootstrap-icons";

const InBoxMessageItem = (props) => {
  const [userData, setUserData] = useState();
  const [isUserDataLoading, setIsUserDataLoading] = useState();
  const [activeMessageItem, setActiveMessageItem] = useState(false);
  const [profileMessageItem, setProfileMessageItem] = useState(false)
  const dispatch = useDispatch()
  const { user } = useAuth();


  const handleActiveMessageItem = (event) => {
    setActiveMessageItem(true);
  };

  const fetchUserData = async (userId) => {
    setIsUserDataLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/auth/user-info",
      method: "GET",
      params: {
        userid: userId,
      },
    });
    if (!httpResponse.error) {
      setUserData(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
    setIsUserDataLoading(false);
  };

  const onChecked = (event) => {
    if (event.target.checked) {
      console.log("Check");
    } else {
      console.log("UnCheck");
    }
  };

  const fecthUnreadMessagesCount = async () => {
    const httpResponse = await sendHttpRequest({
      url: "/messages/unread",
      method: "GET",
      params: {
        userId: user?.userId,
        tenantId: user?.tenantId
      },
    });
    if (!httpResponse.error) {
      dispatch(setUnreadMessageCount(httpResponse?.data));
    }

  };
  const handleMessageReadStatus = async (threadId) => {
    if (props?.inbox) {
      const httpResponse = await sendHttpRequest({
        url: "/messages/mark-read",
        method: "PUT",
        params: {
          userId: user?.userId,
          threadId: threadId,
          tenantId: user?.tenantId
        },
      });
      if (!httpResponse.error) {
        // console.log(httpResponse.data);
        fecthUnreadMessagesCount();
        props.fetchUnreadMessagesCount();
      } else {
        console.log(httpResponse.error);
      }
    }
  };

  useEffect(() => {
    // console.log("props?.id", props?.userId);
    fetchUserData(props?.userId);
  }, [props?.userId]);

  return (
    <>
      <div
        className={
          props?.threadId === props?.messageItem?.threadId
            ? "row px-0 py-2 bg-white border--bottom"
            : "row px-0 py-2 border--bottom"
        }
        style={
          props?.threadId === props?.messageItem?.threadId
            ? {
              borderRight: "3px solid #EC609B",
              cursor: "pointer",
            }
            : {
              borderRight: "1px solid #d9e8ec",
              cursor: "pointer",
            }
        }
      >
        <div className="col-1" style={{ maxWidth: "2vw" }}>
          <input
            className={`form-check-input input ms-0 shadow-none ${classes["message__checkbox"]}`}
            type="checkbox"
            // onChange={onChecked}
            name="message-checkBox"
            // id="rovcb"
            disabled={true}
          />
        </div>
        {/* style={{maxWidth:"100vw",minWidth:"81vw"}} */}
        <div
          className="col-11"
          onClick={(e) => {

            props?.setThreadId(props?.messageItem?.threadId);
            handleActiveMessageItem(e);
            handleMessageReadStatus(props?.messageItem?.threadId);

          }}
        >
          <div className="d-flex flex-row justify-content-between ">
            <div className="d-flex">
              <div
                className={
                  props?.inbox === true
                    ? props?.messageItem?.status !== "READ"
                      ? "me-2 font-14 text--terciary fw-sb"
                      : "me-2 font-14 text--terciary"
                    : "me-2 font-14 text--terciary"
                }
              >
                <span style={activeMessageItem ? { fontWeight: "400" } : {}}>
                  {isUserDataLoading ? (
                    <Skeleton style={{ width: "2vw" }} />
                  ) : (
                    // userData?.communicationNumbers?.electronicMail[0]
                    userData?.name?.at(0)?.firstName + " " + userData?.name?.at(0)?.lastName
                  )}
                </span>
              </div>

              <div>
                <span
                  className="text--secondary font-14 ps-2 border--left"
                >
                  {/* Patient */}
                  {RolesTextConversion(userData?.role)}
                </span>
              </div>

              <div>
                <span
                  className="text--secondary font-14 ps-2"
                >
                  {props?.messageItem?.urgency && <ExclamationCircle
                    color="red"
                    size={16}
                  />}
                </span>
              </div>
            </div>
            <div className="d-flex">
              <div>
                {props?.messageItem?.attachment !== undefined &&
                  props?.messageItem?.attachment !== null && (
                    <img
                      className={`${classes["icon__file"]}`}
                      src={IconFile}
                      alt={"file icon"}
                    ></img>
                  )}
              </div>
              <div className="ms-3">
                <img
                  className={`${classes["icon__archive"]}`}
                  src={IconArchive}
                  alt={"archieve icon"}
                ></img>
              </div>
            </div>
          </div>
          <div>
            <div className="d-flex justify-content-between">
              <div
                className={
                  props?.inbox === true
                    ? props?.messageItem?.status !== "READ"
                      ? "me-2 font-16 text--terciary fw-sb"
                      : "me-2 font-16 text--terciary"
                    : "me-2 font-16 text--terciary"
                }
              >
                <span style={activeMessageItem ? { fontWeight: "400" } : {}}>
                  {/*subject*/}
                  {props?.messageItem?.subject}
                </span>
              </div>
              <div className="text--secondary font-14">
                {/*Date*/}
                {dateTimeHelper(
                  props?.messageItem?.deliveryTime,
                  "MMM D, YYYY - LT"
                )}
              </div>
            </div>
          </div>
          <div className="font-14 text--secondary">
            {/*Body*/}
            {
              fieldValidation(props?.threadId, "all")
                ? (props?.messageItem?.body !== "undefined" ? truncate(props?.messageItem?.body, 150) : "")
                : (props?.messageItem?.body !== "undefined" ? truncate(props?.messageItem?.body, 50) : "")
            }
          </div>
        </div>
        {/* {console.log("activeMessageItem",activeMessageItem)} */}
      </div>
    </>
  );
};

export default InBoxMessageItem;

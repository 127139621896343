
import { fetchSocialHistoryUrl } from "../../res/endpointurls";
import sendHttpRequest from "../hooks/sendHttpRequest";

export default class PatientDemographicsService {
    static PATIENT_INFORMATION_URL = '/patient/information';

    static getPatientDemographics = async (patientId) => {
        try {
            const httpResponse = await sendHttpRequest({
                url: this.PATIENT_INFORMATION_URL,
                method: "GET",
                params: {
                    patientId: patientId
                }
            });
            if (!httpResponse.error) {
                const res = { ...httpResponse.data, status: httpResponse.status };
                return res;
            }
            else {
                return httpResponse;
            }
        }
        catch (err) {
            console.log("Error in fetching patient demographics: ", err);
        }
    }

    static fecthSocialHistory = async (patientId) => {
        const httpResponse = await sendHttpRequest({
            url: fetchSocialHistoryUrl(patientId),
            method: "GET",
        });
        if (!httpResponse.error) {
            // console.log(httpResponse.data);
            return httpResponse.data;
        }
    };

}
export const strings = {
  en: {
    waiting_room_header_text: "Some people are waiting to be admitted.",
    move_to_waiting_room: "Move patient to the virtual waiting room",
    approve: "Approve",
    allow: "Allow",
    deny: "Deny",
    allow_all: "Allow All",
    deny_all: "Deny All",
    audio_configuration:
      "Please adjust your audio configuration for your appointment with",
    join_now: "Join Now",
    audio: "Audio",
    video: "Video",
    on: "On",
    off: "Off",
    date: "Date",
    time: "Time",
    chiefcomplaint: "Chief Complaint",
    allergies: "Allergies",
    nofoodallergies: "Food: No allergies specified",
    nodrugallergies: "Drug: No allergies specified",
    noupcomingappointment: "No Upcoming Appointment",
    admittedToWaitingRoom:
      "Please wait for your physician to add you into the video call.",
    rejectedFromWaitingRoom:
      "Your request to join the video call was declined. Please contact your care team if you feel this was in error.",
    noDataAvailable: "No data available",
    noPrescriptionAvailable: "No pending prescription requests",
    sessionTimeOut: "Your session timed out due to inactivity. Please login again."
  },
};

export const patient_profile_strings = {
  en: {
    patientInformation: "Patient Information",
    substanceUse: "Substance Use",
    careTeam: "Care Team",
    insurance: "Insurance Eligibility Check",
    chronicConditions: "Chronic Conditions",
    primaryInsurance: "Primary Insurance",
    secondaryInsurance: "Secondary Insurance",
    eligibleCoverage: "Active",
    ineligibleCoverage: "Inactive",
    lastChecked: "Last Checked",
    insuranceCheck: "Insurance Check",
    selectInsurance: "Insurance Type",
    practiceTypeCode: "Practice Type Code",
    billing: "Billing History",
    // time_service_payment:"Time of Service Payments"
    time_service_payment: "Payments"
  },
};

export const common_strings = {
  en: {
    zipcode: "Zip Code",
    country: "Country",
    phone: "Phone",
    fax: "Fax",
    city: "City",
    state: "State",
    email: "Email",
    edit: "Edit",
    dob: "Date of Birth",
    dob_on_file: "DOB on file",
    dob_from_pharmacy: "DOB from pharmacy",
    age: "Age",
    biologicalSex: "Biological Sex",
    address: "Address",
    primaryPhone: "Primary Phone",
    emergencyContact: "Emergency Contact",
    preferredPharmacy: "Preferred Pharmacy",
    preferredFacility: "Preferred Facility",
    preferredInsurance: "Preferred Insurance",
    type: "Type",
    id: "Id",
    copay: "Copay",
    copayOut: "Copay Out-net",
    effectiveDate: "Effective Date",
    provider: "Provider",
    physician: "Physician",
    prescriber: "Prescriber",
    check: "Check",
    editInsurance: "Edit Insurance",
    addPatient: "Add Patient",
    name: "Insurance Name",
    pharmacyLabelName: "Pharmacy Name",
    policyNumber: "Policy Number",
    memberId: "Member Id",
    teminationDate: "Termination Date",
    relationshipToInsured: "Relationship to Insured",
    contactNumber: "Contact Number",
    saveChanges: "Save Changes",
    patient: "Patient",
    prescriberName: "Prescriber Name",
    pharmacy: "Pharmacy",
    presciber: "Prescriber",
    patientName: "Patient Name",
    gender: "Gender",
    weight_str: 'Weight',
    height_str: 'Height',
    firstName: "First Name",
    lastName: "Last Name",
    middleName: "Middle Name",
    addressLine1: "Address line 1",
    addressLine2: "Address line 2",
    biologicalSex: "Biological Sex",
    insurance_str: "Please provide the following information for the insurance policy holder",
    place_of_service_str: "Place of Service",
    no_of_rooms_str: "Number of Rooms",
    room_prefix_str: "Room Prefix",
  },
};

export const facility_strings = {
  en: {
    set_as_primary_string: "Set as Primary Facility",
    address1: "Address 1",
    address2: "Address 2",
    name: "Name",
  },
};

export const order_strings = {
  en: {
    medication_accordion_title: "Medication",
    pharmacy_accordion_title: "Pharmacy",
    order_summary_accordion_title: "Order Summary",
    prescribe_medication_title: "Prescription",
    current_medication_title: "Current Medications",
    ePrescription: "ePrescription",
    ePrescribe: "ePrescribe",
    fax: "Fax",
    print: "Print",
    faxRx: "faxRx",
    printRx: "Print Rx",
  },
};

export const reusable_strings = {
  patient_str: "Patient",
  dob_str: "DOB",
  date_of_birth_str: "Date of Birth",
  email_str: "Email",
  phone_str: "Phone",
  address_str: "Address",
  pharmacy_str: "Pharmacy",
  prescribing_physician_str: "Prescribing Physician",
  npi_str: "NPI",
  prescribing_facility_str: "Prescribing Facility",
  order_type_str: "Order Type",
  signature_str: "Signature",
  gender_str: "Gender",
  height_str: "Height",
  weight_str: "Weight",
  fax_str: "Fax",
  specialty_str: "Specialty",
};
export const preferred_pharmacy_modal_strings = {
  en: {
    modal_title: "Patient Preferred Pharmacy",
    modal_body_msg:
      "Please confirm to set the listed pharmacy as patient preferred. This will overwrite any preferred pharmacy set earlier",
    cancel_button_str: "Cancel",
    preferred_button_str: "Set as Patient Preferred",
  },
};
export const search_medication = {
  en: {
    title: "Search Medication",
    med_not_found: "Medication not found",
  },
};

export const file_uploading_helping_strings = {
  en: {
    error_2MB: "File size exceeded the maximum limit of 2 MB",
    error_25MB: "File size exceeded the maximum limit of 25 MB",
    invlaid_file: "File type is invalid",
    invalid_file_pixels: "Image resolution should be lower than 750 x 150 pixels",
    lower_pixel_length: "Image resolution should minimum be recommended pixels",
    resolution_pixels: "(Recommended resolution 500 X 100 pixels)",
    task_review_str: "Create task for document review",
    asignee_str: "Assignee",
    error_5MB: "File size exceeded the maximum limit of 5 MB",
  },
};

export const numbersEnum = {
  en: {
    emergencyTelephoneExt: "OT",
    homeTelephoneExt: "HT",
    cellPhoneExt: "PT",
  },
};

export const addressType = {
  en: {
    currentAddress: "ca",
  },
};

export const newAppointmentModalStrings = {
  en: {
    type: "Appointment Type",
    select: "Select Appointment",
    new: "New Appointment",
    followUp: "Follow Up Appointment",
    custom: "Custom Appointment",
    chiefcomplaint: "Reason for Visit",
    provider: "Provider",
    selectProvider: "Select Provider",
    comment: "Comments",
    viewAvailableAppointments: "View available appointments by",
    dateTime: "Date and time",
    providerEarlistAvailability: "Provider's earliest availability",
    starttime: "Start Time",
    noSlotFound: "No slots found",
    passAppointments: "Past Appointments",
    facilities_str: "Facilities",
    referringPhysician: "Referring Physician",
    addReferringPhysician: "Add Referring Physician",

  }
}

export const allergiesStrings = {
  en: {
    allergies: "Allergies",
    category: "Category",
    substance: "Substance",
    severity: "Severity",
    reaction: "Reaction",
    addAllergy: "Add Allergy",
    selectCategory: "Select Category",
    drug: "Drug",
    food: "Food",
    common: "Common",
    mild: "Mild",
    moderate: "Moderate",
    severe: "Severe",
    reactions: "Reactions",
    save: "Save"
  }
}

export const hospitalizationStrings = {
  en: {
    hospitalization: "Hospitalizations",
    table_reason: "Reason",
    reason: "Reason *",
    table_lengthOfStay: "Length of Stay",
    lengthOfStay: "Length of Stay *",
    addHospitalizationHistory: "Add Hospitalization History",
    save: "Save"
  }
}

export const surgeryStrings = {
  en: {
    table_procedure: "Procedure",
    date: "Date",
    procedure: "Procedure *",
    note: "Note",
    save: "Save",
    update: "Update"
  }
}

export const medicalHistoryStrings = {
  en: {
    table_mh_name: "Name",
    name: "Name *",
    dose: "Dose",
    strength: "Strength",
    frequency: "Frequency",
    medication_status: "Medication Status",
    save: "Save",
    update: "Update"
  }
}

export const familyMedicalHistoryStrings = {
  en: {
    familyMedicalHistory: "Family Medical History",
    table_relationship: "Relationship",
    relationship: "Relationship *",
    table_medicalCondition: "Medical Condition",
    medicalCondition: "Medical Condition *",
    note: "Note",
    addFamilyMedicalHistory: "Add Family Medical History",
    save: "Save",
    editFamilyMedicalHistory: "Edit Family Medical History",

  }
}

export const taskTypesEnum = {
  en: {
    renewal_type: "renewal_request",
    encounter_type: "encounter",
    changerx_type: "change_request",
    renewal: "Renewal Request",
    cancelType: "cancel_request",
    change_type: "change_request",
    change: "Change Request",
    cancel: "Cancel Request",
    encounter: "Pending Encounter",
    changerx: "Change Request",
    document_review: "document_review",
    refill_request: "refill_request"
  }
}

export const taskCompletedByStrings = {
  en: {
    auto_denied_status: "Completed (auto-denied)",
    auto_denied_by: "",
  }
}

export const renewalRequestStrings = {
  en: {
    renewalRequest: "Renewal Request",
    originalPrescription: "Original Prescription",
    requestedMedication: "Requested Medication",
    drugDescription: "Drug Description",
    sig: "Sig",
    refills: "Refills",
    quantity: "Quantity",
    daysSupply: "Days Supply",
    substitutions: "Substitutions",
    approve: "Approve",
    approveWithChanges: "Approve with Changes",
    replace: "Replace",
    validate: "Validate",
    deny: "Deny",
    approved: "approved",
    approved_with_changes: "approved_with_changes",
    denied: "denied",
    denyRenewalRequest: "Deny Renewal Request",
    denyChangeRequest: "Deny Change Request",
    reasonForDenial: "Reason for Denial",
    cancel: "Cancel",
    notAvailable: "Not Available",
    replaceRequestTitle: "Replacement prescription for renewal request from pharmacy",
    renewalRequestTitle: "The patient DOB sent by the pharmacy differs from the one on file. To update the patient record with the DOB from the pharmacy, please approve the renewal request; otherwise, please deny the request and send a new prescription order for the given medication.",
    renewalRequestInfoChange: "Patient demographics received from pharmacy differs from the one on file. Please click approve to echo back data from the pharmacy or replace to use data from the patient record.",
    send: "Send",
    note: "Note"

  }
}
export const orderPrescriptionTableStrings = {
  en: {
    drugDescription: "Drug Description",
    sig: "Sig",
    dispense: "Dispense",
    refills: "Refills",
    status: "Status",
  }
}

export const orderPrescriptionStrings = {
  en: {
    eprescribe_msg: "Rx submitted for processing",
    eprescribe_cancelation_msg: "Rx cancelation submitted for processing"
  }
}

export const searchPharmacyStrings = {
  en: {
    no_result_found: "No results found",
    minimum_input_length: "Enter a minimum of 2 characters"
  }
}

export const relationships = ["Parent", "Sister", "Brother", "Grandparent", "Aunt", "Uncle", "Child", "Niece", "Nephew", "Other"];

export const pharmacyList = ["Compounding", "DME", "In House Dispensing", "Long Term Care", "Mail Order", "Retail", "Specialty", "Twenty Four Hour Store"];

export const gendersList = ["Male", "Female", "Unknown"];

export const no_patient_record = { unknown_patient_msg: "No patient record found" }

export const lab_requisition_strings = {
  en: {
    patient_details_str: "Patient",
    requester_details_str: "Requesting Physician",
    name_str: "Name",
    address_str: "Address",
    telephone_str: "Telephone Number",
    date_of_birth_str: "Date of Birth",
    gender_str: "Gender",
    male_str: "Male",
    female_str: "Female",
    other_str: "Other",
    organization_str: "Organization",
    facility_str: "Facility",
    physician_npi_str: "Physician's NPI",
    work_address_str: "Work Address",
    sample_details_str: "Specimen Information",
    date_collected_str: "Date Collected",
    time_collected_str: "Time Collected",
    urgency_str: "Urgency",
    normal_str: "Normal",
    urgent_str: "Urgent",
    fasting_str: "Fasting",
    non_fasting: "Non Fasting",
    number_of_hrs_str: "Number of hours",
    clinical_info_str: "Relevant clinical information",
    drug_therapy_str: "Drug therapy",
    last_dose: "Last dose",
    date_str: "Date",
    time_str: "Time",
    other_clinical_info_str: "Other relevant clinical information",
    profile_test_str: "Profile test",
    biochemistry_str: "Biochemistry",
    hematology_str: "Hematology",
    microbiology_str: "Microbiology",
    anatomical_pathology_str: "Anatomical Pathology",
    examinationRequested_str: "Labs Requested",
    additional_Tests_str: "Additional Tests",
    reason_Tests_str: "Reason for Test",
    icd10_codes_str: "ICD-10 Codes",
    signature_str: "Signature",
    summary_str: "Summary",
    consent_str: "I confirm that the ordered lab tests are medically necessary for this patient and are part of their diagnostic and treatment plan.",
    print_lab_order: "Print",
    fax_lab_order: "Fax",
  }
}
export const soap_strings = {
  en: {
    subjective: "Subjective",
    chiefcomplaint: "Chief Complaint",
    histor_of_Present_illness: "History of Present Illness",
    review_of_systems: "Review of Systems",
    past_medical_history: "Past Medical History",
    objective: "Objective",
    physical_examination: "Physical Examination",
    vitals: "Vitals",
    assessment: "Assessment",
    diagnosis: "Diagnosis",
    pastDiagnosis: "Past Diagnoses",
    addVitals: "Add Vitals",
    addDiagnosis: "Add Diagnosis",
    plan: "Plan",
    medication_str: "Medications Ordered",
    labs_str: "Labs Ordered",
  }
}

export const deleteConfirmationStrings = {
  en: {
    lab_Order_delete: "This will remove all lab tests in the order. Are you sure you want to proceed?",
    medication_delete: "This will remove all medications in the order. Are you sure you want to proceed?",

  }
}

export const tenantSettingStrings = {
  en: {
    practice_info_str: "Practice Information",
    practice_name_str: "Practice Name",
    practice_type_code_str: "Practice Type Code",
    title_str: "Title",
    first_name_str: "First Name",
    last_name_str: "Last Name",
    email_str: "Email",
    phone_str: "Phone",
    address_str: "Address",
    group_npi_str: "Group NPI",
    company_logo_str: "Company Logo",
    appt_settings_str: "Appointment Settings",
    length_of_consult_str: "Length of Consult (mins)",
    length_of_follow_up_str: "Length of Follow-up (mins)",
    date_format_str: "Date Format",
    defualt_timezone_str: "Default Time Zone",
    patient_eligibility_str1: "Run patient eligibility checks",
    patient_eligibility_str2: "hours before appointment",
    notification_settings_str: "Notification Settings",
    from_email_str: "Notification From Email",
    from_number_str: "Notification From Number",
    subscription_str: "Subscription Details",
    prescribing_users_str: "Number of Prescribing Users",
    verification_str: "Skip identity verification",
    contract_start_str: 'Contract Start Date',
    contract_end_str: 'Contract End Date',
    price_of_consult_str: 'Price',
    price_of_follow_up_str: 'Price',
    paypal_settings_str: "Paypal Settings",
    merchant_id_str: "Brain Tree Merchant Id",
    public_key_str: "Brain Tree Public Key",
    private_key_str: "Brain Tree Private Key",
    add_service_item_str: "Appt. Type",
    servise_item_str: "Service Item",
    description_str: "Description",
    service_charges_str: "Service Charges",
    duration_str: "Duration (min)",

  }
}

export const pageNotFoundStrings = {
  en: {
    text: "Somethings went wrong. Please contact support."
  }
}

export const VISIT_STATUS_VALUES = {
  'completed': 'consultation-completed',
  'arrived': 'arrived',
  'providerReady': 'provider-ready',
  'inConsultation': 'in-consultation',
  'maReviewed': 'ma-reviewed',
  'checkedIn': 'checkin',
  'departed': 'departed',

}

export const VISIT_STATUS_COLORS = {
  'arrived': '#E0FFFF',
  'provider-ready': '#FFFFE0',
  'in-consultation': '#FFDAB9',
  'ma-reviewed': '#E6E0EC',
  'consultation-completed': '#D3D3D3',
  'checkin': 'rgba(144, 238, 144, 0.2)',
  'departed': '#E0FFFF',
  'shcheduled': '#ADD8E6'

}

export const imaging_order_strings = {
  en: {
    service_requested_str: "Service Requested",
    imaging_order_str: "Imaging Order",
    imaging_consent_str: "I confirm that the ordered imaging tests are medically necessary for this patient and are part of their diagnostic and treatment plan.",

  }
}

// export const patient_insurance = {
//   en:{
//     insured_firstName: "firstName",
//     insured_lastName: "lastName",
//     insured_middleName: "middleName",

//   }
// }


const ScriptIcon = (props) => {
  return (
    <svg
      id="Icon-Script"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 16.667 20"
      {...props}
      fill={props.style.color}
    >
      <path
        id="Icon-Script-2"
        data-name="Icon-Script"
        d="M14.5,11.667a.833.833,0,0,1-.833.833H7a.833.833,0,0,1,0-1.667h6.667A.833.833,0,0,1,14.5,11.667Zm-3.333,2.5H7a.833.833,0,1,0,0,1.667h4.167a.833.833,0,0,0,0-1.667Zm7.5-5.429v7.1A4.172,4.172,0,0,1,14.5,20H6.167A4.172,4.172,0,0,1,2,15.833V4.167A4.172,4.172,0,0,1,6.167,0H9.929a5.8,5.8,0,0,1,4.125,1.708l2.9,2.9a5.792,5.792,0,0,1,1.709,4.124ZM12.876,2.887A4.175,4.175,0,0,0,12,2.237v3.6a.833.833,0,0,0,.833.833h3.6a4.152,4.152,0,0,0-.651-.875ZM17,8.737c0-.137-.027-.269-.039-.4H12.833a2.5,2.5,0,0,1-2.5-2.5V1.706c-.135-.013-.267-.039-.4-.039H6.167a2.5,2.5,0,0,0-2.5,2.5V15.833a2.5,2.5,0,0,0,2.5,2.5H14.5a2.5,2.5,0,0,0,2.5-2.5Z"
        transform="translate(-2 0)"
        stroke=""
      />
    </svg>
  );
};

export default ScriptIcon;

import { FormSelect, Modal } from "react-bootstrap";
import { ReactComponent as AddButtonIcon } from "../../../../assets/icons/Icon-Add.svg";
import { useEffect, useRef, useState } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { Divider, Grid, Skeleton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classes from "./AppointmentScheduleModal.module2.css";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ScheduleSlotRow from "../../General/ScheduleSlotRow";
import sendHttpRequest from "../../../hooks/sendHttpRequest";
import CustomDatePicker from "../../General/CustomDatePicker";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { RolesTextConversion } from "../../../../utils/helpers/RolesMap";
import { useUnsavedWorkPrompt } from "../../../hooks/useUnsavedWorkPrompt";
import { unsavedWorkHandler } from "../../../../utils/helpers/HelperFunctions";
import { useAuth } from "../../../hooks/useAuth";
import { providerWithNpiUrl } from "../../../../res/endpointurls";
import {
  changeDateTimeFormat,
  convertUTCtoTimzone,
} from "../../../../utils/dateTimeHelper";
import { DateTime } from "luxon";
import CalenderDatePicker from "../../General/CalenderDatePicker";
import { useSelector } from "react-redux";
import { APPOINTMENTS } from "../../../../utils/helpers/subscriptionsConstants";

const useStyle = makeStyles({
  root: {
    width: "63vw",
  },
});

const AppointmentScheduleModal2 = (props) => {
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  // const [selectedProviderId, setSelectedProviderId] = useState();
  const [selectedProviderId, setSelectedProviderId] = useState(
    props?.selectedPrescriberId
  );
  const [expanded2, setExpanded2] = useState("panel2");
  const [timezone, setTimezone] = useState();
  const [timexone, setTimexone] = useState("");
  const [prescribersList, setPrescribersList] = useState([]);
  const date = new Date();
  // const year = date.getFullYear();
  // const month = String(date.getMonth() + 1).padStart(2, "0");
  // const day = String(date.getDate()).padStart(2, "0");
  const joined = convertUTCtoTimzone(date, "", "YYYY-MM-DD");
  const joinedEndDate = convertUTCtoTimzone(
    moment(date).add(6, "days"),
    "",
    "YYYY-MM-DD"
  );
  // const joined = [year, month, day].join("-");

  const [secheduleStartDate, setScheduleStartDate] = useState(joined);
  // const [secheduleEndDate, setScheduleEndDate] = useState(joined);
  const [secheduleEndDate, setScheduleEndDate] = useState(joinedEndDate);
  const [isScheduledPublished, setIsScheduledPublished] = useState(false);
  const [nextWeekDate, setNextWeekDate] = useState(
    moment(new Date()).add(6, "days").format("MM-DD-YYYY")
  );
  // const [nextWeekDate, setNextWeekDate] = useState(new Date(new Date().getTime() + 6 * 24 * 60 * 60 * 1000));

  const [mondaySlotsList, setMondaySlotsList] = useState([
    { slot: 0, startTime: "", endTime: "" },
  ]);
  const [tuesdaySlotsList, setTuesdaySlotsList] = useState([
    { slot: 0, startTime: "", endTime: "" },
  ]);
  const [wednesdaySlotsList, setWednesdaySlotsList] = useState([
    { slot: 0, startTime: "", endTime: "" },
  ]);
  const [thursdaySlotsList, setThursdaySlotsList] = useState([
    { slot: 0, startTime: "", endTime: "" },
  ]);
  const [fridaySlotsList, setFridaySlotsList] = useState([
    { slot: 0, startTime: "", endTime: "" },
  ]);
  const [saturdaySlotsList, setSaturdaySlotsList] = useState([
    { slot: 0, startTime: "", endTime: "" },
  ]);
  const [sundaySlotsList, setSundaySlotsList] = useState([
    { slot: 0, startTime: "", endTime: "" },
  ]);

  let count = 0;
  const classesStyle = useStyle();
  const [monCount, setMonCount] = useState(1);
  const [tueCount, setTueCount] = useState(1);
  const [wedCount, setWedCount] = useState(1);
  const [thuCount, setThuCount] = useState(1);
  const [friCount, setFriCount] = useState(1);
  const [satCount, setSatCount] = useState(1);
  const [sunCount, setSunCount] = useState(1);

  let slotsArray = [
    mondaySlotsList,
    tuesdaySlotsList,
    wednesdaySlotsList,
    thursdaySlotsList,
    fridaySlotsList,
    saturdaySlotsList,
    sundaySlotsList,
  ];

  const [isDirty, onDirty, onPristine] = useUnsavedWorkPrompt();

  // Provider facilities
  const [providerFacilities, setProviderFacilities] = useState([]);
  const [isProviderFacilitiesLoading, setIsProviderFacilitiesLoading] =
    useState(false);
  // const [selectedFacilityId,setSelectedFacilityId] = useState(user?.facility?.facilityId)
  const [selectedFacilityId, setSelectedFacilityId] = useState("");

  const fecthAllPrescribers = async () => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: providerWithNpiUrl(user?.tenantId),
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log("prescribers-> ", httpResponse.data);
      setPrescribersList(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
    setIsLoading(false);
  };

  const fetchProviderFacilities = async (userId, tenantId) => {
    setIsProviderFacilitiesLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/provider/${userId}/tenant/${tenantId}/facilities`,
      method: "GET",
    });
    if (!httpResponse.error) {
      setProviderFacilities(httpResponse.data);
    }
    setIsProviderFacilitiesLoading(false);
  };

  const saveProviderSchedule = async (scheduleSlots, isEmpty) => {
    if (isEmpty) {
      setIsScheduledPublished(true);
      // setIsLoading(true);
      const httpResponse = await sendHttpRequest({
        url: "/availabilities",
        method: "POST",
        data: scheduleSlots,
      });
      if (!httpResponse.error) {
        props?.setIsSentSchedule(true);
        handleDeleteSlotsAfterSaving();
        resetStates();
        // props?.handleModal();
        // console.log(httpResponse.data);
      } else {
        if (httpResponse.status !== 500) {
          if (httpResponse.status === 400 && httpResponse?.errorMessage?.moreInfo.includes("SLOTS_OVERLAP")) {
            props?.setIsTimeSlotOverlap(true);
            props?.setTimeSlotOverlapErrorMessage(httpResponse?.errorMessage?.message)
          }
          else {
            props?.setIsSentScheduleFail(true);
          }
        }
      }
      // setIsLoading(false);
      setIsScheduledPublished(false);
    } else {
      props?.setScheduleUploadFail(true);
    }
  };

  const resetStates = () => {
    setMondaySlotsList([
      { slot: 0, startTime: "", endTime: "", isValid: false },
    ]);
    setTuesdaySlotsList([
      { slot: 0, startTime: "", endTime: "", isValid: false },
    ]);
    setWednesdaySlotsList([
      { slot: 0, startTime: "", endTime: "", isValid: false },
    ]);
    setThursdaySlotsList([
      { slot: 0, startTime: "", endTime: "", isValid: false },
    ]);
    setFridaySlotsList([
      { slot: 0, startTime: "", endTime: "", isValid: false },
    ]);
    setSaturdaySlotsList([
      { slot: 0, startTime: "", endTime: "", isValid: false },
    ]);
    setSundaySlotsList([
      { slot: 0, startTime: "", endTime: "", isValid: false },
    ]);

    setMonCount(1);
    setTueCount(1);
    setWedCount(1);
    setThuCount(1);
    setFriCount(1);
    setSatCount(1);
    setSunCount(1);

    // setScheduleStartDate(joined);
    // setScheduleEndDate(moment(new Date()).add(6, "days").format("YYYY-MM-DD"));
    // setNextWeekDate(moment(new Date()).add(6, "days").format("MM-DD-YYYY"));
  };

  const handleAddMondaySlot = () => {
    let monSlotEmpty = false;
    if (mondaySlotsList?.length > 0) {
      mondaySlotsList?.forEach((slot) => {
        if (slot?.startTime === "" || slot?.endTime === "") {
          return (monSlotEmpty = true);
        }
      });
    } else {
      setMondaySlotsList([
        ...mondaySlotsList,
        { slot: monCount, startTime: "", endTime: "", isValid: false },
      ]);
      setMonCount(monCount + 1);
    }

    if (!monSlotEmpty) {
      setMondaySlotsList([
        ...mondaySlotsList,
        { slot: monCount, startTime: "", endTime: "", isValid: false },
      ]);
      setMonCount(monCount + 1);
    }
  };
  const handleAddTuesdaySlot = () => {
    let tueSlotEmpty = false;
    if (tuesdaySlotsList?.length > 0) {
      tuesdaySlotsList?.forEach((slot) => {
        if (slot?.startTime === "" || slot?.endTime === "") {
          return (tueSlotEmpty = true);
        }
      });
    } else {
      setTuesdaySlotsList([
        ...tuesdaySlotsList,
        { slot: tueCount, startTime: "", endTime: "", isValid: false },
      ]);
      setTueCount(tueCount + 1);
    }

    if (!tueSlotEmpty) {
      setTuesdaySlotsList([
        ...tuesdaySlotsList,
        { slot: tueCount, startTime: "", endTime: "", isValid: false },
      ]);
      setTueCount(tueCount + 1);
    }
  };
  const handleAddWednesdaySlot = () => {
    let wedSlotEmpty = false;
    if (wednesdaySlotsList?.length > 0) {
      wednesdaySlotsList?.forEach((slot) => {
        if (slot?.startTime === "" || slot?.endTime === "") {
          return (wedSlotEmpty = true);
        }
      });
    } else {
      setWednesdaySlotsList([
        ...wednesdaySlotsList,
        { slot: wedCount, startTime: "", endTime: "", isValid: false },
      ]);
      setWedCount(wedCount + 1);
    }

    if (!wedSlotEmpty) {
      setWednesdaySlotsList([
        ...wednesdaySlotsList,
        { slot: wedCount, startTime: "", endTime: "", isValid: false },
      ]);
      setWedCount(wedCount + 1);
    }
  };
  const handleAddThursdaySlot = () => {
    let thursSlotEmpty = false;
    if (thursdaySlotsList?.length > 0) {
      thursdaySlotsList?.forEach((slot) => {
        if (slot?.startTime === "" || slot?.endTime === "") {
          return (thursSlotEmpty = true);
        }
      });
    } else {
      if (!thursSlotEmpty) {
        setThursdaySlotsList([
          ...thursdaySlotsList,
          { slot: thuCount, startTime: "", endTime: "", isValid: false },
        ]);
        setThuCount(thuCount + 1);
      }
    }

    if (!thursSlotEmpty) {
      setThursdaySlotsList([
        ...thursdaySlotsList,
        { slot: thuCount, startTime: "", endTime: "", isValid: false },
      ]);
      setThuCount(thuCount + 1);
    }
  };
  const handleAddFridaySlot = () => {
    let friSlotEmpty = false;
    if (fridaySlotsList?.length > 0) {
      fridaySlotsList?.forEach((slot) => {
        if (slot?.startTime === "" || slot?.endTime === "") {
          return (friSlotEmpty = true);
        }
      });
    } else {
      setFridaySlotsList([
        ...fridaySlotsList,
        { slot: friCount, startTime: "", endTime: "", isValid: false },
      ]);
      setFriCount(friCount + 1);
    }

    if (!friSlotEmpty) {
      setFridaySlotsList([
        ...fridaySlotsList,
        { slot: friCount, startTime: "", endTime: "", isValid: false },
      ]);
      setFriCount(friCount + 1);
    }
  };
  const handleAddSaturdaySlot = () => {
    let satSlotEmpty = false;
    if (saturdaySlotsList?.length > 0) {
      saturdaySlotsList?.forEach((slot) => {
        if (slot?.startTime === "" || slot?.endTime === "") {
          return (satSlotEmpty = true);
        }
      });
    } else {
      setSaturdaySlotsList([
        ...saturdaySlotsList,
        { slot: satCount, startTime: "", endTime: "", isValid: false },
      ]);
      setSatCount(satCount + 1);
    }

    if (!satSlotEmpty) {
      setSaturdaySlotsList([
        ...saturdaySlotsList,
        { slot: satCount, startTime: "", endTime: "", isValid: false },
      ]);
      setSatCount(satCount + 1);
    }
  };
  const handleAddSundaySlot = () => {
    let sunSlotEmpty = false;
    if (sundaySlotsList?.length > 0) {
      sundaySlotsList?.forEach((slot) => {
        if (slot?.startTime === "" || slot?.endTime === "") {
          return (sunSlotEmpty = true);
        }
      });
    } else {
      setSundaySlotsList([
        ...sundaySlotsList,
        { slot: sunCount, startTime: "", endTime: "", isValid: false },
      ]);
      setSunCount(sunCount + 1);
    }

    if (!sunSlotEmpty) {
      setSundaySlotsList([
        ...sundaySlotsList,
        { slot: sunCount, startTime: "", endTime: "", isValid: false },
      ]);
      setSunCount(sunCount + 1);
    }
  };

  const handleDeleteSlotsAfterSaving = () => {
    for (let i = 0; i < sundaySlotsList.length; i++) {
      deleteDaySlots("Sunday", sundaySlotsList, 0)
    }
    for (let i = 0; i < mondaySlotsList.length; i++) {
      deleteDaySlots("Monday", mondaySlotsList, 0)
    }
    for (let i = 0; i < tuesdaySlotsList.length; i++) {
      deleteDaySlots("Tuesday", tuesdaySlotsList, 0)
    }
    for (let i = 0; i < wednesdaySlotsList.length; i++) {
      deleteDaySlots("Wednesday", wednesdaySlotsList, 0)
    }
    for (let i = 0; i < thursdaySlotsList.length; i++) {
      deleteDaySlots("Thursday", thursdaySlotsList, 0)
    }
    for (let i = 0; i < fridaySlotsList.length; i++) {
      deleteDaySlots("Friday", fridaySlotsList, 0)
    }
    for (let i = 0; i < saturdaySlotsList.length; i++) {
      deleteDaySlots("Saturday", saturdaySlotsList, 0)
    }
  }
  const deleteDaySlots = (slotDay, slotList, slotId) => {
    let day = slotDay;
    let slotsList = slotList;
    let id = slotId;
    let result = "";

    if (slotsList?.length > 0) {
      result = slotsList.filter((slotItem) => {
        return slotItem.slot != id;
      });
      console.log("result:::::::", result);
      setSlotsList(day, result);
    } else if (slotsList?.length == 1) {
      slotsList[0].startTime = "";
      slotsList[0].endTime = "";
      slotsList[0].isValid = false;

      if (
        slotsList[0].hasOwnProperty("startRef") &&
        slotsList[0].startRef !== null
      ) {
        slotsList[0].startRef.style.border = "1px solid #D9E8EC";
        slotsList[0].startRef.value = "";
      }
      if (
        slotsList[0].hasOwnProperty("endRef") &&
        slotsList[0].endRef !== null
      ) {
        slotsList[0].endRef.style.border = "1px solid #D9E8EC";
        slotsList[0].endRef.value = "";
      }
      // slotsList[0] = { slot: slotsList[0].slot, startTime: "", endTime: "", isValid: false };
    }
  };
  const handleDeleteDaySlots = (event) => {
    let day = event.target.getAttribute("data-day");
    let slotsList = getDaySlotsList(day);
    let id = event.target.id;
    let result = "";

    if (slotsList?.length > 1) {
      result = slotsList.filter((slotItem) => {
        return slotItem.slot != id;
      });
      setSlotsList(day, result);
    } else if (slotsList?.length == 1) {
      slotsList[0].startTime = "";
      slotsList[0].endTime = "";
      slotsList[0].isValid = false;

      if (
        slotsList[0].hasOwnProperty("startRef") &&
        slotsList[0].startRef !== null
      ) {
        slotsList[0].startRef.style.border = "1px solid #D9E8EC";
        slotsList[0].startRef.value = "";
      }
      if (
        slotsList[0].hasOwnProperty("endRef") &&
        slotsList[0].endRef !== null
      ) {
        slotsList[0].endRef.style.border = "1px solid #D9E8EC";
        slotsList[0].endRef.value = "";
      }
      // slotsList[0] = { slot: slotsList[0].slot, startTime: "", endTime: "", isValid: false };
    }
  };

  const setSlotsList = (day, filteredList) => {
    switch (day) {
      case "Monday":
        return setMondaySlotsList(filteredList);
      case "Tuesday":
        return setTuesdaySlotsList(filteredList);
      case "Wednesday":
        return setWednesdaySlotsList(filteredList);
      case "Thursday":
        return setThursdaySlotsList(filteredList);
      case "Friday":
        return setFridaySlotsList(filteredList);
      case "Saturday":
        return setSaturdaySlotsList(filteredList);
      case "Sunday":
        return setSundaySlotsList(filteredList);
      default:
        return [];
    }
  };

  const getDaySlotsList = (day) => {
    switch (day) {
      case "Monday":
        return mondaySlotsList;
      case "Tuesday":
        return tuesdaySlotsList;
      case "Wednesday":
        return wednesdaySlotsList;
      case "Thursday":
        return thursdaySlotsList;
      case "Friday":
        return fridaySlotsList;
      case "Saturday":
        return saturdaySlotsList;
      case "Sunday":
        return sundaySlotsList;
      default:
        return [];
    }
  };

  const handleSlotsDayTimeChange = (event) => {
    onDirty();
    let e = event.target;
    let id = e.id;
    let value = e.value;
    let name = e.name;
    let day = e.getAttribute("data-day");
    updateDaySlots(getDaySlotsList(day), name, e, id, value);
  };

  const updateDaySlots = (slots, name, e, id, value) => {
    for (let i = 0; i < slots?.length; i++) {
      if (slots[i].slot == id) {
        if (name === "startTime") {
          slots[i].startTime = value;
          checkRefProperty(slots[i], "startRef", e);
        } else if (name === "endTime") {
          slots[i].endTime = value;
          checkRefProperty(slots[i], "endRef", e);
        }
        slots[i].isValid = checkTimeValidity(slots, i);
        showRemoveorderOnValidity(slots[i]);
      }
    }
  };

  const checkRefProperty = (slotsObj, key, element) => {
    if (!slotsObj.hasOwnProperty(key)) {
      slotsObj[key] = element;
    }
  };

  const showRemoveorderOnValidity = (timeObject) => {
    if (timeObject.isValid) {
      if (timeObject?.startRef)
        timeObject.startRef.style.border = "1px solid #D9E8EC";
      if (timeObject?.endRef)
        timeObject.endRef.style.border = "1px solid #D9E8EC";
    } else {
      if (timeObject?.startRef)
        timeObject.startRef.style.border = "1px solid #FD0D1B";
      if (timeObject?.endRef)
        timeObject.endRef.style.border = "1px solid #FD0D1B";
      // props?.setScheduleTimeSLotFail(true);
    }
  };

  const checkTimeValidity = (time_array, index) => {
    if (
      time_array[index].startTime !== "" &&
      time_array[index].endTime !== ""
    ) {
      if (time_array[index].endTime <= time_array[index].startTime) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const handleProviderId = (event) => {
    event.preventDefault();
    // alert(event.target.value)
    onDirty();
    setSelectedFacilityId("")
    fetchProviderFacilities(event.target.value, user?.tenantId);
    setSelectedProviderId(event.target.value);
    let timezoneValue =
      event.target[event.target.selectedIndex].getAttribute("timezone");
    setTimezone(timezoneValue);
    // console.log("timezone", timezone);
  };

  const handleTimezone = () => {
    // let date = new Date();
    // let dateAsString = date.toString();
    // let timezone = dateAsString.match(/([A-Z]+[\+-][0-9]+.[0-9])/)[1];
    // let timezone = "A" + localStorage.getItem("timezone");

    return timezone;
    // setTimeZone(timezone);
  };

  // const checkIfAnySlotIsFill = (isEmpty, slotsData) => {
  //   for (let i = 0; i < Object.values(slotsData)?.length; i++) {
  //     if (Object.values(slotsData)[i][0].startTime !== "" && Object.values(slotsData)[i][0].endTime === "") {
  //       return true;
  //     }
  //     if (Object.values(slotsData)[i][0].startTime === "" && Object.values(slotsData)[i][0].endTime !== "") {
  //       return true;
  //     }
  //   }
  //   return isEmpty;
  // }
  // const checkIfSlotsAreEmpty = (slotsData) => {
  //   let isEmpty = false;
  //   for (let i = 0; i < Object.values(slotsData)?.length; i++) {
  //     if (Object.values(slotsData)[i][0].startTime === "" && Object.values(slotsData)[i][0].endTime === "") {
  //       isEmpty = true;
  //     } else {
  //       return false;
  //     }
  //   }
  //   return isEmpty;
  // }
  // const checkIfTimeIsCorrect = (schedule) => {
  //   let isCorrect = false;
  //   for (let i = 0; i < Object.values(schedule)?.length; i++) {
  //     if (Object.values(schedule)[i].startTime >= Object.values(schedule)[i].endTime) {
  //       isCorrect = true;
  //     }
  //   }
  //   return isCorrect;
  // }
  const formatScheduleDate = () => {
    let scheduleData = {};
    if (
      mondaySlotsList[0].startTime !== undefined &&
      mondaySlotsList[0].startTime !== ""
    ) {
      scheduleData = { ...scheduleData, monday: mondaySlotsList };
    }
    if (
      tuesdaySlotsList[0].startTime !== undefined &&
      tuesdaySlotsList[0].startTime !== ""
    ) {
      scheduleData = { ...scheduleData, tuesday: tuesdaySlotsList };
    }
    if (
      wednesdaySlotsList[0].startTime !== undefined &&
      wednesdaySlotsList[0].startTime !== ""
    ) {
      scheduleData = { ...scheduleData, wednesday: wednesdaySlotsList };
    }
    if (
      thursdaySlotsList[0].startTime !== undefined &&
      thursdaySlotsList[0].startTime !== ""
    ) {
      scheduleData = { ...scheduleData, thursday: thursdaySlotsList };
    }
    if (
      fridaySlotsList[0].startTime !== undefined &&
      fridaySlotsList[0].startTime !== ""
    ) {
      scheduleData = { ...scheduleData, friday: fridaySlotsList };
    }
    if (
      saturdaySlotsList[0].startTime !== undefined &&
      saturdaySlotsList[0].startTime !== ""
    ) {
      scheduleData = { ...scheduleData, saturday: saturdaySlotsList };
    }
    if (
      sundaySlotsList[0].startTime !== undefined &&
      sundaySlotsList[0].startTime !== ""
    ) {
      scheduleData = { ...scheduleData, sunday: sundaySlotsList };
    }
    return scheduleData;
  };
  const saveScheduleOfProvider = (e) => {
    e.preventDefault()
    let validation = false;
    let scheduleData = formatScheduleDate();
    // console.log("scheduleData: ",scheduleData);

    let slotsData = {
      provider: { userId: selectedProviderId },
      TimeZone: handleTimezone(),
      startFrom: secheduleStartDate,
      endDate: secheduleEndDate,
      schedule: scheduleData,
      tenantId: user?.tenantId,
      facilityId: selectedFacilityId
    };

    // checkIfAnySlotIsFill(checkIfSlotsAreEmpty(slotsData.Schedule), slotsData.Schedule)
    validation = validationBeforeSubmit(slotsArray, validation);
    let days =
      DateTime.fromISO(slotsData.startFrom).diff(
        DateTime.fromISO(slotsData.endDate),
        ["years", "months", "days", "hours"]
      ).days *
      -1 +
      1;
    // console.log(slotsData);
    // console.log(DateTime.fromISO(slotsData.startFrom).weekday);
    // console.log(DateTime.fromISO(slotsData.startFrom).diff(DateTime.fromISO(slotsData.endDate), ["years", "months", "days", "hours"]).days);
    if (validation) {
      validation = timeValidationBeforeSubmit(slotsArray, false, 0, days);
    }

    if (validation) {
      removeInputRefs(slotsArray);
    }
    saveProviderSchedule(slotsData, validation);
  };

  const removeInputRefs = () => {
    for (let i = 0; i < slotsArray.length; i++) {
      for (let j = 0; j < slotsArray[i].length; j++) {
        if (slotsArray[i][j].hasOwnProperty("startRef")) {
          delete slotsArray[i][j].startRef;
        }
        if (slotsArray[i][j].hasOwnProperty("endRef")) {
          delete slotsArray[i][j].endRef;
        }
      }
    }
  };

  const validationBeforeSubmit = (slotsArray, validation) => {
    for (let i = 0; i < slotsArray.length; i++) {
      for (let j = 0; j < slotsArray[i].length; j++) {
        if (
          slotsArray[i][j].startTime !== "" &&
          slotsArray[i][j].endTime !== ""
        ) {
          if (slotsArray[i][j].isValid === false) {
            return false;
          }
        }

        if (slotsArray[i][j].isValid) {
          validation = true;
        }
      }
    }
    return validation;
  };

  const timeValidationBeforeSubmit = (
    slotsArray,
    validation,
    min = 0,
    max = 0
  ) => {
    console.log(slotsArray, max, min);

    for (let i = min; i <= max - min + 1; i++) {
      console.log(slotsArray[i], i);
      for (let j = 0; j < slotsArray[i].length; j++) {
        if (slotsArray[i][j].isValid) {
          return true;
        }
      }
    }
    return validation;
  };

  const handleScheduleStartDate = (startDateValue) => {
    // alert(event.target.value)
    // console.log(startDateValue);
    onDirty();
    setScheduleStartDate(startDateValue);
    if (startDateValue !== null) {
      setNextWeekDate(
        moment(new Date(startDateValue)).add(6, "days").format("MM-DD-YYYY")
      );
      setScheduleEndDate(
        moment(startDateValue).add(6, "days").format("YYYY-MM-DD")
      );
    }
  };
  const handleScheduleEndDate = (endDateValue) => {
    onDirty();
    // alert(event.target.value)
    setScheduleEndDate(endDateValue);
  };

  const getUserTimezone = async () => {
    const httpResponse = await sendHttpRequest({
      url: "/auth/user/timezone",
      method: "GET",
    });
    if (!httpResponse.error) {
      setTimexone(httpResponse?.data?.timezone);
    } else {
      console.log(httpResponse.error);
    }
  };

  const handlefacilityId = (facilityId) => {
    setSelectedFacilityId(facilityId);
    // fecthProviderSchedule(selectedProviderId, startDate, endDate,facilityId)
  }

  useEffect(() => {
    // getUserTimezone();
    fecthAllPrescribers();
    setSelectedProviderId(props?.selectedPrescriberId);
    if (user?.role !== "PracticeAdmin") {
      fetchProviderFacilities(props?.selectedPrescriberId, user?.tenantId)
    }
  }, [props?.selectedPrescriberId]);

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          resetStates();
          props?.handleModal();
        }}
        backdropClassName="modal-backdrop"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        contentClassName="modal-border modal-shadow modal__size"
        keyboard={false}
        dialogClassName={classesStyle.root}
        scrollable
        centered
      // style={{style}}
      >
        <Modal.Header className="modal-header">
          <span
            style={{
              lineHeight: "12px",
              width: "18px",
              fontSize: "8pt",
              color: "#336383",
              position: "absolute",
              top: 15,
              right: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              unsavedWorkHandler(
                "modal",
                "There are unsaved changes. Do you wish to continue?",
                isDirty,
                () => {
                  resetStates();
                  props?.handleModal();
                },
                onPristine,
                props?.handleModal
              );
            }}
          >
            {<CloseIcon />}
          </span>
          <Modal.Title className="font-20 fw-sb">
            Publish Schedule
            <div className="" style={{ fontWeight: 400, fontSize: "12px" }}>
              {/* {"All times in: " + Date().slice(Date().indexOf("(") + 1, Date().lastIndexOf(")"))} */}
              {timezone !== undefined ? "All times in: " + timezone : ""}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            id="appointmentScheduleForm"
            onSubmit={(e) => {
              e.preventDefault();
              onPristine();
              if (
                document
                  .getElementById("appointmentScheduleForm")
                  .reportValidity() && selectedFacilityId !== ""
              ) {
                saveScheduleOfProvider(e);
              } else {
                props?.setIsTimeSlotOverlap(true);
                props?.setTimeSlotOverlapErrorMessage("Please select a facility")
              }
            }}
          >
            <div className="row mb-5">

              {/* <div className="d-flex flex-row justify-content-start"> */}
              <div
                className="col-sm-3 col-md-3"
              >
                {/* <ProviderListDropdown/> */}
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <div>
                    <label className="text--terciary b2 fw-sb">
                      Provider
                    </label>

                    <select
                      className={`form-select font-13 border--default br-10 shadow-none ${classes["custom-select"]}`}
                      aria-label="Provider select"
                      style={{
                        height: "36px",
                        // background: "#FAFCFC",
                      }}
                      required
                      onChange={(e) => handleProviderId(e)}
                      onInvalid={(e) => {
                        e.target.setCustomValidity(
                          "Please select provider from the list"
                        );
                      }}
                      onInput={(e) => {
                        e.target.setCustomValidity("");
                      }}
                      // disabled = {user.role === "staff" || user.role === "PracticeAdmin" ? false : true}
                      disabled={
                        subscriptionPrivilegeGroups[APPOINTMENTS]?.psp?.active === false ? true : false
                      }
                    >
                      <option value="" disabled selected hidden>
                        Select provider
                      </option>
                      {prescribersList !== undefined &&
                        prescribersList?.length > 0 &&
                        prescribersList?.map((prescriberItem) => {
                          return (
                            <option
                              value={prescriberItem?.userId}
                              key={prescriberItem?.userId}
                              selected={
                                props?.selectedPrescriberId ===
                                prescriberItem?.userId
                              }
                              timezone={prescriberItem?.timezone}
                            >
                              {prescriberItem?.name[0]?.prefix}{" "}
                              {prescriberItem?.name[0]?.firstName}{" "}
                              {prescriberItem?.name[0]?.lastName}{" "}
                              {RolesTextConversion(prescriberItem?.role) !==
                                undefined
                                ? "(" +
                                RolesTextConversion(
                                  prescriberItem?.role
                                ) +
                                ")"
                                : ""}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                )}
              </div>
              <div className="col-sm-3 col-md-3 mb-3">
                {isProviderFacilitiesLoading ? (
                  <Skeleton />
                ) : (
                  <>
                    <label className="text--terciary b2 fw-sb">
                      {"Facility"}
                    </label>
                    <select
                      className={`form-select font-12  border--default br-10 shadow-none input`}
                      aria-label="action select"
                      style={{
                        height: "36px",
                      }}
                      onChange={(e) => handlefacilityId(e.target.value)}
                      value={selectedFacilityId}
                    >
                      <option
                        className="text--terciary"
                        value={""}
                        key={1}
                        selected
                        disabled
                        hidden
                      >
                        {"Select Facility"}
                      </option>
                      {providerFacilities?.length > 0 ?
                        providerFacilities.map((facilityItem) => {
                          return (
                            <option
                              key={facilityItem?.facilityId}
                              id={facilityItem?.facilityId}
                              value={facilityItem?.facilityId}
                            >
                              {facilityItem?.businessName}
                            </option>
                          );
                        }) :
                        <option value={""} disabled key={"no-data"}>{"No data available"}</option>
                      }
                    </select>
                  </>
                )}
              </div>
              <div className="col-sm-3 sol-md-3">
                <label className="text--terciary b2 fw-sb">Start Date</label>
                <span
                  style={{
                    fontSize: "12px",
                    border: "1px solid #D9E8EC",
                  }}
                  className={`input-group-text p-0 pe-2 br-10 bg-white text--secondary`}
                >
                  {/* <div className="ms-2" style={{ marginTop: "0.2em" }}>
                        Start
                      </div> */}
                  <div>
                    <CalenderDatePicker
                      dobHandler={handleScheduleStartDate}
                      dateFormat="MMM d, yyyy"
                      date={secheduleStartDate}
                      minDate={joined}
                      borderColor="#fff"
                      publishSchedule={true}
                      showNextYear={true}
                      f13={true}
                      required
                    />
                  </div>
                </span>
              </div>
              <div className="col-md-3 col-sm-3">
                <label className="text--terciary b2 fw-sb">End Date</label>
                <span
                  style={{
                    fontSize: "12px",
                    border: "1px solid #D9E8EC",
                  }}
                  className={`input-group-text p-0 pe-2 br-10 bg-white text--secondary`}
                >

                  <div>
                    <CalenderDatePicker
                      dobHandler={handleScheduleEndDate}
                      dateFormat="MMM d, yyyy"
                      date={secheduleEndDate}
                      minDate={joinedEndDate}
                      borderColor="#fff"
                      publishSchedule={true}
                      showNextYear={true}
                      prescribeDrug={true}
                      f13={true}
                      required
                    />
                  </div>
                </span>

              </div>
              {/* </div> */}
              <div className="col-md-12 ps-3">
                <div className="d-flex flex-row mt-5">
                  <div className="scheduling-grid col-12 p-1">
                    <div>
                      {/* <Grid item xs={11}> */}
                      <h6 className="font-12 m-0 text--secondary">Sun</h6>
                      {/* </Grid> */}
                      <div className="scheduling-grid-col-7">
                        {sundaySlotsList.map((sundaySlot, index) => {
                          return (
                            <div key={sundaySlot.slot}>
                              <ScheduleSlotRow
                                id={sundaySlot.slot}
                                slotObject={sundaySlot}
                                handleDelete={handleDeleteDaySlots}
                                handleStartTimeChange={handleSlotsDayTimeChange}
                                handleEndTimeChange={handleSlotsDayTimeChange}
                                day="Sunday"
                              />
                            </div>
                          );
                        })}
                        <Grid
                          container
                          spacing={1}
                          justifyContent="space-between"
                          direction="row"
                        >
                          <Grid item>
                            <div
                              className="d-flex mt-1 ms-3"
                              style={{ cursor: "pointer" }}
                            >
                              {/* <AddButtonIcon */}
                              <span
                                className="font-10 fw-sb text--blue"
                                onClick={() => handleAddSundaySlot()}
                              >
                                <u>Add Row</u>
                              </span>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                    <div>
                      {/* <Grid item xs={11}> */}
                      <h6 className="font-12 fw-thin m-0 text--secondary ">
                        Mon
                      </h6>
                      {/* </Grid> */}
                      <div className="scheduling-grid-col-1">
                        {mondaySlotsList?.map((mondaySlotItem, index) => {
                          // console.log("mondaySlotItem",mondaySlotItem);
                          return (
                            <div key={mondaySlotItem.slot}>
                              <ScheduleSlotRow
                                // key = {mondaySlotItem.id}
                                id={mondaySlotItem.slot}
                                slotObject={mondaySlotItem}
                                handleDelete={handleDeleteDaySlots}
                                handleStartTimeChange={handleSlotsDayTimeChange}
                                handleEndTimeChange={handleSlotsDayTimeChange}
                                day="Monday"
                              />
                            </div>
                          );
                        })}
                        <Grid
                          container
                          spacing={1}
                          justifyContent="space-between"
                          direction="row"
                        >
                          <Grid item>
                            <div
                              className="d-flex mt-1 ms-3"
                              style={{ cursor: "pointer" }}
                            >
                              {/* <AddButtonIcon */}
                              <span
                                className="font-10 fw-sb text--blue"
                                onClick={() => handleAddMondaySlot()}
                              >
                                <u>Add Row</u>
                              </span>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                    <div>
                      {/* <Grid item xs={11}> */}
                      <h6 className="font-12 fw-thin m-0 text--secondary ">
                        Tue
                      </h6>
                      {/* </Grid> */}
                      <div className="scheduling-grid-col-2">
                        {tuesdaySlotsList.map((tuesdaySlot, index) => {
                          return (
                            <div key={tuesdaySlot.slot}>
                              <ScheduleSlotRow
                                id={tuesdaySlot.slot}
                                slotObject={tuesdaySlot}
                                handleDelete={handleDeleteDaySlots}
                                handleStartTimeChange={handleSlotsDayTimeChange}
                                handleEndTimeChange={handleSlotsDayTimeChange}
                                day="Tuesday"
                              />
                            </div>
                          );
                        })}
                        <Grid
                          container
                          spacing={1}
                          justifyContent="space-between"
                          direction="row"
                        >
                          <Grid item>
                            <div
                              className="d-flex mt-1 ms-3"
                              style={{ cursor: "pointer" }}
                            >
                              {/* <AddButtonIcon */}
                              <span
                                className="font-10 fw-sb text--blue"
                                onClick={() => handleAddTuesdaySlot()}
                              >
                                <u>Add Row</u>
                              </span>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                    <div>
                      {/* <Grid item xs={11}> */}
                      <h6 className="font-12 fw-thin m-0 text--secondary ">
                        Wed
                      </h6>
                      {/* </Grid> */}
                      <div className="scheduling-grid-col-3">
                        {wednesdaySlotsList.map((wednesdaySlot, index) => {
                          return (
                            <div key={wednesdaySlot.slot}>
                              <ScheduleSlotRow
                                id={wednesdaySlot.slot}
                                slotObject={wednesdaySlot}
                                handleDelete={handleDeleteDaySlots}
                                handleStartTimeChange={handleSlotsDayTimeChange}
                                handleEndTimeChange={handleSlotsDayTimeChange}
                                day="Wednesday"
                              />
                            </div>
                          );
                        })}
                        <Grid
                          container
                          spacing={1}
                          justifyContent="space-between"
                          direction="row"
                        >
                          <Grid item>
                            <div
                              className="d-flex mt-1 ms-3"
                              style={{ cursor: "pointer" }}
                            >
                              {/* <AddButtonIcon */}
                              <span
                                className="font-10 fw-sb text--blue"
                                onClick={() => handleAddWednesdaySlot()}
                              >
                                <u>Add Row</u>
                              </span>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                    <div>
                      {/* <Grid item xs={11}> */}
                      <h6 className="font-12 fw-thin m-0 text--secondary ">
                        Thu
                      </h6>
                      {/* </Grid> */}
                      <div className="scheduling-grid-col-4">
                        {thursdaySlotsList.map((thursdaySlot, index) => {
                          return (
                            <div key={thursdaySlot.slot}>
                              <ScheduleSlotRow
                                id={thursdaySlot.slot}
                                slotObject={thursdaySlot}
                                handleDelete={handleDeleteDaySlots}
                                handleStartTimeChange={handleSlotsDayTimeChange}
                                handleEndTimeChange={handleSlotsDayTimeChange}
                                day="Thursday"
                              />
                            </div>
                          );
                        })}
                        <Grid
                          container
                          spacing={1}
                          justifyContent="space-between"
                          direction="row"
                        >
                          <Grid item>
                            <div
                              className="d-flex mt-1 ms-3"
                              style={{ cursor: "pointer" }}
                            >
                              {/* <AddButtonIcon */}
                              <span
                                className="font-10 fw-sb text--blue"
                                onClick={() => handleAddThursdaySlot()}
                              >
                                <u>Add Row</u>
                              </span>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                    <div>
                      {/* <Grid item xs={11}> */}
                      <h6 className="font-12 fw-thin m-0 text--secondary ">
                        Fri
                      </h6>
                      {/* </Grid> */}
                      <div className="scheduling-grid-col-5">
                        {fridaySlotsList.map((fridaySlot, index) => {
                          return (
                            <div key={fridaySlot.slot}>
                              <ScheduleSlotRow
                                id={fridaySlot.slot}
                                slotObject={fridaySlot}
                                handleDelete={handleDeleteDaySlots}
                                handleStartTimeChange={handleSlotsDayTimeChange}
                                handleEndTimeChange={handleSlotsDayTimeChange}
                                day="Friday"
                              />
                            </div>
                          );
                        })}
                        <Grid
                          container
                          spacing={1}
                          justifyContent="space-between"
                          direction="row"
                        >
                          <Grid item>
                            <div
                              className="d-flex mt-1 ms-3"
                              style={{ cursor: "pointer" }}
                            >
                              {/* <AddButtonIcon */}
                              <span
                                className="font-10 fw-sb text--blue"
                                onClick={() => handleAddFridaySlot()}
                              >
                                <u>Add Row</u>
                              </span>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                    <div>
                      {/* <Grid item xs={11}> */}
                      <h6 className="font-12 fw-thin m-0 text--secondary ">
                        Sat
                      </h6>
                      {/* </Grid> */}
                      <div className="scheduling-grid-col-6">
                        {saturdaySlotsList.map((saturdaySlot, index) => {
                          return (
                            <div key={saturdaySlot.slot}>
                              <ScheduleSlotRow
                                id={saturdaySlot.slot}
                                slotObject={saturdaySlot}
                                handleDelete={handleDeleteDaySlots}
                                handleStartTimeChange={handleSlotsDayTimeChange}
                                handleEndTimeChange={handleSlotsDayTimeChange}
                                day="Saturday"
                              />
                            </div>
                          );
                        })}
                        <Grid
                          container
                          spacing={1}
                          justifyContent="space-between"
                          direction="row"
                        >
                          <Grid item>
                            <div
                              className="d-flex mt-1 ms-3"
                              style={{ cursor: "pointer" }}
                            >
                              {/* <AddButtonIcon */}
                              <span
                                className="font-10 fw-sb text--blue"
                                onClick={() => handleAddSaturdaySlot()}
                              >
                                <u>Add Row</u>
                              </span>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer className="modal-footer-header">
          <button
            type="submit"
            disabled={isScheduledPublished}
            className="btn blue_task_button px-5 py-2"
            // onClick={() => {
            //   saveScheduleOfProvider();
            // }}
            form="appointmentScheduleForm"
            style={{
              border: "#004FD4 0% 0% no-repeat padding-box",
              width: "160px",
              height: "44px",
            }}
          >
            {isScheduledPublished ? "Saving..." : "Save"}
          </button>
        </Modal.Footer>
      </Modal>
      {/* {console.log("monCount",monCount)} */}
      {/* {console.log("mondaySlotsList",mondaySlotsList)}
      {console.log("tuesdaySlotsList",tuesdaySlotsList)} */}
      {/* {console.log("sundaySlotsList",sundaySlotsList)} */}


    </>
  );
};

export default AppointmentScheduleModal2;

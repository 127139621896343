import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import { deleteConfirmationStrings } from "../../../res/strings";

const DeleteLabOrderConfirmationModal = (props) => {

    const __str = deleteConfirmationStrings.en;
    return (
        <Modal
            show={props?.show}
            onHide={() => {
                props.handleModal();
            }}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            backdropClassName="modal-backdrop"
            contentClassName={`modal-border modal-shadow `}
            scrollable={false}
            centered
        >
            <Modal.Header  >
                <span
                    style={{
                        lineHeight: "12px",
                        width: "18px",
                        fontSize: "8pt",
                        color: "#336383",
                        position: "absolute",
                        top: 15,
                        right: 15,
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        props?.handleModal()
                    }}
                >
                    {<CloseIcon />}
                </span>
                <Modal.Title className="font-20 fw-sb">{props?.deleteMedication !== true ? "Delete Lab Orders" : "Delete Medications"} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row  justify-content-center">
                        <div className="col-12">
                            <span className="font-14 text--terciary">{props?.deleteMedication !== true ? __str.lab_Order_delete : __str.medication_delete}</span>
                            {/* <ul className="list-unstyled">
                                {props?.medications?.map((medication, index) => {
                                    return <li key={index}>{medication?.drugDescription}</li>
                                })}
                            </ul> */}
                            <div className="text-center">

                                <button
                                    className={`btn fw-sb b1 ${props?.areLabOrdersDeleting ? "disabled" : ""} btn--border text--blue bg--lightGreen px-4 br-10 py-1`}
                                    onClick={() => {
                                        props.handleModal();
                                    }}
                                >
                                    No
                                </button>
                                <button className={`btn ${props?.areLabOrdersDeleting ? "disabled" : ""} btn--danger shadow-none text-white btn--border--red ms-3 px-4 py-1 br-10 b1`}
                                    disabled={props?.areLabOrdersDeleting}
                                    onClick={() => props.handleDelete(props?.labOrderId)}
                                >
                                    Yes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DeleteLabOrderConfirmationModal
import { Box, Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import classes from "./PatientItem.module.css";
import {
  changeDateTimeFormat,
  dateTimeHelper,
} from "../../utils/dateTimeHelper";
import { ReactComponent as ViewSummary } from "../../../src/assets/icons/Icon-Preview-Summary.svg";
import { ReactComponent as AddAddendum } from "../../../src/assets/icons/Icon-Add-Addendum.svg";
import sendHttpRequest from "../hooks/sendHttpRequest";
import {
  setAllPhysicalSystemsStatus,
  setDiagnosis,
  setDisableSendRxButton,
  setPatientEncounterData,
  setPrescriptionNoteText,
  setUpdateEncounterData,
  updatePrescribedMedication,
} from "../../redux/actions/providerActions";
import { truncate } from "../../utils/helpers/HelperFunctions";
import { encounterSummaryUrl } from "../../res/endpointurls";
import { PnToolTip } from "../UI/Reusable/PnComponents";
import { VideoCallContext } from "../UI/AgoraHooks/VideoCallContext";
import { useFlags } from "launchdarkly-react-client-sdk";
import PaymentsIcon from "@mui/icons-material/Payments";
import useClaimsSubmission from "../hooks/useClaimsSubmission";
import { useAuth } from "../hooks/useAuth";
import { ENCOUNTERS } from "../../utils/helpers/subscriptionsConstants";

const EncounterItem = (props) => {
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);


  const navigate = useNavigate();
  const dispatch = useDispatch();
  const patient = props?.prescriptionReference?.patient;
  const patientName = props?.prescriptionReference?.patient?.name[0];
  const pEncounter = props?.prescriptionReference?.encounter;
  const videoCallContext = useContext(VideoCallContext);
  const [encounter, setEncounter] = useState("");
  const [isLoadingEnc, setIsLoadingEnc] = useState(false);
  const appointmentType =
    props?.prescriptionReference?.appointment?.appointmentType?.key;
  const signEncStatus =
    props?.prescriptionReference?.encounter?.status?.toLowerCase();
  const { user } = useAuth();
  const patientVisitType =
    props?.prescriptionReference?.appointment?.patientVisitType?.displayValue ??
    "";

  //=======Claims Submission=======//
  const { claimSubmission } = useFlags();
  const { navigateToClaimsSubmissionPage } = useClaimsSubmission();

  let createdDateTime = props?.createdDateTime;
  let showEncounterActionButtons = props?.showEncounterActionButtons;

  const fetchPatientEncounter = async (encId) => {
    setIsLoadingEnc(false);
    const httpResponse = await sendHttpRequest({
      url: encounterSummaryUrl(encId),
      method: "GET",
    });
    if (!httpResponse.error) {
      let encounterData = httpResponse.data.appointment;
      // console.log("Encounter Data", encounterData);
      // console.log("httpResponse", httpResponse.data);
      videoCallContext.updateAppointmentData(httpResponse.data.appointmentId);
      videoCallContext.updateAppointmentObject(httpResponse.data);
      setEncounter({
        ...encounterData,
        prescriptionReference: { ...httpResponse.data },
      });
      handlePatientEncouterData({
        ...encounterData,
        prescriptionReference: { ...httpResponse.data },
      });
    } else {
      console.log(httpResponse.error);
    }
    setIsLoadingEnc(true);
  };

  const handleEncData = () => {
    props?.handleToggle();
    handleEncounterData(props?.prescriptionReference?.prescriptionReferenceId);
  };

  const handleEncounterData = (encId) => {
    fetchPatientEncounter(encId);
    // fetchPrescribedMedication(encId)
    dispatch(updatePrescribedMedication([]));
  };

  const handlePatientEncouterData = (data) => {
    // console.log("Data in encounter item: ", data);
    dispatch(setPrescriptionNoteText(""));

    let diagnosis = {
      Note: "",
      Secondary: [],
      Primary: [],
    };
    if (
      data?.prescriptionReference?.patientChart?.diagnosis !== undefined &&
      data?.prescriptionReference?.patientChart?.diagnosis !== null &&
      data?.prescriptionReference?.patientChart?.diagnosis?.length > 0
    ) {
      diagnosis.Note =
        data?.prescriptionReference?.patientChart?.diagnosis[0]?.note;
      data?.prescriptionReference?.patientChart?.diagnosis.forEach(
        (diagnosisItem) => {
          if (diagnosisItem?.type?.toLowerCase() === "p") {
            diagnosis.Primary = [diagnosisItem];
          } else {
            diagnosis.Secondary.push(diagnosisItem);
          }
        }
      );
    }

    dispatch(setUpdateEncounterData({ Diagnosis: diagnosis }));
    dispatch(setDiagnosis(diagnosis));
    dispatch(
      setPatientEncounterData({
        ...data,
        prescriptionReference: {
          ...data?.prescriptionReference,
          encounter: {
            ...data?.prescriptionReference?.encounter,
            notes: data?.prescriptionReference?.encounter?.notes || "",
          },
        },
      })
    );
    dispatch(
      setAllPhysicalSystemsStatus(
        data?.prescriptionReference?.encounter?.allSystemsOk
      )
    );
    if (data?.prescriptionReference?.encounter?.status === "DISPATCHED") {
      dispatch(setDisableSendRxButton(true));
    } else {
      dispatch(setDisableSendRxButton(false));
    }
    // console.log(
    //   "Going In if condition",
    //   props?.prescriptionReference?.prescriptionReferenceId,
    //   // appointmentType
    // );
    if (
      (appointmentType?.toLowerCase() === "follow_up" || appointmentType === undefined) &&
      props?.prescriptionReference?.encounter?.encounterNoteType === "SOAP_NOTE"
    ) {
      // console.log({ "props?.prescriptionReference?.appointment?.id,": props?.prescriptionReference?.appointment?.id, "prescriptionReference": props?.prescriptionReference })
      props?.handleBackdropClose();
      navigate(
        `/encounter/${props?.prescriptionReference?.patient?.userId}/soap-note`,
        {
          state: {
            isResume: props?.prescriptionReference?.soapNote !== undefined ? true : false,
            // isResume:  true,
            resource: "encounter",
            prescriptionReferenceId:
              props?.prescriptionReference?.prescriptionReferenceId,
            appointmentId: props?.prescriptionReference?.appointment?.id,
          },
          replace: false,
        }
      );
    } else {
      props?.handleBackdropClose();
      navigate(
        `/consult/${props?.prescriptionReference?.providerTenantPracticeLocation?.provider?.userId}/${props?.prescriptionReference?.prescriptionReferenceId}/${props?.prescriptionReference?.patient?.userId}`,
        {
          state: {
            prescriptionReferenceId:
              props?.prescriptionReference?.prescriptionReferenceId,
            referringPhysician: props?.prescriptionReference?.appointment?.referringPhysician
          },
        }
      );
    }

    // dispatch(setDiagnosis(diagnosis));
    // dispatch(setVideoCallState(false));
  };

  // console.log(props?.prescriptionReference);
  // console.log(props?.prescriptionReference?.encounter)

  return (
    <Grid
      sx={{ marginBottom: "-8px" }}
      container
      p={1}
      alignItems="center"
      className={`${classes["row--border"]} mt-2  ${props?.isEven ? "bg--background" : ""
        } ${props?.isLast ? classes["border--bottom"] : ""}`}
      hidden={
        signEncStatus === "closed"
          ? subscriptionPrivilegeGroups[ENCOUNTERS]?.vse?.active === false
            ? true
            : false
          : props?.hidden
      }
    >
      <Grid item xs={1.2}>
        <Typography component="div">
          <Box
            sx={{
              textAlign: "left",
              // m: 1,
              fontFamily: "Montserrat",
              fontSize: "12px",
              color: "#336383",
            }}
          >
            {/* {props?.encounter?.CreatedDate ? props?.encounter?.CreatedDate : "Not Available"} */}
            {createdDateTime
              ? dateTimeHelper(
                createdDateTime || createdDateTime,
                user?.tenant?.dateFormat
              )
              : "Not"}
            <br />
            {createdDateTime
              ? dateTimeHelper(createdDateTime || createdDateTime, "LT")
              : " Available"}
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={1.4}>
        <Typography component="div">
          <PnToolTip
            title={patientName?.firstName + " " + patientName?.lastName}
          >
            <Box
              sx={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "12px",
                color: "#323232",
                textAlign: "left",
              }}
            >
              {truncate(
                patientName?.firstName + " " + patientName?.lastName,
                20
              )}
            </Box>
          </PnToolTip>
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography component="div">
          <Box
            sx={{
              textAlign: "left",
              // ml: 2,
              fontFamily: "Montserrat",
              fontSize: "12px",
              color: "#336383",
            }}
          >
            {patient?.dateOfBirth
              ? changeDateTimeFormat(
                patient?.dateOfBirth,
                user?.tenant?.dateFormat
              )
              : "Not Available"}
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography component="div">
          <Box
            sx={{
              textAlign: "left",
              fontFamily: "Montserrat",
              // ml: 2,
              fontSize: "12px",
              color: "#336383",
            }}
          >
            {patient?.gender ?? "Not Available"}
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography component="div">
          <Box
            sx={{
              textAlign: "left",
              // ml: 4,
              fontFamily: "Montserrat",
              fontSize: "12px",
              color: "#336383",
            }}
          >
            {props?.prescriptionReference?.encounter?.chiefComplaint ? (
              <PnToolTip
                title={props?.prescriptionReference?.encounter?.chiefComplaint}
              >
                <span>
                  {truncate(
                    props?.prescriptionReference?.encounter?.chiefComplaint,
                    20
                  )}
                </span>
              </PnToolTip>
            ) : (
              "Not Available"
            )}
            {/* {(props?.encounter?.ChiefComplaint ?? "Not Available")} */}
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={1.5}>
        <Typography component="div">
          <Box
            sx={{
              textAlign: "left",
              // m: 1,
              fontFamily: "Montserrat",
              fontSize: "12px",
              color: "#336383",
            }}
          >
            {/* {props?.prescriptionReference?.encounter?.encounterNoteType !== "PROVIDER_NOTE" ? props?.prescriptionReference?.encounter?.encounterType : ""} */}
            {/* {props?.prescriptionReference?.encounter?.encounterNoteType !==
              "PROVIDER_NOTE"
              ? patientVisitType === "In-Patient" ? "In-Person" : patientVisitType
              : ""} */}
            {props?.prescriptionReference?.encounter?.encounterNoteType ===
              "PROVIDER_NOTE"
              ? "Provider Note"
              : props?.prescriptionReference?.encounter?.encounterNoteType ===
                "SOAP_NOTE"
                ? "Soap Note"
                : props?.prescriptionReference?.encounter?.encounterNoteType ===
                  "ENCOUNTER"
                  ? "Chart Note"
                  : ""
            }
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={1.5}>
        <Typography component="div">
          <Box
            sx={{
              textAlign: "left",
              // m: 1,
              fontFamily: "Montserrat",
              fontSize: "12px",
              color: "#336383",
            }}
          >
            {props?.prescriptionReference?.encounter?.encounterNoteType !==
              "PROVIDER_NOTE"
              ? props?.prescriptionReference?.encounter?.status?.toLowerCase() ===
                "closed"
                ? "Signed"
                : props?.prescriptionReference?.encounter?.status?.toLowerCase() ===
                  "started" ||
                  props?.prescriptionReference?.encounter?.status?.toLowerCase() ===
                  "dispatched"
                  ? "Unsigned"
                  : "" ?? "Not Available"
              : ""}
          </Box>
        </Typography>
      </Grid>
      {props?.showEncounterActionButtons === true ? (
        props?.prescriptionReference?.encounter?.status?.toLowerCase() ===
          "closed" ? (
          <div className={`mx-auto`}>
            {/* <div className="align-self-center"> */}
            <PnToolTip title="Patient Encounter Note" arrow placement="top">
              <button
                style={{
                  backgroundColor: "#FAFCFC",
                  border: "2px solid #d9e8ec",
                }}
                onClick={() => {
                  props?.handleModal(
                    props?.prescriptionReference?.prescriptionReferenceId ?? ""
                  );
                  props?.handleToggle();
                  props.setAddEncounterId({
                    encounter: props?.prescriptionReference,
                    isSummary: true,
                  });
                }}
                className="btn shadow-none br-10"
                hidden={subscriptionPrivilegeGroups[ENCOUNTERS]?.ves?.active === false ? true : false}
              >
                <ViewSummary
                  style={{
                    color: "#336383",
                    fill: "#336383",
                    width: 15,
                    height: 15,
                  }}
                />
              </button>
            </PnToolTip>
            {props?.prescriptionReference?.encounter?.encounterNoteType !==
              "PROVIDER_NOTE" ? (
              <PnToolTip title="Show Addendums" arrow placement="top">
                <button
                  style={{
                    backgroundColor: "#FAFCFC",
                    border: "2px solid #d9e8ec",
                  }}
                  onClick={() => {
                    props?.handleAddenShowModal(
                      props?.prescriptionReference?.encounter?.encounterId ?? ""
                    );
                    props.setAddEncounterId({
                      encounter: props?.prescriptionReference,
                      isSummary: false,
                    });
                    props?.handleToggle();
                  }}
                  className="btn shadow-none mx-1 br-10"
                  hidden={subscriptionPrivilegeGroups[ENCOUNTERS]?.vae?.active === false ? true : false}
                >
                  <AddAddendum
                    style={{
                      color: "#336383",
                      fill: "#336383",
                      width: 15,
                      height: 15,
                    }}
                  />
                </button>
              </PnToolTip>
            ) : null}

            {/* {claimSubmission && props?.prescriptionReference?.encounter?.encounterNoteType !==
              "PROVIDER_NOTE" ? (
              <PnToolTip title="Create Superbill" arrow placement="top">
                <button
                  style={{
                    backgroundColor: "#FAFCFC",
                    border: "2px solid #d9e8ec",
                  }}
                  onClick={() => {
                    navigateToClaimsSubmissionPage(
                      props?.prescriptionReference?.prescriptionReferenceId,
                      props?.prescriptionReference?.encounter?.encounterId,
                      props?.prescriptionReference?.patient?.userId
                    );
                  }}
                  className="btn shadow-none mx-1 br-10"
                
                >
                  <PaymentsIcon
                    style={{
                      color: "#336383",
                      fill: "#336383",
                      width: 15,
                      height: 15,
                    }}
                  />
                </button>
              </PnToolTip>
            ) : null} */}
            {/* <PnToolTip title="Add Addendums" arrow placement="top">
            <button
              style={{
                backgroundColor: "#FAFCFC",
                border: "2px solid #d9e8ec",
              }}
              onClick={() => {
                props?.handleAddenModal(props?.prescriptionReference?.encounter?.encounterId ?? "");
              }}
              className="btn shadow-none br-10"
            >
              <ViewAddendum style={{
                color: "#336383", fill: "#336383", width: 15,
                height: 15
              }} />
            </button>
          </PnToolTip> */}
          </div>
        ) : (
          <div className="mx-auto">
            {/* {isLoadingEnc && (appointmentType !== undefined && appointmentType !== 'FOLLOW_UP') &&
                (props?.handleBackdropClose(),
                  navigate(
                    `/consult/${props?.prescriptionReference?.providerTenantPracticeLocation?.provider?.userId}/${props?.prescriptionReference?.prescriptionReferenceId}/${props?.prescriptionReference?.patient?.userId}`, { state: { prescriptionReferenceId: props?.prescriptionReference?.prescriptionReferenceId } }
                  )
                )
              } */}
            {/* {isLoadingEnc && ((appointmentType === 'FOLLOW_UP' || appointmentType === undefined) && props?.prescriptionReference?.encounter?.encounterNoteType === "SOAP_NOTE") &&
                (props?.handleBackdropClose(),
                  navigate(
                    `/all-patients/${props?.prescriptionReference?.patient?.userId}/soap-note`, { state: { isResume: true, resource:"encounter", prescriptionReferenceId: props?.prescriptionReference?.prescriptionReferenceId }, replace: false }
                  )
                )
              } */}
            <button
              className="btn btn-sm blue_task_button"
              // onClick={() => handlePatientEncouterData()
              disabled={props.disabled}
              onClick={() => handleEncData()}
              hidden={subscriptionPrivilegeGroups[ENCOUNTERS]?.rpe?.active === false ? true : false}
            >
              Resume
            </button>
          </div>
        )
      ) : null}
    </Grid>
  );
};

export default EncounterItem;

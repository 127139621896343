import { useEffect, useRef } from "react";
import styles from "./PatientProfileVitals.module.css";
export default function InputWithGroup(props) {
    const inputRef = useRef(null);

    useEffect(() => {
        const handleWheel = (event) => {
            event.preventDefault();
        }
        const inputElement = inputRef.current;
        inputElement.addEventListener("wheel", handleWheel, { passive: false });
        return () => {
            inputElement.removeEventListener("wheel", handleWheel);
        }
    }, []);
    return (
        <div className={styles.positiveInputContainer}>
            {props.label === "SpO2" ? <span className="font-10 text-start fw-thin text--secondary" {...(props.label === "SpO2" && { dangerouslySetInnerHTML: { __html: "SpO<sub>2</sub>" } })} >

            </span> : <span className="font-10 text-start fw-thin text--secondary">
                {props.label}
            </span>}
            <div className={styles.positiveInputWrapper}>
                <input
                    type="number"
                    min="0"
                    step="any"
                    name={props.name}
                    value={props.value}
                    {...(props?.disabled && { disabled: true })}
                    onChange={props.handleChange}
                    className={styles.positiveInput}
                    ref={inputRef}
                />
                <span className={styles.unit}>{props.unit}</span>
            </div>
        </div>
    );
}
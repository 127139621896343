import PageHeader from "../../patientportal/generic/PageHeader";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { ThemeProvider } from "@mui/material/styles";
import Sticky from "react-stickynode";
import { useState } from "react";
import { TabPanel, theme } from "../UI/Reusable/PnComponents";
import PatientListReports from "./PatientListReports";

const ReportsSearchPage = (props) => {
  const [tabsList, setTabsList] = useState([
    "Patient List",
    "Bulk Letter Dashboard",
    "Clinical Quality Measures",
  ]);
  const [value, setValue] = useState(0);

  const TabsMap = function (tabTitle) {
    const tabsMap = new Map();

    tabsList.forEach((element, index) => {
      tabsMap.set(element, index);
      tabsMap.set(index, element);
    });
    return tabsMap.get(tabTitle);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <PageHeader title="Reports" />
      <div className="container-fluid ">
        <div className="row ">
          <div className="col-12 g-0 justify-content-center">
            <ThemeProvider theme={theme}>
              <Sticky enabled={true} top={70} innerZ={20} bottomBoundary={1200}>
                <div
                  id="header-tabs-box"
                  style={{
                    backgroundColor: "white",
                    borderBottom: "1px solid #d9e8ec",
                  }}
                >
                  <Tabs
                    indicatorColor="secondary"
                    value={value}
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={handleChange}
                    style={{
                      backgroundColor: "white",
                    }}
                  >
                    {tabsList.map((item, key) => {
                      return (
                        <Tab
                          key={key}
                          label={TabsMap(key)}
                          style={{
                            fontSize: "12px",
                            fontWeight: "600",
                            // font:'normal normal 600 14px/47px Montserrat',
                            opacity: "1",
                            textTransform: "initial",
                            // color: `${key == tabsList.length - 1 ? "#dddddd" : "#336383"
                            //   }`,
                          }}
                          // disabled={key == tabsList.length - 1 ? true : false}
                        />
                      );
                    })}
                  </Tabs>
                </div>
              </Sticky>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <TabPanel
                value={value}
                index={TabsMap("Patient List")}
                className="bg--background pb-5"
              >
                
                <PatientListReports />
                    
              </TabPanel>
            </ThemeProvider>
          </div>
        </div>
      </div>
    </>
  );
};
export default ReportsSearchPage;

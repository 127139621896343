import { Modal } from "react-bootstrap";
import { unsavedWorkHandler } from "../../../utils/helpers/HelperFunctions";
import CloseIcon from "@mui/icons-material/Close";
import CustomDatePicker from "../General/CustomDatePicker";
import { useState } from "react";
import sendHttpRequest from "../../hooks/sendHttpRequest";
import { useNavigate } from "react-router-dom";
import classes from "./ConfirmDateOfBirthModal.module.css";
import { DateTime } from "luxon";
import CalenderDatePicker from "../General/CalenderDatePicker";

const ConfirmDateOfBirthModal = (props) => {
  const navigate = useNavigate();
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [savingButton, setSavingButton] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");

  const postNewPassword = async () => {
    setSavingButton(true);
    const httpResponse = await sendHttpRequest({
      url: props.url,
      method: "POST",
      data: {
        password: props?.password,
        key: props?.username,
        EmailConsent: props?.EmailConsent,
        dob: dateOfBirth,
      },
    });
    if (!httpResponse.error) {
      navigate("/login", { replace: true });
      props?.handleModal();
    } else {
      if(httpResponse.status === 400){
        setErrorResponse(httpResponse?.errorMessage?.message);
        props.setActivationError(httpResponse?.errorMessage?.message);
      }
      else if(httpResponse.status === 403){
        props?.setDisableButton(true)
        // props?.validateKey();
        props?.setIsLinkValid(false);
        props.handleModal();
        props.setActivationError(httpResponse?.errorMessage?.message);
      }
      
    }
    setSavingButton(false);
  };

  const handleDateOfBirth = (dob) => {
    setDateOfBirth(dob);
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          props?.validateKey();
          props.handleModal();

        }}
        // size = "xl"
        backdrop="static"

        backdropClassName="modal-backdrop"
      contentClassName="modal-border modal-shadow"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <span
            style={{
              lineHeight: "12px",
              width: "18px",
              fontSize: "8pt",
              color: "#336383",
              position: "absolute",
              top: 15,
              right: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              //   unsavedWorkHandler(
              //     "modal",
              //     "There are unsaved changes. Do you wish to continue?",
              //     isDirty,
              //     props.handleModal,
              //     onPristine,
              //     );
              props?.validateKey();
              props.handleModal();
            }}
          >
            {<CloseIcon />}
          </span>
          <Modal.Title className="font-20 fw-sb text--terciary">
            Enter Date of Birth
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            id="confirmDateOfBirth"
            onSubmit={(e) => {
              e.preventDefault();
              // props.handleModal();
              postNewPassword();
            }}
          >
            <div className="row container-fluide px-4">
              <div className=" col-12 mt-3  d-flex flex-column  align-items-center justify-content-between">
                <div className="">
                  <div className="font-16 text--danger">{errorResponse}</div>
                  <label
                    htmlFor="dob"
                    className="form-label m-0 font-12 text--secondary fw-sb"
                  >
                    Date of Birth <span>&#42;</span>
                  </label>
                  <div style={{ width: "15vw" }}>
                    <CalenderDatePicker
                      required={"true"}
                      dobHandler={handleDateOfBirth}
                      dateFormat="MM/dd/yyyy"
                      maxDate={DateTime.now()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="submit"
            form="confirmDateOfBirth"
            style={{ height: "44px" }}
            className={`btn bg--blue text-white font-14 px-4 br-10 py-1 shadow-none`}
            disabled={props?.disableButton}
          >
            {savingButton ? "Saving..." : "Continue"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmDateOfBirthModal;

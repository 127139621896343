import React from 'react'
import PatientNavbar from '../../components/UI/PatientNavbar'
import MessageTabs from '../../components/UI/Tabs/MessageTabs'

const PatientMessagesPage = () => {
  return (
    <>
      {/* <PatientNavbar /> */}
      <div>
            <MessageTabs patientPortal = {true} />
      </div>
    </>
  )
}

export default PatientMessagesPage
import { useEffect, useState } from "react";
import sendHttpRequest from "../../../components/hooks/sendHttpRequest";
import { useAuth } from "../../../components/hooks/useAuth";
import { fetchInvoiceDetailURL, fetchInvoicesURL } from "../../../res/endpointurls";
import { Skeleton } from "@mui/material";
import { calenderDateTimeHelper } from "../../../utils/dateTimeHelper";
import BillingCardContainer from "./BillingCardContainer";
import classes from "./InvoiceDetailItems.module.css";
import { currencyFormatter, truncate } from "../../../utils/helpers/HelperFunctions";
import { PnToolTip } from "../../../components/UI/Reusable/PnComponents";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import { useSelector } from "react-redux";
import { number } from "card-validator";

export const InvoiceDetailItem = (props) => {
  const patientInsurances = useSelector(
    (state) => state.patientInsuranceData.patientInsurance
  );
  const { user } = useAuth();
  const [invoiceDetail, setInvoiceDetail] = useState()
  const [isInvoiceDetailLoading, setIsInvoiceDetailLoading] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [primaryInsurance, setPrimaryInsurance] = useState();
  const [claimedCoveredAmountSum, setClaimedCoveredAmountSum] = useState(0);
  const [transactionAmountSum, setTransactionAmountSum] = useState(0);


  let providerPrefix =
    props?.invoicesItem?.providerTenantPracticeLocation?.provider?.name[0]?.prefix ??
    "";
  let providerFirstName =
    props?.invoicesItem?.providerTenantPracticeLocation?.provider?.name[0]
      ?.firstName ?? "";
  let providerLasttName =
    props?.invoicesItem?.providerTenantPracticeLocation?.provider?.name[0]?.LastName ??
    "";
  let qualification =
    props?.invoicesItem?.providerTenantPracticeLocation?.provider?.qualification ?? "";
  let patientFirstName = props?.invoicesItem?.patient?.name[0]?.firstName;
  let patientLastName = props?.invoicesItem?.patient?.name[0]?.lastName;


  const fetchInvoiceDetail = async () => {
    setIsInvoiceDetailLoading(true);
    const httpResponse = await sendHttpRequest({
      url: fetchInvoiceDetailURL(user?.userId, props.invoicesItem?.patientBillId),
      method: "GET"
    })

    if (!httpResponse.error) {
      setInvoiceDetail(httpResponse.data);
      let total = httpResponse.data?.serviceBills?.reduce((sum, transaction) => { return sum + parseFloat(transaction.claimCoveredAmount) }, 0)
      if(Number(total) > 0) {
        total = -total;
      }
      setClaimedCoveredAmountSum(total);
    }
    setIsInvoiceDetailLoading(false);
  }

  const handleInvoiceDetail = () => {
    setShowDetail(!showDetail);
    if (invoiceDetail === undefined) {
      fetchInvoiceDetail();
    }
  }
  function sumTransactions(transactions) {
    const total = transactions?.reduce((sum, transaction) => {
      if (transaction.transactionType.toLowerCase() !== "claimed") {
        return sum + parseFloat(transaction.transactionAmount);
      }
      return sum;
    }, 0);

    return -total; // Return the sum in negative form
  }
  function getClaimedTransactionAmount(transactions) {
    return transactions?.reduce((total, transaction) => {
      if (transaction.transactionType.toLowerCase() === "claimed") {
        return total + parseFloat(transaction.transactionAmount);
      }
      return Number(total) > 0 ? -total : total;
    }, 0);
  }


  useEffect(() => {
    let pi = patientInsurances.find((item) => { return item.type === "Primary" })
    setPrimaryInsurance(pi);

    let transactionSum = props?.invoicesItem?.patientBillAppointmentTransactionHistory?.reduce((sum, transaction) => { if(transaction?.transactionType !== "Claimed"){return  sum + parseFloat(transaction?.transactionAmount)}else {
      return sum;
    }  }, 0)
    if ( Number(transactionSum) > 0) {
      transactionSum = -transactionSum;
    }
    setTransactionAmountSum(transactionSum);
    if (props?.mostRecent === true) {
      setShowDetail(true);
      fetchInvoiceDetail();
    }
  }, [])
  return (
    <>
      <div className="row">
        <div className="col-2">
          <span className="font-12 text--secondary fw-sb">
            {calenderDateTimeHelper(props?.invoicesItem?.createdDate, "MMM DD, YYYY")}
          </span>
        </div>

        <div className="col-6">
          <div className="row">
            <div className="col-6">
              <span className="font-12 text--terciary fw-sb d-block">
                {props?.invoicesItem?.serviceDescription}
              </span>
              <span className="font-12 text--terciary d-block">
                {"Physician Services"}
              </span>
              <span className="font-12 text--terciary d-block">
                {"Provider: ".concat(
                  providerPrefix,
                  providerFirstName,
                  " ",
                  providerLasttName,
                  "(",
                  qualification,
                  ")"
                )}
              </span>
              <span className="font-12 text--terciary d-block">
                {"Patient: ".concat(patientFirstName, " ", patientLastName)}
              </span>
              <span className="font-12 text--terciary d-block">
                {"Primary Payer: ".concat(
                  props?.invoicesItem?.providerTenantPracticeLocation?.facility?.businessName ?? ""
                )}
              </span>
            </div>
          </div>
        </div>

        <div className="col-2">
          <div className="row">
            <div className="col-12">
              <span className="d-block">&nbsp;</span>
              <span className="font-12 text--terciary d-block">{"Billed"}</span>
              <span className="font-12 text--secondary d-block">{"Insurance Covered"}</span>
              <span className="font-12 text--secondary d-block">{"You Paid"}</span>
              <span className="font-12 text--terciary fw-sb d-block">{"Your Balance"}</span>
            </div>
          </div>
        </div>

        <div className="col-2">
          <div className="row">
            <div className="col-10 text-end">
              <span
                className={`${props?.invoicesItem?.paymentStatus?.toLowerCase() === "paid"
                  ? classes["paid"]
                  : classes["un-paid"]
                  } font-10 fw-sb`}
              >
                {props?.invoicesItem?.paymentStatus?.toLowerCase() === "paid" ? "Paid Off" : "Pending"}
              </span>
              <span className="font-12 text--terciary d-block">
                {currencyFormatter("USD", props?.invoicesItem?.amountBilled)}
              </span>
              <span className="font-12 text--secondary fw-sb d-block">
                {currencyFormatter(
                  "USD",
                  getClaimedTransactionAmount(props?.invoicesItem?.patientBillAppointmentTransactionHistory)
                )}
              </span>
              <span className="font-12 text--secondary fw-sb d-block">
                {currencyFormatter(
                  "USD",
                  sumTransactions(props?.invoicesItem?.patientBillAppointmentTransactionHistory)
                )}
              </span>
              <span className="font-12 text--terciary fw-sb d-block">
                {currencyFormatter("USD", props?.invoicesItem?.outstandingBalance)}
              </span>
            </div>
            <div className="col-2"></div>
          </div>
        </div>

        <div className="col-12" hidden={!showDetail}>
          {isInvoiceDetailLoading ? (
            <Skeleton />
          ) : (
            <>
              <div className="row mt-2 border--top">
                <div className="col-2"></div>
                <div className="col-10">
                  <span className="font-14 text--secondary fw-sb">{"Detailed Account Information"}</span>
                </div>
                <div className="row ms-1">
                  {invoiceDetail?.serviceBills.map((item, index) => (
                    <>
                      <div className="col-2"></div>
                      <div className="col-6 text--terciary font-12">
                        <PnToolTip title={item?.description}>
                          <span>{truncate(item?.description,100).concat(" - ", item?.code)}</span>
                        </PnToolTip>
                      </div>
                      <div className="col-2 text--terciary font-12">
                        <span>{currencyFormatter("USD", -item?.claimCoveredAmount)}</span>
                      </div>
                      <div className="col-2"></div>
                    </>
                  ))}
                  <div className="col-2"></div>
                  <div className="col-10">
                    <span className="font-12 fw-sb text--terciary">{"Payments and Adjustments"}</span>
                  </div>
                  <div className="col-2"></div>
                  <div className="col-6">
                    <span className="font-12 text--terciary">
                      {primaryInsurance?.insuranceName ?? ""}
                    </span>
                  </div>
                  <div className="col-4 text--terciary font-12">
                    <span>{currencyFormatter("USD", claimedCoveredAmountSum)}</span>
                  </div>
                  <div className="col-2"></div>
                  <div className="col-6">
                    <span className="font-12 text--terciary">
                      {props?.invoicesItem?.patientBillAppointmentTransactionHistory?.at(0)?.isCopay
                        ? "Copay Payment"
                        : "You Paid"}
                    </span>
                  </div>
                  <div className="col-4 text--terciary font-12">
                    <span>{currencyFormatter("USD", transactionAmountSum)}</span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div
          className="col-12 text-center border--top mt-1 cursor--pointer"
          onClick={handleInvoiceDetail}
        >
          <div className="d-flex flex-row justify-content-center align-items-center">
            <div style={{ paddingTop: '16px' }} className="font-12  fw-sb text--secondary">
              {showDetail ? "Hide Details" : "View Details"}
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

const InvoiceDetailItems = (props) => {
  const { user } = useAuth();
  const [invoicesData, setInvoiceData] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);

  // Pagination states
  const [pages, setPages] = useState([]);
  const [pageNumberLimit, setPageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [maxPageLimit, setMaxPageLimit] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(undefined);

  const fetchInvoicesData = async (currentPage) => {
    setIsDataLoading(true);
    const httpResponse = await sendHttpRequest({
      url: fetchInvoicesURL(user.userId),
      method: "GET",
      params: {
        size: 5,
        page: currentPage - 1 ?? 0,
        sort: 'createdDate,desc'
      },
    });
    if (!httpResponse.error) {
      setInvoiceData(httpResponse.data);

      let noOfPages = httpResponse?.data?.totalPages;
      setTotalResults(noOfPages);
      setCurrentItems(httpResponse.data?.content);
      setMaxPageLimit(noOfPages);
      let pagesList = [];
      for (let i = 1; i <= noOfPages; i++) {
        pagesList.push(i);
      }
      // console.log("pagesList : ",pagesList);
      setPages(pagesList);
    }
    setIsDataLoading(false);
  };

  // Pagination Handlers
  const RenderPages = () => {
    return (
      <ul className={`${classes["pageNumbers"]}`}>
        {pages?.length > 1 &&
          pages?.map((number) => {
            if (
              number < maxPageNumberLimit + 1 &&
              number > minPageNumberLimit
            ) {
              return (
                <li
                  key={number}
                  id={number}
                  className={`${classes["list__item"]} ${currentPage === number ? classes["active__list__item"] : ""
                    }`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleCurrentPage(number);
                  }}
                >
                  {/* {number >= 5 && pages.length > 5 && number !== pages.length ? `${number}   ...${pages.length}` : number} */}
                  {number}
                </li>
              );
            } else {
              return null;
            }
          })}
      </ul>
    );
  };

  const handleNextButton = (event) => {
    event.preventDefault();
    setCurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
    fetchInvoicesData(currentPage + 1);
    // searchPharmacy(inputValue, searchFilter, currentPage + 1,proximity);
  };
  const handlePreButton = (event) => {
    event.preventDefault();
    // setPageNumber(pageNumber - 1);
    // console.log("currentPage: ",currentPage-1);
    if (currentPage - 1 >= 1) {
      setCurrentPage((p) => currentPage - 1);
      fetchInvoicesData(currentPage - 1);
    }
    if ((currentPage - 1) % pageNumberLimit == 0) {
      if (minPageNumberLimit - pageNumberLimit < 0) {
        setMaxPageNumberLimit(5);
        setMinPageNumberLimit(0);
      } else {
        setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
        setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
      }
    }
    // searchPharmacy(inputValue, "Retail,MailOrder", currentPage - 1);
  };
  const handleFirstPage = (event) => {
    event.preventDefault();
    setMaxPageNumberLimit(5);
    setMinPageNumberLimit(0);

    handleCurrentPage(1);
  };
  const handleLastPage = (event) => {
    event.preventDefault();
    let lastPage = Math.ceil(totalResults);
    let maxPageNumberLimitValue = lastPage;
    if (maxPageNumberLimitValue % 10 > 0) {
      maxPageNumberLimitValue = maxPageNumberLimitValue - 5;
    }
    setCurrentPage(totalResults);
    setMaxPageNumberLimit(lastPage);
    setMinPageNumberLimit(lastPage - pageNumberLimit);

    handleCurrentPage(lastPage);
  };
  const handleCurrentPage = (pNumber) => {
    setCurrentPage(pNumber);
    fetchInvoicesData(pNumber);
    // searchPharmacy(inputValue, searchFilter, pNumber,proximity);
  };

  useEffect(() => {
    fetchInvoicesData(currentPage);
  }, []);

  return (
    <>
      {isDataLoading ? (
        <Skeleton />
      ) : currentItems?.length > 0 ? (
        <>
          {currentItems?.map((invoicesItem) => {
            return (
              <div className="row justify-content-center mt-3">
                <div className="col-10">
                  <BillingCardContainer>
                    <InvoiceDetailItem
                      key={invoicesItem?.patientBillId}
                      invoicesItem={invoicesItem}
                    />
                  </BillingCardContainer>
                </div>
              </div>
            );
          })}
          <div className="row justify-content-center">
            {isDataLoading ? (
              <Skeleton />
            ) : (
              <div
                className={` mt-4 align-self-center col-sm-8 col-8 justify-content-center`}
              >
                <div className={" d-flex flex-row justify-content-between"}>
                  {pages.length > 1 ? (
                    <div className="ps-2 mt-2">
                      <PnToolTip title="First" placement="top">
                        <button
                          disabled={currentPage === 1}
                          onClick={(e) => {
                            handleFirstPage(e);
                          }}
                          className={`${classes["prev__btn"]} me-2 bg--fafcfc border--default br-10`}
                        >
                          {/* <img src={IconNext} alt="icon-previous"></img> */}
                          <FirstPageIcon style={{ color: "#ec609b" }} />
                        </button>
                      </PnToolTip>
                      <PnToolTip title="Previous" placement="top">
                        <button
                          disabled={currentPage === 1}
                          onClick={(e) => {
                            handlePreButton(e);
                          }}
                          className={`${classes["prev__btn"]} bg--fafcfc border--default br-10`}
                        >
                          <NavigateBeforeIcon style={{ color: "#ec609b" }} />
                          {/* <img src={IconNext} alt="icon-previous"></img> */}
                        </button>
                      </PnToolTip>
                    </div>
                  ) : (
                    ""
                  )}
                  <RenderPages />

                  {pages.length > 1 ? (
                    <div className="mt-2">
                      <PnToolTip title="Next" placement="top">
                        <button
                          disabled={currentPage === pages.length}
                          onClick={(e) => {
                            handleNextButton(e);
                          }}
                          className={`${classes["next__btn"]} me-2 bg--fafcfc border--default br-10`}
                        >
                          {/* <img src={IconNext} alt="icon-next"></img> */}
                          <NavigateNextIcon style={{ color: "#ec609b" }} />
                        </button>
                      </PnToolTip>
                      <PnToolTip title="Last" placement="top">
                        <button
                          disabled={currentPage === pages.length}
                          onClick={(e) => {
                            handleLastPage(e);
                          }}
                          className={`${classes["next__btn"]} bg--fafcfc border--default br-10`}
                        >
                          {/* <img src={IconNext} alt="icon-next"></img> */}
                          <LastPageIcon style={{ color: "#ec609b" }} />
                        </button>
                      </PnToolTip>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <span className="font-12 text--terciary">{"No data available"}</span>
      )}
    </>
  );
};

export default InvoiceDetailItems;

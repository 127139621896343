import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import sendHttpRequest from "../hooks/sendHttpRequest";
import EditButtonIcon from "../../assets/icons/Icon-Edit.svg";
import AddButtonIcon from "../../../src/assets/icons/Icon-Add.svg";
import { useParams } from "react-router-dom";
import { Skeleton } from "@mui/material";
import TrashIcon from "../../assets/icons/TrashIcon";
import { getDiagnosisHistoryUrl } from "../../res/endpointurls";
import DeleteConfirmationModal from "../UI/Modals/PatientModals/DeleteConfirmationModal";
import EditIconComponent from "../../assets/iconComponents/EditIconComponent";
import EditIcon from "../../assets/icons/EditIcon";
import DeleteDiagnosisModal from "../providernote/DeleteDiagnosisModal";
import useProviderNoteDiagnosis from "../hooks/useProviderNoteDiagnosis";
import EditDiagnosisModal from "../providernote/EditDiagnosisModal";
import { Rowing } from "@mui/icons-material";
import DiagnosisModal from "../providernote/DiagnosisModal";
import { useSelector } from "react-redux";
import { ENCOUNTERS } from "../../utils/helpers/subscriptionsConstants";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FAFCFC",
    color: "#336383",
    fontWeight: 400,
    fontSize: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    paddingRight: "0px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // width: 100,
  "&:nth-of-type(even)": {
    backgroundColor: "#FAFCFC",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles({
  customTable: {
    "& .MuiTableCell-sizeMedium": {
      padding: "0px", // <-- arbitrary value
    },
  },
  tableCell: {
    textTransform: "capitalize",
  },
});

const DiagnosisHistoryTable = (props) => {
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);

  const { providerId, encounterId, patientId } = useParams();
  const classes = useStyles();
  const { openDiagnosisModal, setOpenDiagnosisModal, handleDiagnosisModal, openDiagnosisSnackbar, handleDiagnosisSnackBarClose, diagnosisAddedMessage, setDiagnosisAddedMessage,
    diagnosisSeverity, setDiagnosisSeverity, callApi, setCallApi, openDeleteDiagnosisModal, handleDeleteDiagnosisModal, openEditDiagnosisModal,
    handleEditDiagnosisModal, setOpenEditDiagnosisModal, setOpenDeleteDiagnosisModal, deleteDiagnosisLoading,
    editedDiagnosis, setEditedDiagnosis, isDiagnosisUpdating, updateDiagnosis, setOpenDiagnosisSnackBar, setOpenDeleteDiagnosis, deleteDiagnosis
  } = useProviderNoteDiagnosis();
  const [diagnosisHistory, setDiagnosisHistory] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [prescriptionReference, setPrescriptionReference] = useState(null);
  const [diagnosisName, setDiagnosisName] = useState("");
  const [editDiagnosisHistory, setEditDiagnosisHistory] = useState("");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [diagnosis, setDiagnosis] = useState(null);
  const [deletedDiagnosis, setDeletedDiagnosis] = useState(null);
  const [diagnosisHistoryStatusCode, setDiagnosisHistoryStatusCode] =
    useState(null);


  const fetchDiagnosisHistory = async (patientId) => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: getDiagnosisHistoryUrl(patientId || props?.patientId),
      method: "GET",
    });
    if (!httpResponse.error) {
      setDiagnosisHistory(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
    setIsLoading(false);
    setDiagnosisHistoryStatusCode(httpResponse.status);
  };

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const OpeneditModal = () => {
    setOpenEditModal(!openEditModal);
  };

  const handleDeleteDiagnosis = (diagnosis) => {
    setOpenDeleteDiagnosisModal(true);
    setDeletedDiagnosis(diagnosis);
  };

  const handleEditDiagnosis = (diagnosis) => {
    setDiagnosis(diagnosis);
    setOpenEditDiagnosisModal(true);
    setEditedDiagnosis(diagnosis);
  };

  const handleAddDiagnosis = () => {
    setPrescriptionReference(props.prescriptionReferenceId);
    setOpenDiagnosisModal(true);
  };

  useEffect(() => {
    fetchDiagnosisHistory(patientId || props?.patientId);
  }, [props?.patientId, patientId, callApi]);

  return (
    <>

      <div
        className="d-flex mb-1 justify-content-between align-items-baseline"
        {...props}
      >
        <div className="d-inline-flex flex-column align-self-center">
          <h4 className="h18">Diagnosis History</h4>
        </div>
        <div className="d-flex align-self-center" style={{ height: "40px", width: "40px" }}


        >
          {!props?.viewOnly && (
            <img
              style={{ color: "var(--primary)" }}
              className="btn shadow-none"
              src={AddButtonIcon}
              onClick={() => handleAddDiagnosis()}
              alt={"Add button diagnosis"}
              hidden={subscriptionPrivilegeGroups[ENCOUNTERS]?.adg?.active === false ? true : false}
            />
          )}
        </div>

      </div>

      <TableContainer
        sx={{ borderRadius: "16px", border: "1px solid #D9E8EC" }}
      >
        <Table>
          <TableHead
            sx={{
              borderTopColor: "#FAFCFC",
              borderRightColor: "#FAFCFC",
              borderLeftColor: "#FAFCFC",
              borderBottom: "1px solid #D9E8EC",
            }}
          >
            <StyledTableRow>
              <StyledTableCell>Date of Diagnosis</StyledTableCell>
              <StyledTableCell>ICD-10 Code</StyledTableCell>
              <StyledTableCell>Diagnosis</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              {!props?.viewOnly && (
                <>
                  {/* <StyledTableCell></StyledTableCell>{" "} */}
                </>
              )}
            </StyledTableRow>
          </TableHead>
          {
            diagnosisHistory.length > 0 ?
              <TableBody>
                {isLoading ? (
                  <StyledTableRow>
                    <StyledTableCell>
                      <Skeleton animation="wave" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Skeleton animation="wave" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Skeleton animation="wave" />
                    </StyledTableCell>
                    {!props?.viewOnly && (
                      <>
                        {/* <StyledTableCell>
                          <Skeleton animation="wave" />
                        </StyledTableCell> */}
                      </>
                    )}
                  </StyledTableRow>
                ) :
                  diagnosisHistoryStatusCode === 200 ? (
                    diagnosisHistory?.length !== 0 ? (
                      diagnosisHistory?.map((row, index) => {
                        return (
                          <StyledTableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <StyledTableCell sx={{ maxWidth: 150, minWidth: 100, fontWeight: 600 }}>
                              {row?.createdAt?.split('T')[0] !== undefined &&
                                row?.createdAt?.split('T')[0] !== ""
                                ? row.createdAt?.split('T')[0]
                                : "Not available"}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ color: "#336383" }}
                            >
                              {row?.code !== undefined &&
                                row?.code !== ""
                                ? row.code
                                : "Not available"}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ color: "#336383" }}
                            >
                              {row?.description !== undefined &&
                                row?.description !== ""
                                ? row.description
                                : "Not available"}
                            </StyledTableCell>
                            {/* <StyledTableCell
                              sx={{ color: "#336383" }}
                            >
                              {row?.status !== undefined &&
                                row?.status !== ""
                                ? row.status
                                : "Not available"}
                            </StyledTableCell> */}
                            <StyledTableCell
                              sx={{ color: "#336383" }}
                            >
                              <span
                                className={` px-1 py-1 fw-sb font-14 br-10 ${row?.status === true
                                  ? "bg--mild text--secondary"
                                  : "bg--severe text--primary"
                                  }  d-inline-block`}
                              >
                                {row?.status !== undefined &&
                                  row?.status === true
                                  ? "Active"
                                  : "Inactive"}
                              </span>
                            </StyledTableCell>
                            {!props?.viewOnly && (
                              <>
                                {/* <StyledTableCell /> */}
                                <StyledTableCell style={{ cursor: "pointer" }}>
                                  <div className="d-flex flex-row col-11 justify-content-end ms-1">
                                    <div className="row">
                                      <div className="col-4">
                                        {
                                          <EditIcon
                                            style={{ color: "#336383", height: "15px", width: "15px" }}
                                            id={row.diagnosisId}
                                            onClick={() => handleEditDiagnosis(row)}
                                          />
                                        }
                                      </div>
                                      <div className="col-4">
                                        {
                                          <TrashIcon
                                            style={{ color: "#ec609b", height: "15px", width: "15px" }}
                                            id={row.diagnosisId}
                                            onClick={() => handleDeleteDiagnosis(row)}
                                          />
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </StyledTableCell>
                              </>
                            )}
                          </StyledTableRow>
                        );
                      })
                    )
                      : " "
                  ) : ''
                }
              </TableBody>
              : <p className="m-2 font-14 p-1">No data available</p>
          }
        </Table>
      </TableContainer>

      {openDeleteDiagnosisModal ? (
        <DeleteDiagnosisModal
          show={openDeleteDiagnosisModal}
          handleModal={handleDeleteDiagnosisModal}
          deletedDiagnosis={deletedDiagnosis}
          handleDelete={deleteDiagnosis}
          setCallApi={setCallApi}
          deleteDiagnosisLoading={deleteDiagnosisLoading}
        />
      ) : null}
      {openEditDiagnosisModal ? (
        <EditDiagnosisModal
          show={openEditDiagnosisModal}
          handleModal={handleEditDiagnosisModal}
          editDiagnosis={editedDiagnosis}
          handleEditDiagnosis={handleEditDiagnosisModal}
          setCallApi={setCallApi}
          updateDiagnosis={updateDiagnosis}
          isDiagnosisUpdating={isDiagnosisUpdating}

        />
      ) : null}

      {openDiagnosisModal ? (
        <DiagnosisModal
          show={openDiagnosisModal}
          handleModal={handleDiagnosisModal}
          prescriptionReference={prescriptionReference}
          setDiagnosisAddedMessage={setDiagnosisAddedMessage}
          setDiagnosisSeverity={setDiagnosisSeverity}
          setOpenDiagnosisSnackBar={setOpenDiagnosisSnackBar}
          setCallApi={setCallApi}


        />
      ) : null}
    </>
  );
};
export default DiagnosisHistoryTable;


const CCIcon = (props) => {
  return (
    <svg
      id="Icon_material-credit-card"
      data-name="Icon material-credit-card"
      xmlns="http://www.w3.org/2000/svg"
      // {...props}
      // fill={props.style.color}
      width="24"
      height="19.2"
      viewBox="0 0 24 19.2"
    >
      <path id="Icon_material-credit-card-2" data-name="Icon material-credit-card" d="M24.6,6H5.4A2.382,2.382,0,0,0,3.012,8.4L3,22.8a2.392,2.392,0,0,0,2.4,2.4H24.6A2.392,2.392,0,0,0,27,22.8V8.4A2.392,2.392,0,0,0,24.6,6Zm0,16.8H5.4V15.6H24.6Zm0-12H5.4V8.4H24.6Z" transform="translate(-3 -6)" fill="#336383" />
    </svg>
  );
};

export default CCIcon;

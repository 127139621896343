// import _ from 'lodash';

export const ConfigMap = function (urlType) {
  const configs = new Map();
  configs.set("APP", process.env.REACT_APP_BASE_URL);
  return configs.get(urlType);
};

export const IDmeConfigMap = function (urlType) {
  const configs = new Map();
  configs.set(
    'APP',
    `/idverification/verify`
  );

  //Production Server
  // configs.set("APP", "https://api.prescribenow.com:8443/bk");
  return configs.get(urlType);
};

export const featureFlagMap = function (){

  // {console.log("process.env.REACT_APP_SURESCRIPT_FEATURE_FLAG",process.env.REACT_APP_SURESCRIPT_FEATURE_FLAG_LOCAL);}
  const featureFlags = new Map();
  // featureFlags.set('surescript', process.env.REACT_APP_SURESCRIPT_FEATURE_FLAG) //local 
  featureFlags.set('surescript', process.env.REACT_APP_SURESCRIPT_FEATURE_FLAG) //local 

  // if(process.env.NODE_ENV === 'development') {
  // } 
  
    // console.log(process.env.REACT_APP_SURESCRIPT_FEATURE_FLAG_LOCAL)  
  
  return featureFlags;
};

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { useSelector } from "react-redux";
import placeholder from "../../assets/images/placeholder.png";
import { Avatar, Card, Grid, Skeleton, Stack } from "@mui/material";
import { Box } from "@mui/system";
import {
  changeDateTimeFormat,
  dateTimeHelper,
  getAgeByDateOfBirth,
} from "../../utils/dateTimeHelper";
import { formatPhoneNumber } from "../../utils/helpers/HelperFunctions";
import { numbersEnum } from "../../res/strings";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../UI/Reusable/PnComponents";
import { fetchSocialHistoryQuestionsUrl, fetchSocialHistoryUrl } from "../../res/endpointurls";

const PatientProfileEncounter = (props) => {
  const __str = numbersEnum.en;
  const { providerId, encounterId, patientId } = useParams();
  const patientEncounterInformation = useSelector(
    (state) => state.PatientEncounterData.appoinment.encounter
  );

  const [isLoading, setIsLoading] = useState(true);
  const [isRecentEncountersLoading, setIsRecentEncountersLoading] =
    useState(false);
  const [isError, setIsError] = useState(false);
  const [patientInformation, setPatientInformation] = useState();
  const gender = patientInformation?.gender;
  const dateOfBirth = patientInformation?.dateOfBirth;
  const emergencyTelephone =
    patientInformation?.communicationNumbers?.emergencyContact?.find((numberItem) => {
      return numberItem?.phoneNumber !== "";
    })?.phoneNumber || "";
  const age = getAgeByDateOfBirth(patientInformation?.dateOfBirth);
  const primaryTelephone =
    patientInformation?.communicationNumbers?.numbers?.find((numberItem) => {
      return numberItem?.type === __str.cellPhoneExt;
    })?.number || null;
  // const substanceUse = recentEncounters?.SubstanceUse;
  const chronoicConditions = patientEncounterInformation?.chronicConditions;
  const [recentEncountersList, setRecentEncountersList] = useState("");
  const chiefComplaint = props?.value?.encounter?.chiefComplaint;

  const [expanded, setExpanded] = useState("panel1");
  const [expanded2, setExpanded2] = useState("panel2");
  const [expanded3, setExpanded3] = useState("panel3");
  const [expanded4, setExpanded4] = useState("panel4");
  const [expanded5, setExpanded5] = useState("panel5");
  const [socialHistory, setSocialHistory] = useState([]);
  const [isSocialHistoryLoading, setIsSocialHistoryLoading] = useState(false);
  const [substanceUse, setSubstanceUse] = useState([]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChange2 = (panel) => (event, newExpanded) => {
    setExpanded2(newExpanded ? panel : false);
  };

  const handleChange3 = (panel) => (event, newExpanded) => {
    setExpanded3(newExpanded ? panel : false);
  };

  const handleChange4 = (panel) => (event, newExpanded) => {
    setExpanded4(newExpanded ? panel : false);
  };

  const handleChange5 = (panel) => (event, newExpanded) => {
    setExpanded5(newExpanded ? panel : false);
  };

  const fecthPatientEncounterInformation = async (patientId, encounterId) => {
    setIsLoading(true);

    const httpResponse = await sendHttpRequest({
      url: "/patient/information",
      method: "GET",
      params: {
        patientId: patientId,
        encounterId: encounterId,
      },
    });
    if (!httpResponse.error) {
      setPatientInformation(httpResponse.data);
    }
    setIsLoading(false);
  };

  // const fetchRecentEncounters = async () => {
  //   setIsRecentEncountersLoading(true);
  //   const httpResponse = await sendHttpRequest({
  //     url: `/encounter/${encounterId}/resume/`,
  //     method: "GET",
  //   });
  //   if (!httpResponse.error) {
  //     // console.log(httpResponse.data);
  //     setRecentEncountersList(httpResponse.data);
  //   } else {
  //     // console.log(httpResponse.error);
  //   }
  //   setIsRecentEncountersLoading(false);
  // };

  const fetchSocialHistoryQuestions = async () => {
    const httpResponse = await sendHttpRequest({
      url: fetchSocialHistoryQuestionsUrl(encounterId),
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log("Social History questions", httpResponse.data);
      // populateSubstanceUse(httpResponse.data);
      let substanceUseData = [];
      let arr = httpResponse?.data;
      arr.sort((a, b) => a?.question?.sequence - b?.question?.sequence);
      if (arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          if (
            arr[i].question?.toLowerCase() == "tobacco" && arr[i].answerStrings?.length > 0 ||
            arr[i].question?.toLowerCase() == "alcohol" && arr[i].answerStrings?.length > 0 ||
            arr[i].question?.toLowerCase() == "recreational drugs" && arr[i].answerStrings?.length > 0
          ) {
            substanceUseData.push({
              Question: arr[i]?.question,
              Answer: arr[i]?.answerStrings,
            });
          }
        }
        setSubstanceUse(substanceUseData);
      } else {
        setSubstanceUse([]);
      }
    } else {
      console.log("Social History Error", httpResponse.error);
    }
  };

  const fetchSocialHistory = async () => {
    setIsSocialHistoryLoading(true);
    const httpResponse = await sendHttpRequest({
      url: fetchSocialHistoryUrl(patientId),
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log("Social History", httpResponse.data);
      if (httpResponse.data?.length > 0) {
        populateSubstanceUse(httpResponse.data);
      } else {
        fetchSocialHistoryQuestions();
      }
    } else {
      fetchSocialHistoryQuestions();
      console.log(httpResponse.error);
    }
    setIsSocialHistoryLoading(false);
  };

  const populateSubstanceUse = (data) => {
    let substanceUseData = [];
    let arr = data;
    arr.sort((a, b) => a?.question?.sequence - b?.question?.sequence);
    if (arr.length > 0) {
      for (let i = 0; i < arr.length; i++) {
        if (
          arr[i].question.question?.toLowerCase() == "tobacco" && arr[i].answerStrings?.length > 0 ||
          arr[i].question.question?.toLowerCase() == "alcohol" && arr[i].answerStrings?.length > 0 ||
          arr[i].question.question?.toLowerCase() == "recreational drugs" && arr[i].answerStrings?.length > 0
        ) {
          substanceUseData.push({
            Question: arr[i].question.question,
            Answer: arr[i].answerStrings,
          });
        }
      }
      setSubstanceUse(substanceUseData);
    } else {
      setSubstanceUse([]);
    }
  };

  useEffect(() => {
    fetchSocialHistory();
    fecthPatientEncounterInformation(patientId, encounterId);
    // fetchRecentEncounters();
  }, [encounterId, patientId]);

  // console.log(recentEncounters);

  return (
    <>
      <div className="row  justify-content-center pb-5 mb-5">
        {isLoading ? <Skeleton /> : <div
          className="col-sm-10 mt-4 mb-5 bg-white br-10 border--default"
          style={{ border: "1px solid #D9E8EC" }}
        >
          {/* Personal Info Accordion */}
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <h2 className="font-18 fw-sb m-0">Patient Information</h2>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container spacing={5}>
                <Grid item>
                  <Stack>
                    <label className="text--secondary font-12 fw-sb">
                      Date of Birth
                    </label>
                    <p className="m-0 text--terciary font-14">
                      {/* January 02, 1992 */}
                      {dateOfBirth !== undefined &&
                        dateOfBirth !== null &&
                        dateOfBirth !== ""
                        ? changeDateTimeFormat(dateOfBirth, "MMM D, YYYY")
                        : ""}

                      {/* {console.log("dateOfBirth",dateOfBirth)} */}
                    </p>
                  </Stack>
                </Grid>

                <Grid item>
                  <Stack>
                    <label className="text--secondary font-12 fw-sb">Age</label>
                    <p className="m-0 text--terciary font-14">
                      {age !== undefined && age !== null && age !== ""
                        ? age
                        : ""}
                    </p>
                  </Stack>
                </Grid>

                <Grid item>
                  <Stack>
                    <label className="text--secondary font-12 fw-sb">
                      Biological Sex
                    </label>
                    <p className="m-0 text--terciary font-14">
                      {gender !== undefined && gender !== null && gender !== ""
                        ? gender
                        : ""}
                    </p>
                  </Stack>
                </Grid>

                <Grid item>
                  <Stack>
                    <label className="text--secondary font-12 fw-sb">
                      Address
                    </label>
                    <p className="m-0 text--terciary font-14">
                      {/* Lavender Dr. 5817 Corpus Christi, TX */}
                      {/* {address!==undefined && address!=='' && address  } */}
                      {patientInformation?.address[0]?.addressLine1}{" "}
                      {patientInformation?.address[0]?.addressLine2 !== undefined && patientInformation?.address[0]?.addressLine2?.length > 0 ? patientInformation?.address[0]?.addressLine2 + " " : ""}
                      {patientInformation?.address[0]?.city?.trim()}
                      {patientInformation?.address[0]?.city !== undefined &&
                        ", "}
                      {patientInformation?.address[0]?.stateProvince}{" "}
                      {patientInformation?.address[0]?.postalCode}
                    </p>
                  </Stack>
                </Grid>

                <Grid item>
                  <Stack>
                    <label className="text--secondary font-12 fw-sb">
                      Phone
                    </label>
                    <p className="m-0 text--terciary font-14">
                      {primaryTelephone !== undefined &&
                        primaryTelephone !== null &&
                        primaryTelephone !== ""
                        ? formatPhoneNumber(primaryTelephone) !== null
                          ? formatPhoneNumber(primaryTelephone)
                          : ""
                        : ""}
                    </p>
                  </Stack>
                </Grid>
                <Grid item>
                  <Stack>
                    <label className="text--secondary font-12 fw-sb">
                      Emergency Contact
                    </label>
                    <p className="m-0 text--terciary font-14">
                      {emergencyTelephone !== undefined &&
                        emergencyTelephone !== null &&
                        emergencyTelephone !== ""
                        ? formatPhoneNumber(emergencyTelephone) !== null
                          ? formatPhoneNumber(emergencyTelephone)
                          : ""
                        : ""}
                    </p>
                  </Stack>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Chief complaints accordion */}
          <Accordion
            expanded={expanded2 === "panel2"}
            onChange={handleChange2("panel2")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <h2 className="font-18 fw-sb m-0">Chief Complaint</h2>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={5}>
                <Grid item>
                  <p className="m-0 text--terciary font-14">
                    {/* Fever, coughing and shortness of breath */}
                    {chiefComplaint !== undefined &&
                      chiefComplaint !== null &&
                      chiefComplaint !== ""
                      ? chiefComplaint
                      : ""}
                  </p>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Substance Use accordion */}
          <Accordion
            expanded={expanded3 === "panel3"}
            onChange={handleChange3("panel3")}
          >
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <h2 className="font-18 fw-sb m-0">Substance Use</h2>
            </AccordionSummary>
            <AccordionDetails>
              {isSocialHistoryLoading === true ? (
                <Grid container spacing={5}>
                  <Grid item>
                    <Skeleton />
                  </Grid>
                </Grid>
              ) : (
                substanceUse !== undefined &&
                substanceUse?.length > 0 && (
                  <div className="row">
                    <>
                      <div className="col-4">
                        {substanceUse?.map((questionItem, index) => {
                          if (index < 2) {
                            return (
                              <div className="mt-3" key={index + 1}>
                                <label className="font-14 fw-sb">
                                  {questionItem?.Question}
                                </label>
                                <div className="font-12">
                                  {questionItem?.Answer !== undefined &&
                                    questionItem?.Answer !== "" &&
                                    questionItem?.Answer?.length > 0
                                    ? questionItem?.Answer[0]
                                    : ""}
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                      <div className="col-6">
                        {substanceUse.map((questionItem, index) => {
                          if (
                            questionItem?.Question?.toLowerCase() ==
                            "recreational drugs"
                          ) {
                            return (
                              <div className="mt-3" key={index + 2}>
                                <label className="font-14 fw-sb">
                                  {questionItem?.Question}
                                </label>
                                <div className="font-12">
                                  {questionItem?.Answer !== undefined &&
                                    questionItem?.Answer?.length > 0
                                    ? questionItem?.Answer?.map(
                                      (rdrug, index) => {
                                        if (
                                          index !==
                                          questionItem?.Answer?.length - 1
                                        ) {
                                          return rdrug + ", ";
                                        } else {
                                          return rdrug;
                                        }
                                      }
                                    )
                                    : ""}
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </>
                  </div>
                )
              )}
            </AccordionDetails>
          </Accordion>

          {/* Chronic conditions Accordion */}
          <Accordion
            expanded={expanded4 === "panel4"}
            onChange={handleChange4("panel4")}
          >
            <AccordionSummary
              aria-controls="panel4d-content"
              id="panel4d-header"
            >
              <h2 className="font-18 fw-sb m-0">Chronic Conditions</h2>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={5}>
                <Grid item>
                  <p className="m-0 text--terciary font-14">
                    {/* Depression, anxiety */}
                    {chronoicConditions !== undefined &&
                      chronoicConditions !== null &&
                      chronoicConditions !== ""
                      ? chronoicConditions
                      : ""}
                  </p>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Recent Encounters Tiles */}
          <Accordion
            expanded={expanded5 === "panel5"}
            onChange={handleChange5("panel5")}
          >
            <AccordionSummary
              aria-controls="panel5d-content"
              id="panel5d-header"
            >
              <h2 className="font-18 fw-sb m-0">Recent Encounters</h2>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>
                {recentEncountersList !== undefined &&
                  recentEncountersList?.length > 0 &&
                  recentEncountersList?.map((encounter) => {
                    return (
                      <Grid key={encounter?.id} item xs={4}>
                        <Card sx={{ p: 1 }} key={encounter.Prescriber.id}>
                          <Stack direction="row" spacing={6}>
                            <Box>
                              <Stack direction="row">
                                <Avatar
                                  src={
                                    encounter?.Prescriber?.NonVeterinarian
                                      ?.profilePicture || placeholder
                                  }
                                  alt="provider picture"
                                ></Avatar>
                                <Stack sx={{ ml: 1 }}>
                                  <span className="fw-sb font-16 lh-0 m-0 d-block">
                                    {encounter?.Prescriber?.NonVeterinarian
                                      ?.Name?.LastName &&
                                      encounter?.Prescriber?.NonVeterinarian
                                        ?.Name?.Prefix +
                                      encounter.Prescriber.NonVeterinarian
                                        ?.Name?.LastName}
                                  </span>
                                  <span className="font-10 lh-0 d-block text--secondary  m-0">
                                    {encounter?.Prescriber?.NonVeterinarian
                                      ?.Specialty &&
                                      encounter?.Prescriber?.NonVeterinarian
                                        ?.Specialty}
                                  </span>
                                </Stack>
                              </Stack>
                            </Box>
                            <div className="text-center">
                              <button className="btn disabled px-5 bg--lightGreen font-14 shadow-none br-10 fw-sb text--blue">
                                {" "}
                                View
                              </button>
                            </div>
                          </Stack>
                          <Stack direction="row" spacing={3}>
                            <Stack direction="column" spacing={0}>
                              <label
                                className="text--secondary font-12 m-0"
                                style={{ marginBottom: "-8px" }}
                              >
                                Date
                              </label>
                              <p
                                className="m-0 text--terciary font-12"
                                style={{ marginTop: "-8px" }}
                              >
                                {/* Tuesday, March 15, 2022 */}
                                {encounter?.date !== null &&
                                  encounter?.date !== undefined &&
                                  encounter?.date !== ""
                                  ? dateTimeHelper(
                                    encounter?.date,
                                    "MMM D, YYYY"
                                  )
                                  : ""}
                              </p>
                            </Stack>
                            <Stack>
                              <label className="text--secondary font-12 m-0">
                                Time
                              </label>
                              <p
                                className="m-0 text--terciary font-12"
                                style={{ marginTop: "-8px" }}
                              >
                                {/* 11:30am -11:45am */}
                                {encounter?.date !== null &&
                                  encounter?.date !== undefined
                                  ? dateTimeHelper(encounter?.date, "LT")
                                  : ""}
                              </p>
                            </Stack>
                            <Stack></Stack>
                          </Stack>
                        </Card>
                      </Grid>
                    );
                  })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>}
      </div>
    </>
  );
};

export default PatientProfileEncounter;

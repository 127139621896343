import { useEffect, useState } from "react";
import { Divider, Skeleton } from "@mui/material";
import classes from "./PatientVitals.module.css";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { dateTimeHelper } from "../../utils/dateTimeHelper";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUpdateEncounterData } from "../../redux/actions/providerActions";
import { patientVitalsHistoryUrl } from "../../res/endpointurls";
import { useRef } from "react";
import { ERROR_MESSAGES, FIELD_NAMES, MIN_MAX_VALUES } from "../validations/validationschemas";
import { useAuth } from "../hooks/useAuth";
import InPatientAppointmentService from "../Services/InPatientAppointment.service";
import { PATIENTS } from "../../utils/helpers/subscriptionsConstants";
const PatientVitals = (props) => {
  const updateEncounterData =
    useSelector(
      (state) => state.UpdateEncounter.Encounter
    );
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);

  const { user } = useAuth();
  const field_Name = FIELD_NAMES.vitals;
  const VITAL_VALIDATION = MIN_MAX_VALUES.vitals;
  const validationErrorMsgs = ERROR_MESSAGES.vitals;
  const [vitalValidationErrors, setVitalValidationErrors] = useState([]);


  const dispatch = useDispatch();
  const { providerId, encounterId, patientId } = useParams();
  const [recentVitals, setRecentVitals] = useState(null);
  const [vitalHistory, setVitalHistory] = useState([]);
  const [isVitalHistoryLoading, setIsVitalHistoryLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [vitals, setVitals] = useState();


  const temprature = useRef();
  const systolic = useRef();
  const diastolic = useRef();
  const spo = useRef();
  const height = useRef();
  const weight = useRef();
  const pulse = useRef();
  const bmi = useRef();



  const fetchVitalsHistory = async (encounterId, patientId) => {
    setIsVitalHistoryLoading(true);
    const httpResponse = await sendHttpRequest({
      url: patientVitalsHistoryUrl(encounterId, patientId),
      method: "GET",
    });
    if (!httpResponse.error) {
      setVitalHistory(httpResponse.data);

      // setVitalHistory(httpResponse.data);
    } else {
      console.log("VitalsL ", httpResponse.error);
    }
    setIsVitalHistoryLoading(false);
  };

  const handleVitalsValidation = (fieldValue, fieldName) => {
    let value = fieldValue;
    props.setUnsavedChanges(true);
    if ((value < VITAL_VALIDATION[fieldName]?.min || value > VITAL_VALIDATION[fieldName].max) && value.length > 0) {
      let fieldExist = vitalValidationErrors.filter((item) => { return item.fieldName === field_Name[fieldName] });
      if (fieldExist.length === 0) {
        setVitalValidationErrors([...vitalValidationErrors, { "fieldName": field_Name[fieldName] }])
      }
    } else {
      let temp = vitalValidationErrors.filter((item) => { return item.fieldName !== field_Name[fieldName] });
      setVitalValidationErrors(temp);
    }
  }
  const handleTemperature = (event) => {
    event.preventDefault();
    let temperature = event.target.value;
    handleVitalsValidation(temperature, field_Name.temperature)
    setVitals({
      ...vitals,
      temperature: temperature,
    });

    dispatch(
      setUpdateEncounterData({
        ...updateEncounterData,
        encounterId: encounterId,
        vital: {
          ...updateEncounterData?.vital,
          temperature: temperature,
        },
      })
    );
  };

  const handleBloodPressureSystolic = (event) => {
    // console.log("Systolic",event.target.value);
    // setSystolic(event.target.value)
    event.preventDefault();
    props.setUnsavedChanges(true);
    let systolic = event.target.value;
    handleVitalsValidation(systolic, field_Name.systolic)
    setVitals({
      ...vitals,
      systolic: systolic,
    });
    dispatch(
      setUpdateEncounterData({
        ...updateEncounterData,
        encounterId: encounterId,
        vital: {
          ...updateEncounterData?.vital,
          systolic: systolic,
        },
      })
    );
  };

  const handleBloodPressureDiastolic = (event) => {
    // console.log("Diastolic",event.target.value);
    // setDiastolic(event.target.value)
    event.preventDefault();
    props.setUnsavedChanges(true);
    let diastolic = event.target.value;
    handleVitalsValidation(diastolic, field_Name.diastolic)
    setVitals({
      ...vitals,
      diastolic: diastolic,
    });
    dispatch(
      setUpdateEncounterData({
        ...updateEncounterData,
        encounterId: encounterId,
        vital: {
          ...updateEncounterData.vital,
          diastolic: diastolic,
        },
      })
    );
  };

  const handlePulse = (event) => {
    // console.log("Pulse",event.target.value);
    // setPulse(event.target.value)
    event.preventDefault();
    props.setUnsavedChanges(true);
    let pulse = event.target.value;
    handleVitalsValidation(pulse, field_Name.pulse)

    setVitals({
      ...vitals,
      pulse: pulse,
    });
    dispatch(
      setUpdateEncounterData({
        ...updateEncounterData,
        encounterId: encounterId,
        vital: {
          ...updateEncounterData.vital,
          pulse: pulse,
        },
      })
    );
  };

  const handleSPO = (event) => {
    // console.log("SPO",event.target.value);
    // setSpo(event.target.value)
    event.preventDefault();
    props.setUnsavedChanges(true);
    let SPO = event.target.value;
    handleVitalsValidation(SPO, field_Name.SPO)

    setVitals({
      ...vitals,
      spo2: SPO,
    });
    dispatch(
      setUpdateEncounterData({
        ...updateEncounterData,
        encounterId: encounterId,
        vital: {
          ...updateEncounterData.vital,
          spo2: SPO,
        },
      })
    );
  };

  const handleWeight = (event) => {
    event.preventDefault();
    props.setUnsavedChanges(true);
    let weight = event.target.value;
    handleVitalsValidation(weight, field_Name.weight);

    // changed from non decimal to handle decimal values
    // const weightValue = Math.round(event.target.value.replace(/\D/g, ""));
    const weightValue = weight;
    if (
      updateEncounterData?.vital?.weight !== null &&
      vitals?.weight !== undefined
    ) {
      const bmiValue = calcBMI(weightValue, updateEncounterData?.vital?.height);
      handleBMI(bmiValue);
      document.getElementById("bmi").value = bmiValue;
    }
    setVitals({
      ...vitals,
      weight: weightValue,
    });
    dispatch(
      setUpdateEncounterData({
        ...updateEncounterData,
        encounterId: encounterId,
        vital: {
          ...updateEncounterData.vital,
          weight: weightValue,
          bmi: document.getElementById("bmi").value
        },
      })
    );
  };

  const handleHeight = (event) => {
    event.preventDefault();
    props.setUnsavedChanges(true);
    let height = event.target.value;
    handleVitalsValidation(height, field_Name.height);

    // chnages non decimal to handle non decimal value
    // const heightValue = Math.round(weight.replace(/\D/g, ""));
    const heightValue = height;
    if (
      updateEncounterData?.vital?.height !== null &&
      updateEncounterData?.vital?.height !== undefined
    ) {
      const bmiValue = calcBMI(updateEncounterData?.vital?.weight, heightValue);
      // console.log(bmiValue);
      handleBMI(bmiValue);
      document.getElementById("bmi").value = bmiValue;
    }
    setVitals({
      ...vitals,
      height: heightValue,
    });

    dispatch(
      setUpdateEncounterData({
        ...updateEncounterData,
        encounterId: encounterId,
        vital: {
          ...updateEncounterData.vital,
          height: heightValue,
          bmi: document.getElementById("bmi").value
        },
      })
    );
  };

  const handleBMI = (value) => {
    // console.log("BMI", value);
    // setBmi(height)

    setVitals({
      ...vitals,
      bmi: value,
    });

    dispatch(
      setUpdateEncounterData({
        ...updateEncounterData,
        encounterId: encounterId,
        vital: {
          ...updateEncounterData.vital,
          bmi: value,
        },
      })
    );
  };

  const clearVitals = () => {
    temprature.current.value = "";
    systolic.current.value = "";
    diastolic.current.value = "";
    spo.current.value = "";
    height.current.value = "";
    weight.current.value = "";
    pulse.current.value = "";
    bmi.current.value = "";
  }
  async function makeAppointmentProviderReady() {
    const response = await InPatientAppointmentService.markAppointmentProviderReady(props?.appointmentId, "provider-ready");
    // if (!response.error) {
    //   navigate('/dashboard', {
    //     replace: true
    //   })
    // }
  }
  const handleVitalsUpdate = async () => {
    await props?.handleUpdateEncounter(true);
    // if (user?.role === "MedicalAssistant") {
    //   await makeAppointmentProviderReady();
    //   props.setStatus('provider-ready');
    // }
    fetchVitalsHistory(encounterId, patientId);
    clearVitals();
  }

  const calcBMI = (weight, height) => {
    const heightSqr = Math.pow(Number(height), 2);
    return ((Number(weight) / Number(heightSqr)) * 703).toFixed(1);
  };
  // console.log("VITALS", vitals)
  useEffect(() => {
    // fetchRecentVitals(encounterId);
    fetchVitalsHistory(encounterId, patientId);
    clearVitals();
  }, [encounterId]);

  return isLoading ? (
    <>
      <Skeleton
        animation="wave"
        variant="text"
        style={{ top: "30%" }}
        width={"100%"}
        height={20}
      />
      <Skeleton
        animation="wave"
        variant="text"
        style={{ top: "30%" }}
        width={"100%"}
        height={50}
      />
      <Skeleton
        animation="wave"
        variant="text"
        style={{ top: "30%" }}
        width={"100%"}
        height={80}
      />
    </>
  ) : (
    <>
      {/* {console.log("vitalData", vitalData.length)}
      {console.log("reached")} */}

      <div hidden={subscriptionPrivilegeGroups[PATIENTS]?.apv?.active === false ? true : false}>
        <div className="row mt-3">
          <div className="col-10">
            <h3 className="font-18 fw-sb m-0">Recent</h3>
            <p className="font-14 text--secondary m-0">
              {/* 15 February, 2022 &ndash; 11:29am */}
              {dateTimeHelper(
                props?.encounterApiData?.createdAt,
                "MMM D, YYYY - LT"
              )}
            </p>
          </div>
          {/* <div className="col-2 align-self-center text-end">
            <button
              className={`btn blue_task_button text-white font-14 px-4 br-10 py-1`}
              onClick = {()=>{props?.handleUpdateEncounter()}}
            >
              {"Add"}
            </button>
          </div> */}
        </div>
        <div
          className={`row ${classes["box-border"]} g-0 br-10 border--default box-shadow py-4 px-4`}
        >
          <div className="col-md pe-1">
            <span
              style={{ fontSize: "12px" }}
              className="text--secondary fw-thin d-block mb-1"
            >
              Temp
            </span>

            <div className="input-group flex-nowrap">
              <input
                id="temperature"
                ref={temprature}
                className={`form-control p-2 border-end-0 ${classes["input"]} text--terciary font-16 fw-sb br-10  shadow-none `}
                type="number"
                min="0"
                // style={{ flexBasis: "60px" }}
                defaultValue={
                  updateEncounterData?.vital?.temperature !== undefined &&
                    updateEncounterData?.vital?.temperature !== ""
                    ? updateEncounterData?.vital?.temperature
                    : recentVitals?.temperature
                }
                onChange={(e) => handleTemperature(e)}
              />

              <span
                style={{ fontSize: "12px" }}
                className={`input-group-text pe-1 br-10 ${classes["b"]} border-1 br-10 bg-white border-start-0`}
              >
                &deg;F
              </span>
            </div>
          </div>

          <div className="col-md-3 pe-1">
            <span
              style={{ fontSize: "12px" }}
              className=" text--secondary fw-thin d-block mb-1"
            >
              Blood Pressure
            </span>
            <div className="row g-0">
              <div className="col-4">
                <input
                  id="systolic"
                  type="number"
                  ref={systolic}
                  style={{ flexBasis: "60px" }}
                  className={`form-control p-2 flex-grow-0 form-control-sm ${classes["input-bp"]} text--terciary font-16 fw-sb br-10  shadow-none `}
                  defaultValue={
                    updateEncounterData?.vital?.systolic !== undefined &&
                      updateEncounterData?.vital?.systolic !== ""
                      ? updateEncounterData?.vital?.systolic
                      : recentVitals?.systolic
                  }
                  onChange={(e) => handleBloodPressureSystolic(e)}
                />
              </div>

              <div className="col-1">
                <span className="my-2 text-center text--secondary d-block">
                  &#47;
                </span>
              </div>

              <div className="col-7">
                <div className="input-group ">
                  {/* <div class="input-group-append"> */}

                  {/* <span class="input-group-text" id="basic-addon1">@</span> */}
                  {/* </div> */}
                  <input
                    id="diastolic"
                    type="number"
                    ref={diastolic}
                    // style={{ flexBasis: "20px" }}
                    className={`form-control p-2 form-control-sm ${classes["input"]} text--terciary font-16 fw-sb br-10  shadow-none `}
                    defaultValue={
                      updateEncounterData?.vital?.diastolic !== undefined &&
                        updateEncounterData?.vital?.diastolic !== ""
                        ? updateEncounterData?.vital?.diastolic
                        : recentVitals?.diastolic
                    }
                    onChange={(e) => handleBloodPressureDiastolic(e)}
                  />
                  <span
                    style={{ fontSize: "12px" }}
                    className={`input-group-text br-10 pe-1 ${classes["b"]} p-0 bg-white  text--secondary`}
                  >
                    mmHg
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md pe-1">
            <span
              style={{ fontSize: "12px" }}
              className="text--secondary fw-thin d-block mb-1"
            >
              Pulse
            </span>
            <div className="input-group   mb-3">
              <input
                id="pulse"
                ref={pulse}
                type="number"
                style={{ flexBasis: "45px" }}
                className={`form-control p-2 form-control-sm ${classes["input"]} text--terciary font-16 fw-sb br-10  shadow-none `}
                defaultValue={
                  updateEncounterData?.vital?.pulse !== undefined &&
                    updateEncounterData?.vital?.pulse !== ""
                    ? updateEncounterData?.vital?.pulse
                    : recentVitals?.pulse
                }
                onChange={(e) => handlePulse(e)}
              />
              <span
                style={{ fontSize: "12px" }}
                className={`input-group-text br-10 pe-1 ${classes["b"]} p-0 bg-white  text--secondary`}
              >
                bpm
              </span>
            </div>
          </div>

          <div className="col-md pe-1">
            <span
              style={{ fontSize: "12px" }}
              className="text--secondary ps-2 fw-thin d-block mb-1"
            >
              SPO<sub>2</sub>
            </span>
            <div className="input-group flex-nowrap">
              <input
                id="spo"
                ref={spo}
                type="number"
                style={{ flexBasis: "100%" }}
                className={`form-control p-2 flex-grow-0 form-control-sm ${classes["input"]} text--terciary font-16 fw-sb br-10  shadow-none `}
                defaultValue={
                  updateEncounterData?.vital?.spo2 !== undefined &&
                    updateEncounterData?.vital?.spo2 !== ""
                    ? updateEncounterData?.vital?.spo2
                    : recentVitals?.spo2
                }
                onChange={(e) => handleSPO(e)}
              />
              <span
                style={{ fontSize: "12px" }}
                className={`input-group-text p-0 pe-1 br-10 ${classes["b"]} bg-white text--secondary`}
              >
                %
              </span>
            </div>
          </div>

          <div className="col-md pe-1">
            <span
              style={{ fontSize: "12px" }}
              className="text--secondary ps-1 fw-thin d-block mb-1"
            >
              Height
            </span>
            <div className="input-group  flex-nowrap mb-3">
              <input
                id="height"
                ref={height}
                type="number"
                style={{ flexBasis: "100%" }}
                className={`form-control p-2 flex-grow-0 form-control-sm ${classes["input"]} text--terciary font-16 fw-sb br-10  shadow-none `}
                defaultValue={
                  updateEncounterData?.vital?.height !== undefined &&
                    updateEncounterData?.vital?.height !== ""
                    ? updateEncounterData?.vital?.height
                    : recentVitals?.height
                }
                onChange={(e) => handleHeight(e)}
              />
              <span
                style={{ fontSize: "12px" }}
                className={`input-group-text pe-1 br-10 ${classes["b"]} p-0 bg-white  text--secondary`}
              >
                inches
              </span>
            </div>
          </div>

          <div className="col-md pe-1">
            <span
              style={{ fontSize: "12px" }}
              className="text--secondary fw-thin d-block mb-1"
            >
              Weight
            </span>
            <div className="input-group mb-3 flex-nowrap">
              <input
                id="weight"
                ref={weight}
                type="number"
                // onKeyPress={(e) => handleWeight(e)}
                // onKeyPress={"return (event.charCode > 31 && event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"}
                style={{ flexBasis: "100%" }}
                className={`form-control p-2 flex-grow-0 form-control-sm ${classes["input"]} text--terciary font-16 fw-sb br-10  shadow-none `}
                defaultValue={
                  updateEncounterData?.vital?.weight !== undefined &&
                    updateEncounterData?.vital?.weight !== ""
                    ? updateEncounterData?.vital?.weight
                    : recentVitals?.weight
                }
                onChange={(e) => handleWeight(e)}
              />
              <span
                style={{ fontSize: "12px" }}
                className={`input-group-text p-0 pe-1 ${classes["b"]} bg-white text--secondary`}
              >
                lbs
              </span>
            </div>
          </div>

          <div className="col-md">
            <span
              style={{ fontSize: "12px" }}
              className="text--secondary ps-1 fw-thin d-block mb-1"
            >
              BMI
            </span>

            <input
              id="bmi"
              ref={bmi}
              type="number"
              disabled
              className={`form-control bg--disabled p-2 form-control-sm ${classes["input-bp"]} text--terciary font-16 fw-sb br-10  shadow-none `}
              defaultValue={
                updateEncounterData?.vital?.bmi !== undefined &&
                  updateEncounterData?.vital?.bmi !== ""
                  ? updateEncounterData?.vital?.bmi
                  : recentVitals?.bmi
              }
            // onChange={(e) => handleBMI(e)}
            />
          </div>
          <div className="col-md  text-end px-2 py-1  text-end">
            <span
              style={{ fontSize: "12px" }}
              className="text--secondary ps-1 fw-thin d-block "
            >
              &nbsp;
            </span>
            <button
              className={`btn blue_task_button ${vitalValidationErrors?.length > 0 ? 'disabled' : ''}  text-white font-14 px-4 br-10 py-1`}
              style={{ height: "40px" }}
              onClick={() => {
                handleVitalsUpdate();
              }}
            >
              {"Add"}
            </button>
          </div>


          {
            <div className="row">
              <div className="col-12">
                {
                  vitalValidationErrors?.length > 0 &&
                  <>
                    <span className="font-12 text--terciary">
                      {"Some of the values input for patient vitals fall beyond the typical norms shown below:"}
                    </span>
                    <ul>
                      {
                        vitalValidationErrors.map((errorItem) => {
                          return (
                            <li className="font-12 text--terciary">{validationErrorMsgs[errorItem.fieldName]}</li>
                          )
                        })
                      }
                    </ul>
                    <div className="">
                      <span className="text--terciary font-12">{"Please confirm if you wish to proceed: "}</span>
                      <button
                        className="btn d9e8ec--button"
                        onClick={() => {
                          // handleVitalsUpdate();
                          setVitalValidationErrors([]);
                        }}>{"Confirm"}</button>
                    </div>


                  </>
                }
              </div>

            </div>
          }
        </div>
        {
          subscriptionPrivilegeGroups[PATIENTS]?.vpv?.active === true ?
            <Divider className="my-5 bg--lightgreen" /> : null
        }
      </div>


      <div className={`row ${subscriptionPrivilegeGroups[PATIENTS]?.vpv?.active === false ? 'mt-3' : ''}`} hidden={subscriptionPrivilegeGroups[PATIENTS]?.vpv?.active === false ? true : false}>
        <div className="col-12 sol-sm-12">
          <h3 className="font-18 fw-sb m-0">History</h3>
          {/* {console.log("vitalHistory.length", vitalHistory.length)} */}
          {isVitalHistoryLoading ? <Skeleton /> : vitalHistory?.length === 0 ? "No vitals history available" : <div className="table-responsive">
            <table className={`${classes.pvt} mt-4`}>
              <thead className="text--secondary bg--background font-12">
                <tr>
                  <th className="fw-thin ">Vitals</th>
                  {/* {alert(vitalHistory.length)} */}
                  {
                    /* map over to print the dates and time stamps  */
                    vitalHistory && vitalHistory?.length > 0
                      ? vitalHistory?.map((item, index) => {
                        return (
                          <th key={index}>
                            <span
                              className="d-block"
                              style={{ marginBottom: "-7px" }}
                            >
                              {/* 10 Feb */}
                              {item?.createdAt !== null &&
                                item?.createdAt !== undefined
                                ? dateTimeHelper(
                                  item?.createdAt,
                                  "MMM D, YYYY"
                                )
                                : ""}
                            </span>
                            <span className="d-block fw-thin">
                              {/* 11:12am */}
                              {item?.createdAt !== null &&
                                item?.createdAt !== undefined
                                ? dateTimeHelper(item?.createdAt, "LT")
                                : ""}
                            </span>
                          </th>
                        );
                      })
                      : ""
                  }
                </tr>
              </thead>
              <tbody>
                <tr className={`${classes["border__tr"]} bg-white`}>
                  <td
                    className={`py-3  ${classes["table__tr--background"]} font-14`}
                  >
                    Temperature (&deg;F)
                  </td>
                  {
                    /* Render vital values for temperature */
                    vitalHistory?.length > 0
                      ? vitalHistory?.map((item, index) => {
                        return (
                          <td
                            key={index}
                            className="py-3 font-14 fw-sb text-center"
                          >
                            {item?.temperature !== null
                              ? item?.temperature
                              : "--"}
                            {item?.temperature != null &&
                              item?.temperature != "" && <span>&deg;</span>}
                          </td>
                        );
                      })
                      : ""
                  }
                </tr>
                <tr
                  className={`${classes["border__tr"]} ${classes["tr--stripe"]}`}
                >
                  <td
                    className={`py-3  ${classes["table__tr--background"]} font-14`}
                  >
                    Blood Pressure (mmHg)
                  </td>
                  {
                    /* Render vital values for Blood Pressure */
                    vitalHistory?.length > 0
                      ? vitalHistory.map((item, index) => {
                        return (
                          <td
                            className="py-3 font-14 fw-sb text-center"
                            key={index}
                          >
                            {item?.systolic !== null ? item?.systolic : "-"}
                            {item?.systolic !== null &&
                              item?.systolic !== "" && <span>&#47;</span>}
                            {item?.diastolic !== null
                              ? item?.diastolic
                              : "-"}
                          </td>
                        );
                      })
                      : ""
                  }
                </tr>
                <tr className={`${classes["border__tr"]} bg-white`}>
                  <td
                    className={`py-3  ${classes["table__tr--background"]} font-14`}
                  >
                    Pulse (bpm)
                  </td>

                  {
                    /* Render vital values for pulse */
                    vitalHistory?.length > 0
                      ? vitalHistory.map((item, index) => {
                        return (
                          <td
                            className="py-3 font-14 fw-sb text-center"
                            key={index}
                          >
                            {item?.pulse !== null && item?.pulse !== ""
                              ? item?.pulse
                              : "--"}
                          </td>
                        );
                      })
                      : ""
                  }
                </tr>
                <tr
                  className={`${classes["border__tr"]} ${classes["tr--stripe"]}`}
                >
                  <td
                    className={`py-3  ${classes["table__tr--background"]} font-14`}
                  >
                    SPO<sub>2</sub> (%)
                  </td>
                  {
                    /* Render vital values for SPO2 */
                    vitalHistory?.length > 0
                      ? vitalHistory.map((item, index) => {
                        return (
                          <td
                            className="py-3 font-14 fw-sb text-center"
                            key={index}
                          >
                            {item?.spo2 !== null && item?.spo2 !== ""
                              ? item?.spo2
                              : "--"}
                          </td>
                        );
                      })
                      : ""
                  }
                </tr>
                <tr className={`${classes["border__tr"]} bg-white`}>
                  <td
                    className={`py-3 ${classes["table__tr--background"]} font-14`}
                  >
                    Height (inches)
                  </td>
                  {
                    /* Render vital values for Weight */
                    vitalHistory?.length > 0
                      ? vitalHistory.map((item, index) => {
                        return (
                          <td
                            className="py-3 font-14 fw-sb text-center"
                            key={index}
                          >
                            {item?.height !== null ? item?.height : "--"}
                          </td>
                        );
                      })
                      : ""
                  }
                </tr>
                <tr
                  className={`${classes["border__tr"]} ${classes["tr--stripe"]}`}
                >
                  <td
                    className={`py-3 ${classes["table__tr--background"]} font-14`}
                  >
                    Weight (lbs)
                  </td>
                  {
                    /* Render vital values for Weight */
                    vitalHistory?.length > 0
                      ? vitalHistory.map((item, index) => {
                        return (
                          <td
                            className="py-3 font-14 fw-sb text-center"
                            key={index}
                          >
                            {item?.weight !== null ? item?.weight : "--"}
                          </td>
                        );
                      })
                      : ""
                  }
                </tr>
                <tr
                  style={{ borderBottomLeftRadius: "30px" }}
                  className={`${classes["border__tr"]} bg-white`}
                >
                  <td
                    className={`py-3 ${classes["table__tr--background"]} font-14`}
                  >
                    BMI
                  </td>
                  {
                    /* Render vital values for BMI */
                    vitalHistory?.length > 0
                      ? vitalHistory.map((item, index) => {
                        return (
                          <td
                            className="py-3 font-14 fw-sb text-center"
                            key={index}
                          >
                            {item?.bmi != null
                              ? item?.bmi
                              : "--"}
                          </td>
                        );
                      })
                      : ""
                  }
                </tr>
              </tbody>
            </table>
          </div>}

        </div>
      </div>

      {/* {console.log("vitalValidationErrors: ",vitalValidationErrors)} */}
    </>
  );
};

export default PatientVitals;

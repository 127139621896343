import SearchIcon from "@mui/icons-material/Search";
import classes from "./DiagnosisSearchComponent.module.css";

export default function DiagnosisSearch(props) {
    return (<div>
        <div className="input-group" style={{
            border: "1px solid #D9E8EC",
            borderRadius: "10px"
        }}>
            <input type="text" className="form-control font-12 br-10 shadow-none border-0  " placeholder="Search diagnosis" value={props.diagnosisName} onChange={e => {
                props.handleSearchInput(e.target.value);
            }} onBlur={() => {
                setTimeout(() => {
                    props.setSuggestions([]);
                    props.setIsError(false);
                    props.setIsLoading(false);
                }, 10);
            }} />

            <button className="border-0 outline-0 px-1 bg-white  br-10 shadow-none zindex-1" type="submits" value={props.searchInputValue} onClick={() => props.handleSearchInput(props.searchInputValue)} onBlur={() => {
                setTimeout(() => {
                    props.setSuggestions([]);
                    props.setIsError(false);
                    props.setIsLoading(false);
                }, 5);
            }}>
                <SearchIcon className="text--secondary" />
            </button>
        </div>

        <ul className={`list-group ${classes["diagnosis__suggestions"]}`}>
            {props.isError ? <li className={`list-group-item ${classes["search__list__item"]}`}>
                No result found
            </li> : props.isLoading ? <li className={`list-group-item ${classes["search__list__item"]}`}>
                Loading...
            </li> : props.suggestions.length > 0 && props.suggestions.map((suggestion, index) => {
                return <li tabIndex={index} // className="list-group-item"
                    className={`border--default ${classes.li} ${classes["search__list__item"]}`} key={suggestion.code} onMouseDown={() => props.handleAddDiagnosis(suggestion)} // onKeyDown = {()=>console.log("works")}
                >
                    <span>{suggestion.code} - </span>
                    <span className="ms-1">{suggestion.description}</span>
                </li>;
            })}
        </ul>
    </div>);
}
import { useEffect, useState } from "react";
import CalenderDatePicker from "../UI/General/CalenderDatePicker";
import classes from "./BadgeButtons.module.css";
import { calenderDateTimeHelper } from "../../utils/dateTimeHelper";

const ButtonComp = (props) => {
  const [isActive, setIsActive] = useState(props?.practiceAdmin ? "1 Month": "6 Months");

  useEffect(() => {
    console.log("props.activeBtn: ",props.activeBtn);
    
    setIsActive(props.activeBtn);
  }, [props.activeBtn]);

  return (
    <>
      <button
        id={props?.text}
        className={`btn br-10 border--default fw-sb ${classes["badge-btn"]} shadow-none ${
          isActive === props?.text ? "bg--d9e8ec text--secondary" : "bg--fafcfc text--terciary"
        } `}
        onClick={(e) => {
          props.handleButtonClick(e.target.id);
        }}
      >
        {props.text}
      </button>
    </>
  );
};

const BadgeButtons = (props) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [activeBtn, setActiveBtn] = useState(props?.practiceAdmin  ? "1 Month": "6 Months");

  const [buttonOptions,setButtonOptions] = useState([]); 
   

  const handleStartDate = (dateVal) => {
    if (dateVal !== undefined) {
      setStartDate(dateVal);
      props.setFromDateVal(calenderDateTimeHelper(dateVal, "YYYY-MM-DD"));
    }
  };

  const handleEndDate = (dateVal) => {
    if (dateVal !== undefined) {
      setEndDate(dateVal);
      props.setToDateVal(calenderDateTimeHelper(dateVal, "YYYY-MM-DD"));
    }
  };

  const handleButtonClick = (buttonText) => {
    setStartDate(undefined);
    setEndDate(undefined);
    const currentDate = new Date(); // Current date
    let calculatedStartDate;

    if (buttonText === "6 Months" || buttonText === "1 Month") {
      calculatedStartDate = new Date(currentDate.setMonth(currentDate.getMonth() - (buttonText === "6 Months" ? 6 : 1)));
    } else if (buttonText === "12 Months" || buttonText === "2 Months") {
      calculatedStartDate = new Date(currentDate.setMonth(currentDate.getMonth() - (buttonText === "12 Months" ? 12 : 2)));
    } else if (buttonText === "Quarter to Date") {
      const currentMonth = new Date().getMonth();
      const quarterStartMonth = currentMonth - (currentMonth % 3);
      calculatedStartDate = new Date(new Date().setMonth(quarterStartMonth));
    }

    // Set the start date and end date of parent
    props.setToDateVal(calenderDateTimeHelper(new Date(), "YYYY-MM-DD"));
    props.setFromDateVal(calenderDateTimeHelper(calculatedStartDate, "YYYY-MM-DD"));

    setActiveBtn(buttonText);
  };

  useEffect(() => {
    if (startDate?.length > 0 || endDate?.length > 0) {
      setActiveBtn(undefined);
    }
  }, [startDate, endDate]);

  useEffect(()=>{
    if(props.practiceAdmin){
      setButtonOptions(["1 Month", "2 Months", "Quarter to Date"]);
    }else{
      setButtonOptions(["6 Months", "12 Months", "Quarter to Date"]);
    }
    
     
  },[props.practiceAdmin])

  return (
    <>
      <div className={`row mt-3 pb-2 ${props?.hideBorder ? "" : "border--bottom"} ${props?.borderTop ? "border--top py-2" : ""}`}>
        <div className="col-1">
          <label className="text--terciary font-12 align-self-center fw-sb">Start Date:</label>
        </div>
        <div className="col-2">
          <CalenderDatePicker
            className={"col-3"}
            f12={true}
            dobHandler={handleStartDate}
            dateFormat="MMM d, yyyy"
            date={startDate !== undefined ? startDate : ""}
            key={`startDate${startDate}`}
          />
        </div>

        <div className="col-1">
          <label className="text--terciary font-12 align-self-center fw-sb">End Date:</label>
        </div>
        <div className="col-2">
          <CalenderDatePicker
            f12={true}
            dobHandler={handleEndDate}
            dateFormat="MMM d, yyyy"
            date={endDate !== undefined ? endDate : ""}
            key={`EndDate${endDate}`}
          />
        </div>

        {buttonOptions.map((option) => (
          <div className="col-2" key={option}>
            <ButtonComp
              text={option}
              setActiveBtn={setActiveBtn}
              activeBtn={activeBtn}
              handleButtonClick={handleButtonClick}
              practiceAdmin = {props?.practiceAdmin}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default BadgeButtons;

import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import sendHttpRequest from "../hooks/sendHttpRequest";
import EditButtonIcon from "../../assets/icons/Icon-Edit.svg";
import AddButtonIcon  from "../../../src/assets/icons/Icon-Add.svg";
import SurgeryModal from "../UI/Modals/PatientModals/SurgeryModal";
import SurgeryEditModal from "../UI/Modals/PatientModals/SurgeryEditModal";
import { useParams } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { changeDateTimeFormat } from "../../utils/dateTimeHelper";
import TrashIcon from "../../assets/icons/TrashIcon";
import { getSurgeryHistoryUrl, addSurgeryUrl, updateSurgeryUrl } from "../../res/endpointurls";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { truncate } from "../../utils/helpers/HelperFunctions";
import { surgeryStrings } from "../../res/strings";
import EditIcon from "../../assets/icons/EditIcon";

const ColoredToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#336383",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#336383",
  },
}));
function getObjectKeys(params) {
  return Object.keys(params);
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FAFCFC",
    color: "#336383",
    fontWeight: 400,
    fontSize: 12,
    width: 150
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    paddingRight: "0px",
    width: 150
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  minWidth: 130,
  "&:nth-of-type(even)": {
    backgroundColor: "#FAFCFC",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles({
  customTable: {
    "& .MuiTableCell-sizeMedium": {
      padding: "0px", // <-- arbitrary value
    },
  },
  tableCell: {
    textTransform: "capitalize",
  },
});

const SurgeryTable = (props) => {
  const { providerId, encounterId, patientId } = useParams();
  const classes = useStyles();
  const _str = surgeryStrings.en;

  const [surgery, setSurgery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [surgeryName, setSurgeryName] = useState("");
  const [surgeryDate, setSurgeryDate] = useState("");
  const [surgeryNote, setSurgeryNote] = useState("");
  const [editSurgery, setEditSurgery] = useState("");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [updateSurgeryId, setUpdateSurgeryId] = useState("");
  const [surgeryStatusCode, setSurgeryStatusCode] = useState("");
  const [noteTextSize, setNoteTextSize] = useState(70);
  const fetchSurgery = async (patientId) => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: getSurgeryHistoryUrl(patientId || props?.patientId),
      method: "GET",
    });
    if (!httpResponse.error) {
      setSurgery(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
    setIsLoading(false);
    setSurgeryStatusCode(httpResponse.status);
  };

  const clearState = () =>{
    setSurgeryName("");
    setSurgeryDate("");
    setSurgeryNote("");
  }
  const addSurgeryRequestHandler = async () => {
    const httpResponse = await sendHttpRequest({
      url: addSurgeryUrl(encounterId),
      method: "POST",
      data: {
        surgicalSite: surgeryName,
        surgicalDate: surgeryDate,
        note: surgeryNote,
      },
    });
    if (!httpResponse.error) {
      fetchSurgery(patientId);
      // console.log(httpResponse.data);
      clearState();
    } else {
      console.log(httpResponse.error);
    }
  };

  const editSurgeryRequestHandler = async () => {
    const httpResponse = await sendHttpRequest({
      url: updateSurgeryUrl(encounterId, updateSurgeryId),
      method: "PUT",
      data: {
        surgeryId: updateSurgeryId,
        surgicalSite: surgeryName,
        surgicalDate: surgeryDate,
        note: surgeryNote,
      },
    });
    if (!httpResponse.error) {
      fetchSurgery(patientId);
      clearState();
      // console.log(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
  };

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const OpeneditModal = () => {
    setOpenEditModal(!openEditModal);
  };

  useEffect(() => {
    fetchSurgery(patientId || props?.patientId);
    return () => {
      clearState();
    }
  }, [patientId, props?.patientId]);

  const handleEditModal = (event) => {
    let id = event.target.id;
    setUpdateSurgeryId(id);
    const surgeryResult = surgery?.filter((surgery) => {
      return surgery.surgeryId == id;
    });

    setEditSurgery(surgeryResult[0]);
    setSurgeryDate(surgeryResult[0].surgicalDate);
    setSurgeryName(surgeryResult[0].surgicalSite);
    setSurgeryNote(surgeryResult[0].note);
    setOpenEditModal(!openModal);
  };

  return (
    <>
      <div
        className="d-flex mb-1 justify-content-between align-items-baseline"
        {...props}
      >
        <div className="d-inline-flex flex-column align-self-center">
          <h4 className="h18">Surgical History</h4>
          {/* <p style={{ color: 'var(--secondary)' }}>Lorem Ipsum dolor asit</p> */}
        </div>
        <div className="d-flex align-self-center " style={{height:"40px", width:"40px"}}>
          {!props?.viewOnly && (
            <img
              style={{ color: "var(--primary)" }}
              className="btn shadow-none"
              onClick={() => handleModal()}
            src={AddButtonIcon}
            alt={"Add Sugery Button"}
            />
          )}
        </div>
      </div>

      {openModal ? <SurgeryModal
        show={openModal}
        handleModal={handleModal}
        setSurgeryName={setSurgeryName}
        setSurgeryDate={setSurgeryDate}
        setSurgeryNote={setSurgeryNote}
        addSurgeryRequestHandler={addSurgeryRequestHandler}
      /> : null}

      {openEditModal ? (
        <SurgeryEditModal
          show={openEditModal}
          handleModal={OpeneditModal}
          setSurgeryName={setSurgeryName}
          setSurgeryDate={setSurgeryDate}
          setSurgeryNote={setSurgeryNote}
          editSurgery={editSurgery}
          editSurgeryRequestHandler={editSurgeryRequestHandler}
        />
      ) : null}
      <TableContainer
        sx={{ borderRadius: "16px", border: "1px solid #D9E8EC" }}
      >
        <Table>
          <TableHead
            sx={{
              borderTopColor: "#FAFCFC",
              borderRightColor: "#FAFCFC",
              borderLeftColor: "#FAFCFC",
              borderBottom: "1px solid #D9E8EC",
            }}
          >
            <StyledTableRow>
              <StyledTableCell>{_str.table_procedure}</StyledTableCell>
              <StyledTableCell>{_str.date}</StyledTableCell>
              <StyledTableCell>{_str.note}</StyledTableCell>
              {!props?.viewOnly && (
                <>
                  {/* <StyledTableCell></StyledTableCell> */}
                  <StyledTableCell></StyledTableCell>{" "}
                </>
              )}
            </StyledTableRow>
          </TableHead>
          {surgery.length>0 ?
          <TableBody>

              {isLoading ? <StyledTableRow>
                <StyledTableCell>
                  <Skeleton animation="wave" />
                </StyledTableCell>
                <StyledTableCell>
                  <Skeleton animation="wave" />
                </StyledTableCell>
                <StyledTableCell>
                  <Skeleton animation="wave" />
                </StyledTableCell>
                {!props?.viewOnly && (
                  <>
                    {" "}
                    {/* <StyledTableCell>
                      <Skeleton animation="wave" />
                    </StyledTableCell> */}
                    <StyledTableCell>
                      <Skeleton animation="wave" />
                    </StyledTableCell>
                  </>
                )}
              </StyledTableRow>
                :
                surgeryStatusCode === 200 ? (
                  surgery?.map((row, index) => {

                    return (
                      <StyledTableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <StyledTableCell sx={{ fontWeight: 600 }}>
                          {row?.surgicalSite !== undefined &&
                            row?.surgicalSite !== ""
                            ? row.surgicalSite
                            : "Not available"}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ color: "#336383" }}
                        >
                          {row?.surgicalDate !== undefined &&
                            row?.surgicalDate !== ""
                            && row?.surgicalDate !== null
                            ? changeDateTimeFormat(
                              row.surgicalDate,
                              "MMM D, YYYY"
                            )
                            : "Not available"}
                        </StyledTableCell>
                        {row.note.length > noteTextSize ?
                          <ColoredToolTip title={row?.note} arrow placement="top">
                            <StyledTableCell
                            // sx={{ maxWidth: 300, minWidth: 200, color: "#336383" }}
                            >
                              {row?.note !== undefined && row?.note !== ""
                                ? truncate(row.note, noteTextSize)
                                : "Not available"}
                            </StyledTableCell>
                          </ColoredToolTip>
                          : <StyledTableCell
                            sx={{ color: "#336383" }}
                          >
                            {row?.note !== undefined && row?.note !== ""
                              ? row.note
                              : "Not available"}
                          </StyledTableCell>
                        }
                        {!props?.viewOnly && (
                          <>
                            {/* <StyledTableCell /> */}
                            <StyledTableCell style={{ cursor: "pointer" }}>
                              <div className="d-flex flex-row col-11 ms-1 justify-content-end" >
                                <div className="row">
                                  <div className="col-4">
                                    <EditIcon
                                      style={{color: "#336383", height: "15px", width: "15px"}}
                                      onClick={(e) => handleEditModal(e)}
                                      id={row.surgeryId}
                                    />
                                  </div>
                                  <div className="col-4">
                                    <TrashIcon
                                      style={{ color: "#A2D1DE", height: "15px", width: "15px", }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </StyledTableCell>
                          </>
                        )}
                      </StyledTableRow>
                    );
                  })
                ) : ''

              }

          </TableBody>
          : <p className="m-2 font-14 p-1">No data available</p>
        }
        </Table>
      </TableContainer>
    
    </>
  );
};
export default SurgeryTable;

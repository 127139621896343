import { useLocation, useNavigate } from "react-router-dom";
import PageHeader from "../../generic/PageHeader";
import ApplePay from "../../../components/payment/ApplePay";
import GooglePayButton from "../../../components/payment/googlePay/GooglePayButton";
import { Box, Divider, Typography } from "@mui/material";
import { PaymentMethodTypes } from "../../../components/payment/MakePaymentModal";
import CurrencyInput from "react-currency-input-field";
import applePayLogo from "../../../assets/icons/apple_pay_icon.png";
import options from "../../../utils/data";
import { Typeahead } from "react-bootstrap-typeahead";
import CCIcon from "../../../assets/icons/CCIcon";
import googleLogo from "../../../assets/logo/google-logo.png";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAuth } from "../../../components/hooks/useAuth";
import sendHttpRequest from "../../../components/hooks/sendHttpRequest";
import { client, hostedFields } from "braintree-web";
import { getPaymentToken, transferPatientPayment, transferPayment } from "../../../res/endpointurls";
import { Stack } from "react-bootstrap";
import classes from "../../../patientportal/patientpages/PatientPaymentPage.module.css";
import { cardsMap } from "../../../utils/helpers/HelperFunctions";
import CustomizedSnackbars from "../../../components/UI/General/CustomizedSnackbars";

const PaymentForm = ({
  setIsPaymentSent,
  amountValue,
  appointment,
  confirmPayment,
  setConfirmPayment,
  setIsLoading,
  setIsAllFieldsFilled,
}) => {
  const navigate = useNavigate();
  const [nameOnCC, setNameOnCC] = useState("");
  const [provider, setProvider] = useState(
    appointment?.appointmentSlot?.providerTenantPracticeLocation?.provider
      ?.userId
  );

  const [creditCardType, setCreditCardType] = useState(null);
  const [hostedFieldsInstance, setHostedFieldsInstance] = useState("");
  const [amount, setAmount] = useState(amountValue ?? 0);
  const [token, setToken] = useState("");
  const [error, setError] = useState("");
  const [tokenizeErr, setTokenizeErr] = useState("");
  const [isSent, setIsSent] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);

  // const [isAllFieldsFilled, setIsAllFieldsFilled] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const confirmButtonRef = useRef();

  const { user } = useAuth();

  const handlePayment = () => {
    setIsLoading(true);
    tokenizeFields();
  };

  const handleReceipt = async (appointmentId) => {
    setIsPrinting(true);
    const httpResponse = await sendHttpRequest({
      url: `/appointments/${appointmentId}/receipt`,
      method: "GET",
      responseType: "arraybuffer",
      params: {
        userId: user?.userId,
      },
    });
    if (!httpResponse.error) {
      const link = document.createElement("a");
      link.target = "_blank";
      link.download = `Receipt_${appointmentId}`;
      const href = URL.createObjectURL(
        new Blob([httpResponse.data], {
          type: "application/pdf",
          encoding: "UTF-8",
        })
      );
      link.href = href;
      link.click();
      link.remove();
      URL.revokeObjectURL(href);
    }
    setIsPrinting(false);
  };

  function displayErrorMessage(container, message) {
    // console.log("container", container);
    // Remove existing error message if present
    removeErrorMessage(container);

    // Create error message element
    var errorMessage = document.createElement("span");
    errorMessage.className = "error-message font-10 text--danger fw-thin";
    errorMessage.textContent = message;
    errorMessage.style.display = "block";
    errorMessage.style.marginTop = "6px";
    errorMessage.style.marginLeft = "-3px";
    setDisableButton(true);

    confirmButtonRef.current.disabled = true;

    // Append error message below the container
    // container.parentNode.insertBefore(errorMessage, container.nextSibling);
    container.appendChild(errorMessage);
  }

  function removeErrorMessage(container) {
    var errorMessage = container.parentNode.querySelector(".error-message");
    if (errorMessage) {
      // Remove error message if found
      if (checkAllFieldsFilled) {
        setDisableButton(false);
        confirmButtonRef.current.disabled = false;
      }
      errorMessage.parentNode.removeChild(errorMessage);
    }
  }

  const checkAllFieldsFilled = (fields) => {
    const fieldNames = Object.keys(fields);
    for (const fieldName of fieldNames) {
      if (fields[fieldName].isEmpty) {
        return false;
      }
    }
    return true;
  };

  const getErrorMessage = (container) => {
    const errorElement = container.querySelector(".error-message");
    if (errorElement) {
      return errorElement.textContent;
    }
    return null;
  };

  const handleFieldBlur = (event) => {
    const field = event.fields[event.emittedBy];

    if (field.isEmpty) {
      displayErrorMessage(field.container, "Field is required");
    } else if (!field.isValid) {
      // Check if there is already an error message and the field is invalid
      const existingErrorMessage = getErrorMessage(field.container);
      if (!existingErrorMessage) {
        displayErrorMessage(field.container, "Invalid field");
      }
    } else {
      removeErrorMessage(field.container);
    }

    const allFieldsFilled = checkAllFieldsFilled(event.fields);
    setIsAllFieldsFilled(allFieldsFilled);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSent(false);
    setError({ show: false });
  };

  const cardNameHolderHandler = (e) => {
    e.preventDefault();
    setNameOnCC(e.target.value);
  };

  const hostedForm = useCallback((token) => {
    client
      .create({
        authorization: token,
      })
      .then(function (clientInstance) {
        hostedFields.create(
          {
            client: clientInstance,
            styles: {
              input: {
                "font-size": "12px",
                "font-style": "Montserrat",
              },
              "input.invalid": {
                color: "#FD0D1B",
              },
              "input.valid": {
                color: "#323232",
              },
              "::-webkit-input-placeholder": {
                color: "#70707070",
              },
              ":-ms-input-placeholder": {
                color: "#70707070",
              },
              // 'input.placeholder': {
              //     'color': "#dddddd"
              // }
            },
            fields: {
              // cardholderName: {
              //     container: '#cardholderName',
              //     placeholder: 'John Doe',
              // },
              number: {
                container: "#card-number",
                // placeholder: '4111 1111 1111 1111',
              },
              cvv: {
                container: "#cvv",
                placeholder: "•••",
              },
              expirationDate: {
                container: "#expiration-date",
                placeholder: "MM/YYYY",
              },
            },
          },
          function (hostedFieldsErr, hostedFieldsInstance) {
            if (hostedFieldsErr) {
              // console.error(hostedFieldsErr);
              return;
            }
            // function findLabel(field) {
            //     return document.querySelector('.hosted-field--label[for="' + field.container.id + '"]');
            // }
            setHostedFieldsInstance(hostedFieldsInstance);
            hostedFieldsInstance.on("notEmpty", function (event) {});
            hostedFieldsInstance.on("empty", function (event) {
              if (event.emittedBy === "number") {
                setCreditCardType(null);
              }
            });
            hostedFieldsInstance.on("cardTypeChange", function (event) {
              // var field = event.fields[event.emittedBy];
              // var label = findLabel(field);
              // console.log(label);
              // if (field.isPotentiallyValid) {
              //     label.classList.remove('invalid');
              // } else {
              //     label.classList.add('invalid');
              // }
              // Change card bg depending on card type
              if (event.cards.length === 1) {
                setCreditCardType(event.cards[0].type);
                //   $('header').addClass('header-slide');

                // Change the CVV length for AmericanExpress cards
                if (event.cards[0].code.size === 4) {
                  hostedFieldsInstance.setAttribute({
                    field: "cvv",
                    attribute: "placeholder",
                    value: "••••",
                  });
                }
              } else {
                hostedFieldsInstance.setAttribute({
                  field: "cvv",
                  attribute: "placeholder",
                  value: "•••",
                });
              }
            });

            hostedFieldsInstance.on("blur", handleFieldBlur);

            // Add event listener for input validation
            hostedFieldsInstance.on("validityChange", function (event) {
              var field = event.fields[event.emittedBy];

              if (field.isValid) {
                // Field is valid, remove error message
                removeErrorMessage(field.container);
              } else if (field.isPotentiallyValid) {
                // Field is potentially valid, remove error message if displayed
                removeErrorMessage(field.container);
              } else {
                // Field is invalid, display error message
                // card-number
                // expiration-date
                // cvv
                if (field.container.id === "card-number") {
                  displayErrorMessage(field.container, "Invalid card number");
                } else if (field.container.id === "expiration-date") {
                  displayErrorMessage(
                    field.container,
                    "Invalid expiration date"
                  );
                }
              }

              const initialFields = hostedFieldsInstance.getState().fields;
              const allFieldsFilled = checkAllFieldsFilled(initialFields);
              setIsAllFieldsFilled(allFieldsFilled);
            });
          }
        );
      });
  });

  const tearDownFields = () => {
    if (hostedFieldsInstance !== "") {
      hostedFieldsInstance.teardown(function (teardownErr) {
        if (teardownErr) {
          // console.error('Could not tear down Hosted Fields!');
        } else {
          // console.info('Hosted Fields has been torn down!');
        }
      });
    }
  };

  async function getToken() {
    const httpResponse = await sendHttpRequest({
      // url: `/braintree/${userId}/get_token`,
      url: getPaymentToken(user?.tenantId),
      method: "GET",
    });
    if (!httpResponse.error) {
      setToken(httpResponse.data);
      hostedForm(httpResponse.data);
      // braintreeClient(httpResponse.data)
      // setIsApptCreated(true);
      // timeExpired();
    } else {
      setError(httpResponse.errorMessage);
      // console.log(httpResponse);
    }
    // getExtras();
  }

  const sendNounce = async (nounce) => {
    const httpResponse = await sendHttpRequest({
      url: transferPatientPayment(user?.userId),

      method: "POST",
      data: {
        nounce: nounce,
        providerId: provider,
        amount: Number(amount),
        nameOnCC: nameOnCC,
        transactionType: "CreditCard",
        appointmentId: appointment?.id,
        userId: user?.userId,
      },
    });
    if (!httpResponse.error) {
      setIsSent(true);
      tearDownFields();
      // fetchBillingHistory(true, {description: "Paid via credit card", due: amount});
      setIsPaymentSent(true);
      setAmount(0);

      // fetchCurrentBill();
    } else {
      if (httpResponse?.status === 500) {
        setError({ message: "Something went wrong", show: true });
      } else {
        setError(
          { ...httpResponse?.errorMessage, show: true } ?? {
            message: "Payment not completed",
            show: true,
          }
        );
      }
    }
    setIsLoading(false);
    // setIsTimeOver(false);
    setConfirmPayment(false);
  };

  const tokenizeFields = () => {
    // let state = hostedFieldsInstance.getState();
    // let fields = Object.keys(state.fields);
    // console.log(state);
    hostedFieldsInstance?.tokenize({}, function (tokenizeErr, payload) {
      if (tokenizeErr) {
        setTokenizeErr(tokenizeErr);
        return;
      } else {
        sendNounce(payload.nonce);
      }
    });
  };

  useEffect(() => {
    if (confirmPayment === true) {
      handlePayment();
    }
  }, [confirmPayment]);
  useEffect(() => {
    getToken();
    return () => {
      // clearStates();
      // tearDownFields();
    };
  }, []);

  useEffect(() => {
    setAmount(amountValue);
  }, [amountValue]);

  useEffect(() => {
    if (isSent) {
      navigate("/");
    }
  }, [isSent]);

  return (
    <>
      <form
        id="credit-card-form"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="col-md-12 mb-2">
          <label
            htmlFor="physician"
            className="form-label m-0 font-10 text--secondary fw-sb"
          >
            Card Holder Name
          </label>
          {/* <div style={{ backgroundColor: "#ffffff", border: "1px solid #D9E8EC", borderRadius: '10px', p: 0 }}>
                            <div id="cardholderName" className={`${classes.hostedFields}`}></div>
                        </div> */}

          <input
            type="text"
            className={`form-control text--terciary refill_input font-12 m-0 border--default br-10 shadow-none input`}
            id="physician"
            onChange={cardNameHolderHandler}
          />
        </div>

        <div className="col-md-12 mb-2">
          <label
            htmlFor="physician"
            className="form-label m-0 font-10 text--secondary fw-sb"
          >
            Credit Card Number
          </label>
          <Stack direction="row" justifyContent={"between"} spacing={2}>
            <div
              className="col-sm-12"
              style={{
                backgroundColor: "#ffffff",
                border: "1px solid #D9E8EC",
                borderRadius: "10px",
                p: 0,
              }}
            >
              <div className="row">
                <div className="col-sm-8 justify-content-start align-self-center">
                  <span className="font-12 fw-sb text--terciary">
                    <div>
                      <div
                        id="card-number"
                        className={`${classes.hostedFields}`}
                      ></div>
                    </div>
                  </span>
                </div>
                <div className="col-sm-4 align-self-center">
                  <div className="d-flex flex-row justify-content-end me-2">
                    {creditCardType !== null && cardsMap(creditCardType)}
                  </div>
                </div>
              </div>
            </div>
          </Stack>
        </div>

        <div className="col-md-10 mb-2">
          <h6 className="text--secondary font-12 fw-sb">Expiration Date</h6>
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-md-6">
              <div className="col-md-12 mb-2">
                <label
                  htmlFor="Month"
                  className="form-label m-0 font-10 text--secondary fw-sb"
                >
                  MM/YYYY
                </label>

                <span className="font-12 fw-sb text--terciary">
                  <div
                    style={{
                      backgroundColor: "#ffffff",
                      border: "1px solid #D9E8EC",
                      borderRadius: "10px",
                      p: 0,
                    }}
                  >
                    <div
                      id="expiration-date"
                      className={`${classes.hostedFields}`}
                    ></div>
                  </div>
                </span>
              </div>
            </div>

            <div className="col-md-6">
              <div className="col-md-12 mb-2">
                <label
                  htmlFor="ccv"
                  className="form-label m-0 font-10 text--secondary fw-sb"
                >
                  {"CVV"}
                  {/* {creditCardType?.code?.name} */}
                </label>

                <span className="font-12 fw-sb text--terciary">
                  <div
                    style={{
                      backgroundColor: "#ffffff",
                      border: "1px solid #D9E8EC",
                      borderRadius: "10px",
                      p: 0,
                    }}
                  >
                    <div id="cvv" className={`${classes.hostedFields}`}></div>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </form>
      {/* {hostedForm()} */}

      <CustomizedSnackbars
        message={"Payment processed successfully."}
        severity="success"
        open={isSent}
        handleClose={handleClose}
      />
      <CustomizedSnackbars
        message={error?.message}
        severity="error"
        open={error?.show}
        handleClose={handleClose}
      />
    </>
  );
};

const BillingAddress = (props) => {
  return (
    <>
      <div className={`row ${props.showHeader !== true ? "mt-3" : ""}`}>
        {props.showHeader ? (
          <div className="col-12 d-flex justify-content-between mb-3">
            <label className="  fw-sb font-16 text--secondary align-self-center">
              {props.title}
            </label>
            <img
              src={props.logo}
              style={
                props?.title === "Google Pay"
                  ? { height: "2em", width: "2em" }
                  : { height: "50px", width: "50px" }
              }
            />
          </div>
        ) : null}
        <div className="col-md-12 mb-2">
          <Typography component="span">
            <Box
              sx={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "12px",
                color: "#336383",
              }}
            >
              Billing Address
            </Box>
          </Typography>
        </div>
        <div className="col-md-12">
          <label
            htmlFor="address"
            className="form-label m-0 font-10 text--secondary fw-sb"
          >
            Address
          </label>
          <input
            type="text"
            className={`form-control text--terciary refill_input font-12 m-0 border--default br-10 shadow-none input`}
            id="address"
            // onChange={addressHandler}
          />
        </div>
        <div className="col-md-4 mb-2">
          <label
            htmlFor="city"
            className="form-label m-0 font-10 text--secondary fw-sb"
          >
            City
          </label>
          <input
            type="text"
            className={`form-control text--terciary refill_input font-12 m-0 border--default br-10 shadow-none input`}
            id="city"
            // onChange={cityHandler}
          />
        </div>
        <div className="col-md-4">
          <div className="col-md-12 mb-2">
            <label
              htmlFor="state"
              className="form-label m-0 font-10 text--secondary fw-sb"
            >
              State
            </label>
            <Typeahead
              id="state"
              labelKey="code"
              //   onChange={setSelectedState}
              options={options}
              className="rbt-menu my-select"
              style={{
                borderRadius: "10px",
                fontSize: 12,
              }}
              autoComplete="false"
              onInvalid={(e) => {
                e.target.setCustomValidity("Please fill out this field");
              }}
              onInput={(e) => {
                e.target.setCustomValidity("");
              }}
              inputProps={{
                // required: true,
                className: "font-12",
              }}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="col-md-12 mb-2">
            <label
              htmlFor="ZipCode"
              className="form-label m-0 font-10 text--secondary fw-sb"
            >
              ZipCode
            </label>
            <input
              type="text"
              className={`form-control text--terciary refill_input font-12 m-0 border--default br-10 shadow-none input`}
              id="zipcode"
              autoComplete="false"
              minLength="5"
              maxLength="5"
              //   onChange={zipcodeHandler}
            />
          </div>
        </div>
      </div>
    </>
  );
};
const CurrencyInputField = (props) => {
  const location = useLocation();
  const handleServiceFee = (valueInp) => {
    console.log(
      "valueInp: ",
      valueInp,
      props.copayValue,
      location.state.amountValue
    );
    let value = Number(valueInp);
    props.setAmountInput(valueInp);
    if (props.copayValue > 0 && value < props.copayValue) {
      console.log("I'm if");
      props.setIsValidAmount(false);
    } else if (
      props.copayValue === 0 &&
      value < Number(location.state.amountValue)
    ) {
      console.log("I'm else if");
      props.setIsValidAmount(false);
    } else {
      console.log("I'm else");
      props.setIsValidAmount(true);
    }
  };
  return (
    <>
      <CurrencyInput
        id="cc_amount"
        name="cc_amount"
        placeholder="$0.00"
        className="text--terciary text-end font-12 input form-control shadow-none border--default br-10"
        value={props.amountInput}
        decimalsLimit={2}
        fixedDecimalLength={2}
        prefix="$"
        decimalSeparator="."
        groupSeparator=","
        intlConfig={{ locale: "en-US", currency: "USD" }}
        onValueChange={handleServiceFee}
        align={"right"}
        onBlur={() => {
          props.setAmountInput(props.amountInput);
        }}
        // disabled = {true}
      />
    </>
  );
};
const PatientPaymentsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  console.log("location.state.amountDue: ", location.state.amountDue);
  const { user } = useAuth();

  const [paymentType, setPaymentType] = useState();
  const [isPaymentSent, setIsPaymentSent] = useState(false);
  const [prescribersList, setPrescribersList] = useState([]);
  const [isPrescriberLoading, setIsPrescriberLoading] = useState(false);
  const [confirmPayment, setConfirmPayment] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const appointment = location.state.appointment;

  const [amountInput, setAmountInput] = useState(
    location.state.amountDue ?? 0
  );
  const [amountDue, setAmountDue] = useState(0);
  const [copayValue, setCopayValue] = useState(0.0);
  const [isValidAmount, setIsValidAmount] = useState(true);
  const [isPaymentDisabled, setIsPaymentDisabled] = useState(false);
  const [isAllFieldsFilled, setIsAllFieldsFilled] = useState(false);
  const [totalDues, setTotalDues] = useState(0);

  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [selectedState, setSelectedState] = useState([]);
  const [zipcode, setZipcode] = useState("");
  const addressHandler = (e) => {
    setAddress(e.target.value);
  };

  const cityHandler = (e) => {
    setCity(e.target.value);
  };

  const zipcodeHandler = (e) => {
    setZipcode(e.target.value);
  };
  const handleServiceFee = (valueInp) => {;
    let value = Number(valueInp);
    setAmountInput(valueInp);
    console.log("value: ",value,Number(value));
    if(Number(value) > 0 || isNaN(Number(value)) === true){
        setIsValidAmount(true); // for unhiding the error message
    }else if(Number(value) <= 0 ){
        setIsValidAmount(false);
    }
  };

  return (
    <>
      <PageHeader title={"Make Payment"} />
      <div className="container mb-3">
        <div className="row mt-3 justify-content-center">
          {/* Right Section */}
          <div className="col-4 border--right">
            <div className="container mt-2">
              <div className="row">
                <div className="form-group mt-1">
                  <PaymentMethodTypes setPaymentType={setPaymentType} />
                </div>
                <div
                  className="col-12 mt-3 pb-3"
                  style={
                    paymentType !== undefined
                      ? { border: "2px solid #336383", borderRadius: "7px" }
                      : {}
                  }
                >
                  {paymentType === "creditCard" ? (
                    <>
                      <div className="col-12" data-toggle="buttons">
                        <div className="d-flex flex-row justify-content-between  mt-3">
                          <label
                            htmlFor="cc"
                            className={`fw-sb font-16 text--secondary`}
                          >
                            Credit Card
                          </label>
                          <CCIcon />
                        </div>
                      </div>
                      <PaymentForm
                        setIsPaymentSent={setIsPaymentSent}
                        isPaymentSent={isPaymentSent}
                        amountValue={amountInput}
                        userId={user?.id}
                        prescribersList={prescribersList}
                        isPrinting={false}
                        appointment={appointment}
                        //   handleModal={handleModal}
                        patientPortal={true}
                        hideTags={true}
                        confirmPayment={confirmPayment}
                        setConfirmPayment={setConfirmPayment}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        setIsAllFieldsFilled={setIsAllFieldsFilled}
                      />
                      <Divider className="mt-3" />
                      <BillingAddress showHeader={false} />
                      <Divider className="mt-3" />
                      <div className="row justify-content-center mt-3">
                        <div className="col-9 text-center">
                          <span className="font-12">Powered by </span>
                          <img
                            width={90}
                            src="https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-200px.png"
                          />
                        </div>
                      </div>
                    </>
                  ) : paymentType === "googlePay" ? (
                    <div className="mt-2">
                      <BillingAddress
                        showHeader={true}
                        logo={googleLogo}
                        title={"Google Pay"}
                      />
                      <GooglePayButton
                        setIsPaymentSent={setIsPaymentSent}
                        isPaymentSent={isPaymentSent}
                        amountValue={amountInput}
                        userId={user?.userId}
                        prescribersList={prescribersList}
                        isPrinting={false}
                        patientPortal={true}
                        billingProvider={
                          appointment?.appointmentSlot
                            ?.providerTenantPracticeLocation?.provider?.userId
                        }
                        appointmentId={appointment?.id}
                        isCopPay={false}
                        isValidAmount={!isPaymentDisabled}
                        handleModal={() => {
                          navigate("/");
                        }}
                        isPatientPayment={true}
                      />
                    </div>
                  ) : paymentType === "applePay" ? (
                    <>
                      <BillingAddress
                        showHeader={true}
                        logo={applePayLogo}
                        title={"Apple Pay"}
                      />
                      <ApplePay
                        patientPortal={true}
                        billingProvider={
                          appointment?.appointmentSlot
                            ?.providerTenantPracticeLocation?.provider?.userId
                        }
                        appointmentId={appointment?.id}
                        amountValue={amountInput}
                        setIsPaymentSent={setIsPaymentSent}
                        isPaymentSent={isPaymentSent}
                        // appointmentDto={JSON.parse(localStorage.getItem("apptDto"))}
                        isCopPay={false}
                        userId={user?.id}
                        prescribersList={prescribersList}
                        //   handleModal={handleModal}
                      />
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {/* Left Section */}
          <div className="col-4">
          <div className="col-12 mt-1">
              <label htmlFor="amount" className="font-12 text--secondary fw-sb">
                {"Amount to Pay"}
              </label>
              
              <CurrencyInput
                id="cc_amount"
                name="cc_amount"
                placeholder="$0.00"
                className="text--terciary text-end font-12 input form-control shadow-none border--default br-10"
                value={amountInput}
                decimalsLimit={2}
                fixedDecimalLength={2}
                prefix="$"
                decimalSeparator="."
                groupSeparator=","
                intlConfig={{ locale: "en-US", currency: "USD" }}
                onValueChange={(value)=>{handleServiceFee(value)}}
                align={"right"}
                onBlur={()=>{handleServiceFee(amountInput)}}
                // disabled = {true}
              />
              {/* <CurrencyInputField copayValue={copayValue} setAmountInput={setAmountInput} setIsValidAmount={setIsValidAmount} amountInput={amountInput}/> */}
            </div>
            <span className="text--danger font-12" hidden={isValidAmount}>{"Invalid amount"}</span>
            <div className="row d-flex flex-row justify-content-between mt-3">
              <div className="col-5">
                <button
                  type="btn"
                  className={`btn fw-sb font-12 shadow-none ${classes.cancel_button} text--danger b2 br-10`}
                  // form="credit-card-form"
                    disabled={isLoading}
                  value={true}
                  onClick={() => navigate(-1)}
                >
                  {"Cancel"}
                </button>
              </div>
              <div className="col-5" hidden={paymentType !== "creditCard"}>
                <button
                  type="btn"
                  //   ref={confirmButtonRef}
                  className={`btn fw-sb font-12 ${classes.task_button} text--white b2 br-10 shadow-none`}
                  form="credit-card-form"
                  disabled={isLoading ? true : isAllFieldsFilled === false ? true: Number(amountInput) <= 0  ? true : isPaymentDisabled }
                  value={true}
                  onClick={() => {
                    setConfirmPayment(true);
                  }}
                >
                  {"Confirm"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientPaymentsPage;

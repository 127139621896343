
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";
// import moment from "moment";
import format from "date-fns/format";
import moment from "moment-timezone";

import "react-datepicker/dist/react-datepicker.css";

function range(start, end, increment) {
  const list = [];
  for (let i = start; i < end; i = i + increment) {
    list.push(start++);
  }
  return list;
}

const CustomDatePicker = (props) => {
  const [startDate, setStartDate] = useState(props?.date);
  let years;
  if (props?.MedicalLicense) {
    years = range(getYear(new Date()), getYear(new Date()) + 10, 1);
  }
  else {
    if (props?.viewSchedule === true) {
      years = range(getYear(new Date()) - 3, getYear(new Date()) + 3, 1);
    } else if (props?.publishSchedule === true) {
      years = range(getYear(new Date()), getYear(new Date()) + 3, 1);
    } else {
      years = props?.showNextYear ? range(1900, getYear(new Date()) + 2, 1) : range(1900, getYear(new Date()) + 1, 1);
    }
  }
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // commenting this code to for service date
  // if (props?.date > startDate) {
  //   setStartDate(props?.date);
  // } 
  useEffect(() => {
    if (props?.prescribeDrug) {
      setStartDate(props?.date);
    }
  }, [props?.date, props?.prescribeDrug])
  useEffect(() => {
    setStartDate(startDate);
  }, [startDate, props?.prescribeDrug])


  return (
    <DatePicker
      className={
        props?.borderColor ?
          "form-control font-13 shadow-none datepicker border--white br-10 fw-sb" :
          props?.f13 ?
            "form-control font-13 shadow-none input border--default br-10 " :
            props?.f12 ?
              "form-control font-12 shadow-none input border--default br-10 " :
              "form-control font-14 shadow-none input border--default br-10 "
      }
      dateFormat={props?.dateFormat ? props.dateFormat : "MM/d/yyyy"}
      required={props?.required ? true : false}
      maxDate={props?.maxDate ? props?.maxDate : ''}
      minDate={props?.minDate ? props?.minDate : ''}
      ref={props?.InputRef}
      {...(props?.clearable === true && { isClearable: true })}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        // changeDate(parseISO(dateTimeHelper(date, "YYYY-MM-DD"))),
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              {"<"}
            </button> */}

          <select
            value={months[getMonth(date)]}
            // value={months[getMonth(parseISO(dateTimeHelper(date, "YYYY-MM-DD")))]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
            // default = {props.defaultValue}
            selected={props.defaultValue ? moment(props.defaultValue, 'mm/dd/yyyy') : moment()}
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <select
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          {/* <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {">"}
            </button> */}
        </div>
      )}

      placeholderText={props?.placeholder ? props?.placeholder : props.dateFormat ? props.dateFormat : "mm/dd/yyyy"}
      selected={startDate}
      name={props?.name}
      id={props?.id}
      onChange={(date) => { setStartDate(date); date != null ? props.dobHandler(format(date, "yyyy-MM-dd")) : props.dobHandler(null); }}
    />
  );
};

export default CustomDatePicker;
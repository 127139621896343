
import { useEffect, useState } from 'react';
import { X } from 'react-bootstrap-icons';

import NdcCodeSearch from '../ndc-search/NdcCodeSearch';
import useNdcCodeSearch from '../ndc-search/useNdcCodeSearch';
import CalenderDatePicker from '../UI/General/CalenderDatePicker';
import useCptCodeSearch from '../cptcodes-search/useCptCodeSearch';
import CptCodeSearch from '../cptcodes-search/CptCodeSearch';
import { PnMultiSelect } from '../UI/PnMultiSelect';
import FeeScheduleConfirmationModal from '../../claim-management-module/src/components/UI/Modals/FeeScheduleConfirmationModal';
import useModal from '../hooks/useModal';
import sendHttpRequest from '../hooks/sendHttpRequest';
import { useAuth } from '../hooks/useAuth';
import TextArea from '../forms/TextArea';

const modifier = [
    { label: '21', value: '21' },
    { label: '22', value: '22' },
    { label: '26', value: '26' },
    { label: '50', value: '50' },
    { label: '52', value: '52' },
    { label: '53', value: '53' },
    { label: '60', value: '60' },
    { label: '62', value: '62' },
    { label: '80', value: '80' },
    { label: '82', value: '82' },
    { label: 'P1-P6', value: 'P1-P6' },
    { label: '24', value: '24' },
    { label: '25', value: '25' },
    { label: '51', value: '51' },
    { label: '57', value: '57' },
    { label: '58', value: '58' },
    { label: '59', value: '59' },
    { label: '76', value: '76' },
    { label: '77', value: '77' },
    { label: '78', value: '78' },
    { label: '79', value: '79' },
    { label: '91', value: '91' },
    { label: 'E1-E4', value: 'E1-E4' },
    { label: 'FA', value: 'FA' },
    { label: 'F1-F9', value: 'F1-F9' },
    { label: 'LC', value: 'LC' },
    { label: 'LD', value: 'LD' },
    { label: 'LT', value: 'LT' },
    { label: 'RC', value: 'RC' },
    { label: 'RT', value: 'RT' },
    { label: 'TA', value: 'TA' },
    { label: 'T1-T9', value: 'T1-T9' }
];



export default function ClaimService(props) {
    const { user } = useAuth();
    const [diagnosisOptions, setDiagnosisOptions] = useState([]);


    const { cptSuggestions, setCptSuggestions, isCptLoading, setIsCptLoading, isCptError, setIsCptError, cptSearchInputValue, setCptSearchInputValue, cptCodeName, setCptCodeName, selectedCptCode, setSelectedCptCode, searchCptCode, handleCptCodeSearchInput, handleAddCptCode, handleRemoveCptCode } = useCptCodeSearch();

    const { ndcCodeSuggestions,
        setNdcCodeSuggestions,
        isNdcCodeLoading,
        setIsNdcCodeLoading,
        isNdcCodeError,
        setIsNdcCodeError,
        ndcCodeSearchInputValue,
        setNdcCodeSearchInputValue,
        ndcCodeName,
        setNdcCodeName,
        selectedNdcCode,
        setSelectedNdcCode,
        handleNdcCodeSearchInput,
        handleAddNdcCode,
        handleRemoveNdcCode } = useNdcCodeSearch();

    const [feeSchedule, setFeeSchedule] = useState({});

    const [cptCodeToFeeSchedule, setCptCodeToFeeSchedule] = useState(null);


    const [amount, setAmount] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [quantityError, setQuantityError] = useState(false);
    const [total, setTotal] = useState(null);
    const [enableFields, setEnableFields] = useState(false);
    const { open, handleModal } = useModal(false);
    const [note, setNote] = useState("");
    const [noteLengthError, setNoteLengthError] = useState(false);



    const [cptCodesList, setCptCodesList] = useState([]);
    const addCptCodeToList = (addedCptCode) => {
        handleAddCptCode(addedCptCode);
        props?.setServiceDto(previousObject => ({ ...previousObject, "hcpcsProcedureCode": addedCptCode?.code, "hcpcsProcedureDescription": addedCptCode?.description }))
        setCptCodeToFeeSchedule({ serviceNumber: props?.serviceNumber, cptCode: addedCptCode });
        setCptCodesList(prevList => [...prevList, addedCptCode]);
    }

    const removeCptCodeFromList = (removedCptCode) => {
        handleRemoveCptCode(removedCptCode);
        const tempList = cptCodesList.filter(cpt => cpt.code !== removedCptCode);
        setCptCodesList(prevList => [...tempList]);

    }


    const [selectedModifiers, setSelectedModifiers] = useState([]);
    const [selectedDiagnosis, setSelectedDiagnosis] = useState([]);


    const handleSelectChange = (selectedOptions) => {
        setSelectedModifiers(selectedOptions);
        let modifiers = "";
        selectedOptions?.forEach((option, index) => {
            modifiers += option?.value;
            if (index < selectedOptions.length - 1) {
                modifiers += ",";
            }
        });
        props?.setServiceDto(previousObject => ({ ...previousObject, "modifiers": modifiers }))

    };


    const handleDiagnosisSelectChange = (selectedOptions) => {
        setSelectedDiagnosis(selectedOptions);
        const recentlyAdded = selectedOptions?.at(-1);
        if (recentlyAdded) {
            const diagnosis = props?.diagnoses?.find(d => d?.diagnosisId == recentlyAdded?.value);
            delete diagnosis?.diagnosisId;
            if (props?.serviceDto?.diagnoses === undefined) {
                props?.setServiceDto(previousObject => ({ ...previousObject, "diagnoses": [diagnosis] }))
            }
            else {
                props?.setServiceDto(previousObject => ({ ...previousObject, "diagnoses": [...previousObject?.diagnoses, diagnosis] }))
            }
        }
        // console.log(diagnosisPointers);

        // props?.setServiceDto(previousObject => ({...previousObject}))
    };


    async function getFeeSchedule(tenantId, code, serviceNumber) {
        const httpResponse = await sendHttpRequest({ method: 'GET', url: `/claims/tenants/${tenantId}/fee-schedule/${code}` });
        if (httpResponse.status == 200) {
            setFeeSchedule(prevFeeSchedule => ({
                ...prevFeeSchedule,
                [serviceNumber]: { ...httpResponse.data }
            }));
            // console.log(httpResponse.data);
            setAmount(parseFloat(httpResponse?.data?.price));
            setTotal(parseFloat(httpResponse?.data?.price * quantity));
            props?.setServiceDto(previousObject => ({ ...previousObject, "serviceNumber": props?.serviceNumber }));
            props?.setServiceDto(previousObject => ({ ...previousObject, "charges": httpResponse?.data?.price || '' }));
            props?.setServiceDto(previousObject => ({ ...previousObject, "total": httpResponse?.data?.price * quantity || '' }));
            // setAmount(props?.feeSchedule[props?.serviceNumber]?.price);
            // setTotal(props?.feeSchedule[props?.serviceNumber]?.price * quantity || '');
            // props?.setServiceDto(previousObject => ({ ...previousObject, "serviceNumber": props?.serviceNumber }))
        }
        else if (httpResponse.status == 404) {
            // setEnableFields(previousValue => !previousValue);
            setFeeSchedule(prevFeeSchedule => ({
                ...prevFeeSchedule,
                [serviceNumber]: { ...httpResponse.data }
            }));
            handleModal();
        }
    }


    function handleStartDate(date) {

        props?.setServiceDto(previousObject => ({ ...previousObject, "dateOfServiceFrom": date }))


    }

    function handleEndDate(date) {

        props?.setServiceDto(previousObject => ({ ...previousObject, "dateOfServiceTo": date }))


    }

    useEffect(() => {
        // console.log("cptCodeToFeeSchedule", cptCodeToFeeSchedule);
        if (cptCodeToFeeSchedule !== null && cptCodeToFeeSchedule?.cptCode !== null) {
            getFeeSchedule(user?.tenantId, cptCodeToFeeSchedule?.cptCode?.code, cptCodeToFeeSchedule?.serviceNumber)
        }
    }, [cptCodeToFeeSchedule])





    useEffect(() => {

        const diagnosisToShow = props.diagnoses.map((diagnosis) => ({
            value: diagnosis.diagnosisId,
            label: `${diagnosis?.code} - ${diagnosis.description}`,
            code: `${diagnosis?.code}`
        }));
        setDiagnosisOptions(prev => diagnosisToShow);
    }, [props?.diagnoses])




    return <>
        {open ? <FeeScheduleConfirmationModal
            key={props?.serviceNumber}
            show={open}
            feeSchedule={feeSchedule}
            handleModal={handleModal}
            setAmount={setAmount}
            amount={amount}
            quantity={quantity}
            serviceNumber={props?.serviceNumber}
            setQuantity={setQuantity}
            setTotal={setTotal}
            total={total}
            selectedCptCode={selectedCptCode}
            setServiceDto={props?.setServiceDto}
        /> : null}

        <div className='row pt-3'>
            <div className='col-12'>
                <h2 className='font-16 fw-sb m-0 p-0'>Service {props.serviceNumber}</h2>
            </div>
        </div>
        {/* Cpt and Diagnoses section */}
        <div className='row py-2'>
            <div className='col-12 col-sm-6'>
                <span className='d-block font-12 text--secondary '>
                    Diagnoses  <span>&#42;</span>
                </span>


                <PnMultiSelect onChange={handleDiagnosisSelectChange}
                    name="diagnosis"
                    isMulti
                    closeMenuOnSelect={false}
                    options={diagnosisOptions}
                    value={selectedDiagnosis}
                />
            </div>
            <div className='col-12 col-sm-6'>
                <span className='fw-thin ms-1 font-12 text--secondary d-block'>CPT Codes  <span>&#42;</span></span>
                <CptCodeSearch
                    suggestions={cptSuggestions}
                    setSuggestions={setCptSuggestions}
                    isLoading={isCptLoading}
                    setIsLoading={setIsCptLoading}
                    isError={isCptError}
                    setIsError={setIsCptError}
                    searchInputValue={cptSearchInputValue}
                    setSearchInputValue={setCptSearchInputValue}
                    cptCodeName={cptCodeName}
                    setCptCodeName={setCptCodeName}
                    selectedCptCode={selectedCptCode}
                    setSelectedCptCode={setSelectedCptCode}
                    searchCptcode={searchCptCode}
                    handleSearchInput={handleCptCodeSearchInput}
                    handleAddCptCode={addCptCodeToList}
                    handleClearCptCode={removeCptCodeFromList}
                />
                {cptCodesList?.map((cpt, index) => (
                    <div key={cpt.code} className='font-12 px-2 py-1 text--terciary d-flex justify-content-between'>
                        <div>{`${cpt?.code} - ${cpt.description} `}</div>
                        <div className='cross-sign' onClick={() => removeCptCodeFromList(cpt.code)}><X color="#aa0505" size={20} /></div>
                    </div>
                ))}
            </div>
        </div>
        {/* Ndc and Modifiers section */}
        <div className='row py-2'>
            <div className='col-12 col-sm-6'>

                <span className='fw-thin ms-1 font-12 text--secondary d-block'>NDC Codes</span>
                <NdcCodeSearch
                    suggestions={ndcCodeSuggestions}
                    setSuggestions={setNdcCodeSuggestions}
                    isError={isNdcCodeError}
                    setIsError={setIsNdcCodeError}
                    isLoading={isNdcCodeLoading}
                    setIsLoading={setIsNdcCodeLoading}
                    handleAddNdcCode={handleAddNdcCode}
                    handleSearchInput={handleNdcCodeSearchInput}
                    searchInputValue={ndcCodeSearchInputValue}
                    setSearchInputValue={setNdcCodeSearchInputValue}
                    handleAddCptCode={handleAddCptCode}
                    ndcCodeName={ndcCodeName}
                    setNdcCodeName={setNdcCodeName}
                    selectedNdcCode={selectedNdcCode}
                    setSelectedNdcCode={setSelectedNdcCode}
                />

            </div>
            <div className='col-12 col-sm-6'>
                <span className='fw-thin ms-1 font-12 text--secondary d-block'>Modifiers <span>&#42;</span> </span>

                <PnMultiSelect onChange={handleSelectChange}
                    name="modifier"
                    isMulti

                    closeMenuOnSelect={false}
                    options={modifier}
                    value={selectedModifiers}
                />


            </div>
        </div>
        {/* Last section */}
        <div className='row py-2'>
            <div className='col-sm-3'>
                <span className='fw-thin ms-1 font-12 text--secondary d-block'>Start Date  <span>&#42;</span></span>
                <CalenderDatePicker dobHandler={handleStartDate} disableFuture={true} />
            </div>
            <div className='col-sm-3'>
                <span className='fw-thin ms-1 font-12 text--secondary d-block'>End Date  <span>&#42;</span></span>
                <CalenderDatePicker dobHandler={handleEndDate} disableFuture={true} />
            </div>
            <div className='col-sm-2'>
                <span className='fw-thin ms-1 font-12 text--secondary d-block'>Amount  <span>&#42;</span></span>
                <input value={amount} onChange={e => { setAmount(parseFloat(e.target.value)); setTotal(e.target.value * quantity); props?.setServiceDto(previousObject => ({ ...previousObject, "total": (e.target.value * quantity), "charges": e.target.value })) }} type="text" className='form-control font-14 m-0 border--default br-10 shadow-none input' />
            </div>
            <div className='col-sm-2'>
                <span className='fw-thin ms-1 font-12 text--secondary d-block'>Quantity  <span>&#42;</span></span>
                <input value={quantity} onChange={(event) => {
                    const value = event.target.value;
                    // Ensure the input is a positive integer
                    const isValidQuantity = /^\d+$/.test(value) && parseInt(value, 10) > 0;

                    if (isValidQuantity) {
                        setQuantityError(false);
                        setQuantity(value);
                        setTotal(value * amount);
                        props?.setServiceDto(previousObject => ({ ...previousObject, "quantity": value }));
                        props?.setServiceDto(previousObject => ({ ...previousObject, "total": (value * amount) }));
                    } else {
                        // Handle invalid input: you can set an error message, reset the input, etc.
                        setQuantityError("Quantity must be a positive integer and cannot be zero or a decimal.");
                        setQuantity("");  // Reset the input value
                        setTotal(0);  // Reset the total value
                        props?.setServiceDto(previousObject => ({ ...previousObject, "quantity": 1 }));
                        props?.setServiceDto(previousObject => ({ ...previousObject, "charges": 0 }));
                    }
                }} type="text" className='form-control font-14 m-0 border--default br-10 shadow-none input' />

            </div>
            <div className='col-sm-2'>
                <span className='fw-thin ms-1 font-12 text--secondary d-block'>Total</span>
                <input disabled value={total} type="text" className='form-control font-14 m-0 border--default br-10 shadow-none input' />
            </div>
            <div className='col-sm-12'>
                {quantityError !== false ? <span className='font-10 text--danger fw-thin'>{quantityError}</span> : null}
            </div>
            <div className='col-sm-12'>
                <span className='fw-thin ms-1 font-12 text--secondary d-block'>Note</span>
                <TextArea
                    className="form-control br-10 input font-14 border--default shadow-none"
                    gettext={(e) => {
                        setNote(e)
                        props?.setServiceDto(previousObject => ({ ...previousObject, "note": e }));
                        if (e?.length <= 80) {
                            setNoteLengthError(false);
                        }
                        else {
                            setNoteLengthError(true)
                        }
                    }}
                    rows={3}
                    maxLength={210}
                />
                {noteLengthError === true ? <span className='font-10 ms-1 text--danger fw-thin d-block mt-1-'>Note cannot exceed 80 characters</span> : null}
            </div>
        </div>
    </>;
}
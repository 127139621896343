import { Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import IconFile from "../../assets/icons/Icon-file.svg";
import { dateTimeHelper } from "../../utils/dateTimeHelper";
import { truncate } from "../../utils/helpers/HelperFunctions";
import { RolesTextConversion } from "../../utils/helpers/RolesMap";
import sendHttpRequest from "../hooks/sendHttpRequest";
import PatientProfileMessageContainer from "./PatientProfileMessageContainer";

const PatientProfileMessageItem = (props) => {
  const [userData, setUserData] = useState();
  const [isUserDataLoading, setIsUserDataLoading] = useState();
  const [showMessageThread, setShowMessageThread] = useState(false);
  const [activeMessageItem, setActiveMessageItem] = useState(false);

  const fetchUserData = async (userId) => {
    setIsUserDataLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/auth/user-info",
      method: "GET",
      params: {
        userid: userId,
      },
    });
    if (!httpResponse.error) {
      setUserData(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
    setIsUserDataLoading(false);
  };
  const handleMessageReadStatus = async () => {
    const httpResponse = await sendHttpRequest({
      url: "/markRead",
      method: "GET",
      params: {
        threadId: props?.messageItem?.threadId,
      },
    });
    if (!httpResponse.error) {
      // console.log("Message Read");
    } else {
      console.log(httpResponse.error);
    }
  };
  const handleActiveMessageItem = (event) => {
    props?.setActiveThreadId(props?.messageItem?.threadId);
    setActiveMessageItem(true);
  };

  // const handleMessageThread = () => {
  //   setShowMessageThread(!showMessageThread);
  // };
  useEffect(() => {
    fetchUserData(props?.messageItem?.from);
  }, [props?.messageItem?.from]);

  return (
    <>
      <div>
        <div
          className={"row py-2 px-3 border--bottom cursor--pointer"}
          onClick={() => {
            handleActiveMessageItem();
            // handleMessageReadStatus(props?.messageItem?.threadId);
            props?.handleMessageThread();
          }}
        >
          <div className="col-12 ">
            <div className="d-flex flex-row justify-content-between ">
              <div className="d-flex">
                {isUserDataLoading ? (
                  <Skeleton style={{ width: "2vw" }} />
                ) : (
                  <>
                    <div
                      // className={"me-2 font-14 text--terciary fw-sb"}
                      className={
                        // props?.messageItem?.status !== "READ"
                        //   ?
                        "me-2 font-14 text--terciary fw-sb"
                        // : "me-2 font-14 text--terciary"
                      }
                    >
                      <span
                      // style={activeMessageItem ? { fontWeight: "400" } : {}}
                      >
                        {
                          userData?.name?.at(0)?.firstName + " " + userData?.name?.at(0)?.lastName
                        }
                      </span>
                    </div>

                    <div>
                      <span
                        className="text--secondary font-14 ps-2 border--left"

                      >
                        {RolesTextConversion(userData?.role)}
                      </span>
                    </div>
                    {/* <div className="ms-4 rounded-circle font-12 text--secondary fw-sb px-2 bg--d9e8ec">
                        10
                      </div> */}
                  </>
                )}
              </div>
              <div className="d-flex">
                <div>
                  {props?.messageItem?.attachment !== undefined &&
                    props?.messageItem?.attachment !== null && (
                      <img
                        className={`icon--default`}
                        src={IconFile}
                        alt={"file icon"}
                      ></img>
                    )}
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex justify-content-between">
                <div
                  // className={"me-2 font-16 text--terciary"}
                  className={
                    // props?.messageItem?.status !== "READ"
                    //   ?
                    "me-2 font-14 text--terciary fw-sb"
                    // : "me-2 font-14 text--terciary"
                  }
                >
                  <span
                  // style={activeMessageItem ? { fontWeight: "400" } : {}}
                  >
                    {props?.messageItem?.subject}
                  </span>
                </div>
                <div className="text--secondary font-14">
                  {/* Jan 5, 2022 - 10:34 am */}
                  {dateTimeHelper(
                    props?.messageItem?.deliveryTime,
                    "MMM DD, YYYY - LT"
                  )}
                </div>
              </div>
            </div>
            <div className="font-14 text--secondary">
              {/*Body*/}
              {truncate(props?.messageItem?.body, 120)}
            </div>
          </div>

          {/* {console.log("activeMessageItem",activeMessageItem)} */}
        </div>

        {/* <PatientProfileMessageContainer
          show={showMessageThread}
          handleclose={handleMessageThread}
          placement={"bottom"}
          backdrop={false}
          threadId={props?.messageItem?.threadId}
        /> */}
      </div>
    </>
  );
};

export default PatientProfileMessageItem;

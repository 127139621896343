import { useEffect, useState } from "react";
import PageHeader from "../../patientportal/generic/PageHeader";
import { Accordion, AccordionDetails, AccordionSummary } from "../UI/Reusable/PnComponents";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import PatientBillingTable from "../patient/billing/PatientBillingTable";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { useAuth } from "../hooks/useAuth";

const appointmentTableRows = [
    { "Appointments": "Data source for building appointment-focused reports", link: "/reports/appointments" },
    { "Forced Appointments": "Find all appointments which are currently forced in", link: "/reports/forced" },
    { "Huddle Sheet": "View today's appointments with relevant details to help plan for the day", link: "/reports/huddle-sheet" }
]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#FAFCFC",
        color: "#336383",
        fontWeight: 400,
        fontSize: 12,
        fontFamily: "Montserrat",
        // width: 150,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        paddingRight: "0px",
        fontFamily: "Montserrat",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: "#FAFCFC",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));


const ReportsPage = () => {
    const {user} = useAuth();
    const [allReportsAccordion, setAllReportsAccordion] = useState(false);
    const [appointmentAccordion, setAppointmentAccordion] = useState("Appointment");
    const [billingAccordion, setBillingAccordion] = useState(false);
    const [clinicalAccordion, setClinicalAccordion] = useState(false);
    const [dataSourceAccordion, setDataSourceAccordion] = useState(false);
    const navigate = useNavigate();

    const handleChange = (panel) => (event, newExpanded) => {
        if (panel === "All Reports") {
            setAllReportsAccordion(newExpanded ? panel : false);
        } else if (panel === "Appointment") {
            setAppointmentAccordion(newExpanded ? panel : false);
        } else if (panel === "Billing") {
            setBillingAccordion(newExpanded ? panel : false);
        } else if (panel === "Clinical") {
            setClinicalAccordion(newExpanded ? panel : false);
        } else if (panel === "Data Source") {
            setDataSourceAccordion(newExpanded ? panel : false);
        }
    };
    const [billingHistoryData, setBillingHistoryData] = useState([]);
    const [isBillinghistoryLoading, setIsBillinghistoryLoading] = useState(false);
    
    return (<>
        <PageHeader title="Reports" />
        <div className="container-fluid">
            <div className="row justify-content-center mt-3 ">
                <div className='col-sm-10 border--default br-10 '>
                    {/* <Accordion
                        expanded={allReportsAccordion === "All Reports"}
                        onChange={handleChange("All Reports")}
                        defaultExpanded={true}
                    >
                        <AccordionSummary
                            aria-controls="allreports-content"
                            id="allreports-header"
                        >
                            <h2 className="font-16 fw-sb m-0" id="all-reports-accordion">
                                All Reports
                            </h2>
                        </AccordionSummary>
                        {allReportsAccordion ? <AccordionDetails>
                            <button className="blu-task-btn btn" onClick={()=>{navigate("/reports-search")}}>{"Click me"}</button>

                        </AccordionDetails> : null}
                    </Accordion> */}

                    <Accordion
                        expanded={appointmentAccordion === "Appointment"}
                        onChange={handleChange("Appointment")}
                        defaultExpanded={true}
                    >
                        <AccordionSummary
                            aria-controls="appointment-content"
                            id="appointment-header"
                        >
                            <h2 className="font-16 fw-sb m-0" id="appointment-accordion">
                                Appointment
                            </h2>
                        </AccordionSummary>
                        {appointmentAccordion ? <AccordionDetails>
                            <div className="container-fluid">
                                <div className="row ">
                                    <div className="col-12">
                                        <AppointmentsAccordionDetials
                                            columnHeadersList={['Name', 'Description']}
                                            appointmentTableRows={appointmentTableRows}
                                            navigateTo={(item) => {
                                                console.log(item);
                                                navigate(item)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </AccordionDetails> : null}
                    </Accordion>

                    <Accordion
                        expanded={billingAccordion === "Billing"}
                        onChange={handleChange("Billing")}
                    >
                        <AccordionSummary
                            aria-controls="billing-content"
                            id="billing-header"
                        >
                            <h2 className="font-16 fw-sb m-0" id="billing-accordion">
                                Billing
                            </h2>
                        </AccordionSummary>
                        {billingAccordion ? <AccordionDetails>
                            <PatientBillingTable
                                columnHeadersList={[
                                    { key: "createdDate", value: "Date" },
                                    {
                                      key: "providerTenantPracticeLocation.provider.name.firstName",
                                      value: "Provider",
                                    },
                                    {
                                      key: "patient.name.firstName",
                                      value: "Patient",
                                    },
                                    {
                                      key: "Place of Service",
                                      value: "Place of Service",
                                    },
                                    {
                                      key: "serviceDescription ",
                                      value: "Service Description",
                                    },
                                    { key: "paymentType ", value: "Payment Type" },
                                    {
                                      key: "amountBilled ",
                                      value: "Amount Billed",
                                    },
                                    {
                                      key: "paymentStatus",
                                      value: "Payment Status",
                                    },
                                    { key: "outstandingBalance", value: "Outstanding" },
                                    { key: " ", value: " " },
                                  ]}
                                  data={billingHistoryData}
                                  patientFilter={true}
                            
                            />

                        </AccordionDetails> : null}
                    </Accordion>

                    <Accordion
                        expanded={clinicalAccordion === "Clinical"}
                        onChange={handleChange("Clinical")}
                    >
                        <AccordionSummary
                            aria-controls="clinical-content"
                            id="clinical-header"
                        >
                            <h2 className="font-16 fw-sb m-0" id="clinical-accordion">
                                Clinical
                            </h2>
                        </AccordionSummary>
                        {clinicalAccordion ? <AccordionDetails>

                        </AccordionDetails> : null}
                    </Accordion>

                    <Accordion
                        expanded={dataSourceAccordion === "Data Source"}
                        onChange={handleChange("Data Source")}
                    >
                        <AccordionSummary
                            aria-controls="datasource-content"
                            id="data-source-header"
                        >
                            <h2 className="font-16 fw-sb m-0" id="data-source-accordion">
                                Data Source
                            </h2>
                        </AccordionSummary>
                        {dataSourceAccordion ? <AccordionDetails>

                        </AccordionDetails> : null}
                    </Accordion>
                </div>
            </div>
        </div>
    </>);
}

export default ReportsPage;


function AppointmentsAccordionDetials({ columnHeadersList, appointmentTableRows, navigateTo }) {
    return <TableContainer
        sx={{ borderRadius: "16px", border: "1px solid #D9E8EC" }}
    >
        <Table>
            <TableHead
                sx={{
                    borderTopColor: "#FAFCFC",
                    borderRightColor: "#FAFCFC",
                    borderLeftColor: "#FAFCFC",
                    borderBottom: "1px solid #D9E8EC",
                }}
            >
                <StyledTableRow>
                    {columnHeadersList?.map((item, idx) => (
                        <StyledTableCell key={item?.concat(idx)}>{item}</StyledTableCell>
                    ))}
                </StyledTableRow>
            </TableHead>
            <TableBody>
                {/* {isLoading ? <>
                    {columnHeadersList?.map((item, idx) => {
                        return <StyledTableRow>
                            <StyledTableCell>
                                <Skeleton animation="wave" />
                            </StyledTableCell>
                        </StyledTableRow>
                    })}
                </> : <> */}
                {appointmentTableRows?.map((item, idx) => {
                    const key = Object.keys(item)?.at(0);
                    const value = item[key];
                    const route = item['link'];
                    return <StyledTableRow
                        key={value}
                        sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                        }}
                    >
                        <StyledTableCell
                            sx={{ color: "#336383", fontWeight: "600" }}
                        >
                            {key === 'Forced Appointments' || key === 'Appointments' ? <span

                                className="text--grey">{key}</span> : <span
                                    role="button"
                                    onClick={() => navigateTo(route)}
                                    className="text-underline btn-disable pn-link-button cross-sign">{key}</span>}
                        </StyledTableCell>
                        <StyledTableCell
                        // sx={{ width: "100", color: "#336383" }}
                        >
                            {value}
                        </StyledTableCell>
                    </StyledTableRow>
                })}
                {/* </>} */}
            </TableBody>
        </Table>
    </TableContainer>
}
import sendHttpRequest from "../hooks/sendHttpRequest";

export default class PracticeAdminAppointmentService{

    static getAppointments = async (from,to,providerId,facility, tenantId, size,page) => {
        try {
            const response = await sendHttpRequest({
                method: "GET",
                url: `/tenants/${tenantId}/appointments`,
                params: {
                    size:size,
                    page:page,
                    ...(from && {from:from}),
                    ...(to && {to:to}),
                    ...(providerId && {providerIds:providerId}),
                    ...(facility && {facilityIds:facility}),
                }
            });
            if (!response.error) {
                return response.data;
            }
        }
        catch (err) {
            console.log(err);
        }
    }
}
import { Box, createTheme, Tab, Tabs, ThemeProvider } from "@mui/material"
import PageHeader from "../../generic/PageHeader"
import { patientBillingDashboardMapKeyValueArray } from "./PatientBillingConstants"
import { useEffect, useState } from "react"
import { TabPanel } from "../../../components/UI/Reusable/PnComponents";
import classes from "./PatientBillingDashboard.module.css";
import { currencyFormatter } from "../../../utils/helpers/HelperFunctions";
import PatientBalanceCard from "./PatientBalanceCard";
import PatientPaidInvoices from "./PatientPaidInvoices";
import BillingCardContainer from "./BillingCardContainer";
import { useAuth } from "../../../components/hooks/useAuth";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PatientPaymentHistory from "./PatientPaymentHistory";
import InvoiceDetailItems from "./InvoiceDetailItems";

const theme = createTheme({
    palette: {
        primary: {
            main: "#336383",
            light: "#336383",
            dark: "#336383",
            contrastText: "#336383",
        },
        secondary: {
            main: "#EC609B",
        },
    },
    typography: {
        fontFamily: "Montserrat",
        fontSize: 14,
    },
});



const PatientBillingDashboard = (props) => {
    const patientInsurances = useSelector(
        (state) => state.patientInsuranceData.patientInsurance
    );
    const {user} = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    const [tabValue, setTabValue] = useState(0);
    
    const [responsiblePerson, setResponsiblePerson] = useState();
    
    const handleResponsiblePerson = (isUser,rPerson)=>{
        if(isUser){
            setResponsiblePerson({
                name:user?.name[0]?.firstName+" "+user?.name[0]?.lastName,
                personId:"10410458",
                address:user.address[0]?.addressLine1.concat(" ",user.address[0]?.addressLine1," ",user.address[0]?.city," ",user.address[0]?.stateProvince,", ",user.address[0]?.postalCode), 
                phone:user?.communicationNumbers?.numbers[0]?.number,
                emergencyContact:user?.communicationNumbers?.emergencyContact?.length > 0 ? user?.communicationNumbers?.emergencyContact[0]?.phonenumber :"",
                email:user?.communicationNumbers?.electronicMail[0] ?? ""
            })
        }else{
            setResponsiblePerson({
                name:rPerson?.firstName+" "+rPerson?.lastName,
                personId:"10410458",
                address:rPerson?.addressLine1.concat(" ",rPerson?.addressLine1," ",rPerson?.city," ",rPerson?.state,", ",rPerson?.zipCode), 
                phone:"",
                emergencyContact:"",
                email:""
            })
        }
    }
    function handleChange(event, newValue) {
        setTabValue(newValue);
    }
    useEffect(()=>{
        let primaryInsurance = patientInsurances.find((item) => { return item.type?.toLowerCase() === "primary" })

        // if (primaryInsurance !== undefined && primaryInsurance?.relationshipToInsured?.toLowerCase() !== "self") {
            // setResponsiblePerson(primaryInsurance);
        // }
        if (primaryInsurance !== undefined && primaryInsurance?.relationshipToInsured?.toLowerCase() !== "self") {
            handleResponsiblePerson(false,primaryInsurance)
        }else{
            handleResponsiblePerson(true)
        }
    },[])
    return (
        <>
            <PageHeader title={`Billing for ${user?.name[0]?.firstName+" "+user?.name[0]?.lastName}`} />
            <ThemeProvider theme={theme}> <div className='container-fluid'>
                <div className='row h-100'>
                    <div style={{ minHeight: '100vh' }} className={`py-3 col-sm-3 patient-billing-left`}>
                        <div>
                            <span className='font-16 fw-sb text--terciary d-block'>Responsible for Payment</span>
                            <div className={`${classes.person_item}`}>
                                <div>
                                    <span className="font-12 fw-sb">Name: </span>
                                </div>
                                <div>
                                    <span className="fw-thin font-12">{responsiblePerson?.name}</span>
                                </div>

                            </div>

                            <div className={`${classes.person_item}`}>
                                <div>
                                    <span className="font-12 fw-sb">Responsible Person # </span>
                                </div>
                                <div>
                                    <span className="fw-thin font-12">10410458</span>
                                </div>

                            </div>

                            <div className={`${classes.person_item}`}>
                                <div>
                                    <span className="font-12 fw-sb">Address: </span>
                                </div>
                                <div>
                                    <span className="fw-thin font-12">{responsiblePerson?.address ?? ""}</span>
                                </div>

                            </div>

                            <div className={`${classes.person_item}`}>
                                <div>
                                    <span className="font-12 fw-sb">Mobile Phone: </span>
                                </div>
                                <div>
                                    <span className="fw-thin font-12">{responsiblePerson?.phone ?? ""}</span>
                                </div>

                            </div>

                            <div className={`${classes.person_item}`}>
                                <div>
                                    <span className="font-12 fw-sb">Home Telephone: </span>
                                </div>
                                <div>
                                    <span className="fw-thin font-12">{responsiblePerson?.emergencyContact ?? ""}</span>
                                </div>

                            </div>

                            <div className={`${classes.person_item}`}>
                                <div>
                                    <span className="font-12 fw-sb">Email: </span>
                                </div>
                                <div>
                                    <span className="fw-thin font-12">{responsiblePerson?.email ?? ""}</span>
                                </div>

                            </div>

                        </div>

                        <div>
                            <span className='font-16 fw-sb text--terciary d-block'>Patients Included</span>
                        </div>
                    </div>
                    <div className='col-sm-9'>
                        <div className='row'>
                            <div className='col-sm-12 px-0'>
                                <Box
                                    sx={{
                                        minWidth: '100%',
                                        borderBottom: 1,
                                        borderColor: "#D9E8EC",
                                    }}
                                >
                                    <Tabs
                                        indicatorColor="secondary"
                                        value={tabValue}

                                        variant="scrollable"
                                        scrollButtons="auto"
                                        onChange={handleChange}
                                    >
                                        {patientBillingDashboardMapKeyValueArray.map(entry => {
                                            return <Tab
                                                key={entry?.at(0)}
                                                label={entry?.at(1)}

                                                style={{
                                                    fontSize: "12px",
                                                    fontWeight: "600",
                                                    textTransform: "initial",
                                                    color: "#336383",
                                                }}
                                            />
                                        })}
                                    </Tabs>
                                </Box>
                                <TabPanel value={tabValue} index={0}>
                                    <div className="row justify-content-around">
                                        <div className="col-sm-5 py-3">
                                            <span className="d-block ps-1 fw-sb font-16">Balances</span>
                                            <PatientBalanceCard amountDue = {location.state?.amountDue ?? 0}/>
                                        </div>
                                        <div className="col-sm-5 py-3">
                                            <span className="d-block ps-1 fw-sb font-16">Payments</span>
                                            <BillingCardContainer>
                                                <PatientPaidInvoices/>
                                            </BillingCardContainer>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel value={tabValue} index={1}>
                                    <div className="row justify-content-around">
                                        <div className="col-sm-12 py-3">
                                            <InvoiceDetailItems/>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel value={tabValue} index={2}>
                                    <div className="row justify-content-around">
                                        <div className="col-sm-12 py-3">
                                            <PatientPaymentHistory />
                                        </div>
                                    </div>
                                </TabPanel>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div></ThemeProvider>


        </>
    )
}

export default PatientBillingDashboard
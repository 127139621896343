import { Divider } from "@mui/material";
import { useAuth } from "../../components/hooks/useAuth";
import FamilyMedicalHistoryTable from "../../components/patient/FamilyMedicalHistoryTable";
import MedicationHistoryTable from "../../components/patient/MedicationHistoryTable";
import PageHeader from "../generic/PageHeader";

const SidebarLinkComponent = ({ children, id }) => {
    return (
      <a className="text-decoration-none" href={`#${id}`}>
        <p className="m-0 p-0 ps-2 text--secondary font-12" data-to-scrollspy-id={`${id}`}>{children}</p>
      </a>
    )
  }
const PatientHistory = () => {
  const { user } = useAuth();
  return (
    <>
      <PageHeader title={"History"} />
      <div className="row justify-content-center mb-3">
      <div className="col-md-2 col-sm-2 col-lg-2 ps-5 pt-3">
                <div class="position-relative w-100">
                  <div className="d-flex flex-column position-fixed" style={{ "marginTop": "calc(20vh)" }}>
                    {/* <SidebarLinkComponent id={"diagnosis"} > Diagnosis History </SidebarLinkComponent> */}
                    <SidebarLinkComponent id={"medicationHistory"} > Medication History </SidebarLinkComponent>
                    <SidebarLinkComponent id={"familyMedicationHistory"} > Family Medical History </SidebarLinkComponent>
                    {/* <SidebarLinkComponent id={"surgery"} > Surgical History </SidebarLinkComponent>
                    <SidebarLinkComponent id={"hospitalization"} > Hospitalizations </SidebarLinkComponent>
                    <SidebarLinkComponent id={"socialHistory"} > Social History </SidebarLinkComponent> */}
                  </div>
                </div>
              </div>
        <div className="col-8 mt-3">
          <MedicationHistoryTable
            id={"medicationHistory"}
            patientId={user.userId}
            patientPortal = {true}
          />
        </div>
        <div className="col-2"></div>
        <div className="col-8 mt-3">
            <Divider
            sx={{
                marginTop: 6,
                marginBottom: 3,
                color: "#336383",
            }}
            />
            <FamilyMedicalHistoryTable id={"familyMedicationHistory"} patientId={user.userId} patientPortal={true}/>
        </div>
      </div>
    </>
  );
};

export default PatientHistory;

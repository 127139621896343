import axios from "axios";
import uuid from "react-uuid";
import { ConfigMap } from "../../utils/helpers/ConfigMap";

let cancelToken;


export const search = async (requestConfig) => {
  let isCancelled = false
  if (cancelToken) {
    cancelToken.cancel("Request cancel due to new request");
    isCancelled = true;
  } else {
    isCancelled = false;
  }
  cancelToken = axios.CancelToken.source();
  let requestConfigCopy = { ...requestConfig, cancelToken: cancelToken, isCancelled }
  return sendHttpRequest(requestConfigCopy)
}

let reqCancelToken;
export const sendHttpRequestWithCancellation = async (requestConfig) => {
  let isCancelled = false
  if (reqCancelToken) {
    reqCancelToken.cancel("Request cancel due to new request");
    isCancelled = true;
  } else {
    isCancelled = false;
  }
  reqCancelToken = axios.CancelToken.source();
  let requestConfigCopy = { ...requestConfig, cancelToken: reqCancelToken, isCancelled }
  return sendHttpRequest(requestConfigCopy)
}

const sendHttpRequest = async (requestConfig) => {
  let data = {};
  let responseError = false;
  let statusCode = null;
  let isLoading = true;
  let errorMessage;
  let isCancelled = requestConfig.isCancelled
  let responseHeaders = null;

  const baseURL = ConfigMap("APP");
  axios.defaults.withCredentials = true;
  axios.defaults.headers = {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
    Accept: requestConfig?.Accept ? requestConfig?.Accept : "application/json",
    p_tid: uuid(),
    "X-SOURCE": "pxnow",
    "X-TenantID": JSON.parse(localStorage.getItem("user"))?.practiceLocationId
    // "Authorization": localStorage.getItem("tkn") !== null ? localStorage.getItem("tkn") : ""
  };
  if (requestConfig.url !== "/auth/signin") {
    axios.defaults.headers.Authorization = localStorage.getItem("tkn") !== null ? localStorage.getItem("tkn") : "";
  }

  await axios({
    url: `${baseURL}` + requestConfig.url,
    method: requestConfig.method ? requestConfig.method : "GET",
    params: requestConfig.params ? requestConfig.params : {},
    data: requestConfig.data ? requestConfig.data : {},
    headers: requestConfig.headers ? requestConfig.headers : {},
    responseType: requestConfig.responseType ? requestConfig.responseType : null,
    cancelToken: requestConfig?.cancelToken?.token,
  })
    .then(function (response) {
      data = response.data;
      statusCode = response.status;
      isCancelled = false
      responseHeaders = response.headers;
      if (response["headers"]["authorization"] !== undefined) {
        localStorage.setItem("tkn", response.headers["authorization"]);
      }
    })
    .catch(function (error) {
      statusCode = error?.response?.status;
      responseError = true;
      errorMessage = error?.response?.data;
      isCancelled = false;
      // console.log(error.config);
    });
  isLoading = false;
  return {
    isCancelled,
    isLoading,
    error: responseError,
    data,
    errorMessage,
    status: statusCode,
    responseHeaders
  };
};

export default sendHttpRequest;

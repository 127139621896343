import { Modal } from "react-bootstrap";
import classes from "./SocialHistoryModal.module.css";
import { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import sendHttpRequest from "../../../hooks/sendHttpRequest";

import MultipleSelectDropdown from "./MuiMuiltiSelect";
import { setIsPatientEdited } from "../../../../redux/actions/providerActions";
import { useDispatch } from "react-redux";
import { addSocialHistoryUrl } from "../../../../res/endpointurls";

const SocialHistoryModal = (props) => {

  const dispatch = useDispatch();

  // console.log(props);

  const [isSocialHistorySaved, setIsSocialHistorySaved] = useState(false);
  const [answersData, setAnsweresData] = useState([]);
  const [recreationalDrugs, setRecreationalDrugs] = useState([])
  const [employement, setEmployement] = useState(false);
  const [unEmployment, setUnEmployment] = useState(false);
  const [recreationalDrugsAnswers, setRecreationalDrugsAnswers] = useState([])
  const [recreationalDrugsAnswersList, setRecreationalDrugsAnswersList] = useState([])
  const [socialHistorySaving, setSocialHistorySaving] = useState(false);

  const sendSaveSocialHistoryRequest = async () => {
    setIsSocialHistorySaved(true);
    let postData = []
    for (let i = 0; i < answersData?.length; i++) {
      if (answersData[i].answer[0] !== null) {
        postData.push(
          {
            question: 
            { 
              questionId: answersData[i].questionId
            },
            answer: answersData[i].answer[0] !== -1 ? answersData[i].answer : [""]
          }
          )
        }
        // else {
          //   postData.push({ question: { questionId: answersData[i].questionId }})
      // }
    }
    // console.log(postData);
    setSocialHistorySaving(true);
    const httpResponse = await sendHttpRequest({
      url: addSocialHistoryUrl(props.patientId),
      method: "POST",
      data: postData,
    });
    if (!httpResponse.error) {
      dispatch(setIsPatientEdited(true));
      setIsSocialHistorySaved(false);
      setAnsweresData([]);
      props.handleModal();
    } else {
      console.log(httpResponse.error);
    }
    setSocialHistorySaving(false);

    // }
  };
  const handleRadioButtonAnswer = (event) => {
    let questionId = event.target.id;
    let answerId = event.target.value;
    let textValue = event.target.innerHTML;
    // event.target.style.border = "2px solid #EC609B";
    // console.log("Question ID", questionId);
    // console.log("Answere ID", answereId);
    for (let i = 0; i < answersData.length; i++) {
      if (questionId == answersData[i].questionId) {
        answersData[i].answer = [parseInt(answerId)];
      }
    }

    if (textValue?.toLowerCase() === "employed") {
    
      setEmployement(true);
      setUnEmployment(false);
    } else if (textValue?.toLowerCase() === "unemployed") {
      setEmployement(false);
      setUnEmployment(true);
    }
  };

  const handleAnswer = (event) => {
    // console.log(
    //   "Question->",
    //   event.target[event.target.selectedIndex].getAttribute("data")
    // );
    // console.log("Answere->", event.target.value);
    let questionId =
      event.target[event.target.selectedIndex].getAttribute("data");
    let answerId = event.target.value;
    for (let i = 0; i < answersData.length; i++) {
      if (questionId == answersData[i].questionId) {
        answersData[i].answer = [parseInt(answerId)];
      }
    }
  };
  const handleAnswers = () => {
    let tempAnswers = [];
    for (let i = 0; i < props?.socialHistory?.length; i++) {
      let questionId = props?.socialHistory[i]?.question?.questionId;
      let answerId = null;
      if (props?.socialHistory[i]?.question?.question?.toLowerCase() === "recreational drugs") {
        let rdList = []
        for (let r = 0; r < props?.socialHistory[i]?.question?.answers?.length; r++) {
          rdList.push(props?.socialHistory[i]?.question?.answers[r])
        }
        setRecreationalDrugs(rdList)
        setRecreationalDrugsAnswersList(props?.socialHistory[i]?.answerStrings)

      }
      if (props?.socialHistory[i]?.question?.question?.toLowerCase() === "employment") {
        // console.log("props.socialHistory[i]", props.socialHistory[i])
        if (props?.socialHistory[i]?.answerStrings?.length > 0) {
          if (props?.socialHistory[i]?.answerStrings[0]?.toLowerCase() === "unemployed") {
            setUnEmployment(
              true || false
            );
          }
          if (
            props.socialHistory[i]?.answerStrings[0]?.toLowerCase() === "employed"
          ) {
            setEmployement( true || false);
          }
        }
      }

      for (let j = 0; j < props?.socialHistory[i]?.answer?.length; j++) {
        if (props?.socialHistory[i].answer?.length > 0) {
          answerId = props?.socialHistory[i]?.answer[0];
        }
      }
      // console.log({QuestionId:questionId,AnswerId:answereId});
      tempAnswers.push({ questionId: questionId, answer: [answerId] });
    }
    // console.log("temp answers",tempAnswers);
    setAnsweresData(tempAnswers);
  };

  useEffect(() => {
    handleAnswers();
    return(() => {})
  }, []);
  
  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.handleModal();
        // setSocialHistoryQuestions([]);
        setAnsweresData([]);
      }}
      size="lg"
      backdrop="static"
      backdropClassName="modal-backdrop"
      contentClassName="modal-border modal-shadow"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <div className="container-fluid px-3 ">
        <Modal.Header closeButton style={{ borderBottom: 'none' }}>
          <Modal.Title>
            <span className="font-18 fw-sb text--terciary">Add Social History</span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <form
            id="socialHistoryForm"
            onSubmit={(e) => {
              e.preventDefault();

            }}
          >

            <div className="row ">
              <div className="col-sm-6 ">
                {props.socialHistory !== undefined &&
                  props.socialHistory?.length > 0 ? (
                  props.socialHistory?.map((question, index) => {
                    if (index < 3 && props.shList.includes(question?.question?.question)) {
                      if (question?.question?.questionType?.toLowerCase() === "dropdown") {
                        return (
                          <div key={question?.question?.questionId} className={index !== 0 ? "mt-3" : "mb-3"}>
                            <label className="font-12 text--secondary" >
                              {question?.question?.question}
                            </label>
                            {question.question?.questionType?.toLowerCase() !== "dropdown" ? (
                              <div className="d-flex flex-row">

                              </div>
                            ) : (
                              <select
                                className={`form-select  cursor--pointer  font-14 shadow-none ${classes["custom-select"]}`}
                                aria-label="Provider select"
                                style={{
                                  border: "1px solid #D9E8EC",
                                  borderRadius: "10px",
                                  height: "40px",

                                }}
                                onChange={(e) => handleAnswer(e)}
                              >
                                <option
                                  value="-1"
                                  data={question?.question?.questionId}
                                  disabled
                                  selected
                                  hidden
                                >
                                  Select answer
                                </option>
                                <option value="-1" data={question?.question?.questionId}></option>
                                {question?.question?.answers?.map((answer) => {
                                  return (
                                    <option
                                      key={answer?.answerId + "" + question?.question?.questionId}
                                      value={answer?.answerId}
                                      data={question?.question?.questionId}
                                      selected={
                                        question?.answer?.length > 0 ? answer?.answerId == question?.answer[0] : false
                                      }
                                    >
                                      {answer.answer}
                                    </option>
                                  );
                                })}
                              </select>
                            )}
                          </div>
                        );
                      } else if (question.question?.questionType?.toLowerCase() === "radio" && props.shList.includes(question?.question?.question)) {
                        return(
                          <div key={question?.question?.questionId} className={`${index !== 0} ? mt-3 : mb-3`}>
                            <label className="font-12 text--secondary" >
                              {question?.question?.question}
                            </label>
                            <div className={`d-flex flex-row `}>
                            <div>
                              <button
                                className="btn text-terciary b2 px-2.9 br-10 py-1 font-14 shadow-none"
                                type="radio"
                                name="rb"
                                key={question?.question?.answers[0].answerId + "" + question?.question?.questionId}
                                value={question?.question?.answers[0].answerId}
                                id={question?.question?.questionId}
                                style={
                                  employement
                                    ? {
                                      border: "2px solid #EC609B",
                                      borderRadius: "10px",
                                      background:
                                        "#FFFFFF 0% 0% no-repeat padding-box",
                                      height: "40px",
                                    }
                                    : {
                                      border: "1px solid #D9E8EC",
                                      borderRadius: "10px",
                                      background:
                                        "#FFFFFF 0% 0% no-repeat padding-box",
                                      height: "40px",
                                    }
                                }
                                onClick={(e) => {
                                  handleRadioButtonAnswer(e);
                                }}
                              >
                                {question?.question?.answers[0].answer}
                              </button>

                              {/* </input> */}
                            </div>
                            <div className="ms-2">
                              <button
                                className="btn text-terciary b2 px-2.9 br-10 py-1 font-14 shadow-none"
                                type="radio"
                                name="rb"
                                // key={answer?.Id + "" + question?.Id}
                                value={question?.question?.answers[1].answerId}
                                id={question.question?.questionId}
                                style={
                                  unEmployment
                                    ? {
                                      border: "2px solid #EC609B",
                                      borderRadius: "10px",
                                      background:
                                        "#FFFFFF 0% 0% no-repeat padding-box",
                                      height: "40px",
                                    }
                                    : {
                                      border: "1px solid #D9E8EC",
                                      borderRadius: "10px",
                                      background:
                                        "#FFFFFF 0% 0% no-repeat padding-box",
                                      height: "40px",
                                    }
                                }
                                onClick={(e) => {
                                  handleRadioButtonAnswer(e);
                                }}
                              >
                                {question?.question?.answers[1].answer}
                              </button>

                              {/* </input> */}
                            </div>
                          {/* {question.Answers.map((answer,anIndex) => {
                          return (
                            
                          );
                        })} */}
                          </div>
                        </div>
                      )}
                      else if (question?.question?.questionType?.toLowerCase() === "checkbox" && props.shList.includes(question?.question?.question)) {
                        return (
                          <div className={`${index !== 0} ? mt-3 : mb-3`} >
                            <label className="font-12 text--secondary" >Recreational Drugs</label>
                            <MultipleSelectDropdown
                              recreationalDrugs={recreationalDrugs}
                              setRecreationalDrugs={setRecreationalDrugs}
                              setRecreationalDrugsAnswers={setRecreationalDrugsAnswers}
                              recreationalDrugsAnswersList={recreationalDrugsAnswersList}
                              answeresData={answersData}
                              questionId={question?.question?.questionId}
                            />
                          </div>

                        )

                      }
                    }
                  })
                ) : (
                  <Skeleton />
                )}
              </div>
              <div className="col-sm-6 ">
                {props.socialHistory !== undefined &&
                  props.socialHistory?.length > 0 ? (
                  props.socialHistory?.map((question, index) => {
                    if (index >= 3 && props.shList.includes(question?.question?.question)) {
                      return (
                        <div key={question?.question?.questionId} className={index !== 3 ? "mt-3" : "mb-3"}>
                          <label className="font-12 text--secondary" >
                            {question?.question?.question}
                          </label>
                          {question?.question?.questionType?.toLowerCase() !== "dropdown" ? (
                            <div className="d-flex flex-row">
                              <div>
                                <button
                                  className="btn text-terciary b2 px-2.9 br-10 py-1 font-14 shadow-none"
                                  type="radio"
                                  name="rb"
                                  key={question?.question?.answers[0].answerId + "" + question?.question?.questionId}
                                  value={question?.question?.answers[0].answerId}
                                  id={question?.question?.questionId}
                                  style={
                                    employement
                                      ? {
                                        border: "2px solid #EC609B",
                                        borderRadius: "10px",
                                        background:
                                          "#FFFFFF 0% 0% no-repeat padding-box",
                                        height: "40px",
                                      }
                                      : {
                                        border: "1px solid #D9E8EC",
                                        borderRadius: "10px",
                                        background:
                                          "#FFFFFF 0% 0% no-repeat padding-box",
                                        height: "40px",
                                      }
                                  }
                                  onClick={(e) => {
                                    handleRadioButtonAnswer(e);
                                  }}
                                >
                                  {question?.question?.answers[0].answer}
                                </button>

                                {/* </input> */}
                              </div>
                              <div className="ms-2">
                                <button
                                  className="btn text-terciary b2 px-2.9 br-10 py-1 font-14 shadow-none"
                                  type="radio"
                                  name="rb"
                                  // key={answer?.Id + "" + question?.Id}
                                  value={question?.question?.answers[1].answerId}
                                  id={question.question?.questionId}
                                  style={
                                    unEmployment
                                      ? {
                                        border: "2px solid #EC609B",
                                        borderRadius: "10px",
                                        background:
                                          "#FFFFFF 0% 0% no-repeat padding-box",
                                        height: "40px",
                                      }
                                      : {
                                        border: "1px solid #D9E8EC",
                                        borderRadius: "10px",
                                        background:
                                          "#FFFFFF 0% 0% no-repeat padding-box",
                                        height: "40px",
                                      }
                                  }
                                  onClick={(e) => {
                                    handleRadioButtonAnswer(e);
                                  }}
                                >
                                  {question?.question?.answers[1].answer}
                                </button>

                                {/* </input> */}
                              </div>
                              {/* {question.Answers.map((answer,anIndex) => {
                              return (
                                
                              );
                            })} */}
                            </div>
                          ) : (
                            <select
                              className={`form-select  cursor--pointer  font-14 shadow-none ${classes["custom-select"]}`}
                              aria-label="Provider select"
                              style={{
                                border: "1px solid #D9E8EC",
                                borderRadius: "10px",
                                height: "40px",
                              }}
                              onChange={(e) => handleAnswer(e)}
                            >
                              <option
                                value="-1"
                                data={question?.question?.questionId}
                                disabled
                                selected
                                hidden
                              >
                                Select answer
                              </option>
                              <option value="-1" data={question?.question?.questionId}></option>
                              {question?.question?.answers.map((answer) => {
                                return (
                                  <option
                                    key={answer?.answerId + "" + question?.questionId}
                                    value={answer.answerId}
                                    data={question?.question?.questionId}
                                    selected={
                                      question?.answer?.length > 0 ? answer?.answerId == question?.answer[0] : false
                                    }
                                  >
                                    {answer.answer}
                                  </option>
                                );
                              })}
                            </select>
                          )}
                        </div>
                      );
                    }
                  })
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
          </form>
        </Modal.Body>
      </div>

      <Modal.Footer style={{ border: "none" }}>
        {isSocialHistorySaved === false ? (
          <button
            // type="submit"
            // form="socialHistoryForm"
            className="btn blue_task_button"
            onClick={() => sendSaveSocialHistoryRequest()}
          >
            {props?.isAnswered ? "Save" : "Add Social History"}
          </button>
        ) : (
          <button
            className="btn blue_task_button"
            disabled
          >
            {"Saving..."}
          </button>
        )}
      </Modal.Footer>
      {/* {console.log("Answeres data", answeresData)}
      {console.log("Recreational drugs: ",recreationalDrugs)}
      {console.log("Recreational  drug selected answers: ",recreationalDrugsAnswers)} */}
    </Modal>
  );
};

export default SocialHistoryModal;

import { Modal } from "react-bootstrap";
import classes from "./EditDiagnosisModal.module.css";
import SearchIcon from "@mui/icons-material/Search";
import { useRef, useState } from "react";
import sendHttpRequest from "../hooks/sendHttpRequest";
import CloseIcon from "@mui/icons-material/Close";
import { useCallback } from "react";
import CustomDatePicker from "../UI/General/CustomDatePicker";
import Badge from "../UI/Reusable/Badge";
import CalenderDatePicker from "../UI/General/CalenderDatePicker";
import { fieldValidation } from "../../utils/helpers/HelperFunctions";
import { DateTime } from "luxon";

const EditDiagnosisModal = (props) => {
    const [suggestions, setSuggestions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState("");
    const [diagnosisName, setDiagnosisName] = useState("");
    const [updateDiagnosisObject, setUpdateDiagnosisObject] = useState(props?.editDiagnosis);
    const [startDate, setStartDate] = useState(props?.editDiagnosis?.startDate ?? "");
    const [endDate, setEndDate] = useState("");
    const [primaryDiagnosis, setPrimaryDiagnosis] = useState(props?.editDiagnosis?.type === "P" ? true : false);
    const [status, setStatus] = useState(props?.editDiagnosis?.status);
    const startDateRef = useRef(props?.editDiagnosis?.startDate);

    const handleStartDate = useCallback((dateValue) => {
        console.log(dateValue);
        setStartDate(dv => dateValue);
        startDateRef.current = dateValue;
        setUpdateDiagnosisObject(prevObj => ({ ...prevObj, startDate: dateValue }));
    }, [setStartDate]);
    const handleEndDate = useCallback((dateValue) => {
        // console.log(startDateRef.current, dateValue, endDate);
        // console.log(typeof startDate, typeof dateValue);
        // console.log(updateDiagnosisObject);
        if ((startDateRef.current !== "" && startDateRef.current < dateValue) || dateValue === undefined) {
            // console.log(dateValue)
            setEndDate(dateValue);
            setUpdateDiagnosisObject(prevObj => ({ ...prevObj, endDate: dateValue }));
        } else if (!fieldValidation(startDateRef.current, "un") && dateValue === null) {
            // console.log(dateValue)
            setEndDate(dateValue);
            setUpdateDiagnosisObject(prevObj => ({ ...prevObj, endDate: dateValue }));
        }
    }, [setEndDate]);

    const searchDiagnosis = async (diagnosisInput) => {
        setIsLoading(true);

        const httpResponse = await sendHttpRequest({
            url: `/diagnosis`,
            method: "GET",
            params: {
                search: diagnosisInput,
            },
        });
        if (!httpResponse.error) {
            setSuggestions(httpResponse.data);
        } else {
            setIsError(true);
        }
        setIsLoading(false);

        // setIsError(false)
    };

    const handleSearchInput = (icdInput) => {
        if (icdInput.length > 2) {
            // setDiagnosisName(icdInput)
            searchDiagnosis(icdInput);
            setSearchInputValue(icdInput);
        }
        setDiagnosisName(icdInput);
        setSuggestions([]);
        setIsLoading(false);
        setIsError(false);
    };

    const handleSearchIconBtn = (icdInput) => {
        // console.log("event.target.value", searchInputValue);
        // let icdInput = event.target.value
        handleSearchInput(icdInput);
        if (icdInput.length > 2) {
            // setDiagnosisName(icdInput)
            searchDiagnosis(icdInput);
            setSearchInputValue(icdInput);
        }
        setDiagnosisName(icdInput);
        setSuggestions([]);
        setIsLoading(false);
        setIsError(false);
    };
    const handleSelectedDiagnosis = (suggestion) => {
        // console.log({ ...props?.editDiagnosis, ...suggestion })

        const tempObj = { ...props?.editDiagnosis, ...suggestion };
        if (status !== undefined) {
            tempObj.status = status;
        }
        if (startDate !== "") {
            tempObj.startDate = startDate;
        }
        else if (props?.editDiagnosis?.startDate !== undefined && props?.editDiagnosis?.startDate !== null) {
            tempObj.startDate = props?.editDiagnosis?.startDate;
        }
        if (endDate !== "") {
            tempObj.endDate = endDate;
        }
        else if (props?.editDiagnosis?.endDate !== undefined && props?.editDiagnosis?.endDate !== null) {

            tempObj.endDate = props?.editDiagnosis?.endDate;
        }
        if (primaryDiagnosis === true) {
            tempObj.type = 'P';
        }
        else {
            tempObj.type = 'S';
        }
        setDiagnosisName("");

        setSuggestions([]);
        setIsError(false);
        setIsLoading(false);
        setUpdateDiagnosisObject(p => tempObj);
        // console.log(suggestion);
    };

    const handleRemoveDiagnosis = (removeId) => {
        document.querySelectorAll(".react-datepicker__close-icon").forEach(i => i.click());
        setDiagnosisName("");
        setPrimaryDiagnosis(false);
        setStatus(false);
        setUpdateDiagnosisObject(p => { });
        setUpdateDiagnosisObject(p => undefined);
        if (props?.soapNoteKey) {
            let ans = props?.diagnosesData.filter((item) => { return item?.code !== removeId });
            props?.setDiagnosesData(ans);
        }
    };

    const handleUpdateDiagnosis = () => {
        if (props?.soapNoteKey) {
            let ans = props?.diagnosesData.filter((item) => { return item?.code !== props?.editDiagnosis?.code });
            let primaryDiagnosisObject = ans.find((item)=>{ return item.type === 'P'})
            if(primaryDiagnosisObject !== undefined && updateDiagnosisObject?.type === 'P'){
                primaryDiagnosisObject.type = 'S'
                ans = ans.map((item) => (item.code === primaryDiagnosisObject.code ? primaryDiagnosisObject : item));
            }
            props?.setDiagnosesData(p => ans);
        }
        props?.updateDiagnosis(updateDiagnosisObject, props?.soapNoteKey);

    };

    return (
        <Modal
            show={props?.show}
            onHide={() => {
                props.handleModal();
            }}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            backdropClassName="modal-backdrop"
            contentClassName={`modal-border modal-shadow `}
            scrollable={false}
            centered

        // style={{style}}
        >
            <Modal.Header  >
                <span
                    style={{
                        lineHeight: "12px",
                        width: "18px",
                        fontSize: "8pt",
                        color: "#336383",
                        position: "absolute",
                        top: 15,
                        right: 15,
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        props?.handleModal()
                    }}
                >
                    {<CloseIcon />}
                </span>
                <Modal.Title className="font-20 fw-sb">Edit Diagnosis</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={`container`}>
                    <div className="row text-center justify-content-center">
                        <div className="col-12">
                            <div>
                                {/* <p className="font-18 fw-sb">Diagnosis</p> */}
                                <div>
                                    <div
                                        className="input-group"
                                        style={{
                                            border: "1px solid #D9E8EC",
                                            borderRadius: "10px",
                                        }}
                                    >
                                        <input
                                            type="text"
                                            className="form-control br-10 shadow-none border-0  "
                                            placeholder="Search here"
                                            //   defaultValue={diagnosisName}
                                            value={diagnosisName}
                                            onChange={(e) => {
                                                handleSearchInput(e.target.value);
                                            }}
                                            onBlur={() => {
                                                setTimeout(() => {
                                                    setSuggestions([]);
                                                    setIsError(false);
                                                    setIsLoading(false);
                                                }, 10);
                                            }}
                                        />

                                        <button
                                            className="btn bg-white zindex-1 br-10 shadow-none"
                                            type="submits"
                                            value={searchInputValue}
                                            onClick={() => handleSearchIconBtn(searchInputValue)}
                                            onBlur={() => {
                                                setTimeout(() => {
                                                    setSuggestions([]);
                                                    setIsError(false);
                                                    setIsLoading(false);
                                                }, 5);
                                            }}
                                        >
                                            <SearchIcon className="text--secondary" />
                                        </button>
                                    </div>

                                    <ul
                                        className={`list-group ${classes["diagnosis__suggestions__dropdown"]}`}
                                    >
                                        {isError ? (
                                            <li
                                                className={`list-group-item ${classes["search__list__item"]}`}
                                            >
                                                No result found
                                            </li>
                                        ) : isLoading ? (
                                            <li
                                                className={`list-group-item ${classes["search__list__item"]}`}
                                            >
                                                Loading...
                                            </li>
                                        ) : (
                                            suggestions.length > 0 &&
                                            suggestions.map((suggestion, index) => {
                                                return (
                                                    <li
                                                        tabIndex={index}
                                                        // className="list-group-item"
                                                        className={`border--default ${classes.li} ${classes["search__list__item"]}`}
                                                        key={suggestion.code}
                                                        onMouseDown={() =>
                                                            handleSelectedDiagnosis(suggestion)
                                                        }

                                                    // onKeyDown = {()=>console.log("works")}
                                                    >
                                                        <span>{suggestion.code} - </span>
                                                        <span className="ms-1">
                                                            {suggestion.description}
                                                        </span>
                                                    </li>
                                                );
                                            })
                                        )}
                                    </ul>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className={`row justify-content-start my-2 br-10 font-14`}>
                        {updateDiagnosisObject !== undefined ? (

                            <div className="col-12">
                                <Badge
                                    data={`${updateDiagnosisObject.code} - ${updateDiagnosisObject.description}`}
                                    handleRemove={handleRemoveDiagnosis}
                                    removeId={updateDiagnosisObject?.code}
                                />
                            </div>

                        ) : null}
                    </div>
                    <div className='row py-1'>
                        <div className='col-sm-6'>
                            <div className="form-group">
                                <label
                                    htmlFor="diagnosisStartDate"
                                    className="ms-1 font-12 text--secondary"
                                >
                                    Start Date
                                </label>
                                {
                                    <CalenderDatePicker
                                        f12={true}
                                        placeholder="mm/dd/yyyy"
                                        dobHandler={handleStartDate}
                                        dateFormat="MM/dd/yyyy"
                                        maxDate={DateTime.now()}
                                        clearable={true}
                                        {...(props?.editDiagnosis?.startDate !== null && props?.editDiagnosis?.startDate !== undefined && { date: props?.editDiagnosis?.startDate })}
                                    />
                                }
                            </div>
                        </div>
                        {console.log("props?.editDiagnosis", props?.editDiagnosis)}
                        <div className='col-sm-6'>
                            <div className="form-group">
                                <label
                                    htmlFor="diagnosisEndDate"
                                    className="ms-1 font-12 text--secondary"
                                >
                                    End Date
                                </label>
                                {
                                    <CalenderDatePicker
                                        f12={true}
                                        dobHandler={handleEndDate}
                                        dateFormat="MM/dd/yyyy"
                                        placeholder="mm/dd/yyyy"
                                        clearable={true}
                                        maxDate={DateTime.now()}
                                        minDate={startDate}
                                        {...(props?.editDiagnosis?.endDate !== null && props?.editDiagnosis?.endDate !== undefined && { date: props?.editDiagnosis?.endDate })}

                                    />
                                }
                            </div>
                        </div>

                    </div>
                    <div className='row py-1'>
                        <div className='col-6'>
                            <div className="form-check py-1">
                                <input className="form-check-input shadow-none" checked={primaryDiagnosis} onChange={e => {
                                    setPrimaryDiagnosis(e.target.checked);
                                    setUpdateDiagnosisObject(prevObj => ({ ...prevObj, type: e.target.checked ? 'P' : 'S' }));
                                }} type="checkbox" id="primaryDiagnosis" />
                                <label className="form-check-label font-12 text--terciary" htmlFor="primaryDiagnosis">
                                    Primary Diagnosis
                                </label>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="form-check py-1">
                                <input className="form-check-input shadow-none" checked={status} onChange={e => {
                                    setStatus(e.target.checked);
                                    setUpdateDiagnosisObject(prevObj => ({ ...prevObj, status: e.target.checked }));
                                }} type="checkbox" id="status" />
                                <label className="form-check-label font-12 text--terciary" htmlFor="status">
                                    Active
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="row py-1 justify-content-end">
                        <div className="col-4">
                            <button
                                disabled={props?.isDiagnosisUpdating || updateDiagnosisObject === undefined}
                                className={`btn  blue_task_button font-14 shadow-none ${updateDiagnosisObject === undefined ? "disbaled " : ""
                                    }`}
                                id={props?.editDiagnosis}
                                onClick={handleUpdateDiagnosis}
                            >
                                Update
                            </button>
                        </div>

                    </div>
                </div>
            </Modal.Body>

        </Modal>
    );
};

export default EditDiagnosisModal;

export const billingAndClaimsMapKeyValueArray = [
    [0, 'Patient Information'],
    [1, 'Encounters'],
    // [2, 'Invoices'],
    // [3, 'Claim Submission'],
    // [4, 'Claim Status'],
    // [5, 'Super Bill'],
    // [6, 'ERA Status'],
    // [7, 'Fee Schedule'],
]

export const STATUS_MAP = new Map([
    ['CLAIM_READY_FOR_SUBMISSION', 'Ready for Submission'],
    ['CLAIM_SUBMITTED', 'Submitted'],
    ['CLAIM_SUBMISSION_ERROR', 'Submission Error'],
    ['CLAIM_APPROVED', 'Approved'],
    ['CLAIM_DENIED', "Claim Denied"]

]);
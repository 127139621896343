import { IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RefillRequestModal from "../patientmodals/RefillRequestModal";
import { dateTimeHelper } from "../../utils/dateTimeHelper";

const Medications = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [refillModal, setRefillModal] = useState(false);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const refillModalHandler = () => {
    setRefillModal(true);
  };

  const refillModalClose = () => {
    setRefillModal(false);
  };


  return (
    <>
      {refillModal && (
        <RefillRequestModal
          show={refillModal}
          handleRefillRequestModalClose={refillModalClose}
          refillRequestObj={props?.prescriptionItem}
          fecthPatientRefills={props.fecthPatientRefills}
        />
      )}

      {/*  <div className="card pn-card py-3 "> */}
      <div className="px-3 pb-2 border-bottom">
        <div className="row justify-content-between">
          <div className="col-10">
            <span className="text--terciary m-0 fw-sb font-14 d-block">
              {props?.prescriptionItem?.drugDescription}
            </span>
            <span className="text--terciary m-0 font-14 d-block">
              {props?.prescriptionItem?.sigText}
            </span>
            <span className="text--secondary m-0 font-12 d-block">
              {props?.prescriptionItem?.refillStatus?.toLowerCase() === "pending"
                ? "Refill Requested: " +
                dateTimeHelper(
                  props?.prescriptionItem?.lastRefillDate,
                  "MMM D, YYYY"
                ) +
                " at " +
                dateTimeHelper(props?.prescriptionItem?.lastRefillDate, "LT") +
                " (Pending approval)"
                :
                props?.prescriptionItem?.refillStatus?.toLowerCase() === "declined" ?
                  "Refill Requested: " +
                  dateTimeHelper(
                    props?.prescriptionItem?.lastRefillDate || "",
                    "MMM D, YYYY"
                  ) +
                  " at " +
                  dateTimeHelper(props?.prescriptionItem?.lastRefillDate || "", "LT") +
                  " (Refill denied)"
                  :
                    props?.prescriptionItem?.refillStatus?.toLowerCase() === "refilled" ?
                    "Refill Requested: " +
                    dateTimeHelper(
                      props?.prescriptionItem?.lastRefillDate || "",
                      "MMM D, YYYY"
                    ) +
                    " at " +
                    dateTimeHelper(props?.prescriptionItem?.lastRefillDate || "", "LT") +
                    " (Refill approved)"
                  :
                  props?.prescriptionItem?.lastRefillDate !== undefined ?
                    "Last Refill: " +
                    dateTimeHelper(
                      props?.prescriptionItem?.lastRefillDate || "",
                      "MMM D, YYYY"
                    ) +
                    " at " +
                    dateTimeHelper(props?.prescriptionItem?.lastRefillDate, "LT")
                    : ""
              }
            </span>
          </div>
          <div className="col-2" 
          // hidden={!props?.medications?.isRefillAvailable}
          >
            <IconButton
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              className="ms-4"
              aria-label="more"
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              dense
              onClose={handleClose}
              disableScrollLock={true}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                dense
                onClick={() => {
                  handleClose();
                  refillModalHandler();
                }}
                sx={{ fontFamily: "Montserrat", fontSize:12 }}
                disabled={
                  props?.prescriptionItem?.refillStatus?.toLowerCase() === "pending"
                  ? true
                  : props?.prescriptionItem?.rxStatus?.toLowerCase() === "ordered"
                  ? true
                  : props?.prescriptionItem?.refillStatus?.toLowerCase() === "declined"
                  ? true
                  : false
                }
              >
                Request Refill
              </MenuItem>
            </Menu>
          </div>
        </div>
        {/* {console.log("props?.medications?.LastRefillDate",dateTimeHelper(props?.medications?.LastRefillDate, "LT"))} */}
      </div>
      {/*  </div>  */}
    </>
  );
};

export default Medications;

import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const PrescriptionBarChart = ({ prescriptions }) => {
  const chartRef = useRef(null); // Reference to the canvas
  const chartInstance = useRef(null); // To store the Chart.js instance

  // Function to process prescription data
  const processPrescriptionData = (data) => {
    const monthCounts = {
      "Jan": { SURESCRIPTS: 0, PRINT: 0, FAX: 0 },
      "Feb": { SURESCRIPTS: 0, PRINT: 0, FAX: 0 },
      "Mar": { SURESCRIPTS: 0, PRINT: 0, FAX: 0 },
      "Apr": { SURESCRIPTS: 0, PRINT: 0, FAX: 0 },
      "May": { SURESCRIPTS: 0, PRINT: 0, FAX: 0 },
      "Jun": { SURESCRIPTS: 0, PRINT: 0, FAX: 0 },
      "Jul": { SURESCRIPTS: 0, PRINT: 0, FAX: 0 },
      "Aug": { SURESCRIPTS: 0, PRINT: 0, FAX: 0 },
      "Sep": { SURESCRIPTS: 0, PRINT: 0, FAX: 0 },
      "Oct": { SURESCRIPTS: 0, PRINT: 0, FAX: 0 },
      "Nov": { SURESCRIPTS: 0, PRINT: 0, FAX: 0 },
      "Dec": { SURESCRIPTS: 0, PRINT: 0, FAX: 0 },
    };

    data.forEach(item => {
      const month = new Date(item.createdAt).toLocaleString('default', { month: 'short' });
      if (monthCounts[month] !== undefined) {
        monthCounts[month][item.workflowType]++;
      }
    });

    return {
      months: Object.keys(monthCounts),
      surecript: Object.values(monthCounts).map(month => month.SURESCRIPTS),
      fax: Object.values(monthCounts).map(month => month.FAX),
      print: Object.values(monthCounts).map(month => month.PRINT),
    };
  };

  useEffect(() => {
    const { months, surecript, fax, print } = processPrescriptionData(prescriptions);

    const data = {
      labels: months, // Months for the x-axis
      datasets: [
        {
          label: "SureScript",
          data: surecript,
          backgroundColor: "#4CAF50", // Green
        },
        {
          label: "Fax",
          data: fax,
          backgroundColor: "#FF9800", // Orange
        },
        {
          label: "Print",
          data: print,
          backgroundColor: "#F44336", // Red
        },
      ],
    };

    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: true,
          position: "bottom",
        },
        title: {
          display: true,
          text: "Monthly Prescription Methods",
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: "Number of Prescriptions",
          },
        },
      },
    };

    // Destroy the existing chart instance if it exists to avoid memory leaks
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    // Create the new chart instance
    chartInstance.current = new Chart(chartRef.current, {
      type: "bar",
      data: data,
      options: options,
    });

    // Cleanup the chart when the component unmounts
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [prescriptions]);

  return (
    <div
      style={{
        width: "100%",
        height: "300px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <canvas ref={chartRef} />
    </div>
  );
};

export default PrescriptionBarChart;

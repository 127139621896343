import { useEffect, useState } from "react";
import sendHttpRequest from "../hooks/sendHttpRequest";
import Navbar from "../UI/Navbar";
import NewPharmacyModal from "../UI/Modals/PharmacyModal/NewPharmacyModal";
import { Box, Grid, Typography, Stack, Avatar, Skeleton } from "@mui/material";
import classes from "../patient/PatientItem.module.css";
import Loading from "../UI/General/Loading";
import PharmacyItem from "../pharmacy/PharmacyItem";
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";
import EditButtonIcon from "../../assets/icons/Icon-Edit.svg";
import TrashIcon from "../../assets/icons/TrashIcon";
import { useAuth } from "../hooks/useAuth";
import { pharmacySearchURL } from "../../res/endpointurls";
import { strings } from "../../res/strings";

const PharmacyPage = (props) => {
  const __str = strings?.en
  const [newPharmacyModal, setNewPharmacyModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pharmacies, setPharmacies] = useState([]);
  const [error, setError] = useState();
  const [isSent, setIsSent] = useState(false);
  const { user } = useAuth();

  const handleNewPharmacyModal = () => {
    setNewPharmacyModal(true);
  };

  const handleNewPharmacyModalClose = () => {
    setNewPharmacyModal(false);
  };

  const fetchAllPharmacies = async () => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: pharmacySearchURL(user?.tenantId),
      method: "GET",
    });
    if (!httpResponse.error) {
      setPharmacies(httpResponse.data);
    } else {
      console.log(httpResponse.error);
      setError(httpResponse.error);
    }
    setIsLoading(false);
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsSent(false);
  };

  useEffect(() => {
    fetchAllPharmacies();
  }, [])

  return (
    <>

      {newPharmacyModal && <NewPharmacyModal
        show={newPharmacyModal}
        handleNewPharmacyModal={handleNewPharmacyModal}
        handleNewPharmacyModalClose={handleNewPharmacyModalClose}
        // addNewPatientRequestHandler={addNewPatientRequestHandler}
        setIsSent={setIsSent}
        fetchAllPharmacies={fetchAllPharmacies}
      />}
      <div className="container-fluid">
        <div className="row p-1 py-3 bg-white border-bottom justify-content-between">
          <div className="col-sm-3 align-self-center">
            <h2 className="font-22  text--terciary fw-sb">Pharmacies</h2>
          </div>
          <div className="col-sm-4 align-self-center">
            <div className="row">
              <div className="col-sm-6"></div>
              <div className="col-sm-6">
                <button
                  onClick={handleNewPharmacyModal}
                  className="btn blue_task_button br-10 bg--blue text-white font-14 fw-sb"
                >
                  Add Pharmacy
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mb-5">
        <div className="row mt-3 justify-content-center">
          <div className="col-sm-11">
            <Grid

              container
              sx={{ marginBottom: "-8px" }}
              p={1}
              className={` bg-white ${classes["row__head"]} ${pharmacies?.length === 0 ?  classes['border--bottom'] : "" }`}
            >
              <Grid item xs={2} sm={2} md={2}>

                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      m: 1,
                      fontFamily: "Montserrat",
                      fontWeight: "400",
                      fontSize: "12px",
                      color: "#336383",
                    }}
                  >
                    Pharmacy Name
                  </Box>
                </Typography>
                {/* </Stack> */}
              </Grid>
              <Grid item xs={1.25} sm={1.25}>
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      mt: 1,
                      mr: 4,
                      fontFamily: "Montserrat",
                      fontWeight: "400",
                      fontSize: "12px",
                      color: "#336383",
                    }}
                  >
                    Pharmacy Type
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={2.25}>
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      mt: 1,
                      ml: 2,
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      color: "#336383",
                    }}
                  >
                    Address
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={1.5} >
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      marginTop: "8px",
                      ml: 5,
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      color: "#336383",
                    }}
                  >
                    Phone
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={1.5}>
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      mt: 1,
                      ml: 4,
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      color: "#336383",
                    }}
                  >
                    Fax
                  </Box>
                </Typography>
              </Grid>

              <Grid item xs={1}>
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      mt: 1,
                      ml: 4,
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      color: "#336383",
                    }}
                  >
                    NPI
                  </Box>
                </Typography>
              </Grid>

              <Grid item xs={1}>
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      mt: 1,
                      ml: 4,
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      color: "#336383",
                    }}
                  >
                    Controlled Substance
                  </Box>
                </Typography>
              </Grid>

              <Grid item xs={0.5}>

              </Grid>

              <Grid item xs={0.5}>

              </Grid>

            </Grid>

            {
              isLoading ? <Skeleton animation="wave" /> : pharmacies?.length > 0 ? pharmacies?.map((pharmacy, index) => {
                let address = pharmacy?.addressLine1?.trim().concat(", ", pharmacy?.city.trim(), ", ", pharmacy?.stateProvince?.trim() == undefined ? '' : pharmacy?.stateProvince?.trim().concat(", "), pharmacy?.postalCode?.trim());
                if (index === (pharmacies?.length - 1)) {
                  return <PharmacyItem key={index} IsControlledSubstance={pharmacy?.isControlledSubstance === "0" ? false : true} NPI={pharmacy?.npi} isLast={true} isEven={index % 2 === 0 ? true : false} BusinessName={pharmacy?.businessName} Address={address} Type={pharmacy?.pharmacyType} Phone={pharmacy?.primaryTelephone} Fax={pharmacy?.faxNumber} />
                }
                else {
                  return <PharmacyItem key={index} IsControlledSubstance={pharmacy?.isControlledSubstance === "0" ? false : true} NPI={pharmacy?.npi} isLast={false} isEven={index % 2 === 0 ? true : false} BusinessName={pharmacy?.businessName} Address={address} Type={pharmacy?.pharmacyType} Phone={pharmacy?.primaryTelephone} Fax={pharmacy?.faxNumber} />
                }
              }) : <div className="text--terciary font-14 p-2">{__str?.noDataAvailable}</div>
            }
          </div>
        </div>
      </div>

      <CustomizedSnackbars
        message={"New pharmacy added."}
        severity="success"
        open={isSent}
        handleClose={handleClose}
      />
    </>
  );
};

export default PharmacyPage;

import { Modal } from "react-bootstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Stack } from "@mui/material";
import { ConfigMap } from "../../utils/helpers/ConfigMap";
import { changeDateTimeFormat, dateTimeHelper } from "../../utils/dateTimeHelper";
import { AllergySummaryComponent, fieldValidation, formatPhoneNumber } from "../../utils/helpers/HelperFunctions";
import { numbersEnum } from "../../res/strings";
import { useAuth } from "../../components/hooks/useAuth";
import { downloadPatientAfterVisitSummaryURL } from "../../res/endpointurls";
import { useState } from "react";

const PatientVisitSummaryModal = (props) => {
  const { user } = useAuth();

  const summary = props.summary;



  const primaryDiagnosis = summary?.patientChart?.diagnosis?.length > 0 ? summary?.patientChart?.diagnosis?.filter(diagnosis => diagnosis?.type?.toLowerCase() === 'p')[0] : '';
  const secondaryDiagnoses = summary?.patientChart?.diagnosis?.length > 0 ? summary?.patientChart?.diagnosis?.filter(diagnosis => diagnosis?.type?.toLowerCase() === 's') : '';
  const [isFileDownloading, setIsFileDownloading] = useState(false);
  const handleDownload = async (encounterId) => {
    setIsFileDownloading(true);
    await fetch(ConfigMap("APP") + downloadPatientAfterVisitSummaryURL(encounterId), {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
        "Authorization": localStorage.getItem("tkn") !== null ? localStorage.getItem("tkn") : ""
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // console.log(blob);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `after-summary.pdf`,
        );
        link.target = "_blank"

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();
        link.parentNode.removeChild(link);
        setIsFileDownloading(false);
      })
  }


  return (
    <>
      <Modal
        show={props?.show}
        onHide={() => {
          props?.handleModal();
        }}
        size="lg"
        backdropClassName="modal-backdrop"
        contentClassName="modal-border modal-shadow"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title className="font-18 text--terciary">
            Patient After Visit Summary
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='container br-10 px-2 py-2' style={{ border: '2px solid #d9e8ec', maxHeight: '60vh', overflowY: 'auto', overflowX: 'hidden' }}>
            <div className='row'>
              <div className="col-sm-12">
                <div className='d-flex justify-content-between'>
                  <h3 className='font-16 fw-sb text--terciary'>
                    Patient Information
                  </h3>
                </div>

                <Stack direction="row" spacing={6}>
                  <Stack direction="column" item>
                    <h6 className="font-14 m-0 text--terciary">Name</h6>
                    <p className='font-12 m-0'>{summary?.patient?.name[0]?.firstName ?? ""} {summary?.patient?.name[0]?.lastName ?? ""}</p>
                    {/* <p className='font-12 m-0'>{props?.encounter?.status !== 'CLOSED' ? props?.encounter?.HumanPatient?.Name?.FirstName ?? "" : props?.encounter?.Patient?.Name?.length > 0 ? props?.encounter?.Patient?.Name[0]?.FirstName : "" ?? ""} {props?.encounter?.status !== 'CLOSED' ? props?.encounter?.HumanPatient?.Name?.LastName ?? "No data available" : props?.encounter?.Patient?.Name?.length > 0 ? props?.encounter?.Patient?.Name[0].LastName : "No data available" ?? "No data available"}</p> */}
                    {/* <p className='font-12 m-0'>{props?.encounter?.status !== 'CLOSED' && props?.encounter?.status !== 'DISPATCHED' ? props?.encounter?.HumanPatient?.Name?.FirstName ?? "" : props?.encounter?.Patient?.Name?.length > 0 ? props?.encounter?.Patient?.Name[0]?.FirstName : "" ?? ""} {props?.encounter?.status !== 'CLOSED' && props?.encounter?.status !== 'DISPATCHED' ? props?.encounter?.HumanPatient?.Name?.LastName ?? "No data available" : props?.encounter?.Patient?.Name?.length > 0 ? props?.encounter?.Patient?.Name[0].LastName : "No data available" ?? "No data available"}</p> */}
                  </Stack>
                  <Stack direction="column" item>
                    <h6 className="font-14 m-0 text--terciary">Date of Birth</h6>

                    <p className='font-12 m-0'>{changeDateTimeFormat(summary?.patient?.dateOfBirth ?? null, "MMM D, YYYY") ?? "No data available"}</p>
                  </Stack>
                  <Stack direction="column" item>
                    <h6 className="font-14 m-0 text--terciary">Biological Sex</h6>
                    <p className='font-12 m-0'>{summary?.patient?.gender ?? ""} </p>
                  </Stack>
                </Stack>

                <div>
                  <h6 className='font-14 m-0'>Address</h6>
                  <p className='font-12 m-0'>
                    {summary?.patient?.address[0]?.addressLine1 ?? ""}{" "}
                    {summary?.patient?.address[0]?.city?.trim() ?? "" ?? " "}
                    &nbsp;
                    {summary?.patient?.address[0]?.stateProvince ?? ""}{" "}
                    {summary?.patient?.address[0]?.postalCode ?? ""}
                  </p>
                </div>

                <div>
                  <h6 className='font-14 m-0'>Phone</h6>
                  <p className='font-12 m-0'>
                    {formatPhoneNumber(summary?.patient?.communicationNumbers?.numbers?.filter(number => number?.type?.toLowerCase() === numbersEnum.en.cellPhoneExt?.toLowerCase())[0]?.number) ?? ""}
                  </p>
                </div>

                <div hidden={summary?.patient?.communicationNumbers?.emergencyContact?.find((numberItem) => { return numberItem?.phoneNumber !== "" }) === undefined}>
                  <h6 className='font-14 m-0'>Emergency Contact Number</h6>
                  <p className='font-12 m-0'>
                    {formatPhoneNumber(summary?.patient?.communicationNumbers?.emergencyContact?.find((numberItem) => { return numberItem?.phoneNumber !== "" })?.phoneNumber || "") ?? ""}
                  </p>
                </div>

                <div>
                  <h6 className='font-14 m-0'>Encounter Date</h6>
                  <p className='font-12 m-0'>
                    {summary?.encounter?.encounterCreatedDate ? dateTimeHelper(summary?.encounter?.encounterCreatedDate, "MMM D, YYYY") : ""}
                  </p>
                </div>


              </div>
            </div>
            <hr />

            <div className='row'>
              <div className="col-sm-12">
                <div className='d-flex justify-content-between'>
                  <h3 className='font-16 fw-sb text--terciary'>
                    Reason for Visit
                  </h3>

                </div>

                <div>
                  <h6 className='font-14 m-0'>Chief Complaint</h6>
                  <p className='font-12 m-0'>{summary?.encounter?.chiefComplaint ?? ""}</p>
                </div>

                <div>
                  <h6 className='font-14 m-0'>Note</h6>
                  <p className='font-12 m-0'>{summary?.encounter?.notes ?? ""} </p>
                </div>


              </div>
            </div>
            <hr />


            <div className='row'>
              <div className="col-sm-12">
                <div className='d-flex justify-content-between'>
                  <h3 className='font-16 fw-sb text--terciary'>
                    Allergies
                  </h3>

                </div>

                <div>
                  {summary?.allergies !== undefined && summary?.allergies?.length > 0 ? summary?.allergies?.map((row, index) => {
                            return (
                                <AllergySummaryComponent allergy={row} />
                            );
                    }): ""}
                </div>

              </div>
            </div>
            <hr />

            {/* <div className='row'>
              <div className="col-sm-12">
                <div className='d-flex justify-content-between'>
                  <h3 className='font-16 fw-sb text--terciary'>
                    History of Present Illness
                  </h3>

                </div>

                <div>
                  <p className='font-12 m-0'>{summary?.encounter?.historyOfPresentIllness ?? ""}</p>
                </div>

              </div>
            </div>
            <hr /> */}


            {/* <div className='row'>
              <div className="col-sm-12">
                <div className='d-flex justify-content-between'>
                  <h3 className='font-16 fw-sb text--terciary'>
                    Review of Systems
                  </h3>

                </div>
                <div>
                  <p className='font-12 m-0'>{summary?.encounter?.reviewOfSystem ?? ""}</p>
                </div>

              </div>
            </div>
            <hr /> */}

            {/* 
            <div className='row'>
              <div className="col-sm-12">
                <div className='d-flex justify-content-between'>
                  <h3 className='font-16 fw-sb text--terciary'>
                    Vitals
                  </h3>

                </div>

                <div>
                  <p className='font-12 m-0'>
                    {props?.encounter?.vital === 0 ? "No data available" : ""}
                    {summary?.patientChart?.vitals?.length === 0 ? "No data available" : ""}
                    {summary?.patientChart?.vitals?.at(0)?.temperature !== null && summary?.patientChart?.vitals?.at(0)?.temperature !== undefined && summary?.patientChart?.vitals?.at(0)?.temperature?.trim() !== '' ? summary?.patientChart?.vitals?.at(0)?.temperature?.concat("°F, ") : ''}
                    {summary?.patientChart?.vitals?.at(0)?.systolic !== null && summary?.patientChart?.vitals?.at(0)?.systolic !== undefined && summary?.patientChart?.vitals?.at(0)?.systolic?.trim() !== '' && summary?.patientChart?.vitals?.at(0)?.diastolic !== null && summary?.patientChart?.vitals?.at(0)?.diastolic !== undefined && summary?.patientChart?.vitals?.at(0)?.diastolic?.trim() !== '' ? summary?.patientChart?.vitals?.at(0)?.systolic?.concat("/", summary?.patientChart?.vitals?.at(0)?.diastolic, " mmHg, ") : ''}
                    {summary?.patientChart?.vitals?.at(0)?.pulse !== null && summary?.patientChart?.vitals?.at(0)?.pulse !== undefined && summary?.patientChart?.vitals?.at(0)?.pulse?.trim() !== '' ? summary?.patientChart?.vitals?.at(0)?.pulse?.concat("bpm, ") : ''}
                    {summary?.patientChart?.vitals?.at(0)?.spo2 !== null && summary?.patientChart?.vitals?.at(0)?.spo2 !== undefined && summary?.patientChart?.vitals?.at(0)?.spo2?.trim() !== '' ? summary?.patientChart?.vitals?.at(0)?.spo2?.concat("%, ") : ''}
                    {summary?.patientChart?.vitals?.at(0)?.weight !== null && summary?.patientChart?.vitals?.at(0)?.weight !== undefined && summary?.patientChart?.vitals?.at(0)?.weight?.trim() !== '' ? summary?.patientChart?.vitals?.at(0)?.weight?.concat("lbs, ") : ''}

                    {summary?.patientChart?.vitals?.at(0)?.height !== null && summary?.patientChart?.vitals?.at(0)?.height !== undefined && summary?.patientChart?.vitals?.at(0)?.height?.trim() !== '' ? summary?.patientChart?.vitals?.at(0)?.height?.concat("in, ") : ''}
                    {summary?.patientChart?.vitals?.at(0)?.bmi !== null && summary?.patientChart?.vitals?.at(0)?.bmi !== undefined && summary?.patientChart?.vitals?.at(0)?.bmi?.trim() !== '' ? summary?.patientChart?.vitals?.at(0)?.bmi?.concat("bmi ") : ''}
                  </p>
                </div>

              </div>
            </div>
            <hr /> */}


            {/* <div className='row'>
              <div className="col-sm-12">
                <div className='d-flex justify-content-between'>
                  <h3 className='font-16 fw-sb text--terciary'>
                    Physical Exam
                  </h3>

                </div>

                <div>

                  <p className='font-12 m-0'>{summary?.encounter?.physicalExam ?? "No data available"}</p>
                </div>

              </div>
            </div>
            <hr /> */}

            <div className='row'>
              <div className="col-sm-12">
                <div className='d-flex justify-content-between'>
                  <h3 className='font-16 fw-sb text--terciary'>
                    Diagnosis
                  </h3>

                </div>

                <div>
                  <p className='font-12 m-0'>
                    Primary Diagnosis <br />
                    Code : {(primaryDiagnosis?.code) ?? (primaryDiagnosis?.code !== undefined && Object.keys(primaryDiagnosis)?.length > 0 ? primaryDiagnosis.code ?? "No data available" : "No data available")},
                    Description: {(primaryDiagnosis?.description) ?? (primaryDiagnosis?.description !== undefined && primaryDiagnosis?.description !== null ? primaryDiagnosis?.description ?? "No data available" : "No data available")} <br />
                    Secondary Diagnosis<br />
                    {
                      secondaryDiagnoses !== ''
                        ?
                        (secondaryDiagnoses?.length > 0 ? secondaryDiagnoses?.map((item, index) => {
                          return (
                            <>
                              Code : {item?.code ?? "No data available"}, Description: {item?.description ?? "No data available"} <br />
                            </>
                          )

                        }) : '')
                        :
                        "No data available"
                    }
                    {secondaryDiagnoses?.length <= 0 || secondaryDiagnoses === undefined ? <br /> : ""}
                    Note: {primaryDiagnosis?.note ?? primaryDiagnosis?.note ?? "No data available"}
                  </p>
                </div>

              </div>
            </div>
            <hr />

            <div className='row'>
              <div className="col-sm-12">
                <div className='d-flex justify-content-between'>
                  <h3 className='font-16 fw-sb text--terciary'>
                    Treatment Plan
                  </h3>

                </div>

                <div>

                  <p className='font-12 m-0'>


                    {
                      summary?.medicationRx !== undefined ?
                        summary?.medicationRx?.length > 0 ? summary?.medicationRx?.map((item, index) => {
                          return (
                            <>
                              Medicine: {item?.drugDescription ?? "No data available"}, Sig: {item?.sigText ?? "No data available"} <br />
                            </>
                          )

                        }) : "No data available" : "No data available"

                    }
                  </p>
                  <p className='font-12 m-0'>
                    Assessment Note: {summary?.encounter?.assessmentNote ?? "No data available"}
                  </p>
                  <p className='font-12 m-0'>
                    Recommendation Note: {summary?.encounter?.recommendationNote ?? "No data available"}
                  </p>
                </div>

              </div>
            </div>
            <hr />



            <div className='row' hidden={summary?.encounter?.status?.toLowerCase() === 'closed' ? false : true}>
              <div className="col-sm-12">
                <div className='d-flex justify-content-between'>
                  <h3 className='font-16 fw-sb text--terciary'>
                    {"Signed by"}
                  </h3>
                </div>
                <div>
                  <p className='font-12 m-0'>
                    {summary?.encounter?.signedBy?.name[0]?.firstName} {summary?.encounter?.signedBy?.name[0]?.lastName}{", "} {summary?.encounter?.signedBy?.qualification} {"on "} {summary?.encounter?.signedDateTime !== null && summary?.encounter?.signedDateTime !== undefined ? dateTimeHelper(summary?.encounter?.signedDateTime, 'MMM D, YYYY LT') : "No data available"}
                  </p>
                </div>
              </div>
            </div>

            <hr hidden={summary?.encounter?.status?.toLowerCase() === 'closed' ? false : true} />



          </div>
        </Modal.Body>


        <Modal.Footer style={props?.pendingTask === true ? { display: "none" } : { border: "none" }} >
          <button disabled={isFileDownloading} className={`btn blue_task_button align-self-center`} onClick={() => handleDownload(summary?.encounter?.encounterId)}>Export as PDF</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PatientVisitSummaryModal;

import { ActionTypes } from "../constants/actionTypes"

const providerListInitialState = {
    
        providerList:[]
    

}

const setProviderListReducer = (state=providerListInitialState, action)=>{
    if (action.type === ActionTypes.SET_PROVIDER_LIST ){
        return {
            ...state,
            providerList: action.payload
            
        }
    }
    return state
}


export default setProviderListReducer;
import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import sendHttpRequest from "../../../../../components/hooks/sendHttpRequest";
import { useState } from "react";


const DeletePatientEncounterConfirmationModal = (props) => {
    const [isDeleting, setIsDeleting] = useState(false);
    async function deletePatientEncounter(encounterId) {
        setIsDeleting(true);
        const options = {
            method: 'DELETE',
            url: `/claims/patients/${encounterId}/encounters`,
        }
        const httpResponse = await sendHttpRequest(options);
        if (!httpResponse.error) {
            props?.setReloadEncounters(p => !p);
            props?.handleModal();
        }
        else {
            console.error(httpResponse);
        }
        setIsDeleting(false);
    }

    return (
        <Modal
            show={props?.show}
            onHide={() => {
                props.handleModal();
            }}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            backdropClassName="modal-backdrop"
            contentClassName={`modal-border modal-shadow `}
            scrollable={false}
            centered

        // style={{style}}
        >
            <Modal.Header  >
                <span
                    style={{
                        lineHeight: "12px",
                        width: "18px",
                        fontSize: "8pt",
                        color: "#336383",
                        position: "absolute",
                        top: 15,
                        right: 15,
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        props?.handleModal()
                    }}
                >
                    {<CloseIcon />}
                </span>
                <Modal.Title className="font-20 fw-sb">Delete Patient Encounter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row  justify-content-center">
                        <div className="col-12">
                            <span className="font-14 text--terciary">{"This will delete patient encounter from the list. Please confirm to proceed."}</span>
                            <p className="">{""}</p>
                            <div className="text-center">

                                <button
                                    disabled={isDeleting}
                                    className={`btn fw-sb b1 ${isDeleting ? "disabled" : ""} btn--border text--blue bg--lightGreen px-4 br-10 py-1`}
                                    onClick={() => {
                                        props.handleModal();
                                    }}
                                >
                                    Cancel
                                </button>
                                <button className={`btn ${isDeleting ? "disabled" : ""} btn--danger shadow-none text-white btn--border--red ms-3 px-4 py-1 br-10 b1`}
                                    disabled={isDeleting}
                                    onClick={() => deletePatientEncounter(props?.encounterId)}

                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default DeletePatientEncounterConfirmationModal;

export let AgoraRTC = require('agora-rtc-sdk-ng');

class Track {
  constructor() {
    this._value = null;
  }

  set value(newValue) {
    this._value = newValue;
  }

  get value() {
    return this._value;
  }
}

export let localAudioTrack = new Track();

export let localVideoTrack = new Track();
export const apiKey = "e9ccb1b4b9654f299814d244e3e4f96c";
export const configuration = { mode: "rtc", codec: "vp8" };
export let client = AgoraRTC.createClient(configuration);

export function pVideo(localVideoTrack, isChecked, client, divReference) {
  if (localVideoTrack != undefined && localVideoTrack != null) {
    if (isChecked) {
      client.publish(localVideoTrack);
      localVideoTrack.play(divReference);
      localVideoTrack.setEnabled(true);
    } else {
      console.log("I am stopping video");
      client.unpublish(localVideoTrack);
      localVideoTrack.stop();
      localVideoTrack.setEnabled(false);
    }
  }
}

export function pAudio(localAudioTrack, isChecked, client) {
  if (localAudioTrack != undefined && localAudioTrack != null) {
    if (isChecked) {
      // client.publish(localAudioTrack);
      console.log("Enabling audio track");
      localAudioTrack.setEnabled(true);
      localAudioTrack.setMuted(false);
    } else {
      // client.unpublish(localAudioTrack);
      console.log("Disabling audio track");
      // localAudioTrack.setEnabled(false);
      localAudioTrack.setMuted(true);
    }
  }
}

import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import sendHttpRequest from "../../hooks/sendHttpRequest";
import classes from "./PatientRegistrationLoginModal.module.css";
import { useAuth } from "../../hooks/useAuth";

const PatientRegistrationLoginModal = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    // const [errorResponse, setErrorResponse] = useState(code !== 200 ? "Please enter valid credentials" : "");
    const [errorResponse, setErrorResponse] = useState("");
    const navigate = useNavigate();
    const { login, user } = useAuth();

    const handleLoginButton = async () => {

        if (password !== "" && email !== "") {
            setErrorResponse("");
            const httpResponse = await sendHttpRequest({
                url: `/auth/patient/${props?.tid}/signin`,
                method: "POST",
                data: {
                    username: email,
                    password: password,
                }
            })
            if (!httpResponse.error) {
                let response = httpResponse.data;
                login({
                        loggedIn: true,
                        role: response?.role ?? "",
                        userId: response?.userId ?? "",
                        selectedUserId: response?.userId ?? "",
                        timezone: response?.timezoneOffset ?? "",
                        timezoneText: response?.timezone ?? "",
                        name: response?.name,
                        firstName: response?.name[0]?.firstName ?? "",
                        lastName: response?.name[0]?.lastName ?? "",
                        prefix: response?.name[0]?.prefix ?? "",
                        tenantId: response?.tenant?.tenantId,
                        tenant: response?.tenant
                    });
                navigate(`/patient-registration/options/${props?.tid}`, { replace: true });
            }
            else {
                setErrorResponse(httpResponse?.errorMessage?.message);
            }

        } else {
            setErrorResponse("Error");
        }
    };

    const handleEmailInput = (event) => {
        setEmail(event.target.value);
    };


    const handlePasswordInput = (event) => {
        setPassword(event.target.value);
    };


    return (
        <Modal
            show={props.show}
            onHide={() => {
                setErrorResponse("");
                props?.handleLoginCloseFromCrossModal();
            }}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName={`${classes.loginmodal__size}`}
            contentClassName={`${classes.modal_border} ${classes.modal_shadow}`}
            backdropClassName={`${classes.modal_backdrop}`}
            centered
            keyboard={false}
            backdrop="static"
        // style={{style}}
        >
            <Modal.Header closeButton style={{ border: "none" }}>
                <Modal.Title id="contained-modal-title-vcenter">

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row text-left justify-content-center">
                        <div className="col-12">
                            <p className="font-24 text--terciary fw-b">Login</p>
                            <p className="font-14 text--secondary">Personalized treatment to help you feel your best</p>

                            <Box component="div" noValidate sx={{ mt: 1 }}>
                                <span className="text--danger font-10">{errorResponse}</span>
                                <div className="form-group mb-2">
                                    <label
                                        htmlFor="email"
                                        style={{
                                            fontFamily: "Montserrat",
                                            fontSize: "10px",
                                            color: "#336383",
                                        }}
                                        className="text--terciary font-12"
                                    >
                                        Email
                                    </label>
                                    <input
                                        className="form-control fw-sb font-12 shadow-none br-10 input"
                                        type="email"
                                        autoFocus={true}
                                        // value={question?.states[0]?.answer}
                                        onChange={(e) => {
                                            handleEmailInput(e);
                                        }}
                                        onKeyPress={(e) => {
                                            if (e.code === "Enter" || e.charCode === 13) {
                                                handleLoginButton();
                                            }
                                        }}
                                    />
                                </div>

                                <div className="form-group">
                                    <label
                                        htmlFor="password"
                                        style={{
                                            fontFamily: "Montserrat",
                                            fontSize: "10px",
                                            color: "#336383",
                                        }}
                                        className="text--terciary font-12"
                                    >
                                        Password
                                    </label>
                                    <input
                                        className="form-control font-12 shadow-none br-10 input"
                                        type="password"
                                        onChange={(e) => {
                                            handlePasswordInput(e);
                                        }}
                                        onKeyPress={(e) => {
                                            if (e.code === "Enter" || e.charCode === 13) {
                                                handleLoginButton();
                                            }
                                        }}
                                        required
                                    />
                                </div>
                                <Grid container>
                                    <Grid sx={{ marginTop: 0.5 }} item>
                                        <Typography
                                            component="h3"
                                            sx={{
                                                color: "#336383",
                                                fontFamily: "Montserrat",
                                                fontWeight: "400",
                                                fontSize: "14px",
                                                lineHeight: "26px",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                // window.location.replace("../get-email");
                                                navigate('/get-email', { replace: true });
                                            }}
                                        >
                                            <span
                                                disabled
                                                className="font-12 fw-sb text-decoration-underline"
                                            >
                                                Forgot Password?
                                            </span>
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <div className="d-grid gap-2 mb-2">
                                    <button
                                        disabled={isLoading}
                                        className={`br-10 mt-4  text-center text-white btn blue_task_button`}
                                        onClick={(e) => {
                                            handleLoginButton();
                                        }}
                                    >
                                        Login
                                    </button>
                                </div>
                            </Box>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal >
    );
};

export default PatientRegistrationLoginModal;

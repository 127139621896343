import React from 'react'
import { Modal } from 'react-bootstrap';
import CloseIcon from "@mui/icons-material/Close";
import { WarningIcon } from '../UI/Reusable/WarningIcon';
import { getPatientConcatenatedNameFromNameObject, removeIfAllEmpty } from '../../utils/helpers/HelperFunctions';
import { changeDateTimeFormat } from '../../utils/dateTimeHelper';
import sendHttpRequest from '../hooks/sendHttpRequest';
import { useNavigate } from 'react-router-dom';
const PatientAlreadyExistsModal = (props) => {
    const { existingRecord, newRecord, primaryInsuranceJson, secondaryInsuranceJson, tertiaryInsuranceJson } = props;
    let existingPatientName = getPatientConcatenatedNameFromNameObject(existingRecord?.name?.at(0));
    let existingPatientGender = existingRecord?.gender;
    let existingPatientDateOfBirth = changeDateTimeFormat(existingRecord?.dateOfBirth, "MMM D, YYYY");
    let newPatientName = getPatientConcatenatedNameFromNameObject(newRecord?.name?.at(0));
    let newPatientGender = newRecord?.gender;
    let newPatientDateOfBirth = changeDateTimeFormat(newRecord?.dateOfBirth, "MMM D, YYYY");

    const navigate = useNavigate();
    async function addNewRecordHandler(newRecord) {
        const insurancesArray = [];
        if (removeIfAllEmpty(primaryInsuranceJson) === false) {
            insurancesArray.push(primaryInsuranceJson);
        }
        if (removeIfAllEmpty(secondaryInsuranceJson) === false) {
            insurancesArray.push(secondaryInsuranceJson);
        }
        if (removeIfAllEmpty(tertiaryInsuranceJson) === false) {
            insurancesArray.push(tertiaryInsuranceJson);
        }
        const httpResponse = await sendHttpRequest({
            url: "/patient/create",
            method: "POST",
            params: {
                action: "add"
            },
            data: {
                ...newRecord,
                patientInsurance: insurancesArray,
            },
        });
        if (!httpResponse.error) {

            navigate("/all-patients", { replace: true });
            props.handleWarningModalClose();

        }
    }

    async function mergeRecordHandler(patientId) {
        const insurancesArray = [];
        if (removeIfAllEmpty(primaryInsuranceJson) === false) {
            insurancesArray.push(primaryInsuranceJson);
        }
        if (removeIfAllEmpty(secondaryInsuranceJson) === false) {
            insurancesArray.push(secondaryInsuranceJson);
        }
        if (removeIfAllEmpty(tertiaryInsuranceJson) === false) {
            insurancesArray.push(tertiaryInsuranceJson);
        }
        const httpResponse = await sendHttpRequest({
            url: "/patient/create",
            method: "POST",
            params: {
                action: "merge",
                existingPatientId: patientId
            },
            data: {
                ...newRecord,
                patientInsurance: insurancesArray,
            },
        });
        if (!httpResponse.error) {
            navigate(`/all-patients/${patientId}`, { replace: true });
            props.handleWarningModalClose();
        }
    }

    return (
        <Modal
            show={props.show}
            onHide={() => {
                props.handleCloseModal();
            }}
            size="md"
            contentClassName={`modal-border modal-shadow`}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdropClassName={`modal-backdrop`}
            backdrop="static"
        >
            <Modal.Header style={{ border: "none" }}>
                <div className="row ">
                    <div className="col-12 ">
                        <Modal.Title className="font-18 fw-sb">  <WarningIcon style={{ height: "19px", marginBottom: '4px', fill: "#f8a02e" }} />
                            Potential Duplicate Patient Detected</Modal.Title>
                        <span
                            style={{
                                lineHeight: "12px",
                                width: "18px",
                                fontSize: "8pt",
                                color: "#336383",
                                position: "absolute",
                                top: 15,
                                right: 15,
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                props.handleWarningModalClose();
                            }}
                        >
                            {<CloseIcon />}
                        </span>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row">
                        <div className="col-12 p-0">
                            <p className='p-0 m-0 font-14'>    The system has detected a potential duplicate patient based on the following matching details:</p>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-6 p-0 col-12'>
                            <span className='fw-sb p-0 m-0 d-block font-14'>Existing Record</span>

                            <span className='d-block fw-sb font-12'>
                                Name: <span className='fw-thin'>
                                    {existingPatientName}
                                </span>
                            </span>

                            <div className='d-flex gap-3'>
                                <div className='fw-sb font-12'>
                                    DoB: <span className='fw-thin'>
                                        {existingPatientDateOfBirth}
                                    </span>
                                </div>

                                <div className='fw-sb font-12'>
                                    Gender: <span className='fw-thin'>
                                        {existingPatientGender}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6 p-0 col-12'>
                            <span className='fw-sb p-0 m-0 d-block font-14'>Newly Entered Record</span>

                            <span className='d-block fw-sb font-12'>
                                Name: <span className='fw-thin'>
                                    {newPatientName}
                                </span>
                            </span>

                            <div className='d-flex gap-3'>
                                <div className='fw-sb font-12'>
                                    DoB: <span className='fw-thin'>
                                        {newPatientDateOfBirth}
                                    </span>
                                </div>

                                <div className='fw-sb font-12'>
                                    Gender: <span className='fw-thin'>
                                        {newPatientGender}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row p-0'>
                        <div className='col-12 p-0'>
                            <div className='d-flex px-0 justify-content-between mt-3'>
                                <div>
                                    <button
                                        onClick={() => { props.handleWarningModalClose() }}
                                        className='btn br-10 bg--d9e8ec shadow-none  text--blue font-12 fw-sb'>Close</button>
                                </div>
                                <div className='d-flex'>
                                    <div>
                                        <button
                                            onClick={() => mergeRecordHandler(existingRecord?.userId)}
                                            className='btn br-10 blue_task_button    fw-sb'>Merge Records</button>
                                    </div>
                                    <div>
                                        <button
                                            onClick={() => addNewRecordHandler(newRecord)}
                                            className='btn br-10 blue_task_button   fw-sb'>Add New Record</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Modal.Body>
        </Modal>
    )
}

export default PatientAlreadyExistsModal
import { useEffect, useRef, useState } from "react";
import options from "../../../../../../utils/data";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import { fieldNames } from "../../../../../../utils/helpers/ErrorFields";
import ErrorMessage from "../../../../../../components/UI/Reusable/ErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import { setDirtyBitInStore } from "../../../../../../redux/actions/providerActions";
import PhoneNumberInput from "../../../../../../components/UI/Reusable/PhoneNumberInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import { getStandardizedAddress } from "../../../../../../utils/helpers/DataLoaders";

import { ValidationErrorMessage } from "../../../../../../components/validations/ValidationErrorMessage";
import { ERROR_MESSAGES, FIELD_NAMES, MIN_MAX_VALUES } from "../../../../../../components/validations/validationschemas";
import { useLocation, useNavigate } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import { DateTime } from "luxon";
import MaskInput, { isValidSSN, isValidZipCode, formatPhoneNumber, navigateOnDirtyBit } from "../../../../../../utils/helpers/HelperFunctions";
import CalenderDatePicker from "../../../../../../components/UI/General/CalenderDatePicker";


const NewPatientModal = (props) => {
  const field_Names = { ...FIELD_NAMES.user.name, ...FIELD_NAMES.patient };
  const PATIENT_VALIDATION = { ...MIN_MAX_VALUES.name, ...MIN_MAX_VALUES.patient };
  const validationErrorMsgs = { ...ERROR_MESSAGES.user.name, ...ERROR_MESSAGES.patient };
  const [validationErrors, setValidationErrors] = useState({});

  let phoneRegEx = /\([0-9]{3}\) [0-9]{3}-[0-9]{4}/i;
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const dirtyBitStatus = useSelector((state) => state.dirtyStatus.isDirty);
  const [firstName, setFirstName] = useState(null);
  const [emergencyContactName, setEmergencyContactName] = useState(null);
  const [emergencyContactPhone, setEmergencyContactPhone] = useState(props?.patientJson?.EmergencyContact ?? null);
  const [emergencyContactRelation, setEmergencyContactRelation] = useState("");
  const [middleName, setMiddleName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [dob, setDob] = useState();
  const [ssn, setSsn] = useState("");
  const [gender, setGender] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState([]);
  const [zipCode, setZipCode] = useState("");
  const [landline, setLandline] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [emergencyContact, setEmergencyContact] = useState("");
  const [email, setEmail] = useState(null);
  const [serverErrors, setServerErrors] = useState(props?.serverErrors);
  const firstNameRef = useRef();
  const [consent, setConsent] = useState(false);

  const [isCellPhoneValid, setIsCellPhoneValid] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [validSSN, setValidSSN] = useState(props?.patientJson?.validSSN ?? "");
  const [regexErrors, setRegexErrors] = useState(
    {
      'landline': false,
      'primary': false,
      'emergency': false,
      'zipcode': false,
      'ssn': false
    }
  );


  const [isStandardAddress, setIsStandardAddress] = useState(false);
  const [standardizedAddress, setStandardizedAddress] = useState('');
  const [insured, setInsured] = useState(true);
  // const fetchAllPharmacies = async () => {
  //   // setIsLoading(true);
  //   const httpResponse = await sendHttpRequest({
  //     url: "/pharmacy/all",
  //     method: "GET",
  //   });
  //   if (!httpResponse.error) {
  //     // console.log(httpResponse.data);
  //     setPharmacies(httpResponse.data);
  //   } else {
  //     console.log(httpResponse.error);
  //     // setError(httpResponse.error);
  //   }
  //   // setIsLoading(false);
  // };

  const clearState = () => {
    setFirstName(null);
    setEmergencyContactName(null);
    setEmergencyContactPhone("");
    setEmergencyContactRelation("");
    setLastName(null);
    setSsn("");
    setGender("");
    // setPharmacy("");
    setCity("");
    setState("");
    setEmail("");
    setCellPhone("");
    setLandline("");
    setZipCode("");
    setServerErrors(null);
    setInsured(false);
  };
  // console.log("PATIENT_VALIDATION: ",PATIENT_VALIDATION);
  const firstNameHandler = (event) => {
    let firstName = event.target.value;
    setFirstName(firstName?.trim());

    if (firstName?.length > PATIENT_VALIDATION.max) {
      setValidationErrors({ ...validationErrors, [field_Names.firstNameMax]: validationErrorMsgs.firstNameMax })
    }
    else {
      let temp = validationErrors;
      delete temp.firstNameMax;
      setValidationErrors(temp)
    }
    dispatch(setDirtyBitInStore(true));
    props?.setPatientJson((pj) => {
      return { ...pj, FirstName: firstName?.trim() };
    });
  };

  const middleNameHandler = (event) => {
    let middleName = event.target.value;
    setMiddleName(middleName?.trim());

    if (middleName?.length > PATIENT_VALIDATION.max) {
      setValidationErrors({ ...validationErrors, [field_Names.middleNameMax]: validationErrorMsgs.middleNameMax })
    }
    else {
      let temp = validationErrors;
      delete temp.middleNameMax;
      setValidationErrors(temp)
    }
    dispatch(setDirtyBitInStore(true));
    props?.setPatientJson((pj) => {
      return { ...pj, MiddleName: middleName?.trim() };
    });
  };

  const lastNameHandler = (event) => {
    let lastName = event.target.value;
    setLastName(lastName?.trim());

    if (lastName?.length > PATIENT_VALIDATION.max) {
      setValidationErrors({ ...validationErrors, [field_Names.lastNameMax]: validationErrorMsgs.lastNameMax })
    }
    else {
      let temp = validationErrors;
      delete temp.lastNameMax;
      setValidationErrors(temp)
    }

    dispatch(setDirtyBitInStore(true));
    props?.setPatientJson((pj) => {
      return { ...pj, LastName: lastName?.trim() };
    });
  };

  const ssnHandler = (event) => {
    const value = event.target.value;

    // Check for empty or placeholder SSN
    if (value === '___-__-____' || value === '') {
      setSsn(null);
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        ssn: false,
      }));
      props?.setPatientJson((pj) => ({ ...pj, ssn: null }));
      props?.setIsValidSSN(true); // Assuming valid if empty
    }
    // Check for valid SSN
    else if (isValidSSN(value)) {
      setSsn(value);
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        ssn: false,
      }));
      props?.setPatientJson((pj) => ({ ...pj, ssn: value }));
      props?.setIsValidSSN(true); // SSN is valid
    }
    // If SSN is invalid
    else {
      setSsn(value);
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        ssn: true,
      }));
      props?.setPatientJson((pj) => ({ ...pj, ssn: value }));
      props?.setIsValidSSN(false); // SSN is invalid
    }
  };

  const genderHandler = (event) => {
    setGender(event.target.value);
    dispatch(setDirtyBitInStore(true));
    props?.setPatientJson((pj) => {
      return { ...pj, biologicalSex: event.target.value };
    });
  };


  const suffixHandler = (event) => {
    dispatch(setDirtyBitInStore(true));
    props?.setPatientJson((pj) => {
      return { ...pj, suffix: event.target.value };
    });
  };

  // const pharmacyHandler = (event) => {
  //   setPharmacy(event.target.value);
  //   props?.setPatientJson((pj) => {
  //     return { ...pj, pharmacy: event.target.value };
  //   });
  // };

  const zipCodeHandler = (event) => {
    // handleStandardAddress();
    setZipCode(event.target.value);
    props?.setPatientJson((pj) => {
      return { ...pj, ZipCode: event.target.value };
    });
    if (isValidZipCode(event.target.value)) {
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        zipcode: false,
      }));
      dispatch(setDirtyBitInStore(true));
      setZipCode(event.target.value);
      props?.setPatientJson((pj) => {
        return { ...pj, ZipCode: event.target.value };
      });
    } else {
      if (event.target.value === "") {
        setRegexErrors((previousSnapshot) => ({
          ...previousSnapshot,
          zipcode: false,
        }));
      } else {
        dispatch(setDirtyBitInStore(true));
        setRegexErrors((previousSnapshot) => ({
          ...previousSnapshot,
          zipcode: true,
        }));
      }
    }
  };

  const cityHandler = (event) => {
    let cityName = event.target.value;
    setCity(cityName);

    if (cityName?.length > PATIENT_VALIDATION.city.max) {
      setValidationErrors({ ...validationErrors, [field_Names.city]: validationErrorMsgs.city })
    }
    else {
      let temp = validationErrors;
      delete temp.city;
      setValidationErrors(temp);
    }
    dispatch(setDirtyBitInStore(true));
    props?.setPatientJson((pj) => {
      return { ...pj, City: event.target.value };
    });
    // if (standardizedAddress !== undefined && standardizedAddress !== '' && event.target.value !== standardizedAddress.city) {
    //   console.log("Called in city");
    //   clearTimeout(t);
    //   t = setTimeout(() => handleStandardAddress(), 2000);
    // }
  };

  const addressLine1Handler = (event) => {
    let address1 = event.target.value;
    setAddressLine1(address1);
    if (address1?.length > PATIENT_VALIDATION.addressLine1.max) {
      setValidationErrors({ ...validationErrors, [field_Names.addressLine1]: validationErrorMsgs.addressLine1 })
    } else {
      let temp = validationErrors;
      delete temp.addressLine1;
      setValidationErrors(temp);
    }
    dispatch(setDirtyBitInStore(true));
    props?.setPatientJson((pj) => {
      return { ...pj, addressLine1: event.target.value };
    });

  };

  const insuranceHandler = (event) => {
    setInsured(event.target.checked);
    dispatch(setDirtyBitInStore(true));

    props?.setPatientJson((pj) => { return { ...pj, Insured: event.target.checked } });
    if (event.target.checked === false) {
      console.log("Called")
      props?.clearInsuranceData();
    }
  }


  const addressLine2Handler = (event) => {
    let address2 = event.target.value;
    setAddressLine2(address2);

    if (address2?.length > PATIENT_VALIDATION.addressLine2.max) {
      setValidationErrors({ ...validationErrors, [field_Names.addressLine2]: validationErrorMsgs.addressLine2 })
    }
    else {
      let temp = validationErrors;
      delete validationErrors.addressLine2;
      setValidationErrors(temp)
    }
    dispatch(setDirtyBitInStore(true));
    props?.setPatientJson((pj) => {
      return { ...pj, addressLine2: event.target.value };
    });
    // clearTimeout(timer);
    // const newTimer = setTimeout(() => { handleStandardAddress(); }, 3000)
    // setTimer(newTimer);
    // if (standardizedAddress !== undefined && standardizedAddress !== '' && event.target.value !== standardizedAddress.addressLine1) {
    //   console.log("Called in address");
    //   handleStandardAddress();
    // }
  };

  const landlineHanlder = (phoneValue) => {
    if (phoneValue !== undefined && phoneValue && isValidPhoneNumber(phoneValue?.toString()) && phoneValue?.length === 12) {
      let landlineNumber = formatPhoneNumber(phoneValue?.replace("+1", ""));

      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        landline: false,
      }));
      setLandline(landlineNumber);
      dispatch(setDirtyBitInStore(true));
      props?.setPatientJson((pj) => {
        return { ...pj, Landline: landlineNumber };
      });
    } else if (phoneValue !== undefined && !isValidPhoneNumber(phoneValue.toString()) && phoneValue?.length > 2) {
      dispatch(setDirtyBitInStore(true));
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        landline: true,
      }))
    } else if (phoneValue === undefined || phoneValue?.length === 2) {
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        landline: false,
      }));
      setLandline("");
      // dispatch(setDirtyBitInStore(true));
      props?.setPatientJson((pj) => {
        return { ...pj, Landline: "" };
      });
    }
  };

  const cellPhoneHandler = (phoneValue) => {
    // console.log("phoneValue: ",phoneValue);
    if (phoneValue !== undefined && isValidPhoneNumber(phoneValue?.toString()) && phoneValue?.length == 12) {
      // console.log("Valed Part");
      let mobileNumber = formatPhoneNumber(phoneValue?.replace("+1", ""));
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: false,
      }));
      dispatch(setDirtyBitInStore(true));
      setCellPhone(mobileNumber);
      props?.setPatientJson((pj) => {
        return { ...pj, CellPhone: mobileNumber };
      });
    } else if (phoneValue !== undefined && !isValidPhoneNumber(phoneValue?.toString()) && phoneValue?.length > 2) {
      dispatch(setDirtyBitInStore(true));
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: true,
      }));
      setCellPhone("");
      props?.setPatientJson((pj) => {
        return { ...pj, CellPhone: "" };
      });
    } else if (phoneValue?.length === 2 || phoneValue === undefined) {
      // dispatch(setDirtyBitInStore(true));
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: false,
      }));
      setCellPhone("");
      props?.setPatientJson((pj) => {
        return { ...pj, CellPhone: "" };
      });
    }
  };

  const emergencyContactHandler = (phoneValue) => {
    if (phoneValue !== undefined && isValidPhoneNumber(phoneValue?.toString())) {
      let emergencyContactNumber = formatPhoneNumber(phoneValue?.replace("+1", ""));
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        emergency: false,
      }));
      dispatch(setDirtyBitInStore(true));
      setEmergencyContactPhone(emergencyContactNumber);
      props?.setPatientJson((pj) => {
        return { ...pj, EmergencyContact: emergencyContactNumber };
      });
    } else if (phoneValue !== undefined && !isValidPhoneNumber(phoneValue.toString()) && phoneValue?.length > 2) {
      dispatch(setDirtyBitInStore(true));
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        emergency: true,
      }));
      setEmergencyContactPhone("")
      props?.setPatientJson((pj) => {
        return { ...pj, EmergencyContact: "" };
      });

    } else if (phoneValue === undefined || phoneValue?.length === 2) {
      // dispatch(setDirtyBitInStore(true));
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        emergency: false,
      }));
      setEmergencyContactPhone("")
      props?.setPatientJson((pj) => {
        return { ...pj, EmergencyContact: "" };
      });
    }
  };

  const emergencyContactNameHandler = (event) => {
    setEmergencyContactName(event.target.value);
    dispatch(setDirtyBitInStore(true));
    props?.setPatientJson((pj) => {
      return { ...pj, emergencyContactName: event.target.value };
    });
  };

  const emergencyContactRelationshipHandler = (event) => {
    setEmergencyContactRelation(event.target.value);
    dispatch(setDirtyBitInStore(true));
    props?.setPatientJson((pj) => {
      return { ...pj, Relationship: event.target.value };
    });
  };

  const emailHandler = (event) => {
    setEmail(event.target.value);
    dispatch(setDirtyBitInStore(true));
    props?.setPatientJson((pj) => {
      return { ...pj, Email: event.target.value };
    });
  };

  const dobHandler = (d) => {

    setDob(d);
    dispatch(setDirtyBitInStore(true));
    props?.setPatientJson((pj) => {
      return { ...pj, dob: d };
    });
  };

  const consentHandler = (event) => {
    setConsent(!consent);
    dispatch(setDirtyBitInStore(true));
    props?.setPatientJson((pj) => {
      return { ...pj, Consent: event.target.checked };
    });
  };

  const handleStandardAddress = async () => {
    if (props?.patientJson?.addressLine1 !== '' && props?.patientJson?.City !== '' && props?.patientJson?.ZipCode !== '') {
      if (standardizedAddress == undefined || standardizedAddress == '') {
        setIsStandardAddress(false);
        const stdAddress = await getStandardizedAddress(props?.patientJson?.addressLine1, props?.patientJson?.addressLine2, props?.patientJson?.City, props?.patientJson?.state, props?.patientJson?.ZipCode);
        await setStandardizedAddress(stdAddress);
      }
      else if (props?.patientJson?.addressLine1 !== standardizedAddress?.addressLine1 ||
        props?.patientJson?.city !== standardizedAddress?.city ||
        props?.patientJson?.state !== standardizedAddress?.stateProvince ||
        props?.patientJson?.zipCode !== standardizedAddress?.postalCode
      ) {
        setIsStandardAddress(false);
        const stdAddress = await getStandardizedAddress(props?.patientJson?.addressLine1, props?.patientJson?.addressLine2, props?.patientJson?.City, props?.patientJson?.state, props?.patientJson?.ZipCode);
        await setStandardizedAddress(stdAddress);
      }
      setShowAddress(true);
    }
  };

  function BottomNavigation(props) {
    return (
      <div className="position-fixed pb-4 mb-1 w-100 bottom-0 mx-auto bg-white bottom__nav--shadow" style={{ overflow: 'hidden', zIndex: 0 }}>
        <LinearProgress variant="determinate" style={{
          backgroundColor: "#D9E8EC",
          height: "1px"
        }} value={100} />
        <div className="d-flex p-3 ">
          <div className="position-fixed " style={{
            left: "78%",
          }}>
            <button className="btn  br-10 font-14 ms-2 fw-sb text--blue btn__bottom d9e8ec--button" style={{
              paddingLeft: "24px",
              paddingRight: "24px"
            }}
              onClick={() => {
                navigateOnDirtyBit(
                  dirtyBitStatus,
                  navigate,
                  -1,
                  dispatch,
                  setDirtyBitInStore
                );
              }
              }>
              Cancel
            </button>

            <button
              disabled={
                props?.isSending ||
                regexErrors?.zipcode === true ||
                regexErrors?.emergency === true ||
                regexErrors?.landline === true ||
                regexErrors?.ssn === true ||
                regexErrors?.primary === true ||
                (validationErrors && Object.keys(validationErrors).length > 0)
              }
              className={`btn btn-lg blue_task_button b2 px-4 br-10 py-1 ${props?.isSending ? "bg--disabled" : ""}`}
              // className="btn ms-2 blue_task_button"
              onClick={(e) => {
                props.handleChange();

              }}
            >
              {/* {location.pathname.search("add") !== -1 ? props?.isSending ? "Saving..." : "Save" : ""} */}
              {"Next"}
            </button>
          </div>
        </div>
      </div>
    );
  }

  const updatePatientAddress = (e) => {
    setShowAddress(false);
    e.preventDefault();
    dispatch(setDirtyBitInStore(true));
    setIsStandardAddress(true);
    setAddressLine1(standardizedAddress?.addressLine1);
    props?.setPatientJson((pj) => {
      return { ...pj, addressLine1: standardizedAddress?.addressLine1 };
    });

    setAddressLine2(standardizedAddress?.addressLine2);
    props?.setPatientJson((pj) => {
      return { ...pj, addressLine2: standardizedAddress?.addressLine2 };
    });

    setCity(standardizedAddress.city);
    props?.setPatientJson((pj) => {
      return { ...pj, City: standardizedAddress?.city };
    });


    handlePreset([options.find(s => s?.code?.toLowerCase() === standardizedAddress?.stateProvince?.toLowerCase())]);

    setZipCode(standardizedAddress?.postalCode);
    props?.setPatientJson((pj) => {
      return { ...pj, ZipCode: standardizedAddress?.postalCode };
    });

  }

  useEffect(() => {
    firstNameRef.current.focus();
    // fetchAllPharmacies();
    props?.setPatientForm(document.getElementById("patientForm"));
    return () => {
      clearState();
    };
  }, [props?.serverErrors]);

  const handlePreset = (s) => {
    setState(s);
    dispatch(setDirtyBitInStore(true));
    props?.setPatientJson((pj) => {
      return { ...pj, state: s[0]?.code };
    });
    // if (standardizedAddress !== undefined && standardizedAddress !== '' && s[0].code !== standardizedAddress.stateProvince) {
    //   console.log("Called by state", s, standardizedAddress?.postalCode)
    //   handleStandardAddress();
    // }
  }

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-7">
          <form
            id="patientForm"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="row mt-2  ">
              <div className="col-md-4">
                <label
                  htmlFor="FirstName"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  First Name <span>&#42;</span>
                </label>
                <input
                  type="text"
                  onChange={firstNameHandler}
                  ref={firstNameRef}
                  defaultValue={props?.patientJson.FirstName}
                  className={`form-control font-14 m-0 border--default br-10 shadow-none input`}
                  id="FirstName"
                  name="FirstName"
                  required
                // onBlur={firstNameBlur}
                />
                <ErrorMessage
                  serverErrorsArray={props?.serverErrors}
                  fieldName={fieldNames.firstName}
                />
                <ErrorMessage
                  serverErrorsArray={props?.serverErrors}
                  fieldName={fieldNames.name}
                />
                <ValidationErrorMessage
                  fieldName={field_Names.firstNameMax}
                  errors={validationErrors}
                />
              </div>

              <div className="col-md-4">
                <label
                  htmlFor="MiddleName"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Middle Name
                </label>
                <input
                  type="text"
                  onChange={middleNameHandler}
                  defaultValue={props?.patientJson.MiddleName}
                  className={`form-control font-14 m-0 border--default br-10 shadow-none input`}
                  id="MiddleName"
                  name="MiddleName"
                // onBlur={firstNameBlur}
                />
                <ValidationErrorMessage
                  fieldName={field_Names.middleNameMax}
                  errors={validationErrors}
                />
              </div>

              <div className="col-md-4 ">
                <label
                  htmlFor="LastName"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Last Name <span>&#42;</span>
                </label>
                <input
                  type="text"
                  onChange={lastNameHandler}
                  defaultValue={props?.patientJson.LastName}
                  autoComplete="off"
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="LastName"
                  name="LastName"
                  required
                />
                <ErrorMessage
                  serverErrorsArray={props?.serverErrors}
                  fieldName={fieldNames.lastName}
                />
                <ErrorMessage
                  serverErrorsArray={props?.serverErrors}
                  fieldName={fieldNames.name}
                />
                <ValidationErrorMessage
                  fieldName={field_Names.lastNameMax}
                  errors={validationErrors}
                />
              </div>

              <div className="col-md-3 mt-2">
                <label
                  htmlFor="patientSuffix"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Suffix
                </label>
                <input
                  type="text"
                  onChange={suffixHandler}
                  defaultValue={props?.patientJson.suffix}
                  autoComplete="off"
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="Suffix"
                  name="Suffix"

                />
              </div>

              <div className="col-md-3 mt-2">
                <label
                  htmlFor="BiologicalSex"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Biological Sex <span>&#42;</span>
                </label>
                <select
                  onChange={genderHandler}
                  className="form-select font-14 border--default br-10 shadow-none input"
                  aria-label="biologicalSex"
                  id="biologicalSex"
                  name="biologicalSex"
                  required
                  style={{ borderRadius: "10px" }}
                >
                  <option
                    selected={
                      props?.patientJson.biologicalSex === "" ? true : false
                    }
                    value=""
                  >
                    Select
                  </option>
                  <option
                    selected={
                      props?.patientJson.biologicalSex === "Male" ? true : false
                    }
                    value="Male"
                  >
                    Male
                  </option>
                  <option
                    selected={
                      props?.patientJson.biologicalSex === "Female" ? true : false
                    }
                    value="Female"
                  >
                    Female
                  </option>
                  <option
                    selected={
                      props?.patientJson.biologicalSex === "Unknown" ? true : false
                    }
                    value="Unknown"
                  >
                    Unknown
                  </option>
                </select>
                <ErrorMessage
                  serverErrorsArray={props?.serverErrors}
                  fieldName={fieldNames.gender}
                />
              </div>

              <div className="col-md-3 mt-2">
                <label
                  htmlFor="dob"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Date of Birth <span>&#42;</span>
                </label>
                <CalenderDatePicker
                  name="dob"
                  label="Date of birth"
                  date={
                    props?.patientJson.dob !== ""
                      ? props?.patientJson.dob
                      : ""
                  }
                  required={true}
                  dobHandler={dobHandler}
                  disableFuture={true}
                  maxDate={DateTime.now()}
                />
                <ErrorMessage
                  serverErrorsArray={props?.serverErrors}
                  fieldName={"dateOfBirth"}
                />
              </div>

              <div className="col-md-3 mt-2">
                <label
                  htmlFor="SSN"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  SSN
                </label>
                <MaskInput
                  mask={"999-99-9999"}
                  onChange={ssnHandler}
                  defaultValue={props?.patientJson.ssn}
                  autoComplete="false"
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="SSN"
                  name="SSN"
                  minLength="9"
                />
                {props?.isValidSSN === false ? <span className="text--danger font-10 ms-2 mt-0">Invalid SSN</span> : null}
              </div>

              <div className="col-md-12 mt-2">
                <label
                  htmlFor="AddressLine1"
                  className="form-label fw-sb m-0 font-12 text--secondary"
                >
                  Address line 1<span>&#42;</span>
                </label>
                <input
                  type="text"
                  onChange={addressLine1Handler}
                  value={props?.patientJson.addressLine1}
                  onBlur={() => { handleStandardAddress() }}
                  // onFocus={() => { handleStandardAddress() }}
                  autoComplete="off"
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="AddressLine1"
                  name="AddressLine1"
                  required
                />
                <ErrorMessage
                  serverErrorsArray={props?.serverErrors}
                  fieldName={fieldNames.address}
                />
                <ErrorMessage
                  serverErrorsArray={props?.serverErrors}
                  fieldName={fieldNames.addressLine1}
                />
                <ValidationErrorMessage
                  fieldName={field_Names.addressLine1}
                  errors={validationErrors}
                />
                {/* {serverErrors !== null && serverErrors?.map((error) => { return error.attributeName === "AddressLine1" && <span className="text--danger font-10 ms-2 mt-0 lh-0"> {error.message} </span> })} */}
              </div>

              <div className="col-md-12 mt-2">
                <label
                  htmlFor="AddressLine2"
                  className="form-label fw-sb m-0 font-12 text--secondary"
                >
                  Address line 2
                </label>
                <input
                  type="text"
                  onChange={addressLine2Handler}
                  value={props?.patientJson.addressLine2}
                  onBlur={() => { handleStandardAddress() }}
                  // onFocus={() => { handleStandardAddress() }}
                  autoComplete="off"
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="AddressLine2"
                  name="AddressLine2"

                />
                <ErrorMessage
                  serverErrorsArray={props?.serverErrors}
                  fieldName={fieldNames.address}
                />
                <ErrorMessage
                  serverErrorsArray={props?.serverErrors}
                  fieldName={fieldNames.addressLine1}
                />
                <ValidationErrorMessage
                  fieldName={field_Names.addressLine2}
                  errors={validationErrors}
                />
                {/* {serverErrors !== null && serverErrors?.map((error) => { return error.attributeName === "AddressLine1" && <span className="text--danger font-10 ms-2 mt-0 lh-0"> {error.message} </span> })} */}
              </div>

              <div className="col-md-6 mt-2">
                <label
                  htmlFor="City"
                  className="form-label fw-sb m-0 font-12 text--secondary"
                >
                  City <span>&#42;</span>
                </label>
                <input
                  type="text"
                  onChange={cityHandler}
                  value={props?.patientJson.City}
                  onBlur={() => handleStandardAddress()}
                  // onFocus={() => handleStandardAddress()}
                  autoComplete="off"
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="City"
                  name="City"
                  required
                />
                <ErrorMessage
                  serverErrorsArray={props?.serverErrors}
                  fieldName={fieldNames.city}
                />
                <ValidationErrorMessage
                  fieldName={field_Names.city}
                  errors={validationErrors}
                />
              </div>

              <div className="col-md-3 mt-2">
                <label
                  htmlFor="State"
                  className="form-label fw-sb m-0 font-12 text--secondary"
                >
                  State <span>&#42;</span>
                </label>

                <Typeahead
                  id="state"
                  name="state"
                  labelKey="name"
                  onChange={handlePreset}
                  onBlur={handleStandardAddress}
                  // onFocus={handleStandardAddress}
                  options={options}
                  className="rbt-menu my-select font-12"
                  selected={
                    props.patientJson?.state?.length > 0
                      ? [options.find((s) => s.code === props?.patientJson?.state)]
                      : ""
                  }
                  // defaultInputValue={standardizedAddress !== undefined && standardizedAddress !== '' ? options.find(s => s.code === standardizedAddress.stateProvince)?.name : props?.patientJson?.state[0]?.name}
                  style={{ borderRadius: "10px" }}
                  autoComplete="off"
                  onInvalid={(e) => {
                    e.target.setCustomValidity("Please select a state");
                  }}
                  onInput={(e) => {
                    e.target.setCustomValidity("");
                  }}
                  inputProps={{ required: true, className: "font-14" }}
                />
                <ErrorMessage
                  serverErrorsArray={props?.serverErrors}
                  fieldName={fieldNames.stateProvince}
                />
              </div>

              <div className="col-md-3 mt-2">
                <label
                  htmlFor="ZipCode"
                  className="form-label fw-sb m-0 font-12 text--secondary"
                >
                  Zip Code <span>&#42;</span>
                </label>
                <input
                  type="text"
                  value={props?.patientJson?.ZipCode}
                  onChange={(e) => zipCodeHandler(e)}
                  onBlur={handleStandardAddress}
                  // onFocus={handleStandardAddress}
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="ZipCode"
                  name="ZipCode"
                  required
                />
                <ErrorMessage
                  serverErrorsArray={props?.serverErrors}
                  fieldName={fieldNames.postalCode}
                />
                <span className="text--danger py-1 font-10 d-block">
                  {regexErrors?.zipcode === true
                    ? "Incorrect Zip Code format"
                    : " "}
                </span>
              </div>

              {showAddress &&
                (<div className="col-md-12  mt-2">
                  {standardizedAddress !== '' && standardizedAddress !== undefined ? <span className="d-block text-decoration-underline br-10 text--danger font-14 fw-sb">USPS Standard Address</span> : null}
                  {standardizedAddress !== '' && standardizedAddress !== undefined ? <div className=" row ">

                    <div className="col-sm-3">
                      <span className="d-block font-12 text--terciary ">
                        {standardizedAddress?.addressLine1}
                      </span>
                      <span className="d-block font-12 text--terciary ">
                        {standardizedAddress?.addressLine2}
                      </span>
                      <span className="d-block font-12 text--terciary ">
                        {standardizedAddress?.city}, {standardizedAddress?.stateProvince}, {standardizedAddress?.postalCode}
                      </span>
                    </div>
                    <div className="col-sm-4 align-self-center">
                      <button className="btn btn-outline-primary btn-outline-blue shadow-none font-12" onClick={(event) => updatePatientAddress(event)}>Use Standard Address</button>
                    </div>
                  </div> : null}
                </div>
                )}


              <div className="col-md-12">
                <h3 className="mt-3 mb-2 font-16 fw-sb">Contact Information</h3>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label
                    htmlFor="CellPhone"
                    className="form-label m-0 fw-sb font-12 text--secondary"
                  >
                    Mobile Phone <span>&#42;</span>
                  </label>
                  {/* <PhoneInput
                    name="CellPhone"
                    defaultValue={props?.patientJson.CellPhone}
                    onChange={cellPhoneHandler}
                    id="CellPhone"
                    
                    // onBlur={(event) => { event.target.value === "(___) ___-____" && setPhoneErrors((previousSnapshot) => ({ ...previousSnapshot, "primary": false })) }}
                    required
                  ></PhoneInput> */}
                  <div>
                    <PhoneNumberInput
                      value={props?.patientJson.CellPhone || ""}
                      name="CellPhone"
                      onChange={cellPhoneHandler}
                      id="CellPhone"
                      required={true}
                    />
                  </div>

                  <span className="text--danger py-1 font-10 d-block">
                    {regexErrors?.primary === true ? (
                      "Incorrect number format"
                    ) : (
                      <ErrorMessage
                        serverErrorsArray={props?.serverErrors}
                        fieldName={"number"}
                      />
                    )}
                  </span>
                </div>

                <div className="col-md-6">
                  <label
                    htmlFor="Email"
                    className="form-label m-0 fw-sb font-12 text--secondary"
                  >
                    Email <span>&#42;</span>
                  </label>
                  <input
                    type="email"
                    onChange={emailHandler}
                    defaultValue={props?.patientJson.Email}
                    autoComplete="off"
                    className="form-control font-14 border--default br-10 shadow-none input"
                    id="Email"
                    name="Email"
                    required
                  />
                </div>

                <div className="col-md-6 ">
                  <label
                    htmlFor="Landline"
                    className="form-label m-0 fw-sb font-12 text--secondary"
                  >
                    Home Phone
                  </label>
                  <div>
                    <PhoneNumberInput
                      name="Landline"
                      value={props?.patientJson.Landline}
                      onChange={landlineHanlder}
                      id="Landline"
                      required={false}
                    />
                  </div>
                  <span className="text--danger py-1 font-10 d-block">
                    {regexErrors?.landline === true
                      ? "Incorrect number format"
                      : <ErrorMessage
                        serverErrorsArray={props?.serverErrors}
                        fieldName={"number"}
                      />}
                  </span>
                </div>
              </div>

            </div>
          </form>
          {/* </Modal.Body> */}
          {/* <Modal.Footer className="modal-footer"> */}
          <div className="row w-100 py-4 justify-content-start">
            {/* <div className="form-group  col-12">
              <input
                className="form-check-input"
                type="checkbox"
                onChange={consentHandler}
                defaultChecked={props?.patientJson.Consent}
                name="consent"
                id="consent"
              />
              <label
                htmlFor="consent"
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "12px",
                  lineHeight: "20px",
                  color: "#323232",
                }}
                className="form-check-label  ms-2 text-justify"
              >
                Patient consents to receive email notifications.
              </label>
            </div> */}
            {/* <div className="col-6 text-end">
              <button
                type="btn"
                className={`btn blue_task_button bg--blue fw-sb font-14 text-white btn-lg b2 px-5 br-10 py-1 ${props?.isSending ? "bg--disabled" : ""}`}
                disabled={
                  props?.isSending ||
                    regexErrors?.zipcode === true ||
                    regexErrors?.emergency === true ||
                    regexErrors?.landline === true ||
                    validSSN === false ||
                    regexErrors?.primary === true
                    ? true
                    : Object?.keys(validationErrors)?.length !== 0 ? true : false

                }
                onClick={props?.handleSubmit}
              >
                {props?.isSending ? "Saving..." : "Save"}
              </button>
            </div> */}
          </div>
          {/* {console.log("props?.serverErrors",props?.serverErrors["Patient.Name.FirstName"])} */}

          {/* </Modal.Footer> */}
          {/* </Modal> */}
        </div>
      </div>
      <BottomNavigation isSending={props?.isSending} handleChange={props?.handleSubmit} primaryInsuranceJson={props?.primaryInsuranceJson} />
    </>
  );
};

export default NewPatientModal;

import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import {
  ERROR_MESSAGES,
  FIELD_NAMES,
  MIN_MAX_VALUES,
} from "../../../validations/validationschemas";
import { useState } from "react";
import classes from "../../../patient/PatientVitals.module.css";
import { useRef } from "react";
import { useEffect } from "react";
import InPatientAppointmentService from "../../../Services/InPatientAppointment.service";

const AddVitalsModal = (props) => {
  const field_Name = FIELD_NAMES.vitals;
  const VITAL_VALIDATION = MIN_MAX_VALUES.vitals;
  const validationErrorMsgs = ERROR_MESSAGES.vitals;
  const [vitalValidationErrors, setVitalValidationErrors] = useState([]);
  const [vitals, setVitals] = useState();

  const temprature = useRef();
  const systolic = useRef();
  const diastolic = useRef();
  const spo = useRef();
  const height = useRef();
  const weight = useRef();
  const pulse = useRef();
  const bmi = useRef();

  async function makeAppointmentProviderReady(appointmentId) {
    const response = await InPatientAppointmentService.markAppointmentProviderReady(appointmentId, "provider-ready");
  }

  const handleVitalsValidation = (fieldValue, fieldName) => {
    let value = fieldValue;
    // props.setUnsavedChanges(true);
    if (
      (value < VITAL_VALIDATION[fieldName]?.min ||
        value > VITAL_VALIDATION[fieldName].max) &&
      value.length > 0
    ) {
      let fieldExist = vitalValidationErrors.filter((item) => {
        return item.fieldName === field_Name[fieldName];
      });
      if (fieldExist.length === 0) {
        setVitalValidationErrors([
          ...vitalValidationErrors,
          { fieldName: field_Name[fieldName] },
        ]);
      }
    } else {
      let temp = vitalValidationErrors.filter((item) => {
        return item.fieldName !== field_Name[fieldName];
      });
      setVitalValidationErrors(temp);
    }
  };
  const handleTemperature = (event) => {
    event.preventDefault();
    let temperature = event.target.value;
    handleVitalsValidation(temperature, field_Name.temperature);
    setVitals({
      ...vitals,
      temperature: temperature,
    });
  };

  const handleBloodPressureSystolic = (event) => {
    // console.log("Systolic",event.target.value);
    // setSystolic(event.target.value)
    event.preventDefault();
    // props.setUnsavedChanges(true);
    let systolic = event.target.value;
    handleVitalsValidation(systolic, field_Name.systolic);
    setVitals({
      ...vitals,
      systolic: systolic,
    });
  };

  const handleBloodPressureDiastolic = (event) => {
    // console.log("Diastolic",event.target.value);
    // setDiastolic(event.target.value)
    event.preventDefault();
    // props.setUnsavedChanges(true);
    let diastolic = event.target.value;
    handleVitalsValidation(diastolic, field_Name.diastolic);
    setVitals({
      ...vitals,
      diastolic: diastolic,
    });
  };

  const handlePulse = (event) => {
    // console.log("Pulse",event.target.value);
    // setPulse(event.target.value)
    event.preventDefault();
    // props.setUnsavedChanges(true);
    let pulse = event.target.value;
    handleVitalsValidation(pulse, field_Name.pulse);

    setVitals({
      ...vitals,
      pulse: pulse,
    });
  };

  const handleSPO = (event) => {
    // console.log("SPO",event.target.value);
    // setSpo(event.target.value)
    event.preventDefault();
    // props.setUnsavedChanges(true);
    let SPO = event.target.value;
    handleVitalsValidation(SPO, field_Name.SPO);

    setVitals({
      ...vitals,
      spo2: SPO,
    });
  };

  const handleWeight = (event) => {
    event.preventDefault();
    // props.setUnsavedChanges(true);
    let weight = event.target.value;
    handleVitalsValidation(weight, field_Name.weight);

    // changed from non decimal to handle decimal values
    // const weightValue = Math.round(event.target.value.replace(/\D/g, ""));
    const weightValue = weight;
    if (weightValue !== null && weightValue !== undefined) {
      const bmiValue = calcBMI(weightValue, vitals?.height);
      handleBMI(bmiValue);
      document.getElementById("bmi").value = bmiValue;
    }
    setVitals({
      ...vitals,
      weight: weightValue,
    });
    // if(!isNaN(weight) && !isNaN(vitals.height)){
    //     setVitals({
    //         ...vitals,
    //         bmi
    //     })
    // }
  };

  const handleHeight = (event) => {
    event.preventDefault();
    // props.setUnsavedChanges(true);
    let height = event.target.value;
    handleVitalsValidation(height, field_Name.height);

    // chnages non decimal to handle non decimal value
    // const heightValue = Math.round(weight.replace(/\D/g, ""));
    const heightValue = height;
    if (height !== null && height !== undefined) {
      const bmiValue = calcBMI(vitals?.weight, heightValue);
      // console.log(bmiValue);
      handleBMI(bmiValue);
      document.getElementById("bmi").value = bmiValue;
    }
    setVitals({
      ...vitals,
      height: heightValue,
    });
  };

  const handleBMI = (value) => {
    // console.log("BMI", value);
    // setBmi(height)

    setVitals({
      ...vitals,
      bmi: value,
    });
  };
  const calcBMI = (weight, height) => {
    const heightSqr = Math.pow(Number(height), 2);
    return ((Number(weight) / Number(heightSqr)) * 703).toFixed(1);
  };

  const clearVitals = () => {
    temprature.current.value = "";
    systolic.current.value = "";
    diastolic.current.value = "";
    spo.current.value = "";
    height.current.value = "";
    weight.current.value = "";
    pulse.current.value = "";
    bmi.current.value = "";
  };
  // useEffect(()=>{
  //     return ()=> clearVitals()
  // },[])
  return (
    <>
      <Modal
        show={props.show}
        // onHide={() => {
        //   setPrescriberScheduleData([]);
        //   props.handleModal();
        // }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        backdropClassName="modal-backdrop"
        contentClassName="modal-border modal-shadow"
        // dialogClassName={classes.root}
        scrollable
        centered
      >
        <Modal.Header className="modal-header">
          <span
            style={{
              lineHeight: "12px",
              width: "18px",
              fontSize: "8pt",
              color: "#336383",
              position: "absolute",
              top: 15,
              right: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              props?.handleModal();
            }}
          >
            {<CloseIcon />}
          </span>
          <Modal.Title className="font-20 fw-sb">Add Vitals</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12  ">
              <div className={`row  g-0 br-10 `}>
                <div className="col-md pe-1">
                  <span
                    style={{ fontSize: "12px" }}
                    className="text--secondary fw-thin d-block mb-1"
                  >
                    Temp
                  </span>

                  <div className="input-group flex-nowrap">
                    <input
                      id="temperature"
                      ref={temprature}
                      className={`form-control p-2 border-end-0 ${classes["input"]} text--terciary font-16 fw-sb br-10  shadow-none `}
                      type="number"
                      min="0"
                      onChange={(e) => handleTemperature(e)}
                    />

                    <span
                      style={{ fontSize: "12px" }}
                      className={`input-group-text pe-1 br-10 ${classes["b"]} border-1 br-10 bg-white border-start-0`}
                    >
                      &deg;F
                    </span>
                  </div>
                </div>

                <div className="col-md pe-1">
                  <span
                    style={{ fontSize: "12px" }}
                    className=" text--secondary fw-thin d-block mb-1"
                  >
                    Blood Pressure
                  </span>
                  <div className="row g-0">
                    <div className="col-4">
                      <input
                        id="systolic"
                        type="number"
                        ref={systolic}
                        style={{ flexBasis: "60px" }}
                        className={`form-control p-2 flex-grow-0 form-control-sm ${classes["input-bp"]} text--terciary font-16 fw-sb br-10  shadow-none `}
                        onChange={(e) => handleBloodPressureSystolic(e)}
                      />
                    </div>

                    <div className="col-1">
                      <span className="my-2 text-center text--secondary d-block">
                        &#47;
                      </span>
                    </div>

                    <div className="col-7">
                      <div className="input-group ">
                        {/* <div class="input-group-append"> */}

                        {/* <span class="input-group-text" id="basic-addon1">@</span> */}
                        {/* </div> */}
                        <input
                          id="diastolic"
                          type="number"
                          ref={diastolic}
                          // style={{ flexBasis: "20px" }}
                          className={`form-control p-2 form-control-sm ${classes["input"]} text--terciary font-16 fw-sb br-10  shadow-none `}
                          onChange={(e) => handleBloodPressureDiastolic(e)}
                        />
                        <span
                          style={{ fontSize: "12px" }}
                          className={`input-group-text br-10 pe-1 ${classes["b"]} p-0 bg-white  text--secondary`}
                        >
                          mmHg
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md pe-1">
                  <span
                    style={{ fontSize: "12px" }}
                    className="text--secondary fw-thin d-block mb-1"
                  >
                    Pulse
                  </span>
                  <div className="input-group   mb-3">
                    <input
                      id="pulse"
                      ref={pulse}
                      type="number"
                      style={{ flexBasis: "45px" }}
                      className={`form-control p-2 form-control-sm ${classes["input"]} text--terciary font-16 fw-sb br-10  shadow-none `}
                      onChange={(e) => handlePulse(e)}
                    />
                    <span
                      style={{ fontSize: "12px" }}
                      className={`input-group-text br-10 pe-1 ${classes["b"]} p-0 bg-white  text--secondary`}
                    >
                      bpm
                    </span>
                  </div>
                </div>
              </div>
              <div className="row g-0 ">
                <div className="col-md pe-1">
                  <span
                    style={{ fontSize: "12px" }}
                    className="text--secondary  fw-thin d-block mb-1"
                  >
                    SPO<sub>2</sub>
                  </span>
                  <div className="input-group flex-nowrap">
                    <input
                      id="spo"
                      ref={spo}
                      type="number"
                      style={{ flexBasis: "100%" }}
                      className={`form-control p-2 flex-grow-0 form-control-sm ${classes["input"]} text--terciary font-16 fw-sb br-10  shadow-none `}
                      onChange={(e) => handleSPO(e)}
                    />
                    <span
                      style={{ fontSize: "12px" }}
                      className={`input-group-text p-0 pe-1 br-10 ${classes["b"]} bg-white text--secondary`}
                    >
                      %
                    </span>
                  </div>
                </div>

                <div className="col-md pe-1">
                  <span
                    style={{ fontSize: "12px" }}
                    className="text--secondary  fw-thin d-block mb-1"
                  >
                    Height
                  </span>
                  <div className="input-group  flex-nowrap mb-3">
                    <input
                      id="height"
                      ref={height}
                      type="number"
                      style={{ flexBasis: "100%" }}
                      className={`form-control p-2 flex-grow-0 form-control-sm ${classes["input"]} text--terciary font-16 fw-sb br-10  shadow-none `}
                      onChange={(e) => handleHeight(e)}
                    />
                    <span
                      style={{ fontSize: "12px" }}
                      className={`input-group-text pe-1 br-10 ${classes["b"]} p-0 bg-white  text--secondary`}
                    >
                      inches
                    </span>
                  </div>
                </div>

                <div className="col-md pe-1">
                  <span
                    style={{ fontSize: "12px" }}
                    className="text--secondary fw-thin d-block mb-1"
                  >
                    Weight
                  </span>
                  <div className="input-group mb-3 flex-nowrap">
                    <input
                      id="weight"
                      ref={weight}
                      type="number"
                      // onKeyPress={(e) => handleWeight(e)}
                      // onKeyPress={"return (event.charCode > 31 && event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"}
                      style={{ flexBasis: "100%" }}
                      className={`form-control p-2 flex-grow-0 form-control-sm ${classes["input"]} text--terciary font-16 fw-sb br-10  shadow-none `}
                      onChange={(e) => handleWeight(e)}
                    />
                    <span
                      style={{ fontSize: "12px" }}
                      className={`input-group-text p-0 pe-1 ${classes["b"]} bg-white text--secondary`}
                    >
                      lbs
                    </span>
                  </div>
                </div>

                <div className="col-md">
                  <span
                    style={{ fontSize: "12px" }}
                    className="text--secondary  fw-thin d-block mb-1"
                  >
                    BMI
                  </span>

                  <input
                    id="bmi"
                    ref={bmi}
                    type="number"
                    disabled
                    className={`form-control bg--disabled p-2 form-control-sm ${classes["input-bp"]} text--terciary font-16 fw-sb br-10  shadow-none `}
                    onChange={(e) => handleBMI(e)}
                  />
                </div>
              </div>
              {
                <div className="row">
                  <div className="col-12">
                    {vitalValidationErrors?.length > 0 && (
                      <>
                        <span className="font-12 text--terciary">
                          {
                            "Some of the values input for patient vitals fall beyond the typical norms shown below:"
                          }
                        </span>
                        <ul>
                          {vitalValidationErrors.map((errorItem) => {
                            return (
                              <li className="font-12 text--terciary">
                                {validationErrorMsgs[errorItem.fieldName]}
                              </li>
                            );
                          })}
                        </ul>
                        {/* <div className="">
                          <span className="text--terciary font-12">
                            {"Please confirm if you wish to proceed: "}
                          </span>
                          <button
                            className="btn d9e8ec--button"
                            onClick={() => {
                              // handleVitalsUpdate();
                              // setVitalValidationErrors([]);
                            }}
                          >
                            {"Confirm"}
                          </button>
                        </div> */}
                      </>
                    )}
                  </div>
                </div>
              }
            </div>
            <div className="text-end mt-3">
              <button
                className="btn blue_task_button text--white font-12 px-2"
                disabled={vitals === undefined ? true : false}
                onClick={async (e) => {
                  e.preventDefault();
                  props.setVitals({ ...vitals, bmi: document.getElementById("bmi").value });
                  // await makeAppointmentProviderReady(props?.appointmentId);
                  props?.handleClose();
                }}
              >
                {"Add"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddVitalsModal;

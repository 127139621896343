import { DateTime } from 'luxon';
import React, { useRef } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead';
import { useLocation } from 'react-router-dom';
import veeoneimage from "../../assets/images/veeone.png";
import SignatureCanvas from "react-signature-canvas";
import CalenderDatePicker from '../UI/General/CalenderDatePicker';
import CustomDatePicker from '../UI/General/CustomDatePicker';
import PhoneNumberInput from '../UI/Reusable/PhoneNumberInput';
import options from "../../utils/data";
import { useState } from 'react';
import CustomizedSnackbar from '../UI/General/CustomizedSnackbars';
import sendHttpRequest from '../hooks/sendHttpRequest';



const TeleconsultForm = (props) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const key = searchParams.get('key');
    const [filename, setFilename] = useState(null);
    const [sigPad, setSigPad] = useState();
    const [userData, setUserData] = useState({});
    const [state, setState] = useState();
    const [isSent, setIsSent] = useState(false);
    const fd = useRef(new FormData());
    const [isSubmitted, setIsSubmitted] = useState(false);
    const typeaheadRef = useRef();
    const [error, setShowError] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setIsSent(false);
    };

    const handleCloseError = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setShowError(false);
    };


    const handleUserInput = (event) => {

        setUserData((user) => {
            return { ...user, [event.target.name]: event.target.value };
        });

    };

    const handleState = (s) => {
        setState(s);
        setUserData((user) => {
            return { ...user, state: s?.at(0)?.name };
        });
    }


    const resetForm = () => {
        setUserData("");
        document.querySelectorAll(".react-datepicker__close-icon")
            .forEach((i) => i.click());

        document.getElementById('phoneNumber-teleconsent').value = '';
        // const clearTypeaheadValue = () => {
        if (typeaheadRef.current) {
            typeaheadRef.current.clear();
        }
        sigPad.clear();

    }


    const convertToImage = () => {
        let trimmedDataURL = sigPad.getTrimmedCanvas().toDataURL("image/png");
        // console.log(trimmedDataURL);
        let mimeType = trimmedDataURL.split(",")[0].split(":")[1].split(";")[0];

        fetch(trimmedDataURL)
            .then((res) => res.blob())
            .then((blob) => {
                const file = new File([blob], "signature.png", {
                    type: mimeType,
                });
                setFilename(file);
            });
    };

    const handleSaveConsentForm = async (e) => {
        e.preventDefault();
        if (document.getElementById("consent-form").reportValidity() && userData?.phoneNumber !== "") {
            props.handleDisableNext(true);
            fd.current.set("firstName", userData?.firstName || "");
            fd.current.set(`lastName`, userData?.lastName);
            fd.current.set("dateOfBirth", userData?.dateOfBirth);
            fd.current.set("email", userData?.email);
            fd.current.set("phoneNumber", userData?.phoneNumber);
            fd.current.set("addressLine1", userData?.addressLine1 || "");
            fd.current.set("city", userData?.city || "");
            fd.current.set("state", userData?.state || "");
            fd.current.set("zipcode", userData?.zipcode || "");
            fd.current.set("consent", userData?.consent || "");
            fd.current.set("firstNameGuardian", userData?.firstNameGuardian || "");
            fd.current.set("lastNameGuardian", userData?.lastNameGuardian || "");

            if (filename !== null) {
                fd.current.set("file", filename);
            }

            const httpResponse = await sendHttpRequest({
                url: `/consentform/${props?.formId}`,
                method: "POST",
                data: fd.current,
                headers: {
                    "Content-Type": "multipart/form-data; boundary=",
                },
            });

            if (!httpResponse.error) {
                setIsSent(true);
                setIsSubmitted(true);
                props.handleDisableNext(true);
                fd.current = null;
                resetForm();
            }
            else {
                console.log("Error");
                setShowError(true);
                props.handleDisableNext(false);
                fd.current = new FormData();
            }

        }
        else {
            return;
        }

    }


    return (

        <div className='container pb-5'>
            <CustomizedSnackbar
                message={"Consent form submitted successfully"}
                severity="success"
                open={isSent}
                handleClose={handleClose}
            />
            <CustomizedSnackbar
                message={"Error while submitting consent form"}
                severity="error"
                open={error}
                handleClose={handleCloseError}
            />
            {/* <div className='row pt-5 pb-2 justify-content-center'>
                <div className='col-sm-9 text-center'>
                    <img src={veeoneimage} alt="VeeOne Health" width="400" className="img-fluid" />
                    <h2 className="fw-sb mt-5">Telemedicine Consent Form</h2>
                </div>
            </div> */}

            <div className="row justify-content-center">
                <div className='col-sm-9'>
                    <h3 className="font-16 fw-sb">Patient Information</h3>
                    <form id="consent-form" onSubmit={handleSaveConsentForm}>
                        <div className='row'>
                            <div className='col-12 col-sm-6'>
                                <div className='form-group'>
                                    <label className="form-label m-0 font-12 text--secondary fw-sb" htmlFor='firstname'>First Name <span>&#42;</span></label>
                                    <input
                                        type="text"
                                        className={`form-control font-14 m-0 border--default br-10 shadow-none input`}
                                        id="firstname"
                                        name="firstName"
                                        onChange={handleUserInput}
                                        required
                                        value={userData?.firstName ?? ""}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-sm-6'>
                                <div className='form-group'>
                                    <label className="form-label m-0 font-12 text--secondary fw-sb" htmlFor='lastname'>Last Name <span>&#42;</span></label>
                                    <input
                                        type="text"
                                        className={`form-control font-14 m-0 border--default br-10 shadow-none input`}
                                        id="lastname"
                                        name="lastName"
                                        onChange={handleUserInput}
                                        required
                                        value={userData?.lastName ?? ""}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-12 col-sm-6'>
                                <div className='form-group'>
                                    <label className="form-label m-0 font-12 text--secondary fw-sb" htmlFor='dateOfBirth'>Date of Birth <span>&#42;</span></label>
                                    <CustomDatePicker
                                        name="dateOfBirth"
                                        label="Date of Birth"
                                        clearable={true}
                                        required={true}
                                        dobHandler={(dateValue) => {
                                            setUserData((user) => ({ ...user, dateOfBirth: dateValue }))
                                        }}

                                        disableFuture={true}
                                        maxDate={DateTime.now()}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className='row mt-2'>
                            <div className="col-12 col-md-6">
                                <label
                                    htmlFor="email"
                                    className="form-label m-0 fw-sb font-12 text--secondary"
                                >
                                    Email <span>&#42;</span>
                                </label>
                                <input
                                    type="email"
                                    className="form-control font-14 border--default br-10 shadow-none input"
                                    id="email"
                                    name="email"
                                    onChange={handleUserInput}
                                    required
                                    value={userData?.email ?? ""}
                                />
                            </div>

                            <div className="col-12 col-md-6">
                                <label
                                    htmlFor="phoneNumber"
                                    className="form-label m-0 fw-sb font-12 text--secondary"
                                >
                                    Phone Number <span>&#42;</span>
                                </label>
                                <div>
                                    <PhoneNumberInput
                                        name="phoneNumber"
                                        id="phoneNumber-teleconsent"
                                        onChange={(v) => {
                                            setUserData((user) => {
                                                return { ...user, ["phoneNumber"]: v };
                                            });
                                        }}
                                        required={true}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row mt-2'>
                            <div className="col-sm-12">
                                <label
                                    htmlFor="address"
                                    className="form-label fw-sb m-0 font-12 text--secondary"
                                >
                                    Address
                                </label>
                                <input
                                    type="text"
                                    className="form-control font-14 border--default br-10 shadow-none input"
                                    id="address"
                                    onChange={handleUserInput}
                                    name="addressLine1"
                                    value={userData?.addressLine1 ?? ""}
                                />

                            </div>


                            <div className="col-md-6 mt-2">
                                <label
                                    htmlFor="City"
                                    className="form-label fw-sb m-0 font-12 text--secondary"
                                >
                                    City
                                </label>
                                <input
                                    type="text"
                                    className="form-control font-14 border--default br-10 shadow-none input"
                                    id="City"
                                    onChange={handleUserInput}
                                    name="city"
                                    value={userData?.city ?? ""}
                                />
                            </div>

                            <div className="col-md-3 mt-2">
                                <label
                                    htmlFor="State"
                                    className="form-label fw-sb m-0 font-12 text--secondary"
                                >
                                    State
                                </label>

                                <Typeahead
                                    id="state"
                                    name="state"
                                    ref={typeaheadRef}
                                    labelKey="name"
                                    onChange={handleState}
                                    options={options}
                                    className="rbt-menu my-select font-12"
                                    style={{ borderRadius: "10px" }}
                                    inputProps={{ className: "font-14" }}
                                />
                            </div>

                            <div className="col-md-3 mt-2">
                                <label
                                    htmlFor="ZipCode"
                                    className="form-label fw-sb m-0 font-12 text--secondary"
                                >
                                    Zip Code
                                </label>
                                <input
                                    type="text"
                                    className="form-control font-14 border--default br-10 shadow-none input"
                                    id="ZipCode"
                                    name="zipcode"
                                    value={userData?.zipcode ?? ""}
                                    onChange={handleUserInput}
                                />
                            </div>
                        </div>

                        <h3 className="font-16 fw-sb mt-3">Guardian Information</h3>
                        <div className='row'>
                            <div className='col-12 col-sm-6'>
                                <div className='form-group'>
                                    <label className="form-label m-0 font-12 text--secondary fw-sb" htmlFor='firstNameGuardian'>First Name</label>
                                    <input
                                        type="text"
                                        className={`form-control font-14 m-0 border--default br-10 shadow-none input`}
                                        id="firstNameGuardian"
                                        name="firstNameGuardian"
                                        value={userData?.firstNameGuardian ?? ""}
                                        onChange={handleUserInput}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-sm-6'>
                                <div className='form-group'>
                                    <label className="form-label m-0 font-12 text--secondary fw-sb" htmlFor='lastNameGuardian'>Last Name</label>
                                    <input
                                        type="text"
                                        className={`form-control font-14 m-0 border--default br-10 shadow-none input`}
                                        id="lastNameGuardian"
                                        name="lastNameGuardian"
                                        value={userData?.lastNameGuardian ?? ""}
                                        onChange={handleUserInput}
                                    />
                                </div>
                            </div>


                        </div>

                        <div className='row mt-3'>
                            <div className='col-12'>
                                <div>
                                    <div>
                                        <input
                                            checked={userData?.consent ?? false} type="checkbox" name="consent" onChange={(event) => {
                                                setUserData((user) => {
                                                    return { ...user, [event.target.name]: event.target.checked };
                                                });

                                            }} className="form-check-input" id="consentCheck" />
                                        <label className="form-check-label ms-2 font-14" htmlFor="consentCheck"><strong>
                                            By checking this box, I understand the
                                            following:</strong> <span>&#42;</span>
                                        </label>
                                    </div>
                                    {/* </strong> */}
                                    <ul className='list font-12 list-rounded'>
                                        <li> I authorize VeeOne Medical Group Professional
                                            Corporation (VMG) providers to perform treatment or
                                            necessary procedure to me/ or my (for Parent/Guardian)
                                            dependant.</li>
                                        <li> I understand that I have a responsibility to verify my
                                            identity and credentials of the healthcare provider
                                            rendering my care via telehealth and to confirm that he
                                            or she is my healthcare provider.</li>
                                        <li> I understand that the laws that protect privacy and
                                            confidentiality of medical information also apply to
                                            telemedicine, and that no information obtained in the
                                            use of telemedicine which identifies me will be
                                            disclosed to researchers or other entities without my
                                            consent.</li>
                                        <li> I understand that I have the right to withhold or
                                            withdraw my consent to the use of telemedicine in the
                                            course of my care at any time, without affecting my
                                            right to future care or treatment.</li>
                                        <li> I understand that I have the right to inspect all
                                            information obtained and recorded in the course of a
                                            telemedicine interaction, and may receive copies of this
                                            information for a reasonable fee.</li>
                                        <li> I understand that telehealth billing information is
                                            collected in the same manner as a regular office visit.
                                            My financial responsibility will be determined
                                            individually and governed by my insurance carrier(s),
                                            Medicare, or Medicaid, and it is my responsibility to
                                            check with my insurance plan to determine coverage.</li>
                                        <li> I understand that telemedicine may involve electronic
                                            communication of my personal medical information to
                                            other medical practitioners who may be located in other
                                            areas, including out of state.</li>
                                        <li> I understand that I must take reasonable steps to
                                            protect myself from unauthorized use of my electronic
                                            communications by others. The healthcare provider is not
                                            responsible for breaches of confidentiality caused by an
                                            independant third party or by me.</li>
                                        <li> I understand that I may expect the anticipated benefits
                                            from the use of telemedicine in my care, but that no
                                            results can be guaranteed or assured.
                                        </li></ul>
                                </div>

                            </div>
                        </div>

                        <div className='row mt-3'>
                            <div className='col-sm-6'>
                                <SignatureCanvas
                                    penColor="green"
                                    ref={(ref) => {
                                        setSigPad(ref);
                                    }}
                                    onBegin={() => {
                                        // onDirty();
                                    }}
                                    onEnd={() => {
                                        convertToImage();
                                    }}
                                    canvasProps={{
                                        width: 425,
                                        height: 150,
                                        className: "telemedicine-canvas",
                                    }}
                                />

                            </div>

                        </div>
                        <div className="row">
                            <div className="col-sm-6 text-end">
                                <button className='btn btn-outline-secondary shadow-none font-14 br-10'
                                    onClick={(e) => { e.preventDefault(); sigPad.clear(); setFilename(null) }}
                                >Clear</button>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-sm-6">
                                <button form="consent-form" type='submit' className='btn blue_task_button font-14 br-10'

                                >Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div >
        </div >

    )
}

export default TeleconsultForm
import { useState } from "react";
import { Modal } from "react-bootstrap";
import CustomDatePicker from "../../General/CustomDatePicker";
import { FileUploader } from "react-drag-drop-files";
import { useUnsavedWorkPrompt } from "../../../hooks/useUnsavedWorkPrompt";
import { unsavedWorkHandler } from "../../../../utils/helpers/HelperFunctions";
import CloseIcon from "@mui/icons-material/Close";
import { file_uploading_helping_strings } from "../../../../res/strings";
import { useEffect } from "react";
import CalenderDatePicker from "../../General/CalenderDatePicker";
import sendHttpRequest from "../../../hooks/sendHttpRequest";
import { providerWithNpiUrl } from "../../../../res/endpointurls";
import { useAuth } from "../../../hooks/useAuth";
import classes from "./FileUploadModal.module.css";

const FileUploadModal = (props) => {
  const { user } = useAuth();
  const [providerStatus, setProviderStatus] = useState("");
  const [fileName, setFileName] = useState([]);
  const [invalidFileType, setInvalidFileType] = useState(false);
  const [invalidFileSize, setInvalidFileSize] = useState(false);
  const [isFileValid, setIsFileValid] = useState(false);
  const [isDirty, onDirty, onPristine] = useUnsavedWorkPrompt();
  const _str = file_uploading_helping_strings.en;
  const [prescribersList, setPrescribersList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isTaskChecked, setIsTaskChecked] = useState(false);

  const MAX_UPLOAD_SIZE = parseInt(
    process.env.REACT_APP_DOCUMENT_UPLOAD_FILE_SIZE
  );
  const fileTypes = ["JPEG", "PNG", "GIF", "JPG", "PDF"];

  const fileType = (event) => {
    setProviderStatus(event.target.selectedOptions[0].dataset.isreviewrequired);
    props.setFileType(event.target.value);
  };

  const fecthAllPrescribers = async () => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: providerWithNpiUrl(user?.tenantId),
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log("prescribers-> ", httpResponse.data);
      setPrescribersList(httpResponse.data);
    }
    setIsLoading(false);
  };

  const handleFileInput = (file) => {
    if (file.size < MAX_UPLOAD_SIZE) {
      let filePath = file.name;
      var allowedExtensions =
        /(\.png|\.pdf|\.jpeg|\.jpg\.PNG|\.PDF|\.JPEG|\.JPG)$/i;
      if (!allowedExtensions.exec(filePath)) {
        file = "";
        setInvalidFileType(true);
      } else {
        setIsFileValid(true);
        const temp = [];
        temp.push(file);
        setFileName(temp);
        props.setSelectedFile(file);
        setInvalidFileType(false);
        setSaveAs(file.name);
      }
      setInvalidFileSize(false);
    } else {
      file = "";
      setInvalidFileSize(true);
      setIsFileValid(false);
    }
  };

  const setSaveAs = (fileName) => {
    document.getElementById("saveAs").value = fileName;
    props?.setDocAlias(fileName);
  };
  const clean = () => {
    setInvalidFileSize(false);
    setInvalidFileType(false);
    props?.setServiceDate(null);
    props?.setRequiredFileError("");
  };
  const handleTaskAsignee = (event) => {
    // event.preventDefault();
    if (event.target.checked) {
      setIsTaskChecked(p=>true);
    } else {
      props.setAssignee(undefined);
      setIsTaskChecked(p=>false);
    }
  };
  const handleProviderId = (event) => {
    props.setAssignee(event.target.value);
  };

  useEffect(() => {
    fecthAllPrescribers();
    return () => {
      setProviderStatus(null);
      setInvalidFileSize(null);
      setInvalidFileType(null);
      setFileName(null);
      setIsFileValid(null);
      props?.setServiceDate(null);
      props?.setAssignee(undefined);
    };
  }, []);

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          clean();
          props.handleServieDate("");
          setProviderStatus(null);
          setInvalidFileSize(null);
          setInvalidFileType(null);
          props?.setServiceDate(null);
          setFileName(null);
          setIsFileValid(null);
          unsavedWorkHandler(
            "modal",
            "There are unsaved changes. Do you wish to continue?",
            isDirty,
            props?.handleFileUploadModalClose,
            onPristine,
            props?.handleFileUploadModalClose
          );
        }}
        size="md"
        centered
        backdrop="static"
        backdropClassName="modal-backdrop"
        contentClassName="modal-border modal-shadow"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header className="modal-header">
          <span
            style={{
              lineHeight: "12px",
              width: "18px",
              fontSize: "8pt",
              color: "#336383",
              position: "absolute",
              top: 15,
              right: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              clean();
              unsavedWorkHandler(
                "modal",
                "There are unsaved changes. Do you wish to continue?",
                isDirty,
                props?.handleFileUploadModalClose,
                onPristine,
                props?.handleFileUploadModalClose
              );
            }}
          >
            {<CloseIcon />}
          </span>
          <Modal.Title className="font-20 fw-sb text--terciary">
            Upload Document
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <form
                  id="fileUploadForm"
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (e.target.reportValidity()) {
                      setProviderStatus("");
                      props?.sendFileAndType();
                      setInvalidFileType(false);
                      setInvalidFileSize(false);
                      props.handleServieDate("");
                      onPristine();
                      props?.handleFileUploadModalClose();
                    }
                  }}
                >
                  <div className="col-12 font-12 mt-3">
                    {/* <h3 className='font-14 m-0'><label htmlFor="formReportFile" class="form-label">Documents</label></h3> */}
                    {/* <input required class="form-control" type="file" accept="application/pdf, image/png, image/jpeg" id="formReportFile" onChange={ReportType} /> */}
                    <FileUploader
                      handleChange={(f) => {
                        handleFileInput(f);
                        onDirty();
                      }}
                      name="file"
                      label="Drop a file or click here"
                      types={fileTypes}
                      required
                    >
                      <div className="py-4 border--dashed bg--white br-10 d-flex justify-content-center">
                        <div>
                          <u className="file-link">{"select a file"}</u>&nbsp;
                          {"or drag and drop here"}
                        </div>
                      </div>
                    </FileUploader>
                    <span className="font-12 text-danger">
                      {props?.requiredFileError !== undefined
                        ? props?.requiredFileError
                        : ""}
                    </span>
                    {invalidFileType ? (
                      <>
                        <br />
                        <span className="font-12 text-danger">
                          {_str["invalid_file"]}
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                    {invalidFileSize ? (
                      <>
                        <br />
                        <span className="font-12 text-danger">
                          {_str["error_25MB"]}
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="mt-3">
                    <h3 className="font-12 m-0">
                      Save As <span>&#42;</span>
                    </h3>
                    <input
                      type={"text"}
                      id="saveAs"
                      required
                      onChange={(e) => {
                        onDirty();
                        props.handleDocAlias(e);
                      }}
                      className={`form-control font-13 m-0 border--default br-10 shadow-none input`}
                    />
                  </div>
                  <div className="row mt-2">
                    <div className="col-6">
                      <h3 className="font-12 m-0">Document Type</h3>
                      <select
                        className="form-select m-0 border--default br-10 shadow-none font-13"
                        aria-label="File Type Select"
                        onChange={(e) => {
                          onDirty();
                          fileType(e);
                        }}
                        required
                      >
                        <option value="">Select File Type</option>
                        {props?.showUploadData?.length > 0
                          ? props?.showUploadData?.map((item, index) => {
                              return (
                                <option
                                  key={item?.id}
                                  data-isReviewRequired={item?.isReviewRequired}
                                  value={item?.id}
                                >
                                  {item?.name ?? ""}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                    </div>
                    <div className="col-6">
                      <h3 className="font-12 m-0">Service Date</h3>
                      {/* <div className="row">
                        <div className="col-9 p-0 m-0 px-1"> */}
                      <CalenderDatePicker
                        name="servicedate"
                        id={"datepicker"}
                        f13={true}
                        dobHandler={(e) => {
                          onDirty();
                          props.handleServieDate(e);
                        }}
                        dateFormat="MMM d, yyyy"
                        maxDate={new Date()}
                      />
                    </div>
                    {/* <div className="col-3 p-0 px-2">
                          <button
                            type="button"
                            style={{ height: 34 }}
                            className={`btn px-2 br-10 border--default bg--disabled shadow-none`}
                          >
                            <CalendarMonthOutlinedIcon
                              style={{
                                marginBottom: 6,
                                color: "#336383",
                                fill: "#336383",
                                height: 18,
                                width: 18,
                              }}
                            />
                          </button>
                        </div>
                      </div> */}
                    {/* </div> */}
                  </div>
                  <div className="">
                  <div className="form-check form-check-inline mt-3">
                      <input
                        className="form-check-input cursor--pointer shadow-none"
                        type="checkbox"
                        name="createTask"
                        id="createTask" 
                        onChange={(e) => {
                          handleTaskAsignee(e);
                        }}
                        checked={isTaskChecked}
                      />
                      <label
                        className="form-check-label font-12 align-self-center"
                        htmlFor="createTask" 
                      >
                        {_str.task_review_str}
                      </label>
                    </div>

                    {isTaskChecked === true ? (
                      <div className="mt-1" hidden={!isTaskChecked}>
                        <label className="text--terciary b2 fw-sb">
                          {_str.asignee_str} <span>&#42;</span>
                        </label>

                        <select
                          className={`form-select font-12 shadow-none ${classes["custom-select"]}`}
                          aria-label="Provider select"
                          style={{
                            border: "1px solid #D9E8EC",
                            // height: "48px",
                            borderRadius: "10px",
                            // background: "#FAFCFC",
                          }}
                          required
                          onChange={(e) => handleProviderId(e)}
                        >
                          <option value="" disabled selected hidden>
                            {"Select "} {_str.asignee_str.toLowerCase()}
                          </option>
                          {prescribersList !== undefined &&
                            prescribersList?.length > 0 &&
                            prescribersList?.map((prescriberItem) => {
                              return (
                                <option
                                  value={prescriberItem?.userId}
                                  key={prescriberItem?.userId}
                                  selected={
                                    props?.selectedPrescriberId ==
                                    prescriberItem?.userId
                                  }
                                >
                                  {prescriberItem?.name[0]?.prefix}{" "}
                                  {prescriberItem?.name[0]?.firstName}{" "}
                                  {prescriberItem?.name[0]?.lastName}{" "}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    ) : null}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer style={{ border: "none" }}>
          <button
            type="submit"
            form="fileUploadForm"
            className="btn bg--blue blue_task_button text-white b2 px-4 br-10 py-1"
            disabled={
              isFileValid === false
                ? true
                : props?.isUploading === true
                ? true
                : false
            }
          >
            {props?.isUploading === true ? "Uploading..." : "Upload"}
          </button>
        </Modal.Footer>
        {/* {console.log("isTaskChecked: ", isTaskChecked)} */}
      </Modal>
    </>
  );
};

export default FileUploadModal;

import { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input/input";
import "react-phone-number-input/style.css";

const PhoneNumberInput = (props) => {
  const [value, setValue] = useState("+1" + props?.value || "");

  useEffect(() => {
    // console.log("value: ",value);
    props?.onChange(value);
    setValue(value);

  }, [value])
  return (
    <PhoneInput
      defaultCountry="US"
      className={`border--default ${props?.fontClass ? props?.fontClass : "font-14"} br-10 form-control shadow-none outline-none input ${props?.disabled === true ? 'bg--disabled' : ''}`}
      value={value}
      name={props?.name}
      id={props?.id}
      onChange={setValue}
      required={props?.required === true ? true : false}
      onInvalid={(e) => {
        e.target.setCustomValidity("Please fill out this field");
      }}
      onInput={(e) => {
        e.target.setCustomValidity("");
      }}
      disabled={props?.disabled === true ? true : false}
    />
  );
};

export default PhoneNumberInput;

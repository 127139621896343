import { useEffect } from "react";
import { LinearProgress, Skeleton } from "@mui/material";
import { common_strings } from "../../../../res/strings";
import { setDirtyBitInStore } from "../../../../redux/actions/providerActions";
import { useDispatch, useSelector } from "react-redux";
import PhoneNumberInput from "../../../../components/UI/Reusable/PhoneNumberInput";
import { useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import {
  formatPhoneNumber,
  isValidZipCode,
  navigateOnDirtyBit,
} from "../../../../utils/helpers/HelperFunctions";
import { Typeahead } from "react-bootstrap-typeahead";
import options from "../../../../utils/data";
import {
  ERROR_MESSAGES,
  FIELD_NAMES,
  MIN_MAX_VALUES,
} from "../../../../components/validations/validationschemas";
import { ValidationErrorMessage } from "../../../../components/validations/ValidationErrorMessage";
import { useLocation, useNavigate } from "react-router-dom";
import CalenderDatePicker from "../../../../components/UI/General/CalenderDatePicker";
import { DateTime } from "luxon";
import { Accordion, AccordionDetails, AccordionSummary } from "../../../../components/UI/Reusable/PnComponents";

const InsurancePage = (props) => {


  const location = useLocation();
  const navigate = useNavigate();
  const dirtyBitStatus = useSelector((state) => state.dirtyStatus.isDirty);
  const _str = common_strings["en"];
  const field_Names = { ...FIELD_NAMES.user.name, ...FIELD_NAMES.patient };
  const PATIENT_VALIDATION = {
    ...MIN_MAX_VALUES.name,
    ...MIN_MAX_VALUES.patient,
  };
  const validationErrorMsgs = {
    ...ERROR_MESSAGES.user.name,
    ...ERROR_MESSAGES.patient,
  };
  const [validationErrors, setValidationErrors] = useState({});
  const [regexErrors, setRegexErrors] = useState({
    landline: false,
    primary: false,
    emergency: false,
    zipcode: false,
  });
  const dispatch = useDispatch();
  const [contactNumberError, setContactNumberError] = useState("");
  const [payerPhoneError, setPayerPhoneError] = useState("");
  const [payersList, setPayersList] = useState(props?.payersList);
  const [payerPhone, setPayerPhone] = useState(props?.insuranceJson?.payerPhone || "");



  const handleInput = (event) => {
    try {
      const { value, name } = event.target;
      // if (value !== "") {
        dispatch(setDirtyBitInStore(true));
        if (props && typeof props.setInsuranceJson === 'function') {
          if (props?.setUpdateDisabled !== undefined) {
            props?.setUpdateDisabled(true);
          }
          props?.setInsuranceJson({ ...props?.insuranceJson, [name]: value });
        }

      // }
    } catch (error) {
      console.error("An error occurred in handleInput:", error);
    }
  };
  const handlePhoneNumber = (phoneValue) => {
    if (props?.setUpdateDisabled !== undefined) {
      props?.setUpdateDisabled(true);
    }
    if (
      phoneValue !== undefined &&
      isValidPhoneNumber(phoneValue.toString()) &&
      phoneValue?.length === 12
    ) {
      let phoneNumber = formatPhoneNumber(phoneValue?.replace("+1", ""));
      dispatch(setDirtyBitInStore(true));
      props?.setInsuranceJson((ij) => {
        return { ...ij, ["contactNumber"]: phoneNumber };
      });
      setContactNumberError("");
    } else if (
      phoneValue !== undefined &&
      !isValidPhoneNumber(phoneValue.toString()) &&
      phoneValue?.length > 12
    ) {
      dispatch(setDirtyBitInStore(true));
      props?.setInsuranceJson((ij) => {
        return { ...ij, ["contactNumber"]: "" };
      });
      setContactNumberError("Incorrect number format");
    } else if (phoneValue === undefined || phoneValue?.length === 2) {
      dispatch(setDirtyBitInStore(true));
      props?.setInsuranceJson((ij) => {
        return { ...ij, ["contactNumber"]: "" };
      });
      setContactNumberError("");
    }
  };

  const handlePayerPhone = (phoneValue) => {
    if (props?.setUpdateDisabled !== undefined) {
      props?.setUpdateDisabled(true);
    }
    if (
      phoneValue !== undefined &&
      isValidPhoneNumber(phoneValue.toString()) &&
      phoneValue?.length === 12
    ) {
      let phoneNumber = formatPhoneNumber(phoneValue?.replace("+1", ""));
      dispatch(setDirtyBitInStore(true));
      props?.setInsuranceJson((ij) => {
        return { ...ij, ["payerPhone"]: phoneNumber };
      });
      setPayerPhone(phoneValue);

      setPayerPhoneError("");
    } else if (
      phoneValue !== undefined &&
      !isValidPhoneNumber(phoneValue.toString()) &&
      phoneValue?.length > 12
    ) {
      dispatch(setDirtyBitInStore(true));
      setPayerPhone("");
      props?.setInsuranceJson((ij) => {
        return { ...ij, ["payerPhone"]: "" };
      });
      setPayerPhoneError("Incorrect number format");
    } else if (phoneValue === undefined || phoneValue?.length === 2) {
      dispatch(setDirtyBitInStore(true));
      props?.setInsuranceJson((ij) => {
        return { ...ij, ["payerPhone"]: "" };
      });
      setPayerPhone("");
      setPayerPhoneError("");
    }
  };

  const handleInsuranceCode = (event) => {
    if (props?.setUpdateDisabled !== undefined) {
      props?.setUpdateDisabled(true);
    }
    dispatch(setDirtyBitInStore(true));
    props?.setInsuranceJson((ij) => {
      return {
        ...ij,
        payerCode: event.target.value,
        insuredPayerCode: event.target.value,
        payerName:
          event.target.selectedOptions[0].attributes["data-name"].value,
      };
    });

    props?.setInsuranceJson((ij) => {
      return {
        ...ij,
        payerCode: event.target.value
      }
    });
  };

  const handleEffectiveDate = (date) => {
    if (props?.setUpdateDisabled !== undefined) {
      props?.setUpdateDisabled(true);
    }
    dispatch(setDirtyBitInStore(true));
    props?.setInsuranceJson((ij) => {
      return { ...ij, effectiveDate: date };
    });
  };

  const handleTerminationDate = (date) => {
    if (props?.setUpdateDisabled !== undefined) {
      props?.setUpdateDisabled(true);
    }
    dispatch(setDirtyBitInStore(true));
    props?.setInsuranceJson((ij) => {
      return { ...ij, terminationDate: date };
    });
  };

  // console.log(payersList);

  const insuredDobHandler = (iDob) => {
    if (props?.setUpdateDisabled !== undefined) {
      props?.setUpdateDisabled(true);
    }
    dispatch(setDirtyBitInStore(true));
    props?.setInsuranceJson((ij) => {
      return { ...ij, dateOfBirth: iDob };
    });
  };

  const handleInsuredSuffix = (event) => {
    if (props?.setUpdateDisabled !== undefined) {
      props?.setUpdateDisabled(true);
    }
    dispatch(setDirtyBitInStore(true));
    props.setInsuranceJson((ij) => {
      return { ...ij, suffix: event.target.value };
    });

  }


  const handleInsuredName = (event) => {
    if (props?.setUpdateDisabled !== undefined) {
      props?.setUpdateDisabled(true);
    }
    dispatch(setDirtyBitInStore(true));
    let iname = event.target.name;
    let inameValue = event.target.value;
    let fieldName = iname + "Max";
    if (inameValue.length > PATIENT_VALIDATION.max) {
      setValidationErrors({
        ...validationErrors,
        [fieldName]: validationErrorMsgs[fieldName],
      });
    } else {
      let temp = validationErrors;
      delete temp[fieldName];
      setValidationErrors(temp);
      if (iname === "firstName") {
        props.setInsuranceJson((ij) => {
          return { ...ij, firstName: inameValue };
        });
      } else if (iname === "lastName") {
        props.setInsuranceJson((ij) => {
          return { ...ij, lastName: inameValue };
        });
      } else if (iname === "middleName") {
        props.setInsuranceJson((ij) => {
          return { ...ij, middleName: inameValue };
        });
      }
    }
  };
  const handleInsuredGender = (event) => {
    if (props?.setUpdateDisabled !== undefined) {
      props?.setUpdateDisabled(true);
    }
    setDirtyBitInStore(true);
    props.setInsuranceJson((ij) => {
      return { ...ij, gender: event.target.value };
    });
  };
  const handleInsuredCity = (event) => {
    if (props?.setUpdateDisabled !== undefined) {
      props?.setUpdateDisabled(true);
    }
    setDirtyBitInStore(true);
    let cityValue = event.target.value;
    if (cityValue.length > PATIENT_VALIDATION.city.max) {
      setValidationErrors({
        ...validationErrors,
        [field_Names.city]: validationErrorMsgs.city,
      });
    } else {
      let temp = validationErrors;
      delete temp.city;
      setValidationErrors(temp);
    }
    props.setInsuranceJson((ij) => {
      return { ...ij, city: cityValue };
    });
  };
  const handleInsuredState = (stateValue) => {
    if (props?.setUpdateDisabled !== undefined) {
      props?.setUpdateDisabled(true);
    }
    setDirtyBitInStore(true);
    props.setInsuranceJson((ij) => {
      return { ...ij, state: stateValue[0]?.code || "" };
    });
  };
  const handleInsuredZipCode = (event) => {
    if (props?.setUpdateDisabled !== undefined) {
      props?.setUpdateDisabled(true);
    }
    setDirtyBitInStore(true);
    let zipCodeValue = event.target.value;
    if (isValidZipCode(zipCodeValue)) {
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        zipcode: false,
      }));
    } else {
      if (zipCodeValue === "") {
        setRegexErrors((previousSnapshot) => ({
          ...previousSnapshot,
          zipcode: false,
        }));
      } else {
        setRegexErrors((previousSnapshot) => ({
          ...previousSnapshot,
          zipcode: true,
        }));
      }
    }
    props.setInsuranceJson((ij) => {
      return { ...ij, zipCode: zipCodeValue };
    });
  };
  const handleInsuredAddressLine1 = (event) => {
    if (props?.setUpdateDisabled !== undefined) {
      props?.setUpdateDisabled(true);
    }
    setDirtyBitInStore(true);
    let address1 = event.target.value;
    if (address1?.length > PATIENT_VALIDATION.addressLine1.max) {
      setValidationErrors({
        ...validationErrors,
        [field_Names.addressLine1]: validationErrorMsgs.addressLine1,
      });
    } else {
      let temp = validationErrors;
      delete temp.addressLine1;
      setValidationErrors(temp);
    }
    props.setInsuranceJson((ij) => {
      return { ...ij, addressLine1: address1 };
    });
  };
  const handleInsuredAddressLine2 = (event) => {
    setDirtyBitInStore(true);
    props.setInsuranceJson((ij) => {
      return { ...ij, addressLine2: event.target.value };
    });
  };

  const handlePayerState = (stateValue) => {
    if (stateValue !== undefined && stateValue?.length > 0) {
      if (props?.setUpdateDisabled !== undefined) {
        props?.setUpdateDisabled(true);
      }
    }
    setDirtyBitInStore(true);
    props.setInsuranceJson((ij) => {
      return { ...ij, payerState: stateValue[0]?.code || "" };
    });
  };

  function BottomNavigation(props) {
    return (
      <div className="position-fixed pb-4 mb-1 w-100 bottom-0 mx-auto bg-white bottom__nav--shadow" style={{ overflow: 'hidden', zIndex: 0 }}>
        <LinearProgress variant="determinate" style={{
          backgroundColor: "#D9E8EC",
          height: "1px"
        }} value={100} />
        <div className="d-flex p-3 flex-row">
          <div className="position-fixed " style={{ left: "67%" }}>
            <button className="btn  br-10 font-12 ms-2 fw-sb text--blue btn__bottom d9e8ec--button" style={{
              paddingLeft: "24px",
              paddingRight: "24px"
            }}
              onClick={() => {
                navigateOnDirtyBit(
                  dirtyBitStatus,
                  navigate,
                  -1,
                  dispatch,
                  setDirtyBitInStore
                );
              }
              }>
              Cancel
            </button>
            <button className="btn  br-10 font-12 ms-2 me-1 fw-sb text--blue btn__bottom d9e8ec--button" style={{
              paddingLeft: "24px",
              paddingRight: "24px"
            }}
              onClick={(e) => {
                // navigateOnDirtyBit(
                //   dirtyBitStatus,
                //   navigate,
                //   -1,
                //   dispatch,
                //   setDirtyBitInStore
                // );
                props?.handlePrevious(e, 0)
              }
              }>
              {"Previous"}
            </button>
            <button
              type="submit"
              form={props?.type}
              className={`btn btn-lg blue_task_button b2 px-4 br-10 py-1`}
              // onClick={function (event) {
              //   props.handleChange();
              // }}
              disabled={props?.updateDisabled === undefined ? false : props?.updateDisabled ? false : true}
            >
              {location.pathname.search("edit") !== -1 ? "Update" : ""}
              {location.pathname.search("add") !== -1 ? "Save" : ""}
            </button>
          </div>
        </div>
      </div>
    );
  }

  useEffect(() => {
    // fetchUserInsurance();
    // fetchPayersList();
    if (props?.type?.toLowerCase() === "primary") {
      props?.setPrimaryInsurance(document.getElementById(props?.type));
      document
        .getElementById(props?.type)
        .addEventListener("submit", function (evt) {
          evt.preventDefault();
        });
    } else if (props?.type?.toLowerCase() === "secondary") {
      props?.setSecondaryInsurance(document.getElementById(props?.type));
      document
        .getElementById(props?.type)
        .addEventListener("submit", function (evt) {
          evt.preventDefault();
        });
    } else if (props?.type?.toLowerCase() === "tertiary") {
      props?.setTertiaryInsurance(document.getElementById(props?.type));
      document
        .getElementById(props?.type)
        .addEventListener("submit", function (evt) {
          evt.preventDefault();
        });
    }
  }, [props?.type, payersList?.isLoading]);



  return (
    <>
      <div className="row justify-content-center">
        <div className="col-sm-9 col-md-9">
          <div className="container-fluid bg--background ">
            <div className="row justify-content-center">
              <div className="col-10">
                <form
                  id={props?.type}
                  onSubmit={(e) => {
                    e.preventDefault();
                    props?.handleSubmit("insurance");
                    // submitAccountChanges();
                  }}
                >

                  <div className="row">
                    <Accordion
                      defaultExpanded={true}
                    >
                      <AccordionSummary>
                        <h2 className="m-0 fw-sb font-14">Payer Information</h2>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="row pb-1">
                          {/* <div className="col-12 m-0">
                      <h3 className="font-16 m-0 fw-sb">Payer Information</h3>
                    </div> */}
                          <div className="col-sm-5">
                            <label
                              htmlFor={props?.type + "InsuranceName"}
                              className="form-label m-0 font-12 text--secondary fw-sb"
                            >
                              {_str.name}<span>&nbsp;&#42;</span>
                            </label>
                            <select
                              className={`input border--default br-10 form-select font-12 shadow-none custom-select`}
                              aria-label="PayerList"
                              name="insuranceName"
                              id={props?.type + "InsuranceName"}
                              required
                              onChange={(e) => handleInsuranceCode(e)}
                              onInvalid={(e) => {
                                e.target.setCustomValidity(
                                  "Please select payer from the list"
                                );
                              }}
                              onInput={(e) => {
                                e.target.setCustomValidity("");
                              }}
                              value={props?.insuranceJson?.payerCode}
                            >
                              <option value="" selected disabled hidden>
                                Select Payer
                              </option>
                              {payersList?.isLoading === true ? (
                                <Skeleton width={"100%"} animation="wave" />
                              ) : (
                                payersList?.list?.map((payer,index) => {
                                  return (

                                    <option
                                      key={payer?.payerCode+index}
                                      value={payer?.payerCode}
                                      data-name={payer?.name}
                                    // selected={
                                    //   props?.insuranceJson?.payerCode ===
                                    //   payer?.payerCode && true
                                    // }
                                    >

                                      {payer?.name}
                                    </option>
                                  );
                                })


                              )}
                            </select>

                          </div>

                          {/* <div className="col-sm-3">
                            <label
                              htmlFor={props?.type + "InsurancePayerCode"}
                              className="form-label m-0 font-12 text--secondary fw-sb"
                            >
                              Payer Code<span>&nbsp;&#42;</span>
                            </label>
                            <input
                              type="text"
                              name="payerCode"
                              id={props?.type + "InsurancePayerCode"}
                              value={props?.insuranceJson?.payerCode || ""}
                              className={`form-control font-12 m-0 border--default br-10 shadow-none input`}
                              onChange={(e) => handleInput(e)}
                              required
                            />
                          </div>


                          <div className="col-sm-4">
                            <label
                              htmlFor="payerPhone"
                              className="form-label m-0 font-12 text--secondary fw-sb"
                            >
                              Payer Phone<span>&nbsp;&#42;</span>
                            </label>

                            <PhoneNumberInput
                              name="payerPhone"
                              fontClass="font-12"
                              value={payerPhone}
                              onChange={handlePayerPhone}
                              id={"payerPhone"}
                              required={true}
                            />
                          </div> */}
                          <div className="col-sm-12 mt-1">
                            <label
                              htmlFor={props?.type + "PayerAddress1"}
                              className="form-label m-0 font-12 text--secondary fw-sb"
                            >
                              Payer Address Line 1<span>&nbsp;&#42;</span>
                            </label>
                            <input
                              type="text"
                              name="payerAddress1"
                              id={props?.type + "PayerAddress1"}
                              defaultValue={props?.insuranceJson?.payerAddress1 || ""}
                              className={`form-control font-12 m-0 border--default br-10 shadow-none input`}
                              onChange={(e) => handleInput(e)}
                              required
                            />
                          </div>
                          <div className="col-sm-12 mt-1">
                            <label
                              htmlFor={props?.type + "PayerAddress2"}
                              className="form-label m-0 font-12 text--secondary fw-sb"
                            >
                              Payer Address Line 2
                            </label>
                            <input
                              type="text"
                              // disabled={true}
                              // value={insuranceInfo?.payerAddress2}
                              name="payerAddress2"
                              id={props?.type + "PayerAddress2"}
                              defaultValue={props?.insuranceJson?.payerAddress2 || ""}
                              className={`form-control font-12 m-0 border--default br-10 shadow-none input`}
                              onChange={(e) => handleInput(e)}
                              
                            />
                          </div>

                        </div>

                        {/* Payer Address Start */}
                        <div className="row">
                          <div className="col-sm-4">
                            <label
                              htmlFor={props?.type + "PayerCity"}
                              className="form-label m-0 font-12 text--secondary fw-sb"
                            >
                              Payer City<span>&nbsp;&#42;</span>
                            </label>

                            <input
                              type="text"
                              // disabled={true}
                              // value={insuranceInfo?.PolicyNumber}
                              name="payerCity"
                              id={props?.type + "PayerCity"}
                              defaultValue={props?.insuranceJson?.payerCity || ""}
                              className={`form-control font-12 m-0 border--default br-10 shadow-none input`}
                              onChange={(e) => handleInput(e)}
                              required
                            />
                          </div>

                          <div className="col-sm-4">
                            <label
                              htmlFor={props?.type + "PayerState"}
                              className="form-label m-0 font-12 text--secondary fw-sb"
                            >
                              Payer State<span>&nbsp;&#42;</span>
                            </label>

                            <Typeahead
                              id={props?.type + "PayerState"}
                              name="payerState"
                              labelKey="name"
                              onChange={handlePayerState}
                              options={options}
                              className="rbt-menu my-select font-12"
                              selected={
                                props?.insuranceJson?.payerState !== "" &&
                                  props.insuranceJson?.payerState !== undefined &&
                                  props.insuranceJson?.payerState !== null
                                  ? [options.find((s) => s.code === props?.insuranceJson?.payerState)]
                                  : null
                              }
                              style={{ borderRadius: "10px" }}
                              autoComplete="off"
                              onInvalid={(e) => {
                                e.target.setCustomValidity("Please select a state");
                              }}
                              onInput={(e) => {
                                e.target.setCustomValidity("");
                              }}
                              inputProps={{ required: true, className: "font-12" }}
                            />
                          </div>

                          <div className="col-sm-4">
                            <label
                              htmlFor={props?.type + "PayerZip"}
                              className="form-label m-0 font-12 text--secondary fw-sb"
                            >
                              Payer Zip Code<span>&nbsp;&#42;</span>
                            </label>

                            <input
                              type="text"
                              // disabled={true}
                              // value={insuranceInfo?.PolicyNumber}
                              name="payerZip"
                              id={props?.type + "PayerZip"}
                              defaultValue={props?.insuranceJson?.payerZip || ""}
                              className={`form-control font-12 m-0 border--default br-10 shadow-none input`}
                              onChange={(e) => handleInput(e)}
                              required
                            />
                          </div>
                        </div>
                        {/* Payer Address End */}
                      </AccordionDetails>
                    </Accordion>
                  </div>

                  <div className="row mb-5">
                    <Accordion
                      defaultExpanded={true}
                    >
                      <AccordionSummary>
                        <h2 className="m-0 font-14 fw-sb">Insurance Information</h2>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="row  py-2">
                          {/* <div className="col-md-4">
                            <label
                              htmlFor={props?.type + "PolicyNumber"}
                              className="form-label m-0 font-12 text--secondary fw-sb"
                            >
                              {_str.policyNumber}<span>&nbsp;&#42;</span>
                            </label>
                            <input
                              type="text"

                              name="policyNumber"
                              id={props?.type + "PolicyNumber"}
                              defaultValue={props?.insuranceJson?.policyNumber || ""}
                              className={`form-control font-12 m-0 border--default br-10 shadow-none input`}
                              onChange={(e)=>handleInput(e)}
                              required
                            />
                          </div> */}
                          <div className="col-md-4">
                            <label
                              htmlFor={props?.type + "MemberId"}
                              className="form-label m-0 font-12 text--secondary fw-sb"
                            >
                              {_str.memberId}<span>&nbsp;&#42;</span>
                            </label>
                            <input
                              type="text"
                              // value={insuranceInfo?.MemberId}
                              name="memberId"
                              id={props?.type + "MemberId"}
                              defaultValue={props?.insuranceJson?.memberId || ""}
                              className={`form-control font-12 m-0 border--default br-10 shadow-none input`}
                              onChange={(e) => handleInput(e)}
                              required
                            />
                          </div>
                          <div className="col-md-4">
                            <label
                              htmlFor={props?.type + "GroupNumber"}
                              className="form-label m-0 font-12 text--secondary fw-sb"
                            >
                              Group Number<span>&nbsp;&#42;</span>
                            </label>
                            <input
                              type="text"
                              // value={insuranceInfo?.GroupNumber}
                              name="groupNumber"
                              id={props?.type + "GroupNumber"}
                              defaultValue={props?.insuranceJson?.groupNumber || ""}
                              className={`form-control font-12 m-0 border--default br-10 shadow-none input`}
                              onChange={(e) => handleInput(e)}
                              required
                            />
                          </div>

                          <div className="col-sm-4">
                            <label
                              htmlFor={props?.type + "ContactNumber"}
                              className="form-label m-0 font-12 text--secondary fw-sb"
                            >
                              {_str.contactNumber}<span>&nbsp;&#42;</span>
                            </label>
                            <div>
                              <PhoneNumberInput
                                name="contactNumber"
                                fontClass="font-12"
                                value={props?.insuranceJson?.contactNumber}
                                onChange={handlePhoneNumber}
                                id={"contactNumber"}
                                required={true}
                              />
                            </div>
                            <span className="text--danger py-1 font-10 d-block">
                              {contactNumberError && contactNumberError}
                            </span>
                          </div>
                        </div>
                        <div className="row py-2">
                          <div className="col-sm-4">
                            <label
                              htmlFor={props?.type + "effectiveDate"}
                              className="form-label m-0 font-12 text--secondary fw-sb"
                            >
                              {_str.effectiveDate}<span>&nbsp;&#42;</span>
                            </label>
                            <CalenderDatePicker
                              // InputRef={dateRef}
                              name="effectiveDate"
                              f12={true}
                              id={props?.type + "effectiveDate"}
                              // className={`form-control font-12 m-0 border--default br-10 input`}
                              required={true}
                              dateFormat="MM-dd-yyyy"
                              bgDisabled={false}
                              maxDate={DateTime.now()}
                              date={
                                props?.insuranceJson?.effectiveDate !== ""
                                && props?.insuranceJson?.effectiveDate
                              }
                              dobHandler={handleEffectiveDate}
                            // minDate={new Date()}
                            />
                          </div>
                          <div className="col-sm-4">
                            <label
                              htmlFor={props?.type + "terminationDate"}
                              className="form-label m-0 font-12 text--secondary fw-sb"
                            >
                              {_str.teminationDate}<span>&nbsp;&#42;</span>
                            </label>
                            <CalenderDatePicker
                              // InputRef={dateRef}
                              name="terminationDate"
                              id={props?.type + "terminationDate"}
                              // className={`form-control font-12 m-0 border--default br-10 input`}
                              f12={true}
                              required={true}
                              bgDisabled={false}
                              dateFormat="MM-dd-yyyy"
                              date={
                                props?.insuranceJson?.terminationDate !== "" && props?.insuranceJson?.terminationDate !== undefined
                                && props?.insuranceJson?.terminationDate
                              }
                              dobHandler={handleTerminationDate}
                              minDate={props?.insuranceJson?.effectiveDate}
                            />
                          </div>


                          <div className="col-sm-4">
                            <div className="form-group">
                              <label
                                htmlFor={props?.type + "RelationshipToInsured"}
                                className="form-label m-0 font-12 text--secondary fw-sb"
                              >
                                {_str.relationshipToInsured}<span>&nbsp;&#42;</span>
                              </label>
                              <select
                                id={props?.type + "RelationshipToInsured"}
                                className={`input border--default br-10 form-select font-12 shadow-none custom-select`}
                                aria-label="Insruance Type"
                                required
                                name="relationshipToInsured"
                                onChange={(e) => {
                                  handleInput(e);
                                }}
                                onInvalid={(e) => {
                                  e.target.setCustomValidity(
                                    "Please select relation from the list"
                                  );
                                }}
                                onInput={(e) => {
                                  e.target.setCustomValidity("");
                                }}
                              >
                                <option value="" selected disabled hidden>
                                  Select Relationship
                                </option>
                                <option
                                  key="Self"
                                  value="Self"
                                  selected={
                                    props?.insuranceJson?.relationshipToInsured === "Self"
                                  }
                                >
                                  Self
                                </option>
                                <option
                                  key="Child"
                                  value="Child"
                                  selected={
                                    props?.insuranceJson?.relationshipToInsured ===
                                    "Child"
                                  }
                                >
                                  Child
                                </option>
                                <option
                                  key="Spouse"
                                  value="Spouse"
                                  selected={
                                    props?.insuranceJson?.relationshipToInsured ===
                                    "Spouse"
                                  }
                                >
                                  Spouse
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>


                        {/* Insured information */}

                        {props.insuranceJson?.relationshipToInsured !== "" &&
                          props.insuranceJson?.relationshipToInsured?.toLowerCase() !==
                          "self" ? (
                          <div className="row pb-3 mb-3 ">
                            <div className="col-sm-10  pb-2">
                              {/* <Divider /> */}
                              <span className="font-12 text--secondary">
                                {_str.insurance_str}
                              </span>
                            </div>
                            <div className="col-md-3 mt-2">
                              <label
                                htmlFor={props?.type + "insured-firstname"}
                                className="form-label m-0 font-12 text--secondary fw-sb"
                              >
                                {_str.firstName} <span>&#42;</span>
                              </label>
                              <input
                                type="text"
                                // disabled={true}
                                // value={insuranceInfo?.insured-firstname}
                                name="firstName"
                                id={props?.type + "insured-firstname"}
                                defaultValue={props?.insuranceJson?.firstName || ""}
                                className={`form-control font-12 m-0 border--default br-10 shadow-none input`}
                                onChange={handleInsuredName}
                                required
                              />
                              <ValidationErrorMessage
                                fieldName={field_Names.firstNameMax}
                                errors={validationErrors}
                              />
                              {/* {serverErrors.length > 0 && serverErrors?.map((error) => { return error.attributeName === "FirstName" && <span className="text--danger font-10 ms-2 mt-0 lh-0"> {error.message} </span> })} */}
                            </div>

                            <div className="col-md-3 mt-2">
                              <label
                                htmlFor={props?.type + "lastName"}
                                className="form-label m-0 font-12 text--secondary fw-sb"
                              >
                                {_str.middleName}
                              </label>
                              <input
                                type="text"
                                // disabled={true}
                                // value={insuranceInfo?.lastName}
                                name="middleName"
                                id={props?.type + "middleName"}
                                defaultValue={props?.insuranceJson?.middleName || ""}
                                className={`form-control font-12 m-0 border--default br-10 shadow-none input`}
                                onChange={handleInsuredName}

                              />
                              <ValidationErrorMessage
                                fieldName={field_Names.middleNameMax}
                                errors={validationErrors}
                              />
                              {/* {serverErrors.length > 0 && serverErrors?.map((error) => { return error.attributeName === "FirstName" && <span className="text--danger font-10 ms-2 mt-0 lh-0"> {error.message} </span> })} */}
                            </div>

                            <div className="col-md-3 mt-2">
                              <label
                                htmlFor={props?.type + "lastName"}
                                className="form-label m-0 font-12 text--secondary fw-sb"
                              >
                                {_str.lastName}<span>&#42;</span>
                              </label>
                              <input
                                type="text"
                                // disabled={true}
                                // value={insuranceInfo?.lastName}
                                name="lastName"
                                id={props?.type + "lastName"}
                                defaultValue={props?.insuranceJson?.lastName || ""}
                                className={`form-control font-12 m-0 border--default br-10 shadow-none input`}
                                onChange={handleInsuredName}
                                required
                              />
                              <ValidationErrorMessage
                                fieldName={field_Names.lastNameMax}
                                errors={validationErrors}
                              />
                              {/* {serverErrors.length > 0 && serverErrors?.map((error) => { return error.attributeName === "FirstName" && <span className="text--danger font-10 ms-2 mt-0 lh-0"> {error.message} </span> })} */}
                            </div>

                            <div className="col-md-3 mt-2">
                              <label
                                htmlFor={props?.type + "suffix"}
                                className="form-label m-0 font-12 text--secondary fw-sb"
                              >
                                Suffix
                              </label>
                              <input
                                type="text"
                                // disabled={true}
                                // value={insuranceInfo?.lastName}
                                name="suffix"
                                id={props?.type + "suffix"}
                                defaultValue={props?.insuranceJson?.suffix || ""}
                                className={`form-control font-12 m-0 border--default br-10 shadow-none input`}
                                onChange={handleInsuredSuffix}

                              />

                              {/* {serverErrors.length > 0 && serverErrors?.map((error) => { return error.attributeName === "FirstName" && <span className="text--danger font-10 ms-2 mt-0 lh-0"> {error.message} </span> })} */}
                            </div>
                            <div className="col-md-3 mt-2">
                              <label
                                htmlFor="BiologicalSex"
                                className="form-label m-0 font-12 text--secondary fw-sb"
                              >
                                {_str.biologicalSex} <span>&#42;</span>
                              </label>
                              <select
                                onChange={(e) => handleInsuredGender(e)}
                                className="form-select font-12 border--default br-10 shadow-none input"
                                aria-label="biologicalSex"
                                id="biologicalSex"
                                name="biologicalSex"
                                required
                                style={{ borderRadius: "10px" }}
                              >
                                <option value="">Select</option>
                                <option
                                  value="Male"
                                  selected={props.insuranceJson?.gender === "Male"}
                                >
                                  Male
                                </option>
                                <option
                                  value="Female"
                                  selected={props.insuranceJson?.gender === "Female"}
                                >
                                  Female
                                </option>
                                <option
                                  value="Unknown"
                                  selected={props.insuranceJson?.gender === "Unknown"}
                                >
                                  Unknown
                                </option>
                              </select>
                            </div>

                            <div className="col-md-3 mt-2">
                              <label
                                htmlFor="insured-dob"
                                className="form-label m-0 font-12 text--secondary fw-sb"
                              >
                                {_str.dob} <span>&#42;</span>
                              </label>
                              <CalenderDatePicker
                                name="insured-dob"
                                date={
                                  props?.insuranceJson.dateOfBirth !== ""
                                    ? props?.insuranceJson?.dateOfBirth
                                    : ""
                                }
                                required={true}
                                dobHandler={insuredDobHandler}
                                dateFormat="MM-dd-yyyy"
                                f12={true}
                                disableFuture={true}
                                maxDate={DateTime.now()}
                              />
                            </div>

                            <div className="col-md-12 mt-2">
                              <label
                                htmlFor="AddressLine1"
                                className="form-label fw-sb m-0 font-12 text--secondary"
                              >
                                {_str.address}<span>&#42;</span>
                                <span>&nbsp; </span>
                              </label>
                              <input
                                type="text"
                                onChange={handleInsuredAddressLine1}
                                defaultValue={props?.insuranceJson?.addressLine1}
                                autoComplete="off"
                                className="form-control font-12 border--default br-10 shadow-none input"
                                id="AddressLine1"
                                name="AddressLine1"
                                required
                              />
                              <ValidationErrorMessage
                                fieldName={field_Names.addressLine1}
                                errors={validationErrors}
                              />

                              {/* {serverErrors !== null && serverErrors?.map((error) => { return error.attributeName === "AddressLine1" && <span className="text--danger font-10 ms-2 mt-0 lh-0"> {error.message} </span> })} */}
                            </div>

                            <div className="col-md-4 mt-2">
                              <label
                                htmlFor="City"
                                className="form-label fw-sb m-0 font-12 text--secondary"
                              >
                                City <span>&#42;</span>
                              </label>
                              <input
                                type="text"
                                onChange={handleInsuredCity}
                                defaultValue={props?.insuranceJson.city || ""}
                                autoComplete="off"
                                className="form-control font-12 border--default br-10 shadow-none input"
                                id="City"
                                name="City"
                                required
                              />
                              <ValidationErrorMessage
                                fieldName={field_Names.city}
                                errors={validationErrors}
                              />
                            </div>

                            <div className="col-md-4 mt-2">
                              <label
                                htmlFor="State"
                                className="form-label fw-sb m-0 font-12 text--secondary"
                              >
                                State <span>&#42;</span>
                              </label>

                              <Typeahead
                                id="state"
                                name="state"
                                labelKey="name"
                                onChange={handleInsuredState}
                                // onBlur={handleStandardAddress}
                                // onFocus={handleStandardAddress}
                                options={options}
                                className="rbt-menu my-select font-12"
                                selected={
                                  props?.insuranceJson?.state !== "" &&
                                    props.insuranceJson?.state !== undefined &&
                                    props.insuranceJson?.state !== null
                                    ? [options.find((s) => s.code === props?.insuranceJson?.state)]
                                    : null
                                }
                                style={{ borderRadius: "10px" }}
                                autoComplete="off"
                                onInvalid={(e) => {
                                  e.target.setCustomValidity("Please select a state");
                                }}
                                onInput={(e) => {
                                  e.target.setCustomValidity("");
                                }}
                                inputProps={{ required: true, className: "font-12" }}
                              />
                            </div>

                            <div className="col-md-4 mt-2">
                              <label
                                htmlFor="ZipCode"
                                className="form-label fw-sb m-0 font-12 text--secondary"
                              >
                                Zip Code <span>&#42;</span>
                              </label>
                              <input
                                type="text"
                                // value={props?.patientJson?.ZipCode}
                                onChange={(e) => handleInsuredZipCode(e)}
                                defaultValue={props.insuranceJson?.zipCode}
                                className="form-control font-12 border--default br-10 shadow-none input"
                                id="ZipCode"
                                name="ZipCode"
                                required
                              />
                              <span className="text--danger py-1 font-10 d-block">
                                {regexErrors?.zipcode === true
                                  ? "Incorrect Zip Code format"
                                  : " "}
                              </span>
                            </div>
                            <div className="col-md-5 mt-2"></div>
                          </div>
                        ) : null}
                      </AccordionDetails>
                    </Accordion>
                  </div>




                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BottomNavigation handleChange={props?.handleSubmit} type={props?.type} handlePrevious={props?.handlePrevious} updateDisabled={props?.updateDisabled} />
      {/* {console.log("props?.updateDisabled: ", props?.updateDisabled)} */}

      {/* <CustomizedSnackbars
        message={"Changes saved successfully"}
        severity="success"
        open={isPasswordChanged}
        handleClose={handlePasswordChangeToastClose}
      /> */}
      {/* {console.log("insuranceJson", props.insuranceJson)}
      {console.log("validationErrors", validationErrors)} */}
    </>
  );
};

export default InsurancePage;

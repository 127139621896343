const PatientsIcon = (props) => {
  return (
    <svg
      id="Icon-Patients"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 28.003 28"
      {...props}
      fill={props.style.color}
    >
      <path
        id="Icon-Patients-2"
        data-name="Icon-Patients"
        d="M18.667,26.833a8.167,8.167,0,0,0-16.334,0,1.167,1.167,0,1,1-2.333,0,10.5,10.5,0,0,1,21,0,1.167,1.167,0,0,1-2.333,0Zm3.943-9.644c-1.312-1.052-3.943-3.417-3.943-5.435A2.458,2.458,0,0,1,21,9.187a2.458,2.458,0,0,1,2.333,2.341A2.339,2.339,0,1,1,28,11.755c0,2.018-2.632,4.385-3.944,5.435a1.156,1.156,0,0,1-1.447,0ZM3.5,7a7,7,0,1,1,7,7A7,7,0,0,1,3.5,7ZM5.834,7A4.666,4.666,0,1,0,10.5,2.333,4.666,4.666,0,0,0,5.834,7Z"
        stroke=""
      />
    </svg>
  );
};

export default PatientsIcon;

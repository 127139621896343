import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useRef, useState } from "react";
import options from "../../../../utils/data";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import sendHttpRequest from "../../../hooks/sendHttpRequest";

import InputMask from "react-input-mask";
import { useAuth } from "../../../hooks/useAuth";
import { getStandardizedAddress } from "../../../../utils/helpers/DataLoaders";
import { pharmacyList } from "../../../../res/strings";
import { useUnsavedWorkPrompt } from "../../../hooks/useUnsavedWorkPrompt";
import { unsavedWorkHandler } from "../../../../utils/helpers/HelperFunctions";


function PhoneInput(props) {
  return (
    <InputMask
      mask="(999) 999-9999"
      value={props.value}
      required
      onChange={props.onChange}
      maskChar="_"
      className="form-control font-14 border--default br-10 shadow-none input"
      onInvalid={(e) => { e.target.setCustomValidity('Please fill out this field') }}
      onInput={(e) => { e.target.setCustomValidity('') }}
      {...props}
    ></InputMask>
  );
}

const NewPharmacyModal = (props) => {
  const pharmacyNameRef = useRef();
  const { user } = useAuth();
  const [isDirty, onDirty, onPristine] = useUnsavedWorkPrompt()
  const [pharmacyName, setPharmacyName] = useState("");
  const [pharmacyType, setPharmacyType] = useState("");
  const [streetName, setStreetName] = useState("");
  const [phone, setPhone] = useState("");
  const [fax, setFax] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState([]);
  const [zipCode, setZipCode] = useState("");
  const [controlledSubstance, setControlledSubstance] = useState(false);
  const [npi, setNpi] = useState("");
  const [regexErrors, setRegexErrors] = useState({
    PrimaryTelephone: '',
    Fax: ''
  });
  const [serverErrors, setServerErrors] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [standardizedAddress, setStandardizedAddress] = useState('');
  const [isStandardAddress, setIsStandardAddress] = useState(false);

  const handleStandardAddress = async () => {
    if (streetName !== '' && city !== '' && zipCode !== '') {
      if (standardizedAddress == undefined || standardizedAddress == '') {
        setIsStandardAddress(false);
        const stdAddress = await getStandardizedAddress(streetName, null, city, state, zipCode);
        await setStandardizedAddress(stdAddress);
        setShowAddress(true);

      }
      else if (streetName !== standardizedAddress?.addressLine1 ||
        city !== standardizedAddress?.city ||
        state[0]?.code !== standardizedAddress?.stateProvince ||
        zipCode !== standardizedAddress?.postalCode
      ) {
        setIsStandardAddress(false);
        const stdAddress = await getStandardizedAddress(streetName, null, city, state, zipCode);
        await setStandardizedAddress(stdAddress);
        setShowAddress(true);
      }

    }
  };

  const handlePreset = (s) => {
    setState(s);
    onDirty();
  }

  const updateAddress = (e) => {
    setShowAddress(false);
    e.preventDefault();
    setIsStandardAddress(true);
    setStreetName(standardizedAddress?.addressLine1)
    setCity(standardizedAddress.city);
    handlePreset([options.find(s => s?.code?.toLowerCase() === standardizedAddress?.stateProvince?.toLowerCase())]);
    setZipCode(standardizedAddress?.postalCode);
    onDirty();
  }

  const zipCodeHandler = (event) => {
    setZipCode(event.target.value);
    onDirty();
  };

  const cityHandler = (event) => {
    setCity(event.target.value);
    onDirty();
  };

  const pharmacyNameHandler = (event) => {
    setPharmacyName(event.target.value);
    onDirty();
  };

  const pharmacyTypeHandler = (event) => {
    setPharmacyType(event.target.value);
    onDirty();
  };

  const streetNameHandler = (event) => {
    setStreetName(event.target.value);
    onDirty();
  };

  const phoneHandler = (event) => {
    setPhone(event.target.value);
    onDirty();
  };

  const faxHandler = (event) => {
    setFax(event.target.value);
    onDirty();
  };

  const npiHandler = (event) => {
    setNpi(event.target.value);
    onDirty();
  }

  useEffect(() => {
    pharmacyNameRef.current.focus();
  }, []);

  const clearState = () => {
    setCity("");
    setPharmacyName("");
    setPharmacyType("");
    setNpi("");
    setStreetName("");
    setZipCode("");
    setState("");
    setPhone("");
    setFax("");
    setControlledSubstance(false);
  }

  const addPharmacyRequestHandler = async (pharmacyObject) => {
    setIsSending(true);
    const httpResponse = await sendHttpRequest({
      url: `/pn-pharmacy/tenant/${user?.tenantId}`,
      method: "POST",
      data: pharmacyObject,
    });
    if (!httpResponse.error) {
      props.setIsSent(true);
      props.fetchAllPharmacies();
      props.handleNewPharmacyModalClose();
      clearState();
      setIsSending(false);
      onPristine();

    } else {
      setServerErrors(httpResponse.errorMessage);
      setIsSending(false);
    }
  };

  const addPharmacyHandler = () => {
    let newObj = {};
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (pharmacyName !== undefined && pharmacyName !== "") {
      newObj.businessName = pharmacyName;
    }
    if (pharmacyType !== undefined && pharmacyType !== "") {
      newObj.pharmacyType = pharmacyType;
    }
    if (npi !== undefined && npi !== "") {
      newObj.npi = npi;
    }
    if (streetName !== undefined && streetName !== "") {
      newObj.addressLine1 = streetName;
    }
    if (controlledSubstance !== undefined) {
      newObj.isControlledSubstance = controlledSubstance;
    }

    if (city !== undefined && city !== "") {
      newObj.city = city;
    }

    if (state !== undefined && state !== "" && state.length > 0 && state[0] !== undefined && state[0] !== null) {
      newObj.stateProvince = state[0].code;
    }

    if (zipCode !== undefined && zipCode !== "") {
      newObj.postalCode = zipCode;
    }

    if (phone !== undefined && phone !== "") {
      if (phoneRegex.test(phone)) {
        setRegexErrors((prevState) => { return { ...prevState, PrimaryTelephone: '' } });
        newObj.primaryTelephone = phone;
        setIsValid(true);
      }
      else {
        setRegexErrors((prevState) => { return { ...prevState, PrimaryTelephone: 'Invalid phone number' } });
        setIsValid(false);
      }
    }

    if (fax !== undefined && fax !== "") {

      if (phoneRegex.test(fax)) {
        setRegexErrors((prevState) => { return { ...prevState, Fax: '' } });
        newObj.faxNumber = fax;
        setIsValid(true);
      }
      else {
        setRegexErrors((prevState) => { return { ...prevState, Fax: 'Invalid fax number' } });
        setIsValid(false);
      }

    }

    if (regexErrors.Fax === '' && regexErrors.PrimaryTelephone === '') {
      addPharmacyRequestHandler(newObj);
    }
  };
  return (
    <Modal
      show={props.show}
      onHide={() => {

        setRegexErrors(null);
        unsavedWorkHandler("modal", "There are unsaved changes, do you wish to continue?", isDirty, props.handleNewPharmacyModalClose, onPristine, props.handleNewPharmacyModalClose)
      }}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      backdropClassName="modal-backdrop"
      contentClassName="modal-border modal-shadow"
    >
      <Modal.Body>
        <div className="container-fluid px-3 ">
          <div className="row ">
            <div className="col-12 ">
              <span
                style={{
                  lineHeight: "12px",
                  width: "18px",
                  fontSize: "8pt",
                  color: "#336383",
                  position: "absolute",
                  top: 15,
                  right: 15,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setRegexErrors(null);
                  unsavedWorkHandler("modal", "There are unsaved changes, do you wish to continue?", isDirty, props.handleNewPharmacyModalClose, onPristine, props.handleNewPharmacyModalClose)

                }}
              >
                {<CloseIcon />}
              </span>
            </div>
          </div>
          <div className="row ">
            <div className="col-12">
              <div className="d-flex ">
                {/* <div>
                  <img src={AddButtonIcon} alt=""></img>
                </div> */}
                <div className="mt-2">
                  <p className="h18 fw-sb " style={{ fontWeight: "600" }}>
                    Add Pharmacy
                  </p>
                </div>
              </div>
            </div>
          </div>
          <form
            id="pharmacyForm"
            onSubmit={(e) => {
              e.preventDefault();
              addPharmacyHandler();

            }}
          >
            <div className="row mt-3  ">
              <div className="col-md-6">
                <label
                  htmlFor="PharmacyName"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Pharmacy Name <span>&#42;</span>
                </label>
                <input
                  type="text"
                  onChange={pharmacyNameHandler}
                  ref={pharmacyNameRef}
                  className={`form-control font-14 m-0 border--default br-10 shadow-none input`}
                  id="pharmacyName"
                  name="businessName"
                  required
                />
              </div>

              <div className="col-md-6">
                <label
                  htmlFor="PharmacyType"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Pharmacy Type <span>&#42;</span>
                </label>
                <select
                  onChange={pharmacyTypeHandler}
                  className="form-select font-14 border--default br-10 shadow-none input"
                  aria-label="pharmacyType"
                  id="pharmacyType"
                  required
                  style={{ borderRadius: "10px" }}
                >
                  <option selected value=""></option>
                  {
                    pharmacyList?.map((pharmacy, index) => {
                      return <option key={index} value={pharmacy}>{pharmacy}</option>
                    })
                  }
                </select>
              </div>

              <div className="col-md-12 mt-3">
                <label
                  htmlFor="StreetName"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Street Name <span>&#42;</span>
                </label>
                <input
                  type="text"
                  value={streetName}
                  onChange={streetNameHandler}
                  onBlur={() => { handleStandardAddress() }}
                  autoComplete={false}
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="streetName"
                  required
                />
              </div>

              <div className="col-md-4 mt-3">
                <label
                  htmlFor="City"
                  className="form-label fw-sb m-0 font-12 text--secondary"
                >
                  City <span>&#42;</span>
                </label>
                <input
                  type="text"
                  value={city}
                  onChange={cityHandler}
                  onBlur={() => { handleStandardAddress() }}
                  autoComplete={false}
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="City"
                  required
                />
              </div>

              <div className="col-md-4 mt-3">
                <label
                  htmlFor="State"
                  className="form-label fw-sb m-0 font-12 text--secondary"
                >
                  State
                </label>
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="name"
                  value={state}
                  onChange={setState}
                  onBlur={() => { handleStandardAddress() }}
                  options={options}
                  className="rbt-menu my-select"
                  style={{ borderRadius: "10px" }}
                  autoComplete={false}
                  inputProps={{ className: "font-14" }}

                />
              </div>

              <div className="col-md-4 mt-3">
                <label
                  htmlFor="ZipCode"
                  className="form-label fw-sb m-0 font-12 text--secondary"
                >
                  Zip Code <span>&#42;</span>
                </label>
                <input
                  type="text"
                  onChange={zipCodeHandler}
                  value={zipCode}
                  onBlur={() => { handleStandardAddress() }}
                  autoComplete={false}
                  minLength="5"
                  maxLength="9"
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="ZipCode"
                  required
                />
              </div>

              {showAddress &&
                (<div className="col-md-12  mt-2">
                  {standardizedAddress !== '' && standardizedAddress !== undefined ? <span className="d-block text-decoration-underline br-10 text--danger font-14 fw-sb">USPS Standard Address</span> : null}
                  {standardizedAddress !== '' && standardizedAddress !== undefined ? <div className=" row ">

                    <div className="col-sm-6">
                      <span className="d-block font-12 text--terciary ">
                        {standardizedAddress?.addressLine1}
                      </span> <span className="d-block font-12 text--terciary ">
                        {standardizedAddress?.city}, {standardizedAddress?.stateProvince}, {standardizedAddress?.postalCode}
                      </span>
                    </div>
                    <div className="col-sm-6 align-self-center">
                      <button className="btn btn-outline-primary btn-outline-blue shadow-none font-12" onClick={(event) => updateAddress(event)}>Use Standard Address</button>
                    </div>
                  </div> : null}
                </div>
                )}


              <div className="row mt-3">
                <div className="col-md-6 ">
                  <label
                    htmlFor="Phone"
                    className="form-label m-0 fw-sb font-12 text--secondary"
                  >
                    Phone <span>&#42;</span>
                  </label>
                  <PhoneInput
                    onChange={phoneHandler}
                    id="Phone"
                  ></PhoneInput>

                  <span className="text--danger font-10 ms-2 mt-0">
                    {regexErrors !== null && regexErrors.PrimaryTelephone !== undefined && regexErrors.PrimaryTelephone !== '' ? regexErrors.PrimaryTelephone : ''}

                  </span>
                </div>
                <div className="col-md-6 ">
                  <label
                    htmlFor="Fax"
                    className="form-label m-0 fw-sb font-12 text--secondary"
                  >
                    Fax <span>&#42;</span>
                  </label>
                  <PhoneInput onChange={faxHandler} id="fax" />

                  <span className="text--danger font-10 ms-2 mt-0">
                    {regexErrors !== null && regexErrors.Fax !== undefined && regexErrors.Fax !== '' ? regexErrors.Fax : ''}

                  </span>
                </div>
              </div>

              <div className="col-md-6">
                <label
                  htmlFor="NPI"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  NPI <span>&#42;</span>
                </label>
                <input
                  type="text"
                  onChange={npiHandler}

                  className={`form-control font-14 m-0 border--default br-10 shadow-none input`}
                  id="NPI"
                  name="NPI"
                  minLength="10"
                  maxLength="10"
                  required
                />
              </div>


              <div className="col-md-6">
                <label
                  htmlFor="ControlledSubstance"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >

                </label>
                <div className="form-check mt-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={(e) => {
                      e.target.checked
                        ? setControlledSubstance(true)
                        : setControlledSubstance(false);
                    }}
                    value="ControlledSubstance"
                    id="controlledSubstance"
                  />
                  <label
                    className="form-check-label font-12 fw-sb text--secondary "
                    htmlFor="controlledSubstance"
                  >
                    Controlled Substance
                  </label>
                </div>
              </div>



              {/* <div className="col-md-12 " style={{ marginBottom: "-10px" }}>
                <h3 className="mt-2 font-14 fw-sb">Contact Information</h3>
              </div> */}


            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ border: "none" }}>
        <button
          type="submit"
          className={`btn bg--blue fw-sb font-14 text-white b2 px-4 br-10 py-1 shadow-none ${isSending ? 'disabled' : ''}`}
          form="pharmacyForm"
        >
          {isSending ? "Saving..." : "Save"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewPharmacyModal;

import { makeStyles } from "@mui/styles";
import React from "react";
import Drawer from "../UI/Drawer/Drawer";
import { Outlet, useLocation } from "react-router-dom";
import Navbar from "../UI/Navbar";
import PatientDrawer from "../UI/Drawer/PatientDrawer";
import PatientNavbar from "../UI/PatientNavbar";

const useStyle = makeStyles({
  page: {
    width: "100%",
    overflowY: "scroll",
    scrollbarColor: "#FAFCFC",
    overflowX: "hidden",
  },
  root: {
    display: "flex",
    minHeight: "100vh",
    background: "#FAFCFC",
  },
});

const UrlMap = function (url) {
  const urls = new Map();
  urls.set("/patient-portal/payment", true);
  // urls.set("/patient-portal/notifications", true);
  urls.set("/new/appointment", true);
  urls.set("/follow-up/appointment", true);
  urls.set("/new/payment", true);
  urls.set('/my-account', true);

  return urls.get(url) || false;
};

export default function PatientLayout({ children }) {
  const classes = useStyle();
  const location = useLocation();
  return (
    <>
      <div className="row sticky-top ">
        <div className={`col-12 z-1020`} style={{ zIndex: "1020 !important" }}>
          <PatientNavbar />
        </div>
      </div>

      <div className={`${classes.root}`}>
        {!UrlMap(location.pathname) && <PatientDrawer />}
        <div className={`${classes.page} page`}>{children}</div>
      </div>
    </>
  );
}

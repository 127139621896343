import { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input/input";
import "react-phone-number-input/style.css";

const PhoneInputNew = (props) => {
    const [value, setValue] = useState(props.value || "");

    useEffect(() => {
        setValue(props.value || "");
    }, [props.value]);

    const handleChange = (newValue) => {
        setValue(newValue);
        props.onChange(newValue);
    };

    return (
        <PhoneInput
            defaultCountry="US"
            className={`border--default ${props.fontClass ? props.fontClass : "font-14"} br-10 form-control shadow-none outline-none input ${props.disabled === true ? 'bg--disabled' : ''}`}
            value={value}
            name={props.name}
            id={props.id}
            onChange={handleChange}
            required={props.required === true}
            onInvalid={(e) => {
                e.target.setCustomValidity("Please fill out this field");
            }}
            onInput={(e) => {
                e.target.setCustomValidity("");
            }}
            disabled={props.disabled === true}
        />
    );
};

export default PhoneInputNew;
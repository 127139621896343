import { Modal } from "react-bootstrap";
import TextArea from "../../../forms/TextArea";
import CloseIcon from "@mui/icons-material/Close";
import classes from "./AllergyModal.module.css";
import { useEffect, useState } from "react";
import { allergiesStrings } from "../../../../res/strings";

const AllergyModal = (props) => {
  const _str = allergiesStrings.en;
  const [severityActive, setSeverityActive] = useState("");
  const handleSubstanceInput = (e) => {
    props.setSubstance(e.target.value);
  };
  const handleSeverityLow = () => {
    props.setSeverity("Mild");
    setSeverityActive("Mild");
  };
  const handleSeverityModerate = () => {
    props.setSeverity("Moderate");
    setSeverityActive("Moderate");
  };
  const handleSeveritySevere = () => {
    props.setSeverity("Severe");
    setSeverityActive("Severe");
  };

  const setAllergyReactions = (event) => {
    props.setReactions(event);
  };


  useEffect(() => {
    return () => {
      props.setSeverity(null);
      setSeverityActive(null);
      props.setReactions(null);
      props.setSubstance(null);
    }
  }, [])

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.handleModal();
      }}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      backdropClassName="modal-backdrop"
      contentClassName="modal-border modal-shadow"
      backdrop="static"

    // style={{style}}
    >
      {/* <Modal.Header closeButton style={{ border: "none" }}></Modal.Header> */}
      <Modal.Body>
        <div className="container-fluid px-3 ">
          <div className="row ">
            <div className="col-12 ">
              <span
                style={{
                  lineHeight: "12px",
                  width: "18px",
                  fontSize: "8pt",
                  color: "#336383",
                  position: "absolute",
                  top: 15,
                  right: 15,
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.handleModal();
                  setSeverityActive("");
                }}
              >
                {<CloseIcon />}
              </span>
            </div>
          </div>
          <div className="row ">
            <div className="col-12">
              <div className="d-flex ">
                {/* <div>
                  <img src={AddButtonIcon} alt=""></img>
                </div> */}
                <div className="mt-2">
                  <p className="h18 fw-sb " style={{ fontWeight: "600" }}>
                    {_str.addAllergy}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <form
            id="allergyAddForm"
            onSubmit={(e) => {
              e?.preventDefault();
              props?.addAllergyRequestHandler();
              setSeverityActive("");
              props?.handleModal();
            }}
          >
            <div className="row mt-4">
              <div className="col-sm-6">
                <label className="b2 text--secondary ">{_str.substance}</label>
                <input
                  type="text"
                  pattern=".*\S+.*"
                  required
                  className="form-control br-10 font-14  shadow-none"
                  onChange={(e) => handleSubstanceInput(e)}
                  value={props.edit && props.editAllergy.DrugProductCoded.Text}
                  style={{
                    border: "1px solid #D9E8EC",
                  }}
                >
                  {/* {console.log(props.editAllergy.DrugProductCoded.Text)} */}
                </input>
              </div>
              <div className="col-sm-6 ">
                <label className="b2 text--secondary">{_str.category}</label>
                <select
                  className={`form-select font-14 br-10 shadow-none ${classes["custom-select"]}`}
                  aria-label="Default select example"
                  value={props.edit && props.editAllergy.category}
                  style={{
                    border: "1px solid #D9E8EC",
                  }}
                  required
                  onChange={props.handleAllergyCategory}
                  onInvalid={(e) => {
                    e.target.setCustomValidity(
                      "Please select category from the list"
                    );
                  }}
                  onInput={(e) => {
                    e.target.setCustomValidity("");
                  }}
                >
                  <option value="">
                    {_str.selectCategory}
                  </option>
                  <option value="Drug">{_str.drug}</option>
                  <option value="Food">{_str.food}</option>
                  <option value="Common">{_str.common}</option>
                </select>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-sm-8">
                <label className="b2 text--secondary">{_str.severity}</label>
                <div className="d-flex">
                  <button
                    type="button"
                    style={{ color: "var(--terciary)" }}
                    className={
                      severityActive === "Mild"
                        ? "btn var(--primary) btn--border font-14 fw-sb shadow-none border--primary"
                        : "btn var(--primary) btn--border font-14 fw-sb br-10 shadow-none"
                    }
                    id="Mild"
                    key="Mild"
                    onClick={() => handleSeverityLow()}
                  // style={{ width: "40px", height: "40px" }}
                  >
                    {_str.mild}
                  </button>
                  <button
                    type="button"
                    style={{ color: "var(--terciary)" }}
                    className={
                      severityActive === "Moderate"
                        ? "btn var(--primary) btn--border font-14 fw-sb  shadow-none border--primary ms-2"
                        : "btn var(--primary) btn--border font-14 fw-sb br-10  shadow-none ms-2"
                    }
                    id="Moderate"
                    key="Moderate"
                    onClick={() => handleSeverityModerate()}
                  // style={{ width: "40px", height: "40px" }}
                  >
                    {_str.moderate}
                  </button>
                  <button
                    type="button"
                    style={{ color: "var(--terciary)" }}
                    className={
                      severityActive === "Severe"
                        ? "btn var(--primary) btn--border font-14 fw-sb  shadow-none border--primary ms-2"
                        : "btn var(--primary) btn--border font-14 fw-sb br-10  shadow-none ms-2"
                    }
                    // style={{ width: "40px", height: "40px" }}
                    id="Severe"
                    key={"Severe"}
                    onClick={() => handleSeveritySevere()}
                  >
                    {_str.severe}
                  </button>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-sm-12 ">
                <label className="b2 text--secondary">{_str.reactions}</label>
                <TextArea
                  pattern=".*\S+.*"
                  className="form-control font-14 br-10 shadow-none "
                  style={{ border: "1px solid #D9E8EC" }}
                  gettext={setAllergyReactions}
                />
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>

      <Modal.Footer style={{ border: "none" }}>
        <button
          className="btn bg--blue text-white b2 px-4 br-10 py-1 shadow-none"
          type="submit"
          form="allergyAddForm"
        >
          {_str.save}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AllergyModal;

import InboxMessageBar from "./InboxMessageBar";
import InBoxMessageItem from "./InboxMessageItem";
import IconArchive from "../../assets/icons/Icon-archive.svg";
import { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import TrashIcon from "../../assets/icons/Trash.svg";
import MessageContainer from "./MessageContainer";
import classes from "./InboxMessages.module.css";
import { useLocation } from "react-router-dom";

const InboxMessages = (props) => {
  const MINUTE_MS = 15000;
  const location = useLocation();
  const [checkAllMessages, setCheckAllMessages] = useState(false);
  const [openSingleMessage, setOpenSingleMessage] = useState(false);
  const [threadId, setThreadId] = useState(
    location?.state?.from
      ? undefined
      : location?.state !== null
        ? location?.state
        : undefined
  );

  const handleOpenSingleMessage = () => {
    setOpenSingleMessage(!openSingleMessage);

  };
  const handleThreadId = (id) => {

    setThreadId(id);
  };

  useEffect(() => {
    props?.fetchInboxMessages();
    const interval = setInterval(() => {
      console.log("props?.isStartPooling: ",props?.isStartPooling);
      if(props?.isStartPooling === true){
        props?.fetchInboxMessages();
      }
    }, MINUTE_MS);
    
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    
  }, [props.filterValue,props?.isStartPooling]);
  return (
    <>
      {props?.inbox ? (
        <InboxMessageBar
          messageCount={props?.inboxMessageCount}
          openSingleMessage={threadId}
          barText="Inbox"
          setThreadId={setThreadId}
          filterValue={props.filterValue}
          setFilterValue={props.setFilterValue}
          setMessagesData = {props?.setInboxMessagesData}
          type = {"inbox"}
          setIsStartPooling = {props?.setIsStartPooling}
          setIsMsgLoading = {props?.setIsMsgLoading}
        />
      ) : (
        <InboxMessageBar
          messageCount={props?.sentMessageCount}
          openSingleMessage={threadId}
          barText="Sent"
          setThreadId={setThreadId}
          setMessagesData = {props?.setSentMessagesData}
          type = {"sent"}
          setIsStartPooling = {props?.setIsStartPooling}
          setIsMsgLoading = {props?.setIsMsgLoading}
        />
      )}

      <div className="px-2  ">
        <div className="row " scroll="no">
          <div
            className={
              threadId !== undefined
                ? `col-5 ${classes["message__scroll"]}`
                : `col-12 ${classes["message__scroll"]}`
            }
          >
            <div className="row ">
              {props?.inboxMessagesData.length > 0 && (
                <div className={"col-12 py-2  d-flex flex-row border--bottom"}>
                  <div>
                    <input
                      className={`form-check-input input ms-0 shadow-none ${classes["checkbox__all"]}`}
                      type="checkbox"
                      // onChange=
                      name="checkboxAll"
                      id="checkboxAll"
                      disabled={true}
                    // onClick={() => {
                    //   handleCheckAllMessages();
                    // }}
                    />
                  </div>
                  <div className="d-flex ms-3">
                    <div className={`ms-2 ${classes["icon__archive"]}`}>
                      <img src={IconArchive} alt={"file archive"}></img>
                    </div>
                    <div className={`ms-4 ${classes["icon__trash"]}`}>
                      <img src={TrashIcon} alt={"file icon"} style={{}}></img>
                    </div>
                    {/* <div><img src={IconArchive} alt="archive message" style={{color:"#d9e8ec"}}></img></div> */}
                  </div>
                </div>
              )}
            </div>

            <div className="row ">
              {/* <div className="col-12"> */}
              <div className={"col-12 "}>
                {
                  // props?.isInboxMessagesLoading ? (
                  //   // <Skeleton />
                  // ) : (
                  props?.inboxMessagesData.length > 0 &&
                  props?.inboxMessagesData.map((messageItem, index) => {
                    // console.log(messageItem);
                    return (
                      <>
                        <InBoxMessageItem
                          checkAllMessages={checkAllMessages}
                          messageItem={messageItem}
                          userId={
                            props?.inbox ? messageItem?.from : messageItem?.to
                          }
                          openSingleMessage={openSingleMessage}
                          handleOpenSingleMessage={handleOpenSingleMessage}
                          setThreadId={setThreadId}
                          fetchUnreadMessagesCount={props.fetchUnreadMessagesCount}
                          threadId={threadId}
                          inbox={props?.inbox}
                          key={index}
                        />
                      </>
                    );
                  })
                  // )
                }
              </div>
            </div>
          </div>
          <div className={threadId !== undefined && "col-7"}>
            {threadId !== undefined && (
              <>
                {/* <div>{"Header->"} {threadId}</div> */}
                <MessageContainer
                  threadId={threadId}
                  setThreadId={setThreadId}
                  inbox={props?.inbox}
                  patientPortal={props?.patientPortal}


                />
              </>
            )}
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default InboxMessages;

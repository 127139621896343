import { useAuth } from "../../components/hooks/useAuth";
import LabOrdersTable from "../../components/patient/LabOrdersTable";
import PageHeader from "../generic/PageHeader";

const PatientLabTests = ()=>{
    const {user} = useAuth();
    return (
        <>
            <PageHeader title={"Labs"}/>
            <div className="row justify-content-center mt-3">
                <div className="col-11">
                    <LabOrdersTable patientId={user.userId} patientPortal={true}/>
                </div>
            </div>
        </>
    )
}

export default PatientLabTests;
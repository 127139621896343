import placeholder from "./../../assets/images/placeholder.png";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MessagesIcon from "../UI/Drawer/DrawerItemIcons/MessagesIcon";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { useNavigate, useParams } from "react-router-dom";
import {
  calenderDateTimeHelper,
  getAgeByDateOfBirth,
} from "../../utils/dateTimeHelper";
import { formatPhoneNumber } from "../../utils/helpers/HelperFunctions";
import { setIsPatientEdited } from "../../redux/actions/providerActions";
import TaskIcon from "../UI/Drawer/DrawerItemIcons/TaskIcon";
import PendingTasksModal from "../UI/Modals/ProviderModals/PendingTasksModal";
import { Backdrop, CircularProgress, Skeleton } from "@mui/material";
import PatientProfileMessagesModal from "../UI/Modals/PatientModals/PatientProfileMessagesModal";
import { numbersEnum } from "../../res/strings";
import { fecthPatientInformationURL, fetchPatientGeneralInfoURL, fetchSocialHistoryUrl } from "../../res/endpointurls";
import { ChevronLeft } from "react-bootstrap-icons";

const PatientInformation = (props) => {

  const patientEncounterInformation = useSelector(
    (state) => state.PatientEncounterData.appoinment.Encounter
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSocialHistoryEdited = useSelector((state) => state.isPatientEdited);
  const { providerId, encounterId, patientId } = useParams();
  // console.log("providerId: ",providerId);
  const [isLoading, setIsLoading] = useState(true);
  const [patientInformation, setPatientInformation] = useState();

  const profilePicture = patientEncounterInformation?.profilePicture;
  const firstName = patientInformation?.name[0]?.firstName;
  const lastName = patientInformation?.name[0]?.lastName;
  const gender = patientInformation?.gender;
  const dateOfBirth = patientInformation?.dateOfBirth;
  const referringPhysician = props?.referringPhysician;

  // const emergencyTelephone = patientInformation?.primaryTelephone
  const emergencyTelephone = patientInformation?.communicationNumbers?.emergencyContact?.find((numberItem) => {
    return numberItem?.phoneNumber !== "";
  })?.phoneNumber || null;
  const [openPendingTasksModal, setOpenPendingTasksModal] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  // const [tasksCount, setTasksCount] = useState(null);
  const [openMessagesModal, setOpenMessagesModal] = useState(false);
  const [isSmoker, setIsSmoker] = useState(2);

  let years = 0;
  if (dateOfBirth !== undefined) {
    if (Object.keys(dateOfBirth).length !== 0) {
      years = getAgeByDateOfBirth(dateOfBirth.Date);
    }
  }

  const unreadMessageCount = useSelector(
    (state) => state.ProviderUnreadMessageCount.count
  );

  const handlePendingTasksModal = () => {
    setOpenPendingTasksModal(!openPendingTasksModal);
  };

  const handleBackdropClose = () => {
    setOpenBackdrop(false);
  };

  const handleToggle = () => {
    setOpenBackdrop(!openBackdrop);
  };

  const handleMessagesModal = () => {
    setOpenMessagesModal((previous) => {
      return !previous;
    });
  };

  // const fetchTasksCount = async (patientId) => {
  //   const httpResponse = await sendHttpRequest({
  //     url: "/tasks",
  //     method: "GET",
  //     params: {
  //       PatientId: patientId,
  //     },
  //   });
  //   if (!httpResponse.error) {
  //     // console.log("Pending tasks: ", httpResponse.data,httpResponse.data.length);
  //     setTasksCount(httpResponse.data?.length);
  //     // console.log(httpResponse.data)
  //   }
  // };

  const fecthPatientInformation = async (patientId) => {
    props.setAppointmentStatusLoading(true);
    props?.setIsPatientNotFound(false);

    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: fecthPatientInformationURL(),
      method: "GET",
      params: {
        patientId: patientId
      }
    });
    if (!httpResponse.error) {
      setPatientInformation(httpResponse.data);
      // console.log("Patient Profile in Encounter ====> ",httpResponse.data);
      dispatch(setIsPatientEdited(false));
    }
    else {
      if (httpResponse.status === 404) {
        props?.setIsPatientNotFound(true);
      }
    }
    setIsLoading(false);
    props.setAppointmentStatusLoading(false);
  };

  const fecthSocialHistory = async (patient_id) => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: fetchSocialHistoryUrl(patient_id),
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
      setSmokerStatus(httpResponse.data);
    }
    setIsLoading(false);
  };

  const setSmokerStatus = (socialHistory) => {
    if (socialHistory != undefined && socialHistory != null && socialHistory != "" && socialHistory?.length > 0) {
      socialHistory.forEach((sh) => {
        if (sh.question.questionId === 1) {
          if (sh.answer != null && sh.answer.length > 0) {
            if (sh.answer[0] > 2) {
              setIsSmoker(1);
            } else {
              setIsSmoker(0);
            }
          }
        }
      })
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      await fecthPatientInformation(patientId);

      // Guard statement, will call fetchSocialHistory if patient is valid
      if (props?.isPatientNotFound === false) {
        await fecthSocialHistory(patientId);
      }
    };

    fetchData();
  }, [isSocialHistoryEdited.isPatientEdited]);

  // console.log("Patient Information",patientInformation)
  return (
    <>
      {openBackdrop && (
        <Backdrop sx={{ color: "#EC609B", zIndex: 900000 }} open={openBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {openPendingTasksModal && (
        <PendingTasksModal
          show={openPendingTasksModal}
          patientSpecificTasks={true}
          inModal={true}
          PatientId={patientInformation?.id}
          handleModal={handlePendingTasksModal}
          handleBackdropClose={handleBackdropClose}
          handleToggle={handleToggle}
        />
      )}

      {openMessagesModal && (
        <PatientProfileMessagesModal
          show={openMessagesModal}
          patientId={patientInformation?.id}
          handleMessagesModal={handleMessagesModal}
        />
      )}
      <div className={`d-flex px-4 py-2  `}>
        {isLoading === true ? (
          <Skeleton variant="text" width={500} />
        ) : (
          <div className="d-flex justify-content-between w-100">
            <div className="align-self-center ">
              <div className="d-flex">
                <div className="align-self-center">
                  <div
                    style={{ width: "40px", height: "40px" }}
                    className={`bg--background  br-10 me-3 border--default border align-self-center text-center my-auto `}
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <ChevronLeftIcon
                      style={{ color: "#EC609B" }}
                      className="cursor-pointer my-auto mt-2"
                    />
                  </div>
                </div>
                <div>
                  {isLoading === true ? (
                    <Skeleton variant="circular" width={40} height={40} />
                  ) : (
                    <img
                      src={
                        profilePicture !== undefined && profilePicture !== ""
                          ? profilePicture
                          : placeholder
                      }
                      width="50"
                      height="50"
                      alt={props?.PatientName}
                      className="rounded-circle"
                      draggable={false}
                    />
                  )}
                </div>
                <div className="ms-2 align-self-center">
                  <h3 className="mb-0 ">
                    {firstName !== undefined && firstName !== ""
                      ? firstName + " " + lastName
                      : ""}
                    &nbsp;{" "}
                    <small className="text--secondary text--small">
                      {/* Male - 40 years */}
                      {gender !== undefined && gender !== "" && gender} -{" "}
                      {dateOfBirth !== undefined &&
                        getAgeByDateOfBirth(dateOfBirth) + " years"}
                    </small>
                  </h3>
                </div>
                <div className=" ms-2 border--left align-self-center  ">
                  <h3 className="ms-2 mb-0">
                    <small className=" text--secondary text--small">
                      {"DOB: " +
                        calenderDateTimeHelper(dateOfBirth, "MMM DD, YYYY")}
                    </small>
                  </h3>
                </div>
                <div className=" ms-2 border--left align-self-center  ">
                  <h3 className="ms-2 mb-0">
                    <small className=" text--secondary text--small">
                      {isSmoker === 1 ? "Smoker" : isSmoker === 0 ? "Non smoker" : ""}
                    </small>
                  </h3>
                </div>
                {referringPhysician !== undefined && referringPhysician !== null && Object.keys(referringPhysician)?.length > 0 ? <div className=" ms-2 border--left align-self-center  ">
                  <h3 className="ms-2 mb-0">
                    <small className=" text--secondary text--small">
                      <span className="fw-sb">Referring Physician: </span> {referringPhysician?.firstName} {referringPhysician?.lastName} - {referringPhysician?.phone}
                    </small>
                  </h3>
                </div> : null}
              </div>
            </div>

            <div className="d-flex gap-3 align-items-center">
              {props?.join !== true ? (
                emergencyTelephone !== null &&
                  emergencyTelephone !== undefined &&
                  emergencyTelephone !== "" ? (
                  <div className="align-self-center">
                    <span
                      className="font-12 d-block text--secondary"
                      style={{ marginBottom: "-4px" }}
                    >
                      Emergency Contact
                    </span>
                    <span className="font-14 d-block fw-sb m-0">
                      {emergencyTelephone !== undefined &&
                        emergencyTelephone !== "" &&
                        formatPhoneNumber(emergencyTelephone) !== null
                        ? formatPhoneNumber(emergencyTelephone)
                        : ""}
                    </span>
                  </div>
                ) : null
              ) : null}

              {props?.join !== true ? (
                <>
                  <div className="align-self-center">
                    <button
                      type="button"
                      className="btn pn-border disabled outline-0 shadow-none  position-relative"
                      onClick={() => handleMessagesModal()}
                    >
                      <MessagesIcon
                        // style={{ color: "#dddddd", fill: "#dddddd" }}
                        style={{ color: "#336383", fill: "#336383" }}
                        width="18"
                        height="18"
                      />
                      {unreadMessageCount > -1 && (
                        <span className="position-absolute visually-hidden top-0 start-100 translate-middle badge rounded-pill bg__secondary">
                          {unreadMessageCount}{" "}
                          <span className="visually-hidden">
                            unread messages
                          </span>
                        </span>
                      )}
                    </button>
                  </div>

                  <div className="align-self-center">
                    <button
                      type="button"
                      className="btn disabled pn-border outline-0 shadow-none  position-relative"
                      onClick={handlePendingTasksModal}
                    >
                      <TaskIcon
                        style={{
                          color: "#336383",
                          fill: "#336383",
                          width: "18px",
                          height: "18px",
                        }}
                      />
                      {/* {tasksCount !== null && tasksCount > 0 && (
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg__secondary">
                          {tasksCount}{" "}
                          <span className="visually-hidden">
                            unread messages
                          </span>
                        </span>
                      )} */}
                    </button>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        )}

        {/* <div className="d-flex gap-3 align-items-center">
          <div className="align-self-center">
            <p className="b1 text--secondary mb-0">Emergency Contact</p>
            <p className="b1 fw-sb">
              {emergencyTelephone !== undefined &&
              emergencyTelephone !== "" &&
              formatPhoneNumber(emergencyTelephone) !== null
                ? formatPhoneNumber(emergencyTelephone)
                : ""}
            </p>
          </div>

          <div className="align-self-center">
            <button
              type="button"
              className="btn border outline-0 shadow-none  position-relative"
              disabled
            >
              <MessagesIcon
                style={{ color: "#dddddd", fill: "#dddddd" }}
                // style={{ color: "#336383", fill: "#336383" }}
                width="18"
                height="18"
              />
              {unreadMessageCount > 0 && (
                <span
                  className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg__secondary"
                  style={{ backgroundColor: "#dddddd" }}
                >
                  {unreadMessageCount}{" "}
                  <span className="visually-hidden">unread messages</span>
                </span>
              )}
            </button>
          </div>
        </div> */}
      </div>
      {/* {console.log("patientEncounterInformation",patientEncounterInformation)} */}
      {/* {console.log("isSocialHistoryEdited",isSocialHistoryEdited)} */}
    </>
  );
};

export default PatientInformation;

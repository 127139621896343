import { useEffect, useState } from "react";
import { Tabs, Tab, Backdrop, CircularProgress, Skeleton } from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import { TabPanel, theme } from "../../components/UI/Reusable/PnComponents";
import classes from "./PatientInsurancePage.module.css";
import InsurancePage from "./InsurancePage";
import sendHttpRequest from "../../components/hooks/sendHttpRequest";
import { payersListUrl } from "../../res/endpointurls";
import PageHeader from "../generic/PageHeader";
import { useAuth } from "../../components/hooks/useAuth";
import { removeIfAllEmpty } from "../../utils/helpers/HelperFunctions";
import CustomizedSnackbar from "../../components/UI/General/CustomizedSnackbars";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setPatientInsurance } from "../../redux/actions/patientActions";
import { setDirtyBitInStore } from "../../redux/actions/providerActions";

const PatientInsurnacePage = (props) => {
  const patientInsurances = useSelector(
    (state) => state.patientInsuranceData.patientInsurance
  );
  const dispatch = useDispatch();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [insuranceType, setInsuranceType] = useState("Primary");
  const [insurancevalue, setInsurancevalue] = useState(0);
  const [patientForm, setPatientForm] = useState("");
  const [primaryInsurance, setPrimaryInsurance] = useState("");
  const [primaryInsurance1, setPrimaryInsurance1] = useState({});
  const [secondaryInsurance, setSecondaryInsurance] = useState("");
  const [secondaryInsurance1, setSecondaryInsurance1] = useState({});
  const [tertiaryInsurance, setTertiaryInsurance] = useState("");
  const [tertiaryInsurance1, setTertiaryInsurance1] = useState({});
  const [payersList, setPayersList] = useState({ isLoading: false });
  const [isSending,setIsSending] = useState(false);
  const [isSent,setIsSent] = useState(false);


  const [primaryInsuranceJson, setPrimaryInsuranceJson] = useState({
    insuranceName: "",
    payerCode: "",
    // policyNumber: "",
    memberId: "",
    groupNumber: "",
    effectiveDate: "",
    terminationDate: "",
    relationshipToInsured: "",
    contactNumber: "",
    type: "Primary",
    firstName: "",
    lastName: "",
    middleName: "",
    gender: "",
    dateOfBirth: "",
    city: "",
    state: "",
    zipCode: "",
    addressLine1: "",
    addressLine2: "",
  });

  const [secondaryInsuranceJson, setSecondaryInsuranceJson] = useState({
    insuranceName: "",
    payerCode: "",
    // policyNumber: "",
    memberId: "",
    groupNumber: "",
    effectiveDate: "",
    terminationDate: "",
    relationshipToInsured: "",
    contactNumber: "",
    type: "Secondary",
    firstName: "",
    lastName: "",
    middleName: "",
    gender: "",
    dateOfBirth: "",
    city: "",
    state: "",
    zipCode: "",
    addressLine1: "",
    addressLine2: "",
  });

  const [tertiaryInsuranceJson, setTertiaryInsuranceJson] = useState({
    insuranceName: "",
    payerCode: "",
    // policyNumber: "",
    memberId: "",
    groupNumber: "",
    effectiveDate: "",
    terminationDate: "",
    relationshipToInsured: "",
    contactNumber: "",
    type: "Tertiary",
    firstName: "",
    lastName: "",
    middleName: "",
    gender: "",
    dateOfBirth: "",
    city: "",
    state: "",
    zipCode: "",
    addressLine1: "",
    addressLine2: "",
  });

  const handleInsuranceChange = (event, newValue) => {
    setInsurancevalue(newValue);
  };

  const getInsuranceTabValue = function (insuranceTabsTitle) {
    const insuranceTabsMap = new Map();
    insuranceTabsMap.set("Primary", 0);
    insuranceTabsMap.set("Secondary", 1);
    insuranceTabsMap.set("Tertiary", 2);
    return insuranceTabsMap.get(insuranceTabsTitle);
  };

  const getTabValue = function (tabTitle) {
    const tabsMap = new Map();
    tabsMap.set("General", 0);
    tabsMap.set("Insurance", 1);
    return tabsMap.get(tabTitle);
  };

  const fetchPayersList = async () => {
    setPayersList((prev) => ({ ...prev, isLoading: true }));
    const httpResponse = await sendHttpRequest({
      method: "GET",
      url: payersListUrl(),
    });
    if (!httpResponse.error) {
      setPayersList((prev) => ({ ...prev, list: httpResponse.data }));
    }
    setPayersList((prev) => ({ ...prev, isLoading: false }));
  };
  const fecthPatientInsuranceInformation = async (userId)=>{
    const httpResponse = await sendHttpRequest({
      url:`/patients/${userId}/insurances`,
      method:"GET"
    })

    if(!httpResponse.error){
      let sortedInsuranceData = httpResponse.data.sort((a, b) => {
        const order = ["Primary", "Secondary", "Tertiary"];
        return order.indexOf(a.type) - order.indexOf(b.type);
      });
      dispatch(setPatientInsurance(sortedInsuranceData))
    }
  }
  const editPatientRequestHandler = async () => {
    const insurancesArray = [];
    // if (patientObject?.insured === true) {
    if (removeIfAllEmpty(primaryInsuranceJson) === false) {
      insurancesArray.push(primaryInsuranceJson);
    }
    if (removeIfAllEmpty(secondaryInsuranceJson) === false) {
      insurancesArray.push(secondaryInsuranceJson);
    }
    if (removeIfAllEmpty(tertiaryInsuranceJson) === false) {
      insurancesArray.push(tertiaryInsuranceJson);
    }
    // }
    console.log("insurancesArray: ",insurancesArray);
    setIsSending(true);
    dispatch(setDirtyBitInStore(false));
    const httpResponse = await sendHttpRequest({
      url: `/patients/${user.userId}/insurances`,
      method: "PUT",
      data: insurancesArray
    });
    if (!httpResponse.error) {
      fecthPatientInsuranceInformation(user?.userId);
      setIsSent(true);
      // navigate("/");
    } else {
      // console.log(httpResponse.errorMessage);
      // setServerErrors(httpResponse.errorMessage);
    }
    setIsSending(false);
  };

  const handleSubmit = () => {
    editPatientRequestHandler();
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
        return;
    }

    setIsSent(false);
};
  useEffect(() => {
    if (payersList?.list === undefined) {
      fetchPayersList();
    }
    if(patientInsurances?.length > 0){
      let primaryInsObj = patientInsurances.find((item)=>{ return item.type?.toLowerCase() === "primary"})
      let secondaryInsObj = patientInsurances.find((item)=>{ return item.type?.toLowerCase() === "secondary"})
      let terciaryInsObj = patientInsurances.find((item)=>{ return item.type?.toLowerCase() === "tertiary"})
      console.log("patientInsurances: ",patientInsurances);
      console.log("primaryInsObj: ",primaryInsObj);
      if(primaryInsObj !==  undefined){
        setPrimaryInsuranceJson(primaryInsObj)
      }
      if(secondaryInsObj !==  undefined){
        setSecondaryInsuranceJson(secondaryInsObj)
      }
      if(terciaryInsObj !==  undefined){
        setTertiaryInsuranceJson(terciaryInsObj)
      }

      // setPrimaryInsuranceJson(patientInsurances.)
    }
    return ()=>{setIsSent(false)}
  }, []);

  return (
    <>
      <PageHeader title="Insurance" />
      <ThemeProvider theme={theme}>
        <div
          className={`container-fluid bg-white px-3 py-3 ${classes["page__title-border"]}`}
        >
          <div className="row justify-content-center">
            <div className="col-sm-4 col-md-4">
              <Tabs
                indicatorColor="secondary"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#EC609B",
                  },
                }}
                value={insurancevalue}
                variant="scrollable"
                scrollButtons="auto"
                onChange={handleInsuranceChange}
              >
                <Tab
                  key={getInsuranceTabValue("Primary")}
                  label={"Primary"}
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    textTransform: "initial",
                    color: "#336383",
                    fontFamily: "Montserrat",
                  }}
                />

                <Tab
                  key={getInsuranceTabValue("Secondary")}
                  label={"Secondary"}
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    textTransform: "initial",
                    color: "#336383",
                    fontFamily: "Montserrat",
                  }}
                />

                <Tab
                  key={getInsuranceTabValue("Tertiary")}
                  label={"Tertiary"}
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    textTransform: "initial",
                    color: "#336383",
                    fontFamily: "Montserrat",
                  }}
                />
              </Tabs>
            </div>
            {payersList?.isLoading ? <Skeleton/>:
              <TabPanel
                value={insurancevalue}
                index={getInsuranceTabValue("Primary")}
                className="bg--background "
              >
                <InsurancePage
                  payersList={payersList}
                  insuranceJson={primaryInsuranceJson}
                  setInsuranceJson={setPrimaryInsuranceJson}
                  handleSubmit={handleSubmit}
                  setPrimaryInsurance={setPrimaryInsurance}
                  type={"primary"}
                />
              </TabPanel>
            }

            <TabPanel
              value={insurancevalue}
              index={getInsuranceTabValue("Secondary")}
              className="bg--background "
            >
              <InsurancePage
                payersList={payersList}
                insuranceJson={secondaryInsuranceJson}
                setInsuranceJson={setSecondaryInsuranceJson}
                handleSubmit={handleSubmit}
                setSecondaryInsurance={setSecondaryInsurance}
                type={"secondary"}
              />
            </TabPanel>

            <TabPanel
              value={insurancevalue}
              index={getInsuranceTabValue("Tertiary")}
              className="bg--background "
            >
              <InsurancePage
                payersList={payersList}
                insuranceJson={tertiaryInsuranceJson}
                setInsuranceJson={setTertiaryInsuranceJson}
                handleSubmit={handleSubmit}
                setTertiaryInsurance={setTertiaryInsurance}
                type={"tertiary"}
              />
            </TabPanel>
          </div>
        </div>
      </ThemeProvider>
      <CustomizedSnackbar
        message={"Insurance data saved successfully"}
        severity="success"
        open={isSent}
        handleClose={handleClose}
      />
      {console.log("primaryInsuranceJson: ", primaryInsuranceJson)}
      {console.log("secondaryInsuranceJson: ", secondaryInsuranceJson)}
    </>
  );
};

export default PatientInsurnacePage;

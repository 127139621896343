import { momentLocalizer, Views } from "react-big-calendar";

import moment from 'moment';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import { useState } from "react";
const localizer = momentLocalizer(moment);

function convertDateFormat(inputDate) {
    // Parse the input date string
    const parsedDate = new Date(inputDate);

    // Format the date to yyyy-MM-dd
    const formattedDate = `${parsedDate.getFullYear()}-${(parsedDate.getMonth() + 1).toString().padStart(2, '0')}-${parsedDate.getDate().toString().padStart(2, '0')}`;

    return formattedDate;
}

const CustomToolbar = (toolbar, props) => {
    const [activeView, setActiveView] = useState(toolbar.view);
    let currentDate;

    const goToBack = () => {
        setActiveView(toolbar.view)
        const backDate = moment(toolbar.date).subtract(1, toolbar.view).toDate();
        console.log(convertDateFormat(backDate))
        toolbar.onNavigate('prev', backDate);
        if (toolbar.view?.toLowerCase() === 'day') {
            toolbar.daysHandler(convertDateFormat(backDate));
        }
    };

    const goToNext = () => {
        setActiveView(toolbar.view)
        const nextDate = moment(toolbar.date).add(1, toolbar.view).toDate();
        console.log(convertDateFormat(nextDate))

        toolbar.onNavigate('next', nextDate);
        if (toolbar.view?.toLowerCase() === 'day') {
            console.log('Called');
            toolbar.daysHandler(convertDateFormat(nextDate));
        }
    };



    const goToToday = () => {
        const now = new Date();
        toolbar.date.setMonth(now.getMonth());
        toolbar.date.setFullYear(now.getFullYear());
        toolbar.date.setDate(now.getDate());
        if (toolbar.view != Views.DAY) {
            toolbar.onNavigate('date');
            toolbar.onView(Views.MONTH);
            setActiveView(Views.MONTH)
        }
        else if (toolbar.view === Views.DAY) {
            toolbar.onNavigate('date')
            toolbar.onView(Views.DAY);
            setActiveView(Views.DAY);
        }
        toolbar.handler();
    }

    const goToDayView = () => {

        toolbar.onView(Views.DAY);
        // Use the onView callback to set the active view after the view has changed

        setActiveView(Views.DAY);
    };

    const goToWeekView = () => {
        toolbar.onView(Views.WEEK);
        setActiveView(Views.WEEK);
    };

    const goToMonthView = () => {
        toolbar.onView(Views.MONTH);
        setActiveView(Views.MONTH);
    };


    // console.log(toolbar);
    if (toolbar.view === Views.DAY) {
        currentDate = moment(toolbar.date).format('dddd, MMM D, YYYY');
    } else if (toolbar.view === Views.WEEK) {
        const startDate = moment(toolbar.date).startOf('week').format('MMM D');
        const endDate = moment(toolbar.date).endOf('week').format('MMM D, YYYY');
        currentDate = `${startDate} - ${endDate}`;
    } else {
        currentDate = moment(toolbar.date).format('MMMM YYYY');
    }
    return (
        <div className="rbc-toolbar">
            <span className="rbc-btn-group">
                <button type="button" className="text--secondary clickable fw-sb" onClick={goToToday}>Today</button>
                <button type="button" className="clickable" onClick={goToBack}>
                    <ChevronLeft className="text--primary fw-sb" />
                </button>
                <button type="button" className="clickable" onClick={goToNext}>
                    <ChevronRight className="fw-sb text--primary " />
                </button>
            </span>
            <span className="rbc-toolbar-label font-16">{currentDate}</span>
            <span className="rbc-btn-group">
                <button type="button" className={activeView === Views.MONTH ? "rbc-active" : ''} onClick={goToMonthView}>
                    Month
                </button>
                <button type="button" className={activeView === Views.WEEK ? "rbc-active" : ''} onClick={goToWeekView}>
                    Week
                </button>
                <button type="button" className={activeView === Views.DAY ? "rbc-active" : ''} onClick={goToDayView}>
                    Day
                </button>


            </span>


        </div>
    );
};

export default CustomToolbar;
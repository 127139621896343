import CloseIcon from "@mui/icons-material/Close";
import { Modal } from "react-bootstrap";
import { numbersEnum, renewalRequestStrings, common_strings, order_strings } from "../../res/strings";
import { isValidInteger } from "../../utils/helpers/HelperFunctions";
import { useState } from "react";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { acceptRenewalRequestUrl } from "../../res/endpointurls";
import { useAuth } from "../hooks/useAuth";
import { Backdrop, CircularProgress } from "@mui/material";
import { useUnsavedWorkPrompt } from "../hooks/useUnsavedWorkPrompt";
import { unsavedWorkHandler } from "../../utils/helpers/HelperFunctions";
import OrderPrescription from "../prescription/OrderPrescription";
import { useToggle } from "../hooks/useToggle";
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";
import InfoIcon from '@mui/icons-material/Info';


const ReplaceRxModal = (props) => {

    const [isDirty, onDirty, onPristine] = useUnsavedWorkPrompt();
    let _str = { ...numbersEnum?.en, ...renewalRequestStrings.en, ...common_strings.en, ...order_strings.en };
    const { user } = useAuth();
    const task = props?.renewalTask;
    const [originalPrescription, setOriginalPrescription] = useState();
    const [medicationExpanded, setMedicationExpanded] = useState("medication");
    const [summaryExpanded, setSummaryExpanded] = useState("summary");


    const [refills, setRefills] = useState(originalPrescription?.refills);
    const [isRefillChanged, setIsRefillChanged] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);


    const [regexError, setRegexError] = useState({ refills: false });
    const [errorMessage, setIsErrorMessage] = useToggle(false);

    const handleRefillChange = (event) => {
        setRefills(event.target.value);
        onDirty();
        if (isValidInteger(event.target.value)) {
            setRegexError((previousSnapshot) => ({ ...previousSnapshot, refills: false }));
            setIsRefillChanged(Number(event.target.value) !== Number(originalPrescription?.refills) ? true : false);
        }
        else {
            setRegexError((previousSnapshot) => ({ ...previousSnapshot, refills: true }));
        }
    }

    const handleChange = (panel) => (event, newExpanded) => {
        if (panel === "medication") {
            setMedicationExpanded(newExpanded ? panel : false);
        } else if (panel === "summary") {
            setSummaryExpanded(newExpanded ? panel : false);
        }
    };


    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setIsErrorMessage(false);
    };




    // let patient = props?RxObject?.patient;
    return (
        <>
            <Modal
                show={props.show}
                onHide={() => {
                    unsavedWorkHandler("modal", "There are unsaved changes, do you wish to continue?", isDirty, props.handleModal, onPristine, props.handleModal)
                    // props.handleModal();
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                backdropClassName="modal-backdrop vh-100"
                contentClassName="modal-border modal-shadow vh-100"
                scrollable
                centered
            >
                <Modal.Header className="modal-header">
                    <span
                        style={{
                            lineHeight: "12px",
                            width: "18px",
                            fontSize: "8pt",
                            color: "#336383",
                            position: "absolute",
                            top: 15,
                            right: 15,
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            unsavedWorkHandler("modal", "There are unsaved changes, do you wish to continue?", isDirty, props.handleModal, onPristine, props.handleModal)
                            // props.handleModal();
                        }}
                    >
                        {<CloseIcon />}
                    </span>
                    <Modal.Title className="font-20 fw-sb">{_str.renewalRequest}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row justify-content-start">
                        <div className="col-sm-12">
                            <div className="m-0 p-0 ps-2 font-13 fw-sb text--terciary">
                                <InfoIcon style={{ fill: "#004fd4" }} /> {_str.replaceRequestTitle}
                            </div>
                        </div>
                        <div className="col-sm-12 col-12 ">
                            <OrderPrescription
                                newPrescriptionRequest={true}
                                encounterId={task?.encounter?.encounterId}
                                patientId={task?.prescriptionReference?.patient?.userId}
                                providerId={task?.prescriptionReference?.provider?.userId}
                                task={task?.taskId}
                                replaceRequest={true}
                                orderPharmacy={task?.prescription?.pharmacy}
                                showError={setIsErrorMessage}
                                showMessage={props.showMessage}
                                reloadTasks={props.reloadTasks}
                                handleModal={props.handleModal}
                            />
                        </div>
                    </div>
                </Modal.Body>

                {/* <Modal.Footer>

                    <button
                        disabled={regexError?.refills === true ? true : false}
                        className="btn blue_task_button text-white font-14 px-4 br-10 py-1 fw-sb"
                        onClick={(e) => {

                            // props.handleModal();
                        }}
                        style={{
                            border: "#004FD4 0% 0% no-repeat padding-box",
                            height: "44px",
                        }}
                    >
                        {_str.replace}
                    </button>
                </Modal.Footer> */}
            </Modal>

            <Backdrop
                sx={{
                    color: "#EC609B",
                    zIndex: (theme) => theme.zIndex.drawer + 1000,
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <CustomizedSnackbars
                message={"Error in processing request. Please try again."}
                severity="error"
                open={errorMessage}
                handleClose={handleSnackbarClose}
            />


            {/* {console.log("monCount",monCount)} */}
            {/* {console.log("mondaySlotsList",mondaySlotsList)} */}
        </>
    );
};

export default ReplaceRxModal;

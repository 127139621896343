import { ActionTypes } from "../constants/actionTypes"
import placeholder from "./../../assets/images/placeholder.png"
const prscriberInitialState = {
    prescriber: {

        prescriberId: 0,
        selectedPrescriberId: 0,
        selectedPrescriberName: {
            FirstName: '',
            LastName: '',
        },
        selectedPrescriberRole: '',
        Name: {
            FirstName: '',
            LastName: '',
        },
        profilePicture: placeholder,
        role: ''
    }

}

const setPrescriberInfoReducer = (state = prscriberInitialState, action) => {
    if (action.type === ActionTypes.SET_PRESCRIBER_INFO) {
        return {
            ...state,
            prescriber: action.payload

        }
    }
    return state
}


export default setPrescriberInfoReducer;
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import sendHttpRequest from "../../hooks/sendHttpRequest";
import CustomDatePicker from "../General/CustomDatePicker";
import classes from "./ProviderAvailabilitySection.module.css";
import moment from "moment-timezone";
import { DateTime } from "luxon";
import {
  addDays,
  calenderDateTimeHelper,
  changeDateTimeFormat,
  convertUTCtoTimzone,
  dateTimeHelper,
  getDMYformate,
} from "../../../utils/dateTimeHelper";
import IconNext from "../../../assets/icons/Icon-Next.svg";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useAuth } from "../../hooks/useAuth";
import CalenderDatePicker from "../General/CalenderDatePicker";
const ProviderAvailabilitySection = (props) => {
  const { user } = useAuth();
  const [activeRow, setActiveRow] = useState();
  const [freeSlots, setFreeSlots] = useState([]);
  const [isSlotsLoading, setIsSlotsLoading] = useState(false);
  const [fromDate, setFromDate] = useState(new Date());
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(7);
  const [pages, setPages] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageNumberLimit, setPageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [maxPageLimit, setMaxPageLimit] = useState(0);
  const joined = convertUTCtoTimzone(new Date(), "","YYYY-MM-DD");
  const [selectedProviderId, setSelectedProviderId] = useState(
    props?.providerId
  );

  if (freeSlots?.length > 0 && currentItems?.length === 0) {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    setCurrentItems(freeSlots.slice(indexOfFirstItem, indexOfLastItem));
  }
  const handleActiveRow = (dataItem, e) => {
    e.preventDefault();
    setActiveRow(dataItem);
    handleProviderTimeSlot(dataItem);
  };
  const fecthFreeSlots = async (
    dateValue,
    prescriberId,
    appointmentType,
    pageNo,
    durationMinutes
  ) => {
    setIsSlotsLoading(true);
    setFreeSlots([]);
    setCurrentItems([]);
    setPages([]);
    let toDateValue = addDays(dateValue, 6);
    let fromDateValue = getDMYformate(dateValue, "YYYY-MM-DD");
    const httpResponse = await sendHttpRequest({
      url: "/availabilities/availableSlots",
      method: "GET",
      params: {
        providerId: prescriberId,
        fromDate: fromDateValue,
        toDate: toDateValue,
        appointmentType: appointmentType,
        patientId: props?.patientId,
        page: pageNo,
        duplicates: false,
        size:7,
        tenantId: user?.tenantId,
        duration: durationMinutes === undefined ? appointmentType?.durationMinutes : durationMinutes
      },
    });
    if (!httpResponse.error) {
      setFreeSlots(httpResponse?.data?.providersAvailabilityList);
      // setMaxPageLimit(httpResponse?.data?.totalPages);
      setMaxPageLimit(Math.ceil(httpResponse?.data?.totalPages / itemsPerPage));
      let pagesList = [];
      // for (
      //   let i = 1;
      //   i <= Math.ceil(httpResponse?.data?.totalPages / itemsPerPage);
      //   i++
      // ) {
      //   pagesList.push(i);
      // }
      setPages(pagesList);
    } else {
      console.log(httpResponse.error);
    }
    setIsSlotsLoading(false);
  };
  const handleFromDate = (dateValue) => {
    setPageNumber(0);
    props?.setScheduleDate(getDMYformate(dateValue, "YYYY-MM-DD"));
    setFromDate(dateValue);
    fecthFreeSlots(
      getDMYformate(dateValue, "YYYY-MM-DD"),
      props?.providerId,
      props?.appointmentType?.key,
      0,
      props?.appointmentType?.durationMinutes
    );
  };
  const handleProviderTimeSlot = (timeSlot) => {
    props?.setProviderID(timeSlot?.providerTenantPracticeLocation?.provider?.userId);
    props?.setScheduleDate(calenderDateTimeHelper(timeSlot?.startDateTime, "YYYY-MM-DD"));
    props?.setSlotStartTime(timeSlot?.startDateTime);
  };

  const handleCurrentPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    const indexOfLastItem = pageNumber * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    setCurrentItems(freeSlots.slice(indexOfFirstItem, indexOfLastItem));
  };
  const RenderPages = () => {
    return (
      <ul className={`${classes["pageNumbers"]}`}>
        {pages?.length > 1 &&
          pages?.map((number) => {
            if (
              number < maxPageNumberLimit + 1 &&
              number > minPageNumberLimit
            ) {
              return (
                <li
                  key={number}
                  id={number}
                  className={`${
                    currentPage === number ? classes["active__list__item"] : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleCurrentPage(number);
                  }}
                >
                  {number}
                </li>
              );
            } else {
              return null;
            }
          })}
      </ul>
    );
  };

  const handleNextButton = (event) => {
    event.preventDefault();
    if (pageNumber + 1 <= maxPageLimit) {
      fecthFreeSlots(
        getDMYformate(fromDate, "YYYY-MM-DD"),
        props?.providerId,
        props?.appointmentType?.key,
        pageNumber + 1,
        props?.appointmentType?.durationMinutes
      );
      setPageNumber(pageNumber + 1);
    }
  };
  const handlePreButton = (event) => {
    event.preventDefault();

    if (pageNumber - 1 >= 0) {
      fecthFreeSlots(
        getDMYformate(fromDate, "YYYY-MM-DD"),
        props?.providerId,
        props?.appointmentType?.key,
        pageNumber - 1,
        props?.appointmentType?.durationMinutes
      );
      setPageNumber(pageNumber - 1);
    }
  };

  useEffect(() => {
    // if(pageNumber === 0 ){
    setPageNumber(0);
    fecthFreeSlots(
      getDMYformate(props?.scheduleDate, "YYYY-MM-DD"),
      props?.providerId,
      props?.appointmentType?.key,
      0,
      props?.appointmentType?.durationMinutes
    );
    // }
  }, [props?.appointmentType, props?.handleFreeSlots, props?.patientId]);

  return (
    <div className="row ">
      <div className="col-sm-5">
        <div className="d-flex flex-row">
          <p className="ms-1 my-auto text--terciary b2 fw-sb font-12 align-self-center">
            {/* {fromDate !==undefined ? getDMYformate(fromDate,"MMM D,YYYY"):""} */}
            From
          </p>
          <div className="ms-1">
            <CalenderDatePicker
              f12={true}
              dobHandler={handleFromDate}
              dateFormat="MMM d, yyyy"
              date={joined}
              minDate={joined}
              viewSchedule={true}
            />
          </div>
        </div>
      </div>
      <div className="col-sm-4">
        <div className="d-flex flex-row mt-2">
          <p className=" font-12 text--terciary fw-sb my-auto">
            {"Duration:"} &nbsp;
          </p>
          <p className="ms-1 font-12 text--secondary fw-sb my-auto">
            {props?.appointmentDuration !== undefined
              ? props?.appointmentDuration + " mins"
              : ""}
          </p>
        </div>
      </div>
      <div className="col-sm-3">
        <div className="align-self-center">
          <button
            className="p-1  br-10 bg--background  border--default br-10 "
            onClick={(e) => {
              handlePreButton(e);
            }}
          >
            <ChevronLeftIcon className="text--primary" />
          </button>
          <button
            className="p-1  br-10 bg--background  border--default br-10  ms-2"
            onClick={(e) => {
              handleNextButton(e);
            }}
          >
            <ChevronRightIcon className="text--primary" />
          </button>
        </div>
      </div>

      {/* Table */}

      <div>
        {/* Head */}
        <Grid
          container
          sx={{ marginBottom: "-8px" }}
          // p={1}
          className={` bg-white ${classes["row__head"]} mt-3`}
        >
          <Grid item xs={4}>
            <span className="font-12 text--secondary  fw-sb">Date</span>
          </Grid>
          <Grid item xs={4}>
            <span className="font-12 text--secondary  fw-sb">Start Time</span>
          </Grid>
          <Grid item xs={4}>
            <span className="font-12 text--secondary  fw-sb">Provider</span>
          </Grid>
        </Grid>

        {/* Items */}

        {isSlotsLoading ? (
          <Skeleton />
        ) : (
          currentItems.map((slotItem, index) => {
            var provider = slotItem?.providerTenantPracticeLocation?.provider;
            return (
              <Grid
                key={index}
                sx={{ marginBottom: "-8px" }}
                container
                p={1}
                alignItems="center"
                className={`${classes["row--border"]} mt-2 cursor--pointer  ${
                  activeRow != slotItem ? "bg--background" : "bg--d9e8ec"
                } ${
                  index === currentItems.length - 1
                    ? classes["border--bottom"]
                    : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  handleActiveRow(slotItem, e);
                }}
              >
                <Grid item xs={4}>
                  <Typography>
                    <span className="font-12 text--terciary fw-sb p-0">
                      {getDMYformate(slotItem?.startDateTime, "MMM DD, yyyy")}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography component="div">
                    <span className="font-12 text--terciary  p-0">
                      {getDMYformate(slotItem?.startDateTime, "LT")}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography component="div">
                    <span className="font-12 text--terciary p-0 ">
                      {provider?.name !== null && provider?.name[0]?.prefix !== undefined &&
                      provider?.name[0]?.prefix !== null  
                        ? provider?.name[0]?.prefix + " "
                        : ""}
                      {provider?.name !== null && provider?.name[0]?.firstName !== undefined ? provider?.name[0]?.firstName +
                        " " +
                        provider?.name[0]?.lastName :""}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            );
          })
        )}
      </div>
      <div className="col-12 mt-2">
        {isSlotsLoading ? (
          <Skeleton />
        ) : (
          <ul className={`${classes["pageNumbers"]} `}>
            {freeSlots?.length > 7 ? (
              <li>
                <button
                  onClick={(e) => {
                    handlePreButton(e);
                  }}
                  className={`${classes["prev__btn"]} bg--fafcfc border--default br-10`}
                >
                  <img src={IconNext} alt="icon-previous"></img>
                </button>
              </li>
            ) : (
              ""
            )}

            <RenderPages />
            {freeSlots?.length > 7 ? (
              <li>
                <button
                  onClick={(e) => {
                    handleNextButton(e);
                  }}
                  className={`${classes["next__btn"]} bg--fafcfc border--default br-10`}
                >
                  <img src={IconNext} alt="icon-next"></img>
                </button>
              </li>
            ) : (
              ""
            )}
          </ul>
        )}
      </div>

      {/* {console.log("maxPageLimit",maxPageLimit)}  
      {console.log("Current page number",pageNumber)}  */}
      {/* {console.log("props?.providerId",props?.providerId)}
      {console.log("selectedProviderId",selectedProviderId)} */}
    </div>
  );
};

export default ProviderAvailabilitySection;

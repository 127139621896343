import { useEffect, useRef, useState } from "react";
import SearchProvider from "../UI/General/SearchProvider";
import Navbar from "../UI/Navbar";
import { X } from "react-bootstrap-icons";
import sendHttpRequest from "../hooks/sendHttpRequest";
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";
import { Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setDirtyBitInStore } from "../../redux/actions/providerActions";
import { ChevronLeft } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import SignatureUploadModal from "../UI/Modals/SignatureModals/SignatureUploadModal";
import SignatureImageUploadModal from "../UI/Modals/SignatureModals/SignatureImageUploadModal";
import { useAuth } from "../hooks/useAuth";
import { navigateOnDirtyBit } from "../../utils/helpers/HelperFunctions";
import PageHeader from "../../patientportal/generic/PageHeader";
import { ACCOUNT_SETTINGS } from "../../utils/helpers/subscriptionsConstants";

class Person {
  constructor(userNew) {
    this.userId = userNew.id;
    this.username = userNew.username;
    this.password = userNew.password;
  }
}

const SettingsPage = (props) => {
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);
  const [providers, setProviders] = useState([]);
  const [isNotificationsUpdated, setIsNotificationsUpdated] = useState(false);
  const [isUpdatingNotifications, setIsUpdatingNotifications] = useState(false);
  const [notificationsResponse, setNotificationsResponse] = useState("");
  const [allowCheckbox, setAllowCheckbox] = useState(true);
  const [isLoadingNotificants, setIsLoadingNotificants] = useState(false);
  const [trimmedDataURL, setTrimmedDataURL] = useState(null);
  const [openSignaturePad, setOpenSignaturePad] = useState(false);
  const [openSignatureImagePad, setOpenSignatureImagePad] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [printedRx, setPrintedRx] = useState(false);
  const [encounterSum, setEncounterSum] = useState(false);
  const [afterVisitSum, setAfterVisitSum] = useState(false);
  const [labOrdersCheck, setLabOrdersCheck] = useState(false);
  const [imagingOrders, setImagingOrders] = useState(false);
  const [settings, setSettings] = useState(null);
  const [isDisable, setIsDisable] = useState(
    trimmedDataURL === null && settings === ""
  );
  const [filename, setFilename] = useState(null);
  const dirtyBitStatus = useSelector((state) => state.dirtyStatus.isDirty);
  const fd = useRef(new FormData());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [notifications, setNotifications] = useState([]);
  const [isNotifcationLoading, setIsNotifcationLoading] = useState(false);
  const [imageURL, setImageURL] = useState();


  const handleNotificationsToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsNotificationsUpdated(false);
    setErrorStatus(false);
    setUploadStatus(false);
  };

  const handleProvidersAdd = (provider) => {
    dispatch(setDirtyBitInStore(true));
    const tempList = [...providers];
    if (provider?.userId != user?.userId) {
      tempList.push(provider);
      const unique = [
        ...new Map(tempList.map((item) => [item.userId, item])).values(),
      ];
      setProviders(unique);
    }

  };

  const handleSignaturePadClose = () => {
    if (trimmedDataURL !== "") {
      dispatch(setDirtyBitInStore(true));
    }
    setOpenSignaturePad(false);
  };

  const handleSignatureImageClose = () => {
    if (trimmedDataURL !== "") {
      dispatch(setDirtyBitInStore(true));
    }
    setOpenSignatureImagePad(false);
  };

  const handleRemove = (id) => {
    dispatch(setDirtyBitInStore(true));
    const dummy = providers.filter((provider) => provider?.userId !== id);
    setProviders(dummy);
  };

  // const getMapFromSession = async () => {
  //   setIsLoadingFromSession(false);
  //   const httpResponse = await sendHttpRequest({
  //     url: "/session",
  //     method: "GET",
  //   });
  //   if (!httpResponse.error) {
  //     setUserInfo(JSON.parse(httpResponse.data.user));
  //     setSelectedUser(JSON.parse(httpResponse.data.selected_user));
  //     getSignature(JSON.parse(httpResponse.data.selected_user));
  //   } else {
  //   }
  //   setIsLoadingFromSession(true);
  // };

  const createProvidersIdList = () => {
    let users = [];
    if (providers?.length > 0) {
      users = providers?.map((provider) => provider?.userId);
    }
    return users;
  };

  function b64toBlob(base64, type = "application/octet-stream") {
    try {
      const binStr = window.atob(base64);
      const len = binStr?.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      return new Blob([arr], { type: type });
    } catch (e) {
      // setIsLoadingFetch(true);
    }
  }

  const convertToImage = (fileV, trimmedDataURL) => {
    let mimeType = trimmedDataURL.split(",")[0].split(":")[1].split(";")[0];

    fetch(trimmedDataURL)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], fileV?.name ?? "signature.png", {
          type: mimeType,
        });
        setFilename(file);
        checkForDisable(settings, trimmedDataURL);
      });
  };

  const getSignatureData = () => {

    let userIds = createProvidersIdList();

    fd.current.append("printedRx", printedRx);
    fd.current.append(`taskDelegationIds`, userIds);
    fd.current.append("afterVisitSummary", afterVisitSum);
    fd.current.append("encounterSummary", encounterSum);
    fd.current.append("imagingOrders", imagingOrders);
    fd.current.append("labOrders", labOrdersCheck);
    fd.current.append("smsNotificationFor14Hours", false);
    fd.current.append("smsNotificationFor24Hours", false);
    fd.current.append("emailNotificationFor24Hours", false);
    fd.current.append("signaturePath", settings?.signaturePath ?? "");

    // notifications.forEach((item,index)=>{
    //   console.log(item);
    //   fd.current.append(`notificationSetting[${index}]`,JSON.stringify(item))
    // })

    if (filename !== null) {
      fd.current.append("file", filename);
    }
  };

  const postSignatureData = async () => {
    // setIsUploading(true);
    getSignatureData();
    const httpResponse = await sendHttpRequest({
      url: `/provider/${user?.userId}/settings`,
      method: settings === "" ? "POST" : "PUT",
      data: fd.current,
      headers: {
        "Content-Type": "multipart/form-data; boundary=",
      },
    });
    if (!httpResponse.error) {
      // emptyStates();
      // setIsUploading(false);
      setUploadStatus(true);
      getSignature();
    } else {
      setErrorStatus(true);
      console.log(httpResponse.error);
    }
    // setIsUploading(false);
    dispatch(setDirtyBitInStore(false));
    // handleFileUploadModalClose();
  };

  const setSignaturePermissions = (data) => {
    setPrintedRx(data?.printedRx ?? false);
    setEncounterSum(data?.encounterSummary ?? false);
    setAfterVisitSum(data?.afterVisitSummary ?? false);
    setLabOrdersCheck(data?.labOrders ?? false);
    setImagingOrders(data?.imagingOrders ?? false);
  };

  const getSignature = async () => {
    setIsNotifcationLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/provider/${user?.userId}/settings`,
      method: "GET",
    });
    if (!httpResponse.error) {
      // emptyStates();
      setSettings((prevState) => {
        return httpResponse.data;
      });
      setProviders(httpResponse.data?.taskDelegation);
      setSignaturePermissions(httpResponse.data);
      checkForDisable(httpResponse.data, null);
      setNotifications(httpResponse.data?.notificationSetting)

      let blob = b64toBlob(httpResponse.data?.signature, "image/png");
      setImageURL(URL.createObjectURL(blob));
      setFilename(blob);

    } else {
      setErrorStatus(true);
      console.log(httpResponse.error);
    }
    setIsNotifcationLoading(false);
  };
  const updateNotificationSettings = async () => {
    const httpResponse = await sendHttpRequest({
      url: `/provider/${user?.userId}/notification-settings`,
      method: 'PUT',
      data: { notificationSetting: notifications }
    })
  }

  const authorizeNotifications = async () => {
    setIsUpdatingNotifications(true);
    let users = createProvidersIdList();
    const httpResponse = await sendHttpRequest({
      url: `/provider/${user?.userId}/authorizeNotifications`,
      method: "POST",
      data: {
        users,
      },
    });
    if (!httpResponse.error) {
      setNotificationsResponse(httpResponse.data);
      setIsNotificationsUpdated(true);
      // setProviders([]);
      getAuthorizedNotifcants();
      dispatch(setDirtyBitInStore(false));
    } else {
      setErrorMessage(httpResponse.error);
      console.log(httpResponse.error);
    }
    setIsUpdatingNotifications(false);
  };

  const getAuthorizedNotifcants = async () => {
    setIsLoadingNotificants(true);
    const httpResponse = await sendHttpRequest({
      url: `/provider/${user?.userId}/authorizeNotifications`,
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
    setIsLoadingNotificants(false);
  };

  const checkForDisable = (settingsObject, trimmedDataURL) => {
    setIsDisable((prevState) => {
      return trimmedDataURL === null && settingsObject === "";
    });
  };

  const beforeUnloadWarning = function (event) {
    if (dirtyBitStatus === true) {
      event.returnValue =
        "There are unsaved changes on this page. Do you wish to continue?";
    }
  };

  window.addEventListener("beforeunload", beforeUnloadWarning);


  // Notification update
  const getNotificationItem = (notificationType, hrsmin) => {
    let result = notifications.find(
      (item) => {
        return item.durationUnit === hrsmin && item.sendType === notificationType;
      }
    );
    return result;
  }
  const handleInputs = (durationUnit, sendType, value, type) => {
    let tenantNotificationsTemp = notifications;
    let index = tenantNotificationsTemp.findIndex(
      (item) => item.durationUnit === durationUnit && item.sendType === sendType
    );
    if (type === "checkbox") {
      tenantNotificationsTemp[index].active = value;
    } else {
      tenantNotificationsTemp[index].duration = value;
    }
    setNotifications(tenantNotificationsTemp)
  };
  const handleChange = (event) => {
    let id = event.target.id;
    console.log(event.target.checked);
    if (id === "email-hrs-checkbox") {
      handleInputs("h", "email", event.target.checked, "checkbox");
    } else if (id === "email-mins-checkbox") {
      handleInputs("m", "email", event.target.checked, "checkbox");
    } else if (id === "email-hrs-input") {
      handleInputs("h", "email", event.target.value, "input");
    } else if (id === "email-mins-input") {
      handleInputs("m", "email", event.target.value, "input");
    } else if (id === "sms-hrs-checkbox") {
      handleInputs("h", "sms", event.target.checked, "checkbox");
    } else if (id === "sms-mins-checkbox") {
      handleInputs("m", "sms", event.target.checked, "checkbox");
    } else if (id === "sms-hrs-input") {
      handleInputs("h", "sms", event.target.value, "input");
    } else if (id === "sms-mins-input") {
      handleInputs("m", "sms", event.target.value, "input");
    }
  };



  useEffect(() => {
    setImageURL(trimmedDataURL)
  }, [trimmedDataURL])

  useEffect(() => {
    // getAuthorizedNotifcants();
    getSignature();
    // getMapFromSession();
    checkForDisable("", null);
    return () => {
      setProviders([]);
      setSettings(null);
      setSignaturePermissions(null);
      dispatch(setDirtyBitInStore(false));
      window.removeEventListener("beforeunload", beforeUnloadWarning);
    };
  }, []);

  return (
    <>
      <SignatureUploadModal
        show={openSignaturePad}
        handleSignaturePadClose={handleSignaturePadClose}
        setTrimmedDataURL={setTrimmedDataURL}
        uploadFile={convertToImage}
      />
      <SignatureImageUploadModal
        show={openSignatureImagePad}
        setOpenSignatureImagePad={setOpenSignatureImagePad}
        handleSignatureImageClose={handleSignatureImageClose}
        setTrimmedDataURL={setTrimmedDataURL}
        setSelectedFile={setSelectedFile}
        uploadFile={convertToImage}
      />
      <PageHeader title="Settings" />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          updateNotificationSettings();
          postSignatureData();
        }}
        id={"settingForm"}
      >
        <div className="container bg--background">
          <div className="row justify-content-center pt-3 pb-3">
            <div className="col-sm-12 col-md-6 " hidden={subscriptionPrivilegeGroups[ACCOUNT_SETTINGS]?.vtd?.active === false ? true : false}>
              <h2 className="font-20 text--terciary py-3">Task Delegation</h2>
              <div>
                {/* <input
                  className="form-check-input border-color-d9e8ec checkbox-border-width shadow-none"
                  type="checkbox"
                  id="allowCheckbox"
                  checked={allowCheckbox}
                  onChange={()=>setAllowCheckbox(!allowCheckbox)}
                /> */}
                <label className="font-14 ms-1" htmlFor="allowCheckbox">
                  Allow users listed below to process refill requests on my behalf
                </label>
              </div>
              <div className="py-2">
                <SearchProvider
                  handleProvidersAdd={handleProvidersAdd}
                  width="100%"
                  PlaceHolder="Search prescribing user"
                  focus={true}
                />
              </div>
              <div className="d-flex mt-2 flex-row flex-wrap gap-2">
                {isLoadingNotificants ? (
                  <div style={{ minWidth: "100%" }}>
                    <Skeleton animation="wave" />
                  </div>
                ) : (
                  providers?.map((provider, index) => {
                    return (
                      <div key={provider?.userId + index}>
                        <span className="badge font-14 fw-sb text--terciary bg--d9e8ec  py-1 px-2 br-10">
                          {provider?.name[0]?.firstName + " "}
                          {provider?.name[0]?.lastName}
                          {provider?.qualification !== undefined && provider?.qualification !== null ? ", " + provider?.qualification : ""}

                          <span
                            className="cross-sign"
                            onClick={() => handleRemove(provider?.userId)}
                          >
                            <X
                              color="#336383"
                              style={{ marginBottom: "2px" }}
                              size={24}
                            />
                          </span>
                        </span>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-6">
              <div className="horizontal-rule" />
            </div>
          </div>

          {
            isNotifcationLoading ? <Skeleton /> :
              subscriptionPrivilegeGroups[ACCOUNT_SETTINGS]?.uns?.active === true ?
                <div className="row py-3 justify-content-center">
                  <div className="col-sm-12 col-md-6">
                    <h2 className="font-20 text--terciary py-3">
                      Notification Settings for Patient Appointments
                    </h2>

                    <h2 className="font-16 fw-sb text--terciary py-2">
                      Email Notification
                    </h2>
                    <div
                      className="px-2 g-1 row row-cols-lg-auto align-items-center align-self-center  py-1"
                      style={{
                        border: "1px solid #d9e8ec",
                        borderRadius: "10px",
                      }}
                    >
                      <div className="col-12">
                        <div className="form-check my-auto">
                          <input
                            className="form-check-input border-color-d9e8ec checkbox-border-width shadow-none"
                            type="checkbox"
                            defaultChecked={getNotificationItem("email", "h")?.active ?? false}
                            id={"email-hrs-checkbox"}
                            onChange={(e) => { handleChange(e) }}
                          />
                          <label className="form-check-label font-14">
                            Send reminder
                          </label>
                        </div>
                      </div>
                      <div className="col-12">
                        <input
                          defaultValue={getNotificationItem("email", "h")?.duration ?? ""}
                          className="form-control d-inline br-10 font-14  border--default shadow-none input"
                          style={{ width: "10%", marginRight: "1%" }}
                          id={"email-hrs-input"}
                          onChange={(e) => { handleChange(e) }}
                          required={getNotificationItem("email", "h")?.active}
                        ></input>
                        <label className="form-check-label  font-14">
                          hours before appointment time
                        </label>
                      </div>
                      <div className="col-12">
                        <div className="form-check my-auto">
                          <input
                            className="form-check-input border-color-d9e8ec checkbox-border-width shadow-none"
                            type="checkbox"
                            defaultChecked={getNotificationItem("email", "m")?.active ?? false}
                            id={"email-mins-checkbox"}
                            onChange={(e) => { handleChange(e) }}
                          />
                          <label className="form-check-label font-14">
                            Send reminder
                          </label>
                        </div>
                      </div>
                      <div className="col-12">
                        <input
                          defaultValue={getNotificationItem("email", "m")?.duration ?? ""}
                          className="form-control d-inline br-10 font-14  border--default shadow-none input"
                          style={{ width: "10%", marginRight: "1%" }}
                          id={"email-mins-input"}
                          onChange={(e) => { handleChange(e) }}
                          required={getNotificationItem("email", "m")?.active}
                        ></input>
                        <label className="form-check-label  font-14">
                          minutes before appointment time
                        </label>
                      </div>
                    </div>

                    <h2 className="font-16 fw-sb text--terciary pb-2 py-3">
                      SMS Notifications
                    </h2>
                    <div
                      className="px-2 g-1 row row-cols-lg-auto align-items-center align-self-center  pt-1 pb-1"
                      style={{
                        borderTop: "1px solid #d9e8ec",
                        borderBottom: "1px solid #d9e8ec",
                        borderRight: "1px solid #d9e8ec",
                        borderLeft: "1px solid #d9e8ec",
                        borderTopRightRadius: "10px",
                        borderTopLeftRadius: "10px",
                      }}
                    >
                      <div className="col-12">
                        <div className="form-check my-auto">
                          <input
                            className="form-check-input border-color-d9e8ec checkbox-border-width shadow-none"
                            type="checkbox"
                            defaultChecked={getNotificationItem("sms", "h")?.active ?? false}
                            id={"sms-hrs-checkbox"}
                            onChange={(e) => { handleChange(e) }}
                          />
                          <label className="form-check-label font-14">
                            Send reminder
                          </label>
                        </div>
                      </div>
                      <div className="col-12">
                        <input
                          className="form-control d-inline br-10 font-14  border--default shadow-none input"
                          style={{ width: "10%", marginRight: "1%" }}
                          defaultValue={getNotificationItem("sms", "h")?.duration ?? ""}
                          id={"sms-hrs-input"}
                          onChange={(e) => { handleChange(e) }}
                          required={getNotificationItem("sms", "h")?.active}
                        ></input>
                        <label className="form-check-label  font-14">
                          hours before appointment time
                        </label>
                      </div>
                    </div>
                    <div
                      className="px-2  g-1 row row-cols-lg-auto align-items-center align-self-center  pt-1 pb-1"
                      style={{
                        borderBottom: "1px solid #d9e8ec",
                        borderRight: "1px solid #d9e8ec",
                        borderLeft: "1px solid #d9e8ec",
                        borderBottomRightRadius: "10px",
                        borderBottomLeftRadius: "10px",
                      }}
                    >
                      <div className="col-12">
                        <div className="form-check my-auto">
                          <input
                            className="form-check-input border-color-d9e8ec checkbox-border-width shadow-none"
                            type="checkbox"
                            defaultChecked={getNotificationItem("sms", "m")?.active ?? false}
                            id={"sms-mins-checkbox"}
                            onChange={(e) => { handleChange(e) }}

                          />

                          <label className="form-check-label font-14">
                            Send reminder
                          </label>
                        </div>
                      </div>
                      <div className="col-12">
                        <input
                          defaultValue={getNotificationItem("sms", "m")?.duration ?? ""}
                          className="form-control d-inline br-10 font-14  border--default shadow-none input"
                          style={{ width: "10%", marginRight: "1%" }}
                          id={"sms-mins-input"}
                          onChange={(e) => { handleChange(e) }}
                          required={getNotificationItem("sms", "m")?.active}
                        ></input>
                        <label className="form-check-label  font-14">
                          minutes before appointment time
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                : null
          }

          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-6">
              <div className="horizontal-rule" />
            </div>
          </div>

          <div className="row pt-3 justify-content-center">
            <div className="col-sm-12 col-md-6">
              {
                subscriptionPrivilegeGroups[ACCOUNT_SETTINGS]?.aps?.active === true ?
                  <>
                    <h2 className="font-20 text--terciary py-3">Signature</h2>
                    <p className="font-14 text--terciary">
                      Please upload or draw your signature to be added to the documents
                      listed below.
                    </p>
                    <div>
                      <button
                        type="button"
                        onClick={() => {
                          setOpenSignatureImagePad(!openSignatureImagePad);
                        }}
                        className={`btn btn-md btn-block d9e8ec--button px-3 br-10 font-12 fw-sb`}
                      >
                        {"Upload Image"}
                      </button>
                      &nbsp; &nbsp; &nbsp;
                      <button
                        type="button"
                        onClick={() => {
                          setOpenSignaturePad(!openSignaturePad);
                        }}
                        className={`btn btn-md btn-block d9e8ec--button px-3 br-10 font-12 fw-sb`}
                      >
                        {"Signature Pad"}
                      </button>
                    </div>
                    {(settings !== null && settings !== "" && imageURL !== undefined && imageURL !== null) || (trimmedDataURL !== null) ?
                      <div style={{ height: "180px" }} className="row py-2">
                        <div className="h-100 d-inline-block mt-1 bg--white border--default br-10 col-sm p-1 ms-2">
                          <div className="text-center align-content-center">
                            <img
                              className="w-100 br-10"
                              style={{ height: "150px", objectFit: "contain" }}
                              // src={"C:\\resources\\uploads\\signatures\\1129signature.jpg"}
                              src={imageURL !== undefined ? imageURL : trimmedDataURL !== null ? trimmedDataURL : settings?.signaturePath}
                            />
                          </div>
                        </div>
                      </div>
                      : ""}
                  </> : null
              }
              <div className="row py-1" hidden={subscriptionPrivilegeGroups[ACCOUNT_SETTINGS]?.usd?.active === false ? true : false}>
                <div className="col-md-4 col-sm-4">
                  <div className="form-check my-auto">
                    <input
                      className="form-check-input border-color-d9e8ec checkbox-border-width shadow-none"
                      type="checkbox"
                      name="printedRx"
                      id="printedRx"
                      onClick={(e) => {

                        setPrintedRx(e.target.checked);
                      }}
                      checked={printedRx}
                      disabled={isDisable}
                    />
                    <label
                      htmlFor="printedRx"
                      className="form-check-label text--terciary font-14"
                    >
                      Printed Rx
                    </label>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4">
                  <div className="form-check my-auto">
                    <input
                      className="form-check-input border-color-d9e8ec checkbox-border-width shadow-none"
                      type="checkbox"
                      onClick={(e) => {
                        setEncounterSum(e.target.checked);
                      }}
                      checked={encounterSum}
                      name="es"
                      id="es"
                      disabled={isDisable}
                    />
                    <label
                      htmlFor="es"
                      className="form-check-label text--terciary font-14"
                    >
                      Encounter Summary
                    </label>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4">
                  <div className="form-check my-auto">
                    <input
                      className="form-check-input border-color-d9e8ec checkbox-border-width shadow-none"
                      type="checkbox"
                      checked={afterVisitSum}
                      onClick={(e) => {
                        setAfterVisitSum(e.target.checked);
                      }}
                      disabled={isDisable}
                      name="avs"
                      id="avs"
                    />
                    <label
                      htmlFor="avs"
                      className="form-check-label text--terciary font-14"
                    >
                      After Visit Summary
                    </label>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4">
                  <div className="form-check my-auto">
                    <input
                      className="form-check-input border-color-d9e8ec checkbox-border-width shadow-none"
                      type="checkbox"
                      name="lo"
                      checked={labOrdersCheck}
                      id="lo"
                      // disabled={true}
                      onClick={(e) => { setLabOrdersCheck(e.target.checked) }}
                    />
                    <label
                      htmlFor="lo"
                      className="form-check-label text--terciary font-14"
                    >
                      Lab Orders
                    </label>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4">
                  <div className="form-check my-auto">
                    <input
                      className="form-check-input border-color-d9e8ec checkbox-border-width shadow-none"
                      type="checkbox"
                      name="io"
                      id="io"
                      checked={imagingOrders}
                      // disabled={true}
                      onClick={(e) => { setImagingOrders(e.target.checked) }}
                    />
                    <label
                      htmlFor="io"
                      className="form-check-label text--terciary font-14"
                    >
                      Imaging Orders
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-end">
            <div className="col-md-4 col-sm mb-3 me-5">
              <button
                disabled={isUpdatingNotifications}
                id={"settingForm"}
                type="submit"
                onClick={() => {
                  // authorizeNotifications();
                  // updateNotificationSettings();
                  // postSignatureData();
                }}
                className={`btn blue_task_button`}
              >
                {isUpdatingNotifications ? "Saving..." : "Save Changes"}
              </button>
            </div>
          </div>
        </div>
      </form>

      <CustomizedSnackbars
        message={"Something went wrong"}
        severity="error"
        open={errorStatus}
        handleClose={handleNotificationsToast}
      />

      {/* <CustomizedSnackbars
        message="Prescriber settings updated successfully"
        severity="success"
        open={uploadStatus}
        handleClose={handleNotificationsToast}
      /> */}

      <CustomizedSnackbars
        message={"Provider settings updated successfully"}
        severity="success"
        open={uploadStatus || isNotificationsUpdated}
        handleClose={handleNotificationsToast}
      />

      {/* {console.log("notifications",notifications)} */}
    </>
  );
};

export default SettingsPage;

import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import {
  addTimeHelper,
  changeDateTimeFormat,
  dateTimeHelper,
} from "../../utils/dateTimeHelper";
import classes from "./PatientApptRerveModal.module.css";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #D9E8EC",
  borderRadius: "10px",
  boxShadow: "0px 8px 16px #00000014",
  p: 4,
  justifyContent: "center",
};

const CheckPatientInsuredModal = (props) => {
  const navigate = useNavigate();
  // console.log(props)
  const [isInsured,setIsInsured] = useState();

  const handleInsuranceCheck = (value)=>{
    setIsInsured(value);
  }
  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.handleModal();
      }}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      backdropClassName="modal-backdrop"
      contentClassName={`modal-border modal-shadow `}
      scrollable={false}
      centered
    >
      <Modal.Header>
        <span
          style={{
            lineHeight: "12px",
            width: "18px",
            fontSize: "8pt",
            color: "#336383",
            position: "absolute",
            top: 15,
            right: 15,
            cursor: "pointer",
          }}
          onClick={() => {
            props.handleModal();
          }}
        >
          {<CloseIcon />}
        </span>
        <Modal.Title className="font-20 fw-sb">
          {"Insurance Confirmation"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid">
          <div className="row">
            <div className="col-8">{"Are you an insured patient?"}</div>
            <div className="col-4">
              <div className="form-group mt-1">
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    name={"insurance-check"}
                    id={"Yes"}
                    value={"Yes"}
                    className=" form-check-input me-1 cursor--pointer shadow-none"
                    // checked={}
                    onChange={(e) => handleInsuranceCheck(true)}
                  />
                  <label
                    htmlFor={"Yes"}
                    className="form-check-label cursor--pointer font-12 text--terciary"
                  >
                    {"Yes"}
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    name={"insurance-check"}
                    id={"No"}
                    value={"No"}
                    className=" form-check-input me-1 cursor--pointer shadow-none"
                    // checked={}
                    onChange={(e) => handleInsuranceCheck(false)}
                  />
                  <label
                    htmlFor={"No"}
                    className="form-check-label cursor--pointer font-12 text--terciary"
                  >
                    {"No"}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          disabled={isInsured === undefined}
          onClick={() => props.isPatientInsured(isInsured)}
          className="btn blue_task_button  "
        >
          {"Confirm"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default CheckPatientInsuredModal;

import { useCallback } from "react";
import { useState } from "react";
import { search } from "../hooks/sendHttpRequest";

export default function useNdcCodeSearch() {
    const [ndcCodeSuggestions, setNdcCodeSuggestions] = useState([]);
    const [isNdcCodeLoading, setIsNdcCodeLoading] = useState(false);
    const [isNdcCodeError, setIsNdcCodeError] = useState(false);
    const [ndcCodeSearchInputValue, setNdcCodeSearchInputValue] = useState("");
    const [ndcCodeName, setNdcCodeName] = useState("");
    const [selectedNdcCode, setSelectedNdcCode] = useState(undefined);

    const searchNdcCode = async (ndcCodeInput) => {
        setIsNdcCodeLoading(true);
        const httpResponse = await search({
            url: `/diagnosis`,
            method: "GET",
            params: {
                search: ndcCodeInput,
            },
        });
        if (!httpResponse.error) {
            setIsNdcCodeLoading(false);
            setNdcCodeSuggestions(httpResponse.data);
            setIsNdcCodeError(false);
        } else {
            if (httpResponse?.status === 400) {
                setIsNdcCodeError(true);
                setIsNdcCodeLoading(false);
            }
        }
    };

    const handleNdcCodeSearchInput = useCallback(
        (cptInput) => {
            if (cptInput.length > 2) {
                // setDiagnosisName(icdInput)
                searchNdcCode(cptInput);
                setNdcCodeSearchInputValue(cptInput);
            }
            setNdcCodeName(cptInput);
            setNdcCodeSuggestions([]);
            setIsNdcCodeLoading(false);
        },
        [
            searchNdcCode,
            setNdcCodeSearchInputValue,
            setNdcCodeName,
            setNdcCodeSuggestions,
            setIsNdcCodeLoading,
        ]
    );

    const handleRemoveNdcCode = (removeId) => {
        setSelectedNdcCode(undefined);
        setNdcCodeName("");
    };

    const handleAddNdcCode = (cptCode) => {
        setNdcCodeName("");
        setSelectedNdcCode(cptCode);
    };

    return {
        ndcCodeSuggestions,
        setNdcCodeSuggestions,
        isNdcCodeLoading,
        setIsNdcCodeLoading,
        isNdcCodeError,
        setIsNdcCodeError,
        ndcCodeSearchInputValue,
        setNdcCodeSearchInputValue,
        ndcCodeName,
        setNdcCodeName,
        selectedNdcCode,
        setSelectedNdcCode,
        searchNdcCode,
        handleNdcCodeSearchInput,
        handleAddNdcCode,
        handleRemoveNdcCode

    }
}
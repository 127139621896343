const EditIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" data-day={props.day} {...props} fill={props?.style?.color}>
            <g id="EditIcon" transform="translate(1 1)">
                <path id={props.id} dataName="Caminho 68" d="M11.946,6H4.988A1.988,1.988,0,0,0,3,7.988V21.9a1.988,1.988,0,0,0,1.988,1.988H18.9A1.988,1.988,0,0,0,20.891,21.9V14.946" transform="translate(-3 -3.891)" fill="none" stroke={props?.style?.color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                <path id={props.id}dataName="Caminho 69" d="M22.437,3.436a2.109,2.109,0,0,1,2.982,2.982L15.976,15.86,12,16.854l.994-3.976Z" transform="translate(-6.036 -2.818)" fill="none" stroke={props?.style?.color} stroke-linecap="round" strokeLinejoin="round" strokeWidth="2" />
            </g>
        </svg>
    );
};

export default EditIcon;
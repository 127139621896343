export const RolesMap = function (orignalRole) {
  const roles = new Map();
  roles.set("Physician", "doctor");
  roles.set("NursePractitioner", "nurse");
  roles.set("PracticeAdmin", "staff");
  roles.set("RegisteredNurse", "staff");
  roles.set("PhysicianAssistant", "staff");
  roles.set("Staff", "staff");
  roles.set("MedicalAssistant", "Medical Assistant");
  roles.set("PhysicianAssistant/NursePractitioner", "Physician Assistant / Nurse Practitioner");

  return roles.get(orignalRole);
};

export const RolesTextConversion = function (orignalRole) {
  const roles = new Map();
  roles.set("Physician", "Physician");
  roles.set("NursePractitioner", "Nurse Practitioner");
  roles.set("PracticeAdmin", "Practice Admin");
  roles.set("RegisteredNurse", "Registered Nurse");
  roles.set("PhysicianAssistant", "Physician Assistant");
  roles.set("Staff", "Staff");
  roles.set("patient", "Patient");
  roles.set("BillingSpecialist", "Billing Specialist");
  roles.set("MedicalAssistant", "Medical Assistant");
  roles.set("PhysicianAssistant/NursePractitioner", "Physician Assistant / Nurse Practitioner");
  return roles.get(orignalRole?.trim());
};

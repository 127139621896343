import { Backdrop, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { useAuth } from "../hooks/useAuth";

const TenantInvite = () => {

  const navigate = useNavigate();
  const { user } = useAuth();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [searchParams] = useSearchParams();
  const key = searchParams.get("key");

  const handleToggle = () => {
    setOpenBackdrop(!openBackdrop);
  };

  const tenantInvite = async () => {
    handleToggle();
    const httpResponse = await sendHttpRequest({
      url: "/provider/new-tenant",
      method: "GET",
      params: {
        key: key,
        // userId: user?.userId
      }
    });
    if (!httpResponse.error) {
      navigate("/dashboard");
    } else {
      // return false;
    }
    handleToggle();
  }

  useEffect(() => {
    console.log(searchParams.get("key"));
    console.log(key);
    if (user === null || user === undefined) {
      navigate("/login", {replace:true, state:{code:searchParams.get("key")}});
    } else {
      tenantInvite();
    }
    
    return () => { };
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: "#EC609B", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
};

export default TenantInvite;

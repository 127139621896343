import { useCallback } from "react";
import { useState } from "react";
import ProviderNoteService from "../Services/ProviderNote.service";
import { useToggle } from "./useToggle";

const useProviderNoteDiagnosis = () => {
    const [openDiagnosisModal, setOpenDiagnosisModal] = useState(false);
    const [openDiagnosisSnackbar, setOpenDiagnosisSnackBar] = useState(false);
    const [diagnosisAddedMessage, setDiagnosisAddedMessage] = useState("");
    const [diagnosisSeverity, setDiagnosisSeverity] = useState("");
    const [callApi, setCallApi] = useToggle(false);
    const [openDeleteDiagnosisModal, setOpenDeleteDiagnosisModal] = useState(false);
    const [deletedDiagnosis, setDeletedDiagnosis] = useState(undefined); //temp object to hold deleted diagnosis object
    const [deleteDiagnosisLoading, setDeleteDiagnosisLoading] = useState(false);
    const [diagnosesData, setDiagnosesData] = useState([]);

    const handleDiagnosisModal = useCallback(() => {
        setOpenDiagnosisModal(prevIsOpen => !prevIsOpen);
    }, []);


    const handleDeleteDiagnosisModal = useCallback(function (diagnosisObject) {
        setDeletedDiagnosis(diagnosisObject);
        setOpenDeleteDiagnosisModal(prevIsOpen => !prevIsOpen);
    }, [setDeletedDiagnosis, setOpenDeleteDiagnosisModal]);

    const handleDiagnosisSnackBarClose = useCallback((event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenDiagnosisSnackBar(prevState => !prevState);
    }, [setOpenDiagnosisSnackBar]);

    const deleteDiagnosis = async (diagnosis,soapNoteKey) => {
        if(soapNoteKey === true){
            let result = diagnosesData.filter((item)=>{return item?.code !== diagnosis?.code})
            setDiagnosesData(result);
            setOpenDeleteDiagnosisModal(p => !p);
        }else{
            setDeleteDiagnosisLoading(p => true);
            const res = await ProviderNoteService.deleteDiagnosisFromProviderNote(diagnosis);
            setOpenDeleteDiagnosisModal(p => !p);
            setCallApi(p => !p);
            setDeleteDiagnosisLoading(p => false);
        }


    }
    //====Delete Diagnosis code====END====// 


    //====Edit Diagnosis Code START=====//
    const [openEditDiagnosisModal, setOpenEditDiagnosisModal] = useState(false);
    const [editedDiagnosis, setEditedDiagnosis] = useState(undefined);
    const [isDiagnosisUpdating, setIsDiagnosisUpdating] = useState(false);
    const handleEditDiagnosisModal = useCallback(function (diagnosisObject) {
        setEditedDiagnosis(diagnosisObject);

        setOpenEditDiagnosisModal(prevIsOpen => !prevIsOpen);
    }, [setEditedDiagnosis, setOpenEditDiagnosisModal]);

    const updateDiagnosis = async (d,soapNoteKey) => {
        if(soapNoteKey){
            // console.log("editedDiagnosis: ",editedDiagnosis);
            let result = diagnosesData.filter((item)=>{return item.code !== d.code && item.code !== editedDiagnosis?.code})
            setDiagnosesData([...result,d]);
            setOpenEditDiagnosisModal(prevIsOpen => !prevIsOpen);
        }else{
            setIsDiagnosisUpdating(true);
            const res = await ProviderNoteService.updateDiagnosisInProviderNote(d);
            setOpenEditDiagnosisModal(prevIsOpen => !prevIsOpen);
            setCallApi(prevToggle => !prevToggle);
        }
        setIsDiagnosisUpdating(false);
    }



    return {
        openDiagnosisModal,
        setOpenDiagnosisModal,
        handleDiagnosisModal,
        openDiagnosisSnackbar,
        handleDiagnosisSnackBarClose,
        diagnosisAddedMessage,
        setDiagnosisAddedMessage,
        diagnosisSeverity,
        setDiagnosisSeverity,
        callApi,
        setCallApi,
        openDeleteDiagnosisModal,
        handleDeleteDiagnosisModal,
        openEditDiagnosisModal,
        handleEditDiagnosisModal,
        setOpenEditDiagnosisModal,
        setOpenDeleteDiagnosisModal,
        deleteDiagnosisLoading,
        deletedDiagnosis,
        deleteDiagnosis,
        editedDiagnosis,
        setEditedDiagnosis,
        isDiagnosisUpdating,
        updateDiagnosis,
        setOpenDiagnosisSnackBar,
        diagnosesData, 
        setDiagnosesData
    }
}

export default useProviderNoteDiagnosis;
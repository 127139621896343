import { Modal } from "react-bootstrap";
import classes from './CancelAppointmentModal.module.css';
import CloseIcon from "@mui/icons-material/Close";
import PatientsIcon from "../Drawer/DrawerItemIcons/PatientsIcon";
import AppointmentsIcon from "../Drawer/DrawerItemIcons/AppointmentsIcon";
import sendHttpRequest from "../../hooks/sendHttpRequest";
import { dateTimeHelper } from "../../../utils/dateTimeHelper";
const CancelAppointmentModal = (props) => {

  const cancelAppointmentsRequest = async (appointmentId) => {
    props.setIsAppointmentCancelled(false);
    props.setIsAppointmentCancelling(true);
    const httpResponse = await sendHttpRequest({
      url: `/appointment/${appointmentId}/cancel`,
      method: "POST",
    });
    if (!httpResponse.error) {
      let response = httpResponse.data;
      props.handleCancelModalClose();
      props.fecthAppointments(dateTimeHelper(props.date, "YYYY-MM") ?? "");
      props.setIsAppointmentCancelled(true);
      if (props.currentCellAppointments !== undefined) {
        const remainingAppointments = props.currentCellAppointments.filter((app) => app.id !== appointmentId);
        props.setCurrentCellAppointments(() => [...remainingAppointments]);
      }
    } else {
      props.setAppointmentCancellationFail(true);
      props.setApppointmentCancellationError(httpResponse?.errorMessage?.message ?? "Something went wrong");
    }
    props.setIsAppointmentCancelling(false);
  };

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.handleCancelModalClose();
      }}
      size="md"
      contentClassName={`modal-border modal-shadow ${classes['modal__border']} ${classes['cancelmodal__size']}`}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdropClassName={`modal-backdrop ${classes['modal__backdrop']}`}
      backdrop="static"
    >
      <Modal.Header style={{ border: "none" }}>
      <div className="row ">
            <div className="col-12 ">
              <span
                style={{
                  lineHeight: "12px",
                  width: "18px",
                  fontSize: "8pt",
                  color: "#336383",
                  position: "absolute",
                  top: 15,
                  right: 15,
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.handleCancelModalClose();
                }}
              >
                {<CloseIcon />}
              </span>
            </div>
          </div>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid">
        
        </div>
        <div className="container">
          <div className="row text-center justify-content-center">
            <div className="col-12">
              <h2 className="font-20 m-0 text--terciary">Cancel Appointment</h2>
            </div>
          </div>
          <div className="row mt-5 justify-content-center">
            <div className="col-2 text-end  align-self-center">
              <PatientsIcon style={{marginLeft:'32px',display:'block',color:'#EC609B'}} />
            </div>
            <div className="col-8">
            <p className="text--secondary font-14 m-0">
                Patient: <span className="fw-sb">{props?.firstName?.concat(" ", props?.lastName)}</span>
              </p>
              <p className="text--secondary font-14 m-0">
                Provider: <span className="fw-sb">{props?.prescriberPrefix}{" "}{props?.prescriberFirstName}{" "}{props?.prescriberLastName}</span>
              </p>
            </div>
          </div>
          <div className="row my-2 justify-content-center">
                <div className="col-12">
                <div className={`mx-auto ${classes['cancelmodal__hr']}`}/>
                </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-2 text-end  align-self-center">
              <AppointmentsIcon  style={{marginLeft:'32px', display:'block',color:'#EC609B'}} />
            </div>
            <div className="col-8">
            <span className="d-block font-14 text--secondary">
                  {props?.date}
                </span>
                <span className="d-block font-14 text--secondary">
                  <span className="fw-sb">{props?.startDateTime}</span> &ndash; {props?.endTime}
                </span>
            </div>
          </div>

         
              <div className="d-grid text-center gap-1 mt-5 mb-2 ">
                <div className="g-col-12">
                 <button onClick={()=>props.handleCancelModalClose()} className={`${props?.isAppointmentCancelling ? 'disabled' : ''} ${classes['modal__button']} btn bg--d9e8ec  text--blue font-14 fw-sb ${classes["modal__button"]}`}>Keep Appointment</button>

                </div>
                <div className="g-col-12">
                <button onClick={()=>{cancelAppointmentsRequest(props?.appointmentId);}} className={` ${props?.isAppointmentCancelling ? 'disabled' : ''} ${classes['modal__button']} btn shadow-none bg--red text-white font-14 fw-sb ${classes["modal__button"]}`}>{props?.isAppointmentCancelling ? 'Canceling...' : 'Cancel Appointment'}</button>
                
                </div>
              </div>
          
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CancelAppointmentModal;

import React, { useEffect, useState } from "react";
import {
  client as braintreeClient,
  googlePayment as googlePayInstance,
} from "braintree-web";
import { getPaymentToken, transferAppointmentPayment, transferAppointmentWithPayment, transferPatientPayment, transferPayment } from "../../../res/endpointurls";
import { useAuth } from "../../hooks/useAuth";
import sendHttpRequest from "../../hooks/sendHttpRequest";
import gpay from "../../../assets/images/gpay.png";
import googleLogo from "../../../assets/logo/google-logo.png";
import CurrencyInput from "react-currency-input-field";
import { Backdrop, CircularProgress } from "@mui/material";
import CustomizedSnackbars from "../../UI/General/CustomizedSnackbars";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const GooglePayButton = ({
  setIsPaymentSent,
  isPaymentSent,
  amountValue,
  prescribersList,
  userId,
  fetchBillingHistory,
  fetchCurrentBill,
  billingProvider,
  patientPortal,
  appointmentId,
  timeExpired,
  appointmentDto,
  isCopPay,
  handleModal,
  isValidAmount,
  isPatientPayment

}) => {
  const patientInsuranceData = useSelector((state)=>state.patientInsuranceData.patientInsurance);
  
  const { user } = useAuth();
  const [googlePaymentInstance, setGooglePaymentInstance] = useState(null);
  const [paymentsClient, setPaymentsClient] = useState(null);
  const [isGooglePayReady, setIsGooglePayReady] = useState(false);
  const [amountInput, setAmountInput] = useState(amountValue);
  const [provider, setProvider] = useState();

  const [token, setToken] = useState("");
  const [error, setError] = useState("");
  const [tokenizeErr, setTokenizeErr] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [open, setOpen] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [isCopPayment,setIsCopPayment] = useState(false);
  const location = useLocation();


  async function getToken() {
    const httpResponse = await sendHttpRequest({
      // url: `/braintree/${userId}/get_token`,
      url: getPaymentToken(user?.tenantId),
      method: "GET",
    });
    if (!httpResponse.error) {
      return httpResponse.data;
      //   hostedForm(httpResponse.data);
      // braintreeClient(httpResponse.data)
      // setIsApptCreated(true);
      // timeExpired();
    } else {
      setError(httpResponse.errorMessage);
      // console.log(httpResponse);
    }
    // getExtras();
  }

  const sendNounce = async (nounce) => {

    console.log("userId ", user?.userId);
    
    var url = isPatientPayment !== undefined && isPatientPayment === true ? transferPatientPayment(userId) : appointmentDto !== undefined ? transferAppointmentWithPayment(user?.tenantId) : appointmentId !== undefined ? transferAppointmentPayment(appointmentId)  : transferPayment(user?.tenantId);

    const httpResponse = await sendHttpRequest({


      url: url,
      method: "POST",
      data: {
        nounce: nounce,
        providerId: provider,
        userId: userId,
        amount: amountInput,
        // nameOnCC:"Zeeshan",
        transactionType: "GooglePay",
        ...(appointmentId !== undefined && {appointmentId:appointmentId}),
        ...(appointmentDto !== undefined && {appointmentDto:appointmentDto}),
        copay:isCopPayment
      },
    });
    if (!httpResponse.error) {
      if(patientPortal !== true){
        fetchBillingHistory(true, {
          description: "Paid via Google Pay",
          due: amountInput,
        });
        setIsPaymentSent(true);
        setAmountInput(0);
        fetchCurrentBill();
      }else if(patientPortal === true){
        if(setIsPaymentSent !== undefined){
          setIsPaymentSent(true)
        }
        if(timeExpired){
          timeExpired();
        }
        if(handleModal){
          handleModal();
        }
      }
    } else {
      if (httpResponse?.status === 500) {
        setError({ message: "Something went wrong", show: true });
      } else {
        setError(
          { ...httpResponse?.errorMessage, show: true } ?? {
            message: "Payment not completed",
            show: true,
          }
        );
      }
    }
    // setIsSending(false);
    // setIsTimeOver(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSent(false);
    setError({ show: false });
  };
  const loadGooglePayScript = () => {
    return new Promise((resolve, reject) => {
      if (document.getElementById("google-pay-script")) {
        resolve();
        return;
      }
      const script = document.createElement("script");
      script.id = "google-pay-script";
      script.src = "https://pay.google.com/gp/p/js/pay.js";
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });
  };
  const initializeBraintree = async () => {
    try {
      setAmountInput(amountValue);
      await loadGooglePayScript();
      let token = await getToken();
      // let token = await brainTreeToken;

      const clientInstance = await braintreeClient.create({
        authorization: token, // Replace with your actual token
      });

      const googlePaymentInstance = await googlePayInstance.create({
        client: clientInstance,
        googlePayVersion: 2,
        // googleMerchantId: "12345678901234567890", // Replace with your actual merchant ID
        googleMerchantId: process.env.REACT_APP_GPAY_MERCHANT_ID,
      });

      setGooglePaymentInstance(googlePaymentInstance);
      const paymentsClientInstance =
        new window.google.payments.api.PaymentsClient({
          environment: process.env.REACT_APP_GPAY_ENV, // Change to 'PRODUCTION' when going live
        });

      setPaymentsClient(paymentsClientInstance);

      const isReadyToPay = await paymentsClientInstance.isReadyToPay({
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods:
          googlePaymentInstance.createPaymentDataRequest()
            .allowedPaymentMethods,
        existingPaymentMethodRequired: true,
      });

      if (isReadyToPay.result) {
        setIsGooglePayReady(true);
      }
    } catch (error) {
      console.error("Error initializing Braintree and Google Pay:", error);
    }
  };
  

  const handlePayment = async (event) => {
    event.preventDefault();
    setIsSending(true);

    if (!googlePaymentInstance || !paymentsClient) {
      console.error("Google Pay or Braintree is not initialized");
      return;
    }

    try {
      const paymentDataRequest = googlePaymentInstance.createPaymentDataRequest(
        {
          transactionInfo: {
            currencyCode: "USD",
            totalPriceStatus: "FINAL",
            totalPrice: amountInput.toString(),
          },
        }
      );

      const paymentData = await paymentsClient.loadPaymentData(
        paymentDataRequest
      );

      const paymentMethodNonce = await googlePaymentInstance.parseResponse(
        paymentData
      );
      console.log("Payment Method Nonce:", paymentMethodNonce.nonce);
      sendNounce(paymentMethodNonce.nonce);
    } catch (error) {
      console.error("Error handling Google Pay payment:", error);
    }
    // setIsSending(false);
  };

  const handleAmountInput = (amount)=>{
    if(Number(amount) > 0){
      setAmountInput(amount)
    }
  }

  useEffect(() => {
    initializeBraintree();
  }, []);

  useEffect(()=>{
    setAmountInput(pre=>parseFloat(amountValue).toFixed(2));
    setProvider(billingProvider);
    setIsCopPayment(isCopPay)
  },[amountValue,isCopPay])
  return (
    <div className="row">
      {isGooglePayReady ? (
        <>
          <div className="col-6" hidden={patientPortal}>
            <label htmlFor="amount" className="font-12 text--secondary">
              {"Amount"}
            </label>
            <CurrencyInput
              id="cash"
              name="cash"
              placeholder="$0.00"
              className="text--terciary text-end font-12 input form-control shadow-none border--default br-10"
              value={amountInput}
              // decimalsLimit={2}
              // fixedDecimalLength={2}
              prefix="$"
              decimalSeparator="."
              groupSeparator=","
              intlConfig={{ locale: "en-US", currency: "USD" }}
              onValueChange={(value, name) => setAmountInput(value)}
              align={"right"}
              // disabled = {true}
            />
          </div>
          <div className="col-6" hidden={patientPortal}>
            <label htmlFor="provider" className="font-12 text--secondary">
              {"Provider"}
            </label>
            <select
              className={`form-select font-12 border--default br-10 shadow-none input`}
              aria-label="action select"
              style={{
                border: "1px solid #D9E8EC",
                //   width: `${props.tasksOnly ? "100%" : "80%"}`,
                height: "36px",
                // fontStyle:"italic"
              }}
              onChange={(e) => setProvider(e.target.value)}
              defaultValue={billingProvider}
              required={true}
            >
              <option
                className="text--terciary"
                value={""}
                key={1}
                selected
                disabled
                hidden
              >
                {"Select Provider"}
              </option>
              {prescribersList?.length > 0 &&
                prescribersList.map((prescriberItem) => {
                  return (
                    <option
                      key={prescriberItem?.userId}
                      id={prescriberItem?.userId}
                      value={prescriberItem?.userId}
                      selected={prescriberItem?.userId === provider}
                    >
                      {"".concat(
                        prescriberItem?.name[0]?.prefix || "",
                        " ",
                        prescriberItem?.name[0]?.firstName || "",
                        " ",
                        prescriberItem?.name[0]?.lastName
                      )}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="col-12 ">
            <lable>&nbsp;</lable>
            <button
              style={{
                backgroundColor: "#555",
                width: "100%",
                height: "2.9em",
              }}
              id="google-pay"
              onClick={handlePayment}
              className=" font-12 br-10 bg--black text--white fw-sb"
              disabled = {(Number(amountInput) <= 0 || isNaN(Number(amountInput))) ? true : patientPortal ? !isValidAmount  : provider === undefined}
              hidden={patientPortal ? isSending :false}
            >
              <span className="me-1">{"Pay with"}</span>
              <img src={googleLogo} style={{ height: "2em", width: "2em" }} />
              <span>{"Pay"}</span>
            </button>
          </div>
        </>
      ) : (
        <p className="font-12 text--terciary">Loading Google Pay...</p>
      )}
      <div id="container"></div>

      <CustomizedSnackbars
        message={"Payment Received"}
        severity="success"
        open={isSent}
        handleClose={handleClose}
      />
      <CustomizedSnackbars
        message={error?.message}
        severity="error"
        open={error?.show}
        handleClose={handleClose}
      />
      {/* {console.log("location: ",location)} */}
      {console.log("Number(amountInput) <= 0: ",Number(amountInput) <= 0,amountInput)}
    </div>
  );
};

export default GooglePayButton;

import { Box, Grid, Stack, Typography, IconButton } from "@mui/material";
import React, { useState } from "react";
import classes from "./PatientItem.module.css";
import EditButtonIcon from "../../assets/icons/Icon-Edit.svg";
import TrashIcon from "../../assets/icons/TrashIcon";
import { RolesMap, RolesTextConversion } from "../../utils/helpers/RolesMap";
import { formatPhoneNumber } from "../../utils/helpers/HelperFunctions";
import { useNavigate } from "react-router-dom";
import LockResetIcon from "@mui/icons-material/LockReset";
import InitiateResetPasswordModal from "../UI/Modals/ProviderModals/InitiateResetPasswordModal";
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";
import { useSelector } from "react-redux";
import { PRACTICE_MANAGEMENT } from "../../utils/helpers/subscriptionsConstants";

const FacilityItem = (props) => {
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);


  const navigate = useNavigate();

  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [pwdResetRequestResponse, setPwdResetRequestResponse] = useState("");
  const [openPwdResetToast, setOpenPwdResetToast] = useState(false);

  const initiatePasswordReset = () => {
    setOpenResetPasswordModal(true);
  };
  const handleResetPasswordModal = () => {
    setOpenResetPasswordModal(!openResetPasswordModal);
  };
  const handlePasswordResetToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenPwdResetToast(false);
  };

  return (
    <>
      <Grid
        sx={{ marginBottom: "-8px" }}
        container
        p={1}
        alignItems="center"
        className={`${classes["row--border"]} mt-2 ${props?.isEven && "bg--background"
          } ${props?.isLast && classes["border--bottom"]}`}
      >
        <Grid item xs={3.5}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography component="div" style={{ overflowWrap: "break-word" }}>
              <Box
                sx={{
                  textAlign: "left",
                  m: 1,
                  alignItems: "center",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "12px",
                  color: "#323232",
                }}
              >
                {props?.facility?.businessName}
                {props?.facility?.primary && (
                  <span className="badge ms-3 font-10 fw-sb text--secondary bg--d9e8ec px-2 br-10">Primary</span>
                )}
              </Box>
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={3.5}>
          <Typography component="div" style={{ overflowWrap: "break-word" }}>
            <Box
              sx={{
                textAlign: "left",
                // ml: -1,
                alignItems: "center",
                fontFamily: "Montserrat",
                fontSize: "12px",
                color: "#336383",
              }}
            >
              {/* Physician Assistant */}
              {props?.facility?.addressLine1}{" "}
              {props?.facility?.city !== undefined && props?.facility?.city !== "" && props?.facility?.city}{", "}
              {props?.facility?.state}{" "}
              {props?.facility?.zipCode}{" "}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography component="div" style={{ overflowWrap: "break-word" }}>
            <Box
              sx={{
                textAlign: "left",
                // ml: -4,
                alignItems: "center",
                fontFamily: "Montserrat",
                fontSize: "12px",
                color: "#336383",
              }}
            >
              {formatPhoneNumber(props?.facility?.phoneNumber) !== null
                ? formatPhoneNumber(props?.facility?.phoneNumber)
                : ""}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography component="div" style={{ overflowWrap: "break-word" }}>
            <Box
              sx={{
                textAlign: "left",
                fontWeight: "400",
                // ml: 5,
                alignItems: "center",
                fontFamily: "Montserrat",
                fontSize: "12px",
                color: "#336383",
              }}
            >
              {formatPhoneNumber(props?.facility?.faxNumber) !== null
                ? formatPhoneNumber(props?.facility?.faxNumber)
                : ""}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Box>
            <div className="align-self-center ">
              <div className="d-flex flex-row col-10 justify-content-end">
                <div className="row">
                  <div className="col-4">
                    <IconButton className="p-0">
                      <img
                        className="cursor--pointer"
                        onClick={(e) => {
                          navigate("/edit-facility", {
                            state: props?.facility,
                          });
                        }}
                        width={15}
                        height={15}
                        id={props?.prescriber?.Id}
                        src={EditButtonIcon}
                        alt="edit icon"
                      ></img>
                    </IconButton>
                  </div>
                  <div className="col-4" hidden={subscriptionPrivilegeGroups[PRACTICE_MANAGEMENT]?.dtf?.active === false ? true : false}>
                    <IconButton className="p-0">
                      <TrashIcon
                        style={{ color: "#A2D1DE" }}
                        width={15}
                        height={15}
                      />
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Grid>
      </Grid>

      {/* {console.log(props?.prescriber)} */}
    </>
  );
};

export default FacilityItem;

import { useNavigate } from "react-router";
import { useAuth } from "../../../../components/hooks/useAuth";
import { useEffect, useRef, useState } from "react";
import {
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    TableSortLabel,
} from "@mui/material";
import useOnClickOutside from "../../../../components/hooks/useOnClickOutside";
import RedeemIcon from '@mui/icons-material/Redeem';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PrintIcon from "@mui/icons-material/Print";
import { calenderDateTimeHelper } from "../../../../utils/dateTimeHelper";
import TrashButtonIcon from "../../../../assets/icons/Icon-Trash.svg";
import TrashButtonIconDisabled from "../../../../assets/icons/Icon-Trash-Disabled.svg";
import { PnToolTip } from "../../../../components/UI/Reusable/PnComponents";
import useModal from "../../../../components/hooks/useModal";
import DeletePatientEncounterConfirmationModal from "../UI/Modals/DeletePatientEncounterConfirmationModal";
import { EditIconComponent } from "../../../../assets/icons/EditIconComponent";
import { useSelector } from "react-redux";

function ClaimPatientEncountersTable({ columnHeadersList, data, navigateTo, switchView, setEncounterToEdit, setEditMode, setReloadEncounters }) {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [isPrinting, setIsPrinting] = useState(false);
    const [disabledItem, setDisabledItem] = useState()
    const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);


    const [sortBy, setSortBy] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [isOpen, setIsOpen] = useState(false);
    const [filterList, setFilterList] = useState([]);
    const toggleOpen = () => setIsOpen(!isOpen);
    const menuClass = `dropdown-menu br-7 p-2 bg--white ${isOpen ? " show" : ""}`;
    const dropdownRef = useRef();
    useOnClickOutside(dropdownRef, () => setIsOpen((p) => false));
    const [uniqueProvidersList, setUniqueProvidersList] = useState([])
    const [sortedData, setSortedData] = useState([]);

    const { open: openDeleteEncounterModal, handleModal: handleDeleteEncounterModal } = useModal(false);
    const [deleteEncounterId, setDeleteEncounterId] = useState(null);

    const handleSort = (column) => {
        // console.log("column: ", column);
        if (sortBy === column) {
            setSortDirection(() => sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy((p) => column);
            setSortDirection((p) => 'asc');
        }
    };
    const getValue = (item, column) => {
        const keys = column.split('.');
        let value = item;
        for (let key of keys) {
            if (key === "provider.firstName") {
                value = value["firstName"][0];
            } else if (value.hasOwnProperty(key)) {
                value = value[key];
            } else {
                return null;
            }

        }
        return value;
    };
    const sortData = () => {
        return data.slice().sort((a, b) => {
            if (sortBy) {
                const aValue = getValue(a, sortBy);
                const bValue = getValue(b, sortBy);

                // console.log("aValue,bValue", aValue, bValue);
                if (aValue < bValue) {
                    return sortDirection === 'asc' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortDirection === 'asc' ? 1 : -1;
                }
                return 0;
            }
            return 0;
        });
    }




    const handleFilterChecks = (event) => {
        if (event.target.checked) {
            const tempList = [...filterList];
            tempList.push(event.target.id);
            setFilterList(tempList);
        } else {
            const dummy = filterList.filter((uid) => uid !== event.target.id);
            setFilterList(dummy);
        }
    };
    const handleClearFiltering = () => {
        setFilterList([]);
        setIsOpen(!isOpen);
        sortedData.forEach((item, index) => {
            let providerId = item?.providerTenantPracticeLocation.provider?.userId;
            document.getElementById(providerId).checked = false;
        });
        setSortedData(() => sortData());
        // fetchAllAttachments();
    };
    const handleFiltering = () => {
        setIsOpen(!isOpen);
        let filterData = [];
        if (filterList?.length == 0) {
            setSortedData(sortData());
        } else {
            filterList.forEach(filterItem => {
                filterData.push(...data.filter((item) => { return item?.providerTenantPracticeLocation?.provider?.userId === Number(filterItem) }));
            });
            // console.log("filterList: ", filterList);
            // console.log("filterData: ", filterData);
            setSortedData(() => filterData)
        }
        // sortedData = data.filter((item)=>{ item?.providerTenantPracticeLocation?.provider?.userId})
        // fetchAttachments();
    };

    useEffect(() => {
        setSortedData(() => sortData())
    }, [sortBy, sortDirection])


    useEffect(() => {
        const uniqueObjects = data.filter((item, index, arr) => {
            // console.log("Item", item);
            const firstName = item.provider.firstName;
            if (!firstName) return false; // Skip items without a firstName
            return arr.findIndex(obj => obj.provider.firstName === firstName) === index;
        });
        setSortedData(sortData());
        setUniqueProvidersList(uniqueObjects)
    }, [data]);
    return (
        <>
            {openDeleteEncounterModal ? <DeletePatientEncounterConfirmationModal show={openDeleteEncounterModal} handleModal={handleDeleteEncounterModal} encounterId={deleteEncounterId} setReloadEncounters={setReloadEncounters} /> : null}
            <TableContainer sx={{ borderRadius: "10px", border: "1px solid #D9E8EC", maxHeight: 440 }} className="hide-scrolbar">
                <Table stickyHeader aria-label="sticky table">
                    <TableHead sx={{ borderTopColor: "#FAFCFC", borderRightColor: "#FAFCFC", borderLeftColor: "#FAFCFC", borderBottom: "1px solid #D9E8EC" }}>
                        <TableRow>

                            {columnHeadersList?.map((item, idx) => {
                                return <TableCell key={item?.key?.concat(idx)}>
                                    <TableSortLabel
                                        // active={sortBy === item?.key}
                                        direction={sortBy === item?.key ? sortDirection : 'asc'}
                                        onClick={() => handleSort(item?.key)}
                                        hideSortIcon={true}
                                    >
                                        <span className="font-12 text--secondary">{item?.value}</span>
                                        {/* Conditional rendering of custom-colored icon */}
                                        {/* {console.log(item?.key)} */}
                                        {item?.key === "provider.firstName" || item?.key === "encounterMetadata.dateOfServiceTo" ? (<span style={{ color: "#EC609B" }}> {/* Change the color here */}
                                            {sortDirection === 'desc' && sortBy === item?.key ? (
                                                <KeyboardArrowDownIcon />
                                            ) : (
                                                <KeyboardArrowUpIcon />
                                            )}
                                        </span>) : null}

                                    </TableSortLabel>
                                </TableCell>
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData.map((item, idx) => {
                            // console.log(item);

                            const keys = Object.keys(item);
                            let firstName = item?.provider?.firstName;
                            let lastName = item?.provider?.lastName;
                            let prefix = item?.provider?.prefix;

                            return (
                                <TableRow key={idx} sx={{ "&:last-child td, &:last-child th": { border: 0 }, }}>
                                    <TableCell sx={{ color: "#336383" }}>
                                        <span className="font-12">{"".concat(prefix ?? "", " ", firstName, " ", lastName)}</span>
                                    </TableCell>
                                    <TableCell sx={{ color: "#336383" }}>
                                        <span className="font-12">{calenderDateTimeHelper(item?.encounterMetadata?.dateOfServiceTo, "MMM dd, yyyy")}</span>
                                    </TableCell>

                                    <TableCell sx={{ color: "#336383" }}>
                                        <span className="font-12">{item?.encounterMetadata?.serviceLocation}</span>
                                    </TableCell>
                                    <TableCell sx={{ color: "#336383" }}>
                                        <span className="font-12">{item?.encounterMetadata?.placeOfService}
                                        </span>                                    </TableCell>
                                    <TableCell sx={{ color: "#336383" }}>
                                        <span className="font-12">{item?.diagnosis?.at(0)?.code}</span>
                                    </TableCell>
                                    <TableCell sx={{ color: "#336383" }}>
                                        <span className="font-12">{calenderDateTimeHelper(item?.dateOfCurrentIllnessOrSymptom, "MMM dd, yyyy")}</span>
                                    </TableCell>
                                    <TableCell sx={{ color: "#336383" }}>
                                        <span className="font-12">{item?.claimSubmitted === true ? "Claim Submitted" : "Claim Pending"}</span>
                                    </TableCell>

                                    <TableCell sx={{ color: "#336383" }}>

                                        <div className="d-flex gap-2">
                                            <div onClick={() => {
                                                if (item?.claimSubmitted === false) {
                                                    setEditMode(p => true);
                                                    switchView();
                                                    setEncounterToEdit(p => item);
                                                }
                                            }}
                                                className={`${item?.claimSubmitted === true ? 'cursor--disabled' : 'cursor--pointer'} `}
                                                hidden={subscriptionPrivilegeGroups['Billing & Claims'] !== undefined && subscriptionPrivilegeGroups['Billing & Claims']?.bee?.active === true ? false : true}

                                            >
                                                <PnToolTip title={`${item?.claimSubmitted === true ? '' : 'Edit Encounter'}`}>
                                                    <EditIconComponent
                                                        alt={"editIcon"}
                                                        id="encounter-edit"
                                                        style={{ color: `${item?.claimSubmitted === true ? '#bcd6da' : '#336383'}`, width: "15px", height: "15px" }}
                                                    ></EditIconComponent>
                                                </PnToolTip>
                                            </div>
                                            <div onClick={() => {
                                                if (item?.claimSubmitted === false) {
                                                    setDeleteEncounterId(item?.encounterId);
                                                    handleDeleteEncounterModal();
                                                }
                                            }}
                                                className={`${item?.claimSubmitted === true ? 'cursor--disabled' : 'cursor--pointer'}`}
                                                hidden={subscriptionPrivilegeGroups['Billing & Claims']?.bde?.active === true ? false : true}
                                            >
                                                <PnToolTip title={`${item?.claimSubmitted === true ? '' : 'Delete Encounter'}`}>
                                                    {item?.claimSubmitted === true ? <img
                                                        src={TrashButtonIconDisabled}
                                                        alt={"deleteIconDisabled"}
                                                        id="encounter-delete-disabled"
                                                        style={{ width: "15px", height: "15px" }}
                                                    ></img> : <img
                                                        src={TrashButtonIcon}
                                                        alt={"deleteIcon"}
                                                        id="encounter-delete"
                                                        style={{ width: "15px", height: "15px" }}
                                                    ></img>}
                                                </PnToolTip>
                                            </div>

                                            {/* <div>
                                                {item?.claimSubmitted === false ? <PnToolTip title="Prepare Claim Submission">

                                                    <RedeemIcon
                                                        onClick={() => { navigate('/patient-claim-submit', { state: { encounter: item } }) }}
                                                        fontSize="small"
                                                        style={{ color: "#336383" }}
                                                        className="cursor--pointer font-12 disabled"
                                                    />

                                                </PnToolTip> : <IconButton >
                                                    <PrintIcon
                                                        // onClick={() => { printBillReceipt(item?.patientBillId); }}
                                                        style={{ color: "#336383" }}
                                                        className="cursor--pointer disabled"
                                                    />
                                                </IconButton>}
                                            </div> */}

                                            <div >
                                                {item?.claimSubmitted === false ? <PnToolTip title="Prepare Claim Submission">

                                                    <RedeemIcon
                                                        onClick={() => { navigate('/patient-claim-submit', { state: { encounter: item } }) }}
                                                        fontSize="small"
                                                        style={{ color: "#336383" }}
                                                        className="cursor--pointer font-12 disabled"
                                                    />

                                                </PnToolTip> : null}
                                            </div>

                                        </div>


                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default ClaimPatientEncountersTable;
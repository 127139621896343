import React, { useState, useEffect, useRef } from "react";
import Skeleton from "@mui/material/Skeleton";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { ReactComponent as EditButtonIcon } from "../../assets/icons/Icon-Edit.svg";
import AddButtonIcon  from "../../assets/icons/Icon-Add.svg";
import { useParams } from "react-router-dom";
import SocialHistoryModal from "../UI/Modals/PatientModals/SocialHistoryModal";
import SocialHistoryEditModal from "../UI/Modals/PatientModals/SocialHistoryEditModal";
import { addSocialHistoryUrl, fetchSocialHistoryQuestionsUrl, fetchSocialHistoryUrl } from "../../res/endpointurls";
import classes from "../../components/UI/General/DocumentUpload.module.css";
import useOnClickOutside from "../hooks/useOnClickOutside";
import { fieldValidation, sleepSync } from "../../utils/helpers/HelperFunctions";

const SocialHistoryTable = (props) => {
  const { providerId, encounterId, patientId } = useParams();
  const [socialHistory, setSocialHistory] = useState([]);
  const [lazyCall, setLazyCall] = useState(true);
  const [socialHistoryClone, setSocialHistoryClone] = useState([]);
  const [socialHistoryCloneUpdated, setSocialHistoryCloneUpdated] = useState([]);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [updateSocialHistoryId, setUpdateSocialHistoryId] = useState("");
  const [editSocialHistory, setEditSocialHistory] = useState("");
  const [socialHistoryQuestions, setSocialHistoryQuestions] = useState([]);
  const [isAnswered, setIsAnswered] = useState(false);
  const [shList, setSHList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);
  const menuClass = `dropdown-menu br-5 bg--white ${isOpen ? " show" : ""}`;
  const dropdownRef = useRef();
  
  useOnClickOutside(dropdownRef, () => setIsOpen(p => false));

  const fetchSocialHistory = async () => {
    setIsLoading(true);
    emptyStates();
    const httpResponse = await sendHttpRequest({
      url: fetchSocialHistoryUrl(patientId),
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log("Social History", httpResponse.data);
      if (httpResponse.data.length > 0) {
        let arr = httpResponse.data;

        arr.sort((a, b) => a?.question?.sequence - b?.question?.sequence);
        
        arr.forEach(element => {
          element.question.answers.sort((a, b) => a?.answerId - b?.answerId);
        });
        
        setSocialHistory(arr);
        setIsAnswered(true);

      } else {
        setIsAnswered(false);
      }
    } else {
      console.log(httpResponse);
    }
    setIsLoading(false);
  };

  const fetchSocialHistoryQuestions = async (encounterId) => {
    setSocialHistoryQuestions([]);
    const httpResponse = await sendHttpRequest({
      url: fetchSocialHistoryQuestionsUrl(encounterId),
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log("Social History questions", httpResponse.data);
      setSocialHistoryQuestions(httpResponse.data);
    } else {
      console.log("Social History Error", httpResponse.error);
    }
  };

  const emptyStates = () => {
    setQuestion("");
    setAnswer("");
    setSocialHistory([]);
    setSocialHistoryClone([]);
    setSocialHistoryCloneUpdated([]);
    setSHList([]);
  };

  const handleModal = () => {
    setIsOpen(false);
    setOpenModal(!openModal);
    if (openModal) {
      fetchSocialHistoryQuestions();
      fetchSocialHistory();
    } 
  };
  
  const handleSHQuestions = (e, index=null) => {
    if(typeof e === 'string') {
      if (!shList.includes(e)) {
        pushValueToArray(shList, setSHList, e, index);
      }
    }
    else {
      if (e.target.checked) {
        pushValueToArray(shList, setSHList, e.target.name, index);
      } else {
        // setSocialHistoryClone(socialHistoryClone.filter(shc => shc?.question.question !== e.target.name));
        // socialHistoryClone.sort((a, b) => a?.question?.sequence - b?.question?.sequence);
        setSocialHistoryCloneUpdated(socialHistoryCloneUpdated.filter(shc => shc?.question.question !== e.target.name));
        socialHistoryCloneUpdated.sort((a, b) => a?.question?.sequence - b?.question?.sequence);
        const dummy = shList.filter((name) => name !== e.target.name);
        setSHList(dummy);
      }
    }
  };

  const pushValueToArray = (shList, setSHList, value, index) => {
    const tempList = [...shList];
    tempList.push(value);
    setSHList(tempList);
    if (index!=null && !fieldValidation(socialHistoryClone?.find(shc => shc?.question?.question === value), 'un')) {
      socialHistoryClone.push(socialHistory?.find(sh => sh?.question?.question === value));
      socialHistoryClone.sort((a, b) => a?.question?.sequence - b?.question?.sequence);
      socialHistoryCloneUpdated.push(socialHistory?.find(sh => sh?.question?.question === value));
      socialHistoryCloneUpdated.sort((a, b) => a?.question?.sequence - b?.question?.sequence);
    } else if (index==null && !fieldValidation(socialHistoryCloneUpdated?.find(shc => shc?.question?.question === value), 'un')) {
      socialHistoryCloneUpdated.push(socialHistory?.find(sh => sh?.question?.question === value));
      socialHistoryCloneUpdated.sort((a, b) => a?.question?.sequence - b?.question?.sequence);
    }
  }


  useEffect(() => {
    fetchSocialHistory();
    // fetchSocialHistoryQuestions();
    return( () => {});
  }, []);

  return (
    <>
      <div
        className="d-flex mb-1 justify-content-between align-items-baseline"
        {...props}
      >
        <div className="d-inline-flex mb-3 flex-column align-self-center">
          <h4 className="h18">Social History</h4>
          {/* <p style={{ color: 'var(--secondary)' }}>Lorem Ipsum dolor asit</p> */}
        </div>
        <div className="d-flex" style={{height:"40px", width:"40px"}}>
          {!props?.viewOnly && (
              <img
                style={{ color: "var(--primary)" }}
                className="btn shadow-none"
                // onClick={() => handleModal()}
                onClick={toggleOpen}
                src={AddButtonIcon}
                alt={"Add social history button"}
              />
            )}

        <div
          ref={dropdownRef}
          className={menuClass}
          style={{
            minWidth: 220,
            maxWidth: 120,
            opacity: 1,
            // zIndex: 0,
            marginLeft: '-190px',
          }}
        >
          <div className={`p-2 ps-3 ${classes["scroll"]}`}>
            {socialHistory !== undefined && socialHistory?.length > 0 && socialHistory?.map((questionItem, index) => {
              if (questionItem !== null && questionItem?.answer?.length > 0) {
                handleSHQuestions(questionItem?.question?.question, index);
              }              
              if (questionItem !== null) {
                return (
                  <div key={questionItem?.question?.question + index}>
                    <input
                      type="checkbox"
                      defaultChecked={questionItem?.answer?.length > 0}
                      // disabled={questionItem?.answer.length > 0}
                      id={questionItem?.question?.questionId}
                      name={questionItem?.question?.question}
                      onClick={handleSHQuestions}
                      className="form-check-input shadow-none"
                      style={{
                        width: 15,
                        height: 15,
                      }}
                    />
                    <label
                      htmlFor={questionItem?.question?.questionId}
                      className="form-check-label font-12 ms-2 text--secondary"
                    >
                      {questionItem?.question?.question}
                    </label>
                  </div>
                );
              }})}
          </div>
          <div className="text-center mt-2">
            <button
              type="button"
              onClick={toggleOpen}
              className="btn px-4 br-10 btn-sm font-12 me-1 fw-sb text--blue bg--d9e8ec"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleModal}
              className="btn px-4 br-10 btn-sm ms-1 text-white font-12 fw-sb bg--blue"
              disabled={shList?.length <= 0}
            >
              {shList?.length > 0 ? `Update` : `Add`}
            </button>
          </div>
        </div>

        </div>
        {/* <button
          style={{ color: "var(--primary)"}}
          class="btn shadow-none"
          onClick={() => handleModal()}
        >
          <EditButtonIcon style={{ height:"20px", width:"20px" }} />
        </button> */}
      </div>
      {openModal && (
        <SocialHistoryModal
          show={openModal}
          handleModal={handleModal}
          shList={shList}
          setQuestion={setQuestion}
          setAnswer={setAnswer}
          patientId={patientId}
          socialHistoryQuestions={socialHistoryQuestions}
          socialHistory={socialHistoryCloneUpdated}
          fetchSocialHistory={fetchSocialHistory}
          isAnswered={isAnswered}
        />
      )}

      <div className="row">
        {isLoading ? (
          <Skeleton />
        ) : (
          socialHistoryClone !== undefined &&
          socialHistoryClone?.length > 0 ? 
            <>
              <div className="col-6">
                {socialHistoryClone.map((questionItem, index) => {
                  if (index < 3 && questionItem !== null && shList.includes(questionItem?.question?.question)) {
                    if (questionItem?.question?.question?.toLowerCase() === "recreational drugs") {
                      return (
                        <div className="mb-3" key={questionItem?.question?.question + index}>
                          <label className="font-14 fw-sb">
                            {questionItem?.question?.question}
                          </label>
                          <div className="font-12">
                            {questionItem?.answerStrings !== undefined &&
                              questionItem?.answerStrings?.length > 0 ? (
                              questionItem?.answerStrings?.map((drugItem, index) => {
                                if (index !== (questionItem?.answerStrings?.length - 1)) {
                                  return drugItem + ", "
                                } else {
                                  return drugItem
                                }
                              })
                            ) : (
                              <p>&nbsp;</p>
                            )}
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div className="mb-3" key={questionItem?.question?.question + index}>
                          <label className="font-14 fw-sb">
                            {questionItem?.question?.question}
                          </label>
                          <div className="font-12">
                            {questionItem?.answerStrings !== undefined &&
                              questionItem?.answerStrings?.length > 0 ? (
                              questionItem?.answerStrings
                            ) : (
                              <p>&nbsp;</p>
                            )}
                          </div>
                        </div>
                      );
                    }
                  }
                })}
              </div>
              <div className="col-6">
                {socialHistoryClone.map((questionItem, index) => {
                  if (index >= 3 && questionItem !== null && shList.includes(questionItem?.question?.question)) {
                    return (
                      <div className="mb-3" key={questionItem?.question?.question + index}>
                        <label className="font-14 fw-sb">
                          {questionItem?.question?.question}
                        </label>
                        <div className="font-12">
                          {questionItem?.answerStrings !== undefined && questionItem?.answerStrings?.length > 0 ? (
                            questionItem?.answerStrings
                          ) : (
                            <p>&nbsp; </p>
                          )}
                          {/* <p>&nbsp; anbsdokdm</p> */}
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </>
          : 
          socialHistoryQuestions !== undefined &&
          socialHistoryQuestions?.length > 0 ? 
            <>
              <div className="col-6">
                {socialHistoryQuestions.map((questionItem, index) => {
                  if (index < 3 && questionItem !== null && shList.includes(questionItem?.question?.question)) {
                    if (questionItem?.question?.toLowerCase() === "recreational drugs") {
                      return (
                        <div className="mb-3" key={questionItem?.questionId + index}>
                          <label className="font-14 fw-sb">
                            {questionItem?.question}
                          </label>
                        </div>
                      );
                    } else {
                      return (
                        <div className="mb-3" key={questionItem?.questionId + index}>
                          <label className="font-14 fw-sb">
                            {questionItem?.question}
                          </label>
                        </div>
                      );
                    }
                  }
                })}
              </div>
              <div className="col-6">
                {socialHistoryQuestions.map((questionItem, index) => {
                  if (index >= 3 && questionItem !== null && shList.includes(questionItem?.question?.question)) {
                    return (
                      <div className="mb-3" key={questionItem?.questionId + index}>
                        <label className="font-14 fw-sb">
                          {questionItem?.question}
                        </label>
                      </div>
                    );
                  }
                })}
              </div>
            </>
            : ""
        )}
      </div>
    </>
  );
};
export default SocialHistoryTable;

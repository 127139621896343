import {ActionTypes} from "../constants/actionTypes"

const initialState = {
    prescribedDrugList:[]
}

const setPrescribedMedicationReducer = (state=initialState, action)=>{
    if (action.type === ActionTypes.SET_PRESCRIBED_MEDICATION ){
        return {...state, prescribedDrugList :[...state?.prescribedDrugList, action.payload]}
    }
    else if (action.type === ActionTypes.UPDATE_PRESCRIBED_MEDICATION ){
        // console.log("action.payload",action.payload);
        return {...state, prescribedDrugList :action.payload}
    }
    return state
}

export default setPrescribedMedicationReducer;
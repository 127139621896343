import React, { useState, useEffect } from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import classes from "./AppointmentOptions.module.css";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import EventIcon from "@mui/icons-material/Event";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import prescriptionmedication from "../../../assets/icons/Icon-Pharmacy.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import sendHttpRequest from "../../hooks/sendHttpRequest";
import PatientRegistrationFooter from "../patient-registration-footer-login/PatientRegistrationFooter";
import PatientRegistrationHeader from "../header/PatientRegistrationHeader";
import { useAuth } from "../../hooks/useAuth";

const PatientVisitType = ({ setPatientVisitType , setSelectedFacility}) => {
  const [patientVisit, setPatientVisit] = useState("virtual");

  const handlePntVisitType = (event) => {
    const id = event.target.id;
    setPatientVisit(id);
    setPatientVisitType(id);
    if (id === "virtual") {
      setSelectedFacility(0);
    }
  };

  return (
    <>
    <div className="font-14 text--secondary fw-sb">{"Appointment Type"}</div>
      <input
        type="radio"
        name="patientVisitType"
        id="virtual"
        value="virtual"
        className="me-1 cursor--pointer"
        checked={patientVisit === "virtual"}
        onChange={(e) => handlePntVisitType(e)}
      />
      <label htmlFor="virtual" className="me-5 font-14 text--secondary">
        {"Virtual"}
      </label>
      <input
        type="radio"
        name="patientVisitType"
        id="in-patient"
        value="in-patient"
        className="me-1 cursor--pointer"
        checked={patientVisit === "in-patient"}
        onChange={(e) => handlePntVisitType(e)}
      />
      <label htmlFor="in-patient" className="me-5 font-14 text--secondary">
        {"In-Person"}
      </label>
    </>
  );
};

const ProviderFacility = ({
  facilitiesData,
  isFacilityLoading,
  handlefacilityId,
  setSelectedFacility,
  fecthAllPrescribers,
  appointmentType,
}) => {
  return (
    <>
      <label className="font-14 text--secondary">{"Facilities"}</label>
      {isFacilityLoading ? (
        <Skeleton />
      ) : (
        <select
          className={`form-select font-14 shadow-none ${classes["custom-select"]} border--default br-10`}
          aria-label="Select follow up appointment"
          style={{
            height: "40px",
          }}
          onChange={(e) => {
            handlefacilityId(e.target.value);
            // onDirty();
          }}
        >
          <option value="0" selected key={"facilityAll"}>
            {"All Facilities"}
          </option>
          {facilitiesData.map((facilityItem) => {
            return (
              <option
                value={facilityItem?.facilityId}
                id={facilityItem?.facilityId}
                key={facilityItem?.facilityId}
              >
                {facilityItem?.businessName}
              </option>
            );
          })}
        </select>
      )}
    </>
  );
};
function AppointmentOptions(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { tid } = useParams();
  const { user } = useAuth();

  const [isFollowUpAppointment, setIsFollowUpAppointment] = useState(false);
  const [isNewAppointment, setIsNewAppointment] = useState(false);
  const [selected, setSelected] = useState("");
  const [radioValue, setRadioValue] = useState("");
  const [otherSelected, setOtherSelected] = useState(true);
  const [pricing, setPricing] = useState(null);
  const [isPricingLoading, setIsPricingLoading] = useState(false);
  const [chiefComplaint, setChiefComplaint] = useState("");

  // =========Patient visit type=================
  const [patientVisitType, setPatientVisitType] = useState("virtual");

  // =========Facility===========================
  const [selectedFacility, setSelectedFacility] = useState(0);
  const [isFacilityLoading, setIsFacilityLoading] = useState(false);
  const [facilitiesData, setFacilitiesData] = useState([]);

  // ==============Appointment Service==================
  const [activeService,setActiveService] = useState();

  const fetchProviderFacilities = async () => {
    setIsFacilityLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/facilities/tenant/${user?.tenantId}`,
      method: "GET",
    });
    if (!httpResponse.error) {
      setFacilitiesData(httpResponse.data);
    }
    setIsFacilityLoading(false);
  };

  async function getOptionsPricing() {
    setIsPricingLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/tenants/prices/${tid}`,
      method: "GET",
    });
    if (!httpResponse.error) {
      setPricing(httpResponse.data);
    }
    setIsPricingLoading(false);
  }

  function handleNewAppointmentOption(params) {
    setIsFollowUpAppointment(false);
    setIsNewAppointment(true);
    setSelected("new");
    // onChange(params);
  }

  function handleFollowUpAppointmentOption(params) {
    setIsFollowUpAppointment(true);
    setIsNewAppointment(false);
    setSelected("follow_up");
    // onChange(params);
  }
  function handleAppointmentType(serviceItem){
    console.log("serviceItem: ",serviceItem);
    setSelected(serviceItem)
  }

  function handleNextButton() {
    console.log(selected);
    navigate(`/schedule/appointment`, {
      state: {
        price: selected?.price,
        chiefComplaint,
        type: selected,
        facilityId: selectedFacility,
        patientVisitType,
      },
      replace: true,
    });
    
  }
  const handleChiefComplaint = (chiefComplaintValue) => {
    setChiefComplaint(chiefComplaintValue.trim());
  };

  const handlefacilityId = (facilityId) => {
    setSelectedFacility(facilityId);
  };

  useEffect(() => {
    document.body.style.backgroundColor = "#D9E8EC";
    getOptionsPricing();
    fetchProviderFacilities();

    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  return (
    <>
      <div style={{ backgroundColor: "#D9E8EC", minHeight: "100%" }}>
        <PatientRegistrationHeader />
        <div style={{ marginTop: "10%", paddingBottom: "2rem" }}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-7 g-0">
                {isPricingLoading ? (
                  <Skeleton />
                ) : (
                  <Typography
                    display="block"
                    variant="h3"
                    sx={{
                      color: "#336383",
                      fontWeight: 600,
                      fontSize: "16px",
                      fontFamily: "Montserrat",
                    }}
                    component="h1"
                    gutterBottom
                  >
                    We have following treatment options available for you.
                  </Typography>
                )}
              </div>
              <div className="col-sm-7">
                {isPricingLoading ? (
                  <Skeleton />
                ) : (
                  <div className="row gx-md-5 gy-2 mb-5">
                    {
                      pricing?.map((serviceItem)=>{
                        const isSelected = selected?.key === serviceItem?.key;
                        return (
                          <div
                            className="col-md-4 px-3"
                            onClick={()=>{handleAppointmentType(serviceItem)}}
                            key={serviceItem?.key}
                          >
                            <div
                              className="row bg-light p-3"
                              style={
                                isSelected
                                ? {
                                      borderRadius: "10px",
                                      border: "3px solid #336383",
                                    }
                                  : { borderRadius: "10px" }
                              }
                            >
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-12">
                                    <div className="d-flex flex-row justify-content-between">
                                      <div className="d-flex gap-1">
                                        <div
                                          className=" br-10 border--default p-2"
                                          style={{
                                            border: "1px solid #d9e8ec",
                                            borderRadius: "10px",
                                          }}
                                        >
                                          <EventIcon style={{ color: "#336383" }} />
                                        </div>
                                        <span className="fw-sb align-self-center font-14 text--secondary">
                                          {serviceItem?.title}
                                        </span>
                                      </div>

                                      <div className="align-self-center">
                                        <span className="font-16 text--primary fw-sb">
                                          ${serviceItem?.price}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                    {/* <div
                      className="col-md-6 px-3"
                      onClick={handleFollowUpAppointmentOption}
                    >
                      <div
                        className="row  p-3  bg-light"
                        style={
                          isFollowUpAppointment
                            ? {
                                borderRadius: "10px",
                                border: "3px solid #336383",
                              }
                            : { borderRadius: "10px" }
                        }
                      >
                        <div className="col-12">
                          <div className="row">
                            <div className="col-12">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="d-flex gap-1">
                                  <div
                                    className=" br-10 border--default p-2"
                                    style={{
                                      border: "1px solid #d9e8ec",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    <EventRepeatIcon
                                      style={{ color: "#336383" }}
                                    />
                                  </div>
                                  <span className="fw-sb align-self-center font-14 text--secondary">
                                    Follow Up Appointment
                                  </span>
                                </div>

                                <div className="align-self-center">
                                  <span className="font-16 text--primary fw-sb">
                                    ${pricing?.followUpPrice}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    {/* Patient visit type */}
                    <div className="col-12 col-md-12 g-0 mt-2 ms-1">
                      <PatientVisitType
                        key={"patient-visit-type"}
                        setPatientVisitType={setPatientVisitType}
                        setSelectedFacility = {setSelectedFacility}
                      />
                    </div>
                    {/* Facility */}
                    <div className="col-6 col-md-6 g-0 mt-2 ms-1">
                      {patientVisitType === "in-patient" ? (
                        <ProviderFacility
                          facilitiesData={facilitiesData}
                          isFacilityLoading={isFacilityLoading}
                          setSelectedFacility={setSelectedFacility}
                          handlefacilityId={handlefacilityId}
                        />
                      ) : null}
                    </div>

                    <div className="col-12 col-md-12 g-0 mt-2">
                      <label className="font-14 text--secondary ps-1 fw-sb">
                        {"Reason for Visit"}
                      </label>
                      {/* <h2 className="font-18 text--terciary">Chief Complaint</h2> */}

                      <textarea
                        className="form-control br-10 input font-14 border--default shadow-none"
                        type={"text"}
                        id={"chiefComplaint"}
                        onChange={(e) => {
                          e.preventDefault();
                          handleChiefComplaint(e.target.value);
                        }}
                        rows={4}
                      />
                    </div>
                  </div>
                )}

                {/* <div className='mb-3 p-5'>

                                </div> */}
              </div>
            </div>
          </div>
        </div>
        <PatientRegistrationFooter
          onNextHandler={handleNextButton}
          disabled={selected === "" ? true : false}
          isLoggedIn={true}
        />
      </div>
      {console.log("pricing?.find((item)=>{return item?.key === activeService?.key}): ",pricing?.find((item)=>{return item?.key === selected?.key}))}
    </>
  );
}

export default AppointmentOptions;

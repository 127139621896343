import { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import { useNavigate } from "react-router-dom";

const EncounterPieChart = ({ encounterData }) => {
  const navigate = useNavigate();
  const chartRef = useRef(null); // Reference to the canvas
  const chartInstance = useRef(null); // To store the Chart.js instance

  // Function to process the encounter data
  const processEncounterData = (data) => {
    return {
      signed: data.signed ?? 0,
      unsigned: data.unsigned ?? 0,
    };
  };

  const navigateToEncountersPage = (routeVal, encounterType) => {
    navigate(routeVal, {
      state: { encounterType },
    });
  };

  useEffect(() => {
    const encounterStats = encounterData;

    const data = {
      labels: ["Signed", "Unsigned"],
      datasets: [
        {
          label: "Encounter Status",
          data: [encounterStats?.signed ?? 0, encounterStats?.unsigned ?? 0],
          backgroundColor: ["#18C383", "#F44336"], // Signed - Green, Unsigned - Red
          hoverOffset: 4,
        },
      ],
    };

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: "bottom",
        },
        title: {
          display: true,
          text: "Encounter Pie Chart",
        },
      },
    };

    // Destroy the existing chart instance if it exists to avoid memory leaks
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    // Create the new chart instance
    chartInstance.current = new Chart(chartRef.current, {
      type: "pie",
      data: data,
      options: options,
    });

    // Cleanup the chart when the component unmounts
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [encounterData]);

  return (
    <div
      style={{
        width: "100%",
        height: "300px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={() => navigateToEncountersPage("/encounters-all", "all")}
    >
      <canvas ref={chartRef} />
    </div>
  );
};

export default EncounterPieChart;

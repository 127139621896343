/*
    MUI Component
*/
import { Backdrop, Box, CircularProgress, Tab, Tabs } from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { ChevronLeft } from "@mui/icons-material";
import InboxMessages from "../../messages/InboxMessages";
import NewMessageModal from "../Modals/ProviderModals/NewMessageModal";
import CustomizedSnackbars from "../General/CustomizedSnackbars";
import { useDispatch, useSelector } from "react-redux";
import sendHttpRequest from "../../hooks/sendHttpRequest";
import classes from "./MessageTabs.module.css";
import PatientNewMessageModal from "../Modals/PatientModals/PatientNewMessageModal";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { MESSAGES } from "../../../utils/helpers/subscriptionsConstants";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`prescribenow-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#336383",
      light: "#336383",
      dark: "#336383",
      contrastText: "#336383",
    },
    secondary: {
      main: "#EC609B",
    },
  },
  typography: {
    fontFamily: "Montserrat",
    fontSize: 14,
  },
});

export default function MessageTabs(props) {
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);
  const [tabsList, setTabsList] = useState([]);
  const navigate = useNavigate();

  const [value, setValue] = useState(0);
  const [openMessage, setOpenMessage] = useState(false);
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [isMessageSentError, setIsMessageSentError] = useState(false);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const [filterValue, setFilterValue] = useState("all");

  const [isSessionUpdated, setIsSessionUpdated] = useState(false);
  const [isPrescribersFetched, setisPrescribersFetched] = useState(false);

  /*sent*/
  const [sentmessagesData, setSentMessagesData] = useState([]);
  const [isSentMessagesLoading, setIsSentMessagesLoading] = useState(false);
  const [sentMessageCount, setsentMessageCount] = useState(0);
  const [isSentMessagesCountLoading, setIsSentMessagesCountLoading] =
    useState(false);

  /*Inbox*/
  const [inboxMessagesData, setInboxMessagesData] = useState([]);
  const [isInboxMessagesLoading, setIsInboxMessagesLoading] = useState(false);
  const [inboxMessageCount, setInboxMessageCount] = useState(0);
  const [isInboxMessagesCountLoading, setIsInboxMessagesCountLoading] =
    useState(false);

  /*Patient Message Modal*/
  const [openPatientMessageModal, setOpenPatientMessageModal] = useState(false);

  // Pooling
  const [isStartPooling, setIsStartPooling] = useState(true);

  const { user } = useAuth();

  const fetchSentMessages = async () => {
    setIsSentMessagesLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/messages",
      method: "GET",
      params: {
        userId: user?.userId,
        type: "sent",
        tenantId: user?.tenantId
      },
    });
    if (!httpResponse.error && isStartPooling === true) {
      // console.log("httpResponse.data", httpResponse.data);
      setSentMessagesData(httpResponse.data);
      setsentMessageCount(httpResponse.data.length);
    } else {
      console.log(httpResponse.error);
    }
    setIsSentMessagesLoading(false);
  };


  const fetchUnreadMessagesCount = async () => {
    console.log("Called")
    const httpResponse = await sendHttpRequest({
      url: "/messages/unread",
      method: "GET",
      params: {
        userId: user?.userId,
        tenantId: user?.tenantId
      },
    });
    if (!httpResponse.error) {
      setInboxMessageCount(p => httpResponse?.data?.unreadMessageCount)
    }

  };

  const fetchInboxMessages = async () => {
    setIsInboxMessagesLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/messages",
      method: "GET",
      params: {
        userId: user?.userId,
        type: "inbox",
        tenantId: user?.tenantId,
        filter: filterValue,
      },
    });
    if (!httpResponse.error && isStartPooling === true) {
      setInboxMessagesData(httpResponse.data);
      const unreadMessages = httpResponse.data.filter(message => message.status?.toLowerCase() !== "read")?.length ?? 0;
      setInboxMessageCount(unreadMessages);
    } else {
      console.log(httpResponse.error);
    }
    setIsInboxMessagesLoading(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBackdropClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const handleMessageModal = () => {
    setOpenMessage(!openMessage);
  };

  const handleMessageModalClose = () => {
    setOpenMessage(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsMessageSent(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsMessageSentError(false);
  };

  if (isSessionUpdated === true) {
    setIsSessionUpdated(false);
  }

  const changeTab = (newValue) => {
    setValue(newValue);
  };

  /*Patient message modal hanlders*/
  const handlePatientNewMessageModal = () => {
    setOpenPatientMessageModal(!openPatientMessageModal);
  };

  useEffect(() => {
    fetchSentMessages();
  }, [isMessageSent]);

  useEffect(() => {
    let tabs = ["Inbox", "Sent", "Archive"];
    let tabsTempList = [];
    for (let i = 0; i < tabs.length; i++) {
      if (subscriptionPrivilegeGroups[MESSAGES]?.vmi?.active === true && tabs[i] === "Inbox") {
        tabsTempList.push(tabs[i])
      }
      else if (subscriptionPrivilegeGroups[MESSAGES]?.vsm?.active === true && tabs[i] === "Sent") {
        tabsTempList.push(tabs[i])
      }
      if (subscriptionPrivilegeGroups[MESSAGES]?.vmi?.active === false && subscriptionPrivilegeGroups[MESSAGES]?.vsm?.active === true) {
        setValue(1);
      } else if (subscriptionPrivilegeGroups[MESSAGES]?.vmi?.active === true) {
        setValue(0);
      }
      setTabsList(tabsTempList);
    }
  }, [])
  return (
    <ThemeProvider theme={theme}>
      <>
        {openMessage && (
          <NewMessageModal
            show={openMessage}
            handleMessageModal={handleMessageModal}
            setIsMessageSent={setIsMessageSent}
            setIsMessageSentError={setIsMessageSentError}
            fetchSentMessages={fetchSentMessages}
          />
        )}
        {openPatientMessageModal && (
          <PatientNewMessageModal
            show={openPatientMessageModal}
            handleClose={handlePatientNewMessageModal}
          />
        )}

        <Box
          sx={{
            borderBottom: 1,
            zIndex: 0,
            borderColor: "#D9E8EC",
            flexGrow: 1,
          }}
          className="d-flex sticky-top bg-white flex-row justify-content-between px-3 py-3"
        >
          <div className="text--terciary align-self-center ">
            <div className="d-flex flex-row">
              <div
                style={{ width: "32px", height: "32px" }}
                className={`bg--background ${classes["chevron__left__div"]} br-10 me-3 border--default border text-center my-auto `}
                onClick={() => {
                  navigate(-1)
                }}
              >
                <ChevronLeft
                  style={{ color: "#EC609B", width: "20px", height: "20px" }}
                  className="my-auto mt-1 cursor-pointer"
                />
              </div>
              <div className="font-22 fw-sb align-self-center">Messages</div>
            </div>
          </div>
          <div>
            <Tabs
              indicatorColor="secondary"
              value={value}
              variant="scrollable"
              scrollButtons="auto"
              onChange={handleChange}
            >
              {tabsList.map((item, key) => {
                return (
                  item.toLocaleLowerCase() === "archive" ?
                    <Tab
                      key={key}
                      label={item}
                      className={`${classes["message__tab"]} disabled-link`}
                      disabled={true}
                      hidden
                    />
                    :
                    <Tab
                      key={key}
                      label={item}
                      className={`${classes["message__tab"]} `}
                    />
                );
              })}
            </Tabs>
          </div>
          <div className=" align-self-center ">
            {props?.patientPortal ? (
              <button
                className={`btn btn blue_task_button`}
                onClick={() => handlePatientNewMessageModal()}
                disabled
              >
                New Message
              </button>
            ) : (
              <button
                className={`btn blue_task_button`}
                onClick={() => handleMessageModal()}
                hidden={subscriptionPrivilegeGroups[MESSAGES]?.smu?.active === false && subscriptionPrivilegeGroups[MESSAGES]?.smp?.active === false}
              >
                New Message
              </button>
            )}
          </div>
        </Box>
        {/* Inbox */}
        {
          subscriptionPrivilegeGroups[MESSAGES]?.vmi?.active === true || props?.patientPortal ?
            <TabPanel value={value} index={0} className="bg--background">
              <InboxMessages
                inbox={true}
                isInboxMessagesCountLoading={isInboxMessagesCountLoading}
                inboxMessageCount={inboxMessageCount}
                isInboxMessagesLoading={isInboxMessagesLoading}
                inboxMessagesData={inboxMessagesData}
                fetchInboxMessages={fetchInboxMessages}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                fetchUnreadMessagesCount={fetchUnreadMessagesCount}
                patientPortal={props?.patientPortal}
                setInboxMessagesData={setInboxMessagesData}
                isStartPooling={isStartPooling}
                setIsStartPooling={setIsStartPooling}
                setIsMsgLoading={setIsInboxMessagesLoading}
              />
            </TabPanel> : null
        }
        {/* Sent */}
        {
          subscriptionPrivilegeGroups[MESSAGES]?.vsm?.active === true || props?.patientPortal ?
            <TabPanel value={value} index={1} className="bg--background">
              <InboxMessages
                inbox={false}
                isInboxMessagesCountLoading={isSentMessagesCountLoading}
                inboxMessagesData={sentmessagesData}
                isInboxMessagesLoading={isSentMessagesLoading}
                sentMessageCount={sentMessageCount}
                fetchInboxMessages={fetchSentMessages}
                fetchUnreadMessagesCount={fetchUnreadMessagesCount}
                patientPortal={props?.patientPortal}
                setSentMessagesData={setSentMessagesData}
                isStartPooling={isStartPooling}
                setIsStartPooling={setIsStartPooling}
                setIsMsgLoading={setIsSentMessagesLoading}
              />
            </TabPanel> : null

        }
        <TabPanel value={value} index={2} className="bg--background ">
          Archive
        </TabPanel>
        {/* <TabPanel value={value} index={3} className="bg--background vh-100">
          Referral
        </TabPanel> */}
      </>
      <Backdrop
        sx={{ color: "#EC609B", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <CustomizedSnackbars
        message={"Message sent successfully"}
        severity="success"
        open={isMessageSent}
        handleClose={handleClose}
      />
      <CustomizedSnackbars
        message={"Message could not be sent"}
        severity="error"
        open={isMessageSentError}
        handleClose={handleCloseError}
      />
    </ThemeProvider>
  );
}

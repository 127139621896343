import { useState } from "react";
import { lab_requisition_strings, numbersEnum } from "../../res/strings";
import {
  calenderDateTimeHelper,
  getDMYformate,
} from "../../utils/dateTimeHelper";
import { formatPhoneNumber } from "../../utils/helpers/HelperFunctions";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../UI/Reusable/PnComponents";
import classes from "./LabRequisitionPage.module.css";
import SearchIcon from "@mui/icons-material/Search";
import TextArea from "../forms/TextArea";
import ReactSignatureCanvas from "react-signature-canvas";
import sendHttpRequest, { search } from "../hooks/sendHttpRequest";
import { providerSettingSignaturesUrl } from "../../res/endpointurls";
import CustomDatePicker from "../UI/General/CustomDatePicker";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useEffect } from "react";
import { Skeleton } from "@mui/material";
import TrashButtonIcon from "../../assets/icons/Icon-Trash.svg";
import { DateTime } from "luxon";
import CalenderDatePicker from "../UI/General/CalenderDatePicker";

const LabRequisitionPage = (props) => {
  const __str = lab_requisition_strings.en;
  const _numbString = numbersEnum.en;
  const { patientId } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();

  const [patientReqtrDetailsExpanded, setPatientReqtrDetailsExpanded] =
    useState("patientRqtr");
  const [sampleDetailsExpanded, setSampleDetailsExpanded] = useState(false);
  const [profileTestExpanded, setProfileTestExpanded] = useState("profileTest");
  const [biochemistryExpanded, setBiochemistryExpanded] = useState(false);
  const [hematologyExpanded, setHematologyExpanded] = useState("hematology");
  const [microbiologyExpanded, setMicrobiologyExpanded] = useState(false);
  const [anatomicalPathologyExpanded, setAnatomicalPathologyExpanded] =
    useState(false);
  const [examinationExpanded, setExaminationExpanded] = useState(false);
  const [icd10Expanded, setIcd10Expanded] = useState(false);
  const [summaryExpanded, setSummaryExpanded] = useState(false);

  //patient provider data
  const [patientData, setPatientData] = useState();
  const [providerData, setProviderData] = useState();
  const [isPatientDataLoading, setIsPatientDataLoading] = useState(false);
  const [isProviderDataLoading, setIsProviderDataLoading] = useState(false);

  // Sample Details
  const [sampleDate, setSampleDate] = useState();
  const [sampleTime, setSampleTime] = useState();
  const [isFasting, setIsFasting] = useState(false);
  const [fastingHrs, setFastingHrs] = useState(0);
  const [samplesTypes, setSamplesTypes] = useState([]);
  const [otherSampleType, setOtherSampleType] = useState(false);
  const [otherSampleValue, setOtherSampleValue] = useState();
  const [sampleList, setSampleList] = useState([]);
  const [isSampleListLoading, setIsSampleListLoading] = useState(false);

  // Examination search
  const [isExamReqtLoading, setIsExamReqtLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [examTestQuery, setExamTestQuery] = useState([]);
  const [examinationTestList, setExaminationTestList] = useState([]);
  const [additionalTest, setAdditionalTest] = useState();

  // ICD10 search
  const [isICD10Loading, setIsICD10Loading] = useState(false);
  const [icd10Suggestions, setIcd10Suggestions] = useState([]);
  const [icd10Query, setIcd10Query] = useState("");
  const [iCD10CodeList, setICD10CodeList] = useState([]);

  // Signature
  const [sigPad, setSigPad] = useState();
  const [filename, setFilename] = useState(null);

  // Maps
  const [examinationMap, setExaminationMap] = useState(new Map());
  // const sampleList = [
  //   {
  //     description: "Blood",
  //     labOrderSampleId : 1,
  //   },
  //   {
  //     description: "Urine",
  //     labOrderSampleId : 2,
  //   },
  //   {
  //     description: "Swab",
  //     labOrderSampleId : 3,
  //   },
  //   {
  //     description: "Tissue",
  //     labOrderSampleId : 4,
  //   },
  //   {
  //     description: "Feces",
  //     labOrderSampleId : 5,
  //   },
  //   {
  //     description: "Feces",
  //     labOrderSampleId : 6,
  //   },
  //   {
  //     description: "Fluids",
  //     labOrderSampleId : 7,
  //   },
  //   {
  //     description: "Cytology",
  //     labOrderSampleId : 8,
  //   },
  // ];
  const profileTestList = [
    "G2000",
    "DFS",
    "G2000-X",
    "LFT",
    "GT9",
    "RFT",
    "GTI",
    "TFT",
    "NEO",
    "MAC",
    "ES",
    "LGL",
    "HB3",
    "LIP",
  ];
  const biochemistryList = [
    "CEA",
    "HIV 1&2",
    "CA1",
    "HbA1c",
    "CA5",
    "HBsAg",
    "CA9",
    "H. pylori",
    "PSA",
    "Uric Acid",
    "AFP",
    "Free T4",
    "Glucose",
  ];
  const hematologyList = [
    "FBE (incl. ESR)",
    "FBC",
    "Hb",
    "TWDC",
    "Platelets",
    "ABO & Rh (D)",
    "Malaria parasites",
  ];
  const microbiologyList = [
    "Urine FEME",
    "RPR (VDRL)",
    "Microscopy/Culture/Sensitivity",
    "AFB (ZN) Smear Only",
    "AFB Smear & Culture",
  ];
  const anatomicalPathologyList = ["Histology", "Non-Gynae/FNA"];
  const examinationList = [
    "Profile test",
    "Biochemistry",
    "Hematology",
    "Microbiology",
    "Anatomical Pathology",
    "Blood Panels",
    "Single Tests",
  ];

  const fetchProviderData = async () => {
    setIsProviderDataLoading(true);
    // setIsUserDataLoading(false);
    const httpResponse = await sendHttpRequest({
      url: `/provider/${user?.userId}`,
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
      setProviderData(httpResponse.data);
    }
    setIsProviderDataLoading(false);
  };
  const fetchPatientData = async () => {
    setIsPatientDataLoading(true);
    // setIsUserDataLoading(false);
    const httpResponse = await sendHttpRequest({
      url: "/patient/information",
      method: "GET",
      params: {
        patientId: patientId,
      },
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
      setPatientData(httpResponse.data);
    }
    setIsPatientDataLoading(false);
  };

  const fetchSamples = async () => {
    setIsSampleListLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/lab-test-request/lab-samples",
      method: "GET",
    });
    if (!httpResponse.error) {
      setSampleList(httpResponse.data);
    }
    setIsSampleListLoading(false);
  };
  const searchICD10Codes = async (diagnosisInput) => {
    setIcd10Suggestions([]);
    setIsICD10Loading(true);
    const httpResponse = await search({
      url: `/diagnosis`,
      method: "GET",
      params: {
        search: diagnosisInput,
      },
    });
    if (!httpResponse.error) {
      setIcd10Suggestions(httpResponse.data);
    } else {
      setIcd10Suggestions([{ code: "-1" }]);
    }
    setIsICD10Loading(false);
  };
  const searchExaminationTest = async (diagnosisInput) => {
    setSuggestions([]);
    setIsExamReqtLoading(true);
    const httpResponse = await search({
      url: `/lab-test-request/lab-test`,
      method: "GET",
      params: {
        search: diagnosisInput,
      },
    });
    if (!httpResponse.error) {
      setSuggestions(httpResponse.data);
    } else {
      setSuggestions([{ code: "-1" }]);
    }
    setIsExamReqtLoading(false);
  };
  const sendLabOrder = async () => {
    let labOrderSample = [];
    let examinationTests = [];
    let ics10Codes = [];
    for (let index = 0; index < samplesTypes.length; index++) {
      const element = samplesTypes[index];
      labOrderSample.push(element);
    }
    if (otherSampleType === true) {
      labOrderSample.push({ labOrderSampleId: otherSampleValue?.labOrderSampleId, description: "Others" });
    }

    for (let index = 0; index < examinationTestList.length; index++) {
      const element = examinationTestList[index];
      examinationTests.push(element);
    }

    const httpResponse = await sendHttpRequest({
      url: `/lab-test-request`,
      method: "POST",
      responseType: "arraybuffer",
      data: {
        prescriptionReference: {
          patient: { userId: patientData?.userId },
          provider: { userId: user?.userId },
        },
        numberOfHours: fastingHrs,
        fasting: isFasting,
        sampleCollectionDateTime:
          calenderDateTimeHelper(sampleDate, "YYYY-MM-DD") + "T" + sampleTime,
        additionalTest: additionalTest,
        labOrderSample: labOrderSample,
        labOrderTest: examinationTests,
        diagnoses: iCD10CodeList,
        sampleNamely: otherSampleType ? otherSampleValue?.description : "",
      },
    });
    if (!httpResponse.error) {
      if (httpResponse.data !== undefined) {
        const link = document.createElement("a");


        link.target = "_blank";
        // link.download = `Lab_Order_${patientData?.userId}`;
        link.download = `${httpResponse?.responseHeaders?.pdf_name?.split('=')?.at(1)}` ?? `Lab_Order_${patientData?.userId}`;
        const href = URL.createObjectURL(
          new Blob([httpResponse.data], {
            type: "application/pdf",
            encoding: "UTF-8",
          })
        );
        link.href = href;
        link.click();
        link.remove();
        URL.revokeObjectURL(href);
      }
      navigate(`/all-patients/${patientData?.userId}`, {
        state: {
          patientId: `${patientData?.userId}`,
          selectedIndex: `${patientData?.userId}`,
          index: `${patientData?.userId}`,
        },
      });
    }
  };

  const handleChange = (panel) => (event, newExpanded) => {
    if (panel === "patientRqtr") {
      setPatientReqtrDetailsExpanded(newExpanded ? panel : false);
    } else if (panel === "sampleDetails") {
      setSampleDetailsExpanded(newExpanded ? panel : false);
    } else if (panel === "examination") {
      setExaminationExpanded(newExpanded ? panel : false);
    } else if (panel === "icd10") {
      setIcd10Expanded(newExpanded ? panel : false);
    } else if (panel === "summary") {
      setSummaryExpanded(newExpanded ? panel : false);
    }
  };

  const handleExaminationSections = (event) => {
    const newMap = new Map(examinationMap);
    setExaminationMap(newMap.set(event.target.id, event.target.checked));
  };

  const convertToImage = () => {
    let trimmedDataURL = sigPad.getTrimmedCanvas().toDataURL("image/png");
    // console.log(trimmedDataURL);
    let mimeType = trimmedDataURL.split(",")[0].split(":")[1].split(";")[0];

    fetch(trimmedDataURL)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "signature.png", {
          type: mimeType,
        });
        setFilename(file);
      });
  };

  const saveSignature = async () => {
    if (
      providerData?.providerSetting === undefined ||
      providerData?.providerSetting === "" ||
      providerData?.providerSetting === null ||
      providerData?.providerSetting?.signaturePath === undefined ||
      providerData?.providerSetting?.signaturePath === "" ||
      providerData?.providerSetting?.signaturePath === null
    ) {
      const fd = new FormData();
      if (filename !== null) {
        fd.append("file", filename);
      }
      const httpResponse = await sendHttpRequest({
        url: providerSettingSignaturesUrl(providerData?.userId),
        // providerId === undefined ? providerData?.userId : providerId
        method: "POST",
        data: fd,
        headers: {
          "Content-Type": "multipart/form-data; boundary=",
        },
      });
      // if (!httpResponse.error) {
      //   rxAction();
      // }
    } else {
      // rxAction();
    }
  };

  const handleSampleDetailsSection = () => {
    setPatientReqtrDetailsExpanded(false);
    setSampleDetailsExpanded("sampleDetails");
  };

  const handleExaminationSection = (event) => {
    event.preventDefault();
    setSampleDetailsExpanded(false);
    setExaminationExpanded("examination");
  };
  const handleICD10Section = () => {
    setExaminationExpanded(false);
    setIcd10Expanded("icd10");
  };
  const handleSummarySection = () => {
    setIcd10Expanded(false);
    setSummaryExpanded("summary");
  };

  const handleSampleDate = (dateValue) => {
    setSampleDate(dateValue);
  };
  const handleSampleTime = (event) => {
    setSampleTime(event.target.value);
  };
  const handleFasting = (event) => {
    if (event.target.checked === true) {
      setIsFasting(true);
    } else {
      setIsFasting(false);
    }
  };
  const handleFastingHrs = (event) => {
    setFastingHrs(event.target.value);
  };
  const hanldeSampleType = (event, sampleObject) => {
    let sTypes = [];
    if (event.target.checked === true) {
      sTypes = samplesTypes;
      setSamplesTypes([...sTypes, sampleObject]);
    } else {
      sTypes = samplesTypes.filter((item) => {
        return item.description !== sampleObject?.description;
      });

      setSamplesTypes(sTypes);

    }
    // console.log("sTypes: ", sTypes);
  };
  const handleOtherSampleType = (event) => {
    setOtherSampleType(event.target.checked);
  };
  const handleOtherSampleValue = (event) => {
    setOtherSampleValue({ labOrderSampleId: event.target.id, description: event.target.value });
  };

  //ICD10 code handlers
  const handleICD10Search = (query) => {
    setIcd10Query(query);
    if (query.length > 1) {
      searchICD10Codes(query);
    } else {
      setIsICD10Loading(false);
      setIcd10Suggestions([]);
    }
  };
  const handleICD10SearchBtn = (query) => {
    handleICD10Search(query);
  };

  const handleICD10CodeList = (icd10Object) => {
    let icdlist = iCD10CodeList.filter((item) => {
      return item.code !== icd10Object.code;
    });
    setICD10CodeList([...icdlist, icd10Object]);
  };
  const handleDeleteICD10 = (icd10Object) => {
    let icdlist = iCD10CodeList.filter((item) => {
      return item.code !== icd10Object.code;
    });
    setICD10CodeList([...icdlist]);
  };

  //examination test handlers
  const handleExamTestSearch = (query) => {
    setExamTestQuery(query);
    if (query.length > 1) {
      searchExaminationTest(query);
    } else {
      setIsExamReqtLoading(false);
      setSuggestions([]);
    }
  };
  const handleExamSearchBtn = (query) => {
    handleExamTestSearch(query);
  };

  const handleExamTestList = (examObject) => {
    let examlist = examinationTestList.filter((item) => {
      return item.labOrderTestId !== examObject.labOrderTestId;
    });
    setExaminationTestList([...examlist, examObject]);
  };
  const handleDeleteExamTest = (examObject) => {
    let examList = examinationTestList.filter((item) => {
      return item.labOrderTestId !== examObject.labOrderTestId;
    });
    setExaminationTestList([...examList]);
  };
  const handleAdditionalTest = (additionalTest) => {
    setAdditionalTest(additionalTest);
  };

  // provider and patient component

  const ProviderPatientDetails = () => {
    return (
      <>
        {/* Patient Details */}
        <div className="col-6">
          <h3 className="text--terciary font-14 fw-sb m-0">
            {__str.patient_details_str}
          </h3>
          {isPatientDataLoading ? (
            <Skeleton />
          ) : (
            <div className="text--terciary font-12 border--default br-10 px-2">
              <div>
                <span className="text--terciary font-12 ">
                  {__str.name_str}: &nbsp;
                </span>
                <span>
                  {"".concat(
                    patientData?.name[0]?.firstName || "",
                    " ",
                    patientData?.name[0]?.lastName || ""
                  )}
                </span>
              </div>
              <div>
                <span>{__str.gender_str}: &nbsp;</span>
                <span className="">
                  {patientData !== undefined ? patientData?.gender : ""}
                </span>
              </div>
              <div>
                <span className="text--terciary font-12">
                  {__str.date_of_birth_str}: &nbsp;
                </span>
                <span>
                  {patientData !== undefined
                    ? getDMYformate(patientData?.dateOfBirth, "MMM DD, YYYY")
                    : ""}
                </span>
              </div>
              <div>
                <span className="text--terciary font-12">
                  {__str.telephone_str}: &nbsp;
                </span>
                <span>
                  {patientData !== undefined
                    ? formatPhoneNumber(
                      patientData?.communicationNumbers?.numbers?.find(
                        (numberItem) => {
                          return (
                            numberItem?.type === _numbString.cellPhoneExt
                          );
                        }
                      )?.number
                    )
                    : ""}
                </span>
              </div>
              <div>
                <span className="text--terciary font-12 ">
                  {__str.address_str}: &nbsp;
                </span>
                <span className="font-12 text--terciary ">
                  {/* 221B Bakers, Street, 25454, NYC */}
                  {patientData !== undefined && patientData?.address?.length > 0
                    ? "".concat(
                      patientData?.address[0]?.addressLine1?.length > 0
                        ? patientData?.address[0]?.addressLine1 + ", "
                        : "",
                      patientData?.address[0]?.addressLine2?.length > 0
                        ? patientData?.address[0]?.addressLine2 + ", "
                        : "",

                      patientData?.address[0]?.city?.length > 0
                        ? patientData?.address[0]?.city + ", "
                        : "",

                      patientData?.address[0]?.stateProvince || "",
                      " ",
                      patientData?.address[0]?.postalCode
                    )
                    : ""}
                </span>
              </div>
            </div>
          )}
        </div>

        {/* Requester Details */}
        <div className="col-6">
          <h3 className="text--terciary font-14 fw-sb m-0">
            {__str.requester_details_str}
          </h3>
          {isProviderDataLoading ? (
            <Skeleton />
          ) : (
            <div className="text--terciary font-12 border--default br-10 px-2">
              <div>
                <span className="text--terciary font-12 ">
                  {__str.name_str}: &nbsp;
                </span>
                <span>
                  {"".concat(
                    providerData?.name[0]?.firstName || "",
                    " ",
                    providerData?.name[0]?.lastName || ""
                  )}
                </span>
              </div>
              <div>
                <span>{__str.physician_npi_str}: &nbsp;</span>
                <span>{providerData?.identification?.npi}</span>
              </div>
              <div>
                <span>{__str.organization_str}: &nbsp;</span>
                <span>
                  {providerData?.providerTenantPracticeLocation?.tenant
                    ?.businessName || ""}
                </span>
              </div>
              <div>
                <span>{__str.facility_str}: &nbsp;</span>
                <span>
                  {providerData?.providerTenantPracticeLocation?.facility
                    ?.businessName || ""}
                </span>
              </div>
              <div className="mb-1">
                <span>{__str.address_str}: &nbsp;</span>
                <span>
                  {providerData !== undefined
                    ? "".concat(
                      providerData?.providerTenantPracticeLocation?.facility
                        ?.addressLine1,
                      " ",
                      providerData?.providerTenantPracticeLocation?.facility
                        ?.addressLine2 || ""
                    )
                    : null}{" "}
                  {providerData !== undefined
                    ? "".concat(
                      providerData?.providerTenantPracticeLocation?.facility
                        ?.city || "",
                      ", ",
                      providerData?.providerTenantPracticeLocation?.facility
                        ?.state || "",
                      " ",
                      providerData?.providerTenantPracticeLocation?.facility
                        ?.zipCode || ""
                    )
                    : null}
                </span>
              </div>
            </div>
          )}
        </div>
      </>
    );
  };
  // Summary Item

  const SummaryItem = (props) => {
    return (
      <>
        <div className="col-sm-3">
          <span className="font-12 text--secondary">{props.title}</span>
        </div>
        <div className="col-sm-9 font-14 text--terciary fw-sb">
          {props.description}
          {/* <span className="font-14 text--terciary fw-sb">
          </span> */}
        </div>
      </>
    );
  };
  // List Item
  const ListItem = ({ list, handleDelete, type, hideAction }) => {
    return (
      <>
        {list?.length > 0
          ? list.map((item) => {
            return (
              <div className="d-flex flex-row justify-content-between font-12 text--terciary mt-2">
                <div>{item?.description}</div>
                <div>
                  {hideAction !== true ? (
                    <img
                      src={TrashButtonIcon}
                      alt={"deleteIcon"}
                      id={type === "exam" ? item?.labOrderTestId : item?.code}
                      className="me-2 cursor--pointer"
                      style={{ width: "15px", height: "15px" }}
                      onClick={() => {
                        handleDelete(item);
                      }}
                    ></img>
                  ) : null}
                </div>
              </div>
            );
          })
          : null}
      </>
    );
  };

  useEffect(() => {
    fetchPatientData();
    fetchProviderData();
    fetchSamples();
  }, []);

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-8">
          <Accordion
            expanded={patientReqtrDetailsExpanded === "patientRqtr"}
            onChange={handleChange("patientRqtr")}
            defaultExpanded={true}
          // className="mt-4"
          >
            <AccordionSummary
              aria-controls="panelpatientrequester-content"
              id="panelpatientrequester-header"
            >
              <h2 className="font-14 fw-sb m-0">
                {__str.patient_details_str.concat(
                  " & ",
                  __str.requester_details_str
                )}
              </h2>
            </AccordionSummary>
            <AccordionDetails>
              <div className="row">
                <ProviderPatientDetails />
                <div className="col-12 text-end mt-2">
                  <button
                    className="btn d9e8ec--button "
                    onClick={() => handleSampleDetailsSection()}
                  >
                    {"Next"}
                  </button>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      {/* Sample details */}
      <div className="row justify-content-center">
        <div className="col-8">
          <Accordion
            expanded={sampleDetailsExpanded === "sampleDetails"}
            onChange={handleChange("sampleDetails")}
            defaultExpanded={false}
          // className="mt-4"
          >
            <AccordionSummary
              aria-controls="panelsampleDetails-content"
              id="panelsampleDetails-header"
            >
              <h2 className="font-14 fw-sb m-0">{__str.sample_details_str}</h2>
            </AccordionSummary>
            <AccordionDetails>
              <form
                id="smapleForm"
                onSubmit={(e) => handleExaminationSection(e)}
              >
                <div className="row text--terciary font-12  border--default br-10 m-1">
                  <div className="col-sm-4 mt-3">
                    <div className="d-flex flex-row justify-content-between">
                      <label className="font-12 text-nowrap align-self-center">
                        {__str.date_collected_str}:
                      </label>
                      <div className="form-check form-check-inline ">
                      <CalenderDatePicker
                        dateFormat="MMM d, yyyy"
                          min={DateTime.now()}
                          date={DateTime.now()}
                          dobHandler={handleSampleDate}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3 mt-3">
                    <label className="font-12 ">
                      {__str.time_collected_str}: &nbsp;
                    </label>
                    <input
                      type="time"
                      name="startTime"
                      data-format="hh:mm"
                      onInvalid={(e) => {
                        e.target.setCustomValidity("Please enter a valid time");
                      }}
                      onInput={(e) => {
                        e.target.setCustomValidity("");
                      }}
                      required
                      className={`font-13 p-1 ${classes["input__time"]}`}
                      onChange={(e) => handleSampleTime(e)}
                    />
                  </div>
                  <div className="col-sm-5 mt-3">
                    <div className="d-flex flex-row ">
                      <div className="align-self-center">
                        <input
                          className="form-check-input me-2"
                          type="checkbox"
                          name="fastingtype"
                          id={"fasting"}
                          // value={"fasting"}
                          onChange={(e) => {
                            handleFasting(e);
                          }}
                        // checked={orderType === _str?.ePrescription}
                        />
                        <label
                          className="form-check-label font-12"
                          for={"fasting"}
                        >
                          {__str.fasting_str}
                        </label>
                      </div>

                      <div className="col-sm-2 ms-2">
                        <input
                          className={`form-control border--default br-10 shadow-none font-14 ${classes["input"]}`}
                          type="number"
                          id={"no-of-hours"}
                          onInvalid={(e) => {
                            e.target.setCustomValidity(
                              "Please enter number of hours"
                            );
                          }}
                          onInput={(e) => {
                            e.target.setCustomValidity("");
                          }}
                          required
                          onChange={(e) => {
                            handleFastingHrs(e);
                          }}
                          disabled={!isFasting}
                        />
                      </div>
                      <label
                        className=" align-self-center font-12 "
                        for={"no-of-hours"}
                      >
                        &nbsp;{__str.number_of_hrs_str}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 mt-3 ">
                    <div className=" text--terciary font-12  p-2">
                      {isSampleListLoading ? (
                        <Skeleton />
                      ) : (
                        <div className="d-flex flex-row justify-content-between">
                          {sampleList.map((item) => {
                            if (item?.description !== "Others") {
                              return (
                                <div
                                  className="form-check form-check-inline mt-1"
                                  key={item?.description}
                                >
                                  <input
                                    className="form-check-input mt-1"
                                    type="checkbox"
                                    // name="orderType"
                                    id={item?.labOrderSampleId}
                                    value={item?.description}
                                    onChange={(e) => {
                                      hanldeSampleType(e, item);
                                    }}
                                  // checked={orderType === _str?.ePrescription}
                                  />
                                  <label
                                    className="form-check-label font-12 align-self-center"
                                    for={item}
                                  >
                                    {item?.description}
                                  </label>
                                </div>
                              );
                            }
                          })}
                        </div>
                      )}
                      {sampleList?.length > 0
                        ? sampleList.map((sampleItem) => {
                          if (sampleItem?.description === "Others") {
                            return (
                              <div className="col-6 " key={"Other-input"}>
                                <div className=" d-flex flex-row me-2 text--terciary font-12">
                                  <div
                                    className="form-check form-check-inline mt-1"
                                  // key={"Others"}
                                  >
                                    <input
                                      className="form-check-input mt-1"
                                      type="checkbox"
                                      // name="otherSmapleType"
                                      id={"Other"}
                                      name={"Other"}
                                      onChange={(e) => {
                                        handleOtherSampleType(e);
                                      }}
                                    // checked={orderType === _str?.ePrescription}
                                    />
                                    <label
                                      className="form-check-label font-12 align-self-center"
                                      for={"Other"}
                                    >
                                      {"Other"}
                                    </label>
                                  </div>
                                  <input
                                    className={`ms-1 form-control border--default br-10 shadow-none font-14 ${classes["input"]} `}
                                    type="text"
                                    id={sampleItem?.labOrderSampleId}
                                    onInvalid={(e) => {
                                      e.target.setCustomValidity(
                                        "Please enter other sample name"
                                      );
                                    }}
                                    onInput={(e) => {
                                      e.target.setCustomValidity("");
                                    }}
                                    disabled={!otherSampleType}
                                    onChange={(e) => {
                                      handleOtherSampleValue(e);
                                    }}
                                    required
                                  />
                                </div>
                              </div>
                            );
                          }
                        })
                        : null}
                    </div>
                  </div>
                  <div className="col-12 text-end mt-2 mb-2">
                    <button
                      type="submit"
                      form={"smapleForm"}
                      className="btn d9e8ec--button "
                    >
                      {"Next"}
                    </button>
                  </div>
                </div>
              </form>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      {/* Examination  */}
      <div className="row justify-content-center">
        <div className="col-8">
          <Accordion
            expanded={examinationExpanded === "examination"}
            onChange={handleChange("examination")}
            defaultExpanded={false}
          // className="mt-4"
          >
            <AccordionSummary
              aria-controls="panelsampleDetails-content"
              id="panelsampleDetails-header"
            >
              <h2 className="font-14 fw-sb m-0">
                {__str.examinationRequested_str}
              </h2>
            </AccordionSummary>
            <AccordionDetails>
              <div className="row">
                <div className=" col-sm-6 col-6">
                  <div
                    className={`input-group border--default  br-10 ${classes["search__container"]}`}
                  >
                    <input
                      type="text"
                      className={`form-control col-sm-6  font-14 border-0 br-10 shadow-none ${classes.input}`}
                      id="examtest"
                      name="examtest"
                      // ref={searchRef}
                      autoComplete="off"
                      placeholder="Search for examination request"
                      value={examTestQuery}
                      // disabled={props?.disableSendRxButton ? true : props?.updateCheck ? true : false}
                      onChange={(event) =>
                        handleExamTestSearch(event.target.value)
                      }
                      onBlur={() => {
                        setTimeout(() => {
                          setSuggestions([]);
                        }, 10);
                      }}
                    />
                    <button
                      className={`btn br-10 shadow-none`}
                      type="submit"
                      value={examTestQuery}
                      onClick={() => handleExamSearchBtn(examTestQuery)}
                      // disabled={props?.updateCheck ? true : false}
                      onBlur={() => {
                        setTimeout(() => {
                          setSuggestions([]);
                          //   setIsError(false);
                        }, 5);
                      }}
                    >
                      <SearchIcon className=" text--secondary" />
                    </button>
                  </div>

                  <ul
                    className={` cursor--pointer ${classes["suggestion__list"]} hide-scrolbar`}
                    style={{
                      // width: `${props?.newPrescriptionRequest ? "44%" : "34%"}`,
                      width: "47%",
                    }}
                  >
                    {isExamReqtLoading ? (
                      <>
                        <li>Loading...</li>
                      </>
                    ) : (
                      examTestQuery?.length > 1 &&
                      suggestions?.length > 0 &&
                      suggestions.map((suggestion) => {
                        return suggestion.code !== "-1" ? (
                          <li
                            className={`border--default ${classes.li}`}
                            // onClick={() => props.prescribeDrug(props.selectedDrug)}
                            onMouseDown={() => {
                              setExamTestQuery("");
                              handleExamTestList(suggestion);
                              setSuggestions([]);
                            }}
                          >
                            {suggestion?.description}
                          </li>
                        ) : (
                          <li key={"xyz"}>{"Not Found"}</li>
                        );
                      })
                    )}
                  </ul>
                </div>
                <div className="col-6 border--left ps-2">
                  <ListItem
                    list={examinationTestList}
                    handleDelete={handleDeleteExamTest}
                    type={"exam"}
                  />
                </div>
                <div className="col-12 mt-3">
                  <h2 className="font-14 fw-sb">
                    {__str.additional_Tests_str}
                  </h2>
                  <TextArea
                    className="form-control my-2 input br-10 shadow-none border--default "
                    rows={3}
                    gettext={handleAdditionalTest}
                  />
                </div>
                <div className="col-12 text-end mt-2">
                  <button
                    className="btn d9e8ec--button "
                    onClick={() => handleICD10Section()}
                  >
                    {"Next"}
                  </button>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>

      {/* ICD 10 codes */}
      <div className="row justify-content-center">
        <div className="col-8">
          <Accordion
            expanded={icd10Expanded === "icd10"}
            onChange={handleChange("icd10")}
            defaultExpanded={false}
          >
            <AccordionSummary
              aria-controls="panelICD10Details-content"
              id="panelICD10Details-header"
            >
              <h2 className="font-14 fw-sb">{__str.icd10_codes_str}</h2>
            </AccordionSummary>
            <AccordionDetails>
              <div className="row">
                <div className=" col-sm-6 col-6">
                  <div
                    className={`input-group border--default  br-10 ${classes["search__container"]}`}
                  >
                    <input
                      type="text"
                      className={`form-control col-sm-6  font-14 border-0 br-10 shadow-none ${classes.input}`}
                      id="icd10-search"
                      name="icd10-search"
                      // ref={searchRef}
                      autoComplete="off"
                      placeholder="Search for ICD-10 codes"
                      value={icd10Query}
                      onChange={(e) => handleICD10Search(e.target.value)}
                      onBlur={() => {
                        setTimeout(() => {
                          setIcd10Suggestions([]);
                        }, 10);
                      }}
                    />
                    <button
                      className={`btn br-10 shadow-none`}
                      type="submit"
                      value={icd10Query}
                      onClick={() => handleICD10SearchBtn(icd10Query)}
                      // disabled={props?.updateCheck ? true : false}
                      onBlur={() => {
                        setTimeout(() => {
                          setIcd10Suggestions([]);
                          //   setIsError(false);
                        }, 5);
                      }}
                    >
                      <SearchIcon className=" text--secondary" />
                    </button>
                  </div>

                  <ul
                    className={` cursor--pointer ${classes["suggestion__list"]} hide-scrolbar`}
                    style={{
                      width: "47%",
                    }}
                  >
                    {isICD10Loading ? (
                      <>
                        <li>Loading...</li>
                      </>
                    ) : (
                      icd10Query?.length > 1 &&
                      icd10Suggestions?.length > 0 &&
                      icd10Suggestions.map((suggestionItem) => {
                        return suggestionItem?.code !== "-1" ? (
                          <li
                            className={`border--default ${classes.li}`}
                            onMouseDown={() => {
                              setIcd10Query("");
                              handleICD10CodeList(suggestionItem);
                              setIcd10Suggestions([]);
                            }}
                          >
                            {suggestionItem?.description}
                          </li>
                        ) : (
                          <li key={"xyz2"}>{"Not Found"}</li>
                        );
                      })
                    )}
                  </ul>
                </div>
                <div className="col-6 border--left ps-2">
                  <ListItem
                    list={iCD10CodeList}
                    handleDelete={handleDeleteICD10}
                    type={"icd10"}
                    hideAction={false}
                  />
                </div>

                <div className="col-12 text-end mt-2">
                  <button
                    className="btn d9e8ec--button "
                    onClick={() => handleSummarySection()}
                  >
                    {"Next"}
                  </button>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      {/* Summary */}
      <div className="row justify-content-center">
        <div className="col-8">
          <Accordion
            expanded={summaryExpanded === "summary"}
            onChange={handleChange("summary")}
            defaultExpanded={false}
          >
            <AccordionSummary
              aria-controls="panelSummaryDetails-content"
              id="panelSummaryDetails-header"
            >
              <h2 className="font-14 fw-sb">{__str.summary_str}</h2>
            </AccordionSummary>
            <AccordionDetails>
              <div className="row">
                <ProviderPatientDetails />
                <div className="horizontal-rule my-2" />
                {/* Sample details */}
                <SummaryItem
                  title={__str.date_collected_str}
                  description={calenderDateTimeHelper(sampleDate, "MMM DD, YYYY")}
                />
                <SummaryItem
                  title={__str.time_collected_str}
                  description={sampleTime}
                />
                <SummaryItem
                  title={__str.fasting_str}
                  description={
                    isFasting ? "".concat("Yes, ", fastingHrs, " hours") : "No"
                  }
                />
                <SummaryItem
                  title={"Samples"}
                  description={
                    samplesTypes?.length > 0 ? (samplesTypes.map((item) => item.description)).join(", ") : ""
                  }
                />
                {otherSampleType === true ? (
                  <SummaryItem
                    title={"Other sample"}
                    description={otherSampleValue?.description}
                  />
                ) : null}
                {/* Examination section */}
                <div className="horizontal-rule my-2" />
                <SummaryItem
                  title={__str.examinationRequested_str}
                  description={
                    <ListItem
                      list={examinationTestList}
                      handleDelete={handleDeleteExamTest}
                      type={"exam"}
                      hideAction={true}
                    />
                  }
                />
                {additionalTest?.length > 0 ? (
                  <SummaryItem
                    title={__str.additional_Tests_str}
                    description={additionalTest}
                  />
                ) : null}

                {/* ICD10 Code section */}
                <div className="horizontal-rule my-2" />
                <SummaryItem
                  title={__str.icd10_codes_str}
                  description={
                    <ListItem
                      list={iCD10CodeList}
                      handleDelete={handleDeleteICD10}
                      type={"icd10"}
                      hideAction={true}
                    />
                  }
                />

                {/* Signature section */}
                <div className="horizontal-rule my-2" />
                <div
                  className="col-12 mt-3"
                  hidden={
                    providerData !== "" &&
                    providerData?.providerSetting &&
                    providerData?.providerSetting?.signaturePath !== "" &&
                    providerData?.providerSetting?.signaturePath !== null
                  }
                >
                  <span className="font-12 text--secondary">
                    {__str.signature_str}
                  </span>
                </div>
                <div
                  className="col-8"
                  hidden={
                    providerData !== "" &&
                    providerData?.providerSetting &&
                    providerData?.providerSetting?.signaturePath !== "" &&
                    providerData?.providerSetting?.signaturePath !== null
                  }
                >
                  <div style={{ height: "180px" }} className="row ">
                    <div className="h-100 d-inline-block mt-1 bg--white border--default br-10 col-sm ms-2">
                      <div className="text-center align-content-center">
                        <ReactSignatureCanvas
                          penColor="green"
                          ref={(ref) => {
                            setSigPad(ref);
                          }}
                          onBegin={() => {
                            // onDirty();
                          }}
                          onEnd={() => {
                            convertToImage();
                          }}
                          canvasProps={{
                            width: 485,
                            height: 150,
                            className: "sigCanvas",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  {/* <CustomDatePicker date={new Date()} min={new Date()} /> */}
                </div>
                <div className="col-12 text-end mt-3">
                  <button
                    className="blue_task_button"
                    onClick={() => {
                      saveSignature();
                      sendLabOrder();
                    }}
                    disabled={examinationTestList?.length > 0 ? iCD10CodeList?.length > 0 ? false : true : true}
                  >
                    {"Print"}
                  </button>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>

      {/* {console.log("patientId: ", patientId)} */}
      {/* {console.log("Sample types: ",samplesTypes)} */}
      {/* {console.log("sampleTime: ",sampleTime)} */}
      {console.log("suggestions", suggestions)}
      {console.log("icd10  suggestions ", icd10Suggestions)}
    </>
  );
};

export default LabRequisitionPage;

import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import classes from "./JoinVideoSetup.module.css";
import { MicMute } from "react-bootstrap-icons";
import { Mic } from "react-bootstrap-icons";
import { AgoraRTC } from "./agoraDataObjects";
import { client } from "./agoraDataObjects";
import { localAudioTrack, localVideoTrack } from "./agoraDataObjects";
import { pAudio } from "./agoraDataObjects";
import { useToggle } from "../../components/hooks/useToggle";
import { useNavigate } from "react-router-dom";
import { strings } from "../../res/strings";
import sendHttpRequest, { search } from "../../components/hooks/sendHttpRequest";
import { useAuth } from "../../components/hooks/useAuth";
import { VideoCallContext } from "../../components/UI/AgoraHooks/VideoCallContext";
import { setConsulationInProgress, setVideoOpen } from "../../redux/actions/providerActions";
import { useStopwatch } from "react-timer-hook";



const sendNotificationToProvider = async (appointmentId, tenantId) => {
  const httpResponse = await sendHttpRequest({
    method: "GET",
    url: `/appointment/patientWaiting`,
    params: {
      appointmentId: appointmentId,
      tenantId: tenantId
    },
  });
  console.log("Notification to provider sent", httpResponse);
};

const JoinVideoSetup = () => {
  const [showError, setShowError] = useState(false);
  const { appointmentData: { data: appointment }, createLocalTracks, addAudioTrack, addVideoTrack } = useContext(VideoCallContext);
  const videoCallContext = useContext(VideoCallContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    seconds,
    minutes,
    hours,
  } = useStopwatch({ autoStart: true });


  const provider = appointment?.appointmentSlot?.providerTenantPracticeLocation?.provider?.name[0];
  // console.log(provider);
  const providerId = appointment?.appointmentSlot?.providerTenantPracticeLocation?.provider?.userId;
  const patientId = appointment?.appointmentSlot?.patient?.userId;
  const encounterId = appointment?.prescriptionReference?.prescriptionReferenceId;
  const prescriberVideo = useRef(true);
  const prescriberAudio = useRef(true);
  const [areResourcesInitialized, setAreResourcesInitialized] = useState(false);
  const [audioToggle, setAudioToggle] = useToggle(prescriberVideo.current);
  const [startPolling, setStartPolling] = useState(false);
  const [pollingData, setPollingData] = useState(null);
  const [requestSent, setRequestSent] = useToggle(false);
  const [isSessionEnding, setIsSessionEnding] = useState(false);
  const [muteStatus, setMuteStatus] = useState(false);
  const { user } = useAuth();
  const idRef = useRef();

  const sendTokenRequest = async (appointmentId) => {
    const httpResponse = await sendHttpRequest({
      method: "GET",
      url: `/video/tokenrequest/${appointmentId}`,
    });

    if (!httpResponse.error) {
      console.log(httpResponse);
      //Check for 202
      if (httpResponse.status === 202) {
        return {
          message: strings.en.admittedToWaitingRoom,
          isLoading: true,
          navigate: false,
        };
      }

      //Check for 204 - no content
      if (httpResponse.status === 204) {
        //Do nothing or still in waiting room message
        return { message: strings.en.admittedToWaitingRoom, isLoading: true, navigate: false };
      }

      //Check for 200 ok - token received now navigate to the new page
      if (httpResponse.status === 200) {
        //Provider admitted the patient to the video call
        window.clearInterval(idRef.current);
        setStartPolling(p => false);
        return { navigate: true, data: httpResponse.data };
      }

      //403 means you are not allowed to join this video consultaion stop polling
      if (httpResponse.status === 202) {
        return {
          message: strings.en.rejectedFromWaitingRoom,
          isLoading: false,
          navigate: false,
        };
      }
    }
  };


  const canConnectVideo = async () => {
    let appId = appointment?.id;
    const httpResponse = await search({
      method: 'GET',
      url: `/video/JoinRequestStatus/${appId}`
    });
    if (!httpResponse.error) {
      console.log(httpResponse);
      if (httpResponse?.data !== undefined && httpResponse.data?.toLowerCase() === "end") {
        return false;
      }
      else if (httpResponse?.data !== undefined && (httpResponse.data?.toLowerCase() === "new" || httpResponse.data?.toLowerCase() === "leave")) {
        return true
      }
    }
  }

  const sessionBreakRequest = async (appointmentId, hours, minutes, seconds) => {
    setIsSessionEnding(true);

    const breakType = "LEAVE"
    let timeInSeconds = 0;
    try {
      timeInSeconds = (Number(hours) * 60 * 60) + Number(minutes) * 60 + Number(seconds);
    }
    catch (e) {
      console.error("CANNOT CONVERT TIME INTO SECONDS: ", e);
    }

    const httpResponse = await sendHttpRequest({
      method: "POST",
      url: `/video/SessionBreakRequest`,
      data: {
        appiontmentId: appointmentId,
        videoJoinRequestType: breakType,
        callDuration: timeInSeconds || 5000,
      }
    })

    if (!httpResponse.error) {

      videoCallContext.endSession().then(() => {
        videoCallContext.clearVideoCallContextData();
        dispatch(setConsulationInProgress(false));
        dispatch(setVideoOpen(false));
      });
    }
    setIsSessionEnding(false);
  }

  /**
   * If the prescriberAudio.current is true, then call the pAudio function with the localAudioTrackValue,
   * false, and client parameters, and set the audioToggle to false. Otherwise, call the pAudio function
   * with the localAudioTrackValue, true, and client parameters, and set the audioToggle to true.
   * @param localAudioTrackValue - This is the local audio track
   * @param client - AgoraRTC Client object
   */
  const pAudioHandler = (localAudioTrackValue, client) => {
    if (prescriberAudio.current === true) {
      console.log('Audio getting off')
      pAudio(localAudioTrackValue, false, client);
      setMuteStatus(true);
      setAudioToggle(false);
    } else {
      pAudio(localAudioTrackValue, true, client);
      setAudioToggle(true);
      setMuteStatus(false);
    }
  };

  useEffect(() => {
    if (startPolling === true) {
      idRef.current = setInterval(() => {
        sendTokenRequest(appointment?.id).then((response) => {
          console.log("Response", response);
          setPollingData(response);
          if (response?.data !== undefined && response?.data !== null) {
            localStorage.setItem("data", JSON.stringify(response));
            videoCallContext.endSessionWithoutDataRefresh();
            navigate(
              `/patient-portal/consult/${providerId}/${encounterId}/${appointment?.id}`,
              { state: { ...appointment, muteStatus }, replace: true }
            );
          }
        });
      }, 15000);
    } else {
      // console.log("Stopping polling");
      window.clearInterval(idRef.current);
      idRef.current = null;
    }
    return () => {
      console.log("Stopping polling");
      // videoCallContext.endSession();
      window.clearInterval(idRef.current);
      idRef.current = null;
    };
  }, [startPolling]);

  useEffect(() => {

    console.log(pollingData);

  }, [pollingData]);



  // window.addEventListener('popstate', handleBack);
  useEffect(() => {

    async function init() {
      dispatch(setConsulationInProgress(true));
      setAreResourcesInitialized(true);
      const res = await createLocalTracks();
      addVideoTrack(res[1]);
      addAudioTrack(res[0]);
      res[1].play(document.getElementById("join-publisher"));
      document
        .querySelector(`[id^="agora-video-player-track"]`)
        .classList.add("video-track-pn");
      setAreResourcesInitialized(false);
    }

    init();

    return () => {
      // window.removeEventListener('popstate', handleBack);

      console.log("Calling effect clean")
      if (localStorage.getItem('data') === null) {
        videoCallContext.endUnConnectedSession();
      }
    };
  }, []);

  return (
    <>
      <div
        style={{ backgroundColor: "#FAFCFC" }}
        className="bg--background br-10"
      >
        <div className="row br-10 justify-content-center">
          <div className="col-sm-10 ">
            <div className="row justify-content-center pt-3">
              <div className="col-sm-12">
                <span className="font-14 text--secondary d-block text-center ">
                  {strings.en.audio_configuration}
                </span>
              </div>

              <div className="col-sm-6 text-center">
                <span
                  className="fw-sb d-block font-14 text--secondary"
                  style={{ marginBottom: "-4px" }}
                >

                  {provider?.prefix !== undefined && provider?.prefix !== null ? provider?.prefix + ' ' : 'Dr. '}
                  {provider?.firstName !== undefined && provider?.firstName !== null && provider?.firstName + ' '}
                  {provider?.lastName !== undefined && provider?.lastName !== null && provider?.lastName}

                </span>
                {/* <span className="fw-sb d-block m-0 font-14 text--secondary">{dateTimeHelper(appointmentInformation?.AvailableSlot?.StartDate, "MMM D, YYYY")} <span className="fw-thin">at</span> {dateTimeHelper(appointmentInformation?.AvailableSlot?.StartDateTime + ":00", "LT")}</span> */}
              </div>
            </div>

            <div
              id="join-publisher"
              className={`jp-subscriber mx-auto mt-3 ${classes["aspect-ratio-container"]}`}
            >
              <div className="button-grid mt-auto justify-content-center d-flex gap-3">
                <div>
                  <div
                    role="button"
                    onClick={() => {
                      pAudioHandler(localAudioTrack.value, client);
                    }}
                    className="btn-container d-flex"
                  >
                    <div
                      className={`btn-icon ${audioToggle === true
                        ? "btn-icon--positive"
                        : "btn-icon--negative"
                        }`}
                    >
                      {audioToggle === true ? (
                        <Mic color="#fff" size="18" />
                      ) : (
                        <MicMute color="#fff" size="18" />
                      )}
                    </div>
                    <div className="btn-label-text ps-1 pe-1">
                      <span className="font-14 fw-sb text--terciary">
                        Audio:{" "}
                        {audioToggle === true ? (
                          <span className="font-14 fw-thin">
                            {strings.en.on}
                          </span>
                        ) : (
                          <span className="font-14 fw-thin">
                            {strings.en.off}
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3 mb-4 justify-content-center">
          <div className="col-sm-12 text-center">
            {!requestSent ? <button
              className={`btn join-now-button shadow-none`}
              onClick={() => {
                canConnectVideo().then((response) => {
                  if (response === true) {
                    setStartPolling(true);
                    setRequestSent((prev) => !prev);
                    setPollingData(true);
                    sendNotificationToProvider(appointment?.id, user?.tenantId);
                    // });
                  }
                  else if (response === false) {
                    setShowError(true);
                  }
                });

              }}
              disabled={areResourcesInitialized || isSessionEnding}
            >
              {strings.en.join_now}
            </button>
              : <button
                className={`btn leave-session-button shadow-none `}
                disabled={isSessionEnding}
                onClick={() => {
                  setStartPolling(false);
                  setRequestSent((prev) => !prev);
                  sessionBreakRequest(appointment?.id, hours, minutes, seconds).then(() => {
                    window.location.href = "/eportal/patient-portal/dashboard"
                  })
                }}
              >
                Leave Session
              </button>}
          </div>
          {pollingData !== null ? (
            <div className="col-sm-8 mt-2 alert bg--disabled text-center">
              <span className="font-14 fw-sb text--secondary">
                {/* {pollingData?.message}{" "} */}
                Please wait for your physician to add you into the video call.
              </span>
            </div>
          ) : null}


        </div>
      </div>


    </>
  );
};

export default JoinVideoSetup;

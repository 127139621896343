import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const PatientDataLineChart = ({ patientData = [] }) => {
  const chartRef = useRef(null); // Reference to the canvas
  const chartInstance = useRef(null); // To store the Chart.js instance

  // Function to process the patient data
  const processPatientData = (data) => {
    const monthCounts = {
      "Jan": 0, "Feb": 0, "Mar": 0, "Apr": 0, "May": 0, 
      "Jun": 0, "Jul": 0, "Aug": 0, "Sep": 0, "Oct": 0, 
      "Nov": 0, "Dec": 0
    };

    data.forEach(item => {
      const month = new Date(item.createdAt).toLocaleString('default', { month: 'short' });
      if (monthCounts[month] !== undefined) {
        monthCounts[month]++;
      }
    });

    return {
      months: Object.keys(monthCounts),
      values: Object.values(monthCounts),
    };
  };

  useEffect(() => {
    if (!Array.isArray(patientData)) {
      console.error("Patient data should be an array");
      return;
    }

    const processedData = processPatientData(patientData);

    const data = {
      labels: processedData.months, // Months
      datasets: [
        {
          label: "Patient Registrations",
          data: processedData.values, // Data values
          fill: false,
          borderColor: "#3e95cd",
          tension: 0.1,
        },
      ],
    };

    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: true,
          position: "bottom",
        },
        title: {
          display: true,
          text: "Patient Registration by Month",
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: "Number of Patients",
          },
        },
      },
    };

    // Destroy the existing chart instance if it exists to avoid memory leaks
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    // Create the new chart instance
    chartInstance.current = new Chart(chartRef.current, {
      type: "line",
      data: data,
      options: options,
    });

    // Cleanup the chart when the component unmounts
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [patientData]);

  return (
    <div
      style={{
        width: "100%",
        height: "300px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <canvas ref={chartRef} />
    </div>
  );
};

export default PatientDataLineChart;

import { calenderDateTimeHelper } from "../../utils/dateTimeHelper"
import CustomText from "../UI/General/CustomText"
import { PnToolTip } from "../UI/Reusable/PnComponents"

export const EditedMedicationView = ({ _str, requestedMedication }) => {
    return (
        <div className="text--terciary font-12 ">
            <div>
                <span className="text--terciary font-12 ">{_str.drugDescription}: &nbsp;</span>
                <span >{requestedMedication?.drugDescription}</span>
            </div>
            <div>
                <span className="">{_str.sig}: &nbsp;</span>
                {/* {"".concat(requestedMedication?.sigText)} */}
                <CustomText text = {requestedMedication?.sigText} showLimit = {150}/>
            </div>

            <div>
                <span className="">{_str.quantity}: &nbsp;</span>
                {requestedMedication?.quantity + ` ${requestedMedication?.quantityUnitOfMeasure}s`}
                &nbsp;&nbsp;&nbsp;&nbsp;

                {requestedMedication?.daysSupply !== undefined ?
                    <><span className="">{_str.daysSupply}: &nbsp;</span>
                        {requestedMedication?.daysSupply || _str.notAvailable}</> : null}
            </div>

            <div className="d-flex flex-row">
                <PnToolTip
                    title={`Total quantity to be dispensed = ${isNaN((Number(requestedMedication?.refills) * Number(requestedMedication?.quantity)) + Number(requestedMedication?.quantity)) === true ? 0 : (Number(requestedMedication?.refills) * Number(requestedMedication?.quantity)) + Number(requestedMedication?.quantity)} ${"".concat(requestedMedication?.quantityUnitOfMeasure?.toLowerCase() + "(s)")}`}>
                    <div>
                        <span className="">{_str.refills}: &nbsp;</span>
                        {"".concat("", requestedMedication?.refills)}
                    </div>
                </PnToolTip>

            </div>

            <div>
                <span className="">Substitutions: &nbsp;</span>
                {Number(requestedMedication?.substitution) === 1
                    ? "Not Allowed"
                    : "Allowed"}

            </div>
            {
                requestedMedication.earliestFillDate !== undefined ?
                    <div>
                        <span className="">Effective Date: &nbsp;</span>
                        {
                            requestedMedication?.earliestFillDate !== undefined && requestedMedication?.earliestFillDate !== null ? calenderDateTimeHelper(
                                requestedMedication?.earliestFillDate,
                                "YYYY-MM-DD"
                            ) : 'Not available'}

                    </div> : null
            }

            {requestedMedication?.pharmacyNote ? <div>
                <span>Note: &nbsp;</span>
                {requestedMedication?.pharmacyNote}
            </div> : null}
        </div>
    )
}
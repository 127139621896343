import { Modal } from "react-bootstrap";
import AddButtonIcon from "../../assets/icons/Icon-Add.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useRef, useState } from "react";

import sendHttpRequest from "../../components/hooks/sendHttpRequest";
// import TextArea from "antd/lib/input/TextArea";
import TextArea from "../../components/forms/TextArea";
import { formatPhoneNumber, formatPostalCode } from "../../utils/helpers/HelperFunctions";
import { useAuth } from "../../components/hooks/useAuth";
import { Box, Stack, Typography } from "@mui/material";

const RefillRequestModal = (props) => {
  const { user } = useAuth();
  const [serverErrors, setServerErrors] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [refillsRequestedCount, setRefillsRequestedCount] = useState(1);

  let providerFName =
    props?.refillRequestObj?.prescriptionReference
      ?.providerTenantPracticeLocation?.provider?.name[0]?.firstName;
  let providerLName =
    props?.refillRequestObj?.prescriptionReference
      ?.providerTenantPracticeLocation?.provider?.name[0]?.lastName;
  let qualification =
    props?.refillRequestObj?.prescriptionReference
      ?.providerTenantPracticeLocation?.provider?.qualification;

  let pharmacyCity =
    props?.refillRequestObj?.pharmacy?.standardizedCity?.trim();
  let pharmacyStateProvince = "";
  let pharmacyPostalCode = "";

  let pharmacyInfo =
    props?.refillRequestObj?.pharmacy?.standardizedOrganizationName +
    ", " +
    props?.refillRequestObj?.pharmacy?.standardizedAddressLine1 +
    ", " +
    pharmacyCity;

  if (props?.refillRequestObj?.pharmacy?.stateProvince !== undefined) {
    pharmacyInfo += ", " + props?.refillRequestObj?.pharmacy?.stateProvince;
    pharmacyStateProvince = props?.refillRequestObj?.pharmacy?.stateProvince;
  }
  if (props?.refillRequestObj?.pharmacy?.standardizedPostalCode !== undefined) {
    pharmacyInfo +=
      ", " +
      formatPostalCode(
        props?.refillRequestObj?.pharmacy?.standardizedPostalCode
      );
    pharmacyPostalCode = formatPostalCode(
      props?.refillRequestObj?.pharmacy?.standardizedPostalCode
    );
  }
  // console.log(pharmacyInfo);

  const refillRequestHandler = async () => {
    setIsSending(true);
    const httpResponse = await sendHttpRequest({
      url: `/rx/medications/${props?.refillRequestObj?.medicationRxId}/refill-request`,
      method: "POST",
      data: {
        // EncounterId: props?.refillRequestObj?.EncounterId,
        medicationRxId: props?.refillRequestObj?.medicationRxId,
        pharmacy: {
          organizationId: props?.refillRequestObj?.pharmacy?.organizationId,
        },
        refills: refillsRequestedCount,
      },
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.status);
      props.fecthPatientRefills();
      props.handleRefillRequestModalClose();
      clearState();
      setIsSending(false);
    } else {
      console.log(httpResponse.errorMessage);
      setServerErrors(httpResponse.errorMessage);
      setIsSending(false);
    }
  };

  const refillsCountHandler = (event) => {
    setRefillsRequestedCount(event.target.value);
  };

  const clearState = () => {
    setServerErrors(null);
  };

  useEffect(() => {
    return () => {
      clearState(); // This worked for me
    };
  }, []);

  const addRefillRequestHandler = () => {
    let newObj = { HumanPatient: {} };
    console.log(newObj);
    refillRequestHandler(newObj);
  };

  console.log(props?.refillRequestObj);

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.handleRefillRequestModalClose();
      }}
      size="md"
      backdropClassName="modal-backdrop"
      contentClassName="modal-border modal-shadow"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered

      // style={{style}}
    >
      {/* <Modal.Header closeButton style={{ border: "none" }}></Modal.Header> */}
      <Modal.Header className="modal-header">
        <span
          style={{
            lineHeight: "12px",
            width: "18px",
            fontSize: "8pt",
            color: "#336383",
            position: "absolute",
            top: 15,
            right: 15,
            cursor: "pointer",
          }}
          onClick={() => {
            props.handleRefillRequestModalClose();
          }}
        >
          {<CloseIcon />}
        </span>
        <Modal.Title className="font-20 fw-sb">{"Refill Request"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid px-3 ">
          <form
            id="refillRequestForm"
            onSubmit={(e) => {
              e.preventDefault();
              addRefillRequestHandler();
            }}
          >
            {/* {console.log("Refill Request Object==>", props?.refillRequestObj)} */}
            <div className="row ">
              <div className="col-md-12 mb-2">
                <label
                  htmlFor="physician"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Prescribing Physician
                </label>
                <input
                  disabled
                  type="text"
                  className={`form-control font-14 m-0 bg--disabled border--default br-10 shadow-none input`}
                  id="physician"
                  defaultValue={"".concat(
                    providerFName,
                    " ",
                    providerLName,
                    ", ",
                    qualification
                  )}
                />
              </div>
              <div className="col-md-12 mb-2">
                <label
                  htmlFor="DispensedMedication"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Medication
                </label>
                <input
                  type="text"
                  className="form-control bg--disabled font-14 border--default br-10 shadow-none input"
                  id="DispensedMedication"
                  disabled
                  defaultValue={props?.refillRequestObj?.drugDescription}
                />
              </div>

              <div className="col-md-6">
                <label
                  htmlFor="Quantity"
                  className="form-label  m-0 font-12 text--secondary fw-sb"
                >
                  Quantity
                </label>
                <input
                  type="text"
                  className="form-control bg--disabled  font-14 border--default br-10 shadow-none input"
                  id="Quantity"
                  disabled
                  defaultValue={props?.refillRequestObj?.quantity}
                />
              </div>

              <div className="col-md-6">
                <label
                  htmlFor="DaysSupply"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Days Supply
                </label>
                <input
                  type="text"
                  className="form-control bg--disabled  font-14 border--default br-10 shadow-none input"
                  id="DaysSupply"
                  disabled
                  defaultValue={props?.refillRequestObj?.daysSupply}
                />
              </div>

              <div className="col-md-12 mt-3">
                <label
                  htmlFor="RefillsCount"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  {"Number of refills"}
                </label>
                <select
                  onChange={(e) => refillsCountHandler(e)}
                  className="form-select font-14 border--default br-10 shadow-none input"
                  aria-label="RefillsCount"
                  id="RefillsCount"
                  required
                  style={{ borderRadius: "10px" }}
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => {
                    return (
                      <option
                        key={item}
                        value={item}
                      >
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>
              <Stack direction="column" className="mt-3">
                <Stack direction="row" justifyContent={"between"} spacing={2}>
                  <div
                    className="col-sm-12"
                    style={{
                      backgroundColor: "#ffffff",
                      border: "1px solid #D9E8EC",
                      borderRadius: "10px",
                    }}
                  >
                    <div className="row">
                      <div className="col-sm-10 justify-content-start align-self-center">
                        <Typography component="span">
                          <Box
                            sx={{
                              p: 1,
                              fontFamily: "Montserrat",
                              fontWeight: "600",
                              fontSize: "10px",
                              color: "#336383",
                            }}
                          >
                            {"Pharmacy"}
                          </Box>
                        </Typography>
                      </div>
                      {/*  */}
                    </div>
                    <div className="row">
                      <div className="col-sm-12 justify-content-start align-self-center ">
                        <Typography component="span">
                          <Box
                            sx={{
                              p: 1,
                              fontFamily: "Montserrat",
                              fontWeight: "600",
                              fontSize: "12px",
                              color: "#323232",
                              borderTop: "1px solid #D9E8EC",
                            }}
                          >
                            {
                              props?.refillRequestObj?.pharmacy
                                ?.standardizedOrganizationName
                            }
                          </Box>
                        </Typography>
                      </div>
                      <span
                        className="ms-2 text--secondary font-12"
                        style={{ marginTop: "-6px" }}
                      >
                        {"".concat(
                          props?.refillRequestObj?.pharmacy?.standardizedAddressLine1,
                          ", ",
                          props?.refillRequestObj?.pharmacy?.standardizedAddressLine2
                            ?.length > 0
                            ? props?.refillRequestObj?.pharmacy?.standardizedAddressLine2 +
                            ", "
                            : "",
                            props?.refillRequestObj?.pharmacy?.standardizedCity || "",
                          ", ",
                          props?.refillRequestObj?.pharmacy?.stateProvince || "",
                          " ",
                          formatPostalCode(
                            props?.refillRequestObj?.pharmacy?.standardizedPostalCode
                          ) || ""
                        )}{" "}
                        <br />{" "}
                        {"Phone: " +
                          formatPhoneNumber(
                            props?.refillRequestObj?.pharmacy
                              ?.primaryTelephone
                          )}
                        &nbsp;&nbsp;
                        {"Fax: " +
                          formatPhoneNumber(
                            props?.refillRequestObj?.pharmacy
                              ?.fax
                          )}
                        <br />
                      </span>
                    </div>
                  </div>
                </Stack>
              </Stack>

              {/* <div className="col-12">
                <label
                  htmlFor="pharmacy"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Pharmacy
                </label>
                <TextArea
                  disabled
                  // type="text"
                  className={`form-control font-14 m-0 bg--disabled border--default br-10 shadow-none input`}
                  id="pharmacy"
                  // defaultValue={
                  //   ", " +
                  //   props?.refillRequestObj?.pharmacy?.standardizedAddressLine1 +
                  //   ", " +
                  //   props?.refillRequestObj?.pharmacy?.standardizedCity?.trim() +
                  //   ", " +
                  //   props?.refillRequestObj?.StateProvince +
                  //   ", " +
                  //   props?.refillRequestObj?.PostalCode
                  // }
                  defaultValue={pharmacyInfo}
                  rows = {5}
                />
              </div> */}
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ border: "none" }}>
        <button
          type="submit"
          className={`btn blue_task_button fw-sb font-14 text-white b2 px-4 br-10 py-1 ${
            isSending ? "bg--disabled" : ""
          }`}
          form="refillRequestForm"
        >
          {isSending ? "Requesting..." : "Request Refill"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default RefillRequestModal;

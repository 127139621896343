import sendHttpRequest from "../hooks/sendHttpRequest";

export default class LabOrderService {
    static printAllLabOrders = async (labOrderId) => {
        console.log("Print All Lab Orders");
        const link = document.createElement("a");
        link.target = "_blank";
        const httpResponse = await sendHttpRequest({
            url: `/lab-test-request/${labOrderId}/print`,
            method: "GET",
            responseType: "arraybuffer",
        });
        if (!httpResponse.error) {

            const href = URL.createObjectURL(
                new Blob([httpResponse.data], {
                    type: "application/pdf",
                    encoding: "UTF-8",
                })
            );
            link.href = href;
            link.download = `${httpResponse?.responseHeaders?.pdf_name?.split('=')?.at(1)}`;
            link.click();
            link.remove();
            URL.revokeObjectURL(href);

        }
    }

    static deleteAllLabOrders = async (labOrderId) => {
        console.log("Deleting lab orders ....");
        try {
            const httpResponse = await sendHttpRequest({ url: `/lab-test-request/${labOrderId}`, method: 'DELETE' });
            if (!httpResponse.error) {
                if (httpResponse.status == 200) {
                    return true;
                }
                else {
                    return false;
                }
            }
        }
        catch (error) {
            console.log("Error while deleting lab requests: ", error);
        }
    }

}
import { ActionTypes } from "../constants/actionTypes";

const videoCallEndInitialState = {
    isVideoCallEnded: false
}

const setVideoCallEndStateReducer = (state=videoCallEndInitialState, action)=>{
    if (action.type === ActionTypes.SET_IS_VIDEO_CALL_END ){
        return {
            ...state,
            isVideoCallEnded: action.payload
        
        }
    }
    return state;
}

export default setVideoCallEndStateReducer; 
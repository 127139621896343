import { ActionTypes } from "../constants/actionTypes";

const joinPageInitialState = { joinPage: false };

const setJoinPageReducer = (state = joinPageInitialState, action) => {
  if (action.type === ActionTypes.SET_JOIN_PAGE) {
    return {
      ...state,
      joinPage: action.payload,
    }
  }
  return state;
};

export default setJoinPageReducer;

import { Modal } from "react-bootstrap";

const ErrorModal = (props) => {

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.handleModal();
      }}
      backdropClassName="modal-backdrop"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"      
      contentClassName="modal-border modal-shadow"
      // contentClassName={`${classes["appointmentmodal__style"]} `}
      // dialogClassName={`${classes["custom-modal-style"]}`}
      // style={{style}}
    >
      <Modal.Body>
        <div className="container">
          <div className="row text-center justify-content-center">
            <div className="col-12">
              <p className="font-16 text--terciary fw-sb">
                {props?.modalHeaderText}
              </p>
              <div className="font-14 text--secondary">
                <span>
                  {props?.modalText}
                </span>
              </div>

              <div className="mt-3">
                <button
                  className="btn fw-sb b1  btn--border d9e8ec--button px-4 br-10 py-1"
                  onClick={() => {
                    props.handleModal();
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* {console.log(props?.prescriber)} */}
      </Modal.Body>
    </Modal>
  );
};

export default ErrorModal;

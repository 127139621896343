import { useEffect, useState } from "react";
import classes from "./EncountersTablePage.module.css";
import {
  PnToolTip,
  StyledTableCell,
  StyledTableRow,
  TabPanel,
  theme,
} from "../UI/Reusable/PnComponents";
import {
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  ThemeProvider,
} from "@mui/material";
import {
  calenderDateTimeHelper,
  dateTimeHelper,
} from "../../utils/dateTimeHelper";
import PageHeader from "../UI/General/PageHeader";
import { PracticeAdminHeader } from "../pages/InPatientDashboard";
import SearchPatient from "../UI/Header/SearchPatient";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { useAuth } from "../hooks/useAuth";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CalenderDatePicker from "../UI/General/CalenderDatePicker";
import { useLocation } from "react-router-dom";
import { RolesMap, RolesTextConversion } from "../../utils/helpers/RolesMap";
import { providerWithNpiUrl } from "../../res/endpointurls";
import { truncate } from "../../utils/helpers/HelperFunctions";

const PrescriptionDataTable = () => {
  const { user } = useAuth();
  const location = useLocation();

  // ================Page Header Information============================
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [providerId,setProviderId] = useState();

  const [isPatientsDataLoading, setIsPatientsDataLoading] = useState(false);

  // =================Pagination Information=============================
  const [pages, setPages] = useState([]);
  const [pageNumberLimit, setPageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [maxPageLimit, setMaxPageLimit] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(undefined);

  
  const columnHeadersList = [
    "Date",
    "Name",
    "Sig",
    "Days Supply",
    "Quantity",
    "Refills",
    "Provider",
    "Pharmacy",
    "Method",
  ];
  const patientTableRows = [];

  const fetchPrescriptionssData = async (page,startDate,endDate,providerId) => {
    setIsPatientsDataLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/tenants/${user?.tenantId}/prescriptions`,
      method: "GET",
      params: {
        size: 10,
        page: page - 1,
        ...(startDate && {from:startDate}),
        ...(endDate && {to:endDate}),
        ...(providerId && {providerId:providerId}),

      },
    });

    if (!httpResponse.error) {
      let noOfPages = httpResponse?.data?.totalPages;
      setTotalResults(noOfPages);
      setMaxPageLimit(noOfPages);
      let pagesList = [];
      for (let i = 1; i <= noOfPages; i++) {
        pagesList.push(i);
      }
      // console.log("pagesList : ",pagesList);
      setPages(pagesList);

      setCurrentItems(httpResponse?.data?.content);
    }
    setIsPatientsDataLoading(false);
  };

  // ============================Pagination Handlers============================
  // Pagination Handlers
  const RenderPages = () => {
    return (
      <ul className={`${classes["pageNumbers"]}`}>
        {pages?.length > 1 &&
          pages?.map((number) => {
            // if(number > maxPageNumberLimit){
            //   console.log(number,maxPageNumberLimit + 1,minPageNumberLimit,number < maxPageNumberLimit + 1 ,
            //     number > minPageNumberLimit);
            // }
            if (
              number < maxPageNumberLimit + 1 &&
              number > minPageNumberLimit
            ) {
              return (
                <li
                  key={number}
                  id={number}
                  className={`${classes["list__item"]} ${
                    currentPage === number ? classes["active__list__item"] : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleCurrentPage(number);
                  }}
                >
                  {/* {number >= 5 && pages.length > 5 && number !== pages.length ? `${number}   ...${pages.length}` : number} */}
                  {number}
                </li>
              );
            } else {
              return null;
            }
          })}
      </ul>
    );
  };

  const handleNextButton = (event) => {
    event.preventDefault();
    setCurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
    fetchPrescriptionssData(currentPage + 1,startDate,endDate,providerId);
  };
  const handlePreButton = (event) => {
    event.preventDefault();
    // setPageNumber(pageNumber - 1);
    // console.log("currentPage: ",currentPage-1);
    if (currentPage - 1 >= 1) {
      setCurrentPage((p) => currentPage - 1);
      fetchPrescriptionssData(currentPage - 1,startDate,endDate,providerId);
    }
    if ((currentPage - 1) % pageNumberLimit == 0) {
      if (minPageNumberLimit - pageNumberLimit < 0) {
        setMaxPageNumberLimit(5);
        setMinPageNumberLimit(0);
      } else {
        setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
        setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
      }
    }
    // searchPharmacy(inputValue, "Retail,MailOrder", currentPage - 1);
  };
  const handleFirstPage = (event) => {
    event.preventDefault();
    setMaxPageNumberLimit(5);
    setMinPageNumberLimit(0);

    handleCurrentPage(1);
  };
  const handleLastPage = (event) => {
    event.preventDefault();
    let lastPage = Math.ceil(totalResults);
    let maxPageNumberLimitValue = lastPage;
    if (maxPageNumberLimitValue % 10 > 0) {
      maxPageNumberLimitValue = maxPageNumberLimitValue - 5;
    }
    setCurrentPage(totalResults);
    setMaxPageNumberLimit(lastPage);
    setMinPageNumberLimit(lastPage - pageNumberLimit);

    handleCurrentPage(lastPage);
  };
  const handleCurrentPage = (pNumber) => {
    setCurrentPage(pNumber);
    fetchPrescriptionssData(pNumber,startDate,endDate,providerId);
  };

  useEffect(() => {
    fetchPrescriptionssData(1,location?.state?.startDate,location?.state?.endDate);
    setStartDate(location?.state?.startDate);
    setEndDate(location?.state?.endDate);
  }, []);

  useEffect(()=>{
    // fetchPrescriptionssData(1,startDate,endDate,providerId);
    setCurrentPage(1);
  },[startDate,endDate,providerId])

  return (
    <>
      <PageHeader title={"Prescriptions"} />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-11 py-2">
            <PrescriptionTableSearchFilters setCurrentItems={setCurrentItems} fetchPrescriptionssData={fetchPrescriptionssData} startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} setProviderId={setProviderId} />
          </div>
          <div className="col-11">
            <TableContainer
              sx={{ borderRadius: "16px", border: "1px solid #D9E8EC" }}
            >
              <Table>
                <TableHead
                  sx={{
                    borderTopColor: "#FAFCFC",
                    borderRightColor: "#FAFCFC",
                    borderLeftColor: "#FAFCFC",
                    borderBottom: "1px solid #D9E8EC",
                  }}
                >
                  <StyledTableRow>
                    {columnHeadersList?.map((item, idx) => (
                      <StyledTableCell key={item?.concat(idx)}>
                        <span className="font-12 text--secondary">{item}</span>
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {isPatientsDataLoading ? (
                    <StyledTableRow
                      key={"patients-data-loading"}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <StyledTableCell>
                        <Skeleton />
                      </StyledTableCell>
                      
                      
                    </StyledTableRow>
                  ) : (
                    currentItems?.map((item, idx) => {
                      let prefix = item?.prescriptionReference?.providerTenantPracticeLocation?.provider?.name[0]?.prefix ?? "";
                      let firstName = item?.prescriptionReference?.providerTenantPracticeLocation?.provider?.name[0]?.firstName ?? "";
                      let lastName = item?.prescriptionReference?.providerTenantPracticeLocation?.provider?.name[0]?.lastName ?? "" ;
                      return (
                        <StyledTableRow
                          key={item?.userId}
                          // onClick={() => { navigateToPatientProfile(item?.patientId, item?.id, roomObject) }}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            // "&:hover": { cursor: 'pointer' }
                          }}
                        >
                          <StyledTableCell >
                            <span className="font-12 text--secondary">
                              {calenderDateTimeHelper(
                                item?.createdDate,
                                "MMM DD, YYYY"
                              )}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell >
                            <PnToolTip title={item?.drugDescription}> 
                                <span className="font-12 text--secondary">
                                {truncate(item?.drugDescription,15)}
                                </span>
                            </PnToolTip>
                          </StyledTableCell>
                          <StyledTableCell >
                            <PnToolTip title={item?.sigText}>
                                <span className="font-12 text--secondary">
                                {truncate(item?.sigText,20)}
                                </span>
                            </PnToolTip>
                          </StyledTableCell>
                          <StyledTableCell >
                            <span className="font-12 text--secondary">
                              {item?.daysSupply}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell >
                            <span className="font-12 text--secondary">
                              {item?.quantity}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell >
                            <span className="font-12 text--secondary">
                              {item?.refills}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell >
                            <span className="font-12 text--secondary">
                              {prefix.concat(" ",firstName,lastName)}
                            </span>
                          </StyledTableCell>
                          
                          <StyledTableCell >
                            <span className="font-12 text--secondary">
                              {item?.pharmacy?.standardizedOrganizationName}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell >
                            <span className="font-12 text--secondary">
                              {item?.workflowType}
                            </span>
                          </StyledTableCell>
                          
                        </StyledTableRow>
                      );
                    })
                  )}
                  {/* </>} */}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div
            className={` mt-4 align-self-center col-sm-6 col-6 justify-content-center`}
          >
            {isPatientsDataLoading ? (
              <Skeleton />
            ) : (
              <div className={" d-flex flex-row justify-content-between"}>
                {pages.length > 1 ? (
                  <div className="ps-2 mt-2">
                    <PnToolTip title="First" placement="top">
                      <button
                        disabled={currentPage === 1}
                        onClick={(e) => {
                          handleFirstPage(e);
                        }}
                        className={`${classes["prev__btn"]} me-2 bg--fafcfc border--default br-10`}
                      >
                        {/* <img src={IconNext} alt="icon-previous"></img> */}
                        <FirstPageIcon style={{ color: "#ec609b" }} />
                      </button>
                    </PnToolTip>
                    <PnToolTip title="Previous" placement="top">
                      <button
                        disabled={currentPage === 1}
                        onClick={(e) => {
                          handlePreButton(e);
                        }}
                        className={`${classes["prev__btn"]} bg--fafcfc border--default br-10`}
                      >
                        <NavigateBeforeIcon style={{ color: "#ec609b" }} />
                        {/* <img src={IconNext} alt="icon-previous"></img> */}
                      </button>
                    </PnToolTip>
                  </div>
                ) : (
                  ""
                )}

                <RenderPages />

                {pages.length > 1 ? (
                  <div className="mt-2">
                    <PnToolTip title="Next" placement="top">
                      <button
                        disabled={currentPage === pages.length}
                        onClick={(e) => {
                          handleNextButton(e);
                        }}
                        className={`${classes["next__btn"]} me-2 bg--fafcfc border--default br-10`}
                      >
                        {/* <img src={IconNext} alt="icon-next"></img> */}
                        <NavigateNextIcon style={{ color: "#ec609b" }} />
                      </button>
                    </PnToolTip>
                    <PnToolTip title="Last" placement="top">
                      <button
                        disabled={currentPage === pages.length}
                        onClick={(e) => {
                          handleLastPage(e);
                        }}
                        className={`${classes["next__btn"]} bg--fafcfc border--default br-10`}
                      >
                        {/* <img src={IconNext} alt="icon-next"></img> */}
                        <LastPageIcon style={{ color: "#ec609b" }} />
                      </button>
                    </PnToolTip>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PrescriptionDataTable;

const PrescriptionTableSearchFilters = (props) => {
  const { user } = useAuth();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [providerId, setProviderId] = useState("");
  const [isPrescribersLoading, setIsPrescribersLoading] = useState(false);
  const [prescribersList, setPrescribersList] = useState([]);

  // Fetch Prescribers
  const fetchAllPrescribers = async () => {
    setIsPrescribersLoading(true);
    const httpResponse = await sendHttpRequest({
      url: providerWithNpiUrl(user?.tenantId),
      method: "GET",
    });
    if (!httpResponse.error) {
      setPrescribersList(httpResponse.data);
    } else {
      setPrescribersList([]);
      console.log(httpResponse.error);
    }
    setIsPrescribersLoading(false);
  };

  const handleStartDate = (dateVal) => {
    if (dateVal !== undefined) {
      setStartDate(dateVal);
      props?.setStartDate(dateVal);
    }
  };

  const handleEndDate = (dateVal) => {
    if (dateVal !== undefined) {
      setEndDate(dateVal);
      props?.setEndDate(dateVal);
    }
  };

  const handleChange = (e) => {
    let id = e.target.id;
    let value = e.target.value;

    if (id === "providerId") {
      setProviderId(value);
      props?.setProviderId(value);
    }
  };

  // Filter handler
  const handleSearch = (e) => {
    e.preventDefault();
    props?.fetchPrescriptionssData(1, startDate, endDate, providerId);
  };

  // Clear Filter handler
  const handleClearFilter = () => {
    setStartDate("");
    setEndDate("");
    setProviderId("");

    // Clear filters in the parent component
    props?.setStartDate("");
    props?.setEndDate("");
    props?.setProviderId("");

    // Reload data without filters
    props?.fetchPrescriptionssData(1, "", "", "");
  };

  useEffect(() => {
    if (prescribersList?.length === 0) {
      fetchAllPrescribers();
    }
    setStartDate(props?.startDate);
    setEndDate(props?.endDate);
  }, [props?.startDate, props?.endDate]);

  return (
    <>
      <div className="row">
        <div className="col-2">
          <label className="text--terciary font-12 fw-sb">Start Date</label>
          <CalenderDatePicker
            f12={true}
            dobHandler={handleStartDate}
            dateFormat="MMM d, yyyy"
            date={startDate !== undefined ? startDate : ""}
            key={`startDate${startDate}`}
          />
        </div>

        <div className="col-2">
          <label className="text--terciary font-12 fw-sb">End Date</label>
          <CalenderDatePicker
            f12={true}
            dobHandler={handleEndDate}
            dateFormat="MMM d, yyyy"
            date={endDate !== undefined ? endDate : ""}
            key={`EndDate${endDate}`}
          />
        </div>

        <div className="col-sm-2 align-self-center">
          <div className="d-flex text--terciary">
            {isPrescribersLoading ? (
              <Skeleton animation="wave" variant="text" />
            ) : (
              <div>
                <label className="text--terciary b2">Provider</label>
                <select
                  className={`form-select border--default br-10 font-14 shadow-none custom-select`}
                  aria-label="Provider select"
                  
                  id="providerId"
                  onChange={handleChange}
                  value={providerId}  // Binding the selected provider
                >
                  <option value="" disabled selected hidden>
                    Select provider
                  </option>
                  {prescribersList?.length > 0
                    ? prescribersList.map((prescriberItem) => (
                        <option
                          id={prescriberItem?.userId ?? ""}
                          value={prescriberItem?.userId ?? ""}
                          key={prescriberItem?.userId ?? ""}
                        >
                          {prescriberItem?.name[0]?.prefix}{" "}
                          {prescriberItem?.name[0]?.firstName}{" "}
                          {prescriberItem?.name[0]?.lastName}{" "}
                          {RolesTextConversion(prescriberItem?.role)
                            ? `(${RolesTextConversion(prescriberItem?.role)})`
                            : ""}
                        </option>
                      ))
                    : "No prescribers available"}
                </select>
              </div>
            )}
          </div>
        </div>

        <div className="col-2 align-self-center mt-3">
          <label>&nbsp;</label>
          <button
            className="btn blue_task_button br-10 shadow-none"
            onClick={handleSearch}  // Trigger search
          >
            Filter
          </button>
        </div>

        <div className="col-2 align-self-center mt-3">
          <label>&nbsp;</label>
          <span
            className="pn-link-button d-inline-block text-decoration-underline fw-sb font-12 text--secondary cursor--pointer"
            onClick={()=>handleClearFilter()}  // Trigger clear filter
          >
            Clear Filter
          </span>
        </div>
      </div>
    </>
  );
};


import React from 'react';

export const ValidationErrorMessage = ({ errors, fieldName }) => {
    if (errors !== undefined && fieldName !== null && fieldName !== undefined) {
        // console.log("Called if ");
        if (errors[fieldName]) {
            return <div className='font-10 text--danger fw-thin ps-1'>{errors[fieldName]}</div>;
        }
    }
    // console.log("Called else ");
    return <></>;
};

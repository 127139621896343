
const Amex = (props) => {
  return (
    <svg id="amex" width="30px" height="25px" viewBox="0 0 60 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="amex" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M60,35 C60,37.75 57.75,40 55,40 L5,40 C2.25,40 0,37.75 0,35 L0,5 C0,2.25 2.25,0 5,0 L55,0 C57.75,0 60,2.25 60,5 L60,35 Z" id="Shape" fill="#F5F5F5"></path>
        <path d="M55,0 L5,0 C2.25,0 0,2.25 0,5 L0,35 C0,37.75 2.25,40 5,40 L55,40 C57.75,40 60,37.75 60,35 L60,5 C60,2.25 57.75,0 55,0 Z M55,1 C57.206,1 59,2.794 59,5 L59,35 C59,37.206 57.206,39 55,39 L5,39 C2.794,39 1,37.206 1,35 L1,5 C1,2.794 2.794,1 5,1 L55,1 Z" id="Shape" fill="#D0D0D0" fill-rule="nonzero"></path>
        <path d="M16.382,17.733 L17.57,20.472 L15.101,20.472 L16.382,17.733 Z M38.202,22.024 L34.073,22.024 L34.073,20.54 L38.202,20.54 L38.202,19.191 L34.073,19.191 L34.073,17.977 L38.202,17.977 L38.202,16.83 L41.265,19.893 L38.202,22.97 L38.202,22.024 Z M42.48,18.812 L40.456,16.626 L32.333,16.626 L32.333,23.373 L40.187,23.373 L42.414,21.16 L44.654,23.373 L46.746,23.373 L43.548,19.959 L46.841,16.626 L44.655,16.626 L42.48,18.812 Z M30.78,16.626 L27.946,16.626 L25.814,21.147 L23.52,16.626 L20.686,16.626 L20.686,23.171 L17.758,16.626 L15.1,16.626 L11.969,23.373 L13.885,23.373 L14.519,21.835 L18.162,21.835 L18.891,23.373 L22.413,23.373 L22.413,17.881 L24.909,23.373 L26.555,23.373 L29.038,17.975 L29.038,23.373 L30.779,23.373 L30.78,16.626 Z M16.989,23.333 L15.747,23.333 L16.989,23.333 Z M15.752,23.233 L15.115,24.723 L9.406,24.723 L13.912,15.277 L24.674,15.277 L25.76,17.363 L26.775,15.277 L30.598,15.277 L32.525,15.277 L41.284,15.277 L42.524,16.574 L43.856,15.277 L50.594,15.277 L45.81,19.989 L50.387,24.723 L43.867,24.723 L42.408,23.332 L40.961,24.723 L32.525,24.723 L30.598,24.723 L17.687,24.723 L16.987,23.233 L15.752,23.233 Z" id="Shape" fill="#129AD7" fill-rule="nonzero"></path>
      </g>
    </svg>
  );
};

export default Amex;

import { Modal } from "react-bootstrap";
import classes from '../PrescriptionModal/NewPrescriptionModal.module.css';
import CloseIcon from "@mui/icons-material/Close";
import sendHttpRequest from "../../../hooks/sendHttpRequest";
import { useDispatch, useSelector } from "react-redux";
import { setUpdateEncounterData, updatePrescribedMedication } from "../../../../redux/actions/providerActions";
import OrderPrescription from "../../../prescription/OrderPrescription";
import { useEffect } from "react";
import { calenderDateTimeHelper } from "../../../../utils/dateTimeHelper";
const ReorderPrescriptionModal = (props) => {
  const dispatch = useDispatch();
  const updateEncounterData = useSelector(
    (state) => state.UpdateEncounter?.Encounter
  );
  const prescribedMedications = useSelector(
    (state) => state.PrescribedMedication.prescribedDrugList
  );

//   useEffect(()=>{
    
//   },[])
  
    return (
        <Modal
          show={props.show}
          onHide={() => {
            dispatch(
              setUpdateEncounterData({
                ...updateEncounterData,
                Pharmacy: {},
              })
            );
            // props.handleModal();
          }}
          
          contentClassName="modal-border modal-shadow h-100 bg--background"
          backdropClassName="modal-backdrop"
          dialogClassName={`${classes['modal__width']}`}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          scrollable
        >
           <Modal.Header className="modal-header">
        <span
                style={{
                  lineHeight: "12px",
                  width: "18px",
                  fontSize: "8pt",
                  color: "#336383",
                  position: "absolute",
                  top: 15,
                  right: 15,
                  cursor: "pointer",
                }}
                onClick={() => {
                  dispatch(
                    setUpdateEncounterData({
                      ...updateEncounterData,
                      Pharmacy: {},
                    })
                  );
                  if(prescribedMedications?.length > 1){
                    dispatch(updatePrescribedMedication([...prescribedMedications.slice(1,)]));
                  }else if(prescribedMedications?.length <=1 ){
                    dispatch(updatePrescribedMedication([]));
                  }
                  props.handleModal();
                }}
              >
                {<CloseIcon />}
              </span>
          <Modal.Title className="font-20 fw-sb text--terciary">
            Reorder Prescription
          </Modal.Title>
        </Modal.Header>
          <Modal.Body>
            <OrderPrescription
                // handleSetIsSent={props?.handleSetIsSent}
                newPrescriptionRequest={false}
                handleNewPrescriptionModal={props?.handleModal}
                encounterId={props.patientId}
                patientId={props.patientId}
                reOrderPrescription = {true}
                orderPharmacy = {props.RxObject?.pharmacy}
                replaceRequest = {false}
                fetchPrescriptionHistory = {props.fetchPrescriptionHistory}

            />
            {/* {console.log("props.RxObject?.pharmacy: ",props.RxObject)} */}
          </Modal.Body>
        </Modal>
    );
}

export default ReorderPrescriptionModal;
import { MedicalServices } from '@mui/icons-material';
import { Backdrop, CircularProgress, LinearProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import PageHeader from '../../../../patientportal/generic/PageHeader';
import { calenderDateTimeHelper } from '../../../../utils/dateTimeHelper';
import { useAuth } from '../../../../components/hooks/useAuth';
import sendHttpRequest from '../../../../components/hooks/sendHttpRequest';
import CustomizedSnackbars from "../../../../components/UI/General/CustomizedSnackbars";
import useSnackbar from '../../../../components/hooks/useSnackbar';
import useClaimPlaceOfServiceCodes from '../../claim-hooks/useClaimPlaceOfServiceCodes';
import { useSelector } from 'react-redux';
import { BILLING_AND_CLAIMS } from '../../../../utils/helpers/subscriptionsConstants';
import CptCodeSearch from '../../../../components/cptcodes-search/CptCodeSearch';
import { PnMultiSelect } from '../../../../components/UI/PnMultiSelect';
import useCptCodeSearch from '../../../../components/cptcodes-search/useCptCodeSearch';
import { X } from 'react-bootstrap-icons';
import CalenderDatePicker from '../../../../components/UI/General/CalenderDatePicker';
import useModal from '../../../../components/hooks/useModal';
import FeeScheduleAddModalFromClaim from '../UI/Modals/FeeScheduleAddModalFromClaim';
import NdcCodeSearch from '../../../../components/ndc-search/NdcCodeSearch';
import useNdcCodeSearch from '../../../../components/ndc-search/useNdcCodeSearch';
import TextArea from '../../../../components/forms/TextArea';

const modifier = [
    { label: '21', value: '21' },
    { label: '22', value: '22' },
    { label: '26', value: '26' },
    { label: '50', value: '50' },
    { label: '52', value: '52' },
    { label: '53', value: '53' },
    { label: '60', value: '60' },
    { label: '62', value: '62' },
    { label: '80', value: '80' },
    { label: '82', value: '82' },
    { label: 'P1-P6', value: 'P1-P6' },
    { label: '24', value: '24' },
    { label: '25', value: '25' },
    { label: '51', value: '51' },
    { label: '57', value: '57' },
    { label: '58', value: '58' },
    { label: '59', value: '59' },
    { label: '76', value: '76' },
    { label: '77', value: '77' },
    { label: '78', value: '78' },
    { label: '79', value: '79' },
    { label: '91', value: '91' },
    { label: 'E1-E4', value: 'E1-E4' },
    { label: 'FA', value: 'FA' },
    { label: 'F1-F9', value: 'F1-F9' },
    { label: 'LC', value: 'LC' },
    { label: 'LD', value: 'LD' },
    { label: 'LT', value: 'LT' },
    { label: 'RC', value: 'RC' },
    { label: 'RT', value: 'RT' },
    { label: 'TA', value: 'TA' },
    { label: 'T1-T9', value: 'T1-T9' }
];

function BottomNavigation(props) {
    return (
        <div className="position-fixed pb-4 mb-1 w-100 bottom-0 mx-auto bg-white bottom__nav--shadow">
            <LinearProgress variant="determinate" style={{
                backgroundColor: "#D9E8EC",
                height: "1px"
            }} value={100} />
            <div className="d-flex p-3 ">
                <div className="position-fixed " style={{
                    left: "88%"
                }}>
                    <button className="btn  br-10 font-14 ms-2 fw-sb blue_task_button btn__bottom d9e8ec--button" style={{
                        paddingLeft: "24px",
                        paddingRight: "24px"
                    }}
                        onClick={() => {
                            props?.save(props?.tenantId, props?.patientId, props?.providerId, props?.claimId)
                        }}
                        disabled={props?.disableSave}
                    // hidden={props?.hideSaveButton}
                    >
                        Update
                    </button>
                </div>
            </div>
        </div>
    );
}


const EditPatientClaimPage = (props) => {
    const location = useLocation();
    const claimData = location?.state?.data;
    const encounter = location?.state?.data?.encounter;
    const claimServicesData = location?.state?.data?.claimServices;
    const [diagnosisOptions, setDiagnosisOptions] = useState([]);
    const [selectedDiagnosisOptions, setSelectedDiagnosisOptions] = useState({});
    const [serviceData, setServiceData] = useState([]);
    const [selectedModifiers, setSelectedModifiers] = useState([]);
    const [disableEdit, setDisableEdit] = useState(false);
    const [noteLengthError, setNoteLengthError] = useState(false);


    const { user } = useAuth();
    const navigate = useNavigate();
    // const [prescriptionReferenceData, isPrescriptionReferenceDataLoading] = usePrescriptionReference(location?.state?.prescriptionReferenceId);
    const [primaryInsurance, setPrimaryInsurance] = useState(encounter?.patient?.patientInsurance?.find(insurance => insurance?.type === "Primary") ?? null);
    const [diagnosisList, setDiagnosisList] = useState([]);

    // console.log("encounter: ", encounter);

    const { ndcCodeSuggestions,
        setNdcCodeSuggestions,
        isNdcCodeLoading,
        setIsNdcCodeLoading,
        isNdcCodeError,
        setIsNdcCodeError,
        ndcCodeSearchInputValue,
        setNdcCodeSearchInputValue,
        ndcCodeName,
        setNdcCodeName,
        selectedNdcCode,
        setSelectedNdcCode,
        handleNdcCodeSearchInput,
        handleAddNdcCode,
        handleRemoveNdcCode } = useNdcCodeSearch();


    const { placeOfServiceCodes, isPlaceOfServiceCodeLoading } = useClaimPlaceOfServiceCodes();
    const placeOfServiceMap = new Map();

    for (const { code, name } of placeOfServiceCodes) {
        placeOfServiceMap.set(code, name);
    }




    const [isClaimSubmitting, setIsClaimSubmitting] = useState(false);
    const [openClaimSnackbar, claimSnackbarMessage, claimSnackbarSeverity, setOpenClaimSnackbar, setClaimSnackbarMessage, setClaimSnackbarSeverity, handleClaimSnackbarClose] = useSnackbar();
    const [disableSave, setDisableSave] = useState(false);
    const [patientInsuranceDataId, setPatientInsuranceDataId] = useState(null);


    const [billingProviders, setBillingProviders] = useState([]);
    const [areBillingProvidersLoading, setAreBillingProvidersLoading] = useState(false);
    const [selectedBillingProvider, setSelectedBillingProvider] = useState(encounter?.provider?.providerId);

    const [isCheckingEnrollment, setIsCheckingEnrollment] = useState(false);
    const [isProviderEnrolled, setIsProviderEnrolled] = useState(true);

    const handleDiagnosisSelectChange = (index, selected) => {
        setSelectedDiagnosisOptions((prev) => ({
            ...prev,
            [index]: selected,
        }));
    };

    const handleModifierChange = (index, selected) => {
        setSelectedModifiers((prev) => ({
            ...prev,
            [index]: selected,
        }))
    }

    const handleChange = (index, field, value) => {


        setServiceData(prevState => {
            const newData = [...prevState];
            if (field === 'quantity') {
                newData[index]['total'] = value * parseInt(prevState[index].charges);
            }
            newData[index][field] = value; // Update the specific field
            return newData;
        });


    };

    const removeCptCode = (index) => {
        setServiceData(prevState => {
            const newData = [...prevState];
            newData[index]['charges'] = '';
            newData[index]['quantity'] = 1;
            newData[index]['total'] = '';
            newData[index]['hcpcsProcedureCode'] = '';
            newData[index]['hcpcsProcedureDescription'] = '';
            return newData;
        });
    }

    async function getFeeSchedule(tenantId, code) {
        const httpResponse = await sendHttpRequest({ method: 'GET', url: `/claims/tenants/${tenantId}/fee-schedule/${code}` });
        return httpResponse;
    }

    const addCptCode = async (index, cptcode) => {
        const feeScheduleData = await getFeeSchedule(user?.tenantId, cptcode?.code);
        setServiceData(prevState => {
            const newData = [...prevState];
            newData[index]['charges'] = '';
            newData[index]['quantity'] = 1;
            newData[index]['total'] = '';
            newData[index]['hcpcsProcedureCode'] = cptcode.code;
            newData[index]['hcpcsProcedureDescription'] = cptcode.description;
            return newData;
        });
        return feeScheduleData;
    }

    const getFilteredOptions = (index) => {
        const selectedValues = Object.values(selectedDiagnosisOptions).flat() || [];
        const selectedIds = selectedValues.map(option => option.code);
        return diagnosisOptions.filter(option => !selectedIds.includes(option.code));
    };

    const getFilteredModifiers = () => {
        // Ensure selectedModifiers is an array or fallback to an empty array
        const selectedValues = selectedModifiers ? Object.values(selectedModifiers).flat() : [];

        // Map to get selected IDs, ensuring we only include valid options
        const selectedIds = selectedValues.map(option => option?.value).filter(value => value != null);

        // Filter modifiers based on selectedIds
        return modifier.filter(option => !selectedIds.includes(option.value));
    };

    const checkEnrollment = async (tenantId, providerId) => {
        setIsCheckingEnrollment(true);
        const httpResponse = await sendHttpRequest({
            url: `/claims/enrollment/status/tenants/${tenantId}/providers/${providerId}`,
            method: 'GET'
        });

        if (httpResponse.data == true) {
            setIsProviderEnrolled(p => true);
        }
        else {
            setIsProviderEnrolled(p => false);
        }


        setIsCheckingEnrollment(false);

    }

    const fetchBillingProviders = async () => {
        setAreBillingProvidersLoading(true);
        const httpResponse = await sendHttpRequest({
            url: `/claims/tenants/${user?.tenantId}/providers`,
            method: "GET",
        });
        if (!httpResponse.error) {
            setBillingProviders(httpResponse.data);
        } else {
            setBillingProviders([]);
            console.log(httpResponse.error);
        }
        setAreBillingProvidersLoading(false);
    };

    function handlePatientInsuranceDataId(event) {
        setPatientInsuranceDataId(event.target.value);
    }


    function validateServices(serviceData) {
        for (let i = 0; i < serviceData.length; ++i) {
            const service = serviceData[i];
            let modifiersFE = selectedModifiers[i + 1];
            // console.log(`Modifiers ${i + 1}:`, modifiersFE);
            // console.log(`Modifiers ${i + 1}:`, service?.modifiers);

            let diagnosesFE = selectedDiagnosisOptions[i + 1];
            if (service?.charges !== undefined
                && service?.charges !== null
                && service?.dateOfServiceFrom !== ""
                && service?.dateOfServiceFrom !== null
                && service?.dateOfServiceFrom !== undefined
                && service?.dateOfServiceTo !== null
                && service?.dateOfServiceTo !== undefined
                && service?.dateOfServiceTo !== ""
                && service?.hcpcsProcedureCode !== undefined
                && service?.hcpcsProcedureCode !== null
                && service?.hcpcsProcedureDescription !== ""
                && service?.total !== ""
                && service?.total !== null
                && service?.total !== undefined
                && modifiersFE !== undefined
                && modifiersFE !== null
                && modifiersFE?.length > 0
                && diagnosesFE?.length > 0
                && (service?.note !== undefined ? (service?.note == "" || service?.note?.length <= 80) : true)
            ) {

                setDisableEdit(p => false);
            }
            else {

                setDisableEdit(p => true)
            }
        }
    }



    async function saveClaim(tenantId, patientId, providerId, claimId) {

        setIsClaimSubmitting(true);
        let claimServicesList = [];
        for (let index = 0; index < serviceData.length; ++index) {
            let serviceObject = serviceData[index];
            let diagnosesFE = selectedDiagnosisOptions[index + 1];
            let modifiersFE = selectedModifiers[index + 1];
            let modifiersBE = "";
            let diagnosesBE = [];
            diagnosesFE.forEach(d => {
                const diagnosis = {
                    code: d.code,
                    description: d.label.substring(d.label.indexOf("-") + 2),
                }
                diagnosesBE.push(diagnosis)
            });
            modifiersBE = modifiersFE.map(m => m.value).join(",")
            serviceObject['diagnoses'] = diagnosesBE;
            serviceObject['modifiers'] = modifiersBE;
            serviceObject['charges'] = parseInt(serviceObject['charges']);
            // delete serviceObject['claimServiceId'];
            // console.log("Service Objct after del,", serviceObject);
            claimServicesList.push(serviceObject)
        }


        const data = {
            claimEncounterDataId: encounter?.encounterId,
            patientInsuranceDataId: patientInsuranceDataId,
            encounter: encounter,
            claimServices: claimServicesList,
            patientId: patientId,
            providerId: providerId,
            tenantId: tenantId,
            billingProvider: {
                providerId: selectedBillingProvider
            }
        }

        const httpResponse = await sendHttpRequest({ method: 'PUT', url: `/claims/tenants/${tenantId}/insurance-claims/${claimId}`, data: data });
        if (!httpResponse.error) {
            setClaimSnackbarMessage("Claim data updated successfully");
            setClaimSnackbarSeverity("success");
            setOpenClaimSnackbar(true);
            setDisableSave(true);
            setTimeout(() => {
                navigate('/claims-all')
            }, 2000);
        }
        else {
            setClaimSnackbarMessage("Error while updating claims data");
            setClaimSnackbarSeverity("error");
            setOpenClaimSnackbar(true);
            setDisableSave(false);

        }
        setIsClaimSubmitting(false);
    }





    useEffect(() => {
        setDiagnosisList(encounter?.diagnosis);
        fetchBillingProviders().then(() => {
            checkEnrollment(user?.tenantId, selectedBillingProvider);
        });

        if (claimData?.patientInsuranceDataId !== undefined && claimData?.patientInsuranceDataId !== null && claimData?.patientInsuranceDataId !== "") {
            setPatientInsuranceDataId(claimData?.patientInsuranceDataId);
        }
        else {
            let primaryInsurance = encounter?.patient?.patientInsurance?.find((item) => { return item.type === "Primary" })
            if (primaryInsurance !== undefined) {
                setPatientInsuranceDataId(primaryInsurance?.patientInsuranceId)
            }
        }
    }, [location?.state?.encounter]);


    useEffect(() => {
        // Populate the diagnosis options from encounter data
        const options = encounter?.diagnosis?.map((diagnosis) => ({
            value: diagnosis.diagnosisId,
            label: `${diagnosis.code} - ${diagnosis.description}`,
            code: diagnosis.code,
        })) ?? []; // Default to an empty array if undefined

        setDiagnosisOptions(options);
    }, [encounter]);

    useEffect(() => {
        // Populate selected diagnoses from claim services data
        const selectedOptions = claimServicesData?.reduce((acc, serviceData, index) => {
            const diagnosis = serviceData?.diagnoses?.map((diagnosis) => ({
                value: diagnosis.diagnosisId,
                label: `${diagnosis.code} - ${diagnosis.description}`,
                code: diagnosis.code,
            })) || []; // Ensure it defaults to an empty array if undefined

            acc[index + 1] = diagnosis; // Assign the diagnosis array to the current index
            return acc; // Return the accumulator
        }, {}) || {}; // Ensure it defaults to an empty object if claimServicesData is undefined

        setSelectedDiagnosisOptions(selectedOptions);
    }, [claimServicesData]);

    useEffect(() => {
        // Map over claimServicesData to create form data
        const initialFormData = claimServicesData.map(service => ({
            claimServiceId: service.claimServiceId,
            hcpcsProcedureCode: service.hcpcsProcedureCode,
            hcpcsProcedureDescription: service.hcpcsProcedureDescription,
            charges: service.charges,
            providerPaymentAmount: service.claimServicePayment?.providerPaymentAmount,
            total: service.total || 0,
            modifiers: service.modifiers || '',
            dateOfServiceFrom: service.dateOfServiceFrom || '', // Add dateOfServiceFrom
            dateOfServiceTo: service.dateOfServiceTo || '',
            quantity: service.quantity || 1,
            note: service?.note ?? ""
        }));



        setServiceData(initialFormData);
    }, [claimServicesData]);


    useEffect(() => {
        const selectedOptions = claimServicesData?.reduce((acc, serviceData, index) => {
            const modifiers = serviceData?.modifiers?.split(",");
            const modifiersOptions = modifiers?.map(modifier => ({
                label: modifier,
                value: modifier
            }));
            acc[index + 1] = modifiersOptions;
            return acc;
        }, {}) || {};

        setSelectedModifiers(selectedOptions);
    }, [claimServicesData]);

    useEffect(() => {
        validateServices(serviceData);
    }, [serviceData, selectedModifiers, selectedDiagnosisOptions]);








    return (
        <>
            <PageHeader title="Edit Claim" sticky={true}>
            </PageHeader>
            <main>

                <div className='container py-3'>



                    <div className='row justify-content-center'>
                        <div className='col-sm-7'>
                            {isProviderEnrolled === false ? <div className='row py-2 justify-content-center'>
                                <div className='col-sm-8'>
                                    <div className="alert alert-danger font-10" role="alert">
                                        Provider Enrollement is Incomplete.
                                    </div>
                                </div>
                            </div> : null}
                            <div className='row px-2'>
                                <div className="col-sm-3">
                                    <span className='d-block fw-sb font-12 text--secondary '>
                                        Provider
                                    </span>
                                    <span className='d-block  font-12 text--terciary'>
                                        {/* {getProviderConcatenatedNameFromNameObject(prescriptionReferenceData?.providerTenantPracticeLocation?.provider?.name?.at(0))} */}
                                        {`${encounter?.provider?.prefix} ${encounter?.provider?.firstName} ${encounter?.provider?.lastName}`}
                                    </span>
                                </div>
                                <div className="col-sm-3">
                                    <span className='d-block fw-sb font-12 text--secondary '>
                                        Service Location
                                    </span>
                                    <span className='d-block font-12 text--terciary'>
                                        {/* {prescriptionReferenceData?.providerTenantPracticeLocation?.facility?.businessName} */}
                                        {`${encounter?.encounterMetadata?.serviceLocation}`}
                                    </span>
                                </div>
                                <div className="col-sm-4">
                                    <span className='d-block fw-sb font-12 text--secondary '>
                                        Place of Service
                                    </span>

                                    <span className='font-12 text--terciary'>

                                        {placeOfServiceMap.get(encounter?.encounterMetadata?.placeOfService)}</span>


                                </div>
                            </div>
                            {/* {console.log("encounter", encounter)} */}
                            <div className='row px-2'>
                                <div className="col-sm-3">
                                    <span className='d-block fw-sb font-12 text--secondary '>
                                        Service Date
                                    </span>
                                    <span className='d-block  font-12 text--terciary'>
                                        {/* {getProviderConcatenatedNameFromNameObject(prescriptionReferenceData?.providerTenantPracticeLocation?.provider?.name?.at(0))} */}
                                        {calenderDateTimeHelper(encounter?.encounterMetadata?.dateOfServiceTo, "MMM DD, YYYY")}
                                        {/* {`${encounter?.provider?.prefix} ${encounter?.provider?.firstName} ${encounter?.provider?.lastName}`} */}
                                    </span>
                                </div>
                                <div className="col-sm-3">
                                    <span className='d-block fw-sb font-12 text--secondary '>
                                        Last Seen Date
                                    </span>
                                    <span className='d-block font-12 text--terciary'>
                                        {/* {prescriptionReferenceData?.providerTenantPracticeLocation?.facility?.businessName} */}
                                        {calenderDateTimeHelper(encounter?.lastSeenDate, "MMM DD, YYYY")}
                                    </span>
                                </div>
                                <div className="col-sm-3">
                                    <span className='d-block fw-sb font-12 text--secondary '>
                                        Date of Current Illness
                                    </span>
                                    <span className='d-block font-12 text--terciary'>
                                        {/* {prescriptionReferenceData?.providerTenantPracticeLocation?.facility?.businessName} */}
                                        {calenderDateTimeHelper(encounter?.dateOfCurrentIllnessOrSymptom, "MMM DD, YYYY")}
                                    </span>


                                </div>
                                {encounter?.patient?.gender?.toLowerCase() === "female" && encounter?.lastMenstrualPeriodDate !== null ? <div className="col-sm-3">
                                    <span className='d-block fw-sb font-12 text--secondary '>
                                        Last Menstural Period
                                    </span>
                                    <span className='d-block font-12 text--terciary'>
                                        {/* {prescriptionReferenceData?.providerTenantPracticeLocation?.facility?.businessName} */}
                                        {calenderDateTimeHelper(encounter?.lastMenstrualPeriodDate, "MMM DD, YYYY")}
                                    </span>


                                </div> : null}
                            </div>


                            <div className="row px-2 mt-2">
                                <div className='col-sm-5'>
                                    <span className='d-block fw-sb font-12 text--secondary '>
                                        Insurance  <span>&#42;</span>
                                    </span>
                                    <select
                                        className={`form-select br-10 font-12 input border--default shadow-none`}
                                        aria-label="Insurance Select"
                                        onChange={(e) => handlePatientInsuranceDataId(e)}
                                        value={patientInsuranceDataId}
                                    >
                                        <option value="" selected>Select Insurance</option>
                                        {encounter?.patient?.patientInsurance?.map(insurance => {
                                            return <option className='font-12' value={insurance?.patientInsuranceId}>{insurance?.payerName} - {insurance?.type}</option>
                                        })}
                                    </select>
                                </div>

                                <div className='col-sm-5 offset-sm-1'>
                                    <span className='d-block fw-sb font-12 text--secondary '>
                                        Billing Provider  <span>&#42;</span>
                                    </span>
                                    <select
                                        className={`form-select br-10 font-12 input border--default shadow-none`}
                                        aria-label="Insurance Select"
                                        onChange={(e) => {
                                            setSelectedBillingProvider(e.target.value)
                                            checkEnrollment(user?.tenantId, e.target.value);
                                        }}
                                        value={selectedBillingProvider}
                                    >
                                        <option value="" selected>Select Billing Provider</option>
                                        {billingProviders?.map(provider => {
                                            return <option className='font-12' value={provider?.providerId}>{provider?.prefix} {provider?.firstName} {provider?.lastName}</option>
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className='row px-2 mb-5 pb-4'>
                                {
                                    serviceData?.map((service, index) => {

                                        return <>

                                            <div className='row pt-3'>
                                                <div className='col-12'>
                                                    <h2 className='font-16 fw-sb m-0 p-0'>Service {index + 1}</h2>
                                                </div>
                                            </div>

                                            <div className='row py-2'>
                                                <div className='col-12 col-sm-6'>
                                                    <span className='d-block font-12 text--secondary '>
                                                        Diagnoses <span>&#42;</span>
                                                    </span>


                                                    <PnMultiSelect
                                                        name="diagnosis"
                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        options={getFilteredOptions(index + 1)}
                                                        value={selectedDiagnosisOptions[index + 1] || []}
                                                        onChange={(selected) => handleDiagnosisSelectChange(index + 1, selected)}
                                                    />
                                                </div>
                                                <div className='col-12 col-sm-6'>
                                                    {/* <span className='fw-thin ms-1 font-12 text--secondary d-block'>CPT Codes</span> */}
                                                    <CptCodeSearchWrapper
                                                        key={index}
                                                        index={index}
                                                        service={service}
                                                        setServiceData={setServiceData}
                                                        onAddCptCode={addCptCode}
                                                        onRemoveCptCode={() => { removeCptCode(index) }}
                                                    />

                                                </div>
                                            </div>

                                            <div className='row py-2'>
                                                <div className='col-12 col-sm-6'>

                                                    <span className='fw-thin ms-1 font-12 text--secondary d-block'>NDC Codes</span>
                                                    <NdcCodeSearch
                                                        suggestions={ndcCodeSuggestions}
                                                        setSuggestions={setNdcCodeSuggestions}
                                                        isError={isNdcCodeError}
                                                        setIsError={setIsNdcCodeError}
                                                        isLoading={isNdcCodeLoading}
                                                        setIsLoading={setIsNdcCodeLoading}
                                                        handleAddNdcCode={handleAddNdcCode}
                                                        handleSearchInput={handleNdcCodeSearchInput}
                                                        searchInputValue={ndcCodeSearchInputValue}
                                                        setSearchInputValue={setNdcCodeSearchInputValue}
                                                        handleAddCptCode={() => { }}
                                                        ndcCodeName={ndcCodeName}
                                                        setNdcCodeName={setNdcCodeName}
                                                        selectedNdcCode={selectedNdcCode}
                                                        setSelectedNdcCode={setSelectedNdcCode}
                                                    />

                                                </div>
                                                <div className='col-12 col-sm-6'>
                                                    <span className='fw-thin ms-1 font-12 text--secondary d-block'>Modifiers <span>&#42;</span></span>

                                                    <PnMultiSelect
                                                        onChange={(selected) => handleModifierChange(index + 1, selected)}

                                                        name="modifier"
                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        options={getFilteredModifiers()}
                                                        value={selectedModifiers[index + 1]}
                                                    />


                                                </div>
                                            </div>

                                            <div className='row py-2'>
                                                <div className='col-sm-3'>
                                                    <span className='fw-thin ms-1 font-12 text--secondary d-block'>Start Date <span>&#42;</span></span>
                                                    <CalenderDatePicker
                                                        dobHandler={(d) => handleChange(index, 'dateOfServiceFrom', d)}
                                                        date={service?.dateOfServiceFrom}
                                                        disableFuture={true}
                                                    />
                                                </div>
                                                <div className='col-sm-3'>
                                                    <span className='fw-thin ms-1 font-12 text--secondary d-block'>End Date <span>&#42;</span></span>
                                                    <CalenderDatePicker
                                                        disableFuture={true}
                                                        dobHandler={(d) => handleChange(index, 'dateOfServiceTo', d)}
                                                        date={service.dateOfServiceTo} />
                                                </div>
                                                <div className='col-sm-2'>
                                                    <span className='fw-thin ms-1 font-12 text--secondary d-block'>Amount <span>&#42;</span></span>
                                                    <input
                                                        disabled
                                                        value={service.charges}
                                                        type="text"
                                                        className='form-control font-14 m-0 border--default br-10 shadow-none input' />
                                                </div>
                                                <div className='col-sm-2'>
                                                    <span className='fw-thin ms-1 font-12 text--secondary d-block'>Quantity <span>&#42;</span></span>
                                                    <input
                                                        value={service.quantity}
                                                        onChange={(e) => handleChange(index, 'quantity', parseInt(e.target.value) || 0)}
                                                        type="text" className='form-control font-14 m-0 border--default br-10 shadow-none input' />
                                                </div>
                                                <div className='col-sm-2'>
                                                    <span className='fw-thin ms-1 font-12 text--secondary d-block'>Total</span>
                                                    <input
                                                        disabled
                                                        value={service.total}
                                                        type="text"
                                                        className='form-control font-14 m-0 border--default br-10 shadow-none input' />
                                                </div>
                                            </div>

                                            <div className='row py-2'>
                                                <span className='fw-thin ms-1 font-12 text--secondary d-block'>Note</span>
                                                <div className='col-sm-12'>
                                                    <TextArea
                                                        className="form-control br-10 input font-14 border--default shadow-none"
                                                        value={service.note}
                                                        gettext={(e) => {
                                                            handleChange(index, 'note', e);
                                                            if (e.length > 80) {
                                                                setNoteLengthError(true);
                                                            }
                                                            else {
                                                                setNoteLengthError(false)
                                                            }
                                                        }}
                                                        rows={3}
                                                        maxLength={210} />
                                                    {noteLengthError === true ? <span className='font-10 ms-1 text--danger fw-thin d-block mt-1-'>Note cannot exceed 80 characters</span> : null}
                                                </div>
                                            </div>
                                        </>
                                    })
                                }
                                <div className=' text-end'>
                                    <button
                                        onClick={() => {
                                            const slist = [...serviceData];
                                            const emptyServiceObj = {
                                                claimServiceId: '',
                                                hcpcsProcedureCode: '',
                                                hcpcsProcedureDescription: '',
                                                charges: 0,
                                                total: 0,
                                                modifiers: '',
                                                dateOfServiceFrom: '', // Add dateOfServiceFrom
                                                dateOfServiceTo: '',
                                                quantity: 1
                                            }
                                            slist.push(emptyServiceObj);
                                            setServiceData(prevState => {
                                                return slist;
                                            })

                                        }}
                                        className="btn pn-button-icon-secondary  br-10 shadow-none lh-0 py-0 ps-0 pe-1 align-items-center fw-sb text--secondary font-12">
                                        <span className="pn-button-icon-secondary pe-1"><MedicalServices color="#336383" size={14} /> </span>
                                        <span className="d-inline-block pn-link-button" style={{ verticalAlign: 'middle' }}>Add Service</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-4 border--left'>


                            <div>
                                <div className='row py-1'>
                                    <div className='col-12'>
                                        <span className='text--terciary d-block font-14 fw-sb'>Patient</span>
                                    </div>
                                </div>
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Name</div>
                                    <div className='col-7 font-12'>{`${encounter?.patient?.firstName} ${encounter?.patient?.lastName}`}</div>
                                </div>
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>DOB</div>
                                    <div className='col-7 font-12'>{calenderDateTimeHelper(encounter?.patient?.dob, "MMM DD, YYYY")}</div>
                                </div>
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Gender</div>
                                    <div className='col-7 font-12'>{`${encounter?.patient?.gender}`}</div>
                                </div>
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Address</div>
                                    <div className='col-7 font-12'>
                                        {`${encounter?.patient?.addressLine1}${encounter?.patient?.addressLine2 !== null ? `, ${encounter?.patient?.addressLine2}` : ''}, ${encounter?.patient?.city}, ${encounter?.patient?.state}, ${encounter?.patient?.zipCode}`}
                                    </div>
                                </div>

                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Phone</div>
                                    <div className='col-7 font-12'>{`${encounter?.patient?.primaryPhone}`}</div>
                                </div>


                            </div>

                            {primaryInsurance !== null ? <div>
                                <div className='row py-1'>
                                    <div className='col-12'>
                                        <span className='text--terciary d-block font-14 fw-sb'>Insurance</span>
                                    </div>
                                </div>
                                {primaryInsurance?.payerName !== null ? <div className='row pb-1'>
                                    <div className='col-4 font-12'>Payer Name</div>
                                    <div className='col-7 font-12'>{primaryInsurance?.payerName}</div>
                                </div> : null}
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Type</div>
                                    <div className='col-7 font-12'>{primaryInsurance?.type}</div>
                                </div>
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Member ID</div>
                                    <div className='col-7 font-12'>{primaryInsurance?.memberId}</div>
                                </div>
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Group</div>
                                    <div className='col-7 font-12'>{primaryInsurance?.groupNumber}</div>
                                </div>
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Effective Date </div>
                                    <div className='col-7 font-12'>    {calenderDateTimeHelper(primaryInsurance?.effectiveDate, "MMM DD, YYYY")}
                                    </div>
                                </div>


                            </div> : null}
                        </div>
                    </div>
                </div>
                <BottomNavigation disableSave={disableEdit} claimId={claimData?.claimId} patientInsuranceDataId={patientInsuranceDataId} save={saveClaim} patientId={encounter?.patient?.patientId} providerId={encounter?.provider?.providerId} tenantId={user?.tenantId} hideSaveButton={false} />
            </main>
            <Backdrop
                sx={{
                    color: "#EC609B",
                    zIndex: (theme) => theme.zIndex.drawer + 1000,
                }}
                open={isClaimSubmitting || isCheckingEnrollment}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {openClaimSnackbar ? <CustomizedSnackbars
                message={claimSnackbarMessage}
                severity={claimSnackbarSeverity}
                open={openClaimSnackbar}
                handleClose={handleClaimSnackbarClose}
            /> : null}
        </>
    )
}

const CptCodeSearchWrapper = ({ setServiceData, service, index, onRemoveCptCode, onAddCptCode }) => {
    const {
        cptSuggestions,
        setCptSuggestions,
        isCptLoading,
        setIsCptLoading,
        isCptError,
        setIsCptError,
        cptSearchInputValue,
        setCptSearchInputValue,
        cptCodeName,
        setCptCodeName,
        selectedCptCode,
        setSelectedCptCode,
        searchCptCode,
        handleCptCodeSearchInput,
        handleAddCptCode,
        handleRemoveCptCode
    } = useCptCodeSearch();




    const cptAddHandler = async (cptCode) => {
        const feeScheduleData = await onAddCptCode(index, cptCode);

        if (Object.keys(feeScheduleData?.data).length > 0) {
            // Populate the fields
            // console.log(feeScheduleData);
            setServiceData(prevState => {
                const newData = [...prevState];
                newData[index]['charges'] = parseInt(feeScheduleData?.data?.charge);
                newData[index]['quantity'] = 1;
                newData[index]['total'] = parseInt(feeScheduleData?.data?.price)
                newData[index]['hcpcsProcedureCode'] = feeScheduleData?.data?.code?.code;
                newData[index]['hcpcsProcedureDescription'] = feeScheduleData?.data?.code?.description;
                return newData;
            });

        }
        else {
            handleModal();
        }
    }

    const { open, handleModal } = useModal(false);
    return (
        <div className='col-12 col-sm-6 w-100'>
            {open ? <FeeScheduleAddModalFromClaim
                show={open}
                index={index}
                service={service}
                handleModal={handleModal}
                setServiceData={setServiceData}
            /> : null}
            <span className='fw-thin ms-1 font-12 text--secondary d-block'>CPT Codes <span>&#42;</span></span>
            <CptCodeSearch
                suggestions={cptSuggestions}
                setSuggestions={setCptSuggestions}
                isLoading={isCptLoading}
                setIsLoading={setIsCptLoading}
                isError={isCptError}
                setIsError={setIsCptError}
                searchInputValue={cptSearchInputValue}
                setSearchInputValue={setCptSearchInputValue}
                cptCodeName={cptCodeName}
                setCptCodeName={setCptCodeName}
                selectedCptCode={selectedCptCode}
                setSelectedCptCode={setSelectedCptCode}
                searchCptcode={searchCptCode}
                handleSearchInput={handleCptCodeSearchInput}
                handleAddCptCode={cptAddHandler}
                handleClearCptCode={() => setSelectedCptCode(null)}
            />
            {service?.hcpcsProcedureCode && (
                <div className='font-12 px-2 py-1 text--terciary d-flex justify-content-between'>
                    <div>{`${service.hcpcsProcedureCode} - ${service.hcpcsProcedureDescription}`}</div>
                    <div className='cross-sign' onClick={onRemoveCptCode}>
                        <X color="#aa0505" size={20} />
                    </div>
                </div>
            )}
        </div>
    );
};








export default EditPatientClaimPage
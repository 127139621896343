import { Box, Grid, Skeleton, Typography } from '@mui/material'
import React from 'react'

import classes from "../../patient/PatientItem.module.css";
import PatientEncounterItem from './PatientEncounterItem';

const PatientEncountersList = (props) => {

  return (
    <div className="row mt-5 justify-content-center mb-5 ">
      <div className="col-sm-11">
        <Grid
          container
          sx={{ marginBottom: "-8px" }}
          p={1}
          className={` bg-white ${classes["row__head"]} `}
        >
          <Grid item xs={1.5}>
            {/* <Stack direction="row" alignItems="center" spacing={2}> */}
            <Typography component="div">
              <Box
                sx={{
                  textAlign: "left",
                  m: 1,
                  ml: 0,
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: "12px",
                  color: "#336383",
                }}
              >
                Date &amp; Time
              </Box>
            </Typography>
            {/* </Stack> */}
          </Grid>
          <Grid item xs={2}>
            <Typography component="div">
              <Box
                sx={{
                  textAlign: "left",
                  mt: 1,

                  // mr: 4,
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: "12px",
                  color: "#336383",
                }}
              >
                Provider Name
              </Box>
            </Typography>
          </Grid>

          <Grid item xs={1.5}>
            <Typography component="div">
              <Box
                sx={{
                  textAlign: "left",
                  mt: 1,
                  // ml: 4,
                  fontFamily: "Montserrat",
                  fontSize: "12px",
                  color: "#336383",
                }}
              >
                Specialty
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography component="div">
              <Box
                sx={{
                  textAlign: "left",
                  mt: 1,
                  // ml: 4,
                  fontFamily: "Montserrat",
                  fontSize: "12px",
                  color: "#336383",
                }}
              >
                Classification
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography component="div">
              <Box
                sx={{
                  textAlign: "left",
                  mt: 1,
                  // ml: 4,
                  fontFamily: "Montserrat",
                  fontSize: "12px",
                  color: "#336383",
                }}
              >
                Type
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography component="div">
              <Box
                sx={{
                  textAlign: "left",
                  mt: 1,
                  // ml: 4,
                  fontFamily: "Montserrat",
                  fontSize: "12px",
                  color: "#336383",
                }}
              >
                Status
              </Box>
            </Typography>
          </Grid>
        </Grid>
        {/* {console.log(props?.recentEncountersList)} */}
        {
          props?.isLoadingEncounters ? <Skeleton animation="wave" /> : props?.recentEncountersList?.map((encounter, index) => {
            // if (encounter?.Status === 'closed' || encounter?.Status === 'started' || encounter?.Status === 'dispatched') {

            if (encounter?.encounter?.status?.toLowerCase() === 'closed') {
              if (index === (props?.recentEncountersList?.length - 1)) {
                return <PatientEncounterItem status={encounter?.encounter?.status?.toLowerCase()} setAddEncounterId={props?.setAddEncounterId} setProviderNote={props?.setProviderNote} createdDateTime={encounter?.createdDate} showEncounterActionButtons={true} viewOnly={true} key={encounter?.encounter?.encounterId} handleBackdropClose={props?.handleBackdropClose} handleToggle={props?.handleToggle} encounter={encounter} isEven={index % 2 === 0 ? true : false} isLast={true} handleAddenShowModal={props?.handleAddenShowModal} handleAddenModal={props?.handleAddenModal} handleModal={props?.handleModal} />
              }
              else {
                return <PatientEncounterItem status={encounter?.encounter?.status?.toLowerCase()} setAddEncounterId={props?.setAddEncounterId} setProviderNote={props?.setProviderNote} createdDateTime={encounter?.createdDate} showEncounterActionButtons={true} viewOnly={true} handleBackdropClose={props?.handleBackdropClose} handleToggle={props?.handleToggle} key={encounter?.encounter?.encounterId} encounter={encounter} isEven={index % 2 === 0 ? true : false} handleAddenShowModal={props?.handleAddenShowModal} handleAddenModal={props?.handleAddenModal} handleModal={props?.handleModal} />
              }
            }
            else {
              if (encounter?.providerNote?.providerNoteId !== undefined && encounter?.providerNote?.providerNoteId !== null) {
                return <PatientEncounterItem status={'closed'} setProviderNoteType={props?.setProviderNoteType} setAddEncounterId={props?.setAddEncounterId} createdDateTime={encounter?.createdDate} showEncounterActionButtons={false} viewOnly={true} key={encounter?.encounter?.encounterId} handleBackdropClose={props?.handleBackdropClose} handleToggle={props?.handleToggle} encounter={encounter} isEven={index % 2 === 0 ? true : false} isLast={false} handleAddenShowModal={props?.handleAddenShowModal} handleAddenModal={props?.handleAddenModal} handleModal={props?.handleModal} />
              }
            }
            // }

          })
        }
      </div>
    </div>
  )
}

export default PatientEncountersList
import PageHeader from "../generic/PageHeader"

const PatientEstimates = ()=>{
    return (
        <>
            <PageHeader title={"Estimates"}/>
        </>
    )
}

export default PatientEstimates;
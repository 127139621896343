import CloseIcon from "@mui/icons-material/Close";
import classes from "./IdmeVerificationModal.module.css";
import { Modal } from "react-bootstrap";
import { Typography } from "@mui/material";
import { IDmeConfigMap, ConfigMap } from "../../../../utils/helpers/ConfigMap";
import { useAuth } from "../../../hooks/useAuth";

import * as React from "react"

const IdMeSvg = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 220 43"
    style={{
      enableBackground: "new 0 0 220 43",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".st0{fill:#08833d}.st0,.st1{fill-rule:evenodd;clip-rule:evenodd}.st1,.st3{fill:#fff}"
      }
    </style>
    <g id="Page-1">
      <g id="_x30_1-Desktop" transform="translate(-302 -2846)">
        <g id="Verify-IDme" transform="translate(302 2846)">
          <g id="Group-7">
            <path
              id="Background-Copy"
              className="st0"
              d="M3.8 0h212.5c2.1 0 3.8 1.7 3.8 3.8v35c0 2.1-1.7 3.8-3.8 3.8H3.8c-2.1 0-3.8-1.7-3.8-3.8v-35C0 1.7 1.7 0 3.8 0z"
            />
            <path
              id="Background-Copy_1_"
              className="st0"
              d="M3.8 0h212.5c2.1 0 3.8 1.7 3.8 3.8v35c0 2.1-1.7 3.8-3.8 3.8H3.8c-2.1 0-3.8-1.7-3.8-3.8v-35C0 1.7 1.7 0 3.8 0z"
            />
            <g id="Group-5-Copy-3" transform="translate(43)">
              <path id="Fill-4" className="st1" d="M.1 42.5h.7V0H.1z" />
            </g>
            <g
              style={{
                enableBackground: "new",
              }}
            >
              <path
                className="st3"
                d="M65.4 15.3h2.3L64.1 26h-2.5L58 15.3h2.3l2 6.4c.1.4.2.8.3 1.3.1.5.2.8.2 1 .1-.4.2-1.2.5-2.4l2.1-6.3zM72.6 26.1c-1.3 0-2.3-.4-3.1-1.1-.7-.7-1.1-1.8-1.1-3.1 0-1.4.3-2.4 1-3.2.7-.8 1.6-1.1 2.8-1.1 1.2 0 2.1.3 2.7 1s1 1.6 1 2.7v1.1h-5.3c0 .6.2 1.1.6 1.5s.8.5 1.5.5c.5 0 1-.1 1.4-.2s.9-.3 1.4-.5v1.7c-.4.2-.8.3-1.3.4-.4.3-.9.3-1.6.3zm-.3-6.9c-.5 0-.8.2-1.1.5-.3.3-.4.7-.5 1.3h3.1c0-.6-.2-1-.4-1.3-.3-.3-.6-.5-1.1-.5zM82.3 17.7c.3 0 .6 0 .8.1l-.2 2.1c-.2 0-.4-.1-.7-.1-.7 0-1.3.2-1.7.5-.4.4-.6.9-.6 1.5V26h-2.2v-8.2h1.7l.3 1.4h.1c.3-.5.6-.8 1-1.1.6-.3 1-.4 1.5-.4zM84.5 15.7c0-.7.4-1.1 1.2-1.1s1.2.4 1.2 1.1c0 .3-.1.6-.3.8-.2.2-.5.3-.9.3-.8 0-1.2-.4-1.2-1.1zM86.8 26h-2.2v-8.2h2.2V26zM93.7 19.5h-1.9V26h-2.2v-6.5h-1.2v-1.1l1.2-.6v-.6c0-.9.2-1.6.7-2s1.2-.6 2.2-.6c.8 0 1.5.1 2.1.3l-.6 1.6c-.4-.1-.9-.2-1.2-.2-.3 0-.5.1-.7.3-.1.2-.2.4-.2.7v.5h1.9v1.7z"
              />
              <path
                className="st3"
                d="M93.8 17.8h2.4l1.5 4.6c.1.4.2.9.3 1.4.1-.5.2-1 .3-1.4l1.5-4.6h2.4L98.9 27c-.3.9-.8 1.5-1.4 1.9-.6.4-1.3.6-2.1.6-.4 0-.8 0-1.1-.1v-1.8c.3.1.6.1.9.1.4 0 .7-.1 1-.4.3-.2.5-.6.7-1.1l.1-.4-3.2-8zM114.1 26l-.6-2.9-.8-3.6h-.1l-1.5 6.5h-2.4l-2.3-8.2h2.2l.9 3.6c.2.6.3 1.5.5 2.7 0-.4.1-1 .3-1.8l.1-.6 1-3.9h2.5l1 3.9c0 .1 0 .3.1.5 0 .2.1.4.1.7 0 .2.1.5.1.7 0 .2 0 .4.1.5 0-.4.1-.8.2-1.4.1-.6.2-1 .2-1.2l1-3.6h2.2l-2.4 8.2h-2.4zM120.1 15.7c0-.7.4-1.1 1.2-1.1s1.2.4 1.2 1.1c0 .3-.1.6-.3.8-.2.2-.5.3-.9.3-.8 0-1.2-.4-1.2-1.1zm2.4 10.3h-2.2v-8.2h2.2V26zM128.3 24.4c.4 0 .9-.1 1.4-.3v1.7c-.6.2-1.2.4-2.1.4-.9 0-1.5-.2-2-.7-.4-.5-.6-1.1-.6-2v-3.9h-1v-.9l1.2-.7.6-1.7h1.4V18h2.3v1.7h-2.3v3.9c0 .3.1.6.3.7.2 0 .5.1.8.1zM138.9 26h-2.2v-4.8c0-1.2-.4-1.8-1.3-1.8-.6 0-1.1.2-1.4.6s-.4 1.1-.4 2.1V26h-2.2V14.6h2.2v2.3c0 .2 0 .6-.1 1.3l-.1.7h.1c.5-.8 1.3-1.2 2.4-1.2 1 0 1.7.3 2.2.8.5.5.7 1.3.7 2.2V26z"
              />
            </g>
            <g id="idme-wallet-copy-2" transform="translate(147 12)">
              <path
                id="Shape"
                className="st3"
                d="M2.1 0h-.8C.4 0 0 .3 0 .9v15.2c0 .6.4.9 1.3.9H2c.9 0 1.3-.3 1.3-.9V.9C3.4.3 2.9 0 2.1 0z"
              />
              <path
                id="Shape_1_"
                className="st3"
                d="M16.1 15.2c0-1.8 1.2-3.4 2.9-3.9.2-.8.3-1.8.3-2.8C19.3 3 16.4 0 11.2 0H5.7c-.8 0-1.2.3-1.2 1.1v14.8c0 .7.4 1.1 1.1 1.1h5.5c2 0 3.6-.4 4.9-1.3.1-.1.1-.3.1-.5zm-4.9-1.6H8.7V3.4h2.5c3.2 0 3.9 2.8 3.9 5.1s-.7 5.1-3.9 5.1z"
              />
              <circle id="Oval" className="st3" cx={19.8} cy={15.3} r={1.7} />
              <path
                id="Shape_2_"
                className="st3"
                d="M46.2 12.4H46c-.3 0-.4.1-.6.3-.1.1-.2.3-.3.5-.3.6-.7 1.2-1.2 1.6-.7.6-1.7.9-2.5.7-.5-.1-.8-.5-1.1-.7-.4-.5-.6-1.3-.5-2.1 1.2-.1 4.9-.7 5.3-3.5.1-.5-.1-1.1-.4-1.5-.4-.5-1.2-.8-2-.8-2.5 0-4.9 2.6-5.2 5.6-.1.8 0 1.6.3 2.3-.2.2-.4.3-.6.4-.2.1-.4.1-.5.1-.2-.1-.2-.3-.3-.4-.1-.5 0-1.2.2-1.8.1-.4.2-.8.3-1.1.3-1.1.6-2.2.5-3.3-.1-1-.8-1.7-1.9-1.7C34 7 33 8.1 32.4 9c0-.7-.2-1.2-.5-1.5s-.7-.5-1.3-.5c-1.5 0-2.4 1-3.1 1.9v-.3c0-.4.1-.9-.3-1.3-.2-.2-.4-.3-.8-.3-.3 0-.4 0-.7.1 0 0-.3.1-.4.2-.2.2-.1.5-.1.7v.6c-.1 1.1-.3 2.2-.4 3.1l-.3 1.5c-.3 1.1-.5 2.3-.6 3.4 0 .1 0 .2.1.3.1.1.2.1.3.1h.1c.8 0 1.6 0 1.8-.5.2-.5.3-1.1.4-1.7l.1-.3c.2-1.3.4-2.2.9-3.3.2-.6.6-1.1.9-1.5.4-.5.8-1 1.3-1 .2 0 .3.1.4.2.4.6-.1 2.6-.4 3.5-.1.2-.1.3-.1.5l-.2.7c-.3 1-.5 1.9-.6 3v.4l.1.1c.2.2 1.7-.1 1.7-.1.5-.2.7-.7.7-.9.1-.5.2-.9.3-1.3.2-.8.3-1.7.6-2.5.5-1.6 1.2-2.7 1.9-3.3.3-.2.7-.3.8-.1.3.3.1 1.3 0 1.7-.1.5-.2 1.1-.4 1.7-.1.3-.2.7-.3 1.1-.2 1.1-.4 2.4.2 3.2.3.4.8.6 1.4.6.7 0 1.3-.2 2-.7.2-.1.4-.3.6-.5.8.9 1.6 1.3 2.9 1.3 2.8 0 4.1-1.9 4.9-3.3l.3-.6c-.1-.6-.2-.9-.4-1zm-3.5-3.6V9.1c0 .2-.1.5-.1.7-.4 1.3-1.5 1.6-2.5 1.6.1-.6.3-1.1.6-1.6.5-1 1.1-1.6 1.6-1.6h.2l.1.1v.4c.1 0 .1 0 .1.1z"
              />
            </g>
          </g>
          <g id="Group-15" transform="translate(11 11)">
            <path
              id="Shape_3_"
              className="st3"
              d="M10.5 21C4.7 21 0 16.3 0 10.5S4.7 0 10.5 0c1.9 0 3.8.5 5.5 1.6.5.3.7 1 .4 1.5-.3.5-1 .7-1.5.4-1.3-.8-2.8-1.2-4.3-1.2C6 2.3 2.3 6 2.3 10.6s3.7 8.3 8.3 8.3 8.3-3.7 8.3-8.3c0-.6-.1-1.2-.3-1.9-.1-.6.2-1.2.8-1.3.6-.1 1.2.2 1.3.8.2.9.3 1.7.3 2.4C21 16.3 16.3 21 10.5 21z"
            />
            <path
              id="Path"
              className="st1"
              d="M10.5 13.7 21.7 2.9c.5-.4.5-1.1 0-1.6-.5-.4-1.2-.4-1.6 0l-10.3 10L7 8.7c-.5-.4-1.2-.4-1.6 0-.5.4-.5 1.1 0 1.6l3.5 3.4c.2.2.5.3.8.3s.6-.1.8-.3z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)



const IdmeVerificationModal = (props) => {

  const { user } = useAuth();

  return (
    <>

      <Modal
        show={props?.show}
        onHide={() => {
          props.handleModal();
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        backdropClassName="modal-backdrop"
        contentClassName="modal-border modal-shadow"
        // dialogClassName={classes.root}
        centered
      >
        <Modal.Header className="modal-header" >
          <span
            style={{
              lineHeight: "12px",
              width: "18px",
              fontSize: "8pt",
              color: "#336383",
              position: "absolute",
              top: 15,
              right: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              props?.handleModal();
            }}
          >
            {<CloseIcon />}
          </span>
          <Modal.Title className="font-20 fw-sb">
            {"Identity Verification"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="com-md-12 ">
            <div className="row justify-content-center p-2 ">
              <div className="row " style={{ minHeight: "38vh" }}>
                <Typography
                  style={{
                    fontSize: 14,
                    color: "#336383",
                    fontFamily: "Montserrat",
                    m: 0,
                  }}
                >
                  {"To ensure security for you and your patients, we have partnered with ID.me to verify your identity and medical credentials. You will need the following to complete this process:"}
                  <ul className={`mt-2 ${classes["ul-style"]}`}>
                    <li className="text--secondary">
                      {"A valid government issued photo ID"}
                    </li>
                    <li className="text--secondary">
                      {"Social security number"}
                    </li>
                    <li className="text--secondary">
                      {"Access to a smartphone or web camera"}
                    </li>
                  </ul>
                  {"Please click below to sign up for an ID.me account (or log in to your existing one). This will launch ID.me, which will guide you through the verification process and will redirect you back to PrescribeNow once verification is complete."}
                </Typography>

                <div className="row justify-content-center mt-4 align-self-center">
                  <button
                    className={`btn fw-sb p-0 shadow-none`}
                    style={{ width: '55%' }}
                    onClick={() => {
                      // window.open("https://api.idmelabs.com/oauth/authorize?client_id=dbaf5cca56458af897269570d0754436&redirect_uri=https://www.prescribenow.com/idme/callback&response_type=code&scope=openid%20http://idmanagement.gov/ns/assurance/ial/2/aal/2/erx", '_blank', 'noopener,noreferrer');
                      window.open(ConfigMap("APP") + IDmeConfigMap("APP") + `?tenantId=${user?.tenantId}`, "_parent");
                      props.handleModal();
                    }}
                  >
                    <IdMeSvg />
                  </button>
                  <p style={{ fontFamily: 'Helvetica', fontSize: '10px', textJustify: 'justify', color: '#757575' }} className="pt-2">
                    ID.me is our trusted technology partner in helping to keep your personal information safe. They specialize in digital identity protection and help us make sure you're you—and not someone pretending to be you—before we give you access to your information.
                  </p>

                </div>


              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default IdmeVerificationModal;

import moment from "moment";
import { dateTimeHelper } from "../../../utils/dateTimeHelper";
import { useAuth } from "../../hooks/useAuth";
import useMediaQuery from "../../hooks/useMediaQuery";
const GreetingMessage = () => {
  const { user } = useAuth();
  const isMobile = useMediaQuery("(max-width: 1345px)");

  // const getMapFromSession = async () => {
  //   setIsLoading(false);
  //   const httpResponse = await sendHttpRequest({
  //     url: "/session",
  //     method: "GET",
  //   });
  //   if (!httpResponse.error) {
  //     setIsLoading(true);
  //   } else {
  //   }
  // };

  return (
    <div className="mt-3">
      <p className="font-14 text-muted color_Date mb-0">
        {dateTimeHelper(moment(), "dddd, MMM D, YYYY")}
      </p>
      <h1 className={`${isMobile ? 'font-18' : 'font-22'}`}>
        Good day, {user?.prefix === undefined ? "" : user?.prefix}{" "}
        {user?.lastName === null
          ? ""
          : user?.lastName === undefined
            ? ""
            : user?.lastName}
      </h1>
    </div>
  );
};

export default GreetingMessage;

import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { jsonToMap } from "../../../utils/helpers/HelperFunctions";
import { useAuth } from "../../hooks/useAuth";
import Layout from "../../layouts/Layout";
import { VideoCallContext } from "../AgoraHooks/VideoCallContext";

export const ProtectedRoutes = () => {
    const { user, sessionExpired } = useAuth();
    const videoCallContext = useContext(VideoCallContext)
    const map = jsonToMap(user);


    if (map.get("sessionExpired")) {
        sessionExpired();
    }
    else if (!user) {
        return <Navigate to="/" />;
    } else {
        if (map.get("role") === "patient") {
            return <Navigate to="/patient-portal/dashboard" />;
        }
    }
    return (
        <Layout isVideoCallActive={videoCallContext.getIsVideoCallActive()} >
            <Outlet />
        </Layout>
    )
};
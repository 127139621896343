import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { isOnlySpaces } from "../../../../utils/helpers/HelperFunctions";
import TextArea from "../../../forms/TextArea";
import { useState } from "react";

const ForwardReceiptModal = (props) => {
  const [disable, setDisable] = useState(false);

  const setReceiverEmail = (event) => {
    props.setReceiverEmail(event.target.value);
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          props.handleForwardReceiptCloseModal();
        }}
        size="md"
        keyboard={false}
        backdropClassName="modal-backdrop"
        contentClassName="modal-border modal-shadow"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="modal-header" closeButton>
          <Modal.Title className="font-18 text--terciary">
            Forward Receipt
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <form
              id="forwardReceiptForm"
              onSubmit={async (e) => {
                e.preventDefault();
                setDisable(true);
                await props.forwardReceipt(props.patientBillId);
                setDisable(false);
                // props.handleAddenModal();
              }}
            >
              <div className="row">
                <div className="col-sm-12">
                  <div>
                    {/* <h3 className='font-14 m-0'>Title</h3> */}
                    {/* <TextArea className="form-control shadow-none" placeholder={""} style={{ border: "1px solid #D9E8EC" }} gettext={gettext} /> */}
                    <h3
                      htmlFor="addedndumtitle"
                      className="form-label font-14 m-0"
                    >
                      Email
                    </h3>
                    <input
                      type="text"
                      required
                      className="form-control input br-10 shadow-none border--default"
                      placeholder={"Enter email"}
                      id="addendumtitle"
                      onChange={(e) => {
                        setReceiverEmail(e)
                      }}
                      aria-describedby="addedndumTitle"
                      onBlur={(e) => {
                        if (isOnlySpaces(e.target.value)) {
                          e.target.value = "";
                        }
                      }}
                      defaultValue={props.emailValue}
                    //   value={props.emailValue}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>

        <Modal.Footer style={{ border: "none" }}>
          <button
            className="btn text-white b2 px-4 br-10 py-1 blue_task_button"
            type="submit"
            form="forwardReceiptForm"
            disabled={disable}
          >
            {disable ? `Sending` : `Send`}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ForwardReceiptModal;

// import moment from "moment";
import moment from "moment-timezone";
import { DateTime } from "luxon";
import { fieldValidation } from "./helpers/HelperFunctions";

export const dateTimeHelper = function (utcDateTime, format) {
  format = converToMoment(format);
  let date = '';
  let user = JSON.parse(localStorage.getItem("user"));
  // moment(utcDateTime).toISOString();
  if (user !== null) {
    // date = moment.utc(utcDateTime).local().format(format);
    date = moment.utc(utcDateTime).utcOffset(user.timezone).format(format);
  }
  return date;
};

export const convertUTCtoTimzone = function (utcDateTime, timezone, format) {
  format = converToMoment(format);
  if (timezone !== "") {
    return moment.utc(utcDateTime).utcOffset(timezone).format(format);
  } else {
    return changeDateTimeFormat(utcDateTime, format);
  }
};

export const convertUTCtoTimzoneLuxon = function (utcDateTime, timezone) {
  //very bad code
  //problem is luxon do not convert date to proper timezone without time. 
  let currentTime = DateTime.now().toISO().split("T")[1].split("+")[0];
  let currentDateTime = utcDateTime + "T" + currentTime.split(":")[0] + ":" + currentTime.split(":")[1];
  if (DateTime.fromISO(currentDateTime).setZone(timezone).toISO() !== "") {
    return DateTime.fromISO(currentDateTime).setZone(timezone);
  } else {
    return "";
  }
};

export const changeDateTimeFormat = function (dateTime, format) {
  format = converToMoment(format);
  return moment(dateTime).format(format);
};

export const changeDateTimeFormat2 = function (dateTime, format) {
  return DateTime.fromISO(dateTime).toFormat(format);
};

export const getTimeZoneOffset = function () {
  let user = JSON.parse(localStorage.getItem("user"));
  return user.timezone.split(' ')[user.timezone.split(' ').length - 1];
}

const converToMoment = function (format) {
  let formatArray = ['dddd, MMM D, YYYY', 'YYYY-MM-DDTHH:mm', 'HH:mm', 'dddd, MMM D, yyyy', 'ddd, MMM D, yyyy', 'ddd, MMM D, YYYY'];
  if (fieldValidation(format, "un") && !formatArray.includes(format)) {
    format = format?.replace("hh:mm", "LT");
    format = format?.replaceAll("m", "M");
    format = format?.replaceAll("y", "Y");
    format = format?.replaceAll("d", "D");
  }

  return format;
}

export const calenderDateTimeHelper = function (utcDateTime, format) {
  format = converToMoment(format);
  // console.log("local date =>",moment.utc(utcDateTime).local().format(format));
  return moment(utcDateTime).format(format);
};

export const addTimeHelper = function (timeValue, addMinutes) {
  return moment(timeValue, "h:mm LT").add(addMinutes, "minutes").format("LT");
};
export const getNextMonth = function (currentMonth, incrementValue) {
  return moment(currentMonth, "YYYY-MM-DD")
    .add(incrementValue, "months")
    .format("YYYY-MM");
};
export const getMonthNameWithYear = function (dateInput, incrementValue) {
  return moment(dateInput, "YYYY-MM-DD")
    .add(incrementValue, "months")
    .format("MMMM YYYY");
};
export const getAgeByDateOfBirth = function (dateOfBirth) {
  return moment().diff(dateOfBirth, "years", false);
};
export const getMonthByDate = function (date) {
  return moment(date).format("MM");
};
export const getLocalTimeFromUTC = function (timeValue, addMinutes) {
  return moment(timeValue, "h:mm LT").add(addMinutes, "minutes").format("LT");
};

export const getLocalTimeInUTC = function (date, format) {
  if (format) {
    // format = converToMoment(format);
    return moment.utc(date).format("YYYY-MM-DDTHH:mm:ss");
  } else {
    return moment.utc(date);
  }
};

export const getLocalDateFromUTC = function (utcDate, format) {
  format = converToMoment(format);
  // console.log("local date =>",moment.utc(utcDate).local().format(format));
  return moment.utc(utcDate).local().format(format);
};

export const getTimeDifference = (startTimeValue, endTimeValue) => {
  let startTime = moment(startTimeValue).toISOString();
  let endTime = moment(endTimeValue).toISOString();

  var end = DateTime.fromISO(endTime);
  var start = DateTime.fromISO(startTime);
  var diffInSeconds = end.diff(start, "seconds");

  // let duration = moment.duration(endTime.diff(startTime));
  let duration = diffInSeconds.toObject();
  return duration;
};

export const getUTCTime = (date, time) => {
  let user = JSON.parse(localStorage.getItem("user"));
  let fp = user.timezone.slice(1, 4);
  let lp = user.timezone.slice(4, 6);
  let tz = fp + ":" + lp;

  let datetime = date + "T" + time + ":00" + tz;
  // const d = new Date(datetime);
  const utcTime = moment.utc(datetime).format("YYYY-MM-DDTHH:mm");
  return utcTime;
};

export const getDMYformate = (date, format) => {
  format = converToMoment(format);
  return moment(date).format(format)
}

export const dateDifference = (date1, date2) => {
  let d1 = moment(date1, "YYYY-MM-DD")
  let d2 = moment(date2, "YYYY-MM-DD")
  return d2.diff(d1, 'days')
}

export const addDays = (dateValue, daysValue) => {

  let currentDate = moment(dateValue);
  return currentDate.add(daysValue, 'days').format("YYYY-MM-DD");
}

export const decreaseDays = (dateValue, daysValue) => {
  let currentDate = moment(dateValue);
  return currentDate.subtract(daysValue, 'days').format("YYYY-MM-DD")
}
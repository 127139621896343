import CountUp from 'react-countup';

const ClaimStatusCard = (props) => {
  return (
    <>
      <div className="row">
        <div className="col-1"></div>
        <div className={`${props?.practiceAdmin ? "col-7":"col-6"} br-10  fw-sb ${props.badgeClass} text--terciary text--center font-12 mt-2`}>
          <span >{props.claimText}</span>
        </div>
        <div className="col-4"></div>
      </div>
      <div className="row d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
        <div className="col-12 d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
          <span className={`${props?.infoColor} font-48 fw-sb`}><CountUp end={props.claimPercentage} duration={3} />{props?.practiceAdmin  ?  "":"%"}</span>
        </div>
      </div>
      <div className="row text-center" hidden={props?.practiceAdmin}>
        <div className="col-1"></div>
        <div className={`col-5 br-10 ${props?.badgeClass}`}>
          <span className="fw-sb font-14"><CountUp end={props.noOfClaims} duration={3} /> {"claims"}</span>
        </div>
      </div>
    </>
  );
}

export default ClaimStatusCard;

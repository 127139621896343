import { Modal } from "react-bootstrap";
import classes from "./TwoFAModal.module.css";
import InputMask from 'react-input-mask';
import { useEffect, useState } from 'react';
import sendHttpRequest from "../../../hooks/sendHttpRequest";
import { truncateWithStartIndexGiven } from "../../../../utils/helpers/HelperFunctions";

function PhoneInput(props) {
    return (
        <InputMask
            mask='(999) 999-9999'
            value={props.value}
            onChange={props.onChange}
            maskChar="_"
            className="form-control font-14 border--default br-10 shadow-none input"
            onInvalid={(e) => { e.target.setCustomValidity('Please fill out this field') }}
            onInput={(e) => { e.target.setCustomValidity('') }}
            {...props}
        >
        </InputMask>
    );
}

const TwoFAModal = (props) => {

    const [securityHandler, setSecurityHandler] = useState();
    const [step, setStep] = useState(1);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [cellPhone, setCellPhone] = useState(props?.mobilePhone?.number !== null && props?.mobilePhone?.number);

    const cellPhoneHandler = (event) => {
        setCellPhone(event.target.value);
    };

    // console.log(cellPhone);

    const securityCodeHandler = (event) => {
        if(event.target.value.length <= 6){
            setSecurityHandler(event.target.value);
        }
    };

    const incrementStep = () => {

        if (step === 1) {
            twoFactorValidate();
        } else if (step === 2) {
            clearStates();
        }

        // if (step < 2) {
        //     setStep(step + 1);
        // } else {
        //     clearStates();
        // }
    };
 
    const twoFactorValidate = async () => {
        setIsLoading(true);
        const httpResponse = await sendHttpRequest({
            url: "/auth/twofactor/validate",
            method: "PUT",
            data: {
                token: securityHandler,
                // number: cellPhone
            }
        });
        if (!httpResponse.error) {
            console.log("step");
            setStep(step + 1);
            props?.setTwoFactorState(true);
            // props?.fetchUserProfile();
            setError("");
            // console.log(httpResponse.data);
        } else {
            setError(httpResponse.errorMessage.message);
        }
        setIsLoading(false);
    };

    const clearStates = () => {
        setCellPhone("");
        setStep(-1);
        setSecurityHandler("");
        setError("");
        props?.handleModal();
    }

    useEffect(() => {
        setCellPhone(props?.mobilePhone?.number !== null && props?.mobilePhone?.number);
        return (() => {
            clearStates();
        })
    }, [props]);

    return (
        <Modal
            show={props?.show}
            onHide={() => {
                clearStates();
            }}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            dialogClassName={`${classes["custom-modal-width"]}`}
            contentClassName={`${classes["custom-modal-height"]}`}
        >
            <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row text-center justify-content-center">
                        <div className="col-12">
                            <p className="h18 fw-sb m-0">{props?.headerTitle}</p>
                        </div>
                    </div>
                    <div className="row text-center">
                        <div className="col-12">
                            {
                                // step === 0 ? <span className="d-block font-12 text--secondary">Enter your 10-digit cell phone number where you would like to receive your security code.</span>:
                                step === 1 ? <>
                                        <span className="d-block font-12 text--secondary">Enter the 6-digit code sent to</span>
                                        <span className="d-block font-12 text--secondary">your registered number</span>
                                        <span className="font-14 fw-sb text--tertiary">(XXX)-XXX {truncateWithStartIndexGiven(cellPhone, cellPhone?.length - 4)}</span>
                                    </>
                                        : step === 2 ? <span className="d-block font-12 text--secondary">Two-Step Verification is now enabled for your account.</span> : ""
                            }
                            <span className="d-block text--danger font-12">
                                {error!== "" ? error : ""}
                            </span>
                            {/* <div className="mt-2" hidden={step === 1 || step === 2 ? true : false}>
                                <span className="d-block text--secondary">
                                    <PhoneInput defaultValue={props?.mobilePhone?.number} onChange={cellPhoneHandler} id="cellPhone"></PhoneInput>
                                </span>
                            </div> */}
                            <div className="mt-2" hidden={step === 2 ? true : false}>
                                <span className="d-block text--secondary">
                                    <input 
                                        className="form-control font-12 border--default br-10 shadow-none input"
                                        type="number" 
                                        onChange={securityCodeHandler} 
                                        maxLength="6" 
                                        id="code" 
                                        value={securityHandler}
                                        autoFocus={true}
                                        style={{textAlign:"center", fontWeight:600, fontSize: 14}}
                                        onKeyPress={(e)=>{
                                            if (e.code === "Enter" ) {
                                                incrementStep();
                                            }
                                        }}
                                        ></input>
                                </span>
                            </div>
                            <div className="mt-2 text-center">
                                <button disabled={isLoading} onClick={() => { incrementStep(); }} className={` btn blue_task_button br-10 font-12 ${classes.next_button}`}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* {console.log("props.medicineName",props.medicineName)} */}
            </Modal.Body>
        </Modal >
    );
};

export default TwoFAModal;

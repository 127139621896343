import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useRef, useState } from "react";
import options from "../../../../utils/data";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import sendHttpRequest from "../../../hooks/sendHttpRequest";
import InputMask from "react-input-mask";
// import FacesheetPicker from "../../General/FacesheetPicker";
import CustomDatePicker from "../../General/CustomDatePicker";
import PhoneInput from "../../Reusable/PhoneNumber";
import { isPalindrome, isValidZipCode } from "../../../../utils/helpers/HelperFunctions";

import {
  addObjectToArray,
  createIfNotExists,
  fieldValidation,
  formatPhoneNumber,
} from "../../../../utils/helpers/HelperFunctions";
import { numbersEnum } from "../../../../res/strings";
import { useAuth } from "../../../hooks/useAuth";
import { useDispatch } from "react-redux";
import { setDirtyBitInStore } from "../../../../redux/actions/providerActions";
import { isNaN } from "lodash";
import CalenderDatePicker from "../../General/CalenderDatePicker";

const NewPatientFacesheetModal = (props) => {
  const _numbersEnum = numbersEnum["en"];
  const { user } = useAuth();
  console.log(props?.facesheetContent);
  const [patient, setPatient] = useState(props?.facesheetContent);
  const [firstName, setFirstName] = useState(patient?.firstName ?? "");
  const [lastName, setLastName] = useState(patient?.lastName ?? "");
  let date;
  let year;
  let month;
  let day;
  let joined;
  try {
    date = new Date(patient?.patientDateOfBirth);
  }
  catch (ex) {
    console.error(ex);
  }
  if (date !== undefined && !isNaN(date?.getFullYear()) && !isNaN(date?.getMonth()) && !(isNaN(date?.getDate()))) {

    year = date.getFullYear();
    month = String(date.getMonth() + 1).padStart(2, "0");
    day = String(date.getDate()).padStart(2, "0");
    joined = [year, month, day].join("-");
  }
  else {
    date = undefined;
  }

  const [dob, setDob] = useState(date !== undefined ? joined : "");
  const [ssn, setSsn] = useState(patient?.patientSsn ?? "");
  const [gender, setGender] = useState(patient?.patientSex ?? "");
  const [pharmacy, setPharmacy] = useState("");
  const [pharmacies, setPharmacies] = useState("");
  const [addressLine1, setAddressLine1] = useState(
    patient?.streetAddress?.concat(" ", patient?.streetAddressLineTwo || "") ??
    ""
  );

  const [city, setCity] = useState(patient?.city ?? "");
  const [state, setState] = useState(
    [
      options.find(o => o.code === patient?.state?.trim())
    ]
  );
  // console.log([options[options.findIndex((item)=>{return item.code == patient?.State?.trim()})]]);
  const [zipCode, setZipCode] = useState(patient?.zipCode || "");
  const [landline, setLandline] = useState("");
  const [cellPhone, setCellPhone] = useState(
    formatPhoneNumber(patient?.patientCellPhone) ?? ""
  );
  // console.log({cellPhone:cellPhone, PatientCellPhone:patient?.PatientCellPhone});
  const [emergencyContact, setEmergencyContact] = useState("");
  const [email, setEmail] = useState(null);
  const [serverErrors, setServerErrors] = useState(null);
  const firstNameRef = useRef();
  const [isSending, setIsSending] = useState(false);
  const [consent, setConsent] = useState(false);
  const dispatch = useDispatch();
  const [regexErrors, setRegexErrors] = useState(
    {
      'zipcode': false
    }
  );

  const addNewPatientRequestHandler = async (patientObject) => {
    setIsSending(true);
    const httpResponse = await sendHttpRequest({
      url: "/patient/create/facesheet",
      method: "POST",
      data: {
        ...patientObject,
      },
      params: {
        faceSheetId: patient?.id
      }
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.status);
      props.setIsSent(true);
      props?.handleFacesheetPatientDependency();
      if (props?.fetchPatients !== null) {
        props.fetchPatients();
      }
      setIsSending(false);
      props.handleNewPatientModalClose();
    } else {
      // console.log(httpResponse.errorMessage);
      setServerErrors(httpResponse?.errorMessage);
      setIsSending(false);
    }
  };

  const fetchAllPharmacies = async () => {
    // setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/pn-pharmacy",
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
      setPharmacies(httpResponse.data);
    } else {
      console.log(httpResponse.error);
      // setError(httpResponse.error);
    }
    // setIsLoading(false);
  };

  const firstNameHandler = (event) => {
    setFirstName(event.target.value);
  };
  const lastNameHandler = (event) => {
    setLastName(event.target.value);
  };
  const ssnHandler = (event) => {
    setSsn(event.target.value);
  };
  const genderHandler = (event) => {
    setGender(event.target.value);
  };
  const pharmacyHandler = (event) => {
    setPharmacy(event.target.value);
  };
  const zipCodeHandler = (event) => {
    setZipCode(event.target.value);
    if (isValidZipCode(event.target.value)) {
      setRegexErrors((previousSnapshot) => ({ ...previousSnapshot, "zipcode": false }))
      dispatch(setDirtyBitInStore(true));
      setZipCode(event.target.value);
    }
    else {
      if (event.target.value === '') {
        setRegexErrors((previousSnapshot) => ({ ...previousSnapshot, "zipcode": false }))
      }
      else {
        dispatch(setDirtyBitInStore(true));
        setRegexErrors((previousSnapshot) => ({ ...previousSnapshot, "zipcode": true }))
      }
    }
  };
  const cityHandler = (event) => {
    setCity(event.target.value);
  };
  const addressLine1Handler = (event) => {
    setAddressLine1(event.target.value);
  };

  const landlineHanlder = (event) => {
    setLandline(event.target.value);
  };
  const cellPhoneHandler = (event) => {
    setCellPhone(event.target.value);
  };

  const emergencyContactHandler = (event) => {
    setEmergencyContact(event.target.value);
  };

  const emailHandler = (event) => {
    setEmail(event.target.value);
  };

  const dobHandler = (d) => {
    setDob(d);
  };

  const consentHandler = (event) => {
    setConsent(!consent);
  };

  useEffect(() => {
    firstNameRef.current.focus();
    fetchAllPharmacies();
  }, []);

  const customProps = {
    date: dob
  }

  const addPatientHandler = () => {
    let tempPatientObject = {};
    tempPatientObject.emailConsent = consent;
    if (gender !== "Select" && fieldValidation(gender, "all")) {
      tempPatientObject.gender = gender;
    }
    if (pharmacy !== "Select" && fieldValidation(pharmacy, "all")) {
      tempPatientObject.preferredPharmacy = pharmacy;
    }
    if (fieldValidation(dob, "all")) {
      tempPatientObject.dateOfBirth = dob;
    }
    if (fieldValidation(landline, "all")) {
      const tempNumber = {
        number: landline,
        type: _numbersEnum.homeTelephoneExt,
      };
      createIfNotExists(tempPatientObject, "communicationNumbers", "object");
      createIfNotExists(tempPatientObject["communicationNumbers"], "numbers", "array");
      addObjectToArray(tempPatientObject?.communicationNumbers?.numbers, tempNumber);
    }
    if (fieldValidation(cellPhone, "all")) {
      const tempNumber = { number: cellPhone, type: _numbersEnum.cellPhoneExt };
      createIfNotExists(tempPatientObject, "communicationNumbers", "object");
      createIfNotExists(tempPatientObject?.communicationNumbers, "numbers", "array");
      addObjectToArray(tempPatientObject?.communicationNumbers?.numbers, tempNumber);
    }
    if (fieldValidation(emergencyContact, "all")) {
      const tempNumber = {
        number: emergencyContact,
        type: _numbersEnum.emergencyTelephoneExt,
      };
      createIfNotExists(tempPatientObject, "communicationNumbers", "object");
      createIfNotExists(tempPatientObject?.communicationNumbers, "numbers", "array");
      addObjectToArray(tempPatientObject?.communicationNumbers?.numbers, tempNumber);
    }

    if (fieldValidation(email, "all")) {
      createIfNotExists(tempPatientObject, "communicationNumbers", "object");
      createIfNotExists(tempPatientObject?.communicationNumbers, "electronicMail", "array");
      addObjectToArray(tempPatientObject?.communicationNumbers?.electronicMail, email);
    }

    if (fieldValidation(ssn, "all")) {
      createIfNotExists(tempPatientObject, "identification", "object");
      tempPatientObject.identification.socialSecurity = ssn;
    }

    if (fieldValidation(firstName, "all") && fieldValidation(lastName, "all")) {
      createIfNotExists(tempPatientObject, "name", "array");
      addObjectToArray(tempPatientObject?.name, { firstName: firstName, lastName: lastName, type: "cn" });
    }
    const tempAddress = {};
    tempAddress['type'] = "ca";
    if (fieldValidation(addressLine1, "all")) {
      createIfNotExists(tempPatientObject, "address", "array");
      tempAddress['addressLine1'] = addressLine1;
    }
    if (fieldValidation(city, "all")) {
      createIfNotExists(tempPatientObject, "address", "array");
      tempAddress['city'] = city;
    }
    if (fieldValidation(state, "all") && fieldValidation(state[0], "un")) {
      tempAddress['stateProvince'] = state[0].code;
    }
    if (fieldValidation(zipCode, "all")) {
      tempAddress['postalCode'] = zipCode;
    }
    if (Object.keys(tempAddress).length > 1) {
      createIfNotExists(tempPatientObject, "address", "array");
      addObjectToArray(tempPatientObject?.address, tempAddress);
    }

    tempPatientObject.tenant = user?.tenant;

    addNewPatientRequestHandler(tempPatientObject);
  };

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.handleNewPatientModalClose();
      }}
      backdropClassName="modal-backdrop"
      contentClassName="modal-border modal-shadow"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"

    // style={{style}}
    >
      <Modal.Header className="modal-header">
        <span
          style={{
            lineHeight: "12px",
            width: "18px",
            fontSize: "8pt",
            color: "#336383",
            position: "absolute",
            top: 15,
            right: 15,
            cursor: "pointer",
          }}
          onClick={() => {
            props.handleNewPatientModalClose();
          }}
        >
          {<CloseIcon />}
        </span>

        <Modal.Title className="font-20 fw-sb text--terciary">
          Add Patient
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid px-3 ">
          {/* <div className="row ">
            <div className="col-12 ">
              
            </div>
          </div> */}

          <form
            id="patientFacesheetForm"
            onSubmit={(e) => {
              e.preventDefault();
              addPatientHandler();
            }}
          >
            <div className="row mt-2  ">
              <div className="col-md-6">
                <label
                  htmlFor="FirstName"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  First Name <span>&#42;</span>
                </label>
                <input
                  type="text"
                  onChange={firstNameHandler}
                  ref={firstNameRef}
                  className={`form-control font-14 m-0 border--default br-10 shadow-none input`}
                  id="FirstName"
                  value={firstName}
                  // onBlur={firstNameBlur}
                  required
                />
                {/* {serverErrors !== null && serverErrors?.map((error) => { return error.attributeName === "FirstName" && <span className="text--danger font-10 ms-2 mt-0 lh-0"> {error.message} </span> })} */}
              </div>
              <div className="col-md-6 ">
                <label
                  htmlFor="LastName"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Last Name <span>&#42;</span>
                </label>
                <input
                  type="text"
                  onChange={lastNameHandler}
                  autoComplete="false"
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="LastName"
                  value={lastName}
                  required
                />
                {/* {serverErrors !== null && serverErrors?.map((error) => { return error.attributeName === "LastName" && <span className="text--danger font-10 ms-2 mt-0 lh-0"> {error.message} </span> })} */}
              </div>

              <div className="col-md-3 mt-3">
                <label
                  htmlFor="BiologicalSex"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Biological Sex <span>&#42;</span>
                </label>
                <select
                  onChange={genderHandler}
                  className="form-select font-14 border--default br-10 shadow-none input"
                  aria-label="biologicalSex"
                  id="biologicalSex"
                  defaultValue={gender}
                  style={{ borderRadius: "10px" }}
                  required
                >
                  <option value="">Select</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Unknown">Unknown</option>
                </select>
              </div>
              <div className="col-md-3 mt-3 ">
                <label
                  htmlFor="dob"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Date of Birth <span>&#42;</span>
                </label>
                <CalenderDatePicker
                  required={true}
                  dobHandler={dobHandler}

                  {...(dob !== "" && customProps)}
                  dateFormat="MM/dd/yyyy"
                  maxDate={new Date()}
                />
              </div>
              <div className="col-md-6 mt-3">
                <label
                  htmlFor="SSN"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  SSN
                </label>
                <input
                  type="text"
                  onChange={ssnHandler}
                  autoComplete="false"
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="SSN"
                  value={ssn}
                />

                {/* {serverErrors !== null && serverErrors?.map((error) => { return error.attributeName === "SocialSecurity" && <span className="text--danger font-10 ms-2 mt-0"> {error.message} </span> })} */}

                <span className="text--danger font-10 ms-2 mt-0">
                  {serverErrors !== null &&
                    serverErrors?.hasOwnProperty(
                      "Patient.Identification.SocialSecurity"
                    )
                    ? serverErrors["Patient.Identification.SocialSecurity"]
                    : ""}
                </span>
              </div>

              <div className="col-md-12">
                <label
                  htmlFor="AddressLine1"
                  className="form-label fw-sb m-0 font-12 text--secondary"
                >
                  Address
                </label>
                <input
                  type="text"
                  onChange={addressLine1Handler}
                  autoComplete="false"
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="AddressLine1"
                  value={addressLine1}
                />
                {/* {serverErrors !== null && serverErrors?.map((error) => { return error.attributeName === "AddressLine1" && <span className="text--danger font-10 ms-2 mt-0 lh-0"> {error.message} </span> })} */}
              </div>

              {/* <div className="col-md-11">
                <label
                  htmlFor="AddressLine2"
                  className="form-label fw-sb m-0 font-12 text--secondary"
                >
                  Line 2
                </label>
                <input
                  type="text"
                  onChange={addressLine2Handler}
                  autoComplete="false"
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="AddressLine2"
                />
              

              </div> */}

              <div className="col-md-6 mt-2">
                <label
                  htmlFor="City"
                  className="form-label fw-sb m-0 font-12 text--secondary"
                >
                  City
                </label>
                <input
                  type="text"
                  onChange={cityHandler}
                  autoComplete="false"
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="City"
                  value={city}
                />
                {/* {serverErrors !== null > 0 && serverErrors?.map((error) => { return error.attributeName === "City" && <span className="text--danger font-10 ms-2 mt-0 lh-0"> {error.message} </span> })} */}
              </div>

              <div className="col-md-3 mt-2">
                <label
                  htmlFor="State"
                  className="form-label fw-sb m-0 font-12 text--secondary"
                >
                  State
                </label>
                {console.log(patient?.state)}

                <Typeahead
                  id="state"
                  labelKey="name"
                  onChange={setState}
                  options={options}
                  selected={state
                  }
                  // selected={state}
                  className="rbt-menu my-select"
                  style={{ borderRadius: "10px" }}
                  autoComplete="false"
                  inputProps={{ className: "font-14" }}
                />
              </div>

              <div className="col-md-3 mt-2">
                <label
                  htmlFor="ZipCode"
                  className="form-label fw-sb m-0 font-12 text--secondary"
                >
                  Zip Code
                </label>
                <input
                  type="text"
                  onChange={zipCodeHandler}
                  autoComplete="false"
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="ZipCode"
                  value={zipCode}
                  minLength="5"
                  maxLength="10"
                />
                <span className="text--danger py-1 font-10 d-block">{regexErrors?.zipcode === true ? "Incorrect Zip Code format" : " "}</span>
              </div>
              {/* {serverErrors?.length > 0 && serverErrors?.map((error) => { return error.attributeName === "Address" && <div className="col-md-12" style={{ marginBottom: '-10px' }}> <span className="text--danger font-10 ms-2 mt-0 lh-0"> {error.message} </span> </div> })} */}
              {/* <div className="col-md-6 mt-2  ">
                <label
                  htmlFor="PreferredPharmacy"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Preferred Pharmacy
                </label>
                <select
                  id="PreferredPharmacy"
                  className={`form-select  br-10 border--default input  font-14 shadow-none`}
                  aria-label="Select Pharmacy"
                  onChange={(event) => {
                    pharmacyHandler(event);
                  }}
                >
                  <option value="">Select Pharmacy</option>
                  {pharmacies !== undefined && pharmacies?.length > 0
                    ? pharmacies?.map((pharmacy) => {
                      let pharmacyText = pharmacy?.BusinessName?.concat(
                        ", ",
                        pharmacy?.AddressLine1?.trim(),
                        ", ",
                        pharmacy?.City?.trim(),
                        ", ",
                        pharmacy?.StateProvince?.trim(),
                        ", ",
                        pharmacy?.PostalCode?.trim()
                      );
                      return (
                        <option
                          title={pharmacyText}
                          data-text={pharmacyText}
                          className="text-truncate w-100"
                          value={pharmacy?.Id}
                          key={pharmacyText}
                        >
                          {pharmacyText}
                        </option>
                      );
                    })
                    : ""}
                </select>
              </div> */}

              <div className="col-md-12 " style={{ marginBottom: "-10px" }}>
                <h3 className="mt-3 mb-3 font-16 fw-sb">Contact Information</h3>
              </div>

              <div className="row" style={{ marginBottom: "-10px" }}>
                <div className="col-md-6">
                  <label
                    htmlFor="CellPhone"
                    className="form-label m-0 fw-sb font-12 text--secondary"
                  >
                    Mobile Phone
                  </label>
                  <PhoneInput
                    onChange={cellPhoneHandler}
                    value={cellPhone}
                    id="CellPhone"
                  ></PhoneInput>
                  {/* <input
                type="text"
                onChange={cellPhoneHandler}
                autoComplete="false"
                className="form-control font-14 border--default br-10 shadow-none input"
                id="CellPhone"
                placeholder="(111) 111-1111"
              /> */}
                  <span className="text--danger font-10 ms-2 mt-0">
                    {/* {regexErrors?.CellPhone !== undefined && regexErrors.CellPhone !== '' ? regexErrors.CellPhone : ''} */}
                    {serverErrors !== null &&
                      serverErrors?.hasOwnProperty(
                        "Patient.CommunicationNumbers.PrimaryTelephone.Number"
                      )
                      ? serverErrors[
                      "Patient.CommunicationNumbers.PrimaryTelephone.Number"
                      ]
                      : ""}
                    {serverErrors !== null &&
                      serverErrors?.hasOwnProperty(
                        "Patient.CommunicationNumbers.PrimaryTelephone"
                      )
                      ? serverErrors[
                      "Patient.CommunicationNumbers.PrimaryTelephone"
                      ]
                      : ""}
                    {/* {console.log("SE===>", serverErrors)} */}
                  </span>
                </div>
                <div className="col-md-6 ">
                  <label
                    htmlFor="Landline"
                    className="form-label m-0 fw-sb font-12 text--secondary"
                  >
                    Home Phone
                  </label>
                  <PhoneInput
                    onChange={landlineHanlder}
                    id="Landline"
                  ></PhoneInput>

                  <span className="text--danger font-10 ms-2 mt-0">
                    {serverErrors !== null &&
                      serverErrors?.hasOwnProperty(
                        "Patient.CommunicationNumbers.HomeTelephone.Number"
                      )
                      ? serverErrors[
                      "Patient.CommunicationNumbers.HomeTelephone.Number"
                      ]
                      : ""}
                  </span>
                </div>

                <div className="col-md-6 mb-0">
                  <label
                    htmlFor="EmergencyContact"
                    className="form-label m-0 fw-sb font-12 text--secondary"
                  >
                    Emergency Contact
                  </label>
                  <PhoneInput
                    onChange={emergencyContactHandler}
                    id="EmergencyContact"
                  ></PhoneInput>

                  <span className="text--danger font-10 ms-2 mt-0">
                    {/* {regexErrors.EmergencyContact !== undefined && regexErrors.EmergencyContact !== '' ? regexErrors.EmergencyContact : ''} */}
                    {serverErrors !== null &&
                      serverErrors?.hasOwnProperty(
                        "Patient.CommunicationNumbers.OtherTelephone.Number"
                      )
                      ? serverErrors[
                      "Patient.CommunicationNumbers.OtherTelephone.Number"
                      ]
                      : ""}
                  </span>
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="Email"
                    className="form-label m-0 fw-sb font-12 text--secondary"
                  >
                    Email <span>&#42;</span>
                  </label>
                  <input
                    type="email"
                    onChange={emailHandler}
                    autoComplete="false"
                    className="form-control font-14 border--default br-10 shadow-none input"
                    id="Email"
                    required
                  />

                  <span className="text--danger font-10 ms-2 mt-0">
                    {/* {regexErrors.EmergencyContact !== undefined && regexErrors.EmergencyContact !== '' ? regexErrors.EmergencyContact : ''} */}
                    {serverErrors !== null &&
                      serverErrors?.hasOwnProperty(
                        "Patient.CommunicationNumbers.ElectronicMail"
                      )
                      ? serverErrors[
                      "Patient.CommunicationNumbers.ElectronicMail"
                      ]
                      : ""}
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <div className="row w-100 justify-content-between">
          <div className="form-group  col-6">
            <input
              className="form-check-input"
              type="checkbox"
              onChange={consentHandler}
            />
            <label
              htmlFor="checkbox"
              style={{
                fontFamily: "Montserrat",
                fontSize: "12px",
                lineHeight: "20px",
                color: "#323232",
              }}
              className="form-check-label  ms-2 text-justify"
            >
              Patient consents to receive email notifications.
            </label>
          </div>
          <div className="col-6 text-end">
            <button
              type="submit"
              className={`btn bg--blue fw-sb font-14 text-white b2 px-4 br-10 py-1 shadow-none ${isSending ? "disabled" : ""
                }`}
              form="patientFacesheetForm"
            >
              {isSending ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default NewPatientFacesheetModal;

import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";


const DeleteDiagnosisModal = (props) => {

    return (
        <Modal
            show={props?.show}
            onHide={() => {
                props.handleModal();
            }}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            backdropClassName="modal-backdrop"
            contentClassName={`modal-border modal-shadow `}
            scrollable={false}
            centered

        // style={{style}}
        >
            <Modal.Header  >
                <span
                    style={{
                        lineHeight: "12px",
                        width: "18px",
                        fontSize: "8pt",
                        color: "#336383",
                        position: "absolute",
                        top: 15,
                        right: 15,
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        props?.handleModal()
                    }}
                >
                    {<CloseIcon />}
                </span>
                <Modal.Title className="font-20 fw-sb">Delete Diagnosis</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row  justify-content-center">
                        <div className="col-12">
                            <span className="font-14 text--terciary">{"This will delete "} <span className="text--secondary fw-sb">{props?.deletedDiagnosis?.code + " - " + props?.deletedDiagnosis?.description}</span> {" from the list of diagnoses. Please confirm to proceed."}</span>
                            <p className="">{""}</p>
                            <div className="text-center">

                                <button
                                    disabled={props?.deleteDiagnosisLoading}
                                    className={`btn fw-sb b1 ${props?.deleteDiagnosisLoading ? "disabled" : ""} btn--border text--blue bg--lightGreen px-4 br-10 py-1`}
                                    onClick={() => {
                                        props.handleModal();
                                    }}
                                >
                                    Cancel
                                </button>
                                <button className={`btn ${props?.deleteDiagnosisLoading ? "disabled" : ""} btn--danger shadow-none text-white btn--border--red ms-3 px-4 py-1 br-10 b1`}
                                    disabled={props?.deleteDiagnosisLoading}
                                    onClick={() => props.handleDelete(props?.deletedDiagnosis,props?.soapNoteKey ?? false)}
                                    id={props?.deletedDiagnosis?.code}
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default DeleteDiagnosisModal;

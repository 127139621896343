import { useState } from "react";
import { Modal } from "react-bootstrap";
import sendHttpRequest from "../../../hooks/sendHttpRequest";
import { useAuth } from "../../../hooks/useAuth";

const InitiateResetPasswordModal = (props) => {
  const { user } = useAuth();
  const [isResetPasswordRequestSent, setIsResetPasswordRequestSent] =
    useState(false);
  const sendPasswordResetRequest = async () => {
    setIsResetPasswordRequestSent(true);
    const httpResponse = await sendHttpRequest({
      url: `/auth/initiatePasswordReset`,
      method: "POST",
      data: {
        userId: props?.Id,
        tenantId: user?.tenantId
      },
    });
    if (!httpResponse.error) {
      props?.setOpenPwdResetToast(true);
      props?.setPwdResetRequestResponse("Password reset link sent");
      props?.handleModal();
    } else {
      console.log(httpResponse.error);
    }
    setIsResetPasswordRequestSent(false);
  };

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.handleModal();
      }}
      backdropClassName="modal-backdrop"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"      
      contentClassName="modal-border modal-shadow"
      // contentClassName={`${classes["appointmentmodal__style"]} `}
      // dialogClassName={`${classes["custom-modal-style"]}`}
      // style={{style}}
    >
      <Modal.Body>
        <div className="container">
          <div className="row text-center justify-content-center">
            <div className="col-12">
              <p className="font-16 text--terciary fw-sb">
                Reset Password
              </p>
              <div className="font-14 text--secondary">
                <span>
                  {props?.patient===true ? "Please click Confirm to reset password for the patient: " : "Please click Confirm to reset password for the user: "}
                </span>
                <p className="text--terciary fw-sb ">
                  {props?.FirstName}&nbsp;{props?.LastName}
                </p>
              </div>

              <div className="mt-3">
                <button
                  className="btn fw-sb b1  btn--border text--blue bg--lightGreen px-4 br-10 py-1"
                  onClick={() => {
                    props.handleModal();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn--blue text-white  ms-3 px-4 py-1 br-10 b1"
                  onClick={() => sendPasswordResetRequest()}
                  disabled={isResetPasswordRequestSent}
                  // id={props.diagnosis}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* {console.log(props?.prescriber)} */}
      </Modal.Body>
    </Modal>
  );
};

export default InitiateResetPasswordModal;

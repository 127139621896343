import { ActionTypes } from '../constants/actionTypes'

export const setAppointments = (providerRef) => {
    return {
        type: ActionTypes.SET_APPOINTMENTS,
        payload: providerRef
    }
}
export const setRefillRequests = (data) => {
    return {
        type: ActionTypes.SET_REFILL_REQUSTES,
        payload: data
    }
}
export const setMessages = (data) => {
    return {
        type: ActionTypes.SET_MESSAGES,
        payload: data
    }
}
export const setUnreadMessageCount = (count) => {
    return {
        type: ActionTypes.SET_UNREAD_MESSAGE_COUNT,
        payload: count,
    }
}
export const setDiagnosis = (payload) => {
    return {
        type: ActionTypes.SET_DIAGNOSIS,
        payload: payload,
    }
}
export const setPrescribedMedication = (payload) => {
    return {
        type: ActionTypes.SET_PRESCRIBED_MEDICATION,
        payload: payload,
    }
}
export const updatePrescribedMedication = (payload) => {
    return {
        type: ActionTypes.UPDATE_PRESCRIBED_MEDICATION,
        payload: payload,
    }
}
export const setPrescriberInfo = (payload) => {
    return {
        type: ActionTypes.SET_PRESCRIBER_INFO,
        payload: payload,
    }
}
export const setDrawerState = (payload) => {
    return {
        type: ActionTypes.SET_DRAWER_OPEN,
        payload: payload,
    }
}
export const setPatientEncounterData = (payload) => {
    return {
        type: ActionTypes.SET_PATIENT_ENCOUNTER_DATA,
        payload: payload,
    }
}
export const setUpdateEncounterData = (payload) => {
    return {
        type: ActionTypes.SET_UPDATE_ENCOUNTER_DATA,
        payload: payload,
    }
}
export const setDisableSendRxButton = (payload) => {
    return {
        type: ActionTypes.SET_DISABLE_SEND_RX_BUTTON,
        payload: payload,
    }
}
export const setPatientIntakeFormReadSatus = (payload) => {
    return {
        type: ActionTypes.SET_PATIENT_INTAKE_FORM_READ_STATUS,
        payload: payload,
    }
}
export const setAllPhysicalSystemsStatus = (payload) => {
    return {
        type: ActionTypes.SET_ALL_PHYSICAL_SYSTEMS_STATUS,
        payload: payload,
    }
}

export const setVideoCall = (payload) => {
    return {
        type: ActionTypes.SET_VIDEO_CALL,
        payload: payload

    }
}

export const setPatientID = (payload) => {
    return {
        type: ActionTypes.SET_PATIENT_ID,
        payload: payload,
    }
}

export const setIsVideoCallEnd = (payload) => {
    return {
        type: ActionTypes.SET_IS_VIDEO_CALL_END,
        payload: payload,
    }
}
export const setProviderList = (payload) => {
    return {
        type: ActionTypes.SET_PROVIDER_LIST,
        payload: payload,
    }
}


export const setMicStatus = (payload) => {
    return {
        type: ActionTypes.SET_MIC_STATUS,
        payload: payload,
    }
}

export const setForceShutdown = (payload) => {
    return {
        type: ActionTypes.SET_FORCE_SHUTDOWN,
        payload: payload,
    }
}

export const setIsPatientEdited = (payload) => {
    return {
        type: ActionTypes.SET_IS_PATIENT_EDITED,
        payload: payload
    }
}
export const setPrescriptionNoteText = (payload) => {
    return {
        type: ActionTypes.SET_PRESCRIPTION_NOTE_TEXT,
        payload: payload
    }
}

export const setDirtyBitInStore = (payload) => {
    return {
        type: ActionTypes.SET_DIRTY_BIT,
        payload: payload
    }
}
export const setRequiredDataCheck = (payload) => {
    return {
        type: ActionTypes.SET_REQUIRED_DATA_CHECK,
        payload: payload,
    }
}

export const setVideoOpen = (payload) => {
    return {
        type: ActionTypes.SET_VIDEO_OPEN,
        payload: payload
    }
}

export const setConsulationInProgress = (payload) => {
    return {
        type: ActionTypes.SET_CONSULTATION_IN_PROGRESS,
        payload: payload
    }
}

export const setJoinPage = (payload) => {
    return {
        type: ActionTypes.SET_JOIN_PAGE,
        payload: payload
    }
}
export const setUserPermissionsData = (payload) => {
    return {
        type: ActionTypes.SET_USER_PERMISSIONS,
        payload: payload,
    }
}
export const setUserTimezoneData = (payload) => {
    return {
        type: ActionTypes.SET_USER_TIMEZONE,
        payload: payload,
    }
}
export const setTenantSubscriptions = (payload) => {
    return {
        type: ActionTypes.SET_TENANT_SUBSCRIPTIONS,
        payload: payload,
    }
}

export const setSubscriptionPrivilegeGroups = (payload) => {
    return {
        type: ActionTypes.SET_SUBSCRIPTION_PRIVILIGES,
        payload: payload
    }
}

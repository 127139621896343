import { ActionTypes } from "../constants/actionTypes"

const micInitialState = {    
        isMute:false
}

const setMicStatusReducer = (state=micInitialState, action)=>{
    if (action.type === ActionTypes.SET_MIC_STATUS ){
        return {
            ...state,
            isMute: action.payload
        
        }
    }
    return state
}


export default setMicStatusReducer;
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { Modal } from "react-bootstrap";
import sendHttpRequest from "../../../hooks/sendHttpRequest";
import classes from "./NewMessageDashboardModal.module.css";
import { useNavigate, useLocation, Link } from "react-router-dom";
import MessagesIcon from "../../Drawer/DrawerItemIcons/MessagesIcon";
import MessageItem from "../../../pages/MessageItem";
import { useAuth } from "../../../hooks/useAuth";
import { strings } from "../../../../res/strings";

const NewMsgOnDashboardModal = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const __str = strings.en
  const [pendingTaskData, setPendingTaskData] = useState([]);
  const [isPendingTaskDataLoading, setIsPendingTaskDataLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [open, setOpen] = useState(false);

  const fetchPendingTaskData = async () => {
    setIsPendingTaskDataLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/messages",
      method: "GET",
      params: {
        userId: user?.userId,
        type: "inbox",
        tenantId: user?.tenantId
      },
    });
    if (!httpResponse.error) {
      // console.log("Pending tasks: ", httpResponse.data,httpResponse.data.length);
      setPendingTaskData(httpResponse.data.filter((msg) => msg?.status?.toLowerCase() === "delivered"));
    } else {
      console.log(httpResponse.error);
    }
    setIsPendingTaskDataLoading(false);
  };

  const markMessageAsRead = async (id) => {
    setIsPendingTaskDataLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/messages/mark-read",
      method: "PUT",
      params: {
        userId: user?.userId,
        threadId: id,
        tenantId: user?.tenantId
      },
    });
    if (!httpResponse.error) {
      // console.log("Pending tasks: ", httpResponse.data,httpResponse.data.length);
      setPendingTaskData(httpResponse.data.messages);
    } else {
      console.log(httpResponse.error);
    }
    setIsPendingTaskDataLoading(false);
  };


  const [isSessionUpdated, setIsSessionUpdated] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSent(false);
  };

  if (isSessionUpdated === true) {
    fetchPendingTaskData();
    setIsSessionUpdated(false);
  }

  useEffect(() => {
    fetchPendingTaskData();
  }, []);

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          props.handleModal();
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        backdropClassName="modal-backdrop"
        contentClassName="modal-border modal-shadow"
        scrollable
        centered
      >
        <Modal.Header className="modal-header">
          <span
            style={{
              lineHeight: "12px",
              width: "18px",
              fontSize: "8pt",
              color: "#336383",
              position: "absolute",
              top: 15,
              right: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              props?.handleModal();
            }}
          >
            {<CloseIcon />}
          </span>
          <div className="d-flex">
            <div
              className={`bg--lightBlue text--secondary my-auto p-3`}
              style={{
                // height: "60px",
                // width: "60px",
                borderRadius: "20px",
              }}
            >
              <div>
                <MessagesIcon
                  style={{
                    color: "#004FD41A",
                    fill: "#004FD4",
                    width: "24px",
                    height: "24px",
                  }}
                  className="mx-auto  d-block mt-50"
                />
              </div>
            </div>

            <div className="text--terciary font-18 fw-sb align-self-center ms-2">
              <div>New</div>
              <div>Messages</div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="row mt-3 justify-content-center mb-5">
            <Backdrop sx={{ color: "#EC609B", zIndex: 1900000000 }} open={open}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <div className="col-sm-11 font-12 text-end">
              <Link
                to="/messages"
                state={{ from: location }}
                className="fw-sb text-blue"
              >
                View all
              </Link>
            </div>
            <div className="col-sm-11">
              <Grid
                container
                sx={{ marginBottom: "-8px" }}
                p={1}
                className={`sticky-top bg-white ${classes["row__head"]} ${pendingTaskData?.length === 0 ? classes['border--bottom'] : ""} `}
              >
                <Grid item xs={3} sm={3} md={3}>
                  <Typography component="div">
                    <Box
                      sx={{
                        textAlign: "left",
                        m: 1,
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#336383",
                      }}
                    >
                      From
                    </Box>
                  </Typography>
                  {/* </Stack> */}
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Typography component="div">
                    <Box
                      sx={{
                        textAlign: "left",
                        mt: 1,
                        mr: 4,
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#336383",
                      }}
                    >
                      Message
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography component="div">
                    <Box
                      sx={{
                        textAlign: "left",
                        mt: 1,
                        ml: 2,
                        fontFamily: "Montserrat",
                        fontSize: "12px",
                        color: "#336383",
                      }}
                    >
                      Date & Time
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>

              {isPendingTaskDataLoading ? (
                <Skeleton animation="wave" />
              ) : pendingTaskData?.length > 0 ? (
                pendingTaskData?.map((pendingTaskDataItem, index) => {

                  if (index === (pendingTaskData.length - 1)) {
                    return (
                      <MessageItem
                        key={index}
                        task={pendingTaskDataItem}
                        markMessageAsRead={markMessageAsRead}
                        isLast={true}
                        isEven={index % 2 === 0 ? true : false}
                      />
                    );
                  } else {
                    return (
                      <MessageItem
                        key={index}
                        task={pendingTaskDataItem}
                        markMessageAsRead={markMessageAsRead}
                        isEven={index % 2 === 0 ? true : false}
                      />
                    );
                  }
                })
              ) : (
                <div className="font-14 text--terciary p-2">{__str?.noDataAvailable}</div>
              )}
              {/* <TaskItem /> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* {console.log("monCount",monCount)} */}
      {/* {console.log("mondaySlotsList",mondaySlotsList)} */}
    </>
  );
};

export default NewMsgOnDashboardModal;

import { ActionTypes } from "../constants/actionTypes"

const initialState = {
    subscriptionPrivilegeGroups: {}
}

const setSubscriptionPrivilegeGroups = (state = initialState, action) => {
    if (action.type === ActionTypes.SET_SUBSCRIPTION_PRIVILIGES) {
        return { ...state, subscriptionPrivilegeGroups: action.payload }
    }
    return state
}

export default setSubscriptionPrivilegeGroups;
import { useEffect, useState } from "react";
import classes from "./PatientProfileMessages.module.css";
import SearchIcon from "@mui/icons-material/Search";
import PatientProfileMessageItem from "./PatientProfileMessageItem";
import PatientProfileMessageContainer from "./PatientProfileMessageContainer";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { Skeleton } from "@mui/material";
import { useAuth } from "../hooks/useAuth";

const PatientProfileMessages = (props) => {
  const { user } = useAuth();
  const [patientProfileMessages, setPatientProfileMessages] = useState([]);
  const [isPatientMessagesLoading, setIsPatientMessagesLoading] =
    useState(false);
  const [showMessageThread, setShowMessageThread] = useState(false);
  const [activeThreadId, setActiveThreadId] = useState();

  const fetchPatientProfileMessages = async () => {
    setIsPatientMessagesLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/messages",
      method: "GET",
      params: {
        patientId: props?.patientId,
        tenantId: user?.tenantId
      },
    });
    if (!httpResponse.error) {
      setPatientProfileMessages(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
    setIsPatientMessagesLoading(false);
  };
  const handleMessageThread = () => {
    setShowMessageThread(!showMessageThread);
  };

  useEffect(() => {
    fetchPatientProfileMessages();
    setShowMessageThread(false);
  }, [props?.patientId]);
  return (
    <div className="container-fluid  g-0">
      <div className="d-flex flex-row  justify-content-between  border--bottom  p-2">
        <div className="align-self-center text--secondary font-14 lh-0">
          {/* Showing 4 of 4 */}
        </div>
        <div
          className="input-group border--default br-10"
          style={{ maxWidth: "25vw" }}
        >
          <input
            type="text"
            className="form-control br-10 align-self-center shadow-none border-0  "
            placeholder="Search messages"
          />

          <button className="btn bg-white  br-10 shadow-none" type="submits">
            <SearchIcon className="text--secondary" />
          </button>
        </div>
      </div>
      <div
        className={`hide-scrolbar ${classes["message__profile__container"]}`}
      >
        {/* Message Items */}
        {isPatientMessagesLoading ? (
          <Skeleton />
        ) : (
          patientProfileMessages?.length > 0 &&
          patientProfileMessages.map((message) => {
            return (
              <PatientProfileMessageItem
                messageItem={message}
                setActiveThreadId={setActiveThreadId}
                handleMessageThread={handleMessageThread}
              />
            );
          })
        )}
        {/* <PatientProfileMessageContainer/> */}
      </div>

      {showMessageThread && (
        <PatientProfileMessageContainer
          show={showMessageThread}
          handleclose={handleMessageThread}
          placement={"bottom"}
          backdrop={false}
          threadId={activeThreadId}
          handleMessageThread={handleMessageThread}
        />
      )}
    </div>
  );
};

export default PatientProfileMessages;

export const fieldNames = {
    firstName: "name[].firstName",
    lastName: "name[].lastName",
    suffixName: "name[].suffixName",
    name: "name",
    gender: "gender",
    address: "address",
    addressLine1: "address[].addressLine1",
    city: "address[].city",
    dateOfBirth: "dateOfBirth",
    stateProvince: "address[].stateProvince",
    postalCode: "address[].postalCode",
    email: "communicationNumbers.electronicMail[0]",
    mobilePhone: "communicationNumbers.numbers[].number",
    role: "role",
    facility: "facility",
    emailAlreadyExist:"message",
    npi:"npi",
    ssn:"ssn",
    tin:"",

}

export const facilityFieldNames = {
    businessName: "businessName",
    addressLine1: "addressLine1",
    city: "city",
    country: "country",
    state: "state",
    zipCode: "zipCode",
    email: "email",
    phoneNumber: "phoneNumber",
    faxNumber: "faxNumber",
}
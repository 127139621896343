import React from 'react'
import { unique } from '../../utils/helpers/HelperFunctions';

const TaxonomyCodeForm = ({ stateLicenses, taxonomyCode, setTaxonomyCode, taxonomyDescription, setTaxonomyDescription }) => {
    return (
        <div className="col-12 col-md-12 mt-2 pb-2 ">
            <div className='row'>
                <div className="col-12 col-sm-6">
                    <h3 className="text--terciary font-14 fw-sb m-0">Taxonomy Code</h3>
                </div>
            </div>
            <div className="row ">
                <div className="col-12 col-sm-6">
                    <select
                        className={`input form-select font-12 text--terciary  border--default shadow-none br-10  text-wrap`}
                        onChange={e => {
                            console.log(e.target.value)
                            setTaxonomyCode(e.target.value);
                        }}
                        value={taxonomyCode}
                    >
                        <option value="" disabled> </option>
                        {unique(stateLicenses, ['taxonomyCode'])?.map((state) => {
                            if (state?.taxonomyCode !== null) {
                                return <option value={`${state.taxonomyCode}-${state.state}`} key={`${state.taxonomyCode}-${state.state}`}>{`${state.taxonomyCode} - ${state.taxonomyDescription ?? 'No description available'}`}</option>
                            }
                        })}
                    </select>
                </div>
            </div>
        </div>
    )
}

export default TaxonomyCodeForm
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import CalenderDatePicker from "../UI/General/CalenderDatePicker";
import { ChevronLeft } from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { useAuth } from "../hooks/useAuth";
import {
  calenderDateTimeHelper,
  getAgeByDateOfBirth,
} from "../../utils/dateTimeHelper";
import {
  convertToCSV,
  generateCSVFile,
  truncate,
} from "../../utils/helpers/HelperFunctions";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { PnToolTip } from "../UI/Reusable/PnComponents";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FAFCFC",
    color: "#336383",
    fontWeight: 400,
    fontSize: 12,
    fontFamily: "Montserrat",
    // width: 150,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    paddingRight: "0px",
    fontFamily: "Montserrat",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#FAFCFC",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const GenderComponent = ({ filters, setFilters }) => {
  const handleGenderValue = (event) => {
    let id = event.target.id;
    setFilters({ ...filters, gender: id });
  };
  return (
    <>
      <div className="font-12 ms-4 mt-1">
      <input
          type="radio"
          name="gender"
          id="Female"
          value={"female"}
          className="me-1"
          onChange={(e) => handleGenderValue(e)}
        />
        <label htmlFor="female" className="me-3">
          {"Female"}
        </label>
        <input
          type="radio"
          name="gender"
          id="Male"
          value={"male"}
          className="me-1 mt-1"
          onChange={(e) => handleGenderValue(e)}
        />
        <label htmlFor="male" className="  align-self-center">
          {"Male"}
        </label>
        
      </div>
    </>
  );
};
const AgeComponent = ({ filters, setFilters }) => {
  const [agefilter, setAgefilter] = useState(">");
  const [ageTo, setAgeTo] = useState();
  const [ageFrom, setAgeFrom] = useState();

  const handleAgeFilter = (event) => {
    let value = event.target.value;
    setFilters({ ...filters, ageFrom: null, ageTo: null});
    setAgeFrom("")
    setAgeTo("")
    if (value === ">") {
      setAgefilter(">");
    } else if (value === "<") {
      setAgefilter("<");
    } else if (value === "=") {
      setAgefilter("=");
    } else if (value === "between") {
      setAgefilter("between");
    }
  };
  const handleAgeInputs = (event) => {
    let id = event.target.id;
    let value = event.target.value;
    if(agefilter === ">"){
      setAgeFrom(value);
      setFilters({ ...filters, ageFrom: value, ageTo: null});
    }else if(agefilter === "<"){
      // setAgeTo(value);
      setAgeFrom(value)
      setFilters({ ...filters, ageTo: value, ageFrom: null });
    }
    else if(agefilter === "="){
      setAgeFrom(value);
      setFilters({ ...filters, ageFrom: value , ageTo: value});
    }
    else if(agefilter === "between"){
      if (id === "ageFrom") {
        setAgeFrom(value);
        setFilters({ ...filters, ageFrom: value });
      } else if (id === "ageTo") {
        setAgeTo(value);
        setFilters({ ...filters, ageTo: value });
      }
    }
  };
  return (
    <>
      <div className="row ms-2 mt-1">
        <div className="col-5">
          <select
            className={`form-select   font-12  border--default br-10 shadow-none input`}
            aria-label="action select"
            style={{
              height: "36px",
            }}
            onChange={(e) => handleAgeFilter(e)}
          >
            <option
              className="text--terciary fst-normal"
              value={""}
              key={"Select"}
              selected
              hidden
              disabled
            >
              {"Select"}
            </option>
            {["<", ">", "=", "between"].map((item) => {
              return (
                <option
                  className={`text--terciary fst-normal ${item !== "between" ? 'fw-sb':''}`}
                  value={item}
                  key={item}
                  id={item}
                  selected = {item === ">" ? true:false}
                >
                  {item}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-5 d-flex flex-row ">
          <input
            type="number"
            className="input form-control me-1 shadow-none font-12 border--default br-10"
            onChange={(e) => {
              handleAgeInputs(e);
            }}
            id={"ageFrom"}
            value={ageFrom}
          />
          <label className="text--terciary font-12 me-1 align-self-center" hidden = {agefilter !== "between" ?  true:false}>
            {"To"}
          </label>
          <input
            type="number"
            className="input form-control shadow-none font-12 border--default br-10"
            onChange={(e) => {
              handleAgeInputs(e);
            }}
            id={"ageTo"}
            value={ageTo}
            hidden = {agefilter !== "between" ?  true:false}
          />
        </div>
        {/* <div className="col-3">
            </div>
            <div className="col-3">
                <div className="d-flex">
                </div>
            </div> */}
      </div>
    </>
  );
};
const DiagnosisCodeComponent = ({ filters, setFilters }) => {
  const handleInput = (event) => {
    setFilters({ ...filters, diagnosis: event.target.value });
  };
  return (
    <>
      <div className="d-flex justify-content-between ms-4">
        <select
          className={`form-select   font-12  border--default br-10 shadow-none input`}
          aria-label="action select"
          style={{
            height: "36px",
          }}
          // onChange={(e) => handlePracticeTypeCode(e)}
        >
          {/* <option
            className="text--terciary fst-normal"
            value={""}
            key={"Select"}
            selected
            hidden
            disabled
          >
            {"Select"}
          </option> */}
          {["Includes"].map((item) => {
            return (
              <option
                className="text--terciary fst-normal"
                value={item}
                key={item}
                selected = {item === "Includes" ?  true:false}
                
              >
                {item}
              </option>
            );
          })}
        </select>
        <input
          type="text"
          className="font-12 input form-control border--default br-10 shadow-none ms-2"
          onChange={(e) => {
            handleInput(e);
          }}
        />
      </div>
    </>
  );
};

const PatientListReports = (props) => {
  const { user } = useAuth(); 
  const [checkBoxKeys, setCheckBoxKeys] = useState();
  const [selectedCheckboxes, setSelectedCheckboxes] = useState();
  const [gender, setGender] = useState(false);
  const [filters, setFilters] = useState();
  const [searchResultData, setSearchResultData] = useState([]);
  const [csvData,setCSVData] = useState([]);

  const [patientsCheckList, setPatientsCheckList] = useState([
    "Last Seen (Last Sign Visit Note)",
    "Patient Passport Status",
    "Order Status (Close-the-Loop)",
  ]);
  const [demographics, setDemographics] = useState([
    "Age",
    "Gender",
    "Race",
    "Ethincity",
    "Preferred Language",
    "Preferred Contact Method",
  ]);
  const [clinicalList, setClinicalList] = useState([
    "HCC Risk Score",
    "Most Recent Lab Results",
    "Active Problems in Clinical Profile",
    "Permanent Medications in Clinical Profile",
    "Vaccinations in Clinical Profile",
  ]);
  const patientResultRows = [
    {
      firstName: "Hailey",
      lastName: "Jones",
      HCC_Score: ".37",
      dateOfBirth: "1954-08-10",
      age: "67 yrs, 5mo",
      gender: "F",
      contact: "555-555-5555",
      lastAppointment: "10/06/21",
    },
    {
      firstName: "Molly",
      lastName: "Morse",
      HCC_Score: ".091",
      dateOfBirth: "1959-09-09",
      age: "67 yrs, 5mo",
      gender: "F",
      contact: "555-555-5555",
      lastAppointment: "10/06/21",
    },
  ];

  const fetchPatients = async () => {
    setSearchResultData([]);
    const httpResponse = await sendHttpRequest({
      url: `/patient/tenant/${user?.tenantId}/search`,
      method: "Get",
      params: filters,
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
      let data = httpResponse.data;
      // console.log(data?.length);
      let dataList = [];
      let csvDataList = [];
      if (data?.length > 0) {
        for (let i = 0; i < data?.length; i++) {
          let dataObject = {
            firstName: data[i]?.name[0]?.firstName,
            lastName: data[i]?.name[0]?.lastName,
            dateOfBirth: data[i]?.dateOfBirth,
            age: "".concat(getAgeByDateOfBirth(data[i]?.dateOfBirth), " yrs"),
            contact: data[i].communicationNumbers?.numbers[0]?.number,
            gender: data[i]?.gender,
            lastAppointmentDate: data[i]?.lastAppointment,
            upcomingAppointmentDate: data[i]?.upcomingAppointment,
            userId: data[i]?.userId,
          }
          let csvObject = {
            "First Name": data[i]?.name[0]?.firstName,
            "Last Name": data[i]?.name[0]?.lastName,
            "Date of Birth": calenderDateTimeHelper(data[i]?.dateOfBirth,"YYYY-MM-DD"),
            "Age": "".concat(getAgeByDateOfBirth(data[i]?.dateOfBirth), " yrs"),
            "Contact": data[i].communicationNumbers?.numbers[0]?.number,
            "Gender": data[i]?.gender,
            "Last Appointment Date": data[i]?.lastAppointment !== undefined ? calenderDateTimeHelper(data[i]?.lastAppointment,"YYYY/MM/DD") : "",
            "Upcoming Appointment Date": data[i]?.upcomingAppointment !== undefined ? calenderDateTimeHelper(data[i]?.upcomingAppointment,"YYYY/MM/DD") : "",
          }
          dataList.push(dataObject);
          csvDataList.push(csvObject)
        }
        setSearchResultData(dataList);
        setCSVData(csvDataList)
      }
    }
  };

  const handleDemographicsCheck = (event) => {
    let id = event.target.id;
    if (event.target.checked === true) {
      setSelectedCheckboxes({ ...selectedCheckboxes, [id]: true });
    } else {
      let checklist = selectedCheckboxes;
      delete checklist[id];
      setSelectedCheckboxes({ ...checklist, [id]: false });
      if (id === "Age") {
        delete filters.ageTo;
        delete filters.ageFrom;
      } else if (id === "Gender") {
        delete filters.gender;
      }
    }
  };

  const handleClinicalCheckboxes = (event) => {
    let id = event.target.id;
    if (
      event.target.checked === true &&
      id === "Active Problems in Clinical Profile"
    ) {
      setSelectedCheckboxes({ ...selectedCheckboxes, diagnosis: true });
    } else {
      let checklist = selectedCheckboxes;
      delete checklist.diagnosis;
      delete filters.diagnosis;
      setSelectedCheckboxes({ ...checklist, [id]: false });
    }
  };

  const handleDownloadFile = () => {
    // let value = event.target.value;
    // if (value === "Download CSV") {
      let csvString = convertToCSV(csvData);
      generateCSVFile(csvString,"Patients_".concat(calenderDateTimeHelper(new Date(),"YYYY-MM-DD"),"_",calenderDateTimeHelper(new Date(),"hh:mm")));
    // }
  };

  useEffect(() => {
    let keysList = [...patientsCheckList, ...demographics, ...clinicalList];
    let keyMap = new Map();
    for (let index = 0; index < keysList.length; index++) {
      keyMap.set(keysList[index], keysList[index]);
    }
    setCheckBoxKeys(keyMap);
  }, []);
  return (
    <>
      <div className="row px-2 pt-1">
        {/* Left Section */}
        <div className="col-3 border--right">
          <h2 className="text--terciary font-14">{"Patient List"}</h2>
          {/* Patient List section */}
          <div className="">
            <input
              key={"false"}
              className="form-check-input cursor--pointer shadow-none input border--default br-10"
              type="checkbox"
              name="eligibility-check"
              id={"eligibility-check"}
              disabled
              //   onChange={(e) => {
              //     handleEligibilityCheck(e);
              //   }}
              //   defaultChecked={tenantSettingsData?.checkEligibility ?? false}
            />
            <label
              className="form-check-label font-12  my-auto ms-2 me-2"
              htmlFor={"eligibility-check"}
            >
              {patientsCheckList[0]}
            </label>
            {false && (
              <div className="row ms-2 ">
                {/* <div className="col-2"></div> */}
                <div className="col-5">
                  <select
                    className={`form-select   font-12  border--default br-10 shadow-none input`}
                    aria-label="action select"
                    style={{
                      height: "36px",
                    }}
                    // onChange={(e) => handlePracticeTypeCode(e)}
                  >
                    <option
                      className="text--terciary fst-normal"
                      value={""}
                      key={"Select"}
                      selected
                      hidden
                      disabled
                    >
                      {"Select"}
                    </option>
                    {["Before", "After"].map((item) => {
                      return (
                        <option
                          className="text--terciary fst-normal"
                          value={item}
                          key={item}
                          selected={item === "Before" ? true : false}
                        >
                          {item}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-6 g-0">
                  <CalenderDatePicker
                    key={"contractEndDate"}
                    name="contractEndDate"
                    id={"contractEndDate"}
                    f12={true}
                    dobHandler={(e) => {
                      // handleContractEndDate(e);
                    }}
                    date={new Date()} // dobHandler={(e) => {
                    dateFormat="MMM d, yyyy"
                  />
                </div>
              </div>
            )}
          </div>
          {patientsCheckList.slice(1).map((checkboxItem) => {
            return (
              <>
                <div className="mt-1">
                  <input
                    key={checkboxItem}
                    className="form-check-input cursor--pointer shadow-none input border--default br-10"
                    type="checkbox"
                    name={checkboxItem}
                    id={checkboxItem}
                    disabled
                    //   onChange={(e) => {
                    //     handleEligibilityCheck(e);
                    //   }}
                    //   defaultChecked={tenantSettingsData?.checkEligibility ?? false}
                  />
                  <label
                    className="form-check-label font-12  my-auto ms-2 me-2"
                    htmlFor={checkboxItem}
                  >
                    {checkboxItem}
                  </label>
                </div>
              </>
            );
          })}

          {/* Demographics */}
          <h2 className="font-14 text--terciary mt-2">{"Demographics"}</h2>
          {demographics.map((checkboxItem) => {
            return (
              <>
                <div className="mt-1">
                  <input
                    key={checkboxItem}
                    className="form-check-input cursor--pointer shadow-none input border--default br-10"
                    type="checkbox"
                    name={checkboxItem}
                    id={checkboxItem}
                    onChange={(e) => {
                      handleDemographicsCheck(e);
                    }}
                    disabled={
                      checkboxItem === "Age"
                        ? false
                        : checkboxItem !== "Gender"
                        ? true
                        : false
                    }
                    //   defaultChecked={tenantSettingsData?.checkEligibility ?? false}
                  />
                  <label
                    className="form-check-label font-12  my-auto ms-2 me-2"
                    htmlFor={checkboxItem}
                  >
                    {checkboxItem}
                  </label>
                </div>
                {checkboxItem === "Gender" && selectedCheckboxes?.Gender ? (
                  <GenderComponent filters={filters} setFilters={setFilters} />
                ) : null}
                <div className="col-12">
                  {checkboxItem === "Age" && selectedCheckboxes?.Age ? (
                    <AgeComponent filters={filters} setFilters={setFilters} />
                  ) : null}
                </div>
              </>
            );
          })}

          {/* Clinical Data */}
          <h2 className="text-terciary font-14 mt-2">{"Clinical Data"}</h2>
          <div className="d-flex">
            <div className=" align-self-center">
              <span className="font-12 text--terciary me-1">{"Matches"}</span>
            </div>
            <div className="me-1">
              <select
                className={`form-select   font-12 border--default br-10 shadow-none input`}
                aria-label="action select"
                style={{
                  height: "36px",
                }}
                // onChange={(e) => handlePracticeTypeCode(e)}
                disabled
              >
                <option
                  className="text--terciary fst-normal"
                  value={""}
                  key={"Select"}
                  selected
                  hidden
                  disabled
                >
                  {"Select"}
                </option>
                {["all"].map((item) => {
                  return (
                    <option
                      className="text--terciary fst-normal"
                      value={item}
                      key={item}
                      selected={item === "all" ? true : false}
                    >
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className=" align-self-center">
              <span className="font-12 text--terciary ps-0">
                {"of the following:"}
              </span>
            </div>
          </div>
          {clinicalList.map((checkboxItem) => {
            return (
              <>
                <div className="mt-1">
                  <input
                    key={checkboxItem}
                    className="form-check-input cursor--pointer shadow-none input border--default br-10"
                    type="checkbox"
                    name={checkboxItem}
                    id={checkboxItem}
                    disabled={
                      checkboxItem === "Active Problems in Clinical Profile"
                        ? false
                        : true
                    }
                    onChange={(e) => {
                      handleClinicalCheckboxes(e);
                    }}
                    //   defaultChecked={tenantSettingsData?.checkEligibility ?? false}
                  />
                  <label
                    className="form-check-label font-12  my-auto ms-2 me-2"
                    htmlFor={checkboxItem}
                  >
                    {checkboxItem}
                  </label>
                </div>
                <div className="col-12">
                  {checkboxItem === "Active Problems in Clinical Profile" &&
                  selectedCheckboxes?.diagnosis === true ? (
                    <DiagnosisCodeComponent
                      filters={filters}
                      setFilters={setFilters}
                    />
                  ) : null}
                </div>
              </>
            );
          })}
          {/* <div className="d-flex ms-3">
            <select
              className={`form-select   font-12 border--default br-10 shadow-none input me-1`}
              aria-label="action select"
              style={{
                height: "36px",
              }}
              // onChange={(e) => handlePracticeTypeCode(e)}
            >
              <option
                className="text--terciary fst-normal"
                value={""}
                key={"Select"}
                selected
                hidden
                disabled
              >
                {"Select"}
              </option>
              {["Doesn't include"].map((item) => {
                return (
                  <option
                    className="text--terciary fst-normal"
                    value={item}
                    key={item}
                    selected={item === "Doesn't include" ? true : false}
                  >
                    {item}
                  </option>
                );
              })}
            </select>
            <input
              type="text"
              placeholder="Enter text"
              className="form-control font-12 text--terciary input shadow-none border--default br-10"
            />
          </div> */}
          <div className="col-12 mt-2">
            <button
              className="w-100 btn blue_task_button font-12"
              disabled={
                filters === undefined
                  ? true
                  : Object.keys(filters).length === 0
                  ? true
                  : false
              }
              onClick={() => {
                fetchPatients();
              }}
            >
              {"Generate List"}
            </button>
          </div>
        </div>

        {/* Right Section */}
        <div className="col-9">
          <span className="text--terciary font-12" hidden={true}>
            {"Showing Patients who meet all of these criterias:"}
          </span>

          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <span
                className="text--terciary align-self-center font-12 fw-sb me-1"
                hidden={true}
              >
                {"1-2 of 2"}
              </span>
              <button className="btn border--default br-7 font-12 me-2 shadow-none" disabled>
                {"First"}
              </button>
              <div
                style={{ width: "32px", height: "34px" }}
                className={`bg--background  br-7 me-2 border--default border align-self-center text-center my-auto `}
                onClick={() => {
                  //   navigate(-1);
                }}
                disabled
              >
                <ChevronLeft
                  style={{ color: "#EC609B", width: "20px", height: "20px" }}
                  className=" my-auto mt-1 "
                />
              </div>
              <div
                style={{ width: "32px", height: "34px" }}
                className={`bg--background  br-7 me-2 border--default border align-self-center text-center my-auto `}
                onClick={() => {
                  //   navigate(-1);
                }}
                disabled
              >
                <ChevronLeft
                  style={{
                    color: "#EC609B",
                    width: "20px",
                    height: "20px",
                    transform: "rotate(180deg)",
                  }}
                  className=" my-auto mt-1"
                />
              </div>
              <button className="btn border--default br-7 font-12 shadow-none" disabled>
                {"Last"}
              </button>
            </div>
            <div
              className="cursor--pointer align-self-center me-1"
              onClick={() => handleDownloadFile()}
              hidden = {searchResultData?.length === 0 ? true : false}
            >
              <PnToolTip title={"Download CSV"} className="">
                <FileDownloadIcon
                  sx={{
                    color: "#336383",
                    fontWeight: 400,
                    fontSize: "1.5rem",
                  }}
                />
              </PnToolTip>
            </div>
          </div>
          <div className="mt-1">
            <PatientSearchResult
              columnHeadersList={[
                "Name",
                "HCC Score",
                "Date of Birth / Age",
                "Gender",
                "Preferred Contact Method",
                "Contact",
                "Last Appointment",
                "Next Appointment",
              ]}
              data={searchResultData}
            />
          </div>
        </div>
      </div>
      {/* {console.log("checkBoxKeys",checkBoxKeys)} */}
      {/* {console.log("selectedCheckboxes: ", selectedCheckboxes)} */}
      {/* {console.log("filters: ", filters)} */}

      {/* {console.log("Filter results:", searchResultData)} */}
    </>
  );
};

export default PatientListReports;

function PatientSearchResult({ columnHeadersList, data, navigateTo }) {
  const {user} = useAuth();
  const navigate = useNavigate();
  return (
    <TableContainer sx={{ borderRadius: "16px", border: "1px solid #D9E8EC" }}>
      <Table>
        <TableHead
          sx={{
            borderTopColor: "#FAFCFC",
            borderRightColor: "#FAFCFC",
            borderLeftColor: "#FAFCFC",
            borderBottom: "1px solid #D9E8EC",
          }}
        >
          <StyledTableRow>
            {columnHeadersList?.map((item, idx) => (
              <StyledTableCell key={item?.concat(idx)}><span  className="font-12 text--secondary">{item}</span></StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {/* {isLoading ? <>
                    {columnHeadersList?.map((item, idx) => {
                        return <StyledTableRow>
                            <StyledTableCell>
                                <Skeleton animation="wave" />
                            </StyledTableCell>
                        </StyledTableRow>
                    })}
                </> : <> */}
          {data?.map((item, idx) => {
            const keys = Object.keys(item);
            // const value = item[key];
            // const route = item["link"];
            // console.log("value: ",value);
            return (
              <StyledTableRow
                key={idx}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <StyledTableCell sx={{ color: "#336383" }}>
                  <span
                    onClick={() => navigate(`/all-patients/${item?.userId}`, {
                      state: {
                        patientId: `${item?.userId}`,
                        selectedIndex: `${item?.userId}`,
                        index: `${item?.userId}`,
                      },
                    })}
                    className="text-underline pn-link-button cursor--pointer"
                  >
                    {item.firstName !== undefined
                      ? <PnToolTip
                          title = {item.firstName.concat(" ", item.lastName)}
                          >
                            {truncate(item.firstName.concat(" ", item.lastName),25)}
                          </PnToolTip>
                      : "-"}
                  </span>
                </StyledTableCell>
                <StyledTableCell sx={{ color: "#336383" }}>
                  <span
                    // onClick={() => navigateTo(route)}
                    className="  "
                  >
                    {item.HCC_Score !== undefined ? item.HCC_Score : "-"}
                  </span>
                </StyledTableCell>
                <StyledTableCell sx={{ color: "#336383" }}>
                  <span
                    // onClick={() => navigateTo(route)}
                    className="  "
                  >
                    {item.dateOfBirth !== undefined
                      ? calenderDateTimeHelper(item.dateOfBirth,user?.tenant?.dateFormat).concat(" - ", item.age)
                      : "-"}
                  </span>
                </StyledTableCell>
                <StyledTableCell sx={{ color: "#336383" }}>
                  <span
                    // onClick={() => navigateTo(route)}
                    className="  "
                  >
                    {item.gender !== undefined ? item.gender : "-"}
                  </span>
                </StyledTableCell>
                <StyledTableCell sx={{ color: "#336383" }}>
                  <span
                    // onClick={() => navigateTo(route)}
                    className="  "
                  >
                    {"-"}
                  </span>
                </StyledTableCell>
                <StyledTableCell sx={{ color: "#336383" }}>
                  <span
                    // onClick={() => navigateTo(route)}
                    className="  "
                  >
                    {item.contact !== undefined ? item.contact : "-"}
                  </span>
                </StyledTableCell>
                <StyledTableCell sx={{ color: "#336383" }}>
                  <span
                    // onClick={() => navigateTo(route)}
                    className="  "
                  >
                    {item.lastAppointmentDate !== undefined
                      ? calenderDateTimeHelper(
                          item.lastAppointmentDate,
                          user?.tenant?.dateFormat
                        )
                      : "-"}
                  </span>
                </StyledTableCell>
                <StyledTableCell sx={{ color: "#336383" }}>
                  <span
                    // onClick={() => navigateTo(route)}
                    className="  "
                  >
                    {item.upcomingAppointmentDate !== undefined
                      ? calenderDateTimeHelper(
                          item.upcomingAppointmentDate,
                          user?.tenant?.dateFormat
                        )
                      : "-"}
                  </span>
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
          {/* </>} */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import { MedicalServices } from '@mui/icons-material';
import { Backdrop, CircularProgress, LinearProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import PageHeader from '../../../../patientportal/generic/PageHeader';
import { calenderDateTimeHelper } from '../../../../utils/dateTimeHelper';
import ClaimService from '../../../../components/claims/ClaimService';
import { useAuth } from '../../../../components/hooks/useAuth';
import sendHttpRequest from '../../../../components/hooks/sendHttpRequest';
import CustomizedSnackbars from "../../../../components/UI/General/CustomizedSnackbars";
import useSnackbar from '../../../../components/hooks/useSnackbar';
import useClaimPlaceOfServiceCodes from '../../claim-hooks/useClaimPlaceOfServiceCodes';
import { useSelector } from 'react-redux';
import { BILLING_AND_CLAIMS } from '../../../../utils/helpers/subscriptionsConstants';
import { Alert } from 'react-bootstrap';

function BottomNavigation(props) {
    const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);

    return (
        <div className="position-fixed pb-4 mb-1 w-100 bottom-0 mx-auto bg-white bottom__nav--shadow">
            <LinearProgress variant="determinate" style={{
                backgroundColor: "#D9E8EC",
                height: "1px"
            }} value={100} />
            <div className="d-flex p-3 ">
                <div className="position-fixed d-flex  " style={{
                    left: subscriptionPrivilegeGroups[BILLING_AND_CLAIMS]?.bsc?.active === true ? "78%" : "88%"
                }}>

                    <button className="btn  br-10 font-14 ms-2 fw-sb blue_task_button btn__bottom d9e8ec--button" style={{
                        paddingLeft: "24px",
                        paddingRight: "24px"
                    }}
                        onClick={() => {
                            if (subscriptionPrivilegeGroups[BILLING_AND_CLAIMS]?.bsc?.active) {
                                props?.submitClaim(props?.tenantId, props?.patientId, props?.providerId)
                            }
                        }}

                        disabled={props?.isProviderEnrolled}
                        // hidden={props?.hideSaveButton}
                        hidden={subscriptionPrivilegeGroups[BILLING_AND_CLAIMS]?.bsc?.active === true ? false : true}
                    >
                        Submit
                    </button>
                    <button className="btn  br-10 font-14 ms-2 fw-sb blue_task_button btn__bottom d9e8ec--button" style={{
                        paddingLeft: "24px",
                        paddingRight: "24px"
                    }}
                        onClick={() => {
                            props?.save(props?.tenantId, props?.patientId, props?.providerId)
                        }}
                        disabled={props?.disableSave}
                    // hidden={props?.hideSaveButton}
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
}


const SubmitPatientClaimPage = (props) => {

    const location = useLocation();
    const encounter = location?.state?.encounter;
    const { user } = useAuth();
    const navigate = useNavigate();
    // const [prescriptionReferenceData, isPrescriptionReferenceDataLoading] = usePrescriptionReference(location?.state?.prescriptionReferenceId);
    const [primaryInsurance, setPrimaryInsurance] = useState(encounter?.patient?.patientInsurance?.find(insurance => insurance?.type === "Primary") ?? null);
    const [diagnosisList, setDiagnosisList] = useState([]);

    const [errors, setErrors] = useState([]);


    const { placeOfServiceCodes, isPlaceOfServiceCodeLoading } = useClaimPlaceOfServiceCodes();
    const placeOfServiceMap = new Map();

    for (const { code, name } of placeOfServiceCodes) {
        placeOfServiceMap.set(code, name);
    }

    const [services, setServices] = useState([1]);
    const [serviceObjects, setServiceObjects] = useState([]);
    const [serviceDto, setServiceDto] = useState({});

    const [isClaimSubmitting, setIsClaimSubmitting] = useState(false);
    const [openClaimSnackbar, claimSnackbarMessage, claimSnackbarSeverity, setOpenClaimSnackbar, setClaimSnackbarMessage, setClaimSnackbarSeverity, handleClaimSnackbarClose] = useSnackbar();
    const [disableSave, setDisableSave] = useState(false);
    const [patientInsuranceDataId, setPatientInsuranceDataId] = useState(null);


    const [billingProviders, setBillingProviders] = useState([]);
    const [areBillingProvidersLoading, setAreBillingProvidersLoading] = useState(false);
    const [selectedBillingProvider, setSelectedBillingProvider] = useState(encounter?.provider?.providerId);

    const [isCheckingEnrollment, setIsCheckingEnrollment] = useState(false);
    const [isProviderEnrolled, setIsProviderEnrolled] = useState(true);

    const checkEnrollment = async (tenantId, providerId) => {
        setIsCheckingEnrollment(true);
        const httpResponse = await sendHttpRequest({
            url: `/claims/enrollment/status/tenants/${tenantId}/providers/${providerId}`,
            method: 'GET'
        });

        if (httpResponse.data == true) {
            setIsProviderEnrolled(p => true);
        }
        else {
            setIsProviderEnrolled(p => false);
        }


        setIsCheckingEnrollment(false);

    }

    const fetchBillingProviders = async () => {
        setAreBillingProvidersLoading(true);
        const httpResponse = await sendHttpRequest({
            // url: providerWithNpiUrl(user?.tenantId),
            url: `/claims/tenants/${user?.tenantId}/providers`,
            method: "GET",
        });
        if (!httpResponse.error) {
            setBillingProviders(httpResponse.data);
        } else {
            setBillingProviders([]);
            console.log(httpResponse.error);
        }
        setAreBillingProvidersLoading(false);
    };

    function handlePatientInsuranceDataId(event) {
        setPatientInsuranceDataId(event.target.value);
    }

    // console.log(serviceDto);
    // console.log("encounter", encounter);

    async function saveClaim(tenantId, patientId, providerId) {
        setIsClaimSubmitting(true);
        let servicesList = serviceObjects;
        if (Object.keys(serviceDto).length > 0) {
            servicesList = [...serviceObjects, serviceDto];
        }
        const data = {
            claimEncounterDataId: encounter?.encounterId,
            patientInsuranceDataId: patientInsuranceDataId,
            encounter: encounter,
            claimServices: servicesList,
            patientId: patientId,
            providerId: providerId,
            tenantId: tenantId,
            billingProvider: {
                providerId: selectedBillingProvider
            }
        }

        const httpResponse = await sendHttpRequest({ method: 'POST', url: `/claims/tenants/${tenantId}/insurance-claims`, data: data });
        if (!httpResponse.error) {
            setClaimSnackbarMessage("Claim data saved successfully");
            setClaimSnackbarSeverity("success");
            setOpenClaimSnackbar(true);
            setDisableSave(true);
            setTimeout(() => {
                navigate('/claims-all')
            }, 2000);
        }
        else {
            setClaimSnackbarMessage("Error while saving claims data");
            setClaimSnackbarSeverity("error");
            setOpenClaimSnackbar(true);
            setDisableSave(false);

        }
        setIsClaimSubmitting(false);
    }

    const dismissError = (index) => {
        setErrors((prevErrors) => prevErrors.filter((_, i) => i !== index));
    };


    async function submitClaim(tenantId, patientId, providerId) {
        setIsClaimSubmitting(true);
        let servicesList = serviceObjects;
        if (Object.keys(serviceDto).length > 0) {
            servicesList = [...serviceObjects, serviceDto];
        }
        const data = {
            claimEncounterDataId: encounter?.encounterId,
            patientInsuranceDataId: patientInsuranceDataId,
            encounter: encounter,
            claimServices: servicesList,
            patientId: patientId,
            providerId: providerId,
            tenantId: tenantId,
            billingProvider: {
                providerId: selectedBillingProvider
            }
        }

        console.log(data);

        const httpResponse = await sendHttpRequest({ method: 'POST', url: `/claims/tenants/${tenantId}/insurance-claims/save-and-submit`, data: data });
        console.log(httpResponse);
        if (!httpResponse.error) {
            setClaimSnackbarMessage("Claim submitted successfully");
            setClaimSnackbarSeverity("success");
            setOpenClaimSnackbar(true);
            setDisableSave(true);
            setTimeout(() => {
                navigate('/claims-all')
            }, 2000);
        }
        else if (httpResponse.status === 400 || httpResponse.status === 500 || httpResponse.error) {
            setClaimSnackbarMessage("Error in Claim Processing");
            setClaimSnackbarSeverity("error");
            setOpenClaimSnackbar(true);
            const errorsList = httpResponse.errorMessage?.object;
            setErrors(errorsList);

        }
        else {
            const errors = httpResponse?.data?.claimResults?.errors;
            setErrors(p => errors);
        }
        setIsClaimSubmitting(false);
    }



    useEffect(() => {
        setDiagnosisList(encounter?.diagnosis);
        fetchBillingProviders().then(() => {
            checkEnrollment(user?.tenantId, selectedBillingProvider);
        });

        let primaryInsurance = encounter?.patient?.patientInsurance?.find((item) => { return item.type === "Primary" })
        if (primaryInsurance !== undefined) {
            setPatientInsuranceDataId(primaryInsurance?.patientInsuranceId)
        }
    }, [location?.state?.encounter]);






    return (
        <>
            <PageHeader title="Claim Preparation" sticky={true}>
            </PageHeader>
            {/* <PatientStaticProfileHeader
                patientId={encounter?.patient?.patientId}
                user={user}
                setIsPatientNotFound={setIsPatientNotFound}

            /> */}
            {/* <div className="border--default" /> */}
            <main>

                <div className='container py-3'>

                    {errors?.length > 0 ? (
                        <>
                            <div className="row justify-content-center mt-3">
                                <div className="col-sm-7">
                                    {errors?.map((error, index) => {
                                        return (
                                            <Alert
                                                key={index}
                                                variant="danger"
                                                dismissible
                                                className='font-10'
                                                onClose={() => dismissError(index)}
                                            >
                                                <strong>{error?.Code}</strong> - {error?.SupplementalInformation}
                                            </Alert>
                                        );
                                    })}
                                </div>
                            </div>
                        </>
                    ) : null}

                    <div className='row justify-content-center'>
                        <div className='col-sm-7'>
                            {isProviderEnrolled === false ? <div className='row py-2 justify-content-center'>
                                <div className='col-sm-8'>
                                    <div className="alert alert-danger font-10" role="alert">
                                        Provider Enrollement is Incomplete.
                                    </div>
                                </div>
                            </div> : null}
                            <div className='row px-2'>
                                <div className="col-sm-3">
                                    <span className='d-block fw-sb font-12 text--secondary '>
                                        Provider
                                    </span>
                                    <span className='d-block  font-12 text--terciary'>
                                        {/* {getProviderConcatenatedNameFromNameObject(prescriptionReferenceData?.providerTenantPracticeLocation?.provider?.name?.at(0))} */}
                                        {`${encounter?.provider?.prefix} ${encounter?.provider?.firstName} ${encounter?.provider?.lastName}`}
                                    </span>
                                </div>
                                <div className="col-sm-3">
                                    <span className='d-block fw-sb font-12 text--secondary '>
                                        Service Location
                                    </span>
                                    <span className='d-block font-12 text--terciary'>
                                        {/* {prescriptionReferenceData?.providerTenantPracticeLocation?.facility?.businessName} */}
                                        {`${encounter?.encounterMetadata?.serviceLocation}`}
                                    </span>
                                </div>
                                <div className="col-sm-4">
                                    <span className='d-block fw-sb font-12 text--secondary '>
                                        Place of Service
                                    </span>

                                    <span className='font-12 text--terciary'>

                                        {placeOfServiceMap.get(encounter?.encounterMetadata?.placeOfService)}</span>


                                </div>
                            </div>
                            {/* {console.log("encounter", encounter)} */}
                            <div className='row px-2'>
                                <div className="col-sm-3">
                                    <span className='d-block fw-sb font-12 text--secondary '>
                                        Service Date
                                    </span>
                                    <span className='d-block  font-12 text--terciary'>
                                        {/* {getProviderConcatenatedNameFromNameObject(prescriptionReferenceData?.providerTenantPracticeLocation?.provider?.name?.at(0))} */}
                                        {calenderDateTimeHelper(encounter?.encounterMetadata?.dateOfServiceTo, "MMM DD, YYYY")}
                                        {/* {`${encounter?.provider?.prefix} ${encounter?.provider?.firstName} ${encounter?.provider?.lastName}`} */}
                                    </span>
                                </div>
                                <div className="col-sm-3">
                                    <span className='d-block fw-sb font-12 text--secondary '>
                                        Last Seen Date
                                    </span>
                                    <span className='d-block font-12 text--terciary'>
                                        {/* {prescriptionReferenceData?.providerTenantPracticeLocation?.facility?.businessName} */}
                                        {calenderDateTimeHelper(encounter?.lastSeenDate, "MMM DD, YYYY")}
                                    </span>
                                </div>
                                <div className="col-sm-3">
                                    <span className='d-block fw-sb font-12 text--secondary '>
                                        Date of Current Illness
                                    </span>
                                    <span className='d-block font-12 text--terciary'>
                                        {/* {prescriptionReferenceData?.providerTenantPracticeLocation?.facility?.businessName} */}
                                        {calenderDateTimeHelper(encounter?.dateOfCurrentIllnessOrSymptom, "MMM DD, YYYY")}
                                    </span>


                                </div>
                                {encounter?.patient?.gender?.toLowerCase() === "female" && encounter?.lastMenstrualPeriodDate !== null ? <div className="col-sm-3">
                                    <span className='d-block fw-sb font-12 text--secondary '>
                                        Last Menstural Period
                                    </span>
                                    <span className='d-block font-12 text--terciary'>
                                        {/* {prescriptionReferenceData?.providerTenantPracticeLocation?.facility?.businessName} */}
                                        {calenderDateTimeHelper(encounter?.lastMenstrualPeriodDate, "MMM DD, YYYY")}
                                    </span>


                                </div> : null}
                            </div>


                            <div className="row px-2 mt-2">
                                <div className='col-sm-5'>
                                    <span className='d-block fw-sb font-12 text--secondary '>
                                        Insurance  <span>&#42;</span>
                                    </span>
                                    <select
                                        className={`form-select br-10 font-12 input border--default shadow-none`}
                                        aria-label="Insurance Select"
                                        onChange={(e) => handlePatientInsuranceDataId(e)}
                                        value={patientInsuranceDataId}
                                    >
                                        <option value="" selected>Select Insurance</option>
                                        {encounter?.patient?.patientInsurance?.map(insurance => {
                                            return <option className='font-12' value={insurance?.patientInsuranceId}>{insurance?.payerName} - {insurance?.type}</option>
                                        })}
                                    </select>
                                </div>

                                <div className='col-sm-5 offset-sm-1'>
                                    <span className='d-block fw-sb font-12 text--secondary '>
                                        Billing Provider  <span>&#42;</span>
                                    </span>
                                    <select
                                        className={`form-select br-10 font-12 input border--default shadow-none`}
                                        aria-label="Insurance Select"
                                        onChange={(e) => {
                                            setSelectedBillingProvider(e.target.value)
                                            checkEnrollment(user?.tenantId, e.target.value);
                                        }}
                                        value={selectedBillingProvider}
                                    >
                                        <option value="" selected>Select Billing Provider</option>
                                        {billingProviders?.map(provider => {
                                            return <option className='font-12' value={provider?.providerId}>{provider?.prefix} {provider?.firstName} {provider?.lastName}</option>
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className='row px-2 mb-5 pb-4'>
                                {services.map((s) => <ClaimService serviceDto={serviceDto} setServiceDto={setServiceDto} setServiceObjects={setServiceObjects} key={s} serviceNumber={s} diagnoses={diagnosisList} />)}
                                <div className=' text-end'>
                                    <button
                                        onClick={() => {
                                            const slist = [...services];
                                            slist.push(slist[slist.length - 1] + 1);
                                            setServices(p => slist);
                                            setServiceObjects([...serviceObjects, serviceDto]);
                                            setServiceDto({});

                                        }}
                                        className="btn pn-button-icon-secondary  br-10 shadow-none lh-0 py-0 ps-0 pe-1 align-items-center fw-sb text--secondary font-12">
                                        <span className="pn-button-icon-secondary pe-1"><MedicalServices color="#336383" size={14} /> </span>
                                        <span className="d-inline-block pn-link-button" style={{ verticalAlign: 'middle' }}>Add Service</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-4 border--left'>

                            <div>
                                <div className='row py-1'>
                                    <div className='col-12'>
                                        <span className='text--terciary d-block font-14 fw-sb'>Patient</span>
                                    </div>
                                </div>
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Name</div>
                                    <div className='col-7 font-12'>{`${encounter?.patient?.firstName} ${encounter?.patient?.lastName}`}</div>
                                </div>
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>DOB</div>
                                    <div className='col-7 font-12'>{calenderDateTimeHelper(encounter?.patient?.dob, "MMM DD, YYYY")}</div>
                                </div>

                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Gender</div>
                                    <div className='col-7 font-12'>{`${encounter?.patient?.gender}`}</div>
                                </div>
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Address</div>
                                    <div className='col-7 font-12'>
                                        {`${encounter?.patient?.addressLine1}${encounter?.patient?.addressLine2 !== null ? `, ${encounter?.patient?.addressLine2}` : ''}, ${encounter?.patient?.city}, ${encounter?.patient?.state}, ${encounter?.patient?.zipCode}`}
                                    </div>
                                </div>

                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Phone</div>
                                    <div className='col-7 font-12'>{`${encounter?.patient?.primaryPhone}`}</div>
                                </div>


                            </div>

                            {primaryInsurance !== null ? <div>
                                <div className='row py-1'>
                                    <div className='col-12'>
                                        <span className='text--terciary d-block font-14 fw-sb'>Insurance</span>
                                    </div>
                                </div>
                                {primaryInsurance?.payerName !== null ? <div className='row pb-1'>
                                    <div className='col-4 font-12'>Payer Name</div>
                                    <div className='col-7 font-12'>{primaryInsurance?.payerName}</div>
                                </div> : null}
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Type</div>
                                    <div className='col-7 font-12'>{primaryInsurance?.type}</div>
                                </div>
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Member ID</div>
                                    <div className='col-7 font-12'>{primaryInsurance?.memberId}</div>
                                </div>
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Group</div>
                                    <div className='col-7 font-12'>{primaryInsurance?.groupNumber}</div>
                                </div>
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Effective Date </div>
                                    <div className='col-7 font-12'>    {calenderDateTimeHelper(primaryInsurance?.effectiveDate, "MMM DD, YYYY")}
                                    </div>
                                </div>


                            </div> : null}
                        </div>
                    </div>
                </div>
                <BottomNavigation
                    isProviderEnrolled={!isProviderEnrolled || disableSave || patientInsuranceDataId === null || patientInsuranceDataId == "" || serviceDto?.diagnoses == undefined || serviceDto?.diagnoses == null ||
                        serviceDto?.hcpcsProcedureCode == undefined ||
                        (serviceDto?.modifiers == undefined || serviceDto?.modifiers == null || serviceDto?.modifiers?.length == 0) ||
                        serviceDto?.charges == undefined || (serviceDto.note !== undefined && serviceDto.note.length > 80) || serviceDto?.dateOfServiceTo == undefined || serviceDto?.dateOfServiceFrom == undefined} disableSave={disableSave || patientInsuranceDataId === null || patientInsuranceDataId == "" || (serviceDto.note !== undefined && serviceDto.note.length > 80) || serviceDto?.diagnoses == undefined || serviceDto?.diagnoses == null ||
                            serviceDto?.hcpcsProcedureCode == undefined || (serviceDto?.modifiers == undefined || serviceDto?.modifiers == null || serviceDto?.modifiers?.length == 0) ||
                            serviceDto?.charges == undefined || serviceDto?.dateOfServiceTo == undefined || serviceDto?.dateOfServiceFrom == undefined} patientInsuranceDataId={patientInsuranceDataId} submitClaim={submitClaim} save={saveClaim} patientId={encounter?.patient?.patientId} providerId={encounter?.provider?.providerId} tenantId={user?.tenantId} hideSaveButton={false} />
            </main>
            <Backdrop
                sx={{
                    color: "#EC609B",
                    zIndex: (theme) => theme.zIndex.drawer + 1000,
                }}
                open={isClaimSubmitting || isCheckingEnrollment}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {openClaimSnackbar ? <CustomizedSnackbars
                message={claimSnackbarMessage}
                severity={claimSnackbarSeverity}
                open={openClaimSnackbar}
                handleClose={handleClaimSnackbarClose}
            /> : null}

            {
                console.log("serviceDto: ", serviceDto)
            }
            {console.log("serviceObjects: ", serviceObjects)}
        </>
    )
}




export default SubmitPatientClaimPage
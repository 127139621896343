import { Box, Grid, Typography, Stack, Skeleton } from "@mui/material";
import classes from "../patient/PatientItem.module.css";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import sendHttpRequest from "../hooks/sendHttpRequest";
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FacilityItem from "../patient/FacilityItem";
import { useAuth } from "../hooks/useAuth";
import PageHeader from "../../patientportal/generic/PageHeader";

const FacilityPage = (props) => {

  const [isLoading, setIsLoading] = useState(true);
  const [patientId, setPatientId] = useState("");
  const [fileUploadModal, setFileUploadModal] = useState(false);
  const [statusCodeFacility, setStatusCodeFacility] = useState("");
  const [updatePatientId, setUpdatePatientId] = useState("");
  const [openFacesheatModal, setOpenFacesheatModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Something went wrong");
  const [facesheetUploaded, setFacesheetUploaded] = useState(false);
  const [facesheetUploadedFail, setFacesheetUploadedFail] = useState(false);
  const [isUploading, setisUploading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [isEditSent, setIsEditSent] = useState(false);
  const [newUserModal, setNewUserModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [userToEdit, setUserToEdit] = useState([]);
  const { user } = useAuth();
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);


  //Provider Dropdown code
  const dispatch = useDispatch();
  const [facilityList, setFacilityList] = useState([]);
  const [isSessionUpdated, setIsSessionUpdated] = useState(false);
  const [isFacilityFetched, setisFacilityFetched] = useState(false);
  const isPracticeAdmin = localStorage.getItem("role");

  //Navigate to Add user page
  const navigate = useNavigate();

  const fetchAllFacilities = async () => {
    const httpResponse = await sendHttpRequest({
      url: `/facilities/tenant/${user?.tenantId}`,
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
      setisFacilityFetched(true);
      setFacilityList(httpResponse.data);
    } else {
      setFacilityList([]);
      console.log(httpResponse.error);
    }
    setIsLoading(false);
    setStatusCodeFacility(httpResponse.status);
  };

  const handleEditUserModal = (id) => {
    let userResult = [];
    if (id !== "") {
      facilityList?.length > 0 &&
        (userResult = facilityList.filter((item) => {
          return item.Id == id;
        }));
    }
    setUserToEdit(userResult[0]);

    setEditUserModal(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsSent(false);
    setIsEditSent(false);
  };

  const handleFacesheetClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setFacesheetUploaded(false);
  };

  const handleFacesheetUploadFailClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setFacesheetUploadedFail(false);
  };

  const handleFileUploadModal = (patientId) => {
    setPatientId(patientId);
    setFileUploadModal(!fileUploadModal);
  };

  if (isSessionUpdated === true) {
    fetchAllFacilities();
    setIsSessionUpdated(false);
  }

  useEffect(() => {
    fetchAllFacilities();
  }, []);

  return (
    <>
      <PageHeader title="Facilities">
        <div className="align-self-center">
          <button
            className="btn font-12 text-white fw-sb px-5 br-10 task_button"
            hidden={subscriptionPrivilegeGroups['Practice Management'] !== undefined ? subscriptionPrivilegeGroups['Practice Management']?.aft?.active === true ? false : true : false}
            onClick={() => {
              navigate("/add-facility");
            }}
          >
            {"Add Facility"}
          </button>
        </div>
      </PageHeader>
      <div className="container-fluid mt-3">
        <div className="d-flex pe-2 flex-row justify-content-end">
          <div className="">
            <div className="input-group  border--default br-10 bg--disabled ">
              <input
                type="text"
                disabled
                className={`form-control border-0 ms-1 bg--disabled`}
                placeholder="Search facility"
              />
              <button
                className="btn bg--disabled shadow-none"
                disabled
                type="submit"
              >
                <SearchIcon className="text--secondary" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid ps-5 pe-5">
        <div className="row justify-content-center">
          <div className="col-md col-sm my-3">
            <Grid
              container
              sx={{ marginBottom: "-8px" }}
              p={1}
              alignItems="center"
              className={`bg-white ${classes["row__head"]} `}
            >
              <Grid item xs={3.5}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography component="div">
                    <Box
                      sx={{
                        textAlign: "right",
                        m: 1,
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#336383",
                      }}
                    >
                      Name
                    </Box>
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={3.5}>
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      // mt:1,
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "#336383",
                    }}
                  >
                    Address
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      // mt:1,
                      fontWeight: "400",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "#336383",
                    }}
                  >
                    Phone
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      // mt:1,
                      fontWeight: "400",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "#336383",
                    }}
                  >
                    Fax
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
            {isLoading ? (
              <Skeleton animation="wave" />
            ) : statusCodeFacility === 200 ? (
              facilityList?.length > 0 ? (
                facilityList?.map((facility, index) => {
                  if (index === facilityList?.length - 1) {
                    return (
                      <FacilityItem
                        key={index}
                        facility={facility}
                        isLast={true}
                        handleEditUserModal={handleEditUserModal}
                      />
                    );
                  } else {
                    return (
                      <FacilityItem
                        key={index}
                        facility={facility}
                        handleEditUserModal={handleEditUserModal}
                      />
                    );
                  }
                })
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <CustomizedSnackbars
        message={errorMessage}
        severity="error"
        open={facesheetUploadedFail}
        handleClose={handleFacesheetUploadFailClose}
      />
    </>
  );
};

export default FacilityPage;

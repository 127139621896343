import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router"
import MedicationIcon from '@mui/icons-material/Medication';
import BiotechIcon from '@mui/icons-material/Biotech';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import PageHeader from "../../patientportal/generic/PageHeader";
import TextArea from "../forms/TextArea";
import LabRequisitionFormModal from "../UI/Modals/LabRequisitionFormModal";
import CustomizedSnackbar from "../UI/General/CustomizedSnackbars";
import DiagnosisModal from "../providernote/DiagnosisModal";
import { useAuth } from "../hooks/useAuth";
import ProviderNoteService from "../Services/ProviderNote.service";
import DiagnosisTableProviderNote from "../providernote/DiagnosisItem";
import DeleteDiagnosisModal from "../providernote/DeleteDiagnosisModal";
import { Backdrop, CircularProgress, Skeleton, LinearProgress } from "@mui/material";
import EditDiagnosisModal from "../providernote/EditDiagnosisModal";
import useModal from "../hooks/useModal";
import NewPrescriptionModal from "../UI/Modals/PrescriptionModal/NewPrescriptionModal";
import PrescriptionRequests from "./PrescriptionRequests";
import useProviderNoteDiagnosis from "../hooks/useProviderNoteDiagnosis";
import EditButtonIcon from "../../assets/icons/Icon-Edit.svg";
import TrashButtonIcon from "../../assets/icons/Icon-Trash.svg";
// import { CloudDownloadIcon } from "react-bootstrap-icons";
// import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteAllMedicationsConfirmationModal from "../providernote/DeleteAllMedicationsConfirmationModal";
import useSnackbar from "../hooks/useSnackbar";
import LabOrderService from "../Services/LabOrder.service";
import DeleteLabOrderConfirmationModal from "../providernote/LabOrder/DeleteLabOrderConfirmationModal";
import { PnToolTip } from "../UI/Reusable/PnComponents";
import { updatePrescribedMedication } from "../../redux/actions/providerActions";
import { useDispatch, useSelector } from "react-redux";
import { calenderDateTimeHelper } from "../../utils/dateTimeHelper";
import PatientDemographics from "../providernote/PatientDemographics";
import NotFoundMessage from "../UI/General/NotFoundMessage";
import { ENCOUNTERS, TASKS } from "../../utils/helpers/subscriptionsConstants";



function BottomNavigation(props) {
    return (
        <div className="  position-fixed w-100 mb-1 pb-4 bottom-0 mx-auto  bg-white bottom__nav--shadow" hidden={props?.permissionHidden === false ? true : false}>
            <LinearProgress variant="determinate" style={{
                backgroundColor: "#D9E8EC",
                height: "1px"
            }} value={100} />

            <div className="d-flex p-3 ">
                <div className="position-fixed " style={{
                    left: "88%"
                }}>
                    {<button
                        disabled={props?.summaryModal}
                        className="btn ms-2 blue_task_button"
                        onClick={function (event) {
                            props.handleChange();
                        }}
                    >
                        Save
                    </button>}
                </div>
            </div>
        </div>
    );
}


const ProviderNotePage = () => {

    const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);

    const { pId } = useParams();
    const { user } = useAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { openDiagnosisModal, handleDiagnosisModal, openDiagnosisSnackbar, handleDiagnosisSnackBarClose, diagnosisAddedMessage, setDiagnosisAddedMessage, diagnosisSeverity, setDiagnosisSeverity, callApi, setCallApi, openDeleteDiagnosisModal, handleDeleteDiagnosisModal, openEditDiagnosisModal, handleEditDiagnosisModal, setOpenEditDiagnosisModal, setOpenDeleteDiagnosisModal, deleteDiagnosisLoading, deletedDiagnosis, deleteDiagnosis, editedDiagnosis, isDiagnosisUpdating, updateDiagnosis, setOpenDiagnosisSnackBar
    } = useProviderNoteDiagnosis();
    const [openLabRequisitionModal, setOpenLabRequisitionModal] = useState(false);
    const [openLabOrderSnackBar, setOpenLabOrderSnackBar] = useState(false);
    const [prescriptionReference, setPrescriptionReference] = useState(undefined);
    const [message, setMessage] = useState();
    const [severity, setSeverity] = useState();
    const [prescriptionReferenceData, setPrescriptionReferenceData] = useState(undefined);
    const [prescriptionReferenceIsLoading, setPrescriptionReferenceIsLoading] = useState(false);
    const [isPrescriptionReferenceIdLoading, setIsPrescriptionReferenceIdLoading] = useState(false);
    const [isPrintInProgress, setIsPrintInProgress] = useState(false);

    const [providerNoteText, setProviderNoteText] = useState("");
    const [isProviderNoteSaving, setIsProviderNoteSaving] = useState(false);
    const [prescriptionData, setPrescriptionData] = useState();

    const referenceFrom = useRef("provider-note");

    const [isPatientNotFound, setIsPatientNotFound] = useState(false);




    //=====New Prescription Modal Code START=====//
    const { open, handleOpen, handleClose } = useModal(false);
    const [isSent, setIsSent] = useState(false);
    const [isPrescriptionSent, setIsPrescriptionSent] = useState(Math.random());
    const [editMedicationMode, setEditMedicationMode] = useState(false);
    const handleSetIsSent = () => {
        setIsSent(true);
    };


    //====Delete Medications Modal====//
    const { open: deleteMedicationModalOpen, handleModal: handleDeleteMedicationModal } = useModal(false);
    const [deleteMedicationSnackbarOpen, deleteMedicationSnackbarMessage, deleteMedicationSnackbarSeverity,
        setDeleteMedicationSnackbarOpen, setDeleteMedicationSnackbarMessage, setDeleteMedicationSnackbarSeverity,
        handleDeleteMedicationSnackbarClose] = useSnackbar(false);
    const [areMedicationsDeleting, setAreMedicationsDeleting] = useState(false);



    //=====Delete Lab Orders Modal====//
    const { open: deleteLabOrdersModalOpen, handleModal: handleDeleteLabOrdersModal } = useModal(false);
    const [deleteLabOrdersSnackbarOpen, deleteLabOrdersSnackbarMessage, deleteLabordersSnackbarSeverity,
        setDeleteLabOrdersSnackbarOpen, setDeleteLabOrdersSnackbarMessage, setDeleteLabOrdersSnackbarSeverity,
        handleDeleteLabOrdersSnackbarClose] = useSnackbar(false);
    const [areLabOrdersDeleting, setAreLabOrdersDeleting] = useState(false);


    //======Edit Lab Order Modal======//
    const { open: openEditLabOrderModal, handleModal: handleEditLabOrderModal } = useModal();

    //=====Navigate to Patients Page====//
    const navigateToPatientsPage = () => {
        // navigate(`/all-patients/`);
        navigate(`/all-patients/${pId}`, {
            state: { referer: "provider-note" },
        });
    }


    const handleProviderNoteText = (text) => {
        setProviderNoteText(text);
    }

    const handleLabRequisitionModal = useCallback(() => {
        setOpenLabRequisitionModal(prevIsOpen => !prevIsOpen);
    }, []);





    const handleLabOrderSnackBar = useCallback((event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenLabOrderSnackBar(prevState => !prevState);

    }, [setOpenLabOrderSnackBar]);



    const fetchProviderNotes = async () => {
        setPrescriptionReferenceIsLoading(true);
        const data = await ProviderNoteService.getProviderNoteData(prescriptionReference);
        setPrescriptionReferenceData(data);
        setPrescriptionReferenceIsLoading(false);
    }

    const fetchPrescriptionReferenceId = async () => {
        setIsPrescriptionReferenceIdLoading(true);
        const res = await ProviderNoteService.getPrescriptionReferenceNumberFromId(pId, user?.userId);
        setPrescriptionReference(res.prescriptionReferenceId)
        setIsPrescriptionReferenceIdLoading(false);
    }

    const handleSaveProviderNote = async () => {
        setIsProviderNoteSaving(true);
        const providerNoteDto = {
            "workflowStatus": "ACTIVE",
            "providerNote": {
                "note": providerNoteText
            },
            "spi": user?.spi ?? null
        }
        const res = await ProviderNoteService.saveProviderNote(prescriptionReference, providerNoteDto);
        if (res === true) {
            setOpenLabOrderSnackBar(p => true);
            setMessage("Provider note saved successfully");
            setSeverity("success");
        }
        if (res === true) {
            setIsProviderNoteSaving(false);
            setTimeout(navigateToPatientsPage(), 3000);
        }
    }

    const handlePrintAllMedications = async (prescriptionReference) => {
        setIsPrintInProgress(true);
        const res = await ProviderNoteService.printAllMedications(prescriptionReference);
        setIsPrintInProgress(false);
    }

    const handleDeleteMedications = async (prescriptionReference) => {
        setAreMedicationsDeleting(true);
        const res = await ProviderNoteService.deleteAllMedications(prescriptionReference);
        setAreMedicationsDeleting(false);
        if (typeof res === "boolean" && res === true) {
            setDeleteMedicationSnackbarMessage("Medications deleted successfully");
            setDeleteMedicationSnackbarSeverity("success");
            setDeleteMedicationSnackbarOpen(true);
        }
        else {
            setDeleteMedicationSnackbarMessage("Error while deleting medications");
            setDeleteMedicationSnackbarSeverity("error");
            setDeleteMedicationSnackbarOpen(true);
        }
        handleDeleteMedicationModal();
        setCallApi(prev => !prev);
    };

    const handlePrintAllLabOrders = async (labOrderId) => {
        setIsPrintInProgress(true);
        const res = await LabOrderService.printAllLabOrders(labOrderId);
        setIsPrintInProgress(false);
    }

    const handleDeleteLabOrders = async (labOrderId) => {
        setAreLabOrdersDeleting(true);
        const res = await LabOrderService.deleteAllLabOrders(labOrderId);
        setAreLabOrdersDeleting(false);
        if (typeof res === "boolean" && res === true) {
            setDeleteLabOrdersSnackbarMessage("Lab Order deleted successfully");
            setDeleteLabOrdersSnackbarSeverity("success");
            setDeleteLabOrdersSnackbarOpen(true);
        }
        else {
            setDeleteLabOrdersSnackbarMessage("Error while deleting Lab Order");
            setDeleteLabOrdersSnackbarSeverity("error");
            setDeleteLabOrdersSnackbarOpen(true);
        }
        handleDeleteLabOrdersModal();
        setCallApi(prev => !prev);
    };

    //===Handle Edit Medication====//
    const handleEditMedications = (prescriptionData) => {
        //  console.log("Called");
        let medicationList = [];
        for (let i = 0; i < prescriptionData?.medicationRx?.length; i++) {
            let medItem = prescriptionData?.medicationRx[i];
            console.log("medItem: ", medItem);
            medicationList.push({
                DDID: medItem?.drugId ?? medItem?.DDID,
                drugDbCode: medItem?.drugId ?? medItem?.DDID,
                drugId: medItem?.drugId ?? medItem?.DDID,
                drugDescription: medItem?.drugDescription,
                ndc: medItem?.ndc,

                Sig: {
                    SigText: medItem?.sigText ?? medItem?.Sig?.SigText,
                },
                sigText: medItem?.sigText ?? medItem?.Sig?.SigText,
                textValue: medItem?.sigText ?? medItem?.Sig?.SigText,
                Quantity: parseFloat(medItem?.quantity ?? medItem?.Quantity),
                daySupply: medItem?.daysSupply ?? medItem?.daySupply,
                NumberOfRefills: medItem?.refills ?? medItem?.NumberOfRefills,
                RefillAsNeeded: false,
                DispenseAsWritten: medItem?.substitution ?? medItem?.DispenseAsWritten,
                // Note: medItem?.pharmacyNote !== undefined ? medItem?.pharmacyNote : "",
                Note: medItem?.pharmacyNote ?? medItem?.Note,
                DoseForm: medItem?.quantityUnitOfMeasure ?? medItem?.DoseForm,
                PrescriptionStartDate: {
                    Date: calenderDateTimeHelper(medItem?.prescriptionStartDate ?? medItem?.PrescriptionStartDate?.Date, "YYYY-MM-DD"),
                },
                EarliestFillDate: {
                    Date: calenderDateTimeHelper(medItem?.earliestFillDate ?? medItem?.EarliestFillDate?.Date, "YYYY-MM-DD"),
                },
                WrittenDate: {
                    Date: calenderDateTimeHelper(
                        new Date(),
                        "YYYY-MM-DD"
                    ),
                },
                alertPhysician72: medItem?.alertPhysician ?? medItem?.alertPhysician72,
                PatientDirections: "",
                flag: true,
                quantityUom: medItem?.quantityUnitOfMeasure ?? medItem?.quantityUom,
                quantityUnitOfMeasureCode:
                    medItem?.quantityUnitOfMeasureCode,
            },)
        }
        console.log("medicationList: ", medicationList);
        if (prescriptionData !== undefined && medicationList?.length > 0) {
            //   setPrescriptionData({medicationRx:medicationList,pharmacyOrganisationId:prescriptionData?.pharmacyOrganisationId});,
            setPrescriptionData({ medicationRx: [...medicationList] })
            dispatch(updatePrescribedMedication([...medicationList]));

        }
        setEditMedicationMode(true);
        handleOpen();

    }


    useEffect(() => {
        console.log("getting prescription reference number...");
        if (isPatientNotFound === false) {
            fetchPrescriptionReferenceId();
        }

    }, [pId, user?.userId])

    useEffect(() => {
        if (prescriptionReference !== undefined) {
            console.log("Getting provider note data");
            fetchProviderNotes();
        }
    }, [callApi])


    return (isPatientNotFound === false ? <>
        <PageHeader title="Provider Note" />
        {/* <PatientStaticProfileHeader soapNote={true} patientId={pId} /> */}
        <PatientDemographics patientId={pId} setIsPatientNotFound={setIsPatientNotFound} />
        <main>
            <div className="container-fluid py-3 mb-3">

                <div className="row justify-content-center">
                    <div className="col-sm-9">
                        <div className="row justify-content-end">
                            <div className="col-sm-6">
                                <label className="form-label font-12 text--secondary ms-1 p-0">Provider Note</label>
                            </div>
                            <div className="col-sm-6 ">
                                <div className=" d-flex align-self-center justify-content-end" role="group">
                                    {
                                        subscriptionPrivilegeGroups[ENCOUNTERS]?.adg?.active !== false &&
                                        <button
                                            onClick={handleDiagnosisModal}
                                            className="btn pn-button-icon-secondary  br-10 shadow-none lh-0 py-0 ps-0 pe-1 align-items-center fw-sb text--secondary font-12"
                                        >
                                            <span className="pn-button-icon-secondary pe-1"><MedicalInformationIcon color="#336383" size={14} /> </span>
                                            <span className="d-inline-block pn-link-button" style={{ verticalAlign: 'middle' }}>Add Diagnosis</span>
                                        </button>
                                    }
                                    {
                                        subscriptionPrivilegeGroups[TASKS]?.orx?.active !== false &&
                                        <button onClick={handleOpen} className="btn pn-button-icon-secondary  br-10 shadow-none lh-0 py-0 ps-0 pe-1 align-items-center fw-sb text--secondary font-12">
                                            <span className=" pn-button-icon-secondary pe-1"><MedicationIcon color="#336383" size={14} /> </span>
                                            <span className="d-inline-block pn-link-button" style={{ verticalAlign: 'middle' }}>Add Medication</span>
                                        </button>
                                    }

                                    {
                                        subscriptionPrivilegeGroups[TASKS]?.olp?.active !== false &&
                                        <button
                                            onClick={handleLabRequisitionModal}
                                            className="btn pn-button-icon-secondary  br-10 shadow-none lh-0 py-0 ps-0 pe-1 align-items-center fw-sb text--secondary font-12">
                                            <span className="pn-button-icon-secondary pe-1"><BiotechIcon color="#336383" size={14} /> </span>
                                            <span className="d-inline-block pn-link-button" style={{ verticalAlign: 'middle' }}>Add Lab Order</span>
                                        </button>
                                    }
                                </div>
                            </div>

                        </div>



                        <TextArea
                            className="form-control br-10 input font-14 border--default shadow-none"
                            gettext={handleProviderNoteText}
                            rows="5"
                        />


                    </div>
                </div>

                <div className="row justify-content-center pt-3">
                    <div className="col-sm-5">
                        {prescriptionReferenceIsLoading ? <Skeleton style={{ width: '100%' }} animation="wave" /> :
                            prescriptionReferenceData !== undefined &&
                            prescriptionReferenceData?.patientChart !== null &&
                            prescriptionReferenceData?.patientChart?.diagnosis?.length > 0 &&
                            <h2 className="font-16 fw-sb text--terciary">Diagnoses</h2>}
                        {prescriptionReferenceIsLoading ? <Skeleton style={{ width: '100%' }} animation="wave" /> : prescriptionReferenceData !== undefined && prescriptionReferenceData?.patientChart !== null && prescriptionReferenceData?.patientChart?.diagnosis?.length > 0 && <DiagnosisTableProviderNote
                            diagnoses={prescriptionReferenceData?.patientChart?.diagnosis}
                            deleteDiagnosisConfirmation={handleDeleteDiagnosisModal}
                            handleEditDiagnosis={handleEditDiagnosisModal}
                        />}
                    </div>
                    <div className="col-4"></div>
                </div>

                <div className="row justify-content-center pt-4">
                    <div className="col-sm-9">
                        {prescriptionReferenceIsLoading ? <Skeleton style={{ width: '100%' }} animation="wave" /> : prescriptionReferenceData !== undefined &&
                            prescriptionReferenceData?.medicationRx !== null && prescriptionReferenceData?.medicationRx?.length > 0 &&
                            <div className="row">
                                <div className="col-4">
                                    <h2 className="font-16 fw-sb text--terciary">Medications Ordered</h2>
                                </div>
                                <div className="col-4">
                                    <div className="d-flex ms-5 gap-3">
                                        <div onClick={() => { handleEditMedications(prescriptionReferenceData) }} className="cursor--pointer">
                                            <PnToolTip title={"Edit Medications"}>
                                                <img
                                                    src={EditButtonIcon}
                                                    alt={"editIcon"}
                                                    id="medication-edi"
                                                    style={{ width: "15px", height: "15px" }}
                                                ></img>
                                            </PnToolTip>

                                        </div>
                                        <div className="cursor--pointer" onClick={() => handlePrintAllMedications(prescriptionReference)}>
                                            <PnToolTip title={"Download"}>
                                                <FileDownloadIcon
                                                    sx={{ color: '#336383', fontWeight: 400, fontSize: '1.3rem' }}
                                                />
                                            </PnToolTip>
                                        </div>
                                        <div
                                            onClick={handleDeleteMedicationModal}
                                            className="cursor--pointer">
                                            <PnToolTip title={"Delete Medications"}>
                                                <img
                                                    src={TrashButtonIcon}
                                                    alt={"deleteIcon"}
                                                    id="medication-delete"
                                                    style={{ width: "15px", height: "15px" }}
                                                ></img>
                                            </PnToolTip>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        }
                        {prescriptionReferenceIsLoading ? <Skeleton style={{ width: '100%' }} animation="wave" /> : prescriptionReferenceData !== undefined &&
                            prescriptionReferenceData?.medicationRx !== null &&
                            prescriptionReferenceData?.medicationRx !== undefined &&
                            prescriptionReferenceData?.medicationRx?.length > 0 &&
                            prescriptionReferenceData?.medicationRx?.map((medication, index) => {
                                return (
                                    <div className="d-flex justify-content-between">
                                        <div key={medication?.drugDbCode} className="font-14 py-1 text--terciary">
                                            {(index + 1) + ". " + medication?.drugDescription}
                                        </div>
                                        {/* <div className="d-flex gap-2">
                                                <div>
                                                    {
                                                        <img
                                                            src={EditButtonIcon}
                                                            alt={"editIcon"}
                                                            id={medication?.drugDbCode}
                                                            style={{ width: "15px", height: "15px" }}
                                                        ></img>
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        <img
                                                            src={TrashButtonIcon}
                                                            alt={"deleteIcon"}
                                                            id={medication?.drugDbCode}
                                                            className="me-2"
                                                            style={{ width: "15px", height: "15px" }}
                                                        ></img>
                                                    }
                                                </div>
                                            </div> */}
                                    </div>)
                            })
                        }
                        {/* // <MedicationListTable medications={prescriptionReferenceData?.medicationRx} />
                                // : null} */}


                    </div>
                    <div className="col-4"></div>
                </div>

                <div className="row justify-content-center py-4 mb-4" hidden={subscriptionPrivilegeGroups[TASKS]?.dlp?.active === false ? true : false}>
                    <div className="col-sm-9">
                        {prescriptionReferenceIsLoading ? <Skeleton style={{ width: '100%' }} animation="wave" /> : prescriptionReferenceData !== undefined &&
                            prescriptionReferenceData?.labOrder !== null && prescriptionReferenceData?.labOrder !== undefined && prescriptionReferenceData?.labOrder?.labOrderTest?.length > 0 &&
                            <div className="row">
                                <div className="col-4">
                                    <h2 className="font-16 fw-sb text--terciary">Labs Ordered</h2>
                                </div>
                                <div className="col-4">
                                    <div className="d-flex ms-5 gap-3">
                                        <div onClick={() => handleEditLabOrderModal()} className="cursor--pointer">
                                            <PnToolTip title="Edit Lab Order">
                                                <img
                                                    src={EditButtonIcon}
                                                    alt={"editIcon"}
                                                    id="laborder-edit"
                                                    style={{ width: "15px", height: "15px" }}
                                                ></img>
                                            </PnToolTip>
                                        </div>

                                        <div className="cursor--pointer" onClick={() => handlePrintAllLabOrders(prescriptionReferenceData?.labOrder?.labOrderId)}>
                                            <PnToolTip title={"Download"}>
                                                <FileDownloadIcon
                                                    sx={{ color: '#336383', fontWeight: 400, fontSize: '1.3rem' }}
                                                />
                                            </PnToolTip>
                                        </div>
                                        <div
                                            onClick={handleDeleteLabOrdersModal}
                                            className="cursor--pointer">
                                            <PnToolTip title={"Delete Lab Order"}>
                                                <img
                                                    src={TrashButtonIcon}
                                                    alt={"deleteIcon"}
                                                    id="laborder-delete"
                                                    style={{ width: "15px", height: "15px" }}
                                                ></img>
                                            </PnToolTip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {prescriptionReferenceIsLoading ? <Skeleton style={{ width: '100%' }} animation="wave" /> : prescriptionReferenceData !== undefined &&
                            prescriptionReferenceData?.labOrder !== null &&
                            prescriptionReferenceData?.labOrder !== undefined &&
                            prescriptionReferenceData?.labOrder?.labOrderTest?.length > 0 &&
                            prescriptionReferenceData?.labOrder?.labOrderTest !== null ?
                            prescriptionReferenceData?.labOrder?.labOrderTest?.map((test, index) => {
                                return <div key={test?.labOrderTestId} className="font-14 py-1 text--terciary">
                                    {(index + 1) + ". " + test.description}
                                </div>
                            })
                            : null}
                    </div>
                    <div className="col-4"></div>
                </div>

            </div>
            <BottomNavigation handleChange={handleSaveProviderNote} permissionHidden={subscriptionPrivilegeGroups[ENCOUNTERS]?.spn?.active} />
            {/* <div className="container-fluid"
                    style={bottomNavigationStyles}
                >
                    <div className="row justify-content-end py-3">
                        <div className="col-10 text-end">
                            <button disabled={isProviderNoteSaving} style={{ zIndex: 2 }} onClick={handleSaveProviderNote} className="btn blue_task_button font-14 ms-auto">Save</button>
                        </div>
                    </div>
                </div> */}
        </main>
        {openLabRequisitionModal ? (
            <LabRequisitionFormModal
                show={openLabRequisitionModal}
                handleModal={handleLabRequisitionModal}
                patientId={pId}
                assessmentPlan={true}
                setMessage={setMessage}
                setSeverity={setSeverity}
                handleLabOrderSnackBar={handleLabOrderSnackBar}
                setCallApi={setCallApi}
                prescriptionReference={prescriptionReference}
                referenceFrom={referenceFrom.current}
            />
        ) : null}

        {openEditLabOrderModal ? (
            <LabRequisitionFormModal
                show={openEditLabOrderModal}
                handleModal={handleEditLabOrderModal}
                patientId={pId}
                assessmentPlan={true}
                editLabOrderData={prescriptionReferenceData?.labOrder}
                referenceFrom={referenceFrom.current}
                setCallApi={setCallApi}
                providerNote={true}
                prescriptionReference={prescriptionReference}

            />
        ) : null}

        {openDiagnosisModal ? (
            <DiagnosisModal
                show={openDiagnosisModal}
                handleModal={handleDiagnosisModal}
                prescriptionReference={prescriptionReference}
                setDiagnosisAddedMessage={setDiagnosisAddedMessage}
                setDiagnosisSeverity={setDiagnosisSeverity}
                setOpenDiagnosisSnackBar={setOpenDiagnosisSnackBar}
                setCallApi={setCallApi}


            />
        ) : null}

        {openDeleteDiagnosisModal ? (
            <DeleteDiagnosisModal
                show={openDeleteDiagnosisModal}
                handleModal={handleDeleteDiagnosisModal}
                deletedDiagnosis={deletedDiagnosis}
                handleDelete={deleteDiagnosis}
                setCallApi={setCallApi}
                deleteDiagnosisLoading={deleteDiagnosisLoading}

            />
        ) : null}

        {openEditDiagnosisModal ? (
            <EditDiagnosisModal
                show={openEditDiagnosisModal}
                handleModal={handleEditDiagnosisModal}
                editDiagnosis={editedDiagnosis}
                handleEditDiagnosis={handleEditDiagnosisModal}
                setCallApi={setCallApi}
                updateDiagnosis={updateDiagnosis}
                isDiagnosisUpdating={isDiagnosisUpdating}

            />
        ) : null}

        {open ? (
            <NewPrescriptionModal
                patientId={pId}
                show={open}
                handleSetIsSent={handleSetIsSent}
                handleNewPrescriptionModal={handleClose}
                setCallApi={setCallApi}
                referenceFrom={referenceFrom.current}
                prescriptionReference={prescriptionReference}
                providerNote={true}
                prescriptionData={prescriptionData}
                prescriptionRequestModal={
                    <PrescriptionRequests
                        encounterId={pId}
                        patientId={pId}
                        handleNewPrescriptionModal={handleClose}
                        prescriptionReference={prescriptionReference}
                        handleSetIsSent={handleSetIsSent}
                        newPrescriptionRequest={true}
                        referenceFrom={referenceFrom.current}
                        setIsPrescriptionSent={setIsPrescriptionSent}
                        setCallApi={setCallApi}
                        providerNote={true}
                        prescriptionData={prescriptionData}
                        {...(editMedicationMode === true && { editMedicationModeFromProviderNote: editMedicationMode })}

                    />
                }

            />
        ) : null}

        {deleteMedicationModalOpen ? <DeleteAllMedicationsConfirmationModal
            handleModal={handleDeleteMedicationModal}
            show={deleteMedicationModalOpen}
            handleDelete={handleDeleteMedications}
            prescriptionReferenceId={prescriptionReference}
            areMedicationsDeleting={areMedicationsDeleting}
        /> : null}

        {deleteLabOrdersModalOpen ? <DeleteLabOrderConfirmationModal
            handleModal={handleDeleteLabOrdersModal}
            show={deleteLabOrdersModalOpen}
            handleDelete={handleDeleteLabOrders}
            labOrderId={prescriptionReferenceData?.labOrder?.labOrderId}
            areLabOrdersDeleting={areLabOrdersDeleting}

        /> : null}

        <CustomizedSnackbar
            message={diagnosisAddedMessage}
            severity={diagnosisSeverity}
            open={openDiagnosisSnackbar}
            handleClose={handleDiagnosisSnackBarClose}
        />

        <CustomizedSnackbar
            message={message}
            severity={severity}
            open={openLabOrderSnackBar}
            handleClose={handleLabOrderSnackBar}
        />

        <CustomizedSnackbar
            message={deleteMedicationSnackbarMessage}
            severity={deleteMedicationSnackbarSeverity}
            open={deleteMedicationSnackbarOpen}
            handleClose={handleDeleteMedicationSnackbarClose}
        />


        <CustomizedSnackbar
            message={deleteLabOrdersSnackbarMessage}
            severity={deleteLabordersSnackbarSeverity}
            open={deleteLabOrdersSnackbarOpen}
            handleClose={handleDeleteLabOrdersSnackbarClose}
        />

        <Backdrop
            sx={{
                color: "#EC609B",
                zIndex: (theme) => theme.zIndex.drawer + 1000,
            }}
            open={deleteDiagnosisLoading || isPrescriptionReferenceIdLoading || isPrintInProgress || areMedicationsDeleting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </> : <NotFoundMessage message={"Patient not found"} />
    )
}

export default ProviderNotePage
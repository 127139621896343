
import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import sendHttpRequest from "../../../../../components/hooks/sendHttpRequest";
import { useState } from "react";
import { useAuth } from "../../../../../components/hooks/useAuth";


const FeeScheduleAddModalFromClaim = (props) => {
    console.log(props);
    const [save, setSave] = useState(false);
    const [amount, setAmount] = useState(0);
    const { user } = useAuth();

    function handleSaveCheckbox(event) {
        setSave(event.target.checked);
    }


    function handleProceed() {
        console.log("Handle Proceed");
        if (save) {
            saveFeeSchedule();
            props?.setServiceData(prevState => {
                const newData = [...prevState];
                newData[props?.index]['quantity'] = 1;
                newData[props?.index]['total'] = 1 * amount;
                newData[props?.index]['charges'] = amount;

                newData[props?.index]['amount'] = parseInt(amount);
                return newData;
            })
        }
        else {
            console.log("Handle Proceed 2");
            props?.setServiceData(prevState => {
                const newData = [...prevState];
                newData[props?.index]['quantity'] = 1;
                newData[props?.index]['total'] = 1 * amount;
                newData[props?.index]['charges'] = amount;
                newData[props?.index]['amount'] = parseInt(amount);
                console.log(newData[props?.index]);
                return newData;
            })
        }
        props?.handleModal();
    }

    async function saveFeeSchedule() {
        const options = {
            method: 'POST',
            url: `/claims/tenants/${user?.tenantId}/fee-schedule`,
            data: {
                description: props?.service?.hcpcsProcedureCode,
                price: Number(amount),
                code: {
                    code: props?.service?.hcpcsProcedureCode
                },
                category: "procedure",
                charge: Number(amount),
                amount: 0,
                discount: 0
            }
        }
        const httpResponse = await sendHttpRequest(options);
        if (!httpResponse.error) {
            // props?.handleModal();
        }
        else {
            console.log(httpResponse.error);
            console.log(httpResponse);
        }
    }

    return (
        <Modal
            show={props?.show}
            onHide={() => {
                props.handleModal();
            }}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            backdropClassName="modal-backdrop"
            contentClassName={`modal-border modal-shadow `}
            scrollable={false}
            centered

        // style={{style}}
        >
            <Modal.Header  >
                <span
                    style={{
                        lineHeight: "12px",
                        width: "18px",
                        fontSize: "8pt",
                        color: "#336383",
                        position: "absolute",
                        top: 15,
                        right: 15,
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        // props?.setAmount(null);

                        props?.handleModal()
                    }}
                >
                    {<CloseIcon />}
                </span>
                <Modal.Title className="font-20 fw-sb">Fee Schedule Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container-fluid px-2">
                    <div className="row  justify-content-center">
                        <div className="col-12">
                            <p className="font-12 text--terciary">Please add the details for the following selected CPT Code:</p>
                            <span className="d-block font-12 text--secondary fw-sb">
                                {/* {props?.selectedCptCode?.code} - {props?.selectedCptCode?.description} */}
                                {props?.service?.hcpcsProcedureCode} - {props?.service?.hcpcsProcedureDescription}
                            </span>
                            <div className="row mt-2">
                                <div className="col-2">
                                    <span className='fw-thin ms-1 font-12 text--secondary d-block'>Amount</span>

                                    <input value={amount} onChange={(e) => {
                                        setAmount(e.target.value);

                                    }} type="text" className='form-control font-14 m-0 border--default br-10 shadow-none input' />
                                </div>
                                <div className="col-8" style={{ alignContent: 'end' }}>
                                    <div className="form-check ms-1" >
                                        <input
                                            onChange={handleSaveCheckbox}
                                            checked={save}

                                            type="checkbox"
                                            className="form-check-input mt-1 shadow-none"
                                            id="cptsave"
                                        />
                                        <label
                                            htmlFor="cptsave"
                                            className="form-check-label font-12 ms-1 text--secondary"
                                        >
                                            Save this fee schedule for later use also
                                        </label>
                                    </div>
                                </div>

                            </div>
                            <div className="row mt-2 justify-content-end">
                                <div className="col-4">
                                    <button
                                        onClick={handleProceed}
                                        disabled={isNaN(amount)}
                                        className="btn blue_task_button">Proceed</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default FeeScheduleAddModalFromClaim;

  import TAGS from './tags';

  const RolesTapMap = new Map([
    ["Physician", [TAGS.GEN, TAGS.MED, TAGS.CT]],
    ["Nurse Practitioner", [TAGS.GEN, TAGS.MED]],
    ["Practice Admin", [TAGS.GEN]],
    ["Physician Assistant", [TAGS.GEN,TAGS.MED]],
    ["Registered Nurse",[TAGS.GEN]],
    ["staff",[TAGS.GEN]]
  ],
  );

  export default RolesTapMap;
  
import React, { useState } from "react";
import { getDMYformate } from "../../utils/dateTimeHelper";
import { truncate } from "../../utils/helpers/HelperFunctions";
import classes from "./OrderPrescription.module.css";


const OrderMedicationInformation = ({ title, value ,handleText}) => {
  const [showSigMore, setShowSigMore] = useState(false);
  
  const handleMoreSig = () => {
    setShowSigMore(!showSigMore);
  };
  return (
    <div className="row py-1">
      <div className="col-sm-3">
        <span className="font-12 text--secondary">{title}</span>
      </div>
      <div className="col-sm-9">
        <span className="font-14 text--terciary fw-sb">
          {handleText !== true ?  value :
          
          <span>
            {showSigMore ? (
              <>
                {value}
                <span
                  className="text--secondary fw-sb cursor--pointer"
                  onClick={() => {
                    handleMoreSig();
                  }}
                >
                  {" less"}
                </span>
              </>
            ) : (
              <span>
                {truncate(value, 150)}
                {value?.length > 150 ? (
                  <span
                    className="text--secondary fw-sb cursor--pointer"
                    onClick={() => {
                      handleMoreSig();
                    }}
                  >
                    {" more"}
                  </span>
                ) : (
                  ""
                )}
              </span>
            )}
          </span>
          }
        </span>
      </div>
    </div>
  );
};



const OrderMedicationItem = (props) => {
  const [showSigMore, setShowSigMore] = useState(false);
  let frequency = Math.ceil(props?.prescriptionItem?.highDoseForm * props?.prescriptionItem?.highFrequency);
  let quantity = parseFloat(Number(props?.prescriptionItem?.Quantity).toFixed(3));
  let quantityUOM = props?.prescriptionItem?.quantityUom;
  let dispense = "".concat(quantity, " ", quantityUOM || "");
  let daysSupply = props?.prescriptionItem?.daySupply;
  let sig = props?.prescriptionItem?.Sig?.SigText;
  if (isNaN(frequency)) {
    frequency = 0;
  }


  

  return (
    <div className="container-fluid g-0">
      <div className={`row ${classes["medication-title-row"]}`}>
        <div className="col-sm-3">
          <span className="font-12 text--secondary">Medication {props?.index}</span>
        </div>
        <div className="col-sm-9">
          <span className="font-14 text--terciary fw-sb">{props?.prescriptionItem?.drugDescription} </span>
        </div>
      </div>

      {sig !== undefined && sig?.length > 0 && <OrderMedicationInformation title="Sig" value={sig} handleText = {true} />}
      {frequency !== 0 ? <OrderMedicationInformation title="Frequency" value={frequency + " per day"} /> : ""}
      {<OrderMedicationInformation title="Days Supply" value={daysSupply !== undefined && daysSupply !== null && (""+daysSupply).length > 0 ? daysSupply + " days" : "Not available"} />}
      {<OrderMedicationInformation title="Quantity" value={dispense} />}
      {<OrderMedicationInformation title="Refills" value={props?.prescriptionItem?.NumberOfRefills} />}

      {<OrderMedicationInformation title="Substitutions" value={props?.prescriptionItem?.DispenseAsWritten ? "Not Allowed" : "Allowed"} />}
      {<OrderMedicationInformation title="Note to Pharmacist" value={props?.prescriptionItem?.Note} handleText = {true}/>}


      {props?.prescriptionItem?.EarliestFillDate?.Date !== "" ?
        <OrderMedicationInformation
          title="Earliest Fill Date"
          value={getDMYformate(props?.prescriptionItem?.EarliestFillDate?.Date, "MMM DD, YYYY")}
        />
        :
        ""}

    </div>
  );
};

export default OrderMedicationItem;

import { useState, useEffect } from "react";
import TextArea from "../forms/TextArea";
import ChiefComplaints from "../patient/ChiefComplaints";
import sendHttpRequest from "../hooks/sendHttpRequest";
import Divider from "@mui/material/Divider";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import classes from "./prescription.module.css";
import {
  changeDateTimeFormat,
  dateTimeHelper,
} from "../../utils/dateTimeHelper";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setPatientEncounterData,
  setUpdateEncounterData,
} from "../../redux/actions/providerActions";
import {
  patientChiefComplaintUrl,
  patientEncountersMetadataUrl,
} from "../../res/endpointurls";
import HistoryOfPresentIllness from "../patient/HistoryOfPresentIllness";
// import { Empty } from 'antd';

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTimelineDot-root": {
      filled: {
        "& .MuiTimelineDot-filled": {
          backgroundColor: "#323232",
        },
      },
    },
  },
}));

const ReasonOfVisit = (props) => {
  const updateEncounterData = useSelector(
    (state) => state.UpdateEncounter.Ecnounter
  );
  const patientEncounterInformation = useSelector(
    (state) => state.PatientEncounterData.appoinment
  );

  const dispatch = useDispatch();
  const uiclasses = useStyles();
  const [reasonOfVisit, setReasonOfVisit] = useState("");
  const [chiefComplaint, setChiefComplaint] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isChiefComplaintLoading, setIsChiefComplaintLoading] = useState(true);
  const [statusCodeROV, setStatusCodeROV] = useState(null);
  const { providerId, encounterId, patientId } = useParams();
  let noteString = "";

  const fetchNotes = async (patientId) => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: patientEncountersMetadataUrl(patientId, encounterId),
      method: "GET",
    });
    if (!httpResponse.error) {
      setReasonOfVisit(httpResponse.data);
      // dispatch(setPatientEncounterData({ ...patientEncounterInformation, encounter: { ...patientEncounterInformation.encounter, notes: httpResponse.data?.at(0)?.notes || "" } }))
    } else {
      console.log(httpResponse.error);
    }
    setIsLoading(false);
    setStatusCodeROV(httpResponse.status);
  };

  const fetchChiefComplaint = async (encounterId) => {
    setIsChiefComplaintLoading(true);
    const httpResponse = await sendHttpRequest({
      url: patientChiefComplaintUrl(encounterId),
      method: "GET",
    });
    if (!httpResponse.error) {
      setChiefComplaint(httpResponse.data?.chiefComplaint);
    } else {
      // console.log(httpResponse.error);
    }
    setIsChiefComplaintLoading(false);
  };

  useEffect(() => {
    fetchNotes(patientId);
    fetchChiefComplaint(encounterId);
  }, []);

  function getText(text) {
    props.setUnsavedChanges(true);
    noteString = text;
    dispatch(
      setUpdateEncounterData({
        ...updateEncounterData,
        encounterId: encounterId,
        notes: text,
      })
    );
    dispatch(
      setPatientEncounterData({
        ...patientEncounterInformation,
        encounter: { ...patientEncounterInformation.encounter, notes: text },
      })
    );
  }

  return (
    <>
      {/* {console.log("providerId,providerId",providerId,encounterId,patientId)} */}
      <div className="my-5">
        {isChiefComplaintLoading ? (
          <Skeleton animation="wave" />
        ) : (
          <ChiefComplaints
            className="mb-2"
            chiefcomplaints={chiefComplaint ?? ""}
            setUnsavedChanges={props.setUnsavedChanges}
          />
        )}
        <div className="my-4">
          <Divider />
        </div>
        {/* <h4 className="my-2">Note</h4>
        <TextArea
          className="form-control my-2 input br-10 shadow-none border--default "
          // placeholder="Write your note"
          id={"notes"}
          rows={5}
          gettext={getText}
          defaultValue={
            patientEncounterInformation?.encounter?.notes ||
            // reasonOfVisit[0]?.notes ||
            props?.encounterApiData?.encounter?.notes || ""
          }
        />

        <Timeline>
          {isLoading ? (
            <Timeline>

              <Skeleton animation="wave" />{" "}
            </Timeline>
          ) : statusCodeROV === 200 ? (
            reasonOfVisit !== undefined && reasonOfVisit?.length > 0 ? (
              reasonOfVisit.map((note, i) => {
                return note?.notes !== undefined &&
                  note?.notes?.trim() !== "" ? (
                  <TimelineItem key={note + i}>
                    <TimelineSeparator>
                      <TimelineDot
                        classes={uiclasses.root.filled}
                        className="bg--terciary"
                      />
                      <TimelineConnector className="bg--terciary" />
                    </TimelineSeparator>
                    <TimelineContent>
                      <h4 className="mb-0 font-12">
                        {note?.encounterCreatedDate !== undefined &&
                          note?.encounterCreatedDate !== null &&
                          note?.encounterCreatedDate !== ""
                          ? dateTimeHelper(
                            note?.encounterCreatedDate,
                            "MMM D, YYYY - LT"
                          )
                          : ""}
                      </h4>
                      <p className="mt-2 font-14 text--secondary">
                        {note?.notes}
                      </p>
                    </TimelineContent>
                  </TimelineItem>
                ) : null;
              })
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </Timeline> */}
        <HistoryOfPresentIllness
          setUnsavedChanges={props.setUnsavedChanges}
          encounterApiData={props.encounterApiData}
        />
      </div>
      {/* {console.log("updateEncounterData",updateEncounterData)} */}
      {/* {console.log("Appointment Encounter", patientEncounterInformation)} */}
    </>
  );
};

export default ReasonOfVisit;

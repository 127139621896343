import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import PageHeader from "../generic/PageHeader";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { EditIconComponent } from "../../assets/icons/EditIconComponent";
import { calenderDateTimeHelper } from "../../utils/dateTimeHelper";
import { useState } from "react";
import PatientInsurnacePage from "./PatientInsurancePage";

const PatientInsuranceTable = ({columnHeadersList,patientInsurances})=>{
    return (
        <>
            <TableContainer
              sx={{
                borderRadius: "10px",
                border: "1px solid #D9E8EC",
                maxHeight: 440,
              }}
              // className="hide-scrolbar"
              // className={`${classes["scroll__bar"]}`}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead
                  sx={{
                    borderTopColor: "#FAFCFC",
                    borderRightColor: "#FAFCFC",
                    borderLeftColor: "#FAFCFC",
                    borderBottom: "1px solid #D9E8EC",
                  }}
                  className="text--secondary "
                >
                  <TableRow>
                    {columnHeadersList?.map((item, idx) => {
                      return (
                        <TableCell key={item?.concat(idx)}>
                          <span className="text--secondary">{item}</span>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {patientInsurances?.length > 0 ? (
                    patientInsurances?.map((insuranceItem, idx) => {
                      return (
                        <TableRow
                          key={idx}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          // className="cursor--pointer"
                          // onClick={()=>{console.log(item?.invoiceNumber)}}
                        >
                          <TableCell sx={{ color: "#336383" }}>
                            <span className="font-10">{insuranceItem?.type}</span>
                          </TableCell>
                          <TableCell sx={{ color: "#336383" }}>
                            <span className="font-10">{insuranceItem?.insuranceName}</span>
                          </TableCell>
                          <TableCell sx={{ color: "#336383" }}>
                            <span className="font-10">{insuranceItem?.memberId}</span>
                          </TableCell>
                          <TableCell sx={{ color: "#336383" }}>
                            <span className="font-10">{insuranceItem?.groupNumber}</span>
                          </TableCell>
                          <TableCell sx={{ color: "#336383" }}>
                            <span className="font-10">{calenderDateTimeHelper(insuranceItem?.effectiveDate,"MMM DD, YYYY")}</span>
                          </TableCell>
                          <TableCell sx={{ color: "#336383" }}>
                            <span className="font-10">{calenderDateTimeHelper(insuranceItem?.terminationDate,"MMM DD, YYYY")}</span>
                          </TableCell>
                          <TableCell sx={{ color: "#336383" }}>
                            <span className="font-10">{insuranceItem?.inNetworkCopay ?? "$0.00"}</span>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <span className="font-12 text--terciary">
                        {"No data available"}
                      </span>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
        </>
    )
}


const PatientInsuranceEditPage = () => {
  const patientInsurances = useSelector(
    (state) => state.patientInsuranceData.patientInsurance
  );


  const navigate = useNavigate();
  const [isEditMode,setIsEditMode] = useState(false);
  const columnHeadersList = [
    "Insurance Type",
    "Payer",
    "Member Id",
    "Group Number",
    "Effective Date",
    "Termination Date",
    "Copay",
  ];
  return (
    <>
      <PageHeader title={"Insurance"} />
      <div className="container mt-3">
        <div className="row justify-content-center ">
          <div className="col-8 text-end" onClick={() => {navigate("/patient-portal/edit-insurance")}}>
            <EditIconComponent
              className={"cursor--pointer"}
              alt={"editIcon"}
              id="insurance-edit"
              style={{ color: `#336383`, width: "20px", height: "20px" }}
            ></EditIconComponent>
          </div>
                <div className="col-8">
                    <PatientInsuranceTable columnHeadersList={columnHeadersList} patientInsurances={patientInsurances}/>
                </div>
          {/* {
            isEditMode === false? 
                :
                <PatientInsurnacePage editMode={isEditMode}/>
          } */}
        </div>
      </div>
    </>
  );
};

export default PatientInsuranceEditPage;

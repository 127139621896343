import SearchPatient from "./Header/SearchPatient";
import { ReactComponent as TaskIcon } from "../../assets/icons/Icon-task.svg";
import CustomDropdown from "../forms/Dropdown";
import classes from "../UI/General/Stats.module.css";
import { border } from "@mui/system";
import { useSelector } from "react-redux";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { useEffect, useState } from "react";
import placeholder from "./../../assets/images/patientPlaceholder.png";
import PatientDropdown from "../forms/PatientDropdown";
import NotificationDropdown from "../forms/NotificationDropdown";
import LogoMain from "../../assets/icons/Logo.svg";
import { Box } from "@mui/material";
import { useAuth } from "../hooks/useAuth";
// import classes from './Stats.module.css';
const PatientNavbar = (props) => {
  const patientInfo = useSelector((state) => state.PrecriberData.prescriber);
  const timezone = useSelector((state) => state.UserTimeZoneStatus.timezone);
  const [isLoading, setIsLoading] = useState(false);
  const [timexone, setTimexone] = useState("");
  const { user } = useAuth();



  const getUserTimezone = async () => {
    const httpResponse = await sendHttpRequest({
      url: "/auth/user/timezone",
      method: "GET",
    });
    if (!httpResponse.error) {
      setTimexone(httpResponse?.data?.timezone);
    } else {
      console.log(httpResponse.error);
    }
  };

  useEffect(() => {
    // setUserInfo(JSON.parse(localStorage.getItem("user")));
    getUserTimezone();
  }, []);

  return (
    <div
      id="pn-top-navbar"
      className="container-fluid bg-white"
      style={{ borderBottom: "1px solid #d9e8ec", zIndex: "1020" }}
    >
      <nav className="navbar  navbar-expand-sm  d-flex justify-content-between">
        <Box
          component="img"
          alt="PrescribeNow Logo"
          src={LogoMain}
          className="navbar-brand"
        />
        <div>{/* <SearchPatient disable={true} /> */}</div>
        <div className="align-self-center mt-2 font-13">
          {"All times in: "} {timezone}
        </div>

        <div className="d-flex ">
          <div className="d-flex align-items-center ">
            <div>
              <NotificationDropdown
                patient={true}
                notificationList={props?.notificationList}
              />
            </div>
            <div className="me-1">
              <img
                src={
                  user.profilePicture !== undefined
                    ? user.profilePicture
                    : placeholder
                }
                width="40px"
                height="40px"
                alt="User"
                className="rounded-circle"
              />
            </div>
            <div className="ps-2  ">
              {
                <PatientDropdown
                  isLoading={isLoading}
                  userInfo={user}
                  session={props.session}
                  subscriber={props.subscriber}
                  publisher={props.publisher}
                />
              }
            </div>
          </div>
        </div>
      </nav>
      {/* {console.log("patientInfo.profilePicture",patientInfo.profilePicture)} */}
    </div>
  );
};

export default PatientNavbar;

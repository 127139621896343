import { useEffect, useState } from "react";
import { Avatar, Box, Divider, Stack, Switch, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import ChangePasswordModal from "../../components/UI/Modals/ChangePasswordModal";
import moment from "moment-timezone";
import "./myAccount.module.css";
import { styled } from "@mui/material/styles";

import TwoFAModal from "../../components/UI/Modals/ProviderModals/TwoFAModal";
import sendHttpRequest from "../../components/hooks/sendHttpRequest";
import CustomizedSnackbars from "../../components/UI/General/CustomizedSnackbars";
import { changeDateTimeFormat } from "../../utils/dateTimeHelper";
import DefaultAvatarIcon from "../../assets/icons/Icon-Default-Avatar.svg";
import { useAuth } from "../../components/hooks/useAuth";
import { setDirtyBitInStore } from "../../redux/actions/providerActions";
import { useNavigate } from "react-router";
import { ChevronLeft } from "@mui/icons-material";
import classes from "./myAccount.module.css";
import { navigateOnDirtyBit } from "../../utils/helpers/HelperFunctions";
// import 'antd/dist/antd.css';

const PatientAccountPageRegistrationFlow = (props) => {
    const dirtyBitStatus = useSelector((state) => state.dirtyStatus.isDirty);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingTZ, setIsLoadingTZ] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [profileInfo, setProfileInfo] = useState("");
    const [timeZoneList, setTimeZoneList] = useState([]);
    const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
    const [isPasswordChanged, setIsPasswordChanged] = useState(false);
    const [isAccountUpdate, setIsAccountUpdate] = useState(false);
    const prescriberInfo = useSelector((state) => state.PrecriberData.prescriber);
    const [twoFactorState, setTwoFactorState] = useState(false);
    const [openTwoFAModal, setOpenTwoFAModal] = useState(false);
    const [timexone, setTimexone] = useState("");
    const [offset, setOffset] = useState("");
    const [timexoneArr, setTimexoneArr] = useState([]);
    const { user, updateUser } = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const label = { inputProps: { "aria-label": "TwoFactorSwitch" } };
    const TwoFactorSwitch = styled(Switch)(({ theme }) => ({
        padding: 8,
        "& .MuiSwitch-switchBase": {
            "&.Mui-checked": {
                "& + .MuiSwitch-track": {
                    backgroundColor: "#004FD4",
                    opacity: 1,
                },
            },
        },
        "& .MuiSwitch-track": {
            borderRadius: 11 / 2,
            "&:before, &:after": {
                content: '""',
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                width: 16,
                height: 16,
            },
        },
        "& .MuiSwitch-thumb": {
            borderRadius: 11 / 2,
            boxShadow: "none",
            width: 16,
            height: 16,
            margin: 2,
            color: "#fff",
        },
    }));

    const changePassword = () => {
        setOpenChangePasswordModal(true);
    };

    const handleChangePasswordModal = () => {
        setOpenChangePasswordModal(!openChangePasswordModal);
    };

    const handleTwoFAModal = () => {
        setTwoFactorState(!twoFactorState);
    };

    const handlePasswordChangeToastClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setIsPasswordChanged(false);
    };

    const handleAccountUpdateToastClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setIsAccountUpdate(false);
    };

    const fetchUserProfile = async () => {
        setIsLoading(true);
        dispatch(setDirtyBitInStore(false));
        const httpResponse = await sendHttpRequest({
            url: "/auth/currentuser",
            method: "GET",
        });
        if (!httpResponse.error) {
            // console.log(httpResponse.data);
            getHumanReadableTimeZone();
            setProfileInfo(httpResponse.data);
            setTwoFactorState(httpResponse.data.twoFactorAuth);
            setTimexone(httpResponse.data.timezone);
            setOffset(httpResponse.data.timezoneOffset);
            saveTimezoneLocal(httpResponse.data.timezoneOffset);

            if (user?.timezone !== httpResponse.data.timezoneOffset) {
                let newUser = user;
                newUser.timezone = httpResponse.data.timezoneOffset;
                newUser.timezoneText = httpResponse.data.timezone;

                updateUser(newUser);
            }

        } else {
            console.log(httpResponse.error);
        }
        setIsLoading(false);
    };

    const fetchTimeZone = async () => {
        setIsLoadingTZ(true);
        const httpResponse = await sendHttpRequest({
            url: "/timezone",
            method: "GET",
        });
        if (!httpResponse.error) {
            setTimeZoneList(httpResponse.data);
        } else {
            console.log(httpResponse.error);
        }
        setIsLoadingTZ(false);
    };

    const saveTimezoneLocal = (timezoneOffset) => {
        localStorage.setItem("timezone", JSON.stringify(timezoneOffset));
    };

    const getHumanReadableTimeZone = () => {
        let tzArray = moment.tz.names().map((zone) => {
            return moment.tz(zone).format("zz Z");
        });

        setTimexoneArr([...new Set(tzArray)]);
    };

    const submitAccountChanges = async () => {
        setDisableButton(true);
        dispatch(setDirtyBitInStore(false));
        const httpResponse = await sendHttpRequest({
            url: "/auth/user/update",
            method: "PUT",
            data: {
                userId: profileInfo.userId,
                twoFactorAuth: twoFactorState,
                timezone: timexone,
                timezoneOffset: offset,
            },
        });
        if (!httpResponse.error) {
            setIsAccountUpdate(true);
            fetchTimeZone();
            fetchUserProfile();
        } else {
            console.log(httpResponse.error);
        }
        setDisableButton(false);
    };

    const timezoneHandler = (event) => {
        setOffset(
            event.target[event.target.selectedIndex].getAttribute("data-offset")
        );
        setTimexone(event.target.value);
    };

    function twoFactorSwitch(event) {
        if (event.target.checked) {
            if (!profileInfo?.TwoFactorAuth) {
                handleTwoFAModal();
            } else {
                setTwoFactorState(event.target.checked);
            }
        } else {
            setTwoFactorState(event.target.checked);
        }
        // setProfileInfo({...profileInfo, TwoFactorAuth:checked});
    }

    useEffect(() => {
        fetchTimeZone();
        fetchUserProfile();
    }, []);


    return (
        <>
            {/* <PatientNavbar /> */}
            <ChangePasswordModal
                show={openChangePasswordModal}
                handleModal={handleChangePasswordModal}
                setIsPasswordChanged={setIsPasswordChanged}
            />
            {twoFactorState ? <TwoFAModal
                show={twoFactorState}
                handleModal={handleTwoFAModal}
                headerTitle={"Two-Step Verification"}
                setTwoFactorState={setTwoFactorState}
                fetchUserProfile={fetchUserProfile}
                mobilePhone={
                    profileInfo?.communicationNumbers?.numbers.length > 0
                        ? profileInfo?.communicationNumbers?.numbers[0]
                        : null
                }
            /> : null}
            {/* <PageHeader title="My Account"/> */}

            <div className={`container-fluid bg--d9e8ec py-4`}>
                <div className="row justify-content-center">
                    <div className={`col-12 col-sm-6`}>
                        <Stack
                            direction="row"
                            className="py-2"
                            alignItems="center"
                            spacing={2}
                        >
                            <Avatar
                                alt={profileInfo?.Name?.Prefix?.LastName}
                                src={
                                    profileInfo?.profilePicture
                                        ? profileInfo?.profilePicture
                                        : DefaultAvatarIcon
                                }
                            />
                            <Typography component="div">
                                <Box
                                    sx={{
                                        textAlign: "center",
                                        m: 1,
                                        fontFamily: "Montserrat",
                                        fontWeight: "600",
                                        fontSize: "16px",
                                        color: "#323232",
                                    }}
                                >
                                    {/* {prescriberInfo?.Name?.Prefix} {" "} {prescriberInfo?.Name?.FirstName}{" "}{prescriberInfo?.Name?.LastName} */}
                                    {profileInfo?.name?.length > 0
                                        ? profileInfo?.name[0]?.prefix
                                        : ""}
                                    {""}
                                    {profileInfo?.name?.length > 0
                                        ? profileInfo?.name[0]?.firstName
                                        : ""}{" "}
                                    {profileInfo?.name?.length > 0
                                        ? profileInfo?.name[0]?.lastName
                                        : ""}
                                </Box>
                                <Box
                                    sx={{
                                        // textAlign: "center",
                                        ml: 1,
                                        fontFamily: "Montserrat",
                                        fontSize: "14px",
                                        color: "#323232",
                                    }}
                                >
                                    {"PID: " + profileInfo?.patientTenantIdentification}
                                </Box>
                            </Typography>
                        </Stack>
                        <Divider />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className={`${props?.patientRegistrationHeader ? 'col-6' : 'col-9'}`}>
                        <Stack direction="row" className="pt-1" spacing={2}>
                            <Typography component="div">
                                <Box
                                    sx={{
                                        textAlign: "center",
                                        fontFamily: "Montserrat",
                                        fontWeight: "600",
                                        fontSize: "16px",
                                        color: "#323232",
                                    }}
                                >
                                    Personal Information
                                </Box>
                            </Typography>
                        </Stack>
                        <div className="row">
                            <div className="col-sm-6">
                                <label
                                    htmlFor="FirstName"
                                    className="form-label m-0 font-12 text--secondary fw-sb"
                                >
                                    First Name
                                </label>
                                <input
                                    type="text"
                                    disabled={true}
                                    // value={prescriberInfo?.Name?.FirstName}
                                    value={
                                        profileInfo?.name?.length > 0
                                            ? profileInfo?.name[0]?.firstName
                                            : ""
                                    }
                                    className={`form-control font-14 m-0 border--default br-10 shadow-none input`}
                                    style={{ backgroundColor: "#F2F7F8" }}
                                // onBlur={firstNameBlur}
                                />
                            </div>
                            <div className="col-sm-6">
                                <label
                                    htmlFor="LastName"
                                    className="form-label m-0 font-12 text--secondary fw-sb"
                                >
                                    Last Name
                                </label>
                                <input
                                    type="text"
                                    disabled={true}
                                    // value={prescriberInfo?.Name?.LastName}
                                    value={
                                        profileInfo?.name?.length > 0
                                            ? profileInfo?.name[0]?.lastName
                                            : ""
                                    }
                                    className={`form-control font-14 m-0 border--default br-10 shadow-none input`}
                                    style={{ backgroundColor: "#F2F7F8" }}
                                // onBlur={firstNameBlur}
                                />
                            </div>
                        </div>

                        <div className="row py-1" >
                            <div className="col-sm-6">
                                <label
                                    htmlFor="patietnID"
                                    className="form-label m-0 font-12 text--secondary fw-sb"
                                >
                                    Patient ID
                                </label>
                                <input
                                    type="text"
                                    disabled={true}
                                    value={profileInfo?.patientTenantIdentification}
                                    className={`form-control font-14 m-0 border--default br-10 shadow-none input`}
                                    style={{ backgroundColor: "#F2F7F8" }}
                                />
                            </div>
                            <div className="col-sm-6">
                                <label
                                    htmlFor="dateofbirth"
                                    className="form-label m-0 font-12 text--secondary fw-sb"
                                >
                                    Date of Birth
                                </label>
                                <input
                                    type="text"
                                    disabled={true}
                                    value={
                                        profileInfo?.dateOfBirth
                                            ? changeDateTimeFormat(
                                                profileInfo?.dateOfBirth,
                                                "MMM D, YYYY"
                                            )
                                            : ""
                                    }
                                    className={`form-control font-14 m-0 border--default br-10 shadow-none input`}
                                    style={{ backgroundColor: "#F2F7F8" }}
                                />
                            </div>
                        </div>
                        <div className="row py-1">
                            <div className="col-sm-12">
                                <label
                                    htmlFor="address"
                                    className="form-label m-0 font-12 text--secondary fw-sb"
                                >
                                    Address
                                </label>
                                <input
                                    type="text"
                                    disabled={true}
                                    value={
                                        profileInfo?.address?.length > 0
                                            ? (profileInfo?.address[0]?.addressLine1 !== undefined && profileInfo?.address[0]?.addressLine1 !== null
                                                ? profileInfo?.address[0]?.addressLine1?.trim() + " "
                                                : "") +
                                            (profileInfo?.address[0]?.city !== undefined && profileInfo?.address[0]?.city !== null
                                                ? profileInfo?.address[0]?.city?.trim() + ", "
                                                : "") +
                                            (profileInfo?.address[0]?.stateProvince !== undefined && profileInfo?.address[0]?.stateProvince !== null
                                                ? profileInfo?.address[0]?.stateProvince?.trim() + " "
                                                : "") +
                                            (profileInfo?.address[0]?.postalCode !== undefined && profileInfo?.address[0]?.postalCode !== null
                                                ? profileInfo?.address[0]?.postalCode?.trim()
                                                : "")
                                            : ""
                                    }
                                    className={`form-control font-14 m-0 border--default br-10 shadow-none input`}
                                    style={{ backgroundColor: "#F2F7F8" }}
                                />
                            </div>
                        </div>
                        <div className="row py-1" >
                            <div className="col-sm-6">
                                <label
                                    htmlFor="Email"
                                    className="form-label m-0 font-12 text--secondary fw-sb"
                                >
                                    Email
                                </label>
                                <input
                                    type="email"
                                    disabled={true}
                                    value={
                                        profileInfo?.communicationNumbers?.electronicMail.length > 0
                                            ? profileInfo?.communicationNumbers.electronicMail[0]
                                            : ""
                                    }
                                    className={`form-control font-14 m-0 border--default br-10 input`}
                                    style={{ backgroundColor: "#F2F7F8" }}
                                />
                            </div>
                            <div className="col-sm-6">
                                <label
                                    htmlFor="phone"
                                    className="form-label m-0 font-12 text--secondary fw-sb"
                                >
                                    Primary Phone
                                </label>
                                <input
                                    type="phone"
                                    disabled={true}
                                    value={
                                        profileInfo?.communicationNumbers?.numbers.length > 0
                                            ? profileInfo?.communicationNumbers?.numbers[0].number
                                            : ""
                                    }
                                    className={`form-control font-14 m-0 border--default br-10 shadow-none input`}
                                    style={{ backgroundColor: "#F2F7F8" }}
                                />
                            </div>
                        </div>

                        <div className="pt-2">
                            <Divider />
                        </div>

                        <div className="row py-1" >
                            <div className="col-sm-4">
                                <button
                                    className="btn text--blue fw-sb font-14 shadow-none"
                                    onClick={handleChangePasswordModal}
                                >
                                    Change Password
                                </button>
                            </div>
                        </div>

                        <Divider />

                        <div className="row py-1" >
                            <Typography component="div">
                                <Box
                                    sx={{
                                        // textAlign: "left",
                                        fontFamily: "Montserrat",
                                        fontWeight: "600",
                                        fontSize: "16px",
                                        color: "#323232",
                                    }}
                                >
                                    Two-Step Verification
                                </Box>
                            </Typography>
                        </div>
                        <Stack
                            direction="row"
                            className="py-1"
                            justifyContent={"between"}
                            spacing={2}
                        >
                            <div
                                className="col-sm-4"
                                style={{
                                    backgroundColor: "#ffffff",
                                    border: "1px solid #D9E8EC",
                                    borderRadius: "10px",
                                }}
                            >
                                <div className="row">
                                    <div className="col-sm-8 justify-content-start align-self-center">
                                        <Typography component="span">
                                            <Box
                                                sx={{
                                                    marginLeft: 1,
                                                    fontFamily: "Montserrat",
                                                    fontWeight: "600",
                                                    fontSize: "12px",
                                                    color: "#323232",
                                                }}
                                            >
                                                {twoFactorState === true ? "Enabled" : "Disabled"}
                                            </Box>
                                        </Typography>
                                    </div>
                                    <div className="col-4 justify-content-end align-self-center">
                                        <TwoFactorSwitch
                                            {...label}
                                            checked={twoFactorState}
                                            onChange={twoFactorSwitch}
                                        />
                                        {/* <Switch style={{ borderRadius: '5px' }} checked={twoFactorState} onChange={twoFactorSwitch} /> */}
                                    </div>
                                </div>
                            </div>
                        </Stack>

                        <div className="pt-2">
                            <Divider />
                        </div>

                        <Stack
                            direction="row"
                            className="py-1"
                            justifyContent={"between"}
                            alignItems="center"
                            spacing={2}
                        >
                            <Typography component="div">
                                <Box
                                    sx={{
                                        textAlign: "center",
                                        m: 1,
                                        fontFamily: "Montserrat",
                                        fontWeight: "600",
                                        fontSize: "16px",
                                        color: "#323232",
                                    }}
                                >
                                    Timezone
                                </Box>
                            </Typography>
                        </Stack>

                        {/* {console.log(moment(new Date()).utcOffset(0, false).format())} */}
                        {/* {console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)} */}

                        <Stack
                            direction="row"
                            className="py-1"
                            alignItems="center"
                            spacing={2}
                        >
                            <div className="col-sm-4">
                                <select
                                    onChange={timezoneHandler}
                                    className="form-select font-14 fw-sb border--default br-10 shadow-none input"
                                    aria-label="timezone"
                                    id="timezone"
                                    style={{ borderRadius: "10px" }}
                                    placeholder="Select Timezone"
                                >
                                    <option className="fw-sb" disabled selected hidden value="">
                                        Select Timezone
                                    </option>
                                    {isLoading ? (
                                        <option className="fw-sb">Loading...</option>
                                    ) : timeZoneList?.length > 0 ? (
                                        timeZoneList?.map((item, index) => {
                                            return (
                                                <option
                                                    key={item + index}
                                                    value={item?.name}
                                                    data-offset={item?.offset}
                                                    selected={item?.name === profileInfo?.timezone}
                                                    className="fw-sb"
                                                // selected={ === item.Id}
                                                >
                                                    {item?.name} &nbsp; {item?.offset}
                                                    {/* {moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z')} */}
                                                    {/* {Date().slice(Date().indexOf("(") + 1, Date().lastIndexOf(")"))} */}
                                                </option>
                                            );
                                        })
                                    ) : (
                                        ""
                                    )}
                                </select>
                            </div>
                        </Stack>

                        <div className="pt-2">
                            <Divider />
                        </div>

                        <Stack
                            direction="row"

                            className=" py-1 mb-5"
                            spacing={2}
                            justifyContent={"flex-end"}
                        >
                            <Box>
                                <div>
                                    <button
                                        className="btn blue_task_button"
                                        disabled={disableButton}
                                        onClick={submitAccountChanges}
                                    >
                                        Update
                                    </button>
                                </div>
                            </Box>
                        </Stack>
                    </div>
                </div>
            </div>
            <CustomizedSnackbars
                message={"Changes saved successfully"}
                severity="success"
                open={isPasswordChanged}
                handleClose={handlePasswordChangeToastClose}
            />
            <CustomizedSnackbars
                message={"Changes saved successfully"}
                severity="success"
                open={isAccountUpdate}
                handleClose={handleAccountUpdateToastClose}
            />
        </>
    );
};

export default PatientAccountPageRegistrationFlow;


export default [
    {
        "code": "AA",
        "description": "Patient unknown to the Provider"
    },
    {
        "code": "AB",
        "description": "Patient never under Provider care"
    },
    {
        "code": "AC",
        "description": "Patient no longer under Provider care"
    },
    {
        "code": "AE",
        "description": "Medication never prescribed for the patient"
    },
    {
        "code": "AF",
        "description": "Patient should contact Provider first"
    },
    {
        "code": "AM",
        "description": "Patient needs appointment"
    },
    {
        "code": "AN",
        "description": "Prescriber not associated with this practice or location"
    },
    {
        "code": "AP",
        "description": "Request already responded to by other means (e.g. phone or fax)"
    },
    {
        "code": "BE",
        "description": "Medication denied at patient request"
    },
    {
        "code": "AO",
        "description": "No attempt will be made to obtain Prior Authorization"
    },
    {
        "code": "AL",
        "description": "Change not appropriate"
    },
    {
        "code": "other",
        "description": "Other"
    }
];
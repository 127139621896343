const PayPalForm = ()=>{
    return (
        <>
            <div className="row">
                <div className="col-6">
                    <label
                        htmlFor="email"
                        className="form-label font-12 text--secondary m-0 fw-sb"
                    >
                        {"PayPal Email"}
                    </label>
                    <input
                        type="text"
                        autoComplete={false}
                        placeholder="Enter you email"
                        className="form-control font-12 border--default br-10 shadow-none input"
                        id="email"
                        // onChange={(e) => setChequeNumber(e.target.value)}
                        style={{ textAlign: "center" }}
                    />
                </div>
                <div className="col-6">
                    <div>&nbsp;</div>
                    <button
                        // onClick={() => handleReceipt(userId)}
                        // disabled={!isPaymentSent === true ? true : isPrinting}
                        className="btn blue_task_button  "
                        >
                            {"Go"}
                    </button>
                </div>
            </div>
        </>
    )
}

export default PayPalForm;
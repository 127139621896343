import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setConsulationInProgress, setJoinPage } from '../../../redux/actions/providerActions';
import PatientInformation from '../../patient/PatientInformation';
import { MicMute } from "react-bootstrap-icons";
import { CameraVideo } from "react-bootstrap-icons";
import { CameraVideoOff } from "react-bootstrap-icons";
import { Mic } from "react-bootstrap-icons";
import { AgoraRTC } from './agoraDataObjects';
import { client } from "./agoraDataObjects";
import { localVideoTrack } from './agoraDataObjects';
import { localAudioTrack } from './agoraDataObjects';
import { apiKey } from "./agoraDataObjects";
import { configuration } from "./agoraDataObjects";
import { pVideo } from './agoraDataObjects';
import { pAudio } from "./agoraDataObjects";
import { useStateWithCallback } from '../../hooks/useStateWithCallback';
import { useToggle } from '../../hooks/useToggle';
import { useNavigate, useParams } from 'react-router-dom';
import { dateTimeHelper } from '../../../utils/dateTimeHelper';

const AgoraLocalVideoConfigurationPage = () => {
    const { providerId, encounterId, patientId } = useParams();
    const navigate = useNavigate();
    const prescriberVideo = useRef(true);
    const prescriberAudio = useRef(true);
    const [videoToggle, setVideoToggle] = useToggle(prescriberVideo.current);
    const [audioToggle, setAudioToggle] = useToggle(prescriberVideo.current);

    const dispatch = useDispatch();
    function handleBack(e) {
        dispatch(setJoinPage(false));
    }

    const appointmentInformation = useSelector(
        (state) => state.PatientEncounterData.appoinment
    );

    const pVideoHandler = (localVideoTrackValue, client, divReference) => {
        if (prescriberVideo.current === true) {
            pVideo(localVideoTrackValue, false, client, divReference);
            // setPrescriberVideo((prev)=>{return false});
            prescriberVideo.current = false;
            setVideoToggle(false);
        }
        else {
            pVideo(localVideoTrackValue, true, client, divReference);
            // setPrescriberVideo((prev)=>{return true})
            prescriberVideo.current = true;

            document.querySelector(`[id^="agora-video-player-track"]`).classList.add('video-track-pn');
            setVideoToggle(true);
        }

    }

    const pAudioHandler = (localAudioTrackValue, client) => {
        if (prescriberAudio.current === true) {
            pAudio(localAudioTrackValue, false, client);
            setAudioToggle(false);
        }
        else {
            pAudio(localAudioTrackValue, true, client);
            setVideoToggle(true);
        }
    }

    window.addEventListener('popstate', handleBack);
    useEffect(() => {
        AgoraRTC.createMicrophoneAndCameraTracks().then((audio, video) => {
            if (localAudioTrack.value == null) {
                localAudioTrack.value = audio[0];
                Object.freeze(localAudioTrack);
            }
            if (localVideoTrack.value == null) {
                localVideoTrack.value = audio[1];
                Object.freeze(localVideoTrack);
                audio[1].play(document.getElementById("join-publisher"));
                document.querySelector(`[id^="agora-video-player-track"]`).classList.add('video-track-pn');
            }

        })
        return () => {
            window.removeEventListener('popstate', handleBack);

        }
    }, [])


    return (
        <>

            <div style={{ backgroundColor: '#FAFCFC' }} className='bg--background br-10'>
                <div className='row br-10 justify-content-center'>
                    <div className='col-sm-10 '>
                        <div className="row justify-content-center pt-3">
                            <div className="col-sm-12">
                                <span className="font-14 text--secondary d-block text-center ">Please adjust your audio and video configuration for your appointment with  </span>

                            </div>

                            <div className="col-sm-6 text-center">
                                <span className="fw-sb d-block font-14 text--secondary" style={{ marginBottom: '-4px' }}>{appointmentInformation?.Encounter?.Patient?.Name[0].FirstName + " " + appointmentInformation?.Encounter?.Patient?.Name[0].LastName}</span>
                                <span className="fw-sb d-block m-0 font-14 text--secondary">{dateTimeHelper(appointmentInformation?.AvailableSlot?.StartDate, "MMM D, YYYY")} <span className="fw-thin">at</span> {dateTimeHelper(appointmentInformation?.AvailableSlot?.StartDateTime + ":00", "LT")}</span>
                            </div>
                        </div>
                        <div id="join-publisher" className='jp-subscriber mt-3'>
                            <div className='button-grid mt-auto justify-content-center d-flex gap-3'>
                                <div>
                                    <div role="button" onClick={() => { pAudioHandler(localAudioTrack.value, client) }} className='btn-container d-flex'>
                                        <div className={`btn-icon ${audioToggle === true ? 'btn-icon--positive' : 'btn-icon--negative'}`}>
                                            {audioToggle === true ? <Mic color='#fff' size="18" /> : <MicMute color='#fff' size="18" />}
                                        </div>
                                        <div className='btn-label-text ps-1 pe-1'>
                                            <span className='font-14 fw-sb text--terciary'>Audio: {audioToggle === true ? <span className='font-14 fw-thin'>On</span> : <span className='font-14 fw-thin'>Off</span>}</span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div role="button" onClick={() => { pVideoHandler(localVideoTrack.value, client, document.getElementById("join-publisher")) }} className='btn-container d-flex'>
                                        <div className={`btn-icon ${videoToggle === true ? 'btn-icon--positive' : 'btn-icon--negative'}`}>
                                            {videoToggle === true ? <CameraVideo size="18" color="#fff" /> : <CameraVideoOff size="18" color="#fff" />}
                                        </div>
                                        <div className='btn-label-text ps-1 pe-1'>
                                            <span className='font-14 fw-sb text--terciary'>Video: {videoToggle === true ? <span className='font-14 fw-thin text--terciary'>On</span> : <span className='font-14 fw-thin text--terciary'>Off</span>}</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mt-3 mb-4 justify-content-center'>
                    <div className='col-sm-12 text-center'>
                        <button className="join-now-button" onClick={() => {
                            dispatch(setConsulationInProgress(true))
                            navigate(`/consult/${providerId}/${encounterId}/${patientId}`, { replace: true })
                        }}>Join Now</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AgoraLocalVideoConfigurationPage
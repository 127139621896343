import { Modal } from "react-bootstrap";
import TextArea from "../../../forms/TextArea";
import CloseIcon from "@mui/icons-material/Close";
import { hospitalizationStrings } from "../../../../res/strings";

const HospitalizationEditModal = (props) => {

  const _str = hospitalizationStrings.en;

  const setHospitalizationReason = (event) => {
    props.setReason(event);
  }

  const setHospitalizationLengthOfStay = (event) => {
    props.setLengthOfStay(event);
  }

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.handleModal();
      }}
      size="md"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      backdropClassName="modal-backdrop"
      contentClassName="modal-border modal-shadow"
    >
      <Modal.Body>
        <div className="container-fluid px-3 ">
          <div className="row ">
            <div className="col-12 ">
              <span
                style={{
                  lineHeight: "12px",
                  width: "18px",
                  fontSize: "8pt",
                  color: "#336383",
                  position: "absolute",
                  top: 15,
                  right: 15,
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.handleModal();
                }}
              >
                {<CloseIcon />}
              </span>
            </div>
          </div>
          <form id="hospitalizationEditForm" onSubmit={(e) => {
            e.preventDefault();
            props?.editHospitalizationRequestHandler();
            props?.handleModal();
          }}>
          <div className="row ">
            <div className="col-12">
              <div className="d-flex ">
                {/* <div>
                  <img src={AddButtonIcon} alt=""></img>
                </div> */}
                <div className="mt-2">
                  <p className="h18 fw-sb " style={{ fontWeight: "600" }}>
                    Edit Hospitalization History
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-sm-12 ">
              <label className="b2 text--secondary">{_str.reason}</label>
              <TextArea
                className="form-control br-10 font-12 shadow-none "
                style={{ border: "1px solid #D9E8EC" }}
                defaultValue={props.editHospitalization.reason}
                gettext={setHospitalizationReason}
                required
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-sm-12 ">
              <label className="b2 text--secondary">{_str.lengthOfStay}</label>
              <TextArea
                className="form-control br-10 font-12 shadow-none "
                style={{ border: "1px solid #D9E8EC" }}
                defaultValue={props.editHospitalization.lengthOfStay}
                gettext={setHospitalizationLengthOfStay}
                required
              />
            </div>
          </div>
          </form>
        </div>
      </Modal.Body>

      <Modal.Footer style={{ border: "none" }}>
        <button
        type="submit"
        form="hospitalizationEditForm"
        className="btn blue_task_button b2 px-5 br-10"
        >
          Update
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default HospitalizationEditModal;

import { Navigate, Outlet } from "react-router-dom";
import { jsonToMap } from "../../utils/helpers/HelperFunctions";
import { useAuth } from "../hooks/useAuth";

export const OutsideAppLayout = () => {
    const { user, signout } = useAuth();
    const map = jsonToMap(user);

    if (user) {
        if (map.get("loggedIn")) {
            if (map.get("role") !== "patient") {
                return <Navigate to="/dashboard" />;
            } else {
                return <Navigate to="/patient-portal/dashboard" />;
            }
        } 
    }

    return (
        <Outlet />
    )
};
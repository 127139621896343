const TaskIcon = (props) => {
    return (
        <svg
            id="Icon-task"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20.003"
            viewBox="0 0 20 18.314"
            {...props}
            fill={props.style.color} >
            <path id="Icon-task-2" data-name="Icon-task" d="M3.333,5a2.485,2.485,0,0,1-1.768-.732L.278,3.126a.834.834,0,0,1,1.11-1.245L2.709,3.06a.833.833,0,0,0,1.213.033l3-2.859A.833.833,0,0,1,8.074,1.44L5.083,4.285A2.47,2.47,0,0,1,3.333,5ZM20,3.337a.833.833,0,0,0-.833-.833H10.833a.833.833,0,0,0,0,1.667h8.333A.833.833,0,0,0,20,3.337ZM5.083,10.952,8.074,8.107A.833.833,0,1,0,6.925,6.9l-3,2.859a.852.852,0,0,1-1.178,0L1.422,8.439A.833.833,0,1,0,.244,9.617l1.321,1.321a2.5,2.5,0,0,0,3.522.014ZM20,10a.833.833,0,0,0-.833-.833H10.833a.833.833,0,1,0,0,1.667h8.333A.833.833,0,0,0,20,10ZM5.083,17.618,8.07,14.773a.833.833,0,1,0-1.148-1.207l-3,2.859a.833.833,0,0,1-1.213-.033L1.388,15.214a.834.834,0,1,0-1.11,1.245L1.565,17.6a2.5,2.5,0,0,0,3.522.014ZM20,16.67a.833.833,0,0,0-.833-.833H10.833a.833.833,0,1,0,0,1.667h8.333A.833.833,0,0,0,20,16.67Z" transform="translate(0.001 -0.023)" />
        </svg>
    );
};

export default TaskIcon;




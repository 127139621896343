import { useState, useEffect } from "react";
import classes from "./PreferredPharmacyModal.module.css";
import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import {
  formatPhoneNumber,
  formatPostalCode,
  pharmacySpecialty,
} from "../../../../utils/helpers/HelperFunctions";
import { preferred_pharmacy_modal_strings } from "../../../../res/strings";

const PreferredPharmacyModal = (props) => {
  const _str = preferred_pharmacy_modal_strings.en;
  const handlePreferredPharmacyAction = () => {
    props?.persistPreferredPharmacy(props?.pharmacyItem?.organizationId);
    props?.setPreferredPharmacy(props?.pharmacyItem);
    // props?.setPharmacyId(props?.pharmacyItem?.organizationId)
    let results = props?.currentItems.filter((pharmacy) => {
      return pharmacy.organizationId !== props?.pharmacyItem?.organizationId;
    });
    props?.setCurrentItems(results);
    props?.setPreferredPharmacyFlag(true);
    let allCheckboxRefSPH = document.getElementsByClassName("selectedPharmacy");
    for (let i = 0; i < allCheckboxRefSPH.length; i++) {
      allCheckboxRefSPH[i].checked = false;
    }
    let allCheckboxRefPPH =
      document.getElementsByClassName("preferredPharmacy");
    for (let i = 0; i < allCheckboxRefPPH.length; i++) {
      allCheckboxRefPPH[i].checked = false;
    }
    props?.handleModal();
  };

  const handleCancel = () => {
    let allCheckboxRefPPH =
      document.getElementsByClassName("preferredPharmacy");
    for (let i = 0; i < allCheckboxRefPPH.length; i++) {
      allCheckboxRefPPH[i].checked = false;
    }
    props?.handleModal();
  };
  return (
    <Modal
      show={props?.show}
      onHide={() => {
        // props.handleModal();
      }}
      size="md"
      backdropClassName="modal-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      contentClassName="modal-border modal-shadow"

      // style={{style}}
    >
      <Modal.Header>
        <span
          // className={`${classes["close__btn"]}`}

          style={{
            lineHeight: "12px",
            width: "18px",
            fontSize: "8pt",
            color: "#336383",
            position: "absolute",
            top: 15,
            right: 15,
            cursor: "pointer",
          }}
          onClick={() => {
            // props?.setPharmacyItem("")
            props?.handleModal();
          }}
        >
          {<CloseIcon />}
        </span>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="font-20 fw-sb text--terciary"
        >
          <span className="font-16 text--terciary fw-sb">
            {_str.modal_title}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container py-1">
          <div className="row  ">
            <div className="col-12">
              <p className="  font-12 text--terciary fw-sb">
                {_str.modal_body_msg}
              </p>
              <div className="col-12 font-12 text--terciary ms-5">
                <span className="font-12 text--terciary ">
                  {props.pharmacyItem?.standardizedOrganizationName}
                  <br />{" "}
                  {"".concat(
                    props.pharmacyItem?.standardizedAddressLine1,
                    ", ",
                    props.pharmacyItem?.standardizedAddressLine2?.length > 0
                      ? props.pharmacyItem?.standardizedAddressLine2 + ", "
                      : "",
                    props.pharmacyItem?.standardizedCity || "",
                    ", ",
                    props.pharmacyItem?.stateProvince || "",
                    " ",
                    formatPostalCode(props.pharmacyItem?.postalCode) || ""
                  )}
                  <br />{" "}
                </span>
                <div className="">
                  <span className="me-4">
                    {"".concat(
                      "Ph: ",
                      formatPhoneNumber(props.pharmacyItem?.primaryTelephone)
                    )}
                  </span>
                  <span>
                    {"".concat(
                      "Fax: ",
                      formatPhoneNumber(props?.pharmacyItem?.fax)
                    )}
                  </span>
                </div>
                <div>
                  <span>
                    {"Specialty: "}
                    {pharmacySpecialty(props?.pharmacyItem?.speciality)}
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-3 ">
                <button
                  className={`btn fw-sb d9e8ec--button text-blue font-12 px-4 br-10 py-1 ${classes["action__button"]}`}
                  onClick={() => {
                    // props?.setPharmacyItem("");
                    // props?.handleModal();
                    handleCancel();
                  }}
                >
                  {_str.cancel_button_str}
                </button>
                <button
                  className={`btn fw-sb d9e8ec--button text-blue font-12 px-4 br-10 py-1 ${classes["action__button"]}`}
                  onClick={() => {
                    handlePreferredPharmacyAction();
                  }}
                >
                  {_str.preferred_button_str}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PreferredPharmacyModal;

import AppointmentsList from "../appointments/AppointmentsList";
import Stats from "../UI/General/Stats";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import sendHttpRequest, { sendHttpRequestWithCancellation } from "../hooks/sendHttpRequest";
import Greeting from "../UI/General/Greeting";
import Messages from "../messages/Messages";
import PrescriptionRequestList from "../prescription/PrescriptionRequestList";
import Loading from "../UI/General/Loading";
import { setAppointments, setMessages, setUnreadMessageCount } from "../../redux/actions/providerActions";
import MessagesIcon from "../UI/Drawer/DrawerItemIcons/MessagesIcon";
import CalendarIcon from "../UI/Drawer/DrawerItemIcons/CalendarIcon";
import { Backdrop, CircularProgress, Skeleton } from "@mui/material";
import { dateTimeHelper } from "../../utils/dateTimeHelper";
import classes from "./ProviderDashboardPage.module.css";
import moment from "moment-timezone";
import { RolesMap, RolesTextConversion } from "../../utils/helpers/RolesMap";
import PendingEncountersModal from "../UI/Modals/ProviderModals/PendingEncountersModal";
import PendingTasksModal from "../UI/Modals/ProviderModals/PendingTasksModal";
import TaskIcon from "../UI/Drawer/DrawerItemIcons/TaskIcon";
import NewMsgOnDashboardModal from "../UI/Modals/ProviderModals/NewMsgOnDashboardModal";
import NotificationALert from "../notifications/NotificationAlert";
import { dateTimeFormats } from "../../utils/helpers/dateTimeConstants";
import { useInterval } from "../hooks/useInterval";
import drawerButton from "../../assets/icons/drawerButton.svg";

import IdmeVerificationModal from "../UI/Modals/ProviderModals/IdmeVerificationModal";
import BouncingDotsLoader from "../UI/BouncingDotsLoader";
import { useAuth } from "../hooks/useAuth";
import { providerWithNpiUrl } from "../../res/endpointurls";
import MedicalAssistantTableView from "./MedicalAssistantTableView";
import { getPatientConcatenatedNameFromNameObject, getProviderConcatenatedNameFromNameObject } from "../../utils/helpers/HelperFunctions";
import { APPOINTMENTS, ENCOUNTERS, MESSAGES, TASKS } from "../../utils/helpers/subscriptionsConstants";

const ProviderDashboardPage = (props) => {
  const ProviderMessages = useSelector((state) => state.ProviderMessages);
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);


  // const ProviderUnreadMessagesCount = useSelector((state) => state.ProviderUnreadMessageCount);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isEncounterLoading, setIsEncounterLoading] = useState(false);
  const [isSessionUpdated, setIsSessionUpdated] = useState(false);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const [appointments, setAppoinments] = useState([]);
  const [isAppointmentsLoading, setIsAppointmentsLoading] = useState(true);
  const [statusCodeAppointments, setStatusCodeAppointments] = useState(null);
  const [statusCodePrescriptionReq, setStatusCodePrescriptionReq] =
    useState(null);
  const [statusCodeMessages, setStatusCodeMessages] = useState(null);
  const [prescriptionRequests, setPrescriptionRequests] = useState([]);
  const [pendingEncounters, setPendingEncounters] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [selectedUser, setSelectedUser] = useState("");
  // const date = new Date();
  let curentDate = dateTimeHelper(moment(), "YYYY-MM-DDThh:mm");
  const [appointmentDate, setCurrentDate] = useState(curentDate);
  const [prescribersList, setPrescribersList] = useState([]);

  const [isPrescribersFetched, setisPrescribersFetched] = useState(false);
  const [openPendingEcnountersModal, setOpenPendingEcnountersModal] =
    useState(false);
  const [openPendingTasksModal, setOpenPendingTasksModal] = useState(false);
  const [openNewMessageModal, setOpenNewMessageModal] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [idmeVerificationModal, setIdmeVerificationModal] = useState(false);
  const [pendingTaskCount, setPendingTaskCount] = useState(0);
  const [isPendingTaskCountLoading, setIsPendingTaskCountLoading] =
    useState(false);
  const [isMessageCountLoading, setIsMessageCountLoading] = useState(false);
  const [closeNotification, setCloseNotification] = useState(true);
  const [notificationList, setNotificationList] = useState([]);

  const [isNotificationListLoading, setIsNotificationListLoading] =
    useState(false);

  const [IDMELink, setIDEMLink] = useState("");
  const { user, getSecondaryUser, selectUser } = useAuth();
  const [open, setOpen] = useState(true);

  function handleOpenMessages() {
    setOpen(!open);
  }


  const fecthUnreadMessagesCount = async (prescriberId) => {
    setIsMessageCountLoading(true);

    const httpResponse = await sendHttpRequest({
      url: "/messages/unread",
      method: "GET",
      params: {
        userId: user?.userId,
        tenantId: user?.tenantId
      },
    });
    if (!httpResponse.error) {
      setUnreadMessagesCount(httpResponse?.data.unreadMessageCount);
      dispatch(setUnreadMessageCount(httpResponse?.data.unreadMessageCount));
    } else {
      dispatch(setUnreadMessageCount(0));
      console.log(httpResponse.error);
    }
    setIsMessageCountLoading(false);
  };

  const fecthMessages = async () => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/messages",
      method: "GET",
      params: {
        userId: user?.userId,
        type: 'inbox',
        tenantId: user?.tenantId
      },
    });
    if (!httpResponse.error) {
      // console.log("Messages", httpResponse.data);
      dispatch(setMessages(httpResponse.data));
      setIsLoading(false);
    } else {
      console.log(httpResponse.error);
      dispatch(setMessages([]));
    }
    setStatusCodeMessages(httpResponse.status);
  };


  const fetchAppointments = async (appDate, showLoader = true) => {
    // setAppoinments([]);
    if (showLoader) {
      setIsAppointmentsLoading(true);
    }
    // alert(currentDate)

    let providerId = getSecondaryUser();
    let url = "/appointment/upcoming";
    let params = {
      providerId,
      tenantId: user?.tenantId
    }
    if (user?.role === "MedicalAssistant") {
      // url = `/appointment/day`;
      url = `/appointment/upcoming/medical-assistant/${providerId}/tenants/${user?.tenantId}`;
      // params = { tenantId: user?.tenantId, userId: providerId, date: appDate }
    }
    const httpResponse = await sendHttpRequest({
      url: url,
      method: "GET",
      params: params,
    });
    if (!httpResponse.error) {
      if (user?.role === "MedicalAssistant") {
        const medicalAssistantAppointments = httpResponse?.data?.map((appointment) => {
          const startTime = dateTimeHelper(appointment?.appointmentSlot?.startDateTime, dateTimeFormats?.time12HoursFormat);
          const patient = appointment?.prescriptionReference?.patient;
          const patientName = getPatientConcatenatedNameFromNameObject(patient?.name?.at(0));
          const dateOfBirth = dateTimeHelper(patient?.dateOfBirth, dateTimeFormats?.dateOfBirth);
          const biologicalSex = patient?.gender;
          const reasonForVisit = appointment?.chiefComplaint;
          const provider = getProviderConcatenatedNameFromNameObject(appointment?.prescriptionReference?.providerTenantPracticeLocation?.provider?.name?.at(0));
          const examRoom = appointment?.patientVisit?.facilityRoom?.name;
          const examRoomId = appointment?.patientVisit?.facilityRoom?.facilityRoomId;
          const visitStatus = appointment?.patientVisit?.patientStatus?.displayValue;
          const visitStatusValue = appointment?.status !== "cancelled" ? appointment?.patientVisit?.patientStatus?.internalValue : 'canceled'
          const startDate = dateTimeHelper(appointment?.appointmentSlot?.startDateTime, "YYYY-MM-DD");
          const noShow = appointment?.markedNoShow;
          const appt = appointment;
          return {
            id: appointment?.id,
            patientId: patient?.userId,
            startTime,
            patientName,
            dateOfBirth,
            biologicalSex,
            reasonForVisit,
            provider,
            examRoom,
            visitStatus,
            facilityRoomId: examRoomId,
            visitStatusValue: visitStatusValue,
            startDate,
            noShow,
            appointment: appt

          }
        });
        setAppoinments(p => medicalAssistantAppointments)
      }
      else {

        setAppoinments(httpResponse.data);
      }
    } else {
      setAppoinments([]);
    }
    if (showLoader) {
      setIsAppointmentsLoading(false);
    }
    setStatusCodeAppointments(httpResponse.status);
  };



  const fecthPendingEcounters = async (userId) => {
    setIsEncounterLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/provider/${userId}/prescription-reference`,
      method: "GET",
      params: {
        encounterStatus: "STARTED",
        tenantId: user?.tenantId,
      },
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
      setPendingEncounters(httpResponse.data);
      // setPrescriptionRequests(httpResponse.data);
      setIsEncounterLoading(false);
    } else {
      setPendingEncounters({});
      console.log(httpResponse.error);
    }
    setIsEncounterLoading(false);
  };

  const fetchAllPrescribers = async () => {
    setisPrescribersFetched(true);
    const httpResponse = await sendHttpRequest({
      url: providerWithNpiUrl(user?.tenantId),
      method: "GET",
    });
    if (!httpResponse.error) {
      setPrescribersList(httpResponse.data);
    } else {
      setPrescribersList([]);
      // console.log(httpResponse.error);
    }
    setisPrescribersFetched(false);
  };


  const fetchPendingTaskCount = async () => {
    setIsPendingTaskCountLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/tasks/assignee/${user?.userId}/pending-task-count`,
      method: "GET",
      params: {
        tenantId: user?.tenantId
      }
    });
    if (!httpResponse.error) {
      setPendingTaskCount(httpResponse.data.pendingTaskCount);
    } else {
      console.log(httpResponse.error);
    }
    setIsPendingTaskCountLoading(false);
  };

  const handleProviderId = (event) => {
    if (event.target.value != getSecondaryUser()) {
      selectUser(event.target.value);
      setUserData();
    }
  };

  const setUserData = () => {
    setIsSessionUpdated(true);
  };

  const fecthNotifications = async () => {
    setIsNotificationListLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/notifications",
      method: "GET",
      params: {
        userId: user?.userId,
        tenantId: user?.tenantId,
        status: "DELIVERED",
        type: ["NPPES_ACTIONABLE", "APPT_ACTIONABLE", "NON_ACTIONABLE", "CLM_ACTIONABLE"].toString()
      },
    });
    if (!httpResponse.error) {
      setNotificationList(httpResponse.data);
      // console.log(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
    setIsNotificationListLoading(false);
  };

  // const handleChangeRxTask = async (changeRxTask, tid) => {
  //   const reqConfig = {
  //     url: `/tasks/${tid}`,
  //     method: "GET",
  //   }
  //   setChangeRequestBackdropOpen(true);
  //   const httpResponse = await sendHttpRequest(reqConfig);
  //   if (!httpResponse.error) {
  //     setChangeRequestInformation(httpResponse.data);
  //   }
  //   setChangeRequestBackdropOpen(false);

  //   setChangeRequestTask(changeRxTask);
  // }

  if (isSessionUpdated === true) {
    fetchAppointments(user?.role === "MedicalAssistant" ? dateTimeHelper(new Date(), dateTimeFormats.yearMonthDay) : appointmentDate);
    // fecthRefillRequestsRequests(getSecondaryUser());
    fecthUnreadMessagesCount(userInfo.userId);
    fecthMessages(userInfo.userId);
    // fecthPrescriptionRequests(getSecondaryUser());
    fecthPendingEcounters(getSecondaryUser());
    setIsSessionUpdated(false);
    fetchPendingTaskCount();
  }

  const handlePendingEncountersModal = () => {
    setOpenPendingEcnountersModal(!openPendingEcnountersModal);
  };

  const handlePendingTasksModal = () => {
    setOpenPendingTasksModal(!openPendingTasksModal);
  };

  const handleNewMessageModal = () => {
    setOpenNewMessageModal(!openNewMessageModal);
  };

  // const handleSnackbarClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setIsErrorMessage(false);
  // };

  const handleBackdropClose = () => {
    setOpenBackdrop(false);
  };
  const handleToggle = () => {
    setOpenBackdrop(!openBackdrop);
  };

  const handleNotificationClose = () => {
    setCloseNotification(!closeNotification);
  };

  const handleIdMeVerificationModal = () => {
    setIdmeVerificationModal(!idmeVerificationModal);
  };

  // const handleRenewalTask = (renewalRxTask) => {
  //   setRenewalRequestTask(renewalRxTask);
  // }

  // const handleChangeTask = (changeRxTask) => {
  //   setChangeRequestTask(changeRxTask);
  // }

  // useEffect(() => {
  //   fecthPrescriptionRequests(getSecondaryUser());
  // }, [reloadTasks])
  const MINUTE_MS = 15000;
  useEffect(() => {
    setUserData();
    async function initPage() {
      await fetchAppointments(user?.role === "MedicalAssistant" ? dateTimeHelper(new Date(), dateTimeFormats.yearMonthDay) : appointmentDate);
      // fecthPrescriptionRequests(getSecondaryUser());

      await fetchAllPrescribers();

      // getMapFromSession();
      await fecthNotifications();
    }
    initPage()
    // fecthUnreadMessagesCount(userInfo.userId);
  }, [user?.tenantId]);

  useInterval(() => {
    fecthNotifications();
    fecthUnreadMessagesCount(userInfo.userId);
    fetchPendingTaskCount();
    fetchAppointments(user?.role === "MedicalAssistant" ? dateTimeHelper(new Date(), dateTimeFormats.yearMonthDay) : appointmentDate, false);
    fecthPendingEcounters(getSecondaryUser());
  }, MINUTE_MS);

  return (
    <>
      {/* {console.log("prescriberInfo",prescriberInfo)} */}

      {openBackdrop && (
        <Backdrop sx={{ color: "#EC609B", zIndex: 900000 }} open={openBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      {/* {changeRequestBackdropOpen && (
        <Backdrop sx={{ color: "#EC609B", zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={changeRequestBackdropOpen}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )} */}


      <IdmeVerificationModal
        link={IDMELink}
        show={idmeVerificationModal}
        handleModal={handleIdMeVerificationModal}
      />

      {openPendingEcnountersModal && (
        <PendingEncountersModal
          show={openPendingEcnountersModal}
          pendingEncounters={pendingEncounters}
          handleModal={handlePendingEncountersModal}
          handleBackdropClose={handleBackdropClose}
          handleToggle={handleToggle}
        />
      )}
      {openNewMessageModal && (
        <NewMsgOnDashboardModal
          show={openNewMessageModal}
          handleModal={handleNewMessageModal}
          handleBackdropClose={handleBackdropClose}
          handleToggle={handleToggle}
        />
      )}
      {openPendingTasksModal && (
        <PendingTasksModal
          show={openPendingTasksModal}
          handleModal={handlePendingTasksModal}
          handleBackdropClose={handleBackdropClose}
          handleToggle={handleToggle}
        />
      )}
      {/* <Navbar notificationList={notificationList} /> */}
      <div
        id="pn-provider-dashboard"
        className="container-fluid white_background"
      >
        <div style={{ zIndex: 1000 }}>
          {
            notificationList?.length > 0 ?
              <div className="mt-2 p-3">
                {
                  <NotificationALert
                    handleModal={handleIdMeVerificationModal}
                    notificationList={notificationList}
                    handleNotificationClose={handleNotificationClose}
                    fecthNotifications={fecthNotifications}
                    setIDEMLink={setIDEMLink}
                  />
                }
              </div> : null
          }
        </div>
        <Greeting
          className="p-1-8 px-3 pt-3"
          refills={
            subscriptionPrivilegeGroups[TASKS]?.vts?.active === true ?
              <Stats
                id="refill-stats"
                // icon={<ReplayRoundedIcon fontSize="medium" />}
                icon={
                  <TaskIcon
                    style={{
                      color: "#004FD41A",
                      fill: "#004FD4",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                }
                fetchData={handlePendingTasksModal}
                value={pendingTaskCount}
                description="Pending Tasks"
              /> : null
          }
          encounters={subscriptionPrivilegeGroups[ENCOUNTERS]?.vpe?.active === true ?
            <Stats
              id="encounter-stats"
              icon={
                // {<CalendarTodayRoundedIcon fontSize="medium" />}
                <CalendarIcon
                  style={{
                    color: "#004FD41A",
                    fill: "#004FD4",
                    width: "24px",
                    height: "24px",
                  }}
                />
              }
              fetchData={handlePendingEncountersModal}
              value={pendingEncounters?.length}
              description="Pending Encounters"
            /> : null
          }
          messages={
            subscriptionPrivilegeGroups[MESSAGES]?.vmi?.active === true ?
              <Stats
                id="messages-stats"
                icon={
                  <MessagesIcon
                    style={{
                      color: "#004FD41A",
                      fill: "#004FD4",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                }
                fetchData={handleNewMessageModal}
                value={
                  isMessageCountLoading ? (
                    <Loading />
                  ) : unreadMessagesCount !== null ||
                    unreadMessagesCount !== undefined ? (
                    unreadMessagesCount
                  ) : (
                    "0"
                  )
                }
                description="New Messages"
              />
              : null
          }
        />
      </div>

      <div className="container-fluid bg--background">
        <div className="row">
          <div className={`col-sm-12 ${open ? user?.role !== "MedicalAssistant" ? 'col-md-8' : 'col-md-12' : 'col-md-10'} col-12 p-2`}>
            <div className="row mb-4 px-4">
              <div className="col-sm-12 col-md-12">
                {/* {isLoadingFromSession && ( */}
                {RolesMap(user?.role) === "Medical Assistant" ? null : <div
                  style={{ width: "15vw" }}
                  className="d-flex text--terciary "
                  hidden={RolesMap(user?.role) === "doctor" ? true : false}
                >
                  {/* <ProviderListDropdown/> */}
                  {isPrescribersFetched === true ? (
                    <Skeleton
                      hidden={RolesMap(user?.role) === "doctor" ? true : false}
                      animation="wave"
                      variant="text"
                      width={"70%"}
                      height={40}
                    />
                  ) : (
                    <div
                      hidden={RolesMap(user?.role) === "doctor" ? true : false}
                    >
                      {/* <label className="text--terciary b2">Provider</label> */}

                      <select
                        className={`form-select br-10 font-14 shadow-none ${classes["custom-select"]}`}
                        aria-label="Provider select"
                        style={{
                          border: "1px solid #D9E8EC",
                          width: "20vw",
                        }}
                        onChange={(e) => handleProviderId(e)}
                      >
                        <option value="" disabled selected hidden>
                          Select provider
                        </option>
                        {prescribersList?.length > 0
                          ? prescribersList?.map((prescriberItem) => {
                            return (
                              <option
                                value={prescriberItem?.userId ?? ""}
                                key={prescriberItem?.userId ?? ""}
                                selected={getSecondaryUser() == prescriberItem?.userId}
                              >
                                {prescriberItem?.name[0]?.prefix}{" "}
                                {prescriberItem?.name[0]?.firstName}{" "}
                                {prescriberItem?.name[0]?.lastName}{" "}
                                {RolesTextConversion(prescriberItem?.role) !==
                                  undefined
                                  ? "(" +
                                  RolesTextConversion(
                                    prescriberItem?.role
                                  ) +
                                  ")"
                                  : ""}
                              </option>
                            );
                          })
                          : "No prescribers available"}
                      </select>
                    </div>
                  )}
                </div>}
                {/* )} */}
              </div>
            </div>
            <div className="row  mb-4 px-4" hidden={subscriptionPrivilegeGroups[APPOINTMENTS]?.vsa?.active === false ? true : false}>
              <div className="col-sm-12 col-md-12">
                {user?.role === "MedicalAssistant" ? <MedicalAssistantTableView
                  columnHeadersList={["Appointment Time", "Patient", "Date of Birth", "Biological Sex", "Reason for Visit", "Provider", "Exam Room", "Visit Status", ""]}
                  appointmentTableRows={appointments}
                  appointments={appointments}
                  handleBackdropClose={handleBackdropClose}
                  areAppointmentsLoading={isAppointmentsLoading}
                  fetchAppointments={() => { fetchAppointments(user?.role === "MedicalAssistant" ? dateTimeHelper(new Date(), dateTimeFormats.yearMonthDay) : appointmentDate, false) }
                  }
                /> : <AppointmentsList
                  appointmentList={appointments}
                  isLoading={isAppointmentsLoading}
                  providerId={user?.userId}
                  fetchAppointments={() => fetchAppointments(user?.role === "MedicalAssistant" ? dateTimeHelper(new Date(), dateTimeFormats.yearMonthDay) : appointmentDate)}

                />}
              </div>
            </div>
            {user?.role !== "MedicalAssistant" ? <>
              <div
                className="col-12 w-100 my-3"
                style={{
                  backgroundColor: "#d9e8ec",
                  borderBottom: "1px solid #d9e8ec",
                }}
              >
                {/* <Divider className="my-3" /> */}
              </div>
              <div className="row mb-5 px-4" hidden={subscriptionPrivilegeGroups[TASKS]?.vpr?.active === false ? true : false}>
                <div className="col-sm-12 col-md-12">
                  <PrescriptionRequestList />
                </div>
              </div>
            </> : null}

          </div>
          {user?.role !== "MedicalAssistant" ? <div className={`col-sm-12 border--start ${open ? 'col-md-4' : 'col-md-2'} col-12 p-2`} hidden={subscriptionPrivilegeGroups[MESSAGES]?.vmi?.active === false ? true : false}>
            {statusCodeMessages === 200 ? (
              <Messages ProviderMessages={ProviderMessages} open={open} handleOpenMessages={handleOpenMessages} />
            ) : (
              "No messages available"
            )}
            {/* {console.log("ProviderMessages",ProviderMessages)} */}
          </div> : null}
        </div>
      </div>

      {/* <CustomizedSnackbars
        message={"Error in processing request. Please try again."}
        severity="error"
        open={isErrorMessage}
        handleClose={handleSnackbarClose}
      /> */}

      {/* {console.log("prescriberInfo", prescriberInfo)} */}
    </>
  );
};

export default ProviderDashboardPage;




import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { useParams } from "react-router-dom";
import { Skeleton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import {
  calenderDateTimeHelper,
  dateTimeHelper,
} from "../../utils/dateTimeHelper";
import { X } from "react-bootstrap-icons";
import CancelRxModal from "../UI/Modals/ProviderModals/CancelRxModal";
import {
  fieldValidation,
  prescriptionMap,
  truncate,
} from "../../utils/helpers/HelperFunctions";
import CustomizedSnackbar from "../UI/General/CustomizedSnackbars";
import { featureFlagMap } from "../../utils/helpers/ConfigMap";
import { PnToolTip } from "../UI/Reusable/PnComponents";
import { RefreshDataComponent } from "../UI/General/RefreshDataComponent";
import ReplayIcon from "@mui/icons-material/Replay";
import ReorderPrescriptionModal from "../UI/Modals/PrescriptionModal/ReorderPrescriptionModal";
import { updatePrescribedMedication } from "../../redux/actions/providerActions";
import { useDispatch, useSelector } from "react-redux";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FAFCFC",
    color: "#336383",
    fontWeight: 400,
    fontSize: 12,
    // width: 150,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    paddingRight: "0px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#FAFCFC",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles({
  customTable: {
    "& .MuiTableCell-sizeMedium": {
      padding: "0px", // <-- arbitrary value
    },
  },
  tableCell: {
    textTransform: "capitalize",
  },
});

const PrescriptionTable = (props) => {

  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);

  const { patientId } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [medicationHistory, setMedicationHistory] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [medicationHistoryStatusCode, setMedicationHistoryStatusCode] =
    useState(null);
  const [openCancelRxModal, setOpenCancelRxModal] = useState(false);
  const [RxObject, setRxObject] = useState();

  const [cancelRxMessage, setCancelRxMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [cancelRxToastOpen, setCancelRxToastOpen] = useState(false);
  const [cancelRxError, setCancelRxError] = useState(false);
  const featureFlags = featureFlagMap();
  const [openReorderModal, setOpenReorderModal] = useState(false);

  const fetchPrescriptionHistory = async () => {
    setIsLoading(true);
    let pId = patientId || props?.patientId;
    const httpResponse = await sendHttpRequest({
      url: `/patient/${pId}/orders`,
      method: "GET",
    });

    if (!httpResponse.error) {
      // console.log(httpResponse.data);
      setMedicationHistory(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
    setIsLoading(false);
    setMedicationHistoryStatusCode(httpResponse.status);
  };

  const handleCancelRx = (medicationObject) => {
    setRxObject(medicationObject);
    setOpenCancelRxModal(true);
  };
  const handleCancelRxModal = () => {
    setOpenCancelRxModal(!openCancelRxModal);
  };

  const handleCancelRxToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setCancelRxToastOpen(false);
  };
  const handleReorderModal = (rxObject) => {
    // console.log("rxObject: ",rxObject);
    setRxObject(rxObject);
    dispatch(
      updatePrescribedMedication([
        {
          DDID: rxObject?.drugId,
          drugDbCode: rxObject?.drugId,
          drugDescription: rxObject?.drugDescription,
          ndc: rxObject?.ndc,

          Sig: {
            SigText: rxObject?.sigText,
          },
          textValue: rxObject?.sigText,
          Quantity: parseFloat(rxObject?.quantity),
          daySupply: rxObject?.daysSupply,
          NumberOfRefills:
            rxObject?.refills !== undefined &&
              rxObject?.refills !== "" &&
              rxObject?.refills !== 0
              ? rxObject?.refills
              : 0,
          RefillAsNeeded: false,
          DispenseAsWritten: rxObject?.substitution,
          // Note: rxObject?.pharmacyNote !== undefined ? rxObject?.pharmacyNote : "",
          Note: rxObject?.pharmacyNote ?? "",
          DoseForm: rxObject?.quantityUnitOfMeasure || "",
          PrescriptionStartDate: {
            Date: calenderDateTimeHelper(new Date(), "YYYY-MM-DD"),
          },
          EarliestFillDate: {
            Date: calenderDateTimeHelper(new Date(), "YYYY-MM-DD"),
          },
          WrittenDate: {
            Date: calenderDateTimeHelper(rxObject?.writtenDate, "YYYY-MM-DD"),
          },
          alertPhysician72: rxObject?.alertPhysician,
          PatientDirections: "",
          flag: true,
          quantityUom: rxObject?.quantityUnitOfMeasure,
          quantityUnitOfMeasureCode: rxObject?.quantityUnitOfMeasureCode,
          quantityUomCode: rxObject?.quantityUnitOfMeasureCode,
          pharmacy: rxObject?.pharmacy
        },
      ])
    );
    setOpenReorderModal(!openReorderModal);
  };
  // console.log("props?.isPrescriptionSent",props?.isPrescriptionSent);
  useEffect(() => {
    fetchPrescriptionHistory();
  }, [props?.patientId, props?.isPrescriptionSent]);


  return (
    <>
      {openReorderModal && (
        <ReorderPrescriptionModal
          show={openReorderModal}
          handleModal={handleReorderModal}
          encounterId={props.patientId}
          patientId={props.patientId}
          RxObject={RxObject}
          fetchPrescriptionHistory={fetchPrescriptionHistory}
        />
      )}

      {openCancelRxModal && (
        <CancelRxModal
          show={openCancelRxModal}
          handleModal={handleCancelRxModal}
          RxObject={RxObject}
          patient={RxObject?.prescriptionReference?.patient}
          provider={RxObject?.prescriptionReference?.provider}
          pharmacy={RxObject?.pharmacy}
          medication={RxObject}
          encounterId={RxObject?.prescriptionReference?.prescriptionReferenceId}
          cancelRxError={cancelRxError}
          setCancelRxError={setCancelRxError}
          severity={severity}
          setSeverity={setSeverity}
          cancelRxMessage={cancelRxMessage}
          setCancelRxMessage={setCancelRxMessage}
          cancelRxToastOpen={cancelRxToastOpen}
          setCancelRxToastOpen={setCancelRxToastOpen}
          fetchPrescriptionHistory={fetchPrescriptionHistory}
          prescriptionTable={true}
          fromPatientProfile={true}
        />
      )}

      <div className="d-flex justify-content-between mt-3">
        <div></div>
        <RefreshDataComponent
          isComponentDataLoading={isLoading}
          callback={fetchPrescriptionHistory}
          variant="without-bar"
          classes="pe-2"
        />
      </div>
      <TableContainer
        sx={{ borderRadius: "16px", border: "1px solid #D9E8EC" }}
      >
        <Table>
          <TableHead
            sx={{
              borderTopColor: "#FAFCFC",
              borderRightColor: "#FAFCFC",
              borderLeftColor: "#FAFCFC",
              borderBottom: "1px solid #D9E8EC",
            }}
          >
            <StyledTableRow>
              <StyledTableCell>Order Number</StyledTableCell>
              <StyledTableCell>Medication</StyledTableCell>
              <StyledTableCell>Dispense</StyledTableCell>
              <StyledTableCell>Refills</StyledTableCell>
              {/* <StyledTableCell>Prescribed by</StyledTableCell> */}
              <StyledTableCell>Sig</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Last Updated</StyledTableCell>
              {/* <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell> */}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <StyledTableRow>
                <StyledTableCell>
                  <Skeleton animation="wave" />
                </StyledTableCell>
                <StyledTableCell>
                  <Skeleton animation="wave" />
                </StyledTableCell>
                <StyledTableCell>
                  <Skeleton animation="wave" />
                </StyledTableCell>
                <StyledTableCell>
                  <Skeleton animation="wave" />
                </StyledTableCell>
                <StyledTableCell>
                  <Skeleton animation="wave" />
                </StyledTableCell>
                <StyledTableCell>
                  <Skeleton animation="wave" />
                </StyledTableCell>
                <StyledTableCell>
                  <Skeleton animation="wave" />
                </StyledTableCell>
                {/* <StyledTableCell><Skeleton animation="wave" /></StyledTableCell>
                <StyledTableCell><Skeleton animation="wave" /></StyledTableCell> */}
              </StyledTableRow>
            ) : medicationHistoryStatusCode === 200 ? (
              medicationHistory?.length > 0 ? (
                medicationHistory?.map((medicationItem, index) => {
                  let orderNumber = medicationItem?.orderNumber;
                  let drugDescriptionValue = medicationItem?.drugDescription;
                  let dispense = medicationItem?.quantity;
                  let noOfRefills = medicationItem?.refills;
                  let sigValue = medicationItem?.sigText;
                  let status = medicationItem?.rxStatus;
                  let errorNote = medicationItem?.errorText;
                  let pharmacyNote = medicationItem?.pharmacyNote || "";
                  let lastUpdatedDate = medicationItem?.updateAt;
                  let isCancelled = medicationItem?.cancelled;
                  let denyReasonDescription =
                    medicationItem?.denyReasonDescription;
                  if (pharmacyNote?.length > 0) {
                    pharmacyNote = pharmacyNote?.concat(
                      denyReasonDescription?.length > 0
                        ? ": ".concat(denyReasonDescription || "")
                        : ""
                    );
                  }

                  return (
                    <StyledTableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <StyledTableCell
                      // sx={{ width: "100", color: "#336383" }}
                      >
                        {orderNumber}
                      </StyledTableCell>
                      <StyledTableCell
                      // sx={{ width: "100", color: "#336383" }}
                      >
                        {drugDescriptionValue !== undefined
                          ? drugDescriptionValue
                          : ""}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ width: 10 }}
                      // className="text-center"
                      >
                        {dispense !== undefined ? dispense : 0}
                      </StyledTableCell>
                      <StyledTableCell sx={{ width: 10 }}>
                        {noOfRefills !== undefined && noOfRefills !== ""
                          ? noOfRefills
                          : "0"}
                      </StyledTableCell>
                      <StyledTableCell sx={{ color: "#336383" }}>
                        {fieldValidation(sigValue, "all") &&
                          sigValue?.length > 80 ? (
                          <PnToolTip
                            title={sigValue !== undefined ? sigValue : ""}
                            arrow
                            placement="top"
                          >
                            <span>
                              {/* {console.log(sigValue, sigValue.length)} */}
                              {sigValue !== undefined
                                ? truncate(sigValue, 80)
                                : ""}
                            </span>
                          </PnToolTip>
                        ) : sigValue !== undefined ? (
                          truncate(sigValue, 80)
                        ) : (
                          ""
                        )}
                      </StyledTableCell>
                      <StyledTableCell sx={{ width: 125, color: "#336383" }}>
                        {prescriptionMap.get(status.toLowerCase()) ===
                          undefined ? (
                          <span>{status !== undefined ? status : ""}</span>
                        ) : (

                          <PnToolTip
                            title={
                              prescriptionMap.get(status.toLowerCase()) ===
                                "Error"
                                ? errorNote
                                : pharmacyNote?.length > 0
                                  ? pharmacyNote
                                  : "".concat(denyReasonDescription || "")
                            }
                            arrow
                          >
                            <span>
                              {status !== undefined ? status : ""}
                              {pharmacyNote?.length > 0 || errorNote?.length > 0 ? (
                                <InfoIcon
                                  style={{ fill: "#004fd4", height: "12px" }}
                                />
                              ) : (null)}

                            </span>
                          </PnToolTip>
                        )}
                      </StyledTableCell>
                      <StyledTableCell sx={{ width: 105 }}>
                        {lastUpdatedDate !== undefined
                          ? dateTimeHelper(lastUpdatedDate, "MMM DD, YYYY")
                          : ""}
                        <br />
                        {lastUpdatedDate !== undefined
                          ? dateTimeHelper(lastUpdatedDate, "LT")
                          : ""}
                      </StyledTableCell>
                      {featureFlags.get("surescript") === "true" && (
                        <StyledTableCell>
                          {/* <img src={X} alt="For cancel Rx">
                      </img> */}
                          <div className="d-flex flex-row">
                            <PnToolTip title={"Reorder"} arrow placement="top"
                              hidden={subscriptionPrivilegeGroups['Patients']?.rpm?.active === false ? true : false}
                            >
                              <ReplayIcon
                                className={`icon__defualt font--12 text--secondary my-auto cursor--pointer`}
                                fontSize={"medium"}
                                onClick={() => {
                                  handleReorderModal(medicationItem);
                                }}
                              />
                            </PnToolTip>
                            <PnToolTip
                              title={"Cancel Rx"}
                              arrow
                              placement="top"
                              hidden={subscriptionPrivilegeGroups['Patients']?.cpm?.active === false ? true : false}
                            >
                              <X
                                className={`icon__defualt font--18 text--danger  cursor--pointer ${isCancelled ? "disabled-link" : ""
                                  } `}
                                style={{ width: "32px", height: "32px" }}
                                onClick={() => {
                                  handleCancelRx(medicationItem);
                                }}
                              />
                            </PnToolTip>
                          </div>
                        </StyledTableCell>
                      )}
                    </StyledTableRow>
                  );
                })
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomizedSnackbar
        message={cancelRxMessage}
        severity={severity}
        open={cancelRxToastOpen}
        handleClose={handleCancelRxToast}
      />
      {/* {console.log("openReorderModal: ", openReorderModal)}
      {console.log("props?.isPrescriptionSent",props?.isPrescriptionSent)} */}

    </>
  );
};
export default PrescriptionTable;

import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import classes from "../patient/PatientItem.module.css";
import { changeDateTimeFormat, dateTimeHelper, getAgeByDateOfBirth } from "../../utils/dateTimeHelper";
import { useNavigate } from "react-router-dom";
import IconChevronRight from "../../assets/icons/Icon-Chevron-Right.svg";
import IconFile from "../../assets/icons/Icon-file.svg";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { RolesTextConversion } from "../../utils/helpers/RolesMap";
import { truncate } from "../../utils/helpers/HelperFunctions";
import { PnToolTip } from "../UI/Reusable/PnComponents";
import { ExclamationCircle } from "react-bootstrap-icons";

const TRUNCATE_VAL = 25;

const MessageItem = (props) => {
  const navigate = useNavigate();

  const [userData, setUserData] = useState(null);
  const [isUserDataLoading, setIsUserDataLoading] = useState(false);

  const fetchUserData = async (userId) => {
    setIsUserDataLoading(false);
    const httpResponse = await sendHttpRequest({
      url: "/auth/user-info",
      method: "GET",
      params: {
        userid: userId,
      },
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
      setUserData(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
    setIsUserDataLoading(true);
  };

  useEffect(() => {
    fetchUserData(props?.task?.from);
    return () => { }
  }, [])


  return (
    <>
      <Grid
        sx={{ marginBottom: "-8px" }}
        container
        p={1}
        alignItems="center"
        className={`${classes["row--border"]} mt-2  ${props?.isEven ? "bg--background" : ""} ${props?.isLast ? classes["border--bottom"] : ""}`}
      >
        <Grid item xs={3}>
          <Typography component="div">
            <Box
              sx={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "12px",
                color: "#323232",
                ml: 1,
              }}
            >
              {
                <>
                  <div className="d-flex justify-content-start mt-2">
                    <PnToolTip title={userData?.name[0]?.firstName + " " + userData?.name[0]?.lastName}>
                      <span className={`font-12 fw-sb text--terciary`}>
                        {truncate(userData?.name[0]?.firstName + " " + userData?.name[0]?.lastName, TRUNCATE_VAL)}
                      </span>
                    </PnToolTip>
                  </div>
                  <div className="d-flex justify-content-start">
                    <span className={`font-10 text--secondary`}>
                      {isUserDataLoading && (RolesTextConversion(userData?.role))}
                    </span>
                  </div>
                </>
              }
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography component="div">
            <Box
              sx={{
                textAlign: "left",
                fontFamily: "Montserrat",
                fontSize: 12,
                // color: "#336383",
              }}
            >
              <span className={`fw-sb text--terciary`} style={{ marginBottom: "-2px" }}>
                {props?.task?.subject}
              </span>
              {" "}
              <span className={`text--secondary`} >
                {props?.task?.body}
              </span>

            </Box>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography component="span">
            <Box
              sx={{
                textAlign: "left",
                ml: 2,
                fontFamily: "Montserrat",
                fontSize: 12,
              }}
            >
              {
                props?.task?.deliveryTime ? dateTimeHelper(props?.task?.deliveryTime, "MMM D, YYYY") : ""
              }
              <br />
              {
                props?.task?.deliveryTime ? dateTimeHelper(props?.task?.deliveryTime, "LT") : ""
              }
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography component="div">
            <Box
              className="bg--background "
              sx={{
                textAlign: "center",
                mr: 2,
                fontFamily: "Montserrat",
                fontSize: "12px",
                color: "#336383",
              }}
            >
              <div className="d-flex flex-row justify-content-between 
              ">
                {
                  props?.task?.attachment !== undefined ?
                    <div
                      style={{
                        borderRadius: "50px",
                        height: "32px",
                        width: "32px",
                      }}
                      className={
                        "bg--disabled align-self-center my-auto"
                      }
                    >
                      <div
                        className="p-1 d-block align-self-center my-auto font-12"
                        style={{ cursor: "pointer" }}
                      // id={encounterItem?.Id}
                      // onClick={(e) => handleEncData(e)}
                      >
                        <img
                          src={IconFile}
                          alt={"naviagte"}
                          // id={encounterItem?.Id}
                          style={{
                            height: "16px",
                            width: "16px",
                            fontWeight: "600",
                          }}
                        ></img>
                      </div>
                    </div>
                    :
                    <div
                      style={{
                        borderRadius: "50px",
                        height: "32px",
                        width: "32px",
                      }}
                      className={
                        "align-self-center my-auto"
                      }
                    >
                      <div
                        className="p-1 d-block align-self-center my-auto font-12"
                        style={{ cursor: "pointer" }}
                      ></div>
                    </div>
                }
                <div
                  style={{
                    borderRadius: "50px",
                    height: "32px",
                    width: "32px",
                  }}
                  className={
                    "bg--disabled align-self-center my-auto"
                  }
                >
                  <div
                    className="p-1 d-block align-self-center my-auto font-12"
                    style={{ cursor: "pointer" }}
                    // id={encounterItem?.Id}
                    onClick={(e) => { props?.markMessageAsRead(props?.task?.threadId); navigate("/messages", { state: props?.task?.threadId }) }}
                  >
                    <img
                      src={IconChevronRight}
                      alt={"naviagte"}
                      // id={encounterItem?.Id}
                      style={{
                        height: "16px",
                        width: "16px",
                        fontWeight: "600",
                      }}
                    ></img>
                  </div>
                </div>
                <div className="align-self-center">
                  {props?.task?.urgency && <ExclamationCircle
                    color="red"
                    size={16}
                  />}
                </div>
              </div>
            </Box>
          </Typography>
        </Grid>
      </Grid >
      {/* {console.log(RolesTextConversion(userData?.Role))} */}
    </>
  );
}

export default MessageItem;
import { useState, useEffect } from 'react';
import PatientDemographicsService from '../Services/PatientDemographics.service';

const usePatientDemographics = (patientId) => {
    // console.log(patientId);
    const [patientInformation, setPatientInformation] = useState(null);
    const [isPatientInformationLoading, setIsPatientInformationLoading] = useState(false);
    const [error, setError] = useState(false);

    const fetchPatientInformation = async (patientId) => {
        setIsPatientInformationLoading(true);
        setError(false);
        const response = await PatientDemographicsService.getPatientDemographics(patientId);
        // console.log("patient info res", response);
        if (response.status === 200 && response !== null) {
            setPatientInformation(response);
        }
        else {
            setError(true);
            setPatientInformation(response);
        }
        setIsPatientInformationLoading(false);
    }
    useEffect(() => {
        fetchPatientInformation(patientId);
    }, [patientId])

    return { patientInformation, isPatientInformationLoading, error };
}

export default usePatientDemographics;
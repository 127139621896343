import {
    Box,
    Grid,
    Typography,
  } from "@mui/material";
  import styled from "@emotion/styled";
  import React, { useState } from "react";
  import classes from "../patient/PatientItem.module.css";
  import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
  import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import TrashIcon from "../../assets/icons/TrashIcon";
import EditButtonIcon from "../../assets/icons/Icon-Edit-Disabled.svg";
import { formatPhoneNumber } from "../../utils/helpers/HelperFunctions";

  const PharmacyItem = (props) => {
    
    return (
        <Grid
          sx={{ marginBottom: "-8px" }}
          container
          p={1}
          alignItems="center"
          className={`${classes["row--border"]} mt-2  ${props?.isEven ? "bg--background" : ""} ${props?.isLast ? classes["border--bottom"] : ""}`}
        >
          <Grid item xs={2}>
            <Typography component="div">
              <Box
                sx={{
                  textAlign: "left",
                  m: 1,
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "#323232",
                  fontWeight:600
                }}
              >
                 
                  {(props?.BusinessName !== undefined && props?.BusinessName !== null && props?.BusinessName !== "" && props?.BusinessName) || "Not available" }
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={1.25}>
            <Typography component="div">
              <Box
                sx={{
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: "14px",
                  color: "#336383",
                  textAlign: "left"
                }}
              >
                {(props?.Type !== undefined && props?.Type !== null && props?.Type !== "" && props?.Type) || "Not available"}
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={2.25}>
            <Typography component="div">
              <Box
                sx={{
                  textAlign: "left",
                  ml: 2,
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "#336383",
                }}
              >
                {(props?.Address !== undefined && props?.Address !== null && props?.Address !== "" && props?.Address) || "Not available"}
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography component="div">
              <Box
                sx={{
                  textAlign: "left",
                  fontFamily: "Montserrat",
                  ml: 5,
                  fontSize: "14px",
                  color: "#336383",
                }}
              >
                {(props?.Phone !== undefined && props?.Phone !== null && props?.Phone !== "" && formatPhoneNumber(props?.Phone) !== null ? formatPhoneNumber(props?.Phone) : '' ) || "Not available"}
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography component="div">
              <Box
                sx={{
                  textAlign: "left",
                  ml: 4,
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "#336383",
                }}
              >
                {(props?.Fax !== undefined && props?.Fax !== null && props?.Fax !== "" && formatPhoneNumber(props?.Fax) !== null ? formatPhoneNumber(props?.Fax): "Not available")}

                {/* {(props.Fax !== undefined && props.Fax !== null && props.Fax !== "" && props.Fax) || "Not available"} */}
              </Box>
            </Typography>
          </Grid>

          <Grid item xs={1}>
              <Typography component="div">
                <Box
                  sx={{
                    textAlign: "left",
                    mt: 1,
                    ml: 4,
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "#336383",
                  }}
                >
                    
                     {(props?.NPI !== undefined && props?.NPI !== null && props?.NPI !== "" && props?.NPI) || "Not available"}          
                   </Box>
              </Typography>
            </Grid>

            <Grid item xs={1}>
              <Typography component="div">
                <Box
                  sx={{
                    textAlign: "center",
                    mt: 1,
                    ml: 4,
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "#336383",
                  }}
                >
                     {/* {(props.NPI !== undefined && props.NPI !== null && props.NPI !== "" && props.NPI) || "Not available"}           */}
                     {!props?.IsControlledSubstance ? <CloseRoundedIcon sx={{color:'red'}} /> : <DoneRoundedIcon sx={{color:'green'}}/> }
                   </Box>
              </Typography>
            </Grid>


            <Grid item xs={0.5}>
              <Typography component="div">
                <Box
                  sx={{
                    textAlign: "center",
                    mt: 1,
                    ml: 4,
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "#336383",
                  }}
                >
                     {/* {(props.NPI !== undefined && props.NPI !== null && props.NPI !== "" && props.NPI) || "Not available"}           */}
                     <img 
                     src={EditButtonIcon}
                     />
                   </Box>
              </Typography>
            </Grid>

            <Grid item xs={0.5}>
              <Typography component="div">
                <Box
                  sx={{
                    textAlign: "center",
                    mt: 1,
                    ml: 4,
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "#336383",
                  }}
                >
                     {/* {(props.NPI !== undefined && props.NPI !== null && props.NPI !== "" && props.NPI) || "Not available"}           */}
                     <TrashIcon style={{ color: "#DDDDDD" }} />
                   </Box>
              </Typography>
            </Grid>
          
        </Grid>
      );
  }

  export default PharmacyItem;
import { useEffect, useState } from "react";
import classes from "./EncountersTablePage.module.css";
import {
  PnToolTip,
  StyledTableCell,
  StyledTableRow,
  TabPanel,
  theme,
} from "../UI/Reusable/PnComponents";
import {
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  ThemeProvider,
} from "@mui/material";
import {
  calenderDateTimeHelper,
  dateTimeHelper,
} from "../../utils/dateTimeHelper";
import PageHeader from "../UI/General/PageHeader";
import { PracticeAdminHeader } from "../pages/InPatientDashboard";
import SearchPatient from "../UI/Header/SearchPatient";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { useAuth } from "../hooks/useAuth";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CalenderDatePicker from "../UI/General/CalenderDatePicker";
import { useLocation, useNavigate } from "react-router-dom";

const RegisteredPatientTable = () => {
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  // ================Page Header Information============================
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [name, setName] = useState();
  const [minAge, setMinAge] = useState();
  const [maxAge, setMaxAge] = useState();
  const [zipCode, setZipCode] = useState();
  const [method, setMethod] = useState();
  const [gender, setGender] = useState();

  const [isPatientsDataLoading, setIsPatientsDataLoading] = useState(false);

  // =================Pagination Information=============================
  const [pages, setPages] = useState([]);
  const [pageNumberLimit, setPageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [maxPageLimit, setMaxPageLimit] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(undefined);

  const columnHeadersList = [
    "Date",
    "Name",
    "Date of Birth",
    "Biological Sex",
    "Smoker",
    "Phone",
    "Address",
  ];
  const patientTableRows = [];

  const fetchPatientsData = async (
    page,
    startDate,
    endDate,
    minAge,
    maxAge,
    name,
    zipCode,
    method
  ) => {
    setIsPatientsDataLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/tenants/${user?.tenantId}/patients`,
      method: "GET",
      params: {
        size: 10,
        page: page - 1,
        ...(startDate && { from: startDate }),
        ...(endDate && { to: endDate }),
        ...(minAge && { minAge: minAge }),
        ...(maxAge && { maxAge: maxAge }),
        ...(name && { name: name }),
        ...(zipCode && { zipCode: zipCode }),
        ...(method && { methodOfCommunication: method }),
      },
    });

    if (!httpResponse.error) {
      let noOfPages = httpResponse?.data?.totalPages;
      setTotalResults(noOfPages);
      setMaxPageLimit(noOfPages);
      let pagesList = [];
      for (let i = 1; i <= noOfPages; i++) {
        pagesList.push(i);
      }
      // console.log("pagesList : ",pagesList);
      setPages(pagesList);

      setCurrentItems(httpResponse?.data?.content);
    }
    setIsPatientsDataLoading(false);
  };

  // ============================Pagination Handlers============================
  // Pagination Handlers
  const RenderPages = () => {
    return (
      <ul className={`${classes["pageNumbers"]}`}>
        {pages?.length > 1 &&
          pages?.map((number) => {
            // if(number > maxPageNumberLimit){
            //   console.log(number,maxPageNumberLimit + 1,minPageNumberLimit,number < maxPageNumberLimit + 1 ,
            //     number > minPageNumberLimit);
            // }
            if (
              number < maxPageNumberLimit + 1 &&
              number > minPageNumberLimit
            ) {
              return (
                <li
                  key={number}
                  id={number}
                  className={`${classes["list__item"]} ${
                    currentPage === number ? classes["active__list__item"] : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleCurrentPage(number);
                  }}
                >
                  {/* {number >= 5 && pages.length > 5 && number !== pages.length ? `${number}   ...${pages.length}` : number} */}
                  {number}
                </li>
              );
            } else {
              return null;
            }
          })}
      </ul>
    );
  };

  const handleNextButton = (event) => {
    event.preventDefault();
    setCurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
    fetchPatientsData(
      currentPage + 1,
      startDate,
      endDate,
      minAge,
      maxAge,
      name,
      zipCode,
      method
    );
  };
  const handlePreButton = (event) => {
    event.preventDefault();
    // setPageNumber(pageNumber - 1);
    // console.log("currentPage: ",currentPage-1);
    if (currentPage - 1 >= 1) {
      setCurrentPage((p) => currentPage - 1);
      fetchPatientsData(
        currentPage - 1,
        startDate,
        endDate,
        minAge,
        maxAge,
        name,
        zipCode,
        method
      );
    }
    if ((currentPage - 1) % pageNumberLimit == 0) {
      if (minPageNumberLimit - pageNumberLimit < 0) {
        setMaxPageNumberLimit(5);
        setMinPageNumberLimit(0);
      } else {
        setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
        setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
      }
    }
    // searchPharmacy(inputValue, "Retail,MailOrder", currentPage - 1);
  };
  const handleFirstPage = (event) => {
    event.preventDefault();
    setMaxPageNumberLimit(5);
    setMinPageNumberLimit(0);

    handleCurrentPage(1);
  };
  const handleLastPage = (event) => {
    event.preventDefault();
    let lastPage = Math.ceil(totalResults);
    let maxPageNumberLimitValue = lastPage;
    if (maxPageNumberLimitValue % 10 > 0) {
      maxPageNumberLimitValue = maxPageNumberLimitValue - 5;
    }
    setCurrentPage(totalResults);
    setMaxPageNumberLimit(lastPage);
    setMinPageNumberLimit(lastPage - pageNumberLimit);

    handleCurrentPage(lastPage);
  };
  const handleCurrentPage = (pNumber) => {
    setCurrentPage(pNumber);
    fetchPatientsData(
      pNumber,
      startDate,
      endDate,
      minAge,
      maxAge,
      name,
      zipCode,
      method
    );
  };

  useEffect(() => {
    fetchPatientsData(1, location?.state?.startDate, location?.state?.endDate);
    setStartDate(location?.state?.startDate);
    setEndDate(location?.state?.endDate);
  }, []);

  // useEffect(()=>{
  //   fetchPatientsData(1,startDate,endDate,minAge,maxAge,gender,name,zipCode,method);
  // },[startDate,endDate,minAge,maxAge,gender,name,zipCode,method])

  return (
    <>
      <PageHeader title={"Patients"} />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-11 py-2">
            <PatientTableSearchFilters
              setCurrentItems={setCurrentItems}
              fetchPatientsData={fetchPatientsData}
              startDate={startDate}
              endDate={endDate}
              setStartDate={startDate}
              setEndDate={setEndDate}
              setMinAge={setMinAge}
              setMaxAge={setMaxAge}
              setMethod={setMethod}
              setName={setName}
              setZipCode={setZipCode}
            />
          </div>
          <div className="col-11">
            <TableContainer
              sx={{ borderRadius: "16px", border: "1px solid #D9E8EC" }}
            >
              <Table>
                <TableHead
                  sx={{
                    borderTopColor: "#FAFCFC",
                    borderRightColor: "#FAFCFC",
                    borderLeftColor: "#FAFCFC",
                    borderBottom: "1px solid #D9E8EC",
                  }}
                >
                  <StyledTableRow>
                    {columnHeadersList?.map((item, idx) => (
                      <StyledTableCell key={item?.concat(idx)}>
                        <span className="font-12 text--secondary">{item}</span>
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {isPatientsDataLoading ? (
                    <StyledTableRow
                      key={"patients-data-loading"}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <StyledTableCell
                      // sx={{ color: "#336383", fontWeight: "600" }}
                      >
                        <Skeleton />
                      </StyledTableCell>
                      <StyledTableCell
                      // sx={{ width: "100", color: "#336383" }}
                      >
                        <Skeleton />
                      </StyledTableCell>

                      <StyledTableCell>
                        <Skeleton />
                      </StyledTableCell>

                      <StyledTableCell>
                        <Skeleton />
                      </StyledTableCell>

                      <StyledTableCell>
                        <Skeleton />
                      </StyledTableCell>

                      <StyledTableCell>
                        <Skeleton />
                      </StyledTableCell>

                      <StyledTableCell>
                        <Skeleton />
                      </StyledTableCell>

                      <StyledTableCell>
                        <Skeleton />
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : (
                    currentItems?.map((item, idx) => {
                      // const roomObject = Object.keys(selectedRoom).length !== 0 ? ({ "examRoomId": selectedRoom[item?.id]?.roomId, "examRoom": selectedRoom[item?.id]?.roomName }) : ({ "examRoom": item?.examRoom, "examRoomId": item?.facilityRoomId })
                      let firstName = item?.name[0]?.firstName;
                      let lastName = item?.name[0]?.lastName;
                      let phoneNumber =
                        item?.communicationNumbers?.numbers?.find((phone) => {
                          return phone?.type === "PT";
                        })?.number ?? "";
                      return (
                        <StyledTableRow
                          key={item?.userId}
                          // onClick={() => { navigateToPatientProfile(item?.patientId, item?.id, roomObject) }}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            // "&:hover": { cursor: 'pointer' }
                          }}
                        >
                          <StyledTableCell>
                            <span className="font-12 text--secondary">
                              {calenderDateTimeHelper(
                                item?.createdDate,
                                "MMM DD, YYYY"
                              )}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell>
                            <span className="font-12 text--secondary cursor--pointer" onClick={()=>{navigate(`/all-patients/${item?.userId}`);}}>
                              {firstName.concat(" ", lastName)}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell>
                            <span className="font-12 text--secondary">
                              {calenderDateTimeHelper(
                                item?.dateOfBirth,
                                "MMM DD, YYYY"
                              )}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell>
                            <span className="font-12 text--secondary">
                              {item?.gender}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell>
                            <span className="font-12 text--secondary">
                              {item?.smoker ? "Smoker" : "Non Smoker"}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell>
                            <span className="font-12 text--secondary">
                              {phoneNumber}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell>
                            <span className="font-12 text--secondary">
                              {item?.address[0]?.addressLine1}{" "}
                              {item?.address[0]?.addressLine2 !==
                                undefined &&
                              item?.address[0]?.addressLine2
                                ?.length > 0
                                ? item?.address[0]?.addressLine2 +
                                  " "
                                : ""}
                              {item?.address[0]?.city?.trim()}
                              {item?.address[0]?.city !==
                                undefined && ", "}
                              {item?.address[0]?.stateProvince}{" "}
                              {item?.address[0]?.postalCode}
                            </span>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })
                  )}
                  {/* </>} */}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div
            className={` mt-4 align-self-center col-sm-6 col-6 justify-content-center`}
          >
            {isPatientsDataLoading ? (
              <Skeleton />
            ) : (
              <div className={" d-flex flex-row justify-content-between"}>
                {pages.length > 1 ? (
                  <div className="ps-2 mt-2">
                    <PnToolTip title="First" placement="top">
                      <button
                        disabled={currentPage === 1}
                        onClick={(e) => {
                          handleFirstPage(e);
                        }}
                        className={`${classes["prev__btn"]} me-2 bg--fafcfc border--default br-10`}
                      >
                        {/* <img src={IconNext} alt="icon-previous"></img> */}
                        <FirstPageIcon style={{ color: "#ec609b" }} />
                      </button>
                    </PnToolTip>
                    <PnToolTip title="Previous" placement="top">
                      <button
                        disabled={currentPage === 1}
                        onClick={(e) => {
                          handlePreButton(e);
                        }}
                        className={`${classes["prev__btn"]} bg--fafcfc border--default br-10`}
                      >
                        <NavigateBeforeIcon style={{ color: "#ec609b" }} />
                        {/* <img src={IconNext} alt="icon-previous"></img> */}
                      </button>
                    </PnToolTip>
                  </div>
                ) : (
                  ""
                )}

                <RenderPages />

                {pages.length > 1 ? (
                  <div className="mt-2">
                    <PnToolTip title="Next" placement="top">
                      <button
                        disabled={currentPage === pages.length}
                        onClick={(e) => {
                          handleNextButton(e);
                        }}
                        className={`${classes["next__btn"]} me-2 bg--fafcfc border--default br-10`}
                      >
                        {/* <img src={IconNext} alt="icon-next"></img> */}
                        <NavigateNextIcon style={{ color: "#ec609b" }} />
                      </button>
                    </PnToolTip>
                    <PnToolTip title="Last" placement="top">
                      <button
                        disabled={currentPage === pages.length}
                        onClick={(e) => {
                          handleLastPage(e);
                        }}
                        className={`${classes["next__btn"]} bg--fafcfc border--default br-10`}
                      >
                        {/* <img src={IconNext} alt="icon-next"></img> */}
                        <LastPageIcon style={{ color: "#ec609b" }} />
                      </button>
                    </PnToolTip>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisteredPatientTable;

const PatientTableSearchFilters = (props) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [name, setName] = useState();
  const [minAge, setMinAge] = useState();
  const [maxAge, setMaxAge] = useState();
  const [zipCode, setZipCode] = useState();
  const [method, setMethod] = useState();
  const [ageError, setAgeError] = useState(""); // state for error message

  const handleStartDate = (dateVal) => {
    if (dateVal !== undefined) {
      setStartDate(dateVal);
      props?.setStartDate(dateVal);
    }
  };

  const handleEndDate = (dateVal) => {
    if (dateVal !== undefined) {
      setEndDate(dateVal);
      props?.setEndDate(dateVal);
    }
  };

  const handleChange = (e) => {
    let id = e.target.id;
    let value = e.target.value;

    if (id === "name") {
      setName(value);
      props?.setName(value);
    } else if (id === "minAge") {
      setMinAge(value);
      props?.setMinAge(value);

      // Ensure maxAge is not less than minAge when minAge changes
      if (maxAge && Number(value) > maxAge) {
        setAgeError("Min age cannot be greater than max age.");
      } else {
        setAgeError("");
      }
    } else if (id === "maxAge") {
      console.log("maxAge: ", value);
      setMaxAge(value);
      props?.setMaxAge(value);

      // Check if maxAge is less than minAge
      if (minAge && Number(value) < minAge) {
        setAgeError("Max age cannot be less than min age.");
      } else {
        setAgeError("");
      }
    } else if (id === "zipCode") {
      setZipCode(value);
      props?.setZipCode(value);
    } else if (id === "method") {
      setMethod(value);
      props?.setMethod(value);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    props?.fetchPatientsData(
      1,
      startDate,
      endDate,
      minAge,
      maxAge,
      name,
      zipCode,
      method
    );
  };

  const handleClearFilter = () => {
    // Clear all states
    setStartDate("");
    setEndDate("");
    setName("");
    setMinAge("");
    setMaxAge("");
    setZipCode("");
    setMethod("");
    setAgeError("");

    // Call parent component's methods to clear the filters if needed
    props?.setStartDate("");
    props?.setEndDate("");
    props?.setName("");
    props?.setMinAge("");
    props?.setMaxAge("");
    props?.setZipCode("");
    props?.setMethod("");

    props?.fetchPatientsData(1, "", "", "", "", "", "", "");
    props?.setCurrentItems([]);
  };

  useEffect(() => {
    setStartDate(props?.startDate);
    setEndDate(props?.endDate);
  }, [props?.startDate, props?.endDate]);

  return (
    <>
      <div className="row">
        <div className="col-2">
          <label className="text--terciary font-12  fw-sb">Start Date</label>
          <CalenderDatePicker
            f12={true}
            dobHandler={handleStartDate}
            dateFormat="MMM d, yyyy"
            date={startDate !== undefined ? startDate : ""}
            key={`startDate${startDate}`}
          />
        </div>

        <div className="col-2">
          <label className="text--terciary font-12   fw-sb">End Date</label>
          <CalenderDatePicker
            f12={true}
            dobHandler={handleEndDate}
            dateFormat="MMM d, yyyy"
            date={endDate !== undefined ? endDate : ""}
            key={`EndDate${endDate}`}
          />
        </div>

        <div className="col-2">
          <label
            htmlFor="name"
            className="form-label m-0 font-12 text--secondary fw-sb"
          >
            Name
          </label>
          <input
            type="text"
            onChange={(e) => handleChange(e)}
            className={`form-control font-14 m-0 border--default br-10 shadow-none input`}
            id="name"
            name="name"
            placeholder="Enter name"
            value={name}
          />
        </div>

        <div className="col-2">
          <label
            htmlFor="zipCode"
            className="form-label m-0 font-12 text--secondary fw-sb"
          >
            Zip Code
          </label>
          <input
            type="text"
            onChange={(e) => handleChange(e)}
            className={`form-control font-14 m-0 border--default br-10 shadow-none input`}
            id="zipCode"
            name="zipCode"
            placeholder="Enter zip code"
            value={zipCode}
          />
        </div>

        <div className="col-2">
          <label
            htmlFor="minAge"
            className="form-label m-0 font-12 text--secondary fw-sb"
          >
            Min. Age
          </label>
          <input
            type="number"
            onChange={(e) => handleChange(e)}
            className={`form-control font-14 m-0 border--default br-10 shadow-none input`}
            id="minAge"
            name="minAge"
            placeholder="Enter min age"
            value={minAge}
          />
        </div>

        <div className="col-2">
          <label
            htmlFor="maxAge"
            className="form-label m-0 font-12 text--secondary fw-sb"
          >
            Max. Age
          </label>
          <input
            type="number"
            onChange={(e) => handleChange(e)}
            className={`form-control font-14 m-0 border--default br-10 shadow-none input`}
            id="maxAge"
            name="maxAge"
            placeholder="Enter max age"
            value={maxAge}
          />
          {ageError && (
            <small className="text-danger font-12">{ageError}</small>
          )}
        </div>

        <div className="col-2">
          <label className="text--terciary font-12   fw-sb">
            Communication Method
          </label>
          <select
            className={`form-select br-10 border--default font-12 shadow-none`}
            aria-label="facility select"
            id="method"
            onChange={(e) => handleChange(e)}
            value={method}
          >
            <option value="0" selected key={"communication-method"}>
              {"Select Method"}
            </option>
            {["Email", "Sms"].map((item) => {
              return (
                <option value={item} key={item}>
                  {item.charAt(0).toLocaleUpperCase() + item.slice(1)}
                </option>
              );
            })}
          </select>
        </div>

        <div className="col-2 align-self-center mt-3">
          <label htmlFor="filter"> </label>
          <button
            onClick={(e) => {
              handleSearch(e);
            }}
            className="btn blue_task_button br-10 shadow-none"
            disabled={ageError?.length > 0 ? true : false}
          >
            {"Filter"}
          </button>
        </div>

        <div className="col-2 align-self-center mt-3">
          <label htmlFor="clearfilter">&nbsp;</label>
          <span
            id="clearfilter"
            onClick={handleClearFilter} // Add click event to clear the filters
            className="pn-link-button d-inline-block  text-decoration-underline fw-sb font-12 text--secondary cursor--pointer "
          >
            Clear Filter
          </span>
        </div>
      </div>
    </>
  );
};

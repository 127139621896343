import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import classes from "./NotificationAlert.module.css";
import CloseIcon from "@mui/icons-material/Close";
import sendHttpRequest from "../hooks/sendHttpRequest";
import {
  setDiagnosis,
  setDisableSendRxButton,
  setPatientEncounterData,
  setPrescriptionNoteText,
  setUpdateEncounterData,
  setVideoOpen,
  updatePrescribedMedication,
} from "../../redux/actions/providerActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useContext } from "react";
import { VideoCallContext } from "../UI/AgoraHooks/VideoCallContext";
import { appointmentNavigation } from "../../utils/helpers/HelperFunctions";

const NotificationAlert = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useAuth();
  const videoCallContext = useContext(VideoCallContext);
  const appointmentType = props?.notificationList?.at(0)?.messageType === 'APPT_ACTIONABLE' ? props.notificationList?.at(0).appointment?.appointmentType : undefined;

  const sendStartAppointmentRequest = async (notification) => {
    let appointmentId = notification?.id;
    videoCallContext.updateAppointmentData(appointmentId) //This is related to video context - dont change
    videoCallContext.updateAppointmentObject(notification);
    dispatch(setVideoOpen(true));

    let patientId = notification?.appointmentSlot?.patient?.userId;
    let prescriptionReferenceId = notification?.prescriptionReference?.prescriptionReferenceId;
    let providerId = notification?.appointmentSlot?.providerTenantPracticeLocation?.provider?.userId;


    const httpResponse = await sendHttpRequest({
      url: `/appointment/${appointmentId}/start`,
      method: "GET",
    });
    if (appointmentType?.key?.toLowerCase() === 'follow_up' || appointmentType === undefined) {
      navigate(`/all-patients/${patientId}/soap-note`, {
        state: {
          prescriptionReferenceId: prescriptionReferenceId,
          appointmentId: appointmentId,
          isResume: notification?.prescriptionReference?.soapNote !== undefined ? true:false
        }
      });
    } else {
      navigate(`/consult/${providerId}/${prescriptionReferenceId}/${patientId}`, {
        state: {
          prescriptionReferenceId: prescriptionReferenceId,
          appointmentId: appointmentId,
        }
      })
    }
    // navigate(appointmentNavigation(appointmentType?.key?.toLowerCase(),patientId,prescriptionReferenceId,providerId,{ prescriptionReferenceId: prescriptionReferenceId }))

    // setIsSessionUpdated(false);
  };
  const handleMessageReadStatus = async (threadId) => {
    const httpResponse = await sendHttpRequest({
      url: "/messages/mark-read",
      method: "PUT",
      params: {
        userId: user?.userId,
        threadId: threadId,
        tenantId: user?.tenantId
      },
    });
    if (!httpResponse.error) {
      console.log(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
  };

  const fetchClaimData = async (claimId) => {
    const httpResponse = await sendHttpRequest({
      url: `/claims/tenants/${user?.tenantId}/insurance-claims/${claimId}`,
      method: "GET",
    });
    if (!httpResponse.error) {
      console.log(httpResponse.data);
      navigate(`/claim-history/${claimId}`, { state: { data: httpResponse.data } })
    } else {
      console.log(httpResponse.error);
    }
  };
  const handleActions = () => {

    // handleMessageReadStatus(props?.notificationList[0]?.threadId);
    if (props?.notificationList[0]?.messageType === "APPT_ACTIONABLE") {
      console.log(props?.notificationList[0]?.appointment);
      handlePatientEncouterData(props?.notificationList[0]?.appointment);
    } else if (props?.notificationList[0]?.messageType === "NPPES_ACTIONABLE") {
      // navigate(props?.notificationList[0]?.route);
      // props?.setIDEMLink("/idverification/verify")
      props?.handleModal();
    }
    else if(props?.notificationList[0]?.messageType === "CLM_ACTIONABLE") {

      fetchClaimData(props?.notificationList[0]?.claimId);

      handleMessageReadStatus(props?.notificationList[0]?.threadId);
      // navigate(`/claim-history/${item?.claimId}`, { state: { data: item } })
      // navigate(props?.notificationList[0]?.route);
    }
  };

  const handlePatientEncouterData = async (notification) => {
    console.log("Patient Encounter Data===>", notification);

    // dispatch(setConsulationInProgress(true)) //move this to AgoraConfigurationPage
    dispatch(setPrescriptionNoteText(""));
    dispatch(setDisableSendRxButton(false));
    dispatch(updatePrescribedMedication([]));
    if (notification?.status !== "started") {
      await sendStartAppointmentRequest(notification);
    }
    else {
      videoCallContext.updateAppointmentData(notification?.id)
      videoCallContext.updateAppointmentObject(notification);
      if (appointmentType?.key?.toLowerCase() === 'follow_up' || appointmentType === undefined) {
        navigate(`/all-patients/${notification?.appointmentSlot?.patient?.userId}/soap-note`, {
          state: {
            prescriptionReferenceId: notification?.prescriptionReference?.prescriptionReferenceId,
            appointmentId: notification?.id,
          }
        });
      } else {
        navigate(`/consult/${notification?.appointmentSlot?.providerTenantPracticeLocation?.provider?.userId}/${notification?.prescriptionReference?.prescriptionReferenceId}/${notification?.appointmentSlot?.patient?.userId}`, {
          state: {
            prescriptionReferenceId: notification?.prescriptionReference?.prescriptionReferenceId,
            appointmentId: notification?.id,
          }
        })
      }
    }
    dispatch(setPatientEncounterData(notification));
    dispatch(
      setUpdateEncounterData({
        Diagnosis: {
          Note: "",
          Primary: [],
          Secondary: [],
        },
      })
    );
    dispatch(
      setDiagnosis({
        Note: "",
        Primary: [],
        Secondary: [],
      })
    );

  };

  const handleCloseNotifcation = async () => {
    await handleMessageReadStatus(props?.notificationList[0]?.threadId);
    props?.fecthNotifications();
  }

  return (
    <>
      <div
        className={`d-flex flex-row justify-content-between container-fluid  ${classes["notification__container"]}`}
      >
        <div className="d-flex flex-row ">
          <div className={`bg--white rounded-circle p-1 my-auto me-2 ms-3`}>
            <NotificationsNoneOutlinedIcon
              className={`${classes["alert__icon"]}`}
            />
          </div>
          <div className="font-13 text--white my-auto ms-2">
            {props?.notificationList[0]?.body}
          </div>
        </div>
        <div className="me-3 my-auto ">
          {/* ${props?.notificationList[0]?.messageType === "NON_ACTIONABLE" && "visibility--hidden"} */}
          {/* <Link
            to={{
              pathname: `/consult/${props?.notificationList[0]?.PrescriberId}/${props?.notificationList[0]?.EncounterId}/${props?.notificationList[0]?.PatientId}`,
            }}
          > */}
          <button
            className={`bg--primary text--white  border--primary text-nowrap px-3 py-2 font-14  visibility--hidden`}
            style={
              props?.notificationList[0]?.messageType === "NON_ACTIONABLE"
                ? { visibility: "hidden" }
                : { visibility: "visible" }
            }
            onClick={() => {
              handleActions();
            }}
          >
            {props?.notificationList[0]?.buttonText}
          </button>
          {/* </Link> */}
        </div>
        {props?.notificationList[0]?.messageType === "NON_ACTIONABLE" && (
          <div className={`ms-1 mt-1 ${classes["alert__close"]}`} >
            <span
              onClick={() => {
                handleCloseNotifcation();
              }}
            >
              {<CloseIcon />}
            </span>
          </div>
        )}
      </div>
      {props?.notificationList?.length > 1 && (
        <div
          className={`d-flex mx-auto  ${classes["notification__container__shadow"]}`}
        ></div>
      )}
    </>
  );
};

export default NotificationAlert;

import { Modal } from "react-bootstrap";
import classes from '../PrescriptionModal/NewPrescriptionModal.module.css';
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { setDirtyBitInStore, setUpdateEncounterData, updatePrescribedMedication } from "../../../../redux/actions/providerActions";
import { unsavedWorkHandler } from "../../../../utils/helpers/HelperFunctions";
const NewPrescriptionModal = (props) => {
  const dispatch = useDispatch();
  const updateEncounterData = useSelector(
    (state) => state.UpdateEncounter?.Encounter
  );
  const dirtyBitStatus = useSelector((state) => state.dirtyStatus.isDirty);


  return (
    <Modal
      show={props.show}
      onHide={() => {
        dispatch(
          setUpdateEncounterData({
            ...updateEncounterData,
            Pharmacy: {},
          })
        );
        if (props?.soapNoteKey !== true || props?.providerNote !== true) {
          dispatch(updatePrescribedMedication([]));
        }

        props.handleNewPrescriptionModal();
      }}

      contentClassName="modal-border modal-shadow h-100 bg--background"
      backdropClassName="modal-backdrop"
      dialogClassName={`${classes['modal__width']}`}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      scrollable
    >
      <Modal.Header className="modal-header">
        <span
          style={{
            lineHeight: "12px",
            width: "18px",
            fontSize: "8pt",
            color: "#336383",
            position: "absolute",
            top: 15,
            right: 15,
            cursor: "pointer",
          }}
          onClick={() => {
            unsavedWorkHandler(
              "modal",
              "There are unsaved changes. Do you wish to continue?",
              () => (dirtyBitStatus),
              props.handleNewPrescriptionModal,
              () => { dispatch(setDirtyBitInStore(false)) },
              props.handleNewPrescriptionModal
            );
            dispatch(
              setUpdateEncounterData({
                ...updateEncounterData,
                Pharmacy: {},
              })
            );
            if (props?.soapNoteKey !== true || props?.providerNote !== true) {
              dispatch(updatePrescribedMedication([]));
            }
            if(props?.soapNoteKey && props?.prescriptionData !== undefined){
              props?.setMedEditMode(p=>false);
              props?.setPrescriptionData(p=>props?.prescriptionDataCopy)
            }
            props.handleNewPrescriptionModal();
          }}
        >
          {<CloseIcon />}
        </span>
        <Modal.Title className="font-20 fw-sb text--terciary">
          New Prescription
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.prescriptionRequestModal}
      </Modal.Body>
      {/* {console.log("props?.soapNoteKey", props?.soapNoteKey)} */}
    </Modal>
  );
}

export default NewPrescriptionModal
import classes from "./SearchMessage.module.css";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { truncate } from "../../utils/helpers/HelperFunctions";
import { useAuth } from "../hooks/useAuth";
import { X } from "react-bootstrap-icons";

const SearchMessage = (props) => {
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const { user } = useAuth();
  const [searchQuery,setSearchQuery] = useState()

  const searchMessageRequest = async (msg) => {
    setIsLoading(true);
    setIsError(false);
    props?.setIsMsgLoading(true);
    props?.setIsStartPooling(p=>false);

    const httpResponse = await sendHttpRequest({
      url: "/messages",
      method: "GET",
      params: {
        userId: user?.userId,
        search: msg,
        tenantId: user?.tenantId,
        type:props?.type ?? "inbox"
      },
    });
    if (!httpResponse.error) {
      // setSuggestions(httpResponse.data);
      props?.setMessagesData(httpResponse.data)
      // console.log(httpResponse.data);
    } else {
      setSuggestions([httpResponse.data]);
      setIsError(true);
    }
    setIsLoading(false);
    props?.setIsMsgLoading(false);
  };

  const handleOnChange = (event) => {
    let inputValue = event.target.value;
    if (inputValue !== "" && event.key === "Enter") {
      searchMessageRequest(inputValue.trim());
    } else {
      setSuggestions([]);
      setIsLoading(false);
      setIsError(false);
    }
  };
  const handleSearchIconBtn = (event) => {
    event.preventDefault();
    // console.log("event.target.value", searchInputValue);
    handleOnChange(searchInputValue);
  };
  const handleMessageSearch = (msg) => {
    props?.setThreadId(msg?.threadId);
  };
  const handleClearSearch = ()=>{
    props?.setIsStartPooling(true);
    setSearchInputValue("");
    document.getElementById("search-message").value = ""
  }
  useEffect(()=>{
    if(searchInputValue?.length === 0){
      props?.setIsStartPooling(p=>true);
    }
  },[searchInputValue])
  return (
    <>
      <div
        className={`input-group  border--default  br-10 ${classes["message__search__container"]}`}
      >
        <input
          type="text"
          className={`form-control shadow-none border-0 ms-1 font-14 
                      ${classes["searchBar"]}
                      `}
          placeholder={"Search message"}
          onKeyDown={(event) => handleOnChange(event)}
          id = {"search-message"}
          onChange={(e)=>{setSearchInputValue(e.target.value)}}
          onBlur={() => {
            setTimeout(() => {
              setSuggestions([]);
              setIsError(false);
              setIsLoading(false);
            }, 5);
          }}
        />
        {
          searchInputValue?.length > 0 ? 
            <span style={{ alignSelf: "center" }} onClick={()=>{handleClearSearch()}} className="cursor--pointer">
              <X color="#336383"  size={24}/>
            </span>
          :null
        }
        
        <button
          className="btn shadow-none"
          type="submit"
            value={searchInputValue}
            // onClick={(e) => handleSearchIconBtn(e)}
            onBlur={() => {
              setTimeout(() => {
                setSuggestions([]);
                setIsError(false);
                setIsLoading(false);
              }, 5);
            }}
        >
          <SearchIcon style={{ color: "#336383" }} />
        </button>
      </div>

      {
        suggestions.length > 0 && (
          <>
            <div
              className={`${classes["suggestion__container"]} ${
                suggestions.length > 0 ? "overflowy-auto" : undefined
              }`}
            >
              <ul
                className={`list-group border--defualt cursor--pointer ${classes["suggestion_list"]}`}
                style={{}}
              >
                {isError ? (
                  <li className="list-group-item font-14">{"No result found"}</li>
                ) : isLoading ? (
                  <li className="list-group-item font-14">{"Loading..."}</li>
                ) : (
                  suggestions.length > 0 &&
                  suggestions.map((suggestion) => {
                    return (
                      <li className={`list-group-item font-14  ${classes["li"]}`}
                      onMouseDown={()=>handleMessageSearch(suggestion)}
                      >{truncate(suggestion?.body,50)}</li>
                    );
                  })
                )}
              </ul>
            </div>
          
          </>
        )
      }
    </>
  );
};

export default SearchMessage;

import sendHttpRequest from "../hooks/sendHttpRequest";

export default class InPatientService {

    static getFacilityRooms = async (userId) => {
        try {
            const response = await sendHttpRequest({
                method: "GET",
                url: `/provider/${userId}/providerTenantPracticeLocation/facility/facilityRooms`,
            });
            if (!response.error) {
                return response.data;
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    static getMedicalAssistants = async (tenantId) => {
        try {
            const response = await sendHttpRequest({
                method: "GET",
                url: `/provider/tenant/${tenantId}/role/MedicalAssistant`,
            });
            if (!response.error) {
                return response.data;
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    static checkIn = async (appointmentId, roomId, medicalAssistantId) => {
        try {
            const response = await sendHttpRequest({
                method: "POST",
                url: `/appointment/${appointmentId}/checkin`,
                data: {
                    "roomId": roomId,
                    "medicalAssistantId": medicalAssistantId
                }
            });
            if (!response.error) {
                return response;
            }
        }
        catch (err) {
            console.log(err);
        }
    }


}
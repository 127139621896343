import { useState } from "react";
import { Link } from "react-router-dom";
import TasksPage from "../pages/TasksPage";
import { RefreshDataComponent } from "../UI/General/RefreshDataComponent";
const PrescriptionRequestList = (props) => {
  const [prescriptionRequestReload, setPrescriptionReload] = useState(false);
  const [isPrescriptionRequestsLoading, setIsPrescriptionRequestsLoading] = useState(false);
  const handlePrescriptionReload = () => {
    setPrescriptionReload(previous => !previous);
  }

  return (
    <>
      <div
        className={`d-flex mb-1 justify-content-between align-items-baseline`}
      >
        <h2 className="fw-sb font-22">Prescription Requests</h2>
        <div>
          <RefreshDataComponent callback={handlePrescriptionReload} isAsyncCallback={false} isComponentDataLoading={isPrescriptionRequestsLoading} />

          <Link
            to={"/tasks"}
            className="fw-bold font-12 b2 text-decoration-none"
          >
            View all
          </Link>
        </div>
      </div>
      {/* Dynamically render here <PrescriptionRequestItem/> from the API */}
      <TasksPage tasksOnly={true} prescriptionRequestReload={prescriptionRequestReload} setIsPrescriptionRequestsLoading={setIsPrescriptionRequestsLoading} />
    </>
  );
};

export default PrescriptionRequestList;

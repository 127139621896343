import { ActionTypes } from "../constants/actionTypes"

const unreadMessageCount = {
    count:0
}

const setUnreadMessageCountReducer = (state=unreadMessageCount, action)=>{
    if (action.type === ActionTypes.SET_UNREAD_MESSAGE_COUNT ){
        return {
            ...state,
            count : action.payload
        }
    }
    return state
}


export default setUnreadMessageCountReducer;
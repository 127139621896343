import { ActionTypes } from "../constants/actionTypes"

const checkBoxInitialState = {
        isChecked:''
}

const setAllPhysicalSystemsStatusReducer = (state=checkBoxInitialState, action)=>{
    if (action.type === ActionTypes.SET_ALL_PHYSICAL_SYSTEMS_STATUS ){
        return {
            ...state,
            isChecked: action.payload
        }
    }
    return state
}


export default setAllPhysicalSystemsStatusReducer;
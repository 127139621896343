import { useState } from 'react';
import { ArrowClockwise, DashLg } from 'react-bootstrap-icons'
import styles from './RefreshDataComponent.module.css';


export const RefreshDataComponent = ({ callback, isAsyncCallback = true, isComponentDataLoading, classes, title = "Refresh", variant = "with-bar" }) => {

    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const performRefresh = async () => {
        if (isAsyncCallback === true) {
            setIsClicked(true);
            setIsDataLoading(true);
            await callback();
            setIsDataLoading(false);
            setIsClicked(false);
        }
        else {
            setIsClicked(true);
            callback();
        }
    }


    return (
        <>
            <button
                disabled={isDataLoading || isComponentDataLoading}
                onClick={performRefresh}
                className={`btn font-12 d-inline-block shadow-none ${styles['refresh-icon']} ${styles['refresh-icon-title']}  ${classes ?? ''} `}>
                <ArrowClockwise fontSize={16} className={`${(isDataLoading || isComponentDataLoading) && isClicked ? styles['rotate-icon'] : ''}`} />{title}
            </button>
            {variant === "with-bar" ? <div className={`d-inline-block`}><DashLg style={{ transform: 'rotate(90deg)' }} size={22} color="#70707070" /></div> : null}
        </>
    )
}

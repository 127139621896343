import { Typography } from '@mui/material';
import React from 'react'
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { formatPhoneNumber } from '../../../utils/helpers/HelperFunctions';
import sendHttpRequest from '../../hooks/sendHttpRequest';
import { useAuth } from '../../hooks/useAuth';
import useModal from '../../hooks/useModal';
import useSnackbar from '../../hooks/useSnackbar';
import CustomizedSnackbar from '../../UI/General/CustomizedSnackbars';
import PhoneInput from '../../UI/Reusable/PhoneNumber';
import PhoneNumberInput from '../../UI/Reusable/PhoneNumberInput';
import AccountCreatedModal from '../AccountCreatedModal';
import PatientRegistrationHeader from '../header/PatientRegistrationHeader';
import PatientRegistrationFooter from '../patient-registration-footer-login/PatientRegistrationFooter';

const PatientRegistrationEmailVerificationForm = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { tid } = useParams();
    // console.log(location);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const emailFormRef = useRef();
    const firstName = location?.state?.data?.name?.at(0)?.firstName;
    const lastName = location?.state?.data?.name?.at(0)?.lastName;
    const { patientRegistrationLogin } = useAuth();
    const [regexErrors, setRegexErrors] = useState(
        {

            'primary': false,

        }
    );

    const [
        accountCreatedSnackbarOpen,
        accountCreatedSnackbarMessage,
        accountCreatedSnackbarSeverity,
        setAccountCreatedSnackbarOpen,
        setAccountCreatedSnackbarMessage,
        setAccountCreatedSnackbarSeverity,
        handleAccountCreatedSnackbarClose,
    ] = useSnackbar(false);

    const {
        open: openAccountCreatedModal,
        handleOpen: handleOpenAccountCreatedModal,
        handleClose: handleCloseAccountCreatedModal,
        handleModal: handleAccountCreatedModal,
    } = useModal();
    // const [isValid, setIsValid] = useState(() => (true));

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };


    const handlePhoneNumberChange = (phoneValue) => {

        if (phoneValue !== undefined && isValidPhoneNumber(phoneValue?.toString()) && phoneValue?.length == 12) {
            // console.log("Valed Part");
            let mobileNumber = formatPhoneNumber(phoneValue?.replace("+1", ""));
            setRegexErrors((previousSnapshot) => ({
                ...previousSnapshot,
                primary: false,
            }));
            setPhoneNumber(mobileNumber);
        } else if (phoneValue !== undefined && !isValidPhoneNumber(phoneValue?.toString()) && phoneValue?.length > 2) {
            setRegexErrors((previousSnapshot) => ({
                ...previousSnapshot,
                primary: true,
            }));
            setPhoneNumber("");

        } else if (phoneValue?.length === 2 || phoneValue === undefined) {
            // dispatch(setDirtyBitInStore(true));
            setRegexErrors((previousSnapshot) => ({
                ...previousSnapshot,
                primary: false,
            }));
            setPhoneNumber("");

        }
    };

    async function handleSubmitData() {
        if (emailFormRef.current.reportValidity()) {
            const body = {
                ...location?.state?.data,
                username: email,
                password: password,
                communicationNumbers: {
                    electronicMail: [
                        email
                    ],
                    numbers: [
                        {
                            number: formatPhoneNumber(phoneNumber?.replace("+1", "")),
                            type: "PT"
                        }
                    ]
                },

            }
            // console.log(body);
            // return;
            const httpResponse = await sendHttpRequest({
                url: '/patients',
                method: 'POST',
                data: body
            });
            if (!httpResponse.error) {
                localStorage.setItem('tkn', httpResponse['responseHeaders']['authorization']);
                const patientInfoResponse = await sendHttpRequest({
                    method: 'GET',
                    url: '/auth/patient/loginInfo'
                });
                console.log(patientInfoResponse);
                patientRegistrationLogin({
                    loggedIn: true,
                    role: patientInfoResponse?.data?.role ?? "",
                    userId: patientInfoResponse?.data?.userId ?? "",
                    selectedUserId: patientInfoResponse?.data?.userId ?? "",
                    timezone: patientInfoResponse?.data?.timezoneOffset ?? "",
                    timezoneText: patientInfoResponse?.data?.timezone ?? "",
                    name: patientInfoResponse?.data?.name,
                    firstName: patientInfoResponse?.data?.name[0]?.firstName ?? "",
                    lastName: patientInfoResponse?.data?.name[0]?.lastName ?? "",
                    prefix: patientInfoResponse?.data?.name[0]?.prefix ?? "",
                    tenantId: patientInfoResponse?.data?.tenant?.tenantId,
                    tenant: patientInfoResponse?.data?.tenant
                });
                // handleOpenAccountCreatedModal();
                setAccountCreatedSnackbarMessage("Account created successfully");
                setAccountCreatedSnackbarSeverity("success");
                setAccountCreatedSnackbarOpen(true);
            }

        }
        else {
            //TODO: Show Erros
        }
    }


    function handleRedirection() {
        // handleCloseAccountCreatedModal();
        navigate(`/patient-registration/options/${tid}`);
        handleAccountCreatedSnackbarClose();
    }

    useEffect(() => {
        document.body.style.backgroundColor = '#D9E8EC';

        return () => {
            document.body.style.backgroundColor = '';
        };
    }, []);
    return (
        <div style={{ backgroundColor: '#D9E8EC', minHeight: '100%' }}>
            {accountCreatedSnackbarOpen ?
                <CustomizedSnackbar
                    message={accountCreatedSnackbarMessage}
                    severity={accountCreatedSnackbarSeverity}
                    open={accountCreatedSnackbarOpen}
                    handleClose={handleRedirection}
                />
                // <AccountCreatedModal show={openAccountCreatedModal}
                //     firstName={firstName}
                //     lastName={lastName}
                //     email={email}
                //     handleModal={handleRedirection}
                // /> 
                : null}
            <PatientRegistrationHeader />
            <div style={{ marginTop: '10%', paddingBottom: '2rem' }}>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-sm-7'>
                            <Typography display="block" variant="h3" sx={{ color: "#336383", fontWeight: 600, fontSize: '16px', fontFamily: "Montserrat" }} component="h1" gutterBottom>
                                {"Let's create an account."}
                            </Typography>
                            <Typography variant="subtitle2" sx={{ color: "#336383", fontSize: '12px', fontFamily: "Montserrat" }} component="h1" gutterBottom>
                                {"Already have an account? "}
                                {<u className='fw-sb cursor-pointer' onClick={(e) => { }}>Sign In</u>}
                            </Typography>

                            <form ref={emailFormRef} autoComplete="off">
                                <div className='form-group'>
                                    <label htmlFor='patient-reg-email' className='font-12' style={{ color: "#336383", fontFamily: "Montserrat" }}>Email <span>&#42;</span></label>
                                    <input
                                        type='email'
                                        className='form-control input font-14 br-10 border--default shadow-none'
                                        id='patient-reg-email'
                                        required
                                        name="patient-reg-email"
                                        autoComplete='off'
                                        placeholder='Type here'
                                        onChange={handleEmailChange}
                                    />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='cellPhone' className='font-12' style={{ color: "#336383", fontFamily: "Montserrat" }}>Phone Number <span>&#42;</span></label>
                                    <PhoneNumberInput
                                        // value={phoneNumber}
                                        name="CellPhone"
                                        onChange={handlePhoneNumberChange}
                                        id="CellPhone"
                                        value={phoneNumber}
                                        required={true}
                                        className="font-14"
                                    />
                                    <span className="text--danger py-1 font-10 d-block">
                                        {regexErrors?.primary === true
                                            ? "Incorrect number format"
                                            : null}
                                    </span>
                                    {/* {console.log(isValid)} */}
                                    {/* {isValid === false ? <span className='font-10 text-danger'>Invalid Phone Number</span> : null} */}
                                </div>

                                {/* <Divider sx={{ color: "#A2D1DE", width: "380px" }} /> */}
                                <hr />

                                <div className='form-group'>
                                    <label htmlFor='patient-reg-password' className='font-12' style={{ color: "#336383", fontFamily: "Montserrat" }}>Password <span>&#42;</span></label>
                                    <input
                                        type='password'
                                        className='form-control font-14 input br-10 border--default shadow-none'
                                        id='patient-reg-password'
                                        required
                                        name="patient-reg-password"
                                        placeholder='Type here'
                                        onChange={handlePasswordChange}
                                    />
                                </div>

                                <div className='form-group'>
                                    <label htmlFor='repeatPassword' className='font-12' style={{ color: "#336383", fontFamily: "Montserrat" }}>Repeat password <span>&#42;</span></label>
                                    <input
                                        type='password'
                                        required
                                        className='form-control font-14 input br-10 border--default shadow-none'
                                        id='repeatPassword'
                                        placeholder='Type here'
                                        onChange={handleConfirmPasswordChange}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <PatientRegistrationFooter onNextHandler={handleSubmitData} disabled={regexErrors?.primary === true ? true : false} />
        </div>
    )
}

export default PatientRegistrationEmailVerificationForm
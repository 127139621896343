import { X } from 'react-bootstrap-icons'

const Badge = ({ data, handleRemove, removeId }) => {
    return (
        <span className="badge font-14 fw-sb text--terciary text-wrap bg--d9e8ec  py-1 px-2 br-10">
            {data}
            <span
                className="cross-sign"
                onClick={() => handleRemove(removeId)}
            >
                <X
                    color="#336383"
                    style={{ marginBottom: "2px" }}
                    size={24}
                />
            </span>
        </span>
    )
}

export default Badge
const HomeIcon = (props) => {
  return (
    <svg
      id="Icon-home"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props} fill={props.style.color}
    >
      <path
        id="icon-home-2"
        data-name="icon-home"
        d="M19.267,7.566,12.947,1.24a4.172,4.172,0,0,0-5.893,0L.732,7.566A2.484,2.484,0,0,0,0,9.334V17.52a2.5,2.5,0,0,0,2.5,2.5h15a2.5,2.5,0,0,0,2.5-2.5V9.334A2.484,2.484,0,0,0,19.267,7.566ZM12.5,18.354h-5v-3.28a2.5,2.5,0,1,1,5,0Zm5.833-.834a.834.834,0,0,1-.833.834H14.167v-3.28a4.167,4.167,0,1,0-8.333,0v3.28H2.5a.834.834,0,0,1-.833-.834V9.334a.841.841,0,0,1,.244-.59L8.232,2.422a2.506,2.506,0,0,1,3.537,0l6.321,6.325a.841.841,0,0,1,.244.587Z"
        transform="translate(0 -0.021)"
        stroke=""
      />
    </svg>
  );
};

export default HomeIcon;

import { useCallback } from "react";
import { useState } from "react";
import { search } from "../hooks/sendHttpRequest";

export default function useConsentFormSearch() {
    const [consentFormSuggestions, setConsentFormSuggestions] = useState([]);
    const [isConsentFormLoading, setIsConsentFormLoading] = useState(false);
    const [isConsentFormError, setIsConsentFormError] = useState(false);
    const [consentSearchInputValue, setConsentSearchInputValue] = useState("");
    const [consentFormName, setConsentFormName] = useState("");
    const [selectedConsentForm, setSelectedConsentForm] = useState(undefined);

    const searchConsentForm = async (consentInput) => {
        setIsConsentFormLoading(true);
        const httpResponse = await search({
            url: `/appointment-consent-forms`,
            method: "GET",
            params: {
                search: consentInput,
            },
        });
        if (!httpResponse.error) {
            setIsConsentFormLoading(false);
            setConsentFormSuggestions(httpResponse.data);
            setIsConsentFormError(false);
        } else {
            if (httpResponse?.status === 400) {
                setIsConsentFormError(true);
                setIsConsentFormLoading(false);
            }
        }
    };

    const handleConsentFormSearchInput = useCallback(
        (consentInput) => {
            if (consentInput.length > 2) {
                // setDiagnosisName(icdInput)
                searchConsentForm(consentInput);
                setConsentSearchInputValue(consentInput);
            }
            setConsentFormName(consentInput);
            setConsentFormSuggestions([]);
            setIsConsentFormLoading(false);
        },
        [
            searchConsentForm,
            setConsentSearchInputValue,
            setConsentFormName,
            setConsentFormSuggestions,
            setIsConsentFormLoading,
        ]
    );

    const handleRemoveConsentForm = (removeId) => {
        setSelectedConsentForm(undefined);
        setConsentFormName("");
    };

    const handleAddConsentForm = (consentForm) => {
        setConsentFormName("");
        setSelectedConsentForm(consentForm);
        setConsentFormName("");
        setConsentSearchInputValue("");
    };

    return {
        consentFormSuggestions, setConsentFormSuggestions, isConsentFormLoading, setIsConsentFormLoading, isConsentFormError, setIsConsentFormError, consentSearchInputValue, setConsentSearchInputValue, consentFormName, setConsentFormName, selectedConsentForm, setSelectedConsentForm, searchConsentForm, handleConsentFormSearchInput, handleAddConsentForm, handleRemoveConsentForm
    }
}
const TrashIconComponent = (props) => {
  return (
    <svg
      id="Icon-Patients"
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 28.003 28"
      {...props}
      fill={props.style.color}
    >
      <g id="Trash" transform="translate(1 1)">
        <path
          id="Caminho_70"
          data-name="Caminho 70"
          d="M4.5,9h18"
          transform="translate(-4.5 -5)"
          fill="none"
          stroke={props.style.color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <path
          id="Caminho_71"
          data-name="Caminho 71"
          d="M21.5,7V21a2,2,0,0,1-2,2H9.5a2,2,0,0,1-2-2V7m3,0V5a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2V7"
          transform="translate(-5.5 -3)"
          fill="none"
          stroke={props.style.color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <path
          id="Caminho_72"
          data-name="Caminho 72"
          d="M15,16.5v6"
          transform="translate(-8 -7.5)"
          fill="none"
          stroke={props.style.color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <path
          id="Caminho_73"
          data-name="Caminho 73"
          d="M21,16.5v6"
          transform="translate(-10 -7.5)"
          fill="none"
          stroke={props.style.color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  );
};

export default TrashIconComponent;

import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import TextArea from "../forms/TextArea";
import ChiefComplaints from "../patient/ChiefComplaints";
import sendHttpRequest from "../hooks/sendHttpRequest";
import Divider from "@mui/material/Divider";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import { changeDateTimeFormat, dateTimeHelper } from '../../utils/dateTimeHelper';
import TimelineDot from "@mui/lab/TimelineDot";
import classes from "../prescription/prescription.module.css";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import { Empty } from 'antd';
import { setPatientEncounterData, setUpdateEncounterData } from "../../redux/actions/providerActions";
import { patientEncountersMetadataUrl } from "../../res/endpointurls";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTimelineDot-root": {
      filled: {
        "& .MuiTimelineDot-filled": {
          backgroundColor: "#323232",
        },
      },
    },
  },
}));

const PhysicalExam = (props) => {
  const updateEncounterData = useSelector(
    (state) => state.UpdateEncounter.Encounter
  );
  const patientEncounterInformation = useSelector(
    (state) => state.PatientEncounterData.appoinment
  );

  const dispatch = useDispatch();

  const { providerId, encounterId, patientId } = useParams();
  let peString = "",
    json = "";
  const uiclasses = useStyles();
  const [physicalExam, setPhysicalExam] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchPhysicalExam = async (patientId) => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: patientEncountersMetadataUrl(patientId, encounterId),
      method: "GET",
    });
    if (!httpResponse.error) {
      setPhysicalExam(httpResponse.data);

    } else {
      console.log(httpResponse.error);
    }
    setIsLoading(false);
  };


  useEffect(() => {
    fetchPhysicalExam(patientId);
  }, []);

  function getText(text) {
    props.setUnsavedChanges(true);
    dispatch(
      setUpdateEncounterData({ ...updateEncounterData, encounterId: encounterId, physicalExam: text })
    );
    dispatch(setPatientEncounterData({ ...patientEncounterInformation, encounter: { ...patientEncounterInformation?.encounter, physicalExam: text } }))
    peString = text;
    json = { physicalExam: peString };
    return json;
  }

  return (
    <>
      <div className="my-5">
        <h4 className="h18 my-3">Physical Exam</h4>
        <TextArea
          className="form-control input br-10 border--default shadow-none my-2"
          // placeholder="Write your note"
          id={"physicalExam"}
          rows={5}
          gettext={getText}
          defaultValue={patientEncounterInformation?.encounter?.physicalExam || props?.encounterApiData?.encounter?.physicalExam}
        />
        <div className="my-4">
          <Divider sx={{ color: "#336383" }} />
        </div>
        {isLoading ? (
          <Timeline><Skeleton animation="wave" variant="text" width={"100%"} height={200} /></Timeline>
        ) : (
          <Timeline>
            {physicalExam?.length > 0 ? physicalExam?.map((encounter, index) => {
              return (
                encounter?.physicalExam !== undefined && encounter?.physicalExam?.trim() !== '' ? <TimelineItem key={encounter + index}>
                  <TimelineSeparator>
                    <TimelineDot
                      style={{ backgroundColor: "var(--terciary)" }}
                    />
                    <TimelineConnector
                      style={{ backgroundColor: "var(--d9e8ec)" }}
                    />
                  </TimelineSeparator>
                  <TimelineContent>
                    <h4 className="mb-0 font-12">
                      {encounter?.encounterCreatedDate !== undefined && encounter?.encounterCreatedDate !== null && encounter?.encounterCreatedDate !== "" ? dateTimeHelper(encounter?.encounterCreatedDate, "MMM D, YYYY - LT") : ""}
                    </h4>
                    <p
                      className="mt-2 font-16"
                      style={{ color: "var(--secondary)" }}
                    >
                      {encounter?.physicalExam}
                    </p>
                  </TimelineContent>
                </TimelineItem> : null
              );
            }) : ""
            }
          </Timeline>
        )}
      </div>
      {/* {console.log("updateEncounterData",updateEncounterData)} */}
      {/* {console.log("Appointment Encounter", patientEncounterInformation)} */}
    </>
  );
};

export default PhysicalExam;

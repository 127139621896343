import { useSelector } from 'react-redux';
import classes from '../Header/PatientSearch.module.css';
const ProviderSearchItem = (props) => {
  const drawerState = useSelector((state) => state.DrawerState.open);

  return (
    <>
      <li className={`list-group-item font-14 ${classes.li}`} key={props.id}
        style={
          props?.inMessage ? {
            width: props?.showFullInfo ? '26vw' : '25.5vw',
            paddingTop: props?.showFullInfo ? '8px' : '12px',
            paddingBottom: props?.showFullInfo ? '8px' : '12px',
            paddingLeft: '8px',
            display: 'flex',
            fontFamily: 'Montserrat',
            fontSize: '14px',
            border: 0,
            backgroundColor: '#ffffff',
            zIndex: '99999'
          } : {

            minWidth: drawerState ? '52rem' : '59rem',
            paddingTop: '12px',
            paddingBottom: '12px',
            paddingLeft: '8px',
            display: 'block',
            fontFamily: 'Montserrat',
            fontSize: '14px',
            border: 0,
            backgroundColor: '#ffffff',
            zIndex: '99999'
            // borderBottom:'1px solid #d9e8ec'
          }
        }
        onMouseDown={() => props?.OnMouseDown(props?.Provider)}
      >
        {props?.ProviderName}
      </li>
    </>
  )
}

export default ProviderSearchItem
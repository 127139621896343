import React, { useEffect, useRef, useState } from "react";
import PropType from "prop-types";
// import {X} from 'react-bootstrap-icons';
import { Trash } from "react-bootstrap-icons";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { Skeleton, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import { fieldValidation } from "../../utils/helpers/HelperFunctions";
import { RolesTextConversion } from "../../utils/helpers/RolesMap";
const CareTeamItem = ({ name, title, deleteHandler }) => {
  return (
    <>
      <div className="row bg-white py-3 align-items-center justify-content-between border-top border-top-color">
        <div className="col-sm-4  text-start">
          <span className="fw-sb font-16  text--terciary">{name}</span>
        </div>

        <div className="col-sm-4  align-self-center  text-start">
          <span className=" font-14 text--secondary">{title}</span>
        </div>

        <div className="col-sm-2 text-end">
          <span className="cross-sign" onClick={deleteHandler}>
            <Trash color="#336383" style={{ marginBottom: "2px" }} size={18} />
          </span>
        </div>
      </div>
    </>
  );
};

const CustomSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-switchBase": {
    "&.Mui-checked": {
      "& + .MuiSwitch-track": {
        backgroundColor: "#004FD4",
        opacity: 1,
      },
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: 11 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
  },
  "& .MuiSwitch-thumb": {
    borderRadius: 11 / 2,
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
    color: "#fff",
  },
}));

const CareTeamChip = ({
  name,
  value,
  selectedUserTypeMap,
  handleClickItem,
}) => {
  return (
    <div data-key={value} onClick={handleClickItem}>
      <span
        data-key={value}
        className={`badge care-item font-14 fw-sb py-1 px-2 br-10 ${
          selectedUserTypeMap.get("selected") === value
            ? "text-white bg--darkSecondary"
            : "text--terciary bg--a2d1de"
        }`}
      >
        {name + "s"}
      </span>
    </div>
  );
};

const CheckBoxUserItem = ({
  pid,
  firstName,
  lastName,
  role,
  onCheckHandler,
  defaultCheckedItem,
  firstChild
}) => {
  return (
    
      <div className={`row ${!firstChild ? 'border-top border-top-color' : ''} py-2 align-items-center`}>
        <div className="col-sm-4 align-self-center">
          <div className="form-check ms-1 mt-1">
            <input
              className="form-check-input pn-checkbox shadow-none"
              type="checkbox"
              defaultChecked={defaultCheckedItem}
              data-id={pid}
              onChange={onCheckHandler}
              id={firstName.concat(lastName)}
            />
            <label
              className="form-check-label fw-sb font-14"
              htmlFor={firstName.concat(lastName)}
            >
              {firstName.concat(" ", lastName)}
            </label>
          </div>
        </div>
        <div className="col-sm-4 align-self-center">
          <span className="font-12 d-block mt-1 text--secondary ">{RolesTextConversion(role)}</span>
        </div>
      </div>
    
  );
};



const CareTeam = ({
  addToCareTeam,
  removeFromCareTeam,
  filterRole = "all",
  setFilterRole,
  careTeam,
  user, 
  setUser,
  submitHandler, 
  isSending

}) => {
  //States Setup
  // console.log("USER=?",user?.NonVeterinarian?.CareTeam?.includes('1235'));
  const [roles, setRoles] = useState([]);
  const [practiceUsers, setPracticeUsers] = useState([]);
  const [practiceUserStatus, setPracticeUserStatus] = useState("");
  const [isPracticeUsersLoading, setIsPracticeUsersLoading] = useState(false);
  const [selectedUserTypeMap, setSelectedUserTypeMap] = useState(
    new Map([["selected", "all"]])
  );
  const [showCareMembersOnly, setShowCareMembersOnly] = useState(false);

  //State Management Handlers
  const handleCareUser = (event) => {
    const id = event.target.getAttribute("data-id");
    if (event.target.checked) {
      addToCareTeam(id);
    } else {
      removeFromCareTeam(id);
    }
  };



  const findMember = (id) => {
    const obj = practiceUsers.find((o) => o.id === id);
    return obj;
  };

  const handleClickItem = (event) => {
    setSelectedUserTypeMap(
      new Map([["selected", event.target.getAttribute("data-key")]])
    );
    fetchPrescribers(event.target.getAttribute("data-key"));
  };

  const fetchRoles = async () => {
    const httpResponse = await sendHttpRequest({
      url: "/prescriber/roles",
      method: "GET",
    });
    if (!httpResponse.error) {
      const filteredRoles = _.reject(httpResponse.data, function (el) {
        return el.value === "PracticeAdmin";
      });
      filteredRoles.unshift({ key: "All user", value: "all" });
      setRoles(filteredRoles);
    } else {
      console.log(httpResponse.error);
    }
  };

  const fetchPrescribers = async (filterRole) => {
    setIsPracticeUsersLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/prescriber/practiceUsers/list/",
      method: "GET",
      params: {
        role: filterRole,
      },
    });
    if (!httpResponse.error) {
      // console.log("PU=>",httpResponse.data)
      setPracticeUsers(httpResponse.data);
    } else {
     
    }
    setPracticeUserStatus((prev) => {
      return httpResponse.status;
    });
    setIsPracticeUsersLoading(false);
  };

  const filterUsers = (event) => {
    setFilterRole((prev) => {
      return event.target.value;
    });
    fetchPrescribers(event.target.value);
    // console.log(event.target.value);
  };

  const showCareTeamMembers = (event)=>{
    setShowCareMembersOnly(event.target.checked)
    if(event.target.checked === true){
      setPracticeUsers((prev)=>{
        const unFiltered = [...prev];
        const filtered = unFiltered.filter((o)=>{return user?.NonVeterinarian?.CareTeam?.includes(o?.id)})
        return filtered;
      })
    }
    else{
      fetchPrescribers(filterRole || "all");
    }
  }

  useEffect(() => {
    fetchRoles();
    if (filterRole !== "") {
      fetchPrescribers(filterRole);
    }
    return () => {
      setRoles(null);
      setPracticeUsers(null);
    };
  }, []);

  useEffect(() => {}, [selectedUserTypeMap]);

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-sm-9">
          <h2 className="font-16 fw-sb mt-3">Add Care Team Members</h2>
          <div className="d-flex mt-2 flex-row flex-wrap gap-2">
            {roles?.length > 0
              ? roles?.map((item, index) => {
                  return (
                    <CareTeamChip
                      name={item?.key}
                      value={item?.value}
                      handleClickItem={handleClickItem}
                      selectedUserTypeMap={selectedUserTypeMap}
                    />
                  );
                })
              : ""}
          </div>
          <div className="row mt-2 justify-content-start">
            <div className="col-sm-1">
                <CustomSwitch  checked={showCareMembersOnly} onChange={showCareTeamMembers}/>
            </div>
            <div className="col-sm-5 align-self-center">
              <span className="fw-sb font-12">Show Care Team Members only</span>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-sm-9">
          <div className="row align-items-center justify-content-between">
            <h2 className="font-16 fw-sb mt-3">Care Team Members</h2>
            <div id="d" className="col-sm-12 border border-default-color border-container  text-start">
              <div className="row border-bottom border-bottom-color py-2 align-items-center">
                <div className="col-sm-4">
                  <span className="font-12 ms-1 text--secondary fw-thin">Name</span>
                </div>
                <div className="col-sm-4">
                <span className="font-12  text--secondary fw-thin">Role</span>
                </div>
              </div>
              {isPracticeUsersLoading ? (
                <Skeleton animation="wave" />
              ) : practiceUserStatus === 200 ? (
                practiceUsers?.length > 0 &&
                practiceUsers?.map((item, index) => {
                  const defaultChecked = user?.NonVeterinarian?.CareTeam?.length > 0 ? user?.NonVeterinarian?.CareTeam?.includes(item?.id) == true ? true : false : false;
                  return (
                    <CheckBoxUserItem
                      firstChild={index === 0 ? true : false}
                      key={item?.id}
                      pid={item?.id}
                      firstName={item?.FirstName}
                      lastName={item?.LastName}
                      role={item?.Role}
                      onCheckHandler={handleCareUser}
                      defaultCheckedItem={defaultChecked}
                    />
                  );
                })
              ) : practiceUserStatus === 404 ? (
                <span className="fw-sb ms-2 font-14 text--danger">
                  No users found
                </span>
              ) : null}
            </div>
          </div>

          <div className="row mt-3 justify-content-end">
				<div className={`col-sm-3`}>
                    
					<button
						onClick={submitHandler}
						className={`btn bg--blue fw-sb font-14 text-white py-2 px-5 br-10 shadow-none ${
							isSending ? "disabled" : ""
						}`}
					>
						{isSending ? "Adding..." : "Add User"}
					</button> 
				</div>
			</div>
        </div>
      </div>
    </div>
  );
};

CheckBoxUserItem.propType = {
  pid: PropType.isRequired,
  firstName: PropType.string.isRequired,
  lastName: PropType.string.isRequired,
  role: PropType.string.isRequired,
  onCheckHandler: PropType.func,
};

CareTeamItem.propType = {
  name: PropType.string.isRequired,
  title: PropType.string.isRequired,
  deleteHandler: PropType.func.isRequired,
};

export default CareTeam;

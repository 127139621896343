const AllFormsSubmitted = (props) => {

    return (
        <div className="container">
            <div className="d-flex justify-content-center mb-3 fw-sb font-20">
                You may close this window
            </div>
        </div>
    )
}

export default AllFormsSubmitted;
const EncounterIcon = (props) => {
  return (
    <svg
      id="icon-encounter"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20.003"
      viewBox="0 0 20 20.003"
      {...props}
      fill={props.style.color}
    >
      <path
        id="icon-encounter-2"
        data-name="icon-encounter"
        d="M11.562,0a5.8,5.8,0,0,1,.807.265A2.777,2.777,0,0,1,14.007,2.89c.013.944,0,1.888,0,2.832v.266h.235c.95,0,1.9-.006,2.851,0a2.829,2.829,0,0,1,2.841,2.235A2.422,2.422,0,0,1,20,8.746Q20,10,20,11.246a2.812,2.812,0,0,1-2.541,2.742c-.2.016-.4.025-.6.026q-1.767,0-3.534,0a.812.812,0,0,1-.854-.574.79.79,0,0,1,.8-1.007c1.263,0,2.525,0,3.788,0a1.779,1.779,0,0,0,.554-.077,1.218,1.218,0,0,0,.825-1.164c.01-.788.012-1.576,0-2.363a1.237,1.237,0,0,0-1.273-1.251c-1.276-.007-2.552,0-3.827,0a.815.815,0,0,1-.905-.905c0-1.25-.019-2.5.007-3.75A1.3,1.3,0,0,0,11.078,1.56c-.722.013-1.445.011-2.167,0A1.3,1.3,0,0,0,7.564,2.907c.025,1.249.007,2.5.007,3.75a.819.819,0,0,1-.916.914c-1.237,0-2.474.021-3.71-.007A1.31,1.31,0,0,0,1.558,8.952c.019.7.018,1.406,0,2.109a1.307,1.307,0,0,0,1.377,1.377c1.243-.027,2.486-.007,3.729-.007a.815.815,0,0,1,.905.905c0,1.25.019,2.5-.007,3.75a1.3,1.3,0,0,0,1.358,1.356c.722-.012,1.445,0,2.167,0a1.256,1.256,0,0,0,1.34-1.266.792.792,0,1,1,1.578.092,2.833,2.833,0,0,1-2.489,2.723,1.7,1.7,0,0,1-.253.013c-.84,0-1.679,0-2.519,0a2.776,2.776,0,0,1-2.722-2.532C5.965,16.41,6,15.348,5.988,14.287c0-.083,0-.166,0-.273H5.74c-.944,0-1.888.005-2.831,0A2.846,2.846,0,0,1,.1,11.935c-.035-.123-.065-.248-.1-.372V8.438c.032-.124.063-.248.1-.372A2.843,2.843,0,0,1,2.907,5.989c.943-.006,1.887,0,2.83,0h.251v-.25c0-.957,0-1.913,0-2.87A2.837,2.837,0,0,1,8.065.1c.123-.035.248-.065.372-.1Z"
      />
    </svg>
  );
};

export default EncounterIcon;

import { ActionTypes } from "../constants/actionTypes"

const drawerInitialState = {
    
        open:true
    

}

const setDrawerStateReducer = (state=drawerInitialState, action)=>{
    if (action.type === ActionTypes.SET_DRAWER_OPEN ){
        return {
            ...state,
            open: action.payload
            
        }
    }
    return state
}


export default setDrawerStateReducer;
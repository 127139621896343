import { Box, Typography } from "@mui/material";
import GooglePayButton from "./GooglePayButton";
import { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import options from "../../../utils/data";
import CCIcon from "../../../assets/icons/CCIcon";
import googleLogo from "../../../assets/logo/google-logo.png";
const PatientGooglePayForm = (props) => {
  const [selectedState, setSelectedState] = useState();

  const addressHandler = () => {};
  const cityHandler = () => {};
  const zipcodeHandler = () => {};
  return (
    <>
      <div className="row">
        <div className="col-12 d-flex justify-content-between mb-3">
          <label className="  fw-sb font-16 text--secondary">{"Google Pay"}</label>
          <img src={googleLogo} style={{ height: "2em", width: "2em" }} />
        </div>
        <div className="col-md-12 mb-2">
          <Typography component="span">
            <Box
              sx={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "12px",
                color: "#336383",
              }}
            >
              Billing Address
            </Box>
          </Typography>
        </div>
        <div className="col-md-12">
          <label
            htmlFor="address"
            className="form-label m-0 font-10 text--secondary fw-sb"
          >
            Address
          </label>
          <input
            type="text"
            className={`form-control text--terciary refill_input font-12 m-0 border--default br-10 shadow-none input`}
            id="address"
            onChange={addressHandler}
          />
        </div>
        <div className="col-md-4 mb-2">
          <label
            htmlFor="city"
            className="form-label m-0 font-10 text--secondary fw-sb"
          >
            City
          </label>
          <input
            type="text"
            className={`form-control text--terciary refill_input font-12 m-0 border--default br-10 shadow-none input`}
            id="city"
            onChange={cityHandler}
          />
        </div>
        <div className="col-md-4">
          <div className="col-md-12 mb-2">
            <label
              htmlFor="state"
              className="form-label m-0 font-10 text--secondary fw-sb"
            >
              State
            </label>
            <Typeahead
              id="state"
              labelKey="code"
              onChange={setSelectedState}
              options={options}
              className="rbt-menu my-select"
              style={{
                borderRadius: "10px",
                fontSize: 12,
              }}
              autoComplete="false"
              onInvalid={(e) => {
                e.target.setCustomValidity("Please fill out this field");
              }}
              onInput={(e) => {
                e.target.setCustomValidity("");
              }}
              inputProps={{
                required: true,
                className: "font-12",
              }}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="col-md-12 mb-2">
            <label
              htmlFor="ZipCode"
              className="form-label m-0 font-10 text--secondary fw-sb"
            >
              ZipCode
            </label>
            <input
              type="text"
              className={`form-control text--terciary refill_input font-12 m-0 border--default br-10 shadow-none input`}
              id="zipcode"
              autoComplete="false"
              minLength="5"
              maxLength="5"
              onChange={zipcodeHandler}
            />
          </div>
        </div>
        </div>
        <GooglePayButton
          patientPortal={true}
          billingProvider={props.billingProvider}
          appointmentId={props.appointmentId}
          amountValue={props.amountValue}
          setIsPaymentSent={props.setIsPaymentSent}
          timeExpired={props.timeExpired}
          appointmentDto={props.appointmentDto}
          isValidAmount={props.isValidAmount ?? true}
        />
    </>
  );
};

export default PatientGooglePayForm;

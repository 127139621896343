import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import SignatureCanvas from "react-signature-canvas";
import { unsavedWorkHandler } from "../../../../utils/helpers/HelperFunctions";
import { useUnsavedWorkPrompt } from "../../../hooks/useUnsavedWorkPrompt";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles({
  root: {
    width: "50vw",
  },
});

const SignatureUploadModal = (props) => {
  const [isDirty, onDirty, onPristine] = useUnsavedWorkPrompt();
  const classesStyle = useStyle();
  const [sigPad, setSigPad] = useState();
  const b64toImage = () => {
    props?.setTrimmedDataURL((prevState) => {
      return sigPad.getTrimmedCanvas().toDataURL("image/png");
    });
    props?.handleSignaturePadClose();
    props?.uploadFile({}, sigPad.getTrimmedCanvas().toDataURL("image/png"));
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          unsavedWorkHandler(
            "modal",
            "There are unsaved changes. Do you wish to continue?",
            isDirty,
            props?.handleSignaturePadClose,
            onPristine,
            props?.handleSignaturePadClose
          );
          props.handleSignaturePadClose();
        }}
        size="lg"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName={classesStyle.root}
        backdropClassName="modal-backdrop"
        contentClassName="modal-border modal-shadow"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header className="modal-header">
          <span
            style={{
              lineHeight: "12px",
              width: "18px",
              fontSize: "8pt",
              color: "#336383",
              position: "absolute",
              top: 15,
              right: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              unsavedWorkHandler(
                "modal",
                "There are unsaved changes. Do you wish to continue?",
                isDirty,
                props?.handleSignaturePadClose,
                onPristine,
                props?.handleSignaturePadClose
              );
            }}
          >
            {<CloseIcon />}
          </span>
          <Modal.Title className="font-18 fw-sb text--terciary">
            Signature Pad
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-12 border--default br-10">
                <SignatureCanvas
                  penColor="green"
                  ref={(ref) => {
                    setSigPad(ref);
                  }}
                  onBegin={() => {
                    onDirty();
                  }}
                  canvasProps={{
                    width: 685,
                    height: 180,
                    className: "sigCanvas",
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer style={{ border: "none" }}>
          <button
            type="submit"
            form="fileUploadForm"
            className="btn bg--blue blue_task_button text-white b2 px-4 br-10 py-1"
            disabled={props?.isUploading === true ? true : false}
            onClick={b64toImage}
          >
            {props?.isUploading === true ? "Saving..." : "Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SignatureUploadModal;

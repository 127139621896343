import { ActionTypes } from "../constants/actionTypes"

const forceShutdownInitialState = {
        isforceShutdown:false
}

const setForceShutdownReducer = (state=forceShutdownInitialState, action)=>{
    if (action.type === ActionTypes.SET_FORCE_SHUTDOWN ){
        return {
            ...state,
            isforceShutdown: action.payload
            
        }
    }
    return state;
}


export default setForceShutdownReducer;
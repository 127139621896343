import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { unique, unsavedWorkHandler } from "../../utils/helpers/HelperFunctions";
import { useUnsavedWorkPrompt } from "../hooks/useUnsavedWorkPrompt";
import classes from "./DiagnosisModal.module.css";
import { search } from "../hooks/sendHttpRequest";
import { useCallback } from "react";
import CustomDatePicker from "../UI/General/CustomDatePicker";
import { convertUTCtoTimzone } from "../../utils/dateTimeHelper";
import Badge from "../UI/Reusable/Badge";
import ProviderNoteService from "../Services/ProviderNote.service";
import CalenderDatePicker from "../UI/General/CalenderDatePicker";
import { DateTime } from "luxon";
import { useRef } from "react";
import { useAuth } from "../hooks/useAuth";
import { errorStrings } from "../../res/errorStrings";

const DiagnosisModal = (props) => {

  const [isDirty, onDirty, onPristine] = useUnsavedWorkPrompt();
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [diagnosisAddLoading, setDiagnosisAddLoading] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [diagnosisName, setDiagnosisName] = useState("");
  const [selectedDiagnosis, setSelectedDiagnosis] = useState(undefined);
  const [primaryDiagnosis, setPrimaryDiagnosis] = useState(false);
  const [status, setStatus] = useState(false);
  const { user } = useAuth();
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const joined = convertUTCtoTimzone(date, "", "YYYY-MM-DD");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const dateRefS = useRef();
  const dateRefE = useRef();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const __str = errorStrings.en;


  const handleStartDate = useCallback((dateValue) => setStartDate(dateValue), []);
  const handleEndDate = useCallback((dateValue) => setEndDate(dateValue), []);

  // console.log(startDate);
  // console.log(endDate);

  const searchDiagnosis = async (diagnosisInput) => {
    setIsLoading(true);
    const httpResponse = await search({
      url: `/diagnosis`,
      method: "GET",
      params: {
        search: diagnosisInput,
      },
    });
    if (!httpResponse.error) {
      setIsLoading(false);
      setSuggestions(httpResponse.data);
      setIsError(false);
    } else {
      if (httpResponse?.status === 400) {
        setIsError(true);
        setIsLoading(false);
      }
    }
  };

  const handleSearchInput = useCallback(
    (icdInput) => {
      if (icdInput.length > 2) {
        // setDiagnosisName(icdInput)
        searchDiagnosis(icdInput);
        setSearchInputValue(icdInput);
      }
      setDiagnosisName(icdInput);
      setSuggestions([]);
      setIsLoading(false);
    },
    [
      searchDiagnosis,
      setSearchInputValue,
      setDiagnosisName,
      setSuggestions,
      setIsLoading,
    ]
  );

  const handleRemoveDiagnosis = (removeId) => {
    setSelectedDiagnosis(undefined);
    setDiagnosisName("");
    setShowError(false);
    setErrorMessage(undefined);
  };

  const handleAddDiagnosis = (diagnosis) => {
    props?.currentActiveDiagnosis?.forEach((item) => {
      if (item?.code === diagnosis?.code) {
        setShowError(true);
        setErrorMessage(__str.diagnosisAlreadyExists);
        return;
      }
    });
    setDiagnosisName("");
    setSelectedDiagnosis(diagnosis);
  };

  const clearComponentStates = () => {
    setDiagnosisName("");
    setSelectedDiagnosis(undefined);
    setPrimaryDiagnosis(false);
    setStatus(false);
    setStartDate(null);
    setEndDate(null);
    dateRefS.current.removeDateHandle();
    dateRefE.current.removeDateHandle();
    // document
    //   .querySelectorAll(".react-datepicker__close-icon")
    //   .forEach((i) => i.click());
  };


  const handleSaveDiagnosis = (e, isMore) => {
    setDiagnosisAddLoading(true);
    // let result  = props?.diagnosesData.filter((item)=>{return item.code === selectedDiagnosis?.code})
    let type = "";
    if (primaryDiagnosis === true) {
      type = "P";
    } else {
      type = "S";
    }
    if (props?.soapNoteKey === true) {
      let modifiedArray = getDiagnosesListWithSinglePrimaryDiagnosis(selectedDiagnosis, type, startDate, endDate, status, props);

      props?.setDiagnosesData(p => unique(modifiedArray, ["code"]));

      clearComponentStates();
      if (!isMore) {
        props?.handleModal();
      }
      setDiagnosisAddLoading(false);
    } else {
      let isFromAssessmentPlan = props?.assessmentPlan ? true : false;
      ProviderNoteService.saveDiagnosis(
        props.prescriptionReference,
        selectedDiagnosis?.code,
        type,
        selectedDiagnosis?.description,
        startDate,
        endDate,
        "",
        status,
        isFromAssessmentPlan
      ).then((res) => {
        clearComponentStates();
        props.setDiagnosisAddedMessage("Diagnosis added successfully");
        props.setDiagnosisSeverity("success");
        props.setOpenDiagnosisSnackBar(true);
        if (props?.setCallApi !== undefined && props?.setCallApi !== null) {
          console.log("Calling set call api");
          props?.setCallApi((p) => !p);
        }
        if (e.target.id === "SaveButton") {
          props.handleModal();
        }
        setDiagnosisAddLoading(false);
      });
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={() => {
        unsavedWorkHandler(
          "modal",
          "There are unsaved changes, do you wish to continue?",
          isDirty,
          props.handleModal,
          onPristine,
          props.handleModal
        );
        // props.handleModal();
      }}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      backdropClassName="modal-backdrop"
      contentClassName={`modal-border modal-shadow `}
      scrollable={false}
      centered
    >
      <Modal.Header>
        <span
          style={{
            lineHeight: "12px",
            width: "18px",
            fontSize: "8pt",
            color: "#336383",
            position: "absolute",
            top: 15,
            right: 15,
            cursor: "pointer",
          }}
          onClick={() => {
            unsavedWorkHandler(
              "modal",
              "There are unsaved changes, do you wish to continue?",
              isDirty,
              props.handleModal,
              onPristine,
              props.handleModal
            );
            // props.handleModal();
          }}
        >
          {<CloseIcon />}
        </span>
        <Modal.Title className="font-20 fw-sb">Add Diagnosis</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div>
                <div
                  className="input-group"
                  style={{
                    border: "1px solid #D9E8EC",
                    borderRadius: "10px",
                  }}
                >
                  <input
                    type="text"
                    className="form-control br-10 shadow-none border-0 font-12 "
                    placeholder="Search diagnosis"
                    value={diagnosisName}
                    onChange={(e) => {
                      handleSearchInput(e.target.value);
                    }}

                  />

                  <button
                    className="btn bg-white  br-10 shadow-none zindex-1"
                    type="submits"
                    value={searchInputValue}
                    onClick={() => handleSearchInput(searchInputValue)}
                  // onBlur={() => {
                  //   setTimeout(() => {
                  //     setSuggestions([]);
                  //     setIsError(false);
                  //     setIsLoading(false);
                  //   }, 5);
                  // }}
                  >
                    <SearchIcon className="text--secondary" />
                  </button>
                </div>

                <ul
                  className={`list-group ${classes["diagnosis__suggestions"]}`}
                  onBlur={(e) => {
                    setTimeout(() => {
                      setSuggestions([]);
                      setIsError(false);
                      setIsLoading(false);
                    }, 10);
                  }}
                >
                  {isError ? (
                    <li
                      className={`list-group-item ${classes["search__list__item"]}`}
                    >
                      No result found
                    </li>
                  ) : isLoading ? (
                    <li
                      className={`list-group-item ${classes["search__list__item"]}`}
                    >
                      Loading...
                    </li>
                  ) : (
                    suggestions.length > 0 &&
                    suggestions.map((suggestion, index) => {
                      return (
                        <li
                          tabIndex={index}
                          // className="list-group-item"
                          className={`border--default ${classes.li} ${classes["search__list__item"]}`}
                          key={suggestion.code}
                          onMouseDown={() => {
                            handleAddDiagnosis(suggestion); setTimeout(() => {
                              setSuggestions([]);
                              setIsError(false);
                              setIsLoading(false);
                            }, 10);
                          }}
                        // onKeyDown = {()=>console.log("works")}
                        >
                          <span>{suggestion.code} - </span>
                          <span className="ms-1">{suggestion.description}</span>
                        </li>
                      );
                    })
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className="row py-1">
            <div className="col-12">
              {/* {selectedDiagnosis !== undefined && selectedDiagnosis?.code !== undefined && selectedDiagnosis.description !== undefined ? <p className='font-14'><span className='fw-sb'>Selected Diagnosis: </span>{`${selectedDiagnosis.code} - ${selectedDiagnosis.description}`}</p> : null} */}
              {selectedDiagnosis !== undefined &&
                selectedDiagnosis?.code !== undefined &&
                selectedDiagnosis.description !== undefined ? (
                <Badge
                  data={`${selectedDiagnosis.code} - ${selectedDiagnosis.description}`}
                  handleRemove={handleRemoveDiagnosis}
                  removeId={selectedDiagnosis?.code}
                />
              ) : null}
            </div>
            {
              showError ? 
                <div className="col-12">
                  <span className="text--danger font-12">{errorMessage}</span>
                </div> 
              : null
            }
            
          </div>
          <div className="row py-1">
            <div className="col-sm-6">
              <div className="form-group">
                <label
                  htmlFor="diagnosisStartDate"
                  className="ms-1 font-12 text--secondary"
                >
                  Start Date
                </label>
                {
                  <CalenderDatePicker
                    f12={true}
                    ref={dateRefS}
                    dobHandler={handleStartDate}
                    clearable={true}
                    maxDate={joined}
                  />
                }
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label
                  htmlFor="diagnosisEndDate"
                  className="ms-1 font-12 text--secondary"
                >
                  End Date
                </label>
                {
                  <CalenderDatePicker
                    f12={true}
                    ref={dateRefE}
                    dobHandler={handleEndDate}
                    maxDate={joined}
                    // maxDate={startDate}
                    clearable={true}
                  />
                }
              </div>
            </div>
          </div>
          <div className="row py-1">
            <div className="col-6">
              <div className="form-check py-1">
                <input
                  className="form-check-input shadow-none"
                  checked={primaryDiagnosis}
                  onChange={(e) => setPrimaryDiagnosis(e.target.checked)}
                  type="checkbox"
                  id="primaryDiagnosis"
                />
                <label
                  className="form-check-label font-12 text--terciary"
                  htmlFor="primaryDiagnosis"
                >
                  Primary Diagnosis
                </label>
              </div>
            </div>
            <div className="col-6">
              <div className="form-check py-1">
                <input
                  className="form-check-input shadow-none"
                  checked={status}
                  onChange={(e) => setStatus(e.target.checked)}
                  type="checkbox"
                  id="status"
                />
                <label
                  className="form-check-label font-12 text--terciary"
                  htmlFor="status"
                >
                  Active
                </label>
              </div>
            </div>
          </div>

          <div className="py-2 justify-content-center d-flex">
            <div>
              <button
                onClick={() => props.handleModal()}
                disabled={diagnosisAddLoading === true ? true : false}
                className={`btn d9e8ec--button font-12 `}
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                id="AddMore"
                disabled={showError ? true : diagnosisAddLoading}
                onClick={(e) => {
                  handleSaveDiagnosis(e, true);
                }}
                className={`btn ${selectedDiagnosis === undefined ? "disabled" : ""
                  }  blue_task_button outlined font-12`}
              >
                Add More
              </button>
            </div>
            <div>
              <button
                disabled={showError ? true : diagnosisAddLoading }
                onClick={(e) => {
                  handleSaveDiagnosis(e);
                }}
                id="SaveButton"
                className={`btn ${selectedDiagnosis === undefined ? "disabled" : ""
                  }   blue_task_button font-12`}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal >
  );
};

export default DiagnosisModal;


function getDiagnosesListWithSinglePrimaryDiagnosis(selectedDiagnosis, type, startDate, endDate, status, props) {
  const newlyAddedDiagnosis = {
    code: selectedDiagnosis?.code,
    type,
    description: selectedDiagnosis?.description,
    startDate,
    endDate,
    status
  };
  let newList = [...props.diagnosesData, newlyAddedDiagnosis];
  let primaryDiagnosisLength = newList?.filter(d => d.type.toLowerCase() === 'p')?.length;
  let modifiedArray = [];
  if (primaryDiagnosisLength > 1) {
    if (newlyAddedDiagnosis?.type?.toLowerCase() === 'p') {
      modifiedArray = props?.diagnosesData?.map(diagnosis => ({ ...diagnosis, type: 'S' }));
      modifiedArray.push(newlyAddedDiagnosis);
    }
  }
  else {
    modifiedArray = [...props?.diagnosesData, newlyAddedDiagnosis];
  }
  return modifiedArray;
}


import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import PrintIcon from "@mui/icons-material/Print";
import classes from "./PatientPaymentHistory.module.css";
import sendHttpRequest from "../../../components/hooks/sendHttpRequest";
import { useAuth } from "../../../components/hooks/useAuth";
import { currencyFormatter } from "../../../utils/helpers/HelperFunctions";
import {
  calenderDateTimeHelper,
  convertUTCtoTimzone,
} from "../../../utils/dateTimeHelper";
import { PnToolTip } from "../../../components/UI/Reusable/PnComponents";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import CalenderDatePicker from "../../../components/UI/General/CalenderDatePicker";
import moment from "moment";

const PatientPaymentHistory = (props) => {
  const { user } = useAuth();

  const columnHeadersList = [
    { key: "transactionDate", value: "Date" },
    {
      key: "transactionType",
      value: "Payment Type",
    },
    {
      key: "transactionAmount",
      value: "Amount Paid",
    },
    {
      key: "transactionStatus",
      value: "Transaction Status",
    },
    { key: " ", value: " " },
  ];

  const [billingData, setBillingData] = useState([]);
  const [isBillingDataLoading, setIsBillingDataLoading] = useState(false);

  // Pagination states
  const [pages, setPages] = useState([]);
  const [pageNumberLimit, setPageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [maxPageLimit, setMaxPageLimit] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(undefined);

  // Initialize endDate to today's date
  let endDateValue = convertUTCtoTimzone(new Date(), "", "YYYY-MM-DD");

  // Initialize startDate to one month before today's date
  let startDateObj = new Date();
  startDateObj.setMonth(startDateObj.getMonth() - 1);
  let startDateValue = convertUTCtoTimzone(startDateObj, "", "YYYY-MM-DD");

  const [startDate, setStartDate] = useState(startDateValue);
  const [endDate, setEndDate] = useState(endDateValue);

  const fetchBillingData = async (
    currentPage,
    startDateValue,
    endDateValue
  ) => {
    setIsBillingDataLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/patients/${user?.userId}/payment`,
      method: "GET",
      params: {
        page: currentPage !== undefined ? currentPage - 1 : 0,
        size: 10,
        startDate: startDateValue ?? startDate,
        endDate: endDateValue ?? endDate,
      },
    });
    if (!httpResponse.error) {
      setBillingData(httpResponse.data.content);
      let noOfPages = httpResponse?.data?.totalPages;
      setTotalResults(noOfPages);
      setCurrentItems(httpResponse.data?.content);
      setMaxPageLimit(noOfPages);
      let pagesList = [];
      for (let i = 1; i <= noOfPages; i++) {
        pagesList.push(i);
      }
      // console.log("pagesList : ",pagesList);
      setPages(pagesList);
    }
    setIsBillingDataLoading(false);
  };

  // Pagination Handlers
  const RenderPages = () => {
    return (
      <ul className={`${classes["pageNumbers"]}`}>
        {pages?.length > 1 &&
          pages?.map((number) => {
            if (
              number < maxPageNumberLimit + 1 &&
              number > minPageNumberLimit
            ) {
              return (
                <li
                  key={number}
                  id={number}
                  className={`${classes["list__item"]} ${currentPage === number ? classes["active__list__item"] : ""
                    }`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleCurrentPage(number);
                  }}
                >
                  {/* {number >= 5 && pages.length > 5 && number !== pages.length ? `${number}   ...${pages.length}` : number} */}
                  {number}
                </li>
              );
            } else {
              return null;
            }
          })}
      </ul>
    );
  };

  const handleNextButton = (event) => {
    event.preventDefault();
    setCurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
    fetchBillingData(currentPage + 1);
  };
  const handlePreButton = (event) => {
    event.preventDefault();
    // setPageNumber(pageNumber - 1);
    // console.log("currentPage: ",currentPage-1);
    if (currentPage - 1 >= 1) {
      setCurrentPage((p) => currentPage - 1);
      fetchBillingData(currentPage - 1);
    }
    if ((currentPage - 1) % pageNumberLimit == 0) {
      if (minPageNumberLimit - pageNumberLimit < 0) {
        setMaxPageNumberLimit(5);
        setMinPageNumberLimit(0);
      } else {
        setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
        setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
      }
    }
    // searchPharmacy(inputValue, "Retail,MailOrder", currentPage - 1);
  };
  const handleFirstPage = (event) => {
    event.preventDefault();
    setMaxPageNumberLimit(5);
    setMinPageNumberLimit(0);

    handleCurrentPage(1);
  };
  const handleLastPage = (event) => {
    event.preventDefault();
    let lastPage = Math.ceil(totalResults);
    let maxPageNumberLimitValue = lastPage;
    if (maxPageNumberLimitValue % 10 > 0) {
      maxPageNumberLimitValue = maxPageNumberLimitValue - 5;
    }
    setCurrentPage(totalResults);
    setMaxPageNumberLimit(lastPage);
    setMinPageNumberLimit(lastPage - pageNumberLimit);

    handleCurrentPage(lastPage);
  };
  const handleCurrentPage = (pNumber) => {
    setCurrentPage(pNumber);
    fetchBillingData(pNumber);
    // searchPharmacy(inputValue, searchFilter, pNumber,proximity);
  };

  const handleScheduleStartDate = (dateValue) => {
    let startDateValue = moment(dateValue).format("YYYY-MM-DD");
    setStartDate(startDateValue);

    if (moment(startDateValue).isAfter(endDate)) {
      setEndDate(startDateValue);
    }
    fetchBillingData(currentPage, startDateValue, endDate);
  };

  const handleScheduleEndDate = (dateValue) => {
    let endDateValue = moment(dateValue).format("YYYY-MM-DD");
    setEndDate(endDateValue);
    if (moment(endDateValue).isBefore(startDate)) {
      setStartDate(endDateValue);
    }

    fetchBillingData(currentPage, startDate, endDateValue);
  };

  function formatStatus(status) {
    return status
      .toLowerCase()
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  useEffect(() => {
    fetchBillingData();
  }, []);

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-sm-3 col-md-3 mb-3">
          <label className="text--terciary font-12 b2 fw-sb">Start Date</label>
          <CalenderDatePicker
            f12={true}
            dobHandler={handleScheduleStartDate}
            dateFormat="MMM d, yyyy"
            date={startDate}
            maxDate={endDate}
            viewSchedule={false}
            key={"CalStartDate"}
          />
        </div>

        <div className="col-sm-3 col-md-3 mb-3">
          <label className="text--terciary b2 fw-sb">End Date</label>
          <CalenderDatePicker
            f12={true}
            dobHandler={handleScheduleEndDate}
            dateFormat="MMM d, yyyy"
            maxDate={endDateValue}
            minDate={startDate}
            date={endDate}
            viewSchedule={false}
            key={"CalEndDate"}
          />
        </div>
        <div className="col-2"></div>

        <div className="col-8">
          <TableContainer
            sx={{
              borderRadius: "10px",
              border: "1px solid #D9E8EC",
              maxHeight: 440,
              maxWidth: "100%",
              overflowX: "auto",
              "&::-webkit-scrollbar": { height: "8px", width: "4px" },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#D9E8EC",
                borderRadius: "4px",
              },
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead
                sx={{
                  borderTopColor: "#FAFCFC",
                  borderRightColor: "#FAFCFC",
                  borderLeftColor: "#FAFCFC",
                  borderBottom: "1px solid #D9E8EC",
                }}
              >
                <TableRow>
                  {columnHeadersList?.map((item, idx) => {
                    return (
                      <TableCell key={item?.key?.concat(idx)}>
                        <span className="font-12 text--secondary">
                          {item?.value}
                        </span>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {isBillingDataLoading ? (
                  <TableRow>
                    {columnHeadersList?.map((item, idx) => {
                      return (
                        <TableCell key={item?.key?.concat(idx)}>
                          <Skeleton />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ) : currentItems?.length > 0 ? (
                  currentItems.map((invoiceItem, idx) => {
                    return (
                      <TableRow
                        key={idx}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell sx={{ color: "#336383" }}>
                          <span className={"font-12"}>
                            {calenderDateTimeHelper(
                              invoiceItem?.transactionDate,
                              "MMM DD, YYYY"
                            )}
                          </span>
                        </TableCell>
                        <TableCell sx={{ color: "#336383" }}>
                          <span className=" font-12  fw-sb">
                            {invoiceItem?.transactionType}
                          </span>
                        </TableCell>
                        <TableCell sx={{ color: "#336383" }}>
                          <span className=" font-12 ">
                            {currencyFormatter(
                              "USD",
                              invoiceItem?.transactionAmount
                            )}
                          </span>
                        </TableCell>
                        <TableCell sx={{ color: "#336383" }}>
                          <span className=" font-12 ">
                            {formatStatus(invoiceItem?.transactionStatus)}
                          </span>
                        </TableCell>

                        <TableCell sx={{ color: "#336383" }}>
                          {/* <div className="d-flex gap-2">
                                            

                                            <div >
                                                {statusToString(item?.status) === "Not Submitted" ? <PnToolTip title="Review Claim">

                                                    <PreviewIcon
                                                        onClick={() => { navigate('/review-claim', { state: { data: item } }) }}
                                                        fontSize="small"
                                                        style={{ color: "#336383" }}
                                                        className="cursor--pointer font-12 disabled"
                                                    />

                                                </PnToolTip> : null}

                                            </div>

                                        </div> */}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <span className="font-12 text--terciary">
                    {"No data available"}
                  </span>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="row justify-content-center">
          {isBillingDataLoading ? (
            <Skeleton width={50} />
          ) : (
            <div
              className={` mt-4 align-self-center col-sm-8 col-8 justify-content-center`}
            >
              <div className={" d-flex flex-row justify-content-between"}>
                {pages.length > 1 ? (
                  <div className="ps-2 mt-2">
                    <PnToolTip title="First" placement="top">
                      <button
                        disabled={currentPage === 1}
                        onClick={(e) => {
                          handleFirstPage(e);
                        }}
                        className={`${classes["prev__btn"]} me-2 bg--fafcfc border--default br-10`}
                      >
                        {/* <img src={IconNext} alt="icon-previous"></img> */}
                        <FirstPageIcon style={{ color: "#ec609b" }} />
                      </button>
                    </PnToolTip>
                    <PnToolTip title="Previous" placement="top">
                      <button
                        disabled={currentPage === 1}
                        onClick={(e) => {
                          handlePreButton(e);
                        }}
                        className={`${classes["prev__btn"]} bg--fafcfc border--default br-10`}
                      >
                        <NavigateBeforeIcon style={{ color: "#ec609b" }} />
                        {/* <img src={IconNext} alt="icon-previous"></img> */}
                      </button>
                    </PnToolTip>
                  </div>
                ) : (
                  ""
                )}
                <RenderPages />

                {pages.length > 1 ? (
                  <div className="mt-2">
                    <PnToolTip title="Next" placement="top">
                      <button
                        disabled={currentPage === pages.length}
                        onClick={(e) => {
                          handleNextButton(e);
                        }}
                        className={`${classes["next__btn"]} me-2 bg--fafcfc border--default br-10`}
                      >
                        {/* <img src={IconNext} alt="icon-next"></img> */}
                        <NavigateNextIcon style={{ color: "#ec609b" }} />
                      </button>
                    </PnToolTip>
                    <PnToolTip title="Last" placement="top">
                      <button
                        disabled={currentPage === pages.length}
                        onClick={(e) => {
                          handleLastPage(e);
                        }}
                        className={`${classes["next__btn"]} bg--fafcfc border--default br-10`}
                      >
                        {/* <img src={IconNext} alt="icon-next"></img> */}
                        <LastPageIcon style={{ color: "#ec609b" }} />
                      </button>
                    </PnToolTip>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PatientPaymentHistory;

import React, { useEffect, useState } from 'react';
import applePay from 'braintree-web/apple-pay';
import { client as braintreeClient } from 'braintree-web';
import { getPaymentToken, transferPayment } from '../../res/endpointurls';
import { useAuth } from '../hooks/useAuth';
import CurrencyInput from 'react-currency-input-field';
import CustomizedSnackbars from '../UI/General/CustomizedSnackbars';
import { Backdrop, CircularProgress } from '@mui/material';
import sendHttpRequest from '../hooks/sendHttpRequest';
import applePayLogo  from "../../assets/logo/apple-logo.png";

const ApplePay = ({
  setIsPaymentSent,
  amountValue,
  prescribersList,
  userId,
  fetchBillingHistory,
  fetchCurrentBill,
  isCopPay,
  billingProvider,
  patientPortal,
  timeExpired,
  handleModal,
  isValidAmount
}) => {
  const { user } = useAuth();
  const [applePayInstance, setApplePayInstance] = useState(null);
  const [amountInput, setAmountInput] = useState();
  const [provider, setProvider] = useState('');

  const [error, setError] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [isCopPayment,setIsCopPayment] = useState(false);

  async function getToken() {
    const httpResponse = await sendHttpRequest({
      url: getPaymentToken(user?.tenantId),
      method: 'GET',
    });
    if (!httpResponse.error) {
      return httpResponse.data;
    } else {
      setError(httpResponse.errorMessage);
    }
  }

  const sendNonce = async (nonce) => {
    setIsSending(true);
    const httpResponse = await sendHttpRequest({
      url: transferPayment(user?.tenantId),
      method: 'POST',
      data: {
        nonce: nonce,
        providerId: provider,
        userId: userId,
        amount: amountInput,
        transactionType: 'ApplePay',
        copay:isCopPayment
      },
    });
    if (!httpResponse.error) {
      if(patientPortal !== true){
        fetchBillingHistory(true, {
          description: "Paid via Google Pay",
          due: amountInput,
        });
        setIsPaymentSent(true);
        setAmountInput(0);
        fetchCurrentBill();
      }else if(patientPortal === true){
        if(setIsPaymentSent !== undefined){
          setIsPaymentSent(true)
        }
        if(timeExpired){
          timeExpired();
        }
        if(handleModal){
          handleModal();
        }
      }
    } else {
      setError(httpResponse?.errorMessage || { message: 'Payment not completed', show: true });
    }
    setIsSending(false);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSent(false);
    setError({ show: false });
  };
  const loadApplePayScript = () => {
    return new Promise((resolve, reject) => {
      if (document.getElementById("apple-pay-script")) {
        resolve();
        return;
      }
      const script = document.createElement("script");
      script.id = "apple-pay-script"; // Changed ID to a unique one
      // script.src = "https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js";
      script.src = "https://js.braintreegateway.com/web/3.102.0/js/apple-pay.min.js";
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });
  };
  const initializeApplePay = async () => {
    try {
        console.log("Geting Braintree access token...");
        const token = await getToken();
        console.log("Loading apple pay cdn...");
        await loadApplePayScript();

        console.log("Initializing Braintree client Instance...");
      const clientInstance = await braintreeClient.create({
        authorization: token,
      });
      console.log("Braintree client instance: ",clientInstance);

      console.log("Initializing apple pay instance...");
      const applePayInstance = await applePay.create({
        client: clientInstance,
      });
      console.log("Apple pay instanec: ",applePayInstance);

      setApplePayInstance(applePayInstance);
    } catch (error) {
      console.error('Error initializing Braintree and Apple Pay:', error);
    }
  };

  const handlePayment = async (event) => {
    event.preventDefault();
    console.log("handle payment...");

    if (!applePayInstance) {
      console.error('Apple Pay is not initialized');
      return;
    }

    try {
      console.log("loading apple pay script...");
      await loadApplePayScript(); 
      
      console.log("I'm in try block");
      const paymentRequest = applePayInstance.createPaymentRequest({
        total: {
          label: 'My Store',
          amount: amountInput,
        },
        // requiredBillingContactFields: ["postalAddress"]
      });
      console.log("paymentRequest: ",paymentRequest);

      if (window.ApplePaySession) {
        console.log("Creating session...");
        const session = new window.ApplePaySession(3, paymentRequest);
        console.log("session: ",session);

        session.onpaymentauthorized = function (event) {
          applePayInstance.tokenize(
            {
              token: event.payment.token,
            },
            function (tokenizeErr, tokenizedPayload) {
              if (tokenizeErr) {
                session.completePayment(window.ApplePaySession.STATUS_FAILURE);
                return;
              }
              console.log("Nonce: ",tokenizedPayload.nonce);

              sendNonce(tokenizedPayload.nonce);

              session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
            }
          );
          console.log("applePayInstance: ",applePayInstance);
        };
        console.log("session begin");
        session.begin();
      } else {
        console.error('Apple Pay is not supported on this device/browser');
      }
    } catch (error) {
      console.error('Error handling Apple Pay payment:', error);
    }
  };

  useEffect(() => {
    console.log("initializing apple pay....");
    initializeApplePay();
  }, []);

  useEffect(() => {
    setAmountInput(pre=>parseFloat(amountValue).toFixed(2));
    setIsCopPayment(isCopPay);
    setProvider(billingProvider)
  },[amountValue,isCopPay,billingProvider])
  console.log("isCopPayment: ",isCopPayment,amountInput,provider);

  return (
    <div className="row">
      <div className="col-6" hidden={patientPortal}>
        <label htmlFor="amount" className="font-12 text--secondary">
          {'Amount'}
        </label>
        <CurrencyInput
          id="cash"
          name="cash"
          placeholder="$0.00"
          className="text--terciary text-end font-12 input form-control shadow-none border--default br-10"
          value={amountInput}
          prefix="$"
          decimalSeparator="."
          groupSeparator=","
          intlConfig={{ locale: 'en-US', currency: 'USD' }}
          onValueChange={(value) => setAmountInput(value)}
          align="right"
        />
      </div>
      <div className="col-6" hidden={patientPortal === true}>
        <label htmlFor="provider" className="font-12 text--secondary">
          {'Provider'}
        </label>
        <select
          className="form-select font-12 border--default br-10 shadow-none input"
          aria-label="action select"
          style={{
            border: '1px solid #D9E8EC',
            height: '36px',
          }}
          onChange={(e) => setProvider(e.target.value)}
        >
          <option className="text--terciary" value="" key={1} selected disabled hidden>
            {'Select Provider'}
          </option>
          {prescribersList?.length > 0 &&
            prescribersList.map((prescriberItem) => {
              return (
                <option key={prescriberItem?.userId} value={prescriberItem?.userId}>
                  {`${prescriberItem?.name[0]?.prefix || ''} ${prescriberItem?.name[0]?.firstName || ''} ${prescriberItem?.name[0]?.lastName}`}
                </option>
              );
            })}
        </select>
      </div>
      <div className="col-12">
        <label>&nbsp;</label>
        <button
          style={{
            backgroundColor: "#555",
            width: "100%",
            height: "2.9em",
          }}
          id="apple-pay-button"
          onClick={handlePayment}
          className=" font-12 br-10 bg--black text--white fw-sb"
          disabled={patientPortal ? !isValidAmount :provider===undefined}
        >
          <span className="me-1">{"Pay with"}</span>
          <img src={applePayLogo} style={{ height: "3em", width: "3em" }} />
        </button>

      </div>

      <CustomizedSnackbars
        message={'Payment Received'}
        severity="success"
        open={isSent}
        handleClose={handleClose}
      />
      <CustomizedSnackbars
        message={error?.message}
        severity="error"
        open={error?.show}
        handleClose={handleClose}
      />
    </div>
  );
};

export default ApplePay;

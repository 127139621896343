import { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import classes from "./LabRequisitionFormModal.module.css";
import { lab_requisition_strings, numbersEnum } from "../../../res/strings";
import { formatPhoneNumber } from "../../../utils/helpers/HelperFunctions";
import {
  calenderDateTimeHelper,
  getDMYformate,
} from "../../../utils/dateTimeHelper";
import CustomDatePicker from "../General/CustomDatePicker";
import TextArea from "../../forms/TextArea";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../Reusable/PnComponents";

import SearchIcon from "@mui/icons-material/Search";
import ReactSignatureCanvas from "react-signature-canvas";
import sendHttpRequest, { search } from "../../hooks/sendHttpRequest";
import { providerSettingSignaturesUrl } from "../../../res/endpointurls";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useEffect } from "react";
import { Skeleton } from "@mui/material";
import TrashButtonIcon from "../../../assets/icons/Icon-Trash.svg";
import _ from "lodash";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import PhoneNumberInput from "../Reusable/PhoneNumberInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import { featureFlagMap } from "../../../utils/helpers/ConfigMap";
import { useFlags } from "launchdarkly-react-client-sdk";


// ICD10 Code List Items
const ICD10CodeListItems = ({icd10List,iCD10CodeList,setICD10CodeList})=>{
  const handleChange = (event,item)=>{
    let ischecked = event.target.checked
    if(ischecked){
      setICD10CodeList([...iCD10CodeList,item])
    }else{
      let result = iCD10CodeList.filter((itemObject)=>{return itemObject.code !== item.code})
      setICD10CodeList([...result])
    }
  }
  const handleDefaultChecked = (itemObject)=>{
    const result = iCD10CodeList.find((item)=>{ return item?.code === itemObject?.code})
    return result !== undefined
  }
  return (
    <>
      {
        icd10List.map((item)=>{
          return (
            <div className="col-6 col-sm-6">
              <div className=" form-group mx-auto font-14 text--terciary" >
                <input 
                  type="checkbox" 
                  className="form-check-input border--default br-10 cursor--pointer cursor--pointer shadow-none" 
                  id={item?.code} 
                  name={item?.description} 
                  onChange={(e)=>{handleChange(e,item)}}
                  defaultChecked = {handleDefaultChecked(item)}
                  />
                <label for={item?.code} className="form-check-label ms-1">{item?.code.concat(" - ",item?.description)}</label>
            </div>
            </div>
          )
        })
      } 
    </>
  )
}
const LabRequisitionFormModal = (props) => {
  const { pnErx3155DiagnosisEnabled } = useFlags();
  // console.log("pnErx3155DiagnosisEnabled: ",pnErx3155DiagnosisEnabled);
  const __str = lab_requisition_strings.en;
  const _numbString = numbersEnum.en;
  const { patientId } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [labOrderConsent, setLabOrderConsent] = useState(false);
  const ref = useRef();

  const [patientReqtrDetailsExpanded, setPatientReqtrDetailsExpanded] =
    useState("patientRqtr");
  const [sampleDetailsExpanded, setSampleDetailsExpanded] = useState(false);
  const [profileTestExpanded, setProfileTestExpanded] = useState("profileTest");
  const [biochemistryExpanded, setBiochemistryExpanded] = useState(false);
  const [hematologyExpanded, setHematologyExpanded] = useState("hematology");
  const [microbiologyExpanded, setMicrobiologyExpanded] = useState(false);
  const [anatomicalPathologyExpanded, setAnatomicalPathologyExpanded] =
    useState(false);
  const [examinationExpanded, setExaminationExpanded] = useState(false);
  const [icd10Expanded, setIcd10Expanded] = useState(false);
  const [summaryExpanded, setSummaryExpanded] = useState(false);

  //patient provider data
  const [patientData, setPatientData] = useState();
  const [providerData, setProviderData] = useState();
  const [isPatientDataLoading, setIsPatientDataLoading] = useState(false);
  const [isProviderDataLoading, setIsProviderDataLoading] = useState(false);

  // Sample Details
  const [sampleDate, setSampleDate] = useState();
  const [sampleTime, setSampleTime] = useState();
  const [isFasting, setIsFasting] = useState(
    props?.editLabOrderData?.fasting ?? false
  );
  const [fastingHrs, setFastingHrs] = useState(
    props?.editLabOrderData?.numberOfHours ?? ""
  );
  const [samplesTypes, setSamplesTypes] = useState(
    props?.editLabOrderData?.labOrderSample || []
  );
  const [otherSampleType, setOtherSampleType] = useState(
    props?.editLabOrderData?.sampleNamely?.length > 0 ? true : false
  );
  const [otherSampleValue, setOtherSampleValue] = useState({
    labOrderSampleId: 9,
    description: props?.editLabOrderData?.sampleNamely ?? "",
  });
  const [sampleList, setSampleList] = useState([]);
  const [isSampleListLoading, setIsSampleListLoading] = useState(false);
  const [sampleTypesError, setSampleTypesError] = useState();

  // Examination search
  const [isExamReqtLoading, setIsExamReqtLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [examTestQuery, setExamTestQuery] = useState("");
  const [examinationTestList, setExaminationTestList] = useState(
    props?.editLabOrderData?.labOrderTest || []
  );
  const [additionalTest, setAdditionalTest] = useState(
    props?.editLabOrderData?.additionalTest || ""
  );
  const [reasonForTest, setReasonForTest] = useState(
    props?.editLabOrderData?.reasonForTest || ""
  );
  const [examinationTempList, setExaminationTempList] = useState(
    props?.editLabOrderData?.labOrderTest || []
  );
  const [examinationListErrors, setExaminationListErrors] = useState();

  // ICD10 search
  const [isICD10Loading, setIsICD10Loading] = useState(false);
  const [icd10Suggestions, setIcd10Suggestions] = useState([]);
  const [icd10Query, setIcd10Query] = useState("");
  const [iCD10CodeList, setICD10CodeList] = useState(
    props?.editLabOrderData?.diagnoses || []
  );
  const [iCD10CodeData,setICD10CodeData] = useState([]);
  // Signature
  const [sigPad, setSigPad] = useState();
  const [filename, setFilename] = useState(null);

  // Maps
  const [examinationMap, setExaminationMap] = useState(new Map());

  // Order Type
  const [orderType, setOrderType] = useState("print");
  const [fax, setFax] = useState();
  const [faxErrors, setFaxErrors] = useState("");

  const fetchProviderData = async () => {
    setIsProviderDataLoading(true);
    // setIsUserDataLoading(false);
    const httpResponse = await sendHttpRequest({
      url: `/provider/${user?.userId}`,
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
      setProviderData(httpResponse.data);
    }
    setIsProviderDataLoading(false);
  };
  const fetchPatientData = async () => {
    setIsPatientDataLoading(true);
    // setIsUserDataLoading(false);
    // console.log("patientId: ",patientId);
    const httpResponse = await sendHttpRequest({
      url: "/patient/information",
      method: "GET",
      params: {
        patientId: props.patientId,
      },
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
      fetchICD10CodeData(httpResponse.data?.userId);
      setPatientData(httpResponse.data);
    }
    setIsPatientDataLoading(false);
  };

  const fetchSamples = async () => {
    setIsSampleListLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/lab-test-request/lab-samples",
      method: "GET",
    });
    if (!httpResponse.error) {
      setSampleList(httpResponse.data);
    }
    setIsSampleListLoading(false);
  };
  const searchICD10Codes = async (diagnosisInput) => {
    setIcd10Suggestions([]);
    setIsICD10Loading(true);
    const httpResponse = await search({
      url: `/diagnosis`,
      method: "GET",
      params: {
        search: diagnosisInput,
      },
    });
    if (!httpResponse.error) {
      setIcd10Suggestions(httpResponse.data);
    } else {
      if (httpResponse?.status === 400) {
        setIcd10Suggestions([{ code: "-1" }]);
      }
    }
    setIsICD10Loading(false);
  };
  // fetch ICD10 Code data

  const fetchICD10CodeData = async (patientId)=>{
    setIsICD10Loading(true);
    const httpResponse = await sendHttpRequest({
      url:`/patients/${patientId}/diagnoses`,
      method:'Get',
      params: {
        status: true,
        unique: true
      },
    })
    if(!httpResponse.error){
      setICD10CodeData(httpResponse.data)
    }
    setIsICD10Loading(false);
  }
  const searchExaminationTest = async (diagnosisInput) => {
    // setSuggestions([]);
    setIsExamReqtLoading(true);
    const httpResponse = await search({
      url: `/lab-test-request/lab-test`,
      method: "GET",
      params: {
        search: diagnosisInput,
      },
    });
    if (!httpResponse.error) {
      if (httpResponse.data?.length > 0) {
        setSuggestions(httpResponse.data);
      } else {
        setSuggestions([{ code: "-1" }]);
      }
    } else {
      setSuggestions([{ code: "-1" }]);
    }

    setIsExamReqtLoading(false);
  };

  function downloadPdf(httpResponse) {
    const link = document.createElement("a");
    link.target = "_blank";
    link.download =
      `${httpResponse?.responseHeaders?.pdf_name?.split("=")?.at(1)}` ??
      `Lab_Order_${patientData?.userId}`;
    const href = URL.createObjectURL(
      new Blob([httpResponse.data], {
        type: "application/pdf",
        encoding: "UTF-8",
      })
    );
    link.href = href;
    link.click();
    link.remove();
    URL.revokeObjectURL(href);
  }

  const sendLabOrder = async (isFax) => {
    setLabOrderConsent(false);
    let labOrderSample = [];
    let examinationTests = [];
    let dynamicUrl = `/lab-test-request`;
    let ics10Codes = [];
    for (let index = 0; index < samplesTypes.length; index++) {
      const element = samplesTypes[index];
      labOrderSample.push(element);
    }
    if (otherSampleType === true) {
      labOrderSample.push({
        labOrderSampleId: otherSampleValue?.labOrderSampleId,
        description: "Others",
      });
    }

    for (let index = 0; index < examinationTestList.length; index++) {
      const element = examinationTestList[index];
      examinationTests.push(element);
    }

    if (props?.prescriptionReference !== undefined) {
      dynamicUrl = `/prescription-reference/${props?.prescriptionReference}/lab-test-request`;
    }
    let dataObject = {
      prescriptionReference: {
        patient: { userId: patientData?.userId },
        provider: { userId: user?.userId },
      },
      numberOfHours: isFasting === true ? fastingHrs : "",
      fasting: isFasting,
      // sampleCollectionDateTime:
      //   calenderDateTimeHelper(sampleDate, "YYYY-MM-DD") + "T" + sampleTime,
      additionalTest: additionalTest,
      labOrderSample: labOrderSample,
      labOrderTest: examinationTests,
      diagnoses: iCD10CodeList,
      sampleNamely: otherSampleType ? otherSampleValue?.description : "",
      reasonForTest: reasonForTest || "",
      ...(orderType === "fax" && { faxNumber: fax }),
    };
    let httpResponse = undefined;
    if (props?.soapNote === true) {
      props?.setLabOrdersData(dataObject);
      props?.handleModal();
    } else {
      if (
        props?.soapNote !== true &&
        props?.providerNote !== undefined &&
        props?.providerNote === true &&
        props?.editLabOrderData?.labOrderId
      ) {
        httpResponse = await sendHttpRequest({
          url: orderType === "fax" ? dynamicUrl + "/fax"  : dynamicUrl,
          method: "PUT",
          responseType: "arraybuffer",
          data: dataObject,
        });
      } else {
        httpResponse = await sendHttpRequest({
          url:
            orderType === "fax"
              ? props.editLabOrderData?.labOrderId !== undefined ? `/lab-test-request/${props.editLabOrderData?.labOrderId}/fax`
              : dynamicUrl + "/fax"
              : props.editLabOrderData?.labOrderId
              ? `/lab-test-request/${props.editLabOrderData?.labOrderId}`
              : dynamicUrl,
          method: props.editLabOrderData?.labOrderId ? "PUT" : "POST",
          responseType: "arraybuffer",
          data: dataObject,
        });
      }
    }

    if (!httpResponse.error) {
      if (
        props?.assessmentPlan === true &&
        props?.fetchLabOrders !== undefined
      ) {
        props?.fetchLabOrders();
      }
      if (httpResponse.data !== undefined) {
        if (props?.referenceFrom == undefined || props.referenceFrom == null) {
          if (orderType === "print") {
            downloadPdf(httpResponse);
          }

          if (props?.setIsLabOrderSent !== undefined) {
            props?.setIsLabOrderSent((p) => !p);
          }
        } else if (props?.soapNote === true) {
          props?.setLabOrdersData(dataObject);
        }

        if (props?.setCallApi !== undefined && props?.setCallApi !== null) {
          props?.setCallApi((p) => !p);
        }
      }
      if (props?.assessmentPlan !== true) {
        navigate(`/all-patients/${patientData?.userId}`, {
          state: {
            patientId: `${patientData?.userId}`,
            selectedIndex: `${patientData?.userId}`,
            index: `${patientData?.userId}`,
          },
        });
      } else if (
        props.handleLabRequisitionModal !== null &&
        props.handleLabRequisitionModal !== undefined
      ) {
        // console.log('navigate to patient profile labs tab');
        navigate(`/all-patients/${patientId}`, {
          state: { referer: "lab-order" },
        });
      } else {
        if (props?.referenceFrom === undefined) {
          props?.setMessage("Lab order saved successfully");
          props?.setSeverity("success");
          props?.handleLabOrderSnackBar();
        }
      }
    } else {
      if (props?.assessmentPlan) {
        if (props?.referenceFrom === undefined) {
          props?.setMessage("Lab order not sent");
          props?.setSeverity("error");
        }
      }
    }
  };

  const handleChange = (panel) => (event, newExpanded) => {
    if (panel === "patientRqtr") {
      setPatientReqtrDetailsExpanded(newExpanded ? panel : false);
    } else if (panel === "sampleDetails") {
      setSampleDetailsExpanded(newExpanded ? panel : false);
    } else if (panel === "examination") {
      setExaminationExpanded(newExpanded ? panel : false);
    } else if (panel === "icd10") {
      setIcd10Expanded(newExpanded ? panel : false);
    } else if (panel === "summary") {
      setSummaryExpanded(newExpanded ? panel : false);
    }
  };

  const handleExaminationSections = (event) => {
    const newMap = new Map(examinationMap);
    setExaminationMap(newMap.set(event.target.id, event.target.checked));
  };

  const convertToImage = () => {
    let trimmedDataURL = sigPad.getTrimmedCanvas().toDataURL("image/png");
    // console.log(trimmedDataURL);
    let mimeType = trimmedDataURL.split(",")[0].split(":")[1].split(";")[0];

    fetch(trimmedDataURL)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "signature.png", {
          type: mimeType,
        });
        setFilename(file);
      });
  };

  const saveSignature = async () => {
    if (
      providerData?.providerSetting === undefined ||
      providerData?.providerSetting === "" ||
      providerData?.providerSetting === null ||
      providerData?.providerSetting?.signaturePath === undefined ||
      providerData?.providerSetting?.signature === null ||
      providerData?.providerSetting?.signature === undefined
      // providerData?.providerSetting?.signaturePath === "" ||
      // providerData?.providerSetting?.signaturePath === null 
      
    ) {
      const fd = new FormData();
      if (filename !== null) {
        fd.append("file", filename);
        fd.append("labOrders",true);
      }
      const httpResponse = await sendHttpRequest({
        url: providerSettingSignaturesUrl(providerData?.userId),
        method: "POST",
        data: fd,
        headers: {
          "Content-Type": "multipart/form-data; boundary=",
        },
      });
      // if (!httpResponse.error) {
      //   rxAction();
      // }
    } else {
      // rxAction();
    }
  };

  const handleSampleDetailsSection = () => {
    setPatientReqtrDetailsExpanded(false);
    setSampleDetailsExpanded("sampleDetails");
  };

  const handleExaminationSection = (event) => {
    event.preventDefault();
    console.log("otherSampleValue?.description", otherSampleValue?.description);
    if (
      samplesTypes?.length > 0 ||
      (otherSampleValue?.description?.length > 0 && otherSampleType === true)
    ) {
      setSampleDetailsExpanded(false);
      setExaminationExpanded("examination");
      setSampleTypesError("");
    } else {
      setSampleTypesError("Please select a specimen type before proceeding");
    }
  };
  const handleICD10Section = () => {
    setExaminationExpanded(false);
    setIcd10Expanded("icd10");
  };
  const handleSummarySection = () => {
    // setIcd10Expanded(false);
    if (examinationTestList?.length > 0) {
      setExaminationExpanded(false);
      setSummaryExpanded("summary");
      setExaminationListErrors("");
    } else {
      setExaminationListErrors(
        "Please select one or more labs before proceeding"
      );
    }
  };

  const handleSampleDate = (dateValue) => {
    setSampleDate(dateValue);
  };
  const handleSampleTime = (event) => {
    setSampleTime(event.target.value);
  };
  const handleFasting = (event) => {
    if (event.target.checked === true) {
      setIsFasting(true);
    } else {
      setIsFasting(false);
      setFastingHrs("")
    }
  };
  const handleFastingHrs = (event) => {
    let numofHrs = event.target.value;
    if ((numofHrs !== undefined || numofHrs !== null) && Number(numofHrs) > 0) {
      setFastingHrs(numofHrs);
      setIsFasting(true);
    } else {
      setFastingHrs("");
      setIsFasting(false);
    }
  };
  const hanldeSampleType = (event, sampleObject) => {
    let sTypes = [];
    if (event.target.checked === true) {
      sTypes = samplesTypes;
      setSamplesTypes([...sTypes, sampleObject]);
      setSampleTypesError("");
    } else {
      sTypes = samplesTypes.filter((item) => {
        return item.description !== sampleObject?.description;
      });

      setSamplesTypes(sTypes);
    }
    // console.log("sTypes: ", sTypes);
  };
  const handleOtherSampleType = (event,sampleObject) => {
    let checked = event.target.checked
    if(!checked){
      let samplesTemp = samplesTypes
      samplesTemp = samplesTypes.filter((item) => {
        return item.description !== "Others";
      });
      setSamplesTypes(samplesTemp);
    }
    setOtherSampleType(checked);
  };
  const handleOtherSampleValue = (event) => {
    let otherSType = event.target.value;
    if (otherSType?.length > 0) {
      setOtherSampleType(true);
      setOtherSampleValue({
        labOrderSampleId: event.target.id,
        description: otherSType,
      });
      setSampleTypesError("");
    } else {
      event.target.value = ""
      let samplesTemp = samplesTypes
      samplesTemp = samplesTypes.filter((item) => {
        return item.description !== "Others";
      });
      setSamplesTypes(samplesTemp);
      setOtherSampleType(false);
    }
  };

  //ICD10 code handlers
  const handleICD10Search = (query) => {
    setIcd10Query(query);
    if (query.length > 1) {
      searchICD10Codes(query);
    } else {
      setIsICD10Loading(false);
      setIcd10Suggestions([]);
    }
  };
  const handleICD10SearchBtn = (query) => {
    handleICD10Search(query);
  };

  const handleICD10CodeList = (icd10Object) => {
    let icdlist = iCD10CodeList.filter((item) => {
      return item.code !== icd10Object.code;
    });
    setICD10CodeList([...icdlist, icd10Object]);
  };
  const handleDeleteICD10 = (icd10Object) => {
    let icdlist = iCD10CodeList.filter((item) => {
      return item.code !== icd10Object.code;
    });
    setICD10CodeList([...icdlist]);
  };

  //examination test handlers
  const handleExamTestSearch = (query) => {
    setExamTestQuery(query);
    if (query.length > 1) {
      searchExaminationTest(query);
    } else {
      setIsExamReqtLoading(false);
      // setSuggestions([]);
    }
  };
  const handleExamSearchBtn = (query) => {
    handleExamTestSearch(query);
  };

  const handleExamTestList = (examObject) => {
    let ischecked = document.getElementById(examObject.labOrderTestId);
    let examlist = examinationTempList.filter((item) => {
      return item.labOrderTestId !== examObject.labOrderTestId;
    });
    if (ischecked.checked === false) {
      setExaminationTempList([...examlist, examObject]);
    } else {
      setExaminationTempList([...examlist]);
    }
    ischecked.checked = !ischecked.checked;
  };
  const handleDeleteExamTempTest = (examObject) => {
    // console.log("examObject",examObject);
    let examList = examinationTempList.filter((item) => {
      return item.labOrderTestId !== examObject.labOrderTestId;
    });
    // setExaminationTestList([...examList]);
    setExaminationTempList([...examList]);
  };
  const handleDeleteExamTest = (examObject) => {
    // console.log("examObject",examObject);
    let examList = examinationTestList.filter((item) => {
      return item.labOrderTestId !== examObject.labOrderTestId;
    });
    setExaminationTestList([...examList]);
    setExaminationTempList([...examList]);
  };
  const handleAdditionalTest = (additionalTest) => {
    setAdditionalTest(additionalTest);
  };
  const handleReasonForTest = (reasonTestValue) => {
    setReasonForTest(reasonTestValue);
  };

  // provider and patient component

  const ProviderPatientDetails = () => {
    return (
      <>
        {/* Patient Details */}
        <div className="col-6">
          <h3 className="text--terciary font-14 fw-sb m-0">
            {__str.patient_details_str}
          </h3>
          {isPatientDataLoading ? (
            <Skeleton />
          ) : (
            <div className="text--terciary font-12  br-10 px-2">
              <div>
                <span className="text--terciary font-12 ">
                  {__str.name_str}: &nbsp;
                </span>
                <span>
                  {"".concat(
                    patientData?.name[0]?.firstName || "",
                    " ",
                    patientData?.name[0]?.lastName || ""
                  )}
                </span>
              </div>
              <div>
                <span>{__str.gender_str}: &nbsp;</span>
                <span className="">
                  {patientData !== undefined ? patientData?.gender : ""}
                </span>
              </div>
              <div>
                <span className="text--terciary font-12">
                  {__str.date_of_birth_str}: &nbsp;
                </span>
                <span>
                  {patientData !== undefined
                    ? getDMYformate(patientData?.dateOfBirth, "MMM DD, YYYY")
                    : ""}
                </span>
              </div>
              <div>
                <span className="text--terciary font-12">
                  {__str.telephone_str}: &nbsp;
                </span>
                <span>
                  {patientData !== undefined
                    ? formatPhoneNumber(
                        patientData?.communicationNumbers?.numbers?.find(
                          (numberItem) => {
                            return (
                              numberItem?.type === _numbString.cellPhoneExt
                            );
                          }
                        )?.number
                      )
                    : ""}
                </span>
              </div>
              <div>
                <span className="text--terciary font-12 ">
                  {__str.address_str}: &nbsp;
                </span>
                <span className="font-12 text--terciary ">
                  {/* 221B Bakers, Street, 25454, NYC */}
                  {patientData !== undefined && patientData?.address?.length > 0
                    ? "".concat(
                        patientData?.address[0]?.addressLine1?.length > 0
                          ? patientData?.address[0]?.addressLine1 + ", "
                          : "",
                        patientData?.address[0]?.addressLine2?.length > 0
                          ? patientData?.address[0]?.addressLine2 + ", "
                          : "",

                        patientData?.address[0]?.city?.length > 0
                          ? patientData?.address[0]?.city + ", "
                          : "",

                        patientData?.address[0]?.stateProvince || "",
                        " ",
                        patientData?.address[0]?.postalCode
                      )
                    : ""}
                </span>
              </div>
            </div>
          )}
        </div>

        {/* Requester Details */}
        <div className="col-6">
          <h3 className="text--terciary font-14 fw-sb m-0">
            {__str.requester_details_str}
          </h3>
          {isProviderDataLoading ? (
            <Skeleton />
          ) : (
            <div className="text--terciary font-12  br-10 px-2">
              <div>
                <span className="text--terciary font-12 ">
                  {__str.name_str}: &nbsp;
                </span>
                <span>
                  {"".concat(
                    providerData?.name[0]?.firstName || "",
                    " ",
                    providerData?.name[0]?.lastName || ""
                  )}
                </span>
              </div>
              <div>
                <span>{__str.physician_npi_str}: &nbsp;</span>
                <span>{providerData?.identification?.npi}</span>
              </div>
              <div>
                <span>{__str.organization_str}: &nbsp;</span>
                <span>
                  {providerData?.providerTenantPracticeLocation?.tenant
                    ?.businessName || ""}
                </span>
              </div>
              <div>
                <span>{__str.facility_str}: &nbsp;</span>
                <span>
                  {providerData?.providerTenantPracticeLocation?.facility
                    ?.businessName || ""}
                </span>
              </div>
              <div className="mb-1">
                <span>{__str.address_str}: &nbsp;</span>
                <span>
                  {providerData !== undefined
                    ? "".concat(
                        providerData?.providerTenantPracticeLocation?.facility
                          ?.addressLine1,
                        " ",
                        providerData?.providerTenantPracticeLocation?.facility
                          ?.addressLine2 || ""
                      )
                    : null}{" "}
                  {providerData !== undefined
                    ? "".concat(
                        providerData?.providerTenantPracticeLocation?.facility
                          ?.city || "",
                        ", ",
                        providerData?.providerTenantPracticeLocation?.facility
                          ?.state || "",
                        " ",
                        providerData?.providerTenantPracticeLocation?.facility
                          ?.zipCode || ""
                      )
                    : null}
                </span>
              </div>
            </div>
          )}
        </div>
      </>
    );
  };
  // Summary Item

  const SummaryItem = (props) => {
    return (
      <>
        <div className="col-sm-3">
          <span className="font-12 text--secondary">{props.title}</span>
        </div>
        <div
          className={`col-sm-9 font-12 text--terciary  ${
            props?.fasting === true ? "" : "fw-sb"
          }`}
        >
          {props.description}
          {/* <span className="font-14 text--terciary fw-sb">
          </span> */}
        </div>
      </>
    );
  };
  // List Item
  const ListItem = ({ list, handleDelete, type, hideAction }) => {
    return (
      <>
        {list?.length > 0
          ? list.map((item) => {
              return (
                <div className="col-6 col-sm-6" key={item?.description}>
                  <div className="d-flex flex-row justify-content-between font-12 text--terciary mt-2">
                    <div className="">
                      <span>
                        {type === "exam"
                          ? item?.description.concat(
                              " ",
                              item?.alias1?.length > 0
                                ? `(${item?.alias1})`
                                : ""
                            )
                          : item?.code + " - " + item?.description}
                      </span>
                    </div>
                    <div>
                      {hideAction !== true ? (
                        <img
                          src={TrashButtonIcon}
                          alt={"deleteIcon"}
                          id={
                            type === "exam" ? item?.labOrderTestId : item?.code
                          }
                          className="me-2 cursor--pointer"
                          style={{ width: "15px", height: "15px" }}
                          onClick={(e) => {
                            handleDelete(item);
                          }}
                        ></img>
                      ) : null}
                    </div>
                  </div>
                </div>
              );
            })
          : null}
      </>
    );
  };

  const handleConsent = (event) => {
    setLabOrderConsent(event.target.checked);
  };

  useOnClickOutside(ref, () => {
    setSuggestions([]);
  });

  const checkExamIdMatched = (suggestion) => {
    if (examinationTempList?.length > 0) {
      let ans = examinationTempList.find((item) => {
        return item.labOrderTestId === suggestion.labOrderTestId;
      });
      return ans !== undefined;
    } else {
      return false;
    }
  };

  const handleSpecimenInfoCheckbox = (id) => {
    let sampleTypes = props?.editLabOrderData?.labOrderSample;
    let ans = sampleTypes?.find((item) => {
      return item?.labOrderSampleId === id;
    });
    if (sampleTypes?.length > 0) {
      let otherSampleAns = sampleTypes?.find((item) => {
        return item?.labOrderSampleId === 9;
      });
      if (
        otherSampleAns !== undefined &&
        otherSampleType === false &&
        otherSampleValue?.description?.length === 0
      ) {
        setOtherSampleValue({
          labOrderSampleId: 9,
          description: props?.editLabOrderData?.sampleNamely,
        });
        setOtherSampleType(true);
      }
    }
    return ans !== undefined;
  };

  const changeOrderType = (event) => {
    setOrderType(event.target.id);
  };
  const faxHandler = (faxValue) => {
    // let faxNumber = formatPhoneNumber(faxValue?.replace("+1", ""));
    let faxNumber = faxValue?.replace("+1", "");
    faxValue = faxValue?.replace("undefined", "");
    if (typeof faxValue !== "string") {
      return;
    }
    setFax(faxNumber);
    if (
      faxValue !== undefined &&
      isValidPhoneNumber(faxValue) &&
      faxValue.length === 12
    ) {
      setFaxErrors((previousSnapshot) => ({
        ...previousSnapshot,
        error: false,
      }));
    } else if (
      faxValue === undefined ||
      faxValue.length === 3 ||
      faxValue === "+1null"
    ) {
      setFaxErrors((previousSnapshot) => ({
        ...previousSnapshot,
        error: false,
      }));
    } else if (
      faxValue !== undefined &&
      !isValidPhoneNumber(faxValue) &&
      faxValue.length > 2 &&
      !faxValue.includes("null") &&
      !faxValue.includes("undefined")
    ) {
      setFaxErrors((previousSnapshot) => ({
        ...previousSnapshot,
        error: true,
      }));
    }
  };

  

  useEffect(() => {
    fetchPatientData();
    fetchProviderData();
    fetchSamples();

    if (props?.editLabOrderData !== undefined) {
      handleSampleDetailsSection();
    }
    return(()=>{setIsFasting(false);setFastingHrs("")})
  }, []);

  return (
    <>
      <Modal
        show={props.show}
        contentClassName="modal-border modal-shadow h-100 bg--background"
        backdropClassName="modal-backdrop"
        dialogClassName={`${classes["modal__width"]}`}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header className="modal-header">
          <span
            style={{
              lineHeight: "12px",
              width: "18px",
              fontSize: "8pt",
              color: "#336383",
              position: "absolute",
              top: 15,
              right: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              props.handleModal();
            }}
          >
            {<CloseIcon />}
          </span>
          <Modal.Title className="font-20 fw-sb text--terciary">
            {props?.editLabOrderData !== undefined
              ? "Edit Lab Order"
              : "New Lab Order"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <>
              <div className="row justify-content-center">
                <div className="col-8">
                  <Accordion
                    expanded={patientReqtrDetailsExpanded === "patientRqtr"}
                    onChange={handleChange("patientRqtr")}
                    defaultExpanded={true}
                    // className="mt-4"
                  >
                    <AccordionSummary
                      aria-controls="panelpatientrequester-content"
                      id="panelpatientrequester-header"
                    >
                      <h2 className="font-18 fw-sb m-0">
                        {__str.patient_details_str.concat(
                          " & ",
                          __str.requester_details_str
                        )}
                      </h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="row border--default br-10 me-1 ms-1">
                        <ProviderPatientDetails />
                      </div>
                      <div className="col-12 text-end mt-2">
                        <button
                          className="btn d9e8ec--button "
                          onClick={() => handleSampleDetailsSection()}
                        >
                          {"Next"}
                        </button>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
              {/* Sample details */}
              <div className="row justify-content-center">
                <div className="col-8">
                  <Accordion
                    expanded={sampleDetailsExpanded === "sampleDetails"}
                    onChange={handleChange("sampleDetails")}
                    defaultExpanded={false}
                    // className="mt-4"
                  >
                    <AccordionSummary
                      aria-controls="panelsampleDetails-content"
                      id="panelsampleDetails-header"
                    >
                      <h2 className="font-18 fw-sb m-0">
                        {__str.sample_details_str}
                      </h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <form
                        id="sampleForm"
                        onSubmit={(e) => handleExaminationSection(e)}
                      >
                        <div className="row text--terciary font-12   m-1">
                          <div className="col-12  ">
                            <div className="row text--terciary font-12 border--default br-10 p-2">
                              {isSampleListLoading ? (
                                <Skeleton />
                              ) : (
                                <>
                                  {sampleList.map((item, index) => {
                                    if (item?.description !== "Others") {
                                      return (
                                        <div
                                          className="col-sm-2"
                                          key={item?.labOrderSampleId}
                                        >
                                          <div
                                            className="form-check form-check-inline mt-3"
                                            key={item?.description}
                                          >
                                            <input
                                              className="form-check-input "
                                              type="checkbox"
                                              // name="orderType"
                                              id={item?.labOrderSampleId}
                                              value={item?.description}
                                              onChange={(e) => {
                                                hanldeSampleType(e, item);
                                              }}
                                              defaultChecked={handleSpecimenInfoCheckbox(
                                                item?.labOrderSampleId
                                              )}
                                              // checked={orderType === _str?.ePrescription}
                                            />
                                            <label
                                              className="form-check-label font-12 align-self-center"
                                              for={item}
                                            >
                                              {item?.description}
                                            </label>
                                          </div>
                                        </div>
                                      );
                                    }
                                  })}
                                  <div className="col-6 ">
                                    {sampleList?.length > 0
                                      ? sampleList.map((sampleItem) => {
                                          if (
                                            sampleItem?.description === "Others"
                                          ) {
                                            return (
                                              <div
                                                className=""
                                                key={"Other-input"}
                                              >
                                                <div className=" d-flex flex-row me-2 text--terciary font-12 ">
                                                  <div
                                                    className="form-check form-check-inline  align-self-center mt-3"
                                                    // key={"Others"}
                                                  >
                                                    <input
                                                      className="form-check-input  "
                                                      type="checkbox"
                                                      // name="otherSmapleType"
                                                      id={"Other"}
                                                      name={"Other"}
                                                      onChange={(e) => {
                                                        handleOtherSampleType(
                                                          e,otherSampleValue?.description
                                                        );
                                                      }}
                                                      checked={otherSampleType}
                                                      // defaultChecked = {handleSpecimenInfoCheckbox(sampleItem?.labOrderSampleId)}
                                                    />
                                                    <label
                                                      className="form-check-label font-12 align-self-center"
                                                      for={"Other"}
                                                    >
                                                      {"Other"}
                                                    </label>
                                                  </div>
                                                  <input
                                                    className={`ms-1 mt-2 form-control border--default br-10 shadow-none font-14 ${classes["input"]} `}
                                                    type="text"
                                                    id={
                                                      sampleItem?.labOrderSampleId
                                                    }
                                                    onInvalid={(e) => {
                                                      e.target.setCustomValidity(
                                                        "Please enter other sample name"
                                                      );
                                                    }}
                                                    onInput={(e) => {
                                                      e.target.setCustomValidity(
                                                        ""
                                                      );
                                                    }}
                                                    onChange={(e) => {
                                                      handleOtherSampleValue(e);
                                                    }}
                                                    // required
                                                    defaultValue={
                                                      otherSampleValue?.description ||
                                                      ""
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            );
                                          }
                                        })
                                      : null}
                                  </div>
                                </>
                              )}
                              <div className="col-6  mt-2">
                                <div className="d-flex flex-row ">
                                  <div className="align-self-center">
                                    <input
                                      className="form-check-input me-2"
                                      type="checkbox"
                                      name="fastingtype"
                                      id={"fasting"}
                                      // value={"fasting"}
                                      onChange={(e) => {
                                        handleFasting(e);
                                      }}
                                      checked={isFasting}
                                      // defaultChecked={true}
                                    />
                                    <label
                                      className="form-check-label font-12"
                                      for={"fasting"}
                                    >
                                      {__str.fasting_str}
                                    </label>
                                  </div>

                                  <div className="col-sm-2 ms-2">
                                    <input
                                      className={`form-control border--default br-10 shadow-none font-14 ${classes["input"]}`}
                                      type="number"
                                      id={"no-of-hours"}
                                      onInvalid={(e) => {
                                        e.target.setCustomValidity(
                                          "Please enter number of hours"
                                        );
                                      }}
                                      onInput={(e) => {
                                        e.target.setCustomValidity("");
                                      }}
                                      // required
                                      onChange={(e) => {
                                        handleFastingHrs(e);
                                      }}
                                      value={
                                        fastingHrs ?? ""
                                      }
                                      // disabled={!isFasting}
                                    />
                                  </div>
                                  <label
                                    className=" align-self-center font-12 "
                                    for={"no-of-hours"}
                                  >
                                    &nbsp;{__str.number_of_hrs_str}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <p className="text--danger font-14">
                          {sampleTypesError ?? ""}
                        </p>
                        <div className=" text-end mt-2 mb-2">
                          <button
                            type="submit"
                            form={"sampleForm"}
                            className="btn d9e8ec--button "
                          >
                            {"Next"}
                          </button>
                        </div>
                      </form>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
              {/* Examination  */}
              <div className="row justify-content-center">
                <div className="col-8">
                  <Accordion
                    expanded={examinationExpanded === "examination"}
                    onChange={handleChange("examination")}
                    defaultExpanded={false}
                    // className="mt-4"
                  >
                    <AccordionSummary
                      aria-controls="panelsampleDetails-content"
                      id="panelsampleDetails-header"
                    >
                      <h2 className="font-18 fw-sb m-0">
                        {__str.examinationRequested_str}
                      </h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* Labs Requested Section */}
                      <div className="row">
                        <div className=" col-sm-6 col-6">
                          <div
                            className={`input-group border--default  br-10 ${classes["search__container"]}`}
                          >
                            <input
                              type="text"
                              className={`form-control col-sm-6  font-14 border-0 br-10 shadow-none ${classes.input}`}
                              id="examtest"
                              name="examtest"
                              // ref={searchRef}
                              autoComplete="off"
                              placeholder="Search for lab tests"
                              value={examTestQuery}
                              // disabled={props?.disableSendRxButton ? true : props?.updateCheck ? true : false}
                              onChange={(event) =>
                                handleExamTestSearch(event.target.value)
                              }
                            />
                            <button
                              className={`btn br-10 shadow-none`}
                              type="submit"
                              value={examTestQuery}
                              // onClick={() => handleExamSearchBtn(examTestQuery)}
                              // disabled={props?.updateCheck ? true : false}
                              // onBlur={() => {
                              //   setTimeout(() => {
                              //     setSuggestions([]);
                              //     //   setIsError(false);
                              //   }, 5);
                              // }}
                            >
                              <SearchIcon className=" text--secondary" />
                            </button>
                          </div>

                          <div
                            ref={suggestions?.length > 0 ? ref : null}
                            className={` cursor--pointer ${classes["suggestion__list"]} hide-scrolbar`}
                            style={{
                              // width: `${props?.newPrescriptionRequest ? "44%" : "34%"}`,
                              width: "50%",
                            }}
                          >
                            {isExamReqtLoading ? (
                              <>
                                <li>Loading...</li>
                              </>
                            ) : (
                              examTestQuery?.length > 1 &&
                              suggestions?.length > 0 &&
                              suggestions.map((suggestion) => {
                                return suggestion.code !== "-1" ? (
                                  <li
                                    className={`border--default ${classes.li}`}
                                    // onClick={() => props.prescribeDrug(props.selectedDrug)}
                                    onMouseDown={() => {
                                      // setExamTestQuery("");
                                      handleExamTestList(suggestion);
                                      // setSuggestions([]);
                                    }}
                                    key={suggestion?.code}
                                  >
                                    <input
                                      type="checkbox"
                                      className="cursor--pointer"
                                      id={suggestion?.labOrderTestId}
                                      onChange={() =>
                                        handleExamTestList(suggestion)
                                      }
                                      defaultChecked={checkExamIdMatched(
                                        suggestion
                                      )}
                                    />
                                    <label className="ms-1">
                                      {suggestion?.description.concat(
                                        " ",
                                        suggestion?.alias1?.length > 0
                                          ? `(${suggestion?.alias1})`
                                          : ""
                                      )}
                                    </label>
                                  </li>
                                ) : (
                                  <li key={"not-found"}>{"Not Found"}</li>
                                );
                              })
                            )}
                            {examTestQuery?.length > 0 &&
                            suggestions?.length > 0 &&
                            suggestions[0]?.code !== "-1" ? (
                              <div
                                style={{
                                  position: "sticky",
                                  display: "flex",
                                  justifyContent: "center",
                                  padding: "8px",
                                  backgroundColor: "white",
                                  gap: "4px",
                                  bottom: 0,
                                  left: 0,
                                }}
                              >
                                <button
                                  className="btn  d9e8ec--button"
                                  style={{ height: "2.9 em !important" }}
                                  onClick={() => {
                                    setSuggestions([]);
                                    // setExaminationTestList([])
                                  }}
                                >
                                  {"Cancel"}
                                </button>
                                <button
                                  className="btn blue_task_button"
                                  onClick={() => {
                                    setSuggestions([]);
                                    setExaminationTestList(examinationTempList);
                                    setExamTestQuery("");
                                    setExaminationListErrors("");
                                  }}
                                  disabled={examinationTempList?.length === 0}
                                >
                                  {"Add"}
                                </button>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        {/* <div className="col-6"></div> */}

                        <div className="row mt-2">
                          <ListItem
                            list={examinationTestList}
                            handleDelete={handleDeleteExamTest}
                            type={"exam"}
                          />
                        </div>
                        <div className="col-12 mt-3">
                          <h2 className="font-14 fw-sb">
                            {__str.additional_Tests_str}
                          </h2>
                          <TextArea
                            className="form-control my-2 input br-10 shadow-none border--default "
                            rows={2}
                            gettext={handleAdditionalTest}
                            defaultValue={additionalTest}
                          />
                        </div>
                        <div className="col-12 mt-3">
                          <h2 className="font-14 fw-sb">
                            {__str.reason_Tests_str}
                          </h2>
                          <TextArea
                            className="form-control my-2 input br-10 shadow-none border--default "
                            rows={2}
                            gettext={handleReasonForTest}
                            defaultValue={reasonForTest}
                          />
                        </div>
                        {/* <div className="col-12 text-end mt-2">
                          <button
                            className="btn d9e8ec--button "
                            onClick={() => handleICD10Section()}
                          >
                            {"Next"}
                          </button>
                        </div> */}
                      </div>
                      {/* ICD10 Code section */}
                      <div className="row mt-3">
                          {
                            pnErx3155DiagnosisEnabled  ? 
                            <>
                              <div className=" col-sm-12 col-12">
                                <h2 className="font-14 fw-sb">{__str.icd10_codes_str}</h2>
                              </div>
                              {
                                isICD10Loading ? <Skeleton/>:
                                iCD10CodeData?.length > 0 ? 
                                <ICD10CodeListItems
                                  icd10List = {iCD10CodeData}
                                  iCD10CodeList = {iCD10CodeList}
                                  setICD10CodeList = {setICD10CodeList}
                                />:<span className="font-12 text--terciary">{"No data available"}</span>
                              }
                            </>
                            :
                            <>
                              <div className=" col-sm-6 col-6">
                                <div
                                  className={`input-group border--default  br-10 ${classes["search__container"]}`}
                                >
                                  <input
                                    type="text"
                                    className={`form-control col-sm-6  font-14 border-0 br-10 shadow-none ${classes.input}`}
                                    id="icd10-search"
                                    name="icd10-search"
                                    // ref={searchRef}
                                    autoComplete="off"
                                    placeholder="Search for ICD-10 codes"
                                    value={icd10Query}
                                    onChange={(e) =>
                                      handleICD10Search(e.target.value)
                                    }
                                    onBlur={() => {
                                      setTimeout(() => {
                                        setIcd10Suggestions([]);
                                      }, 10);
                                    }}
                                  />
                                  <button
                                    className={`btn br-10 shadow-none`}
                                    type="submit"
                                    value={icd10Query}
                                    onClick={() => handleICD10SearchBtn(icd10Query)}
                                    // disabled={props?.updateCheck ? true : false}
                                    onBlur={() => {
                                      setTimeout(() => {
                                        setIcd10Suggestions([]);
                                        //   setIsError(false);
                                      }, 5);
                                    }}
                                  >
                                    <SearchIcon className=" text--secondary" />
                                  </button>
                                </div>
                                <ul
                                  className={` cursor--pointer ${classes["suggestion__list"]} hide-scrolbar`}
                                  style={{
                                    width: "47%",
                                  }}
                                >
                                  {isICD10Loading ? (
                                    <>
                                      <li>Loading...</li>
                                    </>
                                  ) : (
                                    icd10Query?.length > 1 &&
                                    icd10Suggestions?.length > 0 &&
                                    icd10Suggestions.map((suggestionItem) => {
                                      return suggestionItem?.code !== "-1" ? (
                                        <li
                                          className={`border--default ${classes.li}`}
                                          onMouseDown={() => {
                                            setIcd10Query("");
                                            handleICD10CodeList(suggestionItem);
                                            setIcd10Suggestions([]);
                                          }}
                                        >
                                          {suggestionItem?.code +
                                            " - " +
                                            suggestionItem?.description}
                                        </li>
                                      ) : icd10Suggestions[0]?.code === "-1" ? (
                                        <li key={"xyz2"}>{"Not Found"}</li>
                                      ) : null;
                                    })
                                  )}
                                </ul>
                              </div>
                              <div className="row mt-2">
                                <ListItem
                                  list={iCD10CodeList}
                                  handleDelete={handleDeleteICD10}
                                  type={"icd10"}
                                  hideAction={false}
                                />
                              </div>
                            </>
                          }
                        {/* <div className="col-6"></div> */}
                        <p className="font-14 text--danger">
                          {examinationListErrors ?? ""}
                        </p>
                        <div className="col-12 text-end mt-2">
                          <button
                            className="btn d9e8ec--button "
                            onClick={() => handleSummarySection()}
                          >
                            {"Next"}
                          </button>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>

              {/* Summary */}
              <div className="row justify-content-center">
                <div className="col-8">
                  <Accordion
                    expanded={summaryExpanded === "summary"}
                    onChange={handleChange("summary")}
                    defaultExpanded={false}
                  >
                    <AccordionSummary
                      aria-controls="panelSummaryDetails-content"
                      id="panelSummaryDetails-header"
                    >
                      <h2 className="font-18 fw-sb">{__str.summary_str}</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="row">
                        <ProviderPatientDetails />
                        <div className="horizontal-rule my-2" />
                        {/* Sample details */}
                        {/* <SummaryItem
                          title={__str.date_collected_str}
                          description={calenderDateTimeHelper(
                            sampleDate,
                            "MMM DD, YYYY"
                          )}
                        />
                        <SummaryItem
                          title={__str.time_collected_str}
                          description={sampleTime}
                        /> */}

                        <SummaryItem
                          title={"Samples"}
                          description={
                            samplesTypes?.length > 0
                              ? samplesTypes
                                  .map((item) => item.description)
                                  .join(", ")
                              : ""
                          }
                        />
                        {otherSampleType === true ? (
                          <SummaryItem
                            title={"Other sample"}
                            description={otherSampleValue?.description}
                          />
                        ) : null}
                        <SummaryItem
                          title={__str.fasting_str}
                          description={
                            isFasting
                              ? "".concat("", fastingHrs, " hours")
                              : "Non-Fasting"
                          }
                          fasting={false}
                        />
                        {/* Examination section */}
                        <div className="horizontal-rule my-2" />
                        <SummaryItem
                          title={__str.examinationRequested_str}
                          description={
                            <ListItem
                              list={examinationTestList}
                              handleDelete={handleDeleteExamTest}
                              type={"exam"}
                              hideAction={true}
                            />
                          }
                        />
                        {additionalTest?.length > 0 ? (
                          <SummaryItem
                            title={__str.additional_Tests_str}
                            description={additionalTest}
                          />
                        ) : null}
                        {reasonForTest?.length > 0 ? (
                          <SummaryItem
                            title={__str.reason_Tests_str}
                            description={reasonForTest}
                          />
                        ) : null}

                        {/* ICD10 Code section */}
                        <div className="horizontal-rule my-2" />
                        <SummaryItem
                          title={__str.icd10_codes_str}
                          description={
                            <ListItem
                              list={iCD10CodeList}
                              handleDelete={handleDeleteICD10}
                              type={"icd10"}
                              hideAction={true}
                            />
                          }
                        />

                        {/* Signature section */}
                        <div className="horizontal-rule my-2" />
                        <div
                          className="col-12 mt-3"
                          hidden={
                            providerData !== undefined &&
                            providerData !== "" &&
                            providerData?.providerSetting &&
                            ((providerData?.providerSetting?.signaturePath !==
                              "" &&
                            providerData?.providerSetting?.signaturePath !==
                              null ) || (providerData?.providerSetting?.signature !== null))
                          }
                        >
                          <span className="font-12 text--secondary">
                            {__str.signature_str}
                          </span>
                        </div>
                        <div
                          className="col-8"
                          hidden={
                            providerData !== undefined &&
                            providerData !== "" &&
                            providerData?.providerSetting &&
                            ((providerData?.providerSetting?.signaturePath !==
                              "" &&
                            providerData?.providerSetting?.signaturePath !==
                              null ) || (providerData?.providerSetting?.signature !== null))
                          }
                        >
                          <div style={{ height: "180px" }} className="row ">
                            <div className="h-100 d-inline-block mt-1 bg--white border--default br-10 col-sm ms-2">
                              <div className="text-center align-content-center">
                                <ReactSignatureCanvas
                                  penColor="green"
                                  ref={(ref) => {
                                    setSigPad(ref);
                                  }}
                                  onBegin={() => {
                                    // onDirty();
                                  }}
                                  onEnd={() => {
                                    convertToImage();
                                  }}
                                  canvasProps={{
                                    width: 485,
                                    height: 150,
                                    className: "sigCanvas",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          {/* <CustomDatePicker date={new Date()} min={new Date()} /> */}
                        </div>
                        {/* Order Type Selction */}
                        <div className="row">
                          <div className="col-sm-3 mt-2">
                            <span className="font-12 text--secondary">
                              {"Order Type"}
                            </span>
                          </div>
                          <div className="col-sm-9 mt-2">
                          <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="orderType"
                                id={"print"}
                                value={"print"}
                                onChange={changeOrderType}
                                checked={
                                  orderType ===
                                  __str?.print_lab_order?.toLowerCase()
                                }
                              />
                              <label
                                className="form-check-label font-12"
                                htmlFor={"print"}
                              >
                                {__str.print_lab_order}
                              </label>
                            </div>
                            <div className="form-check form-check-inline ms-4">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="orderType"
                                id="fax"
                                value="fax"
                                onChange={changeOrderType}
                                checked={
                                  orderType ===
                                  __str?.fax_lab_order?.toLowerCase()
                                }
                              />
                              <label
                                className="form-check-label font-12"
                                htmlFor="fax"
                              >
                                {__str.fax_lab_order}
                              </label>
                            </div>
                            {orderType ===
                            __str.fax_lab_order?.toLowerCase() ? (
                              <div className="form-check form-check-inline font-12 text--terciary  p-0">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "start",
                                  }}
                                >
                                  <PhoneNumberInput
                                    onChange={faxHandler}
                                    id="fax"
                                    value={fax}
                                  />
                                  <span className="text--danger d-block font-10 mt-1">
                                    {faxErrors?.error === true
                                      ? "Incorrect number format"
                                      : " "}
                                  </span>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className=" horizontal-rule my-2">
                          <div className="row g-0">
                            <div className="col-8 ">
                              <div className="form-check form-check-inline mt-3">
                                <input
                                  className="form-check-input "
                                  type="checkbox"
                                  // name="orderType"
                                  id={"consent"}
                                  onChange={(e) => {
                                    handleConsent(e);
                                  }}
                                  // checked={orderType === _str?.ePrescription}
                                />
                                <label
                                  className="form-check-label font-12 align-self-center"
                                  for={"consent"}
                                >
                                  {__str.consent_str}
                                </label>
                              </div>
                            </div>
                            <div className="col-4 text-end mt-3">
                              <button
                                className="blue_task_button"
                                onClick={() => {
                                  
                                  saveSignature();
                                  sendLabOrder().then((r) => {
                                    props?.handleModal();
                                  });
                                }}
                                disabled={
                                  labOrderConsent === false
                                    ? true
                                    : examinationTestList.length === 0
                                    ? true
                                    : samplesTypes.length === 0 &&
                                      (otherSampleType === true
                                        ? otherSampleValue?.description
                                            ?.length < 1
                                          ? true
                                          : false
                                        : true)
                                    ? true
                                    : orderType === "fax" &&
                                      faxErrors?.error === true
                                    ? true
                                    : orderType === "fax" && fax?.length < 10
                                    ? true
                                    : orderType === "fax" && fax === null
                                    ? true
                                    : false
                                }
                              >
                                {props?.referenceFrom === "provider-note"
                                  ? "Proceed"
                                  : "Submit"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>

              {/* {console.log("patientId: ", patientId)} */}
              {/* {console.log("Sample types: ",samplesTypes)} */}
              {/* {console.log("sampleTime: ",sampleTime)} */}
              {/* {console.log("suggestions", suggestions)} */}
              {/* {console.log("icd10  suggestions ", icd10Suggestions)} */}
              {/* {console.log("examTestQuery: ",examTestQuery)} */}
              {/* {console.log("examinationTestList: ", examinationTestList)} */}
              {/* {console.log("examinationTempList: ", examinationTempList)} */}
              {/* {console.log("props?.editLabOrderData", props?.editLabOrderData)} */}
              {/* {console.log("samplesTypes: ",samplesTypes)} */}
              {/* {console.log("props?.editLabOrderData?.fasting: ", props?.editLabOrderData?.fasting)} */}
              {/* {console.log("isfasting: ", isFasting)} */}
            </>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LabRequisitionFormModal;

import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useDispatch, useSelector } from "react-redux";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { Link, useNavigate } from "react-router-dom";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import classes from "./NotificationDropdown.module.css";
import { Box, Divider, Typography } from "@mui/material";
import { truncate } from "../../utils/helpers/HelperFunctions";
import { dateTimeHelper } from "../../utils/dateTimeHelper";
import {
  setDiagnosis,
  setDisableSendRxButton,
  setPatientEncounterData,
  setPrescriptionNoteText,
  setUpdateEncounterData,
  updatePrescribedMedication,
} from "../../redux/actions/providerActions";
import { useAuth } from "../hooks/useAuth";
import { useRef } from "react";
import useOnClickOutside from "../hooks/useOnClickOutside";

const NotificationDropdown = (props) => {
  const MINUTE_MS = 15000;
  const { user } = useAuth();
  const notificationDropdownRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [activeAlertIcon, setActiveAlertIcon] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  useOnClickOutside(notificationDropdownRef, () => setIsOpen(p => false));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hanldeActiveALertIcon = () => {
    setActiveAlertIcon(!activeAlertIcon);
  };
  const [actionableNotificationList, setActionableNotificationList] = useState(
    []
  );
  const [notificationListCount, setNotificationListCount] = useState(0);
  const fecthNotifications = async () => {
    const httpResponse = await sendHttpRequest({
      url: "/notifications",
      method: "GET",
      params: props?.patient === true ? {
        userId: user?.userId,
        tenantId: user?.tenantId,
        status: "DELIVERED",
        type: ['NON_ACTIONABLE_CANCEL_APPT_NOTIFICATION_FOR_PATIENT', 'NON_ACTIONABLE_NEW_APPT_NOTIFICATION_FOR_PATIENT', 'NON_ACTIONABLE_RESCHEDULE_APPT_NOTIFICATION_FOR_PATIENT', 'NON_ACTIONABLE_48_HOUR_PRIOR_NOTIFICATION_FOR_PATIENT', 'NEW_APPOINTMENT_FOR_PATIENT_INSTANT_SMS'].toString()
      }
      : {
        userId: user?.userId,
        tenantId: user?.tenantId,
        status: "DELIVERED",
        type: ['APPT_ACTIONABLE', 'NPPES_ACTIONABLE', 'CLM_ACTIONABLE'].toString()
      },
    });
    if (!httpResponse.error) {
      setActionableNotificationList(httpResponse.data);
      // console.log(httpResponse.data);
    } else {
      /* Logging the error to the console. */
      console.log(httpResponse.error);
    }
  };
  const fecthNotificationCount = async () => {
    const httpResponse = await sendHttpRequest({
      url: "/notifications",
      method: "GET",
      params: props?.patient === true ? 
      {
        userId: user?.userId,
        tenantId: user?.tenantId,
        status: "DELIVERED",
        type: ['NON_ACTIONABLE_CANCEL_APPT_NOTIFICATION_FOR_PATIENT', 'NON_ACTIONABLE_NEW_APPT_NOTIFICATION_FOR_PATIENT', 'NON_ACTIONABLE_RESCHEDULE_APPT_NOTIFICATION_FOR_PATIENT', 'NON_ACTIONABLE_48_HOUR_PRIOR_NOTIFICATION_FOR_PATIENT', 'NEW_APPOINTMENT_FOR_PATIENT_INSTANT_SMS'].toString()
      }
      : {
        userId: user?.userId,
        tenantId: user?.tenantId,
        status: "DELIVERED",
        type: ['APPT_ACTIONABLE', 'NPPES_ACTIONABLE', 'PORTAL_NOTIFICATION', 'CLM_ACTIONABLE'].toString()
      },
    });
    if (!httpResponse.error) {
      setNotificationListCount(httpResponse?.data?.length);
      // console.log(httpResponse.data);
    } else {
      // console.log(httpResponse.error);
    }
  };

  const sendStartAppointmentRequest = async (notification) => {
    console.log(notification);

    let appointmentId = notification?.id;
    const httpResponse = await sendHttpRequest({
      url: `/appointment/${appointmentId}/start`,
      method: "GET",
    });
    if (!httpResponse.error) {
      navigate(
        `/consult/${notification?.appointmentSlot?.provider?.userId}/${notification?.prescriptionReference?.prescriptionReferenceId}/${notification?.appointmentSlot?.patient?.userId}`
      );
      console.log("Start appoitnment", httpResponse.data);
    } else {
      // navigate(
      //   `/consult/${notification?.appointmentSlot?.provider?.userId}/${notification?.prescriptionReference?.prescriptionReferenceId}/${notification?.appointmentSlot?.patient?.userId}`
      // );
      console.log(httpResponse.error);
    }
    // setIsSessionUpdated(false);
  };
  const handleMessageReadStatus = async (threadId) => {
    const httpResponse = await sendHttpRequest({
      url: "/messages/mark-read",
      method: "PUT",
      params: {
        userId: user?.userId,
        threadId: threadId,
        tenantId: user?.tenantId
      },
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
  };
  const handleActions = (notification) => {
    handleMessageReadStatus(notification?.threadId);

    if (props?.patient === true) {
      handleNavigation();
    } else {
      if (notification?.messageType === "APPT_ACTIONABLE") {
        // handlePatientEncouterData(notification);
      } else if (notification?.messageType === "NPPES_ACTIONABLE") {
        navigate(notification?.route);
      }else if (notification?.messageType === "CLM_ACTIONABLE") {
        fetchClaimData(notification?.claimId);
      }
    }

  };
  const fetchClaimData = async (claimId) => {
    const httpResponse = await sendHttpRequest({
      url: `/claims/tenants/${user?.tenantId}/insurance-claims/${claimId}`,
      method: "GET",
    });
    if (!httpResponse.error) {
      console.log(httpResponse.data);
      navigate(`/claim-history/${claimId}`, { state: { data: httpResponse.data } })
    } else {
      console.log(httpResponse.error);
    }
  };
  const handlePatientEncouterData = (notification) => {
    dispatch(setPrescriptionNoteText(""));
    dispatch(setDisableSendRxButton(false));
    dispatch(updatePrescribedMedication([]));
    sendStartAppointmentRequest(notification);
    dispatch(setPatientEncounterData(notification?.AppointmentJsonObject));
    dispatch(
      setUpdateEncounterData({
        Diagnosis: {
          Note: "",
          Primary: [],
          Secondary: [],
        },
      })
    );
    dispatch(
      setDiagnosis({
        Note: "",
        Primary: [],
        Secondary: [],
      })
    );
  };



  const handleNavigation = () => {
    props?.patient === true ? navigate("/patient-portal/notifications") : navigate("/notifications");
  };

  const cleanUp = () => {
    // alert("ok")
    // setActiveAlertIcon(!activeAlertIcon);
  };

  useEffect(() => {
    // return () => {
    //   cleanUp();
    // };
    fecthNotificationCount();
    fecthNotifications();
    // props?.patient === true ? fecthNonActionableNotifications() : fecthActionableNotifications();
    const interval = setInterval(() => {
      fecthNotificationCount();
      fecthNotifications()
      // props?.patient === true ? fecthNonActionableNotifications() : fecthActionableNotifications();
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, []);


  const menuClass = `dropdown-menu notifications-list  ${classes["dropdown__menue__items"]}  ${isOpen ? " show" : ""}`;
  return (
    <div ref={notificationDropdownRef} className="dropdown" onClick={() => toggleOpen()}>
      <div className="d-flex align-items-center">
        <div
          className={`bg--white rounded-circle ${classes["alert__icon__container"]} text--secondary`}
        >
          <NotificationsNoneOutlinedIcon
            className={`${classes["alert__icon"]}`}
          />
          {/* {activeAlertIcon ? (
            ) : (
              <NotificationsNoneOutlinedIcon
                className={`${classes["alert__icon__active"]}`}
              />
            )} */}
        </div>
        {/* <div hidden={!notificationListCount > 0} style={{ marginTop: -30, marginLeft: -10 }}> */}
        <div className={` ${classes["stat__value"]}`} style={{ marginLeft: -12 }}>
          <span hidden={!notificationListCount > 0} className={`bg--secondary ${classes["stat__value"]}`}>
            <span className={`bg--secondary text--white font-10`}>
              {notificationListCount !== undefined && notificationListCount}
            </span>
          </span>
        </div>
        {/* </div> */}
      </div>
      <div
        className={menuClass}
        aria-labelledby="dropdownMenuButton"

      >
        {/* <ul> */}
        {actionableNotificationList?.length > 0 &&
          actionableNotificationList?.slice(0, 5)
            // ?.slice(0, 2)
            ?.map((notificationItem, index) => {
              return (
                <Dropdown.Item
                  key={index}
                  eventKey="1"
                  onClick={() => {
                    handleActions(notificationItem);
                  }}
                >
                  <div className="d-flex flex-column">
                    <div className="d-flex flex-row justify-content-between">
                      <div className="text--terciary font-12 fw-sb">
                        {notificationItem?.subject}
                      </div>
                      <div className="text--secondary font-12 fw-sb">
                        {dateTimeHelper(
                          notificationItem?.deliveryTime,
                          "LT"
                        )}
                      </div>
                    </div>
                    <div className="d-flex text-wrap font-12 text--secondary">
                      {truncate(notificationItem?.body, 60)}
                    </div>
                    <Divider className="border--default" />
                  </div>
                </Dropdown.Item>
              );
            })}

        <Dropdown.Item className="text--blue font-12 fw-sb text-end">
          <span onClick={() => handleNavigation()}>
            View all
          </span>
        </Dropdown.Item>

        {/* {actionableNotificationList?.length > 0 ? (
            <Dropdown.Item className="text--blue font--12 fw-sb text-end text-decoration-underline">
              <span onClick={() => handleNavigation()}>
                View all Notifications
              </span>
            </Dropdown.Item>
          ) : (
            <Dropdown.Item className="text--terciary font--12 fw-sb text-end ">
              <span>No new notification</span>
            </Dropdown.Item>
          )} */}
        {/* </ul> */}
      </div>
    </div>
  );
};

export default NotificationDropdown;

import { useState, useEffect } from "react";
import PrescriptionReferenceService from "../Services/PrescriptionReference.service";

export default function usePrescriptionReference(prescriptionReferenceId) {
    const [prescriptionReferenceData, setPrescriptionReferenceData] = useState(undefined);
    const [isPrescriptionReferenceDataLoading, setIsPrescriptionReferenceDataLoading] = useState(false);

    async function fetchPrescriptionReference(id) {
        setIsPrescriptionReferenceDataLoading(true);
        const data = await PrescriptionReferenceService.getPrescriptionReferenceData(id);
        setPrescriptionReferenceData(data);
        setIsPrescriptionReferenceDataLoading(false);
    }

    useEffect(() => {
        fetchPrescriptionReference(prescriptionReferenceId);
        return () => {
            setPrescriptionReferenceData(undefined);
            setIsPrescriptionReferenceDataLoading(undefined);
        }
    }, [prescriptionReferenceId]);

    return [prescriptionReferenceData, isPrescriptionReferenceDataLoading];
}
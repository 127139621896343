import { ActionTypes } from "../constants/actionTypes"

const checkBoxInitialState = {
        timezone:''
}

const setTimezoneReducer = (state=checkBoxInitialState, action)=>{
    if (action.type === ActionTypes.SET_USER_TIMEZONE ){
        return {
            ...state,
            timezone: action.payload
        }
    }
    return state
}


export default setTimezoneReducer;
import Select from 'react-select';
import { colors } from './design-tokens';

// Custom styles for the React Select component
const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderRadius: '10px',
        borderColor: '#d9e8ec',
        boxShadow: 'none',
        fontSize: '12px',
        minHeight: '32px',
        boxShadow: state.isFocused ? '0 0 0 0px #d9e8ec' : provided.boxShadow,
        '&:hover': {
            borderColor: state.isFocused ? '#d9e8ec' : provided.borderColor,
        },


    }),
    option: (provided) => ({
        ...provided,
        fontSize: '12px',
    }),

    dropdownIndicator: (provided) => ({
        ...provided,
        color: colors.primary,
        padding: '6px',
        '& svg': {
            stroke: colors.primary, // Change the color of the chevron
            strokeWidth: '0.1', // Adjust the thickness of the chevron
        },
        '&:hover': {
            color: colors.primary
        }
    }),
};

const ValuesContainer = {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: 1,
    marginTop: '5px',

};

const Value = {
    padding: "0.3rem 0.5rem 0.3rem 0.5rem",
    margin: "0 0.55rem 0.55rem 0",
    fontSize: "0.75rem",
    borderRadius: '10px',
    color: "white",
    fontWeight: "600",
    backgroundColor: "#336383",
    userSelect: "none"
};

const XButton = {
    all: "unset",
    marginLeft: "0.4rem",
    color: "white",
    transition: "fill 0.15s ease-in-out",
    cursor: "pointer"
};





// Example usage of the React Select component with custom styles
export function PnMultiSelect(props) {
    const { isMulti, value, onChange } = props;

    const handleRemoveValue = (removedValue) => {
        if (!onChange) return;
        onChange(value.filter((val) => val.value !== removedValue.value), {
            action: "remove-value",
            removedValue
        });
    };
    return (<>
        <Select
            styles={customStyles}
            controlShouldRenderValue={!isMulti}
            {...props}
        />
        <div style={ValuesContainer}>
            {isMulti
                ? value?.map((val) => (
                    <div key={val.value} style={Value}>
                        {val.label}
                        {props?.isDisabled === true ? null : <button
                            name={val.value}
                            onClick={() => handleRemoveValue(val)}
                            style={XButton}
                        >
                            ✕
                        </button>}
                    </div>
                ))
                : null}
        </div>
    </>
    );
};
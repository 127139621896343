import {dateTimeHelper} from '../../utils/dateTimeHelper'
import placeholder from "../../assets/images/placeholder.png";
const MessageItem = (props) => {
    function truncate(str, n){
    return (str.length > n) ? str.substr(0, n-1) + `...` : str;
  };
  return (
    <div
      key={props.id}
      className={`d-flex p-3 bg-light br-10 flex-row ${props.className}` }
    >
      <div className="flex-shrink-0">
        <img
          src={
            props?.OtherUserImage
              ? props?.OtherUserImage
              : placeholder
          }
          width="40"
          height="40"
          alt={props?.Name}
          draggable={false}
          className="rounded-circle"
        />
      </div>
      <div className="flex-grow-1 ms-2">
        <div className="d-flex flex-rows justify-content-between">
          <h4 className="fw-sb  mb-0">{props?.Name} <span style={{fontSize:'9px' ,width:'16px', height:'16px'}} className="badge p-1 text-small fw-thin lh-0  rounded-pill bg__secondary">{props?.Unread}</span></h4>
          <div className="b3 text--secondary">{props?.Time !== undefined ? dateTimeHelper(props?.Time,'MMM DD, YYYY - LT'):''}</div>
        </div>
        <p className="text-wrap b2 mb-0">{truncate(props?.LastMessage ?? "This is a default message which is used to test the null coalsecing operating slkncaslk", 50)}</p>
      </div>
    </div>
  );
};

export default MessageItem;

import { useEffect, useState } from "react";
import { useAuth } from "../../../components/hooks/useAuth";
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { currencyFormatter } from "../../../utils/helpers/HelperFunctions";

import classes from "./PatientBillingSummaryCard.module.css";
import { useSelector } from "react-redux";
import sendHttpRequest from "../../../components/hooks/sendHttpRequest";
import { fetchOutStandingDuesURL } from "../../../res/endpointurls";
import { Skeleton } from "@mui/material";
import { calenderDateTimeHelper } from "../../../utils/dateTimeHelper";
import { useNavigate } from "react-router-dom";
import PatientPaidInvoices from "./PatientPaidInvoices";

const PatientBillingSummaryCard = (props) => {
    const patientInsurances = useSelector(
        (state) => state.patientInsuranceData.patientInsurance
    );
    const { user } = useAuth();
    const navigate = useNavigate();
    const [responsiblePerson, setResponsiblePerson] = useState();
    const [isOutstandingDuesLoading, setIsOutstandingDuesLoading] = useState(false);
    const [outstandingDuesObj, setOutstandingDuesObj] = useState();



    const fetchDueAmount = async () => {
        setIsOutstandingDuesLoading(true);
        const httpResponse = await sendHttpRequest({
            url: fetchOutStandingDuesURL(user.userId),
            method: "GET"
        })

        if (!httpResponse.error) {
            setOutstandingDuesObj(httpResponse.data)
        }
        setIsOutstandingDuesLoading(false)

    }

    const handlePaymentPageNavigation = (amountDueValue) => {
        if (Number(amountDueValue) > 0) {
            navigate('/patient-portal/pay-bill', { state: { amountDue: amountDueValue } })
        }
    }

    const handleViewBalanceDetails = () => {
        navigate('/patient-portal/billing-dashboard', { state: { amountDue: outstandingDuesObj?.outstandingBalance } })
    }

    const handleResponsiblePerson = (isUser, rPerson) => {
        console.log("rPerson: ",rPerson);
        if (isUser) {
            setResponsiblePerson({
                name: user?.name[0]?.firstName + " " + user?.name[0]?.lastName,
                personId: rPerson !== undefined ? rPerson?.memberId:"",
                address: user.address[0]?.addressLine1.concat(" ", user.address[0]?.addressLine1, " ", user.address[0]?.city, " ", user.address[0]?.state, ", ", user.address[0]?.postalCode),
                phone: user?.communicationNumbers?.numbers[0]?.number,
                emergencyContact: user?.communicationNumbers?.emergencyContact?.length > 0 ? user?.communicationNumbers?.emergencyContact[0]?.phonenumber : "",
                email: user?.communicationNumbers?.electronicMail[0]
            })
        } else {
            setResponsiblePerson({
                name: rPerson?.firstName + " " + rPerson?.lastName,
                personId: rPerson?.memberId,
                address: rPerson?.addressLine1.concat(" ", rPerson?.addressLine1, " ", rPerson?.city, " ", rPerson?.state, ", ", rPerson?.zipCode),
                phone: "",
                emergencyContact: "",
                email: ""
            })
        }
    }
    useEffect(() => {
        fetchDueAmount();
        let primaryInsurance = patientInsurances.find((item) => { return item.type?.toLowerCase() === "primary" })

        if (primaryInsurance !== undefined && primaryInsurance?.relationshipToInsured?.toLowerCase() !== "self") {
            handleResponsiblePerson(false, primaryInsurance)
        } else {
            handleResponsiblePerson(true,primaryInsurance)
        }
    }, [patientInsurances])

    return (
        <>
            <div className="row">
                <div className="col-sm-12">
                    {
                        isOutstandingDuesLoading ? <Skeleton /> : <div className={`${classes['patient-billing-card']} `}>
                            <div className={`px-3 py-3`}>
                                <span className="d-block fw-sb text--secondary font-14">
                                    Responsible Person # <span className="fw-thin text--terciary">109216868 &#40; <>{responsiblePerson !== undefined ? responsiblePerson?.name : ""}</> &#41;</span>
                                </span>
                                <span className="d-block text--terciary font-12">Patients included: <>{responsiblePerson !== undefined ? responsiblePerson?.name : ""}</></span>


                                <div className={`${classes['patient-billing-card-content']}`}>
                                    {/* Amount Due Title */}
                                    <div className="fw-sb text--terciary">
                                        Amount Due
                                    </div>
                                    {/* Amount Due data */}
                                    {/* If the amount is in negative use the class 'patient-billing-amount-negative' */}
                                    <div
                                        className={`${classes['patient-billing-card-amount-due']} ${Number(outstandingDuesObj?.outstandingBalance) > 0 ? `${classes['patient-billing-amount-negative']} cursor--pointer` : `${classes['patient-billing-amount-positive']}`}`}
                                        onClick={() => { handlePaymentPageNavigation(outstandingDuesObj?.outstandingBalance) }}>
                                        {currencyFormatter("USD", outstandingDuesObj?.outstandingBalance)}
                                    </div>
                                    {/* Last Paid Status */}
                                    {
                                        Number(outstandingDuesObj?.lastPaidAmount) > 0 ?
                                            <div className={`${classes['patient-billing-last-paid']}`} hidden={Number(outstandingDuesObj?.lastPaidAmount) === 0}>
                                                Last Paid: {currencyFormatter("USD", outstandingDuesObj?.lastPaidAmount)} on <>{calenderDateTimeHelper(outstandingDuesObj?.lastPaidDate, "MMM DD, YYYY")}</>
                                            </div> : null
                                    }

                                </div>


                            </div>
                            <div className={`${classes['patient-billing-card-actions-container']}`}>
                                <div
                                    onClick={handleViewBalanceDetails}
                                    className={`px-3 ${classes['patient-billing-card-action']}`}><MenuBookOutlinedIcon /> <span>View Balance Details</span></div>
                                <div className={`px-3 ${classes['patient-billing-card-action']}`}
                                    onClick={() => { navigate("/patient-portal/last-billing-summary") }}
                                ><DescriptionOutlinedIcon /> <span>View Last Statement</span></div>
                            </div>
                        </div>}

                </div>
            </div>
        </>
    )
}

export default PatientBillingSummaryCard
import { Skeleton } from '@mui/material';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import sendHttpRequest from '../../components/hooks/sendHttpRequest';
import { useAuth } from '../../components/hooks/useAuth';
import { useLocalStorage } from '../../components/hooks/useLocalStorage';
import useModal from '../../components/hooks/useModal';
import PrescriptionRequests from '../../components/pages/PrescriptionRequests';
import NewPrescriptionModal from '../../components/UI/Modals/PrescriptionModal/NewPrescriptionModal';
import VeeOneMedicationSummaryView from './VeeOneMedicationSummaryView';
import CloseIcon from "@mui/icons-material/Close";
import VeeOnePrescriptionTable from './VeeOnePrescriptionsTable';

const VeeOneOrderMedication = (props) => {
    const { open: openMedicationModal, handleOpen: handleOpenMedicationModal, handleClose: handleCloseMedicationModal, handleModal: handleMedicationModal } = useModal(false);
    const { user, loginWithoutNavigation } = useAuth();
    const [isSent, setIsSent] = useState(false);
    const [isPrescriptionSent, setIsPrescriptionSent] = useState(false);
    const [isToken, setIsToken] = useState(false);
    const [patientId, setPatientId] = useState(null);
    const [providerId, setProviderId] = useState(null);
    const [isInitializing, setIsInitializing] = useState(false);
    const [currentPrescriptionData, setCurrentPrescriptionData] = useState(null);


    const handleSetIsSent = () => {
        setIsSent(true);
    };

    async function getInternalIds(patient_external_id, provider_external_id) {
        const response = await sendHttpRequest({
            method: 'GET',
            url: `/patients/${patient_external_id}/providers/${provider_external_id}`
        });
        if (!response.error) {
            console.log(response.data);
            return response.data;
        }
    }

    async function getProviderDetails(provider_id) {
        const response = await sendHttpRequest({
            method: 'GET',
            url: `/provider/${provider_id}`
        });
        if (!response.error) {
            console.log(response.data);
            return response.data;
        }
    }

    function closeSummary() {
        setCurrentPrescriptionData(null);
    }

    useEffect(() => {
        const handleMessage = async (event) => {
            // console.log(event.origin);
            setIsInitializing(true);
            // if (event.origin !== "http://127.0.0.1:5500") return;
            // let response = event.data.response;
            // console.log(event.data);
            if (event.data.type === 'iframe-params') {
                const patient_external_id = event.data.patientId;
                const provider_external_id = event.data.providerId;
                localStorage.setItem("tkn", event.data.jwt);
                setIsToken(true);
                const ids = await getInternalIds(patient_external_id, provider_external_id);
                setPatientId(ids.patient?.userId);
                setProviderId(ids.provider?.userId);
                const response = await getProviderDetails(ids?.provider?.userId);
                loginWithoutNavigation({
                    loggedIn: true,
                    role: response?.role ?? "",
                    userId: response?.userId ?? "",
                    selectedUserId: response?.userId ?? "",
                    timezone: response?.timezoneOffset ?? "",
                    timezoneText: response?.timezone ?? "",
                    name: response?.name,
                    firstName: response?.name[0]?.firstName ?? "",
                    lastName: response?.name[0]?.lastName ?? "",
                    prefix: response?.name[0]?.prefix ?? "",
                    prescription: response?.Identification?.Prescription,
                    ePrescription: response?.identification?.status,
                    spi: response?.providerTenantPracticeLocation?.spi,
                    tenantId: response?.role === "patient" ? response?.tenant?.tenantId : response?.providerTenantPracticeLocation?.tenant?.tenantId,
                    tenant: response?.role === "patient" ? response?.tenant : response?.providerTenantPracticeLocation?.tenant,
                    facility: response?.providerTenantPracticeLocation?.facility,
                    practiceLocationId: response?.providerTenantPracticeLocation?.providerTenantPracticeLocationId,
                    isFromIframe: true
                });
                setIsInitializing(false);
                handleOpenMedicationModal();
            }

        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);
    return (
        <>
            <div className='container'>
                <div className='row justify-content-center h-100'>
                    <div className='col-sm-9 col-12'>
                        {/* <div className='d-flex justify-content-between'>
                            <h2 className='font-16 fw-sb'>Medications Ordered</h2>
                            <span
                                style={{
                                    lineHeight: "12px",
                                    width: "18px",
                                    fontSize: "8pt",
                                    color: "#336383",
                                    // position: "absolute",
                                    // top: 15,
                                    // right: 15,
                                    marginRight: 8,
                                    cursor: "pointer",
                                }}
                                onClick={closeSummary}

                            >
                                {<CloseIcon />}
                            </span>
                        </div> */}
                        {/* <VeeOneMedicationSummaryView medicationRx={currentPrescriptionData?.medicationRx} /> */}
                        <VeeOnePrescriptionTable patientId={patientId} isPrescriptionSent={isPrescriptionSent} />
                    </div>

                </div>
            </div>
            {openMedicationModal && isToken && patientId !== null ? (
                <NewPrescriptionModal
                    patientId={patientId}
                    show={openMedicationModal}
                    handleSetIsSent={handleSetIsSent}
                    handleNewPrescriptionModal={() => {
                        const message = 'Hello from the iframe!';
                        window.parent.postMessage(message, '*');
                        handleCloseMedicationModal();
                    }}
                    prescriptionRequestModal={
                        <PrescriptionRequests
                            encounterId={patientId}
                            patientId={patientId}
                            handleSetIsSent={handleSetIsSent}
                            handleNewPrescriptionModal={() => {
                                const message = 'Hello from the iframe!';
                                window.parent.postMessage(message, '*');
                                handleCloseMedicationModal();
                            }}
                            newPrescriptionRequest={true}
                            setIsPrescriptionSent={setIsPrescriptionSent}
                            setCurrentPrescriptionData={setCurrentPrescriptionData}
                        />
                    }

                />
            ) : null}
        </>
    )
}

export default VeeOneOrderMedication
import { Divider, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import sendHttpRequest from "../../hooks/sendHttpRequest";
import classes from "./changePasswordModal.module.css";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useRef } from "react";

const ChangePasswordModal = (props) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [newPasswordMatch, setNewPasswordMatch] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [savingButton, setSavingButton] = useState(false);
  const [visibilityCP, setVisibilityCP] = useState(false);
  const [visibilityNP, setVisibilityNP] = useState(false);
  const [visibilityCNP, setVisibilityCNP] = useState(false);
  const currentPswd = useRef();
  const newPswd = useRef();
  const cnewPswd = useRef();

  const handleOldPasswordChange = (event) => {
    setOldPassword(event.target.value);
    setOldPasswordError(false);
  };

  const handleNewPasswordChange = (event) => {
    if (event.target.value === confirmNewPassword) {
      setNewPasswordMatch(true);
      setDisableButton(false);
    } else {
      setDisableButton(true);
      setNewPasswordMatch(false);
    }
    setNewPassword(event.target.value);
  };

  const handleConfirmNewPasswordChange = (event) => {
    setDisableButton(false);
    if (event.target.value !== "") {
      if (event.target.value === newPassword) {
        setNewPasswordMatch(true);
        setDisableButton(false);
      } else {
        setDisableButton(true);
        setNewPasswordMatch(false);
      }
    }
    setConfirmNewPassword(event.target.value);
  };

  const handleVisibilityCP = () => {
    setVisibilityCP(!visibilityCP);
    if (visibilityCP) {
      currentPswd.current.type = "password";
    } else {
      currentPswd.current.type = "text";
    }
  };

  const handleVisibilityNP = () => {
    setVisibilityNP(!visibilityNP);
    if (visibilityNP) {
      newPswd.current.type = "password";
    } else {
      newPswd.current.type = "text";
    }
  };

  const handleVisibilityRNP = () => {
    setVisibilityCNP(!visibilityCNP);
    if (visibilityCNP) {
      cnewPswd.current.type = "password";
    } else {
      cnewPswd.current.type = "text";
    }
  };

  const sendChangePasswordRequest = async () => {
    if (oldPassword !== newPassword && confirmNewPassword === newPassword) {
      setDisableButton(true);
      setSavingButton(true);
      const httpResponse = await sendHttpRequest({
        url: "/auth/changePassword",
        method: "POST",
        data: {
          oldPassword: oldPassword,
          newPassword: newPassword,
        },
      });
      if (!httpResponse.error) {
        props?.setIsPasswordChanged(true);
        resetStates();
        props?.handleModal();
      } else if (httpResponse.error) {
        // console.log("httpResponse.error", httpResponse.errorMessage);
        setOldPasswordError(true);
        newPswd.current.value = "";
        cnewPswd.current.value = "";
        currentPswd.current.value = "";
      }
      setSavingButton(false);
    }
  };

  const resetStates = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setOldPasswordError(false);
    setNewPasswordMatch(false);
    setDisableButton(false);
    setSavingButton(false);
    setVisibilityNP(false);
    setVisibilityCNP(false);
    setVisibilityCP(false);
  };

  useEffect(() => {
    resetStates();
    currentPswd?.current?.focus();
  }, [props?.show]);

  return (
    <>
      <Modal
        show={props?.show}
        onHide={() => {
          props?.handleModal();
          resetStates();
        }}
        backdropClassName="modal-backdrop"
        contentClassName="modal-border modal-shadow"
        size="sm"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName={`${classes["custom-modal-style"]}`}
        centered
      >
        <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
        <Modal.Body>
          <form
            id="changePasswordForm"
            onSubmit={(e) => {
              e.preventDefault();
              sendChangePasswordRequest();
            }}
          >
            <div className="container" style={{ marginTop: -20 }}>
              <div className="row ">
                <div className="col-sm-12 ">
                  <div className="d-flex flex-column align-self-center">
                    <div className="d-flex font-18 fw-sb justify-content-center">
                      Change Password
                    </div>

                    <Stack direction="column" className="mt-4">
                      <label
                        htmlFor="Current Password*"
                        className="form-label font-10 text--secondary mb-0"
                      >
                        Current password*
                      </label>
                      <Stack
                        direction="row"
                        justifyContent={"between"}
                        spacing={2}
                      >
                        <div
                          className="col-sm-12"
                          style={{
                            backgroundColor: "#ffffff",
                            border: "1px solid #D9E8EC",
                            borderRadius: "10px",
                          }}
                        >
                          <div className="row">
                            <div className="col-sm-9 justify-content-start align-self-center">
                              <input
                                ref={currentPswd}
                                type="password"
                                className={`form-control font-12 border--white br-10 shadow-none input ${classes.inputFocus}`}
                                id="old_password"
                                onChange={(e) => handleOldPasswordChange(e)}
                                required
                              />
                            </div>
                            <div className="col-sm-1 ms-3 align-self-center">
                              {visibilityCP ? (
                                <VisibilityOutlinedIcon
                                  id="old_password"
                                  onClick={handleVisibilityCP}
                                  style={{
                                    cursor: "pointer",
                                    color: "#d9e8ec",
                                    height: 20,
                                    weight: 20
                                  }}
                                />
                              ) : (
                                <VisibilityOffOutlinedIcon
                                  id="old_password"
                                  onClick={handleVisibilityCP}
                                  style={{
                                    cursor: "pointer",
                                    color: "#d9e8ec",
                                    height: 20,
                                    weight: 20
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </Stack>
                    </Stack>

                    <Divider className="my-4" style={{ color: "#d9e8ec" }} />
                    <Stack direction="column" className="">
                      <label
                        htmlFor="New Password*"
                        className="form-label font-10 text--secondary mb-0"
                      >
                        New password*
                      </label>
                      <Stack
                        direction="row"
                        justifyContent={"between"}
                        spacing={2}
                      >
                        <div
                          className="col-sm-12"
                          style={{
                            backgroundColor: "#ffffff",
                            border: "1px solid #D9E8EC",
                            borderRadius: "10px",
                          }}
                        >
                          <div className="row">
                            <div className="col-sm-9 justify-content-start align-self-center">
                              <input
                                ref={newPswd}
                                type="password"
                                className={`form-control font-12 border--white br-10 shadow-none input ${classes.inputFocus}`}
                                id="new_password"
                                onChange={(e) => handleNewPasswordChange(e)}
                                required
                              />
                            </div>
                            <div className="col-sm-1 ms-3 align-self-center">
                              {visibilityNP ? (
                                <VisibilityOutlinedIcon
                                  id="new_password"
                                  onClick={handleVisibilityNP}
                                  style={{
                                    cursor: "pointer",
                                    color: "#d9e8ec",
                                    height: 20,
                                    weight: 20
                                  }}
                                />
                              ) : (
                                <VisibilityOffOutlinedIcon
                                  id="new_password"
                                  onClick={handleVisibilityNP}
                                  style={{
                                    cursor: "pointer",
                                    color: "#d9e8ec",
                                    height: 20,
                                    weight: 20
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </Stack>
                    </Stack>

                    <Stack direction="column" className="mt-4">
                      <label
                        htmlFor="Repeat New Password*"
                        className="form-label font-10 text--secondary mb-0"
                      >
                        Confirm new password*
                      </label>
                      <Stack
                        direction="row"
                        justifyContent={"between"}
                        spacing={2}
                      >
                        <div
                          className="col-sm-12"
                          style={{
                            backgroundColor: "#ffffff",
                            border: "1px solid #D9E8EC",
                            borderRadius: "10px",
                          }}
                        >
                          <div className="row">
                            <div className="col-sm-9 justify-content-start align-self-center">
                              <input
                                ref={cnewPswd}
                                type="password"
                                className={`form-control font-12 border--white br-10 shadow-none input ${classes.inputFocus}`}
                                id="repeat_new_password"
                                onChange={(e) =>
                                  handleConfirmNewPasswordChange(e)
                                }
                                required
                              />
                            </div>
                            <div className="col-sm-1 ms-3 align-self-center">
                              {visibilityCNP ? (
                                <VisibilityOutlinedIcon
                                  id="new_password"
                                  onClick={handleVisibilityRNP}
                                  style={{
                                    cursor: "pointer",
                                    color: "#d9e8ec",
                                    height: 20,
                                    weight: 20
                                  }}
                                />
                              ) : (
                                <VisibilityOffOutlinedIcon
                                  id="new_password"
                                  onClick={handleVisibilityRNP}
                                  style={{
                                    cursor: "pointer",
                                    color: "#d9e8ec",
                                    height: 20,
                                    weight: 20
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </Stack>
                    </Stack>

                    <div className="d-flex justify-content-center my-3">
                      <button
                        className={`btn bg--blue ${classes.task_button} text-white b2 px-4 br-10 py-1 fw-sb`}
                        form="changePasswordForm"
                        type="submit"
                        disabled={disableButton}
                      >
                        {savingButton ? "Updating..." : "Update"}
                      </button>
                    </div>
                    <div className="d-flex flex-column justify-content-center ">
                      <div hidden={newPasswordMatch}>
                        <p className="text--danger font-10">
                          {newPasswordMatch === false &&
                            confirmNewPassword?.length > 0 &&
                            "New password must match"}
                        </p>
                      </div>
                      <div hidden={!oldPasswordError}>
                        <p className="text--danger font-10">
                          {oldPasswordError && "Invalid current password"}
                        </p>
                      </div>
                      <div
                        hidden={
                          oldPassword !== "" &&
                          newPassword !== "" &&
                          oldPassword === newPassword
                            ? false
                            : true
                        }
                      >
                        <p className="text--danger font-10">
                          {"Current password and new password cannot be same"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;

import { useEffect, useState } from "react";
import PageHeader from "../../generic/PageHeader";
import sendHttpRequest from "../../../components/hooks/sendHttpRequest";
import { fetchLastBillURL } from "../../../res/endpointurls";
import { useAuth } from "../../../components/hooks/useAuth";
import { method } from "lodash";
import { Skeleton } from "@mui/material";
import { InvoiceDetailItem } from "./InvoiceDetailItems";
import BillingCardContainer from "./BillingCardContainer";

const PatientMostRecentBillDetail = () => {
  const { user } = useAuth();

  const [lastBillData, setLastBillData] = useState();
  const [isLastBillLoading, setIsLastBillLoading] = useState(false);

  const fetchLastBill = async () => {
    setIsLastBillLoading(true);
    const httpResponse = await sendHttpRequest({
      url: fetchLastBillURL(user.userId),
      method: "GET",
    });

    if (!httpResponse.error) {
      setLastBillData(httpResponse.data);
    }

    setIsLastBillLoading(false);
  };

  useEffect(() => {
    fetchLastBill();
  }, []);
  return (
    <>
      <PageHeader title={"Summary"} />
      <div className="row justify-content-center mt-1">
        <div className="col-10">
            {
                isLastBillLoading ? <Skeleton/> : 
                lastBillData !== undefined ?
                <BillingCardContainer>
                    <InvoiceDetailItem
                        invoicesItem={lastBillData}
                        mostRecent = {true}
                    />
                </BillingCardContainer>
                :<span className="font-12 text--terciary">{"No data available"}</span>
            }
        </div>
      </div>
    </>
  );
};

export default PatientMostRecentBillDetail;

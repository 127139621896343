import React, { useState } from 'react'
import { Typography } from '@mui/material'
import LoginModal from '../Modals/LoginModal';
import axios from "axios";
import { ConfigMap } from '../../../../utils/helpers/ConfigMap';
import { useLocation } from 'react-router-dom';
import sendHttpRequest from '../../../hooks/sendHttpRequest';
import PatientApptReservedModal from '../../../../patientportal/patientmodals/PatientApptReservedModal';
import { useAuth } from '../../../hooks/useAuth';


function Footer(props) {

    const { facilityId, patientVisitType, chiefComplaint,type, price, postAnswerAfterLogin, handlePost, handleBack, isFirst, isLast, hideSignin, handleLoginClick } = props;
    const [open, setOpen] = useState(false);
    const [openApptResrvModal, setOpenApptResrvModal] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [username, setUsername] = useState(false);
    const [answer, setAnswer] = useState(false);
    const [apptData, setApptData] = useState();
    const [isReseRveAppt, setIsreseRveAppt] = useState(false);
    const baseURL = ConfigMap("APP");
    const location = useLocation();
    // const type = location?.state?.type

    // console.log("type", type);
    const { user } = useAuth();

    function handleForward() {
        // funcMap(location.pathname)();
        reserveAppt();
    }

    function getUserInfo() {
        // setIsLoading(true);
        axios.defaults.withCredentials = true;
        axios.get(baseURL + '/auth/patient/loginInfo')
            // axios.post('http://202.163.76.100:11235/assessmentForm', answer == "" ? [{}] : answer)
            .then(function (response) {
                // console.log(response.data);
                let obj = response.data

                if (obj.loggedIn) {
                    let string = {
                        loggedIn: obj.loggedIn,
                        role: obj?.role,
                        timezone: obj?.timezone
                    };
                    let string2 = JSON.stringify(string);
                    localStorage.setItem("role", obj.role);
                    localStorage.setItem("timezone", obj.timezone);
                    localStorage.setItem("user", string2);
                    localStorage.setItem("sessionexp", true);
                    setTimeout(() => {
                        // window.location.replace("https://www.prescribenow.com");
                        window.location.replace("../patient-portal/dashboard");
                    }, 20)
                }
            })
            .catch(function (error) {
                console.log(error.response);
            });
    }

    const reserveAppt = async () => {
        setIsreseRveAppt(true);
        const httpResponse = await sendHttpRequest({
            url: "/appointment/reserve",
            method: "POST",
            data: {
                prescriptionReference: {
                    patient: { userId: user?.userId },
                    provider: { userId: props?.providerId }
                },
                chiefComplaint: chiefComplaint,
                comments: "",
                startTime: props?.appoinmentStartTime,
                appointmentType: type,
                appointmentSlot: {
                    patient: { userId: user?.userId },
                    providerTenantPracticeLocation: {
                        provider: {
                            userId: props?.providerId
                        },
                        tenant: {
                            tenantId: user?.tenantId
                        },
                        facility: {
                            facilityId: facilityId
                        },
                    },
                    startDateTime: props?.appoinmentStartTime,
                    endDateTime: props?.appoinmentEndTime,
                    duration: type?.durationMinutes,
                    startDate: props?.scheduleDateToSend,

                },
                patientVisitType: { internalValue: patientVisitType },
                // startTime: props?.appoinmentStartTime,
                // PrescriberId: 0,
                // AppointmentType: "new",
                // date: props?.scheduleDateToSend,
                // Duration: 45,
            },
        });
        if (!httpResponse.error) {
            setApptData({ ...httpResponse.data, price });
            handleApptReserveModal();
            setIsreseRveAppt(false);
            // console.log(httpResponse.data);
            let jsonApptDto = {
                prescriptionReference: {
                    patient: {
                        userId: user?.userId,
                    },
                    provider: {
                        userId: httpResponse.data?.providerTenantPracticeLocation?.provider?.userId,
                    },
                },
                chiefComplaint: chiefComplaint,
                comments: "",
                startTime: props?.appoinmentStartTime,
                appointmentType: type,
                appointmentSlot: {
                    id: httpResponse.data.id,
                    provider: {
                        userId: httpResponse.data?.providerTenantPracticeLocation?.provider?.userId
                    },
                    providerTenantPracticeLocation: {
                        provider: {
                            userId: httpResponse.data?.providerTenantPracticeLocation?.provider?.userId
                        },
                        tenant: {
                            tenantId: user?.tenantId
                        },
                        facility: {
                            facilityId: facilityId
                        },
                    },
                    patient: {
                        userId: user?.userId,
                    },
                    startDateTime: props?.appoinmentStartTime,
                    endDateTime: props?.appoinmentEndTime,
                    duration: type?.durationMinutes,
                    startDate: props?.scheduleDateToSend,
                },
                patientVisitType: { internalValue: patientVisitType },
            };

            localStorage.setItem("apptDto", JSON.stringify(jsonApptDto));

            // props.setIsSentAppointment(true);
        } else {
            setIsreseRveAppt(false);
            props?.setError("Please select time slot and date");
            // props?.setIsAppointmentNotRescheduled(true);
            // props?.setAppointmentNotScheduledError(httpResponse.errorMessage);
            console.log(httpResponse.error);
        }
    }


    function goBack(params) {
        handleBack();
    }

    const handleApptReserveModal = () => {
        setOpenApptResrvModal(!openApptResrvModal)
    }

    const funcMap = function (url) {
        const urls = new Map();
        // urls.set("/new/appointment", reserveAppt);
        urls.set("/new/appointment", reserveAppt);
        return urls.get(url);
    };


    return (
        <>
            <PatientApptReservedModal type={type} chiefComplaint={chiefComplaint} price={price} show={openApptResrvModal} apptData={apptData} handleApptReserveModal={handleApptReserveModal} url={"/new/payment"} />
            <div className="d-flex justify-content-between p-3 fixed-bottom bg-white">
                <div></div>
                <div>
                    <button hidden={isFirst ? true : false || isLast ? true : false} style={{ backgroundColor: "#D9E8EC", color: "#004FD4", borderRadius: "10px", fontSize: "14px", marginRight: "2px", fontWeight: 600 }} className="btn btn__bottom" onClick={(event) => { goBack(event) }}>
                        Previous
                    </button>
                    <button hidden={props?.ishidden} disabled={isReseRveAppt} id={"next"} style={{ backgroundColor: "#004FD4", marginLeft: "10px", color: "#ffffff", borderRadius: "10px", fontSize: "14px", marginRight: "2px", fontWeight: 600 }} className="btn btn__bottom" onClick={(event) => { handleForward(event) }}>
                        {isLast ? "Finish" : "Next"}
                    </button>
                </div>
            </div>
        </>
    )
}

export default Footer;
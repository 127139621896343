import TextArea from "../forms/TextArea";
import sendHttpRequest from "../hooks/sendHttpRequest";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Divider from "@mui/material/Divider";
import { makeStyles } from "@mui/styles";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { dateTimeHelper } from "../../utils/dateTimeHelper";
import { Skeleton } from "@mui/material";
import classes from "./ReviewOfSystems.module.css";
import { useDispatch, useSelector } from "react-redux";
// import { Empty } from 'antd';
import {
  setAllPhysicalSystemsStatus,
  setPatientEncounterData,
  setUpdateEncounterData,
} from "../../redux/actions/providerActions";
import { patientEncountersMetadataUrl } from "../../res/endpointurls";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTimelineDot-root": {
      filled: {
        "& .MuiTimelineDot-filled": {
          backgroundColor: "#323232",
        },
      },
    },
  },
}));

const ReviewOfSystems = (props) => {
  const updateEncounterData = useSelector(
    (state) => state.UpdateEncounter.Encounter
  );
  const patientEncounterInformation = useSelector(
    (state) => state.PatientEncounterData.appoinment
  );
  const isCheckedAllPhysicalSystems = useSelector(
    (state) => state.CheckAllPhysicalSystems
  );
  const dispatch = useDispatch();

  const { providerId, encounterId, patientId } = useParams();

  let noteString = "";
  const uiclasses = useStyles();

  const [notes, setNotes] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [textareaDisable, setTextareaDisable] = useState(false);
  const textAreaRef = useRef();

  const fetchReviewOfSystems = async (patientId) => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      // url: "/reviewOfSystems",
      url: patientEncountersMetadataUrl(patientId, encounterId),
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log("Review of system", httpResponse.data);
      setNotes(httpResponse.data);
      // setTextareaDisable(httpResponse.data[0]?.allSystemsOk || false);

      setIsLoading(false);
    } else {
      console.log(httpResponse.error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReviewOfSystems(patientId);
  }, [patientId]);

  function getText(text) {
    // console.log("text===================", text);
    props.setUnsavedChanges(true);
    noteString = text;
    dispatch(
      setUpdateEncounterData({
        ...updateEncounterData,
        encounterId: encounterId,
        allSystemOk: textareaDisable,
        reviewOfSystem: text,
      })
    );
    dispatch(
      setPatientEncounterData({
        ...patientEncounterInformation,
        encounter: {
          ...patientEncounterInformation.encounter,
          reviewOfSystem: text,
        },
      })
    );
  }

  function onChecked(e) {
    props.setUnsavedChanges(true);
    if (e.target.checked) {
      // document.getElementById("reviewOfSystems").value =
      //   "Review of systems negative except what is indicated in HPI";
      dispatch(
        setUpdateEncounterData({
          ...updateEncounterData,
          encounterId: encounterId,
          allSystemOk: true,
          reviewOfSystem:
            "Review of systems negative except what is indicated in HPI",
        })
      );
      dispatch(
        setPatientEncounterData({
          ...patientEncounterInformation,
          prescriptionReference: {
            ...patientEncounterInformation?.prescriptionReference,
            encounter: {
              ...patientEncounterInformation?.prescriptionReference?.encounter,
              reviewOfSystem:
                "Review of systems negative except what is indicated in HPI",
            }
          }
        })
      );
      dispatch(setAllPhysicalSystemsStatus(true));
      setTextareaDisable(true);
    } else {
      document.getElementById("reviewOfSystems").value = "";
      setTextareaDisable(!textareaDisable);
      dispatch(setAllPhysicalSystemsStatus(false));
      dispatch(
        setUpdateEncounterData({
          ...updateEncounterData,
          encounterId: encounterId,
          allSystemOk: false,
          reviewOfSystem: "",
        })
      );
      dispatch(
        setPatientEncounterData({
          ...patientEncounterInformation,
          encounter: {
            ...patientEncounterInformation.encounter,
            reviewOfSystem:
              "",
          },
        })
      );
      // setTextareaDisable(isCheckedAllPhysicalSystems.isChecked);
    }
  }
  // useEffect(() => {
  //   console.log("Use effect: ",props?.encounterApiData?.encounter?.allSystemsOk);
  //   if (props?.encounterApiData?.encounter?.allSystemsOk !== undefined) {
  //     dispatch(
  //       setAllPhysicalSystemsStatus(
  //         props?.encounterApiData?.encounter?.allSystemsOk
  //       )
  //     );
  //     setTextareaDisable(props?.encounterApiData?.encounter?.allSystemsOk);
  //   }
  // }, []);
  return (
    <>
      <div className="my-5">
        <h4 className="h18 my-3">Review of Systems</h4>
        <input
          className={`form-check-input input ms-0 ${classes["hpiinput"]}`}
          type="checkbox"
          onChange={onChecked}
          name="rovcb"
          id="rovcb"
          checked={
            isCheckedAllPhysicalSystems.isChecked
          }
        />
        <label className={`form-check-label shadow-none  ms-2 font-12`} for="rovcb">
          {/* All physical systems are ok. */}
          Review of systems negative except what is indicated in HPI
        </label>
        <TextArea
          className="form-control br-10 shadow-none input border--default my-2"
          // placeholder="Write your note"
          id={"reviewOfSystems"}
          rows={5}
          gettext={getText}
          disabled={
            isCheckedAllPhysicalSystems.isChecked
          }
          defaultValue={
            isCheckedAllPhysicalSystems.isChecked === true
              ? ""
              :
              patientEncounterInformation?.encounter?.reviewOfSystem ||
              props?.encounterApiData?.encounter?.reviewOfSystem ||
              ""
          }
        // defaultValue = {textareaDisable ? "All physical systems are ok.":''}
        />
        {/* {console.log(patientEncounterInformation?.encounter?.reviewOfSystem, props?.encounterApiData?.encounter?.reviewOfSystem)} */}
        <div className="my-4">
          <Divider sx={{ color: "#336383" }} />
        </div>
        {isLoading ? (
          <Timeline>
            <Skeleton
              animation="wave"
              variant="text"
              width={"100%"}
              height={200}
            />
          </Timeline>
        ) : (
          <Timeline>
            {notes?.length > 0
              ? notes?.map((encounter, index) => {
                if (
                  encounter !== undefined
                ) {
                  return encounter?.reviewOfSystem !== undefined &&
                    encounter?.reviewOfSystem !== null &&
                    encounter?.reviewOfSystem?.trim() !== "" ? (
                    <TimelineItem key={encounter + index}>
                      <TimelineSeparator>
                        <TimelineDot
                          style={{ backgroundColor: "var(--terciary)" }}
                        />
                        <TimelineConnector
                          style={{ backgroundColor: "var(--d9e8ec)" }}
                        />
                      </TimelineSeparator>
                      <TimelineContent>
                        <h4 className="mb-0 font-12">
                          {encounter?.encounterCreatedDate !== undefined
                            ? dateTimeHelper(
                              encounter?.encounterCreatedDate,
                              "MMM D, YYYY - LT"
                            )
                            : ""}
                        </h4>
                        <p
                          className="mt-2 font-16"
                          style={{ color: "var(--secondary)" }}
                        >
                          {encounter?.reviewOfSystem}
                        </p>
                      </TimelineContent>
                    </TimelineItem>
                  ) : (
                    ""
                  );
                }
              })
              : ""}
          </Timeline>
        )}
      </div>
      {/* {console.log("updateEncounterData", updateEncounterData)} */}
      {/* {console.log("Appointment Encounter",patientEncounterInformation)} */}
      {/* {console.log("isCheckedAllPhysicalSystems", isCheckedAllPhysicalSystems)} */}
      {/* {console.log("textareaDisable",textareaDisable)} */}
      {/* {console.log("isCheckedAllPhysicalSystems.isChecked ", isCheckedAllPhysicalSystems.isChecked, patientEncounterInformation)} */}
    </>
  );
};

export default ReviewOfSystems;

import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from '@mui/icons-material/Clear';
import classes from "./CptCodeSearch.module.css";
import { PnToolTip } from "../UI/Reusable/PnComponents";

const CptCodeSearch = (props) => {
    return (
        <div id="parentdiv">
            <div className="input-group" style={{
                border: "1px solid #D9E8EC",
                borderRadius: "10px"
            }}>
                <input type="text" disabled={props?.disabled} className={`form-control font-12 br-10 shadow-none border-0  ${classes.placeholder} `} placeholder="Search medical code" value={props.cptCodeName} onChange={e => {
                    props.handleSearchInput(e.target.value);

                }} onBlur={() => {
                    setTimeout(() => {
                        props.setSuggestions([]);
                        props.setIsError(false);
                        props.setIsLoading(false);
                    }, 10);
                }} />

                <button disabled={props?.disabled} className={`border-0 outline-0 px-1 ${props?.disabled === true ? 'disabled' : 'bg-white'}  br-10 shadow-none zindex-1`} type="submits" value={props.searchInputValue} onClick={() => {
                    if (props?.selectedCptCode === null || props.selectedCptCode === undefined) {

                        props.handleSearchInput(props?.searchInputValue);
                    }
                    else {
                        props?.handleClearCptCode();
                    }
                }} onBlur={() => {
                    setTimeout(() => {
                        props.setSuggestions([]);
                        props.setIsError(false);
                        props.setIsLoading(false);
                    }, 5);
                }}>
                    {props?.selectedCptCode != null && props?.selectedCptCode != undefined && props?.cptCodeName !== null && props?.cptCodeName !== undefined && props?.cptCodeName?.length > 0 ? <ClearIcon fontSize="small" className="text--danger" /> : <SearchIcon fontSize="small" className="text--secondary" />}
                </button>
            </div>

            <ul className={`list-group ${classes["cpt_suggestions"]}`} style={{ width: `${document.getElementById('parentdiv')?.offsetWidth}px` }}>
                {props.isError ? <li className={`list-group-item ${classes["search__list__item"]}`}>
                    No result found
                </li> : props?.isLoading ? <li className={`list-group-item ${classes["search__list__item"]}`}>
                    Loading...
                </li> : props.suggestions.length > 0 && props.suggestions.map((suggestion, index) => {
                    return <PnToolTip title={`${suggestion.code} - ${suggestion.description}`} tabIndex={index}><li tabIndex={index} // className="list-group-item"
                        className={`border--default ${classes.li} ${classes["search__list__item"]}`} style={{ width: `${document.getElementById('parentdiv')?.offsetWidth}px` }} key={suggestion.code} onMouseDown={() => props.handleAddCptCode(suggestion)} // onKeyDown = {()=>console.log("works")}
                    >
                        {/* <span>{suggestion.code}</span> */}
                        <span className="ms-1">{suggestion.code} - {suggestion.description}</span>
                    </li></PnToolTip>;
                })}
            </ul>
        </div>
    )
}

export default CptCodeSearch
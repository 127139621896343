import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import makeStyles from "@mui/styles/makeStyles";
import { InputBase } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SvgIcon from "@mui/material/SvgIcon";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      minWidth: 350,
      marginTop: "8px",
      marginLeft: "8px"
    },
  },
};

const useStyles = makeStyles({
  select: {
    "&:before": {
      borderColor: "white",
    },
    "&:after": {
      borderColor: "white",
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: "white",
    },
  },
  icon: {
    fill: "red",
  },
  root: {
    color: "red",
  },
  listItemText: {
    fontSize: "14px", //Insert your required size
  },
});

const MultipleSelectDropdown = (props) => {
  // const rlist  = ["Amphetamines",
  // "Amyl nitrates/ Poppers"
  // ,"Cannabis/ Marijuana/ THC"]
  const [recreationalDrugNameList, setRecreationalDrugNameList] =
    React.useState([]);


  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setRecreationalDrugNameList(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    let ids = [];
    let answers = event.target.value;
    for (let r = 0; r < answers.length; r++) {
      for (let i = 0; i < props.recreationalDrugs.length; i++) {
        if (props.recreationalDrugs[i].answer == answers[r]) {
          ids.push(props.recreationalDrugs[i].answerId);
        }
      }
    }
    props.setRecreationalDrugsAnswers(ids);
    let index = -1;
    for (let a = 0; a < props.answeresData.length; a++) {
      if (props.answeresData[a].questionId == props.questionId) {
        props.answeresData[a].answer = ids;
      }
    }
  };
  const classes = useStyles();

  React.useEffect(() => {
    let ansData = [];
    
    for (let i = 0; i < props.recreationalDrugs.length; i++) {
      for (let j = 0; j < props.recreationalDrugsAnswersList?.length; j++) {
        if (props.recreationalDrugs[i]?.answer == props.recreationalDrugsAnswersList[j]) {
          ansData.push(props.recreationalDrugs[i].answer);
        }
      }
    }

    let ids = [];
    for (let r = 0; r < ansData.length; r++) {
      for (let i = 0; i < props.recreationalDrugs.length; i++) {
        if (props.recreationalDrugs[i].answer == ansData[r]) {
          ids.push(props.recreationalDrugs[i].answerId);
        }
      }
    }
    props.setRecreationalDrugsAnswers(ids);
    let index = -1;
    for (let a = 0; a < props.answeresData.length; a++) {
      if (props.answeresData[a].questionId == props.questionId) {
        props.answeresData[a].answer = ids;
      }
    }
    setRecreationalDrugNameList(ansData);
  }, [props.recreationalDrugs]);

  const CustomSvgIcon = () => (
    <SvgIcon style={{ fill: "#EC609B", }} className="cursor--pointer">
      <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
    </SvgIcon>
  );


  return (
    <div>
      <FormControl
        style={{
          border: "1px solid #d8e9ec",
          borderRadius: "10px",
          width: "100%",
          minWidth: "100%",
        }}
      >
        {/* <InputLabel id="demo-multiple-checkbox-label"> Tag</InputLabel> */}
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          displayEmpty
          value={recreationalDrugNameList}
          onChange={handleChange}
          style={{ borderTop: "1px solid #d9e8ec", fontSize: "14px", height: "40px" }}
          input={<InputBase className="border-0 font-14" />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <span className="ps-2">Check all that apply</span>;
            }

            return selected.join(", ");
          }}
          MenuProps={MenuProps}
          className={"p-1 font-14"}

          IconComponent={CustomSvgIcon}
        >
          {/* <MenuItem disabled value="">
            <em>Select answers</em>
          </MenuItem> */}
          {props.recreationalDrugs?.map((rdrug) => (
            <MenuItem key={rdrug.answerId} value={rdrug.answer} data-value={rdrug.answerId} className={"p-0"} dense={true}>
              <Checkbox
                checked={recreationalDrugNameList.indexOf(rdrug.answer) > -1}
                className={"ps-.5 pt-0 pb-0 br-10"}
              />
              <ListItemText className={"pt-0 align-self-center"}>
                <span
                  style={{ fontSize: "14px" }}
                  className={
                    recreationalDrugNameList.indexOf(rdrug.answer) > -1 &&
                    "fw-sb pt-0"
                  }
                >
                  {rdrug.answer}
                </span>
              </ListItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/*  {console.log("recreationalDrugNameList", recreationalDrugNameList)} */}
    </div>
  );
};

export default MultipleSelectDropdown;

import { useNavigate } from "react-router-dom";


const useClaimsSubmission = () => {
    const navigate = useNavigate();
    function navigateToClaimsSubmissionPage(prescriptionReferenceId, encounterId, patientId, ...optionalStates) {
        navigate(`/claims/${encounterId}/claim-submission`, { state: { prescriptionReferenceId: prescriptionReferenceId, encounterId: encounterId, patientId: patientId, ...optionalStates } });
    }

    return {
        navigateToClaimsSubmissionPage
    }
}

export default useClaimsSubmission;
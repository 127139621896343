import { ActionTypes } from "../constants/actionTypes";

const videoContainerInitialState = { isVideoOpen: false };

const setVideoOpenReducer = (state = videoContainerInitialState, action) => {
  if (action.type === ActionTypes.SET_VIDEO_OPEN) {
    return {
      ...state,
      isVideoOpen: action.payload,
    }
  }
  return state;
};

export default setVideoOpenReducer;

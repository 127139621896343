import { ActionTypes } from "../constants/actionTypes"

const prescriptionNote = {
    prescriptionNoteValue:""
}

const setPrescriptionNoteReducer = (state=prescriptionNote, action)=>{
    if (action.type === ActionTypes.SET_PRESCRIPTION_NOTE_TEXT ){
        return {
            ...state,
            prescriptionNoteValue: action.payload
            
        }
    }
    return state
}


export default setPrescriptionNoteReducer;
import { Avatar, Box, Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import sendHttpRequest, { search } from "../../components/hooks/sendHttpRequest";
import { addTimeHelper, dateTimeHelper } from "../../utils/dateTimeHelper";
import { Skeleton } from "@mui/material";
import placeholder from "../../assets/images/placeholder.png";
import { useNavigate } from "react-router-dom";
import { strings } from "../../res/strings";
import { useAuth } from "../../components/hooks/useAuth";
import { PnToolTip } from "../../components/UI/Reusable/PnComponents";
import { truncate } from "../../utils/helpers/HelperFunctions";
import { useContext } from "react";
import { VideoCallContext } from "../../components/UI/AgoraHooks/VideoCallContext";
import { useInterval } from "../../components/hooks/useInterval";
import useModal from "../../components/hooks/useModal";
import MakePaymentModal from "../../components/payment/MakePaymentModal";
import PatientPortalVideoCallModal from "../patientvideoconsult/PatientPortalVideoCallModal";
import { downloadPatientAfterVisitSummaryURL } from "../../res/endpointurls";
import { ConfigMap } from "../../utils/helpers/ConfigMap";



const canPatientConnectToVideo = async (appId) => {
  // let appId = appointment?.id;
  const httpResponse = await sendHttpRequest({
    method: 'GET',
    url: `/video/JoinRequestStatus/${appId}`
  });
  if (!httpResponse.error) {
    // console.log(httpResponse);
    if (httpResponse?.data !== undefined && httpResponse.data?.toLowerCase() === "end") {
      return false;
    }
    else if (httpResponse?.data !== undefined && (httpResponse.data?.toLowerCase() === "new" || httpResponse.data?.toLowerCase() === "leave")) {
      return true
    }
  }
}

const UpcomingAppointment = (props) => {

  const videoCallContext = useContext(VideoCallContext);
  const [isLoading, setIsLoading] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [error, setError] = useState("");
  const [foodAllergy, setFoodAllergy] = useState("");
  const [drugAllergy, setDrugAllergy] = useState("");
  const navigate = useNavigate();
  const { user } = useAuth();
  const [allergies, setAllergies] = useState();
  const [isAllergyLoading, setIsAllergyLoading] = useState(false);
  const [canConnectVideo, setCanConnectVideo] = useState([]);


  const [arePastAppointmentsLoading, setArePastAppointmentsLoading] = useState(false);
  const [pastAppointments, setPastAppointments] = useState(null);

  const [currentIndex, setCurrentIndex] = useState(props?.index);

  function getVideoConnectStatus(appointmentId) {
    const status = canConnectVideo?.filter(app => app?.id === appointmentId)?.at(0)?.canConnectVideo;
    return status;
  }

  const {
    open,
    handleOpen,
    handleModal
  } = useModal();

  const {
    open: openVideoModal,
    handleOpen: handleOpenVideoModal,
    handleModal: handleVideoModal
  } = useModal();

  const fetchAllergy = async () => {
    setIsAllergyLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/patient/${user.userId}/allergies`,
      method: "GET"
    });
    if (!httpResponse.error) {
      setAllergies(httpResponse.data);
      allergyString(
        httpResponse.data,
        setFoodAllergy,
        "Food"
      );
      allergyString(
        httpResponse.data,
        setDrugAllergy,
        "Drug"
      );
      // console.log(httpResponse.data);
    }
    setIsAllergyLoading(false);
  };


  let string = "";


  const processArray = async (array) => {
    const processedItems = await Promise.all(array.map(async (appointment) => {
      // Perform async operation on each item
      const result = await canPatientConnectToVideo(appointment?.id);
      // Process the result as needed
      return { id: appointment?.id, canConnectVideo: result };
    }));

    return processedItems;
  };

  const fetchUpcomingAppointment = async (showLoader = true) => {
    props?.setIndex(0);
    if (showLoader) {
      setIsLoading(true);
    }
    props.setIsAppointmentLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/appointment/patient/past",
      method: "GET",
      params: {
        patientId: user?.userId,
        pageSize: 4,
        sortOrder: "currentDateFirst"
      }
    });
    if (!httpResponse.error) {
      // console.log(httpResponse?.data);
      if (httpResponse.data?.length > 0) {
        try {
          const canConnectResponses = await processArray(httpResponse?.data);
          // const canConnect = await canPatientConnectToVideo(httpResponse.data[0]?.id);
          setCanConnectVideo(canConnectResponses);

          props?.setAppointmentsLength(httpResponse?.data?.length);
          // // console.log(canConnect);
          // setCanConnectVideo(canConnect);
        }
        catch (err) {
          console.log("PNPRTL_CANNOT_CONNECT_API_ERROR", err);
        }
        setAppointments(p => httpResponse.data);
        videoCallContext.updateAppointmentData(httpResponse.data[props?.index]);
        localStorage.setItem('context', JSON.stringify(httpResponse.data[props?.index]));

      }
    } else {
      // console.log(httpResponse.error);
      setError(httpResponse.error);
    }
    if (showLoader) {
      setIsLoading(false);
    }
    props.setIsAppointmentLoading(false);
  };

  const fetchPastAppointments = async () => {
    setArePastAppointmentsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/appointment/patient/past",
      method: "GET",
      params: {
        patientId: user?.userId,
        pageSize: 3

      }
    });
    if (!httpResponse.error) {
      setPastAppointments(p => httpResponse.data);

    }
    setArePastAppointmentsLoading(false);
  };

  const allergyString = (allergen, createString, allergenType) => {
    let str = "";
    allergen?.length > 0 &&
      allergen.forEach((allergy, index) => {
        if (allergy?.allergyType === allergenType) {
          // console.log(allergenType, allergen?.length - 1);
          if (allergen?.length - 1 !== index) {
            str = str + allergy?.drugProductCodedText + ", ";
          } else {
            str = str + allergy?.drugProductCodedText;
          }
          // createString((prev) => { console.log(allergy?.DrugProductCoded?.Text + allergen?.length - 1 !== index ? ", " : ""); return prev + allergy?.DrugProductCoded?.Text + allergen?.length - 1 !== index ? ", " : "" })
        }
      });
    createString(str);
  };

  const handleDownload = async () => {
    await fetch(ConfigMap("APP") + downloadPatientAfterVisitSummaryURL(appointments?.at(props?.index)?.prescriptionReference?.encounter?.encounterId), {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
        "Authorization": localStorage.getItem("tkn") !== null ? localStorage.getItem("tkn") : ""
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // console.log(blob);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `after-summary.pdf`,
        );
        link.target = "_blank"

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();
        link.parentNode.removeChild(link);
      })
  }

  const downloadAppointmentReceipt = async () => {
    const httpResponse = await sendHttpRequest({
      url: `/appointments/${appointments?.at(props?.index)?.id}/receipt`,
      method: 'GET',
      responseType: "arraybuffer",
      params: {
        userId: user?.userId
      }

    })
    if (!httpResponse.error) {
      const link = document.createElement("a");
      link.target = "_blank";
      link.download = `Receipt_${appointments?.at(props?.index)?.id}`;
      const href = URL.createObjectURL(
        new Blob([httpResponse.data], {
          type: "application/pdf",
          encoding: "UTF-8",
        })
      );
      link.href = href;
      link.click();
      link.remove();
      URL.revokeObjectURL(href);
    }
  }


  async function handleJoinNow() {
    handleOpenVideoModal();
  }


  useEffect(() => {
    videoCallContext.updateAppointmentData(appointments?.at(props?.index));
    localStorage.setItem('context', JSON.stringify(appointments?.at(props?.index)));
  }, [props?.index])



  useEffect(() => {
    const fetchData = async () => {
      await fetchAllergy();
      await fetchUpcomingAppointment();
    };

    fetchData();
  }, [props.reload]);



  return (
    <>
      {
        open ? <MakePaymentModal amountValue={appointments?.at(props?.index)?.patientBill?.outstandingBalance} show={open}
          handleModal={handleModal}
          appointment={appointments?.at(props?.index)}
          isLoading={isLoading}
          strings={strings}
          foodAllergy={foodAllergy}
          drugAllergy={drugAllergy}
          setReloadAppointments={props?.setReloadAppointments}
        /> : null
      }

      {openVideoModal ? <PatientPortalVideoCallModal
        show={openVideoModal}
        appointment={appointments?.at(props?.index)}
        handleModal={handleVideoModal}

      /> : null}



      {appointments?.length > 0 ? <div className={`card pn-card py-3 px-2 ${props?.dataChanged ? 'fade-in-appointment' : ''}`}>
        {isLoading === false && appointments?.at(props?.index)?.paymentProcessed === false ? <span className="text--danger ms-3 d-block font-12">
          Your account has a past due balance. Please make a payment now to avoid cancellation of your upcoming appointment with {
            appointments?.at(props?.index)?.appointmentSlot?.providerTenantPracticeLocation?.provider
              ?.name[0]?.prefix
          }{" "}
          {
            appointments?.at(props?.index)?.appointmentSlot?.providerTenantPracticeLocation?.provider
              ?.name[0]?.firstName
          }{" "}
          {
            appointments?.at(props?.index)?.appointmentSlot?.providerTenantPracticeLocation?.provider
              ?.name[0]?.lastName
          }
        </span> : null}
        {isLoading ? (
          <Skeleton animation="wave" />
        ) : appointments?.at(props?.index) !== null ? (
          <div className="card-body">
            <Grid key={1} item xs={12}>
              <Stack direction="row" spacing={6}>
                <Box>
                  <Stack direction="row">
                    <Avatar
                      src={
                        appointments?.at(props?.index)?.appointmentSlot?.provider
                          ?.profilePicture || placeholder
                      }
                      alt="provider picture"
                    ></Avatar>
                    <Stack sx={{ ml: 1 }}>
                      {isLoading ? (
                        <span>
                          <Skeleton animation="wave" width={200} />
                        </span>
                      ) : (
                        <span className="fw-sb font-16 lh-0 m-0 d-block">
                          {
                            appointments?.at(props?.index)?.appointmentSlot?.providerTenantPracticeLocation?.provider
                              ?.name[0]?.prefix
                          }{" "}
                          {
                            appointments?.at(props?.index)?.appointmentSlot?.providerTenantPracticeLocation?.provider
                              ?.name[0]?.firstName
                          }{" "}
                          {
                            appointments?.at(props?.index)?.appointmentSlot?.providerTenantPracticeLocation?.provider
                              ?.name[0]?.lastName
                          }
                        </span>
                      )}
                      <span className="font-10 lh-0 d-block text--secondary m-0">
                        {
                          appointments?.at(props?.index)?.appointmentSlot?.providerTenantPracticeLocation?.provider?.speciality?.length > 0 ?
                            appointments?.at(props?.index)?.appointmentSlot?.providerTenantPracticeLocation?.provider?.speciality?.[0]?.specialization : ""
                        }
                      </span>
                    </Stack>
                  </Stack>
                </Box>
              </Stack>
            </Grid>
            <div className="row mt-4">
              <div className="col-sm-6 col-12">
                <Stack direction="column" spacing={0}>
                  <label
                    className="text--secondary font-12 fw-sb m-0"
                    style={{ marginBottom: "-12px" }}
                  >
                    {strings.en.date}
                  </label>
                  <p
                    className="m-0 text--terciary font-12"
                    style={{ marginTop: "-8px" }}
                  >
                    {isLoading ? (
                      <Skeleton animation="wave" />
                    ) : appointments?.at(props?.index)?.appointmentSlot?.startDateTime !== undefined &&
                      appointments?.at(props?.index)?.appointmentSlot?.startDateTime !== "" ? (
                      <>
                        {dateTimeHelper(
                          appointments?.at(props?.index)?.appointmentSlot?.startDateTime,
                          "MMM D, YYYY"
                        )}
                        <br />
                        {dateTimeHelper(
                          appointments?.at(props?.index)?.appointmentSlot
                            ?.startDateTime,
                          "LT"
                        ) +
                          " - " +
                          dateTimeHelper(
                            appointments?.at(props?.index)?.appointmentSlot
                              ?.endDateTime,
                            "LT"
                          )}
                      </>
                    ) : (
                      ""
                    )}
                  </p>
                </Stack>
              </div>
              <div className="col-sm-6 col-12">
                <Stack direction="column" spacing={0}>
                  <label
                    className="text--secondary font-12 fw-sb m-0"
                    style={{ marginBottom: "-12px" }}
                  >
                    {appointments?.at(props?.index)?.patientVisitType?.internalValue?.toLowerCase() === 'in-patient' ? 'Facility' : strings.en.chiefcomplaint}
                  </label>
                  <p
                    className="m-0 text--terciary font-12"
                    style={{ marginTop: "-8px" }}
                  >
                    {isLoading ? (
                      <Skeleton animation="wave" />
                    ) : (
                      appointments?.at(props?.index)?.patientVisitType?.internalValue?.toLowerCase() === 'in-patient' ? `${appointments?.at(props?.index)?.appointmentSlot?.providerTenantPracticeLocation?.facility?.businessName}, ${appointments?.at(props?.index)?.appointmentSlot?.providerTenantPracticeLocation?.facility?.addressLine1}, ${appointments?.at(props?.index)?.appointmentSlot?.providerTenantPracticeLocation?.facility?.city}, ${appointments?.at(props?.index)?.appointmentSlot?.providerTenantPracticeLocation?.facility?.state}, ${appointments?.at(props?.index)?.appointmentSlot?.providerTenantPracticeLocation?.facility?.zipCode}` : appointments?.at(props?.index)?.chiefComplaint
                    )}
                    {/* {encounter?.date !== null && encounter?.date !== undefined && encounter?.date !== '' ? dateTimeHelper(encounter?.date,"MMM DD, YYYY") : ""} */}
                  </p>
                </Stack>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-sm-6 col-12">
                <Stack style={{ fontSize: 12 }}>
                  <label className="text--secondary fw-sb font-12 m-0">
                    {strings.en.allergies}
                  </label>
                  {isLoading ? (
                    <Skeleton animation="wave" />
                  ) : foodAllergy !== "" ? (
                    <PnToolTip
                      title={foodAllergy?.length > 0 ? foodAllergy : strings.en.nofoodallergies}
                      arrow
                      placement="top"
                    >
                      <span>
                        {truncate("Food: " + foodAllergy, 35)}
                      </span>
                    </PnToolTip>
                  ) : (
                    strings.en.nofoodallergies
                  )}
                </Stack>
                <Stack style={{ fontSize: 12 }}>
                  {isLoading ? (
                    <Skeleton animation="wave" />
                  ) : drugAllergy !== "" ? (
                    <PnToolTip
                      title={drugAllergy?.length > 0 ? drugAllergy : strings.en.nofoodallergies}
                      arrow
                      placement="top"
                    >
                      <span>
                        {truncate("Drug: " + drugAllergy, 35)}
                      </span>
                    </PnToolTip>
                  ) : (
                    strings.en.nodrugallergies
                  )}
                </Stack>
                {/* <Stack style={{ fontSize: 14 }}>
              Common:
              {isLoading ? <Skeleton animation="wave" /> :
                appointment?.Encounter?.PatientHistory?.Allergies !== undefined &&
                  appointment?.Encounter?.PatientHistory?.Allergies.length !== 0 ?
                  appointment?.Encounter?.PatientHistory?.Allergies.map((allergy, index) => {
                    if (index < 3) {
                      return <p
                        key={allergy?.Id}
                        className="m-0 text--terciary font-16"
                        style={{ marginTop: "-8px" }}
                      >
                        {allergy?.DrugProductCoded?.Text}
                      </p>
                    }
                    // if (index > 2) {
                    //   string += allergy?.DrugProductCoded?.Text + ' ';
                    // }
                    // if ((appointment?.Encounter?.PatientHistory?.Allergies.length - 3 > 0) && (appointment?.Encounter?.PatientHistory?.Allergies.length - 1 === index)) {
                    //   return <Tooltip title={
                    //     <>
                    //       <ul className='list-unstyled ms-0'>
                    //         {string.split().map((item) => { return item !== " " && <li>{item}</li> })}
                    //       </ul>
                    //     </>
                    //   }><p
                    //     key={allergy?.Id}
                    //     className="m-0 text--secondary font-14"
                    //   >
                    //       {appointment?.Encounter?.PatientHistory?.Allergies.length - 3}{" more..."}
                    //     </p>
                    //   </Tooltip>
                    // }

                  }) : 'No allergies found'}
            </Stack> */}
              </div>
              <div className="col-sm-6 col-12">
                {appointments?.at(props?.index)?.paymentProcessed === false ? <>
                  <label className="text--secondary fw-sb font-12 m-0">
                    Payment Due
                  </label>
                  <span className=" col-5  font-12 d-block text--danger">
                    ${appointments?.at(props?.index)?.patientBill?.outstandingBalance}
                  </span>
                </> : null}
              </div>
            </div>
            <div className="row justify-content-end mt-4">
              <div className="col-2"></div>
              {appointments?.at(props?.index)?.paymentProcessed === false ? <div className="col-sm-5 me-5 col-12 ">
                {/* {console.log("appointment?.patientVisitType?.internalValue?.toLowerCase() === 'in-patient'", appointment?.patientVisitType?.internalValue?.toLowerCase() === 'in-patient')} */}
                <button
                  className="btn bg--blue br-10 text-white font-14 px-5 blue_task_button"
                  onClick={() => {
                    // handleOpen();
                    navigate("/make-payment", { state: { appointment: appointments?.at(props?.index), amountValue: appointments?.at(props?.index)?.patientBill?.amountBilled,
                      amountDue: appointments?.at(props?.index)?.patientBill?.outstandingBalance
                     } })
                  }}
                >
                  Make Payment
                </button>
              </div> : <>
                <div className="col-sm-4 col-12">
                  <button
                    // disabled={!canConnectVideo}
                    className="btn bg--blue br-10 text-white font-14 px-5 d9e8ec--button"
                    onClick={() => {
                      downloadAppointmentReceipt()
                    }}
                    disabled={!appointments?.at(props?.index)?.receiptAvailable}
                  >
                    {"Receipt"}
                  </button>
                </div>
                <div className={`${appointments?.at(props?.index)?.status === 'complete' ? 'col-sm-5' : 'col-sm-4'} col-12 me-3`}>
                  {/* {console.log("appointment?.patientVisitType?.internalValue?.toLowerCase() === 'in-patient'", appointment?.patientVisitType?.internalValue?.toLowerCase() === 'in-patient')} */}
                  {appointments?.at(props?.index)?.status === 'complete' ? <button
                    // disabled={!getVideoConnectStatus(appointments?.at(props?.index)?.id) || appointments?.at(props?.index)?.patientVisitType?.internalValue?.toLowerCase() === 'in-patient' || appointments?.at(props?.index)?.appointmentPassed === true || appointments?.at(props?.index)?.status === 'complete'}
                    className="btn bg--blue br-10 text-white font-14 px-5 blue_task_button"
                    onClick={handleDownload}
                  >
                    Visit Summary
                  </button> : <button
                    disabled={!getVideoConnectStatus(appointments?.at(props?.index)?.id) || appointments?.at(props?.index)?.patientVisitType?.internalValue?.toLowerCase() === 'in-patient' || appointments?.at(props?.index)?.appointmentPassed === true || appointments?.at(props?.index)?.status === 'complete'}
                    className="btn bg--blue br-10 text-white font-14 px-5 blue_task_button"
                    onClick={handleJoinNow}
                  >
                    {strings.en.join_now}
                  </button>}
                </div>
              </>}
            </div>
          </div>
        ) : (
          <span className="font-14">
            {strings.en.noupcomingappointment}
          </span>
        )}
      </div> : null}
    </>

  );
};

export default UpcomingAppointment;

import { Backdrop, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { useAuth } from "../hooks/useAuth";
import { setUserPermissionsData } from "../../redux/actions/providerActions";
import { useDispatch } from "react-redux";

const IdMePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const state = searchParams.get("state");
  const error = searchParams.get("error");
  const error_description = searchParams.get("error_description");
  const {user} = useAuth()

  const handleToggle = () => {
    setOpenBackdrop(!openBackdrop);
  };
  const updateUserPermissions = async () => {
    const httpResponse = await sendHttpRequest({
      url: `/users/${user.userId}/permissions`,
      method: 'GET'
    })
    if (!httpResponse.error) {
      dispatch(setUserPermissionsData(httpResponse.data))
      navigate("/dashboard");
    }
  }

  const sendIdmeCode = async () => {
    handleToggle();
    const httpResponse = await sendHttpRequest({
      url: "/login/oauth2/code/idme",
      method: "GET",
      params: code !== null && state != null ?
        {
          code: code,
          state:state
        }
        :
        {
          error: error.replaceAll("_", " "),
          error_description: error_description?.replaceAll("+", " ")
        }
    });
    if (!httpResponse.error) {
      updateUserPermissions();
    } else {
      // return false;
    }
    handleToggle();
  }

  useEffect(() => {
    sendIdmeCode();
    return () => { };
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: "#EC609B", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
};

export default IdMePage;

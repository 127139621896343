import { Modal } from "react-bootstrap";
import TextArea from "../../../forms/TextArea";
import CloseIcon from "@mui/icons-material/Close";
import { familyMedicalHistoryStrings } from "../../../../res/strings";
import { relationships } from "../../../../res/strings"; 
const FamilyMedicalHistoryModal = (props) => {
  const _str = familyMedicalHistoryStrings.en;

  const setFamilyMedicalHistoryNote = (event) => {
    props.setNote(event);
  };

  const handleRelationShipInput = (event) => {
    if(event.target.value !== ''){
      props.setRelationShip(event.target.value);
    }
  };

  const handleCondition = (event) => {
    props.setCondition(event.target.value);
  };

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.handleModal();
      }}
      size="md"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      backdropClassName="modal-backdrop"
      contentClassName="modal-border modal-shadow"

    >
      <Modal.Body>
        <div className="container-fluid px-3 ">
          <div className="row ">
            <div className="col-12 ">
              <span
                style={{
                  lineHeight: "12px",
                  width: "18px",
                  fontSize: "8pt",
                  color: "#336383",
                  position: "absolute",
                  top: 15,
                  right: 15,
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.handleModal();
                }}
              >
                {<CloseIcon />}
              </span>
            </div>
          </div>
          <div className="row ">
            <div className="col-12">
              <div className="d-flex ">
                {/* <div>
                  <img src={AddButtonIcon} alt=""></img>
                </div> */}
                <div className="mt-2">
                  <p className="h18 fw-sb " style={{ fontWeight: "600" }}>
                    {_str.addFamilyMedicalHistory}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <form id="familyMedicalHistoryForm" onSubmit={(e) => {
            e.preventDefault();
            props?.addFamilyMedicationRequestHandler();
            props?.handleModal();
          }}>
            <div className="row mt-4">
              <div className="col-sm-6">
                <label className="b2 text--secondary ">{_str.medicalCondition}</label>
                <input
                  type="text"
                  required
                  pattern=".*\S+.*"
                  className="form-control font-12 br-10 shadow-none"
                  onChange={(e) => handleCondition(e)}
                  style={{
                    border: "1px solid #D9E8EC",
                  }}
                ></input>
              </div>
              <div className="col-sm-6 ">
                <label className="b2 text--secondary ">{_str.relationship}</label>
                <select className = "form-select font-12 fst br-10 custom-select" 
                onChange={(e) => handleRelationShipInput(e)}
                 required>
                <option selected value=""></option>
                {relationships?.map((relation,index)=>{
                  return <option key={index} value={relation}>{relation}</option>
                })
                }
                </select>

              </div>
            </div>
            <div className="row mt-4">
              <div className="col-sm-12 ">
                <label className="b2 text--secondary">{_str.note}</label>
                <TextArea
                  className="form-control font-12 br-10 shadow-none "
                  style={{ border: "1px solid #D9E8EC" }}
                  gettext={setFamilyMedicalHistoryNote}
                />
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>

      <Modal.Footer style={{ border: "none" }}>
        <button
          type="submit"
          form="familyMedicalHistoryForm"
          className="btn blue_task_button b2 px-5 br-10"
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};
export default FamilyMedicalHistoryModal;

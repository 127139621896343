const MessagesIcon = (props) => {
  return (
    <svg
      id="Icon-messages"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 28.001 28"
      {...props}
      fill={props.style.color}
    >
      <path
        id="Icon-messages-2"
        data-name="Icon-messages"
        d="M26.972,1.029A3.46,3.46,0,0,0,23.706.1L5.039,4.027A5.828,5.828,0,0,0,1.71,13.922l2,2a1.167,1.167,0,0,1,.342.826v3.7a3.459,3.459,0,0,0,.35,1.5l-.009.008.03.03a3.5,3.5,0,0,0,1.589,1.582l.03.03.008-.009a3.459,3.459,0,0,0,1.5.35h3.7a1.167,1.167,0,0,1,.825.341l2,2A5.79,5.79,0,0,0,18.185,28a5.891,5.891,0,0,0,1.873-.308,5.755,5.755,0,0,0,3.9-4.65L27.9,4.334a3.471,3.471,0,0,0-.924-3.305ZM5.366,14.278l-2.006-2a3.43,3.43,0,0,1-.842-3.586A3.474,3.474,0,0,1,5.435,6.325L23.917,2.434,6.387,19.966V16.751A3.474,3.474,0,0,0,5.366,14.278Zm16.3,8.365a3.5,3.5,0,0,1-5.937,2l-2.007-2.007a3.474,3.474,0,0,0-2.472-1.024H8.036L25.568,4.083Z"
        transform="translate(-0.003 -0.001)"
        stroke=""
      />
    </svg>
  );
};

export default MessagesIcon;

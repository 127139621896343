import React, { useState, useEffect } from 'react'
import { Typography, Divider } from '@mui/material'
import AppoinmentCalender from '../../../../UI/General/AppointmentCalender'
import sendHttpRequest from '../../../../hooks/sendHttpRequest';
import { calenderDateTimeHelper, changeDateTimeFormat, dateTimeHelper } from '../../../../../utils/dateTimeHelper';
import { useUnsavedWorkPrompt } from '../../../../hooks/useUnsavedWorkPrompt';
import { useAuth } from '../../../../hooks/useAuth';



function AppointmentForm(props) {

    const { facilityId, patientVisitType, setAppoinmentStartTime, setAppoinmentEndTime, setScheduleDateToSend, setProviderId, type } = props

    const { user } = useAuth();

    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const joined = [year, month, day].join("-");
    const [scheduleDate, setScheduleDate] = useState(joined);
    const [isSlotsLoading, setIsSlotsLoading] = useState(false);
    const [slotTime, setSlotTime] = useState("");
    const [freeSlots, setFreeSlots] = useState([]);
    const [isDirty, onDirty, onPristine] = useUnsavedWorkPrompt();

    const handleSlot = (event) => {
        // setSlotTime(event.target.value);
        setAppoinmentStartTime(event.target.value.split("&")[0]);
        setAppoinmentEndTime(event.target.value.split("&")[1]);
        setProviderId(event.target.id);
    }

    const fecthFreeSlots1 = async (
        scheduleSelectedDate
    ) => {
        setIsSlotsLoading(true);
        setFreeSlots([]);
        const httpResponse = await sendHttpRequest({
            url: "/availabilities/availableSlots",
            method: "GET",
            params: {
                providerId: 0,
                patientId: user.userId,
                fromDate:
                    scheduleSelectedDate === undefined
                        ? scheduleDate
                        : scheduleSelectedDate,
                toDate:
                    scheduleSelectedDate === undefined
                        ? scheduleDate
                        : scheduleSelectedDate,
                appointmentType: type?.key,
                tenantId: user?.tenantId,
                facilityId:facilityId,
                duration: type?.durationMinutes
            },
        });
        if (!httpResponse.error) {
            setFreeSlots(httpResponse?.data?.providersAvailabilityList);
        } else {
            console.log(httpResponse.error);
        }
        setIsSlotsLoading(false);
    };

    useEffect(() => {
        fecthFreeSlots1()
        return () => {
            setSlotTime("");
            setFreeSlots([])
        }
    }, [])

    return (
        <>
            <div className='container-fluid' style={{ backgroundColor: "#D9E8EC" }}>
                <div className='row justify-content-start'>
                    <div className='col-sm-10' style={{ marginBottom: "80px" }}>
                        <div className='mtp-15 mlp-10' >
                            <Typography display="block" variant="h3" sx={{ color: "#336383", fontWeight: 600, fontSize: '16px', fontFamily: "Montserrat" }} component="h1" gutterBottom>
                                {"Choose an appropriate date and time for your appointment"}
                            </Typography>

                        </div>
                        <div className='mt-1 mlp-10'>
                            <div className='row'>
                                <div className='col-sm-9'>
                                    <div className='row'>
                                        <div className='col-sm-12'>
                                            <AppoinmentCalender
                                                setScheduleDate={setScheduleDate}
                                                EnableDirtyCheck={false}
                                                onDirty={false}
                                                providerID={0}
                                                // appointmentType={"new"}
                                                fecthFreeSlots1={fecthFreeSlots1}
                                                appointmentDate={calenderDateTimeHelper(new Date(), "YYYY-MM-DD")}
                                                setScheduleDateToSend={setScheduleDateToSend}
                                                isHeight={true}
                                                appointmentType = {type}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-3' style={{ marginTop: "6%" }}>
                                    <div className="row">
                                        <div className="col-sm-12 align-self-start mt-2">
                                            <span className="font-14 text--secondary fw-sb">{calenderDateTimeHelper(scheduleDate, "dddd, MMM D, YYYY")}</span>
                                        </div>
                                    </div>
                                    {
                                        freeSlots?.length <= 0 ?
                                            <div className="col-sm-12 align-self-start">
                                                <span className="font-14 text--terciary fw-sb">{"No time slots available"}</span>
                                            </div>
                                            :
                                            <>
                                                <div className="row">
                                                    <div className="col-sm-12 align-self-start">
                                                        <span className="font-14 text--terciary fw-sb">{"Available Slots"}</span>
                                                    </div>
                                                </div>
                                                <div className="container-fluid br-10 border--default p-2 mt-2" style={{ overflow: "hidden", maxHeight: "350px", overflowX: "hidden", overflowY: "scroll", background: "#FFFFFF", borderRadius: "10px" }}>
                                                    <div className="row">
                                                        <div className="col-sm-12 align-self-start">
                                                            {
                                                                freeSlots?.map((el, index) => {
                                                                    let aptStartTime = changeDateTimeFormat(
                                                                        el?.startDateTime,
                                                                        // slotsTimezoneOffset !== null ? slotsTimezoneOffset : "",
                                                                        "LT"
                                                                    );
                                                                    let aptEndTime = changeDateTimeFormat(
                                                                        el?.endDateTime,
                                                                        // slotsTimezoneOffset !== null ? slotsTimezoneOffset : "",
                                                                        "LT"
                                                                    );
                                                                    return (
                                                                        <div className={index % 2 === 0 ? `bg--white p-1` : `bg--disabled p-1`} >
                                                                            <input
                                                                                className="form-check-input shadow-none"
                                                                                style={{ marginTop: 6 }}
                                                                                onChange={handleSlot}
                                                                                type="radio"
                                                                                value={el?.startDateTime + "&" + el?.endDateTime}
                                                                                id={el.providerTenantPracticeLocation?.provider?.userId}
                                                                                key={index}
                                                                                name={"timeslot"}
                                                                            />
                                                                            <label
                                                                                className="form-check-label ms-1 font-14 text--terciary "
                                                                                htmlFor={index}
                                                                            >
                                                                                <span className="ms-2" key={index}><span className="fw-sb">{aptStartTime}</span> &ndash; {aptEndTime}</span>
                                                                            </label>
                                                                            <br />
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                    }
                                    <span className='text--red'>{props?.error}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AppointmentForm
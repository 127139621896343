import { Box, Stepper, StepLabel, Step, LinearProgress, Skeleton } from "@mui/material";
import veeoneimage from "../../assets/images/veeone.png";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { createElement } from "react";
import { useEffect } from "react";
import { fieldValidation, patientFormsMap } from "../../utils/helpers/HelperFunctions";
import sendHttpRequest from "../hooks/sendHttpRequest";

const AllForms = () => {

    const [searchParams] = useSearchParams();
    const key = searchParams.get("key");

    const [activeStep, setActiveStep] = useState(0);
    const [disableNextStep, setDisableNextStep] = useState(false);
    const [forms, setForms] = useState([]);
    const [formIds, setFormIds] = useState([]);
    const [imagePath,setImagePath] = useState();
    const [isFormLoading,setIsFormLoading] = useState(false);
    const [isImagePathLoading,setIsImagePathLoading] = useState(false);

    const updateStep = () => {
        setDisableNextStep(true);
        if (activeStep < forms.length-1) {
            setActiveStep(activeStep + 1);
        }
    }

    const handleDisableNext = (isDisable) => {
        setDisableNextStep(!isDisable);
    }

    const fetchForms = async () => {
        setIsFormLoading(true);
        const httpResponse = await sendHttpRequest({
        url: `/consentform`,
        method: "GET",
        params: {key:key}
        });
        if (!httpResponse.error) {

            let formArray = [];
            let formIdArray = [];
            httpResponse.data.forEach(element => {
                formArray.push(element.appointmentConsentForm.name);
                formIdArray.push(element.patientConsentFormId);
            });
            if(imagePath === undefined && httpResponse?.data?.length > 0){
                setImagePath(httpResponse?.data[0]?.tenant?.imagePath)
            }

            formArray.push("All Forms Submitted");
            formIdArray.push(0);
            console.log("formIdArray",formIdArray);
            setForms(formArray);
            setFormIds(formIdArray);
            handleDisableNext(false);

        } else {
            // return false;
        }
        setIsFormLoading(false);
    }
    const fetchLogo = async () =>{
        setIsImagePathLoading(true);
        const httpResponse = await sendHttpRequest({
            url: `/tenants/logo/?consentKey=${key}`,
            method:"GET",
        })
        if(!httpResponse.error){
            console.log("httpResponse.data: ",httpResponse.data);
            setImagePath(httpResponse.data?.imagePath)
        }
        setIsImagePathLoading(false);
    }

    useEffect(() => {
        fetchLogo();
        fetchForms();
        
    },[])

    function BottomNavigation(props) {
        // console.log(props)
        return (
            <div className="position-fixed pb-4 w-100 bottom-0 mx-auto bg-white bottom__nav--shadow">
                <LinearProgress variant="determinate" style={{
                    backgroundColor: "#D9E8EC",
                    height: "1px"
                }} value={100} />
                <div className="d-flex mb-1 p-3 justify-content-end">
                    <div className="position-fixed">
                        <button className="btn blue_task_button"
                            onClick={updateStep}
                            disabled={props.disableNextStep}
                        >
                            {forms.length-2 > activeStep ? `Next` : `Done`}
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className='container pb-5'>
                <div className='row pt-5 justify-content-center'>
                    <div className='col-sm-9 text-center'>
                        {
                            isImagePathLoading ? <Skeleton/> : 
                        <img src={imagePath} alt="logo" width="400" className="img-fluid" />
                        }
                        <div className="py-5">
                            <Box sx={{ width: '100%' }}>
                                <Stepper activeStep={activeStep} alternativeLabel>
                                    {formIds.length > 2 ? forms.map((label, index) => (
                                    <Step key={label} hidden={forms.length-1 === index}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                    )): ""}
                                </Stepper>
                            </Box>
                        </div>
                        <h2 className="fw-sb">{forms[activeStep]}</h2>
                    </div>
                </div>

                <div className="d-flex justify-content-center">
                    <div className="col-11">
                        {fieldValidation(patientFormsMap()?.get(forms[activeStep]), "all") ? createElement(patientFormsMap().get(forms[activeStep]), {handleDisableNext:handleDisableNext, formId:formIds[activeStep]}) : ""}
                    </div>
                </div>
            </div>
            <BottomNavigation disableNextStep={disableNextStep}/>
            {/* {console.log("forms",formIds)} */}
        </>
    )
}

export default AllForms;
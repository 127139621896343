
const Visa = (props) => {
  return (
    <svg id="visa" width="30px" height="25px" viewBox="0 0 60 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="visa" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="visa">
            <path d="M60,35 C60,37.75 57.75,40 55,40 L5,40 C2.25,40 0,37.75 0,35 L0,5 C0,2.25 2.25,0 5,0 L55,0 C57.75,0 60,2.25 60,5 L60,35 Z" id="Path" fill="#F5F5F5"></path>
            <path d="M55,0 L5,0 C2.25,0 0,2.25 0,5 L0,35 C0,37.75 2.25,40 5,40 L55,40 C57.75,40 60,37.75 60,35 L60,5 C60,2.25 57.75,0 55,0 Z M55,1 C57.206,1 59,2.794 59,5 L59,35 C59,37.206 57.206,39 55,39 L5,39 C2.794,39 1,37.206 1,35 L1,5 C1,2.794 2.794,1 5,1 L55,1 Z" id="Shape" fill="#D0D0D0" fill-rule="nonzero"></path>
            <path d="M42.747,21.56 C42.47,20.235 42.199,18.933 41.927,17.631 C41.914,17.63 41.901,17.629 41.888,17.627 C41.415,18.932 40.942,20.237 40.462,21.559 C41.236,21.56 41.974,21.56 42.747,21.56 Z M36.198,25.336 C36.362,24.941 36.524,24.552 36.687,24.163 C37.892,21.284 39.098,18.405 40.303,15.525 C40.538,14.963 40.956,14.677 41.57,14.679 C42.273,14.682 42.975,14.682 43.678,14.677 C43.812,14.676 43.872,14.704 43.903,14.852 C44.614,18.28 45.332,21.706 46.049,25.132 C46.063,25.199 46.071,25.268 46.083,25.345 C45.235,25.345 44.395,25.345 43.54,25.345 C43.444,24.884 43.341,24.43 43.258,23.971 C43.227,23.799 43.159,23.752 42.987,23.753 C41.943,23.762 40.898,23.76 39.854,23.754 C39.71,23.753 39.643,23.798 39.597,23.935 C39.452,24.366 39.296,24.793 39.133,25.218 C39.111,25.276 39.025,25.347 38.968,25.347 C38.069,25.355 37.17,25.352 36.271,25.351 C36.253,25.352 36.235,25.344 36.198,25.336 Z" id="Shape" fill="#152884" fill-rule="nonzero"></path>
            <path d="M36.836,14.964 C36.676,15.712 36.517,16.451 36.358,17.196 C36.055,17.095 35.767,16.985 35.471,16.903 C34.818,16.722 34.152,16.644 33.482,16.774 C33.241,16.821 32.993,16.923 32.792,17.063 C32.347,17.372 32.333,17.882 32.766,18.209 C33.097,18.459 33.471,18.651 33.827,18.867 C34.27,19.135 34.738,19.368 35.154,19.673 C36.212,20.449 36.564,21.527 36.205,22.79 C35.867,23.979 35.032,24.692 33.919,25.121 C32.899,25.514 31.836,25.57 30.761,25.483 C30.085,25.428 29.42,25.31 28.777,25.086 C28.671,25.049 28.567,25.006 28.448,24.96 C28.615,24.176 28.78,23.407 28.94,22.654 C29.397,22.815 29.835,22.999 30.289,23.123 C30.966,23.307 31.663,23.374 32.354,23.219 C32.623,23.159 32.898,23.036 33.12,22.874 C33.535,22.57 33.566,22.01 33.178,21.672 C32.886,21.418 32.538,21.226 32.206,21.021 C31.707,20.713 31.17,20.458 30.702,20.109 C29.14,18.944 29.419,17.184 30.23,16.13 C30.832,15.348 31.658,14.915 32.595,14.683 C33.977,14.341 35.341,14.439 36.683,14.902 C36.732,14.92 36.777,14.94 36.836,14.964 Z" id="Path" fill="#152884"></path>
            <path d="M28.825,14.696 C28.752,15.046 28.682,15.39 28.608,15.733 C27.941,18.852 27.271,21.971 26.611,25.091 C26.568,25.294 26.499,25.363 26.285,25.358 C25.532,25.343 24.779,25.352 24.026,25.352 C23.957,25.352 23.889,25.352 23.792,25.352 C23.863,25.018 23.929,24.705 23.996,24.393 C24.678,21.206 25.361,18.019 26.041,14.831 C26.062,14.732 26.084,14.675 26.204,14.675 C27.046,14.68 27.887,14.678 28.729,14.679 C28.753,14.681 28.777,14.688 28.825,14.696 Z" id="Path" fill="#152884"></path>
            <path d="M11.974,14.679 L12.179,14.679 C13.641,14.679 15.103,14.678 16.565,14.679 C17.337,14.679 17.81,15.073 17.953,15.829 C18.331,17.828 18.709,19.827 19.087,21.826 C19.089,21.838 19.095,21.85 19.114,21.902 C19.155,21.812 19.186,21.751 19.211,21.687 C20.111,19.414 21.012,17.141 21.905,14.866 C21.964,14.715 22.039,14.673 22.193,14.674 C23.016,14.681 23.839,14.677 24.661,14.677 L24.876,14.677 C24.835,14.784 24.804,14.871 24.768,14.956 C23.34,18.365 21.911,21.773 20.485,25.183 C20.435,25.303 20.379,25.356 20.239,25.354 C19.378,25.347 18.517,25.347 17.657,25.354 C17.522,25.355 17.476,25.312 17.443,25.184 C16.719,22.37 15.987,19.558 15.265,16.743 C15.156,16.32 14.925,16.027 14.53,15.839 C13.738,15.461 12.902,15.221 12.053,15.017 C12.011,15.007 11.97,14.994 11.915,14.979 C11.925,14.925 11.933,14.877 11.942,14.829 C11.952,14.788 11.961,14.745 11.974,14.679 Z" id="Path" fill="#152884"></path>
        </g>
    </g>
</svg>
  );
};

export default Visa;

import Logo from "../../../assets/icons/PrescribeNow-Horizontal-Logo-Intake.svg";
import placeholder from "../../../assets/images/patientPlaceholder.png";
import PatientDropdown from '../../forms/PatientDropdown';
import { useAuth } from '../../hooks/useAuth';

function PatientRegistrationHeader(props) {

    const { user } = useAuth();



    return (
        <>

            <div className="bg-white row py-4 fixed-top  justify-content-between">
                <div className='col-10 col-sm-3'>
                    <img src={Logo} className="" width={210} height={40} alt={"PrescribeNow-Logo"} />
                    {/* <div className=''> */}
                    {/* </div> */}
                </div>
                <div className='col-2 col-sm-6'>
                    {user !== undefined && user !== null ? <div className="d-flex justify-content-end flex-end px-2">

                        <div className="me-1">
                            <img
                                src={
                                    user.profilePicture !== undefined
                                        ? user.profilePicture
                                        : placeholder
                                }
                                width="40px"
                                height="40px"
                                alt="User"
                                className="rounded-circle"
                            />
                        </div>
                        <div className="ps-2  ">
                            {
                                <PatientDropdown
                                    userInfo={user}
                                    patientRegistrationHeader={true}
                                />
                            }
                        </div>
                    </div> : null}
                </div>
            </div>
        </>
    )
}

export default PatientRegistrationHeader;
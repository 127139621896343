import { useLocation } from "react-router-dom";
import PageHeader from "../../UI/General/PageHeader";
import { useEffect, useState } from "react";
import { calenderDateTimeHelper } from "../../../utils/dateTimeHelper";
import { useAuth } from "../../hooks/useAuth";
import { Divider } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import sendHttpRequest from "../../hooks/sendHttpRequest";
import IconButton from "@mui/material/IconButton";

const ViewPatientBillSummaryDetail = () => {
  const { user } = useAuth();
  const location = useLocation();
  console.log("location: ", location);
  const summaryObject = location.state.billItem ?? "";
  const [providerName, setProviderName] = useState("");
  const [isPrinting, setIsPrinting] = useState(false);

  const printBillReceipt = async (patientBillId) => {
    setIsPrinting(true);
    const httpResponse = await sendHttpRequest({
      url: `/bills/${patientBillId}/receipt`,
      method: "GET",
      responseType: "arraybuffer",
    });
    if (!httpResponse.error) {
      const link = document.createElement("a");
      link.target = "_blank";
      link.download = `Payment_Receipt_${patientBillId}`;
      const href = URL.createObjectURL(
        new Blob([httpResponse.data], {
          type: "application/pdf",
          encoding: "UTF-8",
        })
      );
      link.href = href;
      link.click();
      link.remove();
      URL.revokeObjectURL(href);
    }
    setIsPrinting(false);
  };

  const SummaryItem = (props) => {
    return (
      <>
        <div className="col-sm-6 pt-1 d-flex justify-content-between pe-3">
          <span className="text-secondary font-12 fw-sb">{props.title}</span>
          <span className="font-12 text--secondary">{props.description}</span>
        </div>
      </>
    );
  };

  const BilledTo = (props) => {
    let firstName = props.user?.name[0]?.firstName;
    let lastName = props.user?.name[0]?.lastName;

    return (
      <>
        <div className="col-12">
          <span className="font-14 text--terciary fw-sb">{"Billed To:"}</span>
        </div>
        <div className="col-12">
          <span className="font-12 text--terciary">
            {"".concat(firstName, " ", lastName)}
          </span>
        </div>
        <div className="col-12">
          <span className="font-12 text--terciary">
            {"Date of Birth: ".concat(
              calenderDateTimeHelper(props.user?.dateOfBirth, "YYYY-MM-DD")
            )}
          </span>
        </div>
        <div className="col-12">
          <span className="font-12 text--terciary">
            {"Biological Sex: ".concat(props.user?.gender)}
          </span>
        </div>
      </>
    );
  };
  const BillingAccount = (props) => {
    let firstName = props.user?.name[0]?.firstName;
    let lastName = props.user?.name[0]?.lastName;

    return (
      <>
        <div className="col-12 mt-3">
          <span className="font-14 text--terciary fw-sb">
            {"Billing Account:"}
          </span>
        </div>
        <div className="col-12">
          <span className="font-12 text--terciary">
            {"".concat(firstName, " ", lastName)}
          </span>
        </div>
        <div className="col-12">
          <span className="font-12 text--terciary">
            {props.user?.address[0]?.addressLine1}{" "}
            {props.user?.address[0]?.addressLine2 !== undefined &&
            props.user?.address[0]?.addressLine2?.length > 0
              ? props.user?.address[0]?.addressLine2 + " "
              : ""}
            {props.user?.address[0]?.city?.trim()}
            {props.user?.address[0]?.city !== undefined && ", "}
            {props.user?.address[0]?.stateProvince}{" "}
            {props.user?.address[0]?.postalCode}
          </span>
        </div>
      </>
    );
  };

  const ServiceComponent = (props) => {
    return (
      <>
        <div className="row ">
          <Divider />
          <div className="col-12 mt-3">
            <span className="font-14 text--terciary fw-sb">
              {"Services Rendered: "}
            </span>
            <div className="row">
              <div className="col-6">
                <span className="font-12 text--terciary fw-sb">
                  {"Description"}
                </span>
              </div>
              <div className="col-2">
                <span className="font-12 text--terciary fw-sb">
                  {"Unit Cost"}
                </span>
              </div>
              <div className="col-2">
                <span className="font-12 text--terciary fw-sb">
                  {"Quantity"}
                </span>
              </div>
              <div className="col-2">
                <span className="font-12 text--terciary fw-sb">
                  {"Total Cost"}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <span className="font-12 text--terciary ">
                  {props.summaryObject?.serviceDescription}
                </span>
              </div>
              <div className="col-2">
                <span className="font-12 text--terciary ">
                  {"$".concat(
                    parseFloat(props.summaryObject?.amountBilled).toFixed(2)
                  )}
                </span>
              </div>
              <div className="col-2">
                <span className="font-12 text--terciary ">{"1"}</span>
              </div>
              <div className="col-2">
                <span className="font-12 text--terciary ">
                  {"$".concat(
                    parseFloat(props.summaryObject?.amountBilled).toFixed(2)
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    let prefix =
      location?.state?.billItem?.providerTenantPracticeLocation?.provider
        ?.name[0]?.prefix ?? "";
    let firstName =
      location?.state?.billItem?.providerTenantPracticeLocation?.provider
        ?.name[0]?.firstName ?? "";
    let lastName =
      location?.state?.billItem?.providerTenantPracticeLocation?.provider
        ?.name[0]?.lastName ?? "";
    setProviderName("".concat(prefix, " ", firstName, " ", lastName) ?? "");
  }, [location?.state?.billItem]);
  return (
    <>
      <PageHeader title={"Bill Summary"} />
      <div className="row justify-content-center">
        {summaryObject !== undefined ? (
          <div className="col-8">
            <div className="row">
              <div className="col-11 text-center mt-3">
                <h3 className="text--terciary fw-sb h4">{"Payment Receipt"}</h3>
              </div>
              <div className="col-1 mt-3 text-end">
                <IconButton
                  disabled={
                    summaryObject?.paymentStatus?.toLowerCase() === "unpaid"
                      ? true
                      : isPrinting
                      ? true
                      : false
                  }
                >
                  <PrintIcon
                    onClick={() => {
                      printBillReceipt(summaryObject?.patientBillId);
                    }}
                    style={
                      (summaryObject?.paymentStatus?.toLowerCase() === "unpaid" || isPrinting)
                        ? {
                            color: "#d9e8ec",
                            height: "18px",
                            width: "18px",
                            background: "none",
                          }
                        : {
                            color: "#336383",
                            height: "18px",
                            width: "18px",
                            background: "none",
                          }
                    }
                    className="cursor--pointer"
                    disabled={
                      summaryObject?.paymentStatus?.toLowerCase() === "unpaid"
                    }
                  />
                </IconButton>
              </div>

              {/* <SummaryItem title={"Service Description: "} description={summaryObject?.serviceDescription ?? ""}/>
                            <SummaryItem title={"Chief Complaint: "} description={summaryObject?.appointment?.chiefComplaint ?? ""}/>
                            <SummaryItem title={"Date: "} description={calenderDateTimeHelper(summaryObject?.createdDate,"YYYY-MM-DD") ?? ""}/>
                            <SummaryItem title={"Provider Name: "} description={providerName ?? ""}/>
                            <SummaryItem title={"Amount Billed: "} description={"$".concat(summaryObject?.amountBilled ?? "0.00") ?? ""}/> */}
              {
                user?.name?.length > 0  ? 
                <>
                  <BilledTo user={user} />
                  <BillingAccount user={user} />
                </>
                :null
              }
              <ServiceComponent user={user} summaryObject={summaryObject} />
              <div className="col-12 mt-3">
                <span className="font-12 text--terciary">{`Thank you for choosing ${providerName}. If you have any questions about this receipt, please contact us at
                noreply@prescribenow.com
                `}</span>
              </div>
              <div className="col-12">
                <span className="font-14 text--terciary fw-sb">
                  {"Refund Policy"}
                </span>
              </div>
              <div className="col-12">
                <span className="font-12 text--terciary">{`
Full refunds are available for cancellations made 24 hours prior to your appointment. No refunds for cancellations
within 24 hours. If our professional cannot attend, we'll reschedule or provide a full refund. Refunds may take 5-7
business days to process. For queries, contact our customer service team.`}</span>
              </div>
            </div>
          </div>
        ) : (
          <span className="text--terciary font-12">{"No record found"}</span>
        )}
      </div>
    </>
  );
};

export default ViewPatientBillSummaryDetail;

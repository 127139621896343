import { Switch } from "@mui/material";
import EditButtonIcon from "../../assets/icons/Icon-Edit.svg";
import TrashButtonIcon from "../../assets/icons/Icon-Trash.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { Form } from "react-bootstrap";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#fafcfc",
        color: "#336383",
        fontWeight: 400,
        fontSize: 12,
        fontFamily: "Montserrat",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: 0,
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 1,
        paddingRight: 1,
        fontFamily: "Montserrat",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: "transparent",
        fontFamily: "Montserrat",
    },
    "&:nth-of-type(odd)": {
        backgroundColor: "transparent",
        fontFamily: "Montserrat",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));



const ChangeDiagnosisStatusSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-switchBase": {
      "&.Mui-checked": {
        "& + .MuiSwitch-track": {
          backgroundColor: "#004FD4",
          opacity: 1,
        },
      },
    },
    "& .MuiSwitch-track": {
      borderRadius: 11 / 2,
      "&:before, &:after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
    },
    "& .MuiSwitch-thumb": {
      borderRadius: 11 / 2,
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
      color: "#fff",
    },
  }));



const DiagnosisTableProviderNote = ({ diagnoses, deleteDiagnosisConfirmation, handleEditDiagnosis, isPastDiagnosis, handleToggleInactive }) => {

    return (
        <TableContainer>
            <Table>
                {diagnoses?.length > 0 && (
                    <TableBody>
                        {diagnoses?.slice()?.sort((a, b) => {
                            if (a.type === 'P') {
                                return -1;
                            } else if (b.type === 'P') {
                                return 1;
                            } else {
                                return 0;
                            }
                        })?.map((diagnosis) => {
                            if (diagnosis?.code !== null) {
                                return (
                                    <StyledTableRow
                                        key={diagnosis?.code}
                                        sx={{ border: 0 }}
                                    >
                                        {/* {diagnosis?.code !== undefined && (
                                            <StyledTableCell sx={{ fontWeight: 600 }}>
                                                
                                            </StyledTableCell>
                                        )} */}
                                        {diagnosis?.code !== undefined && (
                                            <StyledTableCell
                                                sx={{ color: "#336383" }}
                                            >
                                                <span className="fw-sb">{diagnosis?.code} - </span> {diagnosis?.description} {isPastDiagnosis !== true && diagnosis?.type === "P" ? " (Primary)" : null}
                                            </StyledTableCell>
                                        )}
                                        {
                                            isPastDiagnosis !== undefined && isPastDiagnosis === true &&
                                            diagnosis?.code !== undefined  && (

                                                // Switch button to mark as inactive
                                                // <Form.Check 
                                                //     type="switch"
                                                //     id={`custom-switch-${diagnosis?.diagnosisId}`}
                                                //     title="Mark as Inactive"
                                                //     checked={diagnosis?.status === true}
                                                //     onChange={() => handleToggleInactive(diagnosis?.diagnosisId)}
                                                // />

                                                <ChangeDiagnosisStatusSwitch
                                                defaultChecked={diagnosis?.status === true}
                                                    // checked={diagnosis?.status === true}
                                                    onChange={() => handleToggleInactive(diagnosis?.diagnosisId)}
                                                    />
                                                
                                            )
                                        }
                                        {
                                            (isPastDiagnosis === undefined || isPastDiagnosis !== true) &&
                                            diagnosis?.code !== undefined && (

                                            < StyledTableCell
                                                onClick={() => handleEditDiagnosis({ diagnosisId: diagnosis?.diagnosisId, code: diagnosis?.code, description: diagnosis?.description, type: diagnosis?.type, ...(diagnosis?.startDate && { startDate: diagnosis?.startDate }), ...(diagnosis?.endDate && { endDate: diagnosis?.endDate }), status: diagnosis?.status, isPastDiagnosis: isPastDiagnosis})}
                                                id={diagnosis?.code}
                                                disabled={true}
                                                sx={{ cursor: "pointer" }}
                                            >

                                                <img
                                                    disabled={true}
                                                    src={EditButtonIcon}
                                                    alt={"editIcon"}
                                                    id={diagnosis?.code}
                                                    style={{ width: "15px", height: "15px" }}
                                                ></img>
                                            </StyledTableCell>
                                        )
                                        }
                                        {
                                            (isPastDiagnosis === undefined || isPastDiagnosis !== true) && 
                                            diagnosis?.code !== null && (
                                                <StyledTableCell
                                                    onClick={() => deleteDiagnosisConfirmation({ diagnosisId: diagnosis?.diagnosisId, type: diagnosis?.type, code: diagnosis?.code, description: diagnosis?.description })}
                                                    id={diagnosis?.diagnosisId}
                                                    sx={{ cursor: "pointer" }}
                                                >
                                                    <img
                                                        src={TrashButtonIcon}
                                                        alt={"deleteIcon"}
                                                        id={diagnosis?.diagnosisId}
                                                        className="me-2"
                                                        style={{ width: "15px", height: "15px" }}
                                                    ></img>
                                                </StyledTableCell>
                                            )
                                        }
                                    </StyledTableRow>
                                );
                            }
                        })}
                    </TableBody>
                )}
            </Table>
        </TableContainer >
    )
}

export default DiagnosisTableProviderNote
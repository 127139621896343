import classes from "./Stats.module.css";
const Stats = (props) => {

  return (
    <div
      id={`pn-provider-${props?.id}`}
      className="d-flex flex-rows align-items-center "
      onClick={props.fetchData}
      style={{ cursor: "pointer" }}
    >
      <button
        type="button"
        id={`pn-provider-statsIcon-${props?.id}`}
        className={`bg--lightBlue  ${classes["stat__icon__val"]} text--secondary`}
      >
        <span
          id={`pn-provider-statsValue-${props?.id}`}
        >
          {props?.icon}
        </span>
        {
          props?.value > 0 && (
            <span
              id={`pn-provider-statsValue-${props?.id}`}
              className={`${classes["stat__icon__badge"]}`}
            >
              {props?.value}
            </span>
          )
        }
      </button>
      <div>
        <div className={`pt-4 ms-2`}>
          {props?.description}
        </div>
      </div>
    </div>
  );
};

export default Stats;

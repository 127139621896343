import { Divider, Skeleton, Tooltip, tooltipClasses } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDMYformate } from "../../utils/dateTimeHelper";
import CustomDatePicker from "../UI/General/CustomDatePicker";
import classes from "./DrugSearch.module.css";
import { parseISO } from "date-fns";
import { truncate } from "../../utils/helpers/HelperFunctions";
import styled from "@emotion/styled";
import TextArea from "../forms/TextArea";
import { PnToolTip } from "../UI/Reusable/PnComponents";
import {
  ERROR_MESSAGES,
  FIELD_NAMES,
  MIN_MAX_VALUES,
} from "../validations/validationschemas";
import { ValidationErrorMessage } from "../validations/ValidationErrorMessage";
import CalenderDatePicker from "../UI/General/CalenderDatePicker";
import { useRef } from "react";
import sendHttpRequest from "../hooks/sendHttpRequest";

const ColoredToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#336383",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#336383",
  },
}));
const SigSelection = (props) => {
  const prescribedMedications = useSelector(
    (state) => state.PrescribedMedication.prescribedDrugList
  );
  const MEDICATION_VALIDATION = MIN_MAX_VALUES.medication;
  const fieldNames = FIELD_NAMES.medication;
  const validationErrorMsgs = ERROR_MESSAGES.medication;
  // console.log("fieldNames",fieldNames)

  const [validationErrors, setValidationErrors] = useState({});

  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const joined = [year, month, day].join("-");
  //New code
  const [additionalDirections, setAdditionalDirections] = useState("");
  const [patientDirections, setPatientDirections] = useState("");
  const [dispenseQuantityValue, setDispenseQuantityValue] = useState(0);
  const [clearState, setClearState] = useState(false);
  const [medicationType,setMedicationType] = useState(props.drugData?.drugNameCode);
  const [siglist, setSiglist] = useState(props?.sig);
  const [dEASchedule, setDEASchedule] = useState("");
  const [customSigValue, setCustomSigValue] = useState("");
  const quantityRef = useRef();
  //Number of Refills in case of replace request

  // const [numberOfRefillsReplace, setNumberOfRefillsReplace] = useState(0);

  const trigerQuantityInputEvent = (quantity)=>{
    quantityRef.current.value = quantity
    // Manually trigger the onInput event to clear custom validity
    const event = new Event('input', {
      bubbles: true,
      cancelable: true,
    });
    quantityRef.current.dispatchEvent(event);
  }
  const handleDispenseValue = (event) => {
    // console.log("Called Quantity: handleDispenseValue");
    let quantity = event.target.value;
    if (Number(quantity) > 0) {
      if (quantity.length > MEDICATION_VALIDATION.quantityMax) {
        setValidationErrors({
          ...validationErrors,
          [fieldNames.quantityMax]: validationErrorMsgs.quantityMax,
        });
      } else if (quantity.length > 0 && quantity.length <= MEDICATION_VALIDATION.quantityMax) {
        let temp = validationErrors;
        delete temp.quantityMax;
        delete temp.quantityPositive;
        setValidationErrors((p) => temp);
      }
    } else {
      if (Number(quantity) < 0) {
        setValidationErrors({
          ...validationErrors,
          [fieldNames.quantityPositive]: validationErrorMsgs.quantityPositive,
        });
      } else {
        let temp = { ...validationErrors };
        if (quantity?.length > MEDICATION_VALIDATION.quantityMax) {
          delete temp.quantityMax;
        }
        delete temp.quantityPositive;
        setValidationErrors((p) => temp);
      }
    }

    if (
      props?.drugData?.drugOrderId !== "-1" &&
      Number(quantity) > 0 &&
      quantity?.length <= MEDICATION_VALIDATION.quantityMax
    ) {
      let highFrequency = parseFloat(props.selectedSig?.highFrequency);
      let highDoseForm = parseFloat(props.selectedSig?.highDoseForm);
      if (props?.updateCheck && !props?.replaceRequest) {
        highFrequency = parseFloat(props.drugData?.highFrequency);
        highDoseForm = parseFloat(props.drugData?.highDoseForm);
      } else if (
        props?.replaceRequest &&
        isNaN(highFrequency) &&
        isNaN(highDoseForm)
      ) {
        highFrequency = parseFloat(props.drugData?.highFrequency);
        highDoseForm = parseFloat(props.drugData?.highDoseForm);
      }
      props.setDrugData({
        ...props.drugData,
        Quantity: parseFloat(event.target.value),
      });
    } else {
      props.setDrugData({
        ...props.drugData,
        Quantity: quantity,
      });
    }
  };
  const handleDaysSupply = (event) => {
    // console.log("Called days supply: handleDaysSupply");
    event.preventDefault();
    event.target.value = Math.round(event.target.value.replace(/\D/g, ""));
    let daysSupply = event.target.value;
    // console.log("daysSupply: ",daysSupply);
    if (daysSupply?.length > 3) {
      setValidationErrors({
        ...validationErrors,
        [fieldNames.daysSupplyMax]: validationErrorMsgs.daysSupplyMax,
      });
    } else {
      let temp = validationErrors;
      delete validationErrors.daysSupplyMax;
      setValidationErrors({...temp});
    }

    if ((daysSupply == 0 || Number(daysSupply) === 0) && props?.drugData?.customSigFlag === false) {
      event.target.value = "";
      props.setDrugData({
        ...props.drugData,
        Quantity: "",
        daySupply: "",
      });
    }else if((daysSupply == 0 || Number(daysSupply) === 0) && props?.drugData?.customSigFlag === true){
      event.target.value = "";
      props.setDrugData({
        ...props.drugData,
        daySupply: "",
      });
    }
    // props.setDrugData({ ...props.drugData, daySupply: event.target.value });
    let highFrequency = parseFloat(props.selectedSig?.highFrequency);
    let highDoseForm = parseFloat(props.selectedSig?.highDoseForm);
    if (props?.updateCheck && !props?.replaceRequest) {
      highFrequency = parseFloat(props.drugData?.highFrequency);
      highDoseForm = parseFloat(props.drugData?.highDoseForm);
    } else if (
      props?.replaceRequest &&
      isNaN(highFrequency) &&
      isNaN(highDoseForm)
    ) {
      highFrequency = parseFloat(props.drugData?.highFrequency);
      highDoseForm = parseFloat(props.drugData?.highDoseForm);
    }
    // console.log("highFrequency: ",highFrequency);
    // console.log("highDoseForm: ",highDoseForm);
    if(highFrequency > 0 && highDoseForm > 0 && Number(daysSupply) > 0){
      let quantity = Math.ceil(Number(daysSupply) * (highFrequency * highDoseForm));
      // console.log("customSigFlag: ",props?.drugData?.customSigFlag);
      if(props?.drugData?.customSigFlag  === true){
        props.setDrugData({
          ...props.drugData,
          daySupply: daysSupply,
        });
      }else{
        document.getElementById("dispenseQuantity").value = quantity;
        trigerQuantityInputEvent(quantity);
        props.setDrugData({
          ...props.drugData,
          Quantity: quantity,
          daySupply: daysSupply,
        });
      }
    } else if(isNaN(highFrequency) &&
    isNaN(highDoseForm) && props?.drugData?.customSigFlag  === true){
      props.setDrugData({
        ...props.drugData,
        daySupply: daysSupply === 0 ? "":daysSupply,
      });
    }
  };
  const handleDispenseQuantity = (event) => {
    // console.log("event.target.id", event.target.value);
    if (event.target.id !== "-1") {
      let sigTextValue = event.target.value;
      let highDoseForm = parseFloat(event.target.getAttribute("highdoseform"));
      let highFrequency = parseFloat(
        event.target.getAttribute("highfrequency")
      );

      // console.log(highDoseForm,highFrequency);
      let quantity = document.getElementById("dispenseQuantity").value;
      let daysSupply = document.getElementById("daysSupply").value;
      // console.log("quantity: ",quantity,typeof(quantity));

      if (
        daysSupply !== undefined &&
        daysSupply !== "" &&
        daysSupply !== "0" &&
        !isNaN(daysSupply) &&
        !isNaN(highFrequency) &&
        Number(daysSupply) > 0
      ) {
        console.log("I'm in if of handleDispenseQuantity");
        quantity = Math.ceil(daysSupply * (highFrequency * highDoseForm));
        if (quantity?.length > MEDICATION_VALIDATION.quantityMax) {
          setValidationErrors({
            ...validationErrors,
            [fieldNames.quantityMax]: validationErrorMsgs.quantityMax,
          });
        } else {
          let temp = validationErrors;
          delete validationErrors.quantityMax;
          setValidationErrors(temp);
        }
        trigerQuantityInputEvent(quantity);
        // quantityRef.current.dispatchEvent(event);
        props.setDrugData(p=>({
          ...props.drugData,
          Quantity: Number(quantity),
          daySupply: daysSupply,
          highFrequency: highFrequency,
          highDoseForm: highDoseForm,
          Sig: {
            SigText: sigTextValue,
          },
          sigText: sigTextValue,
          textValue: sigTextValue,
          drugOrderId: event.target.id,
          customSigFlag: event.target.id === "-1" ? true : false,
        }));
        
      }
    } else {
      props?.setDrugData({
        ...props?.drugData,
        Sig: {
          SigText: customSigValue,
        },
        sigText: customSigValue,
        textValue: customSigValue,
        drugOrderId: event.target.id,
        customSigFlag: event.target.id === "-1" ? true : false,
      });
    }

    // let highFrequency = event.target.getAttribute("highfrequency");
    // let daysSupply = parseInt(daysSupplyValue);
    // // console.log(daysSupply, highDoseForm, highFrequency);
    // if (isNaN(daysSupply)) {
    //   document.getElementById("dispenseQuantity").value = "";
    // } else {
    //   let dispenseQuantity = Math.ceil(highDoseForm * highFrequency * daysSupply);
    //   props.setDrugData({
    //     ...props.drugData,
    //     Quantity: dispenseQuantity,
    //     daysSupply: daysSupply,
    //   });
    //   document.getElementById("dispenseQuantity").value = dispenseQuantity;
    // }
  };
  const handleNoOfRefill = (event) => {
    // event.target.value = Math.round(
    //   event.target.value.replace("^[a-zA-Z0-9]+$", "")
    // );

    // if (event.target.value == 0) {
    //   event.target.value = "";
    // }
    let refillValue = event.target.value;
    // console.log("refillValue?.length", refillValue?.length);
    if (refillValue?.length > MEDICATION_VALIDATION.numberOfRefillsMax) {
      setValidationErrors({
        ...validationErrors,
        [fieldNames.numberOfRefillsMax]: validationErrorMsgs.numberOfRefillsMax,
      });
    } else {
      let temp = validationErrors;
      delete temp.numberOfRefillsMax;
      setValidationErrors(temp);
    }
    props.setDrugData({
      ...props.drugData,
      NumberOfRefills: refillValue,
    });
  };
  const handleNoteToPharmacy = (event) => {
    let note = event.target.value;
    props.setDrugData({ ...props.drugData, Note: note });
    if (note?.length > MEDICATION_VALIDATION.noteMax) {
      setValidationErrors({
        ...validationErrors,
        [fieldNames.noteMax]: validationErrorMsgs.noteMax,
      });
    } else {
      let temp = validationErrors;
      delete temp.noteMax;
      setValidationErrors(temp);
    }
  };
  const handleRefillAsNeeded = (event) => {
    props.setDrugData({
      ...props.drugData,
      RefillAsNeeded: event.target.checked,
    });
  };
  const handleDispenseAsWritten = (event) => {
    props.setDrugData({
      ...props.drugData,
      DispenseAsWritten: event.target.checked,
    });
  };
  const handlePhysicianAlert72hrs = (event) => {
    props.setDrugData({
      ...props.drugData,
      alertPhysician72: event.target.checked,
    });
  };
  const handlePrescriptionStartDate = (date) => {
    // props?.setUpdateCheck(false);
    setClearState(false);
    props.setDrugData({
      ...props.drugData,
      PrescriptionStartDate: {
        Date: date,
      },
    });
  };
  const handleEarliestFillDate = (date) => {
    // props?.setUpdateCheck(false);
    setClearState(false);
    props.setDrugData({
      ...props.drugData,
      EarliestFillDate: {
        Date: date,
      },
      PrescriptionStartDate: {
        Date: date,
      },
    });
  };

  // Handlers for new sig screen
  const handleAdditionalDirections = (event) => {
    setAdditionalDirections(event.target.value);
    // let patientDirections = props.sigTextValue
    // console.log("Patient Directions : ",event.target.value);
    props?.setDrugData({
      ...props.drugData,
      PatientDirections: event.target.value,
    });
  };

  const handlePatientDirections = (text) => {
    setPatientDirections(text);
  };

  const calculateDispenseQuantity = (daysSupplyValue) => {
    let highDoseForm = parseFloat(props.selectedSig.HighDoseForm);
    let highFrequency = parseFloat(props.selectedSig.HighFrequency);
    let daysSupply = parseInt(daysSupplyValue);
    let dispenseQuantity = highDoseForm * highFrequency * daysSupply;

    if (isNaN(daysSupply)) {
      setDispenseQuantityValue(daysSupplyValue);
      document.getElementById("dispenseQuantity").value = "";
      props.setDrugData({
        ...props.drugData,
        Quantity: 10,
      });
    } else {
      setDispenseQuantityValue(dispenseQuantity);
      document.getElementById("dispenseQuantity").value = dispenseQuantity;
      props.setDrugData({
        ...props.drugData,
        Quantity: 10,
      });
    }
  };
  const handleStrengthChange = (event) => {
    setClearState(false);
    let drugDbCodeValue = event.target.value;
    let drugDescription =
      event.target[event.target.selectedIndex].getAttribute("description");
    let strengthValue =
      event.target[event.target.selectedIndex].getAttribute("strengthvalue");
    let controlledSubstanceCode = event.target[
      event.target.selectedIndex
    ].getAttribute("controlledsubstancecode");
    let ndc = event.target[event.target.selectedIndex].getAttribute("ndc");
    let quantityUom =
      event.target[event.target.selectedIndex].getAttribute("quantityuom");
    let quantityUomCode =
      event.target[event.target.selectedIndex].getAttribute("quantityuomcode");
    let strengthFormCode =
      event.target[event.target.selectedIndex].getAttribute("strengthformcode");
    let strengthUomCode =
      event.target[event.target.selectedIndex].getAttribute("strengthuomcode");
    let drugNameCode =
      event.target[event.target.selectedIndex].getAttribute("drugNamecode");

    props.prescribeDrug({
      ...props?.selectedMedicine,
      drugDbCode: drugDbCodeValue,
      drugDescription: drugDescription,
      DDID: drugDbCodeValue,
      controlledSubstanceCode: controlledSubstanceCode,
      drugNameCode,
      ndc,
      quantityUom,
      quantityUomCode,
      strengthFormCode,
      strengthUomCode,
      strengthValue,
    });
  };
  const handleClearSigSection = (event) => {
    event.preventDefault();
    setClearState(true);
    props.setDrugData(p=>({
      ...props?.drugData,
      DDID: props?.selectedMedicine?.DDID,
      DrugDescription: props?.selectedMedicine?.DispensableDrugs,
      Sig: {
        SigText: "",
      },
      Quantity: "",
      daySupply: undefined,
      NumberOfRefills: 0,
      RefillAsNeeded: false,
      DispenseAsWritten: false,
      DoseForm: "",
      PrescriptionStartDate: {
        Date: getDMYformate(new Date(), "YYYY-MM-DD"),
      },
      EarliestFillDate: {
        Date: getDMYformate(new Date(), "YYYY-MM-DD"),
      },
      WrittenDate: {
        Date: getDMYformate(new Date(), "YYYY-MM-DD"),
      },
      alertPhysician72: false,
      PatientDirections: "",
    }));
    // setClearState(false);
  };

  const populateSigDetails = (highDoseForm, highFrequency, sigText) => {
    if (
      props?.drugData?.Sig?.SigText?.trim()?.toLowerCase() ===
      sigText?.trim()?.toLowerCase()
    ) {
      props?.setDrugData({
        ...props?.drugData,
        highDoseForm: highDoseForm,
        highFrequency: highFrequency,
      });
      return true;
    } else {
      return false;
    }
  };

  const sigrelated = async () => {
    let sigList = await props?.sig;
    // console.log("props?.isLoading: ",props?.isLoading,sigList);
    if (props?.isLoading === false) {
      let isMatched = false;
      sigList?.forEach((drugSig) => {
        const { textValue, drugOrderId, highDoseForm, highFrequency } = drugSig;

        if (
          prescribedMedications[0]?.Sig?.SigText?.trim()?.toLowerCase() ===
          textValue?.trim()?.toLowerCase()
        ) {
          isMatched = true;
          props?.setDrugData(p=>({
            ...prescribedMedications[0],
            highDoseForm: highDoseForm,
            highFrequency: highFrequency,
          }));
        }
      });
      console.log("isMatched: ", isMatched);
      if (
        !isMatched &&
        (props?.replaceRequest === true ||
          props?.reOrderPrescription === true) &&
        prescribedMedications?.length === 1
      ) {
        console.log("setting custom sig ");
        setCustomSigValue(prescribedMedications[0]?.Sig?.SigText);
        props?.setDrugData({
          ...prescribedMedications[0],
          customSigFlag: true,
          drugOrderId: "-1",
          textValue: prescribedMedications[0]?.Sig?.SigText,
          Sig: { SigText: prescribedMedications[0]?.Sig?.SigText },
          sigText: prescribedMedications[0]?.Sig?.SigText,
      
        });
      }
    }
  };

  const handledCustomSig = (customSigValue) => {
    setCustomSigValue(customSigValue);
    if (customSigValue?.length > MEDICATION_VALIDATION.sigTextMax) {
      setValidationErrors({
        ...validationErrors,
        [fieldNames.sigTextMax]: validationErrorMsgs.sigTextMax,
      });
    } else {
      let temp = validationErrors;
      delete temp.sigTextMax;
      setValidationErrors(temp);
    }
    props?.setDrugData({
      ...props?.drugData,
      Sig: { SigText: customSigValue },
      sigText: customSigValue,
      textValue: customSigValue,
      customSigFlag: true,
      drugOrderId: "-1",
    });
  };

  const handleDEAScheduleValue = () => {
    let deaSchedule = props?.drugData?.controlledSubstanceCode;

    if (deaSchedule === "2") {
      setDEASchedule("Schedule II");
    } else if (deaSchedule === "3") {
      setDEASchedule("Schedule III");
    } else if (deaSchedule === "4") {
      setDEASchedule("Schedule IV");
    } else if (deaSchedule === "5") {
      setDEASchedule("Schedule V");
    } else {
      setDEASchedule("");
    }
  };


  const fetchMedicationType = async ()=>{
    const httpResponse = await sendHttpRequest({
      url:`/drugs/?ndc=${props?.drugData?.ndc}`,
      method:'GET'
    })
    if(!httpResponse.error){
      setMedicationType(httpResponse?.data?.drugNameCode);
    }
  }

  const handleQuantityKeyPress = (event) => {
    const enteredChar = String.fromCharCode(event.charCode);
    let value = event.target.value;
    // Allow numeric values and backspace
    if (!/^\d$/.test(enteredChar) && event.charCode !== 8) {
      event.preventDefault();
    }

    // Check for leading zero or negative sign
    if (
      (value === '' && enteredChar === '0') ||
      (value === '0' && enteredChar !== '.') ||
      (value === '-' && enteredChar !== '.')
    ) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    // console.log(props?.isLoading, "useeffect");
    // console.log("I'm Sig selction useEffect");
    async function callSigRelated() {
      await sigrelated();
    }
    // console.log("Is loading staatee",props?.isLoading);
    if (props.replaceRequest === true || props.reOrderPrescription === true) {
      callSigRelated();
    } else {
      if (props?.drugData?.customSigFlag === true) {
        // console.log("drugData?.customSigFlag");
        setCustomSigValue(props?.drugData?.Sig?.SigText);
      } else {
        // console.log("Empty Custom Sig");
        setCustomSigValue("");
      }
    } // console.log("props?.isLoading USEEFFF", await props?.sig, "useeffect");
  }, [props.sig?.length]);

  useEffect(() => {
    setClearState(false);
    return () => {
      setCustomSigValue("");
    };
  }, []);

  useEffect(() => {
    handleDEAScheduleValue();
  }, [props?.drugData?.controlledSubstanceCode]);
  useEffect(() => {
    if (
      props?.drugData?.customSigFlag === false &&
      props?.replaceRequest !== true &&
      props?.reOrderPrescription !== true
    ) {
      setCustomSigValue("");
    }
  }, [props?.drugData?.drugDbCode]);
  useEffect(()=>{
    // setMedicationType(props.drugData?.drugNameCode)

    fetchMedicationType()
  },[props.drugData?.ndc])

  useEffect(()=>{
    async function callSigRelated() {
      await sigrelated();
    }
    if(props?.replaceRequest === true){
      callSigRelated()
    }
  },[props?.availableStrengths,props.sig?.length])
  return (
    <>
      <form
        id="sendRxForm"
        onSubmit={(e) => {
          e.preventDefault();
          props.addPrescriptionHandler();
        }}
      >
        <div
          className={`${classes["sig__info--border"]} px-2 py-3 bg--disabled container`}
          style={{ marginBottom: "20px" }}
        >
          <div className="row">
            <div className={`col-12 `}>
              <div className="d-flex flex-row mb-2">
                <div className="d-flex align-self-center">
                  <span className="font-14">
                    {"Selected medication: "}
                    {/* <strong>{props.selectedMedicine.DispensableDrugs}</strong> */}
                  </span>
                </div>
                <div className="d-flex align-self-center">
                  <span
                    className={`badge br-7 ms-3  fw-sb p-2 font-10 ${
                      medicationType === "Generic Name"
                        ? "border-outlined-blue"
                        : "border-outlined-primary"
                    }`}
                  >
                    {medicationType !== undefined ? medicationType === "Generic Name" ? "Generic" : "Brand" :""}
                  </span>
                </div>
                {dEASchedule !== undefined && dEASchedule?.length > 0 && (
                  <div className="d-flex align-self-center">
                    <span
                      className={`badge br-7 ms-3 fw-sb p-2 font-10 border-outlined-danger`}
                    >
                      {dEASchedule}
                    </span>
                  </div>
                )}
              </div>
              {props?.isAvailablestrengthsLoading ? (
                <Skeleton />
              ) : (
                <div className="col-sm-12 col-12">
                  {props?.availableStrengths?.length > 0 && (
                    <select
                      className={`${classes["custom__select"]}   input form-select font-12 text--secondary fw-sb  mb-3 border--default shadow-none br-10  text-wrap`}
                      aria-label="Strength select"
                      onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleStrengthChange(e);
                      }}
                    >
                      {props?.availableStrengths?.map((strengthItem) => {
                        // console.log("strengthItem",strengthItem);
                        return (
                          <option
                            className="font-12 text--terciary"
                            value={strengthItem?.drugDbCode}
                            description={strengthItem?.drugDescription}
                            controlledsubstancecode={
                              strengthItem?.controlledSubstanceCode
                            }
                            drugnamecode={strengthItem?.drugNameCode}
                            ndc={strengthItem?.ndc}
                            quantityuom={strengthItem?.quantityUom}
                            quantityuomcode={strengthItem?.quantityUomCode}
                            strengthformcode={strengthItem?.strengthFormCode}
                            strengthuomcode={strengthItem?.strengthUomCode}
                            strengthvalue={strengthItem?.strengthValue}
                            selected={
                              strengthItem?.drugDbCode ===
                              props.selectedMedicine?.drugDbCode
                                ? true
                                : false
                            }
                          >
                            {strengthItem?.drugDescription}
                          </option>
                        );
                      })}
                    </select>
                  )}
                </div>
              )}
            </div>
          </div>

          {props?.isLoading ? (
            <Skeleton />
          ) : (
            <>
              <div className="row">
                <div className="">
                  <h2 className="font-14 fw-sb">Sig Information</h2>
                  {props.sig?.length > 0 ? (
                    <select
                      onClick={(e) => {
                        if (e.target.tagName === "OPTION") {
                          e.preventDefault();
                          props.handleSigSelection(e);
                          handleDispenseQuantity(e);
                        }
                      }}
                      className={`form-select no-bg mb-3 ${classes['scroll__bar']} border--default shadow-none ${classes["sig"]} text-wrap`}
                      size={4}
                      aria-label="size 3 select"
                      onInvalid={(e) => {
                        e.target.setCustomValidity(
                          "Please select a sig before proceeding"
                        );
                      }}
                      onInput={(e) => {
                        e.target.setCustomValidity("");
                      }}
                      required
                    >
                      {props?.sig?.map((drugSig) => {
                        const {
                          textValue,
                          drugOrderId,
                          highDoseForm,
                          highFrequency,
                        } = drugSig;
                        return (
                          <option
                            className={`b2 text-wrap br-5 p-1 cursor--pointer`}
                            key={drugOrderId}
                            id={drugOrderId}
                            value={textValue}
                            highdoseform={highDoseForm}
                            highfrequency={highFrequency}
                            style={{ overflowX: "auto" }}
                            selected={
                              props?.drugData?.Sig?.SigText?.trim()?.toLowerCase() ==
                              textValue?.trim()?.toLowerCase()
                                ? true
                                : props?.drugData?.textValue !== undefined &&
                                  props?.drugData?.drugOrderId == drugOrderId
                                ? true
                                : false
                            }
                          >
                            {textValue}
                          </option>
                        );
                      })}
                    </select>
                  ) : (
                    <input
                      type="text"
                      className="form-control font-14  mb-3"
                      disabled
                      value="No sig found"
                    />
                  )}
                  <Divider sx={{ color: "#d9e8ec" }} />
                  {/* New Sig Screen Code */}
                  {(props?.drugData?.customSigFlag === true || props?.drugData?.drugOrderId === "-1") && (
                    <div className="form-group">
                      <div className="d-flex flex-row justify-content-between">
                        <label className="font-12 text--secondary">
                          Custom Sig
                        </label>
                        <div className=" font-10 text--secondary align-self-baseline p-0 my-auto">
                          {props?.drugData?.customSigFlag === true
                            ? customSigValue?.length + "/1000"
                            : ""}
                        </div>
                      </div>
                      <TextArea
                        type="text"
                        value={
                          props?.drugData?.customSigFlag === true
                            ? customSigValue
                            : props?.drugData?.drugOrderId === "-1" ? props?.drugData?.textValue :""
                        }
                        className={`form-control border--default br-10 shadow-none mb-3 font-14 ${classes.input}`}
                        gettext={handledCustomSig}
                        required
                        maxLength={1000}
                        rows={3}
                      />
                      <ValidationErrorMessage
                        fieldName={fieldNames.sigTextMax}
                        errors={validationErrors}
                      />
                    </div>
                  )}
                  {/* <div className="form-group">
                    <label className="font-12 text--secondary">
                      Patient Directions
                    </label>
                    <input
                      type="text"
                      value={props?.drugData?.PatientDirections}
                      className={`form-control border--default br-10 shadow-none mb-3 font-14 ${classes.input}`}
                      onChange={handleAdditionalDirections}
                    />
                  </div> */}
                </div>
              </div>

              <div className="row">
                <div className="col-sm-3">
                  <label className="font-12 text--secondary">Days Supply</label>
                  <input
                    type="text"
                    id="daysSupply"
                    // pattern="^[a-zA-Z0-9]+$"
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                    value={
                      props?.drugData?.daySupply ?? ""
                    }
                    // required
                    autoComplete={false}
                    onInvalid={(e) => {
                      e.target.setCustomValidity("Please enter days supply");
                    }}
                    onInput={(e) => {
                      e.target.setCustomValidity("");
                    }}
                    onChange={(e) => {
                      handleDaysSupply(e);
                    }}
                    // maxLength={3}
                    className={`form-control border--default br-10 shadow-none font-14 ${classes.input} mb-3`}
                    highdoseform={props?.drugData?.highDoseForm}
                    highfrequency={props?.drugData?.highFrequency}
                  />
                  {
                    validationErrors?.length === 0 ?
                    null:
                    <ValidationErrorMessage
                      fieldName={fieldNames.daysSupplyMax}
                      errors={validationErrors}
                    />
                  }
                </div>
                <div className="col-sm-3">
                  <label className="font-12 text--secondary">Unit</label>
                  {/* {console.log("BEFORE UOM", props?.drugData)} */}
                  <ColoredToolTip
                    title={"".concat(
                      props?.drugData?.quantityUom?.charAt(0)?.toUpperCase(),
                      props?.drugData?.quantityUom?.slice(1) || ""
                    )}
                    arrow
                    placement="top"
                  >
                    <span
                      className={`form-control cursor--pointer border--default br-10 shadow-none font-14 mb-3 ${classes.input} ${classes["dose__form"]}`}
                    >
                      {truncate(
                        props?.drugData?.quantityUom
                          ? "".concat(
                              props?.drugData?.quantityUom
                                ?.charAt(0)
                                ?.toUpperCase(),
                              props?.drugData?.quantityUom?.slice(1) || ""
                            )
                          : "",
                        10
                      )}
                    </span>
                  </ColoredToolTip>
                </div>

                <div className="col-sm-3">
                  <label className="font-12 text--secondary">Quantity</label>
                  <input
                    type="number"
                    value={props?.drugData?.Quantity ?? ""
                      // props?.drugData?.drugOrderId === "-1" ? props?.drugData?.Quantity : 
                      // Number(props?.drugData?.daySupply) > 0
                        // ? 
                        // props?.drugData?.Quantity
                        // : ""
                    }
                    ref={quantityRef}
                    // min={0}
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                    required
                    autoComplete="off"
                    onInvalid={(e) => {
                      e.target.setCustomValidity("Please enter quantity");
                    }}
                    onInput={(e) => {
                      e.target.setCustomValidity("");
                    }}
                    pattern="^[a-zA-Z0-9]+$"
                    // placeholder="Dispense"
                    id="dispenseQuantity"
                    // defaultValue={dispenseQuantityValue }
                    className={`form-control border--default br-10 shadow-none font-14 mb-3 ${classes.input}`}
                    onChange={(e) => handleDispenseValue(e)}
                    onKeyPress={handleQuantityKeyPress}
                    highdoseform={props?.drugData?.highDoseForm}
                    highfrequency={props?.drugData?.highFrequency}
                  />
                  <ValidationErrorMessage
                    errors={validationErrors}
                    fieldName={fieldNames.quantityMax}
                  />
                  <ValidationErrorMessage
                    errors={validationErrors}
                    fieldName={fieldNames.quantityPositive}
                  />
                </div>

                <div className="col-sm-3">
                  <label className="font-12 text--secondary">Refills</label>
                  <PnToolTip
                    title={`Total quantity to be dispensed = ${
                      isNaN(
                        Number(props?.drugData?.NumberOfRefills) *
                          Number(props?.drugData?.Quantity) +
                          Number(props?.drugData?.Quantity)
                      ) === true
                        ? 0
                        : Number(props?.drugData?.NumberOfRefills) *
                            Number(props?.drugData?.Quantity) +
                          Number(props?.drugData?.Quantity)
                    } ${"".concat(
                      props?.drugData?.quantityUom?.toLowerCase() + "(s)"
                    )}`}
                  >
                    <input
                      type="number"
                      pattern="^[a-zA-Z0-9]+$"
                      min={0}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                      // placeholder="Refills"
                      value={props?.drugData?.NumberOfRefills}
                      onChange={(e) => handleNoOfRefill(e)}
                      required={props?.replaceRequest === true ? true : false}
                      className={`form-control border--default br-10 shadow-none font-14 ${classes.input}`}
                    />
                  </PnToolTip>
                  <ValidationErrorMessage
                    fieldName={fieldNames.numberOfRefillsMax}
                    errors={validationErrors}
                  />
                </div>
              </div>

              {/* {
                props?.replaceRequest !== true ?
                  <div className="row mb-2">
                    <div className="col-sm-6 col-6">
                      <h6 className="text--secondary font-12 m-0 fw-thin">RxfillIndicator</h6>
                      <select
                        className={`input form-select font-14 text--terciary  border--default shadow-none br-10  text-wrap`}
                        aria-label="Select Rx Fill Indicator"
                        onChange={props?.handleRxFillIndicator}
                        >
                          <option value="" key={-1}>Please select</option>
                          {
                            props?.rxFillIndicator?.map((fillindicator, index)=>{
                              return (
                                <option key={index} value={fillindicator?.value}>
                                  {fillindicator?.indicator}
                                </option>
                              )
                            })
                          }
                      </select>
                    </div>
                  </div>
                : ""
              } */}
              <div className="row ">
                {/* <div className="col-sm-12 col-12">
                  <div className="form-check ">
                    <input
                      type="checkbox"
                      className={`form-check-input ${classes["checkbox-accent"]} border--default`}
                      value="Refill as needed"
                      id="refillAsNeededCb"
                      checked={props?.drugData?.RefillAsNeeded ? true : false}
                      onChange={(e) => handleRefillAsNeeded(e)}
                    />
                    <label
                      className="form-check-label font-14 text--terciary "
                      htmlFor="refillAsNeededCb"
                    >
                      Refill as needed
                    </label>
                  </div>
                </div> */}
                <div className="col-sm-12 col-12">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className={`form-check-input border--default `}
                      value="Dispense as written"
                      onChange={(e) => handleDispenseAsWritten(e)}
                      id="dispenseAsWrittenCb"
                      checked={
                        props?.drugData?.DispenseAsWritten ? true : false
                      }
                    />
                    <label
                      className="form-check-label font-14 text--terciary "
                      htmlFor="dispenseAsWrittenCb"
                    >
                      Dispense as written
                    </label>
                  </div>
                </div>
                <div className="col-sm-12 col-12">
                  <div className="form-check ">
                    <input
                      type="checkbox"
                      className={`form-check-input border--default `}
                      onChange={(e) => handlePhysicianAlert72hrs(e)}
                      id="Alert physician 72 hours before a new refill is required"
                      checked={props?.drugData?.alertPhysician72 ? true : false}
                    />
                    <label
                      className="form-check-label font-14 text--terciary "
                      htmlFor="Alert physician 72 hours before a new refill is required"
                    >
                      Alert physician 72 hours before a new refill is required
                    </label>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-sm-4 col-12 ">
                  <div className="form-group">
                    <label
                      htmlFor="earliestFillDate"
                      className="font-12 text--secondary"
                    >
                      Earliest fill date
                    </label>
                    {
                      <CalenderDatePicker
                        dobHandler={handleEarliestFillDate}
                        dateFormat="MMM d, yyyy"
                        // minDate={parseISO(props?.drugData?.EarliestFillDate?.Date) }
                        minDate={clearState === true ? joined : joined}
                        date={
                          clearState === true
                            ? joined
                            : props?.drugData?.EarliestFillDate?.Date ?? joined
                        }
                        prescribeDrug={true}
                      />
                    }
                  </div>
                </div>
                <div className="col-sm-4 col-12">
                  <div className="form-group">
                    <label
                      htmlFor="prescriptionStartDate"
                      className="font-12 text--secondary"
                    >
                      Start date
                    </label>
                    {
                      <>
                        <CalenderDatePicker
                          dobHandler={handlePrescriptionStartDate}
                          dateFormat="MMM d, yyyy"
                          minDate={
                            clearState === true
                              ? joined
                              : props?.updateCheck
                              ? props?.drugData?.PrescriptionStartDate?.Date ??
                                joined
                              : props?.drugData?.EarliestFillDate?.Date ??
                                joined
                          }
                          date={
                            clearState === true
                              ? joined
                              : props?.updateCheck
                              ? props?.drugData?.PrescriptionStartDate?.Date ??
                                joined
                              : props?.drugData?.EarliestFillDate?.Date ??
                                joined
                          }
                          // defaultValue = {}
                          prescribeDrug={true}
                        />
                      </>
                    }
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-sm-3 ">
                  <button
                    className="btn btn-link  d-inline-block shadow-none p-0  text--blue fw-sb font-12 "
                    onClick={(e) => {
                      handleClearSigSection(e);
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
              <Divider sx={{ backgroundColor: "#d9e8ec", my: 1 }} />
              <div className="row">
                <div className="col-sm-12 col-12">
                  <div className="d-flex flex-row justify-content-between">
                    <h2 className="font-14 text--terciary">
                      Note to Pharmacist
                    </h2>

                    <div className=" font-10 text--secondary align-self-baseline p-0 my-auto">
                      {props?.drugData?.Note?.length > 0
                        ? props?.drugData?.Note?.length + "/210"
                        : "0/210"}
                    </div>
                  </div>
                  <textarea
                    className={`form-control font-14 mb-3 shadow-none ${classes.input} border--default br-10`}
                    id="note"
                    name="note"
                    onChange={(e) => handleNoteToPharmacy(e)}
                    rows="3"
                    defaultValue={props?.drugData?.Note}
                    maxLength={210}
                  ></textarea>
                  <ValidationErrorMessage
                    fieldName={fieldNames.noteMax}
                    errors={validationErrors}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        {/* d-flex mt-3 justify-content-end */}
        <div className="text-end">
          {props?.replaceRequest ? null : (
            <button
              onClick={() => {
                props.handleCancelMedication();
              }}
              className={`btn px-5 py-2 d9e8ec--button`}
            >
              Cancel
            </button>
          )}
          {props.updateCheck ? (
            <button
              // onClick={props.addPrescriptionHandler}-
              className={`btn ${
                props.replaceRequest === true
                  ? "blue_task_button"
                  : "d9e8ec--button"
              }`}
              type="submit"
              form="sendRxForm"
              disabled={
                Object?.keys(validationErrors)?.length !== 0 ? true : false
              }
            >
              {props.replaceRequest === true
                ? "Continue"
                : props.reOrderPrescription === true
                ? "Add to Prescription"
                : props.reOrderPharmacyFlag === true
                ? "Add to Prescription"
                : "Update Medication"}
            </button>
          ) : (
            <button
              // onClick={props.addPrescriptionHandler}
              className={`btn px-2 py-2 d9e8ec--button`}
              form="sendRxForm"
              type="submit"
              disabled={
                props?.isLoading
                  ? true
                  : Object?.keys(validationErrors)?.length !== 0
                  ? true
                  : false
              }
            >
              Add to Prescription
            </button>
          )}
        </div>
      </form>
                
      {/* {console.log("Custom sig value: ",customSigValue)} */}
      {/* {console.log("props?.drugData: ",props?.drugData)} */}
      {/* {console.log("props?.drugData?.daySupply: ",Number(props?.drugData?.daySupply), typeof(props?.drugData?.daySupply))} */}
    </>
  );
};

export default SigSelection;

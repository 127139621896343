import { ActionTypes } from "../constants/actionTypes"
const patientEncounterDataInitialState = {
    appoinment:{}
}

const setPatientEncounterDataReducer = (state=patientEncounterDataInitialState, action)=>{
    if (action.type === ActionTypes.SET_PATIENT_ENCOUNTER_DATA ){
        return {
            ...state,
            appoinment: action.payload
            
        }
    }
    return state
}


export default setPatientEncounterDataReducer;
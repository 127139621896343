
const MasterCard = (props) => {
  return (
<svg id="mastercard" width="30px" height="25px" viewBox="0 0 60 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="mastercard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="mastercard">
            <path d="M60,35 C60,37.75 57.75,40 55,40 L5,40 C2.25,40 0,37.75 0,35 L0,5 C0,2.25 2.25,0 5,0 L55,0 C57.75,0 60,2.25 60,5 L60,35 Z" id="Path" fill="#F5F5F5"></path>
            <path d="M55,0 L5,0 C2.25,0 0,2.25 0,5 L0,35 C0,37.75 2.25,40 5,40 L55,40 C57.75,40 60,37.75 60,35 L60,5 C60,2.25 57.75,0 55,0 Z M55,1 C57.206,1 59,2.794 59,5 L59,35 C59,37.206 57.206,39 55,39 L5,39 C2.794,39 1,37.206 1,35 L1,5 C1,2.794 2.794,1 5,1 L55,1 Z" id="Shape" fill="#D0D0D0" fill-rule="nonzero"></path>
            <circle id="Oval" fill="#F79E1B" cx="35.27" cy="20.012" r="8.599"></circle>
            <circle id="Oval" fill="#EB001B" cx="24.73" cy="19.988" r="8.599"></circle>
            <path d="M26.671,19.993 C26.665,22.757 27.962,25.219 29.984,26.797 C32.014,25.228 33.323,22.773 33.33,20.01 C33.336,17.246 32.039,14.784 30.017,13.206 C27.986,14.773 26.677,17.229 26.671,19.993 Z" id="Path" fill="#FF5F00"></path>
        </g>
    </g>
</svg>
  );
};

export default MasterCard;

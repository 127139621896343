import { useState, useEffect } from 'react';
import sendHttpRequest from '../hooks/sendHttpRequest';
import { Skeleton, Table, TableBody, TableContainer, TableHead } from '@mui/material';

import { StyledTableCell, StyledTableRow, UnStyledTableRow } from '../UI/Reusable/PnComponents';
import { useAuth } from '../hooks/useAuth';
import InPatientService from '../Services/InPatient.service';
import { VideoCallContext } from '../UI/AgoraHooks/VideoCallContext';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDiagnosis, setDisableSendRxButton, setPatientEncounterData, setPrescriptionNoteText, setUpdateEncounterData, updatePrescribedMedication } from '../../redux/actions/providerActions';
import { useNavigate } from 'react-router-dom';
import { RefreshDataComponent } from '../UI/General/RefreshDataComponent';
import { VISIT_STATUS_COLORS } from '../../res/strings';
import { PRACTICE_MANAGEMENT } from '../../utils/helpers/subscriptionsConstants';

const MedicalAssistantTableView = ({
    columnHeadersList,
    appointmentTableRows,
    areAppointmentsLoading,
    fetchAppointments,
    appointments,
    handleBackdropClose
}) => {

    const { user } = useAuth();
    const [rooms, setRooms] = useState([]);
    const [selectedRoom, setSelectedRoom] = useState({});
    const [selectedStatus, setSelectedStatus] = useState({});
    const [areRoomsLoading, setAreRoomsLoading] = useState(false);
    const videoCallContext = useContext(VideoCallContext);
    const [isAppointmentStartLoading, setIsAppointmentStartLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);

    const [resumeSOAPNoteFlag, setResumeSOAPNoteFlag] = useState(false);


    async function getRooms() {
        // console.log("Calling getRooms()...")
        setAreRoomsLoading(true);
        const data = await InPatientService.getFacilityRooms(user?.userId);
        // console.log(data);
        if (data?.length === 0) {
            // setIsCheckInDisabled(true);
        }
        setRooms(data);
        // setSelectedRoom(data?.at(0)?.facilityRoomId);
        setAreRoomsLoading(false);
    }


    async function handleExamRoom(event, id) {
        const appointmentId = event.target.dataset.appid;
        const roomId = event.target.value;
        const selectedOption = event.target.options[event.target.selectedIndex];
        const selectedRoomData = selectedOption.getAttribute('data-room');

        // console.log('Selected room data:', selectedRoomData);
        // return;
        setSelectedRoom({ ...selectedRoom, [appointmentId]: { roomId, roomName: selectedRoomData } });
        const response = await sendHttpRequest({
            method: "POST",
            url: `/appointment/${appointmentId}/checkin`,
            data: {
                "roomId": roomId
            }
        });
        if (!response.error) {
            await getRooms();
        }
    }

    async function handleVisitStatus(event, id) {
        const appointmentId = event.target.dataset.appid;
        const status = event.target.value;
        setSelectedStatus({ ...selectedStatus, [appointmentId]: status });
        const response = await sendHttpRequest({
            method: "POST",
            url: `/appointment/${appointmentId}/${status}`,
        });
        if (!response.error) {
            delete selectedRoom[appointmentId];
            await fetchAppointments();
        }
    }

    const sendStartAppointmentRequest = async (appointmentId, appointment) => {
        setIsAppointmentStartLoading(true);
        videoCallContext.updateAppointmentData(appointmentId);
        videoCallContext.updateAppointmentObject(appointment);
        const httpResponse = await sendHttpRequest({
            url: `/appointment/${appointmentId}/start`,
            method: "GET",
            params: {
                providerId: user?.userId,
            }
        });
        if (!httpResponse.error) {
            videoCallContext.updateAppointmentData(appointmentId);
            const response = httpResponse?.data
            // console.log(response);
            setSelectedStatus({ ...selectedStatus, [response?.id]: response?.patientVisit?.patientStatus?.internalValue });
            if (response?.prescriptionReference?.soapNote !== undefined) {
                setResumeSOAPNoteFlag(true);
            } else {
                setResumeSOAPNoteFlag(false);
            }

        }
        // setIsSessionUpdated(false);
        setIsAppointmentStartLoading(false);
        handleBackdropClose();
    };

    const handlePatientEncouterData = async (appointmentId, appointment, appointmentType, encounterId, patientId, providerId, visitStatus, itemObject) => {
        // dispatch(setJoinPage(true))
        // props?.handleToggle();
        // alert(appointmentType);
        // console.log(visitStatus);

        if (appointment?.status?.toLowerCase() === "pending") {
            await sendStartAppointmentRequest(appointmentId, appointment);
        }
        videoCallContext.updateAppointmentData(appointmentId);
        videoCallContext.updateAppointmentObject(appointment);
        dispatch(setPrescriptionNoteText(""));
        dispatch(setPatientEncounterData({}));
        dispatch(setDisableSendRxButton(false));
        dispatch(updatePrescribedMedication([]));
        dispatch(setPatientEncounterData(appointment));
        // dispatch(setVideoOpen(true));
        // dispatch(setConsulationInProgress(true));
        dispatch(
            setUpdateEncounterData({
                Diagnosis: {
                    Note: "",
                    Primary: [],
                    Secondary: [],
                },
            })
        );
        dispatch(
            setDiagnosis({
                Note: "",
                Primary: [],
                Secondary: [],
            })
        );


        if (appointmentType?.key?.toLowerCase() === "follow_up" || appointmentType?.key === undefined) {
            navigate(`/all-patients/${patientId}/soap-note`, {
                state: {
                    prescriptionReferenceId:
                        encounterId,
                    encounterId: encounterId,
                    appointmentId: appointmentId,
                    selectedStatus: selectedStatus[appointmentId] !== undefined ? selectedStatus[appointmentId] : visitStatus,
                    isResume: itemObject?.appointment?.prescriptionReference?.soapNote !== undefined ? true : false
                },
            });
        } else {
            navigate(`/consult/${providerId}/${encounterId}/${patientId}`, {
                state: {
                    prescriptionReferenceId:
                        encounterId,
                    appointmentId: appointmentId,
                    selectedStatus: selectedStatus[appointmentId] !== undefined ? selectedStatus[appointmentId] : visitStatus
                },
            });
        }
    };


    useEffect(() => {
        getRooms();
    }, [appointmentTableRows]);

    return (
        <>
            <div className="d-flex mb-1 mt-3 justify-content-between align-items-baseline">
                <h2 className="font-22">Upcoming Appointments</h2>
                <div>
                    <RefreshDataComponent
                        callback={() => fetchAppointments()}
                        variant='no-bar'
                    />

                </div>
            </div>
            <TableContainer sx={{ borderRadius: "16px", border: "1px solid #D9E8EC" }}>
                <Table>
                    <TableHead
                        sx={{
                            borderTopColor: "#FAFCFC",
                            borderRightColor: "#FAFCFC",
                            borderLeftColor: "#FAFCFC",
                            borderBottom: "1px solid #D9E8EC",
                        }}
                    >
                        <StyledTableRow>
                            {columnHeadersList?.map((item, idx) => (
                                <StyledTableCell key={item?.concat(idx)}><span className="font-12 text--secondary">{item}</span></StyledTableCell>
                            ))}
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {areAppointmentsLoading ? <StyledTableRow
                            key={"appointments-loading"}
                            sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                            }}
                        >
                            <StyledTableCell
                            // sx={{ color: "#336383", fontWeight: "600" }}
                            >
                                <Skeleton sx={{ width: 70 }} />
                            </StyledTableCell>
                            <StyledTableCell
                            // sx={{ width: "100", color: "#336383" }}
                            >
                                <Skeleton sx={{ width: 70 }} />
                            </StyledTableCell>

                            <StyledTableCell>
                                <Skeleton sx={{ width: 70 }} />
                            </StyledTableCell>

                            <StyledTableCell>
                                <Skeleton sx={{ width: 70 }} />
                            </StyledTableCell>

                            <StyledTableCell>
                                <Skeleton sx={{ width: 70 }} />
                            </StyledTableCell>

                            <StyledTableCell>
                                <Skeleton sx={{ width: 70 }} />
                            </StyledTableCell>

                            <StyledTableCell>
                                <Skeleton sx={{ width: 70 }} />
                            </StyledTableCell>

                            <StyledTableCell>
                                <Skeleton sx={{ width: 70 }} />
                            </StyledTableCell>
                            <StyledTableCell>
                                <Skeleton sx={{ width: 70 }} />
                            </StyledTableCell>
                        </StyledTableRow> :
                            appointmentTableRows?.map((item, idx) => {
                                const roomObject = Object.keys(selectedRoom).length !== 0 ? ({ "examRoomId": selectedRoom[item?.id]?.roomId, "examRoom": selectedRoom[item?.id]?.roomName }) : ({ "examRoom": item?.examRoom, "examRoomId": item?.facilityRoomId })

                                const patientVisitStatus = item?.visitStatusValue
                                const appointmentType = item?.appointment?.appointmentType;
                                const patientId = item?.appointment?.prescriptionReference?.patient?.userId;
                                const providerId = item?.appointment?.prescriptionReference.providerTenantPracticeLocation.provider.userId;
                                const encounterId = item?.appointment?.prescriptionReference?.prescriptionReferenceId;

                                return (

                                    <UnStyledTableRow
                                        key={item?.patientId + idx}
                                        sx={{
                                            "&:last-child td, &:last-child th": { border: 0 },
                                            backgroundColor: VISIT_STATUS_COLORS[item?.visitStatusValue]
                                        }}
                                    >
                                        <StyledTableCell

                                        // sx={{ color: "#336383", fontWeight: "600" }}
                                        >
                                            <div className="p-0 m-0 font-12 d-flex flex-column">
                                                {item?.startTime}
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                        // sx={{ width: "100", color: "#336383" }}
                                        >
                                            {item?.patientName}
                                        </StyledTableCell>

                                        <StyledTableCell>{item?.dateOfBirth}</StyledTableCell>

                                        <StyledTableCell>
                                            {item?.biologicalSex}
                                        </StyledTableCell>

                                        <StyledTableCell>{item?.reasonForVisit}</StyledTableCell>

                                        <StyledTableCell>{item?.provider}</StyledTableCell>
                                        {/* {console.log(item?.startDate, INIT_DATE, item?.startDate == INIT_DATE)} */}
                                        <StyledTableCell onClick={e => e.stopPropagation()}>
                                            {areRoomsLoading ? <Skeleton /> :

                                                subscriptionPrivilegeGroups[PRACTICE_MANAGEMENT]?.arp?.active === true ?
                                                    <div className="form-group">
                                                        <select
                                                            className={`form-select font-13 shadow-none`}
                                                            aria-label="Provider select"
                                                            style={{
                                                                border: "1px solid #D9E8EC",
                                                                height: "36px",
                                                                width: "150px",
                                                                borderRadius: "10px",
                                                                // background: "#FAFCFC",
                                                            }}
                                                            required
                                                            data-appid={item?.id}
                                                            // disabled={item?.startDate !== INIT_DATE}
                                                            onChange={handleExamRoom}
                                                            value={selectedRoom[item?.id] !== undefined && selectedRoom[item?.id]?.roomId !== undefined ? selectedRoom[item?.id]?.roomId : item?.facilityRoomId !== null && item?.facilityRoomId !== undefined ? item?.facilityRoomId : ''}
                                                        >
                                                            {/* {console.log(selectedRoom[item?.id]?.roomId)} */}
                                                            {/* {console.log(selectedRoom[item?.id] !== undefined ? selectedRoom[item?.id] : item?.facilityRoomId !== null && item?.facilityRoomId !== undefined ? item?.facilityRoomId : '')}
                                            {console.log("Item: ", item?.facilityRoomId)}
                                            {console.log("Selected: ", selectedRoom[item?.id])} */}
                                                            {/* {console.log(selectedRoom[item?.id])} */}
                                                            {/* {selectedRoom[item?.id] !== undefined && selectedRoom[item?.id]?.roomId !== undefined && console.log("Room Name", selectedRoom[item?.id]?.roomName)} */}
                                                            {selectedRoom[item?.id]?.roomId === undefined && item?.facilityRoomId !== null && item?.facilityRoomId !== undefined ? <option value={item?.facilityRoomId}>{item?.examRoom}</option> : selectedRoom[item?.id]?.roomId === undefined && item?.facilityRoomId === undefined ? <option value="">Select Room</option> : <option value={selectedRoom[item?.id]?.roomId}>{selectedRoom[item?.id]?.roomName}</option>}
                                                            {rooms?.map((room, idx) => {
                                                                return <option key={idx} data-room={room?.name} value={room?.facilityRoomId}>{room?.name}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                    :
                                                    <span className='font-12 text--terciary'>{item?.examRoom !== undefined ? item?.examRoom : "-"}</span>


                                            }
                                        </StyledTableCell>

                                        <StyledTableCell onClick={e => e.stopPropagation()}>
                                            <div className="form-group">
                                                <select
                                                    key={idx}
                                                    className={`form-select font-13 shadow-none`}
                                                    aria-label="Provider select"
                                                    style={{
                                                        border: "1px solid #D9E8EC",
                                                        height: "36px",
                                                        width: "150px",
                                                        borderRadius: "10px",
                                                        // background: "#FAFCFC",
                                                    }}
                                                    required
                                                    data-appid={item?.id}

                                                    // disabled={item?.startDate !== INIT_DATE}
                                                    onChange={handleVisitStatus}
                                                    value={selectedStatus[item?.id] !== undefined ? selectedStatus[item?.id] : item?.visitStatusValue !== undefined ? item?.visitStatusValue : item?.noShow === true ? 'no-show' : 'scheduled'}
                                                >
                                                    {/* {console.log(item)} */}
                                                    {/* {console.log("selectedStatus", selectedStatus)} */}
                                                    <option value="">Select Status</option>
                                                    <option value="scheduled">Scheduled</option>
                                                    <option value="checkin">Checked In</option>
                                                    <option value="ma-reviewed">MA Review</option>
                                                    <option value="provider-ready">Provider Ready</option>
                                                    <option value="in-consultation">In Consultation</option>
                                                    <option value="consultation-completed">Consultation Complete</option>
                                                    <option value="departed">Visit Complete</option>
                                                    <option value="no-show">No-show</option>
                                                    <option value="canceled">Canceled</option>
                                                </select>
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell>

                                            {
                                                patientVisitStatus?.toLowerCase()?.trim() === "ma-reviewed" || patientVisitStatus?.toLowerCase()?.trim() === "checkin" ? (
                                                    <>
                                                        <button
                                                            disabled={
                                                                videoCallContext.getIsVideoCallActive() &&
                                                                videoCallContext.appointmentData !== null &&
                                                                videoCallContext.appointmentData !== item?.id
                                                            }
                                                            className="btn blue_task_button text-white b2 px-4 br-10 py-1"
                                                            onClick={() => handlePatientEncouterData(item?.id, item?.appointment, appointmentType, encounterId, patientId, providerId, "ma-reviewed")}

                                                        >
                                                            Start
                                                        </button>
                                                    </>
                                                )
                                                    : <>
                                                        <button
                                                            disabled={
                                                                videoCallContext.getIsVideoCallActive() &&
                                                                videoCallContext.appointmentData !== null &&
                                                                videoCallContext.appointmentData !== item?.id
                                                            }
                                                            className="btn blue_task_button text-white b2 px-4 br-10 py-1"
                                                            onClick={() => handlePatientEncouterData(item?.id, item?.appointment, appointmentType, encounterId, patientId, providerId, item?.visitStatusValue, item)}

                                                        >
                                                            View
                                                        </button>
                                                    </>


                                            }
                                        </StyledTableCell>
                                    </UnStyledTableRow>
                                );
                            })}
                        {/* </>} */}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default MedicalAssistantTableView
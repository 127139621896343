import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Modal } from "react-bootstrap";
import { Backdrop, CircularProgress } from "@mui/material";

import TextArea from "../forms/TextArea";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { numbersEnum, renewalRequestStrings, common_strings } from "../../res/strings";
import { acceptRenewalRequestUrl } from "../../res/endpointurls";
import { useAuth } from "../hooks/useAuth";
import { useUnsavedWorkPrompt } from "../hooks/useUnsavedWorkPrompt";
import { unsavedWorkHandler } from "../../utils/helpers/HelperFunctions";
import denialcodes from "./denialcodes";
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";
import { WarningIcon } from "../UI/Reusable/WarningIcon";

const rxRenewalResponseApiCall = async (userId, taskId, action, denyReason, note, spi) => {
    const body = {
        taskId: taskId,
        action: action,
        denyCode: denyReason,
        note: note
    }
    const httpResponse = await sendHttpRequest({ url: acceptRenewalRequestUrl(userId, spi), method: "POST", data: body });
    return httpResponse;
}

const DenyRenewalModal = (props) => {

    console.log(props);

    const [isDirty, onDirty, onPristine] = useUnsavedWorkPrompt();
    let _str = { ...numbersEnum?.en, ...renewalRequestStrings.en, ...common_strings.en };
    const { user } = useAuth();
    const task = props?.renewalTask;
    const [denyReason, setDenyReason] = useState("");
    const [note, setNote] = useState("");
    const [noteRequired, setNoteRequired] = useState(false);

    const [isBackdropOpen, setIsBackdropOpen] = useState(false);
    const [isErrorMessage, setIsErrorMessage] = useState(false);

    const handleSnackbarClose = () => (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setIsErrorMessage(false);
    };


    const handleDenyRequest = async (event) => {

        setIsBackdropOpen(true);
        const response = await rxRenewalResponseApiCall(user?.userId, task?.taskId, _str.denied, denyReason, note, user?.spi);
        if (response?.error === false) {
            // setOpen(false);
            onPristine();
            props.showMessage(true);
            // props.reloadTasks();
            props.handleRenewalModal();
            props.handleModal();
        }
        else {
            props.showError(true);
        }
        props.reloadTasks(p => !p);
        setIsBackdropOpen(false);

    }

    const denyReasonUpdate = (e) => {
        onDirty();
        if (e.target.value === "") {
            setNoteRequired(true);
        } else {
            setNoteRequired(false);
        }
        setDenyReason(e.target.value);
    }

    return (
        <>
            <Modal
                show={true}
                // show={props.show}
                onHide={() => {
                    unsavedWorkHandler("modal", "There are unsaved changes, do you wish to continue?", isDirty, props.handleModal, onPristine, props.handleModal)

                }}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                backdropClassName="modal-backdrop"
                contentClassName="modal-border modal-shadow"
                scrollable
                centered
            >
                <Modal.Header className="modal-header">
                    <span
                        style={{
                            lineHeight: "12px",
                            width: "18px",
                            fontSize: "8pt",
                            color: "#336383",
                            position: "absolute",
                            top: 15,
                            right: 15,
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            unsavedWorkHandler("modal", "There are unsaved changes, do you wish to continue?", isDirty, props.handleModal, onPristine, props.handleModal)

                        }}
                    >
                        {<CloseIcon />}
                    </span>
                    <Modal.Title className="font-20 fw-sb">{_str.denyRenewalRequest}</Modal.Title>
                </Modal.Header>
                <form id="denyRenewalForm" className="text--terciary font-12"
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleDenyRequest(e);
                    }}
                >
                    <Modal.Body>
                        <div className="row">
                            {/* -------------------------Denail Reason----------------------- */}
                            <h6 className="font-12 text--terciary" hidden={task?.prescriptionReference?.patient.userId === 0}><WarningIcon style={{ height: "18px", fill: "#f8a02e" }} /> If denied, the prescription will not be refilled.</h6>
                            <h6 className="font-12 text--terciary" hidden={task?.prescriptionReference?.patient.userId !== 0}><WarningIcon style={{ height: "18px", fill: "#f8a02e" }} /> No patient record found.</h6>
                            <div className="col-12 col-md-12">
                                <h3 className="font-14 fw-sb text--terciary">{_str.reasonForDenial} <span>&#42;</span></h3>
                                <select
                                    onChange={(e) => { denyReasonUpdate(e); }}
                                    className="form-select font-14 border--default br-10 shadow-none input"
                                    aria-label="denialcode"
                                    id="denialcode"
                                    name="denyCode"
                                    required
                                    placeholder="Select Reason for Denial"
                                    style={{ borderRadius: "10px" }}
                                >
                                    <option value="" hidden={denyReason !== '' && true}>Select Reason for Denial</option>
                                    {denialcodes?.map((denialCode) => {
                                        return (<option value={denialCode?.code}>{denialCode?.description}</option>)
                                    })}
                                </select>

                            </div>

                            {/* -------------------------Note to Pharmacy----------------------- */}
                            <div className="col-12 col-md-12 mt-2">
                                <h3 className="font-14 fw-sb text--terciary">Note{noteRequired ? <span> &#42;</span> : ""}</h3>
                                <TextArea
                                    className="form-control br-10 input font-14 border--default shadow-none"
                                    gettext={(e) => { onDirty(); setNote(e) }}
                                    rows={3}
                                    required={noteRequired}
                                />
                            </div>
                        </div>

                    </Modal.Body>

                    <Modal.Footer>
                        <button
                            className="btn d9e8ec--button text-blue font-14 px-4 br-10 py-1 fw-sb"
                            onClick={(e) => {
                                unsavedWorkHandler("modal", "There are unsaved changes, do you wish to continue?", isDirty, () => {
                                    props.handleRenewalModal();
                                    props.handleModal();
                                }, onPristine, () => {
                                    props.handleRenewalModal();
                                    props.handleModal();
                                })
                                //   handleRenewalRequest(e);
                                props.handleRenewalTask(props.renewalTask);
                            }}
                            style={{
                                border: "#004FD4 0% 0% no-repeat padding-box",
                                width: "160px",
                                height: "44px",
                            }}
                        >
                            {_str.cancel}
                        </button>

                        <button
                            className="btn blue_task_button text-white font-14 px-4 br-10 py-1 fw-sb"
                            type="submit"
                            id="denyRenewalForm"
                            style={{
                                border: "#004FD4 0% 0% no-repeat padding-box",
                                height: "44px",
                                width: "160px",
                            }}
                        >
                            {_str.send}
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Backdrop
                sx={{
                    color: "#EC609B",
                    zIndex: (theme) => theme.zIndex.drawer + 1000,
                }}
                open={isBackdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>



        </>
    );
};

export default DenyRenewalModal;

import { useState } from "react";
import { Modal } from "react-bootstrap";
import sendHttpRequest from "../../../hooks/sendHttpRequest";

const AlertModal = ({ show, rightButtonText, leftButtonText, onYes, onNo, heading }) => {
  return (
    <Modal
      show={show}
      // onHide={() => {
      //   props.handleModal();
      // }}
      backdropClassName="modal-backdrop"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      contentClassName="modal-border modal-shadow"
      // contentClassName={`${classes["appointmentmodal__style"]} `}
      // dialogClassName={`${classes["custom-modal-style"]}`}
      // style={{style}}
    >
      <Modal.Body>
        <div className="container">
          <div className="row text-center justify-content-center">
            <div className="col-12">
              <p className="font-14 fw-sb text--terciary ">{heading}</p>

              <div className="mt-3">
                <button
                  className="btn fw-sb b1 btn--border text--blue d9e8ec--button px-5 br-10 py-1"
                  onClick={() => {
                    onNo(false);
                  }}
                >
                  {leftButtonText}
                </button>
                <button
                  className="btn blue_task_button text-white ms-3 px-5 py-1 br-10 b1"
                  onClick={() => {
                    onYes(true);
                  }}
                >
                  {rightButtonText}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* {console.log(props?.prescriber)} */}
      </Modal.Body>
    </Modal>
  );
};

export default AlertModal;

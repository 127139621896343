import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
// import store from "./redux/store";
import { store, persistor } from "./redux/store";

import { AuthProvider } from "./components/hooks/useAuth";
import { VideoCallProvider } from "./components/UI/AgoraHooks/VideoCallContext";
import { PersistGate } from 'redux-persist/integration/react';



ReactDOM.render(
  <React.StrictMode>

    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AuthProvider>
            <VideoCallProvider>
              <App />
            </VideoCallProvider>
          </AuthProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>

  </React.StrictMode>,
  document.getElementById("root")
);

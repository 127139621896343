import React from 'react'
import { ValidationErrorMessage } from '../validations/ValidationErrorMessage'

const NPIForm = ({ npi, handleNpi, validationErrors }) => {
    return (
        <div className="col-12 col-md-12 mt-2 ">
            <div className="row ">
                <div className="col-12 col-sm-6">
                    <h3 className="text--terciary font-14 fw-sb m-0">Type 1 NPI</h3>
                    <input type="text" className="form-control shadow-none font-12 text--terciary input br-10 border--default" onChange={handleNpi} value={npi} />
                    <ValidationErrorMessage errors={validationErrors} fieldName="npi" />
                </div>

            </div>
        </div>
    )
}

export default NPIForm
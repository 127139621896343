import * as React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useSelector } from "react-redux";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { APPOINTMENTS, ENCOUNTERS, TASKS } from "../../utils/helpers/subscriptionsConstants";

export default function PrescriptionAndAppointmentDropdown(props) {

  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);

  const navigate = useNavigate();
  const { user } = useAuth();

  const createSOAPNote = async () => {
    const httpResponse = await sendHttpRequest({
      url: `/soap-note/create`,
      method: 'POST',
      data: {
        providerTenantPracticeLocation: {
          provider: {
            userId: user?.userId
          }
        },
        patient: {
          userId: props.patientId
        }
      }
    })

    if (!httpResponse.error) {
      console.log(httpResponse.data);
      navigate(`/all-patients/${props.patientId}/soap-note`, {
        state: { pageRef: "patient", prescriptionReferenceId: httpResponse.data?.prescriptionReferenceId },
      });
    }
  }


  const handleAppointment = () => {
    props.handleNewAppointment();
  };
  const handleOrderPrescription = () => {
    // props.setOpenViewScheduleModal(true);
    props.handleNewPrescription();
  };
  const handleLabRequisition = () => {
    props.handleLabRequisitionModal();
    // navigate(`/lab-order/${props.patientId}`)
  };

  const handleProviderNote = () => {
    navigate(`/all-patients/${props.patientId}/provider-note`);
  };

  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle
          //  id="dropdown-basic"
          id="demo-customized-button"
          style={{
            border: "#004FD4",
            color: "#004FD4",
            background: "#D9E8EC 0% 0% no-repeat",
            fontSize: "14px",
            fontWeight: "600",
            borderRadius: "10px",
            height: "44px",
            width: "172px",
            fontFamily: "Montserrat",
            textTransform: "capitalize",
            borderBottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          title="New"
          className="font-14 shadow-none"
        >
          New
        </Dropdown.Toggle>

        <Dropdown.Menu
          style={{
            background: "#fff",
            textColor: "#004FD4",
            borderRadius: "10px",
            boxShadow: "0px 13px 26px #0000000F",
            borderColor: "#D9E8EC",
            minWidth: "172px",
            zIndex: 1021,
          }}
          className="font-14 "
        >
          {
            subscriptionPrivilegeGroups[APPOINTMENTS]?.spa?.active === true ?
              <Dropdown.Item
                style={{
                  color: "#323232",
                  fontWeight: "400",
                  hover: {
                    color: "#323232",
                  },
                }}
                className="font-14 "
                onClick={handleAppointment}
                hidden={subscriptionPrivilegeGroups[APPOINTMENTS]?.spa?.active === false ? true : false}
              >
                Appointment
              </Dropdown.Item>
              : null
          }
          {subscriptionPrivilegeGroups[TASKS]?.orx?.active === true ? (
            <Dropdown.Item
              style={{
                color: "#323232",
                fontWeight: "400",
                hover: {
                  color: "#323232",
                },
              }}
              className="font-14 "
              onClick={handleOrderPrescription}
              hidden={subscriptionPrivilegeGroups[TASKS]?.orx?.active === false ? true : false}
            >
              Prescription
            </Dropdown.Item>
          ) : null}
          <Dropdown.Item
            style={{
              color: "#323232",
              fontWeight: "400",
              hover: {
                color: "#323232",
              },
            }}
            className={`font-14 `}
            onClick={props?.handleImagingOrderModal}
          // disabled={user.role !== "Physician" ? true : false}

          >
            Imaging Order
          </Dropdown.Item>
          {subscriptionPrivilegeGroups[TASKS]?.olp?.active === true ? (
            <Dropdown.Item
              style={{
                color: "#323232",
                fontWeight: "400",
                hover: {
                  color: "#323232",
                },
              }}
              className={`font-14 `}
              onClick={handleLabRequisition}
            // disabled={user.role !== "Physician" ? true : false}

            >
              Lab Order
            </Dropdown.Item>
          ) : null}
          {subscriptionPrivilegeGroups[ENCOUNTERS]?.apn?.active === true ? (
            <>
              <Dropdown.Divider />
              <Dropdown.Item
                style={{
                  color: "#323232",
                  fontWeight: "400",
                  hover: {
                    color: "#323232",
                  },
                }}
                className={`font-14 `}
                onClick={handleProviderNote}
              // disabled={user.role !== "Physician" ? true : false}

              >
                Provider Note
              </Dropdown.Item>
            </>
          ) : null}
          {subscriptionPrivilegeGroups[ENCOUNTERS]?.asn?.active === true ? (
            <Dropdown.Item
              style={{
                color: "#323232",
                fontWeight: "400",
                hover: {
                  color: "#323232",
                },
              }}
              className={`font-14 `}
              onClick={createSOAPNote}
            // disabled={user.role !== "Physician" ? true : false}

            >
              SOAP Note
            </Dropdown.Item>
          ) : null}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

import React, { useEffect, useState, useRef } from "react";
import Chart from "chart.js/auto"; // Import Chart.js
import CalenderDatePicker from "../UI/General/CalenderDatePicker"; // Import date picker component
import { useNavigate } from "react-router-dom";

const ClaimsLineChart = ({ claimsData }) => {
  const navigate = useNavigate();
  const chartRef = useRef(null); // Reference for Chart.js
  const chartInstance = useRef(null); // To store the chart instance
  const [filteredData, setFilteredData] = useState([]); // Processed data for the chart
  const [startDate, setStartDate] = useState(""); // State to hold start date filter
  const [endDate, setEndDate] = useState(""); // State to hold end date filter

  const monthsArray = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  useEffect(() => {
    const acceptedArray = new Array(12).fill(0);
    const rejectedArray = new Array(12).fill(0);
    const submittedArray = new Array(12).fill(0);

    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;

    if (claimsData?.length > 0) {
      claimsData.forEach((claim) => {
        const { status, updatedDate } = claim;

        if (updatedDate) {
          const claimDate = new Date(updatedDate);
          const monthIndex = claimDate.getMonth(); // Get month index (0 = January, 11 = December)

          // Check if the claim falls within the selected date range
          if ((!start || claimDate >= start) && (!end || claimDate <= end)) {
            // Increment the appropriate counter based on claim status
            if (status === "CLAIM_APPROVED") {
              acceptedArray[monthIndex] += 1;
            } else if (status === "CLAIM_DENIED") {
              rejectedArray[monthIndex] += 1;
            } else if (status === "CLAIM_SUBMITTED") {
              submittedArray[monthIndex] += 1;
            }
          }
        }
      });

      const formattedData = monthsArray.map((month, index) => [
        month,
        acceptedArray[index],
        rejectedArray[index],
        submittedArray[index],
      ]);

      setFilteredData([["Month", "Accepted", "Rejected", "Pending"], ...formattedData]);
    }

    const data = {
      labels: monthsArray, // X-axis
      datasets: [
        {
          label: "Accepted",
          data: acceptedArray,
          fill: false,
          borderColor: "#18c383",
          tension: 0.1,
        },
        {
          label: "Rejected",
          data: rejectedArray,
          fill: false,
          borderColor: "#fd0d1b",
          tension: 0.1,
        },
        {
          label: "Pending",
          data: submittedArray,
          fill: false,
          borderColor: "#f8a02e",
          tension: 0.1,
        },
      ],
    };

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: "bottom",
        },
        title: {
          display: true,
          text: "Monthly Claims Overview",
        },
      },
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };

    // Destroy the existing chart instance if it exists to avoid memory leaks
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    // Create the new chart instance
    chartInstance.current = new Chart(chartRef.current, {
      type: "line",
      data: data,
      options: options,
    });

    // Cleanup the chart when the component unmounts
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [claimsData, startDate, endDate]);

  // Handle start date selection
  const handleStartDate = (dateVal) => {
    setStartDate(dateVal);
  };

  // Handle end date selection
  const handleEndDate = (dateVal) => {
    setEndDate(dateVal);
  };

  function navigateToClaimsPage(routeVal, status) {
    navigate(routeVal, { state: { claimType: status, startDate: startDate, endDate: endDate } });
  }

  return (
    <div className="row">
      {/* Line Chart */}
      <div className="col-12 cursor--pointer" onClick={() => navigateToClaimsPage("/claims-all", "all")}>
        <div style={{ width: "100%", height: "300px" }}>
          <canvas ref={chartRef} /> {/* Canvas element for the chart */}
        </div>
      </div>
    </div>
  );
};

export default ClaimsLineChart;

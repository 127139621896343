import { Modal } from "react-bootstrap";
import AddButtonIcon from "../../assets/icons/Icon-Add.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useRef, useState } from "react";

import sendHttpRequest from "../../components/hooks/sendHttpRequest";
// import TextArea from "antd/lib/input/TextArea";
import TextArea from "../../components/forms/TextArea";
import { unsavedWorkHandler } from "../../utils/helpers/HelperFunctions";
import { useUnsavedWorkPrompt } from "../../components/hooks/useUnsavedWorkPrompt";
import { useAuth } from "../../components/hooks/useAuth";

const RefillDenielModal = (props) => {
  const [isDirty, onDirty, onPristine] = useUnsavedWorkPrompt();
  const [refillDenyReason,setRefillDenyReason] = useState();
  const denialReasons = ["Patient never under Provider care",
    "Patient no longer under Provider care",
    "Patient has requested refill too soon",
    "Patient should contact Provider first",
    "Patient needs appointment",
    "Medication has been discontinued",
    "Other"]


  


  const handleNote = (value)=>{
    onDirty();
    props.setRefillDenialNote(value);
  }
  const handleReason = (reasonValue)=>{
    onDirty();
    setRefillDenyReason(reasonValue);
    props.setRefillDenialReason(reasonValue);
  }

  return (
    <Modal
      show={props.show}
      onHide={() => {
        // props.handleModal();
      }}
      size="md"
      backdropClassName="modal-backdrop"
      contentClassName="modal-border modal-shadow"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"

      // style={{style}}
    >
      <Modal.Header
          // className={`${classes["appointmentmodal__border--bottom"]}`}
        >
          <span
            style={{
              lineHeight: "12px",
              width: "18px",
              fontSize: "8pt",
              color: "#336383",
              position: "absolute",
              top: 15,
              right: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              unsavedWorkHandler(
                "modal",
                "There are unsaved changes. Do you wish to continue?",
                isDirty,
                props.handleModal,
                onPristine,
                props.handleModal
              );
            }}
          >
            {<CloseIcon />}
          </span>
          <Modal.Title className="font-20 fw-sb text--terciary">
            {"Denial Reason"}
          </Modal.Title>
        </Modal.Header>
      <Modal.Body>
        <div className="container-fluid px-3 ">
          <div className="row ">
            <div className="col-12">
              <form
                id="refillDenyRequestForm"
                onSubmit={(e)=>{
                  e.preventDefault();
                  props.refillDenialRequest();
                }}
              >
                {/* <label
                  htmlFor="denialReason"
                >
                  {"Deninal Reason"}
                </label> */}
                <select
                  //   onChange={(e)=>refillsCountHandler(e)}
                  className="form-select text--terciary font-12 border--default br-10 shadow-none input"
                  aria-label="denialReason"
                  id="denialReason"
                  required
                  style={{ borderRadius: "10px" }}
                  onChange={(e) => {
                    e.preventDefault();
                    handleReason(e.target.value);
                  }}
                >
                  <option selected hidden disabled key={"reason"} value={""}>{"Select reason"}</option>
                  {
                    denialReasons.map((item)=>{
                      return(
                        <option key={item} value={item}>{item}</option>
                      )
                    })
                  }
                  
                </select>

                <div className="mt-3">
                  <span className="text--secondary font-14">{"Note"}</span>
                  <textarea
                    className="form-control br-10 input font-12 border--default shadow-none"
                    type={"text"}
                    // defaultValue={chiefComplaint ?? ""}
                    onChange={(e) => {
                      e.preventDefault();
                      handleNote(e.target.value);
                    }}
                    required = {refillDenyReason !== undefined && refillDenyReason === "Other" ?  true:false}
                    // rows={appointmentType === "FOLLOW_UP" ? 2 : 4}
                  />
                </div>
              </form>

            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ border: "none" }}>
        <button
          type="submit"
          className={`btn blue_task_button fw-sb font-14 text-white b2 px-4 br-10 py-1 `}
            form="refillDenyRequestForm"
        >
          {"Deny"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default RefillDenielModal;

import { useEffect, useState } from "react";
import { cardsMap } from "../../utils/helpers/HelperFunctions";
import { client, hostedFields } from "braintree-web";
import { Backdrop, Box, CircularProgress, Stack, Typography } from "@mui/material";
import classes from "../../patientportal/patientpages/PatientPaymentPage.module.css"
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { useAuth } from "../hooks/useAuth";
import { useCallback } from "react";
import CurrencyInput from "react-currency-input-field";
import { transferPayment, getPaymentToken, transferPatientPayment } from "../../res/endpointurls";

const PaymentForm = ({setIsPaymentSent,isPaymentSent,amountValue,prescribersList, userId, fetchBillingHistory, handleReceipt, isPrinting,fetchCurrentBill,billingProvider,isCopPay,hideTags}) => {

    const [nameOnCC, setNameOnCC] = useState("");
    const [provider, setProvider] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [creditCardType, setCreditCardType] = useState(null);
    const [hostedFieldsInstance, setHostedFieldsInstance] = useState("");
    const [amount, setAmount] = useState(amountValue);
    const [token, setToken] = useState("");
    const [error, setError] = useState("");
    const [tokenizeErr, setTokenizeErr] = useState("");
    const [isSent, setIsSent] = useState(false);
    const {user} = useAuth();
    const [isCopPayment,setIsCopPayment] = useState(false);

    const handlePayment = () => {
        tokenizeFields();
    }

    const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSent(false);
    setError({show:false});
    };

    const cardNameHolderHandler = (e) => {
        setNameOnCC(e.target.value);
    };

    const hostedForm = useCallback((token) => {
        client.create({
            authorization: token
        }).then(function (clientInstance) {
            hostedFields.create(
                {
                    client: clientInstance,
                    styles: {
                        'input': {
                            'font-size': '12px',
                            'font-style': 'Montserrat'
                        },
                        'input.invalid': {
                            'color': '#FD0D1B'
                        },
                        'input.valid': {
                            'color': "#323232"
                        },
                        '::-webkit-input-placeholder': {
                            'color': '#70707070'
                        },
                        ':-ms-input-placeholder': {
                            'color': '#70707070'
                        },
                        // 'input.placeholder': {
                        //     'color': "#dddddd"
                        // }
                    },
                    fields: {
                        // cardholderName: {
                        //     container: '#cardholderName',
                        //     placeholder: 'John Doe',
                        // },
                        number: {
                            container: '#card-number',
                            // placeholder: '4111 1111 1111 1111',
                        },
                        cvv: {
                            container: '#cvv',
                            placeholder: '•••'
                        },
                        expirationDate: {
                            container: '#expiration-date',
                            placeholder: 'MM/YYYY'
                        }
                    }
                }, function (hostedFieldsErr, hostedFieldsInstance) {
                    if (hostedFieldsErr) {
                        // console.error(hostedFieldsErr);
                        return;
                    }
                    // function findLabel(field) {
                    //     return document.querySelector('.hosted-field--label[for="' + field.container.id + '"]');
                    // }
                    setHostedFieldsInstance(hostedFieldsInstance);
                    hostedFieldsInstance.on('notEmpty', function (event) { });
                    hostedFieldsInstance.on('empty', function (event) {
                        if (event.emittedBy === "number") {
                            setCreditCardType(null);
                        }
                    });
                    hostedFieldsInstance.on('cardTypeChange', function (event) {

                        // var field = event.fields[event.emittedBy];
                        // var label = findLabel(field);
                        // console.log(label);
                        // if (field.isPotentiallyValid) {
                        //     label.classList.remove('invalid');
                        // } else {
                        //     label.classList.add('invalid');
                        // }
                        // Change card bg depending on card type
                        if (event.cards.length === 1) {
                            setCreditCardType(event.cards[0].type);
                            //   $('header').addClass('header-slide');

                            // Change the CVV length for AmericanExpress cards
                            if (event.cards[0].code.size === 4) {
                                hostedFieldsInstance.setAttribute({
                                    field: 'cvv',
                                    attribute: 'placeholder',
                                    value: '••••'
                                });
                            }
                        } else {
                            hostedFieldsInstance.setAttribute({
                                field: 'cvv',
                                attribute: 'placeholder',
                                value: '•••'
                            });
                        }
                    });
            });
        });
    });

    const tearDownFields = () => {
        if (hostedFieldsInstance !== "") {
            hostedFieldsInstance.teardown(function (teardownErr) {
                if (teardownErr) {
                    // console.error('Could not tear down Hosted Fields!');
                } else {
                    // console.info('Hosted Fields has been torn down!');
                }
            });
        }
    }

    async function getToken() {
        const httpResponse = await sendHttpRequest({
            // url: `/braintree/${userId}/get_token`,
            url: getPaymentToken(user?.tenantId),
            method: "GET",
        });
        if (!httpResponse.error) {
            setToken(httpResponse.data);
            hostedForm(httpResponse.data);
            // braintreeClient(httpResponse.data)
            // setIsApptCreated(true);
            // timeExpired();
        } else {
            setError(httpResponse.errorMessage);
            // console.log(httpResponse);
        }
        // getExtras();
    };

    const sendNounce = async (nounce) => {
        setIsLoading(true);
        const httpResponse = await sendHttpRequest({
            url: transferPatientPayment(userId),
            method: "POST",
            data: {
                nounce: nounce,
                providerId:provider,
                userId:userId,
                amount:amount,
                nameOnCC:nameOnCC,
                transactionType:"CreditCard",
                copay:isCopPayment
            },
        });
        if (!httpResponse.error) {
            setIsSent(()=>true);
            tearDownFields();
            setIsPaymentSent(true);
            setAmount(0);
            setTimeout(() => {
                fetchBillingHistory(true, {description: "Paid via credit card", due: amount});
                
            }, 2000);
            // fetchCurrentBill();
        } else {
            if (httpResponse?.status === 500) {
                setError({message:"Something went wrong", show:true});
            } else {
                setError({...httpResponse?.errorMessage, show:true} ?? {message:"Payment not completed", show:true});
            }
        }
        setIsLoading(false);
        // setIsTimeOver(false);
    };

    const tokenizeFields = () => {
        // let state = hostedFieldsInstance.getState();
        // let fields = Object.keys(state.fields);
        // console.log(state);
        hostedFieldsInstance.tokenize({
            // billingAddress: isNewCreditCard === true ? {
            //     firstName: nameOnCC.split(" ").length > 0 ? nameOnCC.split(" ")[0] : "",
            //     lastName: nameOnCC.split(" ").length > 0 ? nameOnCC.split(" ")[nameOnCC.split(" ").length - 1] : "",
            //     // company: '',
            //     postalCode: zipcode,
            //     streetAddress: address,
            //     // extendedAddress: 'Unit 1',
            //     locality: city,
            //     countryName: selectedState?.length > 0 ? selectedState[0].name : "",
            //     countryCodeAlpha2: selectedState?.length > 0 ? selectedState[0].code : "",
            //     // countryCodeAlpha3: 'USA',
            //     // countryCodeNumeric: '840'
            // } : {}

        }, function (tokenizeErr, payload) {
            if (tokenizeErr) {
                setTokenizeErr(tokenizeErr)
                return;
            }
            else {
                sendNounce(payload.nonce);
            }
        });
    }

    useEffect(() => {

        getToken();
        return () => {
            // clearStates();
            // tearDownFields();
        }
    }, []);

    useEffect(()=>{
        setAmount(pre=>parseFloat(amountValue).toFixed(2))
        setProvider(billingProvider)
        setIsCopPayment(isCopPay)
    },[amountValue,isCopPay])
    console.log("isCopPayment: ",isCopPayment,amount);


    return (
        <>
            <form id="credit-card-form" onSubmit={(e) => {
                e.preventDefault();
            }}>
                <div className="col-md-12 mb-2" >
                    <label
                        htmlFor="physician"
                        className="form-label m-0 font-10 text--secondary fw-sb"
                    >
                        Credit Card Name
                    </label>
                    {/* <div style={{ backgroundColor: "#ffffff", border: "1px solid #D9E8EC", borderRadius: '10px', p: 0 }}>
                        <div id="cardholderName" className={`${classes.hostedFields}`}></div>
                    </div> */}

                    <input
                        type="text"
                        className={`form-control text--terciary refill_input font-12 m-0 border--default br-10 shadow-none input`}
                        id="physician"
                        onChange={cardNameHolderHandler}
                    />
                </div>

                <div className="col-md-12 mb-2">
                    <label
                        htmlFor="physician"
                        className="form-label m-0 font-10 text--secondary fw-sb"
                    >
                        Credit Card Number
                    </label>
                    <Stack direction="row" justifyContent={"between"} spacing={2}>
                        <div className="col-sm-12" style={{ backgroundColor: "#ffffff", border: "1px solid #D9E8EC", borderRadius: '10px', p: 0 }}>
                            <div className="row">
                                <div className="col-sm-8 justify-content-start align-self-center" >
                                    <span className="font-12 fw-sb text--terciary">
                                        <div>
                                            <div id="card-number" className={`${classes.hostedFields}`}></div>
                                        </div>
                                    </span>
                                </div>
                                <div className="col-sm-4 align-self-center">
                                    <div className="d-flex flex-row justify-content-end me-2">
                                        {
                                            creditCardType !== null && (cardsMap(creditCardType))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Stack>
                    <hr />
                </div>

                <div className="col-md-10 mb-2">
                    <h6 className="text--secondary font-12 fw-sb">Expiration Date</h6>
                </div>
                <div className="col-12">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="col-md-12 mb-2">
                                <label
                                    htmlFor="Month"
                                    className="form-label m-0 font-10 text--secondary fw-sb"
                                >
                                    MM/YYYY
                                </label>
                                {/* <select
                            className={`form-select  br-10 border--default input font-12 shadow-none`}
                            aria-label="Month"
                            onChange={(event) => { monthHandler(event); }}
                        >
                            <option selected disabled hidden value="">Month</option>
                            {months !== undefined && months?.length > 0 ? months?.map((month, index) => {
                                return (
                                    <option title={month} className="text-truncate w-100" value={index + 1} key={index + 1}>
                                        {month}
                                    </option>
                                )
                            }) : ''}
                        </select> */}
                                <span className="font-12 fw-sb text--terciary">
                                    <div style={{ backgroundColor: "#ffffff", border: "1px solid #D9E8EC", borderRadius: '10px', p: 0 }}>
                                        <div id="expiration-date" className={`${classes.hostedFields}`}></div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        {/* <div className="col-md-4">
                    <div className="col-md-12 mb-2">
                        <label
                            htmlFor="Year"
                            className="form-label m-0 font-10 text--secondary fw-sb"
                        >
                            Year
                        </label>
                        <select
                            className={`form-select  br-10 border--default input font-12 shadow-none`}
                            aria-label="Year"
                            id="Year"
                            onChange={(event) => { yearHandler(event); }}
                        >
                            <option selected disabled hidden value="">Year</option>
                            {years !== undefined && years?.length > 0 ? years?.map((year) => {
                                return (
                                    <option title={year} className="text-truncate w-100" value={year} key={year}>
                                        {year}
                                    </option>
                                )
                            }) : ''}
                        </select>
                    </div>
                </div> */}
                        <div className="col-md-6">
                            <div className="col-md-12 mb-2">
                                <label
                                    htmlFor="ccv"
                                    className="form-label m-0 font-10 text--secondary fw-sb"
                                >
                                    {"CVV"}
                                    {/* {creditCardType?.code?.name} */}
                                </label>
                                {/* <input
                            type="text"
                            className={`form-control text--terciary refill_input font-12 m-0 border--default br-10 shadow-none input`}
                            id="cvv"
                            maxLength={creditCardType?.code?.size}
                            onChange={cvvHandler}
                        /> */}
                                <span className="font-12 fw-sb text--terciary">
                                    <div style={{ backgroundColor: "#ffffff", border: "1px solid #D9E8EC", borderRadius: '10px', p: 0 }}>
                                        {/* <CreditCardInput
                                        onChange={cardNumberHandler}
                                        id="card-number"
                                        // defaultValue={ccNum}
                                        required
                                        style={{
                                            border: "1px solid #FFFFFF"
                                        }}
                                    /> */}
                                        <div id="cvv" className={`${classes.hostedFields}`}></div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12">

                    <div className="row">

                        <div className="col-6" hidden={hideTags}>
                            <div className="col-12">
                                <label htmlFor="amount" className="font-12 text--secondary">{"Amount"}</label>
                                <CurrencyInput
                                    id="cc_amount"
                                    name="cc_amount"
                                    placeholder="$0.00"
                                    className="text--terciary text-end font-12 input form-control shadow-none border--default br-10"
                                    value={amount}
                                    decimalsLimit={2}
                                    fixedDecimalLength={2}
                                    prefix="$"
                                    decimalSeparator="."
                                    groupSeparator=","
                                    intlConfig={{ locale: 'en-US', currency: 'USD' }}
                                    onValueChange={(value, name) => setAmount(value)}
                                    align={"right"}
                                    onBlur={()=>setAmount(amount)}
                                    // disabled = {true}
                                />
                            </div>
                        </div>
                        <div className="col-6" hidden={hideTags}>
                            <div className="col-12">
                                <label htmlFor="provider" className="font-12 text--secondary fw-sb">{"Provider"}</label>
                                <select
                                    className={`form-select font-12 border--default br-10 shadow-none input`}
                                    aria-label="action select"
                                    style={{
                                    border: "1px solid #D9E8EC",
                                    //   width: `${props.tasksOnly ? "100%" : "80%"}`,
                                    height: "36px",
                                    // fontStyle:"italic"
                                    }}
                                    onChange={(e) => setProvider(e.target.value)}
                                >
                                    <option
                                        className="text--terciary"
                                        value={""}
                                        key={1}
                                        selected
                                        disabled
                                        hidden
                                    >
                                    {"Select Provider"}
                                    </option>
                                    {
                                    prescribersList?.length > 0 && 
                                    prescribersList.map((prescriberItem)=>{
                                        return (
                                        <option key={prescriberItem?.userId} id = {prescriberItem?.userId} value={prescriberItem?.userId} selected={provider===prescriberItem?.userId}>
                                            {"".concat(prescriberItem?.name[0]?.prefix || ""," ",prescriberItem?.name[0]?.firstName || ""," ",prescriberItem?.name[0]?.lastName)}
                                        </option>
                                        )
                                    })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-12 mt-3" hidden={hideTags}>
                            <div className="d-flex flex-row justify-content-end">
                                <button
                                // id="credit-card-form"
                                onClick={handlePayment}
                                type="btn"
                                disabled={provider === undefined ? true: amount <= 0 || isLoading}
                                className="btn d9e8ec--button  ">
                                    {
                                        !isLoading ? "Submit Payment" :
                                            <CircularProgress
                                                size={24}
                                                sx={{color: "#ec609b"}}
                                            />
                                    }
                                </button>
                                {/* <button
                                    disabled={!isPaymentSent === true ? true: isPrinting}
                                    onClick={() => handleReceipt(userId)}
                                    className="btn blue_task_button  ">
                                    {
                                        !isPrinting ? "Print Receipt" :
                                            <CircularProgress
                                                size={24}
                                                sx={{color: "#ec609b"}}
                                            />
                                    }
                                </button> */}
                            </div>
                        </div>

                    </div>

                </div>
            </form>
            {/* {hostedForm()} */}

            <CustomizedSnackbars
                message={"Payment completed successfully."}
                severity="success"
                open={isSent}
                handleClose={handleClose}
            />
            <CustomizedSnackbars
                message={error?.message}
                severity="error"
                open={error?.show}
                handleClose={handleClose}
            />
        </>
    )

}

export default PaymentForm;
import { useEffect, useRef, useState } from "react";
import { Tabs, Tab, Backdrop, CircularProgress, Skeleton, LinearProgress } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import classes from "./myAccount.module.css";
import sendHttpRequest from "../../components/hooks/sendHttpRequest";
import CustomizedSnackbars from "../../components/UI/General/CustomizedSnackbars";
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/styles";
import InsurancePage from "../../patientportal/patientpages/InsurancePage";
import EditPatientModal from "../UI/Modals/PatientModals/EditPatientModal";
import { addressType, numbersEnum } from "../../res/strings";
import { fieldValidation, formatPhoneNumber, navigateOnDirtyBit, removeIfAllEmpty, UnloadHandler } from "../../utils/helpers/HelperFunctions";
import { TabPanel, theme } from "../UI/Reusable/PnComponents";
import { useDispatch, useSelector } from "react-redux";
import { setDirtyBitInStore } from "../../redux/actions/providerActions";
import { useAuth } from "../hooks/useAuth";
import { payersListUrl, relationsListUrl } from "../../res/endpointurls";
import useEventListener from "../hooks/useEventListener";

// import InsurancePage from "./InsurancePage";


const EditPatientPage = () => {
  const dirtyBitStatus = useSelector((state) => state.dirtyStatus.isDirty);
  const dispatch = useDispatch();
  const { user } = useAuth();
  const __str = numbersEnum.en;
  const _numberTypes = numbersEnum["en"];
  const _addressTypes = addressType["en"];
  const location = useLocation();
  const [value, setValue] = useState(0);
  const [insurancevalue, setInsurancevalue] = useState(0);
  const [payersList, setPayersList] = useState({ isLoading: false });
  const [serverErrors, setServerErrors] = useState();
  const [isSending, setIsSending] = useState(false);
  const [patientForm, setPatientForm] = useState("");
  const insurancePanelRef = useRef();
  const [primaryInsurance, setPrimaryInsurance] = useState("");
  const [primaryInsurance1, setPrimaryInsurance1] = useState({});
  const [secondaryInsurance, setSecondaryInsurance] = useState("");
  const [secondaryInsurance1, setSecondaryInsurance1] = useState({});
  const [tertiaryInsurance, setTertiaryInsurance] = useState("");
  const [tertiaryInsurance1, setTertiaryInsurance1] = useState({});
  const [patientProfileData, setPatientProfileData] = useState(null);
  const [isProfileLoading, setIsProfileLoading] = useState(true);
  const [areApiCallsInProgress, setAreApiCallsInProgress] = useState(false);
  const [isInsuranceLoading, setIsInsuranceLoading] = useState(true);
  const [isValidSSN, setIsValidSSN] = useState('');
  const [relationsList, setRelationsList] = useState([]);
  const [patientJson, setPatientJson] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    dateOfBirth: "",
    socialSecurity: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    postalCode: "",
    preferredPharmacy: "",
    cellPhone: "",
    landline: "",
    emergencyContact: "",
    email: "",
    emailConsent: "",
    insured: false,
    suffix: ""
  });
  const [primaryInsuranceJson, setPrimaryInsuranceJson] = useState({
    insuranceName: "",
    payerCode: "",
    policyNumber: "",
    memberId: "",
    groupNumber: "",
    effectiveDate: "",
    terminationDate: "",
    relationshipToInsured: "",
    contactNumber: "",
    type: "Primary",
    firstName: "",
    lastName: "",
    middleName: "",
    gender: "",
    dateOfBirth: "",
    city: "",
    state: "",
    zipCode: "",
    addressLine1: "",
    addressLine2: "",
  });
  const [secondaryInsuranceJson, setSecondaryInsuranceJson] = useState({
    insuranceName: "",
    payerCode: "",
    policyNumber: "",
    memberId: "",
    groupNumber: "",
    effectiveDate: "",
    terminationDate: "",
    relationshipToInsured: "",
    contactNumber: "",
    type: "Secondary",
    firstName: "",
    lastName: "",
    middleName: "",
    gender: "",
    dateOfBirth: "",
    city: "",
    state: "",
    zipCode: "",
    addressLine1: "",
    addressLine2: "",
  });
  const [tertiaryInsuranceJson, setTertiaryInsuranceJson] = useState({
    insuranceName: "",
    payerCode: "",
    policyNumber: "",
    memberId: "",
    groupNumber: "",
    effectiveDate: "",
    terminationDate: "",
    relationshipToInsured: "",
    contactNumber: "",
    type: "Tertiary",
    firstName: "",
    lastName: "",
    middleName: "",
    gender: "",
    dateOfBirth: "",
    city: "",
    state: "",
    zipCode: "",
    addressLine1: "",
    addressLine2: "",
  });

  function BottomNavigation(props) {
    return (
      <div className="position-fixed pb-4 mb-2 w-100 bottom-0 mx-auto bg-white bottom__nav--shadow">
        <LinearProgress variant="determinate" style={{
          backgroundColor: "#D9E8EC",
          height: "1px"
        }} value="" />
        <div className="d-flex p-3 ">
          <div className="position-fixed " style={{
            left: "78%"
          }}>
            <button className="btn  br-10 font-14 ms-2 fw-sb text--blue btn__bottom d9e8ec--button" style={{
              paddingLeft: "24px",
              paddingRight: "24px"
            }}
              onClick={event => {
                props.handleChange();
              }}>
              Save
            </button>
            <button
              className="btn ms-2 blue_task_button"
              onClick={function (event) {
                props.handleChange(true);
              }}
            >
              Sign
            </button>
          </div>
        </div>
      </div>
    );
  }

  const setInsuranceJsons = (insuranceJson) => {
    if (insuranceJson?.length > 0) {
      const tertiaryArray = insuranceJson.filter((insurance) => insurance.type?.toLowerCase() === "tertiary");
      const tertiary = tertiaryArray?.length === 1 ? tertiaryArray[0] : tertiaryInsuranceJson;
      const primaryArray = insuranceJson.filter((insurance) => insurance.type?.toLowerCase() === "primary");
      const primary = primaryArray?.length === 1 ? primaryArray[0] : primaryInsuranceJson;
      const secondaryArray = insuranceJson.filter((insurance) => insurance.type?.toLowerCase() === "secondary");
      const secondary = secondaryArray?.length === 1 ? secondaryArray[0] : secondaryInsuranceJson;
      if (insuranceJson !== undefined || insuranceJson !== null) {
        if (insuranceJson?.length > 0) {
          setTertiaryInsuranceJson(tertiary);
          setSecondaryInsuranceJson(secondary);
          setPrimaryInsuranceJson(primary);
        }
      }
    }
  };

  const populatePatientJson = (patient) => {
    // console.log("patient", patient);
    if (patient !== undefined || patient !== null) {
      setPatientJson({
        firstName: patient?.name[0]?.firstName,
        lastName: patient?.name[0]?.lastName,
        middleName: patient?.name[0]?.middleName || "",
        gender: patient?.gender,
        dateOfBirth: patient?.dateOfBirth,
        socialSecurity: patient?.identification?.socialSecurity,
        addressLine1: patient?.address[0]?.addressLine1,
        addressLine2: patient?.address[0]?.addressLine2 || "",
        city: patient?.address[0]?.city,
        state: patient?.address[0]?.stateProvince?.trim(),
        postalCode: patient?.address[0]?.postalCode,
        // pharmacy: patientJson?.preferredPharmacy?.organizationId,
        cellPhone:
          patient?.communicationNumbers?.numbers?.find((numberItem) => {
            return numberItem?.type === __str.cellPhoneExt;
          })?.number || null,
        homeTelephone:
          patient?.communicationNumbers?.numbers?.find((numberItem) => {
            return numberItem?.type === __str.homeTelephoneExt;
          })?.number || null,
        emergencyContact:
          patient?.communicationNumbers?.emergencyContact?.find((numberItem) => {
            return numberItem?.phoneNumber !== "";
          })?.phoneNumber || "",
        emergencyContactName:
          patient?.communicationNumbers?.emergencyContact?.find((numberItem) => {
            return numberItem?.name !== "";
          })?.name || "",
        emergencyContactRelationship:
          patient?.communicationNumbers?.emergencyContact?.find((numberItem) => {
            return numberItem?.relationship !== "";
          })?.relationship || "",
        email:
          patient?.communicationNumbers?.electronicMail !== undefined
            ? patient?.communicationNumbers?.electronicMail[0]
            : "",
        emailConsent: patient?.emailConsent,
        insured: patient?.patientInsurance?.length > 0 ? true:false,
        suffix: patient?.name[0]?.suffix || '',
        ...(patient?.familyMember !== undefined && {familyMember:patient?.familyMember}),
        ...(patient?.method !== undefined && {method:patient?.method})
      });
    }
  };



  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInsuranceChange = (event, newValue) => {
    console.log(newValue);
    setInsurancevalue(newValue);
  };

  const editPatientRequestHandler = async (patientObject) => {

    const insurancesArray = [];
    // if (patientObject?.insured === true) {
    if (removeIfAllEmpty(primaryInsuranceJson) === false) {
      insurancesArray.push(primaryInsuranceJson);
    }
    if (removeIfAllEmpty(secondaryInsuranceJson) === false) {
      insurancesArray.push(secondaryInsuranceJson);
    }
    if (removeIfAllEmpty(tertiaryInsuranceJson) === false) {
      insurancesArray.push(tertiaryInsuranceJson);
    }
    // }


    setIsSending(true);
    dispatch(setDirtyBitInStore(false));
    const httpResponse = await sendHttpRequest({
      url: "/patient",
      method: "PUT",
      data: {
        userId: location.state.patientId,
        ...patientObject,
        patientInsurance: insurancesArray,

      },
    });
    if (!httpResponse.error) {
      setIsSent(true);
      fetchPatientInformation(patientProfileData?.userId);
      // clearState();
      setIsSending(false);
      // dispatch(setIsPatientEdited(true));
      // navigate("/all-patients", { replace: true });
      navigate(-1);
    } else {
      console.log(httpResponse.errorMessage);
      setServerErrors(httpResponse.errorMessage);
      setIsSending(false);
    }
  };

  const fetchPatientInformation = async () => {
    setIsProfileLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/patient/information",
      method: "GET",
      params: {
        patientId: location.state.patientId,
      },
    });
    if (!httpResponse.error) {
      setPatientProfileData(httpResponse.data);
      populatePatientJson(httpResponse.data);
      setInsuranceJsons(httpResponse.data?.patientInsurance);
      setIsProfileLoading(false);
      
    } else {
      console.log(httpResponse.error);
      setIsProfileLoading(false);
    }
  };

  // const fecthPatientInsuranceInformation = async () => {
  //   setIsInsuranceLoading(true);
  //   const httpResponse = await sendHttpRequest({
  //     url: "/patient/insurance",
  //     method: "GET",
  //     params: {
  //       PatientId: location.state.patientId,
  //     },
  //   });
  //   if (!httpResponse.error) {
  //     // console.log(httpResponse.data);
  //     setInsuranceJsons(httpResponse.data);
  //     // if (props?.handleRecentEncountersList !== null) {
  //     //   props?.handleRecentEncountersList(httpResponse?.data?.RecentEncounters);
  //     // }

  //     setIsInsuranceLoading(false);
  //   } else {
  //     console.log(httpResponse.error);
  //     setIsInsuranceLoading(false);
  //   }
  // };

  const handleSubmit = () => {
    setValue(0);
    setInsurancevalue(0);
    // if (location.state?.insuranceType == undefined ? document.getElementById("patientEditForm").reportValidity() : true) {
    // patientForm?.requestSubmit();
    console.log("Insider")
    editPatientHandler(patientJson);
    setPrimaryInsurance1(primaryInsuranceJson);
    setSecondaryInsurance1(secondaryInsuranceJson);
    setTertiaryInsurance1(tertiaryInsuranceJson);

  };


  const getInsuranceTabValue = function (insuranceTabsTitle) {
    if (value == 1) {
      const insuranceTabsMap = new Map();
      insuranceTabsMap.set("Primary", 0);
      insuranceTabsMap.set("Secondary", 1);
      insuranceTabsMap.set("Tertiary", 2);
      return insuranceTabsMap.get(insuranceTabsTitle);

    }

  };

  const getTabValue = function (tabTitle) {
    const tabsMap = new Map();
    tabsMap.set("General", 0);
    tabsMap.set("Insurance", 1);
    return tabsMap.get(tabTitle);
  };

  const editPatientHandler = (patientJson) => {
    //Patient object structuring according to NCPDP structure'
    let tempAddress = {};
    let tempPatientObject = {
      emailConsent: patientJson?.emailConsent,
      communicationNumbers: { electronicMail: [], numbers: [], emergencyContact: [] },
    };

    if (
      patientJson?.gender !== "Select" &&
      fieldValidation(patientJson?.gender, "all")
    ) {
      tempPatientObject = {
        ...tempPatientObject,
        gender: patientJson?.gender,
      };
    }

    if (fieldValidation(patientJson?.dateOfBirth, "all")) {
      tempPatientObject = {
        ...tempPatientObject,
        dateOfBirth: patientJson?.dateOfBirth,
      };
    }

    if (
      patientJson?.pharmacy !== "Select Pharmacy" &&
      fieldValidation(patientJson?.pharmacy, "all")
    ) {
      tempPatientObject = {
        ...tempPatientObject,
        preferredPharmacy: patientJson?.pharmacy,
      };
    }

    if (fieldValidation(patientJson?.homeTelephone, "all")) {
      tempPatientObject?.communicationNumbers?.numbers.push({
        number: patientJson?.homeTelephone?.includes("+1") ? formatPhoneNumber(patientJson?.homeTelephone?.replace("+1", "")) : patientJson?.homeTelephone,
        type: _numberTypes.homeTelephoneExt,
      });
    }

    if (fieldValidation(patientJson?.cellPhone, "all")) {
      tempPatientObject?.communicationNumbers?.numbers.push({
        number: patientJson?.cellPhone?.includes("+1") ? formatPhoneNumber(patientJson?.cellPhone?.replace("+1", "")) : patientJson?.cellPhone,
        type: _numberTypes.cellPhoneExt,
      });
    }

    if (fieldValidation(patientJson?.emergencyContact, "all")) {
      if (tempPatientObject?.communicationNumbers?.emergencyContact.length > 0) {
        tempPatientObject.communicationNumbers.emergencyContact[0]['phoneNumber'] = patientJson?.emergencyContact?.includes("+1") ? formatPhoneNumber(patientJson?.emergencyContact?.replace("+1", "")) : patientJson.emergencyContact || "";
      } else {
        tempPatientObject?.communicationNumbers?.emergencyContact.push({
          phoneNumber: patientJson?.emergencyContact?.includes("+1") ? formatPhoneNumber(patientJson?.emergencyContact?.replace("+1", "")) : patientJson.emergencyContact || "",
        });
      }
    }

    if (fieldValidation(patientJson?.emergencyContactRelationship, "all")) {
      if (tempPatientObject?.communicationNumbers?.emergencyContact.length > 0) {
        tempPatientObject.communicationNumbers.emergencyContact[0]['relationship'] = patientJson?.emergencyContactRelationship || "";
      } else {
        tempPatientObject?.communicationNumbers?.emergencyContact.push({
          relationship: patientJson?.emergencyContactRelationship || "",
        });
      }
    }

    if (fieldValidation(patientJson?.emergencyContactName, "all")) {
      if (tempPatientObject?.communicationNumbers?.emergencyContact.length > 0) {
        tempPatientObject.communicationNumbers.emergencyContact[0]['name'] = patientJson?.emergencyContactName || "";
      } else {
        tempPatientObject?.communicationNumbers?.emergencyContact.push({
          name: patientJson?.emergencyContactName || "",
        });
      }
    }

    if (fieldValidation(patientJson?.email, "all")) {
      tempPatientObject?.communicationNumbers?.electronicMail?.push(
        patientJson?.email
      );
    }
    // if (fieldValidation(patientJson?.socialSecurity, "all")) {
    tempPatientObject.identification = { socialSecurity: patientJson?.socialSecurity };
    // }
    const tempName = {};
    if (fieldValidation(patientJson?.firstName, "all")) {
      tempName.firstName = patientJson?.firstName;;
      tempName.type = "cn";
    }

    if (fieldValidation(patientJson?.middleName, "un")) {
      tempName.middleName = patientJson?.middleName;;
      tempName.type = "cn";
    }



    if (fieldValidation(patientJson?.lastName, "all")) {
      tempName.lastName = patientJson?.lastName;
      tempName.type = "cn";
    }


    if (fieldValidation(patientJson?.suffix, "all")) {
      tempName.suffix = patientJson?.suffix;
      tempName.type = "cn";
    }

    if (Object.keys(tempName).length !== 0) {
      tempPatientObject.name = [tempName]

    }

    if (fieldValidation(patientJson?.addressLine1, "all")) {
      tempAddress.addressLine1 = patientJson?.addressLine1;
      tempAddress.type = _addressTypes.currentAddress; //NEED TO BE DISCUSSED
    }
    if (fieldValidation(patientJson?.addressLine2, "all")) {
      tempAddress.addressLine2 = patientJson?.addressLine2;
      tempAddress.type = _addressTypes.currentAddress; //NEED TO BE DISCUSSED
    }

    if (fieldValidation(patientJson?.city, "all")) {
      tempAddress.city = patientJson?.city;
    }

    if (fieldValidation(patientJson?.state, "all")) {
      tempAddress.stateProvince =
        patientJson?.state
    }

    if (fieldValidation(patientJson?.postalCode, "all")) {
      tempAddress.postalCode = patientJson?.postalCode;
    }

    if (Object.keys(tempAddress).length !== 0) {
      tempPatientObject.address = [tempAddress];
    }
    if(Object.keys(patientJson?.familyMember).length > 1 && patientJson?.familyMember?.phoneNumber?.length > 0){
      tempPatientObject.familyMember = patientJson?.familyMember;
    }
    if(patientJson?.method?.length !== 0){
      tempPatientObject.method = patientJson?.method;
    }

    tempPatientObject.tenant = user?.tenant;

    // console.log(tempPatientObject);

    editPatientRequestHandler(tempPatientObject);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSent(false);
  };
  const handleBackdropClose = () => {
    setOpenBackdrop(false);
  };
  const handleToggle = () => {
    setOpenBackdrop(!openBackdrop);
  };

  const fetchPayersList = async () => {
    setPayersList((prev) => ({ ...prev, isLoading: true }));
    const httpResponse = await sendHttpRequest({ method: 'GET', url: payersListUrl() });
    if (!httpResponse.error) {
      setPayersList((prev) => ({ ...prev, list: httpResponse.data }));
    }
    setPayersList((prev) => ({ ...prev, isLoading: false }));
  }

  const fetchRelations = async () => {
    const httpResponse = await sendHttpRequest({ method: 'GET', url: relationsListUrl() });
    if (!httpResponse.error) {
      if (typeof httpResponse.data === "object") {
        setRelationsList(httpResponse.data);
      }
    }
  }

  const navigateToInsuranceTab = (tab) => {

  }

  useEffect(() => {
    const fetchData = async () => {
      setAreApiCallsInProgress(true);
      await fetchPayersList();
      await fetchPatientInformation();
      await fetchRelations();
      setAreApiCallsInProgress(false);

      if (location.state?.insuranceType !== undefined && location.state?.insuranceType === "primary") {
        handleChange(undefined, 1);
        // handleInsuranceChange(undefined, getInsuranceTabValue("Primary"));
        document.getElementById("insurancePanel").scrollIntoView();
      }
      else if (location.state?.insuranceType !== undefined && location.state?.insuranceType === "secondary") {
        handleChange(undefined, 1);
        handleInsuranceChange(undefined, 1);
        document.getElementById("insurancePanel").scrollIntoView();
      }
    };

    fetchData();
  }, []);

  // useEventListener("")

  useEventListener('beforeunload', UnloadHandler(dirtyBitStatus));



  return (
    <ThemeProvider theme={theme}>
      <>
        {/* <Navbar /> */}
        <div
          className={`container-fluid bg-white  justify-content-center px-3 py-3 ${classes["page__title-border"]}`}
        >
          <div id="insurancePanel" className="row">
            <div className="col-sm-4 text--terciary font-32 align-self-center ">
              <div className="d-flex flex-row">
                <div
                  className={`bg--background ${classes["chevron__left__div"]} br-10 me-3 border--default border text-center my-auto `}
                  onClick={() => {
                    navigateOnDirtyBit(
                      dirtyBitStatus,
                      navigate,
                      -1,
                      dispatch,
                      setDirtyBitInStore
                    );
                  }}
                >
                  <ChevronLeft
                    style={{ color: "#EC609B" }}
                    className="my-auto cursor-pointer mb-2"
                  />
                </div>
                <div className="font-24 fw-sb text--terciary align-self-center"> Edit Patient </div>
              </div>
            </div>
            <div className="col-sm-8">
              <Tabs
                indicatorColor="secondary"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#EC609B",
                  },
                }}
                value={value}
                variant="scrollable"
                scrollButtons="auto"
                onChange={(e, value) => handleChange(e, value)}
              >
                <Tab
                  key={getTabValue("General")}
                  label={"General"}
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    textTransform: "initial",
                    color: "#336383",
                    fontFamily: "Montserrat",
                  }}
                />

                {patientJson?.insured === true ? <Tab
                  key={getTabValue("Insurance")}
                  label={"Insurance"}
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    textTransform: "initial",
                    color: "#336383",
                    fontFamily: "Montserrat",
                  }}
                /> : null}
              </Tabs>
            </div>
          </div>
        </div>

        {patientJson?.insured === true ? <TabPanel value={value} index={getTabValue("Insurance")}>
          <div className={`container-fluid bg-white px-3 py-3`}>
            <div className="row justify-content-end">
              <div className="col-sm-8">
                <Tabs
                  indicatorColor="secondary"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#EC609B",
                    },
                  }}
                  value={insurancevalue}
                  variant="scrollable"
                  scrollButtons="auto"
                  onChange={handleInsuranceChange}
                >
                  <Tab
                    key={getInsuranceTabValue("Primary")}
                    label={"Primary"}
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      textTransform: "initial",
                      color: "#336383",
                      fontFamily: "Montserrat",
                    }}
                  />

                  <Tab
                    key={getInsuranceTabValue("Secondary")}
                    label={"Secondary"}
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      textTransform: "initial",
                      color: "#336383",
                      fontFamily: "Montserrat",
                    }}
                  />

                  <Tab
                    key={getInsuranceTabValue("Tertiary")}
                    label={"Tertiary"}
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      textTransform: "initial",
                      color: "#336383",
                      fontFamily: "Montserrat",
                    }}
                  />
                </Tabs>
              </div>
            </div>
          </div>
        </TabPanel> : null}
        <div className="container-fluid p-0 bg--background py-5 mb-5 v-100">
          {/* <div className=""> */}
          {/* <div className=""> */}
          {/* <form id="newPatient" onSubmit={(e) => {
                                e.preventDefault();
                                console.log(e);
                                console.log(e.target[1].value);
                                console.log(e.target);
                                console.log(e.currentTarget);
                                const data = new FormData(e.target);
                                console.log(data);
                                // addPatientHandler();
                            }}> */}
          <TabPanel value={value} index={getTabValue("General")}>
            {/* <NewPatientModal
                  // addNewPatientRequestHandler={addNewPatientRequestHandler}
                  // setIsSent={setIsSent}
                  // isDirty={isDirty}
                  // handleOnDirty={onDirty}
                  // handleOnPristine = {onPristine}
                  // handlePatientAddDependency={handlePatientAddDependency}
                  setPatientJson={setPatientJson}
                  patientJson={patientJson}
                  handleSubmit={handleSubmit}
                  addNewPatientRequestHandler={addNewPatientRequestHandler}
                  setPatientForm={setPatientForm}
                  serverErrors={serverErrors}
                  fetchPatients={null}
                  setS2={setS2}
                  isSending={isSending}
                /> */}
            {!isProfileLoading && (
              <EditPatientModal
                handleSubmit={handleSubmit}
                patientJson={patientJson}
                patientProfileData={patientProfileData}
                setPatientForm={setPatientForm}
                relationsList={relationsList}
                setPatientJson={setPatientJson}
                isSending={isSending}
                isValidSSN={isValidSSN}
                setIsValidSSN={setIsValidSSN}
              />
            )}
          </TabPanel>


          {patientJson?.insured === true ? <><TabPanel
            value={insurancevalue}
            index={getInsuranceTabValue("Primary")}
            className="bg--background"
          >
            {payersList?.isLoading === false ? <InsurancePage
              payersList={payersList}
              insuranceJson={primaryInsuranceJson}
              setInsuranceJson={setPrimaryInsuranceJson}
              handleSubmit={handleSubmit}
              setPrimaryInsurance={setPrimaryInsurance}
              type={"primary"}
            /> : <Skeleton animation="wave" />}
          </TabPanel>

            <TabPanel
              value={insurancevalue}
              index={getInsuranceTabValue("Secondary")}
              className="bg--background "
            >
              {payersList?.isLoading === false ? <InsurancePage
                payersList={payersList}
                insuranceJson={secondaryInsuranceJson}
                setInsuranceJson={setSecondaryInsuranceJson}
                handleSubmit={handleSubmit}
                setSecondaryInsurance={setSecondaryInsurance}
                type={"secondary"}
              /> : <Skeleton animation="wave" />}
            </TabPanel>
            <TabPanel
              value={insurancevalue}
              index={getInsuranceTabValue("Tertiary")}
              className="bg--background "
            >
              {payersList?.isLoading === false ? <InsurancePage
                payersList={payersList}
                insuranceJson={tertiaryInsuranceJson}
                setInsuranceJson={setTertiaryInsuranceJson}
                handleSubmit={handleSubmit}
                setTertiaryInsurance={setTertiaryInsurance}
                type={"tertiary"}
              /> : <Skeleton animation="wave" />}
            </TabPanel>
          </> : null}
        </div>
      </>
      {/* {console.log("serverErrors",serverErrors["Patient.Name.LastName"])} */}

      <Backdrop
        sx={{ color: "#EC609B", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={areApiCallsInProgress || isProfileLoading || isSending}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CustomizedSnackbars
        message={"Data saved successfully"}
        severity="success"
        open={isSent}
        handleClose={handleClose}
      />
    </ThemeProvider>
  );
};
export default EditPatientPage;

import MaskInput, {
  formatPhoneNumber,
  isValidSSN,
  isValidZipCode,
} from "../../utils/helpers/HelperFunctions";
import CalenderDatePicker from "../UI/General/CalenderDatePicker";
import options from "../../../src/utils/data";
import PhoneNumberInput from "../UI/Reusable/PhoneNumberInput";
import {
  FamilyRelation,
  PatientMethodOfCommunication,
} from "../UI/Modals/PatientModals/NewPatientModal";
import { useEffect, useState } from "react";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { relationsListUrl } from "../../res/endpointurls";
import { DateTime } from "luxon";
import { Typeahead } from "react-bootstrap-typeahead";
import { ValidationErrorMessage } from "../validations/ValidationErrorMessage";
import {
  ERROR_MESSAGES,
  FIELD_NAMES,
  MIN_MAX_VALUES,
} from "../validations/validationschemas";
import { setDirtyBitInStore } from "../../redux/actions/providerActions";
import { useDispatch } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";

const AddPatientForm = (props) => {
  const field_Names = { ...FIELD_NAMES.user.name, ...FIELD_NAMES.patient };
  const PATIENT_VALIDATION = {
    ...MIN_MAX_VALUES.name,
    ...MIN_MAX_VALUES.patient,
  };
  const validationErrorMsgs = {
    ...ERROR_MESSAGES.user.name,
    ...ERROR_MESSAGES.patient,
  };
  const dispatch = useDispatch();
  const [validationErrors, setValidationErrors] = useState({});
  const [relationsList, setRelationsList] = useState([]);
  const [isSSNValid, setIsSSNValid] = useState(true);
  const [regexErrors, setRegexErrors] = useState({
    landline: false,
    primary: false,
    emergency: false,
    zipcode: false,
  });

  const fetchRelations = async () => {
    const httpResponse = await sendHttpRequest({
      method: "GET",
      url: relationsListUrl(),
    });
    if (!httpResponse.error) {
      if (typeof httpResponse.data === "object") {
        setRelationsList(httpResponse.data);
      }
    }
  };

  const cellPhoneHandler = (phoneValue) => {
    if (
      phoneValue !== undefined &&
      isValidPhoneNumber(phoneValue?.toString()) &&
      phoneValue?.length == 12
    ) {
      // console.log("Valed Part");
      let mobileNumber = formatPhoneNumber(phoneValue?.replace("+1", ""));
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: false,
      }));
      dispatch(setDirtyBitInStore(true));
      props?.setPatientJson((pj) => {
        return { ...pj, CellPhone: mobileNumber };
      });
    } else if (
      phoneValue !== undefined &&
      !isValidPhoneNumber(phoneValue?.toString()) &&
      phoneValue?.length > 2
    ) {
      dispatch(setDirtyBitInStore(true));
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: true,
      }));
      props?.setPatientJson((pj) => {
        return { ...pj, CellPhone: "" };
      });
    } else if (phoneValue?.length === 2 || phoneValue === undefined) {
      // dispatch(setDirtyBitInStore(true));
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: false,
      }));
      props?.setPatientJson((pj) => {
        return { ...pj, CellPhone: "" };
      });
    }
  };

  const firstNameHandler = (event) => {
    let firstName = event.target.value;

    if (firstName?.length > PATIENT_VALIDATION.max) {
      setValidationErrors({
        ...validationErrors,
        [field_Names.firstNameMax]: validationErrorMsgs.firstNameMax,
      });
    } else {
      let temp = validationErrors;
      delete temp.firstNameMax;
      setValidationErrors(temp);
    }
    dispatch(setDirtyBitInStore(true));
    props?.setPatientJson((pj) => {
      return { ...pj, FirstName: firstName?.trim() };
    });
  };

  const middleNameHandler = (event) => {
    let middleName = event.target.value;

    if (middleName?.length > PATIENT_VALIDATION.max) {
      setValidationErrors({
        ...validationErrors,
        [field_Names.middleNameMax]: validationErrorMsgs.middleNameMax,
      });
    } else {
      let temp = validationErrors;
      delete temp.middleNameMax;
      setValidationErrors(temp);
    }
    dispatch(setDirtyBitInStore(true));
    props?.setPatientJson((pj) => {
      return { ...pj, MiddleName: middleName?.trim() };
    });
  };

  const lastNameHandler = (event) => {
    let lastName = event.target.value;
    // setLastName(lastName?.trim());

    if (lastName?.length > PATIENT_VALIDATION.max) {
      setValidationErrors({
        ...validationErrors,
        [field_Names.lastNameMax]: validationErrorMsgs.lastNameMax,
      });
    } else {
      let temp = validationErrors;
      delete temp.lastNameMax;
      setValidationErrors(temp);
    }

    dispatch(setDirtyBitInStore(true));
    props?.setPatientJson((pj) => {
      return { ...pj, LastName: lastName?.trim() };
    });
  };

  const suffixHandler = (event) => {
    dispatch(setDirtyBitInStore(true));
    props?.setPatientJson((pj) => {
      return { ...pj, suffix: event.target.value };
    });
  };

  const genderHandler = (event) => {
    dispatch(setDirtyBitInStore(true));
    props?.setPatientJson((pj) => {
      return { ...pj, biologicalSex: event.target.value };
    });
  };
  const dobHandler = (d) => {
    dispatch(setDirtyBitInStore(true));
    props?.setPatientJson((pj) => {
      return { ...pj, dob: d };
    });
  };
  const ssnHandler = (event) => {
    if (
      event.target.value === "___-__-____" ||
      event.target.value === "" ||
      event.target.value === null
    ) {
      setIsSSNValid(true);
      props?.setPatientJson((pj) => {
        return { ...pj, SSN: null };
      });
    } else if (isValidSSN(event.target.value)) {
      setIsSSNValid(true);
      props?.setPatientJson((pj) => {
        return { ...pj, SSN: event.target.value };
      });
    } else {
      setIsSSNValid(false);
      props?.setPatientJson((pj) => {
        return { ...pj, SSN: event.target.value };
      });
    }
  };

  const zipCodeHandler = (event) => {
    props?.setPatientJson((pj) => {
      return { ...pj, ZipCode: event.target.value };
    });
    if (isValidZipCode(event.target.value)) {
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        zipcode: false,
      }));
      dispatch(setDirtyBitInStore(true));
      props?.setPatientJson((pj) => {
        return { ...pj, ZipCode: event.target.value };
      });
    } else {
      if (event.target.value === "") {
        setRegexErrors((previousSnapshot) => ({
          ...previousSnapshot,
          zipcode: false,
        }));
      } else {
        dispatch(setDirtyBitInStore(true));
        setRegexErrors((previousSnapshot) => ({
          ...previousSnapshot,
          zipcode: true,
        }));
      }
    }
  };

  const cityHandler = (event) => {
    let cityName = event.target.value;

    if (cityName?.length > PATIENT_VALIDATION.city.max) {
      setValidationErrors({
        ...validationErrors,
        [field_Names.city]: validationErrorMsgs.city,
      });
    } else {
      let temp = validationErrors;
      delete temp.city;
      setValidationErrors(temp);
    }
    dispatch(setDirtyBitInStore(true));
    props?.setPatientJson((pj) => {
      return { ...pj, City: event.target.value };
    });
  };

  const addressLine1Handler = (event) => {
    let address1 = event.target.value;
    if (address1?.length > PATIENT_VALIDATION.addressLine1.max) {
      setValidationErrors({
        ...validationErrors,
        [field_Names.addressLine1]: validationErrorMsgs.addressLine1,
      });
    } else {
      let temp = validationErrors;
      delete temp.addressLine1;
      setValidationErrors(temp);
    }
    dispatch(setDirtyBitInStore(true));
    props?.setPatientJson((pj) => {
      return { ...pj, AddressLine1: event.target.value };
    });
  };

  const addressLine2Handler = (event) => {
    let address2 = event.target.value;

    if (address2?.length > PATIENT_VALIDATION.addressLine2.max) {
      setValidationErrors({
        ...validationErrors,
        [field_Names.addressLine2]: validationErrorMsgs.addressLine2,
      });
    } else {
      let temp = validationErrors;
      delete validationErrors.addressLine2;
      setValidationErrors(temp);
    }
    dispatch(setDirtyBitInStore(true));
    props?.setPatientJson((pj) => {
      return { ...pj, AddressLine2: event.target.value };
    });
  };
  const handlePreset = (s) => {
    dispatch(setDirtyBitInStore(true));
    props?.setPatientJson((pj) => {
      return { ...pj, state: s };
    });
  };
  const landlineHanlder = (phoneValue) => {
    if (
      phoneValue !== undefined &&
      phoneValue &&
      isValidPhoneNumber(phoneValue?.toString()) &&
      phoneValue?.length === 12
    ) {
      let landlineNumber = formatPhoneNumber(phoneValue?.replace("+1", ""));

      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        landline: false,
      }));

      dispatch(setDirtyBitInStore(true));
      props?.setPatientJson((pj) => {
        return { ...pj, Landline: landlineNumber };
      });
    } else if (
      phoneValue !== undefined &&
      !isValidPhoneNumber(phoneValue.toString()) &&
      phoneValue?.length > 2
    ) {
      dispatch(setDirtyBitInStore(true));
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        landline: true,
      }));
    } else if (phoneValue === undefined || phoneValue?.length === 2) {
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        landline: false,
      }));
      // dispatch(setDirtyBitInStore(true));
      props?.setPatientJson((pj) => {
        return { ...pj, Landline: "" };
      });
    }
  };
  const emailHandler = (event) => {
    dispatch(setDirtyBitInStore(true));
    props?.setPatientJson((pj) => {
      return { ...pj, Email: event.target.value };
    });
  };


  const handleNext = ()=>{
    props?.setPatientInformationAccordion(false);
    props.setInsuranceAccorionExpanded("insuranceAccordion");
  }

  useEffect(() => {
    fetchRelations();
  }, []);
  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12">
          <form
            id="patientForm"
            onSubmit={(e) => {
              e.preventDefault();
              handleNext();
            }}
          >
            <div className="row mt-2  ">
              <div className="col-md-4">
                <label
                  htmlFor="FirstName"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  First Name <span>&#42;</span>
                </label>
                <input
                  type="text"
                  onChange={(e)=>firstNameHandler(e)}
                  //   ref={firstNameRef}
                  defaultValue={props?.patientJson?.FirstName}
                  className={`form-control font-14 m-0 border--default br-10 shadow-none input`}
                  id="FirstName"
                  name="FirstName"
                  required
                  // onBlur={firstNameBlur}
                />
                <ValidationErrorMessage
                  fieldName={field_Names.firstNameMax}
                  errors={validationErrors}
                />
              </div>

              <div className="col-md-4">
                <label
                  htmlFor="MiddleName"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Middle Name
                </label>
                <input
                  type="text"
                  onChange={(e)=>middleNameHandler(e)}
                  defaultValue={props?.patientJson?.MiddleName}
                  className={`form-control font-14 m-0 border--default br-10 shadow-none input`}
                  id="MiddleName"
                  name="MiddleName"
                  // onBlur={firstNameBlur}
                />
                <ValidationErrorMessage
                  fieldName={field_Names.middleNameMax}
                  errors={validationErrors}
                />
              </div>

              <div className="col-md-4 ">
                <label
                  htmlFor="LastName"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Last Name <span>&#42;</span>
                </label>
                <input
                  type="text"
                  onChange={lastNameHandler}
                  defaultValue={props?.patientJson?.LastName}
                  autoComplete="off"
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="LastName"
                  name="LastName"
                  required
                />
                <ValidationErrorMessage
                  fieldName={field_Names.lastNameMax}
                  errors={validationErrors}
                />
              </div>

              <div className="col-md-3 mt-2">
                <label
                  htmlFor="patientSuffix"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Suffix
                </label>
                <input
                  type="text"
                  onChange={(e)=>suffixHandler(e)}
                  defaultValue={props?.patientJson?.suffix}
                  autoComplete="off"
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="Suffix"
                  name="Suffix"
                  maxLength={7}
                />
              </div>

              <div className="col-md-3 mt-2">
                <label
                  htmlFor="BiologicalSex"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Biological Sex <span>&#42;</span>
                </label>
                <select
                  onChange={(e)=>{genderHandler(e)}}
                  className="form-select font-14 border--default br-10 shadow-none input"
                  aria-label="biologicalSex"
                  id="biologicalSex"
                  name="biologicalSex"
                  required
                  style={{ borderRadius: "10px" }}
                  value={props?.patientJson?.biologicalSex}
                >
                  <option value="">Select</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Unknown">Unknown</option>
                </select>
              </div>

              <div className="col-md-3 mt-2">
                <label
                  htmlFor="dob"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Date of Birth <span>&#42;</span>
                </label>
                <CalenderDatePicker
                  name="dob"
                  label="Date of birth"
                  date={
                    props?.patientJson?.dob !== ""
                      ? props?.patientJson?.dob
                      : ""
                  }
                  required={true}
                  dobHandler={dobHandler}
                  disableFuture={true}
                  maxDate={DateTime.now()}
                />
              </div>

              <div className="col-md-3 mt-2">
                <label
                  htmlFor="SSN"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  SSN
                </label>
                <MaskInput
                  mask={"999-99-9999"}
                  onChange={(e)=>{ssnHandler(e)}}
                  defaultValue={props?.patientJson?.SSN}
                  autoComplete="false"
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="SSN"
                  name="SSN"
                  minLength="9"
                />
                {isSSNValid === false ? (
                  <span className="text--danger font-10 ms-2 mt-0">
                    Invalid SSN
                  </span>
                ) : null}
              </div>

              {/* <div className="col-md-12 mt-2">
                <label
                  htmlFor="AddressLine1"
                  className="form-label fw-sb m-0 font-12 text--secondary"
                >
                  Address line 1<span>&#42;</span>
                </label>
                <input
                  type="text"
                  onChange={(e)=>{addressLine1Handler(e)}}
                  value={props?.patientJson?.AddressLine1}
                  autoComplete="off"
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="AddressLine1"
                  name="AddressLine1"
                  required
                />
                <ValidationErrorMessage
                  fieldName={field_Names.addressLine1}
                  errors={validationErrors}
                />
              </div> */}

              {/* <div className="col-md-12 mt-2">
                <label
                  htmlFor="AddressLine2"
                  className="form-label fw-sb m-0 font-12 text--secondary"
                >
                  Address line 2
                </label>
                <input
                  type="text"
                  onChange={(e)=>{addressLine2Handler(e)}}
                  value={props?.patientJson?.AddressLine2}
                  autoComplete="off"
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="AddressLine2"
                  name="AddressLine2"
                />
                <ValidationErrorMessage
                  fieldName={field_Names.addressLine2}
                  errors={validationErrors}
                />
                </div> */}

              {/* <div className="col-md-6 mt-2">
                <label
                  htmlFor="City"
                  className="form-label fw-sb m-0 font-12 text--secondary"
                >
                  City <span>&#42;</span>
                </label>
                <input
                  type="text"
                  onChange={(e)=>{cityHandler(e)}}
                  value={props?.patientJson?.City}
                  //   onBlur={() => handleStandardAddress()}
                  // onFocus={() => handleStandardAddress()}
                  autoComplete="off"
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="City"
                  name="City"
                  required
                />

                <ValidationErrorMessage
                  fieldName={field_Names.city}
                  errors={validationErrors}
                />
              </div> */}

              {/* <div className="col-md-3 mt-2">
                <label
                  htmlFor="State"
                  className="form-label fw-sb m-0 font-12 text--secondary"
                >
                  State <span>&#42;</span>
                </label>

                <Typeahead
                  id="state"
                  name="state"
                  labelKey="name"
                  onChange={handlePreset}
                  //   onBlur={handleStandardAddress}
                  options={options}
                  className="rbt-menu my-select font-12"
                  selected={
                    props?.patientJson?.state !== "" &&
                    props.patientJson?.state !== undefined
                      ? props.patientJson?.state
                      : null
                  }
                  style={{ borderRadius: "10px" }}
                  autoComplete="off"
                  onInvalid={(e) => {
                    e.target.setCustomValidity("Please select a state");
                  }}
                  onInput={(e) => {
                    e.target.setCustomValidity("");
                  }}
                  inputProps={{ required: true, className: "font-14" }}
                />
              </div> */}

              {/* <div className="col-md-3 mt-2">
                <label
                  htmlFor="ZipCode"
                  className="form-label fw-sb m-0 font-12 text--secondary"
                >
                  Zip Code <span>&#42;</span>
                </label>
                <input
                  type="text"
                  value={props?.patientJson?.ZipCode}
                  onChange={(e) => zipCodeHandler(e)}
                  //   onBlur={handleStandardAddress}
                  className="form-control font-14 border--default br-10 shadow-none input"
                  id="ZipCode"
                  name="ZipCode"
                  required
                />

                <span className="text--danger py-1 font-10 d-block">
                  {regexErrors?.zipcode === true
                    ? "Incorrect Zip Code format"
                    : " "}
                </span>
              </div> */}

              <div className="col-md-12">
                <h3 className="mt-3 mb-2 font-16 fw-sb">Contact Information</h3>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label
                    htmlFor="CellPhone"
                    className="form-label m-0 fw-sb font-12 text--secondary"
                  >
                    Mobile Phone <span>&#42;</span>
                  </label>
                  <div>
                    <PhoneNumberInput
                      value={props?.patientJson?.CellPhone || ""}
                      name="CellPhone"
                      onChange={cellPhoneHandler}
                      id="CellPhone"
                      required={true}
                    />
                    <span className="text--danger py-1 font-10 d-block">
                      {regexErrors?.primary === true
                        ? "Incorrect number format"
                        : null}
                    </span>
                    
                  </div>
                </div>

                <div className="col-md-6">
                  <label
                    htmlFor="Email"
                    className="form-label m-0 fw-sb font-12 text--secondary"
                  >
                    Email <span>&#42;</span>
                  </label>
                  <input
                    type="email"
                    onChange={(e)=>{emailHandler(e)}}
                    defaultValue={props?.patientJson?.Email}
                    autoComplete="off"
                    className="form-control font-14 border--default br-10 shadow-none input"
                    id="Email"
                    name="Email"
                    required
                  />
                </div>

                <div className="col-md-6 ">
                  <label
                    htmlFor="Landline"
                    className="form-label m-0 fw-sb font-12 text--secondary"
                  >
                    Home Phone
                  </label>
                  <div>
                    <PhoneNumberInput
                      name="Landline"
                      value={""}
                      onChange={landlineHanlder}
                      id="Landline"
                      required={false}
                    />
                  </div>
                  <span className="text--danger py-1 font-10 d-block">
                    {regexErrors?.landline === true
                      ? "Incorrect number format"
                      : null}
                  </span>
                </div>
              </div>

              {/* Emergency Contact Information */}
              {/* <div className="col-md-12">
                <h3 className="mt-3 mb-2 font-16 fw-sb">
                  Emergency Contact Information
                </h3>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label
                    htmlFor="EmergencyContactName"
                    className="form-label m-0 fw-sb font-12 text--secondary"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    // onChange={emergencyContactNameHandler}
                    // defaultValue={
                    //   props?.patientJson?.emergencyContactName ?? ""
                    // }
                    autoComplete="off"
                    className="form-control font-14 border--default br-10 shadow-none input"
                    id="EmergencyContactName"
                    name="EmergencyContactName"
                  />
                </div>

                <div className="col-md-6 ">
                  <label
                    htmlFor="EmergencyPhone"
                    className="form-label m-0 fw-sb font-12 text--secondary"
                  >
                    Phone
                  </label>
                  <div>
                    <PhoneNumberInput
                      name="EmergencyPhone"
                      value={""}
                      onChange={cellPhoneHandler}
                      id="EmergencyPhone"
                      required={false}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <label
                    htmlFor="Relationship"
                    className="form-label m-0 fw-sb font-12 text--secondary"
                  >
                    Relationship
                  </label>
                  <select
                    // onChange={emergencyContactRelationshipHandler}
                    className="form-select font-14 border--default br-10 shadow-none input"
                    aria-label="relation"
                    id="relation"
                    name="relation"
                    style={{ borderRadius: "10px" }}
                    // value={props?.patientJson?.Relationship}
                  >
                    <option value="">Select</option>
                    {relationsList?.length > 0
                      ? relationsList?.map((relation, index) => {
                          return (
                            <option value={relation} key={index}>
                              {relation
                                .match(/([A-Z]?[^A-Z]*)/g)
                                .slice(0, -1)
                                .join(" ")}
                            </option>
                          );
                        })
                      : null}
                  </select>
                </div>
              </div> */}
            </div>
            <div className="text-end">
            <button
                id="patientForm"
                type="submit"
                className={`btn blue_task_button bg--blue fw-sb font-14 text-white btn-lg b2 px-5 br-10 py-1 ${props?.isSending ? "bg--disabled" : ""}`}
                disabled={
                    regexErrors?.zipcode === true ||
                    regexErrors?.emergency === true ||
                    regexErrors?.landline === true ||
                    regexErrors?.primary === true
                    ? true
                    : Object?.keys(validationErrors)?.length !== 0 ? true : false

                }
                // onClick={(e)=>{handleNext(e)}}
              >
                {"Next"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddPatientForm;

import sendHttpRequest from "../hooks/sendHttpRequest";

export default class ProviderNoteService {
    static saveDiagnosis = async (prescriptionReference, diagnosisCode, type, description, startDate = '', endDate = '', note = '', status, assessmentPlanDiagnosis = false) => {
        const dto = {
            "type": type,
            "code": diagnosisCode,
            "description": description,
            "status": status,
            "assessmentPlanDiagnosis": assessmentPlanDiagnosis,
            ...(note !== '' && { "note": note }),
            ...(startDate !== '' && { "startDate": startDate }),
            ...(endDate !== '' && { "endDate": endDate }),
        };
        console.log("dto", dto);
        const httpResponse = await sendHttpRequest({ url: `/prescription-reference/${prescriptionReference}/diagnosis`, data: dto, method: "POST" });
        if (!httpResponse.error) {
            return true;
        }
        return httpResponse.error;
    }


    static getPrescriptionReferenceNumberFromId = async (patientId, providerId) => {
        const dto = {
            "patient": {
                "userId": patientId,
            },
            "providerTenantPracticeLocation": {
                "provider": {
                    "userId": providerId
                }
            }
        }

        const prescriptionReferenceResponse = await sendHttpRequest({ url: `/prescription-reference/providerNote`, data: dto, method: 'POST' });
        if (!prescriptionReferenceResponse.error) {
            return prescriptionReferenceResponse.data;
        }
    }

    static getProviderNoteData = async (prescriptionReferenceId) => {
        console.log("Fetching data against prescription reference id: ", prescriptionReferenceId);
        try {
            const httpResponse = await sendHttpRequest({ url: `/prescription-reference/${prescriptionReferenceId}`, method: 'GET' });
            if (!httpResponse.error) {
                return httpResponse.data;
            }
        }
        catch (e) {
            console.log("getProviderNote API Error: ", e);
        }

    }

    static deleteDiagnosisFromProviderNote = async (diagnosis) => {
        console.info("Deleting diagnosis...", diagnosis);
        try {
            const httpResponse = await sendHttpRequest({ url: `/diagnosis/${diagnosis?.diagnosisId}`, method: 'DELETE' });
            if (!httpResponse.error) {
                console.log(httpResponse)
                if (httpResponse.status == 200) {
                    return true;
                }
                else {
                    return false;
                }
            }
        }
        catch (error) {
            console.log("Error while deleting Diagnosis: ", error);
        }
    }

    static updateDiagnosisInProviderNote = async (diagnosis) => {
        console.info("Updating Diagnosis...");
        try {
            const httpResponse = await sendHttpRequest({ url: `/diagnosis/${diagnosis?.diagnosisId}`, data: diagnosis, method: 'PUT' });
            if (!httpResponse.error) {
                if (httpResponse.status == 200) {
                    return true;
                }
                else {
                    return false;
                }
            }
        }
        catch (error) {
            console.log("Error while updating diagnosis", error);
        }
    }

    static saveProviderNote = async (prescriptionReferenceId, providerNote) => {
        console.info("Saving provider note...");
        try {
            const httpResponse = await sendHttpRequest({ url: `/prescription-reference/${prescriptionReferenceId}/providerNote`, data: providerNote, method: 'PUT' });
            if (!httpResponse.error) {
                // console.log(httpResponse)
                if (httpResponse.status == 200) {
                    return true;
                }
                else {
                    return false;
                }
            }
        }
        catch (error) {
            console.log("Error while saving provider note", error);
        }
    }

    static printAllMedications = async (prescriptionReferenceId) => {
        console.log("Print All medications");
        const link = document.createElement("a");
        link.target = "_blank";
        link.download = `Rx_${prescriptionReferenceId}`;
        const httpResponse = await sendHttpRequest({
            url: `/prescription-reference/${prescriptionReferenceId}/medicationRx/print`,
            method: "GET",
            responseType: "arraybuffer",
        });
        if (!httpResponse.error) {

            const href = URL.createObjectURL(
                new Blob([httpResponse.data], {
                    type: "application/pdf",
                    encoding: "UTF-8",
                })
            );
            link.href = href;
            link.click();
            link.remove();
            URL.revokeObjectURL(href);

        }
    }

    static deleteAllMedications = async (prescriptionReferenceId) => {
        console.log("Deleting medications ....");
        try {
            const httpResponse = await sendHttpRequest({ url: `/prescription-reference/${prescriptionReferenceId}/medicationRx`, method: 'DELETE' });
            if (!httpResponse.error) {
                if (httpResponse.status == 200) {
                    return true;
                }
                else {
                    return false;
                }
            }
        }
        catch (error) {
            console.log("Error while deleting medications: ", error);
        }
    }

}




import { Modal } from "react-bootstrap";
import AddButtonIcon from "../../../../assets/icons/Icon-Add.svg";
import TextArea from "../../../forms/TextArea";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { surgeryStrings } from "../../../../res/strings";
import CustomDatePicker from "../../General/CustomDatePicker";
import CalenderDatePicker from "../../General/CalenderDatePicker";
import { DateTime } from "luxon";

const SurgeryModal = (props) => {

  const _str = surgeryStrings.en;

  const handleSurgeryNameInput = (e) => {
    props.setSurgeryName(e.target.value)
  }

  const handleSurgeryDateInput = (date) => {
    props.setSurgeryDate(date)
  }

  const gettext = (event) => {
    props.setSurgeryNote(event)
  }
  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.handleModal();
      }}
      size="md"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      backdropClassName="modal-backdrop"
      contentClassName="modal-border modal-shadow"

    >
      <Modal.Body>
        <div className="container-fluid px-3 ">
          <div className="row ">
            <div className="col-12 ">
              <span
                style={{
                  lineHeight: "12px",
                  width: "18px",
                  fontSize: "8pt",
                  color: "#336383",
                  position: "absolute",
                  top: 15,
                  right: 15,
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.handleModal();
                }}
              >
                {<CloseIcon />}
              </span>
            </div>
          </div>
          <div className="row ">
            <div className="col-12">
              <div className="d-flex ">
                {/* <div>
                  <img src={AddButtonIcon} alt=""></img>
                </div> */}
                <div className="mt-2">
                  <p className="h18 fw-sb " style={{ fontWeight: "600" }}>
                    Edit Surgery
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-sm-6 ">
              <label className="b2 text--secondary ">{_str.procedure}</label>
              <input
                type="text"
                required
                className="form-control font-12 br-10 shadow-none"
                defaultValue={props.editSurgery.surgicalSite}
                onChange={(e) => handleSurgeryNameInput(e)}
                style={{
                  border: "1px solid #D9E8EC",
                }}
              ></input>
            </div>
            <div className="col-sm-6">
              <label className="b2 text--secondary ">Date</label>
              <CalenderDatePicker
                f12={true}
                dobHandler={handleSurgeryDateInput}
                dateFormat="MMM d, yyyy"
                date={props?.editSurgery?.surgicalDate ? props?.editSurgery?.surgicalDate : null}
                maxDate={DateTime.now().toISODate()}
                required={false}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-sm-12 ">
              <label className="b2 text--secondary">{_str.note}</label>
              <TextArea
                className="form-control font-12 br-10 shadow-none "
                style={{ border: "1px solid #D9E8EC" }}
                defaultValue={props.editSurgery.note}
                gettext={gettext}
              />
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer style={{ border: "none" }}>
        <button
          className="btn blue_task_button b2 px-5 br-10"
          onClick={() => {
            props.editSurgeryRequestHandler();
            props.handleModal();
          }}
        >
          {_str.update}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SurgeryModal;

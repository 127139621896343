import { Modal } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import classes from "./EmailInvitationModal.module.css";
import { useState } from "react";
import sendHttpRequest, { search } from "../../hooks/sendHttpRequest";
import _, { isEmpty } from "lodash";
import { PnToolTip } from "../Reusable/PnComponents";
import { truncate } from "../../../utils/helpers/HelperFunctions";
import { X } from "react-bootstrap-icons";
import { useAuth } from "../../hooks/useAuth";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import TrashButtonIcon from "../../../assets/icons/Icon-Trash.svg";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#fafcfc",
    color: "#336383",
    fontWeight: 400,
    fontSize: 12,
    fontFamily: "Montserrat",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    paddingRight: "0px",
    fontFamily: "Montserrat",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#FAFCFC",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const EmailInvitationModal = (props) => {
  const [suggestions, setSuggestions] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [isProvidersLoading, setIsProvidersLoading] = useState(false);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [providerError,setProviderError] = useState();
  const { user } = useAuth();

  const searchUser = async (searchValue) => {
    setIsProvidersLoading(true);
    setProviderError(false)
    const httpResponse = await search({
      url: `/provider/search`,
      method: "GET",
      params: {
        search: searchValue.trim(),
        // userId: user?.userId,
      },
    });
    if (!httpResponse.error) {
      setSuggestions(httpResponse.data);
    } else {
      setProviderError(true)
      setSuggestions([]);
    }
    setIsProvidersLoading(false);
  };

  const handleEmailInvitation = async () => {
    let providerIDs = [];
    selectedProviders.forEach((providerItem) => {
      providerIDs.push(providerItem?.userId);
    });
    const httpResponse = await sendHttpRequest({
      url: "/provider/new-tenant",
      method: "POST",
      data: {
        providers: providerIDs,
        tenantId: user?.tenantId,
        userId: user?.userId,
      },
    });
    if (!httpResponse?.error) {
      props?.setEmailInvitationToastSevrity("success");
      props?.setEmailInvitationErrorMsg("Email invitation sent successfully");
    } else {
      props?.setEmailInvitationToastSevrity("error");
      props?.setEmailInvitationErrorMsg("Email invitation not sent");
    }
    props?.setEmailInviattionToast(true);
    props?.handleModal();
  };

  const handleOnChange = (event,searchVal) => {
    let searchValue = event.target.value || searchVal;
    // console.log("event.target.value || searchVal",event.target.value || searchVal);
    setSearchInputValue(searchValue);
    if (searchValue?.length > 2 && (event.key === "Enter" || event.type === 'click')) {
      searchUser(searchValue);
    } else {
      setSuggestions([]);
    }
  };

  const handleRemove = (id, userList, setUserList) => {
    const dummy = userList.filter((uName) => uName.userId !== id);
    setUserList(dummy);
  };
  const handleSelectedProvider = (provider) => {
    if (selectedProviders?.length === 0) {
      setSelectedProviders([provider]);
    } else {
      const tempList = [...selectedProviders];
      tempList.push(provider);
      const unique = [
        ...new Map(tempList.map((item) => [item.userId, item])).values(),
      ];

      setSelectedProviders(unique);
    }
    setSearchInputValue("");
    // setSuggestions([]);
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          props?.handleModal();
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
        backdrop="static"
        // dialogClassName={`${classes["custom-modal-style"]}`}
        backdropClassName="modal-backdrop"
        contentClassName="modal-border modal-shadow"
      >
        <Modal.Header closeButton>
          <Modal.Title className="font-20 text--terciary">
            Invite User
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className={``}>
          <div className={` ${classes["custom-modal-style"]}`}>
            <div className={`row `}>
              <div className={`col-12 `}>
                <div className={`input-group border--default br-10`}>
                  <input
                    type="text"
                    //   ref={searchInput}
                    className={`form-control shadow-none border-0 ms-1 font-14 ${classes["searchBar"]}`}
                    placeholder={"Search user"}
                    //   defaultValue={searchInputValue}
                    value={searchInputValue}
                    // onChange={(event) => handleOnChange(event)}

                    onChange={(e) => {
                      handleOnChange(e)
                    }}
                    onKeyPress={(e) => {
                      handleOnChange(e);
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        setSuggestions([]);
                        setProviderError(false)
                        setIsProvidersLoading(false);
                      }, 5);
                    }}
                  />

                  <button
                    className={`btn br-10 shadow-none`}
                    value={searchInputValue}
                    onClick={(e) => handleOnChange(e,searchInputValue)}
                    onBlur={() => {
                      setTimeout(() => {
                        setSuggestions([]);
                        setProviderError(false);
                        setIsProvidersLoading(false);
                      }, 5);
                    }}
                  >
                    <SearchIcon className=" text--secondary"/>
                  </button>
                </div>

                <ul
                  className={` cursor--pointer ${classes["suggestion__list"]} hide-scrolbar`}
                  style={{
                    // width: `${props?.newPrescriptionRequest ? "44%" : "34%"}`,
                    width: "94%",
                  }}
                >
                  {isProvidersLoading ? (
                    <>
                      <li>Loading...</li>
                    </>
                  ) : (
                    providerError === true ? 
                    <>
                      <li>{"Result not found"}</li>
                    </>
                    :
                    suggestions?.length > 0 &&
                    suggestions.map((suggestion) => {
                      return (
                        <li
                          key={
                            suggestion.userId + Math.floor(Math.random() * 100)
                          }
                          id={suggestion.userId}
                          onMouseDown={() => {
                            handleSelectedProvider(suggestion);
                          }}
                        >
                          {suggestion?.name[0]?.firstName.concat(
                            " ",
                            suggestion?.name[0]?.lastName,
                            "    ",
                            "(",suggestion?.identification?.npi || "",")"
                          )}
                        </li>
                      );
                    })
                  )}
                </ul>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12 ">
                {selectedProviders?.length > 0 && (
                  <TableContainer
                    sx={{ borderRadius: "10px", border: "1px solid #D9E8EC" }}
                  >
                    <Table size="small">
                      <TableHead
                        sx={{
                          borderTopColor: "#FAFCFC",
                          borderRightColor: "#FAFCFC",
                          borderLeftColor: "#FAFCFC",
                          borderBottom: "1px solid #D9E8EC",
                        }}
                      >
                        <StyledTableRow>
                          <StyledTableCell>Name</StyledTableCell>
                          <StyledTableCell>NPI</StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                        {selectedProviders?.map((providerItem) => {
                          return (
                            <StyledTableRow
                              key={providerItem?.userId}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                // width: "10rem",
                              }}
                            >
                              <StyledTableCell sx={{ fontWeight: 600 }}>
                                {providerItem?.name[0]?.firstName +
                                  " " +
                                  providerItem?.name[0]?.lastName}
                              </StyledTableCell>

                              <StyledTableCell sx={{ color: "#336383" }}>
                                {providerItem?.identification?.npi !== undefined &&
                                providerItem?.identification?.npi !== null ?
                                providerItem?.identification?.npi : "" }
                              </StyledTableCell>

                              <StyledTableCell
                                onClick={() =>
                                  handleRemove(
                                    providerItem?.userId,
                                    selectedProviders,
                                    setSelectedProviders
                                  )
                                }
                                // id={diagnose?.code}
                                sx={{ cursor: "pointer" }}
                              >
                                <img
                                  src={TrashButtonIcon}
                                  alt={"deleteIcon"}
                                  id={providerItem?.userId}
                                  className={`${classes["delete__icon"]}`}
                                ></img>
                              </StyledTableCell>
                              {/* )} */}
                            </StyledTableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                {/* <div className="d-flex mt-2 flex-row flex-wrap gap-2">
                    {selectedProviders?.length > 0 &&
                      selectedProviders?.map((providerItem) => {
                        return (
                          <PnToolTip
                            title={
                              providerItem?.name[0]?.firstName +
                              " " +
                              providerItem?.name[0]?.lastName
                            }
                            placement={"top"}
                          >
                            <div key={providerItem?.userId}>
                              <span className="badge font-12 fw-sb text--terciary bg--d9e8ec py-1 px-2 br-10">
                                {truncate(
                                  providerItem?.name[0]?.firstName +
                                    " " +
                                    providerItem?.name[0]?.lastName,
                                  25
                                )}
                                {
                                  <span
                                    className="cross-sign"
                                    onClick={() =>
                                      handleRemove(
                                        providerItem?.userId,
                                        selectedProviders,
                                        setSelectedProviders
                                      )
                                    }
                                  >
                                    <X
                                      color="#336383"
                                      style={{ marginBottom: "2px" }}
                                      size={24}
                                    />
                                  </span>
                                }
                              </span>
                            </div>
                          </PnToolTip>
                        );
                      })}
                  </div> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-end ">
              <button
                className={`btn d9e8ec--button br-10 text-white px-4 fw-sb font-14 me-2`}
                type="submit"
                onClick={() => {
                  props?.handleModal();
                }}
              >
                Cancel
              </button>
              <button
                className={`btn blue_task_button br-10 text-white px-4 fw-sb font-14 shadow-none ${
                  selectedProviders?.length === 0 ? "disabled" : ""
                }`}
                onClick={() => handleEmailInvitation()}
              >
                Invite
              </button>
            </div>
          </div>
        </Modal.Body>
        {/* {console.log("suggestions: ",suggestions)}
        {console.log("Select providerL: ",isEmpty(selectedProvider),selectedProvider===undefined,selectedProvider===null)}
        {console.log("searchInputValue",searchInputValue)} */}
      </Modal>
    </>
  );
};

export default EmailInvitationModal;

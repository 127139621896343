import { useState } from 'react';
import { Typography } from '@mui/material'
import axios from "axios";
import { useAuth } from '../../hooks/useAuth';
import PatientRegistrationLoginModal from './PatientRegistrationLoginModal';
import { useParams } from 'react-router-dom';

function PatientRegistrationFooter(props) {

    const [open, setOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [username, setUsername] = useState(false);
    const [answer, setAnswer] = useState(false);
    const { login, user } = useAuth();
    const { tid } = useParams();

    function handleForward(params) {
        // if (isLast) {
        //     window.location.replace("https://www.prescribenow.com");
        // } else if (redirectTo !== null) {
        //     getUserInfo();
        // }
        // else {
        //     handlePost();
        // }
    }

    function postLoginSM() {
        // setIsLoading(true);
        // axios.defaults.withCredentials = true;
        // axios.post(baseURL + '/assessmentForm', [{ question: "login", answer: "login" }])
        //     // axios.post('http://202.163.76.100:11235/assessmentForm', answer == "" ? [{}] : answer)
        //     .then(function (response) {
        //         console.log(response.data);
        //         // setQuestion(response.data);
        //         // setStatusCode(response.status)
        //         // setAnswer('');
        //         // setIsLoading(false);
        //         setOpen(!open);
        //     })
        //     .catch(function (error) {
        //         console.log(error.response);
        //         // if (error.response.status === 500) {
        //         //     alert("Internal Server Error");
        //         // } else {
        //         //     setQuestion(error.response.data);
        //         // }
        //         // setAnswer('');
        //         // setStatusCode(error.response.status)
        //         // setIsLoading(false);
        //     });
    }

    // function getUserInfo() {
    //     // setIsLoading(true);
    //     axios.defaults.withCredentials = true;
    //     axios.get(baseURL + '/auth/patient/loginInfo')
    //         // axios.post('http://202.163.76.100:11235/assessmentForm', answer == "" ? [{}] : answer)
    //         .then(function (response) {
    //             response = response?.data;
    //             login({
    //                 loggedIn: true,
    //                 role: response?.role ?? "",
    //                 userId: response?.userId ?? "",
    //                 selectedUserId: response?.userId ?? "",
    //                 timezone: response?.timezoneOffset ?? "",
    //                 timezoneText: response?.timezone ?? "",
    //                 name: response?.name,
    //                 firstName: response?.name[0]?.firstName ?? "",
    //                 lastName: response?.name[0]?.lastName ?? "",
    //                 prefix: response?.name[0]?.prefix ?? "",
    //                 tenantId: response?.tenant?.tenantId,
    //                 tenant: response?.tenant
    //             });

    //             setTimeout(() => {
    //                 // window.location.replace("https://www.prescribenow.com");
    //                 window.location.replace("../new/appointment");

    //                 // window.location.replace("http://localhost:3001/new/appointment");
    //             }, 20)

    //         });

    //     // .catch(function (error) {
    //     //     console.log(error);
    //     // });
    // }



    // function goBack(params) {
    //     handleBack();
    // }

    function handleLoginModal() {
        postLoginSM();
    }

    function handleLoginCloseFromCrossModal() {
        setOpen(!open);
    }



    return (
        <>
            <PatientRegistrationLoginModal tid={tid} show={open} handleLoginCloseFromCrossModal={handleLoginCloseFromCrossModal} handleLoginModal={handleLoginModal} setIsLoggedIn={setIsLoggedIn} setUsername={setUsername} setAnswer={setAnswer} />
            <div className="d-flex justify-content-between p-3 fixed-bottom bg-white">
                <div className='mt-2'>
                    {/* <div className='mt-2' hidden={hideSignin}> */}
                    <Typography hidden={props?.isLoggedIn} variant="subtitle2" sx={{ color: "#336383", fontSize: '14px', fontFamily: "Montserrat" }} component="h1" gutterBottom>
                        {"Already have an account? "}
                        {<u className='fw-sb cursor-pointer' onClick={handleLoginCloseFromCrossModal}>Sign In</u>}
                        {/* {<u className='fw-sb cursor-pointer' onClick={(e) => handleLoginModal()}>Sign In</u>} */}
                    </Typography>
                </div>
                <div>
                    <button disabled={props?.disabled} id={"next"} onClick={() => props.onNextHandler()} style={{ backgroundColor: "#004FD4", marginLeft: "10px", color: "#ffffff", borderRadius: "10px", fontSize: "14px", marginRight: "2px", fontWeight: 600 }} className="btn btn__bottom blue_task_button" >
                        Next
                    </button>
                </div>
            </div>
        </>
    )
}

export default PatientRegistrationFooter;
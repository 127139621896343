import sendHttpRequest from "../hooks/sendHttpRequest";

export default class InPatientAppointmentService {

    static getAppointments = async (day, tenantId, userId) => {
        try {
            const response = await sendHttpRequest({
                method: "GET",
                url: `/appointment/day`,
                params: {
                    date: day,
                    tenantId: tenantId,
                    userId: userId
                }
            });
            if (!response.error) {
                return response.data;
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    static markAppointmentProviderReady = async (appointmentId, status) => {
        try {
            const response = await sendHttpRequest({
                method: "POST",
                url: `/appointment/${appointmentId}/${status}`,
            });
            if (!response.error) {
                return response;
            }
        }
        catch (err) {
            console.log(err);
        }
    }


}
import { ActionTypes } from "../constants/actionTypes"

const patientID = {
    patientId:-1
}

const setSearchedPatientIdReducer = (state=patientID, action)=>{
    
    if (action.type === ActionTypes.SET_PATIENT_ID ){
        return {
            ...state,
            patientId : action.payload
        }
    }
    return state
}


export default setSearchedPatientIdReducer;
import SearchIcon from "@mui/icons-material/Search";
import classes from "./ConsentFormSearch.module.css";
import { SettingsSuggest } from "@mui/icons-material";

const ConsentFormSearch = (props) => {
    return (
        <div className="d-flex flex-column" style={{ zIndex: "99999999" }}>
            <div className="input-group" style={{
                border: "1px solid #D9E8EC",
                borderRadius: "10px"
            }}>
                <input type="text" className="form-control br-10 shadow-none border-0  " placeholder="Search Consent form" value={props.consentFormName} onChange={e => {
                    props.handleSearchInput(e.target.value);
                }}

                    onKeyPress={e => {
                        if (e.code === "Enter" || e.charCode === 13) {
                            e.preventDefault();
                        }
                    }}
                    onBlur={() => {
                        setTimeout(() => {
                            props.setSuggestions([]);
                            props.setIsError(false);
                            props.setIsLoading(false);
                        }, 10);
                    }} />

                <button className="btn bg-white  br-10 shadow-none zindex-1" type="submits" value={props.searchInputValue} onClick={(e) => { e.preventDefault(); props.handleSearchInput(props.searchInputValue) }} onBlur={() => {

                    setTimeout(() => {
                        props.setSuggestions([]);
                        props.setIsError(false);
                        props.setIsLoading(false);
                    }, 5);
                }}>
                    <SearchIcon className="text--secondary" />
                </button>
            </div>

            {/* <ul className={`list-group ${classes["consent_suggestions"]}`} */}
            <ul className={`list-group ${props?.suggestions?.length > 0 ? 'border--default' : ''}`}
            style={{
                listStyleType: "none",
                paddingLeft: "0",
                zIndex: "99999999",
                borderTop: 0,
                backgroundColor: "#FFF",
                boxShadow:'0px 13px 26px #3363831A'
                //   props?.navigate === true
                //     ? ""
                //     : "0 2px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
                {props.isError ? <li className={`list-group-item ${classes["search__list__item"]}`}>
                    No result found
                </li> : props?.isLoading ? <li className={`list-group-item ${classes["search__list__item"]}`}>
                    Loading...
                </li> : props.suggestions.length > 0 && props.suggestions.map((suggestion, index) => {
                    return <li tabIndex={index} // className="list-group-item"
                        className={` ${classes.li} ${classes["search__list__item"]}`} key={suggestion.name} onMouseDown={() => props.handleAddConsentForm(suggestion)}
                    >
                        <span>{suggestion.name}</span>
                    </li>;
                })}
            </ul>
        </div>
    )
}

export default ConsentFormSearch
import { ActionTypes } from "../constants/actionTypes"

const requiredDataCheckState = {    
        requiredCheck:false
}

const setRequiredDataCheckReducer = (state=requiredDataCheckState, action)=>{
    if (action.type === ActionTypes.SET_REQUIRED_DATA_CHECK ){
        return {
            ...state,
            requiredCheck: action.payload
        
        }
    }
    return state
}


export default setRequiredDataCheckReducer;
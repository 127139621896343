import { useEffect, useRef } from "react";
import { isOnlySpaces } from "../../utils/helpers/HelperFunctions";

const TextArea = (props) => {
  const { gettext, scroll, ...rest } = props;
  const textAreaRef = useRef();
  function onChange(e) {
    e.preventDefault();
    gettext(e.target.value);
  }

  function onScroll(params) {
    // scroll (
    return (
      params.target.scrollHeight - params.target.scrollTop ===
      params.target.clientHeight
    );
    // );
  }
  useEffect(() => {
    if (props?.focus) {
      textAreaRef?.current?.focus();
    }
  }, [])
  return (
    <textarea
      className={props.className}
      placeholder={props.placeholder}
      onChange={(e) => onChange(e)}
      onScroll={onScroll}
      maxLength={props?.maxLength}
      id={props.id}
      ref={textAreaRef}
      onBlur={(e) => {
        // setTimeout(() => {
        if (isOnlySpaces(e.target.value)) {
          e.target.value = "";
        }
        // }, 1);
      }}
      {...rest}
    />
  );
};

export default TextArea;

import sendHttpRequest, { search } from "../../components/hooks/sendHttpRequest";
import { encounterSummaryUrl, getPatientAllergiesUrl, addressStandardizationUrl } from "../../res/endpointurls";

export const summaryGeneration = async (encounterId) => {
    let encounterData = {};
    const s = await sendHttpRequest({ url: encounterSummaryUrl(encounterId), method: "GET", });
    encounterData = await { ...s.data };
    const v = await sendHttpRequest({ url: getPatientAllergiesUrl(encounterData?.patient?.userId), method: "GET" });
    encounterData = { ...encounterData, allergies: await v.data };
    return encounterData;
}

export const getStandardizedAddress = async (addressLine1, addressLine2, city, state, zip, countryCode = "US") => {
    console.log(addressLine1, addressLine2, city, state, zip, countryCode);
    const addressObject = {
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        city: city,
        stateProvince: state?.code,
        postalCode: zip,
        countryCode: countryCode
    }
    const addressStandardizationResult = await search({ url: addressStandardizationUrl(), data: addressObject, method: "POST" });
    if (!addressStandardizationResult.error) {
        return addressStandardizationResult.data;
    }
    else {
        console.log(addressStandardizationResult);
    }

}




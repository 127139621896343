const PharmacyIcon = (props) => {
  return (
    <svg
      id="Icon-pharmacy"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 15 20"
      {...props}
      fill={props.style.color}
    >
      <path
        id="Icon-pharmacy-2"
        data-name="Icon-pharmacy"
        d="M13.833,5.833l-.019-.981A2.493,2.493,0,0,0,13,0H8a2.494,2.494,0,0,0-.833,4.847v.987A4.172,4.172,0,0,0,3,10v5.833A4.172,4.172,0,0,0,7.167,20h6.667A4.172,4.172,0,0,0,18,15.833V10a4.172,4.172,0,0,0-4.167-4.167ZM8,1.667h5a.833.833,0,1,1,0,1.667H8A.833.833,0,1,1,8,1.667Zm8.333,14.167a2.5,2.5,0,0,1-2.5,2.5H7.167a2.5,2.5,0,0,1-2.5-2.5V10a2.5,2.5,0,0,1,2.5-2.5H8a.833.833,0,0,0,.833-.833V5h3.311l.023,1.678A.833.833,0,0,0,13,7.5h.833a2.5,2.5,0,0,1,2.5,2.5Zm-2.5-3.333a.833.833,0,0,1-.833.833H11.333V15a.833.833,0,1,1-1.667,0V13.333H8a.833.833,0,1,1,0-1.667H9.667V10a.833.833,0,1,1,1.667,0v1.667H13A.833.833,0,0,1,13.833,12.5Z"
        transform="translate(-3 0)"
        stroke=""
      />
    </svg>
  );
};

export default PharmacyIcon;

import {
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    tableCellClasses,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { useParams } from "react-router-dom";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { useEffect, useState } from "react";
import {
    calenderDateTimeHelper,
    dateTimeHelper,
} from "../../utils/dateTimeHelper";
import { RefreshDataComponent } from "../UI/General/RefreshDataComponent";
import EditIconComponent from "../../assets/iconComponents/EditIconComponent";
import ReplayIcon from "@mui/icons-material/Replay";
import CustomizedSnackbar from "../UI/General/CustomizedSnackbars";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { PnToolTip } from "../UI/Reusable/PnComponents";
import { useSelector } from "react-redux";
import ImagingOrderModal from "../UI/Modals/ImagingOrderModal";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#FAFCFC",
        color: "#336383",
        fontWeight: 400,
        fontSize: 12,
        // width: 150,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        paddingRight: "0px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: "#FAFCFC",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));
const ImagingOrderTable = (props) => {
    const [isImagingOrderLoading, setIsImagingOrderLoading] = useState(false)
    const [imagingOrders, setImagingOrders] = useState([])
    const [imagingOrderData, setImagingOrderData] = useState();

    // ============Imaging order modal====================================
    const [openImagingOrderModal, setOpenImagingOrderModal] = useState(false);
    const [isImagingOrderSent, setIsImagingOrderSent] = useState(false);
    const [imagingSeverity, setImagingSeverity] = useState("");
    const [imagingMessage, setImagingMessage] = useState(false);


    const fetchImagingOrders = async () => {
        setImagingOrders([])
        setIsImagingOrderLoading(true);
        const httpResponse = await sendHttpRequest({
            url: `/patients/${props?.patientId}/imaging-orders`,
            method: 'GET',
        })

        if (!httpResponse.error) {
            setImagingOrders(httpResponse.data)
        } else {
            setImagingOrders([])
        }
        setIsImagingOrderLoading(false);
    }

    // =====================Download Imaging Order==============
    function downloadPdf(httpResponse) {
        const link = document.createElement("a");
        link.target = "_blank";
        link.download =
            `${httpResponse?.responseHeaders?.pdf_name?.split("=")?.at(1)}` ??
            `Imaging_Order_${props?.patientId}`;
        const href = URL.createObjectURL(
            new Blob([httpResponse.data], {
                type: "application/pdf",
                encoding: "UTF-8",
            })
        );
        link.href = href;
        link.click();
        link.remove();
        URL.revokeObjectURL(href);
    }
    const downloadImagingOrder = async (imagingOrderId) => {
        const httpResponse = await sendHttpRequest({
            url: `/imaging-orders/${imagingOrderId}`,
            method: 'GET',
            Accept: 'application/pdf',
            responseType: "arraybuffer",
        })
        if (!httpResponse.error) {
            downloadPdf(httpResponse)
        }
    }

    // =================Imaging Order handlers======================
    const handleImagingOrderModal = () => {
        setOpenImagingOrderModal(!openImagingOrderModal);
        setImagingOrderData(undefined)
    }

    const handleImagingToast = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setIsImagingOrderSent(false);
    };
    const setEditImagingOrderData = (imagingOrderObject) => {
        setImagingOrderData(imagingOrderObject)
        setOpenImagingOrderModal(true);
    }


    useEffect(() => {
        fetchImagingOrders()
    }, [props?.patientId, props?.isImagingOrderSent])


    return (
        <>

            {
                openImagingOrderModal ?
                    <ImagingOrderModal
                        show={openImagingOrderModal}
                        handleModal={handleImagingOrderModal}
                        patientId={props.patientId}
                        setIsImagingOrderSent={setIsImagingOrderSent}
                        setImagingSeverity={setImagingSeverity}
                        setImagingMessage={setImagingMessage}
                        editMode={true}
                        imagingOrderData={imagingOrderData}
                        setIsImagingOrderDataSent={props?.setIsImagingOrderSent}
                        isImagingOrderDataSent={props?.isImagingOrderSent}
                    /> : null
            }
            <div className="d-flex justify-content-between">
                <div></div>
                <RefreshDataComponent
                    isComponentDataLoading={isImagingOrderLoading}
                    callback={fetchImagingOrders}
                    variant="without-bar"
                    classes="pe-2"
                />
            </div>
            <TableContainer
                sx={{ borderRadius: "16px", border: "1px solid #D9E8EC" }}
                className="hide-scrolbar"
            >
                <Table>
                    <TableHead
                        sx={{
                            borderTopColor: "#FAFCFC",
                            borderRightColor: "#FAFCFC",
                            borderLeftColor: "#FAFCFC",
                            borderBottom: "1px solid #D9E8EC",
                        }}
                    >
                        <StyledTableRow>
                            <StyledTableCell>{"Order Date"}</StyledTableCell>
                            <StyledTableCell>{"Ordered by"}</StyledTableCell>
                            <StyledTableCell>{"Imaging"}</StyledTableCell>
                            <StyledTableCell>{"Additional Test"}</StyledTableCell>
                            {/* <StyledTableCell>{"Reason for Test"}</StyledTableCell> */}
                            <StyledTableCell>{""}</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>

                    <TableBody>
                        {isImagingOrderLoading ? (
                            <StyledTableRow>
                                <StyledTableCell>
                                    <Skeleton animation="wave" />
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Skeleton animation="wave" />
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Skeleton animation="wave" />
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Skeleton animation="wave" />
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Skeleton animation="wave" />
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Skeleton animation="wave" />
                                </StyledTableCell>
                            </StyledTableRow>
                        ) : imagingOrders?.length > 0 ? (
                            imagingOrders.map((imagingItem) => {
                                let orderDate = imagingItem?.createdDate ?? new Date()
                                let firstName = imagingItem?.prescriptionReference?.providerTenantPracticeLocation?.provider?.name[0]?.firstName ?? ""
                                let lastName = imagingItem?.prescriptionReference?.providerTenantPracticeLocation?.provider?.name[0]?.lastName ?? ""
                                let imagingTests = imagingItem?.imagingOrderTests ?? []
                                let additionalTest = imagingItem?.additionalTest ?? ""
                                return (
                                    <StyledTableRow>
                                        <StyledTableCell>{dateTimeHelper(orderDate, "MMM D, YYYY")}</StyledTableCell>
                                        <StyledTableCell>
                                            {"".concat(firstName || "", " ", lastName || "")}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {imagingTests?.map((item) => {
                                                let leftRight = item?.left === true ? " - Left" : item?.right === true ? " - Right" : ""
                                                return (<span className="d-block">{item?.imagingTest?.imageTest?.displayValue + ": " + item?.imagingTest?.description + leftRight}</span>)
                                            })}
                                        </StyledTableCell>
                                        <StyledTableCell>{additionalTest || ""}</StyledTableCell>
                                        {/* <StyledTableCell>{"reasonForTest" || ""}</StyledTableCell> */}
                                        <StyledTableCell >
                                            <div className="d-flex flex-row gap-2" hidden={props?.patientPortal}>

                                                <PnToolTip title={"Edit"} hidden={props?.patientPortal}>
                                                    <div
                                                        className="cursor--pointer "
                                                        onClick={() => {
                                                            setEditImagingOrderData(imagingItem)
                                                        }}
                                                    // hidden={true}
                                                    >
                                                        <EditIconComponent
                                                            style={{ color: "#336383", fill: "#336383" }}
                                                        />
                                                    </div>
                                                </PnToolTip>
                                                <div
                                                    onClick={() => {
                                                        downloadImagingOrder(imagingItem?.imagingOrderId)
                                                    }}
                                                    className="cursor--pointer me-2"

                                                >
                                                    <PnToolTip title={"Download"}>
                                                        <FileDownloadIcon
                                                            sx={{
                                                                color: "#336383",
                                                                fontWeight: 400,
                                                                fontSize: "1.3rem",
                                                            }}
                                                        />
                                                    </PnToolTip>
                                                </div>

                                            </div>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            })
                        ) : <span className="font-12 text--terciary p-2">{"No data available"}</span>}
                    </TableBody>


                </Table>
            </TableContainer>
            {/* <CustomizedSnackbar
                    message={message}
                    severity={severity}
                    open={openLabOrderSnackBar}
                    handleClose={handleLabOrderSnackBar}
                /> */}

            <CustomizedSnackbar
                message={imagingMessage}
                severity={imagingSeverity}
                bottom={250}
                open={isImagingOrderSent}
                handleClose={handleImagingToast}
                style={{
                    bottom: "-60vh",
                    position: "absolute",
                    zIndex: 1600
                }}
            />
            {console.log("props?.patientPortal: ", props?.patientPortal)}
        </>
    )
}

export default ImagingOrderTable;
import { ActionTypes } from "../constants/actionTypes";

const consulationInProgress = { isConsultationInProgress: false };

const setConsulationInProgressReducer = (state = consulationInProgress, action) => {
  if (action.type === ActionTypes.SET_CONSULTATION_IN_PROGRESS) {
    return {
      ...state,
      isConsultationInProgress: action.payload,
    }
  }
  return state;
};

export default setConsulationInProgressReducer;

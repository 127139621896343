import { changeDateTimeFormat } from "../../../utils/dateTimeHelper";
import { useForm } from "react-hook-form"
import sendHttpRequest from "../../hooks/sendHttpRequest";
import { useRef, useState } from "react";
import CustomizedSnackbar from "../../UI/General/CustomizedSnackbars";

const PrivacyNoticeForm = (props) => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({ mode: 'onChange' });

    const [isSent, setIsSent] = useState(false);
    const fd = useRef(new FormData());
    const [isConsentChecked, setIsConsentChecked] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setIsSent(false);
    };


    const onSubmit = (data) => {
        fd.current.set("consent", data?.consent || false);
        submitForm(fd);
    };

    const submitForm = async formdata => {
        const httpResponse = await sendHttpRequest({
            url: `/consentform/${props?.formId}`,
            method: "POST",
            data: formdata.current
        });
        if (!httpResponse.error) { setIsSent(true); props.handleDisableNext(watch("consent")); }
        else {
            props?.handleDisableNext(false);
        }
    }
    //   props.handleDisableNext(watch("consent"));


    return (
        <>
            <div className="container">
                <div className="d-flex justify-content-center mb-3 fw-sb font-18">
                    PrescribeNow Corp.
                </div>
                <div className="d-flex justify-content-center mb-3 fw-sb">
                    Privacy Policy
                </div>
                <div className="d-flex justify-content-center mb-3 fw-sb">
                    Last Updated: {changeDateTimeFormat("09-04-2023", "MMMM DD, YYYY")}
                </div>
                <div className="d-flex justify-content-start mb-3 fw-sb">
                    Introduction
                </div>
                <div className="d-flex justify-content-start mb-3">
                    <p>Protecting your privacy is very important to PrescribeNow Corp. (“<b>we</b>” or “<b>us</b>”). This Privacy Policy (this “<b>Policy</b>”) explains what information we gather, use, and share when you visit any website controlled and operated by us, including when you access our websites via a mobile device. By accessing our website, you agree to be bound by the terms of this Policy. If you disagree with this Policy or with our Website Terms of Use, or do not agree to be bound by them, you are not authorized to access any of our websites.</p>
                </div>
                <div className="d-flex justify-content-start mb-3 fw-sb">
                    Information We Collect
                </div>
                <div className="d-flex justify-content-start mb-2">
                    <p>We may collect Personal or Non-Personal Information from you. “<b>Personal Information</b>” means any information that specifically identifies you as an individual that you provide to us in connection with your use of our website. “<b>Non-Personal Information</b>” is any information you provide to us that cannot be used to determine your identity. The types and amount of information we collect will vary depending on the website and the activity in which you engage.</p>
                </div>
                <div className="d-flex justify-content-start mb-3">
                    <p>If you create an account with us, we may request that you provide us with certain Personal Information, including but not limited to your first and last name, email address, home address (including city and state), telephone number (work or home), and the location and the name of your organization, if applicable.  If you purchase any products made available on our websites, you may also be required to provide us with credit card information.  For some activities, you may be asked to create a username and/or password and/or to provide additional, demographic information, including: (i) your age or date of birth, (ii) gender, (iii) frequency of use, purchase, and consumption of certain of our products and/or other similar products, (iv) facts about your business, and/or, (v) other information relevant to our product you have purchased or the specific website you are visiting.</p>
                </div>
                <div className="d-flex justify-content-start mb-3">
                    <h6 className="fw-sb">How We Collect Information About You</h6>
                </div>
                <div className="d-flex justify-content-start mb-2">
                    <p>We use information that we collect or obtain when you use our website, including Personal Information, for the following purposes:</p>
                </div>
                <div className="d-flex justify-content-start mb-3">
                    <ol>
                        <li>Market research</li>
                        <li>Improving and updating our services</li>
                        <li>The development of new features and new products and services</li>
                        <li>Responding to feedback, questions, and communications concerning our products and services</li>
                        <li>Informing you about our services which may include contacting you by telephone</li>
                        <li>Maintenance and administration of our website.</li>
                    </ol>
                </div>
                <div className="d-flex justify-content-start mb-3 font-14 fw-sb">
                    Means of Collecting Information
                </div>
                <div className="d-flex justify-content-start mb-3">
                    <p>
                        “<b>Cookies</b>” are small text files that are placed on your device by a web server when you access a site. We use Cookies to improve the functionality of our websites. Cookies help websites remember visitors when they return to the site. For example, a Cookie can help a website remember that you’ve already registered, so you don’t have to sign in again each time you visit. We may use cookies to (i) allow us to personalize information for certain segments of our customer base, (ii) allow us to associate individual customers with their information profiles, (iii) keep track of and administer your status, preferences, business information and other information provided by you, (iv) for security purposes, and (v) to understand visitor usage of our websites on an anonymous basis. You may remove any cookies as described above or “opt out” of receiving advertisements or other cookies by using services such as those provided by the Digital Advertising Alliance or Network Advertising Initiative. You may find more information about their members and the “opt out” process by going to their websites at http://www.aboutads.info/choices orhttp://www.networkadvertising.org/.  Although most browsers automatically accept Cookies, you can change your browser options to stop automatically accepting Cookies or to prompt you before accepting Cookies. Please note, however, that if you don’t accept Cookies, you may not be able to access all portions or features of our websites.
                    </p>
                </div>
                <div className="d-flex justify-content-start mb-3">
                    <p>
                        <b>IP Addresses.</b>We may keep track of Internet Protocol (IP) addresses.  We use the IP addresses for various purposes, including to: (i) troubleshoot technical concerns, (ii) maintain website safety and security, (iii) restrict access to our website to certain users, and, (iv) better understand how our website is utilized. An IP address is a number that is used by computers on the network to identify your computer every time you log on to the Internet.
                    </p>
                </div>
                <div className="d-flex justify-content-start mb-3">
                    <p>
                        <b>Log Files.</b> We (or a vendor on our behalf) may collect information in the form of logs. Logs are files that record website activity and gather statistics about web users’ browsing habits. These entries are generated anonymously and help us gather (among other things) (i) a user’s browser type and operating system, (ii) information about a user’s session (such as the URL they came from, the date and time they visited our website, and which pages they’ve viewed on our website and for how long), and, (iii) other similar navigational or click-stream data. We also use information captured in log file for our internal marketing and demographic studies, so we can constantly improve and customize the online services we provide to you. Log files are used by us only internally and are not associated with any particular user.
                    </p>
                </div>
                <div className="d-flex justify-content-start mb-3">
                    <p>
                        <b>Web Beacons.</b> We may use web beacons (also known as Web bugs, pixel tags or clear GIFs) on our websites or include them in the e-mail messages we send you. Web beacons are small strings of code that provide a method of delivering a graphic image on a web page or in an email message for the purpose of transferring data back to us. The information collected via web beacons may include some of the information described in the IP Address section above, as well as information about how a user responds to an email campaign from us. We use web beacon information for a variety of purposes, including but not limited to website traffic reporting, unique visitor counts, advertising and email auditing and reporting, and personalization.
                    </p>
                </div>
                <div className="d-flex justify-content-start mb-3">
                    <p>
                        <b>Newsletters and Communication.</b> If you provide us your email address and/or mailing address (whether as part of our general website registration or for a specific newsletter or communication), or provide your contact information on a form intended as a request for information regarding our products and services, we may use the contact information you provide to send you the specific newsletter, communication, or literature requested. In addition, we may send you communications introducing you to other brands or websites. These materials may come in the form of regular mail or email, depending on the website you register on and the information you provide during registration.
                    </p>
                </div>
                <div className="d-flex justify-content-start mb-3">
                    <p>
                        Newsletters and Communication. If you provide us your email address and/or mailing address (whether as part of our general website registration or for a specific newsletter or communication), or provide your contact information on a form intended as a request for information regarding our products and services, we may use the contact information you provide to send you the specific newsletter, communication, or literature requested. In addition, we may send you communications introducing you to other brands or websites. These materials may come in the form of regular mail or email, depending on the website you register on and the information you provide during registration.
                    </p>
                </div>
                <div className="d-flex justify-content-start mb-3">
                    <p>
                        <b>Business Interests.</b> We may combine (aggregate) your Non-Personal information with other consumers’ information or other publicly available information to help us satisfy our legitimate business interests, such as performing trend analysis or market studies, identifying consumer preferences or interests, setting prices, performing billing functions, establishing credit, or complying with government regulations. We may also share anonymized data, such as statistical or demographic information in aggregate form, with third parties for research or marketing purposes. However, this anonymized data will not contain your individually identifiable Personal Information.
                    </p>
                </div>
                <div className="d-flex justify-content-start mb-2">
                    <h6 className="fw-sb">
                        Disclosure of Information to Third Parties
                    </h6>
                </div>
                <div className="d-flex justify-content-start mb-3">
                    <ul>
                        <li>To comply with any applicable law, regulation, court order or other legal process, or government request as we, in our sole discretion, believe necessary or appropriate</li>
                        <li>To take appropriate action regarding any use of our website that may violate any law or regulation</li>
                        <li>To protect the health, safety, security, property, and interests or rights of us or others</li>
                        <li>In order to investigate or respond to or resolve problems or inquiries or defend our interests.</li>
                        <li>To a third party that acquires our company, such as through a sale of our assets or through bankruptcy proceedings.</li>
                        <li>As otherwise necessary or useful for us to conduct our business, so long as such use is permitted by law.</li>
                    </ul>
                </div>
                <div className="d-flex justify-content-start mb-3">
                    <h6 className="fw-sb">
                        Miscellaneous
                    </h6>
                </div>
                <div className="d-flex justify-content-start mb-2">
                    <p>
                        <b>California Privacy Rights.</b> If you are a resident of the State of California, under the California Consumer Privacy Act of 2018 (CCPA), you have the following rights with respect to your information collected by companies conducting business in California:
                    </p>
                </div>
                <div className="d-flex justify-content-start mb-3">
                    <ul>
                        <li>Right to Access Personal Information We Collect. You have the right to request a copy of Personal Information we collect about you, subject to certain restrictions.</li>
                        <li>Right to Access Personal Information We Sell or Disclose to Third Parties. You have the right to request a copy of your Personal Information we sell or disclose to third parties, subject to certain restrictions.</li>
                        <li>Right to Request Deletion of Personal Information We Collect. You have the right to request that we delete Personal Information we collect about you, subject to certain restrictions.</li>
                        <li>Right to Be Free from Discrimination. You have the right to be free from discrimination for exercising your rights under the CCPA.</li>
                    </ul>
                </div>
                <div className="d-flex justify-content-start mb-2">
                    <p>
                        <b>Personal Information We Collect About You.</b> Under the CCPA, Personal Information refers to information that identifies, relates to, describes, is capable of being associated with, or could reasonably be linked, directly or indirectly, with you or your household. Below is a list of categories of Personal Information we collect about you:
                    </p>
                </div>
                <div className="d-flex justify-content-start mb-3">
                    <ul>
                        <li><b>Identity Information</b> such as first name, last name, driver’s license or state identification, passport number, or similar identifier.</li>
                        <li><b>Contact Information</b> such as physical addresses, e-mail addresses, and phone numbers.</li>
                        <li><b>Education and Employment Information</b> such as schools and colleges/universities attended, degrees earned, certifications and licenses, and other professional-related data.</li>
                        <li><b>Technical and Usage Information</b> such as login data, Internet Protocol (IP) address, geolocation data, access dates and times, cookie data, browser activity, and other user interactions with our websites, applications, and platforms.</li>
                        <li><b>Marketing Information</b> such as your preferences in receiving marketing materials and communications from us.</li>
                        <li><b>Profile Information</b> such as your usernames and passwords, feedback, and survey responses.</li>
                        <li><b>Financial Information</b> such as payment details, either directly collected by us or indirectly collected through a third-party payment service provider.</li>
                        <li><b>Medical Information</b> not governed under the Health Information Portability and Accountability Act (HIPAA) or the Confidentiality of Medication Information Act Part 2.6.</li>
                    </ul>
                </div>
                <div className="d-flex justify-content-start mb-2">
                    <p>
                        We collect the above categories of Personal Information from the following categories of sources:
                    </p>
                </div>
                <div className="d-flex justify-content-start mb-3">
                    <ul>
                        <li>Directly and indirectly from you in the course of providing products and services to you</li>
                        <li>Directly and indirectly from activity on our website and applications including, but not limited to, submissions through our website portals and website usage details</li>
                        <li>Third parties that interact with us in connection with the services we provide.</li>
                    </ul>
                </div>
                <div className="d-flex justify-content-start mb-3">
                    <p>
                        We also collect information that is not deemed Personal Information under the CCPA. This includes publicly available information, such as information lawfully made available from federal, state, or local government records. Protected or health information we collect that is governed by HIPAA or the Confidentiality of Medical Information Act is not subject to the CCPA.
                    </p>
                </div>
                <div className="d-flex justify-content-start mb-2">
                    <p>
                        <b>Use of Personal Information.</b> We may use or disclose Personal Information we collect for one or more of the following business purposes:CCPA.
                    </p>
                </div>
                <div className="d-flex justify-content-start mb-3">
                    <ul>
                        <li>To provide you with information, products, or services that you request from us</li>
                        <li>To provide you with e-mail alerts, event registrations, or other notices concerning our products or services, events, or news that may be of interest to you</li>
                        <li>To carry out and fulfill our obligations and enforce our rights arising from any contracts entered into between you (or an entity on your behalf) and us</li>
                        <li>For analysis and product development</li>
                        <li>To improve our website and present its contents to you</li>
                        <li>To respond to law enforcement requests, court orders, governmental regulations, or as otherwise required under applicable law.</li>
                    </ul>
                </div>
                <div className="d-flex justify-content-start mb-2">
                    <p>
                        <b>Personal Information We Share with Third Parties.</b> We disclose your Personal Information to service providers and third parties to whom you or an entity on your behalf has authorized us to disclose your Personal Information in connection with products and services we provide to you. We require all third parties to respect the security of your Personal Information and to treat it in accordance with applicable law. Below is a list of categories of Personal Information we may share with third parties:
                    </p>
                </div>
                <div className="d-flex justify-content-start mb-3">
                    <ul>
                        <li><b>Identity Information</b> such as first name, last name, driver’s license or state identification, passport number, or similar identifier.</li>
                        <li><b>Contact Information</b> such as physical addresses, e-mail addresses, and phone numbers.</li>
                        <li><b>Education and Employment Information</b> such as schools and colleges/universities attended, degrees earned, certifications and licenses, and other professional-related data.</li>
                        <li><b>Technical and Usage Information</b> such as login data, Internet Protocol (IP) address, geolocation data, access dates and times, cookie data, browser activity, and other user interactions with our websites, applications, and platforms.</li>
                        <li><b>Marketing Information</b> such as your preferences in receiving marketing materials and communications from us.</li>
                        <li><b>Profile Information</b> such as your usernames and passwords, feedback, and survey responses.</li>
                        <li><b>Transaction Information</b> such as details regarding products and services purchased from us.</li>
                        <li><b>Medical Information</b> not governed under HIPAA or Confidentiality of Medical Information Act Part 2.6.</li>
                    </ul>
                </div>
                <div className="d-flex justify-content-start mb-2">
                    <p>
                        <b>Sale of Personal Information to Third Parties.</b> We do not sell or disclose deidentified Personal Information to third parties. All deidentified Personal Information is deidentified in accordance with 45 C.F.R. § 164.541(b)(2).
                    </p>
                </div>
                <div className="d-flex justify-content-start mb-2">
                    <p>
                        To exercise your rights listed above, please submit a request in writing to:
                    </p>
                </div>
                <div className="d-flex justify-content-start mb-3">
                    <p className="m-0">
                        PrescribeNow Corp.
                        <br />
                        3017 Douglas Blvd, Suite 300
                        <br />
                        Roseville, CA 95661
                        <br />
                        Attn: Privacy Officer
                    </p>
                </div>
                <div className="d-flex justify-content-start mb-3">
                    <p>
                        All requests sent via postal mail must be labeled “California Privacy Rights” on the envelope or post card and clearly state the actual request. For all requests, please include your name, street address (if you would like a response via postal mail), city, state, and zip code. We will not accept requests via email, telephone or fax. We are not responsible for notices that are not labeled or sent properly, or do not have complete information. You may designate an individual to submit a request on your behalf. We will use the information you provide in your request to verify your identity and will respond to your request within forty-five (45) days, or within a timeframe otherwise required or permitted under the CCPA. You will not be required to pay a fee to access your Personal Information unless your request is unfounded, excessive, or repetitive.
                    </p>
                </div>
                <div className="d-flex justify-content-start mb-3">
                    <p>
                        <b>How We Respond to Browser “Do Not Track” Signals.</b> Some web browsers incorporate a “Do Not Track” feature that signals to websites that you visit that you do not want to have your online activity tracked. How browsers communicate the Do Not Track signal is not yet uniform. For this reason, our websites and services are not set up to interpret or respond to Do Not Track signals.
                    </p>
                </div>
                <div className="d-flex justify-content-start mb-3">
                    <p>
                        <b>Children’s Online Privacy Protection Act (COPPA).</b> Our services are not directed to children under the age of 13. We do not knowingly collect Personal Information on our website directly from children and will destroy such information if we become aware that a child has disclosed such information to us without proper parental consent. It is our policy not to knowingly solicit or permit children under the age of 13 to provide their Personal Information for any purpose.
                    </p>
                </div>
                <div className="d-flex justify-content-start mb-3">
                    <p>
                        <b>Acceptance of and Changes to Privacy Policy.</b> By accessing or using our websites, you are accepting the practices described in this Policy. We reserve the right to periodically modify this Policy. We will post any modified Policy on this website and will indicated the date of the update. Your continued use of our website after the effective date of any modification means you accept and agree to be bound by the Policy as modified. Any material changes to this Policy will generally apply only to activities and information collected after we post the modified Policy. We encourage you to review this Policy whenever you return to the website to make sure you are aware of the latest version.
                    </p>
                </div>
                <div className="d-flex justify-content-start mb-3">
                    <p>
                        <b>Third Party Websites.</b> Our website may contain links to and from websites maintained by other companies or organizations. We are not responsible for the privacy practices or the content of third party websites. The information practices of third party websites are not covered by this Policy.
                    </p>
                </div>
                <div className="d-flex justify-content-start mb-3">
                    <p>
                        <b>Note to Non-U.S. Visitors.</b> This Policy is intended to meet the laws and regulations of the United States, which may not necessarily be consistent with the laws and regulations of your home country. Any information that you provide to us will be treated in accordance with this Policy, our Website Terms of Use, and U.S. laws.
                    </p>
                </div>
                <div className="d-flex justify-content-start mb-5">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-check">
                            <input className="form-check-input" {...register("consent", { required: true })} type="checkbox" id="privacyNote" onChange={(e) => { setIsConsentChecked(e.target.checked) }} />
                            <label className="form-check-label" htmlFor="privacyNote">
                                I confirm that I have reviewed the privacy policy and provide my consent, agreeing to comply with the terms and conditions stated therein.
                            </label>
                        </div>
                        <button type="submit" className="blue_task_button ms-0 my-3" disabled={!isConsentChecked}>Submit</button>
                    </form>
                </div>
            </div>
            <CustomizedSnackbar
                message={"Privacy form submitted successfully"}
                severity="success"
                open={isSent}
                handleClose={handleClose}
            />
        </>
    )
}

export default PrivacyNoticeForm;
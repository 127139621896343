const Input = (props) => {
  return (
    <input
      type={props.type}
      className={props.className}
      id={props.name}
      name={props.name}
      onChange={props.onChange}
      {...props}
    />
  );
};

export default Input;

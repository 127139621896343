import { useLocation, useNavigate, useParams } from "react-router-dom";
import { soap_strings } from "../../res/strings";
import BiotechIcon from "@mui/icons-material/Biotech";
import EditButtonIcon from "../../assets/icons/Icon-Edit.svg";
import useModal from "../hooks/useModal";
import AddVitalsModal from "../UI/Modals/PatientModals/AddVitalsModal";
import { useRef, useState } from "react";
import classes from "./PatientSoapNotePage.module.css";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { useAuth } from "../hooks/useAuth";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import useProviderNoteDiagnosis from "../hooks/useProviderNoteDiagnosis";
import DiagnosisModal from "../providernote/DiagnosisModal";
import DeleteDiagnosisModal from "../providernote/DeleteDiagnosisModal";
import EditDiagnosisModal from "../providernote/EditDiagnosisModal";
import DiagnosisTableProviderNote from "../providernote/DiagnosisItem";
import MedicationIcon from "@mui/icons-material/Medication";
import PrescriptionRequests from "../pages/PrescriptionRequests";
import NewPrescriptionModal from "../UI/Modals/PrescriptionModal/NewPrescriptionModal";
import useSnackbar from "../hooks/useSnackbar";
import { useCallback } from "react";
import DeleteLabOrderConfirmationModal from "../providernote/LabOrder/DeleteLabOrderConfirmationModal";
import LabRequisitionFormModal from "../UI/Modals/LabRequisitionFormModal";
import TrashButtonIcon from "../../assets/icons/Icon-Trash.svg";
import { X } from "react-bootstrap-icons";
import {
  setConsulationInProgress,
  setDirtyBitInStore,
  setVideoOpen,
  updatePrescribedMedication,
} from "../../redux/actions/providerActions";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { PnToolTip } from "../UI/Reusable/PnComponents";
import PatientSoapSummary from "./PatientSoapSummary";
import {
  encounterSummaryUrl,
  fetchPastSOAPSURLS,
} from "../../res/endpointurls";
import { useEffect } from "react";
import {
  calenderDateTimeHelper,
  dateTimeHelper,
} from "../../utils/dateTimeHelper";
import { fieldValidation } from "../../utils/helpers/HelperFunctions";
import { useDispatch, useSelector } from "react-redux";
import PatientDemographics from "../providernote/PatientDemographics";
import { VideoCallContext } from "../UI/AgoraHooks/VideoCallContext";
import { useContext } from "react";
import { LinearProgress } from "@mui/material";
import PageHeader from "../../patientportal/generic/PageHeader";
import InPatientAppointmentService from "../Services/InPatientAppointment.service";
import EndEncounterConsultationModal from "../UI/Tabs/EndEncounterConsultationModal";
import NotFoundMessage from "../UI/General/NotFoundMessage";
import CustomizedSnackbar from "../UI/General/CustomizedSnackbars";
import { ENCOUNTERS, PATIENTS, TASKS } from "../../utils/helpers/subscriptionsConstants";

const PatientSoapNotePage = () => {

  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);

  const navigate = useNavigate();
  const location = useLocation();

  const pageRef = location?.state?.pageRef;
  const prescriptionReferenceId = location.state?.prescriptionReferenceId ?? "";
  // console.log("prescriptionReferenceId: ",prescriptionReferenceId);
  const isResume = location.state?.isResume ?? false;
  const url = location.state?.resource ?? "";
  const encounterId = location.state?.encounterId ?? "";
  const appointmentId = location.state?.appointmentId ?? "";

  const videoCallContext = useContext(VideoCallContext);
  // console.log("url: ",url);
  // console.log("pageRef: ", pageRef, prescriptionReferenceId,encounterId,appointmentId);
  const __str = soap_strings.en;
  const { user } = useAuth();
  const { patientId } = useParams();
  const referenceFrom = useRef("provider-note");
  const dispatch = useDispatch();
  const {
    open: openVitalAddModal,
    handleOpen: handleOpenVitalAddModal,
    handleClose: handleCloseVitalModal,
    handleModal: handleVitalModal,
  } = useModal();
  const [vitals, setVitals] = useState();
  const [soapNotes, setSoapNotes] = useState();
  const [isPastEncChecked, setIsPastEncChecked] = useState(true);
  const [encountersData, setEncountersData] = useState([]);
  const [isEncountersDataLoading, setIsEncountersDataLoading] = useState(false);
  const [selectedPastEcounter, setSelectedPastEcounter] = useState();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  //   Diagnosis
  const [orderType, setOrderType] = useState();

  // Past Diagnosis
  const [pastDiagnosis, setPastDiagnosis] = useState([]);
  const [inactivePastDiagnosis, setInactivePastDiagnosis] = useState([]);
  const [pastDiagnosisIsLoading, setPastDiagnosisIsLoading] = useState(false);

  // Not Found
  const [isPatientNotFound, setIsPatientNotFound] = useState(false);

  // For new workflow of MA/Staff
  const [status, setStatus] = useState(location?.state?.selectedStatus !== undefined ? location.state.selectedStatus : null);
  const [patientDemographics, setPatientDemographics] = useState(null);
  const [showEndEncounterConsultationModal, setShowEndConsultationModal] = useState(false);
  function handleEndEncounterConsultationModal() {
    setShowEndConsultationModal(prev => !prev);
  }

  const {
    openDiagnosisModal,
    handleDiagnosisModal,
    openDiagnosisSnackbar,
    handleDiagnosisSnackBarClose,
    diagnosisAddedMessage,
    setDiagnosisAddedMessage,
    diagnosisSeverity,
    setDiagnosisSeverity,
    callApi,
    setCallApi,
    openDeleteDiagnosisModal,
    handleDeleteDiagnosisModal,
    openEditDiagnosisModal,
    handleEditDiagnosisModal,
    setOpenEditDiagnosisModal,
    setOpenDeleteDiagnosisModal,
    deleteDiagnosisLoading,
    deletedDiagnosis,
    deleteDiagnosis,
    editedDiagnosis,
    isDiagnosisUpdating,
    updateDiagnosis,
    setOpenDiagnosisSnackBar,
    diagnosesData,
    setDiagnosesData,
  } = useProviderNoteDiagnosis();

  const fetchPatientPastDiagnosis = async () => {
    setPastDiagnosisIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/patients/${patientId}/diagnoses`,
      method: "GET",
      params: {
        status: true,
        unique: true,
        excludeEncounter: prescriptionReferenceId
      },
    });
    if (!httpResponse.error) {
      if (httpResponse.data !== null && httpResponse.data !== undefined) {
        setPastDiagnosis(httpResponse.data);
      }
    }
    setPastDiagnosisIsLoading(false);
  };

  const handleToggleInactive = async (diagnosisId) => {

    const diagnosisIndex = pastDiagnosis.findIndex(d => d.diagnosisId === diagnosisId);
    if (diagnosisIndex !== -1) {
      const diagnosis = pastDiagnosis[diagnosisIndex];
      const newStatus = !diagnosis.status;

      console.log("Marking diagnosis ", diagnosisId, " as ", newStatus);

      const updatedPastDiagnosis = [...pastDiagnosis];
      updatedPastDiagnosis[diagnosisIndex] = { ...diagnosis, status: newStatus };
      setPastDiagnosis(updatedPastDiagnosis);

      // if (newStatus === true) {
      //     // Remove diagnosis from inactivePastDiagnosis
      //     const { [diagnosisId]: _, ...updatedInactivePastDiagnosis } = inactivePastDiagnosis;
      //     setInactivePastDiagnosis(updatedInactivePastDiagnosis);
      // } else {
      //     // Add diagnosis to inactivePastDiagnosis
      //   }
      setInactivePastDiagnosis({ ...inactivePastDiagnosis, [diagnosisId]: newStatus });

    }
  };

  const VitalsValues = () => {
    return (
      <>
        <p className="font-12 m-0">
          {vitals?.temperature !== null &&
            vitals?.temperature !== undefined &&
            vitals?.temperature?.trim() !== ""
            ? vitals?.temperature?.concat(" °F, ")
            : ""}
          {vitals?.systolic !== null &&
            vitals?.systolic !== undefined &&
            vitals?.systolic?.trim() !== "" &&
            vitals?.diastolic !== null &&
            vitals?.diastolic !== undefined &&
            vitals?.diastolic?.trim() !== ""
            ? vitals?.systolic?.concat("/", vitals?.diastolic, " mmHg, ")
            : ""}
          {vitals?.pulse !== null &&
            vitals?.pulse !== undefined &&
            vitals?.pulse?.trim() !== ""
            ? vitals?.pulse?.concat(" bpm, ")
            : ""}
          {vitals?.spo2 !== null &&
            vitals?.spo2 !== undefined &&
            vitals?.spo2?.trim() !== ""
            ? vitals?.spo2?.concat("%, ")
            : ""}
          {vitals?.weight !== null &&
            vitals?.weight !== undefined &&
            vitals?.weight?.trim() !== ""
            ? vitals?.weight?.concat(" lbs, ")
            : ""}

          {vitals?.height !== null &&
            vitals?.height !== undefined &&
            vitals?.height?.trim() !== ""
            ? vitals?.height?.concat(" in, ")
            : ""}
          {vitals?.bmi !== null &&
            vitals?.bmi !== undefined &&
            vitals?.bmi?.trim() !== ""
            ? vitals?.bmi?.concat(" bmi ")
            : ""}
        </p>
      </>
    );
  };
  const handleSoapNotes = (event) => {
    setUnsavedChanges(true);
    dispatch(setDirtyBitInStore(true));
    let id = event.target.id;
    let value = event.target.value;
    if (id === "chief-complaint") {
      setSoapNotes({ ...soapNotes, chiefComplaint: value });
    } else if (id === "history-of-present-illness") {
      setSoapNotes({ ...soapNotes, historyOfPresentIllness: value });
    } else if (id === "past-medication-history") {
      setSoapNotes({ ...soapNotes, pastMedicalHistory: value });
    } else if (id === "review-of-System") {
      setSoapNotes({ ...soapNotes, reviewOfSystem: value });
    } else if (id === "physical-exam") {
      setSoapNotes({ ...soapNotes, physicalExam: value });
    } else if (id === "assessment-note") {
      setSoapNotes({ ...soapNotes, assessmentNote: value });
    } else if (id === "plan-note") {
      setSoapNotes({ ...soapNotes, recommendationNote: value });
    }
  };

  const saveSoapNotes = async (isSigned) => {

    let soapObject = {
      providerTenantPracticeLocation: {
        provider: {
          userId: user.userId,
        },
      },
      patient: {
        userId: patientId,
      },
      isEncounterSigned: isSigned,
      encounter: {
        ...(encounterId && { encounterId: encounterId }),
        chiefComplaint: soapNotes.chiefComplaint,
        historyOfPresentIllness: soapNotes.historyOfPresentIllness,
        physicalExam: soapNotes.physicalExam,
        assessmentNote: soapNotes.assessmentNote,
        reviewOfSystem: soapNotes.reviewOfSystem,
        pastMedicalHistory: soapNotes.pastMedicalHistory,
        recommendationNote: soapNotes.recommendationNote,
      },
      patientChart: {
        vitals: vitals !== undefined ? [vitals] : [],
        diagnosis: diagnosesData,
      },
      labOrder: labOrdersData,
      medicationRx: prescriptionData?.medicationRx,
      pharmacy: {
        pharmacyOrganisationId: prescriptionData?.pharmacyOrganisationId,
      },
      ...(prescriptionReferenceId && {
        prescriptionReferenceId: prescriptionReferenceId,
      }),
      ...(appointmentId && { appointment: { id: appointmentId } }),
      ...(orderType && {
        workflowType:
          orderType === "ePrescription"
            ? "SURESCRIPTS"
            : orderType === "faxRx"
              ? "FAX"
              : "PRINT",
      }),
    };
    try {
      if (isSigned) { endVideoCallIfActive(isSigned); }
      const httpResponse = await sendHttpRequest({
        url: "/soap-note",
        method: "POST",
        data: soapObject,
      });
      if (httpResponse.status === 201 || httpResponse.status === 200) {
        if (unsavedChanges) {
          setUnsavedChanges(false);
          dispatch(setDirtyBitInStore(false));
        }
        if (isSigned) {
          setSoapNoteSnackbarMessage("SOAP Note signed successfully.");
        } else {
          setSoapNoteSnackbarMessage("SOAP Note saved successfully.");
        }
        setSoapNoteSnackbarSeverity("success");
        setOpenSoapNote(true);
        if (url !== "") {
          navigate(`/${url}`);
        }
        // if (isSigned) {
        //   navigate(`/all-patients/${patientId}`, {
        //     state: {
        //       // referer: "soap-note",
        //       patientId: `${patientId}`,
        //       selectedIndex: `${patientId}`,
        //       index: `${patientId}`,
        //     },

        //   });
        // }


        if(inactivePastDiagnosis?.length > 0) {

          const httpResponseDiagnosis = await sendHttpRequest({
            url: `/patients/${patientId}/diagnoses`,
            data: inactivePastDiagnosis,
            method: "PATCH",
          });
          if (!httpResponseDiagnosis.error) {
            console.log("Diagnosis updated successfully");
          }
          else {
            console.log("Diagnosis update failed");
          }
        }


      }
    } catch (error) {
      console.error(error);
    }


  };



  //=====New Prescription Modal Code START=====//
  const { open, handleOpen, handleClose } = useModal(false);
  const [isSent, setIsSent] = useState(false);
  const [isPrescriptionSent, setIsPrescriptionSent] = useState(Math.random());
  const handleSetIsSent = () => {
    setIsSent(true);
  };
  const [prescriptionData, setPrescriptionData] = useState();
  const [selectedPharmacy, setSelectedPharmacy] = useState();
  const [prescriptionDataCopy, setPrescriptionDataCopy] = useState();
  const [medEditMode, setMedEditMode] = useState(false);

  const [openSoapNoteSnackbar, soapNoteSnackbarMessage, soapNoteSnackbarSeverity, setOpenSoapNote, setSoapNoteSnackbarMessage, setSoapNoteSnackbarSeverity, handleSoapNoteSnackbarClose] = useSnackbar();


  //====Delete Medications Modal====//
  const {
    open: deleteMedicationModalOpen,
    handleModal: handleDeleteMedicationModal,
  } = useModal(false);
  const [
    deleteMedicationSnackbarOpen,
    deleteMedicationSnackbarMessage,
    deleteMedicationSnackbarSeverity,
    setDeleteMedicationSnackbarOpen,
    setDeleteMedicationSnackbarMessage,
    setDeleteMedicationSnackbarSeverity,
    handleDeleteMedicationSnackbarClose,
  ] = useSnackbar(false);
  const [areMedicationsDeleting, setAreMedicationsDeleting] = useState(false);

  //===== Lab Orders Modal====//
  const [openLabRequisitionModal, setOpenLabRequisitionModal] = useState(false);
  const {
    open: deleteLabOrdersModalOpen,
    handleModal: handleDeleteLabOrdersModal,
  } = useModal(false);
  const { open: openEditLabOrderModal, handleModal: handleEditLabOrderModal } =
    useModal();
  const [
    deleteLabOrdersSnackbarOpen,
    deleteLabOrdersSnackbarMessage,
    deleteLabordersSnackbarSeverity,
    setDeleteLabOrdersSnackbarOpen,
    setDeleteLabOrdersSnackbarMessage,
    setDeleteLabOrdersSnackbarSeverity,
    handleDeleteLabOrdersSnackbarClose,
  ] = useSnackbar(false);
  const [areLabOrdersDeleting, setAreLabOrdersDeleting] = useState(false);
  const [labOrdersData, setLabOrdersData] = useState();
  const [pastEncounterMedications, setPastEncounterMedications] = useState([]);

  const handleDeleteMedications = async () => {
    setPrescriptionData(undefined);
    handleDeleteMedicationModal();
  };
  const handleLabRequisitionModal = useCallback(() => {
    setOpenLabRequisitionModal((prevIsOpen) => !prevIsOpen);
  }, []);

  // ===========================Lab Order Deletion modal==============================
  const {
    open: openDeleteLabOrdModal,
    handleClose: handleCloseDeleteLabOrd,
    handleModal: handleDelLabOrdModal,
  } = useModal();
  const handleLabOrdersDelete = () => {
    setLabOrdersData(undefined);
    handleDeleteLabOrdersModal();
  };

  const downloadPDF = (data, pdfname) => {
    if (data !== undefined) {
      const link = document.createElement("a");
      link.target = "_blank";
      link.download = `${pdfname}`;
      const href = URL.createObjectURL(
        new Blob([data], {
          type: "application/pdf",
          encoding: "UTF-8",
        })
      );
      link.href = href;
      link.click();
      link.remove();
      URL.revokeObjectURL(href);
    }
  };
  // =================Print Lab Orders=================
  const printLabOrder = async () => {
    const httpResponse = await sendHttpRequest({
      url: "/lab-test-request/print",
      method: "POST",
      responseType: "arraybuffer",
      data: {
        prescriptionReference: {
          patient: { userId: patientId },
          providerTenantPracticeLocation: { provider: { userId: user.userId } },
        },
        ...labOrdersData,
      },
    });
    if (!httpResponse.error && httpResponse.data !== undefined) {
      downloadPDF(
        httpResponse.data,
        `${httpResponse?.responseHeaders?.pdf_name?.split("=")?.at(1)}`
      );
    }
  };

  //==================Print Medication ================
  const printMedications = async () => {
    const httpResponse = await sendHttpRequest({
      url: "/medicationRx/print",
      method: "POST",
      responseType: "arraybuffer",
      data: {
        medicationRx: prescriptionData.medicationRx,
        patientId: patientId,
        providerId: user.userId,
      },
    });
    if (!httpResponse.error && httpResponse.data !== undefined) {
      downloadPDF(httpResponse.data, "Medication_RX_");
    }
  };

  const handleEditMedications = () => {
    //  console.log("Called");
    if (prescriptionData !== undefined && prescriptionData?.medicationRx?.length > 0) {
      setPrescriptionDataCopy(m => prescriptionData);
    }

    let medicationList = [];
    for (let i = 0; i < prescriptionData?.medicationRx?.length; i++) {
      let medItem = prescriptionData?.medicationRx[i];
      // console.log("medItem: ",medItem);
      medicationList.push({
        DDID: medItem?.drugId ?? medItem?.DDID,
        drugDbCode: medItem?.drugId ?? medItem?.DDID,
        drugDescription: medItem?.drugDescription,
        ndc: medItem?.ndc,

        Sig: {
          SigText: medItem?.sigText ?? medItem?.Sig?.SigText,
        },
        sigText: medItem?.sigText ?? medItem?.Sig?.SigText,
        textValue: medItem?.sigText ?? medItem?.Sig?.SigText,
        Quantity:
          parseFloat(medItem?.quantity) ?? parseFloat(medItem?.Quantity),
        daySupply: medItem?.daysSupply ?? medItem?.daySupply,
        NumberOfRefills: medItem?.refills ?? medItem?.NumberOfRefills,
        RefillAsNeeded: false,
        DispenseAsWritten: medItem?.substitution ?? medItem?.DispenseAsWritten,
        // Note: medItem?.pharmacyNote !== undefined ? medItem?.pharmacyNote : "",
        Note: medItem?.pharmacyNote ?? medItem?.Note,
        DoseForm: medItem?.quantityUnitOfMeasure ?? medItem?.DoseForm,
        PrescriptionStartDate: {
          Date: calenderDateTimeHelper(
            medItem?.prescriptionStartDate ??
            medItem?.PrescriptionStartDate?.Date,
            "YYYY-MM-DD"
          ),
        },
        EarliestFillDate: {
          Date: calenderDateTimeHelper(
            medItem?.earliestFillDate ?? medItem?.EarliestFillDate?.Date,
            "YYYY-MM-DD"
          ),
        },
        WrittenDate: {
          Date: calenderDateTimeHelper(new Date(), "YYYY-MM-DD"),
        },
        alertPhysician72: medItem?.alertPhysician ?? medItem?.alertPhysician72,
        PatientDirections: "",
        flag: true,
        quantityUom: medItem?.quantityUnitOfMeasure ?? medItem?.quantityUom,
        quantityUnitOfMeasureCode: medItem?.quantityUnitOfMeasureCode,
        customSigFlag: medItem?.customSigFlag ?? false,
        drugOrderId: medItem?.customSigFlag === true ? "-1" : "",
        highDoseForm: medItem?.highDoseForm ?? "",
        highFrequency: medItem?.highFrequency ?? "",
      });
    }
    // console.log("medicationList: ",medicationList);
    if (prescriptionData !== undefined && medicationList?.length > 0) {
      setPrescriptionData({
        medicationRx: medicationList,
        pharmacyOrganisationId: prescriptionData?.pharmacyOrganisationId,
      });
      dispatch(updatePrescribedMedication([...medicationList]));

    }
    handleOpen();
  };
  // =================Load Past Encounters====================
  const makeMedicationString = (medicationList, recommendationNoteValue) => {
    let outputString = `${recommendationNoteValue?.length > 0 ? recommendationNoteValue + `\n\n\n\n\n` : ""} `;
    if (medicationList?.length > 0) {
      const createdAt = medicationList[0].createdAt;

      outputString = outputString + `Medications Prescribed ${calenderDateTimeHelper(createdAt, "MM/DD/YYYY")}:\n`;
      medicationList.forEach((medication, index) => {
        outputString += `- ${medication.drugDescription}\n`;
      });
    }

    return outputString ?? "";
  }
  const setStateValues = (data) => {
    let diagnosis = data?.patientChart?.diagnosis ?? [];
    let medicationRx = data?.medicationRx ?? [];
    let labOrder = data?.labOrder ?? undefined;
    let vitals = data?.patientChart?.vitals ?? [];
    if (vitals.length > 0) {
      delete vitals[0]["vitalId"];
    }
    diagnosis.forEach((obj) => {
      delete obj["diagnosisId"];
    });
    // console.log("medicationRx data->>>>>>>>>>>>>>>>: ",medicationRx);
    // console.log("Selected item data: ",data);
    setSoapNotes({
      chiefComplaint: data?.encounter?.chiefComplaint ?? "",
      historyOfPresentIllness: data?.encounter?.historyOfPresentIllness ?? "",
      physicalExam: data?.encounter?.physicalExam ?? "",
      assessmentNote: data?.encounter?.assessmentNote ?? "",
      reviewOfSystem: data?.encounter?.reviewOfSystem ?? "",
      pastMedicalHistory: data?.encounter?.pastMedicalHistory ?? "",
      recommendationNote: !isResume ? makeMedicationString(medicationRx, data?.encounter?.recommendationNote) ?? "" : data?.encounter?.recommendationNote ?? "",
    });
    if (vitals?.length > 0) {
      setVitals(...vitals);
    }
    setDiagnosesData([...diagnosis]);
    if (isResume) {
      setPrescriptionData({ medicationRx: [...medicationRx] });
    }
    setLabOrdersData(labOrder);
    setPastEncounterMedications([...medicationRx]);
    // makeMedicationString(medicationRx)
  };
  const fetchPastEncounters = async () => {
    setIsEncountersDataLoading(true);
    const httpResponse = await sendHttpRequest({
      url: fetchPastSOAPSURLS(patientId, user.userId),
      method: "GET",
      params: {
        prescriptionReferenceId: prescriptionReferenceId,
      },
    });
    if (!httpResponse.error) {
      let data = httpResponse.data;
      setEncountersData(data);
      if (isResume) {
        let singleEncounterData = await fetchEncounter();
        if (fieldValidation(singleEncounterData?.soapNote, "un")) {
          setStateValues(singleEncounterData);
        }
      }
      if (data?.length > 0) {
        if (!isResume) {
          setStateValues(data[0]);
        }
        setSelectedPastEcounter(data[0]);
        setIsPastEncChecked(true);
      }
    }
    setIsEncountersDataLoading(false);
  };

  const fetchEncounter = async () => {
    setIsEncountersDataLoading(true);
    // console.log("prescriptionReferenceId: ", prescriptionReferenceId);
    const httpResponse = await sendHttpRequest({
      url: encounterSummaryUrl(prescriptionReferenceId),
      method: "GET",
    });
    if (!httpResponse.error) {
      return httpResponse.data;
    }
    setIsEncountersDataLoading(false);
  };

  // function BottomNavigation(props) {
  //   return (
  //     <div className="position-fixed pb-4 mb-1 w-100 bottom-0 mx-auto bg-white bottom__nav--shadow">
  //       <LinearProgress
  //         variant="determinate"
  //         style={{
  //           backgroundColor: "#D9E8EC",
  //           height: "1px",
  //         }}
  //         value={100}
  //       />
  //       <div className="d-flex p-3 ">
  //         <div
  //           className="position-fixed "
  //           style={{
  //             left: "78%",
  //           }}
  //         >
  // <button
  //   className="btn  br-10 font-14 ms-2 fw-sb text--blue btn__bottom d9e8ec--button"
  //   style={{
  //     paddingLeft: "24px",
  //     paddingRight: "24px",
  //   }}
  //   onClick={() => {
  //     props.handleChange(false);
  //   }}
  //   hidden={props?.permissionsHiddenSave === false ? true : false}
  // >
  //   Save
  // </button>
  // <button
  //   className="btn ms-2 blue_task_button"
  //   onClick={() => {
  //     props.handleChange(true);
  //   }}
  //   hidden={props?.permissionsHiddenSign === false ? true : false}
  // >
  //   Sign
  // </button>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  function BottomNavigation(props) {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [status, setStatus] = useState(props?.status !== null ? props.status : null);


    if (user?.role === "MedicalAssistant") {
      async function makeAppointmentProviderReady(aptId, aptStatus) {
        const response = await InPatientAppointmentService.markAppointmentProviderReady(aptId, aptStatus);
        if (!response.error) {
          // navigate('/dashboard', {
          //   replace: true
          // })
        }
      }

      return (
        <div className="position-fixed row bg-white w-100 bottom-0 mx-auto bottom__nav--shadow">

          <LinearProgress variant="determinate" style={{
            backgroundColor: "#D9E8EC",
            height: "1px"
          }} value={props?.value} />


          <div className="col-12 p-3">
            <div className="row">
              <div className="col-2">
                <div className="form-group">
                  <select
                    className={`form-select font-13 shadow-none`}
                    aria-label="Provider select"
                    style={{
                      border: "1px solid #D9E8EC",
                      height: "36px",
                      width: "250px",
                      borderRadius: "10px",
                      // background: "#FAFCFC",
                    }}
                    required
                    onChange={async (e) => {
                      setStatus(e.target.value);
                      await makeAppointmentProviderReady(props?.appointmentId, e.target.value);
                    }}
                    value={status || props?.status}
                  >
                    <option value="" disabled selected hidden>
                      Select status
                    </option>


                    <option value="scheduled">Scheduled</option>
                    <option value="checkin">Check-in</option>
                    <option value="ma-reviewed">MA Review</option>
                    <option value="provider-ready">Provider Ready</option>
                    <option value="in-consultation">In Consultation</option>
                    <option value="consultation-completed">Consultation Complete</option>
                    <option value="departed">Visit Complete</option>
                    <option value="no-show">No-show</option>
                    <option value="canceled">Canceled</option>

                  </select>
                </div>
              </div>








              <div className="col-3 gap-2 offset-5 text-end">
                <button
                  className="btn  br-10 font-14 ms-2 fw-sb text--blue btn__bottom d9e8ec--button"
                  style={{
                    paddingLeft: "24px",
                    paddingRight: "24px",
                  }}
                  onClick={() => {
                    props.handleChange(false);
                  }}
                  hidden={props?.permissionsHiddenSave === false ? true : false}
                >
                  Save
                </button>
                <button
                  className="btn ms-2 blue_task_button"
                  onClick={() => {
                    props.handleChange(true);
                  }}
                  hidden={props?.permissionsHiddenSign === false ? true : false}
                >
                  Sign
                </button>

                {/* <span className={`text-danger ${isCheckInDisabled ? 'd-block' : 'd-none'} font-12 m-0 p-0`}>No Rooms Available</span> */}
              </div>

            </div>
          </div>



        </div>
      )
    }
    else {
      return (
        <div className="position-fixed row bg-white w-100 bottom-0 mx-auto bottom__nav--shadow">

          <LinearProgress variant="determinate" style={{
            backgroundColor: "#D9E8EC",
            height: "1px"
          }} value={props?.value} />


          <div className="col-12 p-3">
            <div className="row">
              <div className="col-2">

              </div>








              <div className="col-5 gap-2 offset-3 text-end">
                <button
                  className="btn  br-10 font-14 ms-2 fw-sb text--blue btn__bottom d9e8ec--button"
                  style={{
                    paddingLeft: "24px",
                    paddingRight: "24px",
                  }}
                  onClick={() => {
                    props.handleChange(false);
                  }}
                  hidden={props?.permissionsHiddenSave === false ? true : false}
                >
                  Save
                </button>
                <button
                  className="btn ms-2 blue_task_button"
                  onClick={() => {
                    props.handleChange(true);
                  }}
                  hidden={props?.permissionsHiddenSign === false ? true : false}
                >
                  Sign
                </button>

                {props?.appointmentId !== "" && props?.appointmentId !== undefined && user?.role === "Physician" ? <button className="btn ms-2 blue_task_button" onClick={() => { props?.handleEndEncounterConsultationModal() }}>End Consultation</button> : null}


                {/* <span className={`text-danger ${isCheckInDisabled ? 'd-block' : 'd-none'} font-12 m-0 p-0`}>No Rooms Available</span> */}
              </div>

            </div>
          </div>


          {/* <CustomizedSnackbar
          message={checkInSnackbarMessage}
          severity={checkInSnackbarSeverity}
          open={openCheckInSnackbar}
          handleClose={handleCheckInSnackbarClose}
        /> */}
        </div>
      );
    }

  }


  const handleSelectedPastEncounterData = (event) => {
    let prescriptionRefId = event.target.value;
    // console.log("prescriptionRefId: ", prescriptionRefId);
    // console.log("encountersData: ", encountersData[0]?.prescriptionReferenceId);
    let result = encountersData.filter((item) => {
      // console.log(item);
      return item?.prescriptionReferenceId === Number(prescriptionRefId);
    });
    // console.log("result: ", result);
    setSelectedPastEcounter(result[0]);
    // setStateValues(result[0]); 
  };

  function endVideoCallIfActive(isSigned) {
    if (videoCallContext.getIsVideoCallActive()) {
      let durationInSeconds = 15000;
      if (
        document.getElementById("minutes") !== undefined &&
        document.getElementById("minutes") !== null &&
        document.getElementById("seconds") !== undefined &&
        document.getElementById("seconds") !== null
      ) {
        try {
          durationInSeconds =
            Number(document.getElementById("minutes").innerText) * 60 +
            Number(document.getElementById("seconds").innerText);
          // setDuration(durationInSeconds ?? 15000);
        } catch (e) {
          console.error(e);
        }
      }
      videoCallContext.endSession().then(() =>
        sendHttpRequest({
          method: "POST",
          url: `/video/SessionBreakRequest`,
          data: {
            appiontmentId: videoCallContext.appointmentData.data,
            videoJoinRequestType: isSigned ? "END" : "LEAVE",
            callDuration: durationInSeconds,
          },
        })
      );

      dispatch(setConsulationInProgress(false));
      dispatch(setVideoOpen(false));
    }
  }
  const settingStateValues = (data) => {
    // setPrescriptionData(data.)
  };

  useEffect(() => {
    if (
      prescriptionData !== undefined ||
      labOrdersData !== undefined ||
      diagnosesData?.length > 0 ||
      vitals !== undefined
    ) {
      // setUnsavedChanges(true);
      // dispatch(setDirtyBitInStore(true));
    }

    return () => {
      setUnsavedChanges(false);
      dispatch(setDirtyBitInStore(false));
    };
  }, [prescriptionData, labOrdersData, diagnosesData, vitals]);

  useEffect(() => {
    fetchPatientPastDiagnosis();
    if (isPatientNotFound === false) {
      fetchPastEncounters();
    }
  }, []);
  // useEffect(() => {
  //   setSelectedPastEcounter("");
  // }, [isPastEncChecked]);

  return (
    isPatientNotFound === false ? <>
      <PageHeader title="SOAP Note" />
      <PatientDemographics patientId={patientId} setPatientDemographics={setPatientDemographics} isPatientNotFound={isPatientNotFound} setIsPatientNotFound={setIsPatientNotFound} />

      {showEndEncounterConsultationModal && appointmentId !== "" && appointmentId !== null ? <EndEncounterConsultationModal
        show={showEndEncounterConsultationModal}
        handleEndConsulationModal={handleEndEncounterConsultationModal}
        patientName={patientDemographics?.name?.at(0)?.firstName?.concat(" ", patientDemographics?.name?.at(0)?.lastName)}
        appointmentId={appointmentId}
      /> : null}
      <main className=" me-1" style={{ paddingBottom: "10rem" }}>
        <div
          className={`row ${!isPastEncChecked ? "justify-content-center" : ""}`}
        >
          <div className={`${isPastEncChecked ? "col-7 " : "col-8"} `}>
            <div
              className={`row justify-content-center   ${isPastEncChecked ? classes["soup__container"] : ""
                } ${!isPastEncChecked
                  ? `hide-scrolbar`
                  : `${classes["scroll__bar"]}`
                }`}
            >
              {/* {pageRef !== "patient" && encountersData.length > 0 && ( */}
              <div
                className={`col-md-3  offset-md-8 btn font-14 fw-sb text-end   ${encountersData.length === 0 ? "disabled" : ""
                  }`}
                hidden={true}
              >
                <p
                  className="cursor--pointer m-0 p-0 text--blue"
                  onClick={() => {
                    setIsPastEncChecked(!isPastEncChecked);
                  }}
                >
                  {"View past encounters".concat(
                    !isPastEncChecked ? " >>" : ""
                  )}
                </p>
              </div>
              {/* )} */}
              {/* Subjective Section */}
              <div className="col-11 g-0 ">
                <h3
                  className={`font-16 text--terciary ${classes["fieldset-title"]}`}
                >
                  {__str.subjective}
                </h3>
              </div>
              <div
                className={`col-11 g-0  br-10 border--default ${classes["p-1rem"]}`}
              >
                <div className="">
                  <span className="font-12 fw-sb">{__str.chiefcomplaint}</span>
                  <textarea
                    className="form-control shadow-none input br-10 border--default font-12 text--terciary"
                    rows={1}
                    id={"chief-complaint"}
                    defaultValue={soapNotes?.chiefComplaint ?? ""}
                    onChange={(e) => {
                      handleSoapNotes(e);
                    }}
                  />
                </div>
                <div className="mt-1">
                  <span className="font-12 fw-sb">
                    {__str.histor_of_Present_illness}
                  </span>
                  <textarea
                    className="form-control shadow-none input br-10 border--default font-12 text--terciary"
                    rows={2}
                    id={"history-of-present-illness"}
                    defaultValue={soapNotes?.historyOfPresentIllness ?? ""}
                    onChange={(e) => {
                      handleSoapNotes(e);
                    }}
                  />
                </div>
                <div className="mt-1">
                  <span className="font-12 fw-sb">
                    {__str.past_medical_history}
                  </span>
                  <textarea
                    className="form-control shadow-none input br-10 border--default font-12 text--terciary"
                    rows={2}
                    id={"past-medication-history"}
                    defaultValue={soapNotes?.pastMedicalHistory ?? ""}
                    onChange={(e) => {
                      handleSoapNotes(e);
                    }}
                  />
                </div>
                <div className="mt-1">
                  <span className="font-12 fw-sb">
                    {__str.review_of_systems}
                  </span>
                  <textarea
                    className="form-control shadow-none input br-10 border--default font-12 text--terciary"
                    rows={2}
                    id={"review-of-System"}
                    defaultValue={soapNotes?.reviewOfSystem ?? ""}
                    onChange={(e) => {
                      handleSoapNotes(e);
                    }}
                  />
                </div>
              </div>

              {/* Objective Section */}
              <div className="col-11 g-0 mt-3">
                <h3
                  className={`font-16 text--terciary ${classes["fieldset-title"]}`}
                >
                  {__str.objective}
                </h3>
              </div>
              <div
                className={`col-11 g-0  br-10 border--default ${classes["p-1rem"]}`}
              >
                <div className="">
                  {/* <span className="font-12 fw-sb">
                    {__str.physical_examination}
                  </span> */}
                  <textarea
                    className="form-control shadow-none input br-10 border--default font-12 text--terciary"
                    rows={2}
                    id={"physical-exam"}
                    defaultValue={soapNotes?.physicalExam ?? ""}
                    onChange={(e) => {
                      handleSoapNotes(e);
                    }}
                  />
                </div>
                {/* Vital Action button */}
                <div className="row mt-2">
                  <div className={`${isPastEncChecked ? "col-8" : "col-9"}`}>
                    &nbsp;
                  </div>
                  <div
                    className={`${isPastEncChecked ? "col-4" : "col-3"
                      } text-end`}
                  >
                    {/* <span className="d-block-inline ms-3">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </span> */}
                    <button
                      onClick={() => {
                        handleOpenVitalAddModal();
                      }}
                      className="btn pn-button-icon-blue  br-10 shadow-none lh-0 py-0 ps-0 pe-1 align-items-center fw-sb text--blue font-12"
                      hidden={subscriptionPrivilegeGroups[PATIENTS]?.apv?.active === false ? true : false}
                    >
                      <span className="pn-button-icon-blue pe-1">
                        <MedicalInformationIcon color="#336383" size={14} />
                      </span>
                      <span
                        className={`d-inline-block pn-link-button ${isPastEncChecked ? "pe-3" : "pe-3"
                          }`}
                        style={{ verticalAlign: "middle" }}
                      >
                        {__str.addVitals}
                      </span>
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                </div>
                {vitals !== undefined ? (
                  <div className="col-12">
                    <h3 className="font-12 text--terciary ">{__str.vitals}</h3>
                    <div>
                      <VitalsValues />
                    </div>
                  </div>
                ) : null}
                {/* <div className="col-8 border--default br-10 p-2">vitals here</div> */}
              </div>
              {/* Assessment  */}
              <div className="col-11 mt-3 g-0">
                <h3
                  className={`font-16 text--terciary ${classes["fieldset-title"]}`}
                >
                  {__str.assessment}
                </h3>
              </div>
              {/* Add Diagnosis */}
              <div
                className={`col-11 g-0  br-10 border--default ${classes["p-1rem"]}`}
              >
                <textarea
                  className="form-control shadow-none input border--default br-10 font-12 text--terciary"
                  rows={2}
                  id={"assessment-note"}
                  defaultValue={soapNotes?.assessmentNote ?? ""}
                  onChange={(e) => {
                    handleSoapNotes(e);
                  }}
                />
                <div className="row mt-2">
                  <div className={`${isPastEncChecked ? "col-8" : "col-9"}`}>
                    &nbsp;
                  </div>
                  <div
                    className={`${isPastEncChecked ? "col-4" : "col-3"
                      } text-end`}
                  >
                    <button
                      onClick={handleDiagnosisModal}
                      className="btn pn-button-icon-blue  br-10 shadow-none lh-0 py-0 ps-0 pe-1 align-items-center fw-sb text--blue font-12"
                      hidden={subscriptionPrivilegeGroups[ENCOUNTERS]?.adg?.active === false ? true : false}
                    >
                      <span className="pn-button-icon-blue pe-1">
                        <MedicalInformationIcon color="#336383" size={14} />{" "}
                      </span>
                      <span
                        className="d-inline-block pn-link-button"
                        style={{ verticalAlign: "middle" }}
                      >
                        {__str.addDiagnosis}
                      </span>
                    </button>
                  </div>
                </div>
                {diagnosesData?.length > 0 ? (
                  <div className="col-12 g-0 mt-3">
                    <h3 className="font-12 text--terciary ">
                      {__str.diagnosis}
                    </h3>
                    <div className="col-8 mt-2">
                      {/* Diagnosis values */}
                      <DiagnosisTableProviderNote
                        diagnoses={diagnosesData}
                        deleteDiagnosisConfirmation={handleDeleteDiagnosisModal}
                        handleEditDiagnosis={handleEditDiagnosisModal}
                      />
                    </div>
                  </div>
                ) : null}
                {pastDiagnosis?.length > 0 ? (
                  <div className="col-12 g-0 mt-3">
                    <h3 className="font-12 text--terciary ">
                      {__str.pastDiagnosis}
                    </h3>
                    <div className="col-8 mt-2">
                      {/* Diagnosis values */}
                      <DiagnosisTableProviderNote
                        diagnoses={pastDiagnosis}
                        deleteDiagnosisConfirmation={handleDeleteDiagnosisModal}
                        handleEditDiagnosis={handleEditDiagnosisModal}
                        isPastDiagnosis={true}
                        handleToggleInactive={handleToggleInactive}
                      />
                    </div>
                  </div>
                ) : null}
              </div>

              {/* Plan Section */}

              <div className="col-11 mt-3 g-0">
                <h3
                  className={`font-16 text--terciary ${classes["fieldset-title"]}`}
                >
                  {__str.plan}
                </h3>
              </div>
              <div
                className={`col-11 g-0  br-10 border--default ${classes["p-1rem"]}`}
              >
                <textarea
                  className="form-control shadow-none input border--default br-10 font-12 text--terciary"
                  rows={2}
                  id={"plan-note"}
                  defaultValue={soapNotes?.recommendationNote ?? ""}
                  onChange={(e) => {
                    handleSoapNotes(e);
                  }}
                />
                <div className="col-12 g-0 mt-3 ">
                  <div className="d-flex flex-row justify-content-end">
                    <div className="d-flex justify-content-end">
                      <button
                        onClick={() => {
                          handleEditMedications();
                        }}
                        className="btn pn-button-icon-blue  br-10 shadow-none lh-0 py-0 ps-0 pe-1 align-items-center fw-sb text--blue font-12"
                        hidden={subscriptionPrivilegeGroups[TASKS]?.orx?.active === false ? true : false}
                      >
                        <span className=" pn-button-icon-blue pe-1">
                          <MedicationIcon color="#336383" size={14} />{" "}
                        </span>
                        <span
                          className="d-inline-block pn-link-button"
                          style={{ verticalAlign: "middle" }}
                        >
                          Add Medication
                        </span>
                      </button>
                      <button
                        onClick={handleLabRequisitionModal}
                        className="btn pn-button-icon-blue  br-10 shadow-none lh-0 py-0 ps-0 pe-1 align-items-center fw-sb text--blue font-12"
                        hidden={subscriptionPrivilegeGroups[TASKS]?.olp?.active === false ? true : false}
                      >
                        <span className="pn-button-icon-blue pe-1">
                          <BiotechIcon color="#336383" size={14} />{" "}
                        </span>
                        <span
                          className="d-inline-block pn-link-button"
                          style={{ verticalAlign: "middle" }}
                        >
                          Add Lab Order
                        </span>
                      </button>
                    </div>
                  </div>
                </div>

                {/* Rendering Medications */}
                <div
                  className={`row  ${prescriptionData?.medicationRx?.length > 0 ? "pt-3" : ""
                    }`}
                >
                  <div className="col-sm-10">
                    {prescriptionData !== undefined &&
                      prescriptionData?.medicationRx !== undefined &&
                      prescriptionData?.medicationRx?.length > 0 && (
                        <div className="row">
                          <div className="col-sm-6">
                            <h2 className="font-14 fw-sb text--terciary">
                              Medications Ordered
                            </h2>
                          </div>
                          <div className="col-sm-6 d-flex gap-1">
                            <div
                              className="cursor--pointer"
                              onClick={() => {
                                handleEditMedications();
                              }}
                            >
                              <PnToolTip title={"Edit Medications"}>
                                <img
                                  src={EditButtonIcon}
                                  alt={"editIcon"}
                                  id="medication-edi"
                                  style={{ width: "15px", height: "15px" }}
                                ></img>
                              </PnToolTip>
                            </div>

                            <div
                              className="cursor--pointer"
                              onClick={() => printMedications()}
                            >
                              <PnToolTip title={"Download"}>
                                <FileDownloadIcon
                                  sx={{
                                    color: "#336383",
                                    fontWeight: 400,
                                    fontSize: "1.3rem",
                                  }}
                                />
                              </PnToolTip>
                            </div>
                            <div
                              onClick={handleDeleteMedicationModal}
                              className="cursor--pointer"
                            >
                              <PnToolTip title={"Delete Medications"}>
                                <img
                                  src={TrashButtonIcon}
                                  alt={"deleteIcon"}
                                  id="medication-delete"
                                  style={{ width: "15px", height: "15px" }}
                                ></img>
                              </PnToolTip>
                            </div>
                          </div>
                        </div>
                      )}
                    {prescriptionData !== undefined &&
                      prescriptionData?.medicationRx !== undefined &&
                      prescriptionData?.medicationRx?.length > 0 &&
                      prescriptionData?.medicationRx?.map(
                        (medication, index) => {
                          return (
                            <div className="d-flex justify-content-between">
                              <div
                                key={medication?.drugDbCode}
                                className="font-12 py-1 text--terciary"
                              >
                                {medication?.drugDescription.concat(
                                  " - ",
                                  medication?.sigText
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>
                  <div className="col-4"></div>
                </div>

                {/* Render Lab orders */}

                <div
                  className={`row   ${labOrdersData?.labOrderTest?.length > 0 ? "py-3" : ""
                    }`}
                  hidden={subscriptionPrivilegeGroups[TASKS]?.dlp?.active === false ? true : false}
                >
                  <div className="col-sm-10">
                    {labOrdersData?.labOrderTest?.length > 0 && (
                      <div className="row">
                        <div className="col-sm-6">
                          <h2 className="font-14 fw-sb text--terciary">
                            Labs Ordered
                          </h2>
                        </div>
                        <div className="col-sm-6 d-flex flex-row gap-1">
                          <div
                            className="cursor--pointer"
                            onClick={() => {
                              handleEditLabOrderModal();
                            }}
                          >
                            <PnToolTip title="Edit Lab Order">
                              <img
                                src={EditButtonIcon}
                                alt={"editIcon"}
                                id="laborder-edit"
                                style={{ width: "15px", height: "15px" }}
                              ></img>
                            </PnToolTip>
                          </div>

                          <div
                            className="cursor--pointer"
                            onClick={() => printLabOrder()}
                          >
                            <PnToolTip title={"Download"}>
                              <FileDownloadIcon
                                sx={{
                                  color: "#336383",
                                  fontWeight: 400,
                                  fontSize: "1.3rem",
                                }}
                              />
                            </PnToolTip>
                          </div>
                          <div
                            onClick={handleDeleteLabOrdersModal}
                            className="cursor--pointer"
                          >
                            <PnToolTip title={"Delete Lab Order"}>
                              <img
                                src={TrashButtonIcon}
                                alt={"deleteIcon"}
                                id="laborder-delete"
                                style={{ width: "15px", height: "15px" }}
                              ></img>
                            </PnToolTip>
                          </div>
                        </div>
                      </div>
                    )}
                    {labOrdersData?.labOrderTest?.map((test, index) => {
                      return (
                        <div className="font-12 py-1 text--terciary">
                          {test.description.concat(
                            " ",
                            test?.alias1?.length > 0 ? `(${test?.alias1})` : ""
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <div className="col-4"></div>
                </div>
              </div>

              {/* Save Button */}
              {/* <div className="col-11 text-end mt-3 g-0 mb-3 pb-3">
                <button
                  className="btn blue_task_button"
                  onClick={() => {
                    saveSoapNotes();
                  }}
                  disabled={!unsavedChanges}
                >
                  {"Sign"}
                </button>
              </div> */}
            </div>
          </div>
          {isPastEncChecked ? (
            <div className="col-5 ">
              <div className="row mt-2 px-2">
                <div className="col-sm-12 text-end font-14" hidden={true}>
                  {encountersData?.length === 0 ? (
                    <X className="font--18 text--disabled" style={{ width: "28px", height: "28px" }} />
                  ) : (
                    <X
                      className="icon__defualt font--18 text--secondary"
                      onClick={() => {
                        setIsPastEncChecked(!isPastEncChecked);
                      }}
                      style={{ width: "28px", height: "28px" }}
                    />
                  )}{" "}
                </div>
                <div className="col-sm-12 mt-1">
                  <select
                    className={`form-select font-12  cursor--pointer border--default br-10 shadow-none ${classes["custom-select"]
                      } ${encountersData.length === 0 && "bg-disabled"}`}
                    aria-label="Appointment type select"
                    style={{
                      height: "40px",
                    }}
                    required
                    onChange={(e) => {
                      handleSelectedPastEncounterData(e);
                    }}
                    disabled={encountersData.length === 0}
                  >
                    <option selected hidden disabled>
                      {"Select encounter"}
                    </option>
                    {encountersData.length > 0 &&
                      encountersData.map((item) => {
                        return (
                          <option
                            value={item?.prescriptionReferenceId}
                            id={item?.prescriptionReferenceId}
                          >
                            {dateTimeHelper(
                              item?.createdDate,
                              "MMM DD, YYYY LT"
                            ).concat(" - ", item?.encounter?.chiefComplaint)}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className={`row p-2 ps-4 `}>
                <div
                  className={`col-sm-12 ${classes["scroll__bar"]} ${classes["soup__container"]}`}
                >
                  {selectedPastEcounter && (
                    <PatientSoapSummary
                      selectedPastEcounter={selectedPastEcounter}
                    />
                  )}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </main>
      {
        // console.log("Soap Notes: ",soapNotes)
      }

      <BottomNavigation
        handleChange={saveSoapNotes}
        permissionsHiddenSave={subscriptionPrivilegeGroups[ENCOUNTERS]?.ssn?.active}
        permissionsHiddenSign={subscriptionPrivilegeGroups[ENCOUNTERS]?.sns?.active}
        appointmentId={location.state?.appointmentId}
        value={100}
        status={status}
        setStatus={setStatus}
        handleEndEncounterConsultationModal={handleEndEncounterConsultationModal}
      />

      {openVitalAddModal && (
        <AddVitalsModal
          show={openVitalAddModal}
          handleModal={handleCloseVitalModal}
          setVitals={setVitals}
          handleClose={handleCloseVitalModal}
          appointmentId={location.state?.appointmentId}

        />
      )}
      {openDiagnosisModal ? (
        <DiagnosisModal
          show={openDiagnosisModal}
          handleModal={handleDiagnosisModal}
          prescriptionReference={undefined}
          setDiagnosisAddedMessage={setDiagnosisAddedMessage}
          setDiagnosisSeverity={setDiagnosisSeverity}
          setOpenDiagnosisSnackBar={setOpenDiagnosisSnackBar}
          setCallApi={setCallApi}
          setDiagnosesData={setDiagnosesData}
          diagnosesData={diagnosesData}
          soapNoteKey={true}
          currentActiveDiagnosis={pastDiagnosis}
        />
      ) : null}

      {openDeleteDiagnosisModal ? (
        <DeleteDiagnosisModal
          show={openDeleteDiagnosisModal}
          handleModal={handleDeleteDiagnosisModal}
          deletedDiagnosis={deletedDiagnosis}
          handleDelete={deleteDiagnosis}
          setCallApi={setCallApi}
          deleteDiagnosisLoading={deleteDiagnosisLoading}
          setDiagnosesData={setDiagnosesData}
          diagnosesData={diagnosesData}
          soapNoteKey={true}
        />
      ) : null}

      {openEditDiagnosisModal ? (
        <EditDiagnosisModal
          show={openEditDiagnosisModal}
          handleModal={handleEditDiagnosisModal}
          editDiagnosis={editedDiagnosis}
          handleEditDiagnosis={handleEditDiagnosisModal}
          setCallApi={setCallApi}
          updateDiagnosis={updateDiagnosis}
          isDiagnosisUpdating={isDiagnosisUpdating}
          setDiagnosesData={setDiagnosesData}
          soapNoteKey={true}
          diagnosesData={diagnosesData}
        />
      ) : null}
      {open ? (
        <NewPrescriptionModal
          patientId={patientId}
          show={open}
          handleSetIsSent={handleSetIsSent}
          handleNewPrescriptionModal={handleClose}
          setCallApi={setCallApi}
          referenceFrom={referenceFrom.current}
          // prescriptionReference={prescriptionReference}
          setPrescriptionData={setPrescriptionData}
          prescriptionData={prescriptionData}
          setSelectedPharmacy={setSelectedPharmacy}
          selectedPharmacy={selectedPharmacy}
          soapNoteKey={true}
          setOrderType={setOrderType}
          vitals={vitals}
          prescriptionDataCopy={prescriptionDataCopy}
          setMedEditMode={setMedEditMode}
          prescriptionRequestModal={
            <PrescriptionRequests
              encounterId={patientId}
              patientId={patientId}
              handleNewPrescriptionModal={handleClose}
              // prescriptionReference={prescriptionReference}
              handleSetIsSent={handleSetIsSent}
              newPrescriptionRequest={true}
              referenceFrom={referenceFrom.current}
              setIsPrescriptionSent={setIsPrescriptionSent}
              setCallApi={setCallApi}
              setPrescriptionData={setPrescriptionData}
              prescriptionData={prescriptionData}
              setSelectedPharmacy={setSelectedPharmacy}
              selectedPharmacy={selectedPharmacy}
              soapNoteKey={true}
              setOrderType={setOrderType}
              vitals={vitals}
              prescriptionDataCopy={prescriptionDataCopy}
              setMedEditMode={setMedEditMode}
            />
          }
        />
      ) : null}

      {openLabRequisitionModal ? (
        <LabRequisitionFormModal
          show={openLabRequisitionModal}
          handleModal={handleLabRequisitionModal}
          patientId={patientId}
          assessmentPlan={true}
          //   setMessage={setMessage}
          //   setSeverity={setSeverity}
          //   handleLabOrderSnackBar={handleLabOrderSnackBar}
          //   setCallApi={setCallApi}
          //   prescriptionReference={prescriptionReference}
          referenceFrom={referenceFrom.current}
          soapNote={true}
          setLabOrdersData={setLabOrdersData}
        />
      ) : null}
      {openEditLabOrderModal ? (
        <LabRequisitionFormModal
          show={openEditLabOrderModal}
          handleModal={handleEditLabOrderModal}
          patientId={patientId}
          assessmentPlan={true}
          editLabOrderData={labOrdersData}
          referenceFrom={referenceFrom.current}
          setLabOrdersData={setLabOrdersData}
          soapNote={true}
        />
      ) : null}
      {deleteLabOrdersModalOpen ? (
        <DeleteLabOrderConfirmationModal
          handleModal={handleDeleteLabOrdersModal}
          show={deleteLabOrdersModalOpen}
          handleDelete={handleLabOrdersDelete}
          soapNoteKey={true}
        // labOrderId={prescriptionReferenceData?.labOrder?.labOrderId}
        // areLabOrdersDeleting={areLabOrdersDeleting}
        />
      ) : null}
      {deleteMedicationModalOpen ? (
        <DeleteLabOrderConfirmationModal
          show={deleteMedicationModalOpen}
          handleModal={handleDeleteMedicationModal}
          handleDelete={handleDeleteMedications}
          soapNoteKey={true}
          deleteMedication={true}
        // labOrderId={prescriptionReferenceData?.labOrder?.labOrderId}
        // areLabOrdersDeleting={areLabOrdersDeleting}
        />
      ) : null}

      {openSoapNoteSnackbar ? (
        <CustomizedSnackbar
          message={soapNoteSnackbarMessage}
          severity={soapNoteSnackbarSeverity}
          open={openSoapNoteSnackbar}
          handleClose={handleSoapNoteSnackbarClose}
        />
      ) : null}

      {/* {console.log("Diagnosis data: ", diagnosesData)} */}
      {/* {console.log("prescriptionData : ", prescriptionData)}
      {console.log("prescriptionDataCopy : ", prescriptionDataCopy)}
      {console.log("medEditMode : ", medEditMode)} */}
      {/* {console.log("Lab orders data: ", labOrdersData)} */}
      {/* {console.log("Selected pharmacy: ",selectedPharmacy)} */}
      {/* {console.log("Selected past encounter data : ", selectedPastEcounter)} */}
      {/* {console.log("orderType: ", orderType)} */}
      {/* {console.log("pastEncounterMedications: ",pastEncounterMedications)}
      {console.log(soapNotes?.recommendationNote)} */}
      {/* {console.log("isResume:",isResume )} */}
    </> : <NotFoundMessage message={"Patient not found"} />
  );
};

export default PatientSoapNotePage;

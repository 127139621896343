import React, { useContext, useRef, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useDispatch } from "react-redux";
import sendHttpRequest from "../hooks/sendHttpRequest";
import { useNavigate } from "react-router-dom";

import { setConsulationInProgress, setPrescriberInfo, setVideoCall } from "../../redux/actions/providerActions";
import { useAuth } from "../hooks/useAuth";
import { VideoCallContext } from "../UI/AgoraHooks/VideoCallContext";
import { validateNullUndefined } from "../../utils/helpers/HelperFunctions";
import useOnClickOutside from "../hooks/useOnClickOutside";
import classes from "./Dropdown.module.css";
// import placeholder from "./../../assets/images/placeholder.jpg"

const PatientDropdown = (props) => {
  const { user, signout } = useAuth();
  const [chevronIcon, setChevronIcon] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  // const [isLoading, setIsLoading] = useState(true)
  ;
  const videoCallContext = useContext(VideoCallContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleOpen = () => setIsOpen(!isOpen);
  // console.log(props)
  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, () => setIsOpen(p => false));

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <span style={{ color: "#EC609B" }}>
        {/* &#x25bc; */}
        {chevronIcon === true ? (
          <KeyboardArrowDownIcon />
        ) : (
          <KeyboardArrowUpIcon />
        )}
      </span>
    </span>
  ));



  const logout = async () => {
    if (validateNullUndefined(videoCallContext.getIsVideoCallActive()) && videoCallContext.getIsVideoCallActive() && validateNullUndefined(videoCallContext.getVideoTrack()) && validateNullUndefined(videoCallContext.getAudioTrack())) {
      dispatch(setConsulationInProgress(false));
      dispatch(setVideoCall(false));
      const r = await videoCallContext.endSession();
    }
    else if (validateNullUndefined(videoCallContext.getIsVideoCallActive) && videoCallContext.getIsVideoCallActive()) {
      await videoCallContext.releaseMedia();
    }


    // setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/auth/logout",
      method: "POST",
      data: {},
    });
    if (!httpResponse.error) {
      dispatch(
        setPrescriberInfo({
          prescriberId: 0,
          selectedPrescriberId: 0,
          selectedPrescriberName: {
            FirstName: "",
            LastName: "",
          },
          selectedPrescriberRole: "",
          Name: {
            FirstName: "",
            LastName: "",
          },
          profilePicture: "",
          role: "",
        })
      );

      signout();

      // removeCookie("providerId");
      // localStorage.removeItem("sessionexp");
      // localStorage.removeItem("timezone");
      // localStorage.removeItem("forgetPassword");
      // localStorage.removeItem("role");
      // navigate("/", { replace: true });

      // console.log(httpResponse.data);
    } else {
      // console.log(httpResponse.error);
    }
    // setIsLoading(false);
  };

  function logMeOut() {
    logout();
  }

  const menuClass = `dropdown-menu notifications-list ${classes["patient__dropdown__menue__items"]
    }  ${isOpen ? "show" : ""}`;

  return (
    <>
      <div
        ref={dropdownRef}
        className="dropdown dropleft cursor--pointer"
        onClick={() => toggleOpen()}
        style={{ minWidth: 85 }}
      >
        {/* <Dropdown.Toggle as={CustomToggle}> */}
        <div>
          <div className="d-flex">
            <div>
              <div>
                <span
                  className="b2 fw-sb d-block"
                  style={{ marginBottom: "-4px" }}
                >
                  {user?.prefix === undefined ? "" : user?.prefix}{" "}
                  {user?.lastName === undefined ? "" : user?.lastName}
                </span>
                <span className="b3 d-block">
                  {user?.tenant?.businessName}
                </span>
              </div>
            </div>
            <div className="align-self-center ps-2">
              <span style={{ color: "#EC609B" }}>
                {isOpen === true ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </span>
            </div>
          </div>
          {/* <> */}
        </div>
        <div
          className={menuClass}
          style={{ borderTop: "2px solid #EC609B", opacity: 1, zIndex: 1200 }}
        >
          <Dropdown.Item
            eventKey="3"
            className=""
            onClick={() => {
              if (props?.patientRegistrationHeader === true) {

                navigate(`/my-account`)
              }
              else {

                navigate(`/patient-portal/account`)
              }

            }}
          >
            My Account
          </Dropdown.Item>
          <Dropdown.Item eventKey="2" className="" onClick={logMeOut}>
            Logout
          </Dropdown.Item>
        </div>
      </div>
    </>
  );
};
export default PatientDropdown;

import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import PNLogo from "../../assets/logo/LPLogo.svg";
import LoginImage from "../../assets/images/loginImage.PNG";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import classes from "./Login.module.css";
import { useState, useEffect } from "react";
import sendHttpRequest from "../hooks/sendHttpRequest";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setPrescriberInfo } from "../../redux/actions/providerActions";
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";


const theme = createTheme();

export default function EmailForForgetPasswordPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const postEmail = async () => {
    setDisableButton(true);
    const httpResponse = await sendHttpRequest({
      url: "/auth/forgetPassword",
      method: "POST",
      data: {
        username: email,
      },
    });
    if (!httpResponse.error) {
      localStorage.setItem("forgetPassword", true);
      setTimeout(() => {
        navigate("/");
      }, 6000);
    } else {
      // setErrorResponse(httpResponse.errorMessage.error);
    }
    // localStorage.setItem("authLink", '/auth/forgetPassword/stepTwo');
    setIsEmailSent(true);
    setDisableButton(false);
  };

  const handleEmailInput = (event) => {
    setEmail(event.target.value);
  };

  const handleLoginButton = () => {
    postEmail();
  };

  // const handlePasswordChangeToastClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setIsPasswordChanged(false);
  // };'

  useEffect(() => {
    localStorage.removeItem("sessionexp");
    localStorage.removeItem("timezone");
  }, [])


  return (
    <>
      <ThemeProvider theme={theme}>
        <form
          id="getEmailForm"
          onSubmit={(e) => {
            e.preventDefault();
            handleLoginButton();
          }}
        >
          <Grid container component="main" sx={{ height: "100vh" }}>
            <CssBaseline />

            <Grid
              item
              xs={12}
              sm={8}
              md={6}
              component={Paper}
              sx={{
                backgroundImage: "linear-gradient(180deg, #FFFFFF, #F2F7F8)",
              }}
              elevation={6}
              square
            >
              <Box
                sx={{
                  mx: 7,
                  my: 5,
                }}
              >
                <img src={PNLogo} width={260} height={55} />
              </Box>
              <Box
                sx={{
                  my: 8,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  px: 20,
                }}
              >
                <Typography
                  component="h1"
                  className="text--terciary"
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "700",
                    fontSize: "22px",
                    lineHeight: "50px",
                    mb: 2,
                  }}
                >
                  Enter your email
                </Typography>
                <Box component="div" noValidate sx={{ mt: 1 }}>
                  <div className="form-group mb-2">
                    <label
                      htmlFor="password"
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "12px",
                        color: "#336383",
                      }}
                      className="text--terciary font-12"
                    >
                      Email
                    </label>
                    <input
                      className="form-control fw-sb font-14 shadow-none br-10 input"
                      type="email"
                      required
                      autoFocus={true}
                      onChange={(e) => {
                        handleEmailInput(e);
                      }}
                    />
                  </div>
                  <div>
                    <p className="text--blue font-12">
                      {isEmailSent === true &&
                        "If there is a user account associated with this email address, you will receive instructions on resetting your password shortly."}
                    </p>
                  </div>

                  <div className="d-grid gap-2">
                    <button
                      form="getEmailForm"
                      type="submit"
                      disabled={disableButton}
                      className={`btn br-10 shadow-none bg--blue mt-4 py-2 text-center btn-block text-white ${classes.loginBtn}`}
                    >
                      {disableButton ? "Submitting..." : "Submit"}
                    </button>
                    {disableButton && (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: "#EC609B",
                          position: "absolute",
                          marginTop: "30px",
                          marginLeft: "165px",
                        }}
                      />
                    )}
                  </div>
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              xs={false}
              sm={4}
              md={6}
              sx={{
                backgroundImage: `url(${LoginImage})`,
                backgroundRepeat: "no-repeat",
                backgroundColor: (t) =>
                  t.palette.mode === "light"
                    ? t.palette.grey[50]
                    : t.palette.grey[900],
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          </Grid>
        </form>
        {/* <CustomizedSnackbars
          message={"Password changed successfully"}
          severity="success"
          open={isPasswordChanged}
          handleClose={handlePasswordChangeToastClose}
        /> */}
      </ThemeProvider>
    </>
  );
}

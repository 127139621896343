import { DateTime } from "luxon";
import CalenderDatePicker from "../UI/General/CalenderDatePicker";
import PhoneNumberInput from "../UI/Reusable/PhoneNumberInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import { formatPhoneNumber } from "../../utils/helpers/HelperFunctions";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setDirtyBitInStore } from "../../redux/actions/providerActions";

const PatientReferral = (props) => {
  const [regexErrors, setRegexErrors] = useState({
    landline: false,
    primary: false,
    emergency: false,
    zipcode: false,
  });
  const dispatch = useDispatch();
  const [patientReferral, setPatientReferral] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    npi: "",
    phone: "",
    referralDate: "",
    dateOfVisit: "",
  });

  const handlePhysicianPhone = (phoneValue) => {
    if (
      phoneValue !== undefined &&
      isValidPhoneNumber(phoneValue?.toString()) &&
      phoneValue?.length == 12
    ) {
      // console.log("Valed Part");
      let mobileNumber = formatPhoneNumber(phoneValue?.replace("+1", ""));
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: false,
      }));
      dispatch(setDirtyBitInStore(true));
      setPatientReferral({...patientReferral,phone:mobileNumber});
    } else if (
      phoneValue !== undefined &&
      !isValidPhoneNumber(phoneValue?.toString()) &&
      phoneValue?.length > 2
    ) {
      dispatch(setDirtyBitInStore(true));
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: true,
      }));
      setPatientReferral({...patientReferral,phone:""});
    } else if (phoneValue?.length === 2 || phoneValue === undefined) {
      // dispatch(setDirtyBitInStore(true));
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: false,
      }));
      setPatientReferral({...patientReferral,phone:""});
    }
  };

  const handleInput = (e) => {
    e.preventDefault();

    let name = e.target.name;
    let value = e.target.value;

    setPatientReferral({...patientReferral,[name]:value});
  };

  const referralDate = (dateVal) => {
    setPatientReferral({...patientReferral,referralDate:dateVal});
  };
  const visitDate = (dateVal) => {
    
    setPatientReferral({...patientReferral,dateOfVisit:dateVal});
  };


  const handleSubmit = ()=>{
    // props?.setPatientJson((p)=> {return {...props?.patientJson,patientReferral:patientReferral}});
    props?.saveData({patientReferral:patientReferral});
  }

  return (
    <>
      <form
        id="referralForm"
        onSubmit={(e)=>{
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="row">
          <div className="col-4">
            <label
              htmlFor={"firstName"}
              className="form-label m-0 font-12 text--secondary fw-sb"
            >
              First Name<span>&nbsp;&#42;</span>
            </label>
            <input
              type="text"
              name="firstName"
              id={"firstName"}
              className={`form-control font-12 m-0 border--default br-10 shadow-none input`}
              onChange={handleInput}
              required
              maxLength={35}
            />
          </div>
          <div className="col-4">
            <label
              htmlFor={"middleName"}
              className="form-label m-0 font-12 text--secondary fw-sb"
            >
              Middle Name
            </label>
            <input
              type="text"
              name="middleName"
              id={"physician-name"}
              className={`form-control font-12 m-0 border--default br-10 shadow-none input`}
              onChange={handleInput}
              maxLength={35}
            />
          </div>
          <div className="col-4">
            <label
              htmlFor={"lastName"}
              className="form-label m-0 font-12 text--secondary fw-sb"
            >
              Last Name<span>&nbsp;&#42;</span>
            </label>
            <input
              type="text"
              name="lastName"
              id={"lastName"}
              className={`form-control font-12 m-0 border--default br-10 shadow-none input`}
              onChange={handleInput}
              required
              maxLength={35}
            />
          </div>
          <div className="col-6">
            <label
              htmlFor={"npi"}
              className="form-label m-0 font-12 text--secondary fw-sb"
            >
              Physician NPI<span>&nbsp;&#42;</span>
            </label>
            <input
              type="text"
              name="npi"
              id={"npi"}
              className={`form-control font-12 m-0 border--default br-10 shadow-none input`}
              onChange={handleInput}
              required
              maxLength={10}
            />
          </div>
          <div className="col-6">
            <label
              htmlFor="physicianPhone"
              className="form-label m-0 font-12 text--secondary fw-sb"
            >
              Phone<span>&nbsp;&#42;</span>
            </label>

            <PhoneNumberInput
              name="physicianPhone"
              fontClass="font-12"
              value={""}
              onChange={handlePhysicianPhone}
              id={"physicianPhone"}
              required={true}
            />
            <span className="text--danger py-1 font-10 d-block">
              {regexErrors?.primary === true ? "Incorrect number format" : null}
            </span>
          </div>
          <div className="col-6">
            <label
              htmlFor="referralDate"
              className="form-label m-0 font-12 text--secondary fw-sb"
            >
              Referral Date <span>&#42;</span>
            </label>
            <CalenderDatePicker
              name="referralDate"
              label="Referral Date"
              // date={props?.patientJson.dob !== "" ? props?.patientJson.dob : ""}
              required={true}
              dobHandler={referralDate}
              // disableFuture={true}
              // maxDate={DateTime.now()}
            />
          </div>
          <div className="col-6">
            <label
              htmlFor="dateOfVisit"
              className="form-label m-0 font-12 text--secondary fw-sb"
            >
              Date of Visit <span>&#42;</span>
            </label>
            <CalenderDatePicker
              name="dateOfVisit"
              label="dateOfVisit"
              // date={props?.patientJson.dob !== "" ? props?.patientJson.dob : ""}
              required={true}
              dobHandler={visitDate}
              // disableFuture={true}
              // maxDate={DateTime.now()}
            />
          </div>
          <div className="col-12 text-end mt-3 mb-3">
            <button
              form="referralForm"
              className={`btn blue_task_button bg--blue fw-sb font-14 text-white btn-lg b2 px-5 br-10 py-1`}
              type="submit"
              disabled ={regexErrors?.primary || props?.patientJson?.diagnoses === undefined || props?.patientJson?.appointment === undefined}
            >
              {"Submit"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default PatientReferral;

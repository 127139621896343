import { useState } from "react";
import { useAuth } from "../../components/hooks/useAuth";
import ImagingOrderTable from "../../components/patient/ImagingOrderTable";
import PageHeader from "../generic/PageHeader";
const PatientImagingOrder = () => {
    const {user} = useAuth();
    const [isImagingOrderSent,setIsImagingOrderSent] = useState(false);
  return (
    <>
      <PageHeader title={"Imaging Order"} />
      <div className="container-fluid bg--background ">
        <div className="row justify-content-center ">
          <div className={`mt-5 my-5 col-sm-11 pb-5`}>
            <ImagingOrderTable
                patientPortal={true}
              patientId={user.userId}
              isImagingOrderSent={isImagingOrderSent}
              setIsImagingOrderSent={setIsImagingOrderSent}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientImagingOrder;

import { useEffect, useState } from "react";
import { patientVitalsHistoryUrl } from "../../res/endpointurls";
import { useAuth } from "../../components/hooks/useAuth";
import sendHttpRequest from "../../components/hooks/sendHttpRequest";
import { Skeleton, Stack } from "@mui/material";
import { changeDateTimeFormat, dateTimeHelper } from "../../utils/dateTimeHelper";
import classes from "./PatientHealthSummary.module.css";
import PageHeader from "../generic/PageHeader";
import { summaryGeneration } from "../../utils/helpers/DataLoaders";
import { AllergySummaryComponent, formatPhoneNumber } from "../../utils/helpers/HelperFunctions";
import { numbersEnum } from "../../res/strings";
const PatientHealthSummary = (props) => {
    const { user } = useAuth();
    const [appointment, setAppointment] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [summary, setSummary] = useState(null);
    const [showSummaryModal, setShowSummaryModal] = useState(false);
    const [isSummaryLoading, setIsSummaryLoading] = useState(false);
    const [openSummaryBackdrop, setOpenSummaryBackdrop] = useState(false);
    const [prescriptionReferenceId,setPrescriptionReferenceId] = useState()
    const [primaryDiagnosis,setPrimaryDiagnosis] = useState()
    const [secondaryDiagnoses,setSecondaryDiagnoses] = useState()
  
    const handleBackdropClose = () => {
      setOpenSummaryBackdrop(false);
    };
  

//   const fetchVitalsHistory = async (encounterId, patientId) => {
//     setIsVitalHistoryLoading(true);
//     const httpResponse = await sendHttpRequest({
//       url: `/patient/${user.userId}/vitals`,
//       method: "GET",
//     });
//     if (!httpResponse.error) {
//       setVitalHistory(httpResponse.data);

//       // setVitalHistory(httpResponse.data);
//     } else {
//       console.log("VitalsL ", httpResponse.error);
//     }
//     setIsVitalHistoryLoading(false);
//   };


const fetchPastAppointments = async () => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/appointment/patient/past/completed",
      method: "GET",
      params: {
        patientId: user?.userId,
        pageSize:1,
        // sortOrder: "currentDateFirst"
      }
    });
    if (!httpResponse.error) {
        if(httpResponse.data?.length > 0){
            let prfId = httpResponse.data[0]?.prescriptionReference?.prescriptionReferenceId; 
            let data = httpResponse.data[0];
            setPrescriptionReferenceId(prfId);
            generateEncounterSummary(prfId);
            setPrimaryDiagnosis(data?.prescriptionReference?.patientChart?.diagnosis?.length > 0 ? data?.prescriptionReference?.patientChart?.diagnosis?.filter(diagnosis => diagnosis?.type?.toLowerCase() === 'p')[0] : '');
            setSecondaryDiagnoses(data?.prescriptionReference?.patientChart?.diagnosis?.length > 0 ? data?.prescriptionReference?.patientChart?.diagnosis?.filter(diagnosis => diagnosis?.type?.toLowerCase() === 's') : '');
        }
      console.log(httpResponse.data);
    }
    setIsLoading(false);
  };

  const generateEncounterSummary = async (encId) => {
    const data = await summaryGeneration(encId);
    setSummary(data);
    

  
    };

  useEffect(() => {
    fetchPastAppointments();
    // fetchVitalsHistory();
  }, []);

  return (
    <>
        <PageHeader title={"Health Summary"}/>
        <div className="row justify-content-center mt-3 mb-3">
            <div className="col-8">
                {isLoading ? 
                    <Skeleton/>:
                    <div className='container br-10 px-2 py-2' style={{ border: '2px solid #d9e8ec', overflowY: 'auto', overflowX: 'hidden' }}>
                        <div className='row'>
                        <div className="col-sm-12">
                            <div className='d-flex justify-content-between'>
                            <h3 className='font-16 fw-sb text--terciary'>
                                Patient Information
                            </h3>
                            </div>

                            <Stack direction="row" spacing={6}>
                            <Stack direction="column" item>
                                <h6 className="font-14 m-0 text--terciary">Name</h6>
                                <p className='font-12 m-0'>{summary?.patient?.name[0]?.firstName ?? ""} {summary?.patient?.name[0]?.lastName ?? ""}</p>
                                {/* <p className='font-12 m-0'>{props?.encounter?.status !== 'CLOSED' ? props?.encounter?.HumanPatient?.Name?.FirstName ?? "" : props?.encounter?.Patient?.Name?.length > 0 ? props?.encounter?.Patient?.Name[0]?.FirstName : "" ?? ""} {props?.encounter?.status !== 'CLOSED' ? props?.encounter?.HumanPatient?.Name?.LastName ?? "No data available" : props?.encounter?.Patient?.Name?.length > 0 ? props?.encounter?.Patient?.Name[0].LastName : "No data available" ?? "No data available"}</p> */}
                                {/* <p className='font-12 m-0'>{props?.encounter?.status !== 'CLOSED' && props?.encounter?.status !== 'DISPATCHED' ? props?.encounter?.HumanPatient?.Name?.FirstName ?? "" : props?.encounter?.Patient?.Name?.length > 0 ? props?.encounter?.Patient?.Name[0]?.FirstName : "" ?? ""} {props?.encounter?.status !== 'CLOSED' && props?.encounter?.status !== 'DISPATCHED' ? props?.encounter?.HumanPatient?.Name?.LastName ?? "No data available" : props?.encounter?.Patient?.Name?.length > 0 ? props?.encounter?.Patient?.Name[0].LastName : "No data available" ?? "No data available"}</p> */}
                            </Stack>
                            <Stack direction="column" item>
                                <h6 className="font-14 m-0 text--terciary">Date of Birth</h6>

                                <p className='font-12 m-0'>{changeDateTimeFormat(summary?.patient?.dateOfBirth ?? null, "MMM D, YYYY") ?? "No data available"}</p>
                            </Stack>
                            <Stack direction="column" item>
                                <h6 className="font-14 m-0 text--terciary">Biological Sex</h6>
                                <p className='font-12 m-0'>{summary?.patient?.gender ?? ""} </p>
                            </Stack>
                            </Stack>

                            <div>
                            <h6 className='font-14 m-0'>Address</h6>
                            <p className='font-12 m-0'>
                                {summary?.patient?.address[0]?.addressLine1 ?? ""}{" "}
                                {summary?.patient?.address[0]?.city?.trim() ?? "" ?? " "}
                                &nbsp;
                                {summary?.patient?.address[0]?.stateProvince ?? ""}{" "}
                                {summary?.patient?.address[0]?.postalCode ?? ""}
                            </p>
                            </div>

                            <div>
                            <h6 className='font-14 m-0'>Phone</h6>
                            <p className='font-12 m-0'>
                                {formatPhoneNumber(summary?.patient?.communicationNumbers?.numbers?.filter(number => number?.type?.toLowerCase() === numbersEnum.en.cellPhoneExt?.toLowerCase())[0]?.number) ?? ""}
                            </p>
                            </div>

                            <div hidden={summary?.patient?.communicationNumbers?.emergencyContact?.find((numberItem) => { return numberItem?.phoneNumber !== "" }) === undefined}>
                            <h6 className='font-14 m-0'>Emergency Contact Number</h6>
                            <p className='font-12 m-0'>
                                {formatPhoneNumber(summary?.patient?.communicationNumbers?.emergencyContact?.find((numberItem) => { return numberItem?.phoneNumber !== "" })?.phoneNumber || "") ?? ""}
                            </p>
                            </div>

                            <div>
                            <h6 className='font-14 m-0'>Encounter Date</h6>
                            <p className='font-12 m-0'>
                                {summary?.encounter?.encounterCreatedDate ? dateTimeHelper(summary?.encounter?.encounterCreatedDate, "MMM D, YYYY") : ""}
                            </p>
                            </div>


                        </div>
                        </div>
                        <hr />

                        <div className='row'>
                        <div className="col-sm-12">
                            <div className='d-flex justify-content-between'>
                            <h3 className='font-16 fw-sb text--terciary'>
                                Reason for Visit
                            </h3>

                            </div>

                            <div>
                            <h6 className='font-14 m-0'>Chief Complaint</h6>
                            <p className='font-12 m-0'>{summary?.encounter?.chiefComplaint ?? ""}</p>
                            </div>

                            <div>
                            <h6 className='font-14 m-0'>Note</h6>
                            <p className='font-12 m-0'>{summary?.encounter?.notes ?? ""} </p>
                            </div>


                        </div>
                        </div>
                        <hr />


                        <div className='row'>
                        <div className="col-sm-12">
                            <div className='d-flex justify-content-between'>
                            <h3 className='font-16 fw-sb text--terciary'>
                                Allergies
                            </h3>

                            </div>

                            <div>
                            {summary?.allergies !== undefined && summary?.allergies?.length > 0 ? summary?.allergies?.map((row, index) => {
                                        return (
                                            <AllergySummaryComponent allergy={row} />
                                        );
                                }): ""}
                            </div>

                        </div>
                        </div>
                        <hr />
                        <div className='row'>
                        <div className="col-sm-12">
                            <div className='d-flex justify-content-between'>
                            <h3 className='font-16 fw-sb text--terciary'>
                                Diagnosis
                            </h3>

                            </div>

                            <div>
                            <p className='font-12 m-0'>
                                Primary Diagnosis <br />
                                Code : {(primaryDiagnosis?.code) ?? (primaryDiagnosis?.code !== undefined && Object.keys(primaryDiagnosis)?.length > 0 ? primaryDiagnosis.code ?? "No data available" : "No data available")},
                                Description: {(primaryDiagnosis?.description) ?? (primaryDiagnosis?.description !== undefined && primaryDiagnosis?.description !== null ? primaryDiagnosis?.description ?? "No data available" : "No data available")} <br />
                                Secondary Diagnosis<br />
                                {
                                secondaryDiagnoses !== ''
                                    ?
                                    (secondaryDiagnoses?.length > 0 ? secondaryDiagnoses?.map((item, index) => {
                                    return (
                                        <>
                                        Code : {item?.code ?? "No data available"}, Description: {item?.description ?? "No data available"} <br />
                                        </>
                                    )

                                    }) : '')
                                    :
                                    "No data available"
                                }
                                {secondaryDiagnoses?.length <= 0 || secondaryDiagnoses === undefined ? <br /> : ""}
                                Note: {primaryDiagnosis?.note ?? primaryDiagnosis?.note ?? "No data available"}
                            </p>
                            </div>

                        </div>
                        </div>
                        <hr />

                        <div className='row'>
                        <div className="col-sm-12">
                            <div className='d-flex justify-content-between'>
                            <h3 className='font-16 fw-sb text--terciary'>
                                Treatment Plan
                            </h3>

                            </div>

                            <div>

                            <p className='font-12 m-0'>


                                {
                                summary?.medicationRx !== undefined ?
                                    summary?.medicationRx?.length > 0 ? summary?.medicationRx?.map((item, index) => {
                                    return (
                                        <>
                                        Medicine: {item?.drugDescription ?? "No data available"}, Sig: {item?.sigText ?? "No data available"} <br />
                                        </>
                                    )

                                    }) : "No data available" : "No data available"

                                }
                            </p>
                            <p className='font-12 m-0'>
                                Assessment Note: {summary?.encounter?.assessmentNote ?? "No data available"}
                            </p>
                            <p className='font-12 m-0'>
                                Recommendation Note: {summary?.encounter?.recommendationNote ?? "No data available"}
                            </p>
                            </div>

                        </div>
                        </div>
                        <hr />



                        <div className='row' hidden={summary?.encounter?.status?.toLowerCase() === 'closed' ? false : true}>
                        <div className="col-sm-12">
                            <div className='d-flex justify-content-between'>
                            <h3 className='font-16 fw-sb text--terciary'>
                                {"Signed by"}
                            </h3>
                            </div>
                            <div>
                            <p className='font-12 m-0'>
                                {summary?.encounter?.signedBy?.name[0]?.firstName} {summary?.encounter?.signedBy?.name[0]?.lastName}{", "} {summary?.encounter?.signedBy?.qualification} {"on "} {summary?.encounter?.signedDateTime !== null && summary?.encounter?.signedDateTime !== undefined ? dateTimeHelper(summary?.encounter?.signedDateTime, 'MMM D, YYYY LT') : "No data available"}
                            </p>
                            </div>
                        </div>
                        </div>

                        <hr hidden={summary?.encounter?.status?.toLowerCase() === 'closed' ? false : true} />



                    </div>
                }
            </div>
        </div>
      
    </>
  );
};

export default PatientHealthSummary;

import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";

import { Modal } from "react-bootstrap";
import sendHttpRequest from "../../../hooks/sendHttpRequest";
import { useDispatch, useSelector } from "react-redux";
import {
  dateTimeHelper,
  getAgeByDateOfBirth,
} from "../../../../utils/dateTimeHelper";
import CalendarIcon from "../../Drawer/DrawerItemIcons/CalendarIcon";
// import classes from "../../General/Stats.module.css";
import classes from "./PendingEncountersModal.module.css";
import {
  setDiagnosis,
  setDisableSendRxButton,
  setPatientEncounterData,
  setPrescriptionNoteText,
  setUpdateEncounterData,
  updatePrescribedMedication,
} from "../../../../redux/actions/providerActions";
import { Link, useLocation, useNavigate } from "react-router-dom";
import IconChevronRight from "../../../../assets/icons/Icon-Chevron-Right.svg";
import { truncate } from "../../../../utils/helpers/HelperFunctions";
import { PnToolTip } from "../../Reusable/PnComponents";
import { strings } from "../../../../res/strings";
import { ENCOUNTERS } from "../../../../utils/helpers/subscriptionsConstants";

const PendingEncountersModal = (props) => {
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isEncounterLoading, setIsEncounterLoading] = useState(false);
  const [encounter, setEncounter] = useState("");
  const [isLoadingEnc, setIsLoadingEnc] = useState(false);
  const __str = strings.en;

  // console.log(props);

  const fetchPatientEncounter = async (encId) => {
    setIsLoadingEnc(false);
    const httpResponse = await sendHttpRequest({
      url: `/prescription-reference/${encId}`,
      method: "GET",
    });
    if (!httpResponse.error) {
      let appointmentType = httpResponse.data?.appointment?.appointmentType;
      setEncounter(httpResponse.data);

      props?.handleBackdropClose();
      handlePatientEncouterData(httpResponse.data);
      console.log("httpResponse.data: ", httpResponse.data);
      if (appointmentType === "FOLLOW_UP") {
        navigate(
          `/all-patients/${httpResponse.data?.patient?.userId}/soap-note`, { state: { isResume: true, prescriptionReferenceId: httpResponse?.data?.prescriptionReferenceId }, replace: false }
        )
      } else {
        navigate(
          `/consult/${httpResponse.data?.providerTenantPracticeLocation?.provider?.userId}/${encId}/${httpResponse.data?.patient?.userId}`, { state: { prescriptionReferenceId: httpResponse?.data?.prescriptionReferenceId } });
      }
    } else {
      console.log(httpResponse.error);
    }
    setIsLoadingEnc(true);
  };

  const handleEncounterData = (encId) => {
    fetchPatientEncounter(encId);
  };

  const handleEncData = (event) => {
    // console.log(event.target.id);
    props?.handleToggle();
    handleEncounterData(event.target.id);

    // if (isLoadingEnc === true) {
    //   handleBackdropClose();
    //   handlePatientEncouterData(encounter);
    //   navigate(
    //     `/consult/${encounter?.Provider?.id}/${encounter?.Id}/${encounter?.Patient?.id}`
    //   );
    // }
  };

  const handlePatientEncouterData = (encounter) => {
    if (encounter?.RxNote !== undefined) {
      dispatch(setPrescriptionNoteText(encounter?.RxNote));
    } else {
      dispatch(setPrescriptionNoteText(""));
    }
    console.log("encounter?.Diagnosis", encounter?.Diagnosis);
    let diagnosis = {
      Note: "",
      Secondary: [],
      Primary: [],
    };
    if (
      encounter?.Diagnosis?.Note !== undefined &&
      encounter?.Diagnosis?.Note !== null
    ) {
      diagnosis.Note = encounter?.Diagnosis?.Note;
    }
    if (
      encounter?.Diagnosis?.Secondary !== undefined &&
      encounter?.Diagnosis?.Secondary?.length > 0
    ) {
      diagnosis.Secondary = encounter?.Diagnosis?.Secondary;
    }
    if (encounter?.Diagnosis?.Primary !== undefined) {
      if (Object.keys(encounter?.Diagnosis?.Primary).length !== 0) {
        diagnosis.Primary = [encounter?.Diagnosis?.Primary];
      }
    }
    dispatch(setUpdateEncounterData({ Diagnosis: diagnosis }));
    dispatch(setDiagnosis(diagnosis));
    dispatch(setPatientEncounterData({ Encounter: encounter }));
    if (encounter.Status === "DISPATCHED") {
      dispatch(setDisableSendRxButton(true));
    } else {
      dispatch(setDisableSendRxButton(false));
    }

    // dispatch(setDiagnosis(diagnosis));
    // dispatch(setVideoCallState(false));
  };

  //   const handleBackdropClose = () => {
  //     setOpenBackdrop(false);
  //   };
  //   const handleToggle = () => {
  //     setOpenBackdrop(!openBackdrop);
  //   };

  useEffect(() => { }, []);
  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          props.handleModal();
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        backdropClassName="modal-backdrop"
        contentClassName="modal-border modal-shadow"
        // dialogClassName={classes.root}
        scrollable
        centered
      >
        <Modal.Header className="modal-header">
          <span
            style={{
              lineHeight: "12px",
              width: "18px",
              fontSize: "8pt",
              color: "#336383",
              position: "absolute",
              top: 15,
              right: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              props?.handleModal();
            }}
          >
            {<CloseIcon />}
          </span>
          {/* <Modal.Title className="font-20 fw-sb">Pending encounters */}

          {/* </Modal.Title> */}
          <div className="d-flex">
            <div
              className={`bg--lightBlue text--secondary my-auto p-3`}
              style={{
                // height: "60px",
                // width: "60px",
                borderRadius: "20px",
              }}
            >
              <div>
                <CalendarIcon
                  style={{
                    color: "#004FD41A",
                    fill: "#004FD4",
                    width: "24px",
                    height: "24px",
                  }}
                  className="mx-auto  d-block mt-50"
                />
              </div>
            </div>

            <div className="text--terciary font-18 fw-sb align-self-center ms-2">
              <div>Pending</div>
              <div>Encounters</div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="row mt-3 justify-content-center mb-5">
            <div className="col-sm-11 font-14 text-end">
              <Link
                to="/encounter"
                state={{ from: location }}
                className="fw-sb text-blue font-12"
              >
                View all
              </Link>
            </div>
            <div className="col-sm-11">

              <Grid
                container
                sx={{ marginBottom: "-8px" }}
                // p={1}
                className={`sticky-top bg-white ${classes["row__head"]} ${props?.pendingEncounters?.length === 0 ? classes['border--bottom'] : ""}`}
              >
                <Grid item xs={1.88}>
                  {/* <Stack direction="row" alignItems="center" spacing={2}> */}
                  <Typography component="div">
                    <Box
                      sx={{
                        textAlign: "left",
                        m: 1,
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#336383",
                      }}
                    >
                      Date & Time
                    </Box>
                  </Typography>
                  {/* </Stack> */}
                </Grid>
                <Grid item xs={3}>
                  <Typography component="div">
                    <Box
                      sx={{
                        textAlign: "left",
                        mt: 1,
                        ml: 1,
                        // mr: 4,
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#336383",
                      }}
                    >
                      Name
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography component="div">
                    <Box
                      sx={{
                        textAlign: "left",
                        mt: 1,
                        // ml: 2,
                        fontFamily: "Montserrat",
                        fontSize: "12px",
                        color: "#336383",
                      }}
                    >
                      Chief Complaint
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography component="div">
                    <Box
                      sx={{
                        textAlign: "right",
                        marginTop: "8px",
                        // ml: 4,
                        fontFamily: "Montserrat",
                        fontSize: "12px",
                        color: "#336383",
                      }}
                    ></Box>
                  </Typography>
                </Grid>
              </Grid>

              {isEncounterLoading ? (
                <Skeleton />
              ) : (
                props?.pendingEncounters?.length > 0 ?
                  props?.pendingEncounters?.map((encounterItem, index) => {
                    //   if (index < 3) {
                    return (
                      <Grid
                        key={index}
                        sx={{ marginBottom: "-8px" }}
                        container
                        p={1}
                        alignItems="center"
                        className={`${classes["row--border"]} mt-2  ${index % 2 === 0 ? "bg--background" : ""
                          } ${index === props?.pendingEncounters?.length - 1
                            ? classes["border--bottom"]
                            : ""
                          } `}
                      >
                        <Grid item xs={1.88}>
                          <Typography component="div">
                            <Box
                              sx={{
                                textAlign: "left",
                                // m: 1,
                                fontFamily: "Montserrat",
                                fontSize: "12px",
                                color: "#336383",
                              }}
                            >
                              {dateTimeHelper(encounterItem?.encounter?.encounterCreatedDate, "MMM D, YYYY")}<br />
                              {dateTimeHelper(encounterItem?.encounter?.encounterCreatedDate, "LT")}
                            </Box>
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography component="div">
                            <Box
                              sx={{
                                // textAlign: "left",
                                // m: 1,
                                fontFamily: "Montserrat",
                                fontSize: "12px",
                                color: "#336383",
                              }}
                            >
                              <div>
                                <PnToolTip title={encounterItem?.patient?.name[0]?.firstName + " " + encounterItem?.patient?.name[0]?.lastName}>
                                  <div className="text--terciary fw-sb">
                                    {truncate(encounterItem?.patient?.name[0]
                                      ?.firstName +
                                      " " +
                                      encounterItem?.patient?.name[0]
                                        ?.lastName, 18)}
                                  </div>
                                </PnToolTip>
                                <div>
                                  {encounterItem?.patient?.gender +
                                    " " +
                                    getAgeByDateOfBirth(
                                      encounterItem?.patient
                                        ?.dateOfBirth
                                    ) +
                                    " years"}
                                </div>
                              </div>
                            </Box>
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography component="div">
                            <Box
                              sx={{
                                textAlign: "left",
                                // m: 1,
                                fontFamily: "Montserrat",
                                fontSize: "12px",
                                color: "#336383",
                              }}
                            >
                              {encounterItem?.encounter?.chiefComplaint}
                            </Box>
                          </Typography>
                        </Grid>
                        <Grid item xs={1} hidden={subscriptionPrivilegeGroups[ENCOUNTERS]?.rpe?.active === false ? true : false}>
                          <Typography component="div">
                            <Box
                              className="bg--background "
                              sx={{
                                textAlign: "center",
                                //   m: 1,
                                fontFamily: "Montserrat",
                                fontSize: "12px",
                                color: "#336383",
                              }}
                            >
                              <div
                                style={{
                                  borderRadius: "50px",
                                  height: "32px",
                                  width: "32px",
                                }}
                                className={
                                  "bg--disabled align-self-center my-auto"
                                }
                              >
                                <div
                                  className="p-1 d-block align-self-center my-auto font-12"
                                  style={{ cursor: "pointer" }}
                                  id={encounterItem?.prescriptionReferenceId}
                                  onClick={(e) => handleEncData(e)}
                                >
                                  <img
                                    src={IconChevronRight}
                                    alt={"naviagte"}
                                    id={encounterItem?.prescriptionReferenceId}
                                    style={{
                                      height: "16px",
                                      width: "16px",
                                      fontWeight: "600",
                                    }}
                                  ></img>

                                  {/* <
                                    style={{ fontSize: "12px" }}
                                    className={"mt-1"}
                                  /> */}
                                </div>
                              </div>
                            </Box>
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                    //   }
                  })
                  :
                  <div className="font-14 text--terciary p-2">{__str.noDataAvailable}</div>
              )}
            </div>
          </div>
        </Modal.Body>

        {/* <Modal.Footer>
          <button
            className="btn bg--blue text-white font-14 px-4 br-10 py-1 shadow-none"
            onClick={() => {
              props.handleModal();
            }}
            style={{
              border: "#004FD4 0% 0% no-repeat padding-box",
              width: "160px",
              height: "44px",
            }}
          >
            Close
          </button>
        </Modal.Footer> */}
      </Modal>
      {/* {console.log("monCount",monCount)} */}
      {/* {console.log("mondaySlotsList",mondaySlotsList)} */}
    </>
  );
};

export default PendingEncountersModal;

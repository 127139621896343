import { createStore } from "redux"
import reducers from "./reducers/index"
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Configure the persistence options
const persistConfig = {
  key: 'root', // Key under which the state will be stored in localStorage
  storage, // Use the default storage mechanism (localStorage)
  blacklist: ['isConsultationInProgress', 'isVideoCall', 'isVideoOpen']
};

// Create the persisted reducer
const persistedReducer = persistReducer(persistConfig, reducers);

// const store = createStore(reducers,{})
// Create the store
const store = createStore(persistedReducer);
const persistor = persistStore(store);

export { store, persistor };


// export default store;


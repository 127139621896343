import { Box, Grid, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import sendHttpRequest from "../../components/hooks/sendHttpRequest";
import { useAuth } from "../../components/hooks/useAuth";
import PatientNavbar from "../../components/UI/PatientNavbar";
import { dateTimeHelper } from "../../utils/dateTimeHelper";
import RefillRequestModal from "../patientmodals/RefillRequestModal";
import classes from "./../patientpages/PatientPrescriptionPage.module.css";
import { RefreshDataComponent } from "../../components/UI/General/RefreshDataComponent";
import { PnToolTip } from "../../components/UI/Reusable/PnComponents";
import InfoIcon from '@mui/icons-material/Info';
import { ChevronLeft } from "@mui/icons-material";
import PageHeader from "../generic/PageHeader";
import { fieldValidation, formatPhoneNumber, formatPostalCode, truncate } from "../../utils/helpers/HelperFunctions";

const PatientPrescriptionsPage = (props) => {
  const { user } = useAuth();
  const [refillModal, setRefillModal] = useState(false);
  const [patientRefillsList, setPatientRefillsList] = useState([]);
  const [isPatientRefillsLoading, setIsPatientRefillsLoading] = useState(true);
  const [refillRequestObj, setRefillRequestObj] = useState();

  const fecthPatientRefills = async () => {
    setIsPatientRefillsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/patient/${user.userId}/orders`,
      method: "GET",
      params:{
        isPatientPortal:true,
      }
    });
    if (!httpResponse.error) {
      setPatientRefillsList(httpResponse.data);
    } else {
      console.log(httpResponse.error);
      setPatientRefillsList([]);
    }
    setIsPatientRefillsLoading(false);
  };

  const refillModalHandler = () => {
    setRefillModal(true);
  };

  const refillModalClose = () => {
    setRefillModal(false);
  };
  const handleRefill = (event) => {
    let eid = event.target.id;
    // console.log(eid);
    let result = patientRefillsList.filter((refillItem) => {
      return refillItem.medicationRxId == eid;
    });
    console.log("result", result);
    if (result.length === 1) {
      setRefillRequestObj(result[0]);
      refillModalHandler();
    }
  };
  // function dateTimeformat(datetime){
  //   let indexOfTime = datetime.indexOf("M");
  //   let result  = `${datetime.slice(0,indexOfTime-1)} &nbsp; ${datetime.slice(indexOfTime-1,indexOfTime+1)}`;
  //   return result;
  // }

  // console.log(patientRefillsList);
  useEffect(() => {
    fecthPatientRefills();
  }, []);
  return (
    <div>
      {/* <PatientNavbar /> */}
      {refillModal && (
        <RefillRequestModal
          show={refillModal}
          handleRefillRequestModalClose={refillModalClose}
          refillRequestObj={refillRequestObj}
          fecthPatientRefills={fecthPatientRefills}
        />
      )}


      <PageHeader title="Prescriptions" />
      <div className="container-fluid">

        <div className="row mt-3 justify-content-center mb-5">
          <div className="col-12 text-end pe-3">
            <RefreshDataComponent
              callback={fecthPatientRefills}
              isComponentDataLoading={isPatientRefillsLoading}
              variant=""
            />
          </div>
          <div className="col-12 p-3">
            <Grid
              container
              sx={{ marginBottom: "-8px" }}
              p={2}
              className={` bg-white ${classes["row__head"]} `}
            >
              <Grid item xs={1}>
                {/* <Stack direction="row" alignItems="center" spacing={2}> */}
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      // m: 1,
                      mt: 1,
                      fontFamily: "Montserrat",
                      fontWeight: "400",
                      fontSize: "12px",
                      color: "#336383",
                    }}
                  >
                    Date &amp; Time
                  </Box>
                </Typography>
                {/* </Stack> */}
              </Grid>
              <Grid item xs={2.5}>
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      mt: 1,
                      // ml: 1,
                      // mr: 4,
                      fontFamily: "Montserrat",
                      fontWeight: "400",
                      fontSize: "12px",
                      color: "#336383",
                    }}
                  >
                    Medication
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      mt: 1,
                      ml: 1,
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      color: "#336383",
                    }}
                  >
                    Refills
                  </Box>
                </Typography>
              </Grid>
              {/* <Grid item xs={1}>
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      mt: 1,
                      ml: 1,
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      color: "#336383",
                    }}
                  >
                    Last Refill
                  </Box>
                </Typography>
              </Grid> */}
              <Grid item xs={1.7}>
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      marginTop: "8px",
                      // ml: 5,
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      color: "#336383",
                    }}
                  >
                    Prescribed by
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      mt: 1,
                      // ml: 4,
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      color: "#336383",
                    }}
                  >
                    Pharmacy
                  </Box>
                </Typography>
              </Grid>

              <Grid item xs={1}>
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      mt: 1,
                      ml: 1,
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      color: "#336383",
                    }}
                  >
                    Status
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={1.5}>
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      mt: 1,
                      // ml: 4,
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      color: "#336383",
                    }}
                  ></Box>
                </Typography>
              </Grid>
            </Grid>

            {isPatientRefillsLoading ? (
              <Skeleton />
            ) : (
              patientRefillsList !== undefined &&
              patientRefillsList?.length > 0 &&
              patientRefillsList.map((refillItem, index) => {
                let pharmacyContactInfo = " ";
                let pharmacyInfo =
                  // refillItem?..pharmacy?.standardizedOrganizationName +
                  // ", " +
                  refillItem?.pharmacy?.standardizedAddressLine1 +
                  ", " +
                  refillItem?.pharmacy?.standardizedCity?.trim();

                if (refillItem?.pharmacy?.stateProvince !== undefined) {
                  pharmacyInfo += ", " + refillItem?.pharmacy?.stateProvince;
                }
                if (
                  refillItem?.pharmacy?.standardizedPostalCode !== undefined
                ) {
                  pharmacyInfo +=
                    ", " + formatPostalCode(refillItem?.pharmacy?.standardizedPostalCode);
                }
                if (fieldValidation(refillItem?.pharmacy
                  ?.primaryTelephone, "un")) {
                    pharmacyContactInfo += "Ph: " +
                    formatPhoneNumber(
                      refillItem?.pharmacy
                      ?.primaryTelephone
                      )
                }
                if (fieldValidation(refillItem?.pharmacy
                  ?.fax, "un")) {
                    pharmacyContactInfo += "  Fax: " +
                    formatPhoneNumber(
                      refillItem?.pharmacy
                      ?.fax
                      )
                }
                return (
                  <Grid
                    sx={{ marginBottom: "-8px" }}
                    container
                    key={refillItem?.Id}
                    p={1}
                    alignItems="center"
                    className={`${classes["row--border"]} mt-2  ${
                      index % 2 === 0 ? "bg--background" : ""
                    }
                    ${index === 0 ? classes["row--border--upper"] : ""} ${
                      classes["border--bottom"]
                    }`}
                  >
                    <Grid item xs={1}>
                      <Typography component="div">
                        <Box
                          sx={{
                            textAlign: "left",
                            ml: 1,
                            fontFamily: "Montserrat",
                            fontSize: "12px",
                            color: "#336383",
                          }}
                        >
                          {/* Mar 11, 2022 11:20 am */}
                          <span>
                            {refillItem?.writtenDate !== undefined &&
                            refillItem?.writtenDate !== ""
                              ? dateTimeHelper(
                                  refillItem?.updateAt,
                                  "MMM D, YYYY"
                                )
                              : ""}
                              <br/>
                            {refillItem?.writtenDate !== undefined &&
                            refillItem?.writtenDate !== ""
                              ? dateTimeHelper(
                                  refillItem?.updateAt,
                                  "LT"
                                )
                              : ""}
                          </span>
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item xs={2.5}>
                      <Typography component="div">
                        <Box
                          sx={{
                            textAlign: "left",

                            fontFamily: "Montserrat",
                            fontSize: "12px",
                            color: "#323232",
                            // marginBottom:"-7px"
                          }}
                        >
                          {/* Lexapro Oral Tablet 10mg */}
                          {refillItem?.drugDescription}
                        </Box>
                        <Box
                          sx={{
                            textAlign: "left",

                            fontFamily: "Montserrat",
                            fontSize: "12px",
                            color: "#336383",
                          }}
                        >
                          {/* Take 2 tablets by mouth daily */}
                          {refillItem?.sigText}
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography component="div">
                        <Box
                          sx={{
                            textAlign: "left",
                            m: 1,
                            fontFamily: "Montserrat",
                            fontSize: "12px",
                            color: "#336383",
                          }}
                        >
                          {refillItem?.refills !== undefined
                            ? refillItem?.refills
                            : "0"}{" "}
                          {" refills"}
                        </Box>
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={1}>
                      <Typography component="div">
                        <Box
                          sx={{
                            textAlign: "left",
                            m: 1,
                            fontFamily: "Montserrat",
                            fontSize: "13px",
                            color: "#336383",
                          }}
                        >
                          {refillItem?.lastRefillDate !== undefined
                            ? dateTimeHelper(
                              refillItem?.lastRefillDate,
                                "MMM D, YYYY LT"
                              )
                            : ""}
                        </Box>
                      </Typography>
                    </Grid> */}
                    <Grid item xs={1.7}>
                      <Typography component="div">
                        <Box
                          sx={{
                            textAlign: "left",
                            // m: 1,
                            fontFamily: "Montserrat",
                            fontSize: "12px",
                            color: "#336383",
                          }}
                        >
                          {/* Akachi Azubuike, MD */}
                          {refillItem?.prescriptionReference
                            ?.providerTenantPracticeLocation?.provider?.name[0]
                            ?.firstName +
                            " " +
                            refillItem?.prescriptionReference
                              ?.providerTenantPracticeLocation?.provider
                              ?.name[0]?.lastName +
                            ", " +
                            refillItem?.prescriptionReference
                              ?.providerTenantPracticeLocation?.provider
                              ?.qualification}
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography component="div">
                        <Box
                          sx={{
                            textAlign: "left",
                            // m: 1,
                            fontFamily: "Montserrat",
                            fontSize: "12px",
                            color: "#323232",
                            marginBottom: "0px",
                          }}
                        >
                          {/* CVS Pharmacy #6978 */}
                          {refillItem?.pharmacy?.standardizedOrganizationName}
                        </Box>
                        <Box
                          sx={{
                            textAlign: "left",
                            // m: 1,
                            fontFamily: "Montserrat",
                            fontSize: "12px",
                            color: "#336383",
                          }}
                        >
                          {
                            pharmacyInfo?.length > 40 ? 
                            <PnToolTip title={pharmacyInfo}>
                              {truncate(pharmacyInfo, "40")}
                            </PnToolTip>
                            : pharmacyInfo
                          }
                          <br/>
                          {pharmacyContactInfo}
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item xs={1.3}>
                      <Typography component="div">
                        <Box
                          sx={{
                            textAlign: "left",
                            ml: 1,
                            fontFamily: "Montserrat",
                            fontSize: "12px",
                            color:
                              refillItem?.refillStatus === "declined"
                                ? "#FF0000"
                                : "#323232",
                            // fontWeight: "600",
                            marginBottom: "0px",
                          }}
                        >
                          {refillItem?.refillStatus?.toLowerCase() ===
                          "pending" ? (
                            "Refill requested"
                          ) : refillItem?.refillStatus?.toLowerCase() ===
                            "refilled" ? (
                            "Refill approved"
                          ) : refillItem?.refillStatus?.toLowerCase() ===
                            "declined" ? (
                            <span
                              className={`font-10 d-block text--secondary`}
                              style={{ marginTop: 6.5 }}
                            >
                              {"Refill denied"}
                              <PnToolTip title={refillItem?.denyReasonDescription?.toLowerCase() === "other" ? "".concat(" - ",refillItem?.refillNote): "".concat(refillItem?.denyReasonDescription,refillItem?.refillNote?.length > 0 ? " - ":"",refillItem?.refillNote || "")}>
                                <InfoIcon
                                  style={{
                                    height: "10px",
                                    weight: "10px",
                                    fill: "#004fd4",
                                  }}
                                />
                              </PnToolTip>
                            </span>
                          ) : (
                            "Ordered"
                          )}
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item >
                      <Typography component="div" sx={{ alignSelf: "end" }}>
                        <Box
                          sx={{
                            textAlign: "left",
                            // ml: 5,
                            fontFamily: "Montserrat",
                            fontSize: "12px",
                            color: "#336383",
                            alignSelf: "end",
                          }}
                        >
                          <button
                            className="btn d9e8ec--button text--blue  br-10 py-1 fw-sb align-self-center"
                            onClick={(e) => {
                              handleRefill(e);
                            }}
                            id={refillItem?.medicationRxId}
                            disabled={
                              refillItem?.refillStatus?.toLowerCase() === "pending"
                                ? true
                                : refillItem?.rxStatus?.toLowerCase() === "ordered"
                                ? true
                                : refillItem?.refillStatus?.toLowerCase() === "declined"
                                ? true
                                : false
                            }
                          >
                            Refill
                          </button>
                        </Box>
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })
            )}
            {/* </Grid> */}

            {/* {
            isLoading ? <Skeleton animation="wave" /> : statusCodeEncounters === 200 ? encounters?.length > 0 ?
              encounters?.map((encounter, index) => {
                if (encounter?.Status === 'closed' || encounter?.Status === 'started' || encounter?.Status === 'dispatched') {
                  if (index === (encounters?.length - 1)) {
                    return <EncounterItem key={encounter?.id} handleBackdropClose={handleBackdropClose} handleToggle={handleToggle} encounter={encounter} isEven={index % 2 === 0 ? true : false} isLast={true} handleAddenShowModal={handleAddenShowModal} handleAddenModal={handleAddenModal} handleModal={handleModal} />
                  }
                  else {
                    return <EncounterItem handleBackdropClose={handleBackdropClose} handleToggle={handleToggle} key={encounter?.id} encounter={encounter} isEven={index % 2 === 0 ? true : false} handleAddenShowModal={handleAddenShowModal} handleAddenModal={handleAddenModal} handleModal={handleModal} />
                  }
                }

              }) :
              "" :
              ""
          } */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientPrescriptionsPage;

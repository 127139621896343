import { useNavigate } from "react-router";
import { useAuth } from "../../../../components/hooks/useAuth";
import { useEffect, useRef, useState } from "react";
import {
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    TableSortLabel,
} from "@mui/material";
import useOnClickOutside from "../../../../components/hooks/useOnClickOutside";
import RedeemIcon from '@mui/icons-material/Redeem';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PrintIcon from "@mui/icons-material/Print";
import { calenderDateTimeHelper } from "../../../../utils/dateTimeHelper";
import { PnToolTip } from "../../../../components/UI/Reusable/PnComponents";
import useModal from "../../../../components/hooks/useModal";
import DeletePatientEncounterConfirmationModal from "../UI/Modals/DeletePatientEncounterConfirmationModal";
import TrashButtonIcon from "../../../../assets/icons/Icon-Trash.svg";
import TrashButtonIconDisabled from "../../../../assets/icons/Icon-Trash-Disabled.svg";
import { EditIconComponent } from "../../../../assets/icons/EditIconComponent";
import useClaimPlaceOfServiceCodes from "../../claim-hooks/useClaimPlaceOfServiceCodes";


import { STATUS_MAP } from "../../../../billing-and-claims/BillingAndClaimsConstants";
import { useSelector } from "react-redux";
import { BILLING_AND_CLAIMS } from "../../../../utils/helpers/subscriptionsConstants";


function statusToString(status) {
    return STATUS_MAP.get(status);
}


function PendingClaimEncountersTable({ columnHeadersList, data, switchView, setEncounterToEdit, setEditMode, setReloadEncounters }) {
    const { user } = useAuth();
    const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);

    const navigate = useNavigate();
    const { placeOfServiceCodes, isPlaceOfServiceCodeLoading } = useClaimPlaceOfServiceCodes();
    const placeOfServiceMap = new Map();

    for (const { code, name } of placeOfServiceCodes) {
        placeOfServiceMap.set(code, name);
    }

    const [sortBy, setSortBy] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [isOpen, setIsOpen] = useState(false);
    const [filterList, setFilterList] = useState([]);
    const toggleOpen = () => setIsOpen(!isOpen);
    const menuClass = `dropdown-menu br-7 p-2 bg--white ${isOpen ? " show" : ""}`;
    const dropdownRef = useRef();
    useOnClickOutside(dropdownRef, () => setIsOpen((p) => false));
    const [uniqueProvidersList, setUniqueProvidersList] = useState([])
    const [sortedData, setSortedData] = useState([]);
    const { open: openDeleteEncounterModal, handleModal: handleDeleteEncounterModal } = useModal(false);
    const [deleteEncounterId, setDeleteEncounterId] = useState(null);

    const handleSort = (column) => {
        // Toggle the sort direction if the same column is clicked
        const isSameColumn = sortBy === column;
        const newSortDirection = isSameColumn && sortDirection === 'asc' ? 'desc' : 'asc';

        // Set the sorting state
        setSortBy(column);
        setSortDirection(newSortDirection);

        // Sort the data based on the selected column and direction
        const sorted = [...data].sort((a, b) => {
            const aValue = getValue(a, column);
            const bValue = getValue(b, column);

            if (aValue < bValue) return newSortDirection === 'asc' ? -1 : 1;
            if (aValue > bValue) return newSortDirection === 'asc' ? 1 : -1;
            return 0;
        });

        // Update the sorted data state
        setSortedData(sorted);
    };


    const getValue = (item, column) => {
        const keys = column.split('.');
        let value = item;
        for (let key of keys) {
            value = value ? value[key] : null;
            if (value === null || value === undefined) {
                return ''; // Return empty string for consistent sorting
            }
        }
        return value;
    };

    const sortData = () => {
        return data.slice().sort((a, b) => {
            if (sortBy) {
                const aValue = getValue(a, sortBy);
                const bValue = getValue(b, sortBy);

                // console.log("aValue,bValue", aValue, bValue);
                if (aValue < bValue) {
                    return sortDirection === 'asc' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortDirection === 'asc' ? 1 : -1;
                }
                return 0;
            }
            return 0;
        });
    }




    const handleFilterChecks = (event) => {
        if (event.target.checked) {
            const tempList = [...filterList];
            tempList.push(event.target.id);
            setFilterList(tempList);
        } else {
            const dummy = filterList.filter((uid) => uid !== event.target.id);
            setFilterList(dummy);
        }
    };
    const handleClearFiltering = () => {
        setFilterList([]);
        setIsOpen(!isOpen);
        sortedData.forEach((item, index) => {
            let providerId = item?.providerTenantPracticeLocation.provider?.userId;
            document.getElementById(providerId).checked = false;
        });
        setSortedData(() => sortData());
        // fetchAllAttachments();
    };
    const handleFiltering = () => {
        setIsOpen(!isOpen);
        let filterData = [];
        if (filterList?.length == 0) {
            setSortedData(sortData());
        } else {
            filterList.forEach(filterItem => {
                filterData.push(...data.filter((item) => { return item?.providerTenantPracticeLocation?.provider?.userId === Number(filterItem) }));
            });
            // console.log("filterList: ", filterList);
            // console.log("filterData: ", filterData);
            setSortedData(() => filterData)
        }
        // sortedData = data.filter((item)=>{ item?.providerTenantPracticeLocation?.provider?.userId})
        // fetchAttachments();
    };

    function viewEncounterDetails(event, encounter) {
        event.stopPropagation();
        navigate(`/claim-encounter-details/${encounter?.encounterId}`, { state: { encounter } });
    }

    useEffect(() => {
        if (sortBy) {
            setSortedData(sortData());
        }
    }, [sortBy, sortDirection, data]);


    useEffect(() => {
        if (!sortBy && columnHeadersList?.length) {
            // Sort by the first column by default or choose a preferred one
            setSortBy(columnHeadersList[0]?.key);
        }
        setSortedData(sortData());
    }, [data, sortBy, sortDirection]);


    useEffect(() => {
        const uniqueObjects = data.filter((item, index, arr) => {
            // console.log("Item", item);
            const firstName = item.provider.firstName;
            if (!firstName) return false; // Skip items without a firstName
            return arr.findIndex(obj => obj.provider.firstName === firstName) === index;
        });
        setSortedData(sortData());
        setUniqueProvidersList(uniqueObjects)
    }, [data]);
    return (
        <>
            {openDeleteEncounterModal ? <DeletePatientEncounterConfirmationModal show={openDeleteEncounterModal} handleModal={handleDeleteEncounterModal} encounterId={deleteEncounterId} setReloadEncounters={setReloadEncounters} /> : null}
            <TableContainer sx={{ borderRadius: "10px", border: "1px solid #D9E8EC", maxHeight: 540, maxWidth: '100%', overflowX: 'auto', '&::-webkit-scrollbar': { height: '8px', width: '4px' }, '&::-webkit-scrollbar-thumb': { backgroundColor: '#D9E8EC', borderRadius: '4px' } }} >
                <Table stickyHeader aria-label="sticky table">
                    <TableHead sx={{ borderTopColor: "#FAFCFC", borderRightColor: "#FAFCFC", borderLeftColor: "#FAFCFC", borderBottom: "1px solid #D9E8EC" }}>
                        <TableRow>

                            {columnHeadersList?.map((item, idx) => {
                                return <TableCell key={item?.key?.concat(idx)}>
                                    <TableSortLabel
                                        direction={sortBy === item?.key ? sortDirection : 'asc'}
                                        onClick={() => handleSort(item?.key)}
                                        hideSortIcon={true} // We handle icon rendering manually
                                    >
                                        <span className="font-12 text--secondary">{item?.value}</span>

                                        {/* Conditionally render the sort icons for specific columns */}
                                        {(item?.key === "provider.firstName" || item?.key === "encounterMetadata.dateOfServiceTo") && (
                                            <span style={{ color: "#EC609B" }}>
                                                {sortBy === item?.key ? (
                                                    sortDirection === 'desc' ? (
                                                        <KeyboardArrowUpIcon />
                                                    ) : (
                                                        <KeyboardArrowDownIcon />
                                                    )
                                                ) : (
                                                    <KeyboardArrowUpIcon /> // Default icon
                                                )}
                                            </span>
                                        )}
                                    </TableSortLabel>
                                </TableCell>

                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData.map((item, idx) => {
                            const keys = Object.keys(item);
                            let firstName = item?.provider?.firstName;
                            let lastName = item?.provider?.lastName;
                            let prefix = item?.provider?.prefix;

                            let patientFirstName = item?.patient?.firstName;
                            let patientLastName = item?.patient?.lastName;
                            if (item?.claimSubmitted === true) {
                                return null;
                            }

                            return (
                                <TableRow key={idx} sx={{
                                    "&:last-child td, &:last-child th": { border: 0 },
                                    ...(subscriptionPrivilegeGroups[BILLING_AND_CLAIMS]?.bve?.active && {
                                        "&:hover": {
                                            cursor: "pointer !important"
                                        }
                                    })
                                }}

                                    onClick={(event) => {
                                        if (subscriptionPrivilegeGroups[BILLING_AND_CLAIMS]?.bve?.active) {
                                            viewEncounterDetails(event, item)
                                        }
                                    }}

                                >
                                    <TableCell sx={{ color: "#336383" }}>
                                        <span className="font-12">{"".concat(prefix ?? "", " ", firstName, " ", lastName)}</span>
                                    </TableCell>
                                    <TableCell sx={{ color: "#336383" }}>
                                        <span className="font-12">{`${patientFirstName} ${patientLastName}`}</span>
                                    </TableCell>
                                    <TableCell sx={{ color: "#336383" }}>
                                        <span className="font-12">{calenderDateTimeHelper(item?.encounterMetadata?.dateOfServiceTo, "MMM dd, yyyy")}</span>
                                    </TableCell>

                                    <TableCell sx={{ color: "#336383" }}>
                                        <span className="font-12">{item?.encounterMetadata?.serviceLocation}</span>
                                    </TableCell>
                                    <TableCell sx={{ color: "#336383" }}>

                                        <span className="font-12">{placeOfServiceMap.get(item?.encounterMetadata?.placeOfService)}
                                        </span>                                    </TableCell>
                                    <TableCell sx={{ color: "#336383" }}>
                                        <span className="font-12">{item?.diagnosis?.at(0)?.code}</span>
                                    </TableCell>
                                    <TableCell sx={{ color: "#336383" }}>
                                        <span className="font-12">{calenderDateTimeHelper(item?.dateOfCurrentIllnessOrSymptom, "MMM dd, yyyy")}</span>
                                    </TableCell>
                                    <TableCell sx={{ color: "#336383" }}>
                                        <span className="font-12">{item?.claim !== null ? statusToString(item?.claim?.status) : 'Awaiting Claim Preparation'}</span>
                                    </TableCell>


                                    <TableCell sx={{ color: "#336383" }}>

                                        <div className="d-flex gap-2">
                                            <div onClick={(event) => {
                                                event.stopPropagation();
                                                if (item?.claimSubmitted === false) {
                                                    setEditMode(true);
                                                    switchView();
                                                    setEncounterToEdit(p => item);
                                                }
                                            }}
                                                className={`${item?.claimSubmitted === true ? 'cursor--disabled' : 'cursor--pointer'}`}
                                                hidden={subscriptionPrivilegeGroups[BILLING_AND_CLAIMS]?.bee?.active === true ? false : true}
                                            >
                                                <PnToolTip title={`${item?.claimSubmitted === true ? '' : 'Edit Encounter'}`}>
                                                    <EditIconComponent
                                                        alt={"editIcon"}
                                                        id="encounter-edit"
                                                        style={{ color: `${item?.claimSubmitted === true ? '#bcd6da' : '#336383'}`, width: "15px", height: "15px" }}
                                                    ></EditIconComponent>
                                                </PnToolTip>
                                            </div>
                                            <div onClick={(event) => {
                                                event.stopPropagation();
                                                if (item?.claimSubmitted === false) {
                                                    setDeleteEncounterId(item?.encounterId);
                                                    handleDeleteEncounterModal();
                                                }
                                            }} className={`${item?.claimSubmitted === true ? 'cursor--disabled' : 'cursor--pointer'}`}
                                                hidden={subscriptionPrivilegeGroups[BILLING_AND_CLAIMS]?.bde?.active === true ? false : true}
                                            >
                                                <PnToolTip title={`${item?.claimSubmitted === true ? '' : 'Delete Encounter'}`}>
                                                    {item?.claimSubmitted === true ? <img
                                                        src={TrashButtonIconDisabled}
                                                        alt={"deleteIconDisabled"}
                                                        id="encounter-delete-disabled"
                                                        style={{ width: "15px", height: "15px" }}
                                                    ></img> : <img
                                                        src={TrashButtonIcon}
                                                        alt={"deleteIcon"}
                                                        id="encounter-delete"
                                                        style={{ width: "15px", height: "15px" }}
                                                    ></img>}
                                                </PnToolTip>
                                            </div>



                                            <div >
                                                {item?.claimSubmitted === false ? subscriptionPrivilegeGroups[BILLING_AND_CLAIMS]?.bpc?.active === true ? <PnToolTip title="Prepare Claim Submission">

                                                    <RedeemIcon
                                                        onClick={(event) => { event.stopPropagation(); navigate('/patient-claim-submit', { state: { encounter: item } }) }}
                                                        fontSize="small"
                                                        style={{ color: "#336383" }}
                                                        className="cursor--pointer font-12 disabled"
                                                    />

                                                </PnToolTip> : null : null}
                                            </div>

                                        </div>


                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>

    );
}

export default PendingClaimEncountersTable;
/**
 * @author Ahmed Bashir
 * @description Patients list component in the patients tab displays the list of patients in a practice.
 *
 */

import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Skeleton } from "@mui/material";
import { createTheme, ListItemButton, ThemeProvider } from "@mui/material";
import sendHttpRequest from "../hooks/sendHttpRequest";
import placeholder from "../../assets/images/placeholder.png";
import { Search } from "react-bootstrap-icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ChevronBarLeft } from "react-bootstrap-icons";
import {
  changeDateTimeFormat,
  getAgeByDateOfBirth,
} from "../../utils/dateTimeHelper";
import { useAuth } from "../hooks/useAuth";
import { PnToolTip } from "../UI/Reusable/PnComponents";
import { truncate } from "../../utils/helpers/HelperFunctions";

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: "#FAFCFC",
}));

const theme = createTheme({
  components: {
    MuiListItemButton: {
      root: {
        "&$selected": {
          color: "#5FBB7D",
          backgroundColor: "red",
        },
      },
    },
  },
});


const PatientListItem = (props) => {
  const TRUNCATE_VALUE_ON_COLLAPSE = 14;
  const TRUNCATE_VALUE_ON_EXPAND = 22;
  const [hideToolTip, setHideToolTip] = useState(false);


  const checkForToolTip = () => {

    if (props?.isCollapsed) {
      if (
        props?.PatientName?.length > TRUNCATE_VALUE_ON_COLLAPSE
      ) {
        setHideToolTip(false);
      } else {
        setHideToolTip(true);
      }
    } else if (!props?.isCollapsed) {
      if (
        props?.PatientName?.length > TRUNCATE_VALUE_ON_EXPAND
      ) {
        setHideToolTip(false);
      } else {
        setHideToolTip(true);
      }
    }

  }

  useEffect(() => {
    checkForToolTip();
  }, [props?.isCollapsed])

  return (
    <>

      <ListItemButton
        key={props?.id}
        disableRipple
        id={props?.id}
        className="py-1"
        selected={Number(props.selectedIndex) === Number(props.index)}
        onClick={(event) => props.handlePatientClick(event, props.index)}
        style={{
          backgroundColor: `${Number(props.selectedIndex) === Number(props.index) ? "white" : ""}`,
          borderRight: `${Number(props.selectedIndex) === Number(props.index) ? "3px solid #EC609B" : ""}`,
          borderTopRightRadius: `${Number(props.selectedIndex) === Number(props.index) ? "3px solid #EC609B" : ""}`,
          boxShadow: `${Number(props.selectedIndex) === Number(props.index) ? "0px 6px 16px #0000000F" : ""}`,
          borderBottom: "1px solid #d9e8ec",
        }}
      >
        <div id={props?.id}>
          <ListItemAvatar id={props?.id}>
            <Avatar id={props?.id} src={props.img}></Avatar>
          </ListItemAvatar>
        </div>
        <div>
          <ListItemText

            id={props?.id}
            primary={
              <>
                {
                  <>
                    <PnToolTip
                      id={props?.id}
                      title={props?.PatientNameWithMiddleName}
                      hidden={hideToolTip}
                    >
                      <div
                        id={props?.id}

                        style={{
                          fontFamily: "Montserrat",
                          color: "#323232",
                          fontSize: "14px",
                          fontWeight: 600,
                          margin: 0

                        }}
                      >
                        {!props?.isCollapsed ? truncate(props?.PatientName, TRUNCATE_VALUE_ON_EXPAND) + " " : truncate(props?.PatientName, TRUNCATE_VALUE_ON_COLLAPSE)}
                      </div>
                    </PnToolTip>
                    <div
                      id={props?.id}
                      hidden={!hideToolTip}
                      style={{
                        fontFamily: "Montserrat",
                        color: "#323232",
                        fontSize: "14px",
                        fontWeight: 600,
                        margin: 0

                      }}
                    >
                      {!props?.isCollapsed ? truncate(props?.PatientName, TRUNCATE_VALUE_ON_EXPAND) + " " : truncate(props?.PatientName, TRUNCATE_VALUE_ON_COLLAPSE)}
                    </div>
                  </>
                }
                <Typography
                  id={props?.id}
                  sx={{
                    fontFamily: "Montserrat",
                    color: "#336383",
                    fontSize: "10px",
                    fontWeight: 400,
                  }}
                >
                  {!props?.isCollapsed && (
                    <small
                      id={props?.id}
                      style={{
                        color: "#336383",
                        fontSize: 9,
                        fontWeight: 400,
                      }}
                    >
                      {props?.Gender} - {props?.Age} years
                    </small>
                  )}
                </Typography>
                <Typography
                  id={props?.id}
                  sx={{
                    fontFamily: "Montserrat",
                    color: "#336383",
                    fontSize: "10px",
                    fontWeight: 400,
                  }}
                >
                  DOB: {props?.DateOfBirth}
                </Typography>

              </>
            }
            secondary={
              <Typography
                id={props?.id}
                sx={{
                  fontFamily: "Montserrat",
                  color: "#323232",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                {props.ChiefComplaints}
              </Typography>
            }
          />
        </div>
      </ListItemButton>
    </>
  );
};

export default function PatientsList(props) {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [patients, setPatients] = useState([]);
  const { patientId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [statusCodePatients, setStatusCodePatients] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const { user } = useAuth();

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const handleSearchTerm = (event) => {
    // setSelectedIndex(-1);
    setSearchTerm(event.target.value);
  };

  const handlePatientClick = (event, index) => {
    //After making the API call, execute the following function
    props?.setIsPatientNotFound(false);
    setSelectedIndex(index);


    if (event.target.id !== "") {
      navigate(`/all-patients/${event.target.id}`);
      props?.handlePatientId(event.target.id);
    } else {
      navigate(`/all-patients/${event.target.parentNode.id}`);
      props?.handlePatientId(event.target.parentNode.id);
    }
    // props?.handleRecentEncountersList(null);
  };

  const fetchPatients = async () => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/patient/all/tenant/${user?.tenantId}`,
      method: "GET",
    });
    if (!httpResponse.error) {
      setPatients(httpResponse.data);
    }
    setIsLoading(false);
    setStatusCodePatients(httpResponse.status);
  };

  /* Filtering the patients array based on the search term. */
  const filteredPatients = patients?.filter(function (patient) {
    let patientFullName = patient?.name[0]?.firstName
      ?.concat(" ", patient?.name[0]?.lastName || "")
      ?.toLowerCase();
    let filteredResults = patientFullName?.includes(searchTerm?.toLowerCase());
    return filteredResults;
  });

  useEffect(() => {
    if (location?.state?.patientId) {
      setSelectedIndex(location?.state?.patientId);
    }
  }, [window.location.href]);

  useEffect(() => {
    fetchPatients();
    return () => {
      setPatients(null);
    };
  }, [
    patientId,
    props?.patientDependency,
    props?.patientFacesheetDependency,
    props?.patientEditDependency,
  ]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        className="noscrollbar"
        sx={{
          flexGrow: 1,
          maxWidth: 752,
          maxHeight: "99vh",
          overflowY: "scroll",
          zIndex: "10",
          // borderTop: "1px solid #d9e8ec"
        }}
      >
        <Grid
          container
          justifyContent="space-evenly"
          className="sticky-top bg-white"
          style={{ zIndex: "1000", borderBottom: "1px solid #d9e8ec" }}
        >
          <Grid item xs={9} md={9}>
            {/* <input type="text" placeholder="Search patient" className="font-14 form-control shadow-none br-10 border--default w-100 input my-2"/> */}
            <div className="input-group my-2" style={{ zIndex: "10" }}>
              <input
                onChange={handleSearchTerm}
                type="text"
                style={{
                  zIndex: "10",
                  borderLeft: "1px solid #d9e8ec",
                  borderTop: "1px solid #d9e8ec",
                  borderBottom: "1px solid #d9e8ec",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  borderRight: "none",
                }}
                className="font-14 form-control shadow-none br-10 border--default  input "
                id="autoSizingInputGroup"
                placeholder="Search for patient"
              />
              <div
                className="input-group-text bg-white"
                style={{
                  zIndex: "10",
                  borderRight: "1px solid #d9e8ec",
                  borderTopRightRadius: "10px",
                  borderTop: "1px solid #d9e8ec",
                  borderBottom: "1px solid #d9e8ec",
                  borderBottomRightRadius: "10px",
                  borderLeft: "none",
                  cursor: "pointer",
                }}
              >
                <Search color="#336383" />
              </div>
            </div>
          </Grid>
          <Grid item xs={2} md={2} sx={{ zIndex: "10" }}>
            <button
              type="button"
              onClick={props?.toggleCollapse}
              className="btn br-10 border--default outline-0 shadow-none my-2 "
            >
              {/* <UnfoldLessIcon  style={{color:'#336383', transform:'rotate(90deg)'}} /> */}
              <ChevronBarLeft style={props?.isCollapsed ? { color: '#336383', transform: 'rotate(180deg)' } : { color: '#336383' }} />
            </button>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          sx={{ zIndex: "10" }}
        >
          <Grid item xs={12} md={12} sx={{ mx: 0, zIndex: "10" }}>
            <List sx={{ zIndex: "10" }}>
              {isLoading ? (
                <Skeleton animation="wave" />
              ) : patients?.length === 0 ? (<span className="font-14 fw-sb ms-3 d-block text--danger">
                No data available
              </span>) : filteredPatients?.length === 0 ? (
                <span className="font-14 fw-sb ms-3 d-block text--danger">
                  No matches found
                </span>
              ) : (
                filteredPatients?.map((patient, index) => {
                  return (
                    <Demo key={index}>
                      <PatientListItem
                        id={patient?.userId}
                        user={user}
                        index={patient?.userId}
                        selectedIndex={selectedIndex}
                        handlePatientClick={handlePatientClick}
                        firstName={patient?.name[0]?.firstName}
                        lastName={patient?.name[0]?.lastName}
                        PatientName={
                          patient?.name[0]?.firstName +
                          " " +
                          patient?.name[0]?.lastName
                        }
                        PatientNameWithMiddleName={
                          patient?.name[0]?.firstName +
                          " " +
                          patient?.name[0]?.middleName +
                          " " +
                          patient?.name[0]?.lastName
                        }
                        Gender={patient?.gender}
                        Age={getAgeByDateOfBirth(patient?.dateOfBirth)}
                        ChiefComplaints={patient?.chiefComplaint}
                        img={placeholder}
                        DateOfBirth={changeDateTimeFormat(
                          patient?.dateOfBirth,
                          "MMM D, YYYY"
                        )}
                        isCollapsed={props?.isCollapsed}
                      />
                    </Demo>
                  );
                })
              )}
            </List>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

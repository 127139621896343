import { Divider, Skeleton } from "@mui/material";
import { setProviderList } from "../../redux/actions/providerActions";
import SearchPatient from "../UI/Header/SearchPatient";
import {
  PatientVisitType,
  ProviderFacility,
} from "../UI/Modals/NewAppointmentModal";
import {
  calenderDateTimeHelper,
  changeDateTimeFormat,
  convertUTCtoTimzone,
  dateTimeHelper,
} from "../../utils/dateTimeHelper";
import { newAppointmentModalStrings } from "../../res/strings";
import AppoinmentCalender from "../UI/General/AppointmentCalender";
import { useState } from "react";

import classes from "./MakeAppointmentForm.module.css";
import { useEffect } from "react";
import sendHttpRequest, { search } from "../hooks/sendHttpRequest";
import { aptServiceURL } from "../../res/endpointurls";
import { truncate } from "../../utils/helpers/HelperFunctions";
import { PnToolTip } from "../UI/Reusable/PnComponents";

// ICD10 Code List Items
const ICD10CodeListItems = ({ icd10List, iCD10CodeList, setICD10CodeList }) => {
  const handleChange = (event, item) => {
    let ischecked = event.target.checked;
    if (ischecked) {
      setICD10CodeList([...iCD10CodeList, item]);
    } else {
      let result = iCD10CodeList.filter((itemObject) => {
        return itemObject.code !== item.code;
      });
      setICD10CodeList([...result]);
    }
  };
  const handleDefaultChecked = (itemObject) => {
    const result = iCD10CodeList.find((item) => {
      return item?.code === itemObject?.code;
    });
    return result !== undefined;
  };
  return (
    <>
      {icd10List.map((item) => {
        return (
          <div className="col-6 col-sm-6">
            <div className=" form-group mx-auto font-12 text--terciary">
              <input
                key={item?.code}
                type="checkbox"
                className="form-check-input border--default br-10 cursor--pointer cursor--pointer shadow-none"
                id={item?.code}
                name={item?.description}
                onChange={(e) => {
                  handleChange(e, item);
                }}
                defaultChecked={handleDefaultChecked(item)}
              />
              <label for={item?.code} className="form-check-label ms-1">
                <PnToolTip title={item?.code.concat(" - ", item?.description)}>
                  {truncate(item?.code.concat(" - ", item?.description), 50)}
                </PnToolTip>
              </label>
            </div>
          </div>
        );
      })}
    </>
  );
};

const MakeAppointmentForm = (props) => {
  const __str = newAppointmentModalStrings.en;
  const [isSlotsLoading, setIsSlotsLoading] = useState(false);
  const [slotsTimezoneOffset, setSlotsTimezoneOffset] = useState();
  const [appointmentType, setAppointmentType] = useState();
  const [providerID, setProviderID] = useState();
  // const [providerID, setProviderID] = useState();
  const [chiefComplaint, setChiefComplaint] = useState("");
  const [freeSlots, setFreeSlots] = useState([]);
  const [slotId, setSlotId] = useState();
  const [slotStartTime, setSlotStartTime] = useState();
  const [unSelectFreeSlot, setUnSelectFreeSlot] = useState(false);
  const [isCustomAppointmentSelected, setIsCustomAppointmentSelected] =
    useState(false);

  const [handleFreeSlots, setHandleFreeSlots] = useState(true);

  const [appointmentSection, setAppointmentSection] = useState(
    new Map([["selected", "calenderSection"]])
  );

  const [scheduleDate, setScheduleDate] = useState(
    convertUTCtoTimzone(
      new Date(),
      Number(props?.metaData?.providers[0]?.timezoneOffset),
      "YYYY-MM-DD"
    )
  );
  const [appointmentDuration, setAppointmentDuration] = useState();
  const [isDurationLoading, setIsDurationLoading] = useState(false);

  // convertUTCtoTimzone(new Date(), user.timezone, "YYYY-MM-DD LT")
  const [selectedPastAptId, setSelectedPastAptId] = useState();

  // =========Patient visit type=================
  const [patientVisitType, setPatientVisitType] = useState("in-patient");

  // =========Facility===========================
  const [selectedFacility, setSelectedFacility] = useState(0);
  const [isFacilityLoading, setIsFacilityLoading] = useState(false);
  const [facilitiesData, setFacilitiesData] = useState([]);
  

  //==========Appointment Service States=============================
  const [appointmentServices, setAppointmentServices] = useState([]);

  const [iCD10CodeList, setICD10CodeList] = useState([]);
  const [iCD10CodeData, setICD10CodeData] = useState([]);

  const handleCustomDuration = (e) => {
    e.preventDefault();
    // setCustomDuration(e.target.value);
    // onDirty();
  };

  const fecthFreeSlots1 = async (
    providerId,
    appointmentType,
    scheduleSelectedDate,
    durationMinutes
  ) => {
    setIsSlotsLoading(true);
    setFreeSlots([]);
    let timsezoneOffset =
      slotsTimezoneOffset !== undefined
        ? slotsTimezoneOffset
        : Number(props.metaData?.providers[0]?.timezoneOffset);
    const httpResponse = await search({
      url: `/tenants/${props?.metaData?.tenantId}/providers/${providerId}/available-slots`,
      method: "GET",
      params: {
        providerId: providerId,
        fromDate:
          scheduleSelectedDate === undefined
            ? scheduleDate
            : scheduleSelectedDate,
        toDate:
          scheduleSelectedDate === undefined
            ? scheduleDate
            : scheduleSelectedDate,
        // appointmentType: appointmentType,
        // patientId: "3707",
        // page: 0,
        // duplicates: false,
        tenantId: props?.metaData?.tenantId,
        facilityId: selectedFacility,
        duration: 
        durationMinutes === undefined
          ? appointmentType?.durationMinutes
          : durationMinutes,
      },
    });
    if (!httpResponse.error) {
      setFreeSlots(httpResponse?.data);
      
      setSlotStartTime(httpResponse?.data?.at(0)?.startDateTime);
      
    }
    setIsSlotsLoading(false);
  };

  const fetchAppointmentService = async () => {
    const httpResponse = await sendHttpRequest({
      url: aptServiceURL("1"),
      method: "GET",
    });
    if (!httpResponse.error) {
      let data = httpResponse?.data;
      let aptObject = data.find((item) => {
        return item.key === "NEW";
      });
      setAppointmentServices(data);
      setAppointmentType(aptObject);
      setAppointmentDuration(aptObject?.durationMinutes);
    } else {
      setAppointmentServices([]);
    }
  };

  const handleAppointmentType = (event) => {
    let tenantServiceId = event.target.value;
    let aptObject = appointmentServices?.find((item) => {
      return item.tenantServiceId === Number(tenantServiceId);
    });
    let aptType = aptObject.key;

    if (
      appointmentType?.key === "FOLLOW_UP" &&
      selectedPastAptId !== undefined
    ) {
      setChiefComplaint("");
      document.getElementById("chiefComplaint").value = "";
    }

    if (aptType === "CUSTOM") {
      setSelectedPastAptId(undefined);
      setIsCustomAppointmentSelected(true);
      setAppointmentType(aptObject);
      // fecthAllPrescribers(aptType);
      setAppointmentSection(new Map([["selected", "calenderSection"]]));
    } else {
      setIsCustomAppointmentSelected(false);
      setUnSelectFreeSlot(true);
      setSlotId("");
      setSlotStartTime("");
      if (appointmentType?.key === "CUSTOM") {
        // fecthAllPrescribers(aptType);
      }
      setAppointmentType(aptObject);
      if (providerID !== 0 && providerID !== -1) {
        fecthFreeSlots1(
          providerID,
          aptType,
          undefined,
          aptObject?.durationMinutes
        );
      }
    }

    setAppointmentDuration(aptObject?.durationMinutes);
  };
  console.log("slotStartTime: ",slotStartTime);
  
  const handleNext = () => {
    let appoinmentStartTime = slotStartTime;

    props?.setPatientJson({
      ...props.patientJson,
      appointment: {
        chiefComplaint: chiefComplaint,
        appointmentType: { tenantServiceId: appointmentType?.tenantServiceId },
        appointmentSlot: {
          provider: {
            userId: providerID,
          },
          startDateTime: appoinmentStartTime,
          duration: appointmentDuration,
          startDate: scheduleDate,
          facility: {
            facilityId: selectedFacility
          }
        },
        
      },
      diagnoses: iCD10CodeList,
    });
    props?.setAppointmentAccordionExpanded(false);
    props?.setReferralAccordionExpanded("refAccordion");
  };

  const handleFreeSlot = (event) => {
    event.preventDefault();
    setUnSelectFreeSlot(false);
    // let slotId = event.target[event.target.selectedIndex].getAttribute("data");
    let slotStartTime = event.target.value;
    // setSlotId(slotId);
    setSlotStartTime(slotStartTime);

    // alert(event.target.data)
  };

  // const handlefacilityId = (facilityId) => {
  //   setSelectedFacility(facilityId);
  //   if (patientId !== -1) {
  //     fecthAllPrescribers(appointmentType?.key, facilityId);
  //   }
  // };
  useEffect(() => {
    setFacilitiesData(props?.metaData?.facilities || [])
    setProviderID(props?.metaData?.providers[0]?.userId);
    setAppointmentServices(props?.metaData?.tenantServices);
    let aptObject = props?.metaData?.tenantServices.find((item) => {
      return item.key === "NEW";
    });
    setAppointmentType(aptObject);
    setAppointmentDuration(aptObject?.durationMinutes);
    fecthFreeSlots1(props?.metaData?.providers[0]?.userId, "NEW", scheduleDate,aptObject?.durationMinutes);
  }, [props?.metaData?.providers]);


  useEffect(()=>{
    if(selectedFacility !== 0){
      fecthFreeSlots1(providerID,appointmentType?.key,scheduleDate,appointmentDuration)
    }
  },[selectedFacility])

  return (
    <>
      <form
        id="newAppointmentForm"
        onSubmit={(e) => {
          e.preventDefault();
          handleNext();
        }}
      >
        <div
          className={`row ${classes["scroll__bar"]}`}
          // style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}
        >
          <div className="col-6 px-4">
            {/* Facility */}
            <div className="form-group mt-4">
              {patientVisitType === "in-patient" ? (
                <ProviderFacility
                  facilitiesData={facilitiesData}
                  isFacilityLoading={isFacilityLoading}
                  setSelectedFacility={setSelectedFacility}
                    handlefacilityId={()=>{}}
                    isRequired={true}
                />
              ) : null}
            </div>

            {/* Provider */}
            <div className="form-group mt-4">
              <label className="fw-sb font-14 text--terciary">
                {__str.provider}
              </label>
              <span className="font-12 text--terciary d-block">
                {props.metaData?.providers?.length > 0
                  ? "Dr."+" " + props.metaData?.providers[0]?.name[0]?.firstName +
                    " " +
                    props.metaData?.providers[0]?.name[0]?.lastName 
                  : null}
              </span>
            </div>
            {/* Appointment Type */}
            <div className="form-group mt-4">
              <label className="fw-sb font-14 text--terciary">
                {__str.type}
              </label>
              <select
                className={`form-select font-14 shadow-none ${classes["custom-select"]}`}
                aria-label="Appointment type select"
                style={{
                  border: "1px solid #D9E8EC",
                  height: "40px",
                  borderRadius: "10px",
                }}
                required
                onChange={(e) => {
                  handleAppointmentType(e);
                  //   onDirty();
                }}
              >
                {/* <option value="" disabled hidden>
                  {__str.select}
                </option>
                <option value="NEW" selected>
                  {__str.new}
                </option>
                <option value="FOLLOW_UP">{__str.followUp}</option> */}

                {appointmentServices.map((serviceItem) => {
                  return (
                    <option
                      value={serviceItem?.tenantServiceId}
                      key={serviceItem?.tenantServiceId}
                    >
                      {serviceItem?.title}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* Chief Compalint */}
            <div className="form-group mt-4">
              <label className="fw-sb font-14 text--terciary">
                {__str.chiefcomplaint}
              </label>
              {/* <h2 className="font-18 text--terciary">Chief Complaint</h2> */}
              <div>
                <textarea
                  className="form-control br-10 input font-14 border--default shadow-none"
                  type={"text"}
                  id={"chiefComplaint"}
                  defaultValue={chiefComplaint ?? ""}
                  onChange={(e) => {
                    e.preventDefault();
                    setChiefComplaint(e.target.value);
                    // handleChiefComplaint(e.target.value);
                  }}
                  rows={appointmentType?.key === "follow_up" ? 2 : 4}
                />
              </div>
            </div>
          </div>

          {/* Right side Section */}
          <div className=" col-6  border--left">
            {
              <>
                <div className="form-group font-14"></div>
                <div className="form-group">
                  <AppoinmentCalender
                    setScheduleDate={setScheduleDate}
                    EnableDirtyCheck={true}
                    setScheduleDateToSend={null}
                    onDirty={() => {}}
                    providerID={providerID}
                    appointmentType={appointmentType}
                    fecthFreeSlots1={fecthFreeSlots1}
                    appointmentDate={convertUTCtoTimzone(
                      new Date(),
                      Number(props?.metaData?.providers[0]?.timezoneOffset),
                      "YYYY-MM-DD"
                    )}
                    slotsTimezoneOffset={Number(props?.metaData?.providers[0]?.timezoneOffset)}
                    scheduleDate={scheduleDate}
                    // patientId={patientId}
                  />
                </div>
                <div className="form-group mt-2">
                  {isCustomAppointmentSelected === false ? (
                    <div>
                      <div className="row mt-4">
                        <div className="col-sm-4 ">
                          <label className="text--terciary font-14 fw-sb">
                            {__str.starttime}
                          </label>
                          <div className="form-group">
                            <select
                              className={`border--default br-10 col-sm-12 form-select cursor--pointer font-14 shadow-none ${classes["custom-select"]}`}
                              aria-label="Slot select"
                              required
                              onChange={(e) => {
                                handleFreeSlot(e);
                                // onDirty();
                              }}
                            >
                              <option
                                value=""
                                className="col-sm-12 font-14"
                                selected={unSelectFreeSlot ? true : false}
                                // selected
                                disabled
                                hidden
                              ></option>

                              {freeSlots?.length > 0 ? (
                                freeSlots?.map((slotObject, index) => {
                                  let aptStartTime = changeDateTimeFormat(
                                    slotObject?.startDateTime,
                                    "LT"
                                  );
                                  let aptEndTime = changeDateTimeFormat(
                                    slotObject?.EndTime,
                                    "LT"
                                  );

                                  return (
                                    <option
                                      key={slotObject?.startDateTime + index}
                                      className="col-sm-12 font-14 p-1 mt-1"
                                      // data={slotItem?.id}
                                      value={slotObject?.startDateTime}
                                      style={{ textAlign: "justify" }}
                                    >
                                      {aptStartTime}
                                    </option>
                                  );
                                })
                              ) : !isSlotsLoading && freeSlots?.length <= 0 ? (
                                <option
                                  key={0}
                                  className="col-sm-12 font-14 p-1 mt-1"
                                  // data={slotItem?.id}
                                  value=""
                                  style={{ textAlign: "justify" }}
                                  disabled
                                >
                                  {__str.noSlotFound}
                                </option>
                              ) : (
                                <option
                                  key={0}
                                  className="col-sm-12 font-14 p-1 mt-1"
                                  // data={slotItem?.id}
                                  value=""
                                  style={{ textAlign: "justify" }}
                                  disabled
                                >
                                  Loading Slots
                                </option>
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div style={{ width: "10vw" }}>
                            <label className="font-14 fw-sb text--terciary">
                              Duration{" "}
                            </label>
                          </div>
                          <div
                            className="font-14  text--terciary align-self-center br-10"
                            style={{ border: "1px solid #d9e8ec" }}
                          >
                            <div className="center pt-1 ps-2 py-2">
                              {isDurationLoading ? (
                                <Skeleton />
                              ) : appointmentDuration !== undefined ? (
                                appointmentDuration + " minutes"
                              ) : (
                                <>&nbsp;</>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </>
            }
          </div>

          <div className="row mt-3">
            <Divider />
            <div className="col-12 mb-3 mt-2">
              <h2 className="fw-sb font-18 text--terciary">{"Diagnosis"}</h2>
            </div>
            {props?.metaData?.diagnoses?.length > 0 ? (
              <ICD10CodeListItems
                icd10List={props?.metaData?.diagnoses}
                iCD10CodeList={iCD10CodeList}
                setICD10CodeList={setICD10CodeList}
              />
            ) : (
              <span className="font-12 text--terciary">
                {"No data available"}
              </span>
            )}
          </div>
        </div>
      </form>
      <div className="font-12 text--terciary text-end">
        <button
          form="newAppointmentForm"
          type="submit"
          className={`btn blue_task_button bg--blue fw-sb font-14 text-white btn-lg b2 px-5 br-10 py-1 ${
            props?.isSending ? "bg--disabled" : ""
          }`}
          disabled={iCD10CodeList?.length === 0}

          // onClick={(e)=>{handleNext(e)}}
        >
          {"Next"}
        </button>
      </div>
    </>
  );
};

export default MakeAppointmentForm;

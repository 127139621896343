import { ActionTypes } from "../constants/actionTypes"

const initialState = {
  Diagnosis: {
    Note: "",
    Primary: [],
    Secondary: []
  }
}

const setDiagnosisReducer = (state = initialState, action) => {
  if (action.type === ActionTypes.SET_DIAGNOSIS) {
    return { ...state, Diagnosis: action.payload }
  }
  return state
}

export default setDiagnosisReducer;
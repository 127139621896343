import { useState, useEffect } from "react";
import TextArea from "../forms/TextArea";
import CustomDatePicker from "../UI/General/CustomDatePicker";
import { PnToolTip } from "../UI/Reusable/PnComponents";
import classes from "./ChangeRx.module.css";
import CalenderDatePicker from "../UI/General/CalenderDatePicker";

export const EditMedicationForm = ({
    quantity,
    handleDispenseQuantity,
    daysSupply,
    sigs,
    requestedMedication,
    _str,
    selectedSig,
    setSelectedSig,
    setDaysSupply,
    showQuantityError,
    showRefillsError,
    showDaysSupplyError,
    setEffectiveDate,
    effectiveDate,
    refills,
    handleRefills,
    handleDaysSupply,
    substitutions,
    setSubstitutions,
    setDaw,
    daw,
    setShowDaysSupplyError,
    setShowQuantityError,
    setShowRefillsError,
    handleSigSelection }) => {

    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const joined = [year, month, day].join("-");
    const [customSigFlag, setCustomSigFlag] = useState(false);



    useEffect(() => {
        const s = sigs?.find((sigItem) => (sigItem.textValue === requestedMedication?.sigText));
        if (s !== null && s !== undefined) {
            handleSigSelection(s, quantity);
        }
        else if (sigs !== undefined) {

            setSelectedSig({ "textValue": requestedMedication?.sigText })
            setCustomSigFlag(true)
        }
        return () => {
            setShowDaysSupplyError(p => false);
            setShowQuantityError(p => false);
            setShowRefillsError(p => false);
        }
    }, [sigs]);

    const handledCustomSig = (sigText) => {
        setSelectedSig({ "textValue": sigText })
    }

    const handleSubstitution = (e) => {

        setDaw(e.target.checked);
        if (e.target.checked) {
            setSubstitutions(p => "1");
        }
        else {
            setSubstitutions(p => "0");
        }

    }



    return (
        // This form uses controlled inputs, don't change without any solid reason. No need to get references to the DOM element in order to update the values.
        <>
            <form id="editMedicationForm" className="text--terciary font-12" onSubmit={e => e.preventDefault()}>
                <div>
                    <span className="text--terciary font-12 ">{_str.drugDescription}: &nbsp;</span>
                    <span >{requestedMedication?.drugDescription}</span>
                </div>
                <div className="row pb-1">
                    <div className="col-sm-2 pb-0 col-form-label">
                        <span style={{ verticalAlign: 'middle' }}>{_str.sig}: &nbsp;</span>
                    </div>
                    <div className="col-sm-9">
                        {
                            customSigFlag === false ?
                                <select
                                    className={`input form-select font-12 text--terciary  border--default shadow-none br-10  text-wrap`}
                                    aria-label="Strength select"
                                    onChange={(e) => {
                                        const s = sigs.find((sigItem) => (Number(sigItem.id) === Number(e.target.value)));
                                        handleSigSelection(s, quantity);
                                        e.target.value === "custom_sig" ? setCustomSigFlag(true) : setCustomSigFlag(false)
                                    }}
                                    required
                                >
                                    <option value="" selected disabled>Select a Sig</option>
                                    {sigs?.map((sig) => {
                                        return <option
                                        key={sig?.id}
                                        value={sig?.id}
                                        selected={sig?.textValue === requestedMedication?.sigText}
                                        
                                        >{sig?.textValue}</option>
                                    })}
                                    <option value="custom_sig">Enter Sig manually</option>

                                </select>
                                :
                                <TextArea
                                    type={"text"}
                                    rows={3}
                                    defaultValue={requestedMedication?.sigText}
                                    className={`form-control border--default br-10 shadow-none mb-2 font-12 ${classes.input}`}
                                    gettext={handledCustomSig}
                                    required
                                />
                        }
                    </div>
                </div>

                <div className="row pb-2 justify-content-between">
                    <div className="col-5">
                        <div className="row">
                            <div className="col-12 col-sm-5 col-form-label pb-0">
                                <span className="align-middle">{_str.quantity}:</span>
                            </div>
                            <div className="col-12 col-sm-6">
                                <input required type="text" onChange={(e) => handleDispenseQuantity(e)} value={quantity} className={`form-control font-12 br-10 shadow-none input form-control-sm ${showQuantityError ? 'border--error' : ''}`} id="qty" placeholder="" />
                                {/* {showQuantityError === true ? <span className="text--danger font-10">Invalid quantity</span> : null} */}
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="row">
                            <div className="col-12 col-sm-5 pe-0 ps-0 col-form-label pb-0">
                                <span className="align-middle">{_str.daysSupply}:</span>
                            </div>
                            <div className="col-12 col-sm-5">
                                <input type="text" value={daysSupply} onChange={(e) => {
                                    handleDaysSupply(e)
                                }} className={`form-control font-12 br-10 shadow-none input form-control-sm ${showDaysSupplyError ? 'border--error' : ''}`} id="daysSupply" placeholder="" />
                            </div>
                        </div>
                    </div>

                </div>

                <div className="row py-1">
                    <div className="col-5">
                        <div className="row">
                            <div className="col-sm-5 col-form-label pb-0">
                                <span className="align-middle">{_str.refills}: &nbsp;</span>
                            </div>
                            <div className="col-sm-6">
                                <PnToolTip
                                    title={`Total quantity to be dispensed = ${isNaN((Number(refills) * Number(quantity)) + Number(quantity)) === true ? 0 : (Number(refills) * Number(quantity)) + Number(quantity)} ${"".concat(requestedMedication?.quantityUnitOfMeasure?.toLowerCase() + "(s)")}`}>

                                    <input required value={refills} onChange={(e) => handleRefills(e)} type="number" className={`form-control font-12 br-10 shadow-none input form-control-sm ${showRefillsError ? 'border--error' : ''}`} id="numRefills" placeholder="" />
                                </PnToolTip>
                            </div>
                        </div>
                    </div>
                    <div className="col-5 offset-1">
                        <div className="row">
                            <div className="col-12 p-0">
                                <div className="form-group">

                                    <input
                                        type="checkbox"
                                        checked={daw}
                                        onChange={handleSubstitution}
                                        className={`form-check-input font-14 shadow-none border--default `}
                                        id="sub"
                                    />

                                    <label
                                        className="form-check-label align-top font-12 ps-2  text--terciary "
                                        htmlFor="sub"
                                    >
                                        Dispense as Written
                                    </label>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* 
                <div className="row pt-1">
                    <div className="col-12">
                        <div className=" ">
                            <label
                                className="form-check-label font-12 pe-2 text--terciary "
                                htmlFor="sub"
                            >
                                Substitution Allowed:
                            </label>
                            <input
                                type="checkbox"
                                className={`form-check-input font-14 shadow-none border--default `}
                                id="sub"
                            />

                        </div>
                    </div>
                </div> */}


                <div className="row py-1">

                    <div className="col-12 col-sm-3 pe-0 col-form-label pb-0">
                        <span className="align-middle">Effective Date: </span>
                    </div>
                    <div className="col-12 col-sm-8 ps-0 ms-1">
                        <CalenderDatePicker
                            dobHandler={setEffectiveDate}
                            dateFormat="MMM d, yyyy"
                            date={effectiveDate !== undefined ? effectiveDate : joined}
                            // minDate={parseISO(props?.drugData?.EarliestFillDate?.Date) }
                            minDate={joined}
                            prescribeDrug={true}
                            f13={true}
                        />
                    </div>
                </div>
            </form>
        </>
    )
}
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import { useUnsavedWorkPrompt } from "../../components/hooks/useUnsavedWorkPrompt";
import { unsavedWorkHandler } from "../../utils/helpers/HelperFunctions";
import CptCodeSearch from "../../components/cptcodes-search/CptCodeSearch";
import useCptCodeSearch from "../../components/cptcodes-search/useCptCodeSearch";
import CurrencyInput from "react-currency-input-field";
import sendHttpRequest from "../../components/hooks/sendHttpRequest";
import { useAuth } from "../../components/hooks/useAuth";
function EditFeeScheduleModal(props) {
    const [isDirty, onDirty, onPristine] = useUnsavedWorkPrompt();
    const { user } = useAuth();
    const { cptSuggestions, setCptSuggestions, isCptLoading, setIsCptLoading, isCptError, setIsCptError, cptSearchInputValue, setCptSearchInputValue, cptCodeName, setCptCodeName, selectedCptCode, setSelectedCptCode, searchCptCode, handleCptCodeSearchInput, handleAddCptCode, handleRemoveCptCode } = useCptCodeSearch();
    const [billCharge, setBillCharge] = useState(props?.feeScheduleToEdit?.price || '');
    const [description, setDescription] = useState(props?.feeScheduleToEdit?.description || '');

    const addCptCodeToList = (addedCptCode) => {
        handleAddCptCode(addedCptCode);
        // setCptCodesList(prevList => [...prevList, addedCptCode]);
    }
    const [isSaving, setIsSaving] = useState(false);


    async function editFeeSchedule(selectedCptCode, billCharge, feeScheduleId) {
        setIsSaving(true);
        const options = {
            method: 'PUT',
            url: `/claims/tenants/${user?.tenantId}/fee-schedule/${feeScheduleId}`,
            data: {
                description: selectedCptCode?.description,
                price: billCharge,
                code: {
                    code: selectedCptCode?.code?.code !== undefined ? selectedCptCode?.code?.code : selectedCptCode?.code
                }
            }
        }
        const httpResponse = await sendHttpRequest(options);
        if (!httpResponse.error) {
            props?.setReloadSchedule(p => !p);
            props?.handleModal();
        }
        else {
            console.log(httpResponse.error);
            console.log(httpResponse);

        }
        setIsSaving(false);
    }

    function handleDescription(event) {
        const modifiedDescription = event.target.value;
        setSelectedCptCode(previousObject => ({ ...previousObject, "description": modifiedDescription }));
    }


    useEffect(() => {
        handleAddCptCode(props?.feeScheduleToEdit);
        setCptCodeName(props?.feeScheduleToEdit?.code?.code);
    }, [props?.feeScheduleToEdit])

    return (
        <Modal
            show={props.show}
            onHide={() => {
                unsavedWorkHandler(
                    "modal",
                    "There are unsaved changes, do you wish to continue?",
                    isDirty,
                    props.handleModal,
                    onPristine,
                    props.handleModal
                );
                // props.handleModal();
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            backdropClassName="modal-backdrop"
            contentClassName={`modal-border modal-shadow `}
            scrollable={false}
            centered
        >
            <Modal.Header>
                <span
                    style={{
                        lineHeight: "12px",
                        width: "18px",
                        fontSize: "8pt",
                        color: "#336383",
                        position: "absolute",
                        top: 15,
                        right: 15,
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        unsavedWorkHandler(
                            "modal",
                            "There are unsaved changes, do you wish to continue?",
                            isDirty,
                            props.handleModal,
                            onPristine,
                            props.handleModal
                        );
                        // props.handleModal();
                    }}
                >
                    {<CloseIcon />}
                </span>
                <Modal.Title className="font-20 fw-sb">Edit Fee Schedule</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container-fluid">
                    <div className="row ">
                        <div className="col-sm-4">
                            <label className="font-12 fw-sb text--secondary ms-1">Billing Code</label>
                            <CptCodeSearch
                                suggestions={cptSuggestions}
                                setSuggestions={setCptSuggestions}
                                isLoading={isCptLoading}
                                setIsLoading={setIsCptLoading}
                                isError={isCptError}
                                setIsError={setIsCptError}
                                searchInputValue={cptSearchInputValue}
                                setSearchInputValue={setCptSearchInputValue}
                                cptCodeName={cptCodeName}
                                setCptCodeName={setCptCodeName}
                                selectedCptCode={selectedCptCode}
                                setSelectedCptCode={setSelectedCptCode}
                                searchCptcode={searchCptCode}
                                handleSearchInput={handleCptCodeSearchInput}
                                handleAddCptCode={addCptCodeToList}
                            />
                        </div>
                        <div className="col-sm-6">
                            <label className="font-12 fw-sb text--secondary ms-1">Description</label>
                            <input
                                type="text"
                                className="form-control input font-12 br-10 shadow-none border--default"
                                placeholder="Description"
                                onChange={handleDescription}
                                defaultValue={selectedCptCode?.description || ""}
                            />

                        </div>


                        <div className="col-sm-2">
                            <label className="font-12 fw-sb text--secondary ms-1">Bill Charge</label>
                            <CurrencyInput
                                id="bill_charge"
                                name="cost"
                                placeholder="$0.00"
                                className="text--terciary text-center font-12 input form-control shadow-none border--default br-10"
                                prefix="$"
                                decimalSeparator="."
                                groupSeparator=","
                                intlConfig={{ locale: 'en-US', currency: 'USD' }}
                                onValueChange={(value, name) => setBillCharge(value)}
                                value={billCharge}
                            // disabled = {true}
                            />

                        </div>
                    </div>

                    {selectedCptCode !== null && selectedCptCode !== undefined ? <div className="row my-2">
                        <div className="col-sm-12">
                            <div>
                                <span className="fw-sb font-12 text--secondary">Selected Billing Code: </span>
                                <span className="font-12 text--terciary">{selectedCptCode?.code?.code} - {selectedCptCode?.description}</span>
                            </div>
                        </div>
                    </div> : null}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="row justify-content-end">
                    <button
                        disabled={isSaving}
                        onClick={() => { editFeeSchedule(selectedCptCode, billCharge, props?.feeScheduleToEdit?.feeScheduleId) }}
                        className="btn blue_task_button br-10 shadow-none">Save</button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default EditFeeScheduleModal
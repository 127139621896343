export const Divider = ({ color, thickness, style = {} }) => {
    return (
        <div
            style={{
                width: '100%',
                height: thickness,
                backgroundColor: color,
                ...style,
            }}
        />
    );
};
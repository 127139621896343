import { createTheme } from "@mui/material/styles";
import { Box, Tooltip, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";



export const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`prescribenow-tabpanel-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

export const theme = createTheme({
    palette: {
        primary: {
            main: "#336383",
            light: "#336383",
            dark: "#336383",
            contrastText: "#336383",
        },
        secondary: {
            main: "#EC609B",
        },
    },
    typography: {
        fontFamily: "Montserrat",
        fontSize: 14,
    },
});


export const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    typography: {
        fontFamily: "Montserrat",
    },
    "&:not(:last-child)": {
        borderBottom: 0,
    },
    "&:before": {
        display: "none",
    },
    marginBottom: "5px",
    borderRadius: "10px",
    paddingTop: "8px",
    paddingBottom: "8px",

    //   backgroundColor:'#FAFCFC'
}));

export const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={
            <ExpandMoreIcon
                sx={{
                    fontSize: "28px",
                    p: 0.4,
                    transform: "rotate(180deg)",
                    height: "24px",
                    width: "24px",
                }}
            />
        }
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: "none",
    flexDirection: "row",
    "& .MuiAccordionSummary-expandIconWrapper": {
        backgroundColor: "#d9e8ec",
        color: "#004FD4",
        borderRadius: "5px",
    },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        // transform: "rotate(180deg)",
        backgroundColor: "#004FD4",
        color: "#fff",
        borderRadius: "5px",
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(0),
    },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid #d9e8ec",
}));

export const PnToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#336383",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#336383",
    },
}));


export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#FAFCFC",
        color: "#336383",
        fontWeight: 400,
        fontSize: 12,
        fontFamily: "Montserrat",
        // width: 150,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        paddingRight: "0px",
        fontFamily: "Montserrat",
        wordWrap: "break-word"
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: "#FAFCFC",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export const UnStyledTableRow = styled(TableRow)(({ theme }) => ({

    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));



import classes from "./BillingTileContainer.module.css"
const BillingTileContainer = (props) => {
  
  return (
    <>
      <div className={`${classes['billing-tile-container']} ${props?.bgColor}`} style={props?.minHeight !== undefined ? {"minHeight": props?.minHeight }:{"minHeight": "10rem",...props?.style}}>
            {props.children}
        </div>
    </>
  );
};

export default BillingTileContainer;

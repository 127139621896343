import { Box, Grid, Typography, Stack, Skeleton, Backdrop, CircularProgress } from "@mui/material";
import classes from "../patient/PatientItem.module.css";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import sendHttpRequest from "../hooks/sendHttpRequest";
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";
import { useDispatch, useSelector } from "react-redux";
import PracticeUsersItem from "../patient/PracticeUsersItem";
import NewUserModal from "../UI/Modals/PatientModals/NewUserModal";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { providerUrl } from "../../res/endpointurls";
import EmailInvitationModal from "../UI/Modals/EmailInvitationModal";
import ProviderImportModal from "../providerImport/ProviderImportModal";
import PUCreateDropdown from "../prescription/PUCreateDropdown";
import { ChevronLeft } from "@mui/icons-material";
import { PRACTICE_MANAGEMENT } from "../../utils/helpers/subscriptionsConstants";

const PracticeUsersPage = (props) => {
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);


  const [isLoading, setIsLoading] = useState(true);
  const [patientId, setPatientId] = useState("");
  const [fileUploadModal, setFileUploadModal] = useState(false);
  const [statusCodePrescriber, setStatusCodePrescriber] = useState("");
  const [updatePatientId, setUpdatePatientId] = useState("");
  const [openFacesheatModal, setOpenFacesheatModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [facesheetUploaded, setFacesheetUploaded] = useState(false);
  const [facesheetUploadedFail, setFacesheetUploadedFail] = useState(false);
  const [isUploading, setisUploading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [isEditSent, setIsEditSent] = useState(false);
  const [newUserModal, setNewUserModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [userToEdit, setUserToEdit] = useState([]);
  const { user } = useAuth();

  //Provider Dropdown code
  const dispatch = useDispatch();
  const [prescribersList, setPrescribersList] = useState([]);
  const [isSessionUpdated, setIsSessionUpdated] = useState(false);
  const [isPrescribersFetched, setisPrescribersFetched] = useState(false);
  const userRole = user?.role?.toLowerCase();

  //Inviate User
  const [openInviteUserModal, setOpenInviteUserModal] = useState(false);
  const [openUploadUserModal, setOpenUploadUserModal] = useState(false);
  const [emailInviattionToast, setEmailInviattionToast] = useState(false);
  const [emailInvitationToastSevrity, setEmailInvitationToastSevrity] = useState("")
  const [emailInvitationErrorMsg, setEmailInvitationErrorMsg] = useState("");

  const [manualUserState, setManualUserState] = useState({
    manualUserResponseMsg: "",
    manualUserResponseSevrity: "",
    manualUserResponse: false,
  })

  //Navigate to Add user page
  const navigate = useNavigate();

  const fetchAllPrescribers = async () => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: providerUrl(user?.tenantId),
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
      setisPrescribersFetched(true);
      setPrescribersList(httpResponse.data);
    } else {
      setPrescribersList([]);
      console.log(httpResponse.error);
    }
    setIsLoading(false);
    setStatusCodePrescriber(httpResponse.status);
  };

  const fetchSingleProvider = async (id) => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/providers/${id}?includeDisplayClaimsTab=true&tenantId=${user?.tenantId}`,
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
      setisPrescribersFetched(true);
      setPrescribersList(httpResponse.data);
      navigate("/edit-user", { state: { provider: httpResponse.data } });
    } else {
      console.log(httpResponse.error);
    }
  };

  const deleteSingleProvider = async (id) => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/provider/${id}/${user?.tenantId}`,
      method: "delete",
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
      setEmailInviattionToast(true);
      setEmailInvitationToastSevrity("success");
      setEmailInvitationErrorMsg("Practice user deleted successfully");
      fetchAllPrescribers();
    } else {
      setEmailInviattionToast(true);
      setEmailInvitationToastSevrity("error");
      setEmailInvitationErrorMsg(httpResponse?.errorMessage?.message);
      // console.log(httpResponse);
      fetchAllPrescribers();
    }
  };

  const handleNewUserModal = () => {
    setNewUserModal(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsSent(false);
  };

  const handleEditClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsEditSent(false);
  };

  const handleFacesheetClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setFacesheetUploaded(false);
  };

  const handleFacesheetUploadFailClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setFacesheetUploadedFail(false);
  };
  const handleEmailInvitationToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setEmailInviattionToast(false);
    setManualUserState({ ...manualUserState, manualUserResponse: false })
  };

  const handleNewUserModalClose = () => {
    setNewUserModal(false);
  };

  const handleEditUserModalClose = () => {
    setEditUserModal(false);
  };

  const handleFileUploadModal = (patientId) => {
    setPatientId(patientId);
    setFileUploadModal(!fileUploadModal);
  };

  const handleFacesheatModal = () => {
    setOpenFacesheatModal(!openFacesheatModal);
  };

  if (isSessionUpdated === true) {
    fetchAllPrescribers();
    setIsSessionUpdated(false);
  }

  const handleInviteUserModal = () => {
    setOpenInviteUserModal(false);
  }

  const handleUploadUserModal = () => {
    setOpenUploadUserModal(!openUploadUserModal);
  }

  const sheetUploadStatus = (status) => {
    setManualUserState({
      manualUserResponseMsg: status.message,
      manualUserResponseSevrity: status.severity,
      manualUserResponse: true,
    })
  }

  useEffect(() => {
    fetchAllPrescribers();
  }, []);


  return (
    <>
      {openUploadUserModal && (
        <ProviderImportModal
          show={openUploadUserModal}
          handleModal={
            handleUploadUserModal
          }
          sheetUpload={
            sheetUploadStatus
          }
        />
      )}

      {newUserModal && (
        <NewUserModal
          show={newUserModal}
          handleNewUserModal={handleNewUserModal}
          handleNewUserModalClose={handleNewUserModalClose}
          setIsSent={setIsSent}
          fetchAllPrescribers={fetchAllPrescribers}
        />
      )}


      {
        openInviteUserModal &&
        <EmailInvitationModal
          show={openInviteUserModal}
          handleModal={handleInviteUserModal}
          setEmailInviattionToast={setEmailInviattionToast}
          setEmailInvitationErrorMsg={setEmailInvitationErrorMsg}
          setEmailInvitationToastSevrity={setEmailInvitationToastSevrity}
        />
      }



      <div className="container-fluid">
        <div className="row p-1 py-3 bg-white border-bottom justify-content-between">
          <div className="col-sm-3 align-self-center">
            <div className="d-flex flex-row">
              <div
                style={{ width: "32px", height: "32px" }}
                className={`bg--background  br-10 me-3 border--default border align-self-center text-center my-auto `}
                onClick={() => {
                  navigate(-1)
                }}
              >
                <ChevronLeft
                  style={{ color: "#EC609B", height: "20px", weight: "20px" }}
                  className="cursor-pointer my-auto mt-1"
                />
              </div>
              <div className="font-22 fw-sb text--terciary align-self-center">
                Practice Users
              </div>
            </div>
          </div>
          {/* Provider dropdown */}
          <div className="col-sm-4 align-self-center">
            <div className="row ">
              <div className="col-sm-3"></div>
              <div className="col-sm-8 ">
                <div className="input-group  border--default br-10 bg--disabled ">
                  <input
                    type="text"
                    disabled
                    className={`form-control disabled shadow-none border-0 ms-1 bg--disabled`}
                    placeholder="Search for user"
                    onChange={(event) => {
                      // patients.filter(()=>{});
                      // console.log(patients);
                      // patients.filter((patient)=>{return (patient?.FirstName?.concat(" ", patient?.LastName).toLowerCase().includes(event.target.value.toLowerCase())) && patient; }))
                    }}
                  />

                  <button className="btn shadow-none" type="submit">
                    <SearchIcon className="text--secondary" />
                  </button>
                </div>
              </div>
              {/* <div className="col-sm-5" disabled> */}
              {/* <div
                  className={`${classes.filter} `}
                  style={{ backgroundColor: "#F2F7F8" }}
                >
                  <FilterAltOutlinedIcon
                    sx={{ color: "#336383", my: "10px", marginLeft: "3px" }}
                  />
                  <span className="font-14 fw-sb align-self-center text--terciary ms-4 d-inline-block ">
                    Filter
                  </span>
                </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-3">
        <div className="row mb-1 justify-content-end">
          <div className="d-flex flex-row justify-content-end">
            <div className="d-flex align-items-end me-2">
              <button
                onClick={() => {
                  setOpenInviteUserModal(true);
                }}
                className="btn d9e8ec--button br-10 text-white px-4 fw-sb font-14"
                hidden={subscriptionPrivilegeGroups[PRACTICE_MANAGEMENT]?.ijt?.active === false ? true : false}
              >
                Invite User
              </button>
            </div>
            <div className="d-flex align-items-end me-5">
              <PUCreateDropdown
                addViaSheet={"Add via bulk import"}
                link={"/add-user"}
                title={"Add User"}
                handleModal={
                  handleUploadUserModal
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3 mb-5 justify-content-center">
        <div className="col-sm-11 ">
          <Grid
            container
            sx={{ marginBottom: "-8px" }}
            p={1}
            className={`bg-white  ${classes["row__head"]} `}
          >
            {/* <Grid item xs={isPracticeAdmin === "PracticeAdmin" ? 2 : 2.5}> */}
            <Grid item xs={subscriptionPrivilegeGroups[PRACTICE_MANAGEMENT]?.apu?.active === true || subscriptionPrivilegeGroups[PRACTICE_MANAGEMENT]?.rpu?.active === true || subscriptionPrivilegeGroups[PRACTICE_MANAGEMENT]?.dpu?.active === true ? 1.5 : 2}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "right",
                      m: 1,
                      fontFamily: "Montserrat",
                      fontWeight: "400",
                      fontSize: "12px",
                      color: "#336383",
                    }}
                  >
                    {/* PatientName */}
                    Name
                  </Box>
                </Typography>
              </Stack>
            </Grid>
            {/* <Grid item xs={isPracticeAdmin === "PracticeAdmin" ? 2 : 2.5}> */}
            <Grid item xs={subscriptionPrivilegeGroups[PRACTICE_MANAGEMENT]?.apu?.active === true || subscriptionPrivilegeGroups[PRACTICE_MANAGEMENT]?.rpu?.active === true || subscriptionPrivilegeGroups[PRACTICE_MANAGEMENT]?.dpu?.active === true ? 1.5 : 2}>
              <Typography component="div">
                <Box
                  sx={{
                    textAlign: "left",
                    // ml: 1,
                    mt: 1,
                    fontFamily: "Montserrat",
                    fontSize: "12px",
                    color: "#336383",
                  }}
                >
                  {/* Patient Age */}
                  Role
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography component="div">
                <Box
                  sx={{
                    textAlign: "left",
                    mt: 1,
                    fontFamily: "Montserrat",
                    fontSize: "12px",
                    color: "#336383",
                  }}
                >
                  {/* Last request Date */}
                  Speciality
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography component="div">
                <Box
                  sx={{
                    textAlign: "left",
                    // ml: -4,
                    mt: 1,
                    fontFamily: "Montserrat",
                    fontSize: "12px",
                    color: "#336383",
                  }}
                >
                  {/* Last request Date */}
                  Email
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography component="div">
                <Box
                  sx={{
                    textAlign: "right",
                    fontWeight: "400",
                    // ml: 5,
                    mt: 1,
                    fontFamily: "Montserrat",
                    fontSize: "12px",
                    color: "#336383",
                  }}
                >
                  {/* Last Prescripton */}
                  Mobile Phone
                </Box>
              </Typography>
            </Grid>
            {/* <Grid item xs={isPracticeAdmin === "PracticeAdmin" ? 3: 2}> */}
            <Grid item xs={2}>
              <Typography component="div">
                <Box
                  sx={{
                    textAlign: "center",
                    fontWeight: "400",
                    mt: 1,
                    fontFamily: "Montserrat",
                    fontSize: "12px",
                    color: "#336383",
                  }}
                >
                  {/* Last Prescripton */}
                  Status
                </Box>
              </Typography>
            </Grid>
          </Grid>
          {isLoading ? (
            <Skeleton animation="wave" />
          ) : statusCodePrescriber === 200 ? (
            prescribersList?.length > 0 ? (
              prescribersList?.map((provider, index) => {
                if (index === prescribersList?.length - 1) {
                  return (
                    <PracticeUsersItem
                      key={index}
                      provider={provider}
                      user={user}
                      userRole={userRole}
                      isLast={true}
                      handleFileUploadModal={handleFileUploadModal}
                      fetchSingleProvider={fetchSingleProvider}
                      deleteSingleProvider={deleteSingleProvider}
                    />
                  );
                } else {
                  return (
                    <PracticeUsersItem
                      key={index}
                      provider={provider}
                      user={user}
                      userRole={userRole}
                      handleFileUploadModal={handleFileUploadModal}
                      fetchSingleProvider={fetchSingleProvider}
                      deleteSingleProvider={deleteSingleProvider}
                    />
                  );
                }
              })
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      </div>
      <Backdrop
        sx={{
          color: "#EC609B",
          zIndex: (theme) => theme.zIndex.drawer + 1000,
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CustomizedSnackbars
        message={"User added successfully."}
        severity="success"
        open={isSent}
        handleClose={handleClose}
      />
      <CustomizedSnackbars
        message={"User updated successfully."}
        severity="success"
        open={isEditSent}
        handleClose={handleEditClose}
      />
      <CustomizedSnackbars
        message={"User added successfully."}
        severity="success"
        open={facesheetUploaded}
        handleClose={handleFacesheetClose}
      />
      <CustomizedSnackbars
        message={errorMessage}
        severity="error"
        open={facesheetUploadedFail}
        handleClose={handleFacesheetUploadFailClose}
      />
      <CustomizedSnackbars
        message={emailInvitationErrorMsg}
        severity={emailInvitationToastSevrity}
        open={emailInviattionToast}
        handleClose={handleEmailInvitationToast}
      />
      <CustomizedSnackbars
        message={manualUserState?.manualUserResponseMsg}
        severity={manualUserState?.manualUserResponseSevrity}
        open={manualUserState?.manualUserResponse}
        handleClose={handleEmailInvitationToast}
      />
    </>
  );
};

export default PracticeUsersPage;

import { Box, Grid, Typography, Skeleton, CircularProgress, Backdrop } from "@mui/material";
import EncounterItem from "../patient/EncounterItem";
import classes from "../patient/PatientItem.module.css";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import SummaryModal from "../UI/Modals/ProviderModals/SummaryModal";
import AddendumModal from "../UI/Modals/ProviderModals/AddendumModal";
import AddendumShowModal from "../UI/Modals/ProviderModals/AddendumShowModal";
import sendHttpRequest from "../hooks/sendHttpRequest";
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";
import { useSelector } from "react-redux";
import { RolesMap, RolesTextConversion } from "../../utils/helpers/RolesMap";
import { useAuth } from "../hooks/useAuth";
import { addendumUrl, fetchEncountersUrl, providerWithNpiUrl } from "../../res/endpointurls";
import { summaryGeneration } from "../../utils/helpers/DataLoaders";
import { strings } from "../../res/strings";
import { RefreshDataComponent } from "../UI/General/RefreshDataComponent";
import { useContext } from "react";
import { VideoCallContext } from "../UI/AgoraHooks/VideoCallContext";
import { ChevronLeft } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ENCOUNTERS } from "../../utils/helpers/subscriptionsConstants";


const EncountersPage = (props) => {

  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);

  const {
    user: { userId },
    user,
    getSecondaryUser, selectUser
  } = useAuth();

  let uId = null;
  try {
    uId = getSecondaryUser();
  }
  catch (e) {
    console.error("PN_CLIENT_ERR: Cannot get secondary user");
  }
  const videoCallContext = useContext(VideoCallContext);
  const isVideoCallActive = videoCallContext.isVideoCallActive.current;
  const navigate = useNavigate();

  const [dummyState, setDummyState] = useState(false);
  // console.log("Is Video Call Active", isVideoCallActive);
  const __str = strings.en;
  const [areEncountersLoading, setAreEncountersLoading] = useState(true);

  const [isLoadingEnc, setIsLoadingEnc] = useState(true);
  const [isLoadingAddend, setIsLoadingAddend] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [addendumTitle, setAddendumTitle] = useState("");
  const [addendumBody, setAddendumBody] = useState("");
  const [openAddenModal, setOpenAddenModal] = useState(false);
  const [openAddenShowModal, setOpenAddenShowModal] = useState(false);
  const [summary, setSummary] = useState({});
  const [addendums, setAddendums] = useState("");
  const [addEncounterId, setAddEncounterId] = useState("");

  const [prescriptionReference, setEncounters] = useState([]);
  const [isSent, setIsSent] = useState(false);
  const [statusCodeEncounters, setStatusCodeEncounters] = useState(null);
  const [open, setOpen] = useState(false);

  const [prescribersList, setPrescribersList] = useState([]);
  const [selectedProviderId, setSelectedProviderId] = useState();
  const [isSessionUpdated, setIsSessionUpdated] = useState(false);
  const [isPrescribersFetched, setisPrescribersFetched] = useState(false);
  const prescriberInfo = useSelector((state) => state.PrecriberData.prescriber);
  const fetchAllPrescribers = async () => {
    setisPrescribersFetched(true);
    const httpResponse = await sendHttpRequest({
      url: providerWithNpiUrl(user?.tenantId),
      method: "GET",
    });
    if (!httpResponse.error) {
      setPrescribersList(httpResponse.data);
    } else {
      setPrescribersList([]);
      console.log(httpResponse.error);
    }
    setisPrescribersFetched(false);
  };

  const handleBackdropClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const handleProviderId = (event) => {

    if (event.target.value !== getSecondaryUser()) {
      setSelectedProviderId(event.target.value);
      selectUser(event.target.value);
      fetchEncounters(event.target.value);
    }

  };

  const fetchEncounters = async (userId) => {

    setAreEncountersLoading(true);
    const httpResponse = await sendHttpRequest({
      url: fetchEncountersUrl(userId,),
      method: "GET",
      params: {
        tenantId: user?.tenantId
      }
    });
    if (!httpResponse.error) {
      let encounterList = [];
      httpResponse.data.forEach(encounterItem => {
        let encounterStatus = encounterItem?.encounter?.status?.toLowerCase();
        let providerNote = encounterItem?.providerNote;

        if (
          encounterStatus === "closed" ||
          encounterStatus === "started" ||
          encounterStatus === "dispatched" ||
          (providerNote !== undefined && providerNote !== null)
        ) {
          encounterList.push(encounterItem);
        }
      });
      setEncounters(encounterList);
    } else {
      console.log(
        `Error in ${fetchEncountersUrl(userId)}`,
        httpResponse?.error,
        " m = ",
        httpResponse?.errorMessage?.error
      );
    }
    setStatusCodeEncounters(httpResponse.status);
    setAreEncountersLoading(false);
  };

  const fetchAddendums = async (encId) => {
    setIsLoadingAddend(true);
    const httpResponse = await sendHttpRequest({
      url: addendumUrl(encId),
      method: "GET",
    });
    if (!httpResponse.error) {
      setAddendums(httpResponse.data);
      setIsLoadingAddend(false);
      handleBackdropClose();
      setOpenAddenShowModal(!openAddenShowModal);
    } else {
      console.log(
        `Error in ${addendumUrl(encId)}`,
        httpResponse?.error,
        " m = ",
        httpResponse?.errorMessage?.error
      );
    }
    handleBackdropClose();
    setIsLoadingAddend(false);
  };

  const fetchPatientSummary = async (encId) => {
    const data = await summaryGeneration(encId);
    await setSummary(data);
    handleBackdropClose();
    setOpenModal(!openModal);
    setIsLoadingEnc(false);
  };

  function handleModal(encId) {
    if (encId !== "") {
      fetchPatientSummary(encId);

    }
  }

  function handleSumModal() {
    setOpenModal(!openModal);
  }

  function handleAddenModal(encId) {
    // if (encId !== "") {
    // setAddEncounterId(encId);
    setOpenModal(false);
    setOpenAddenModal(true);
    setOpenAddenShowModal(false);
    // }
  }

  function handleAddAddendumCloseModal() {
    setOpenAddenModal(false);
  }

  function handleAddenShowModal(encId) {
    if (encId !== "") {
      handleBackdropClose();
      fetchAddendums(encId);
    }
  }

  function handleAddenModalClose(encId) {
    setOpenAddenShowModal(!openAddenShowModal);
  }

  const addAddendum = async () => {
    const httpResponse = await sendHttpRequest({
      url: addendumUrl(addEncounterId?.encounter?.encounter?.encounterId),
      method: "POST",
      data: {
        addendum: addendumBody,
        title: addendumTitle,
      },
    });
    if (!httpResponse.error) {
      emptyStates();
      setIsSent(true);
      setOpenAddenModal(!openAddenModal);
      if (addEncounterId?.isSummary) {
        handleModal(addEncounterId?.encounter?.prescriptionReferenceId);
      } else {
        handleAddenShowModal(addEncounterId?.encounter?.encounter?.encounterId)
      }
    } else {
      console.log(
        `Error in ${addendumUrl(addEncounterId?.encounter?.encounterId)}`,
        httpResponse?.error,
        " m = ",
        httpResponse?.errorMessage?.error
      );
    }
  };

  function emptyStates() {
    setAddendumBody("");
    setAddendumTitle("");
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSent(false);
  };

  if (isSessionUpdated === true) {
    fetchEncounters(userId);
    setIsSessionUpdated(false);
  }
  console.log("getSecondaryUser(): ", getSecondaryUser());
  useEffect(() => {

    fetchAllPrescribers();

    let uId = null;
    try {
      uId = getSecondaryUser();
    }
    catch (e) {
      console.error("PN_CLIENT_ERR: Cannot get secondary user");
    }
    fetchEncounters(uId ?? userId);
  }, []);



  return (
    <>
      {!isLoadingEnc && (
        <SummaryModal
          summary={summary}
          handleAddenModal={handleAddenModal}
          show={openModal}
          handleModal={handleSumModal}
        />
      )}
      {openAddenModal ? (
        <AddendumModal
          addAddendum={addAddendum}
          setAddendumBody={setAddendumBody}
          setAddendumTitle={setAddendumTitle}
          show={openAddenModal}
          handleAddAddendumCloseModal={handleAddAddendumCloseModal}
          handleAddenModal={handleAddenModal}
        />
      ) : null}
      {!isLoadingAddend && (
        <AddendumShowModal
          addendums={addendums}
          show={openAddenShowModal}
          handleAddenModal={handleAddenModal}
          handleAddenModalClose={handleAddenModalClose}
        />
      )}
      <div className="container-fluid">
        <div className="row p-1 py-3 bg-white border-bottom justify-content-between">
          <div className="col-sm-3 align-self-center">
            <div className="d-flex flex-row">
              <div
                style={{ width: "32px", height: "32px" }}
                className={`bg--background  br-10 me-3 border--default border align-self-center text-center my-auto `}
                onClick={() => {
                  navigate(-1)
                }}
              >
                <ChevronLeft
                  style={{ color: "#EC609B", height: "20px", weight: "20px" }}
                  className="cursor-pointer my-auto mt-1"
                />
              </div>
              <div className="font-22 fw-sb text--terciary align-self-center">
                Encounters
              </div>
            </div>
          </div>
          {/* Provider dropdown */}
          <div className="col-sm-4 align-self-center">
            <div
              style={{ width: "15vw" }}
              className="d-flex text--terciary "
              hidden={RolesMap(prescriberInfo?.role) === "nurse" ? false : true}
            >
              {/* <ProviderListDropdown/> */}
              {isPrescribersFetched === true ? (
                <Skeleton
                  animation="wave"
                  variant="text"
                  width={"70%"}
                  height={40}
                />
              ) : (
                <div>
                  {/* <label className="text--terciary b2">Provider</label> */}

                  <select
                    className={`form-select br-10 font-14 shadow-none ${classes["custom-select"]}`}
                    aria-label="Provider select"
                    style={{
                      border: "1px solid #D9E8EC",
                      width: "20vw",
                    }}
                    onChange={(e) => handleProviderId(e)}
                  // value={()=>getSecondaryUser()}
                  >
                    <option value="" disabled selected hidden>
                      Select provider
                    </option>
                    {prescribersList?.length > 0
                      ? prescribersList?.map((prescriberItem) => {
                        return (
                          <option
                            value={prescriberItem?.userId ?? ""}
                            key={prescriberItem?.userId ?? ""}
                            selected={
                              getSecondaryUser() == prescriberItem?.userId
                            }
                          >
                            {prescriberItem?.name[0]?.prefix}{" "}
                            {prescriberItem?.name[0]?.firstName}{" "}
                            {prescriberItem?.name[0]?.lastName}{" "}
                            {RolesTextConversion(prescriberItem?.role) !==
                              undefined
                              ? "(" +
                              RolesTextConversion(prescriberItem?.role) +
                              ")"
                              : ""}
                          </option>
                        );
                      })
                      : "No prescribers available"}
                  </select>
                </div>
              )}
            </div>
          </div>

          <div className="col-sm-4 align-self-center">
            <div className="row">
              <div className="col-5"></div>
              <div className="col-sm-7">
                <div
                  className="input-group br-10 bg--disabled"
                  style={{
                    border: "1px solid #D9E8EC",
                    opacity: "1px",
                    // backgroundColor: "#F2F7F8"
                  }}
                >
                  <input
                    type="text"
                    disabled
                    className={`form-control shadow-none bg--disabled border-0 ms-1`}
                    placeholder="Search encounter"
                    // style={{ backgroundColor: '#F2F7F8' }}
                    onChange={(event) => { }}
                  />

                  <button
                    className="btn br-10 bg--disabled shadow-none"
                    type="submit"
                  >
                    <SearchIcon className="text--secondary bg--disabled" />
                  </button>
                </div>
              </div>
              {/* <div className="col-sm-5" disabled>
                <div className={`${classes.filter} bg--disabled`}>
                  <FilterAltOutlinedIcon
                    sx={{ color: "#336383", my: "10px", marginLeft: "3px" }}
                  />
                  <span className="font-14 fw-sb bg--disabled align-self-center text--terciary ms-4 d-inline-block ">
                    Filter
                  </span>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div>
        {/* <Button onClick={handleToggle}>Show backdrop</Button> */}
        <Backdrop
          sx={{
            color: "#EC609B",
            zIndex: (theme) => theme.zIndex.drawer + 1000,
          }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <div className="row mt-3 justify-content-center p-5 mb-5">
        <div className="col-12">
          <div className="d-flex justify-content-between">
            <div></div>
            <div className="pe-2">
              <RefreshDataComponent
                isComponentDataLoading={areEncountersLoading}
                variant="without-bar"
                callback={() => fetchEncounters(uId ?? userId)}
              />
            </div>
          </div>
        </div>
        <div className="col-12">
          <Grid
            container
            sx={{ marginBottom: "-8px" }}
            p={1}
            className={` bg-white ${classes["row__head"]} ${prescriptionReference?.length === 0 ? classes["border--bottom"] : ""}`}
          >
            <Grid item xs={1.1}>
              {/* <Stack direction="row" alignItems="center" spacing={2}> */}
              <Typography component="div">
                <Box
                  sx={{
                    textAlign: "left",
                    m: 1,
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "12px",
                    color: "#336383",
                  }}
                >
                  Date & Time
                </Box>
              </Typography>
              {/* </Stack> */}
            </Grid>
            <Grid item xs={1.5}>
              <Typography component="div">
                <Box
                  sx={{
                    textAlign: "left",
                    mt: 1,
                    ml: 1,
                    // mr: 4,
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                    fontSize: "12px",
                    color: "#336383",
                  }}
                >
                  Name
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography component="div">
                <Box
                  sx={{
                    textAlign: "left",
                    mt: 1,
                    // ml: 2,
                    fontFamily: "Montserrat",
                    fontSize: "12px",
                    color: "#336383",
                  }}
                >
                  Date of Birth
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography component="div">
                <Box
                  sx={{
                    textAlign: "left",
                    marginTop: "8px",
                    // ml: 4,
                    fontFamily: "Montserrat",
                    fontSize: "12px",
                    color: "#336383",
                  }}
                >
                  Gender
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography component="div">
                <Box
                  sx={{
                    textAlign: "left",
                    mt: 1,
                    // ml: 4,
                    fontFamily: "Montserrat",
                    fontSize: "12px",
                    color: "#336383",
                  }}
                >
                  Chief Complaint
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={1.5}>
              <Typography component="div">
                <Box
                  sx={{
                    textAlign: "left",
                    mt: 1,
                    // ml: 4,
                    fontFamily: "Montserrat",
                    fontSize: "12px",
                    color: "#336383",
                  }}
                >
                  Type
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={1.5}>
              <Typography component="div">
                <Box
                  sx={{
                    textAlign: "left",
                    mt: 1,
                    // ml: 4,
                    fontFamily: "Montserrat",
                    fontSize: "12px",
                    color: "#336383",
                  }}
                >
                  Status
                </Box>
              </Typography>
            </Grid>
          </Grid>

          {areEncountersLoading ? (
            <Skeleton animation="wave" />
          ) : statusCodeEncounters === 200 ? (
            prescriptionReference?.length > 0 ? (
              prescriptionReference?.map((prescriptionReferenceItem, index) => {
                let encounterStatus = prescriptionReferenceItem?.encounter?.status?.toLowerCase();
                let providerNote = prescriptionReferenceItem?.providerNote;
                let showEncounterButtons = true;
                let encounterCreatedDate = prescriptionReferenceItem?.createdDate;
                // if((providerNote !== undefined && providerNote !== null) )
                // {
                //   showEncounterButtons = false;
                // }
                if (
                  encounterStatus === "closed" ||
                  encounterStatus === "started" ||
                  encounterStatus === "dispatched" ||
                  (providerNote !== undefined && providerNote !== null)
                ) {
                  if (index === (prescriptionReference?.length - 1)) {
                    return (
                      <EncounterItem
                        key={index}
                        createdDateTime={encounterCreatedDate}
                        showEncounterActionButtons={showEncounterButtons}
                        handleBackdropClose={handleBackdropClose}
                        handleToggle={handleToggle}
                        prescriptionReference={prescriptionReferenceItem}
                        isEven={index % 2 === 0 ? true : false}
                        isLast={true}
                        handleAddenShowModal={handleAddenShowModal}
                        handleAddenModal={handleAddenModal}
                        setAddEncounterId={setAddEncounterId}
                        handleModal={handleModal}
                        disabled={isVideoCallActive && videoCallContext.appointmentData !== null}
                        dummyState={dummyState}
                        hidden={encounterStatus === "started" ? subscriptionPrivilegeGroups[ENCOUNTERS]?.vpe?.active === false ? true : false : false}
                      />
                    );
                  } else {
                    return (
                      <EncounterItem
                        createdDateTime={encounterCreatedDate}
                        showEncounterActionButtons={showEncounterButtons}
                        handleBackdropClose={handleBackdropClose}
                        handleToggle={handleToggle}
                        key={index}
                        dummyState={dummyState}
                        prescriptionReference={prescriptionReferenceItem}
                        isEven={index % 2 === 0 ? true : false}
                        handleAddenShowModal={handleAddenShowModal}
                        handleAddenModal={handleAddenModal}
                        handleModal={handleModal}
                        setAddEncounterId={setAddEncounterId}
                        disabled={isVideoCallActive && videoCallContext.appointmentData !== null}
                        hidden={encounterStatus === "started" ? subscriptionPrivilegeGroups[ENCOUNTERS]?.vpe?.active === false ? true : false : false}
                      />
                    );
                  }
                }
              })
            ) : (
              <div className="font-14 text--terciary p-2">{__str.noDataAvailable}</div>
            )
          ) : (
            ""
          )}
        </div>
      </div>
      <CustomizedSnackbars
        message={"Addendum saved!"}
        severity="success"
        open={isSent}
        handleClose={handleClose}
      />
    </>
  );
};

export default EncountersPage;

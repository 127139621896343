import { ActionTypes } from "../constants/actionTypes"

const initialState = {permissionsMap:new Map()}

const setUserPermissionsDataReducer = (state = initialState,action)=>{
    // console.log("action: ",action);
    if(action.type === ActionTypes.SET_USER_PERMISSIONS){
        // console.log("==========================setUserPermissionsDataReducer=================================",action.payload);
        return {...state, permissionsMap:action.payload}
    }
    // console.log("========++++++++++++++++++++++++++++++++++++++++++++++++++++++-",state);
    return state;

}

export default setUserPermissionsDataReducer;
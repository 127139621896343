import { Backdrop, CircularProgress, Grid, LinearProgress, Skeleton } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import PatientsList from "../patient/PatientsList";
import PatientStaticProfileHeader from "../patient/PatientStaticProfileHeader";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { ThemeProvider } from "@mui/material/styles";
import PatientCreateDropdown from "../prescription/PatientCreateDropdown";
import ViewPatientProfileEncounter from "../patient/viewPatientProfile/ViewPatientProfileEncounter";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";
import SummaryModal from "../UI/Modals/ProviderModals/SummaryModal";
import AddendumModal from "../UI/Modals/ProviderModals/AddendumModal";
import AddendumShowModal from "../UI/Modals/ProviderModals/AddendumShowModal";
import sendHttpRequest from "../hooks/sendHttpRequest";
import DocumentTabPatientProfile from "../patient/DocumentTabPatientProfile";
import PrescriptionTable from "../patient/PrescriptionTable";
import PatientEncounterCard from "../patient/viewPatientProfile/PatientEncounterCard";
import { ChevronLeft } from "@mui/icons-material";
import PatientHistoryView from "../patient/viewPatientProfile/PatientHistoryView";
import PatientEncountersList from "../patient/viewPatientProfile/PatientEncountersList";
import NewPatientFacesheetModal from "../UI/Modals/PatientModals/NewPatientFacesheetModal";
import FacesheatModal from "../UI/Modals/PatientModals/FacesheetModal";
import PatientProfileMessages from "../messages/PatientProfileMessages";
import { TabPanel, theme } from "../UI/Reusable/PnComponents";
import { addendumUrl, patientAllEncounters } from "../../res/endpointurls";
import { summaryGeneration } from "../../utils/helpers/DataLoaders";
import PatientProfileVitals from "../patient/viewPatientProfile/PatientProfileVitals";
import LabOrdersTable from "../patient/LabOrdersTable";
import { useSelector } from "react-redux";
import Sticky from 'react-stickynode';
import { useAuth } from "../hooks/useAuth";
import useSnackbar from "../hooks/useSnackbar";
import { useFlags } from "launchdarkly-react-client-sdk";
import InPatientService from "../Services/InPatient.service";
import CustomizedSnackbar from "../UI/General/CustomizedSnackbars";
import { WarningIcon } from "../UI/Reusable/WarningIcon";
import ImagingOrderTable from "../patient/ImagingOrderTable";

const tabsListAll = [
  "Patient Information",
  "Documents",
  "Messages",
  "Prescriptions",
  "Encounters",
  "History",
  "Vitals",
  "Labs",
  "Imaging Orders"
];

// const tabsList = [
//   "Patient Information",
//   "Messages",
//   "Documents",
//   "Prescriptions",
//   "Encounters",
//   "History",
//   "Vitals",
//   "Labs",
// ];

function BottomNavigationWithDropdown(props) {
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);

  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [rooms, setRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [medicalAssistants, setMedicalAssistants] = useState([]);
  const [selectedMedicalAssistant, setSelectedMedicalAssistant] = useState(null);
  const [areRoomsLoading, setAreRoomsLoading] = useState(false);
  const [areMedicalAssitantsLoading, setAreMedicalAssistantsLoading] = useState(false);
  const [openCheckInSnackbar, checkInSnackbarMessage, checkInSnackbarSeverity, setOpenCheckInSnackbar, setCheckInSnackbarMessage, setCheckInSnackbarSeverity, handleCheckInSnackbarClose] = useSnackbar();
  const [isCheckInDisabled, setIsCheckInDisabled] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);

  async function getRooms() {
    setAreRoomsLoading(true);
    const data = await InPatientService.getFacilityRooms(user?.userId);
    // console.log(data);
    if (data?.length === 0) {
      setIsCheckInDisabled(true);
    }
    setRooms(data);

    if (location?.state?.examRoom?.examRoomId !== null && location?.state?.examRoom?.examRoomId !== undefined) {
      setSelectedRoom(location?.state?.examRoom?.examRoomId);
      setIsCheckInDisabled(false);
    }
    else {
      // console.log(data?.at(0)?.facilityRoomId);
      setSelectedRoom(() => data?.at(0)?.facilityRoomId);
    }
    setAreRoomsLoading(false);
  }

  async function getMedicalAssistants() {
    setAreMedicalAssistantsLoading(true);
    const data = await InPatientService.getMedicalAssistants(user?.tenantId);
    // console.log(data);
    setMedicalAssistants(data);
    setSelectedMedicalAssistant(data?.at(0)?.userId);
    setAreMedicalAssistantsLoading(false);
  }

  function handleExamRoom(event) {
    setSelectedRoom(event.target.value);
  }

  function handleMedicalAssistant(event) {
    setSelectedMedicalAssistant(event.target.value);
  }

  function displayCheckInError() {
    setCheckInSnackbarMessage("Error while checking in");
    setCheckInSnackbarSeverity("error");
    setOpenCheckInSnackbar(true);
  }

  async function handleCheck() {
    const appointmentId = location?.state?.appointmentId;
    // console.log(location);
    console.log(selectedRoom, selectedMedicalAssistant)
    if (appointmentId === null || appointmentId === undefined) return;
    const response = await InPatientService.checkIn(appointmentId, selectedRoom, selectedMedicalAssistant);
    // console.log(response)
    if (response !== undefined && !response?.error) {
      navigate('/dashboard');
    }
    else if (response === undefined) {
      displayCheckInError();
    }

  }

  useEffect(() => {
    async function effectHandler() {
      setIsDataLoading(true);
      if (subscriptionPrivilegeGroups['Practice Management']?.arp?.active === true) {
        const r = await getRooms();
      }
      const ma = await getMedicalAssistants();
      const sExamRoom = location?.state?.examRoom;
      if (sExamRoom !== null && sExamRoom !== undefined && sExamRoom?.examRoomId !== undefined && sExamRoom?.examRoom !== undefined) {
        // console.log("I am changing the exam room", sExamRoom)
        setSelectedRoom(sExamRoom?.examRoomId);
      }
      setIsDataLoading(false);
    }
    effectHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    areMedicalAssitantsLoading && areRoomsLoading && selectedMedicalAssistant === null && selectedRoom === null ? <Skeleton /> : <div style={{ zIndex: '800' }} className="position-fixed row bg-white w-100 bottom-0 mx-auto bottom__nav--shadow">

      <LinearProgress variant="determinate" style={{
        backgroundColor: "#D9E8EC",
        height: "1px"
      }} value={100} />


      <div className="col-12 p-3">
        <div className="row gap-3">
          <div className="col-2">
            <div className="form-group">
              {isDataLoading ? <Skeleton /> :
                subscriptionPrivilegeGroups['Practice Management']?.arp?.active === true ?

                  <select
                    className={`form-select font-13 shadow-none`}
                    aria-label="Provider select"
                    style={{
                      border: "1px solid #D9E8EC",
                      height: "36px",
                      width: "250px",
                      borderRadius: "10px",
                      // background: "#FAFCFC",
                    }}
                    required
                    onChange={handleExamRoom}
                    value={selectedRoom}
                  >
                    <option key={"deselect-room"} data-room="0" value={0}></option>
                    {location?.state?.examRoom !== null && location?.state?.examRoom !== undefined && location?.state?.examRoom?.examRoom !== undefined && location?.state?.examRoom?.examRoomId !== undefined ? (<option value={location?.state?.examRoom?.examRoomId}>{location?.state?.examRoom?.examRoom}</option>) : null}
                    {rooms?.map((room, idx) => {
                      if (location?.state?.examRoom !== null)
                        return <option key={idx} value={room?.facilityRoomId}>{room?.name}</option>
                    })}
                  </select>
                  :
                  <span className='font-12 text--terciary'>{selectedRoom !== undefined ? location?.state?.examRoom?.examRoom : "-"}</span>

              }
            </div>
          </div>




          <div className="col-2">
            <div className="form-group">
              {isDataLoading ? <Skeleton /> :
                <select
                  className={`form-select font-13 shadow-none`}
                  aria-label="Medical Assistant select"
                  style={{
                    border: "1px solid #D9E8EC",
                    height: "36px",
                    width: "250px",
                    borderRadius: "10px",
                    // background: "#FAFCFC",
                  }}
                  required
                  onChange={handleMedicalAssistant}
                  value={selectedMedicalAssistant}
                >
                  {medicalAssistants?.map((user, idx) => {
                    return <option key={user?.userId} value={user?.userId}>{`${user?.name?.at(0)?.firstName} ${user?.name?.at(0)?.lastName}`}</option>
                  })}
                </select>

              }
            </div>
          </div>

          <div className="col-2 text-center offset-4"><button
            disabled={isCheckInDisabled || isDataLoading}
            className="btn ms-2 blue_task_button"
            onClick={() => handleCheck()}
          >
            Check-In
          </button>
            <span className={`text-danger ${isCheckInDisabled ? 'd-block' : 'd-none'} font-12 m-0 p-0`}>No Rooms Available</span>
          </div>
        </div>
      </div>


      <CustomizedSnackbar
        message={checkInSnackbarMessage}
        severity={checkInSnackbarSeverity}
        open={openCheckInSnackbar}
        handleClose={handleCheckInSnackbarClose}
      />
    </div>




  );
}

const PatientsPageNew = () => {
  const { pId } = useParams();
  const location = useLocation();
  const { inPersonVisitDashboard } = useFlags();
  const { user } = useAuth();


  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);

  const [tabsList, setTabsList] = useState([]);


  const [value, setValue] = useState(0);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [patientId, setPatientId] = useState(location?.state?.patientId || "");
  const [isPatientNotFound, setIsPatientNotFound] = useState(false);
  const [patientChanged, setPatientChanged] = useState(false);
  const [recentEncountersList, setRecentEncountersList] = useState([]);

  const [isLoadingEncounters, setIsLoadingEncounters] = useState(false);

  const [patientFacesheetDependency, setPatientFacesheetDependency] =
    useState(false);
  const [patientEditDependency, setPatientEditDependency] = useState(false);
  const [patientIndex, setPatientIndex] = useState("");
  const [practiceCodeTypeList, setPracticeCodeTypeList] = useState({
    isLoading: false,
    list: [],
  });
  //=================Imaging order Date===============

  const [isImagingOrderSent, setIsImagingOrderSent] = useState(false);
  // ===================Imaging Order State end=======
  const [stickyClass, setStickyClass] = useState(false);
  const scrollRef = useRef();
  const navigate = useNavigate();
  const [dimensions, setDimensions] = useState({
    collapsed: {
      sidebar: 2.5,
      main: 9.5,
    },
    default: {
      sidebar: 2.6,
      main: 9.4,
    },
  });
  const [isPrescriptionSent, setIsPrescriptionSent] = useState(Math.random());


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };


  const handleHrefChange = () => {
    // setWindowHref(window.location.href);
    const path = window.location.href;
    const pathArray = path.split("/");
    const lastElement = pathArray[pathArray.length - 1];
    lastElement !== "all-patients" && handlePatientId(lastElement);
  };

  //===============Manually Add New Patient Modal START==========//
  const [newPatientModal, setNewPatientModal] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [patientAddDependency, setPatientAddDependency] = useState(false);
  const handleNewPatientModal = () => {
    setNewPatientModal(true);
  };

  const handleNewPatientModalClose = () => {
    setNewPatientModal(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsSent(false);
    setSavedAddendum(false);
  };

  const handlePatientAddDependency = () => {
    setPatientAddDependency(!patientAddDependency);
  };

  const handlePatientEditDependency = () => {
    setPatientEditDependency(!patientEditDependency);
  };

  const handleFacesheetPatientDependency = () => {
    setPatientFacesheetDependency(!patientFacesheetDependency);
  };
  //===============Manually Add New Patient Modal END==========//

  //===============Add Patient through facesheet modal start =========//
  const [patientFaceSheetModal, setPatientFaceSheetModal] = useState(false);
  const [facesheetContent, setFacesheetContent] = useState(null);
  const handlePatientFacesheetModal = () => {
    setPatientFaceSheetModal(!patientFaceSheetModal);
  };

  //==============Summary Modal Code START==============//
  const [isLoadingEnc, setIsLoadingEnc] = useState(true);
  const [isLoadingAddend, setIsLoadingAddend] = useState(true);
  const [isError, setIsError] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [addendumTitle, setAddendumTitle] = useState("");
  const [addendumBody, setAddendumBody] = useState("");
  const [openAddenModal, setOpenAddenModal] = useState(false);
  const [openAddenShowModal, setOpenAddenShowModal] = useState(false);
  const [summary, setSummary] = useState({});
  const [addendums, setAddendums] = useState("");
  const [addEncounterId, setAddEncounterId] = useState("");

  const [open, setOpen] = useState(false);
  const [savedAddendum, setSavedAddendum] = useState(false);

  const handleBackdropClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const fetchAddendums = async (encId) => {
    setIsLoadingAddend(true);
    const httpResponse = await sendHttpRequest({
      url: addendumUrl(encId),
      method: "GET",
    });
    if (!httpResponse.error) {
      setAddendums(httpResponse.data);
      setIsLoadingAddend(false);
      handleBackdropClose();
      setOpenAddenShowModal(!openAddenShowModal);
    } else {
      console.log(httpResponse.error);
    }
    setIsLoadingAddend(false);
  };

  const generateEncounterSummary = async (encId) => {
    const data = await summaryGeneration(encId);
    await setSummary(data);
    handleBackdropClose();
    setOpenModal(!openModal);
    setIsLoadingEnc(false);
  };

  function handleModal(encId) {
    if (encId !== "") {
      generateEncounterSummary(encId);
    }
  }

  function handleSumModal() {
    setOpenModal(!openModal);
  }

  function handleAddenModal(encId) {
    // console.log("asdasdasdasdasdas");
    // if (encId !== "") {
    // setAddEncounterId(encId);
    setOpenModal(false);
    setOpenAddenModal(true);
    setOpenAddenShowModal(false);
    // }
  }

  function handleAddAddendumCloseModal() {
    setOpenAddenModal(false);
  }

  function handleAddenShowModal(encId) {
    if (encId !== "") {
      // setAddEncounterId(encId);
      handleBackdropClose();
      fetchAddendums(encId);
    }
  }

  function handleAddenModalClose(encId) {
    setOpenAddenShowModal(!openAddenShowModal);
  }

  const addAddendum = async () => {
    const httpResponse = await sendHttpRequest({
      url: addendumUrl(addEncounterId?.encounter?.encounter?.encounterId),
      method: "POST",
      data: {
        addendum: addendumBody,
        title: addendumTitle,
      },
    });
    if (!httpResponse.error) {
      emptyStates();
      setSavedAddendum(true);
      setOpenAddenModal(!openAddenModal);
      if (addEncounterId.isSummary) {
        handleModal(addEncounterId?.encounter?.prescriptionReferenceId); //triggering summary flow
      } else {
        handleAddenShowModal(addEncounterId?.encounter?.encounter?.encounterId);
      }
    } else {
      setErrorMessage("Addendum cannot be saved!");
      setOpenAddenModal(!openAddenModal);
      console.log(httpResponse.error);
      setFacesheetUploadedFail(true);
      emptyStates();
    }
  };

  function emptyStates() {
    setAddendumBody("");
    setAddendumTitle("");
    setErrorMessage("");
    setSavedAddendum(false);
  }
  //==============Summary Modal Code END==============//

  //=============Recent Encounters Fetch==============//
  const fetchRecentEncounters = async (patientId) => {
    setIsLoadingEncounters(true);
    const httpResponse = await sendHttpRequest({
      url: patientAllEncounters(patientId),
      method: "GET",
    });
    if (!httpResponse.error) {
      setRecentEncountersList(httpResponse.data);
    } else {
      setRecentEncountersList([]);
      // console.log(httpResponse.error);
    }
    setIsLoadingEncounters(false);
  };
  //=============Recent encounters fetch END==========//

  const handlePatientId = (pid) => {
    setPatientId(pid);
  };

  // const handleScroll = (event) => {

  //   if (event.target.id == "encountersLeftScrollBtn") {
  //     scrollRef.current.scrollLeft += 80;
  //   }
  // };

  const [openFacesheatModal, setOpenFacesheatModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [facesheetUploaded, setFacesheetUploaded] = useState(false);
  const [facesheetUploadedFail, setFacesheetUploadedFail] = useState(false);
  const [providerNoteType, setProviderNoteType] = useState("");

  const [appointmentNotScheduledError, setAppointmentNotScheduledError] = useState("Error while creating appointment");
  const [isAppointmentNotRescheduled, setIsAppointmentNotRescheduled] = useState(false);
  const [isAppointmentScheduled, setIsAppointmentScheduled] = useState(false);
  const handleAppointmentToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // setIsSentAppointment(false);
    setIsAppointmentNotRescheduled(false);
  };


  const [isLabOrderSent, setIsLabOrderSent] = useState(false);

  const handleFacesheetUploadFailClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
  };


  const handleFacesheetModal = () => {
    setOpenFacesheatModal(!openFacesheatModal);
  };

  // selected index
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const handleSelectedIndex = (index) => {
    setSelectedIndex(index);
  };

  const fetchPracticeCodeTypes = async () => {
    setPracticeCodeTypeList((prev) => ({ ...prev, isLoading: true }));
    const httpResponse = await sendHttpRequest({
      // url: practiceTypeCodeUrl(),
      url: `/claimsManagement/practiceTypeCodes/list`,
      method: "GET",
    });

    if (!httpResponse.error) {
      // setPracticeCodeTypeList((prev) => ({ ...prev, list: httpResponse.data }));
      const dataArray = Object.keys(httpResponse.data).map(key => {
        return {
          name: key,
          code: httpResponse.data[key]
        };
      });
      // Sorting the array by code
      dataArray.sort((a, b) => {
        return a.code - b.code; // Assuming code is numeric, for strings, use localeCompare()
      });
      setPracticeCodeTypeList((prev) => ({ ...prev, list: dataArray }));
    }
    setPracticeCodeTypeList((prev) => ({ ...prev, isLoading: false }));
  };

  const TabsMap = function (tabTitle) {
    const tabsMap = new Map();

    tabsList.forEach((element, index) => {
      tabsMap.set(element, index);
      tabsMap.set(index, element);
    });
    return tabsMap.get(tabTitle);
  };

  useEffect(() => {
    let tabListTemp = [];
    for (let i = 0; i < tabsListAll.length; i++) {

      if (subscriptionPrivilegeGroups?.Patients?.vmo?.active === true && tabsListAll[i] === "Prescriptions") {
        tabListTemp.push(tabsListAll[i]);
      } else if (subscriptionPrivilegeGroups?.Patients?.vpi?.active === true && tabsListAll[i] === "Patient Information") {
        tabListTemp.push(tabsListAll[i]);
      } else if (subscriptionPrivilegeGroups?.Patients?.vpl?.active === true && tabsListAll[i] === "Labs") {
        tabListTemp.push(tabsListAll[i]);
      }
      else if (subscriptionPrivilegeGroups?.Patients?.vpm?.active === true && tabsListAll[i] === "Messages") {
        tabListTemp.push(tabsListAll[i]);
      }
      else if (subscriptionPrivilegeGroups?.Patients?.vmh?.active === true && tabsListAll[i] === "History") {
        tabListTemp.push(tabsListAll[i]);
      }
      else if ((subscriptionPrivilegeGroups?.Patients?.vpv?.active === true || subscriptionPrivilegeGroups?.Patients?.apv?.active === true) && tabsListAll[i] === "Vitals") {
        tabListTemp.push(tabsListAll[i]);
      }
      else if (subscriptionPrivilegeGroups?.Encounters?.vpd?.active === true && tabsListAll[i] === "Documents") {
        tabListTemp.push(tabsListAll[i]);
      }
      else if (subscriptionPrivilegeGroups?.Patients?.vse?.active === true && tabsListAll[i] === "Encounters") {
        tabListTemp.push(tabsListAll[i]);
      }
      // else if (tabsListAll[i] !== "Prescriptions" && tabsListAll[i] !== "Patient Information" && tabsListAll[i] !== "Labs" && tabsListAll !== "Messages" && tabsListAll[i] !== "History" && tabsListAll[i] !== "Vitals" && tabsListAll[i] !== "Documents") {
      //   tabListTemp.push(tabsListAll[i]);
      // }
    }
    tabListTemp.push("Imaging Orders");
    // console.log("tabListTemp: ",tabListTemp);
    setTabsList(tabListTemp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("popstate", function (event) {
      const path = event.currentTarget.parent.location.pathname;
      const pathArray = path.split("/");
      const lastElement = pathArray[pathArray.length - 1];
      lastElement !== "all-patients" && handlePatientId(lastElement);
      // lastElement !== "all-patients" && setPatientIndex(lastElement);
    });

    const path = window.location.pathname;
    const pathArray = path.split("/");
    const lastElement = pathArray[pathArray.length - 1];
    lastElement !== "all-patients" && handlePatientId(lastElement);
    // lastElement !== "all-patients" && setPatientIndex(lastElement);
    if (patientId) {
      fetchRecentEncounters(patientId);
    }
    return () => {
      setRecentEncountersList([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId, pId]);



  useEffect(() => {
    const path = window.location.pathname;
    const pathArray = path.split("/");
    const lastElement = pathArray[pathArray.length - 1];
    lastElement !== "all-patients" && handlePatientId(lastElement);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  useEffect(() => {
    fetchPracticeCodeTypes();


  }, [pId, patientId]);

  useEffect(() => {

    if (
      location?.state?.referer &&
      (location.state.referer === "soap-note" ||
        location.state.referer === "provider-note")
    ) {
      setValue(TabsMap("Encounters"), tabsList);
    } else if (
      location?.state !== undefined &&
      location?.state !== null &&
      location?.state?.referer !== undefined &&
      location?.state?.referer !== null &&
      location?.state?.referer === "lab-order"
    ) {
      // console.log("In labs tab use effect");
      setValue(TabsMap("Labs"), tabsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.referer,]);


  useEffect(() => { if (patientId) { fetchRecentEncounters(patientId) } }, [isAppointmentScheduled])

  return (
    <>
      {patientFaceSheetModal && (
        <NewPatientFacesheetModal
          show={patientFaceSheetModal}
          handleNewPatientModalClose={handlePatientFacesheetModal}
          // addNewPatientRequestHandler={addNewPatientRequestHandler}
          setIsSent={setIsSent}
          handleFacesheetPatientDependency={handleFacesheetPatientDependency}
          fetchPatients={null}
          facesheetContent={facesheetContent}
        />
      )}

      {openFacesheatModal && (
        <FacesheatModal
          show={openFacesheatModal}
          handleModal={setOpenFacesheatModal}
          setFacesheetUploaded={setFacesheetUploaded}
          setErrorMessage={setErrorMessage}
          setFacesheetContent={setFacesheetContent}
          handlePatientFacesheetModal={handlePatientFacesheetModal}
          setFacesheetUploadedFail={setFacesheetUploadedFail}
          fetchPatients={null}
        />
      )}

      {!isLoadingEnc && (
        <SummaryModal
          providerNoteType={providerNoteType}
          fromPatientProfile={true}
          summary={summary}
          handleAddenModal={handleAddenModal}
          show={openModal}
          handleModal={handleSumModal}
        />
      )}
      {openAddenModal ? <AddendumModal
        addAddendum={addAddendum}
        setAddendumBody={setAddendumBody}
        setAddendumTitle={setAddendumTitle}
        show={openAddenModal}
        encId={addEncounterId}
        handleAddAddendumCloseModal={handleAddAddendumCloseModal}
        handleAddenModal={handleAddenModal}
      /> : null}
      {!isLoadingAddend && (
        <AddendumShowModal
          addendums={addendums}
          show={openAddenShowModal}
          handleAddenModal={handleAddenModal}
          handleAddenModalClose={handleAddenModalClose}
        />
      )}

      <div className="container-fluid border--bottom">
        <div className="d-flex p-1 py-3 bg-white   ">
          <div className="align-self-center">
            <div className="d-flex flex-row">
              <div
                style={{ width: "32px", height: "32px" }}
                className={`bg--background  br-10 me-3 border--default border align-self-center text-center my-auto `}
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ChevronLeft
                  style={{ color: "#EC609B", width: "20px", height: "20px" }}
                  className="cursor-pointer my-auto mt-1"
                />
              </div>
              <div className="font-22  fw-sb text--terciary align-self-center">
                Patients
              </div>
            </div>
          </div>
          <div
            className="ms-auto"
            hidden={subscriptionPrivilegeGroups?.Patients?.anp?.active === false ? true : false}
          >
            <PatientCreateDropdown
              handleNewPatientModal={handleNewPatientModal}
              handleNewPatientModalClose={handleNewPatientModalClose}
              handleFacesheetModal={handleFacesheetModal}
              handleFacesheetModalClose={handleFacesheetModal}
              patientId={patientId}
            />
          </div>
        </div>
      </div>

      <Grid
        container
        sx={{
          backgroundColor: "#FAFCFC",
        }}
      >
        <Grid
          item
          xs={
            isCollapsed
              ? dimensions?.collapsed?.sidebar
              : dimensions?.default?.sidebar
          }
          sm={
            isCollapsed
              ? dimensions?.collapsed?.sidebar
              : dimensions?.default?.sidebar
          }
          md={
            isCollapsed
              ? dimensions?.collapsed?.sidebar
              : dimensions?.default?.sidebar
          }
          sx={{
            backgroundColor: "#FAFCFC",
            borderRight: "1px solid #d9e8ec",
            visibility: subscriptionPrivilegeGroups?.Patients?.vlp?.active === false ? "hidden" : "",
          }}
        >
          <PatientsList
            patientDependency={patientAddDependency}
            patientEditDependency={patientEditDependency}
            patientFacesheetDependency={patientFacesheetDependency}
            // handleRecentEncountersList={handleRecentEncountersList}
            patientId={patientId}
            handleChange={handleChange}
            handlePatientId={handlePatientId}
            isCollapsed={isCollapsed}
            toggleCollapse={toggleCollapse}
            handleSelectedIndex={handleSelectedIndex}
            setIsPatientNotFound={setIsPatientNotFound}
          />
        </Grid>

        {patientId === "" ? (
          <div />
        ) : isPatientNotFound === false ? (
          <Grid
            item
            xs={
              isCollapsed
                ? dimensions?.collapsed?.main
                : dimensions?.default?.main
            }
            sm={
              isCollapsed
                ? dimensions?.collapsed?.main
                : dimensions?.default?.main
            }
            md={
              isCollapsed
                ? dimensions?.collapsed?.main
                : dimensions?.default?.main
            }
            sx={{ overflowX: "hidden", position: 'relative' }}
          // className="noscrollbar"
          >


            <ThemeProvider theme={theme}>
              <Sticky enabled={true} top={70} innerZ={20} bottomBoundary={1200}>
                <div
                  id="header-tabs-box"
                  style={{ backgroundColor: 'white', borderBottom: '1px solid #d9e8ec' }}
                >
                  <PatientStaticProfileHeader
                    patientId={patientId}
                    setIsPatientNotFound={setIsPatientNotFound}
                    setIsPrescriptionSent={setIsPrescriptionSent}
                    setIsLabOrderSent={setIsLabOrderSent}
                    setAppointmentNotScheduledError={setAppointmentNotScheduledError}
                    setIsAppointmentNotRescheduled={setIsAppointmentNotRescheduled}
                    setIsAppointmentScheduled={setIsAppointmentScheduled}
                    isAppointmentScheduled={isAppointmentScheduled}
                    setIsImagingOrderSent={setIsImagingOrderSent}
                    isImagingOrderSent={isImagingOrderSent}
                  />
                  <Tabs
                    indicatorColor="secondary"
                    value={value}
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={handleChange}
                    style={{
                      backgroundColor: "white"
                    }}
                  >
                    {tabsList.map((item, key) => {
                      return (
                        <Tab
                          key={key}
                          label={TabsMap(key)}
                          style={{
                            fontSize: "12px",
                            fontWeight: "600",
                            // font:'normal normal 600 14px/47px Montserrat',
                            opacity: "1",
                            textTransform: "initial",
                            // color: `${key == tabsList.length - 1 ? "#dddddd" : "#336383"
                            //   }`,
                          }}
                        // disabled={key == tabsList.length - 1 ? true : false}
                        />
                      );
                    })}
                  </Tabs>
                </div>
              </Sticky>


            </ThemeProvider>

            <ThemeProvider theme={theme}>
              <TabPanel
                value={value}
                index={TabsMap("Patient Information")}
                className="bg--background pb-5"
              >
                {isLoadingEncounters ? (
                  <Skeleton
                    style={{
                      width: "85%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "24px",
                    }}
                    animation="wave"
                  />
                ) : (
                  recentEncountersList?.length > 0 && (
                    <>
                      <div className="row mt-5 justify-content-center ">
                        <div className="col-sm-11 d-flex justify-content-between">
                          <span className="font-20 align-self-center fw-sb text--terciary mb-2 ms-0 ps-0">
                            Encounters
                          </span>
                          {/* <div className="btn-group mb-2">
                                   <button type="button" onClick={handleScroll} id="encountersLeftScrollBtn" className="btn  border"><ChevronLeft id="encountersLeftScrollBtn" color="#336383"/></button>
                                   <button type="button" onClick={handleScroll} id="encountersRightScrollBtn" className="btn  border"><ChevronRight id="encountersRightScrollBtn" color="#336383"/></button>
                                 </div> */}
                          {recentEncountersList?.length > 4 && (
                            <button
                              onClick={(event) =>
                                handleChange(event, TabsMap("Encounters"))
                              }
                              className="btn br-10 shadow-none text-decoration-underline  fw-sb text--blue font-12"
                              hidden={subscriptionPrivilegeGroups?.Encounters?.vse?.active === false ? true : false}
                            >
                              View all
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-sm-11 g-0">
                          <div
                            ref={scrollRef}
                            className="row flex-nowrap flex-container-scroll"
                          >
                            {recentEncountersList?.map((encounter, index) => {
                              let appointmentStatus = encounter?.appointment?.status?.toLowerCase();
                              if (index < 4) {
                                if ((appointmentStatus === "pending" || appointmentStatus === "started") && subscriptionPrivilegeGroups?.Encounters?.vpe?.active === true && appointmentStatus !== "cancelled") {
                                  return (
                                    <div className="col-6" key={encounter?.prescriptionReferenceId}>
                                      <PatientEncounterCard
                                        key={encounter?.prescriptionReferenceId}
                                        encounter={encounter}
                                        handleBackdropClose={handleBackdropClose}
                                        handleToggle={handleToggle}
                                        handleAddenShowModal={
                                          handleAddenShowModal
                                        }
                                        handleAddenModal={handleAddenModal}
                                        handleModal={handleModal}
                                      />
                                    </div>
                                  );
                                } else if (!(appointmentStatus === "pending" || appointmentStatus === "started") && appointmentStatus !== "cancelled" && appointmentStatus !== "completed") {
                                  return (
                                    <div className="col-6" key={encounter?.prescriptionReferenceId}>
                                      <PatientEncounterCard
                                        key={encounter?.prescriptionReferenceId}
                                        encounter={encounter}
                                        handleBackdropClose={handleBackdropClose}
                                        handleToggle={handleToggle}
                                        handleAddenShowModal={
                                          handleAddenShowModal
                                        }
                                        handleAddenModal={handleAddenModal}
                                        handleModal={handleModal}
                                      />
                                    </div>
                                  )
                                }
                                else if (appointmentStatus === "completed" && subscriptionPrivilegeGroups?.Encounters?.vse?.active === true) {
                                  return (
                                    <div className="col-6" key={encounter?.prescriptionReferenceId}>
                                      <PatientEncounterCard
                                        key={encounter?.prescriptionReferenceId}
                                        encounter={encounter}
                                        handleBackdropClose={handleBackdropClose}
                                        handleToggle={handleToggle}
                                        handleAddenShowModal={
                                          handleAddenShowModal
                                        }
                                        handleAddenModal={handleAddenModal}
                                        handleModal={handleModal}
                                      />
                                    </div>
                                  )
                                }
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    </>
                  )
                )}
                <ViewPatientProfileEncounter
                  // handleRecentEncountersList={handleRecentEncountersList}
                  practiceCodeTypeList={practiceCodeTypeList}
                  patientId={patientId}
                  setIsPatientNotFound={setIsPatientNotFound}
                  handlePatientEditDependency={handlePatientEditDependency}
                  isAppointmentScheduled={isAppointmentScheduled}
                />
              </TabPanel>

              <TabPanel
                value={value}
                index={TabsMap("Documents")}
                className="bg--background"
              >
                <div className="container-fluid bg--background">
                  <div className="row justify-content-center ">
                    <div
                      className={`col-sm-12`}
                      style={{ marginBottom: "8%" }}
                    >
                      {/* <DocumentUpload patientId={patientId} /> */}
                      <DocumentTabPatientProfile patientId={patientId} />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel
                value={value}
                index={TabsMap("Messages")}
                className="bg--background"
              >
                <div className="container-fluid bg--background g-0">
                  <div className="row justify-content-center ">
                    <div
                      className={`col-sm-12`}
                      style={{ marginBottom: "8%" }}
                    >
                      {/* <DocumentUpload patientId={patientId} /> */}
                      <PatientProfileMessages patientId={patientId} />
                    </div>
                  </div>
                </div>
              </TabPanel>

              <TabPanel
                value={value}
                index={TabsMap("Prescriptions")}
                className="bg--background "
              >
                <div className="container-fluid bg--background ">
                  <div className="row justify-content-center ">
                    <div
                      className={`col-sm-11 pb-5`}
                    >
                      <PrescriptionTable
                        patientId={patientId}
                        isPrescriptionSent={isPrescriptionSent}
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>

              <TabPanel
                value={value}
                index={TabsMap("History")}
                className={`bg--background`}
              >
                <PatientHistoryView patientId={patientId} />
              </TabPanel>

              <TabPanel
                value={value}
                index={TabsMap("Encounters")}
                className={`bg--background`}
              >
                <PatientEncountersList
                  setProviderNoteType={setProviderNoteType}
                  patientId={patientId}
                  isLoadingEncounters={isLoadingEncounters}
                  handleBackdropClose={handleBackdropClose}
                  handleToggle={handleToggle}
                  handleAddenShowModal={handleAddenShowModal}
                  handleAddenModal={handleAddenModal}
                  handleModal={handleModal}
                  setAddEncounterId={setAddEncounterId}
                  recentEncountersList={recentEncountersList}
                />
              </TabPanel>

              <TabPanel
                value={value}
                index={TabsMap("Vitals")}
                className={`bg--background`}
              >
                {/* <PatientVitalsView patientId={patientId} /> */}
                {/* <PatientVitals /> */}
                {<PatientProfileVitals />}
              </TabPanel>
              <TabPanel
                value={value}
                index={TabsMap("Labs")}
                className="bg--background"
              >
                <div className="container-fluid bg--background ">
                  <div className="row justify-content-center ">
                    <div
                      className={`mt-5 my-5 col-sm-11 pb-5`}
                    >
                      <LabOrdersTable
                        patientId={patientId}
                        isLabOrderSent={isLabOrderSent}
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel
                value={value}
                index={TabsMap("Imaging Orders")}
                className="bg--background"
              >
                <div className="container-fluid bg--background ">
                  <div className="row justify-content-center ">
                    <div
                      className={`mt-5 my-5 col-sm-11 pb-5`}
                    >
                      <ImagingOrderTable patientId={patientId} isImagingOrderSent={isImagingOrderSent} setIsImagingOrderSent={setIsImagingOrderSent} />
                    </div>
                  </div>
                </div>
              </TabPanel>
            </ThemeProvider>
          </Grid>
        ) : <Grid
          item
          xs={
            isCollapsed
              ? dimensions?.collapsed?.main
              : dimensions?.default?.main
          }
          sm={
            isCollapsed
              ? dimensions?.collapsed?.main
              : dimensions?.default?.main
          }
          md={
            isCollapsed
              ? dimensions?.collapsed?.main
              : dimensions?.default?.main
          }
          sx={{ overflowX: "hidden", position: 'relative' }}
        // className="noscrollbar"
        >
          <div className="container d-flex align-items-center justify-content-center h-75">
            <div className="row justify-content-center">
              <div className="col-12 text-center">

                <h2><WarningIcon style={{ fontSize: '3rem', fill: "#f8a02e", verticalAlign: 'bottom' }} />  Patient not found</h2>
              </div>
            </div>
          </div>

        </Grid>}
      </Grid>

      <CustomizedSnackbars
        bottom={250}
        message={appointmentNotScheduledError}
        severity="error"
        open={isAppointmentNotRescheduled}
        handleClose={handleAppointmentToastClose}
      />

      <CustomizedSnackbars
        message={"New patient added."}
        severity="success"
        open={isSent}
        handleClose={handleClose}
      />
      <Backdrop
        sx={{ color: "#EC609B", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* <CustomizedSnackbars
        message={"Facesheet uploaded successfully."}
        severity="success"
        open={facesheetUploaded}
        handleClose={handleFacesheetClose}
      /> */}
      <CustomizedSnackbars
        message={errorMessage}
        severity="error"
        open={facesheetUploadedFail}
        handleClose={handleFacesheetUploadFailClose}
      />
      <CustomizedSnackbars
        message={"Addendum saved!"}
        severity="success"
        open={savedAddendum}
        handleClose={handleClose}
      />

      {inPersonVisitDashboard && user?.role?.toLowerCase() === "staff" && pId !== undefined && pId !== null && location?.state !== undefined &&
        location?.state !== null &&
        location?.state?.referer !== undefined &&
        location?.state?.referer !== null &&
        location?.state?.referer === "in-patient-dashboard" ? <BottomNavigationWithDropdown /> : null}
    </>
  );
};

export default PatientsPageNew;

import sendHttpRequest from "../hooks/sendHttpRequest";

export default class InPatientExamRoomService {

    static getRoomsByFacilityId = async (facilityId) => {
        try {
            const response = await sendHttpRequest({
                method: "GET",
                url: `/facilities/${facilityId}/rooms`,
            });
            if (!response.error) {
                return response.data;
            }
        }
        catch (err) {
            console.log(err);
        }
    }

}
import { useDispatch, useSelector } from "react-redux";
import PrescriptionListItem from "./PrescriptionListItem";
import classes from "../prescription/PrescriptionListItem.module.css";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { updatePrescribedMedication } from "../../redux/actions/providerActions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { X } from "react-bootstrap-icons";
import { orderPrescriptionTableStrings } from "../../res/strings";
import uuid from "react-uuid";

import { useEffect } from "react";
import { calenderDateTimeHelper } from "../../utils/dateTimeHelper";
import ReorderPrescriptionModal from "../UI/Modals/PrescriptionModal/ReorderPrescriptionModal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FAFCFC",
    color: "#336383",
    fontWeight: 400,
    fontSize: 12,
    fontFamily: "Montserrat",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    paddingRight: "0px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#FAFCFC",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles({
  customTable: {
    "& .MuiTableCell-sizeMedium": {
      padding: "0px", // <-- arbitrary value
    },
  },
  tableCell: {
    textTransform: "capitalize",
  },
});

const PrescriptionList = (props) => {
  // const { user:{prescription} } = useAuth();
  const __str = orderPrescriptionTableStrings.en;
  const [userInfo, setUserInfo] = useState("");

  const prescribedMedications = useSelector(
    (state) => state.PrescribedMedication.prescribedDrugList
  );
  const patientEncounterInformation = useSelector(
    (state) => state.PatientEncounterData.appoinment?.encounter
  );
  const disableSendRxButton = useSelector(
    (state) => state.DisableSendRxButton.disabled
  );
  const prescriptionNoteText = useSelector(
    (state) => state.prescriptionNote.prescriptionNoteValue
  );
  const [prescriptionList, setrPescriptionList] = useState([]);
  const dispatch = useDispatch();
  let { providerId, encounterId, patientId } = useParams();
  const [openReorderModal, setOpenReorderModal] = useState(false);
  const [rxObject, setRxObject] = useState();
  const [disableItem, setDisableItem] = useState(false);

  const editMedicine = (drugObject) => {
    
    let topDiv = document.getElementById("medication");
    topDiv.scrollIntoView({
      behavior: 'smooth'
    });
  // console.log("drugObject.drugDbCode: ",drugObject.drugDbCode);
    props?.setDeleteMedId(drugObject.drugDbCode);
    props.setUpdateCheck(true);
    props?.fetchAvailableStrengths(drugObject);
    props?.prescribeDrug(drugObject);
    
    
  };

  const  deleteObjectFromArray = (array, property, value) =>{
    console.log("In delete function :",array, property, value);
    let found = false;
    return array.filter(obj => {
      if (!found && Number(obj[property]) === Number(value)) {
        found = true;
        return false;
      }
      return true;
    });
  }
  const deleteMedicine = (ddid) => {
    let result = prescribedMedications.filter((medicine) => {
      return medicine.DDID !== ddid;
    });
    let tempDrug = prescribedMedications.filter((medicine) => {
      return medicine.DDID === ddid;
    });
    // console.log("props?.currentMedicationList: ",props?.currentMedicationList);
    if(props?.currentMedicationList !== undefined && props?.currentMedicationList?.length > 0){
      let currentMedObjt = props?.currentMedicationList.find((medecine)=>{ return medecine.drugId === ddid})
      let pharmacyObject = currentMedObjt?.pharmacy ?? undefined;
      // console.log("After deletion Result ", currentMedObjt);
      if(pharmacyObject !== undefined){
        // console.log("props?.reOrderPharmacy: ",props?.reOrderPharmacy);
        let afterDeletion = deleteObjectFromArray(props?.reOrderPharmacy,"organizationId",pharmacyObject?.organizationId);
        // console.log("afterDeletion::::::::::::",afterDeletion);
        props?.setReOrderPharmacyFlag(true);
        props?.setReOrderPharmacy(afterDeletion);
      }
    }
    dispatch(updatePrescribedMedication(result));
    if(props?.setPrescriptionData !== undefined){
      props?.setPrescriptionData({medicationRx:[...result]})
    }
    if (
      tempDrug[0]?.controlledSubstanceCode !== "" &&
      tempDrug[0]?.controlledSubstanceCode !== "1"
    ) {
      props?.constrolSubstanceCount.pop();
    }
  };

  const handleReorderModal = (rxObject) => {
    // console.log("rxObject: ",rxObject);
    if (props?.setReOrderPharmacyFlag) {
      props?.setReOrderPharmacyFlag(true);
    }
    let reOrderRx = {
      DDID: rxObject?.drugId,
      drugDbCode: rxObject?.drugId,
      drugDescription: rxObject?.drugDescription,
      ndc: rxObject?.ndc,

      Sig: {
        SigText: rxObject?.sigText,
      },
      textValue: rxObject?.sigText,
      Quantity: parseFloat(rxObject?.quantity),
      daySupply: rxObject?.daysSupply,
      NumberOfRefills:
        rxObject?.refills !== undefined &&
          rxObject?.refills !== "" &&
          rxObject?.refills !== 0
          ? rxObject?.refills
          : 0,
      RefillAsNeeded: false,
      DispenseAsWritten: rxObject?.substitution ,
      // Note: rxObject?.pharmacyNote !== undefined ? rxObject?.pharmacyNote : "",
      Note: rxObject?.pharmacyNote,
      DoseForm: rxObject?.quantityUnitOfMeasure || "",
      PrescriptionStartDate: {Date:rxObject?.prescriptionStartDate},
      EarliestFillDate: {Date:rxObject?.earliestFillDate},
      WrittenDate: {
        Date: calenderDateTimeHelper(rxObject?.writtenDate, "YYYY-MM-DD"),
      },
      alertPhysician72: rxObject?.alertPhysician,
      PatientDirections: "",
      flag: true,
      quantityUom: rxObject?.quantityUnitOfMeasure,
      quantityUnitOfMeasureCode: rxObject?.quantityUnitOfMeasureCode,
    };
    // let result = props?.reOrderPharmacy.find((item) => { return item.organizationId === rxObject.organizationId })
    // if (props?.reOrderPharmacy?.length > 0 && result?.length === 0) {
      props?.setReOrderPharmacy([...props?.reOrderPharmacy, { ...rxObject?.pharmacy }]);
    // } else {
    //   props?.setReOrderPharmacy([rxObject?.pharmacy]);
    // }
    // console.log("reOrderRx:::::",reOrderRx);
    editMedicine(reOrderRx);
  };
  const closeReOrderPrescriptionModal = () => {
    setOpenReorderModal(false);
  }



  useEffect(() => {
    let medList = [];
    if (props?.reOrderPrescription === true) {
      medList = prescribedMedications.slice(0, 1);
    } else {
      if (props?.currentMed === false) {
        medList = prescribedMedications?.concat(props?.rxMedications);
      } else {
        medList = props?.rxMedications;
      }
    }
    // console.log(prescribedMedications,props?.rxMedications);
    if (medList?.length > 0) {
      setrPescriptionList(medList);
    }
  }, [prescribedMedications, props?.rxMedications]);


  return (
    <>
      {openReorderModal && (
        <ReorderPrescriptionModal
          show={openReorderModal}
          handleModal={closeReOrderPrescriptionModal}
          encounterId={props.patientId}
          patientId={props.patientId}
          RxObject={rxObject}
        // closeReOrderPrescriptionModal = {closeReOrderPrescriptionModal}
        // fetchPrescriptionHistory={fetchPrescriptionHistory}
        />
      )}
      <TableContainer
        sx={{ borderRadius: "10px", border: "1px solid #D9E8EC" }}
      >
        <Table>
          <TableHead
            sx={{
              borderTopColor: "#FAFCFC",
              borderRightColor: "#FAFCFC",
              borderLeftColor: "#FAFCFC",
              borderBottom: "1px solid #D9E8EC",
            }}
          >
            <StyledTableRow>
              <StyledTableCell>{__str.drugDescription}</StyledTableCell>
              <StyledTableCell>{__str.sig}</StyledTableCell>
              <StyledTableCell>{__str.dispense}</StyledTableCell>
              <StyledTableCell>{__str.refills}</StyledTableCell>
              <StyledTableCell>{__str.status}</StyledTableCell>
              <StyledTableCell>{""}</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {prescriptionList?.length > 0 &&
              prescriptionList?.map((prescriptionItem) => {
                // console.log("prescriptionItem",prescriptionItem);
                const {
                  drugDescription,
                  sigText,
                  Sig,
                  drugDbCode,
                  textValue,
                  quantity,
                  Quantity,
                  NumberOfRefills,
                  refills,
                  drugId,
                } = prescriptionItem;
                return (
                  <PrescriptionListItem
                    key={drugDescription + Math.floor(Math.random() * 100)}
                    medecineName={drugDescription}
                    sig={
                      sigText !== undefined
                        ? sigText
                        : textValue !== undefined
                          ? textValue
                          : Sig?.SigText
                    }
                    editMedicine={editMedicine}
                    deleteMedicine={deleteMedicine}
                    drugDbCode={drugDbCode ?? drugId}
                    setDrugData={props?.setDrugData}
                    drugData={props?.drugData}
                    quantity={quantity !== undefined ? quantity : Quantity}
                    refills={refills !== undefined ? refills : NumberOfRefills}
                    updateCheck={props?.updateCheck}
                    flag={props?.providerNote === true ? false : prescriptionItem?.prescriptionStatus ? true : false}
                    status={
                      props?.providerNote === true ? "Pending" : prescriptionItem?.prescriptionStatus
                        ? "Ordered"
                        : "Pending"
                    }
                    prescriptionItem={prescriptionItem}
                    patientData={props?.patientData}
                    rxStatus={
                      prescriptionItem?.rxStatus !== undefined
                        ? prescriptionItem?.rxStatus
                        : ""
                    }
                    isCancelled={
                      prescriptionItem?.cancelled !== undefined
                        ? prescriptionItem?.cancelled
                        : false
                    }
                    currentMed={props?.currentMed === true ? true : false}
                    handleReorderModal={handleReorderModal}
                    reOrderPharmacy={props?.reOrderPharmacy}
                    disableItem={disableItem}
                    setDisableItem={setDisableItem}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* {console.log("prescribedMedications",prescribedMedications.prescribedDrugList)} */}
      {/* 
      {console.log("disableSendRxButton",disableSendRxButton)} */}
      {/* {console.log("prescriptionNoteText",prescriptionNoteText)} */}
      {/* {console.log("pharmacyId",pharmacyId)} */}
      {/* {console.log("drugData in prescription list ",props?.drugData)} */}

      {/* {console.log("patientEncounterInformation: ",patientEncounterInformation)} */}
      {/* {console.log("prescribedMedications", prescribedMedications)}
      {console.log("rxMedications", props?.rxMedications)}
      {console.log("Concatination: ",prescribedMedications.concat(props?.rxMedications))} */}
      {/* {console.log("props?.reOrderPharmacy: ",props?.reOrderPharmacy)} */}
    </>
  );
};
export default PrescriptionList;

import AgoraRTC from "agora-rtc-sdk-ng";
import { useRef } from "react";
import { useState, createContext } from "react";

const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });


export const VideoCallContext = createContext({
    client: client,
    localAudioTrack: null,
    localVideoTrack: null,
    sessionId: null,
    tokenId: null,
    apiKey: 'e9ccb1b4b9654f299814d244e3e4f96c',
    muteAudio: () => { },
    pauseVideo: () => { },
    unmuteAudio: () => { },
    resumeVideo: () => { },
    endSession: () => { },
    createLocalTracks: () => { },
    setLocalAudioTrack: () => { },
    setLocalVideoTrack: () => { },
    remoteUsers: [],
    appointmentData: {},
    addRemoteUser: () => { },
    removeRemoteUser: () => { },
    updateAppointmentData: () => { },
    setSessionId: () => { },
    getSessionId: () => { },
    setTokenId: () => { },
    getTokenId: () => { },
    addAudioTrack: () => { },
    getAudioTrack: () => { },
    addVideoTrack: () => { },
    getVideoTrack: () => { },
    setIsVideoCallActive: () => { },
    getIsVideoCallActive: () => { },
    addRemoteUser: () => { },
    removeRemoteUser: () => { },
    isVideoCallActive: false,
    clearVideoCallContextData: () => { },
    appointmentObject: null,
    updateAppointmentObject: () => { },
    releaseMedia: () => { },
    visitType: null,
    updateVisitType: () => { },
    endSessionWithoutDataRefresh: () => { },
    endUnConnectedSession: () => { }
});

export function VideoCallProvider(props) {

    // const [localAudioTrack, setLocalAudioTrack] = useState(null);
    // const [localVideoTrack, setLocalVideoTrack] = useState(null);
    const localAudioTrack = useRef();
    const localVideoTrack = useRef();
    const sessionId = useRef();
    const tokenId = useRef();
    const isVideoCallActive = useRef();
    const apiKey = 'e9ccb1b4b9654f299814d244e3e4f96c'


    const [appointmentData, setAppointmentData] = useState(null);
    const [appointmentObject, setAppointmentObject] = useState(null);
    const [visitType, setVisitType] = useState(null);
    const [remoteUsers, setRemoteUsers] = useState([]);

    function muteAudio() {
        localAudioTrack.current.setMuted(true);
    }

    function unmuteAudio() {
        localAudioTrack.current.setMuted(false);
    }



    function pauseVideo() {
        client.unpublish(localVideoTrack.current)
            .then(() => {
                localVideoTrack.current.stop();
                document.getElementById("publisher").style.visibility = 'hidden';
            })
            .then(() => {
                localVideoTrack.current.setEnabled(false);
            });
    }

    function resumeVideo() {
        localVideoTrack.current.setEnabled(true).then((res) => {
            document.getElementById("publisher").style.visibility = 'visible';
            client.publish(localVideoTrack.current);
            localVideoTrack.current.play(document.getElementById('publisher'));
        });
    }

    async function endSession() {
        console.log("Calling end session...")
        try {
            await localVideoTrack.current.stop();
            await localAudioTrack.current.stop();
            await localVideoTrack.current.close();
            await localAudioTrack.current.close();
            await client.unpublish([localVideoTrack.current, localAudioTrack.current]);
        }
        catch (error) {
            console.log(error);
        }
        setAppointmentData(p => null);
        setIsVideoCallActive(false);
        await client.leave();

    }

    async function endUnConnectedSession() {
        console.log("Ending unconnected session...")
        try {
            await localVideoTrack.current.stop();
            await localAudioTrack.current.stop();
            await localVideoTrack.current.close();
            await localAudioTrack.current.close();
        }
        catch (error) {
            console.log(error);
        }
        // setAppointmentData(p => null);
        setIsVideoCallActive(false);
        await client.leave();

    }

    async function endSessionWithoutDataRefresh() {
        console.log("Calling end session...")
        try {
            await localVideoTrack.current.stop();
            await localAudioTrack.current.stop();
            await localVideoTrack.current.close();
            await localAudioTrack.current.close();
            // await client.unpublish([localVideoTrack.current, localAudioTrack.current]);
        }
        catch (error) {
            console.log(error);
        }
        // setAppointmentData(p => null);
        setIsVideoCallActive(false);
        await client.leave();

    }


    async function releaseMedia() {
        await localVideoTrack.current.stop();
        await localAudioTrack.current.stop();
        await localVideoTrack.current.close();
        await localAudioTrack.current.close();
    }



    function addVideoTrack(track) {
        // setLocalVideoTrack(p => track);
        localVideoTrack.current = track;
    }

    function getVideoTrack() {
        return localVideoTrack.current;
    }

    function getAudioTrack() {
        return localAudioTrack.current;
    }

    function addAudioTrack(track) {
        // setLocalAudioTrack(p => track);
        localAudioTrack.current = track;
    }

    function setSessionId(id) {
        sessionId.current = id;
    }

    function getSessionId() {
        return sessionId.current;
    }

    function getTokenId() {
        return tokenId.current;
    }

    function setTokenId(tid) {
        tokenId.current = tid;
    }

    function setIsVideoCallActive(status) {
        isVideoCallActive.current = status;
    }

    function getIsVideoCallActive() {
        return isVideoCallActive.current;
    }

    async function createLocalTracks() {
        const localAudio = await AgoraRTC.createMicrophoneAudioTrack();
        const localVideo = await AgoraRTC.createCameraVideoTrack();
        return [localAudio, localVideo];
    }

    function addRemoteUser(user) {
        setRemoteUsers((prevState) => [...prevState, user]);
    }

    function removeRemoteUser(uid) {
        setRemoteUsers((prevState) => prevState.filter((user) => user.uid !== uid));
    }

    function updateAppointmentData(data) {
        // console.log("Called UpdateAppointmentData", data);
        setAppointmentData(p => ({ ...p, data }));
    }

    function updateVisitType(visitType) {
        setVisitType(visitType);
    }


    function updateAppointmentObject(data) {
        setAppointmentObject(p => ({ ...p, data }));
    }

    const clearVideoCallContextData = () => {
        // Clear the client




        // Clear the mute audio function
        muteAudio = () => { };

        // Clear the pause video function
        pauseVideo = () => { };

        // Clear the unmute audio function
        unmuteAudio = () => { };

        // Clear the resume video function
        resumeVideo = () => { };

        // Clear the end session function
        endSession = () => { };

        // Clear the create local tracks function
        createLocalTracks = () => { };


        // Clear the appointment data object
        updateAppointmentData(null);

    };

    return (
        <VideoCallContext.Provider
            value={{
                client,
                muteAudio,
                pauseVideo,
                endSession,
                resumeVideo,
                unmuteAudio,
                createLocalTracks,
                remoteUsers,
                appointmentData,
                addRemoteUser,
                removeRemoteUser,
                updateAppointmentData,
                setSessionId,
                getSessionId,
                setTokenId,
                getTokenId,
                addAudioTrack,
                getAudioTrack,
                addVideoTrack,
                getVideoTrack,
                setIsVideoCallActive,
                getIsVideoCallActive,
                apiKey,
                isVideoCallActive,
                clearVideoCallContextData,
                appointmentObject,
                updateAppointmentObject,
                releaseMedia,
                updateVisitType,
                visitType,
                endSessionWithoutDataRefresh,
                endUnConnectedSession
            }}>
            {props.children}
        </VideoCallContext.Provider>)


}
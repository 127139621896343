
import sendHttpRequest from "../hooks/sendHttpRequest";

export default class ClaimsManagementService {


    static fetchPlaceOfServiceCodes = async () => {
        try {
            const httpResponse = await sendHttpRequest({
                url: `/claimsManagement/placeOfServiceCodes/all`,
                method: "GET",

            });
            if (!httpResponse.error) {
                return httpResponse.data;
            }
            else {
                return null;
            }
        }
        catch (err) {
            console.log("Error in fetching patient demographics: ", err);
        }
    }


}
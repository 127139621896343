import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { calenderDateTimeHelper, getAgeByDateOfBirth } from "../../utils/dateTimeHelper";
import sendHttpRequest from "../hooks/sendHttpRequest";
import MessagesIcon from "../UI/Drawer/DrawerItemIcons/MessagesIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  setDisableSendRxButton,
  setPatientEncounterData,
  setPatientID,
  setPrescriptionNoteText,
  updatePrescribedMedication,
} from "../../redux/actions/providerActions";
import { formatPhoneNumber } from "../../utils/helpers/HelperFunctions";
import NewPrescriptionModal from "../UI/Modals/PrescriptionModal/NewPrescriptionModal";
import PrescriptionRequests from "../pages/PrescriptionRequests";
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";
import placeholder from "../../assets/images/placeholder.png";
import PrescriptionAndAppointmentDropdown from "../prescription/NewPrescriptionAndAppointmentDropdown";
import NewAppointmentModal from "../UI/Modals/NewAppointmentModal";
import InitiateResetPasswordModal from "../UI/Modals/ProviderModals/InitiateResetPasswordModal";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import classes from "./PatientStaticProfileHeader.module.css";
import { RolesTextConversion } from "../../utils/helpers/RolesMap";
import { numbersEnum, orderPrescriptionStrings } from "../../res/strings";
import { useAuth } from "../hooks/useAuth";
import { fecthPatientInformationURL, fetchPatientGeneralInfoURL, fetchSocialHistoryUrl } from "../../res/endpointurls";
import LabRequisitionFormModal from "../UI/Modals/LabRequisitionFormModal";
import ImagingOrderModal from "../UI/Modals/ImagingOrderModal";

const styles = {
  menue_reset_blue: {
    backgroundColor: "#004FD4",
    borderRadius: "10px",
    color: "#fff",
  },
  menue_reset_default: {
    // backgroundColor: "#004FD4",
    // borderRadius: "10px",
    border: "1px solid #d9e8ec",
    borderRadius: "10px",
    color: "#336383",
  },
};

const PatientStaticProfileHeader = (props) => {
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);

  const { user } = useAuth();

  let { patientId } = useParams();
  const dispatch = useDispatch();
  const isPatientEdited = useSelector(
    (state) => state.isPatientEdited.isPatientEdited
  );
  const __str = numbersEnum.en;
  const __order_prescription_str__ = orderPrescriptionStrings.en;
  const viewRef = useRef();
  const [isPatientInformationFetched, setIsPatientInformationFetched] =
    useState(true);
  const [isPatientMsgCountFetched, setIsPatientMsgCountFetched] =
    useState(true);

  const [patientInformation, setPatientInformation] = useState();
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const [isSent, setIsSent] = useState(false);

  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [pwdResetRequestResponse, setPwdResetRequestResponse] = useState("");
  const [openPwdResetToast, setOpenPwdResetToast] = useState(false);
  // const [isAppointmentNotRescheduled, setIsAppointmentNotRescheduled] = useState(false);
  // const [appointmentNotScheduledError, setAppointmentNotScheduledError] = useState("");
  const [isSmoker, setIsSmoker] = useState(2);
  // const [selectedUser, setSelectedUser] = useState("");
  // const [userInfo, setUserInfo] = useState({});
  // const [isLoadingFromSession, setIsLoadingFromSession] = useState(true);

  const emergencyContactNumber = patientInformation?.communicationNumbers?.emergencyContact?.find((numberItem) => {
    return numberItem?.phoneNumber !== "";
  })?.phoneNumber || "";

  let patientName = patientInformation?.name[0];
  // const emergencyContactNumber = patientInformation?.primaryTelephone;


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);



  const [openNewAppointmentModal, setOpenNewAppointmentModal] = useState(false);
  const [isSentAppointment, setIsSentAppointment] = useState(false);
  const prescriberInfo = useSelector((state) => state.PrecriberData.prescriber);

  const [openLabRequisitionModal, setOpenLabRequisitionModal] = useState(false);

  //=============New prescription modal start==============//
  const [newPrescriptionModal, setNewPrescriptionModal] = useState(false);
  const handleNewPrescriptionModal = () => {
    setNewPrescriptionModal(!newPrescriptionModal);
  };

  // ============Imaging order modal====================================
  const [openImagingOrderModal, setOpenImagingOrderModal] = useState(false);
  const [isImagingOrderSent, setIsImagingOrderSent] = useState(false);
  const [imagingSeverity, setImagingSeverity] = useState("");
  const [imagingMessage, setImagingMessage] = useState(false);


  const handleImagingOrderModal = () => {
    setOpenImagingOrderModal(!openImagingOrderModal);
  }




  const handleSetIsSent = () => {
    setIsSent(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSent(false);
  };

  const handleNewPrescription = () => {
    dispatch(updatePrescribedMedication([]));
    dispatch(setDisableSendRxButton(false));
    dispatch(setPrescriptionNoteText(""));
    dispatch(setPatientEncounterData(""));

    // navigate(`/prescription-requests/${patientId}/${patientId}`)
    handleNewPrescriptionModal();
  };
  //=============New prescription modal end==============//



  //=============New appointment modal start============//
  const handleAppointmentToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSentAppointment(false);
  };

  const handleNewAppointmentModal = () => {
    dispatch(setPatientID(-1));
    setOpenNewAppointmentModal(!openNewAppointmentModal);
  };

  const handleNewAppointment = () => {
    handleNewAppointmentModal();
  };
  //===========New appointment modal end===============//

  const fecthUnreadMessagesCount = async (prescriberId) => {
    setIsPatientMsgCountFetched(true);
    const httpResponse = await sendHttpRequest({
      url: "/messages/unreadCount",
      method: "GET",
      params: {
        userId: prescriberId,
        tenantId: user?.tenantId
      },
    });
    if (!httpResponse.error) {
      setUnreadMessagesCount(httpResponse?.data?.TotalUnreadCount);
      setIsPatientMsgCountFetched(false);
    } else {
      console.log(httpResponse.error);
      setIsPatientMsgCountFetched(false);
    }
  };



  const fecthPatientInformation = async () => {
    props?.setIsPatientNotFound(false);
    setIsPatientInformationFetched(true);

    const httpResponse = await sendHttpRequest({
      url: fecthPatientInformationURL(),
      method: "GET",
      params: {
        patientId: props?.patientId
      }
    });
    if (!httpResponse.error) {
      setPatientInformation(httpResponse.data);

    }
    else {
      if (httpResponse.status === 404) {
        props?.setIsPatientNotFound(true);
      }
    }
    setIsPatientInformationFetched(false);
  };

  const fecthSocialHistory = async () => {
    const httpResponse = await sendHttpRequest({
      url: fetchSocialHistoryUrl(props?.patientId),
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);

      setSmokerStatus(httpResponse.data);
    }
  };

  const setSmokerStatus = (socialHistory) => {
    if (socialHistory != undefined && socialHistory != null && socialHistory != "" && socialHistory?.length > 0) {
      const smokingQuestion = socialHistory.find((socialHistory) => (socialHistory?.question?.questionId === 1));
      const smokeValue = smokingQuestion?.answer?.length > 0 ? (smokingQuestion?.answer[0]) : null;
      if (smokeValue !== null) {
        //Value greater than 2 means the patient is a smoker
        if (smokeValue > 2) {
          setIsSmoker(1);
        }
        else {
          setIsSmoker(0);
        }
      }
      else {
        setIsSmoker(-1);
      }
    }
  };

  const initiatePasswordReset = () => {
    setOpenResetPasswordModal(true);
  };
  const handleResetPasswordModal = () => {
    setOpenResetPasswordModal(!openResetPasswordModal);
  };
  const handlePasswordResetToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenPwdResetToast(false);
  };
  const handleImagingToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsImagingOrderSent(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenue = () => {
    setAnchorEl(null);
  };

  const handleLabRequisitionModal = () => {
    // console.log("called handleLabRequisitionModal");
    setOpenLabRequisitionModal(!openLabRequisitionModal);
    // props?.setIsLabOrderSent(p => !p);
  }

  useEffect(() => {
    // getMapFromSession();
    dispatch(setPrescriptionNoteText(""));
    viewRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    fecthPatientInformation(props?.patientId);
    // fecthUnreadMessagesCount();
    fecthSocialHistory();
  }, [props?.patientId, patientId, isPatientEdited]);

  useEffect(() => {
    props?.setIsAppointmentScheduled(!props?.isAppointmentScheduled);
  }, [isSentAppointment])

  return (
    <>
      {openResetPasswordModal ? (
        <InitiateResetPasswordModal
          show={openResetPasswordModal}
          patient={true}
          handleModal={handleResetPasswordModal}
          FirstName={patientInformation?.name[0]?.firstName}
          LastName={patientInformation?.name[0]?.lastName}
          Id={props?.patientId}
          setOpenPwdResetToast={setOpenPwdResetToast}
          setPwdResetRequestResponse={setPwdResetRequestResponse}
        />
      ) : null}

      {newPrescriptionModal ? (
        <NewPrescriptionModal
          patientId={props?.patientId}
          show={newPrescriptionModal}
          handleSetIsSent={handleSetIsSent}
          handleNewPrescriptionModal={handleNewPrescriptionModal}
          prescriptionRequestModal={
            <PrescriptionRequests
              encounterId={props?.patientId}
              patientId={props?.patientId}
              handleNewPrescriptionModal={handleNewPrescriptionModal}
              handleSetIsSent={handleSetIsSent}
              newPrescriptionRequest={true}
              setIsPrescriptionSent={props?.setIsPrescriptionSent}
            />
          }

        />
      ) : null}
      {openNewAppointmentModal ? (
        <NewAppointmentModal
          handleCurrentMonth={null}
          show={openNewAppointmentModal}
          handleModal={handleNewAppointmentModal}
          setIsSentAppointment={setIsSentAppointment}
          selectedPrescribedId={prescriberInfo?.selectedPrescriberId}
          fetchAppointments={null}
          appointmentFromPatient={true}
          patientId={props?.patientId}
          searchDisable={true}
          name={patientInformation?.name}
          setAppointmentNotScheduledError={props.setAppointmentNotScheduledError}
          setIsAppointmentNotRescheduled={props.setIsAppointmentNotRescheduled}

        />
      ) : null}

      {
        openLabRequisitionModal ?
          <LabRequisitionFormModal
            show={openLabRequisitionModal}
            handleModal={handleLabRequisitionModal}
            patientId={props.patientId !== undefined ? props.patientId : patientId}
            setIsLabOrderSent={props?.setIsLabOrderSent}
          />
          : null
      }

      {
        openImagingOrderModal ?
          <ImagingOrderModal
            show={openImagingOrderModal}
            handleModal={handleImagingOrderModal}
            patientId={props.patientId !== undefined ? props.patientId : patientId}
            setIsImagingOrderSent={setIsImagingOrderSent}
            setImagingSeverity={setImagingSeverity}
            setImagingMessage={setImagingMessage}
            setIsImagingOrderDataSent={props?.setIsImagingOrderSent}
            isImagingOrderDataSent={props?.isImagingOrderSent}
          /> : null
      }

      <div
        ref={viewRef}

        className="d-flex py-3 px-4 justify-content-between bg-white">
        <div className="d-flex">
          {isPatientInformationFetched === true ? (
            <Skeleton variant="circular" width={40} height={40} />
          ) : (
            <Avatar
              src={
                patientInformation?.profilePicture !== undefined
                  ? patientInformation?.profilePicture
                  : placeholder
              }
            ></Avatar>
          )}
          <div>
            {isPatientInformationFetched === true ? (
              <Skeleton variant="text" width="80px" />
            ) : (
              isPatientInformationFetched === false && (
                <div className="px-2 mt-1">
                  <h3 className="font-16 m-0 p-0 fw-sb align-self-center" style={{ lineHeight: '16px' }}>
                    {`${patientName?.firstName || ''} ${patientName?.middleName?.length > 0 ? patientName?.middleName : ''} ${patientName?.lastName || ''}`}
                  </h3>
                  <div className="d-flex gap-2">
                    {isPatientInformationFetched ? (
                      <Skeleton variant="text" height="50px" />
                    ) : (<div className="d-flex gap-2">
                      <div className="font-14 text-center fw-thin text--secondary">
                        {"PID: "} {patientInformation?.patientTenantIdentification}
                      </div>
                      <div className="border--right">&nbsp;</div>
                      <div className="font-14 text-center fw-thin text--secondary">
                        {patientInformation?.gender ?? "N/A"} &ndash;{" "}
                        {getAgeByDateOfBirth(patientInformation?.dateOfBirth) +
                          " years"}
                      </div>
                      <div className="border--right">&nbsp;</div>

                      <div className="font-14 text-center fw-thin text--secondary">
                        {"DOB: " +
                          calenderDateTimeHelper(patientInformation?.dateOfBirth, "MMM DD, YYYY")}
                      </div>
                      {
                        isSmoker === 1 && (
                          <div className={`border--right`}>&nbsp;</div>
                        )
                      }

                      {patientInformation?.RecentEncounters?.length > 0 ?
                        <>
                          <div className="font-14 text-center fw-thin text--secondary">
                            {patientInformation?.RecentEncounters?.length > 0
                              ? patientInformation?.RecentEncounters[
                                patientInformation?.RecentEncounters?.length - 1
                              ]?.patientType
                              : ""}
                          </div>
                          {
                            isSmoker === 1 && (
                              <div className={`border--right`}>&nbsp;</div>
                            )
                          }
                        </>
                        : null}

                      {emergencyContactNumber !== undefined && emergencyContactNumber !== '' ? <>
                        <div className="border--right">&nbsp;</div>
                        <div className="font-14 text-center fw-thin text--secondary">
                          Emergency Contact:{" "}
                          <span className="text--terciary fw-sb">
                            {formatPhoneNumber(emergencyContactNumber)}
                          </span>
                        </div>
                      </> : null}


                    </div>)}
                  </div>
                  {/* <h4>{patientInformation?.HumanPatient?.PatientId}</h4> */}
                </div>
              )
            )}
          </div>

        </div>
        <div className="d-flex gap-2 align-items-center">
          {
            // isLoadingFromSession && (
            <div
              // hidden={
              //   user?.role?.toLowerCase() === "practiceadmin"
              //     ? false
              //     : true
              // }
              hidden={subscriptionPrivilegeGroups['Practice Management']?.rpp?.active === false ? true : false}
            >
              <IconButton
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                className={`ms-4 `}
                aria-label="more"
                sx={
                  open
                    ? styles.menue_reset_blue
                    : styles.menue_reset_default
                }
              >
                <MoreHorizIcon />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                dense
                onClose={handleCloseMenue}
                disableScrollLock={true}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem
                  dense
                  onClick={() => {
                    handleCloseMenue();
                    initiatePasswordReset();
                  }}
                  sx={{ fontFamily: "Montserrat" }}
                >
                  Reset Password
                </MenuItem>
              </Menu>
            </div>
            // )
          }

          {!isPatientInformationFetched ? (subscriptionPrivilegeGroups['Appointments']?.spa?.active === true || subscriptionPrivilegeGroups['Tasks']?.orx?.active === true ||
            subscriptionPrivilegeGroups['Encounters']?.apa?.active === true || subscriptionPrivilegeGroups['Tasks']?.olp?.active === true) && (
              <PrescriptionAndAppointmentDropdown
                handleNewAppointment={handleNewAppointment}
                handleNewPrescription={handleNewPrescription}
                handleLabRequisitionModal={handleLabRequisitionModal}
                handleImagingOrderModal={handleImagingOrderModal}
                patientId={props.patientId !== undefined ? props.patientId : patientId}
              />
            ) : (
            <Skeleton width={180} animation="wave" />
          )}
        </div>

      </div>
      {/* {console.log("patientInformation: ",patientInformation)} */}
      <CustomizedSnackbars
        message={__order_prescription_str__.eprescribe_msg}
        severity="success"
        open={isSent}
        handleClose={handleClose}
        style={{
          bottom: "-60vh",
          position: "absolute",
          zIndex: 1600
        }}
      />
      <CustomizedSnackbars
        message={"Appointment saved"}
        severity="success"
        bottom={250}
        open={isSentAppointment}
        handleClose={handleAppointmentToastClose}
        style={{
          bottom: "-60vh",
          position: "absolute",
          zIndex: 1600
        }}
      />
      <CustomizedSnackbars
        message={pwdResetRequestResponse}
        severity="success"
        bottom={250}
        open={openPwdResetToast}
        handleClose={handlePasswordResetToast}
        style={{
          bottom: "-60vh",
          position: "absolute",
          zIndex: 1600
        }}
      />
      <CustomizedSnackbars
        message={imagingMessage}
        severity={imagingSeverity}
        bottom={250}
        open={isImagingOrderSent}
        handleClose={handleImagingToast}
        style={{
          bottom: "-60vh",
          position: "absolute",
          zIndex: 1600
        }}
      />


      {/* {console.log("patientId: ",patientId)} */}
    </>
  );
};

export default PatientStaticProfileHeader;

import { Navigate, Outlet } from "react-router-dom";
import { jsonToMap } from "../../../utils/helpers/HelperFunctions";
import { useAuth } from "../../hooks/useAuth";
import PatientLayout from "../../layouts/PatientLayout";

export const PatientProtectedRoutes = ({ videoTrack, audioTrack, isVideoCallActive, setTracks, resetTracks, appointment, setAppointment }) => {
    const { user, sessionExpired } = useAuth();
    const map = jsonToMap(user);

    if (map.get("sessionExpired")) {
        sessionExpired();
    }
    else if (!user && map.get("role") === "patient") {
        return <Navigate to="/patient-portal" />;
    }
    else {
        if (map.get("role") !== "patient") {
            return <Navigate to="/dashboard" />;
        }
    }

    return (
        <PatientLayout videoTrack={videoTrack} audioTrack={audioTrack} isVideoCallActive={isVideoCallActive} setTracks={setTracks} appointment={appointment} setAppointment={setAppointment} resetTracks={resetTracks}>
            <Outlet context={{
                videoStates: [videoTrack, audioTrack, isVideoCallActive],
                trackMutators: [setTracks, resetTracks],
                appointmentData: [appointment, setAppointment]
            }} />
        </PatientLayout>
    )
};
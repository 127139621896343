const AppointmentsIcon = (props) => {
  return (
    <svg
      id="Icon-Appointments"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 28.001 28"
      {...props}
      fill={props.style.color}
    >
      <path
        id="Icon-Appointments-2"
        data-name="Icon-Appointments"
        d="M5.834,28A5.84,5.84,0,0,1,0,22.167v-14A5.841,5.841,0,0,1,5.834,2.333H7V1.167a1.167,1.167,0,0,1,2.333,0V2.333h9.334V1.167a1.167,1.167,0,1,1,2.333,0V2.333h1.167A5.841,5.841,0,0,1,28,8.167v14A5.84,5.84,0,0,1,22.167,28Zm-3.5-5.833a3.5,3.5,0,0,0,3.5,3.5H22.167a3.5,3.5,0,0,0,3.5-3.5v-10.5H2.333Zm0-14V9.334H25.668V8.167a3.5,3.5,0,0,0-3.5-3.5H5.834A3.5,3.5,0,0,0,2.333,8.167ZM18.084,17.5a1.75,1.75,0,1,1,1.75,1.75A1.75,1.75,0,0,1,18.084,17.5Zm-5.833,0A1.75,1.75,0,1,1,14,19.25,1.749,1.749,0,0,1,12.251,17.5Zm-5.834,0a1.75,1.75,0,1,1,1.75,1.75A1.75,1.75,0,0,1,6.417,17.5Z"

        stroke=""
      />
    </svg>
  );
};

export default AppointmentsIcon;

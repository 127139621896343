import { useEffect, useState } from "react";
import PageHeader from "../../../../components/UI/General/PageHeader"
import { useAuth } from "../../../../components/hooks/useAuth";
import { useLocation } from "react-router-dom";
import sendHttpRequest from "../../../../components/hooks/sendHttpRequest";
import { Backdrop, CircularProgress, LinearProgress } from "@mui/material";
import useClaimPlaceOfServiceCodes from "../../claim-hooks/useClaimPlaceOfServiceCodes";
import { calenderDateTimeHelper } from "../../../../utils/dateTimeHelper";
import { currencyFormatter, formatPhoneNumber } from "../../../../utils/helpers/HelperFunctions";
import useModal from "../../../../components/hooks/useModal";
import ClaimResponseModal from "../UI/Modals/ClaimResponseModal";
import { Accordion, AccordionDetails, AccordionSummary, PnToolTip } from "../../../../components/UI/Reusable/PnComponents";
import { STATUS_MAP } from "../../../../billing-and-claims/BillingAndClaimsConstants";

function statusToString(status) {
    return STATUS_MAP.get(status);
}

function truncate(str, n, ellipsisColor = '#323232') {
    if (str?.length > n) {
        const truncatedStr = str?.substr(0, n - 1);
        return (
            <span>
                {truncatedStr}
                <span style={{ color: ellipsisColor, fontWeight: 600 }}>...</span>
            </span>
        );
    } else {
        return str;
    }
}


function ClaimHistoryPage(props) {
    const { user } = useAuth();
    const location = useLocation();
    const encounter = location?.state?.data?.encounter;
    const referringPhysician = location?.state?.data?.encounter?.referringPhysician;

    const { open: openClaimResponseModal, handleModal: handleClaimResponseModal } = useModal(false);
    const [claimHistory, setClaimHistory] = useState(null);
    const [isClaimHistoryLoading, setIsClaimHistoryLoading] = useState(false);

    const [claimResponseData, setClaimResponseData] = useState(null);
    const [isClaimResponseLoading, setIsClaimResponseLoading] = useState(false);


    const { placeOfServiceCodes, isPlaceOfServiceCodeLoading } = useClaimPlaceOfServiceCodes();
    const placeOfServiceMap = new Map();



    for (const { code, name } of placeOfServiceCodes) {
        placeOfServiceMap.set(code, name);
    }

    async function viewClaimResponse(claimId) {
        setIsClaimResponseLoading(true);
        const httpResponse = await sendHttpRequest({ method: 'GET', url: `/claims/${claimId}/response` });
        if (!httpResponse.error) {
            setClaimResponseData(httpResponse.data);
            handleClaimResponseModal();
        }
        setIsClaimResponseLoading(false);
    }


    async function fetchClaimHistoryByClaimId(claimId) {
        setIsClaimHistoryLoading(true);
        const httpResponse = await sendHttpRequest({
            method: "GET",
            url: `/claims/tenants/${user?.tenantId}/insurance-claims/${claimId}`
        });
        if (!httpResponse.error) {
            console.log(httpResponse.data);
            setClaimHistory(httpResponse.data);
        }
        setIsClaimHistoryLoading(false);
    }

    const totalAdjustmentAmount = claimHistory?.claimServices?.reduce((accumulator, item) => {
        const adjustments = item?.claimServicePayment?.adjustments;
        if (adjustments != null) {
            const adjustmentAmounts = adjustments?.map(adj => adj.adjustmentReasons[0].amount);
            return accumulator + adjustmentAmounts.reduce((sum, amount) => sum + amount, 0);
        }
        else {
            return "N/A"
        }
    }, 0);

    const totalClaimAmount = claimHistory?.claimServices?.reduce((accumulator, item) => {
        const charges = parseFloat(item?.charges);
        return accumulator + charges
    }, 0);


    useEffect(() => {
        fetchClaimHistoryByClaimId(location?.state?.data?.claimId);
    }, [location?.state?.data?.claimId])

    return (
        <>
            {openClaimResponseModal ? <ClaimResponseModal show={openClaimResponseModal} handleModal={handleClaimResponseModal} claimResponseData={claimResponseData} /> : null}

            <PageHeader title="Claim Details" />
            {isClaimHistoryLoading ? <Backdrop
                sx={{ color: "#EC609B", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isClaimHistoryLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop> : <><div className="container-fluid bg--background">
                <div className="row justify-content-center mb-5">
                    <div className="col-sm-11 bg-white mt-2 mb-5 br-10 border--default">
                        <div className="row">
                            <div className="col-sm-12">
                                <Accordion
                                    sx={{ paddingTop: 0, paddingBottom: 0, pt: 0, pb: 0 }}
                                    defaultExpanded={true}
                                >
                                    <AccordionSummary>
                                        <h2 className="font-18 fw-sb m-0">Encounter</h2>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div>
                                                    <div className='row py-1'>
                                                        <div className='col-12'>
                                                            <span className='text--terciary d-block font-14 fw-sb'>Patient</span>
                                                        </div>
                                                    </div>
                                                    <div className='row pb-1'>
                                                        <div className='col-3 fw-sb text--secondary  font-12'>Name</div>
                                                        <div className='col-7 font-12'>
                                                            {`${claimHistory?.encounter?.patient?.firstName} ${claimHistory?.encounter?.patient?.lastName}`}</div>
                                                    </div>
                                                    <div className='row pb-1'>
                                                        <div className='col-3 fw-sb text--secondary  font-12'>DOB</div>
                                                        <div className='col-7 font-12'>{calenderDateTimeHelper(claimHistory?.encounter?.patient?.dob, "MMM DD, YYYY")}</div>
                                                    </div>

                                                    <div className='row pb-1'>
                                                        <div className='col-3 fw-sb text--secondary  font-12'>Gender</div>
                                                        <div className='col-7 font-12'>  {`${claimHistory?.encounter?.patient?.gender}`}</div>
                                                    </div>

                                                    <div className='row pb-1'>
                                                        <div className='col-3 fw-sb text--secondary  font-12'>Address</div>
                                                        <div className='col-7 font-12'>
                                                            {`${claimHistory?.encounter?.patient?.addressLine1} ${claimHistory?.encounter?.patient?.addressLine2 ?? ''}, ${claimHistory?.encounter?.patient?.city}, ${claimHistory?.encounter?.patient?.state}, ${claimHistory?.encounter?.patient?.zipCode}`}
                                                        </div>
                                                    </div>

                                                    <div className='row pb-1'>
                                                        <div className='col-3 fw-sb text--secondary  font-12'>Phone</div>
                                                        <div className='col-7 font-12'>{formatPhoneNumber(claimHistory?.encounter?.patient?.primaryPhone)}</div>
                                                    </div>

                                                    <div className='row pb-1'>
                                                        <div className='col-3 fw-sb text--secondary  font-12'>Service Location</div>
                                                        <div className='col-7 font-12'>{`${claimHistory?.encounter?.encounterMetadata?.serviceLocation}`}</div>
                                                    </div>

                                                    <div className='row pb-1'>
                                                        <div className='col-3 fw-sb text--secondary  font-12'>Place of Service</div>
                                                        <div className='col-7 font-12'>
                                                            {placeOfServiceMap.get(claimHistory?.encounter?.encounterMetadata?.placeOfService)}
                                                        </div>
                                                    </div>

                                                    <div className='row pb-1'>
                                                        <div className='col-3 fw-sb text--secondary  font-12'>Service Date</div>
                                                        <div className='col-7 font-12'>   {calenderDateTimeHelper(claimHistory?.encounter?.encounterMetadata?.dateOfServiceTo, "MMM DD, YYYY")}</div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div>
                                                    <div className='row py-1'>
                                                        <div className='col-12'>
                                                            <span className='text--terciary d-block font-14 fw-sb'>Provider</span>
                                                        </div>
                                                    </div>

                                                    <div className='row pb-1'>
                                                        <div className='col-2 fw-sb text--secondary font-12'>Name</div>
                                                        <div className='col-7 font-12'>
                                                            {`${claimHistory?.encounter.provider?.prefix} ${claimHistory?.encounter?.provider?.firstName} ${claimHistory?.encounter?.provider?.lastName}`}
                                                        </div>
                                                    </div>
                                                    <div className='row pb-1'>
                                                        <div className='col-2 fw-sb text--secondary font-12'>NPI</div>
                                                        <div className='col-7 font-12'>     {`${claimHistory?.encounter.provider?.npi ?? "N/A"}`}</div>
                                                    </div>

                                                    {referringPhysician !== undefined && referringPhysician !== null ?
                                                        <>
                                                            <div className='row py-1'>
                                                                <div className='col-12'>
                                                                    <span className='text--terciary d-block font-14 fw-sb'>Referring Physician</span>
                                                                </div>
                                                            </div>
                                                            <div className='row pb-1'>
                                                                <div className='col-2 fw-sb text--secondary font-12'>Name</div>
                                                                <div className='col-7 font-12'>
                                                                    Dr. {referringPhysician?.firstName} {referringPhysician?.lastName}
                                                                </div>
                                                            </div>
                                                            <div className='row pb-1'>
                                                                <div className='col-2 fw-sb text--secondary font-12'>NPI</div>
                                                                <div className='col-7 font-12'>     {`${referringPhysician?.npi ?? "N/A"}`}</div>
                                                            </div>
                                                            <div className='row pb-1'>
                                                                <div className='col-2 fw-sb text--secondary font-12'>Referral Code</div>
                                                                <div className='col-7 font-12'>     {`${encounter?.referralCode ?? "N/A"}`}</div>
                                                            </div>
                                                            <div className='row pb-1'>
                                                                <div className='col-2 fw-sb text--secondary font-12'>Referral Date </div>
                                                                <div className='col-7 font-12'>     {`${calenderDateTimeHelper(encounter?.referralDate, "MMM DD, YYYY") ?? "N/A"}`}</div>
                                                            </div>

                                                        </>
                                                        : null}

                                                </div>
                                            </div>
                                        </div>

                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                        {/* End of first accordion */}

                        <div className="row">
                            <div className="col-sm-12">
                                <Accordion sx={{ paddingTop: 0, paddingBottom: 0, pt: 0, pb: 0 }} defaultExpanded={true}>
                                    <AccordionSummary>

                                        <h2 className="font-18 fw-sb m-0">Claim Header</h2>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div>
                                                    <div className='row pb-1'>
                                                        <div className='col-4 fw-sb text--secondary font-12'>Claim ID</div>
                                                        <div className='col-7 font-12'>    {claimHistory?.claimNumber}</div>
                                                    </div>

                                                    <div className='row pb-1'>
                                                        <div className='col-4 fw-sb text--secondary font-12'>Status</div>
                                                        <div className='col-7 font-12'>{statusToString(claimHistory?.status)}</div>
                                                    </div>

                                                    <div className='row pb-1'>
                                                        <div className='col-4 fw-sb text--secondary font-12'>Status Change Date</div>
                                                        <div className='col-7 font-12'>
                                                            {claimHistory?.submittedDate !== null ? calenderDateTimeHelper(claimHistory?.submittedDate, "MMM DD, YYYY") : "Not available"}
                                                        </div>
                                                    </div>

                                                    <div className='row pb-1'>
                                                        <div className='col-4 fw-sb text--secondary font-12'>Submit Date</div>
                                                        <div className='col-7 font-12'>
                                                            {claimHistory?.submittedDate !== null ? calenderDateTimeHelper(claimHistory?.submittedDate, "MMM DD, YYYY") : "Not available"}
                                                        </div>
                                                    </div>


                                                    <div className='row pb-1'>
                                                        <div className='col-4 fw-sb text--secondary font-12'>Payer</div>
                                                        <div className='col-7 font-12'>
                                                            {claimHistory?.encounter?.patient?.patientInsurance?.at(0)?.payerName}

                                                        </div>
                                                    </div>

                                                    <div className='row pb-1'>
                                                        <div className='col-4 fw-sb text--secondary font-12'>Payer Control Number</div>
                                                        <div className='col-7 font-12'>
                                                            {claimHistory?.claimPayment?.payerControlNumber}

                                                        </div>
                                                    </div>


                                                    <div className='row pb-1'>
                                                        <div className='col-4 fw-sb text--secondary font-12'>Current State</div>
                                                        <div className='col-7 font-12'>
                                                            {claimHistory?.claimResponse?.currentState ?? "Not Available"}

                                                        </div>
                                                    </div>


                                                </div>
                                            </div>

                                            <div className="col-sm-6">
                                                <div className='row pb-1'>
                                                    <div className='col-4 fw-sb text--secondary font-12'>Total Claim Amount</div>
                                                    <div className='col-7 font-12'>
                                                        {currencyFormatter("USD", totalClaimAmount)}

                                                    </div>
                                                </div>

                                                <div className='row pb-1'>
                                                    <div className='col-4 fw-sb text--secondary font-12'>Adjustment Amount</div>
                                                    <div className='col-7 font-12'>
                                                        {isNaN(totalAdjustmentAmount) ? 'Not available' : currencyFormatter("USD", totalAdjustmentAmount)}


                                                    </div>
                                                </div>

                                                <div className='row pb-1'>
                                                    <div className='col-4 fw-sb text--secondary font-12'>Patient Amount</div>
                                                    <div className='col-7 font-12'>
                                                        {claimHistory?.claimPayment !== null ? currencyFormatter("USD", claimHistory?.claimPayment?.patientResponsibility) : 'Not available'}


                                                    </div>
                                                </div>

                                                <div className='row pb-1'>
                                                    <div className='col-4 fw-sb text--secondary font-12'>Payment Amount</div>
                                                    <div className='col-7 font-12'>
                                                        {claimHistory?.claimPayment !== null ? currencyFormatter("USD", claimHistory?.claimPayment?.totalActualProviderPayment) : 'Not available'}

                                                    </div>
                                                </div>

                                                <div className='row pb-1'>
                                                    <div className='col-4 fw-sb text--secondary font-12'>Payment Reference #</div>
                                                    <div className='col-7 font-12'>
                                                        {claimHistory?.claimPayment?.paymentReferenceNumber ?? "Not available"}
                                                    </div>
                                                </div>

                                                {/* <div className='row pb-1'>
                                                    <div className='col-4 fw-sb text--secondary font-12'>Document Reference #</div>
                                                    <div className='col-7 font-12'>

                                                        {claimHistory?.claimResponse?.relatedDocumentId != null ? <>
                                                            <PnToolTip title={claimHistory?.claimResponse?.relatedDocumentId}>
                                                                <span>{truncate(claimHistory?.claimResponse?.relatedDocumentId, 45, "#336383")}</span>
                                                            </PnToolTip>
                                                        </> : "Not available"}
                                                    </div>
                                                </div> */}


                                            </div>
                                        </div>



                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                        {/* end of second accordion */}


                        <div className="row">
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-12">

                                        <Accordion sx={{ paddingTop: 0, paddingBottom: 0, pt: 0, pb: 0 }} defaultExpanded={true}>
                                            <AccordionSummary>

                                                <h2 className='text--terciary d-block font-18 fw-sb m-0'>Claim Details </h2>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className="row  py-1">
                                                    {claimHistory?.claimServices?.map((claimService, idx) => {

                                                        return <>
                                                            <div className="col-sm-12 ">
                                                                <span style={{ justifyContent: 'space-between' }} className="d-flex  gap-2 font-14 fw-sb">
                                                                    <span>Service Line Item # {idx + 1}
                                                                    </span>
                                                                    <span className={`badge font-12 fw-sb text--terciary text-wrap ${claimService?.status === "Approved" || claimService?.status === null ? "bg--d9e8ec" : "bg--denied"} py-1 px-2 br-10`}>
                                                                        {claimService?.status === "Approved" || claimService?.status === null ? "Approved" : "Denied"}
                                                                    </span></span>
                                                            </div>
                                                            <div className="col-sm-12 py-1">
                                                                <div className="row">
                                                                    <div className="col-sm-6">
                                                                        <div className='row pb-1'>
                                                                            <div className='col-4 fw-sb text--secondary font-12'>Diagnoses</div>
                                                                            <div className='col-7 font-12'>
                                                                                {claimService?.diagnoses?.map((diagnosis, idx) => {
                                                                                    return <span className="d-block font-12 text--terciary">
                                                                                        {diagnosis?.code} - {diagnosis?.description}
                                                                                    </span>
                                                                                })}
                                                                            </div>
                                                                        </div>

                                                                        <div className='row pb-1'>
                                                                            <div className='col-4 fw-sb text--secondary font-12'>HCPCS/CPT</div>
                                                                            <div className='col-7 font-12'>
                                                                                {claimService?.hcpcsProcedureCode}
                                                                            </div>
                                                                        </div>
                                                                        <div className='row pb-1'>
                                                                            <div className='col-4 fw-sb text--secondary font-12'>Modifier(s)</div>
                                                                            <div className='col-7 font-12'>
                                                                                {claimService?.modifiers}
                                                                            </div>
                                                                        </div>
                                                                        <div className='row pb-1'>
                                                                            <div className='col-4 fw-sb text--secondary font-12'>Acceptance Code</div>
                                                                            <div className='col-7 font-12'>
                                                                                {claimService?.claimServicePayment?.adjustments?.at(0)?.adjustmentReasons?.at(0)?.reason?.code ?? "Not available"}
                                                                            </div>
                                                                        </div>
                                                                        <div className='row pb-1'>
                                                                            <div className='col-4 fw-sb text--secondary font-12'>Reason</div>
                                                                            <div className='col-7 font-12'>
                                                                                {claimService?.claimServicePayment?.adjustments?.at(0)?.adjustmentReasons?.at(0)?.reason?.description}

                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    <div className="col-sm-6">
                                                                        <div className='row pb-1'>
                                                                            <div className='col-4 fw-sb text--secondary font-12'>Charge Amount</div>
                                                                            <div className='col-7 font-12'>
                                                                                {currencyFormatter("USD", parseFloat(claimService?.charges))}
                                                                            </div>
                                                                        </div>
                                                                        <div className='row pb-1'>
                                                                            <div className='col-4 fw-sb text--secondary font-12'>Provider Payment Amount</div>
                                                                            <div className='col-7 font-12'>
                                                                                {isNaN(parseFloat(claimService?.claimServicePayment?.providerPaymentAmount)) ? 'Not available' : currencyFormatter("USD", parseFloat(claimService?.claimServicePayment?.providerPaymentAmount))}
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    {claimService?.note !== null ?
                                                                        <div className="col-sm-12">
                                                                            <div className='row pb-1'>
                                                                                <div className='col-2 fw-sb text--secondary font-12'>Note</div>
                                                                                <div className='col-10 font-12'>
                                                                                    {claimService?.note}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : null}
                                                                </div>

                                                            </div>
                                                            {claimHistory?.claimServices?.length > 1 ? <div className="horizontal-rule my-1" /> : null}
                                                        </>
                                                    })}
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                            {/* End of third accordion */}
                        </div>
                    </div>
                </div>




            </div>

            </>
            }
            <BottomNavigation
                viewClaimResponse={() => viewClaimResponse(location?.state?.data?.claimId)}
                disableSave={claimHistory?.claimResponse?.apexEdiClaimNumber !== undefined ? false : true}
            />

            <Backdrop
                sx={{ color: "#EC609B", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isClaimResponseLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}

export default ClaimHistoryPage

function BottomNavigation(props) {
    return (
        <div className="position-fixed pb-4  w-100 bottom-0 mx-auto bg-white bottom__nav--shadow">
            <LinearProgress variant="determinate" style={{
                backgroundColor: "#D9E8EC",
                height: "1px"
            }} value={100} />
            <div className="d-flex p-3 ">
                <div className="position-fixed " style={{
                    left: "86%"
                }}>
                    <button className="btn  br-10 font-14 ms-2 mb-1 fw-sb blue_task_button btn__bottom d9e8ec--button" style={{
                        paddingLeft: "24px",
                        paddingRight: "24px"
                    }}
                        onClick={() => {
                            props?.viewClaimResponse(props?.claimId)
                        }}
                        disabled={props?.disableSave}
                    // hidden={props?.hideSaveButton}
                    >
                        View Claim History
                    </button>
                </div>
            </div>
        </div>
    );
}
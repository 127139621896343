import { useEffect, useState } from "react";
import BadgeButtons from "../billing-specialist-dashboard/BadgeButtons";
import BillingTileContainer from "../billing-specialist-dashboard/BillingTileContainer";
import { useAuth } from "../hooks/useAuth";
import sendHttpRequest, { sendHttpRequestWithCancellation } from "../hooks/sendHttpRequest";
import { Skeleton } from "@mui/material";
import AppointmentPieChart from "./AppointmentPieChart";
import { calenderDateTimeHelper } from "../../utils/dateTimeHelper";
import AppointmentBarChart from "./AppointmentBarChart";
import ClaimStatusCard from "../billing-specialist-dashboard/ClaimStatusCard";
import { useNavigate } from "react-router-dom";
import EncounterPieChart from "./EncounterPieChart";
import PatientDataLineChart from "./PatientDataLineChart";
import PrescriptionBarChart from "./PrescriptionBarChart";

const PracticeAdminDashboardOverview = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [fromDateVal, setFromDateVal] = useState();
  const [toDateVal, setToDateVal] = useState();

  const [appointmentStatus, setAppointmentStatus] = useState("all");
  const [selectedProviderId, setSelectedProviderId] = useState(0);

  const [appointmentChartData, setAppointmentChartData] = useState();
  const [isAppointmentDataLoading, setIsAppointmentDataLoading] = useState(false);

  const [encounterChartData, setEncounterChartData] = useState();
  const [isEncounterDataLoading, setIsEncounterDataLoading] = useState(false);
  
  const [patientsChartData, setPatientsChartData] = useState([]);
  const [isPatientsDataLoading, setIsPatientsDataLoading] = useState(false);

  const [prescriptionsChartData, setPrescriptionsChartData] = useState([]);
  const [isPrescriptionsDataLoading, setIsPrescriptionsDataLoading] = useState(false);

  const fetchStats = async (type,fromDateVal, toDateVal,setIsLoading,setState)=> {
    setIsLoading(true);
    
    const httpResponse = await sendHttpRequest({
      method: "GET",
      url: `/tenants/${user?.tenantId}/${type}/summary`,
      params:{
        ...(fromDateVal?.length > 0 && {from:fromDateVal}),
        ...(toDateVal?.length > 0 && {to:toDateVal}),
      }
    });
    if (!httpResponse.error) {
      
      setState(httpResponse.data);
    }
    setIsLoading(false);
  }
  async function fetchAppointmentsStats(fromDateVal, toDateVal) {
    setIsEncounterDataLoading(true);
    
    const httpResponse = await sendHttpRequestWithCancellation({
      method: "GET",
      url: `/tenants/${user?.tenantId}/encounters/summary`,
      
    });
    if (!httpResponse.error) {
      
      setEncounterChartData(httpResponse.data);
    }
    setIsEncounterDataLoading(false);
  }
  async function fetchAppointmentsStats(fromDateVal, toDateVal) {
    setIsAppointmentDataLoading(true);
    
    const httpResponse = await sendHttpRequestWithCancellation({
      method: "GET",
      url: `/tenants/${user?.tenantId}/appointments/summary?from=${fromDateVal}&to=${toDateVal}`,
    });
    if (!httpResponse.error) {
      
      setAppointmentChartData(httpResponse.data);
    }
    setIsAppointmentDataLoading(false);
  }

  function navigateToClaimsPage(routeVal, status) {
    navigate(routeVal, { state: { startDate: fromDateVal, endDate:toDateVal } });
  }

  useEffect(() => {
    const currentDate = new Date(); // Current date
    let calculatedStartDate = new Date(
      currentDate.setMonth(currentDate.getMonth() - 1)
    );
    setFromDateVal(calenderDateTimeHelper(calculatedStartDate, "YYYY-MM-DD"));
    setToDateVal(calenderDateTimeHelper(new Date(), "YYYY-MM-DD"));
    
    let fromDate = calenderDateTimeHelper(calculatedStartDate, "YYYY-MM-DD");
    let toDate = calenderDateTimeHelper(new Date(), "YYYY-MM-DD");
    fetchStats("appointments",fromDate,toDate,setIsAppointmentDataLoading,setAppointmentChartData);
    fetchStats("encounters",fromDate,toDate,setIsEncounterDataLoading,setEncounterChartData);
    fetchStats("patients",fromDate,toDate,setIsPatientsDataLoading,setPatientsChartData);
    fetchStats("prescriptions",fromDate,toDate,setIsPrescriptionsDataLoading,setPrescriptionsChartData);
  }, []);
  useEffect(() => {
    if(fromDateVal?.length > 0 || toDateVal?.length > 0){
      fetchStats("appointments",fromDateVal,toDateVal,setIsAppointmentDataLoading,setAppointmentChartData);
      fetchStats("encounters",fromDateVal,toDateVal,setIsEncounterDataLoading,setEncounterChartData);
      fetchStats("patients",fromDateVal, toDateVal,setIsPatientsDataLoading,setPatientsChartData);
      fetchStats("prescriptions",fromDateVal, toDateVal,setIsPrescriptionsDataLoading,setPrescriptionsChartData);
    }
  }, [fromDateVal, toDateVal]);

  return (
    <>
      <div className="container mb-3">
        <BadgeButtons
          setFromDateVal={setFromDateVal}
          setToDateVal={setToDateVal}
          borderTop={true}
          practiceAdmin = {true}
        />
        <div className="row py-2">
          {/* <div id="pn-provider-greeting" className="col-3 col-md-3">
            <GreetingMessage />
          </div> */}
          <div className="col-3"></div>
          <div className="col-3 col-md-3 ">
            <BillingTileContainer key={"upcoming-appointments"}>
              {
                isAppointmentDataLoading ? <Skeleton/>:
                <div
                  className="cursor--pointer"
                  style={{ height: "6rem" }}
                  onClick={() => navigateToClaimsPage("/inpatient-dashboard", "")
                  }
                >
                  <ClaimStatusCard
                    badgeClass="badge-success"
                    infoColor="text--green"
                    claimText={"Upcoming Appointments"}
                    claimPercentage={appointmentChartData?.pending}
                    noOfClaims={"2500"}
                    practiceAdmin={true}
                  />
                </div>
              }
            </BillingTileContainer>
          </div>
          <div className="col-3 col-md-3 ">
            
            <BillingTileContainer
              style={{ backgroundColor: "white" }}
              key={"unsigned-encounters"}
            >
              { isEncounterDataLoading ? <Skeleton/> :
                <div
                  className="text-white cursor--pointer"
                  style={{ height: "6rem" }}
                  // onClick={() => {
                  //   // navigateToClaimsPage("/claims/pending", "CLAIM_SUBMITTED");
                  // }}
                >
                  <ClaimStatusCard
                    badgeClass="badge-pending"
                    infoColor="text--warning"
                    claimText={"Unsigned Encounters"}
                    claimPercentage={encounterChartData?.unsigned ?? 0}
                    noOfClaims={"100"}
                    practiceAdmin={true}
                  />
                </div>
              }
            </BillingTileContainer>
          </div>
          <div className="col-3 col-md-3 ">
            <BillingTileContainer key={"prescriptions"}>
              {
                isPrescriptionsDataLoading ? <Skeleton/> :
                <div
                  className="text-white  cursor--pointer "
                  // onClick={}
                  style={{ height: "6rem" }}
                >
                  <ClaimStatusCard
                    badgeClass="badge-in-progress"
                    infoColor="text--secondary"
                    claimText={"Prescriptions"}
                    claimPercentage={prescriptionsChartData?.filter(item=>item?.workflowType === "SURESCRIPTS").length}
                    hideNoOdClaims={true}
                    noOfClaims={0}
                    practiceAdmin={true}
                  />
                </div>
              }
            </BillingTileContainer>
          </div>
        </div>
        <div className="row">
          {/* <div className="col-6 pt-2">
          <BillingTileContainer minHeight={"20rem"} key={"chart-1"}>
            {
                isAppointmentDataLoading ? <Skeleton/>
                :<AppointmentPieChart appointmentData = {appointmentChartData} />
            }
          </BillingTileContainer>
        </div> */}
          <div className="col-6 pt-2" onClick={() => navigateToClaimsPage("/inpatient-dashboard", "")}>
            <BillingTileContainer minHeight={"20rem"} key={"chart-1"}>
              {isAppointmentDataLoading ? (
                <Skeleton />
              ) : (
                <AppointmentBarChart appointmentData={appointmentChartData} />
              )}
            </BillingTileContainer>
          </div>
          <div className="col-6 pt-2" onClick={() => navigateToClaimsPage("/encounters", "")}>
            <BillingTileContainer minHeight={"20rem"} key={"chart-2"}>
              {
                isEncounterDataLoading ? <Skeleton/> :
                <EncounterPieChart encounterData={encounterChartData} />
              }
            </BillingTileContainer>
          </div>
          <div className="col-6 pt-2 cursor--pointer" onClick={() => navigateToClaimsPage("/patients", "")}>
            <BillingTileContainer minHeight={"20rem"} key={"chart-3"}>
              {
                isPatientsDataLoading ? <Skeleton/> :
                <PatientDataLineChart
                  patientData={patientsChartData}
                />
              }
            </BillingTileContainer>
          </div>
          <div className="col-6 pt-2 cursor--pointer" onClick={() => navigateToClaimsPage("/prescriptions", "")}>
            <BillingTileContainer minHeight={"20rem"} key={"chart-4"} >
              {
                isPrescriptionsDataLoading ? <Skeleton/> :
              <PrescriptionBarChart prescriptions={prescriptionsChartData}/>
              }
            </BillingTileContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default PracticeAdminDashboardOverview;

import { useDispatch, useSelector } from "react-redux";
import Input from "../forms/Input";
import { setPatientEncounterData, setUpdateEncounterData } from "../../redux/actions/providerActions";
import { useParams } from "react-router";

const ChiefComplaints = (props) => {

  const { providerId, encounterId, patientId } = useParams();
  const dispatch = useDispatch();

  const updateEncounterData = useSelector(
    (state) => state.UpdateEncounter.Encounter
  );
  const patientEncounterInformation = useSelector(
    (state) => state.PatientEncounterData.appoinment
  );

  function handleInput(event) {

    props.setUnsavedChanges(true);
    dispatch(
      setUpdateEncounterData({
        ...updateEncounterData,
        encounterId: encounterId,
        chiefComplaints: event.target.value,
      })
    );
    dispatch(
      setPatientEncounterData({
        ...patientEncounterInformation,
        encounter: {
          ...patientEncounterInformation.encounter,
          chiefComplaints: event.target.value,
        },
      })
    );

  }

  return (
    <>
      <h2 className='font-18 text--terciary'>Chief Complaint</h2>
      <Input
        type={"text"}
        className={"form-control br-10 shadow-none input border--default my-2"}
        id={"chiefComplaints"}
        name={"chiefComplaints"}
        defaultValue={props.chiefcomplaints}
        onChange={
          handleInput 
        }
      />
      {/* <p className='b2' style={{ 'color': 'var(--secondary)' }}>
        {props.chiefcomplaints}
      </p> */}
    </>

  );
};

export default ChiefComplaints;

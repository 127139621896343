import { ActionTypes } from "../constants/actionTypes"

const videoCallInitialState = {    
        isStart:false
}

const setVideoCallStateReducer = (state=videoCallInitialState, action)=>{
    if (action.type === ActionTypes.SET_VIDEO_CALL ){
        return {
            ...state,
            isStart: action.payload
        
        }
    }
    return state
}


export default setVideoCallStateReducer;
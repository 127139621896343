import { useEffect, useRef, useState } from "react";
import sendHttpRequest from "../../../../components/hooks/sendHttpRequest";
import { Skeleton } from "@mui/material";

import { DateTime } from "luxon";
import { useAuth } from "../../../../components/hooks/useAuth";

import useDiagnosisSearch from "../../../../components/diagnosis-search/useDiagnosisSearch";
import { RolesMap } from "../../../../utils/helpers/RolesMap";
import CalenderDatePicker from "../../../../components/UI/General/CalenderDatePicker";
import DiagnosisSearch from "../../../../components/diagnosis-search/DiagnosisSearch";
import AddIcon from "@mui/icons-material/Add";
import ClaimPatientEncountersTable from "../tables/ClaimPatientEncountersTable";
import useClaimPlaceOfServiceCodes from "../../claim-hooks/useClaimPlaceOfServiceCodes";
import TrashButtonIcon from "../../../../assets/icons/Icon-Trash.svg";
import { useSelector } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";
import { formatPhoneNumber } from "../../../../utils/helpers/HelperFunctions";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import ClaimReferringPhysicianModal from "../UI/Modals/ClaimReferringPhysicianModal";
import { dateTimeHelper } from "../../../../utils/dateTimeHelper";

const ClaimEncountersTabPanel = (props) => {
  const { user } = useAuth();
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } =
    useSelector((state) => state.subscriptionPrivilegeGroups);

  const [renderingProvidersList, setRenderingProvidersList] = useState([]);
  const [areRenderingProvidersLoading, setAreRenderingProvidersLoading] =
    useState(false);
  const { placeOfServiceCodes, isPlaceOfServiceCodeLoading } =
    useClaimPlaceOfServiceCodes();
  const [isTabular, setIsTabular] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(
    props?.encounterDto?.provider?.providerId ?? null
  );
  const [selectedPlaceOfService, setSelectedPlaceOfService] = useState(
    props?.encounterDto?.encounterMetadata?.placeOfService ?? null
  );
  const [selectedServiceLocation, setSelectedServiceLocation] = useState(
    props?.encounterDto?.encounterMetadata?.serviceLocation ?? null
  );

  const [patientEncounters, setPatientEncounters] = useState([]);
  const [arePatientEncountersLoading, setArePatientEncountersLoading] =
    useState(false);

  const [reloadEncounters, setReloadEncounters] = useState(false);

  const [serviceDate, setServiceDate] = useState(null);
  const [lastSeenDate, setLastSeenDate] = useState(null);
  const [dateOfCurrentIllness, setDateOfCurrentIllness] = useState(null);
  const [lastMensturalPeriodDate, setLastMensturalPeriodDate] = useState(null);

  const [serviceLocations, setServiceLocations] = useState([]);
  const [areServiceLocationsLoading, setAreServiceLocationsLoading] =
    useState(false);

  const serviceOptionsRef = useRef([]);

  const {
    suggestions,
    setSuggestions,
    isLoading,
    setIsLoading,
    isError,
    setIsError,
    searchInputValue,
    setSearchInputValue,
    diagnosisName,
    setDiagnosisName,
    selectedDiagnosis,
    setSelectedDiagnosis,
    searchDiagnosis,
    handleSearchInput,
    handleAddDiagnosis,
    handleRemoveDiagnosis,
  } = useDiagnosisSearch();
  const [diagnosisList, setDiagnosisList] = useState([]);

  //=========Referring Physician=========//
  const [referringPhysiciansList, setReferringPhysiciansList] = useState([]);
  const [areReferringPhysiciansLoading, setAreReferringPhysiciansLoading] =
    useState(false);
  const [referringPhysician, setReferringPhysician] = useState({});
  const [showReferringPhysicianFields, setShowReferringPhysicianFields] =
    useState(false);
  const [regexErrors, setRegexErrors] = useState({
    landline: false,
    primary: false,
    emergency: false,
    zipcode: false,
  });

  function handleReferringPhysicianData(eventOrDate) {
    if (typeof eventOrDate === "string") {
      const dateValue = eventOrDate;
      setReferringPhysician((previous) => ({
        ...previous,
        referralDate: dateValue,
      }));
    } else {
      const name = eventOrDate.target.name;
      const value = eventOrDate.target.value;

      if (name === "referringPhysicianDropdown") {
        const referringPhysicianId = value;
        const referringPhysicianData = referringPhysiciansList?.find(
          (physician) => physician?.referringPhysicianId == referringPhysicianId
        );
        setReferringPhysician(referringPhysicianData);
      } else {
        setReferringPhysician((previous) => {
          const newData = { ...previous };
          newData[name] = value;
          return newData;
        });
      }
    }
  }

  const handlePhysicianPhone = (phoneValue) => {
    // console.log("Handling Physcian Phone", phoneValue);
    if (
      phoneValue !== undefined &&
      isValidPhoneNumber(phoneValue?.toString()) &&
      phoneValue?.length == 12
    ) {
      // console.log("Valed Part");
      let mobileNumber = formatPhoneNumber(phoneValue?.replace("+1", ""));
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: false,
      }));
      setReferringPhysician((previous) => {
        const newData = { ...previous };
        newData["phone"] = mobileNumber;
        return newData;
      });
    } else if (
      phoneValue !== undefined &&
      !isValidPhoneNumber(phoneValue?.toString()) &&
      phoneValue?.length > 2
    ) {
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: true,
      }));
      setReferringPhysician((previous) => {
        const newData = { ...previous };
        newData["phone"] = "";
        return newData;
      });
    } else if (phoneValue?.length === 2 || phoneValue === undefined) {
      // dispatch(setDirtyBitInStore(true));
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: false,
      }));

      setReferringPhysician((previous) => {
        const newData = { ...previous };
        newData["phone"] = "";
        return newData;
      });
    }
  };

  async function loadReferringPhysicians(tenantId) {
    setAreReferringPhysiciansLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/claims/${tenantId}/referring-physicians/all`,
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log("Referring Physicians-> ", httpResponse.data);
      setReferringPhysiciansList(httpResponse.data);
    }
    setAreReferringPhysiciansLoading(false);
  }

  async function fetchServiceLocations() {
    setAreServiceLocationsLoading(true);
    // /claims/tenants/{tenantId}/facilities
    const httpResponse = await sendHttpRequest({
      // url: providerWithNpiUrl(user?.tenantId),
      url: `/claims/tenants/${user?.tenantId}/facilities`,
      method: "GET",
    });
    if (!httpResponse.error) {
      setServiceLocations(httpResponse.data);
    } else {
      setServiceLocations([]);
      // console.log(httpResponse.error);
    }
    setAreServiceLocationsLoading(false);
  }

  const handleDiagnosisList = (addedDiagnosis) => {
    handleAddDiagnosis(addedDiagnosis);
    if (diagnosisList?.length === 0) {
      addedDiagnosis["type"] = "P";
    }
    setDiagnosisList((prevList) => [...prevList, addedDiagnosis]);
    props?.setEncounterDto((dto) => ({
      ...dto,
      diagnosis: [...diagnosisList, addedDiagnosis],
    }));
  };

  function handleRemoveDiagnosisFromList(diagnosisToRemove) {
    handleRemoveDiagnosis(diagnosisToRemove?.code);
    const diagnosisToRemoveId = diagnosisToRemove.code;
    setDiagnosisList((prevList) =>
      prevList.filter((diagnosis) => diagnosis.code !== diagnosisToRemove.code)
    );
    props?.setEncounterDto((dto) => ({
      ...dto,
      diagnosis: diagnosisList.filter(
        (diagnosis) => diagnosis?.code !== diagnosisToRemove?.code
      ),
    }));
  }

  function handleProviderId(event) {
    setSelectedProvider(event.target.value);
    props?.setEncounterDto((dto) => ({
      ...dto,
      claimProviderDataId: event.target.value,
      provider: { providerId: event.target.value },
    }));
  }

  function handlePlaceOfService(event) {
    console.log("Chaning Place of Service: ", event.target.value);
    setSelectedPlaceOfService(event.target.value);
    props?.setEncounterDto((dto) => ({
      ...dto,
      encounterMetadata: {
        ...dto?.encounterMetadata,
        placeOfService: event.target.value,
      },
    }));
  }

  function handleServiceLocation(event) {
    setSelectedServiceLocation(event.target.value);
    const facilityId =
      serviceOptionsRef.current[event.target.selectedIndex - 1].getAttribute(
        "data-fid"
      );
    console.log("facilityId", facilityId);
    props?.setEncounterDto((dto) => ({
      ...dto,
      facility: { facilityId: parseInt(facilityId) },
      encounterMetadata: {
        ...dto?.encounterMetadata,
        serviceLocation: event.target.value,
      },
    }));
  }

  function handleServiceDate(date) {
    const tempDate = `${date}T00:00:00`;
    setServiceDate(date);
    props?.setEncounterDto((dto) => ({
      ...dto,
      encounterMetadata: {
        ...dto?.encounterMetadata,
        dateOfServiceTo: tempDate,
      },
    }));
  }

  function handleCurrentIllnessDate(date) {
    const tempDate = `${date}T00:00:00`;
    setDateOfCurrentIllness(date);
    props?.setEncounterDto((dto) => ({
      ...dto,
      dateOfCurrentIllnessOrSymptom: `${date}T00:00:00`,
    }));
  }

  function handleLastSeenDate(date) {
    setLastSeenDate(date);
    props?.setEncounterDto((dto) => ({
      ...dto,
      lastSeenDate: `${date}T00:00:00`,
    }));
  }

  function handleLastMenstrualPeriodDate(date) {
    setLastMensturalPeriodDate(date);
    props?.setEncounterDto((dto) => ({
      ...dto,
      lastMenstrualPeriodDate: `${date}T00:00:00`,
    }));
  }

  const fetchReneringProviders = async () => {
    setAreRenderingProvidersLoading(true);
    const httpResponse = await sendHttpRequest({
      // url: providerWithNpiUrl(user?.tenantId),
      url: `/claims/tenants/${user?.tenantId}/providers`,
      method: "GET",
    });
    if (!httpResponse.error) {
      setRenderingProvidersList(httpResponse.data);
    } else {
      setRenderingProvidersList([]);
      console.log(httpResponse.error);
    }
    setAreRenderingProvidersLoading(false);
  };

  async function fetchClaimPatientEncounters(patientId) {
    setArePatientEncountersLoading(true);
    const httpResponse = await sendHttpRequest({
      // url: providerWithNpiUrl(user?.tenantId),
      url: `/claims/patients/${patientId}/encounters`,
      method: "GET",
    });
    if (!httpResponse.error) {
      setPatientEncounters(httpResponse.data);
      if (httpResponse?.data?.length > 0) {
        setIsTabular(true);
        props?.setTabView(false);
      } else {
        setIsTabular(false);
        props?.setTabView(true);
      }
    } else {
      setPatientEncounters([]);
      console.log(httpResponse.error);
    }
    setArePatientEncountersLoading(false);
  }

  function switchView() {
    setIsTabular(!isTabular);
    props?.setTabView((p) => !p);
  }

  useEffect(() => {
    fetchReneringProviders();
    fetchClaimPatientEncounters(props?.patientId);
    fetchServiceLocations();
  }, [props?.patientId, reloadEncounters]);

  useEffect(() => {
    loadReferringPhysicians(user?.tenantId);
    console.log("edit mode", props?.editMode, "dto", props?.encounterDto);
    if (props?.editMode === true) {
      setReferringPhysician(props?.encounterDto?.referringPhysician);
    }
  }, [props?.editMode]);

  useEffect(() => {
    setDiagnosisList(props?.encounterDto?.diagnosis);
  }, [props?.encounterDto?.diagnosis]);

  return (
    <>
      {showReferringPhysicianFields ? (
        <ClaimReferringPhysicianModal
          show={showReferringPhysicianFields}
          handleModalClose={() => {
            setShowReferringPhysicianFields(false);
          }}
          referringPhysician={referringPhysician}
          regexErrors={regexErrors}
          setShowReferringPhysicianFields={setShowReferringPhysicianFields}
          tenantId={user?.tenantId}
          setReferringPhysician={setReferringPhysician}
          referringPhysiciansList={referringPhysiciansList}
          handleReferringPhysicianData={handleReferringPhysicianData}
          handlePhysicianPhone={handlePhysicianPhone}
          setEncounterDto={props?.setEncounterDto}
          patientProfile={true}
        />
      ) : null}
      {arePatientEncountersLoading ? (
        <Skeleton />
      ) : isTabular === false ? (
        <>
          <div className="row py-1">
            <div className="col-sm-4">
              <div className="form-group">
                <label className="form-label text--secondary font-12 fw-sb">
                  Rendering Provider <span>&#42;</span>
                </label>
                {areRenderingProvidersLoading === true ? (
                  <Skeleton
                    animation="wave"
                    variant="text"
                    hidden={RolesMap(user?.role) === "doctor" ? true : false}
                  />
                ) : (
                  <select
                    className={`form-select br-10 font-12 input border--default shadow-none`}
                    aria-label="Provider select"
                    onChange={(e) => handleProviderId(e)}
                    required={true}
                    value={
                      props?.encounterDto?.provider?.providerId ||
                      selectedProvider
                    }
                  >
                    <option value="" disabled selected hidden>
                      Select provider
                    </option>
                    {renderingProvidersList?.length > 0
                      ? renderingProvidersList?.map((renderingProvider) => {
                          return (
                            <option
                              value={renderingProvider?.providerId ?? ""}
                              key={renderingProvider?.providerId ?? ""}
                            >
                              {renderingProvider?.prefix}{" "}
                              {renderingProvider?.firstName}{" "}
                              {renderingProvider?.lastName}{" "}
                            </option>
                          );
                        })
                      : "No prescribers available"}
                  </select>
                )}
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label className="form-label text--secondary font-12 fw-sb">
                  Place of Service <span>&#42;</span>
                </label>
                {isPlaceOfServiceCodeLoading ? (
                  <Skeleton />
                ) : (
                  <select
                    className={`input border--default br-10 form-select font-12 shadow-none`}
                    onChange={(e) => {
                      handlePlaceOfService(e);
                    }}
                    required={true}
                    value={
                      props?.encounterDto?.encounterMetadata?.placeOfService ||
                      selectedPlaceOfService
                    }
                  >
                    <option value="" disabled selected hidden>
                      Select Place of Service
                    </option>
                    {placeOfServiceCodes?.map((place) => {
                      return (
                        <option value={place.code}>
                          {place.code} - {place.name}
                        </option>
                      );
                    })}
                  </select>
                )}
              </div>
            </div>

            <div className="col-sm-4">
              <div className="form-group">
                <label className="form-label text--secondary font-12 fw-sb">
                  Service Location <span>&#42;</span>
                </label>
                {areServiceLocationsLoading ? (
                  <Skeleton />
                ) : (
                  <select
                    className={`input border--default br-10 form-select font-12 shadow-none`}
                    onChange={(e) => {
                      handleServiceLocation(e);
                    }}
                    required={true}
                    value={
                      props?.encounterDto?.encounterMetadata?.serviceLocation ||
                      selectedServiceLocation
                    }
                  >
                    <option value="" disabled selected hidden>
                      Select Service Location
                    </option>
                    {serviceLocations?.map((item, idx) => {
                      return (
                        <option
                          data-fid={item.facilityId}
                          ref={(ref) => (serviceOptionsRef.current[idx] = ref)}
                          key={item?.facilityId}
                          value={item?.name}
                        >
                          {item?.name}
                        </option>
                      );
                    })}
                    {/* <option value={`${user?.tenant?.businessName}`}>{user?.tenant?.businessName}</option> */}
                  </select>
                )}
              </div>
            </div>
          </div>
          <div className="row py-1">
            <div className="col-sm-3">
              <div className="form-group">
                <label className="font-12 fw-sb text--secondary">
                  Service Date <span>&#42;</span>
                </label>

                <CalenderDatePicker
                  name="serviceDate"
                  className="font-12 simple-placeholder"
                  label="Service Date"
                  f12={true}
                  {...(props?.encounterDto?.encounterMetadata
                    ?.dateOfServiceTo && {
                    date: props?.encounterDto?.encounterMetadata
                      ?.dateOfServiceTo,
                  })}
                  dobHandler={handleServiceDate}
                  disableFuture={true}
                  maxDate={DateTime.now()}
                />
              </div>
            </div>

            <div className="col-sm-3">
              <div className="form-group">
                <label className="font-12 fw-sb text--secondary">
                  Last Seen Date <span>&#42;</span>
                </label>
                <CalenderDatePicker
                  name="lastSeenDate"
                  className="font-12 simple-placeholder"
                  label="Service Date"
                  f12={true}
                  {...(props?.encounterDto?.lastSeenDate && {
                    date: props?.encounterDto?.lastSeenDate,
                  })}
                  required={true}
                  dobHandler={handleLastSeenDate}
                  disableFuture={true}
                  maxDate={DateTime.now()}
                />
              </div>
            </div>

            <div className="col-sm-3">
              <div className="form-group">
                <label className="font-12 fw-sb text--secondary">
                  Date of Current Illness <span>&#42;</span>
                </label>
                <CalenderDatePicker
                  name="dateOfCurrentIllnes"
                  className="font-12 simple-placeholder"
                  label="Service Date"
                  f12={true}
                  {...(props?.encounterDto?.dateOfCurrentIllnessOrSymptom && {
                    date: props?.encounterDto?.dateOfCurrentIllnessOrSymptom,
                  })}
                  required={true}
                  dobHandler={handleCurrentIllnessDate}
                  disableFuture={true}
                  maxDate={DateTime.now()}
                />
              </div>
            </div>

            {props?.selectedPatientInformation?.gender?.toLowerCase() ===
            "female" ? (
              <div className="col-sm-3">
                <div className="form-group">
                  <label className="font-12 fw-sb text--secondary">
                    Last Menstural Period Date <span>&#42;</span>
                  </label>
                  <CalenderDatePicker
                    name="lastMensturalPeriodDate"
                    className="font-12 simple-placeholder"
                    label="Service Date"
                    f12={true}
                    required={true}
                    {...(props?.encounterDto?.lastMenstrualPeriodDate && {
                      date: props?.encounterDto?.lastMenstrualPeriodDate,
                    })}
                    dobHandler={handleLastMenstrualPeriodDate}
                    disableFuture={true}
                    maxDate={DateTime.now()}
                  />
                </div>
              </div>
            ) : null}
          </div>

          <div className="row mt-2">
            <div className="col-sm-12">
              {(props?.encounterDto?.referringPhysician !== null &&
                props?.encounterDto?.referringPhysician !== undefined &&
                props?.editMode === true) ||
              (typeof referringPhysician === "object" &&
                referringPhysician !== null &&
                referringPhysician !== undefined &&
                Object.keys(referringPhysician)?.length > 2 &&
                showReferringPhysicianFields === false) ? (
                <>
                  <span className="font-14 fw-sb text--terciary d-block">
                    Referring Physician
                  </span>
                  <div className="row ">
                    <div className="col-sm-3">
                      <span className="font-12 d-block fw-sb text--secondary">
                        Name
                      </span>
                      <span className="font-12 fw-thin d-block">
                        Dr. {referringPhysician?.firstName}{" "}
                        {referringPhysician?.lastName}
                      </span>
                    </div>
                    <div className="col-sm-2">
                      <span className="font-12 d-block fw-sb text--secondary">
                        NPI{" "}
                      </span>
                      <span className="font-12 d-block fw-thin">
                        {referringPhysician?.npi}
                      </span>
                    </div>
                    <div className="col-sm-2">
                      <span className="font-12 d-block fw-sb text--secondary">
                        Phone{" "}
                      </span>
                      <span className="font-12 d-block fw-thin">
                        {referringPhysician?.phone}
                      </span>
                    </div>
                    <div className="col-sm-2">
                      <span className="font-12 d-block fw-sb text--secondary">
                        Referral Code{" "}
                      </span>
                      <span className="font-12 d-block fw-thin">
                        {referringPhysician?.referralCode ?? "N/A"}
                      </span>
                    </div>
                    <div className="col-sm-2">
                      <span className="font-12 d-block fw-sb text--secondary ">
                        Referral Date{" "}
                      </span>
                      <span className="font-12 d-block fw-thin">
                        {referringPhysician?.referralDate !== null &&
                        referringPhysician?.referralDate !== undefined
                          ? dateTimeHelper(
                              referringPhysician?.referralDate,
                              "MMM DD, YYYY"
                            )
                          : "N/A"}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <div className="form-group mt-2">
                  {/* <span className="text-decoration-underline fw-sb text--secondary font-14 d-flex align-items-center gap-1"><PersonAddAltIcon />{__str.addReferringPhysician}</span> */}
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      if (showReferringPhysicianFields === false) {
                        setShowReferringPhysicianFields(true);
                      }
                    }}
                    className="btn pn-button-icon-blue  br-10 shadow-none lh-0 py-0 ps-0 pe-1 align-items-center fw-sb text--blue font-12"
                  >
                    <span className="pn-button-icon-blue pe-1">
                      <PersonAddAltIcon
                        color="#336383"
                        sx={{ fontSize: "20px" }}
                      />
                    </span>
                    <span
                      className={`d-inline-block pn-link-button `}
                      style={{ verticalAlign: "sub" }}
                    >
                      Add Referring Physician
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="row py-1">
            <div className="col-sm-4">
              <label className="form-label font-12 text--secondary fw-sb">
                Diagnoses <span>&#42;</span>
              </label>
              <DiagnosisSearch
                suggestions={suggestions}
                setSuggestions={setSuggestions}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                isError={isError}
                setIsError={setIsError}
                searchInputValue={searchInputValue}
                setSearchInputValue={setSearchInputValue}
                diagnosisName={diagnosisName}
                setDiagnosisName={setDiagnosisName}
                selectedDiagnosis={selectedDiagnosis}
                setSelectedDiagnosis={setSelectedDiagnosis}
                searchDiagnosis={searchDiagnosis}
                handleSearchInput={handleSearchInput}
                handleAddDiagnosis={handleDiagnosisList}
                handleRemoveDiagnosis={handleRemoveDiagnosis}
              />

              <div className="py-2">
                {diagnosisList?.map((diagnosis, index) => (
                  <span
                    key={diagnosis.code}
                    className="font-12 px-2 text--terciary d-flex gap-3 d-block"
                  >
                    {`${diagnosis?.code} - ${diagnosis.description} ${
                      diagnosis?.type === "P" ? `(Primary)` : ""
                    }`}
                    <span
                      className="cursor--pointer"
                      onClick={() => handleRemoveDiagnosisFromList(diagnosis)}
                    >
                      <img
                        src={TrashButtonIcon}
                        alt={"deleteIcon"}
                        id="encounter-delete"
                        style={{ width: "15px", height: "15px" }}
                      ></img>
                    </span>
                  </span>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {subscriptionPrivilegeGroups["Billing & Claims"] !== undefined &&
          subscriptionPrivilegeGroups["Billing & Claims"]?.bae?.active ===
            true ? (
            <div className="row mt-2 justify-content-end">
              <div className="col-sm-3 text-end">
                <button
                  onClick={switchView}
                  className="btn pn-button-icon-secondary  br-10 shadow-none lh-0 py-0 ps-0 pe-1 align-items-center fw-sb text--secondary font-12"
                >
                  <span className="pn-button-icon-secondary pe-1">
                    <AddIcon color="#336383" size={14} />{" "}
                  </span>
                  <span
                    className="d-inline-block pn-link-button"
                    style={{ verticalAlign: "middle" }}
                  >
                    Add Encounter Data
                  </span>
                </button>
              </div>
            </div>
          ) : null}

          <ClaimPatientEncountersTable
            data={patientEncounters}
            setEncounterToEdit={props?.setEncounterDto}
            setEditMode={props?.setEditMode}
            switchView={switchView}
            setReloadEncounters={setReloadEncounters}
            columnHeadersList={[
              { key: "provider.firstName", value: "Provider" },
              {
                key: "encounterMetadata.dateOfServiceTo",
                value: "Service Date",
              },
              {
                key: "encounterMetadata.serviceLocation",
                value: "Service Location",
              },
              {
                key: "encounterMetadata.placeOfService ",
                value: "Place of Service",
              },
              { key: "diagnosis ", value: "Diagnosis" },
              {
                key: "dateOfCurrentIllnessOrSymptom ",
                value: "Date of Current Illnes",
              },
              { key: "claimSubmitted", value: "Status" },
              { key: " ", value: " " },
            ]}
          />
        </>
      )}
    </>
  );
};

export default ClaimEncountersTabPanel;

import React, { useEffect, useRef, useState } from "react";
import PNLogo from "../../../assets/logo/SYMBOL.svg";
import { useStopwatch } from "react-timer-hook";
import { CameraVideo } from "react-bootstrap-icons";
import { CameraVideoOff } from "react-bootstrap-icons";
import { MicMute } from "react-bootstrap-icons";
import { Mic } from "react-bootstrap-icons";
import { Telephone } from "react-bootstrap-icons";
import CloseIcon from "@mui/icons-material/Close";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setConsulationInProgress,
  setVideoOpen,
} from "../../../redux/actions/providerActions";
import { dateTimeHelper } from "../../../utils/dateTimeHelper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PnToolTip } from "../Reusable/PnComponents";
import {
  formatPhoneNumber,
  sleepSync,
  truncate,
} from "../../../utils/helpers/HelperFunctions";
import { client } from "./agoraDataObjects";
import { useContext } from "react";
import { VideoCallContext } from "../AgoraHooks/VideoCallContext";
import { IconButton, Menu, MenuItem } from "@mui/material";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import classes from "./AgoraVideoBar.module.css";
import sendHttpRequest from "../../hooks/sendHttpRequest";
import PhoneNumberInput from "../Reusable/PhoneNumberInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import CustomizedSnackbar from "../General/CustomizedSnackbars";

const Timer = () => {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useStopwatch({ autoStart: true });

  return (
    <>
      <span>{hours}</span>:<span id="minutes">{minutes}</span>:
      <span id="seconds">{seconds}</span>
    </>
  );
};




const InviteUserForVConsult = ({ show, handleModal, appointmentId, setEmailToastMsg,
  setSeverity,
  setIsInvitationSent }) => {
  const [userEmail, setUserEmail] = useState();
  const [userName, setUserName] = useState();
  const [cellPhone, setCellPhone] = useState("");
  const [cellPhoneError, setCellPhoneError] = useState(false);
  const [isMandatoryFieldAdded, setIsMandatoryFieldAdded] = useState(false);

  const sendEmailInvitation = async () => {
    if (document.getElementById("invite-user")?.reportValidity() === false) {
      return;
    }
    const httpResponse = sendHttpRequest({
      url: `/appointment/${appointmentId}/invitation`,
      method: "POST",
      data: {
        name: userName,
        email: userEmail,
        smsNumber: cellPhone,
        appointmentId: appointmentId,
      },
    });
    if (!httpResponse.error) {
      setIsInvitationSent(true);
      setEmailToastMsg("Invitation sent successfully");
      setSeverity("success");
    } else {
      setIsInvitationSent(true);
      setEmailToastMsg("Invitation not sent due to some error");
      setSeverity("error");
      console.log("failier");
    }
  };

  const handleUserEmail = (event) => {
    event.preventDefault();
    setUserEmail(event.target.value);
    if (event.target.value === '') {
      setIsMandatoryFieldAdded(p => false);
    } else {
      setIsMandatoryFieldAdded(p => true);
    }
  };
  const handleUserName = (event) => {
    event.preventDefault();
    setUserName(event.target.value);
  };
  const cellPhoneHandler = (phoneValue) => {
    console.log("phoneValue: ", phoneValue);
    if (
      phoneValue !== undefined &&
      isValidPhoneNumber(phoneValue?.toString()) &&
      phoneValue?.length == 12
    ) {
      // console.log("Valed Part");
      let mobileNumber = formatPhoneNumber(phoneValue?.replace("+1", ""));
      setCellPhone(mobileNumber);
      setCellPhoneError(false);
      setIsMandatoryFieldAdded(p => true);

    } else if (
      phoneValue !== undefined &&
      !isValidPhoneNumber(phoneValue?.toString()) &&
      phoneValue?.length > 2
    ) {
      setCellPhone("");
      setCellPhoneError(true);
      setIsMandatoryFieldAdded(p => true);
    } else if (phoneValue?.length === 2 || phoneValue === undefined) {
      setCellPhone("");
      setCellPhoneError(false);
    }
  };


  console.log(isMandatoryFieldAdded)
  return (
    <>
      <Modal
        show={show}
        size="md"
        dialogClassName="end-consultation-modal-dialog"
        backdropClassName="modal-backdrop"
        contentClassName="modal-border modal-shadow"
        centered
      >
        <Modal.Header className={`border--bottom`}>
          <span
            style={{
              lineHeight: "12px",
              width: "18px",
              fontSize: "8pt",
              color: "#336383",
              position: "absolute",
              top: 15,
              right: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              handleModal();
            }}
          >
            {<CloseIcon />}
          </span>
          <Modal.Title className="font-20 fw-sb text--terciary">
            Invite user
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row py-2 container-fluide">
            <form
              id="invite-user"
              onSubmit={(e) => {
                e.preventDefault();
                sendEmailInvitation();
                handleModal(e);
              }}
            >
              <div className="col-sm-12 ">
                <label
                  htmlFor="text"
                  className="form-label m-0 fw-sb font-12 text--secondary"
                >
                  Name
                </label>
                <div className="">
                  <input
                    type="text"
                    className={`form-control shadow-none border--default br-10 font-14 ${classes["input__height"]}`}
                    // placeholder={"Enter email"}
                    onChange={(e) => {
                      handleUserName(e);
                    }}
                    required
                  />
                </div>
              </div>
              <div className="col-sm-12 ">
                <label
                  htmlFor="email"
                  className="form-label m-0 fw-sb font-12 text--secondary"
                >
                  Email
                </label>
                <div className="">
                  <input
                    type="email"
                    className={`form-control shadow-none border--default br-10 font-14 ${classes["input__height"]}`}
                    // placeholder={"Enter email"}
                    onChange={(e) => {
                      handleUserEmail(e);
                    }}
                    {...(isMandatoryFieldAdded === false ? ({ required: true }) : ({ required: false }))}
                  />
                </div>
              </div>
              <div className="col-md-12 col-sm-12">
                <label
                  htmlFor="CellPhone"
                  className="form-label m-0 fw-sb font-12 text--secondary"
                >
                  Mobile Phone
                </label>
                <div>
                  <PhoneNumberInput
                    value={cellPhone}
                    name="CellPhone"
                    onChange={cellPhoneHandler}
                    id="CellPhone"
                    {...(isMandatoryFieldAdded === false ? ({ required: true }) : ({ required: false }))}
                  />
                </div>
                {
                  cellPhoneError === true ? (
                    <span className="text--danger py-1 font-10 d-block">
                      {"Incorrect number format"}
                    </span>
                  ) : null}
              </div>
            </form>
          </div>
          <div className="row justify-content-between py-3 px-2">
            <div className="col-sm-5">
              <button
                onClick={() => {
                  handleModal();
                }}
                className="btn w-140 br-10 d9e8ec--button shadow-none text-white font-14 fw-sb"
              >
                Cancel
              </button>
            </div>
            <div className="col-sm-5">
              <button
                form="invite-user"
                type="submit"
                className="btn w-140 br-10 bg--blue shadow-none text-white font-14 fw-sb"
              >
                Send
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const AgoraVideoBar = (props) => {

  const dispatch = useDispatch();
  const videoCallContext = useContext(VideoCallContext);
  const videoOpenState = useSelector((state) => state.isVideoOpen.isVideoOpen);
  const [remoteUsers, setRemoteUsers] = useState(() => (videoCallContext.client.remoteUsers.length));

  const appointmentInformation = useSelector(
    (state) => state.PatientEncounterData.appoinment
  );
  const [isPlayingVideo, setIsPlayingVideo] = useState(true);
  const [muteAudio, setMuteAudio] = useState(true);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openAddUserModal, setAddUserModal] = useState(false);
  const [isInvitationSent, setIsInvitationSent] = useState(false);
  const [emailToastMsg, setEmailToastMsg] = useState("")
  const [severity, setSeverity] = useState("")

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSetIsVideoContainerOpen = (event) => {
    event.stopPropagation();

    try {
      console.log("Called");
      const isRemoteUserAvailable = (videoCallContext.client.remoteUsers?.length > 0);
      console.log("RU", isRemoteUserAvailable);
      if (isRemoteUserAvailable === true) {
        if (!isPlayingVideo) {
          videoCallContext.resumeVideo();
        }
        else {
          videoCallContext.pauseVideo();
        }
        setIsPlayingVideo((prev) => {
          return !prev;
        });
      }
      else {
        console.log("Inside Else Block")
        if (videoOpenState === true) {
          console.log("Inside closing container block")
          dispatch(setVideoOpen(false));
        }
        else {
          console.log("Insider opening container block")
          dispatch(setVideoOpen(true));
        }
      }
    }
    catch (e) {

    }



    // dispatch(setVideoOpen(!videoOpenState));
  };

  const handleMuteAudio = () => {
    videoCallContext.muteAudio();
    setMuteAudio((prev) => {
      return !prev;
    });
  };

  const handleUnmuteAudio = () => {
    videoCallContext.unmuteAudio();
    setMuteAudio((prev) => {
      return !prev;
    });
  };

  const handleEndConsulationModal = (e) => {
    // console.log("props?.endConsultation", props?.endConsultation);
    // console.log(e);

    props.setEndConsultation(p => true);
    // setEndConsultation((prev) => {
    //   return !prev;
    // });
  };

  const returnToEncounter = () => {
    // if (!window.location.pathname.includes('consult')) {
    //   navigate(
    //     `/consult/${appointmentInformation?.appoinmentSlot?.provider?.userId}/${appointmentInformation?.encounter?.encounterId}/${appointmentInformation?.encounter?.patient?.userId}`,
    //     { replace: true }
    //   );
    // }
  };
  const handleAddUserModal = () => {
    setAddUserModal(!openAddUserModal);
  };

  const handleEmailInvitationToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsInvitationSent(false);
  };

  useEffect(() => {
    console.log("Participant Joined", props?.participantJoined);


    if (props?.participantJoined === true) {
      setRemoteUsers(prev => videoCallContext.client.remoteUsers?.length);
    }
    else {
      setRemoteUsers(prev => 0);
    }


  }, [props?.participantJoined])



  return (
    <>
      {openAddUserModal &&
        <InviteUserForVConsult
          show={openAddUserModal}
          handleModal={handleAddUserModal}
          appointmentId={appointmentInformation?.id}
          setEmailToastMsg={setEmailToastMsg}
          setSeverity={setSeverity}
          setIsInvitationSent={setIsInvitationSent}
        />
      }
      <div
        // onClick={returnToEncounter}
        id="agora-video-bar-container"
        className="bg--darkblue row video-bar-py video-bar justify-content-between pe-4"
      >
        <div className="video-bar-ps-4 col-8">
          <div className="row">
            <div className="col-sm-1 align-self-center">
              <img src={PNLogo} width="68" />
            </div>
            <div className="col-sm-10 ps-4">
              <PnToolTip
                title={
                  appointmentInformation?.appointmentSlot?.patient?.name[0]
                    ?.firstName +
                  " " +
                  appointmentInformation?.appointmentSlot?.patient?.name[0]
                    ?.lastName
                }
              >
                <div
                  className="font-16 text-white d-block  fw-sb"
                  style={{ marginBottom: "-4px" }}
                >
                  Ongoing Appointment:{" "}
                  {truncate(
                    appointmentInformation?.appointmentSlot?.patient?.name[0]
                      ?.firstName +
                    " " +
                    appointmentInformation?.appointmentSlot?.patient?.name[0]
                      ?.lastName,
                    28
                  )}
                </div>
              </PnToolTip>
              <span className="font-14 text-white d-inline-block">
                {dateTimeHelper(
                  appointmentInformation?.appointmentSlot?.startDateTime,
                  "LT"
                )}{" "}
              </span>
              <span className="font-14 text-white-70 d-inline-block ms-3">
                Duration: <Timer />{" "}
              </span>
            </div>
          </div>
        </div>

        <div className="col-3 d-flex gap-2 justify-content-end">
          <div>
            <IconButton
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              className="ms-4  "
              aria-label="more"
              style={{ border: "1px white solid", borderRadius: "10px" }}
            >
              <MoreHorizIcon style={{ color: "#fff" }} />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              dense
              onClose={handleClose}
              disableScrollLock={true}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                dense
                onClick={() => {
                  handleClose();
                  handleAddUserModal();
                }}
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: 12,
                  color: "#323232",
                }}
              // disabled={
              //   props?.encounter?.medicationRx[0]?.refillStatus === "pending" ? true : props?.encounter?.medicationRx[0]?.refillStatus === "declined" ? true : false
              // }
              >
                Invite user
              </MenuItem>
            </Menu>
          </div>
          {/* {console.log("remote users: ", remoteUsers)}
          {console.log("isplaying: ", isPlayingVideo)} */}
          <PnToolTip title={remoteUsers > 0 ? isPlayingVideo === false ? "Resume Video" : "Pause Video" : videoOpenState === true ? "Hide video container" : "Open video container"}>
            <button
              onClick={handleSetIsVideoContainerOpen}
              className={`${videoOpenState === false
                ? "video-bar-call-button-open"
                : "video-bar-call-button-close"
                } btn shadow-none text-white fw-sb`}
            >
              {isPlayingVideo === false ? (
                <>
                  <CameraVideoOff className="fw-sb font-20 me-2 control-btn" />
                </>
              ) : (
                <>
                  <CameraVideo className="fw-sb font-20 me-2 control-btn" />
                </>
              )}
            </button>
          </PnToolTip>

          {muteAudio === true ? (
            <button
              onClick={(e) => handleMuteAudio(e)}
              className={`video-bar-call-control video-bar-mute fw-sb control-btn`}
            >
              <Mic className="fw-sb font-20 control-btn" color="#fff" />
            </button>
          ) : (
            <button
              onClick={(e) => handleUnmuteAudio(e)}
              className={`video-bar-call-control video-bar-mute fw-sb control-btn `}
            >
              <MicMute className="fw-sb font-20 control-btn" color="#fff" />
            </button>
          )}
          <button
            onClick={(e) => handleEndConsulationModal(e)}
            className={`video-bar-call-control video-bar-end control-btn `}
          >
            <Telephone
              color="#fff"
              style={{ transform: "rotate(135deg)" }}
              className="font-20 control-btn"
            />
          </button>
        </div>
      </div>

      <CustomizedSnackbar
        message={emailToastMsg}
        severity={severity}
        open={isInvitationSent}
        handleClose={handleEmailInvitationToast}
      />
    </>
  );
};

export default AgoraVideoBar;

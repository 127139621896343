import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EditButtonIcon from "../../assets/icons/Icon-Edit.svg";
import TrashButtonIcon from "../../assets/icons/Icon-Trash.svg";
import DeleteMedication from "../UI/Modals/PrescriptionModal/DeleteMedication";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { X } from "react-bootstrap-icons";
import TrashIconComponent from "../../assets/iconComponents/TrashIconComponent";
import EditIconComponent from "../../assets/iconComponents/EditIconComponent";
import CancelRxModal from "../UI/Modals/ProviderModals/CancelRxModal";
import CustomizedSnackbar from "../UI/General/CustomizedSnackbars";
import { useParams } from "react-router-dom";
import { featureFlagMap } from "../../utils/helpers/ConfigMap";
import {
  truncate,
  truncateWithStartIndexGiven,
} from "../../utils/helpers/HelperFunctions";
import { PnToolTip } from "../UI/Reusable/PnComponents";
import ReplayIcon from "@mui/icons-material/Replay";
import { Hidden } from "@mui/material";
import { PATIENTS } from "../../utils/helpers/subscriptionsConstants";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FAFCFC",
    color: "#336383",
    fontWeight: 400,
    fontSize: 12,
    fontFamily: "Montserrat",
    minWidth: 90,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    paddingRight: "0px",
    fontFamily: "Montserrat",
    minWidth: 90,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  minWidth: 130,
  "&:nth-of-type(even)": {
    backgroundColor: "#FAFCFC",
    fontFamily: "Montserrat",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    fontFamily: "Montserrat",
  },
}));

const PrescriptionListItem = (props) => {
  const featureFlags = featureFlagMap();
  const disableSendRxButton = useSelector(
    (state) => state.DisableSendRxButton.disabled
  );
  const prescribedMedications = useSelector(
    (state) => state.PrescribedMedication.prescribedDrugList
  );
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);
  let { providerId, encounterId, patientId } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [medicineName, setMedicineName] = useState("");
  const [deleteDDID, setDeleteDDID] = useState("");
  const [openCancelRxModal, setOpenCancelRxModal] = useState(false);

  const [cancelRxMessage, setCancelRxMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [cancelRxToastOpen, setCancelRxToastOpen] = useState(false);
  const [cancelRxError, setCancelRxError] = useState(false);
  const [showSigMore, setShowSigMore] = useState(false);
  const [disableItem, setDisableItem] = useState(false);

  const handleDeleteMedicine = (flag) => {
    if (!flag) {
      setMedicineName(props.medecineName);
      setDeleteDDID(props?.drugDbCode);
      setOpenModal(true);
    }
  };
  const handleEditMedicine = (drugDbCodeValue, flag) => {
    // console.log("drugDbCodeValue: ", drugDbCodeValue);
    if (!flag) {
      let drugId = drugDbCodeValue;
      // console.log("prescribedMedications: ", prescribedMedications);
      let result = prescribedMedications.filter((medicine) => {
        return medicine.drugDbCode == drugId;
      });
      // console.log("result: ===============>", result);
      props?.setDrugData({
        ...result[0],
        DDID: drugId,
        drugDbCode: drugId,
        drugDescription: props?.medecineName,
        Quantity: result[0]?.Quantity,
        daySupply: result[0]?.daySupply,

        Sig: {
          SigText: result[0]?.Sig?.SigText,
        },
        sigText: result[0]?.Sig?.SigText,
        textValue: result[0]?.Sig?.SigText,
        drugOrderId: result[0]?.customSigFlag === true ? "-1" : drugId,
        DispenseAsWritten: result[0]?.DispenseAsWritten,
      });
      props.editMedicine({
        ...result[0],
        DDID: drugId,
        drugDbCode: drugId,
        drugDescription: props?.medecineName,
        Quantity: result[0]?.Quantity,
        daySupply: result[0]?.daySupply,

        Sig: {
          SigText: result[0]?.Sig?.SigText,
        },
        sigText: result[0]?.Sig?.SigText,
        textValue: result[0]?.Sig?.SigText,
        drugOrderId: result[0]?.customSigFlag === true ? "-1" : drugId,
        customSigFlag:
          result[0]?.customSigFlag !== undefined
            ? result[0]?.customSigFlag
            : false,
      });
    }
  };
  const handleModal = () => {
    setOpenModal(false);
  };

  const handleCancelRx = (flag) => {
    // setRxObject(medicationObject);
    if (flag) {
      setOpenCancelRxModal(true);
    }
  };

  const handleCancelRxModal = () => {
    setOpenCancelRxModal(!openCancelRxModal);
  };
  const handleCancelRxToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setCancelRxToastOpen(false);
  };

  const handleMoreSig = () => {
    setShowSigMore(!showSigMore);
  };

  const handleDisableReOrder = () => {
    // console.log(
    //   "props?.prescriptionItem?.drugId: ",
    //   props?.prescriptionItem?.drugId
    // );
    const result = prescribedMedications.find((item) => {
      return item.drugDbCode === props?.prescriptionItem?.drugId;
    });
    // console.log("result: ", result);
    if (result !== undefined) {
      setDisableItem(true);
    } else {
      setDisableItem(false);
    }
  };
  useEffect(() => {
    if (props?.currentMed === true) {
      handleDisableReOrder();
    }
  }, [prescribedMedications]);

  return (
    <>
      {openModal && (
        <DeleteMedication
          show={openModal}
          // diagnosis={deleteDiagnosis}
          handleModal={handleModal}
          medicineName={medicineName}
          deleteDDID={deleteDDID}
          deleteMedicine={props.deleteMedicine}
        // handleDelete={handleDelete}
        />
      )}
      {openCancelRxModal && (
        <CancelRxModal
          show={openCancelRxModal}
          handleModal={handleCancelRxModal}
          patient={props?.patientData}
          pharmacy={props?.prescriptionItem?.pharmacy}
          medication={props?.prescriptionItem}
          encounterId={encounterId}
          cancelRxError={cancelRxError}
          setCancelRxError={setCancelRxError}
          severity={severity}
          setSeverity={setSeverity}
          cancelRxMessage={cancelRxMessage}
          setCancelRxMessage={setCancelRxMessage}
          cancelRxToastOpen={cancelRxToastOpen}
          setCancelRxToastOpen={setCancelRxToastOpen}
        />
      )}

      <StyledTableRow
        // key={}

        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <StyledTableCell>{props?.medecineName}</StyledTableCell>
        <StyledTableCell>
          <span>
            {showSigMore ? (
              <>
                {props?.sig}
                <span
                  className="text--secondary fw-sb cursor--pointer"
                  onClick={() => {
                    handleMoreSig();
                  }}
                >
                  {" less"}
                </span>
              </>
            ) : (
              <span>
                {truncate(props?.sig, 100)}
                {props?.sig?.length > 100 ? (
                  <span
                    className="text--secondary fw-sb cursor--pointer"
                    onClick={() => {
                      handleMoreSig();
                    }}
                  >
                    {" more"}
                  </span>
                ) : (
                  ""
                )}
              </span>
            )}
          </span>
        </StyledTableCell>
        <StyledTableCell>
          {parseFloat(Number(props?.quantity).toFixed(3))}
        </StyledTableCell>
        <StyledTableCell>
          {props?.refills?.length === 0 ? "0" : props?.refills}
        </StyledTableCell>
        <StyledTableCell>
          {props?.rxStatus === "Canceled" ? props?.rxStatus : props?.status}
        </StyledTableCell>
        <StyledTableCell>
          {
            <div className="d-flex flex-row justify-content-between me-1">
              {props?.currentMed === true ? (
                <div className="align-self-center">
                  {/* {props?.updateCheck ? (
                    ""
                  ) : ( */}
                  <PnToolTip title={"Reorder"} arrow placement="top"
                    hidden={subscriptionPrivilegeGroups[PATIENTS]?.rpm?.active === false ? true : false}
                  >
                    <ReplayIcon
                      className={`icon__defualt font--12 text--secondary my-auto cursor--pointer ${disableItem ? "disabled-link" : props?.updateCheck ? 'disabled-link' : ''
                        }`}
                      fontSize={"small"}
                      onClick={() => {
                        props?.handleReorderModal(props?.prescriptionItem);
                      }}
                    />
                  </PnToolTip>
                  {/* )} */}
                </div>
              ) : null}
              {featureFlags.get("surescript") === "true" && (
                <div className="align-self-center" hidden={subscriptionPrivilegeGroups[PATIENTS]?.cpm?.active === false ? true : false}>
                  <PnToolTip title={"Cancel"} arrow placement="top">
                    <X
                      className={`icon__defualt font--18  ${props?.flag ? "text--danger" : "d-none icon--disabled"
                        } ${props?.isCancelled === true ? "disabled-link " : ""}`}
                      style={{ width: "28px", height: "28px" }}
                      onClick={() => {
                        handleCancelRx(props?.flag);
                      }}
                    />
                  </PnToolTip>
                </div>
              )}

              <div className=" align-self-center">
                {props?.updateCheck ? (
                  ""
                ) : (
                  <div
                    onClick={() =>
                      handleEditMedicine(props.drugDbCode, props?.flag)
                    }
                    className="cursor--pointer"
                  >
                    <EditIconComponent
                      style={
                        props?.flag === false
                          ? { color: "#336383", fill: "#336383" }
                          : {
                            color: "#bcd6da",
                            fill: "#bcd6da",
                            display: "none",
                          }
                      }
                    />
                  </div>
                )}
              </div>
              <div className=" align-self-center ms-1">
                {props?.updateCheck ? (
                  ""
                ) : (
                  <div
                    onClick={() => handleDeleteMedicine(props?.flag)}
                    className="cursor--pointer "
                  >
                    <PnToolTip title={"Delete"} arrow placement="top">
                      <TrashIconComponent
                        style={
                          props?.flag === false
                            ? { color: "#ff0000", fill: "#ff0000" }
                            : {
                              color: "#bcd6da",
                              fill: "#bcd6da",
                              display: "none",
                            }
                        }
                      />
                    </PnToolTip>
                  </div>
                )}
              </div>
            </div>
          }
        </StyledTableCell>
      </StyledTableRow>
      {/* {console.log("props?.currentMed: ", props?.currentMed)}
      {console.log("Update check: ", props?.updateCheck)}
      {console.log("props?.flag: ", props?.flag)} */}
      {/* {console.log("props.disabled: ",props.disableItem)} */}
      {/* {console.log("prescribedMedications: ", prescribedMedications)} */}

      <CustomizedSnackbar
        message={cancelRxMessage}
        severity={severity}
        open={cancelRxToastOpen}
        handleClose={handleCancelRxToast}
      />
    </>
  );
};

export default PrescriptionListItem;

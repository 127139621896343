import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useRef, useState } from "react";
import { search } from "../../hooks/sendHttpRequest";
import PatientSearchItem from "./PatientSearchItem";

import classes from "../Header/SearchPatient.module.css";
import { useDispatch } from "react-redux";
import { setPatientID } from "../../../redux/actions/providerActions";
import { useNavigate } from "react-router-dom";
import { patientSearchUrl } from "../../../res/endpointurls";
import { useAuth } from "../../hooks/useAuth";
import _ from "lodash";

const SearchPatient = (props) => {
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const dispatch = useDispatch();
  const [patientName, setPatientName] = useState("");
  const [disableSearchBar, setDisableSearchBar] = useState(true);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1); // Track currently selected suggestion

  const navigate = useNavigate();
  const searchInput = useRef();
  const { user } = useAuth();
  const [activeAdvanceSearch, setActiveAdvanceSearch] = useState(false);

  const searchPatient = async (patientName) => {
    setIsLoading(true);
    setIsError(false);
    if (props?.newAppointment === true) {
      props?.setProviderList([]);
      dispatch(setPatientID(-1));
    }

    const httpResponse = await search({
      url: patientSearchUrl(user?.tenantId),
      method: "GET",
      params: {
        search: patientName,
      },
    });
    if (!httpResponse.error) {
      setSuggestions(httpResponse.data);
      // console.log(httpResponse.data);
    } else {
      dispatch(setPatientID(-1));
      setSuggestions([]);
      if (httpResponse?.status === 400) {
        setIsError(true);
      }
    }
    setIsLoading(false);
  };

  const handleOnChange = (inputValue) => {
    const sanitizedInput = inputValue.replace(/[_%]/g, '');

    if (sanitizedInput?.trim()?.length > 0) {
      setPatientName(sanitizedInput);
      searchPatient(sanitizedInput);
      setSearchInputValue(sanitizedInput);

      if (props?.EnableDirtyCheck) {
        props?.onDirty();
      }
    } else {
      setPatientName("");
      setSuggestions([]);
      setSearchInputValue("");
    }
  };

  const handleSearchIconBtn = (event) => {
    event.preventDefault();
    setActiveAdvanceSearch(true);
    // console.log("event.target.value", searchInputValue);
    handleOnChange(searchInputValue);
  };
  const handlePatientSearch = (patient) => {
    // console.log("Patient ID: ", patient?.userId);
    dispatch(setPatientID(patient?.userId));
    setSelectedSuggestionIndex(-1);
    setActiveAdvanceSearch(false);
    setSuggestions([]);
    if (props?.newAppointment === true) {
      // props?.getPatientTimezone(patient?.userId);
      props?.setSlotsTimezoneOffset(patient?.timezoneOffset);
      props?.setSlotsTimezone(patient?.timezone);
    }
    if (
      props?.required &&
      props?.disable === false &&
      props?.postSelectedUser
    ) {
      props?.postSelectedUser(patient?.userId);
    }
    if (props?.navigate) {
      navigate(`/all-patients/${patient?.userId}`, {
        state: {
          patientId: `${patient?.userId}`,
          selectedIndex: `${patient?.userId}`,
          index: `${patient?.userId}`,
        },
      });
      // console.log(patient);
    }
    if (props?.removeName === true) {
      setSearchInputValue("");
    } else {
      setSearchInputValue(
        patient?.name[0]?.firstName + " " + patient?.name[0]?.lastName
      );
    }
    if (props?.handlePatientsAdd !== undefined) {
      props?.handlePatientsAdd({
        id: patient?.userId,
        userId: patient?.userId,
        FirstName: patient?.name[0]?.firstName,
        LastName: patient?.name[0]?.lastName,
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();
      setSelectedSuggestionIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : suggestions.length - 1
      );
    } else if (event.key === "ArrowDown") {
      event.preventDefault();
      setSelectedSuggestionIndex((prevIndex) =>
        prevIndex < suggestions.length - 1 ? prevIndex + 1 : 0
      );
    } else if (event.key === "Enter") {
      event.preventDefault();
      if (selectedSuggestionIndex !== -1) {
        handlePatientSearch(suggestions[selectedSuggestionIndex]);
      }
    }
  };

  useEffect(() => {
    if (props?.focus === true) {
      searchInput?.current?.focus();
    }
    if (props?.disable) {
      setSuggestions([]);
    }
    if (props?.patientId) {
      dispatch(setPatientID(props?.patientId));
      props?.postSelectedUser(props?.patientId);
      if (props?.name?.length > 0 && props?.name !== undefined) {
        setSearchInputValue(
          props?.name[0]?.firstName + " " + props?.name[0]?.lastName
        );
      }
    }
  }, [props?.disable]);

  return (
    <>
      <div
        className={
          props?.disable === true
            ? `input-group bg--disabled border--default br-10`
            : `input-group border--default br-10`
        }
        disabled={true}
        style={{
          width: `${props.width || "396px"}`,
        }}
      >
        <input
          type="text"
          ref={searchInput}
          onKeyDown={handleKeyDown}
          disabled={props?.disable === true ? true : false}
          required={props?.required === true ? true : false}
          className={
            props?.disable === true
              ? `form-control shadow-none border-0 ms-1 font-14 bg--disabled ${classes["searchBar"]}`
              : `form-control shadow-none border-0 ms-1 font-14 ${classes["searchBar"]}`
          }
          placeholder={"Search for patient"}
          // style={{backgroundColor:'white'}}
          style={
            props?.disableSearch === true ? { backgroundColor: "#fff" } : {}
          }
          onChange={(event) => handleOnChange(event.target.value)}
          // defaultValue = {searchInputValue}
          value={searchInputValue}
          onFocus={() => {
            setActiveAdvanceSearch(true);
          }}
          onBlur={() => {
            setTimeout(() => {
              setSuggestions([]);
              setIsError(false);
              setIsLoading(false);
              setActiveAdvanceSearch(false);
            }, 5);
          }}
        />

        <button
          className={
            props?.disable === true
              ? `btn br-10 shadow-none bg--disabled`
              : `btn br-10 shadow-none`
          }
          type="submit"
          value={searchInputValue}
          onClick={(e) => {
            handleSearchIconBtn(e);
          }}
          onBlur={() => {
            setTimeout(() => {
              setSuggestions([]);
              setIsError(false);
              setIsLoading(false);
              setActiveAdvanceSearch(false);
            }, 5);
          }}
        >
          <SearchIcon className=" text--secondary" />
        </button>
      </div>

      <div className="d-flex flex-column" style={{ zIndex: "99999999" }}>
        {/* {suggestions?.length > 0 &&  patientName?.length > 0 ? ( */}
        <div
          className={suggestions.length > 0 ? `overflowy-auto` : undefined}
          style={{ position: "absolute", height: "60vh", zIndex: "99999999" }}
        >
          <ul
            className="list-group border--defualt cursor--pointer"
            style={{
              listStyleType: "none",
              paddingLeft: "0",
              zIndex: "99999999",
              borderTop: 0,
              backgroundColor: "#FFF",
              boxShadow:
                props?.navigate === true
                  ? "0px 13px 26px #3363831A"
                  : "0 2px 8px rgba(0, 0, 0, 0.15)",
            }}
          >
            {isLoading === true ? (
              <PatientSearchItem
                PatientName={"Loading..."}
                showButton={false}
              // isSelected={isSelected}
              // showFullInfo = {props?.navigate}
              />
            ) : isError === true ? (
              <PatientSearchItem
                PatientName={"No result found"}
                showButton={false}
              // isSelected={isSelected}
              // showFullInfo = {props?.navigate}
              />
            ) : (
              suggestions?.length > 0 &&
              suggestions.map((suggestion, index) => {
                return (
                  <PatientSearchItem
                    isSelected={index === selectedSuggestionIndex}
                    key={suggestion?.userId}
                    PatientName={
                      suggestion?.name[0]?.firstName +
                      " " +
                      suggestion?.name[0]?.lastName
                    }
                    Patient={suggestion}
                    startConsult={handlePatientSearch}
                    showButton={false}
                    width={props.width}
                    index={index}
                    setSelectedSuggestionIndex={setSelectedSuggestionIndex}
                    showFullInfo={props?.navigate}
                  />
                );
              })
            )}
          </ul>

          {activeAdvanceSearch
            ? props?.advanceSearch === true && (
              <div
                style={{
                  bottom: 0,
                  position: "sticky",
                  zIndex: "99999999",
                  border: "none",
                  width: `${suggestions?.length > 0 ? "25em" : "25.5em"}`,
                }}
                className="border-0"
                onClick={() => {
                  setSearchInputValue("");
                  navigate("/advanced-search");
                }}
              >
                <ul
                  className="list-group  cursor--pointer border-0"
                  style={{
                    listStyleType: "none",
                    paddingLeft: "0",

                    // top: 0,

                    backgroundColor: "#FFF",
                    boxShadow:
                      props?.navigate === true
                        ? "0px 13px 26px #3363831A"
                        : "0 2px 8px rgba(0, 0, 0, 0.15)",
                  }}
                >
                  <li
                    className={`text-end list-group-item font-12 w-100 border-0 ${classes['li']}`}
                    onMouseDown={(e) => {
                      setSearchInputValue("");
                      console.log(e.target);
                      navigate("/advanced-search");
                    }}
                  >
                    <p className="text--blue font-12 mb-0 fw-sb me-1">
                      {"Advanced Search"}
                    </p>
                  </li>
                </ul>
              </div>
            )
            : null}
        </div>

        {/* ) 
        : (

          isError && (
            <div
            className={suggestions.length > 0 ? `overflowy-auto` : undefined}
            style={{ position: "absolute", height: "60vh", zIndex: "99999999" }}
          >
            <ul
              className="list-group border--defualt cursor--pointer"
              style={{
                listStyleType: "none",
                paddingLeft: "0",
                zIndex: "99999999",
                borderTop: 0,
                backgroundColor: "#FFF",
                boxShadow:
                  props?.navigate === true
                    ? "0px 13px 26px #3363831A"
                    : "0 2px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <PatientSearchItem
                PatientName={"No result found"}
                showButton={false}
                // showFullInfo = {props?.navigate}
              />
              </ul></div>
          )
        )
        } */}
      </div>
      {/* {console.log("searchInputValue",searchInputValue)} */}
      {/* {console.log("isLoading : ",isLoading)} */}
    </>
  );
};

export default SearchPatient;

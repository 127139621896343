import { makeStyles } from "@mui/styles";
import React, { useEffect, useRef, useState } from "react";
import Drawer from "../UI/Drawer/Drawer";
import { useLocation } from "react-router-dom";
import PatientDrawer from "../UI/Drawer/PatientDrawer";
import { useAuth } from "../hooks/useAuth";
import { jsonToMap } from "../../utils/helpers/HelperFunctions";
import Navbar from "../UI/Navbar";
import sendHttpRequest, { search } from "../hooks/sendHttpRequest";
import AgoraVideoBar from "../UI/AgoraVideoComponents/AgoraVideoBar";
import { useDispatch, useSelector } from "react-redux";
import { useToggle } from "../hooks/useToggle";
import AgoraMinimalVideoContainer from "../UI/AgoraSimplified/AgoraMinimalVideoContainer";
import { setConsulationInProgress, setVideoOpen } from "../../redux/actions/providerActions";
import AgoraMinimalVideoContainerV2 from "../UI/AgoraSimplified/AgoraMinimalVideoContainerv2";
import { useContext } from "react";
import { VideoCallContext } from "../UI/AgoraHooks/VideoCallContext";

const useStyle = makeStyles({
  page: {
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    scrollbarColor: "#FAFCFC",
    overflowX: "hidden",
  },
  root: {
    display: "flex",
    minHeight: "100%",
  },
  columnZIndex: "1310",
});

const UrlMap = function (url) {
  const urls = new Map();
  urls.set("/idme/provider", true);
  // urls.set("/notifications", true);

  return urls.get(url) || false;
};

function addPropsToReactElement(element, props) {
  if (React.isValidElement(element)) {
    return React.cloneElement(element, props)
  }
  return element
}

function addPropsToChildren(children, props) {
  if (!Array.isArray(children)) {
    return addPropsToReactElement(children, props)
  }
  return children.map(childElement =>
    addPropsToReactElement(childElement, props)
  )
}
export default function Layout({ children }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const videoCallContext = useContext(VideoCallContext);
  const classes = useStyle();
  const { user } = useAuth();
  const [appointmentPassed, setAppointmentPassed] = useState(true);
  const [endConsultation, setEndConsultation] = useToggle(false);
  const [mute, setMute] = useToggle(false);
  const [canConnect, setCanConnect] = useState(true);
  const [participantJoined, setParticipantJoined] = useState(false);

  const [lvd, setLvd] = useState('');
  const [lad, setLad] = useState('');
  const intervalId = useRef(null);
  // const [pollForUser, setPollForUser] = useState(false);

  const joinPage = useSelector((state) => state.isJoinPageVisible.joinPage);
  const isVideoConsultationInProgress = useSelector(
    (state) => state.isConsultationInProgress.isConsultationInProgress
  );


  const isVideoContainerOpen = useSelector((state) => state.isVideoOpen.isVideoOpen);
  // console.log("Is Video Container Open ===> ", isVideoContainerOpen);

  const appointmentInformation = useSelector(
    (state) => state.PatientEncounterData.appoinment
  );





  const map = jsonToMap(user);
  const [notificationList, setNotificationList] = useState([]);

  // const waitingRoomPolling = async (appointmentId) => {

  //   const httpResponse = await sendHttpRequest({
  //     url: `/video/approvalrequest/${appointmentId}`,
  //     method: "GET",
  //   });
  //   if (!httpResponse.error) {
  //     // console.log(httpResponse);
  //     if (
  //       httpResponse?.data !== null &&
  //       httpResponse?.data !== "" &&
  //       httpResponse?.data !== undefined
  //     ) {
  //       return true;
  //     }
  //   }
  //   return false;

  // };

  const checkAppointmentTimeStatus = async (prescriptionReferenceId) => {
    const httpResponse = await sendHttpRequest({
      url: `/appointment/${prescriptionReferenceId}/passed`,
      method: "GET",
    });
    if (!httpResponse.error) {
      setAppointmentPassed(httpResponse.data);
      // console.log(httpResponse.data);
      return httpResponse.data;

    } else {
      // console.log(httpResponse.error);
    }
  };

  const canProviderConnectToVideo = async (appId) => {
    // let appId = appointment?.id;
    const httpResponse = await search({
      method: 'GET',
      url: `/video/JoinRequestStatus/${appId}`
    });
    if (!httpResponse.error) {
      // console.log(httpResponse);
      if (httpResponse?.data !== undefined && httpResponse.data?.toLowerCase() === "end") {
        return false;
      }
      else if (httpResponse?.data !== undefined && (httpResponse.data?.toLowerCase() === "new" || httpResponse.data?.toLowerCase() === "leave" || httpResponse.data?.toLowerCase() === "waiting")) {
        return true
      }
    }
  }

  // useInterval(() => {
  //   fecthNotifications();
  // }, 15000);

  useEffect(() => { }, [joinPage]);

  // console.log("videoCallContext.appointmentObject", videoCallContext.appointmentObject?.data?.patientVisitType?.internalValue);
  useEffect(
    () => {
      async function videoCallInitCheck() {
        console.log(appointmentInformation);
        console.log(videoCallContext);
        console.log(appointmentInformation?.prescriptionReference?.prescriptionReferenceId);
        console.log(videoCallContext.appointmentData)
        if (videoCallContext?.appointmentObject?.data?.patientVisitType?.internalValue === 'in-patient') return;
        if (appointmentInformation?.prescriptionReference?.prescriptionReferenceId !== undefined && videoCallContext.appointmentData !== null) {
          console.log("Checking appointment status for video call....");
          console.log(videoCallContext.appointmentData);
          // const isRemoteUserInWaitingRoom = waitingRoomPolling(videoCallContext.appointmentData.data);
          if (videoCallContext.appointmentData.data === undefined || videoCallContext.appointmentData.data === null) return;
          const canProvConnect = await canProviderConnectToVideo(videoCallContext.appointmentData.data);
          const isTimePassed = await checkAppointmentTimeStatus(appointmentInformation?.prescriptionReference?.prescriptionReferenceId);
          console.log("Can Provider connect: ", canProvConnect);
          console.log("Is Time Passed: ", isTimePassed);
          if (canProvConnect === true && isTimePassed === false) {
            console.log('Dispatching...');
            console.log('Previous Values...', isVideoConsultationInProgress, isVideoContainerOpen);
            dispatch(setConsulationInProgress(true));
            dispatch(setVideoOpen(true));
          }

          return () => {
            intervalId.current = null;
          }

        }

      }
      videoCallInitCheck()
    }
    , [
      appointmentInformation?.prescriptionReference?.prescriptionReferenceId,
      // isVideoConsultationInProgress,
      videoCallContext.appointmentData?.data
    ]);

  // useEffect(() => {
  //   // fecthNotifications();
  //   return () => {
  //     setNotificationList(null);
  //   };
  // }, []);

  // useEffect(() => { }, [
  //   isVideoConsultationInProgress,
  //   useSelector(
  //     (state) => state.isConsultationInProgress.isConsultationInProgress
  //   ),
  // ]);

  return (
    <>

      <div className="row sticky-top" id="vb">
        {canConnect === true && appointmentPassed === false &&
          isVideoConsultationInProgress === true ? <div className="col-12">
          <AgoraVideoBar
            setEndConsultation={setEndConsultation}
            endConsultation={endConsultation}
            setMute={setMute}
            participantJoined={participantJoined}
            setParticipantJoined={setParticipantJoined}
          />
        </div> : null}
        <div className="col-12">
          <div
            className="row "
            style={{
              display: `${appointmentPassed === false &&
                joinPage === true &&
                isVideoConsultationInProgress === false
                ? "none"
                : "block"
                }`,
            }}
          >
            <div className={`col-12 `} style={{ zIndex: "1400" }}>
              <Navbar
                notificationList={notificationList}
                setLvd={setLvd}
                lvd={lvd}
                setLad={setLad}
                lad={lad}

              />
            </div>
          </div>
        </div>
      </div>
      {/* {console.log({
        canConnect: canConnect,
        appointmentPassed: appointmentPassed,
        isVideoConsultationInProgress: isVideoConsultationInProgress,
        isVideoContainerOpen: isVideoContainerOpen
      })} */}
      {canConnect === true && appointmentPassed === false &&
        isVideoConsultationInProgress === true ? (
        <AgoraMinimalVideoContainerV2
          // containerVisibility={}
          mute={mute}
          setMute={mute}
          setEndConsultation={setEndConsultation}
          participantJoined={participantJoined}
          setParticipantJoined={setParticipantJoined}
          endConsultation={endConsultation} />
      ) : null}



      <div className={classes.root}>
        {map.get("role") === "patient"
          ? !UrlMap(location.pathname) && <PatientDrawer />
          : !UrlMap(location.pathname) && (
            <Drawer
              display={`${appointmentPassed === false &&
                joinPage === true &&
                isVideoConsultationInProgress === false
                ? "none"
                : "block"
                }`}
            />
          )}
        <div className={`${classes.page} page`}>{children}</div>
      </div>

    </>
  );
}

import {
  Box,
  Grid,
  Typography,
  Skeleton,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import classes from "../patient/PatientItem.module.css";
import { useEffect, useState } from "react";
import "./TaskPage.css";

import sendHttpRequest from "../hooks/sendHttpRequest";
import { useParams } from "react-router-dom";
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";
import { useDispatch, useSelector } from "react-redux";
import TaskItem from "./TasksItem";
import SummaryModal from "../UI/Modals/ProviderModals/SummaryModal";
import AddendumModal from "../UI/Modals/ProviderModals/AddendumModal";
import ReviewRefillRequest from "../UI/Modals/ProviderModals/ReviewRefillRequest";
import { useNavigate } from "react-router-dom";
import {
  setDiagnosis,
  setDisableSendRxButton,
  setPatientEncounterData,
  setPrescriptionNoteText,
  setUpdateEncounterData,
} from "../../redux/actions/providerActions";
import { useAuth } from "../hooks/useAuth";
import { summaryGeneration } from "../../utils/helpers/DataLoaders";
import { addendumUrl, fetchRx, markAsNoShowUrl, providerWithNpiUrl } from "../../res/endpointurls";
import { useToggle } from "../hooks/useToggle";
import RenewalRxModal from "../renewalrx/RenewalRxModal";
import DenyRenewalModal from "../renewalrx/DenyRenewalModal";
import ReplaceRxModal from "../renewalrx/ReplaceRenewalRequestModal";
import { strings } from "../../res/strings";
import ChangeRxModal from "../changerx/ChangeRxModal";
import uuid from "react-uuid";
import DenyChangeRxModal from "../changerx/DenyChangeRxModal";
import { fieldValidation } from "../../utils/helpers/HelperFunctions";
import { RefreshDataComponent } from "../UI/General/RefreshDataComponent";
import taskClasses from "./TasksPage.module.css";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { featureFlagMap } from "../../utils/helpers/ConfigMap";
import RefillDenielModal from "../../patientportal/patientmodals/RefillDenielModal";
import PageHeader from "../../patientportal/generic/PageHeader";
import { RolesTextConversion } from "../../utils/helpers/RolesMap";
import { ENCOUNTERS } from "../../utils/helpers/subscriptionsConstants";

const featureFlags = featureFlagMap();

const TasksPage = (props) => {
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);

  const __str = strings.en;
  const { providerId, encounterId, patientId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingEnc, setIsLoadingEnc] = useState(true);
  const [isError, setIsError] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [isSent, setIsSent] = useState(false);
  const [statusCodeEncounters, setStatusCodeEncounters] = useState(null);
  const [statusFilter, setStatusFilter] = useState("all");
  const [typeFilter, setTypeFilter] = useState("all");
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [summary, setSummary] = useState({});
  const [addEncounterId, setAddEncounterId] = useState("");
  const [openAddenModal, setOpenAddenModal] = useState(false);
  const [addendumBody, setAddendumBody] = useState("");
  const [addendumTitle, setAddendumTitle] = useState("");
  const [openRefillReq, setOpenRefillReq] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [refillReqNote, setRrefillReqNote] = useState("");
  const [refillCount, setRefillsCount] = useState(1);
  const [requestCode, setRequestCode] = useState("");
  const [isUnknownPatient, setIsUnknownPatient] = useState(false);

  const [renewalRequestModal, setRenewalRequestModal] = useToggle(false);
  const [denyRenwalRequestModal, setDenyRenewalRequestModal] = useToggle(false);
  const [replaceRenewalRequestModal, setReplaceRenewalRequestModal] =
    useToggle(false);
  const [renewalRequestTask, setRenewalRequestTask] = useState(null);
  const [reloadTasks, setReloadTasks] = useState(false);

  const [changeRequestModal, setChangeRequestModal] = useToggle(false);
  const [changeRequestTask, setChangeRequestTask] = useState(null);
  const [changeRequestInformation, setChangeRequestInformation] =
    useState(null);
  const [changeRequestBackdropOpen, setChangeRequestBackdropOpen] =
    useState(false);
  const [denyChangeRequestModal, setDenyChangeRequestModal] = useToggle(false);
  const [patientObservations, setPatientObservations] = useState(undefined);

  const [showMessage, setShowMessage] = useState(false);
  const [showChangeRequestMessage, setShowChangeRequestMessage] =
    useState(false);
  const [isErrorMessage, setIsErrorMessage] = useState(false);
  const [isChangeRequestLoadingError, setIsChangeRequestLoadingError] =
    useState(false);
  const navigate = useNavigate();
  const { user, getSecondaryUser, selectUser } = useAuth();
  //Provider Dropdown code
  const dispatch = useDispatch();
  const [isSessionUpdated, setIsSessionUpdated] = useState(false);
  const [isNoShow, setIsNoShow] = useState(false);
  const [isPrescribersFetched, setisPrescribersFetched] = useState(false);

  //Pagination

  const [pages, setPages] = useState([]);
  const [pageNumberLimit, setPageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [maxPageLimit, setMaxPageLimit] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [totalResults, setTotalResults] = useState(undefined);

  const [prescriptionReferenceData, setPrescriptionReferenceData] = useState();
  const [openRefillDenyModal, setOpenRefillDenyModal] = useState(false);
  const [refillDenialReason, setRefillDenialReason] = useState();
  const [refillDenialNote, setRefillDenialNote] = useState();
  const [medicationRxId, setMedicationRxId] = useState();
  const [isPrescribersLoading, setisPrescribersLoading] = useState(false);
  const [prescribersList, setPrescribersList] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(getSecondaryUser() ?? user?.userId);

  const handleBackdropClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const fetchTasks = async () => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/tasks/assignee/${user?.userId}`,
      method: "GET",
      params: {
        tenantId: user?.tenantId,
        size: pageSize,
        page: currentPage - 1,
      },
    });
    if (!httpResponse.error) {
      let tempTask = httpResponse.data.taskList;
      console.log("tempTask: ", tempTask, httpResponse.data);

      setTotalResults(httpResponse.data.totalResults);
      if (props?.tasksOnly === true) {
        tempTask = tempTask.filter(function (item) {
          return (
            item.type === "change_request" || item.type === "renewal_request"
          );
        });
      }
      setTasks(tempTask);
      setIsLoading(false);
    } else {
      console.log(httpResponse.error);
      setIsLoading(false);
    }
    setStatusCodeEncounters(httpResponse.status);
  };

  const handleRefillModal = (prescriptionReference) => {
    // setAddEncounterId(enc_id);
    setPrescriptionReferenceData(prescriptionReference);
    setOpenRefillReq(!openRefillReq);
    // getRefillRequestData(enc_id);
  };

  const handleRefillModalClose = () => {
    setOpenRefillReq(false);
  };

  const filterTasks = async (statusFilter, typeFilter, currentPage, selectedProvider) => {
    setIsLoading(true);
    let httpResponse;
    if (!props.tasksOnly) {
      httpResponse = await sendHttpRequest({
        url: `/tasks/assignee/${selectedProvider ?? user?.userId}`,
        method: "GET",
        params: {
          status: statusFilter,
          type: typeFilter,
          tenantId: user?.tenantId,
          size: pageSize,
          page: currentPage !== undefined ? currentPage - 1 : 0,
        },
      });
    } else {
      props.setIsPrescriptionRequestsLoading(true);
      httpResponse = await sendHttpRequest({
        url: fetchRx(user?.userId),
        method: "GET",
        params: {
          status: "pending",
          tenantId: user?.tenantId,
        },
      });
      props.setIsPrescriptionRequestsLoading(false);
    }
    if (!httpResponse.error) {
      let tempTask = httpResponse.data.taskList;
      if (!props.tasksOnly) {
        let noOfItems = httpResponse.data.totalResults;
        console.log("tempTask: ", tempTask, httpResponse.data);
        setTotalResults(noOfItems);
        setCurrentItems(tempTask);
        setMaxPageLimit(Math.ceil(noOfItems / pageSize));
        let pagesList = [];
        for (let i = 1; i <= Math.ceil(noOfItems / pageSize); i++) {
          pagesList.push(i);
        }
        console.log("pagesList : ", pagesList);
        setPages(pagesList);
      }

      if (fieldValidation(tempTask, "all")) {
        if (props?.tasksOnly === true) {
          tempTask = tempTask?.filter(function (item) {
            return (
              item.type === "change_request" || item.type === "renewal_request"
            );
          });
        }
      }
      setTasks(tempTask);
      setIsLoading(false);
    } else {
      console.log(httpResponse.error);
      setIsLoading(false);
    }
    setStatusCodeEncounters(httpResponse.status);
  };

  const filterVeeOneTasks = async (userId, tenantId) => {
    setIsLoading(true);

    let httpResponse;

    props.setIsPrescriptionRequestsLoading(true);
    httpResponse = await sendHttpRequest({
      url: `/tasks/rx/${userId}?status=pending&tenantId=${tenantId}&type=renewal_request&type=change_request`,
      method: "GET",
    });
    props.setIsPrescriptionRequestsLoading(false);

    if (!httpResponse.error) {
      let tempTask = httpResponse.data.taskList;

      // if (fieldValidation(tempTask, "all")) {
      //   if (props?.tasksOnly === true) {
      //     tempTask = tempTask?.filter(function (item) {
      //       return (
      //         item.type === "change_request" || item.type === "renewal_request"
      //       );
      //     });
      //   }
      // }
      setTasks(tempTask);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
    setStatusCodeEncounters(httpResponse.status);
  };

  const fetchPatientSummary = async (encId) => {
    const data = await summaryGeneration(encId);
    await setSummary(data);
    handleBackdropClose();
    setOpenModal(!openModal);
    setIsLoadingEnc(false);
  };


  const fetchAllPrescribers = async () => {
    setisPrescribersLoading(true);
    const httpResponse = await sendHttpRequest({
      url: providerWithNpiUrl(user?.tenantId),
      method: "GET",
    });
    if (!httpResponse.error) {
      setPrescribersList(httpResponse.data);
    } else {
      setPrescribersList([]);
    }
    setisPrescribersLoading(false);
  };



  function handleModal(encId) {
    if (encId !== "") {
      handleToggle();
      fetchPatientSummary(encId);
    }
  }

  function handleSumModal() {
    setOpenModal(!openModal);
  }

  function handleAddenModal(encId) {
    // console.log("asdasdasdasdasdas");
    if (encId !== "") {
      setAddEncounterId(encId);
      setOpenModal(false);
      setOpenAddenModal(!openAddenModal);
    }
  }

  const addAddendum = async () => {
    const httpResponse = await sendHttpRequest({
      url: addendumUrl(addEncounterId),
      method: "POST",
      data: {
        addendum: addendumBody,
        title: addendumTitle,
      },
    });
    if (!httpResponse.error) {
      //   emptyStates();
      setIsSent(true);
    } else {
      console.log(
        `Error in ${addendumUrl(addEncounterId)}`,
        httpResponse?.error,
        " m = ",
        httpResponse?.errorMessage?.error
      );
    }
  };

  const approveRefillRequest = async (event) => {
    setIsSubmit(true);
    const httpResponse = await sendHttpRequest({
      url: `/rx/medications/${event.target.id}/refill-response`,
      method: "POST",
      data: {
        completedBy: {
          userId: user?.userId,
        },
        note: refillReqNote ?? "",
        refills: refillCount,
      },
    });
    if (!httpResponse.error) {
      handleRefillModalClose();
      fetchTasks();
      setIsSent(true);
      // emptyStates();
      // setMedicationData(httpResponse.data);
      // console.log(httpResponse.data);
    }
    setIsSubmit(false);
  };

  const refillDenialRequest = async () => {
    setIsSubmit(true);
    const httpResponse = await sendHttpRequest({
      url: `/rx/medications/${medicationRxId}/refill-response`,
      method: "POST",
      data: {
        completedBy: {
          userId: user?.userId,
        },
        denyReason: refillDenialReason,
        note: refillDenialNote,
      },
    });

    if (!httpResponse.error) {
      setRefillDenialReason("")
      setRefillDenialNote("")
      setMedicationRxId(undefined);
      setOpenRefillDenyModal(false);
      fetchTasks();
    }

    setIsSubmit(false);
  };

  const refillRequestStatus = async (isApproved, id) => {
    setIsSubmit(true);
    const httpResponse = await sendHttpRequest({
      url: "/renewalRequest/action",
      method: "POST",
      data: {
        RenewalRequestId: id,
        IsAccepted: isApproved,
        Note: refillReqNote,
        TotalRefills: refillCount,
      },
    });
    if (!httpResponse.error) {
      handleRefillModalClose();
      fetchTasks();
      setIsSent(true);
      // emptyStates();
      // setMedicationData(httpResponse.data);
      // console.log(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
    setIsSubmit(false);
  };

  const refillRequestStatusHandler = (event) => {
    setMedicationRxId(event.target.id)
    handleRefillModalClose();
    refillRequestStatus(event.target.value, event.target.id);
    setOpenRefillDenyModal(true);
  };
  const handleRefillDenyModal = () => {
    setOpenRefillDenyModal(false);
  };

  const handlePatientEncouterData = async (enc_id, ptnt_id) => {
    handleToggle();
    const httpResponse = await sendHttpRequest({
      url: `/prescription-reference/${enc_id}`,
      method: "GET",
    });
    if (!httpResponse.error) {
      setPatientEncouterData(httpResponse.data, ptnt_id);
    } else {
      console.log(httpResponse.error);
    }
    // setIsLoadingEnc(true);
  };

  const markApptAsNoShow = async (taskId) => {
    const httpResponse = await sendHttpRequest({
      url: markAsNoShowUrl(taskId),
      method: "GET",
    });
    if (!httpResponse.error) {
      setIsNoShow(true);
      filterTasks(statusFilter, typeFilter, currentPage);
    } else {
      console.log(httpResponse.error);
    }
  };

  //code is copied from encouter file
  const setPatientEncouterData = (prescriptionReference, ptnt_id) => {

    let encounter = prescriptionReference?.encounter;

    if (encounter?.encounterNoteType !== "SOAP_NOTE") {
      if (encounter?.RxNote !== undefined) {
        dispatch(setPrescriptionNoteText(encounter?.RxNote));
      } else {
        dispatch(setPrescriptionNoteText(""));
      }
      // console.log("encounter?.Diagnosis",encounter?.Diagnosis);
      let diagnosis = {
        Note: "",
        Secondary: [],
        Primary: [],
      };
      if (
        encounter?.Diagnosis?.Note !== undefined &&
        encounter?.Diagnosis?.Note !== null
      ) {
        diagnosis.Note = encounter?.Diagnosis?.Note;
      }
      if (
        encounter?.Diagnosis?.Secondary !== undefined &&
        encounter?.Diagnosis?.Secondary?.length > 0
      ) {
        diagnosis.Secondary = encounter?.Diagnosis?.Secondary;
      }
      if (encounter?.Diagnosis?.Primary !== undefined) {
        if (Object.keys(encounter?.Diagnosis?.Primary).length !== 0) {
          diagnosis.Primary = [encounter?.Diagnosis?.Primary];
        }
      }
      dispatch(setUpdateEncounterData({ Diagnosis: diagnosis }));
      dispatch(setDiagnosis(diagnosis));
      dispatch(setPatientEncounterData({ Encounter: encounter }));
      if (encounter.Status === "DISPATCHED") {
        dispatch(setDisableSendRxButton(true));
      } else {
        dispatch(setDisableSendRxButton(false));
      }
      handleBackdropClose();

      navigate(
        `/consult/${prescriptionReference?.providerTenantPracticeLocation?.provider?.userId}/${prescriptionReference?.prescriptionReferenceId}/${ptnt_id}`
      );
    } else {
      navigate(
        `/all-patients/${prescriptionReference?.patient?.userId}/soap-note`,
        {
          state: {
            // isResume: true,
            isResume: prescriptionReference?.soapNote !== undefined ? true : false,
            prescriptionReferenceId:
              prescriptionReference?.prescriptionReferenceId,
            appointmentId: prescriptionReference?.appointment?.id
          },
          replace: false,
        }
      );
    }

    // dispatch(setDiagnosis(diagnosis));
    // dispatch(setVideoCallState(false));
  };

  const saveRefillsCount = (refill_count) => {
    setRefillsCount(refill_count);
  };

  const saveRefillsNote = (refill_note) => {
    setRrefillReqNote(refill_note);
  };

  const handleTaskTypeFilter = (event) => {
    setTypeFilter(event.target.value);
    filterTasks(statusFilter, event.target.value, currentPage, selectedProvider);
  };

  const handleTaskStatusFilter = (event) => {
    setStatusFilter(event.target.value);
    filterTasks(event.target.value, typeFilter, currentPage, selectedProvider);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSent(false);
    setIsNoShow(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsErrorMessage(false);
  };

  const handleChangeRequestLoadingErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsChangeRequestLoadingError(false);
  };
  const handleRenewalMessageClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowMessage(false);
  };

  const handleChangeMessageClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowChangeRequestMessage(false);
  };

  const handleRenewalTask = (renewalRxTask) => {
    setRenewalRequestTask(renewalRxTask);
  };

  const handleChangeTask = (changeRxTask) => {
    setChangeRequestTask(changeRxTask);
  };

  const handleChangeRxTask = async (changeRxTask, tid) => {
    const reqConfig = {
      url: `/tasks/${tid}`,
      method: "GET",
    };
    setChangeRequestBackdropOpen(true);
    const httpResponse = await sendHttpRequest(reqConfig);
    if (!httpResponse.error) {
      setChangeRequestInformation(httpResponse.data);
      setRequestCode(httpResponse.data?.rxRequest?.requestCode);
      setPatientObservations(
        httpResponse.data?.rxRequest?.vital !== undefined &&
          httpResponse.data?.rxRequest?.vital !== null
          ? httpResponse.data?.rxRequest?.vital
          : null
      );
    }
    setChangeRequestBackdropOpen(false);
    setIsUnknownPatient(
      Number(changeRxTask?.prescriptionReference?.patient?.userId) === 0
        ? true
        : false
    );
    setChangeRequestTask(changeRxTask);

    return httpResponse;
  };

  // const getPatientObservations = async (patientId) => {
  //     const httpResponse = await sendHttpRequest({
  //         url: fetchPatientObservationsURL(patientId),
  //         method: "GET",
  //     });
  //     if (!httpResponse.error) {
  //         setPatientObservations(httpResponse.data);
  //     } else {
  //         // console.log(httpResponse);
  //     }
  // }

  const handleDenyRequestModal = (renewalRxTask) => {
    setRenewalRequestTask(renewalRxTask);
  };

  if (isSessionUpdated === true) {
    fetchTasks();
    setIsSessionUpdated(false);
  }

  // Pagination handlers
  const RenderPages = () => {
    const [cPage, setCPage] = useState(currentPage);
    useEffect(() => { setCPage(currentPage) }, [currentPage])
    return (
      <ul className={`${taskClasses["pageNumbers"]}`}>
        {pages?.length > 1 &&
          pages?.map((number) => {
            // if(number > maxPageNumberLimit){
            //   console.log(number,maxPageNumberLimit + 1,minPageNumberLimit,number < maxPageNumberLimit + 1 ,
            //     number > minPageNumberLimit);
            // }
            if (
              number < maxPageNumberLimit + 1 &&
              number > minPageNumberLimit
            ) {
              return (
                <li
                  key={number}
                  id={number}
                  className={`${taskClasses["list__item"]} ${cPage === number
                    ? taskClasses["active__list__item"]
                    : ""
                    }`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleCurrentPage(number);
                  }}
                >
                  {/* {number >= 5 && pages.length > 5 && number !== pages.length ? `${number}   ...${pages.length}` : number} */}
                  {number}
                </li>
              );
            } else {
              return null;
            }
          })}
      </ul>
    );
  };

  const handleCurrentPage = (pNumber) => {
    setCurrentPage(pNumber);
    filterTasks(statusFilter, typeFilter, pNumber, selectedProvider);

    // let inputValue = document.getElementById("searchPharmacy").value;
    // searchPharmacy(inputValue, searchFilter, pNumber,proximity);
  };

  const handleNextButton = (event) => {
    event.preventDefault();
    // let inputValue = document.getElementById("searchPharmacy").value;
    setCurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
    filterTasks(statusFilter, typeFilter, currentPage + 1, selectedProvider);
    // searchPharmacy(inputValue, searchFilter, currentPage + 1,proximity);
  };
  const handlePreButton = (event) => {
    event.preventDefault();
    // let inputValue = document.getElementById("searchPharmacy").value;
    // setPageNumber(pageNumber - 1);
    if (currentPage - 1 >= 1) {
      setCurrentPage(currentPage - 1);
      //   searchPharmacy(inputValue, searchFilter, currentPage - 1,proximity);
      filterTasks(statusFilter, typeFilter, currentPage - 1, selectedProvider);
    }
    if ((currentPage - 1) % pageNumberLimit == 0) {
      if (minPageNumberLimit - pageNumberLimit < 0) {
        setMaxPageNumberLimit(5);
        setMinPageNumberLimit(0);
      } else {
        setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
        setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
      }
    }
    // searchPharmacy(inputValue, "Retail,MailOrder", currentPage - 1);
  };

  const clearState = () => {
    setIsLoading(true);
    setIsLoadingEnc(true);
    setIsError(true);
    setTasks([]);
    setIsSent(false);
    setStatusCodeEncounters(null);
    setStatusFilter("all");
    setTypeFilter("all");
    setOpen(false);
    setOpenModal(false);
    setSummary({});
    setAddEncounterId("");
    setOpenAddenModal(false);
    setAddendumBody("");
    setAddendumTitle("");
    setOpenRefillReq(false);
    setIsSubmit(false);
    setRrefillReqNote("");
    setRefillsCount(1);
    setIsSessionUpdated(false);
    setisPrescribersFetched(false);
    setCurrentPage(1);
  };
  const filterTasksByProvider = (providerId) => {
    selectUser(providerId)
    setSelectedProvider(providerId)
    if (props?.veeone === undefined || props?.veeone === false) {

      filterTasks(statusFilter || "all", typeFilter || "all", 1, providerId); // if not present send the specified values
    }
    else {
      filterVeeOneTasks(providerId, props?.tenantId);
    }
  }

  useEffect(() => {
    fetchAllPrescribers();
    if (props?.veeone === undefined || props?.veeone === false) {

      filterTasks(statusFilter || "all", typeFilter || "all"); // if not present send the specified values
    }
    else {
      filterVeeOneTasks(props?.userId, props?.tenantId);
    }
    return () => {
      clearState();
    };
  }, [reloadTasks, props.prescriptionRequestReload]);

  return (
    <>
      {renewalRequestModal ? (
        <RenewalRxModal
          show={renewalRequestModal}
          showError={setIsErrorMessage}
          showMessage={setShowMessage}
          handleReplaceRenewalRequest={setReplaceRenewalRequestModal}
          reloadTasks={setReloadTasks}
          handleModal={setRenewalRequestModal}
          handleDenyRequestModal={setDenyRenewalRequestModal}
          renewalTask={renewalRequestTask}
        />
      ) : null}
      {denyRenwalRequestModal ? (
        <DenyRenewalModal
          show={denyRenwalRequestModal}
          showError={setIsErrorMessage}
          handleRenewalModal={setRenewalRequestModal}
          reloadTasks={setReloadTasks}
          renewalTask={renewalRequestTask}
          handleModal={setDenyRenewalRequestModal}
          handleRenewalTask={handleRenewalTask}
          showMessage={setShowMessage}
        />
      ) : null}
      {replaceRenewalRequestModal ? (
        <ReplaceRxModal
          show={replaceRenewalRequestModal}
          renewalTask={renewalRequestTask}
          showMessage={setShowMessage}
          showError={setIsErrorMessage}
          reloadTasks={setReloadTasks}
          handleModal={setReplaceRenewalRequestModal}
        />
      ) : null}

      {changeRequestModal ? (
        <ChangeRxModal
          show={changeRequestModal}
          showError={setIsErrorMessage}
          showMessage={setShowChangeRequestMessage}
          reloadTasks={setReloadTasks}
          handleModal={setChangeRequestModal}
          handleDenyRequestModal={setDenyChangeRequestModal}
          patientObservations={patientObservations}
          taskDetails={changeRequestInformation}
          requestCode={requestCode}
          isUnknownPatient={isUnknownPatient}
          changeRequestTask={changeRequestTask}
        />
      ) : null}

      {denyChangeRequestModal ? (
        <DenyChangeRxModal
          show={denyChangeRequestModal}
          showError={setIsErrorMessage}
          handleChangeRxModal={setChangeRequestModal}
          handleChangeTask={handleChangeTask}
          reloadTasks={setReloadTasks}
          changeRequestTask={changeRequestTask}
          handleModal={setDenyChangeRequestModal}
          showMessage={setShowChangeRequestMessage}
        />
      ) : null}
      {openRefillReq && (
        <ReviewRefillRequest
          show={openRefillReq}
          isSubmit={isSubmit}
          addEncounterId={addEncounterId}
          saveRefillsNote={saveRefillsNote}
          handleRefillModal={handleRefillModalClose}
          refillRequestStatus={refillRequestStatus}
          refillRequestStatusHandler={refillRequestStatusHandler}
          handleToggle={handleToggle}
          handleBackdropClose={handleBackdropClose}
          saveRefillsCount={saveRefillsCount}
          prescriptionReferenceData={prescriptionReferenceData}
          approveRefillRequest={approveRefillRequest}
        />
      )}
      {!isLoadingEnc && (
        <SummaryModal
          summary={summary}
          handleAddenModal={handleAddenModal}
          show={openModal}
          handleModal={handleSumModal}
        />
      )}
      <AddendumModal
        addAddendum={addAddendum}
        setAddendumBody={setAddendumBody}
        setAddendumTitle={setAddendumTitle}
        show={openAddenModal}
        handleAddenModal={handleAddenModal}
      />

      {openRefillDenyModal ? (
        <RefillDenielModal
          show={openRefillDenyModal}
          handleModal={handleRefillDenyModal}
          setRefillDenialReason={setRefillDenialReason}
          setRefillDenialNote={setRefillDenialNote}
          refillDenialRequest={refillDenialRequest}
        />
      ) : null}

      {!props.tasksOnly ? (
        <PageHeader title={"Tasks"} />
      ) : null}
      <div>
        {/* <Button onClick={handleToggle}>Show backdrop</Button> */}
        <Backdrop
          sx={{ color: "#EC609B", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <div className={`row ${props.tasksOnly ? "p-0" : "p-5"} m-0 `}>
        {!props.tasksOnly ? (
          <>
            <div className="col-3  offset-4 pb-1">
              {isPrescribersLoading === true ? (
                <Skeleton
                  animation="wave"
                  variant="text"
                  width={"70%"}
                  height={40}
                />
              ) : (
                <div>
                  <select
                    className={`form-select br-10 font-14 shadow-none ${classes["custom-select"]}`}
                    aria-label="Provider select"
                    style={{
                      border: "1px solid #D9E8EC",
                      width: "20vw",
                    }}
                    onChange={(e) => filterTasksByProvider(e.target.value)}
                  // value={()=>getSecondaryUser()}
                  >
                    <option value="" disabled selected hidden>
                      Select provider
                    </option>
                    {prescribersList?.length > 0
                      ? prescribersList?.map((prescriberItem) => {
                        return (
                          <option
                            value={prescriberItem?.userId ?? ""}
                            key={prescriberItem?.userId ?? ""}
                            selected={
                              getSecondaryUser() == prescriberItem?.userId
                            }
                          >
                            {prescriberItem?.name[0]?.prefix}{" "}
                            {prescriberItem?.name[0]?.firstName}{" "}
                            {prescriberItem?.name[0]?.lastName}{" "}
                            {RolesTextConversion(prescriberItem?.role) !==
                              undefined
                              ? "(" +
                              RolesTextConversion(prescriberItem?.role) +
                              ")"
                              : ""}
                          </option>
                        );
                      })
                      : "No prescribers available"}
                  </select>
                </div>
              )}
            </div>
            {/* <div className="col-1">&nbsp;</div> */}
            <div className="col-5 text--terciary  pb-1 ">
              <div className="row">
                <div className="col-4  align-self-center text-end">
                  <RefreshDataComponent
                    callback={() =>
                      filterTasks(statusFilter, typeFilter, currentPage)
                    }
                    isComponentDataLoading={isLoading}
                    variant="without-bar"
                  />
                </div>
                <div className="col-4   text--terciary">
                  <select
                    className={`form-select br-10 font-12 shadow-none ${classes["custom-select"]}`}
                    aria-label="Provider select"
                    style={{
                      border: "1px solid #D9E8EC",
                      width: "10vw",
                      height: "36px",
                      color: "#336383",
                      fontWeight: 600,
                    }}
                    onChange={(e) => handleTaskStatusFilter(e)}
                  >
                    <option value={"all"} key={1}>
                      {"All Statuses"}
                    </option>
                    <option value={"pending"} key={2}>
                      {"Pending"}
                    </option>
                    <option value={"completed"} key={3}>
                      {"Completed"}
                    </option>
                    <option value={"expired"} key={4}>
                      {"Expired"}
                    </option>
                    {/* <option
                                                value={"all"}
                                                key={4}
                
                                            >
                                                {"All"}
                                            </option> */}
                    {/* ); */}
                    {/* }) : "No prescribers available"} */}
                  </select>
                </div>
                <div className="col-4  text--secondary fw-sb">
                  <select
                    className={`form-select br-10 font-12 shadow-none ${classes["custom-select"]}`}
                    aria-label="task type select"
                    style={{
                      border: "1px solid #D9E8EC",
                      width: "10vw",
                      height: "36px",
                      color: "#336383",
                      fontWeight: 600,
                    }}
                    onChange={(e) => handleTaskTypeFilter(e)}
                  >
                    <option value={"all"} key={1}>
                      All Tasks
                    </option>
                    {featureFlags.get("surescript") === "true" ? (
                      <option value={"cancel_request"} key={5}>
                        Cancel Requests
                      </option>
                    ) : null}
                    {featureFlags.get("surescript") === "true" ? (
                      <option value={"change_request"} key={6}>
                        Change Requests
                      </option>
                    ) : null}
                    <option value={"encounter"} key={2}>
                      Pending Encounters
                    </option>
                    <option value={"document_review"} key={6}>
                      Document Review
                    </option>
                    {featureFlags.get("surescript") === "true" ? (
                      <option
                        value={"refill_request"}
                        key={3}
                      // selected={cookies.providerId == prescriberItem?.NonVeterinarian?.id}
                      >
                        Refill Requests
                      </option>
                    ) : null}
                    {featureFlags.get("surescript") === "true" ? (
                      <option value={"renewal_request"} key={4}>
                        Renewal Requests
                      </option>
                    ) : null}
                  </select>
                </div>
              </div>
            </div>

            {/* </div> */}
            <Grid
              container
              sx={{ marginBottom: "-8px" }}
              p={1}
              className={` bg-white ${classes["row__head"]} ${tasks?.length === 0 ? classes["border--bottom"] : ""
                }`}
            >
              <Grid item xs={1.2} sm={1.2} md={1.2}>
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      m: 1,
                      fontFamily: "Montserrat",
                      fontWeight: "400",
                      fontSize: "12px",
                      color: "#336383",
                    }}
                  >
                    Date &amp; Time
                  </Box>
                </Typography>
                {/* </Stack> */}
              </Grid>
              <Grid item xs={1.5} sm={1.5}>
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      mt: 1,
                      mr: 4,
                      fontFamily: "Montserrat",
                      fontWeight: "400",
                      fontSize: "12px",
                      color: "#336383",
                    }}
                  >
                    Provider
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={1.5}>
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      mt: 1,
                      ml: 2,
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      color: "#336383",
                    }}
                  >
                    Category
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={1.8}>
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      marginTop: "8px",
                      ml: 2,
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      color: "#336383",
                    }}
                  >
                    Patient
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={3.7}>
                <Typography component="div">
                  <Box
                    sx={{
                      textAlign: "left",
                      mt: 1,
                      // ml: 2,
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      color: "#336383",
                    }}
                  >
                    Highlights
                  </Box>
                </Typography>
              </Grid>

              <Grid item xs={2}></Grid>
            </Grid>
          </>
        ) : null}
        {isLoading ? (
          <Skeleton animation="wave" />
        ) : tasks?.length > 0 ? (
          tasks?.map((task, index) => {
            if (index === tasks?.length - 1) {
              return (
                <TaskItem
                  key={uuid()}
                  task={task}
                  veeone={props?.veeone}
                  isLast={true}
                  isEven={index % 2 === 0 ? true : false}
                  handleRenewalModal={setRenewalRequestModal}
                  handleChangeRequestModal={setChangeRequestModal}
                  handleRenewalTask={handleRenewalTask}
                  handleChangeRxTask={handleChangeRxTask}
                  handlePatientEncouterData={handlePatientEncouterData}
                  handleSummaryModal={handleModal}
                  handleRefillModal={handleRefillModal}
                  showTaskLoadFailedError={setIsChangeRequestLoadingError}
                  // getPatientObservations={getPatientObservations}
                  tasksOnly={props.tasksOnly}
                  markApptAsNoShow={markApptAsNoShow}
                  hidden={
                    task?.status.toLowerCase() === "pending"
                      ? subscriptionPrivilegeGroups[ENCOUNTERS]?.vpe?.active === false
                        ? true
                        : false
                      : false
                  }
                  setReloadTasks={setReloadTasks}
                />
              );
            } else {
              return (
                <TaskItem
                  key={uuid()}
                  isFirst={index === 0 ? true : false}
                  task={task}
                  tasksOnly={props.tasksOnly}
                  veeone={props?.veeone}
                  isEven={index % 2 === 0 ? true : false}
                  handleRenewalModal={setRenewalRequestModal}
                  handleChangeRequestModal={setChangeRequestModal}
                  handleRenewalTask={handleRenewalTask}
                  handleChangeRxTask={handleChangeRxTask}
                  handlePatientEncouterData={handlePatientEncouterData}
                  handleSummaryModal={handleModal}
                  handleRefillModal={handleRefillModal}
                  // getPatientObservations={getPatientObservations}
                  showTaskLoadFailedError={setIsChangeRequestLoadingError}
                  markApptAsNoShow={markApptAsNoShow}
                  hidden={
                    task?.status === "pending"
                      ? subscriptionPrivilegeGroups[ENCOUNTERS]?.vpe?.active === false
                        ? true
                        : false
                      : false
                  }
                  setReloadTasks={setReloadTasks}
                />
              );
            }
          })
        ) : (
          <div className="text--terciary font-14 p-2">
            {props?.tasksOnly
              ? __str.noPrescriptionAvailable
              : __str?.noDataAvailable}
          </div>
        )}
      </div>
      <div className="container-fluid px-4">
        <div className="row justify-content-end me-1">
          {/* <div className="col-4"></div>
            <div className="col-4"></div> */}
          <div className="col-3  ">
            {isLoading ? (
              <Skeleton />
            ) : (
              <div className={"ps-4 d-flex flex-row justify-content-between"}>
                {pages.length > 1 ? (
                  <div className="ps-2 mt-2">
                    <button
                      disabled={currentPage === 1}
                      onClick={(e) => {
                        handlePreButton(e);
                      }}
                      className={`${taskClasses["prev__btn"]} bg--fafcfc border--default br-10`}
                    >
                      <NavigateBeforeIcon style={{ color: "#ec609b" }} />
                      {/* <img src={IconNext} alt="icon-previous"></img> */}
                    </button>
                  </div>
                ) : (
                  ""
                )}

                <RenderPages />

                {pages.length > 1 ? (
                  <div className="mt-2">
                    <button
                      disabled={currentPage === pages.length}
                      onClick={(e) => {
                        handleNextButton(e);
                      }}
                      className={`${taskClasses["next__btn"]} me-2 bg--fafcfc border--default br-10`}
                    >
                      {/* <img src={IconNext} alt="icon-next"></img> */}
                      <NavigateNextIcon style={{ color: "#ec609b" }} />
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <CustomizedSnackbars
        message={"Appointment marked as no show"}
        severity="success"
        open={isNoShow}
        handleClose={handleClose}
      />

      <CustomizedSnackbars
        message={"Refill request response sent"}
        severity="success"
        open={isSent}
        handleClose={handleClose}
      />

      <CustomizedSnackbars
        message={"Error in processing request. Please try again."}
        severity="error"
        open={isErrorMessage}
        handleClose={handleSnackbarClose}
      />

      <CustomizedSnackbars
        message={"Error in loading Change Request. Please try again."}
        severity="error"
        open={isChangeRequestLoadingError}
        handleClose={handleChangeRequestLoadingErrorClose}
      />

      <Backdrop
        sx={{
          color: "#EC609B",
          zIndex: (theme) => theme.zIndex.drawer + 1000,
        }}
        open={changeRequestBackdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {showMessage ? (
        <CustomizedSnackbars
          message={"Response to renewal request sent to pharmacy"}
          severity="success"
          open={showMessage}
          handleClose={handleRenewalMessageClose}
        />
      ) : null}

      {showChangeRequestMessage ? (
        <CustomizedSnackbars
          message={"Response to change request sent to pharmacy"}
          severity="success"
          open={showChangeRequestMessage}
          handleClose={handleChangeMessageClose}
        />
      ) : null}

      {/* {console.log(
        "featureFlags.get('surescript'): ",
        featureFlags.get("surescript"),
        typeof featureFlags.get("surescript")
      )} */}
    </>
  );
};

export default TasksPage;

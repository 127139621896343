import { useCallback, useEffect, useState } from "react";
import PageHeader from "../../../../components/UI/General/PageHeader";
import sendHttpRequest from "../../../../components/hooks/sendHttpRequest";
import { useAuth } from "../../../../components/hooks/useAuth";
import { Skeleton } from "@mui/material";
import AllClaimsTable from "../tables/AllClaimsTable";
import { PnSelect } from "../../../../components/UI/PnSelect";
import { RefreshDataComponent } from "../../../../components/UI/General/RefreshDataComponent";
import CalenderDatePicker from "../../../../components/UI/General/CalenderDatePicker";
import { useLocation } from "react-router-dom";

const CLAIM_STATUS_OPTIONS = [
  { value: "CLAIM_READY_FOR_SUBMISSION", label: "Claim Ready for Submission" },
  { value: "CLAIM_SUBMITTED", label: "Claim Submitted" },
  { value: "CLAIM_APPROVED", label: "Claim Approved" },
  { value: "CLAIM_SUBMISSION_ERROR", label: "Claim Submission Error" },
  { value: "CLAIM_DENIED", label: "Claim Denied" },
];

function AllClaimsPage(props) {
  const { user } = useAuth();
  const location = useLocation();

  const [renderingProvidersList, setRenderingProvidersList] = useState([]);
  const [areRenderingProvidersLoading, setAreRenderingProvidersLoading] =
    useState(false);

  const [selectedProvider, setSelectedProvider] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const [patientsList, setPatientsList] = useState([]);
  const [arePatientsLoading, setArePatientsLoading] = useState(false);

  const [claims, setClaims] = useState([]);
  const [areClaimsLoading, setAreClaimsLoading] = useState(false);

  const [reloadClaims, setReloadClaims] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState(null);
  const [claimNumber, setClaimNumber] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const fetchReneringProviders = async () => {
    setAreRenderingProvidersLoading(true);
    const httpResponse = await sendHttpRequest({
      // url: providerWithNpiUrl(user?.tenantId),
      url: `/claims/tenants/${user?.tenantId}/providers`,
      method: "GET",
    });
    if (!httpResponse.error) {
      const renderingProviders = httpResponse.data;
      const renderingProviderOptions = renderingProviders?.map((provider) => ({
        value: provider?.providerId,
        label: `${provider?.prefix} ${provider?.firstName} ${provider?.lastName}`,
        provider: provider,
      }));
      setRenderingProvidersList(renderingProviderOptions);
    } else {
      setRenderingProvidersList([]);
      console.log(httpResponse.error);
    }
    setAreRenderingProvidersLoading(false);
  };

  const fetchClaimPatients = async () => {
    setArePatientsLoading(true);
    const httpResponse = await sendHttpRequest({
      // url: providerWithNpiUrl(user?.tenantId),
      url: `/claims/tenants/${user?.tenantId}/patients`,
      method: "GET",
    });
    if (!httpResponse.error) {
      const claimPatients = httpResponse.data;
      const patientDropdownOptions = claimPatients?.map((patient) => ({
        value: patient?.patientId,
        label: `${patient?.firstName} ${patient?.lastName}`,
        patient: patient,
      }));
      setPatientsList(patientDropdownOptions);
    } else {
      setPatientsList([]);
      console.log(httpResponse.error);
    }
    setArePatientsLoading(false);
  };

  const fetchAllClaims = async (
    tenantId,
    claimNumber,
    provider,
    patient,
    status,
    fromDate,
    toDate
  ) => {
    // console.log({ provider, patient, status });
    setAreClaimsLoading(true);
    const httpResponse = await sendHttpRequest({
      method: "GET",
      url: `/claims/tenants/${tenantId}/insurance-claims`,
      params: {
        ...(claimNumber !== "" && { claimNumber: claimNumber }),
        providerId: provider?.value,
        patientId: patient?.value,
        claimStatus: status?.value,
        ...(fromDate?.length > 0 && { fromDate: fromDate }),
        ...(toDate?.length > 0 && { toDate: toDate }),
      },
    });
    if (!httpResponse.error) {
      setClaims(httpResponse.data);
    } else {
      console.error(`Error in fetching claims for tenant ${tenantId}`);
    }
    setAreClaimsLoading(false);
  };

  function handleProviderId(selectedOption) {
    setSelectedProvider(selectedOption);
    // props?.setEncounterDto((dto) => ({ ...dto, "claimProviderDataId": event.target.value, "provider": { "providerId": event.target.value } }))
  }

  function handlePatientId(option) {
    setSelectedPatient(option);
  }

  function handleClaimNumber(event) {
    setClaimNumber(event.target.value);
  }

  function handleClaimStatus(option) {
    setSelectedStatus(option);
  }

  const handleFilter = useCallback(async () => {
    fetchAllClaims(
      user?.tenantId,
      claimNumber,
      selectedProvider,
      selectedPatient,
      selectedStatus,
      fromDate,
      toDate
    );
  }, [
    user?.tenantId,
    claimNumber,
    selectedProvider,
    selectedPatient,
    selectedStatus,
    fromDate,
    toDate,
    fetchAllClaims,
  ]);

  const handleClearFilter = useCallback(async () => {
    setClaimNumber("");
    setSelectedPatient(null);
    setSelectedProvider(null);
    setSelectedStatus(null);
    setFromDate(null);
    setToDate(null);
    fetchAllClaims(user?.tenantId, "", null, null, null, null, null);
  }, [
    user?.tenantId,
    claimNumber,
    selectedProvider,
    selectedPatient,
    selectedStatus,
    fromDate,
    toDate,
    fetchAllClaims,
  ]);

  const handleFromDate = (dateValue) => {
    setFromDate(dateValue);
  };
  const handleToDate = (dateValue) => {
    setToDate(dateValue);
  };
  useEffect(() => {
    fetchReneringProviders();
    fetchClaimPatients();
  }, []);

  useEffect(() => {
    setFromDate(location?.state?.startDate || null);
    setToDate(location?.state?.endDate || null);
    fetchAllClaims(
      user?.tenantId,
      claimNumber,
      selectedProvider,
      selectedPatient,
      selectedStatus,
      location?.state?.startDate,
      location?.state?.endDate
    );
  }, [reloadClaims]);
  return (
    <>
      <PageHeader title="Claims" />
      <div className="container">
        <div className="row justify-content-center py-2 mb-1">
          <div className="col-sm-11">
            <div className="row">
              <div className="col-sm-2">
                <label className="text--secondary font-12 b2 fw-sb">From</label>
                {fromDate !== null ? (
                  <CalenderDatePicker
                    f12={true}
                    dobHandler={handleFromDate}
                    dateFormat="MMM d, yyyy"
                    date={fromDate !== null ? fromDate : undefined}
                    //   viewSchedule={true}
                    //   required
                    key={"FromDate"}
                  />
                ) : (
                  <CalenderDatePicker
                    f12={true}
                    dobHandler={handleFromDate}
                    dateFormat="MMM d, yyyy"
                    date={""}
                    key={"FromDateNew"}
                  />
                )}
              </div>
              <div className="col-sm-2">
                <label className="text--secondary font-12 b2 fw-sb">To</label>
                {toDate !== null ? (
                  <CalenderDatePicker
                    f12={true}
                    dobHandler={handleToDate}
                    dateFormat="MMM d, yyyy"
                    date={toDate !== null ? toDate : undefined}
                    //   viewSchedule={true}
                    //   required
                    key={"ToDateNew"}
                  />
                ) : (
                  <CalenderDatePicker
                    f12={true}
                    dobHandler={handleToDate}
                    dateFormat="MMM d, yyyy"
                    date={""}
                    //   viewSchedule={true}
                    //   required
                    key={"ToDate"}
                  />
                )}
              </div>
            </div>
            
          </div>
        </div>
        <div className="row justify-content-center mt-2">
          <div className="col-sm-11">
            <div className="row">
              <div className="col-sm-2">
                <div className="form-group">
                  <label className="form-label text--secondary font-12 fw-sb">
                    Claim Number
                  </label>
                  <input
                    type="text"
                    placeholder="Claim Number"
                    style={{ minHeight: "38px" }}
                    onChange={handleClaimNumber}
                    value={claimNumber}
                    className="form-control simple-placeholder br-10 font-12 shadow-none border--default"
                  />
                </div>
              </div>

              <div className="col-sm-2">
                <div className="form-group">
                  <label className="form-label text--secondary font-12 fw-sb">
                    Rendering Provider
                  </label>
                  {areRenderingProvidersLoading === true ? (
                    <Skeleton animation="wave" variant="text" width="100%" />
                  ) : (
                    <PnSelect
                      isClearable={true}
                      isSearchable
                      onChange={handleProviderId}
                      name="providers"
                      closeMenuOnSelect={true}
                      options={renderingProvidersList}
                      value={selectedProvider}
                    />
                    // <select
                    //     className={`form-select br-10 font-12 input border--default shadow-none`}
                    //     aria-label="Provider select"
                    //     onChange={(e) => handleProviderId(e)}
                    //     value={props?.encounterDto?.provider?.providerId || selectedProvider}
                    // >
                    //     <option value="" disabled selected hidden>
                    //         Select provider
                    //     </option>
                    //     {renderingProvidersList?.length > 0
                    //         ? renderingProvidersList?.map((renderingProvider) => {
                    //             return (
                    //                 <option
                    //                     value={renderingProvider?.providerId ?? ""}
                    //                     key={renderingProvider?.providerId ?? ""}
                    //                 >
                    //                     {renderingProvider?.prefix}{" "}
                    //                     {renderingProvider?.firstName}{" "}
                    //                     {renderingProvider?.lastName}{" "}

                    //                 </option>
                    //             );
                    //         })
                    //         : "No prescribers available"}
                    // </select>
                  )}
                </div>
              </div>

              <div className="col-sm-2">
                <div className="form-group">
                  <label className="form-label text--secondary font-12 fw-sb">
                    Patient
                  </label>
                  {arePatientsLoading === true ? (
                    <Skeleton animation="wave" variant="text" width="100%" />
                  ) : (
                    <PnSelect
                      isClearable={true}
                      isSearchable
                      onChange={handlePatientId}
                      name="patients"
                      closeMenuOnSelect={true}
                      options={patientsList}
                      value={selectedPatient}
                    />
                    // <select
                    //     className={`form-select br-10 font-12 input border--default shadow-none`}
                    //     aria-label="Provider select"
                    //     onChange={(e) => handlePatientId(e)}
                    //     value={props?.encounterDto?.patient?.patientId || selectedPatient}
                    // >
                    //     <option value="" disabled selected hidden>
                    //         Select patient
                    //     </option>
                    //     {patientsList?.length > 0
                    //         ? patientsList?.map((patient) => {
                    //             return (
                    //                 <option
                    //                     value={patient?.patientId ?? ""}
                    //                     key={patient?.patientId ?? ""}
                    //                 >

                    //                     {patient?.firstName}{" "}
                    //                     {patient?.lastName}{" "}

                    //                 </option>
                    //             );
                    //         })
                    //         : "No patients available"}
                    // </select>
                  )}
                </div>
              </div>

              <div className="col-sm-2">
                <div className="form-group">
                  <label className="form-label text--secondary font-12 fw-sb">
                    Status
                  </label>
                  <PnSelect
                    isClearable={true}
                    isSearchable
                    onChange={handleClaimStatus}
                    name="claimStatus"
                    closeMenuOnSelect={true}
                    options={CLAIM_STATUS_OPTIONS}
                    value={selectedStatus}
                  />
                </div>
              </div>
              <div className="col-sm-2 align-self-end">
                <button
                  onClick={handleFilter}
                  className="btn blue_task_button br-10 shadow-none"
                >
                  Filter Data
                </button>
              </div>
              <div className="col-sm-2 align-self-end">
                <span
                  onClick={handleClearFilter}
                  className={`pn-link-button d-inline-block  text-decoration-underline fw-sb font-12 text--secondary cursor--pointer ${
                    (claimNumber == "" || claimNumber == null) &&
                    selectedProvider == null &&
                    selectedPatient == null &&
                    selectedStatus == null &&
                    fromDate == null &&
                    toDate == null
                      ? "disabled-link cursor--disabled"
                      : ""
                  }`}
                >
                  Clear Filters
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="col-sm-3 "></div>
        </div>

        <div
          style={{ minHeight: "500px" }}
          className="row mt-3  justify-content-center"
        >
          <div className="col-sm-11">
            {areClaimsLoading ? (
              <Skeleton width="100%" />
            ) : claims?.length > 0 ? (
              <>
                <div className="row justify-content-end">
                  <div className="col-sm-3 text-end">
                    <RefreshDataComponent
                      classes={"pe-2"}
                      isComponentDataLoading={areClaimsLoading}
                      variant="without-bar"
                      callback={() =>
                        fetchAllClaims(
                          user?.tenantId,
                          claimNumber,
                          selectedProvider,
                          selectedPatient,
                          selectedStatus
                        )
                      }
                    />
                  </div>
                </div>
                <AllClaimsTable
                  setReloadClaims={setReloadClaims}
                  data={claims}
                  columnHeadersList={[
                    { key: "claim.claimNumber", value: "Claim Id" },
                    {
                      key: "claim.claimResponse.apexEdiClaimNumber",
                      value: "Apex Claim Id",
                    },
                    {
                      key: "claim.encounter.provider.firstName",
                      value: "Provider",
                    },
                    {
                      key: "claim.encounter.patient.firstName",
                      value: "Patient",
                    },
                    {
                      key: "claim.encounter.createdDate",
                      value: "Encounter Date",
                    },
                    {
                      key: "claim.encounter.patient.patientInsurance[0].payerName",
                      value: "Payer",
                    },
                    { key: "totalAmount", value: "Amount" },
                    {
                      key: "claim.claimResponse.payerAmount",
                      value: "Payer Amount",
                    },
                    {
                      key: "claim.claimPayment.patientResponsibility",
                      value: "Patient Responsibility",
                    },
                    { key: "status", value: "Status" },
                    { key: " ", value: " " },
                  ]}
                />{" "}
              </>
            ) : (
              <>
                <span className="d-block font-12 fw-sb text--secondary">
                  No claims were found that matched the specified filters.
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AllClaimsPage;

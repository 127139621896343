import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { patient_profile_strings as strings } from "../../../res/strings";
import classes from "./ViewPatientProfileEncounter.module.css";

export const EligibilityInformation = ({ type, notChecked }) => {
    let markup;
    if (notChecked === false || notChecked === undefined) {
        markup = <div
            className={`d-inline-flex align-content-center align-items-center px-3 py-1 mx-3 ${classes["eligibility-container"]
                } ${classes[`${type === "success" ? "success" : "reject"}`]}`}

        >
            <div className="font-12 text--terciary pe-2">
                Status:
            </div>
            <div>
                {type === "success" ? (
                    <CheckCircleIcon sx={{ color: `#18c383`, fontSize: 16 }} />
                ) : (
                    <ErrorIcon sx={{ color: `#FFCB32`, fontSize: 16 }} />
                )}
            </div>
            <div
                className={`font-12 px-2 ${classes["eligibility-text-margin"]} align-self-center text--terciary fw-sb`}
            >
                {type === "success"
                    ? strings.en.eligibleCoverage
                    : strings.en.ineligibleCoverage}
            </div>
        </div>
    }
    else {
        markup = <div
            className={`d-inline-flex align-content-center align-items-center px-3 py-1 mx-3 ${classes["eligibility-container"]
                } ${classes[`${type === "success" ? "success" : "reject"}`]}`}
        >
            <div className="font-12 text--terciary">
                Status:
            </div>
            <div
                className={`font-12 px-1 ${classes["eligibility-text-margin"]} align-self-center text--terciary fw-sb`}
            >
                Unknown
            </div>
        </div>
    }
    return (
        <>
            {markup}
        </>
    );
};
import { ActionTypes } from "../constants/actionTypes"

const checkBoxInitialState = {
        isChecked:''
}

const setPatientIntakeFormStatusReducer = (state=checkBoxInitialState, action)=>{
    if (action.type === ActionTypes.SET_PATIENT_INTAKE_FORM_READ_STATUS ){
        return {
            ...state,
            isChecked: action.payload
        }
    }
    return state
}


export default setPatientIntakeFormStatusReducer;
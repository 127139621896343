import OrderPrescription from "../prescription/OrderPrescription";

const PrescriptionRequests = (props) => {
  // console.log("Prescription Data", props?.prescriptionData)
  return (
    <div>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-sm-12 " style={{ marginBottom: "8%" }}>
            {/* <DrugSearchTemp handleSetIsSent={props?.handleSetIsSent} newPrescriptionRequest = {true} handleNewPrescriptionModal={props?.handleNewPrescriptionModal} encounterId={props.patientId} patientId={props.patientId} /> */}
            <OrderPrescription
              handleSetIsSent={props?.handleSetIsSent}
              newPrescriptionRequest={true}
              handleNewPrescriptionModal={props?.handleNewPrescriptionModal}
              encounterId={props.encounterId}
              patientId={props.patientId}

              setIsPrescriptionSent={props?.setIsPrescriptionSent}
              prescriptionRefId={props.encounterId}
              {...(props?.setCallApi && { setCallApi: props?.setCallApi })}
              {...(props?.prescriptionReference && { prescriptionReference: props?.prescriptionReference })}
              {...(props?.referenceFrom && { referenceFrom: props?.referenceFrom })}
              {...(props?.soapNoteKey && { soapNoteKey: props?.soapNoteKey })}
              {...(props?.setPrescriptionData && { setPrescriptionData: props?.setPrescriptionData })}
              {...(props?.setSelectedPharmacy && { setSelectedPharmacy: props?.setSelectedPharmacy })}
              {...(props?.selectedPharmacy && { selectedPharmacy: props?.selectedPharmacy })}
              {...(props?.prescriptionData && { prescriptionData: props?.prescriptionData })}
              {...(props?.assessmentPlan && { assessmentPlan: props?.assessmentPlan })}
              {...(props?.providerNote && { providerNote: props?.providerNote })}
              {...(props?.editMedicationModeFromProviderNote === true && { editMedicationModeFromProviderNote: props?.editMedicationModeFromProviderNote })}
              {...(props?.setOrderType && { setOrderType: props?.setOrderType })}
              {...(props?.vitals && { vitals: props?.vitals })}
              {...(props?.setCurrentPrescriptionData && { setCurrentPrescriptionData: props?.setCurrentPrescriptionData })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrescriptionRequests;

import { useNavigate } from "react-router";
import { useEffect, useRef, useState } from "react";
import {
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    TableSortLabel,
} from "@mui/material";
import ReplayIcon from '@mui/icons-material/Replay';
import useOnClickOutside from "../../../../components/hooks/useOnClickOutside";
import PreviewIcon from '@mui/icons-material/Preview';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { calenderDateTimeHelper } from "../../../../utils/dateTimeHelper";
import TrashButtonIcon from "../../../../assets/icons/Icon-Trash.svg";
import TrashButtonIconDisabled from "../../../../assets/icons/Icon-Trash-Disabled.svg";
import { PnToolTip } from "../../../../components/UI/Reusable/PnComponents";
import useModal from "../../../../components/hooks/useModal";
import { EditIconComponent } from "../../../../assets/icons/EditIconComponent";
import DeletePatientClaimModal from "../UI/Modals/DeletePatientClaimModal";
import { STATUS_MAP } from "../../../../billing-and-claims/BillingAndClaimsConstants";
import { currencyFormatter } from "../../../../utils/helpers/HelperFunctions";
import { useSelector } from "react-redux";
import { BILLING_AND_CLAIMS } from "../../../../utils/helpers/subscriptionsConstants";

function statusToString(status) {
    return STATUS_MAP.get(status);
}

function DeniedClaimsTable({ columnHeadersList, data, navigateTo, switchView, setEncounterToEdit, setEditMode, setReloadClaims }) {
    const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);

    const navigate = useNavigate();


    const [sortBy, setSortBy] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [isOpen, setIsOpen] = useState(false);
    const [filterList, setFilterList] = useState([]);
    const toggleOpen = () => setIsOpen(!isOpen);
    const [uniqueProvidersList, setUniqueProvidersList] = useState([])
    const [sortedData, setSortedData] = useState([]);

    const { open: openDeleteClaimModal, handleModal: handleDeleteClaimModal } = useModal(false);
    const [deleteClaimId, setDeleteClaimId] = useState(null);

    const handleSort = (column) => {
        console.log("column: ", column);
        if (sortBy === column) {
            setSortDirection(() => sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy((p) => column);
            setSortDirection((p) => 'asc');
        }
    };
    const getValue = (item, column) => {
        const keys = column.split('.');
        let value = item;
        for (let key of keys) {
            if (key === "provider.firstName") {
                value = value["firstName"][0];
            } else if (value.hasOwnProperty(key)) {
                value = value[key];
            } else {
                return null;
            }

        }
        return value;
    };
    const sortData = () => {
        return data.slice().sort((a, b) => {
            if (sortBy) {
                const aValue = getValue(a, sortBy);
                const bValue = getValue(b, sortBy);

                console.log("aValue,bValue", aValue, bValue);
                if (aValue < bValue) {
                    return sortDirection === 'asc' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortDirection === 'asc' ? 1 : -1;
                }
                return 0;
            }
            return 0;
        });
    }






    useEffect(() => {
        setSortedData(() => sortData())
    }, [sortBy, sortDirection])


    useEffect(() => {
        const uniqueObjects = data.filter((item, index, arr) => {

            const firstName = item.encounter.provider.firstName;
            if (!firstName) return false; // Skip items without a firstName
            return arr.findIndex(obj => obj.encounter.provider.firstName === firstName) === index;
        });
        setSortedData(sortData());
        setUniqueProvidersList(uniqueObjects)
    }, [data]);
    return (
        <>
            <TableContainer sx={{ borderRadius: "10px", border: "1px solid #D9E8EC", maxHeight: '60vh', maxWidth: '100%', overflowX: 'auto', '&::-webkit-scrollbar': { height: '8px', width: '4px' }, '&::-webkit-scrollbar-thumb': { backgroundColor: '#D9E8EC', borderRadius: '4px' } }} >
                <Table stickyHeader aria-label="sticky table">
                    <TableHead sx={{ borderTopColor: "#FAFCFC", borderRightColor: "#FAFCFC", borderLeftColor: "#FAFCFC", borderBottom: "1px solid #D9E8EC" }}>
                        <TableRow>

                            {columnHeadersList?.map((item, idx) => {
                                return <TableCell key={item?.key?.concat(idx)}>
                                    <TableSortLabel
                                        // active={sortBy === item?.key}
                                        direction={sortBy === item?.key ? sortDirection : 'asc'}
                                        onClick={() => handleSort(item?.key)}
                                        hideSortIcon={true}
                                    >
                                        <span className="font-12 text--secondary">{item?.value}</span>
                                        {/* Conditional rendering of custom-colored icon */}

                                        {item?.key === "provider.firstName" || item?.key === "encounterMetadata.dateOfServiceTo" ? (<span style={{ color: "#EC609B" }}> {/* Change the color here */}
                                            {sortDirection === 'desc' && sortBy === item?.key ? (
                                                <KeyboardArrowDownIcon />
                                            ) : (
                                                <KeyboardArrowUpIcon />
                                            )}
                                        </span>) : null}

                                    </TableSortLabel>
                                </TableCell>
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData.map((item, idx) => {


                            const keys = Object.keys(item);
                            let firstName = item?.encounter?.provider?.firstName;
                            let lastName = item?.encounter?.provider?.lastName;
                            let prefix = item?.encounter?.provider?.prefix;

                            return (
                                <TableRow key={idx} sx={{ "&:last-child td, &:last-child th": { border: 0 }, }}>

                                    <TableCell sx={{ color: "#336383", userSelect: 'text' }}>
                                        <span
                                            style={{ "userSelect": 'text' }}
                                            onClick={() => { navigate(`/claim-history/${item?.claimId}`, { state: { data: item } }) }}
                                            className="font-12 text-decoration-underline-secondary cursor--pointer">{item?.claimNumber}</span>
                                    </TableCell>

                                    <TableCell sx={{ color: "#336383" }}>
                                        <span className="font-12">{item?.claimResponse?.apexEdiClaimNumber ?? "N/A"}</span>
                                    </TableCell>
                                    <TableCell sx={{ color: "#336383" }}>
                                        <span className="font-12">{"".concat(prefix ?? "", " ", firstName, " ", lastName)}</span>
                                    </TableCell>
                                    <TableCell sx={{ color: "#336383" }}>
                                        <span className="font-12">{"".concat(item?.encounter?.patient?.firstName, " ", item?.encounter?.patient?.lastName)}</span>
                                    </TableCell>
                                    <TableCell sx={{ color: "#336383" }}>
                                        <span className="font-12">{calenderDateTimeHelper(item?.encounter?.createdDate, "MMM dd, yyyy")}</span>
                                    </TableCell>

                                    <TableCell sx={{ color: "#336383" }}>
                                        <span className="font-12">{item?.encounter?.patient?.patientInsurance?.at(0)?.payerName}</span>
                                    </TableCell>

                                    <TableCell sx={{ color: "#336383" }}>
                                        <span className="font-12 text-center">{item?.claimServices?.length}
                                        </span>                                    </TableCell>
                                    <TableCell sx={{ color: "#336383" }}>
                                        <span className="font-12">${item?.totalAmount}.00
                                        </span>                                    </TableCell>


                                    <TableCell sx={{ color: "#336383" }}>
                                        <span className="font-12">
                                            {
                                                item?.claimResponse?.payerAmount !== undefined && item?.claimResponse?.payerAmount !== null ? "$" + item?.claimResponse?.payerAmount +
                                                    ".00" : 'N/A'
                                            }
                                        </span>                                    </TableCell>

                                    <TableCell sx={{ color: "#336383" }}>
                                        <span className="font-12">{item?.claimPayment?.patientResponsibility !== null && !isNaN(item?.claimPayment?.patientResponsibility) ? currencyFormatter("USD", item?.claimPayment?.patientResponsibility) : "N/A"}</span>
                                    </TableCell>
                                    <TableCell sx={{ color: "#336383" }}>
                                        <span className="font-12">{statusToString(item?.status)}</span>
                                    </TableCell>
                                    <TableCell sx={{ color: "#336383" }}>

                                        <div className="d-flex gap-2">



                                            <div>
                                                {item?.status === "CLAIM_DENIED" ? <PnToolTip title="Re-Submit Claim">
                                                    <ReplayIcon
                                                        onClick={() => { navigate('/re-submit-claim', { state: { data: item } }) }}
                                                        fontSize="small"
                                                        style={{ color: "#336383" }}
                                                        className="cursor--pointer font-12"
                                                    />
                                                </PnToolTip> : null}
                                            </div>

                                        </div>


                                    </TableCell>


                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

        </>
    );
}

export default DeniedClaimsTable;
import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import sendHttpRequest from "../../../../../components/hooks/sendHttpRequest";
import { useState } from "react";
import { calenderDateTimeHelper } from "../../../../../utils/dateTimeHelper";


const ClaimResponseModal = (props) => {
    const { claimResponseData } = props;
    console.log(claimResponseData);

    return (
        <Modal
            show={props?.show}
            onHide={() => {
                props.handleModal();
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            backdropClassName="modal-backdrop"
            contentClassName={`modal-border modal-shadow `}
            scrollable={true}
            centered

        // style={{style}}
        >
            <Modal.Header  >
                <span
                    style={{
                        lineHeight: "12px",
                        width: "18px",
                        fontSize: "8pt",
                        color: "#336383",
                        position: "absolute",
                        top: 15,
                        right: 15,
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        props?.handleModal()
                    }}
                >
                    {<CloseIcon />}
                </span>
                <Modal.Title className="font-20 fw-sb">Claim History for <span className="text--secondary">{claimResponseData?.ClaimNumber}</span> </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row ">
                        <div className="col-sm-3">
                            <span className="font-10 text--secondary fw-sb d-block">
                                Patient
                            </span>
                            <span className="font-12  d-block">
                                {claimResponseData?.PatientFirstName} {claimResponseData?.PatientLastName}
                            </span>
                        </div>

                        <div className="col-sm-3">
                            <span className="font-10 text--secondary fw-sb d-block">
                                Vendor Claim Id
                            </span>
                            <span className="font-12  d-block">
                                {claimResponseData?.VendorClaimId}
                            </span>
                        </div>

                        <div className="col-sm-3">
                            <span className="font-10 text--secondary fw-sb d-block">
                                Claim Number
                            </span>
                            <span className="font-12  d-block">
                                {claimResponseData?.ClaimNumber}
                            </span>
                        </div>

                        <div className="col-sm-3">
                            <span className="font-10 text--secondary fw-sb d-block">
                                Current State
                            </span>
                            <span className="font-12  d-block">
                                {claimResponseData?.CurrentState}
                            </span>
                        </div>
                    </div>

                    <div className="row mt-3 ">
                        <div className="col-sm-3">
                            <span className="font-10 text--secondary fw-sb d-block">
                                Claim Amount
                            </span>
                            <span className="font-12  d-block">
                                ${claimResponseData?.TotalClaimAmount}.00
                            </span>
                        </div>

                        <div className="col-sm-3">
                            <span className="font-10 text--secondary fw-sb d-block">
                                Patient Amount
                            </span>
                            <span className="font-12  d-block">
                                ${claimResponseData?.PatientAmount}.00
                            </span>
                        </div>

                        <div className="col-sm-3">
                            <span className="font-10 text--secondary fw-sb d-block">
                                Payer Amount
                            </span>
                            <span className="font-12  d-block">
                                ${claimResponseData?.PayerAmount}.00
                            </span>
                        </div>

                        <div className="col-sm-3">
                            <span className="font-10 text--secondary fw-sb d-block">
                                Adjustments
                            </span>
                            <span className="font-12  d-block">
                                ${claimResponseData?.Adjustments}.00
                            </span>
                        </div>
                    </div>

                    <div className="row mt-3 ">
                        <div className="col-sm-3">
                            <span className="font-10 text--secondary fw-sb d-block">
                                Service Date
                            </span>
                            <span className="font-12  d-block">
                                {calenderDateTimeHelper(claimResponseData?.ServiceDate, "MMM dd, yyyy")}
                            </span>
                        </div>

                        <div className="col-sm-4">
                            <span className="font-10 text--secondary fw-sb d-block">
                                Display Date
                            </span>
                            <span className="font-12  d-block">
                                {calenderDateTimeHelper(claimResponseData?.DisplayDate, "MMM dd, yyyy")}
                            </span>
                        </div>

                        {/* <div className="col-sm-3">
                            <span className="font-10 text--secondary fw-sb d-block">
                                Payer Amount
                            </span>
                            <span className="font-12  d-block">
                                ${claimResponseData?.PayerAmount}.00
                            </span>
                        </div>

                        <div className="col-sm-3">
                            <span className="font-10 text--secondary fw-sb d-block">
                                Adjustments
                            </span>
                            <span className="font-12  d-block">
                                ${claimResponseData?.Adjustments}.00
                            </span>
                        </div> */}
                    </div>

                    {claimResponseData?.HistoryEntries !== null && claimResponseData?.HistoryEntries !== undefined && claimResponseData?.HistoryEntries?.length > 0 ? (<>
                        <div className="row mt-3 ">
                            <div className="col-sm-12">
                                <span className="d-block text--terciary fw-sb font-14">History</span>
                            </div>
                        </div>
                        {claimResponseData.HistoryEntries?.map((history) => {
                            return <div className="row py-2 border--bottom">
                                <div className="col-sm-3">
                                    <span className="font-10 text--secondary fw-sb d-block">
                                        History Type
                                    </span>
                                    <span className="font-12  d-block">
                                        {history?.HistoryType}
                                    </span>
                                </div>

                                <div className="col-sm-3">
                                    <span className="font-10 text--secondary fw-sb d-block">
                                        Entry Date
                                    </span>
                                    <span className="font-12  d-block">
                                        {calenderDateTimeHelper(history?.EntryDate, "MMM dd, yyyy")}
                                    </span>
                                </div>

                                <div className="col-sm-3">
                                    <span className="font-10 text--secondary fw-sb d-block">
                                        Entry Type
                                    </span>
                                    <span className="font-12  d-block">
                                        {history?.EntryType}
                                    </span>
                                </div>

                                <div className="col-sm-3">
                                    <span className="font-10 text--secondary fw-sb d-block">
                                        Actor Type
                                    </span>
                                    <span className="font-12  d-block">
                                        {history?.ActorType}
                                    </span>
                                </div>

                                <div className="col-sm-3 py-2">
                                    <span className="font-10 text--secondary fw-sb d-block">
                                        Actor
                                    </span>
                                    <span className="font-12  d-block">
                                        {history?.ActorText}
                                    </span>
                                </div>

                                <div className="col-sm-3 py-2">
                                    <span className="font-10 text--secondary fw-sb d-block">
                                        Service Charge Amount
                                    </span>
                                    <span className="font-12  d-block">
                                        ${history?.ServiceChargeAmount}
                                    </span>
                                </div>

                                <div className="col-sm-3 py-2">
                                    <span className="font-10 text--secondary fw-sb d-block">
                                        Service Lines
                                    </span>
                                    <span className="font-12  d-block">
                                        {history?.ServiceLines}
                                    </span>
                                </div>

                                <div className="col-sm-3 py-2">
                                    <span className="font-10 text--secondary fw-sb d-block">
                                        Status
                                    </span>
                                    <span className="font-12  d-block">
                                        {history?.StatusText}
                                    </span>
                                </div>

                            </div>
                        })}

                    </>)

                        : null}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ClaimResponseModal;

const PatientSoapSummary = (props) => {
  let visitType =   props?.selectedPastEcounter?.encounter?.visitType ?? "FOLLOW_UP"
  let chiefComplaint = props?.selectedPastEcounter?.encounter?.chiefComplaint;
  let historyOfPresentIllness =
    props?.selectedPastEcounter?.encounter?.historyOfPresentIllness;
  let allergies = props?.selectedPastEcounter?.patientChart?.allergies ?? [];
  let vitals = props?.selectedPastEcounter?.patientChart?.vitals ?? [];
  let physicalExamination = props?.selectedPastEcounter?.encounter?.physicalExam ?? "";
  let assessmentNote =
    props?.selectedPastEcounter?.encounter?.assessmentNote ?? "";
  let planNote =
    props?.selectedPastEcounter?.encounter?.recommendationNote ?? "";
  let reviewOfSystems =
    props?.selectedPastEcounter?.encounter?.reviewOfSystem ?? "";
  let primaryDiagnosis =
    props?.selectedPastEcounter?.patientChart?.diagnosis?.length > 0
      ? props?.selectedPastEcounter?.patientChart?.diagnosis?.filter(
          (diagnosis) => diagnosis?.type?.toLowerCase() === "p"
        )[0]
      : "";
  let secondaryDiagnoses =
    props?.selectedPastEcounter?.patientChart?.diagnosis?.length > 0
      ? props?.selectedPastEcounter?.patientChart?.diagnosis?.filter(
          (diagnosis) => diagnosis?.type?.toLowerCase() === "s"
        )
      : "";
  let medicationRx = props?.selectedPastEcounter?.medicationRx ?? [];
  let labOrder = props?.selectedPastEcounter?.labOrder ?? "";
  let pastMedicationHistory = props?.selectedPastEcounter?.encounter?.pastMedicationHistory ?? "";
  

  const HandleTextComponent = (props) => {
    return (
      <>
        <div>
          <h6 className="font-12 m-0">{props?.title}</h6>
          <p className="font-12 m-0 ps-1">{props?.description}</p>
        </div>
      </>
    );
  };
  const AllergySummaryComponent = ({ allergy }) => {
    return (
      <div className="font-12 m-0 ps-1">
        {allergy?.drugProductCodedText} ({allergy?.allergyType},{" "}
        {allergy?.severityCodedText}) - {allergy?.reactionCodedText}
      </div>
    );
  };
  const PatientVitalComponent = ({ vitals }) => {
    return (
      <>
        <p className={`font-12 m-0 ${visitType === "FOLLOW_UP" ? 'ps-1':'ps-2'}`}>
          {props?.encounter?.vital === 0 ? "" : ""}
          {vitals?.length === 0 ? "" : ""}
          {vitals?.at(0)?.temperature !== null &&
          vitals?.at(0)?.temperature !== undefined &&
          vitals?.at(0)?.temperature?.trim() !== ""
            ? vitals?.at(0)?.temperature?.concat(" °F, ")
            : ""}
          {vitals?.at(0)?.pulse !== null &&
          vitals?.at(0)?.pulse !== undefined &&
          vitals?.at(0)?.pulse?.trim() !== ""
            ? vitals?.at(0)?.pulse?.concat(" BPM, ")
            : ""}
          {vitals?.at(0)?.systolic !== null &&
          vitals?.at(0)?.systolic !== undefined &&
          vitals?.at(0)?.systolic?.trim() !== "" &&
          vitals?.at(0)?.diastolic !== null &&
          vitals?.at(0)?.diastolic !== undefined &&
          vitals?.at(0)?.diastolic?.trim() !== ""
            ? vitals
                ?.at(0)
                ?.systolic?.concat("/", vitals?.at(0)?.diastolic, " mmHg, ")
            : ""}
          {vitals?.at(0)?.spo2 !== null &&
          vitals?.at(0)?.spo2 !== undefined &&
          vitals?.at(0)?.spo2?.trim() !== ""
            ? vitals?.at(0)?.spo2?.concat("%, ")
            : ""}

          {vitals?.at(0)?.height !== null &&
          vitals?.at(0)?.height !== undefined &&
          vitals?.at(0)?.height?.trim() !== ""
            ? vitals?.at(0)?.height?.concat(" in, ")
            : ""}
          {vitals?.at(0)?.weight !== null &&
          vitals?.at(0)?.weight !== undefined &&
          vitals?.at(0)?.weight?.trim() !== ""
            ? vitals?.at(0)?.weight?.concat(" lbs, ")
            : ""}
          {vitals?.at(0)?.bmi !== null &&
          vitals?.at(0)?.bmi !== undefined &&
          vitals?.at(0)?.bmi?.trim() !== ""
            ? "".concat(" BMI: ", vitals?.at(0)?.bmi)
            : ""}
        </p>
      </>
    );
  };
  const DiagnosesComponent = ({
    title,
    primaryDiagnosis,
    secondaryDiagnoses,
  }) => {
    return (
      <>
        <div>
          <h6 className="font-14 m-0">Diagnoses</h6>
          <p className="font-12 m-0 ps-1">
            {primaryDiagnosis?.code ??
              (primaryDiagnosis?.code !== undefined &&
              Object.keys(primaryDiagnosis)?.length > 0
                ? primaryDiagnosis.code
                : "")}
            {primaryDiagnosis?.code !== undefined &&
            Object.keys(primaryDiagnosis)?.length > 0
              ? " - "
              : ""}
            {primaryDiagnosis?.description ??
              (primaryDiagnosis?.description !== undefined &&
              primaryDiagnosis?.description !== null
                ? primaryDiagnosis?.description
                : "")}
            {primaryDiagnosis?.description !== undefined &&
            primaryDiagnosis?.description !== null ? (
              <span className="fw-sb"> (Primary)</span>
            ) : (
              ""
            )}
            <br />
            {secondaryDiagnoses !== ""
              ? secondaryDiagnoses?.length > 0
                ? secondaryDiagnoses?.map((item, index) => {
                    return (
                      <>
                        {item?.code ?? ""} - {item?.description ?? ""} <br />
                      </>
                    );
                  })
                : ""
              : ""}
          </p>
        </div>
      </>
    );
  };
  const MedicationComponent = () => {
    return (
      <>
        <div>
          <h6 className="font-14 m-0">Medication Ordered</h6>
          <p className="font-12 m-0 py-1">
            {medicationRx !== undefined
              ? medicationRx?.length > 0
                ? medicationRx?.map((item, index) => {
                    return (
                      <div key={item?.medicationRxId} className="ms-1">
                        Medicine: {item?.drugDescription ?? ""}, Sig:{" "}
                        {item?.sigText ?? ""} <br />
                      </div>
                    );
                  })
                : ""
              : ""}
          </p>
        </div>
      </>
    );
  };
  const LabOrderComponent = ({labOrderTest}) => {
    return (
      <>
        <div>
          <h6 className="font-14 m-0">{"Labs Ordered"}</h6>
        </div>
        <div className="ms-1">
          {labOrderTest?.map((test, index) => {
            return (
              <div className="font-12 py-1 text--terciary" key = {index}>
                {test.description.concat(
                  " ",
                  test?.alias1?.length > 0 ? `(${test?.alias1})` : ""
                )}
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12 g-0 font-14">
          <h6 className="font-14 fw-sb m-0">{visitType === "FOLLOW_UP" ? "Subjective" : "Reason for Visit"}</h6>
        </div>
        <div className="col-sm-12 mt-2 g-0">
          {/* <Heading>Subjective</Heading> */}
          <div className="ps-1">
            <HandleTextComponent
              title={"Chief Complaints"}
              description={chiefComplaint ?? ""}
            />
            <HandleTextComponent
              title={"History of Present Illness"}
              description={historyOfPresentIllness ?? ""}
            />
          </div>
          {
            visitType === "FOLLOW_UP" && 
              <HandleTextComponent title = "Past Medical History" description={pastMedicationHistory}/>
          }
          {
          visitType === "FOLLOW_UP" && 
          <div className="col-sm-12 g-0 font-14 mt-2">
            <div>
              <h6 className="font-14 m-0 ">{"Review of Systems"}</h6>
              <HandleTextComponent description={reviewOfSystems} />
            </div>
          </div>
        }
        </div>
        {
          visitType !== "FOLLOW_UP" && 
          <div className="col-sm-12 g-0 font-14 mt-2">
            <div>
              <h6 className="font-14 m-0 fw-sb">{"Review of Systems"}</h6>
              <HandleTextComponent description={reviewOfSystems} />
            </div>
          </div>
        }
        {
          visitType !== "FOLLOW_UP" && 
          <div className="col-sm-12 g-0 font-14 mt-2">
          <div>
            <h6 className="font-14 m-0 fw-sb">{"Allergies"}</h6>
            {allergies?.map((item) => {
              return <AllergySummaryComponent allergy={item} />;
            })}
          </div>
        </div>}
        

        {
          visitType === "FOLLOW_UP" && 
          <div className="col-sm-12 g-0 font-14 mt-2">
            <h6 className="font-14 m-0 fw-sb">{"Objective"}</h6>
          </div>
        }
        <div className={`${visitType === "FOLLOW_UP" ? 'font-12':'col-sm-12 mt-2 g-0 font-14'}   `}>
          <h6 className={`${visitType === "FOLLOW_UP" ? 'font-12':' ms-1 fw-sb font-14'}  m-0`}>{"Vitals"}</h6>
          <PatientVitalComponent vitals={vitals} />
        </div>

        <div className={`${visitType === "FOLLOW_UP" ? 'font-12':'col-sm-12 mt-2 g-0 font-14'}   `}>
          <h6 className={`${visitType === "FOLLOW_UP" ? 'font-12':' ms-1 fw-sb font-14'}  m-0`}>{"Physical Exam"}</h6>
          <HandleTextComponent title={""} description={physicalExamination} />
        </div>

        <div className="col-sm-12 g-0 font-14 mt-2">
          <h6 className="font-14 m-0 fw-sb">{"Assessment"}</h6>
          <HandleTextComponent title={""} description={assessmentNote} />
          <div className="ms-1">
            <DiagnosesComponent
              title={"Diagnoses"}
              primaryDiagnosis={primaryDiagnosis}
              secondaryDiagnoses={secondaryDiagnoses}
            />
          </div>
        </div>

        <div className="col-sm-12 g-0 font-14 mt-2">
          <h6 className="font-14 m-0 fw-sb">{"Plan"}</h6>
            <HandleTextComponent title={""} description={planNote} />
          <div className="ps-1">
            <MedicationComponent medicationRx={medicationRx} />
            <LabOrderComponent labOrderTest={labOrder?.labOrderTest ?? []}/>
            
          </div>
        </div>
      </div>
      {/* {console.log("props?.selectedPastEcounter",props?.selectedPastEcounter)} */}
    </>
  );
};
export default PatientSoapSummary;

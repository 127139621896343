import { useState } from "react";
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";
import sendHttpRequest from "../hooks/sendHttpRequest";
import CurrencyInput from 'react-currency-input-field';
import { CircularProgress } from "@mui/material";
import { ConfigMap } from "../../utils/helpers/ConfigMap";
import { transferPatientPayment, transferPayment } from "../../res/endpointurls";
import { useAuth } from "../hooks/useAuth";
import { useEffect } from "react";


const PaymentCashForm = ({setIsPaymentSent,isPaymentSent, amountValue, prescribersList, userId, fetchBillingHistory, handleReceipt, isPrinting,fetchCurrentBill,billingProvider,isCopPay}) => {
    const [isSent, setIsSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [provider, setProvider] = useState();
    const [amountInput, setAmountInput] = useState(amountValue);
    const {user} = useAuth();
    const [isCopPayment,setIsCopPayment] = useState(false);

    const handlePayment = async () => {
        setIsLoading(true);
        const httpResponse = await sendHttpRequest({
            url: transferPatientPayment(userId),
            method: "POST",
            data: {
                providerId:provider,
                userId:userId,
                amount:amountInput,
                cash:"cash",
                transactionType:"cash",
                copay:isCopPayment
            }
        });
        if (!httpResponse.error) {
            setAmountInput(pre=>0);
            setIsSent(true);
            setIsPaymentSent(true);
            fetchBillingHistory(true, {description: "Paid via Cash", due: amountInput});
            setIsPaymentSent(true);
            // fetchCurrentBill();
        } else {
            if (httpResponse?.status === 500) {
                setError({message:"Something went wrong", show:true});
            } else {
                setError({...httpResponse?.errorMessage, show:true} ?? {message:"Payment not completed", show:true});
            }
        }
        setIsLoading(false);
    }

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setIsSent(false);
        setError({show:false});
    };
    const handleInputChange = (value)=>{
        setAmountInput(value);
        setIsCopPayment(false);
    }
    useEffect(()=>{
        setAmountInput(pre=>parseFloat(amountValue).toFixed(2))
        setProvider(billingProvider)
        setIsCopPayment(isCopPay)
        return ()=>{
            setIsCopPayment(false)
        }
    },[amountValue,isCopPay])
    // console.log("isCopPayment: ",isCopPayment,amountInput);

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="row">

                        <div className="col-6">
                            <div className="col-12">
                                <label htmlFor="amount" className="font-12 text--secondary">{"Amount"}</label>
                                <CurrencyInput
                                    id="cash"
                                    name="cash"
                                    placeholder="$0.00"
                                    className="text--terciary text-end font-12 input form-control shadow-none border--default br-10"
                                    value={amountInput}
                                    decimalsLimit={2}
                                    fixedDecimalLength={2}
                                    prefix="$"
                                    decimalSeparator="."
                                    groupSeparator=","
                                    intlConfig={{ locale: 'en-US', currency: 'USD' }}
                                    onValueChange={(value, name) => handleInputChange(value)}
                                    onBlur={()=>handleInputChange(amountInput)}
                                    // disabled = {true}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="col-12">
                                <label htmlFor="provider" className="font-12 text--secondary">{"Provider"}</label>
                                <select
                                    className={`form-select font-12 border--default br-10 shadow-none input`}
                                    aria-label="action select"
                                    style={{
                                    border: "1px solid #D9E8EC",
                                    //   width: `${props.tasksOnly ? "100%" : "80%"}`,
                                    height: "36px",
                                    // fontStyle:"italic"
                                    }}
                                    onChange={(e) => setProvider(e.target.value)}
                                >
                                    <option
                                        className="text--terciary"
                                        value={""}
                                        key={1}
                                        selected
                                        disabled
                                        hidden
                                    >
                                    {"Select Provider"}
                                    </option>
                                    {
                                    prescribersList?.length > 0 && 
                                    prescribersList.map((prescriberItem)=>{
                                        return (
                                        <option 
                                            key={prescriberItem?.userId} 
                                            id = {prescriberItem?.userId} 
                                            value={prescriberItem?.userId}
                                            selected={provider === prescriberItem?.userId}
                                            >
                                            {"".concat(prescriberItem?.name[0]?.prefix || ""," ",prescriberItem?.name[0]?.firstName || ""," ",prescriberItem?.name[0]?.lastName)}
                                        </option>
                                        )
                                    })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="d-flex flex-row justify-content-end">
                                <button
                                // id="credit-card-form"
                                onClick={handlePayment}
                                type="btn"
                                disabled={provider === undefined ? true: amountInput <= 0 || isLoading}
                                className="btn d9e8ec--button  ">
                                    {!isLoading ? "Submit Payment" :
                                    <CircularProgress
                                        size={24}
                                        sx={{color: "#ec609b"}}
                                    />
                                    }
                                </button>
                                {/* <button
                                    disabled={!isPaymentSent === true ? true: isPrinting}
                                    onClick={() => handleReceipt(userId)}
                                    type="btn"
                                    className="btn blue_task_button  ">
                                    {
                                        !isPrinting ? "Print Receipt" :
                                            <CircularProgress
                                                size={24}
                                                sx={{color: "#ec609b"}}
                                            />
                                    }
                                </button> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <CustomizedSnackbars
                    message={"Payment completed successfully."}
                    severity="success"
                    open={isSent}
                    handleClose={handleClose}
                />
            <CustomizedSnackbars
                message={error?.message}
                severity="error"
                open={error?.show}
                handleClose={handleClose}
            />
        </>
    )
}

export default PaymentCashForm;
import { Modal } from "react-bootstrap";
import AddButtonIcon from "../../../../assets/icons/Icon-Add.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useRef, useState } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import sendHttpRequest from "../../../hooks/sendHttpRequest";
import { styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  Switch,
  Typography
} from "@mui/material";

import InputMask from 'react-input-mask';
import { RolesTextConversion } from "../../../../utils/helpers/RolesMap";

function PhoneInput(props) {
  return (
    <InputMask
      mask='(999) 999-9999'
      value={props.value}
      onChange={props.onChange}
      maskChar="_"
      className="form-control font-14 border--default br-10 shadow-none input"
      onInvalid={(e) => { e.target.setCustomValidity('Please fill out this field') }}
      onInput={(e) => { e.target.setCustomValidity('') }}
      {...props}
    >
    </InputMask>
  );
}


const NewUserModal = (props) => {

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState();
  const [ssn, setSsn] = useState("");
  const [role, setRole] = useState("");
  const [pharmacy, setPharmacy] = useState("");
  const [roles, setRoles] = useState([]);
  const [city, setCity] = useState("");
  const [state, setState] = useState([]);
  const [zipCode, setZipCode] = useState('');
  const [landline, setLandline] = useState('');
  const [cellPhone, setCellPhone] = useState('');
  const [emergencyContact, setEmergencyContact] = useState('');
  const [prescribersList, setPrescribersList] = useState([]);
  const [email, setEmail] = useState(null);
  const [serverErrors, setServerErrors] = useState([]);
  const [regexErrors, setRegexErrors] = useState({
    CellPhone: '',
    HomeTelephone: ''
  });
  const firstNameRef = useRef();
  const [isSending, setIsSending] = useState(false);
  const [twoFactorState, setTwoFactorState] = useState(false);
  const [supervisor, setSupervisor] = useState("");

  const label = { inputProps: { "aria-label": "TwoFactorSwitch" } };
  const TwoFactorSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        '& + .MuiSwitch-track': {
          backgroundColor: '#004FD4',
          opacity: 1,
        },
      },
    },
    '& .MuiSwitch-track': {
      borderRadius: 11 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
    },
    '& .MuiSwitch-thumb': {
      borderRadius: 11 / 2,
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
      color: "#fff",
    },
  }));


  const addNewUserRequestHandler = async (userObject) => {
    setIsSending(true);
    const httpResponse = await sendHttpRequest({
      url: "/prescriber/create",
      method: "POST",
      data: {
        NonVeterinarian: userObject.HumanPrescriber,
      },
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.status);
      props.setIsSent(true);
      props.fetchAllPrescribers();
      props.handleNewUserModalClose();
      clearState();
      setIsSending(false);

    } else {
      // console.log(httpResponse.errorMessage);
      setServerErrors(httpResponse.errorMessage);
      setIsSending(false);

    }
  };

  const fetchRoles = async () => {
    const httpResponse = await sendHttpRequest({
      url: "/prescriber/roles",
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
      setRoles(httpResponse.data);
    } else {
      // console.log(httpResponse.error);
    }
  }

  const fetchAllPrescribers = async () => {
    const httpResponse = await sendHttpRequest({
      url: "/prescriber/all",
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log("prescribers-> ", httpResponse.data);
      setPrescribersList(httpResponse.data);
    } else {
      setPrescribersList([]);
      // console.log(httpResponse.error);
    }
  };

  function twoFactorSwitch(event) {
    setTwoFactorState(event.target.checked);
    // setProfileInfo({...profileInfo, TwoFactorAuth:checked});
  }



  const clearState = () => {

    setFirstName('');
    setLastName('');
    setRole('');
    setRoles('');
    setEmail('');
    setCellPhone('');
    setLandline('');

  }

  const handleKeyDown = e => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };
  const firstNameHandler = (event) => {
    setFirstName(event.target.value);
  };
  const lastNameHandler = (event) => {
    setLastName(event.target.value);
  };
  const roleHandler = (event) => {
    setRole(event.target.value);
  };
  const supervisorHandler = (event) => {
    setSupervisor(event.target.value);
  };

  const landlineHanlder = (event) => {
    setLandline(event.target.value);

  };

  const cellPhoneHandler = (event) => {
    setCellPhone(event.target.value);
  };

  const emailHandler = (event) => {
    setEmail(event.target.value);
  };

  useEffect(() => {
    firstNameRef.current.focus();
    fetchRoles();
    fetchAllPrescribers();
    return () => {
      clearState(); // This worked for me
    };
  }, [])

  const addPatientHandler = () => {

    let newObj = { HumanPrescriber: {} };
    // const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    if (role !== "Select" && role !== "") {
      newObj.HumanPrescriber = { ...newObj.HumanPrescriber, roles: role?.trim() };
    }

    if (supervisor !== "Select" && supervisor !== "") {
      newObj.HumanPrescriber = { ...newObj.HumanPrescriber, Supervisor_id: supervisor?.trim() };
    }

    // newObj.HumanPrescriber = { ...newObj.HumanPrescriber, Specialty: role?.trim() };
    newObj.HumanPrescriber = { ...newObj.HumanPrescriber, username: email };
    newObj.HumanPrescriber = { ...newObj.HumanPrescriber, password: "abc123" };

    if (twoFactorState !== "" && twoFactorState !== undefined && twoFactorState !== null) {
      newObj.HumanPrescriber = { ...newObj.HumanPrescriber, Status: twoFactorState };
    }
    // if(yearVal !== "Select" && monthVal !== "Select" && dayVal !== "Select" && yearVal !== "" && monthVal !== "" && dayVal !== ""){
    //     newObj.HumanPatient = {...newObj.HumanPatient,   DateOfBirth:{Date : yearVal?.concat("-",monthVal,"-",dayVal)}};
    // }

    if (dob !== undefined && dob !== null) {
      newObj.HumanPrescriber = { ...newObj.HumanPrescriber, DateOfBirth: { Date: dob } };
    }

    if (landline !== "" && landline !== undefined && landline !== null) {
      // if (phoneRegex.test(landline)) {
      //   setRegexErrors((prevState) => { return { ...prevState, HomeTelephone: '' } });
      newObj.HumanPrescriber = { ...newObj.HumanPrescriber, CommunicationNumbers: { ...newObj.HumanPrescriber?.CommunicationNumbers, HomeTelephone: { Number: landline } } };
      // }
      // else {
      //   setRegexErrors((prevState) => { return { ...prevState, HomeTelephone: 'Invalid phone number' } });
      // }
    }

    if (cellPhone !== "" && cellPhone !== undefined && cellPhone !== null) {
      // if (phoneRegex.test(cellPhone)) {
      //   setRegexErrors((prevState) => { return { ...prevState, CellPhone: '' } });
      newObj.HumanPrescriber = { ...newObj.HumanPrescriber, CommunicationNumbers: { ...newObj.HumanPrescriber?.CommunicationNumbers, PrimaryTelephone: { Number: cellPhone } } };
      // }
      // else {
      // setRegexErrors((prevState) => { return { ...prevState, CellPhone: 'Invalid cell phone number' } });
      // }
    }

    if (email !== "" && email !== null && email !== undefined) {
      newObj.HumanPrescriber = { ...newObj.HumanPrescriber, CommunicationNumbers: { ...newObj.HumanPrescriber?.CommunicationNumbers, ElectronicMail: [email] } };
    }

    if (firstName !== "" && firstName !== undefined && firstName !== null && lastName !== "" && lastName !== undefined && lastName !== null) {
      newObj.HumanPrescriber = { ...newObj.HumanPrescriber, Name: { LastName: lastName, FirstName: firstName } };
    }

    // console.log(newObj);
    addNewUserRequestHandler(newObj);

  };

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.handleNewUserModalClose();
        setRegexErrors(null);
      }}
      size="sm"
      backdropClassName="modal-backdrop"
      contentClassName="modal-border modal-shadow"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"

    // style={{style}}
    >
      {/* <Modal.Header closeButton style={{ border: "none" }}></Modal.Header> */}
      <Modal.Body>
        <div className="container-fluid ">
          <div className="row ">
            <div className="col-12 ">
              <span
                style={{
                  lineHeight: "12px",
                  width: "18px",
                  fontSize: "8pt",
                  color: "#336383",
                  position: "absolute",
                  top: 20,
                  right: 15,
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.handleNewUserModalClose();
                }}
              >
                {<CloseIcon />}
              </span>
            </div>
          </div>
          <div className="row ">
            <div className="col-12">
              <div className="d-flex ">
                {/* <div>
                  <img src={AddButtonIcon} alt=""></img>
                </div> */}
                <div className="mt-2">
                  <p className="h18 fw-sb " style={{ fontWeight: "600" }}>
                    Add User
                  </p>
                </div>
              </div>
            </div>
          </div>
          <form id="patientForm" onSubmit={(e) => {
            e.preventDefault();
            addPatientHandler();
          }}>
            <div className="row mt-3  ">
              <div className="col-md-12">
                <div>
                  <label
                    htmlFor="FirstName"
                    className="form-label m-0 ms-2 font-12 text--secondary fw-sb"
                  >
                    First Name <span>&#42;</span>
                  </label>
                  <input
                    type="text"
                    onChange={firstNameHandler}
                    onKeyDown={handleKeyDown}
                    ref={firstNameRef}
                    className={`form-control font-12 m-0 border--default br-10 shadow-none input`}
                    id="FirstName"
                    required
                  // onBlur={firstNameBlur}

                  />
                  {/* {serverErrors.length > 0 && serverErrors?.map((error) => { return error.attributeName === "FirstName" && <span className="text--danger font-10 ms-2 mt-0 lh-0"> {error.message} </span> })} */}
                </div>

                <label
                  htmlFor="LastName"
                  className="form-label m-0 ms-2 font-12 text--secondary fw-sb"
                >
                  Last Name <span>&#42;</span>
                </label>
                <input
                  type="text"
                  onChange={lastNameHandler}
                  onKeyDown={handleKeyDown}
                  autoComplete={false}
                  className="form-control font-12 border--default br-10 shadow-none input"
                  id="LastName"
                  required
                />
                {/* {serverErrors.length > 0 && serverErrors?.map((error) => { return error.attributeName === "LastName" && <span className="text--danger font-10 ms-2 mt-0 lh-0"> {error.message} </span> })} */}


                <label
                  htmlFor="Email"
                  className="form-label m-0 ms-2 fw-sb font-12 text--secondary"
                >
                  Email <span>&#42;</span>
                </label>
                <input
                  type="email"
                  onChange={emailHandler}
                  autoComplete={false}
                  className="form-control font-12 border--default br-10 shadow-none input"
                  id="Email"
                  required
                />
                <span className="text--danger d-block font-10 ms-2 mt-0">
                  {/* {regexErrors.EmergencyContact !== undefined && regexErrors.EmergencyContact !== '' ? regexErrors.EmergencyContact : ''} */}
                  {serverErrors !== null && serverErrors?.hasOwnProperty("Prescriber.CommunicationNumbers.ElectronicMail") ? serverErrors["Prescriber.CommunicationNumbers.ElectronicMail"] : ''}

                </span>

                <label
                  htmlFor="CellPhone"
                  className="form-label m-0 fw-sb font-12 text--secondary"
                >
                  Mobile Phone <span>&#42;</span>
                </label>
                <PhoneInput onChange={cellPhoneHandler} id="CellPhone" required></PhoneInput>
                <span className="text--danger d-block font-10 ms-2 mt-0">
                  {serverErrors !== null && serverErrors?.hasOwnProperty("Prescriber.CommunicationNumbers.PrimaryTelephone.Number") ? serverErrors["Prescriber.CommunicationNumbers.PrimaryTelephone.Number"] : ''}
                </span>


                <label
                  htmlFor="Landline"
                  className="form-label m-0 ms-2 fw-sb font-12 text--secondary"
                >
                  Home Phone
                </label>
                <PhoneInput onChange={landlineHanlder} id="Landline"></PhoneInput>
                <span className="text--danger d-block font-10 ms-2 mt-0">
                  {serverErrors !== null && serverErrors?.hasOwnProperty("Prescriber.CommunicationNumbers.HomeTelephone.Number") ? serverErrors["Prescriber.CommunicationNumbers.HomeTelephone.Number"] : ''}
                </span>



                <label
                  htmlFor="UserRoles"
                  className="form-label ms-2 m-0 font-12 text--secondary fw-sb"
                >
                  User Role <span>&#42;</span>
                </label>
                <select
                  onChange={roleHandler}
                  className="form-select font-12 border--default br-10 shadow-none input"
                  aria-label="UserRoles"
                  id="UserRoles"
                  required
                  style={{ borderRadius: "10px" }}
                >
                  <option value="" hidden selected disabled>Select</option>
                  {
                    roles.length > 0 ? roles.map((item, index) => {
                      return <option key={index} value={item?.value}>{item?.key}</option>
                    }) : ''
                  }

                </select>

                <label
                  htmlFor="supervisor"
                  className="form-label ms-2 m-0 font-12 text--secondary fw-sb"
                >
                  Supervising Physician
                </label>
                <select
                  onChange={supervisorHandler}
                  className="form-select font-12 border--default br-10 shadow-none input"
                  aria-label="supervisor"
                  id="supervisor"
                  // required
                  style={{ borderRadius: "10px" }}
                >
                  <option value="" hidden selected disabled>Select Supervisor</option>
                  {prescribersList?.prescribers?.length > 0 ? prescribersList?.prescribers?.map((prescriberItem) => {
                    return (
                      prescriberItem?.NonVeterinarian?.role?.trim() === "Physician" && (
                        <option
                          value={prescriberItem?.NonVeterinarian?.id ?? ""}
                          key={prescriberItem?.NonVeterinarian?.id ?? ""}
                        >
                          {prescriberItem?.NonVeterinarian?.Name?.Prefix}{" "}
                          {prescriberItem?.NonVeterinarian?.Name?.FirstName}{" "}
                          {prescriberItem?.NonVeterinarian?.Name?.LastName}{" "}
                        </option>
                      )
                    );
                  }) : ""}
                </select>
                {/* <span className="text--danger font-10 ms-2 mt-0">
                    {serverErrors !== null && serverErrors?.hasOwnProperty("Patient.CommunicationNumbers.HomeTelephone.Number") ? serverErrors["Patient.CommunicationNumbers.HomeTelephone.Number"] : ''}
                  </span> */}

                {/* <label
                  htmlFor="BiologicalSex"
                  className="form-label m-0 font-12 text--secondary fw-sb"
                >
                  Supervising Physician <span>&#42;</span>
                </label>
                <select
                  onChange={genderHandler}
                  className="form-select font-14 border--default br-10 shadow-none input"
                  aria-label="biologicalSex"
                  id="biologicalSex"
                  required
                  style={{ borderRadius: "10px" }}
                >
                  <option value="">Select</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select> */}


                <Stack direction="column">
                  <label
                    htmlFor="UserStatus"
                    className="form-label ms-2 font-12 text--secondary fw-sb mb-0"
                  >
                    User Status
                  </label>
                  <Stack direction="row" justifyContent={"between"} spacing={2}>
                    <div className="col-sm-12" style={{ backgroundColor: "#ffffff", border: "1px solid #D9E8EC", borderRadius: '10px' }}>
                      <div className="row">
                        <div className="col-sm-8 justify-content-start align-self-center" >
                          <Typography component="span">
                            <Box
                              sx={{
                                m: 1,
                                fontFamily: "Montserrat",
                                fontWeight: "600",
                                fontSize: "12px",
                                color: "#323232",
                              }}
                            >
                              {twoFactorState === true ? "Active" : "Inactive"}
                            </Box>
                          </Typography>
                        </div>
                        <div className="col-sm-4 justify-content-end align-self-center" >
                          <TwoFactorSwitch {...label} checked={twoFactorState} onChange={twoFactorSwitch} />
                        </div>
                      </div>
                    </div>
                  </Stack>
                </Stack>


              </div>

            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ border: "none" }}>
        <button
          type="submit"
          className={`btn bg--blue fw-sb font-14 text-white b2 px-4 br-10 py-1 shadow-none ${isSending ? 'disabled' : ''}`}
          form="patientForm"

        >
          {isSending ? "Adding..." : "Add User"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewUserModal;

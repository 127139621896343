import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import sendHttpRequest from "../../hooks/sendHttpRequest";
import { setAppointments } from "../../../redux/actions/providerActions";
import { textAlign } from "@mui/system";
import {
  calenderDateTimeHelper,
  convertUTCtoTimzone,
  dateDifference,
  dateTimeHelper,
  getDMYformate,
  getMonthByDate,
  getMonthNameWithYear,
  getNextMonth,
} from "../../../utils/dateTimeHelper";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useAuth } from "../../hooks/useAuth";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FAFCFC",
    color: "#336383",
    fontWeight: 400,
    fontSize: 12,
    fontFamily: "Montserrat",
    textAlign: "center",
    border: 0,
  },

  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: "0px",
    border: "1px solid #D9E8EC",
    fontFamily: "Montserrat",
    borderLeft: 0,
    overflowX: "hidden",
    overflowY: "hidden",
    // borderRight:0,
    // textAlign:"center"
    // borderLeft:0,
    // borderRight:0,
    // borderBottom:0,
  },

  "&:last-child ": {
    borderRight: 0,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    // backgroundColor: "#FAFCFC",
    height: "42px",
    // height: props?.isHeight === true ? "80px" : "42px",
    borderRight: 0,
    overflowX: "hidden",
    overflowY: "hidden",
  },
  "&:nth-of-type(odd)": {
    // backgroundColor: "#FAFCFC",
    height: "42px",
    // height: props?.isHeight === true ? "80px" : "42px",
    borderRight: 0,
    overflowX: "hidden",
    overflowY: "hidden",
  },

  "&:last-child td, &:last-child th": {
    borderBottom: 0,
  },
}));

const StyledTableRowNA = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    // backgroundColor: "#FAFCFC",
    height: "80px",
    // height: props?.isHeight === true ? "80px" : "42px",
    borderRight: 0,
    overflowX: "hidden",
    overflowY: "hidden",
  },
  "&:nth-of-type(odd)": {
    // backgroundColor: "#FAFCFC",
    height: "80px",
    // height: props?.isHeight === true ? "80px" : "42px",
    borderRight: 0,
    overflowX: "hidden",
    overflowY: "hidden",
  },

  "&:last-child td, &:last-child th": {
    borderBottom: 0,
  },
}));

const AppoinmentCalender = (props) => {
  let count = 0;
  const date = new Date();
  const {user} = useAuth();
  const [userTimeZone,setUserTimeZone] = useState(props?.slotsTimezoneOffset ?? user.timezone);
  const [calenderDates, setCalenderDates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let currentMonthValue = getMonthByDate(
    convertUTCtoTimzone(new Date(),userTimeZone,"YYYY-MM-DD")
  );
  let curentDate = dateTimeHelper(date, "YYYY-MM");
  const [calenderDate, setCalenderDate] = useState(calenderDateTimeHelper(props?.appointmentDate,"YYYY-MM"));
  const [calendarMonth, setCalendarMonth] = useState(calenderDate);
  const [previousSelectedCell, setPreviousSelectedCell] = useState();
  const [selectedCell, setSelectedCell] = useState();
  const [currentDateElementRef, setCurrentDateElementRef] = useState();
  const [aptDate, setAptDate] = useState(props?.appointmentDate)
  const [currentDateRef, setCurrentDateRef] = useState();
  const [isCalendarLoading,setIsCalendarLoading] = useState(false);

  // let appoinmentMonthValue = getMonthByDate(props.Data.date);

  const fetchCalenderData = async (date) => {
    setIsLoading(true);
    setCalenderDates([]);
    setCurrentDateRef(undefined);
    setPreviousSelectedCell(undefined);
    setCurrentDateElementRef(undefined);
    const httpResponse = await sendHttpRequest({
      url: "/availabilities/calendar",
      method: "GET",
      params: {
        yearMonth: date,
      },
    });
    if (!httpResponse.error) {
      // console.log("Calendar data: ", httpResponse.data);
      setCalenderDates(httpResponse.data);
      if (props?.setScheduleDateToSend !== undefined && props?.setScheduleDateToSend !== null) {
        setApptSendDate(httpResponse.data);
      }
    } 
    setIsLoading(false);
  };
  
  const setApptSendDate = (dateArr) => {
    dateArr.forEach(element => {
      if (element.date === convertUTCtoTimzone(new Date(), userTimeZone, "YYYY-MM-DD")) {
          props?.setScheduleDateToSend(element.date); 
        }
    });
  }

  const handleNextMonth = (event) => {
    event.preventDefault();
    let nextMonthDate = getNextMonth(calendarMonth, 1);
    setAptDate(undefined)

    unHighlightCurrentDate(currentDateRef)
    fetchCalenderData(nextMonthDate);
    setCalendarMonth(nextMonthDate);
    if (props?.EnableDirtyCheck) {
      props?.onDirty();
    }
    if (previousSelectedCell !== undefined) {
      previousSelectedCell.style.color = "#336383";
      previousSelectedCell.style.background = "#FFF";

    }
  };
  const handlePreviousMonth = (event) => {
    event.preventDefault();
    let previousMonthDate = getNextMonth(calendarMonth, -1);
    setAptDate(undefined)
    unHighlightCurrentDate(currentDateRef)
    fetchCalenderData(previousMonthDate);
    setCalendarMonth(previousMonthDate);
    if (props?.EnableDirtyCheck) {
      props?.onDirty();
    }
    if (previousSelectedCell !== undefined) {
      previousSelectedCell.style.color = "#336383";
      previousSelectedCell.style.background = "#FFF";
    }
  };
  // const handleCurrentMonth = () => {
  //   // setSelectedCellDate(dateTimeHelper(new Date(), "dddd, MMM DD YYYY"));
  //   setCalendarMonth(calenderDate);
  //   fetchCalenderData(calenderDate);
  // };
  const handleCellBackground = (event) => {

    event.preventDefault();
    if(dateDifference(event.target?.id,convertUTCtoTimzone(new Date(),userTimeZone, "YYYY-MM-DD")) > 0 ){
      return;
    }
    if (props?.isHeight === true) {
      if (
        dateDifference(
        convertUTCtoTimzone(new Date(),userTimeZone, "YYYY-MM-DD"),
        event.target?.id
      ) >= 0) {
        props.fecthFreeSlots1(event.target.id)
      }
    }
    if (props.providerID !== 0 && dateDifference(
      convertUTCtoTimzone(new Date(),userTimeZone, "YYYY-MM-DD"),
      event.target?.id
    ) >= 0) {
      props.fecthFreeSlots1(props?.providerID, props?.appointmentType?.key, event.target.id, props?.appointmentType?.durationMinutes)
    }

    if (props?.EnableDirtyCheck) {
      props?.onDirty();
    }
    if (
      previousSelectedCell === undefined &&
      dateDifference(
        convertUTCtoTimzone(new Date(),userTimeZone, "YYYY-MM-DD"),
        event.target.id
      ) >= 0
    ) {
      props.setScheduleDate(event.target.id);
      if(props?.setScheduleDateToSend !== null && props?.setScheduleDateToSend !== undefined){
        props?.setScheduleDateToSend(event.target.id);
      }
      if (
        currentDateElementRef !== undefined &&
        currentDateElementRef !== null
      ) {
        currentDateElementRef.style.color = "#336383";
        currentDateElementRef.style.background = "#FFF";
      }
      event.target.style.background = "#336383";
      event.target.style.color = "#FFF";
      setPreviousSelectedCell(event.target);
    }
    // (previousSelectedCell !== undefined && selectedCell === undefined){
    else {
      if (
        dateDifference(
          convertUTCtoTimzone(new Date(),userTimeZone, "YYYY-MM-DD"),
          event.target?.id
        ) >= 0
      ) {
        props.setScheduleDate(event.target.id);
        if(props?.setScheduleDateToSend !== null && props?.setScheduleDateToSend !== undefined){
          props?.setScheduleDateToSend(event.target.id);
        }
        event.target.style.color = "#FFF";
        event.target.style.background = "#336383";
        previousSelectedCell.style.color = "#336383";
        previousSelectedCell.style.background = "#FFF";
        setPreviousSelectedCell(event.target);
      }
      setCurrentDateElementRef(null);

      // if(getMonthByDate(previousSelectedCell.style.id) === currentMonthValue)
    }
    //To keep selected cell remain same
    if(event.target.id === props?.scheduleDate){
      event.target.style.color = "#FFF";
      event.target.style.background = "#336383";  
    }
  };

  const handleColor = (id) => {
    let elementRef = document.getElementById(id);
    // console.log("elementRef",elementRef);
    if (currentDateRef === undefined) {
      setCurrentDateRef(id)
    }
    if (
      elementRef !== undefined &&
      elementRef !== null &&
      previousSelectedCell === undefined
    ) {
      // console.log(elementRef);
      elementRef.style.color = "#FFF";
      elementRef.style.background = "#336383";
      if (currentDateElementRef === undefined) {
        setCurrentDateElementRef(elementRef);
      }
      // setPreviousSelectedCell(elementRef)
    }
  };

  const unHighlightCurrentDate = (id) => {
    if (id !== undefined) {
      try {
        let elementRef = document.getElementById(id);
        elementRef.style.color = "#336383";
        elementRef.style.background = "#FFF";
      }
      catch {

      }
    }
  }

  useEffect(() => {
    
    setCalendarMonth(props?.appointmentDate);
    setAptDate(props?.appointmentDate);
    if(props?.slotsTimezoneOffset !== undefined){
      setUserTimeZone(props?.slotsTimezoneOffset)
    }
    fetchCalenderData(calenderDate);
    
    // console.log("props?.appointmentDate",props?.appointmentDate,props?.slotsTimezoneOffset);
  }, [props?.appointmentDate,props?.slotsTimezoneOffset,props?.patientId]);
  return (
    <div>
      <div className="form-group mt-3  d-flex flex-row justify-content-between">
        <div className="align-self-center text--terciary fw-sb font-16">
          {getMonthNameWithYear(calendarMonth)}
        </div>
        <div className="mb-1">
          {/* <div className="align-self-center ms-2"> */}
          <button
            className="p-1  br-10 bg--background  "
            style={{
              border: "1px solid #D9E8EC",
              borderRadius: "10px",
              background: "#FAFCFC",
              width: "40px",
              height: "40px",
            }}
            onClick={(e) => handlePreviousMonth(e)}
          >
            <ChevronLeftIcon className="text--primary" />
          </button>
          {/* </div> */}
          {/* <div className="align-self-center ms-2"> */}
          <button
            className="p-1  br-10 bg--background  ms-2"
            style={{
              border: "1px solid #D9E8EC",
              borderRadius: "10px",
              background: "#FAFCFC",
              width: "40px",
              height: "40px",
            }}
            onClick={(e) => handleNextMonth(e)}
          >
            <ChevronRightIcon className="text--primary" />
          </button>
          {/* </div> */}
        </div>
      </div>

        <TableContainer
          sx={{
            borderRadius: "16px",
            border: "1px solid #D9E8EC",
            overflowX: "hidden",
            overflowY: "hidden",
          }}
        >
          <Table size="small" className="mt-2">
            <TableHead
              sx={{
                // borderTopColor: "#FAFCFC",
                // borderRightColor: "#FAFCFC",
                // borderLeftColor: "#FAFCFC",
                borderBottom: 0,
                border: 0,
              }}
            >
              {
                props?.isHeight === true ?
                  <StyledTableRowNA style={{ height: 10 }}>
                    <StyledTableCell sx={{ maxWidth: "48px" }}>Sun</StyledTableCell>
                    <StyledTableCell sx={{ maxWidth: "48px" }}>Mon</StyledTableCell>
                    <StyledTableCell sx={{ maxWidth: "48px" }}>Tue</StyledTableCell>
                    <StyledTableCell sx={{ maxWidth: "48px" }}>Wed</StyledTableCell>
                    <StyledTableCell sx={{ maxWidth: "48px" }}>Thu</StyledTableCell>
                    <StyledTableCell sx={{ maxWidth: "48px" }}>Fri</StyledTableCell>
                    <StyledTableCell sx={{ maxWidth: "48px" }}>Sat</StyledTableCell>
                  </StyledTableRowNA>
                  :
                  <StyledTableRow style={{ height: 10 }}>
                    <StyledTableCell sx={{ maxWidth: "48px" }}>Sun</StyledTableCell>
                    <StyledTableCell sx={{ maxWidth: "48px" }}>Mon</StyledTableCell>
                    <StyledTableCell sx={{ maxWidth: "48px" }}>Tue</StyledTableCell>
                    <StyledTableCell sx={{ maxWidth: "48px" }}>Wed</StyledTableCell>
                    <StyledTableCell sx={{ maxWidth: "48px" }}>Thu</StyledTableCell>
                    <StyledTableCell sx={{ maxWidth: "48px" }}>Fri</StyledTableCell>
                    <StyledTableCell sx={{ maxWidth: "48px" }}>Sat</StyledTableCell>
                  </StyledTableRow>
              }
            </TableHead>
            {isLoading ? (
              props?.isHeight === true ?
                <StyledTableRowNA>
                  <StyledTableCell sx={{}}>
                    <Skeleton animation="wave" variant="rectangle" width="100%" />
                  </StyledTableCell>
                  <StyledTableCell sx={{}}>
                    <Skeleton animation="wave" variant="rectangle" width="100%" />
                  </StyledTableCell>
                  <StyledTableCell sx={{}}>
                    <Skeleton animation="wave" variant="rectangle" width="100%" />
                  </StyledTableCell>
                  <StyledTableCell sx={{}}>
                    <Skeleton animation="wave" variant="rectangle" width="100%" />
                  </StyledTableCell>
                  <StyledTableCell sx={{}}>
                    <Skeleton animation="wave" variant="rectangle" width="100%" />
                  </StyledTableCell>
                  <StyledTableCell sx={{}}>
                    <Skeleton animation="wave" variant="rectangle" width="100%" />
                  </StyledTableCell>
                  <StyledTableCell sx={{}}>
                    <Skeleton animation="wave" variant="rectangle" width="100%" />
                  </StyledTableCell>
                </StyledTableRowNA>
                :
                <StyledTableRow>
                  <StyledTableCell sx={{}}>
                    <Skeleton animation="wave" variant="rectangle" width="100%" />
                  </StyledTableCell>
                  <StyledTableCell sx={{}}>
                    <Skeleton animation="wave" variant="rectangle" width="100%" />
                  </StyledTableCell>
                  <StyledTableCell sx={{}}>
                    <Skeleton animation="wave" variant="rectangle" width="100%" />
                  </StyledTableCell>
                  <StyledTableCell sx={{}}>
                    <Skeleton animation="wave" variant="rectangle" width="100%" />
                  </StyledTableCell>
                  <StyledTableCell sx={{}}>
                    <Skeleton animation="wave" variant="rectangle" width="100%" />
                  </StyledTableCell>
                  <StyledTableCell sx={{}}>
                    <Skeleton animation="wave" variant="rectangle" width="100%" />
                  </StyledTableCell>
                  <StyledTableCell sx={{}}>
                    <Skeleton animation="wave" variant="rectangle" width="100%" />
                  </StyledTableCell>
                </StyledTableRow>
            ) : (
              <TableBody>
                { calenderDates !== undefined &&
                  calenderDates.length > 0 &&
                  // console.log("calenderDates",calenderDates[0])
                  [1, 2, 3, 4, 5, 6].map(() => {
                    if (count < 42) {
                      // console.log("calenderDates",calenderDates);

                      count = count + 7;

                      // console.log(dateDifference(new Date(),calenderDates[count-7]),new Date(),calenderDates[count-7]);
                      return (
                        props?.isHeight === true
                          ?
                          <StyledTableRowNA
                            sx={{
                              "&:first-child td, &:first-child th": {
                                borderLeft: 0,
                              },
                            }}
                          >
                            {
                              [7, 6, 5, 4, 3, 2, 1].map((item) => {
                                return (
                                  <StyledTableCell
                                    id={calenderDateTimeHelper(
                                      calenderDates[count - item].date,
                                      "YYYY-MM-DD"
                                    )}
                                    value={calenderDates[count - item].date}
                                    align="center"
                                    style={{
                                      verticalAlign: "center",
                                      maxWidth: "48px",
                                      maxHeight: "40px",
                                    }}
                                    onClick={(e) => handleCellBackground(e)}
                                    sx={
                                      currentMonthValue ===
                                        getMonthByDate(calenderDates[count - item].date)
                                        ? { color: "#336383", fontWeight: "600" }
                                        : { color: "#336383" }
                                    }
                                    className={
                                      dateDifference(
                                        convertUTCtoTimzone(new Date(),userTimeZone, "YYYY-MM-DD"),
                                        calenderDates[count - item].date
                                      ) >= 0
                                        ? "bg--white"
                                        : "bg--background "
                                    }
                                  >
                                    {aptDate !== undefined && calenderDateTimeHelper(
                                      aptDate,
                                      "YYYY-MM-DD"
                                    ) === calenderDates[count - item].date &&
                                      handleColor(calenderDates[count - item].date)}
                                    {calenderDateTimeHelper(
                                      calenderDates[count - item].date,
                                      "D"
                                    )}
                                  </StyledTableCell>

                                )
                              })
                            }

                          </StyledTableRowNA>
                          :
                          <StyledTableRow
                            sx={{
                              "&:first-child td, &:first-child th": {
                                borderLeft: 0,
                              },
                            }}
                          >
                            {
                              [7, 6, 5, 4, 3, 2, 1].map((item) => {
                                return (
                                  <StyledTableCell
                                    id={calenderDateTimeHelper(
                                      calenderDates[count - item].date,
                                      "YYYY-MM-DD"
                                    )}
                                    value={calenderDates[count - item].date}
                                    align="center"
                                    style={{
                                      verticalAlign: "center",
                                      maxWidth: "48px",
                                      maxHeight: "40px",
                                    }}
                                    onClick={(e) => handleCellBackground(e)}
                                    sx={
                                      currentMonthValue ===
                                        getMonthByDate(calenderDates[count - item].date)
                                        ? { color: "#336383", fontWeight: "600" }
                                        : { color: "#336383" }
                                    }
                                    className={
                                      dateDifference(
                                        convertUTCtoTimzone(new Date(),userTimeZone, "YYYY-MM-DD"),
                                        calenderDates[count - item].date
                                      ) >= 0
                                        ? "bg--white"
                                        : "bg--background "
                                    }
                                  >
                                    {aptDate !== undefined && calenderDateTimeHelper(
                                      aptDate,
                                      "YYYY-MM-DD"
                                    ) === calenderDates[count - item].date &&
                                      handleColor(calenderDates[count - item].date)}
                                    {calenderDateTimeHelper(
                                      calenderDates[count - item].date,
                                      "D"
                                    )}
                                  </StyledTableCell>

                                )
                              })
                            }

                          </StyledTableRow>
                      );
                    }
                  })}
              </TableBody>
            )}
          </Table>
          {/* {console.log("props.appointmentDate",props.appointmentDate)} */}
        </TableContainer>
          {/* {console.log("aptDate",aptDate)} */}
          {/* {console.log("slotsTimezoneOffset",props?.slotsTimezoneOffset,userTimeZone)} */}
    </div>
  );
};

export default AppoinmentCalender;
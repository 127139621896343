import { Modal } from "react-bootstrap";
import classes from "../Modals/leaveAssesment.module.css";
const LeaveAssessment = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.continueAssessment();
      }}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName={`${classes.leavemodal__size}`}
      contentClassName={`${classes.modal_border} ${classes.modal_shadow}`}
      backdropClassName={`${classes.modal_backdrop}`}
      centered
    // style={{style}}
    >
      <Modal.Header closeButton style={{ border: "none" }}>
        {/* <Modal.Title id="contained-modal-title-vcenter">
                    {props.HeaderTitle}
                </Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row text-center mt-3 justify-content-center">
            <div className="col-12">
              <p className="font-16 fw-sb text--secondary">
                {`Are you sure you would like to quit the ${props?.patientDemographics === true ? "Scheduling":"Assessment"} ?`}
              </p>
              <button
                className="w-100 btn fw-sb b1 text--white btn--border blue_task_button px-4 br-10 py-1 btn btn__bottom--leave"
                style={{}}
                onClick={() => {
                  props.continueAssessment();
                }}
              >
                Continue
              </button>
              <button
                className="w-100 btn fw-sb b1 mt-2 text--white d9e8ec--button btn--border text--blue px-4 br-10 py-1 btn btn__bottom--leave"
                style={{}}
                onClick={() => {
                  props.closeAssesment();
                }}
              >
                Leave
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LeaveAssessment;

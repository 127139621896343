import React from 'react'
import classes from "./LiveChatContainer.module.css";
import avatar from "../../../assets/images/placeholder.png";
import { X, Send } from 'react-bootstrap-icons';
import { Avatar } from '@mui/material';

const SingleMessage  = ({type, userImage, userName, message}) => {
    
    return(
        <div className="py-2">
            <div className={`${classes['message-header']} ${type === 'sender' ? `${classes['message-sender']}` : `${classes['message-receiver']}`}`}>
                <Avatar alt="User Image" src={avatar} /> <span className=" fw-sb font-12">{userName}</span>
            </div>
            <div className={`${classes['message-content']} ${type === 'sender' ? 'flex-row':'flex-row-reverse'}`}>
                <div className={`font-12 fw-thin text--terciary ${classes['message-content-text']}`} style={{ lineHeight: '16px' }}>{message}</div>
            </div>
        </div>
    );
}

const LiveChatContainer = ({setShowMessages}) => {
  return (
    <>
        <div className={`${classes['chat-container']}`}>
            <div className={`${classes['chat-container-header']}`}>
                <span onClick={()=>{setShowMessages((prev)=>(!prev))}} className="d-inline-block font-16 fw-sb text--terciary">Live Chat</span><X className="text--secondary align-self-center cross-sign" style={{backgroundColor:'white', borderRadius:'5px'}} />

            </div>
            <SingleMessage type="sender" userName={"John Provider"} message={"Hi Jane, your mic is muted. Kindly unmute your mic."} />
            <SingleMessage type="receiver" userName={"Jane Patient"} message={"Hi John, doing it. Give me a sec."} />
            <SingleMessage type="sender" userName={"John Provider"} message={"Hi Jane, your mic is muted. Kindly unmute your mic."} />
            <SingleMessage type="receiver" userName={"Jane Patient"} message={"Hi John, doing it. Give me a sec."} />
            <SingleMessage type="sender" userName={"John Provider"} message={"Hi Jane, your mic is muted. Kindly unmute your mic."} />
            <SingleMessage type="receiver" userName={"Jane Patient"} message={"Hi John, doing it. Give me a sec."} />
            <SingleMessage type="sender" userName={"John Provider"} message={"Hi Jane, your mic is muted. Kindly unmute your mic."} />
            <SingleMessage type="receiver" userName={"Jane Patient"} message={"Hi John, doing it. Give me a sec."} />
            <SingleMessage type="sender" userName={"John Provider"} message={"Hi Jane, your mic is muted. Kindly unmute your mic."} />
            <SingleMessage type="receiver" userName={"Jane Patient"} message={"Hi John, doing it. Give me a sec."} />

            <div className={`bg-white br-10 p-1  mb-2 ${classes['new-message-container']}`}>
                <div className='d-flex justify-content-between gap-2 align-items-center'>
                    <div className="w-100">
                        <textarea resize="none" style={{resize:'none'}} rows="1" className='form-control input text-area-scrollbar shadow-none  border--default br-10 font-12' placeholder='Write your message' />
                    </div>
                    <div>
                        <button className={`${classes['send-button']} btn shadow-none d-flex jusitfy-content-center align-items-center`}><Send className='text--secondary fw-sb' size={24} /> </button>
                    </div>
                </div>

            </div>
        </div>
    </>
  )
}

export default LiveChatContainer
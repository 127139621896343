import { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import CalenderDatePicker from "../UI/General/CalenderDatePicker";
import { useNavigate } from "react-router-dom";

const AppointmentBarChart = ({ appointmentData }) => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(""); // Start date filter
  const [endDate, setEndDate] = useState(""); // End date filter
  const chartRef = useRef(null); // Reference to the canvas
  const chartInstance = useRef(null); // To store the Chart.js instance

  const processAppointmentData = (data) => {
    const statusCounts = {
    //   scheduled: 0,
      pending: 0,
      started: 0,
      completed: 0,
      canceled: 0,
      noshow:0,
    };

    data.forEach((appointment) => {
      const { status } = appointment;

      if (statusCounts[status] !== undefined) {
        statusCounts[status] += 1;
      }
    });

    return statusCounts;
  };

  const handleStartDate = (dateVal) => {
    setStartDate(dateVal);
  };

  const handleEndDate = (dateVal) => {
    setEndDate(dateVal);
  };

  function navigateToClaimsPage(routeVal, status) {
    navigate(routeVal, {
      state: { claimType: status, startDate: startDate, endDate: endDate },
    });
  }

  useEffect(() => {
    // const appointmentStats = processAppointmentData(appointmentData);
    const appointmentStats = appointmentData;

    const data = {
      labels: ["In Progress", "Started", "Completed", "Canceled","No Show"],
      datasets: [
        {
          // label: "Appointment Status",
          data: [
            // appointmentStats?.scheduled ?? 0,
            appointmentStats?.pending ?? 0,
            appointmentStats?.started ?? 0,
            appointmentStats?.completed ?? 0,
            appointmentStats?.canceled ?? 0,
            appointmentStats?.noshow ?? 0,
          ],
          backgroundColor: [
            // "#4B8BBE", // Scheduled - Blue
            "#FF8C42", // Pending - Orange
            "#18C383", // Started - Teal Green
            "#6A4C93", // Completed - Purple
            "#E63946", // Canceled - Coral Red
            "#336383", // no show - secondary
          ],
          borderColor: [
            // "#3B71A1", // Scheduled - Darker Blue
            "#D86C3A", // Pending - Darker Orange
            "#18C383", // Started - Darker Teal Green
            "#553074", // Completed - Darker Purple
            "#B3273E", // Canceled - Darker Coral Red
            "#336383",// no show - secondary
          ],
          borderWidth: 1, // Add a border to each bar
        },
      ],
    };

    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: "top",
        },
        title: {
          display: true,
          text: "Appointment Bar Chart",
        },
      },
      scales: {
        y: {
          beginAtZero: true, // Ensure the y-axis starts at 0
          title: {
            display: true,
            text: "Number of Appointments",
          },
        },
      },
    };

    // Destroy the existing chart instance if it exists to avoid memory leaks
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    // Create the new chart instance
    chartInstance.current = new Chart(chartRef.current, {
      type: "bar", // Change chart type to 'bar'
      data: data,
      options: options,
    });

    // Cleanup the chart when the component unmounts
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [appointmentData, startDate, endDate]); // Recreate chart when appointmentData, startDate, or endDate changes

  // console.log("appointmentData: ", appointmentData);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <canvas ref={chartRef} />
      </div>
    </>
  );
};

export default AppointmentBarChart;

/*
    MUI Component
*/
import classes from "./PatientPaymentPage.module.css";
import InputMask from "react-input-mask";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import { useTimer } from "react-timer-hook";
import { DateTime } from "luxon";
import {
  Backdrop,
  Box,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import options from "../../utils/data";
import getYear from "date-fns/getYear";
import { useEffect, useState } from "react";
import { ChevronLeft } from "@mui/icons-material";
import PatientNavbar from "../../components/UI/PatientNavbar";
import CCIcon from "../../assets/icons/CCIcon";
import PayPalIcon from "../../assets/icons/PayPalIcon";
import { useLocation, useNavigate } from "react-router-dom";
import {
  changeDateTimeFormat,
  dateTimeHelper,
  getLocalTimeInUTC,
  getTimeDifference,
} from "../../utils/dateTimeHelper";
import moment, { utc } from "moment";
import cardValidator from "card-validator";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import sendHttpRequest from "../../components/hooks/sendHttpRequest";
import CustomizedSnackbars from "../../components/UI/General/CustomizedSnackbars";
import { client, hostedFields } from "braintree-web";
import * as ReactDOMServer from "react-dom/server";
import { useAuth } from "../../components/hooks/useAuth";
import { cardsMap } from "../../utils/helpers/HelperFunctions";
import { transferAppointmentWithPayment, transferPayment } from "../../res/endpointurls";
import { getPaymentToken } from "../../res/endpointurls";
import EventIcon from "@mui/icons-material/Event";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import LeaveAssessment from "../../components/forms/intakeform/Modals/LeaveAssesment";
import { useRef } from "react";
import { PaymentMethodTypes } from "../../components/payment/MakePaymentModal";
import GooglePayButton from "../../components/payment/googlePay/GooglePayButton";
import ApplePay from "../../components/payment/ApplePay";
import PatientGooglePayForm from "../../components/payment/googlePay/PatientGooglePayForm";
import PatientApplePayForm from "../../components/payment/PatientApplePayForm";
import CurrencyInput from "react-currency-input-field";
import { useSelector } from "react-redux";

const Timer = (props) => {
  let expiryTimestamp = props.expiryTimestamp;

  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({ expiryTimestamp, onExpire: props.timeExpired });

  useEffect(() => {
    return () => {
      expiryTimestamp.setSeconds(0);
    };
  }, []);

  return (
    <>
      <span>{minutes}</span>:
      <span>{seconds?.toString()?.padStart(2, "0")}</span>
    </>
  );
};

function CreditCardInput(props) {
  return (
    <InputMask
      mask="9999 9999 9999 9999"
      value={props.value}
      maskChar="-"
      className="form-control font-14 border--default br-10 shadow-none input"
      onChange={props.onChange}
      onInvalid={(e) => {
        e.target.setCustomValidity("Please fill out this field");
      }}
      onInput={(e) => {
        e.target.setCustomValidity("");
      }}
      {...props}
    ></InputMask>
  );
}

const InvoiceItem = (props) => {
  return (
    <>
      <div className="col-sm-6 pt-1">
        <span className="text-secondary font-12 fw-sb">{props.title}</span>
      </div>
      <div className="col-sm-6 align-self-center text-end">
        <span className="font-12 text--secondary fw-sb">
          {props.description}
        </span>
      </div>
    </>
  );
};
export default function PatientPaymentPage(props) {
  function range(start, end, increment) {
    const list = [];
    for (let i = start; i < end; i = i + increment) {
      list.push(start++);
    }
    return list;
  }

  const patientInsuranceData = useSelector((state)=>state.patientInsuranceData.patientInsurance);
  
  const [leaveModal, setLeaveModal] = useState(false);
  const [thanksModal, setThanksModal] = useState(false);
  const { signout } = useAuth();

  const location = useLocation();
  const navigate = useNavigate();
  // const navigate = useNavigate();
  const [copayValue,setCopayValue] = useState(0);

  const [amountInput,setAmountInput] = useState(location.state.price);
  const logout = async () => {
    const httpResponse = await sendHttpRequest({
      url: "/auth/logout",
      method: "POST",
      data: {},
    });
    if (!httpResponse.error) {
      // removeCookie("providerId");
      localStorage.clear();
      signout();

      // console.log(httpResponse.data);
    } else {
      // console.log(httpResponse.error);
    }
    // setIsLoading(false);
  };

  function closeAssesment() {
    let eqPos = document.cookie.indexOf("=");
    let name = eqPos > -1 ? document.cookie.substr(0, eqPos) : document.cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    setLeaveModal(!leaveModal);
    setThanksModal(!thanksModal);
    // logout();
    navigate("/patient-portal/dashboard", { replace: true });
    // setTimeout(() => {
    //     window.location.replace("https://www.prescribenow.com");
    // }, 50)
  }

  function continueAssessment() {
    setLeaveModal(!leaveModal);
  }

  const months = moment()._locale._months;
  const years = range(getYear(new Date()), getYear(new Date()) + 10, 1);

  const utcDate = DateTime.fromISO(moment.utc().format()).toUTC();
  const expiryTimestamp = utcDate.toJSDate();
  const difference = getTimeDifference(
    dateTimeHelper(utcDate.toISO()),
    dateTimeHelper(location.state?.expirationTime)
  );
  expiryTimestamp.setSeconds(parseInt(difference.seconds));

  const [isCreditCard, setIsCreditCard] = useState(true);
  const [isNewCreditCard, setIsNewCreditCard] = useState(false);
  const [isOldCreditCard, setIsOldCreditCard] = useState(false);
  const [creditCardType, setCreditCardType] = useState(null);
  const [isPaypal, setIsPaypal] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [cvv, setCvv] = useState("");
  const [cardCvvId, setCardCvvId] = useState("");
  const [nameOnCC, setNameOnCC] = useState("");
  const [ccNum, setCcNum] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [selectedState, setSelectedState] = useState([]);
  const [zipcode, setZipcode] = useState("");
  const [isSave, setIsSave] = useState(false);
  const [error, setError] = useState("");
  const [hostedFieldsInstance, setHostedFieldsInstance] = useState("");
  const [tokenizeErr, setTokenizeErr] = useState("");
  const [token, setToken] = useState("");
  const [oldCards, setOldCards] = useState([]);
  const [cardInfo, setCardInfo] = useState([]);
  const [isAllFieldsFilled, setIsAllFieldsFilled] = useState(false);

  const map = new Map();

  const [isTimeOver, setIsTimeOver] = useState(
    difference.seconds > 0 ? false : true
  );
  const [isTimeOverSnackbar, setIsTimeOverSnackbar] = useState(false);
  const [isApptCreated, setIsApptCreated] = useState(false);
  const [oldCreditToSubmit, setOldCreditToSubmit] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const confirmButtonRef = useRef();
  const { user } = useAuth();
  const [isPaymentDisabled,setIsPaymentDisabled] = useState(false)
  const [isValidAmount,setIsValidAmount] = useState(true);

  const [paymentType, setPaymentType] = useState();

  const s1 = {
    textAlign: "left",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "10px",
    color: "#336383",
  };

  const s2 = {
    textAlign: "left",
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "10px",
    color: "#336383",
  };

  const handleCreditCard = () => {
    setIsCreditCard(!isCreditCard);
    setIsPaypal(false);
  };

  const handleNewCreditCard = () => {
    tearDownFields();
    setIsNewCreditCard(true);
    setIsPaypal(false);
    setOldCreditToSubmit(false);
    setIsOldCreditCard(true);
    if (cardCvvId !== "") {
      document.getElementById(cardCvvId).children[1].remove();
      setCardCvvId("");
    }
  };

  const cvvMap = (oldcards) => {
    oldcards.length > 0 &&
      oldcards?.map((card, index) => {
        return map.set("p" + index, false);
      });
  };

  const handleOldCreditCard = (event) => {
    // cvvMap(oldCards);
    // map.set("p"+index, true);

    // let doc = document.querySelector(`[data-cvv-field-id]`);
    const index = event.target.getAttribute("data-cvv-field-id");
    let cctoken = document.getElementById("cctoken" + index);
    setCardInfo(cctoken.getAttribute("data-cc-token"));
    if (cardCvvId !== "" && index !== "p" + index) {
      // console.log(document.getElementById(cardCvvId).children);
      document.getElementById(cardCvvId).children[1].remove();
      setCardCvvId("");
    }
    let d = document.getElementById(`p` + index);
    d.insertAdjacentHTML(
      "beforeend",
      ReactDOMServer.renderToString(
        <div className="col-sm-12 align-self-center ps-2">
          <div className="col-md-6">
            <div className="col-md-12 mb-2">
              <label
                htmlFor="ccv"
                className="form-label m-0 font-10 text--secondary fw-sb"
              >
                CVV
              </label>
              <Typography component="span">
                <Box
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    fontSize: 12,
                    color: "#323232",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#ffffff",
                      border: "1px solid #D9E8EC",
                      borderRadius: 10,
                      p: 0,
                    }}
                  >
                    <div
                      id="old_cvv"
                      className={`${classes.hostedFields}`}
                    ></div>
                  </div>
                </Box>
              </Typography>
            </div>
          </div>
        </div>
      )
    );
    // d.innerHTML = '<div className="col-sm-12 align-self-center ps-2" ><div className="col-md-6"><div className="col-md-12 mb-2"><label htmlFor="ccv" className="form-label m-0 font-10 text--secondary fw-sb">{"CVV"}</label><Typography component="span"><Box sx={{ fontFamily: "Montserrat",fontWeight: 600,fontSize: "12px",color: "#323232" }}><div style={{ backgroundColor: "#ffffff", border: "1px solid #D9E8EC", borderRadius: "10px", p: 0 }}><div id="old_cvv" className={`${classes.hostedFields}`}></div></div></Box></Typography></div></div></div>';
    setCardCvvId("p" + index);
    handleCvvPayment();
    setOldCreditToSubmit(true);
    setIsOldCreditCard(false);
    setIsPaypal(false);
    setIsNewCreditCard(false);
  };

  const handlePayPal = () => {
    setIsPaypal(!isPaypal);
    setIsCreditCard(false);
    setIsNewCreditCard(false);
  };

  const timeExpired = () => {
    setIsTimeOver(true);
    // location?.state?.ExpirationTime = null;
    setIsTimeOverSnackbar(true);
    expiryTimestamp.setSeconds(0);
    setTimeout(() => {
      navigate("/patient-portal/dashoard", { replace: true });
    }, 2000);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsTimeOverSnackbar(false);
    setIsApptCreated(false);
  };

  const payViaCreditCard = async () => {
    // setIsTimeOver(true);
    const httpResponse = await sendHttpRequest({
      url: "/appointment/payment",
      method: "POST",
      data: {
        CardHolderName: nameOnCC,
        // CardNumber: ccNum,
        // ExpirationDate: selectedYear + "-" + selectedMonth,
        // CCV: cvv,
        BillingAddress: address,
        City: city,
        State: selectedState,
        PostalCode: zipcode,
      },
    });
    if (!httpResponse.error) {
      // console.log("httpResponse.data", httpResponse.data);
      setIsApptCreated(true);
      timeExpired();
    } else {
      if (httpResponse.status === 403) {
        setIsTimeOverSnackbar(true);
        timeExpired();
      } else {
        setIsTimeOver(false);
        setError(httpResponse.errorMessage);
      }
      console.log(httpResponse);
    }
    // setIsTimeOver(false);
  };

  const sendNounce = async (nounce, flag, token) => {
    console.log("Sending nounce " + nounce + " to endpoint " + transferAppointmentWithPayment);
    setIsTimeOver(true);
    const httpResponse = await sendHttpRequest({
      url: transferAppointmentWithPayment(user?.tenantId),
      method: "POST",
      data: {
        nounce: nounce,
        amount: Number(amountInput),
        userId: user?.userId,
        oldCard: flag,
        creditCardToken: token,
        saveCard: isSave,
        appointmentDto: JSON.parse(localStorage.getItem("apptDto")),
      },
    });
    if (!httpResponse.error) {
      setIsApptCreated(true);
      timeExpired();
      tearDownFields();
      // }
    } else {
      setIsTimeOver(false);
      if (httpResponse?.status === 500) {
        setError({ message: "Something went wrong" });
      } else {
        setError(
          httpResponse?.errorMessage ?? { message: "Payment not completed" }
        );
      }
    }
    // setIsTimeOver(false);
  };

  const getExtras = async () => {
    const httpResponse = await sendHttpRequest({
      url: "/payments/extras",
      method: "GET",
    });
    if (!httpResponse.error) {
      setOldCards(httpResponse.data);
      cvvMap(httpResponse.data);
      // console.log("httpResponse.data", httpResponse.data);
    } else {
      console.log(httpResponse);
    }
  };

  function displayErrorMessage(container, message) {
    // console.log("container", container);
    // Remove existing error message if present
    removeErrorMessage(container);

    // Create error message element
    var errorMessage = document.createElement("span");
    errorMessage.className = "error-message font-10 text--danger fw-thin";
    errorMessage.textContent = message;
    errorMessage.style.display = "block";
    errorMessage.style.marginTop = "6px";
    errorMessage.style.marginLeft = "-3px";
    setDisableButton(true);
    confirmButtonRef.current.disabled = true;

    // Append error message below the container
    // container.parentNode.insertBefore(errorMessage, container.nextSibling);
    container.appendChild(errorMessage);
  }

  function removeErrorMessage(container) {
    var errorMessage = container.parentNode.querySelector(".error-message");
    if (errorMessage) {
      // Remove error message if found
      setDisableButton(false);
      confirmButtonRef.current.disabled = false;
      errorMessage.parentNode.removeChild(errorMessage);
    }
  }

  const hostedForm = () => {
    client
      .create({
        authorization: token,
      })
      .then(function (clientInstance) {
        console.log("I'm in then ");
        
        hostedFields.create(
          {
            client: clientInstance,
            styles: {
              input: {
                "font-size": "12px",
                "font-style": "Montserrat",
              },
              "input.invalid": {
                color: "#FD0D1B",
              },
              "input.valid": {
                color: "#323232",
              },
              "::-webkit-input-placeholder": {
                color: "rgba(0,0,0,0.5)",
              },
              ":-ms-input-placeholder": {
                color: "rgba(0,0,0,0.5)",
              },
            },
            fields: {
              number: {
                container: "#card-number",
              },
              cvv: {
                container: "#cvv",
                placeholder: "•••",
              },
              expirationDate: {
                container: "#expiration-date",
              },
            },
          },
          function (hostedFieldsErr, hostedFieldsInstance) {
            if (hostedFieldsErr) {
              return;
            }
  
            setHostedFieldsInstance(hostedFieldsInstance);
  
            hostedFieldsInstance.on("notEmpty", function (event) {});
  
            hostedFieldsInstance.on("empty", function (event) {
              if (event.emittedBy === "number") {
                setCreditCardType(null);
              }
            });
  
            hostedFieldsInstance.on("cardTypeChange", function (event) {
              if (event.cards.length === 1) {
                setCreditCardType(event.cards[0].type);
  
                if (event.cards[0].code.size === 4) {
                  hostedFieldsInstance.setAttribute({
                    field: "cvv",
                    attribute: "placeholder",
                    value: "••••",
                  });
                }
              } else {
                hostedFieldsInstance.setAttribute({
                  field: "cvv",
                  attribute: "placeholder",
                  value: "•••",
                });
              }
            });
  
            hostedFieldsInstance.on("blur", handleFieldBlur);
  
            // Add event listener for input validation
            hostedFieldsInstance.on("validityChange", function (event) {
              var field = event.fields[event.emittedBy];
  
              if (field.isValid) {
                // Field is valid, remove error message
                removeErrorMessage(field.container);
              } else if (field.isPotentiallyValid) {
                // Field is potentially valid, remove error message if displayed
                removeErrorMessage(field.container);
              } else {
                // Field is invalid, display error message and set isAllFieldsFilled to false
                setIsAllFieldsFilled(false); // Set false on invalid field
  
                if (field.container.id === "card-number") {
                  displayErrorMessage(field.container, "Invalid card number");
                } else if (field.container.id === "expiration-date") {
                  displayErrorMessage(
                    field.container,
                    "Invalid expiration date"
                  );
                } else if (field.container.id === "cvv") {
                  displayErrorMessage(field.container, "Invalid CVV");
                }
              }
  
              // Check if all fields are valid and filled
              const initialFields = hostedFieldsInstance.getState().fields;
              const allFieldsValidAndFilled = checkAllFieldsFilledAndValid(
                initialFields
              );
              console.log("allFieldsValidAndFilled: ",allFieldsValidAndFilled);
              
              // If all fields are valid and filled, update setIsAllFieldsFilled
              setIsAllFieldsFilled(allFieldsValidAndFilled);
            });
          }
        );
      });
  };
  
  // Helper function to check if all fields are valid and filled
  function checkAllFieldsFilledAndValid(fields) {
    return Object.keys(fields).every(function (key) {
      const field = fields[key];
      console.log("field: ",field);
      
      return field.isValid && field.isFilled;
    });
  }
  
  const checkAllFieldsFilled = (fields) => {
    const fieldNames = Object.keys(fields);
    console.log(fieldNames);
    for (const fieldName of fieldNames) {
      if (fields[fieldName].isEmpty) {
        return false;
      }
    }
    return true;
  };

  const handleFieldBlur = (event) => {
    const field = event.fields[event.emittedBy];

    if (field.isEmpty) {
      displayErrorMessage(field.container, "Field is required");
    } else if (!field.isValid) {
      // Check if there is already an error message and the field is invalid
      const existingErrorMessage = getErrorMessage(field.container);
      if (!existingErrorMessage) {
        displayErrorMessage(field.container, "Invalid field");
      }
    } else {
      removeErrorMessage(field.container);
    }

    const allFieldsFilled = checkAllFieldsFilled(event.fields);
    setIsAllFieldsFilled(allFieldsFilled);
  };

  const tearDownFields = () => {
    if (hostedFieldsInstance !== "") {
      hostedFieldsInstance.teardown(function (teardownErr) {
        if (teardownErr) {
          // console.error('Could not tear down Hosted Fields!');
        } else {
          // console.info('Hosted Fields has been torn down!');
        }
      });
    }
  };

  const getErrorMessage = (container) => {
    const errorElement = container.querySelector(".error-message");
    if (errorElement) {
      return errorElement.textContent;
    }
    return null;
  };

  const handleCvvPayment = () => {
    if (hostedFieldsInstance !== "") {
      hostedFieldsInstance.teardown(function (teardownErr) {
        if (teardownErr) {
          // console.error('Could not tear down Hosted Fields!');
        } else {
          // console.info('Hosted Fields has been torn down!');
        }
      });
    }
    client
      .create({
        authorization: token,
      })
      .then(function (clientInstance) {
        hostedFields.create(
          {
            client: clientInstance,
            styles: {
              input: {
                "font-size": "12px",
                "font-style": "Montserrat",
              },
              "input.invalid": {
                color: "#FD0D1B",
              },
              "input.valid": {
                color: "#323232",
              },
              "::-webkit-input-placeholder": {
                color: "rgba(0,0,0,0.5)",
              },
              ":-ms-input-placeholder": {
                color: "rgba(0,0,0,0.5)",
              },
            },
            fields: {
              cvv: {
                container: "#old_cvv",
                placeholder: "•••",
              },
            },
          },
          function (hostedFieldsErr, hostedFieldsInstance) {
            if (hostedFieldsErr) {
              return;
            }
            setHostedFieldsInstance(hostedFieldsInstance);
          }
        );
      });
  };

  const tokenizeFields = (isOldCard) => {
    // let state = hostedFieldsInstance.getState();
    // let fields = Object.keys(state.fields);
    // console.log(state);
    hostedFieldsInstance.tokenize(
      {
        // billingAddress: isNewCreditCard === true ? {
        //     firstName: nameOnCC.split(" ").length > 0 ? nameOnCC.split(" ")[0] : "",
        //     lastName: nameOnCC.split(" ").length > 0 ? nameOnCC.split(" ")[nameOnCC.split(" ").length - 1] : "",
        //     // company: '',
        //     postalCode: zipcode,
        //     streetAddress: address,
        //     // extendedAddress: 'Unit 1',
        //     locality: city,
        //     countryName: selectedState?.length > 0 ? selectedState[0].name : "",
        //     countryCodeAlpha2: selectedState?.length > 0 ? selectedState[0].code : "",
        //     // countryCodeAlpha3: 'USA',
        //     // countryCodeNumeric: '840'
        // } : {}
      },
      function (tokenizeErr, payload) {
        if (tokenizeErr) {
          setTokenizeErr(tokenizeErr);
          setIsTimeOver(false);
          return;
        }
        if (isOldCard) {
          sendNounce(payload.nonce, true, cardInfo);
        } else {
          sendNounce(payload.nonce, false, null);
        }
      }
    );
  };

  const handlePayment = () => {
    if (oldCreditToSubmit) {
      setIsTimeOver(true);
      tokenizeFields(true);
    } else if (isCreditCard) {
      setIsTimeOver(true);
      tokenizeFields(false);
    } else if (isPaypal) {
    }
  };

  const addressHandler = (e) => {
    setAddress(e.target.value);
  };

  const cityHandler = (e) => {
    setCity(e.target.value);
  };

  const zipcodeHandler = (e) => {
    setZipcode(e.target.value);
  };

  const cardNameHolderHandler = (e) => {
    let name = e.target.value;
    setNameOnCC(name);
    
  };

  const clearStates = function () {
    setOldCards([]);
    setIsCreditCard(false);
    setIsOldCreditCard(false);
    setIsNewCreditCard(false);
    setCardCvvId("");
    setCreditCardType(null);
    setIsPaypal(false);
    setOpen(false);
    setSelectedMonth("");
    setSelectedYear("");
    setCvv("");
    setNameOnCC("");
    setCcNum("");
    setAddress("");
    setCity("");
    setSelectedState([]);
    setZipcode("");
    setIsSave(false);
    setError("");
    setIsTimeOver(false);
    setIsTimeOverSnackbar(false);
    setIsApptCreated(false);
    setHostedFieldsInstance("");
  };

  const saveCardHandler = (e) => {
    setIsSave(e.target.checked);
  };

  window.onload = function () {
    var reloading = sessionStorage.getItem("reloading");
    if (reloading) {
      sessionStorage.removeItem("reloading");
      navigate("/patient-portal/dasboard", { replace: true });
    }
    // expiryTimestamp.setSeconds(0);
  };

  async function getToken() {
    const httpResponse = await sendHttpRequest({
      // url: `/braintree/${user?.userId}/get_token`,
      url: getPaymentToken(user?.tenantId),
      method: "GET",
    });
    if (!httpResponse.error) {
      setToken(httpResponse.data);
      // braintreeClient(httpResponse.data)
      // setIsApptCreated(true);
      // timeExpired();
    } else {
      setIsTimeOver(false);
      setError(httpResponse.errorMessage);
      // console.log(httpResponse);
    }
    getExtras();
    // setIsTimeOver(false);
  }

  const handleServiceFee = (valueInp)=>{
    let value = Number(valueInp)
    setAmountInput(valueInp)
    if(copayValue > 0 && value < copayValue){
      setIsValidAmount(false)
    }else if (copayValue === 0 && value < location.state.price){
      setIsValidAmount(false)
    }else{
      setIsValidAmount(true)
    }
  }
  const handleCopayValue = (updateAmountInput)=>{
    const primaryInsurance = patientInsuranceData?.find(
      (insurance) => insurance?.type?.toLowerCase() === "primary"
    );
    if(primaryInsurance !== undefined  &&  primaryInsurance?.inNetworkCopay !== null && primaryInsurance?.inNetworkCopay !== undefined){
      let copayAmount = parseFloat(primaryInsurance?.inNetworkCopay.replace(/[^0-9.-]+/g, ""));
      setCopayValue(copayAmount);
      if(updateAmountInput){
        setAmountInput(Number(copayAmount  > 0 ? copayAmount :location.state.type.price) + Number(location.state.outstandingBalance ?? 0))
      }
      return copayAmount;
    }
    return 0;
  }
  useEffect(() => {
    getToken();
    sessionStorage.setItem("reloading", "true");
    handleCopayValue(true);
    return () => {
      clearStates();
      expiryTimestamp.setSeconds(0);
      localStorage.removeItem("apptDto");
      // location.state = null;
    };
  }, []);
  useEffect(()=>{
    const isTimeOverOrFieldsEmpty = isTimeOver || !isAllFieldsFilled;
    let copayVal = handleCopayValue(false);
    
    const isInNetworkCopayValid = patientInsuranceData[0]?.inNetworkCopay !== undefined 
      ? Number(copayVal) > amountInput 
      : Number(location.state?.price) > Number(amountInput);
      setIsPaymentDisabled(isTimeOverOrFieldsEmpty || isInNetworkCopayValid)
  },[amountInput,isAllFieldsFilled])

  console.log("isAllFieldsFilled: ",isAllFieldsFilled,isTimeOver);
  
  return (
    <>
      {leaveModal ? (
        <LeaveAssessment
          show={leaveModal}
          continueAssessment={continueAssessment}
          closeAssesment={closeAssesment}
          patientDemographics={props?.patientDemographics ?? false}
        />
      ) : null}
      {props?.newPatient === true ? (
        ""
      ) : (
        <Box
          sx={{
            borderBottom: 1,
            borderTop: 1,
            borderColor: "#D9E8EC",
            flexGrow: 1,
            paddingTop: 2,
          }}
          component="div"
          className="d-flex flex-row justify-content-between px-3 py-2"
        >
          <div className="text--terciary font-32 align-self-center ">
            <div className="d-flex flex-row">
              <div
                className="bg--background br-10 me-3 border--default border text-center my-auto "
                style={{ height: "37px", width: "38px" }}
                onClick={() =>
                  navigate("/patient-portal/dasboard", { replace: true })
                }
              >
                <ChevronLeft
                  style={{ color: "#EC609B", cursor: "pointer" }}
                  className="my-auto mb-2 my-auto"
                />
              </div>
              <div className="font-28">Payment</div>
            </div>
          </div>
        </Box>
      )}
      <div className="row  justify-content-center mb-5">
        <div className="col-3"></div>
        <div className="col-4">
          <div className="d-flex flex-row justify-content-start">
            <Typography component="div">
              <Box
                sx={{
                  textAlign: "left",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  fontSize: "16px",
                  color: "#336383",
                  marginLeft: "2px",
                  opacity: 0,
                }}
              >
                Payment Method
              </Box>
            </Typography>
          </div>
          <div className="row justify-content-start mt-1">
            <div className="col-12 mt-2">
              <PaymentMethodTypes setPaymentType={setPaymentType} />

              {paymentType === "creditCard" ? (
                <div className="d-flex flex-row mt-3">
                  <div className="col-sm-10">
                    <div
                      className="d-flex flex-row"
                      style={{ marginBottom: "80px" }}
                    >
                      <div
                        className="form-check bg-white"
                        style={
                          isCreditCard
                            ? {
                                border: "2px solid #336383",
                                borderRadius: "7px",
                              }
                            : {
                                border: "1px solid #D9E8EC",
                                borderRadius: "7px",
                              }
                        }
                      >
                        {/* <div className="form-check bg-white" style={isCreditCard ? { border: "2px solid #336383", height: "625px", borderRadius: "7px" } : { border: "1px solid #D9E8EC", borderRadius: "7px" }}> */}
                        <div className="btn-group" data-toggle="buttons">
                          <div className="col-sm-10 align-self-center ps-1">
                            <div
                              className=""
                              onClick={handleCreditCard}
                              style={{ width: "365px" }}
                            >
                              {/* <input type="radio" name="cc" className={`form-check-input ${classes["mt-20"]} ${classes.cc_radio} `} onChange={handleCreditCard} checked={isCreditCard} /> */}
                              <label
                                htmlFor="cc"
                                className={` py-3  fw-sb font-16 text--secondary`}
                              >
                                Credit Card
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-2 align-self-center">
                            <div className="d-flex flex-row justify-content-center">
                              <CCIcon />
                            </div>
                          </div>
                        </div>
                        {isCreditCard && (
                          <>
                            {oldCards.length > 0 &&
                              oldCards?.map((card, index) => {
                                return (
                                  <div
                                    className="col-sm-11 justify-content-between mb-2"
                                    key={index}
                                  >
                                    <div
                                      className="form-check align-items-center"
                                      style={{
                                        border: "1px solid #D9E8EC",
                                        borderRadius: "7px",
                                      }}
                                    >
                                      <div
                                        className="col-sm-12"
                                        id={"p" + index}
                                      >
                                        <div className="form-check d-flex flex-row justify-content-end">
                                          <div className="col-sm-10 align-self-center ps-2">
                                            <div className="d-flex flex-row">
                                              <input
                                                type="radio"
                                                id={"mcard"}
                                                data-cvv-field-id={index}
                                                name="all_cc"
                                                className={`form-check-input ${classes["mt-20"]} ${classes.cc_radio} `}
                                                onChange={handleOldCreditCard}
                                              />
                                              <input
                                                type="hidden"
                                                data-cc-token={
                                                  card?.creditCardToken
                                                }
                                                id={"cctoken" + index}
                                                className={`form-check-input ${classes["mt-20"]} ${classes.cc_radio} `}
                                                onChange={handleOldCreditCard}
                                              />
                                              <label
                                                htmlFor="all_cc"
                                                className={`form-check-label py-3 ps-2 fw-sb font-16 ms-1 text--secondary`}
                                              >
                                                {card?.creditCardNumber}
                                              </label>
                                            </div>
                                          </div>
                                          <div className="col-sm-2 align-self-center">
                                            <div className="d-flex flex-row justify-content-center">
                                              {/* <CCIcon /> */}
                                              <img
                                                src={card?.imageUrl}
                                                height={30}
                                                width={40}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        {/* <div className='form-check d-flex flex-row' hidden> */}

                                        {/* </div> */}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}

                            {
                              // isNewCreditCard && (
                              <>
                                <form
                                  id="credit-card-form"
                                  onSubmit={(e) => {
                                    e.preventDefault();

                                    // console.log(hostedFieldsInstance);
                                  }}
                                >
                                  <div className="col-md-10 mb-2">
                                    <label
                                      htmlFor="physician"
                                      className="form-label m-0 font-10 text--secondary fw-sb"
                                    >
                                      Credit Card Name <span>&#42;</span>
                                    </label>
                                    <input
                                      type="text"
                                      className={`form-control text--terciary refill_input font-12 m-0 border--default br-10 shadow-none input`}
                                      id="physician"
                                      onChange={cardNameHolderHandler}
                                      required
                                    />
                                  </div>

                                  <div className="col-md-10 mb-2">
                                    <label
                                      htmlFor="physician"
                                      className="form-label m-0 font-10 text--secondary fw-sb"
                                    >
                                      Credit Card Number <span>&#42;</span>
                                    </label>
                                    <Stack
                                      direction="row"
                                      justifyContent={"between"}
                                      spacing={2}
                                    >
                                      <div
                                        className="col-sm-12"
                                        style={{
                                          backgroundColor: "#ffffff",
                                          border: "1px solid #D9E8EC",
                                          borderRadius: "10px",
                                          p: 0,
                                        }}
                                      >
                                        <div className="row">
                                          <div className="col-sm-8 justify-content-start align-self-center">
                                            <Typography component="span">
                                              <Box
                                                sx={{
                                                  fontFamily: "Montserrat",
                                                  fontWeight: 600,
                                                  fontSize: "12px",
                                                  color: "#323232",
                                                }}
                                              >
                                                <div>
                                                  <div
                                                    id="card-number"
                                                    className={`${classes.hostedFields}`}
                                                  ></div>
                                                </div>
                                              </Box>
                                            </Typography>
                                          </div>
                                          <div className="col-sm-4 align-self-center">
                                            <div className="d-flex flex-row justify-content-end me-2">
                                              {creditCardType !== null &&
                                                cardsMap(creditCardType)}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Stack>
                                    <hr />
                                  </div>

                                  <div className="col-md-10 mb-2">
                                    <Typography component="span">
                                      <Box
                                        sx={{
                                          fontFamily: "Montserrat",
                                          fontWeight: "600",
                                          fontSize: "12px",
                                          color: "#336383",
                                        }}
                                      >
                                        Expiration Date
                                      </Box>
                                    </Typography>
                                  </div>
                                  <div className="col-10">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="col-md-12 mb-2">
                                          <label
                                            htmlFor="Month"
                                            className="form-label m-0 font-10 text--secondary fw-sb"
                                          >
                                            MM/YYYY <span>&#42;</span>
                                          </label>
                                          <Typography component="span">
                                            <Box
                                              sx={{
                                                fontFamily: "Montserrat",
                                                fontWeight: 600,
                                                fontSize: "12px",
                                                color: "#323232",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  backgroundColor: "#ffffff",
                                                  border: "1px solid #D9E8EC",
                                                  borderRadius: "10px",
                                                  p: 0,
                                                }}
                                              >
                                                <div
                                                  id="expiration-date"
                                                  className={`${classes.hostedFields}`}
                                                ></div>
                                              </div>
                                            </Box>
                                          </Typography>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="col-md-12 mb-2">
                                          <label
                                            htmlFor="ccv"
                                            className="form-label m-0 font-10 text--secondary fw-sb"
                                          >
                                            {"CVV"} <span>&#42;</span>
                                            {/* {creditCardType?.code?.name} */}
                                          </label>
                                          <Typography component="span">
                                            <Box
                                              sx={{
                                                fontFamily: "Montserrat",
                                                fontWeight: 600,
                                                fontSize: "12px",
                                                color: "#323232",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  backgroundColor: "#ffffff",
                                                  border: "1px solid #D9E8EC",
                                                  borderRadius: "10px",
                                                  p: 0,
                                                }}
                                              >
                                                <div
                                                  id="cvv"
                                                  className={`${classes.hostedFields}`}
                                                ></div>
                                              </div>
                                            </Box>
                                          </Typography>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-10 mb-2">
                                    <hr />
                                  </div>

                                  <div className="col-md-10 mb-2">
                                    <Typography component="span">
                                      <Box
                                        sx={{
                                          fontFamily: "Montserrat",
                                          fontWeight: "600",
                                          fontSize: "12px",
                                          color: "#336383",
                                        }}
                                      >
                                        Billing Address
                                      </Box>
                                    </Typography>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="col-md-10 mb-2">
                                      <label
                                        htmlFor="address"
                                        className="form-label m-0 font-10 text--secondary fw-sb"
                                      >
                                        Address
                                      </label>
                                      <input
                                        type="text"
                                        className={`form-control text--terciary refill_input font-12 m-0 border--default br-10 shadow-none input`}
                                        id="address"
                                        onChange={addressHandler}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-10">
                                    <div className="row">
                                      <div className="col-md-4">
                                        <div className="col-md-12 mb-2">
                                          <label
                                            htmlFor="city"
                                            className="form-label m-0 font-10 text--secondary fw-sb"
                                          >
                                            City
                                          </label>
                                          <input
                                            type="text"
                                            className={`form-control text--terciary refill_input font-12 m-0 border--default br-10 shadow-none input`}
                                            id="city"
                                            onChange={cityHandler}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="col-md-12 mb-2">
                                          <label
                                            htmlFor="state"
                                            className="form-label m-0 font-10 text--secondary fw-sb"
                                          >
                                            State
                                          </label>
                                          <Typeahead
                                            id="state"
                                            labelKey="code"
                                            onChange={setSelectedState}
                                            options={options}
                                            className="rbt-menu my-select"
                                            style={{
                                              borderRadius: "10px",
                                              fontSize: 12,
                                            }}
                                            autoComplete="false"
                                            // onInvalid={(e) => {
                                            //   e.target.setCustomValidity(
                                            //     "Please fill out this field"
                                            //   );
                                            // }}
                                            // onInput={(e) => {
                                            //   e.target.setCustomValidity("");
                                            // }}
                                            inputProps={{
                                              // required: true,
                                              className: "font-12",
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="col-md-12 mb-2">
                                          <label
                                            htmlFor="ZipCode"
                                            className="form-label m-0 font-10 text--secondary fw-sb"
                                          >
                                            ZipCode
                                          </label>
                                          <input
                                            type="text"
                                            className={`form-control text--terciary refill_input font-12 m-0 border--default br-10 shadow-none input`}
                                            id="zipcode"
                                            autoComplete="false"
                                            minLength="5"
                                            maxLength="5"
                                            onChange={zipcodeHandler}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-10 mb-2">
                                    <hr />

                                    <div className="row justify-content-center">
                                      <div className="col-9 text-center">
                                        <span className="font-12">
                                          Powered by{" "}
                                        </span>
                                        <img
                                          width={90}
                                          src="https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-200px.png"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="col-md-12" style={{ marginLeft: 16 }}>
                                                                                <input className="form-check-input urgnt_chkbks shadow-none" type="checkbox" value="" id="urgent" onChange={saveCardHandler} />
                                                                                <label className="form-check-label font-10 text--secondary" htmlFor="urgent">
                                                                                    Save Credit Card
                                                                                </label>
                                                                            </div> */}
                                  <div
                                    className="col-md-12"
                                    style={{ marginBottom: "20px" }}
                                  ></div>
                                </form>
                                {hostedForm()}
                              </>
                              // )
                            }
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : paymentType === "googlePay" ? (
                <div className="row g-0">
                  <div
                    className="col-10 g-0 mt-3 p-3"
                    style={{
                      border: "2px solid #336383",
                      borderRadius: "7px",
                      marginRight: "auto",
                    }}
                  >
                    {/* <GooglePayButton 
                          patientPortal = {true}
                          billingProvider={location.state?.providerTenantPracticeLocation?.provider?.userId}
                          appointmentId = {location.state?.id}
                          amountValue={location.state?.price}
                          setIsPaymentSent= {setIsApptCreated}
                          timeExpired = {timeExpired}
                          appointmentDto={JSON.parse(localStorage.getItem("apptDto"))}
                      /> */}
                    <PatientGooglePayForm
                      patientPortal={true}
                      billingProvider={
                        location.state?.providerTenantPracticeLocation?.provider
                          ?.userId
                      }
                      appointmentId={location.state?.id}
                      amountValue={amountInput}
                      setIsPaymentSent={setIsApptCreated}
                      timeExpired={timeExpired}
                      appointmentDto={JSON.parse(
                        localStorage.getItem("apptDto")
                      )}
                      isValidAmount = {isValidAmount}
                    />
                  </div>
                  {/* <div className="col-2"></div> */}
                </div>
              ) : paymentType === "applePay" ? (
                <div className="row g-0">
                  <div
                    className="col-10 g-0 mt-3 ps-3 pe-3 pb-3"
                    style={{
                      border: "2px solid #336383",
                      borderRadius: "7px",
                      marginRight: "auto",
                      // paddingTop:"0px"
                    }}
                  >
                    {/* <ApplePay
                    patientPortal={true}
                    billingProvider={
                      location.state?.providerTenantPracticeLocation?.provider
                        ?.userId
                    }
                    appointmentId={location.state?.id}
                    amountValue={location.state?.price}
                    setIsPaymentSent={setIsApptCreated}
                    timeExpired={timeExpired}
                    appointmentDto={JSON.parse(localStorage.getItem("apptDto"))}
                    isCopPay={false}
                  /> */}
                    <PatientApplePayForm
                      patientPortal={true}
                      billingProvider={
                        location.state?.providerTenantPracticeLocation?.provider
                          ?.userId
                      }
                      appointmentId={location.state?.id}
                      amountValue={amountInput}
                      setIsPaymentSent={setIsApptCreated}
                      timeExpired={timeExpired}
                      appointmentDto={JSON.parse(
                        localStorage.getItem("apptDto")
                      )}
                      isCopPay={false}
                      isValidAmount = {isValidAmount}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="col-1"></div>
        <div className="col-4" style={{ marginTop: "2%" }}>
          <div className="col-2"></div>
          <div className="col-8">
            <div className="col-12">
              <div
                className="container-fluid br-10 border--default p-3"
                style={
                  props?.newPatient === true
                    ? { backgroundColor: "#FFFFFF", borderRadius: "10px" }
                    : { borderRadius: "10px" }
                }
              >
                <div className="row">
                  <div className="col-sm-6">
                    <div className="row">
                      <div className="col-sm-6 align-self-center">
                        <div className="d-flex flex-row justify-content-center">
                          <div
                            className=" br-10 border--default p-3 ms-2"
                            style={{
                              height: "58px",
                              width: "58px",
                              border: "1px solid #d9e8ec",
                              borderRadius: "10px",
                            }}
                          >
                            {/* {location?.state?.type === "New Appointment" ? <EventIcon style={{ color: "#336383" }} /> : <EventRepeatIcon style={{ color: "#336383" }} />} */}
                            {<EventIcon style={{ color: "#336383" }} />}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 align-self-center text-end">
                    <span className="font-24 text--primary fw-sb">
                      {"$"}
                      {location?.state?.type?.price}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 mt-2">
                    <span className="font-14 fw-sb text--secondary">
                      {location?.state?.type?.title}
                    </span>
                  </div>
                  <div className="col-sm-12">
                    <span className="font-14 text--secondary">
                      {location?.state?.type?.shortDescription}
                    </span>
                  </div>
                  <div className="col-sm-12">
                    <Typography component="span">
                      <Box
                        sx={{
                          textAlign: "left",
                          fontFamily: "Montserrat",
                          fontWeight: 400,
                          fontSize: "10px",
                          color: "#336383",
                        }}
                      >
                        {location?.state?.provider?.name[0]?.prefix}{" "}
                        {location?.state?.provider?.name[0].lastName}
                      </Box>
                    </Typography>
                  </div>
                  <div className="col-sm-12 pt-4">
                    <Typography component="span">
                      <Box
                        sx={{
                          textAlign: "left",
                          fontFamily: "Montserrat",
                          fontWeight: 400,
                          fontSize: "12px",
                          color: "#336383",
                        }}
                      >
                        {dateTimeHelper(
                          location?.state?.startDateTime,
                          "dddd, MMM D, yyyy"
                        )}
                      </Box>
                    </Typography>
                  </div>
                  <div className="col-sm-12">
                    <Typography component="span">
                      <Box sx={s1} className="font-12 text--secondary fw-thin">
                        <span className="font-12 text--secondary fw-thin">
                          {dateTimeHelper(location.state?.startDateTime, "LT")}{" "}
                          - {dateTimeHelper(location.state?.endDateTime, "LT")}
                        </span>
                        {/* <span style={{ fontWeight: 800 }}>{dateTimeHelper(location.state?.startDateTime, "LT")}</span> - {dateTimeHelper(location.state?.endDateTime, "LT")} */}
                      </Box>
                    </Typography>
                  </div>
                  <InvoiceItem title={"Service Fee:"} description={"$".concat(location.state.type.price ?? "0.00")}/>
                  <InvoiceItem title={"Copay:"} description={"$".concat(copayValue ?? "0.00")}/>
                  <InvoiceItem title={"Outstanding amount:"} description={"$".concat( location.state.outstandingBalance ?? 0)}/>
                  <InvoiceItem title={"Total Dues:"} description={"$".concat( Number(copayValue  > 0 ? copayValue :location.state.type.price) + Number(location.state.outstandingBalance ?? 0))}/>
                  <div className="col-12 mt-3" >
                    <label htmlFor="amount" className="font-12 text--secondary fw-sb">
                      {'Amount to Pay'}
                    </label>
                    <label className="text--danger font-12" hidden={isValidAmount}>{"Minimum amount should be service fee"}</label>
                    <CurrencyInput
                      id="cash"
                      name="cash"
                      placeholder="$0.00"
                      className="text--terciary text-end font-12 input form-control shadow-none border--default br-10"
                      value={amountInput}
                      prefix="$"
                      decimalSeparator="."
                      groupSeparator=","
                      intlConfig={{ locale: 'en-US', currency: 'USD' }}
                      onValueChange={handleServiceFee}
                      align="right"
                      // min={location.state.type.price}
                      allowNegativeValue={false}
                    />
                  </div>
                  <div className="col-sm-12 pt-4">
                    <div
                      className="br-10 border--default py-2 px-4 mt-2"
                      style={{
                        backgroundColor: "#F2F7F8",
                        border: "1px solid #D9E8EC",
                        borderRadius: "10px",
                      }}
                    >
                      <div className="row">
                        <div className="col-sm-12">
                          <Typography component="span">
                            <Box sx={s1}>
                              Reservation valid for{" "}
                              <Timer
                                duration={difference}
                                timeExpired={timeExpired}
                                expiryTimestamp={expiryTimestamp}
                              />
                            </Box>
                          </Typography>
                        </div>
                        <div className="col-sm-12">
                          <Typography component="span">
                            <Box sx={s2}>
                              Payment is required for confirmation
                            </Box>
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row pt-4">
                  <div className="col-6">
                    <button
                      type="btn"
                      className={`btn fw-sb font-12 shadow-none ${classes.cancel_button} text--danger b2 br-10`}
                      // form="credit-card-form"
                      disabled={isTimeOver}
                      value={true}
                      onClick={continueAssessment}
                    >
                      {"Cancel"}
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      type="btn"
                      ref={confirmButtonRef}
                      className={`btn fw-sb font-12 ${classes.task_button} text--white b2 br-10`}
                      form="credit-card-form"
                      disabled={paymentType === "creditCard" ? nameOnCC?.length < 2 || isPaymentDisabled  : isPaymentDisabled}
                      value={true}
                      onClick={handlePayment}

                    >
                      {"Confirm"}
                    </button>
                    {console.log("nameOnCC?.length > 2 && isPaymentDisabled: ",nameOnCC?.length > 2 , isPaymentDisabled)
                    }
                  </div>
                </div>
                <div className="d-flex justify-content-center pt-2">
                  <span
                    className={
                      "font-14 fw-sb text--danger mt-2 text-align-center"
                    }
                  >
                    {error?.message}
                  </span>
                </div>
                <div className="d-flex justify-content-center pt-2">
                  <span
                    className={
                      "font-14 fw-sb text--danger mt-1 text-align-center"
                    }
                  >
                    {error?.RemainingAttempts && "Remaining Attempts: "}{" "}
                    {error?.RemainingAttempts}
                    {tokenizeErr?.message !== "" ?? "Something went wrong"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-2"></div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#EC609B", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CustomizedSnackbars
        message={"Your window for payment is over."}
        severity="error"
        open={isTimeOverSnackbar}
        handleClose={handleClose}
      />
      <CustomizedSnackbars
        message={"Payment processed and Appointment successfully created."}
        severity="success"
        open={isApptCreated}
        handleClose={handleClose}
      />
      {/* {console.log("location: ", location.state)}
      {console.log("localStorage.getItem(): ",JSON.parse(localStorage.getItem("apptDto")))} */}
    </>
  );
}

import { useEffect, useState } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import {
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import classes from "./AppointmentScheduleModal.module.css";
import moment from "moment";
import { Modal } from "react-bootstrap";
import sendHttpRequest from "../../../hooks/sendHttpRequest";
import { useSelector } from "react-redux";
import {
  calenderDateTimeHelper,
  convertUTCtoTimzone,
  dateTimeHelper,
} from "../../../../utils/dateTimeHelper";
import CustomDatePicker from "../../General/CustomDatePicker";
import { RolesTextConversion } from "../../../../utils/helpers/RolesMap";
import { useAuth } from "../../../hooks/useAuth";
import {
  deleteAvailableSlot,
  providerWithNpiUrl,
} from "../../../../res/endpointurls";
import TrashIcon from "../../../../assets/icons/TrashIcon";
import EditButtonIcon from "../../../../assets/icons/EditIcon";
import DeleteConfirmationModal from "../PatientModals/DeleteConfirmationModal";
import ErrorModal from "../PatientModals/ErrorModal";
import CalenderDatePicker from "../../General/CalenderDatePicker";
import { APPOINTMENTS } from "../../../../utils/helpers/subscriptionsConstants";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FAFCFC",
    color: "#336383",
    fontWeight: 400,
    fontSize: 12,
    fontFamily: "Montserrat",
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: "0px",
    // border: "1px solid #D9E8EC",
    fontFamily: "Montserrat",
    // border: 0
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    // backgroundColor: "#FAFCFC",
    height: 40,
  },
  "&:nth-of-type(odd)": {
    // backgroundColor: "#FAFCFC",
    height: 40,
  },
  "&:last-child td, &:last-child th": {
    // borderBottom: 0,
    border: 0,
  },
}));

const useStyle = makeStyles({
  root: {
    height: "70vh",
  },
});

const ViewScheduleModal = (props) => {
  const prescriberInfo = useSelector((state) => state.PrecriberData.prescriber);
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [timexone, setTimexone] = useState("");
  const [selectedProviderId, setSelectedProviderId] = useState();
  // const [selectedProviderId, setSelectedProviderId] = useState();
  const [prescribersList, setPrescribersList] = useState([]);
  const [isProviderScheduleLoading, setIsProviderScheduleLoading] =
    useState(false);
  const [prescriberScheduleData, setPrescriberScheduleData] = useState([]);
  const { user } = useAuth();
  let joined = convertUTCtoTimzone(new Date(), "", "YYYY-MM-DD");
  const [startDate, setStartDate] = useState(joined);
  const joinedEndDate = convertUTCtoTimzone(
    moment(new Date()).add(6, "days"),
    "",
    "YYYY-MM-DD"
  );
  // console.log("user?.timezone: ",user?.timezone,joinedEndDate);
  const [nextWeekDate, setNextWeekDate] = useState(joinedEndDate);
  const [endDate, setEndDate] = useState(nextWeekDate);
  const [deleteMedicationHistoryId, setDeleteMedicationHistoryId] = useState();
  const [deleteMedicationHistoryModal, setDeleteMedicationHistoryModal] =
    useState(false);
  const [providerFacilities, setProviderFacilities] = useState([]);
  const [isProviderFacilitiesLoading, setIsProviderFacilitiesLoading] =
    useState(false);
  // const [selectedFacilityId,setSelectedFacilityId] = useState(user?.facility?.facilityId)
  const [selectedFacilityId, setSelectedFacilityId] = useState("")

  const fecthAllPrescribers = async () => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: providerWithNpiUrl(user?.tenantId),
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log("prescribers-> ", httpResponse.data);
      setPrescribersList(httpResponse.data);
    }
    setIsLoading(false);
  };

  const fetchProviderFacilities = async (userId, tenantId) => {
    setIsProviderFacilitiesLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/provider/${userId}/tenant/${tenantId}/facilities`,
      method: "GET",
    });
    if (!httpResponse.error) {
      setProviderFacilities(httpResponse.data);
    }
    setIsProviderFacilitiesLoading(false);
  };

  const deleteAvailableSlotRequestHandler = async (slotId) => {
    // setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: deleteAvailableSlot(slotId),
      method: "DELETE",
    });
    if (!httpResponse.error) {
      handleDeleteCloseModal();
      fecthProviderSchedule(selectedProviderId, startDate, nextWeekDate, selectedFacilityId);
    }
    // setIsLoading(false);
  };

  const fecthProviderSchedule = async (prescriberId, startDate, endDate, facilityId) => {
    // console.log("prescriberId: ",prescriberId);
    setIsProviderScheduleLoading(true);
    setPrescriberScheduleData([]);
    if (prescriberId !== undefined && prescriberId !== 0) {
      const httpResponse = await sendHttpRequest({
        url: "/availabilities",
        method: "GET",
        params: {
          prescriberId: prescriberId,
          from: startDate,
          to: endDate,
          tenantId: user?.tenantId,
          facilityId: facilityId
        },
      });
      if (!httpResponse.error) {
        // console.log("prescriber schedule data-> ", httpResponse.data);
        if (httpResponse.data !== undefined) {
          if (
            httpResponse.data?.length > 0 &&
            httpResponse.data[0]?.startDateTime !== null
          ) {
            setPrescriberScheduleData(httpResponse.data);
          }
        }
      }
    }
    setIsProviderScheduleLoading(false);
  };

  const checkIfAppointmentExists = async (appointmentId) => {
    const httpResponse = await sendHttpRequest({
      url: `/availabilities/check/provider/${selectedProviderId}/tenant/${user?.tenantId}/availability/${appointmentId}`,
      method: "GET",
    });
    if (!httpResponse.error) {
      if (httpResponse.data) {
        setDeleteMedicationHistoryId(appointmentId);
        setDeleteMedicationHistoryModal(!deleteMedicationHistoryModal);
      } else {
        setIsError(!isError);
      }
    }
  };

  const handleDeleteModal = async (id) => {
    await checkIfAppointmentExists(id);
  };

  const handleDeleteCloseModal = () => {
    setDeleteMedicationHistoryModal(!deleteMedicationHistoryModal);
    setDeleteMedicationHistoryId(null);
  };

  const handleErrorCloseModal = () => {
    setIsError(!isError);
  };

  const handleProviderId = (event) => {
    setSelectedFacilityId("")
    setSelectedProviderId(event.target.value);
    fetchProviderFacilities(event.target.value, user?.tenantId)
    fecthProviderSchedule(event.target.value, startDate, nextWeekDate, selectedFacilityId);
  };

  const handleScheduleStartDate = (dateValue) => {
    let nextWeek = moment(new Date(dateValue))
      .add(6, "days")
      .format("YYYY-MM-DD");
    setStartDate(moment(dateValue).format("YYYY-MM-DD"));
    setNextWeekDate(nextWeek);
    setEndDate(nextWeek);
    fecthProviderSchedule(selectedProviderId, dateValue, nextWeek, selectedFacilityId);
  };

  const handleScheduleEndDate = (dateValue) => {
    if (startDate > dateValue) {
      setNextWeekDate(
        moment(new Date(startDate)).add(6, "days").format("YYYY-MM-DD")
      );
    } else {
      setNextWeekDate(dateValue);
    }
    fecthProviderSchedule(selectedProviderId, startDate, dateValue, selectedFacilityId);
  };

  const getUserTimezone = async () => {
    const httpResponse = await sendHttpRequest({
      url: "/auth/user/timezone",
      method: "GET",
    });
    if (!httpResponse.error) {
      setTimexone(httpResponse?.data?.timezone);
    } else {
      console.log(httpResponse.error);
    }
  };

  const handlefacilityId = (facilityId) => {
    setSelectedFacilityId(facilityId);
    fecthProviderSchedule(selectedProviderId, startDate, endDate, facilityId)
  }

  useEffect(() => {
    getUserTimezone();
    if (prescribersList?.length === 0) {
      fecthAllPrescribers();
      fecthProviderSchedule(props?.selectedPrescriberId, startDate, endDate, selectedFacilityId);
      if (user?.role !== "PracticeAdmin") {
        fetchProviderFacilities(props?.selectedPrescriberId, user?.tenantId);
      }
    }

    setSelectedProviderId(props?.selectedPrescriberId);
  }, [props?.selectedPrescriberId]);

  return (
    <>
      <DeleteConfirmationModal
        show={deleteMedicationHistoryModal}
        handleModal={handleDeleteCloseModal}
        deleteMedicaitonHistoryId={deleteMedicationHistoryId}
        modalText={"Are you sure you want to remove this time slot?"}
        deleteMedicationHistoryRequestHandler={
          deleteAvailableSlotRequestHandler
        }
      />
      <ErrorModal
        show={isError}
        handleModal={handleErrorCloseModal}
        modalText={
          "There are appointments scheduled for this time slot. Please contact the admin for assistance."
        }
        modalHeaderText={"Unable to delete the selected time slots"}
      />
      <Modal
        show={props.show}
        onHide={() => {
          setPrescriberScheduleData([]);
          props.handleModal();
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        backdropClassName="modal-backdrop"
        contentClassName="modal-border modal-shadow"
        // dialogClassName={classes.root}
        scrollable
        centered
      >
        <Modal.Header className="modal-header">
          <span
            style={{
              lineHeight: "12px",
              width: "18px",
              fontSize: "8pt",
              color: "#336383",
              position: "absolute",
              top: 15,
              right: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              props?.handleModal();
            }}
          >
            {<CloseIcon />}
          </span>
          <Modal.Title className="font-20 fw-sb">
            View Schedule
            <div className="" style={{ fontWeight: 400, fontSize: "12px" }}>
              {/* {"All times in: " + Date().slice(Date().indexOf("(") + 1, Date().lastIndexOf(")"))} */}
              {"All times in: "} {timexone}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row p-2 mt-1 mb-5 ">
            <div className="col-sm-3 col-md-3 mb-3">
              {isLoading ? (
                <Skeleton
                  animation="wave"
                  variant="text"
                  width={"70%"}
                  height={40}
                />
              ) : (
                <div>
                  <label className="text--terciary b2 fw-sb">Provider</label>

                  <select
                    className={`form-select font-12 border--default br-10 shadow-none ${classes["custom-select"]}`}
                    aria-label="Provider select"
                    style={{
                      borderRadius: "10px",
                    }}
                    onChange={(e) => handleProviderId(e)}
                  >
                    <option value="" disabled selected hidden>
                      Select provider
                    </option>
                    {prescribersList !== undefined &&
                      prescribersList?.length > 0 &&
                      prescribersList?.map((prescriberItem) => {
                        return (
                          <option
                            value={prescriberItem?.userId}
                            key={prescriberItem?.userId}
                            selected={
                              props?.selectedPrescriberId ==
                              prescriberItem?.userId
                            }
                          >
                            {prescriberItem?.name[0]?.prefix}{" "}
                            {prescriberItem?.name[0]?.firstName}{" "}
                            {prescriberItem?.name[0]?.lastName}{" "}
                            {RolesTextConversion(prescriberItem?.role) !==
                              undefined
                              ? "(" +
                              RolesTextConversion(prescriberItem?.role) +
                              ")"
                              : ""}
                          </option>
                        );
                      })}
                  </select>
                </div>
              )}
            </div>
            <div className="col-sm-3 col-md-3 mb-3">
              {isProviderFacilitiesLoading ? (
                <Skeleton />
              ) : (
                <>
                  <label className="text--terciary b2 fw-sb">{"Facility"}</label>
                  <select
                    className={`form-select font-12  border--default br-10 shadow-none input`}
                    aria-label="action select"
                    style={{
                      height: "36px",
                    }}
                    onChange={(e) => handlefacilityId(e.target.value)}
                    value={selectedFacilityId}
                  >
                    {/* <option
                      className="text--terciary"
                      value={""}
                      key={1}
                      selected
                      disabled
                      hidden
                    >
                      {"Select Facility"}
                    </option> */}
                    {
                      providerFacilities?.length > 1 && (
                        <option id={0} value={0} key={0}>{"All"}</option>
                      )
                    }
                    {
                      providerFacilities?.length > 0 ?
                        providerFacilities.map((facilityItem) => {
                          return (
                            <option key={facilityItem?.facilityId} id={facilityItem?.facilityId} value={facilityItem?.facilityId}>{facilityItem?.businessName}</option>
                          )
                        }) :
                        <option key={"no-data"} value={""} disabled>{"No data available"}</option>
                    }
                  </select>
                </>
              )}
            </div>

            <div className="col-sm-2 col-md-3 mb-3">
              <label className="text--terciary font-12 b2 fw-sb">
                Start Date
              </label>
              <CalenderDatePicker
                f12={true}
                dobHandler={handleScheduleStartDate}
                dateFormat="MMM d, yyyy"
                date={startDate}
                viewSchedule={true}
                required
                key={"CalStartDate"}
              />
            </div>

            <div className="col-sm-3 col-md-3 mb-3">
              <label className="text--terciary b2 fw-sb">End Date</label>
              <CalenderDatePicker
                f12={true}
                dobHandler={handleScheduleEndDate}
                dateFormat="MMM d, yyyy"
                minDate={startDate}
                date={nextWeekDate}
                viewSchedule={true}
                key={"CalEndDate"}
                required
              />
            </div>

            <Divider />

            <div className="row mt-3" style={{ minHeight: "30vh" }}>
              <div className="col-sm-12">
                <TableContainer
                  sx={{
                    borderRadius: "10px",
                    border: "1px solid #D9E8EC",
                  }}
                >
                  <Table>
                    <TableHead
                      sx={{
                        borderTopColor: "#FAFCFC",
                        borderRightColor: "#FAFCFC",
                        borderLeftColor: "#FAFCFC",
                      }}
                    >
                      <StyledTableRow>
                        {/* <StyledTableCell sx={{p:'0.75rem', width:'5rem'}}>Day</StyledTableCell> */}
                        <StyledTableCell sx={{ p: "0.75rem", width: "7rem" }}>
                          Date
                        </StyledTableCell>
                        <StyledTableCell sx={{ p: "0.75rem", width: "5rem" }}>
                          From
                        </StyledTableCell>
                        <StyledTableCell sx={{ p: "0.75rem", width: "5rem" }}>
                          To
                        </StyledTableCell>
                        <StyledTableCell sx={{ p: "0.75rem", width: "5rem" }}>
                          Facility
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ p: "0.75rem", width: "5rem" }}
                        ></StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody sx={{ fontSize: "12px" }}>
                      {isProviderScheduleLoading ? (
                        <StyledTableRow>
                          {/* <StyledTableCell>
                            <Skeleton animation="wave" />
                          </StyledTableCell> */}
                          <StyledTableCell>
                            <Skeleton animation="wave" />
                          </StyledTableCell>
                          <StyledTableCell>
                            <Skeleton animation="wave" />
                          </StyledTableCell>
                          <StyledTableCell>
                            <Skeleton animation="wave" />
                          </StyledTableCell>
                          <StyledTableCell>
                            <Skeleton animation="wave" />
                          </StyledTableCell>
                          <StyledTableCell>
                            <Skeleton animation="wave" />
                          </StyledTableCell>
                        </StyledTableRow>
                      ) : (
                        prescriberScheduleData !== undefined &&
                        prescriberScheduleData?.length > 0 &&
                        prescriberScheduleData?.map((availableSlot, index) => {
                          return (
                            <StyledTableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th ": {
                                  border: 0,
                                },
                              }}
                            >
                              <StyledTableCell
                                className="text-center"
                              // sx={{ width: "100", color: "#336383" }}
                              >
                                {availableSlot?.startDateTime !== undefined &&
                                  dateTimeHelper(
                                    availableSlot?.startDateTime,
                                    "ddd, MMM D, yyyy"
                                  )}
                              </StyledTableCell>
                              <StyledTableCell
                                // sx={{ width: "100" }}
                                className="text-center"
                              >
                                {availableSlot?.startDateTime !== undefined &&
                                  dateTimeHelper(
                                    availableSlot?.startDateTime,
                                    "LT"
                                  )}
                              </StyledTableCell>
                              <StyledTableCell
                                // sx={{ width: "100", color: "#336383" }}
                                className="text-center"
                              >
                                {/* {availableSlot?.EndTime} */}
                                {availableSlot?.endDateTime !== undefined &&
                                  dateTimeHelper(
                                    availableSlot?.endDateTime,
                                    "LT"
                                  )}
                              </StyledTableCell>
                              <StyledTableCell
                                // sx={{ width: "100", color: "#336383" }}
                                className="text-center"
                              >
                                {/* {availableSlot?.EndTime} */}
                                {availableSlot?.providerTenantPracticeLocation?.facility?.businessName ?? ""}
                              </StyledTableCell>
                              <StyledTableCell
                                // sx={{ width: "100", color: "#336383" }}
                                className="text-center"
                              >
                                <div className="d-flex flex-row col-12 justify-content-center">
                                  <div className="row">
                                    {/* <div className="col-4 disabled-link">
                                          <EditButtonIcon 
                                            style={{ height: "18px", color: "#A2D1DE" }}
                                            id={availableSlot?.id}
                                          />
                                        </div> */}
                                    {/* dps */}
                                    <div
                                      className="col-12"
                                      hidden={
                                        Number(user?.userId) ===
                                          Number(selectedProviderId)
                                          ? subscriptionPrivilegeGroups[APPOINTMENTS]?.dps?.active === false
                                            ? true
                                            : false
                                          : subscriptionPrivilegeGroups[APPOINTMENTS]?.dpp?.active === false
                                            ? true
                                            : false
                                      }
                                    >
                                      <TrashIcon
                                        style={{
                                          color: "#ec609b",
                                          height: "15px",
                                          cursor: "pointer",
                                        }}
                                        id={availableSlot?.id}
                                        onClick={(e) =>
                                          handleDeleteModal(availableSlot?.id)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn px-5 py-2 blue_task_button"
            onClick={(e) => {
              e.preventDefault();
              setPrescriberScheduleData([]);
              props.handleModal();
            }}
            style={{
              border: "#004FD4 0% 0% no-repeat padding-box",
              width: "160px",
              height: "44px",
            }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {/* {console.log("monCount",monCount)} */}
      {/* {console.log("mondaySlotsList",mondaySlotsList)} */}
      {/* {console.log("user?.userId !== selectedProviderId",user?.userId , selectedProviderId)} */}
      {/* {console.log(selectedFacilityId,user?.facility?.facilityId)} */}
    </>
  );
};

export default ViewScheduleModal;

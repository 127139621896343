import { useEffect, useState } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import {
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import classes from "./CancelRxModal.module.css";
import moment from "moment";
import { Modal } from "react-bootstrap";
import sendHttpRequest from "../../../hooks/sendHttpRequest";
import { useSelector } from "react-redux";
import {
  calenderDateTimeHelper,
  dateTimeHelper,
} from "../../../../utils/dateTimeHelper";
import CustomDatePicker from "../../General/CustomDatePicker";
import { RolesTextConversion } from "../../../../utils/helpers/RolesMap";
import { useAuth } from "../../../hooks/useAuth";
import { cancelRxURL, providerWithNpiUrl } from "../../../../res/endpointurls";
import {
  formatPhoneNumber,
  formatPostalCode,
} from "../../../../utils/helpers/HelperFunctions";
import TextArea from "../../../forms/TextArea";
import { numbersEnum, orderPrescriptionStrings } from "../../../../res/strings";
import CustomizedSnackbar from "../../General/CustomizedSnackbars";
import { ValidationErrorMessage } from "../../../validations/ValidationErrorMessage";
import { ERROR_MESSAGES, FIELD_NAMES, MIN_MAX_VALUES } from "../../../validations/validationschemas";

const CancelRxModal = (props) => {
  const MEDICATION_VALIDATION = MIN_MAX_VALUES.medication;
  const fieldNames = FIELD_NAMES.medication;
  const validationErrorMsgs = ERROR_MESSAGES.medication;
  const _str = orderPrescriptionStrings.en

  const [validationErrors, setValidationErrors] = useState({})
  const [pharmacyNote, setPharmacyNote] = useState("");

  let __str = numbersEnum?.en;
  let patient = props?.patient;
  let patientName = props?.patient?.name[0];
  let pharmacy = props?.pharmacy;
  let medication = props?.medication;
  const { user } = useAuth();
  let patientPrimaryPh =
    patient?.communicationNumbers?.numbers?.find((numberItem) => {
      return numberItem?.type === __str.cellPhoneExt;
    })?.number || null;

  const handleCancelRx = async () => {
    let patientId = props?.patient?.patientId;
    let providerId = props?.provider?.providerId;
    let medicationRxId = props?.medication?.medicationRxId;
    const httpResponse = await sendHttpRequest({
      url: cancelRxURL(medicationRxId, patientId, providerId, user?.spi, pharmacyNote),
      method: 'GET',

      ...(props?.fromPatientProfile ? {} : {
        params: {
          prescriptionReferenceId: props?.encounterId
        }
      }),
    })
    if (!httpResponse.error) {
      if (props?.prescriptionTable) {
        props?.fetchPrescriptionHistory();
      }
      props?.setSeverity("success");
      props?.setCancelRxMessage(_str.eprescribe_cancelation_msg);

    } else {
      // console.log("In error section");
      props?.setSeverity("error")
      props?.setCancelRxMessage("Rx not cancelled");
    }
    props?.setCancelRxToastOpen(true);
  }

  const handleCancelNote = (noteText) => {
    setPharmacyNote(noteText);
    if (noteText?.length > MEDICATION_VALIDATION.noteMax) {
      setValidationErrors({ ...validationErrors, [fieldNames.noteMax]: validationErrorMsgs.noteMax })
    }
    else {
      let temp = validationErrors;
      delete temp.noteMax;
      setValidationErrors(p => temp)
    }
  }

  // useEffect(()=>{
  //   if(Object.keys(validationErrors)?.length === 0){
  //     setValidationErrors({})
  //   }
  // },[])


  // let patient = props?RxObject?.patient;
  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          props.handleModal();
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        backdropClassName="modal-backdrop"
        contentClassName="modal-border modal-shadow"
        // dialogClassName={classes.root}
        dialogClassName={`${classes["custom-modal-style"]}`}
        scrollable
        centered
      >
        <Modal.Header className="modal-header">
          <span
            style={{
              lineHeight: "12px",
              width: "18px",
              fontSize: "8pt",
              color: "#336383",
              position: "absolute",
              top: 15,
              right: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              props?.handleModal();
            }}
          >
            {<CloseIcon />}
          </span>
          <Modal.Title className="font-20 fw-sb">Cancel Rx</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {/* ------------------Patient Information------------------- */}
            <div className="col-12 col-md-12">
              <h3 className="text--terciary font-14 fw-sb m-0">Patient</h3>
              <div className="text--terciary font-12 border--bottom">
                <div>
                  <span className="text--terciary font-12 ">
                    {"Name:"} &nbsp;
                  </span>
                  <span>
                    {`${patientName?.firstName || ''} ${patientName?.middleName !== null ? patientName?.middleName : ''} ${patientName?.lastName || ''}`}

                  </span>
                </div>
                <div>
                  <span className="text--terciary font-12 ">
                    {"DOB on file:"} &nbsp;
                  </span>
                  <span>
                    {calenderDateTimeHelper(
                      patient?.dateOfBirth,
                      "YYYY-MM-DD"
                    )}{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  <span className="text--terciary font-12 ">
                    {"Gender:"} &nbsp;
                  </span>
                  <span>{patient?.gender}</span>
                </div>
                <div>
                  <span className="text--terciary font-12 ">
                    {"Address:"} &nbsp;
                  </span>
                  <span>
                    {"".concat(
                      patient?.address[0]?.addressLine1,
                      ", ",
                      patient?.address[0]?.addressLine2?.length > 0 ? patient?.address[0]?.addressLine2 + ", " : '',
                      patient?.address[0]?.city,
                      ", ",
                      patient?.address[0]?.stateProvince,
                      " ",
                      patient?.address[0]?.postalCode
                    )}
                  </span>
                </div>
                <div className="mb-2">
                  <span className="text--terciary font-12 ">
                    {"Phone:"} &nbsp;
                  </span>
                  <span>{formatPhoneNumber(patientPrimaryPh)}</span>
                </div>
              </div>
            </div>

            {/* ----------------------Pharmacy Information-------------------- */}
            <div className="col-12 col-md-12 mt-2">
              <h3 className="text--terciary font-14  m-0">Pharmacy</h3>
              <div className="text--terciary font-12 border--bottom">
                <div>
                  <span className="text--terciary font-12 ">
                    {"Name: "} &nbsp;
                  </span>
                  <span>{pharmacy?.standardizedOrganizationName}</span>
                </div>

                <div>
                  <span className=" font-12">{"Address:"} &nbsp;</span>
                  {"".concat(
                    pharmacy?.standardizedAddressLine1?.concat(", ") || "",
                    pharmacy?.standardizedAddressLine2
                      ?.length > 0
                      ? pharmacy?.standardizedAddressLine2.concat(", ")

                      : "",
                    pharmacy?.standardizedCity?.concat(", ") || "",
                    pharmacy?.stateProvince?.concat(", ") || "",

                    formatPostalCode(pharmacy?.standardizedPostalCode || "")
                  )}
                </div>
                <div className="mb-2">
                  <span className=" font-12">{"NPI: "} &nbsp;</span>
                  {
                    (pharmacy?.npi !== undefined && pharmacy?.npi !== null ? pharmacy?.npi : '')
                  }
                  <span className=" font-12">&nbsp;&nbsp;&nbsp;&nbsp;{"Phone:"} &nbsp;</span>
                  {
                    formatPhoneNumber(pharmacy?.primaryTelephone)
                  }
                  <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span className=" font-12">{"Fax:"} &nbsp;</span>
                    {formatPhoneNumber(pharmacy?.fax)}
                  </span>
                </div>
              </div>
            </div>

            {/* ----------------------Drug Information------------------------- */}
            <div className="col-12 col-md-12 mt-2 ">
              <h3 className="text--terciary font-14  m-0">Medication</h3>
              <div className="text--terciary font-12 border--bottom">
                <div>
                  <span className="text--terciary font-12 ">{"Drug Description:"} &nbsp;</span>
                  <span >{medication?.drugDescription}</span>
                </div>
                <div>
                  <span className="">{"Sig:"} &nbsp;</span>
                  {"".concat(medication?.sigText)}
                </div>
                <div>
                  <span className="">{"Quantity:"} &nbsp;</span>
                  <span>{"".concat(medication?.quantity, " ", medication?.quantityUnitOfMeasure)} &nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <span className="">{"Refills:"} &nbsp;</span>
                  {"".concat("", medication?.refills)}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>
                    <span className="">{"Substitutions: "} &nbsp;</span>
                    {(medication?.substitution == "true" || medication?.substitution === true) 
                      ? "Not Allowed"
                      : "Allowed"}
                  </span>
                </div>
                {
                  medication?.pharmacyNote !== undefined &&
                  medication?.pharmacyNote?.length > 0 &&
                  <div>
                    <span>{"Note:"} &nbsp;</span>
                    <span>{medication?.pharmacyNote}</span>
                  </div>
                }
                {/* <div className="mb-2">
                  <span className="">{"Effective Date:"} &nbsp;</span>
                  {"".concat(
                    calenderDateTimeHelper(
                      medication?.writtenDate,
                      "YYYY-MM-DD"
                    )
                  )}
                </div> */}
              </div>
            </div>
            {/* -------------------------Note to Pharmacy----------------------- */}
            <div className="col-12 col-md-12 mt-2">
              <div className="d-flex flex-row justify-content-between">
                <h3 className="font-14 text--terciary m-0">Note</h3>
                <div className="text--secondary font-10 my-auto p-0"><span>{pharmacyNote?.length + "/210"}</span></div>
              </div>
              <TextArea
                className="form-control br-10 input font-14 border--default shadow-none"
                gettext={handleCancelNote}
                rows={3}
                maxLength={210}
              />
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn d9e8ec--button text-blue font-14 px-4 br-10 py-1 fw-sb"
            onClick={(e) => {
              props.handleModal();
            }}
            style={{
              border: "#004FD4 0% 0% no-repeat padding-box",
              width: "160px",
              height: "44px",
            }}
          >
            Close
          </button>
          <button
            className="btn blue_task_button text-white font-14 px-4 br-10 py-1 fw-sb"
            onClick={(e) => {
              handleCancelRx();
              props.handleModal();
            }}
            style={{
              border: "#004FD4 0% 0% no-repeat padding-box",
              width: "160px",
              height: "44px",
            }}
          >
            Cancel Rx
          </button>
        </Modal.Footer>
      </Modal>


      {/* {console.log("monCount",monCount)} */}
      {/* {console.log("mondaySlotsList",mondaySlotsList)} */}
    </>
  );
};

export default CancelRxModal;


import sendHttpRequest from "../hooks/sendHttpRequest";

export default class AppointmentService {


    static fetchPatientDefaultForms = async (patientId) => {
        try {
            const httpResponse = await sendHttpRequest({
                url: `/appointment-consent-forms/${patientId}/default`,
                method: "GET",

            });
            if (!httpResponse.error) {
                return httpResponse.data;
            }
            else {
                return null;
            }
        }
        catch (err) {
            console.log("Error in fetching patient demographics: ", err);
        }
    }


}
import React, { useState, useEffect } from "react";
import sendHttpRequest from "../hooks/sendHttpRequest";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { dateTimeHelper } from "../../utils/dateTimeHelper";
import classes from "./HistoryOfPresentIllness.module.css";
import { useParams } from "react-router-dom";
import { Skeleton } from "@mui/material";
import {
  setPatientEncounterData,
  setUpdateEncounterData,
} from "../../redux/actions/providerActions";
import { useDispatch, useSelector } from "react-redux";
import TextArea from "../forms/TextArea";
import { patientEncountersMetadataUrl } from "../../res/endpointurls";

const HistoryOfPresentIllness = (props) => {
  const updateEncounterData = useSelector(
    (state) => state.UpdateEncounter.Encounter
  );
  const patientEncounterInformation = useSelector(
    (state) => state.PatientEncounterData.appoinment
  );

  const dispatch = useDispatch();
  const { providerId, encounterId, patientId } = useParams();

  const [historyOfPresentIllness, setHistoryOfPresentIllness] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchHistoryOfPresentIllness = async (patientId) => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: patientEncountersMetadataUrl(patientId, encounterId),
      method: "GET",
    });
    if (!httpResponse.error) {
      setHistoryOfPresentIllness(httpResponse.data);
      // dispatch(setPatientEncounterData({ ...patientEncounterInformation, encounter: { ...patientEncounterInformation.encounter, historyOfPresentIllness: httpResponse.data?.at(0)?.historyOfPresentIllness || "" } }))
    } else {
      console.log(httpResponse.error);
    }
    setIsLoading(false);
  };

  function getText(text) {
    props.setUnsavedChanges(true);
    dispatch(
      setUpdateEncounterData({
        ...updateEncounterData,
        encounterId: encounterId,
        historyOfPresentIllness: text,
      })
    );
    dispatch(
      setPatientEncounterData({
        ...patientEncounterInformation,
        encounter: {
          ...patientEncounterInformation.encounter,
          historyOfPresentIllness: text,
        },
      })
    );
  }
  useEffect(() => {
    fetchHistoryOfPresentIllness(patientId);
  }, []);

  return (
    <>
      <div className="my-5">
        <h4 className="h18"> History of Present Illness </h4>
        <div className="mb-3">
          <label htmlFor="" className="form-label"></label>

          <TextArea
            className="form-control shadow-none input br-10 border--default "
            name="historyOfPresentIllness"
            id="historyOfPresentIllness"
            rows="3"
            gettext={getText}
            defaultValue={
              patientEncounterInformation?.encounter
                ?.historyOfPresentIllness ||
              props?.encounterApiData?.encounter?.historyOfPresentIllness
              || ""

            }
          />
        </div>
        {isLoading ? (
          <Timeline>
            {" "}
            <Skeleton
              animation="wave"
              variant="text"
              width={"100%"}
              height={200}
            />{" "}
          </Timeline>
        ) : (
          <Timeline>
            {historyOfPresentIllness?.length > 0
              ? historyOfPresentIllness?.map((encounter, index) => {
                return encounter?.historyOfPresentIllness !== undefined &&
                  encounter?.historyOfPresentIllness?.trim() !== "" ? (
                  <TimelineItem key={encounter + index}>
                    <TimelineSeparator>
                      <TimelineDot
                        style={{ backgroundColor: "var(--terciary)" }}
                      />
                      <TimelineConnector
                        style={{ backgroundColor: "var(--d9e8ec)" }}
                      />
                    </TimelineSeparator>
                    <TimelineContent>
                      <h4 className="mb-0 font-12">
                        {encounter?.encounterCreatedDate !== null &&
                          encounter?.encounterCreatedDate !== "" &&
                          encounter?.encounterCreatedDate != undefined
                          ? dateTimeHelper(
                            encounter?.encounterCreatedDate,
                            "MMM D, YYYY - LT"
                          )
                          : ""}
                      </h4>
                      <p
                        className="mt-2 font-16"
                        style={{ color: "var(--secondary)" }}
                      >
                        {encounter?.historyOfPresentIllness}
                      </p>
                    </TimelineContent>
                  </TimelineItem>
                ) : null;
              })
              : ""}
          </Timeline>
        )}
      </div>
      {/* {console.log("updateEncounterData",updateEncounterData)} */}
    </>
  );
};

export default HistoryOfPresentIllness;

import { ENCOUNTERS, TASKS } from "../../../utils/helpers/subscriptionsConstants";
import GreetingMessage from "./GreetingMessage";
import { useSelector } from "react-redux";

const Greeting = (props) => {
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);
  const classes = "row border-bottom "
  return (
    <div className={`${classes} ${props.className} ${props?.inPatient ? "pb-3" : "py-3"}  `}>
      <div id="pn-provider-greeting" className="col-12 col-sm-12 col-md-4">
        <GreetingMessage />
      </div>
      <div className="col-12 col-sm-12 col-md-8">
        <div className="row justify-content-end description-style">

          {
            subscriptionPrivilegeGroups[TASKS]?.vts?.active === true ?
              <div id="pn-provider-refills" className=" col-12 col-sm-12 col-md-4">
                {props?.refills ?? ""}
              </div> : null
          }
          {
            subscriptionPrivilegeGroups[ENCOUNTERS]?.vpe?.active === true ?
              <div id="pn-provider-encounters" className="col-12 col-sm-12 col-md-4">
                {props?.encounters ?? ""}
              </div>
              : null
          }

          <div id="pn-provider-messages" className="col-12 col-sm-12 col-md-4">
            {props?.messages ?? ""}
          </div>


        </div>
      </div>
    </div>
  );
};

export default Greeting;


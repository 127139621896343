import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import sendHttpRequest from "../hooks/sendHttpRequest";
import EditButtonIcon from "../../assets/icons/Icon-Edit.svg";
import  AddButtonIcon  from "../../../src/assets/icons/Icon-Add.svg";
import FamilyMedicalHistoryModal from "../UI/Modals/PatientModals/FamilyMedicalHistoryModal";
import FamilyMedicalHistoryEditModal from "../UI/Modals/PatientModals/FamilyMedicalHistoryEditModal";
import { useParams } from "react-router-dom";
import { Skeleton, Tooltip, tooltipClasses } from "@mui/material";
import TrashIcon from "../../assets/icons/TrashIcon";
import { getFamilyMedicalHistoryUrl, addFamilyMedicalHistoryUrl, updateFamilyMedicalHistoryUrl } from "../../res/endpointurls";
import { familyMedicalHistoryStrings } from "../../res/strings";
import { truncate } from "../../utils/helpers/HelperFunctions";
import EditIcon from "../../assets/icons/EditIcon";
import { useAuth } from "../hooks/useAuth";

const ColoredToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#336383",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#336383",
  },
}));

function getObjectKeys(params) {
  return Object.keys(params);
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FAFCFC",
    color: "#336383",
    fontWeight: 400,
    fontSize: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    paddingRight: "0px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  minWidth: 130,
  "&:nth-of-type(even)": {
    backgroundColor: "#FAFCFC",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles({
  customTable: {
    "& .MuiTableCell-sizeMedium": {
      padding: "0px", // <-- arbitrary value
    },
  },
  tableCell: {
    textTransform: "capitalize",
  },
});

const FamilyMedicalHistoryTable = (props) => {
  const {user} = useAuth();
  const { providerId, encounterId, patientId } = useParams();
  const _str = familyMedicalHistoryStrings.en;
  const classes = useStyles();
  const [familyMedicalHistory, setFamilyMedicalHistory] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [relationShip, setRelationShip] = useState("");
  const [condition, setCondition] = useState("");
  const [note, setNote] = useState("");
  const [editFMH, setEditFMH] = useState("");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [updateFamilyMedicationHisyoryId, setUpdateFamilyMedicationHisyoryId] =
    useState();
  const [familyMedicalHistoryStatusCode, setFamilyMedicalHistoryStatusCode] =
    useState();
  const [noteTextSize, setNoteTextSize] = useState(70);
  const fetchFamilyMedicalHistory = async (patientId) => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: getFamilyMedicalHistoryUrl(patientId || props?.patientId),
      method: "GET",
    });
    if (!httpResponse.error) {
      setFamilyMedicalHistory(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
    setFamilyMedicalHistoryStatusCode(httpResponse.status);
    setIsLoading(false);
  };

  const handleEditModal = (event) => {
    let id = event.target.id;
    setUpdateFamilyMedicationHisyoryId(id);

    const fmhResult = familyMedicalHistory.filter(
      (item) => {
        return item.familyMedicalHistoryId == id;
      }
    );

    setEditFMH(fmhResult[0]);
    setRelationShip(fmhResult[0].relationship);
    setNote(fmhResult[0].note);
    setCondition(fmhResult[0].condition);

    setOpenEditModal(!openModal);
  };

  const addFamilyMedicationRequestHandler = async () => {
    const httpResponse = await sendHttpRequest({
      url: props?.patientPortal ? `/patients/${user.userId}/family-medical-history`: addFamilyMedicalHistoryUrl(encounterId),
      method: "POST",
      data: {
        condition: condition,
        relationship: relationShip,
        note: note,
      }
    });
    if (!httpResponse.error) {
      emptyStates();
      fetchFamilyMedicalHistory(patientId);
      // console.log(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
  };

  const editFamilyMedicalHistoryRequestHandler = async () => {
    const httpResponse = await sendHttpRequest({
      url: props?.patientPortal ? `/patients/${user.userId}/family-medical-history/${updateFamilyMedicationHisyoryId}`: updateFamilyMedicalHistoryUrl(encounterId, updateFamilyMedicationHisyoryId),
      method: "PUT",
      data: {
        familyMedicalHistoryId: updateFamilyMedicationHisyoryId,
        condition: condition,
        relationship: relationShip,
        note: note,
      },
    });
    if (!httpResponse.error) {
      emptyStates();
      fetchFamilyMedicalHistory(patientId);
      // console.log(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
  };

  const emptyStates = () => {
    setRelationShip("");
    setNote("");
    setCondition("");
  };

  const handleModal = () => {
    setOpenModal(!openModal);
  };
  useEffect(() => {
    fetchFamilyMedicalHistory(patientId || props?.patientId);
  }, [patientId, props?.patientId]);

  const OpeneditModal = () => {
    setOpenEditModal(!openEditModal);
  };

  return (
    <>
      {openModal ? <FamilyMedicalHistoryModal
        show={openModal}
        handleModal={handleModal}
        setRelationShip={setRelationShip}
        setCondition={setCondition}
        setNote={setNote}
        addFamilyMedicationRequestHandler={addFamilyMedicationRequestHandler}
        editFamilyMedicalHistoryRequestHandler={editFamilyMedicalHistoryRequestHandler}
      /> : null}
      {openEditModal ? (
        <FamilyMedicalHistoryEditModal
          show={openEditModal}
          handleModal={OpeneditModal}
          setRelationShip={setRelationShip}
          setCondition={setCondition}
          setNote={setNote}
          editFMH={editFMH}
          editFamilyMedicalHistoryRequestHandler={
            editFamilyMedicalHistoryRequestHandler
          }
        />
      ) : null}
      <div
        className="d-flex mb-1 justify-content-between align-items-baseline"
        {...props}
      >
        <div className="d-inline-flex flex-column align-self-center">
          <h4 className="h18">{_str.familyMedicalHistory}</h4>
          {/* <p style={{ color: 'var(--secondary)' }}>Lorem Ipsum dolor asit</p> */}
        </div>
        <div className="d-flex align-self-center " style={{height:"40px", width:"40px"}}>
          {!props?.viewOnly && (
            <img
              style={{ color: "var(--primary)" }}
              className="btn shadow-none"
              onClick={() => handleModal()}
              src={AddButtonIcon}
              alt={"Add button family medical history"}
            />
          )}
        </div>
      </div>
      
      <TableContainer
        sx={{ borderRadius: "16px", border: "1px solid #D9E8EC" }}
        className="hide-scrolbar"
      >
        <Table>
          <TableHead
            sx={{
              borderTopColor: "#FAFCFC",
              borderRightColor: "#FAFCFC",
              borderLeftColor: "#FAFCFC",
              borderBottom: "1px solid #D9E8EC",
            }}
          >
            <StyledTableRow>
              <StyledTableCell>{_str.table_medicalCondition}</StyledTableCell>
              <StyledTableCell>{_str.table_relationship}</StyledTableCell>
              <StyledTableCell>{_str.note}</StyledTableCell>
              {!props?.viewOnly && (
                <>
                  {/* <StyledTableCell></StyledTableCell> */}
                  {/* <StyledTableCell></StyledTableCell> */}
                </>
              )}
            </StyledTableRow>
          </TableHead>
          {familyMedicalHistory.length > 0 ?
          <TableBody>

              {isLoading ? <StyledTableRow>
                <StyledTableCell>
                  <Skeleton animation="wave" />
                </StyledTableCell>
                <StyledTableCell>
                  <Skeleton animation="wave" />
                </StyledTableCell>
                <StyledTableCell>
                  <Skeleton animation="wave" />
                </StyledTableCell>
                {!props?.viewOnly && (
                  <>
                    {/* <StyledTableCell>
                      <Skeleton animation="wave" />
                    </StyledTableCell> */}
                    {/* <StyledTableCell>
                      <Skeleton animation="wave" />
                    </StyledTableCell> */}
                  </>
                )}
              </StyledTableRow> : familyMedicalHistoryStatusCode === 200 ? familyMedicalHistory?.map((row, index) => {
                return (
                  <StyledTableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <StyledTableCell
                      sx={{ color: "#336383", maxWidth: 150, minWidth: 100 }}
                    >
                      {row?.condition !== undefined &&
                        row?.condition !== ""
                        ? row.condition
                        : "Not available"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row?.relationship !== undefined &&
                        row?.relationship !== ""
                        ? row.relationship
                        : "Not available"}
                    </StyledTableCell>
                    {/* Display long text with Tooltip */}
                    {row.note.length > noteTextSize ?
                      <ColoredToolTip title={row?.note} arrow placement="top">
                        <StyledTableCell sx={{ color: "#336383" }}>
                          {row?.note !== undefined && row?.note !== ""
                            ? truncate(row.note, noteTextSize)
                            : "Not available"}
                        </StyledTableCell>
                      </ColoredToolTip>
                      : <StyledTableCell sx={{ color: "#336383" }}>
                        {row?.note !== undefined && row?.note !== ""
                          ? row.note
                          : "Not available"}
                      </StyledTableCell>
                    }
                    {!props?.viewOnly && (
                      <>
                        {/* <StyledTableCell /> */}
                        <StyledTableCell style={{ cursor: "pointer" }}>
                          <div className="d-flex flex-row col-11 justify-content-end ms-1">
                            <div className="row">
                              <div className="col-4">
                                <EditIcon
                                  style={{color: "#336383", height: "15px", width: "15px"}}
                                  onClick={(e) => handleEditModal(e)}
                                  id={row.familyMedicalHistoryId}
                                />
                              </div>
                              <div className="col-4">
                                <TrashIcon
                                  style={{ color: "#A2D1DE", height: "15px", width: "15px" }}
                                />
                              </div>
                            </div>
                          </div>
                        </StyledTableCell>
                      </>
                    )}
                  </StyledTableRow>
                );
              }) : ""}


          </TableBody>
          : <p className="m-2 font-14 p-1">No data available</p>
        }
        </Table>
      </TableContainer>
      
    </>
  );
};
export default FamilyMedicalHistoryTable;

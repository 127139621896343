import InputMask from "react-input-mask";
export default function PhoneInput(props) {
  return (
    <InputMask
      mask="(999) 999-9999"
      value={props.value}
      onChange={props.onChange}
      maskChar="_"
      className="form-control font-14 border--default br-10 shadow-none input"
      onInvalid={(e) => {
        e.target.setCustomValidity("Please fill out this field");
      }}
      onInput={(e) => {
        e.target.setCustomValidity("");
      }}
      {...props}
    ></InputMask>
  );
}

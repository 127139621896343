import classes from "./BillingCardContainer.module.css";



const BillingCardContainer = ({ children }) => {
    return (
        <div className={`${classes['billing-card-container']}`}>
            {children}
        </div>
    )
}

export default BillingCardContainer
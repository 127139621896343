import sendHttpRequest from "../hooks/sendHttpRequest";

export default class PrescriptionReferenceService {

    static getPrescriptionReferenceData = async (prescriptionReferenceId) => {
        try {
            const response = await sendHttpRequest({
                method: "GET",
                url: `/prescription-reference/${prescriptionReferenceId}`
            });
            if (!response.error) {
                return response.data;
            }
        }
        catch (err) {
            console.log(err);
        }
    }


}
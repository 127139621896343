import PageHeader from "../generic/PageHeader"

const PatientPayments = ()=>{
    return (
        <>
            <PageHeader title={"Payments"}/>
        </>
    )
}

export default PatientPayments;

import { ChevronLeft } from '@mui/icons-material';
import { useNavigate } from 'react-router';


const PageHeader = ({ title, children }) => {
    const navigate = useNavigate();

    return (
        <div className="container-fluid border--bottom bg--white " >
            <div className='d-flex justify-content-between'>
                <div className="d-flex p-1 py-3 bg-white">
                    <div className="align-self-center">
                        <div className="d-flex flex-row">
                            <div
                                style={{ width: "32px", height: "32px" }}
                                className={`bg--background  br-10 me-3 border--default border align-self-center text-center my-auto `}
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                <ChevronLeft
                                    style={{ color: "#EC609B", width: "20px", height: "20px" }}
                                    className="cursor-pointer my-auto mt-1"
                                />
                            </div>
                            <div className="font-22  fw-sb text--terciary align-self-center">
                                {title}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex py-3">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default PageHeader
import { Form, FormSelect, Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import TextArea from "../../components/forms/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Skeleton, styled, alpha } from "@mui/material";
import sendHttpRequest from "../../components/hooks/sendHttpRequest";
import {
  calenderDateTimeHelper,
  changeDateTimeFormat,
  dateTimeHelper,
} from "../../utils/dateTimeHelper";
import classes from "./NewPatientAppointmentModal.module.css";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { setPatientID } from "../../redux/actions/providerActions";
import moment from "moment-timezone";
import { RolesTextConversion } from "../../utils/helpers/RolesMap";
import { useAuth } from "../../components/hooks/useAuth";

import { useUnsavedWorkPrompt } from "../../components/hooks/useUnsavedWorkPrompt";
import { unsavedWorkHandler } from "../../utils/helpers/HelperFunctions";
import AppoinmentCalender from "../../components/UI/General/AppointmentCalender";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import { newAppointmentModalStrings } from "../../res/strings";
import { aptServicePNTURL } from "../../res/endpointurls";


const PatientVisitType = ({
  setPatientVisitType,
  appointmentType,
  fecthAllPrescribers,
}) => {
  const [patientVisit, setPatientVisit] = useState("virtual");

  const handlePntVisitType = (event) => {
    const id = event.target.id;
    setPatientVisit(id);
    setPatientVisitType(id);
    if (id === "virtual") {
      fecthAllPrescribers(0);
    }
  };

  return (
    <>
      
      <input
        type="radio"
        name="patientVisitType"
        id="virtual"
        value="virtual"
        className="me-1 cursor--pointer"
        checked={patientVisit === "virtual"}
        onChange={(e) => handlePntVisitType(e)}
      />
      <label htmlFor="virtual" className="me-5 font-14 text--terciary">
        {"Virtual"}
      </label>
      <input
        type="radio"
        name="patientVisitType"
        id="in-patient"
        value="in-patient"
        className="me-1 cursor--pointer"
        checked={patientVisit === "in-patient"}
        onChange={(e) => handlePntVisitType(e)}
      />
      <label htmlFor="in-patient" className="me-5 font-14 text--terciary">
        {"In-Person"}
      </label>
    </>
  );
};

const ProviderFacility = ({
  facilitiesData,
  isFacilityLoading,
  handlefacilityId,
  setSelectedFacility,
  fecthAllPrescribers,
  appointmentType,
}) => {
  const { user } = useAuth();
  const __str = newAppointmentModalStrings.en;

  return (
    <>
      <label className="fw-sb font-14 text--terciary">
        {__str.facilities_str}
      </label>
      {isFacilityLoading ? (
        <Skeleton />
      ) : (
        <select
          className={`form-select font-14 shadow-none ${classes["custom-select"]} border--default br-10`}
          aria-label="Select follow up appointment"
          style={{
            height: "40px",
          }}
          onChange={(e) => {
            handlefacilityId(e.target.value);
            // onDirty();
          }}
        >
          <option value="0" selected key={"facilityAll"}>
            {"All Facilities"}
          </option>
          {facilitiesData.map((facilityItem) => {
            return (
              <option
                value={facilityItem?.facilityId}
                id={facilityItem?.facilityId}
                key={facilityItem?.facilityId}
              >
                {facilityItem?.businessName}
              </option>
            );
          })}
        </select>
      )}
    </>
  );
};

const NewPatientAppointmentModal = (props) => {
  const patientId = useSelector((state) => state.searchedPatientId.patientId);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingud, setIsLoadingud] = useState(true);
  const [isSlotsLoading, setIsSlotsLoading] = useState(false);
  const [prescribersList, setPrescribersList] = useState([]);
  const [slotsTimezone, setSlotsTimezone] = useState();
  const [slotsTimezoneOffset, setSlotsTimezoneOffset] = useState();
  const [appointmentType, setAppointmentType] = useState();
  const [providerID, setProviderID] = useState(props?.selectedPrescriberId);
  // const [providerID, setProviderID] = useState();
  const [reasonOfVisit, setReasonOfVisit] = useState("");
  const [commentText, setCommentText] = useState("");
  const [freeSlots, setFreeSlots] = useState([]);
  const [slotId, setSlotId] = useState();
  const [slotStartTime, setSlotStartTime] = useState();
  const [unSelectFreeSlot, setUnSelectFreeSlot] = useState(false);
  const [isAppointmentScheduled, setIsAppointmentScheduled] = useState(false);
  const [customStartTime, setCustomStartTime] = useState("");
  const [customEndTime, setCustomEndTime] = useState("");
  const [customDuration, setCustomDuration] = useState(15);
  const [timexone, setTimexone] = useState("");
  const [isDurationLoading, setIsDurationLoading] = useState(false);
  const { user } = useAuth();
  // console.log(props);

  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const joined = [year, month, day].join("-");
  const [scheduleDate, setScheduleDate] = useState(joined);
  const [appointmentDuration, setAppointmentDuration] = useState();

  const [isDirty, onDirty, onPristine] = useUnsavedWorkPrompt();
  //=====Unsaved work code - BEGIN=====//

  // =========Patient visit type=================
  const [patientVisitType, setPatientVisitType] = useState("virtual");
  // =========Facility===========================
  const [selectedFacility, setSelectedFacility] = useState(0);
  const [isFacilityLoading, setIsFacilityLoading] = useState(false);
  const [facilitiesData, setFacilitiesData] = useState([]);
  // console.log("selectedFacility",selectedFacility);

  //==========Appointment Service States=============================
  const [appointmentServices,setAppointmentServices] = useState([]);

  const gettext = (text) => {
    setReasonOfVisit(text.trim());
    onDirty();
  };
  const getComment = (event) => {
    setCommentText(event.trim());
    onDirty();
  };

  const fecthAllPrescribers = async (facilityId) => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/provider/providers",
      method: "GET",
      params: {
        // appointmentType: appointmentType,
        patientId: user.userId,
        facilityId: facilityId
      },
    });
    if (!httpResponse.error) {
      // console.log("prescribers-> ", httpResponse.data);
      setPrescribersList(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
    setIsLoading(false);
  };
  const fecthFreeSlots1 = async (
    providerId,
    apptType,
    scheduleSelectedDate,
    duration
  ) => {
    setIsSlotsLoading(true);
    setFreeSlots([]);
    const httpResponse = await sendHttpRequest({
      url: "/availabilities/availableSlots",
      method: "GET",
      params: {
        providerId: providerId,
        fromDate:
          scheduleSelectedDate === undefined
            ? scheduleDate
            : scheduleSelectedDate,
        toDate:
          scheduleSelectedDate === undefined
            ? scheduleDate
            : scheduleSelectedDate,
        appointmentType: apptType,
        patientId: user?.userId,
        page: 0,
        duplicates: false,
        tenantId: user?.tenantId,
        facilityId: selectedFacility,
        duration: duration === undefined ? appointmentType?.durationMinutes : duration
      },
    });
    if (!httpResponse.error) {
      setFreeSlots(httpResponse?.data?.providersAvailabilityList);
    } else {
      console.log(httpResponse.error);
    }
    setIsSlotsLoading(false);
  };

  const createAppointment = async () => {
    setIsAppointmentScheduled(true);
    let duration = 0;
    let appoinmentStartTime = -1;
    duration = appointmentDuration;
    appoinmentStartTime = slotStartTime;
    // if (appointmentType === "NEW") {
    //   duration = 45;
    //   appoinmentStartTime = slotStartTime;
    // } else if (appointmentType === "FOLLOW_UP") {
    //   duration = 20;
    //   appoinmentStartTime = slotStartTime;
    // }
    const httpResponse = await sendHttpRequest({
      url: "/appointment/reserve",
      method: "POST",
      data: {
        prescriptionReference: {
          patient: {
            userId: user?.userId,
          },
          provider: {
            userId: parseInt(providerID),
          },
        },
        chiefComplaint: reasonOfVisit,
        comments: commentText,
        startTime: appoinmentStartTime,
        appointmentType: appointmentType,
        appointmentSlot: {
          providerTenantPracticeLocation: {
            provider: {
              userId: parseInt(providerID),
            },
            tenant: {
              tenantId: user?.tenantId
            },
            facility: {
              facilityId: selectedFacility
            }
          },
          patient: {
            userId: user?.userId,
          },
          startDateTime: appoinmentStartTime,
          duration: duration,
          startDate: scheduleDate,
        },
        facilityId: selectedFacility,
        tenantId: user?.tenantId,
        patientVisitType: { internalValue: patientVisitType },
      },
    });
    if (!httpResponse.error) {
      let jsonApptDto = {
        prescriptionReference: {
          patient: {
            userId: user?.userId,
          },
          provider: {
            userId: parseInt(providerID),
          },
        },
        chiefComplaint: reasonOfVisit,
        comments: commentText,
        startTime: appoinmentStartTime,
        appointmentType: appointmentType,
        appointmentSlot: {
          id: httpResponse.data.id,
          provider: {
            userId:
              httpResponse.data.providerTenantPracticeLocation.provider?.userId,
          },
          providerTenantPracticeLocation: {
            provider: {
              userId:
                httpResponse.data?.providerTenantPracticeLocation?.provider
                  ?.userId,
            },
          },
          patient: {
            userId: user?.userId,
          },
          startDateTime: appoinmentStartTime,
          duration: duration,
          startDate: scheduleDate,
        },
        patientVisitType: { internalValue: patientVisitType },
      };

      localStorage.setItem("apptDto", JSON.stringify(jsonApptDto));

      props?.setApptData(httpResponse.data);
      props?.setApptTypeData(appointmentType);
      props?.handleApptReserveModal();
      clearStates();
    } else {
      props?.setIsApptCreatedError(true);
      console.log(httpResponse.error);
    }

    // } else {
    //   props?.setIsAppointmentNotRescheduled(true);
    //   props?.setAppointmentNotScheduledError("Please select a valid patient");
    // }
    setIsAppointmentScheduled(false);
  };

  const handleAppointmentType = (event) => {
    let tenantServiceId = event.target.value;
    let aptObject = appointmentServices?.find((item)=>{ return item.tenantServiceId === Number(tenantServiceId)});
    let aptType = aptObject.key;

    setAppointmentType(aptObject);
    setUnSelectFreeSlot(true);
    setSlotId("");
    setSlotStartTime("");
    if (providerID !== 0) {
      // fetchAppointmentDuration(providerID, event.target.value);
      setAppointmentDuration(aptObject?.durationMinutes);
      fecthFreeSlots1(providerID, aptType,undefined,aptObject?.durationMinutes);
    }
  };

  const handleProviderId = (event) => {
    // fetchAppointmentDuration(event.target.value, appointmentType);
    fecthFreeSlots1(event.target.value, appointmentType?.key);
    setProviderID(event.target.value);
  };

  const handleFreeSlot = (event) => {
    setUnSelectFreeSlot(false);
    // let slotId = event.target[event.target.selectedIndex].getAttribute("data");
    let slotStartTime = event.target.value;
    // setSlotId(slotId);
    setSlotStartTime(slotStartTime);

    // alert(event.target.data)
  };

  const handleScheduleDate = (dateValue) => {
    if (providerID !== 0) {
      fecthFreeSlots1(providerID, appointmentType, dateValue);
    }
    setScheduleDate(dateValue);
  };

  const handleCustomStartTimeChange = (e) => {
    setCustomStartTime(e.target.value);
    // console.log("custom start time", e.target.value);
    onDirty();
  };

  const handleCustomDuration = (e) => {
    setCustomDuration(e.target.value);
    onDirty();
  };

  const handleNewAppointmentModal = (e) => {
    // console.log(e );
  };
 
  const removeSelectedUser = async () => {
    setFreeSlots([]);
    setProviderID(props?.selectedPrescriberId);
    const httpResponse = await sendHttpRequest({
      url: "/patient/deselect",
      method: "POST",
    });
    if (!httpResponse.error) {
      // setTimexone(httpResponse?.data?.timezone);
    } else {
      console.log(httpResponse.error);
    }
  };
  const fetchAppointmentDuration = async (providerId, appointmentType) => {
    setIsDurationLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/appointment/duration",
      method: "GET",
      params: {
        prescriberId: providerId,
        appointmentType: appointmentType,
        tenantId: user?.tenantId
      },
    });
    if (!httpResponse.error) {
      let duration = httpResponse.data;
      setAppointmentDuration(duration[0]);
    } else {
      console.log(httpResponse.error);
    }
    setIsDurationLoading(false);
  };

  const fetchProviderFacilities = async () => {
    setIsFacilityLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/facilities/tenant/${user?.tenantId}`,
      method: "GET",
    });
    if (!httpResponse.error) {
      setFacilitiesData(httpResponse.data);
    }
    setIsFacilityLoading(false);
  };

  const fetchAppointmentService = async ()=>{
    const httpResponse = await sendHttpRequest({
      url:aptServicePNTURL(user.tenantId,true),
      method:"GET"
    })
    if(!httpResponse.error){
      let data = httpResponse?.data
      let appt = data.find((item)=> {return item.key?.toLowerCase() === "new"})
      setAppointmentServices(data)
      setAppointmentType(appt)
      setAppointmentDuration(appt?.durationMinutes)
    }else{
      setAppointmentServices([])
    }
  }

  const clearStates = () => {
    setAppointmentType("NEW");
    setFreeSlots([]);
    setSlotsTimezoneOffset("");
    setSlotsTimezone("");
    setProviderID(0);
    removeSelectedUser();
    props.handleModal();
  };

  const handlefacilityId = (facilityId) => {
    setSelectedFacility(facilityId)
    if (user?.userId !== -1) {
      fecthAllPrescribers(facilityId);
    }
  }

  useEffect(() => {
    fecthAllPrescribers(selectedFacility);
    setProviderID(props?.selectedPrescriberId);
    fetchAppointmentService();
    if (facilitiesData?.length === 0) {
      fetchProviderFacilities();
    }

    return () => {
      clearStates();
      setPrescribersList([]);
    };
  }, []);

  return (
    <>
      {/* openApptResrvModal && ( */}
      {/* <PatientApptReservedModal show={openApptResrvModal} apptData={apptData} handleApptReserveModal={handleApptReserveModal} url={"/patient-portal/payment"} /> */}
      {/* ) */}
      <Modal
        show={props.show}
        onHide={() => {
          clearStates();
          setAppointmentType("NEW");
          setFreeSlots([]);
          setProviderID(0);
          setSlotsTimezoneOffset("");
          setSlotsTimezone("");
          handleNewAppointmentModal();
          removeSelectedUser();
          props.handleModal();
        }}
        // size = "xl"
        backdrop="static"
        backdropClassName="modal-backdrop"
        keyboard={false}
        centered
        contentClassName={`${classes["appointmentmodal__style"]} `}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName={`${classes["custom-modal-style"]}`}
      >
        <Modal.Header
          className={`${classes["appointmentmodal__border--bottom"]}`}
        >
          <span
            style={{
              lineHeight: "12px",
              width: "18px",
              fontSize: "8pt",
              color: "#336383",
              position: "absolute",
              top: 15,
              right: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              unsavedWorkHandler(
                "modal",
                "There are unsaved changes. Do you wish to continue?",
                isDirty,
                props.handleModal,
                onPristine,
                props.handleModal
              );
            }}
          >
            {<CloseIcon />}
          </span>
          <Modal.Title className="font-20 fw-sb text--terciary">
            New Appointment
            {/* <div className="" style={{ fontWeight: 400, fontSize: "12px" }}>
              {slotsTimezone === null ||
              slotsTimezone === "" ||
              slotsTimezone === undefined
                ? ""
                : "Patient Timezone: " + slotsTimezone}
            </div> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            id="newAppointmentForm"
            onSubmit={(e) => {
              e.preventDefault();
              createAppointment();
              // props.handleModal();
            }}
          >
            <div className="container-fluid px-3 ">
              <div className="row">
                <div className="col-6 px-4">
                  {/* Appointment type */}
                  <div className="form-group">
                    <label className="fw-sb font-14 text--terciary">
                      Appointment Type
                    </label>
                    <select
                      className={`form-select font-14 shadow-none ${classes["custom-select"]}`}
                      aria-label="Appointment type select"
                      style={{
                        border: "1px solid #D9E8EC",
                        height: "40px",
                        borderRadius: "10px",
                      }}
                      required
                      onChange={(e) => {
                        handleAppointmentType(e);
                        onDirty();
                      }}
                    >
                      {
                      appointmentServices.map((serviceItem)=>{
                        return(
                          <option value={serviceItem?.tenantServiceId} key={serviceItem?.tenantServiceId}>{serviceItem?.title}</option>        
                        )
                      })
                    }
                    </select>
                  </div>
                  {/* Patient Visit type */}
                  <div className="form-group mt-4">
                    <PatientVisitType
                      key={"patient-visit-type"}
                      setPatientVisitType={setPatientVisitType}
                      fecthAllPrescribers={fecthAllPrescribers}
                      appointmentType={appointmentType}
                    />
                  </div>

                  {/* Facility */}
                  <div className="form-group mt-4">
                    {patientVisitType === "in-patient" ? (
                      <ProviderFacility
                        facilitiesData={facilitiesData}
                        isFacilityLoading={isFacilityLoading}
                        setSelectedFacility={setSelectedFacility}
                        handlefacilityId={handlefacilityId}
                      />
                    ) : null}
                  </div>

                  {/* Provider */}
                  <div className="form-group mt-4">
                    <label className="fw-sb font-14 text--terciary">
                      {"Provider"}
                    </label>
                    {isLoading ? (
                      <Skeleton />
                    ) : (
                      <select
                        className={` border--default input br-10 form-select font-14 shadow-none }`}
                        aria-label="Provider select"
                        style={{
                          height: "40px",
                        }}
                        required
                        onChange={(e) => {
                          handleProviderId(e);
                          onDirty();
                        }}
                        onInvalid={(e) => {
                          e.target.setCustomValidity(
                            "Please select provider from the list"
                          );
                        }}
                        onInput={(e) => {
                          e.target.setCustomValidity("");
                        }}
                      >
                        <option
                          value=""
                          disabled
                          selected
                          hidden
                          key={"unselected"}
                        >
                          {"Select Provider"}
                        </option>
                        {patientId !== -1 &&
                          appointmentType !== "CUSTOM" &&
                          prescribersList?.length > 0 && (
                            <option value={0} key={0}>
                              {"Any"}
                            </option>
                          )}
                        {prescribersList !== undefined &&
                          prescribersList?.length > 0 ? (
                          prescribersList?.map((prescriberItem) => {
                            if (prescriberItem?.userId === -10) {
                              return (
                                <option
                                  value=""
                                  disabled
                                  selected
                                  hidden
                                  key={"unselected"}
                                >
                                  {"Select Provider"}
                                </option>
                              );
                            } else {
                              return (
                                <option
                                  value={prescriberItem?.userId}
                                  key={prescriberItem?.userId}
                                  selected={
                                    appointmentType === "FOLLOW_UP"
                                      ? prescriberItem?.userId === providerID
                                        ? true
                                        : false
                                      : false
                                  }
                                >
                                  {prescriberItem?.name[0]?.prefix}{" "}
                                  {prescriberItem?.name[0]?.firstName}{" "}
                                  {prescriberItem?.name[0]?.lastName}{" "}
                                  {prescriberItem?.speciality[0]?.speciality !==
                                    undefined
                                    ? "(" +
                                    prescriberItem?.speciality[0]?.speciality +
                                    ")"
                                    : ""}
                                </option>
                              );
                            }
                          })
                        ) : !isLoading && prescribersList?.length === 0 ? (
                          <option key={-1} value="" disabled>
                            {"No provider available"}
                          </option>
                        ) : (
                          <option key={-2} value="" disabled>
                            {"Loading provider(s)"}
                          </option>
                        )}
                      </select>
                    )}
                  </div>
                  {/* Reason for Visit */}
                  <div className="form-group mt-4">
                    <label className="fw-sb font-14 text--terciary">
                      Reason for Visit
                    </label>
                    {/* <h2 className="font-18 text--terciary">Chief Complaint</h2> */}
                    <div>
                      <TextArea
                        className="form-control input font-14 border--default shadow-none"
                        gettext={gettext}
                        // placeholder={"Write here..."}

                        rows={3}
                      />
                    </div>
                  </div>


                  <div className="form-group mt-4">
                    <label className="fw-sb font-14 text--terciary">
                      Comments
                    </label>
                    <TextArea
                      type="text"
                      rows={3}
                      // placeholder={"Write here..."}
                      className="form-control border--default font-14 input shadow-none"
                      gettext={getComment}
                    />
                  </div>
                </div>
                {/* <div className=" col-1"></div> */}
                <div className=" col-6 px-4">
                  <div className="form-group font-14">
                    <div style={{ borderBottom: "1px solid #d9e8ec" }}>
                      <label className="fw-sb font-14 text--terciary">
                        Select a Date &#38; Time
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <AppoinmentCalender
                      setScheduleDate={setScheduleDate}
                      EnableDirtyCheck={true}
                      onDirty={onDirty}
                      providerID={providerID}
                      appointmentType={appointmentType}
                      fecthFreeSlots1={fecthFreeSlots1}
                      appointmentDate={dateTimeHelper(new Date(), "YYYY-MM-DD")}
                    />
                  </div>
                  <div className="form-group mt-4">
                    {/* <label className="fw-sb font-14 text--terciary">
                      Provider Schedule
                    </label> */}

                    <div>
                      <div>
                        <div>
                          {/* <div className="col-sm-6">
                              <label className="text--terciary b2 fw-sb">
                                Date
                              </label>
                              <CustomDatePicker
                                dobHandler={handleScheduleDate}
                                dateFormat="MM/dd/yyyy"
                              />
                            </div> */}
                          <div className="row">
                            <div className="col-sm-4 ">
                              <label className="text--terciary font-14 fw-sb">
                                Start Time
                              </label>
                              <div>
                                <Form.Group>
                                  <Form.Control
                                    as="select"
                                    className={`col-sm-12 form-select  font-14 shadow-none ${classes["custom-select"]}`}
                                    aria-label="Slot select"
                                    required
                                    style={{
                                      border: "1px solid #D9E8EC",
                                      borderRadius: "10px",
                                    }}
                                    onChange={(e) => {
                                      handleFreeSlot(e);
                                      onDirty();
                                    }}
                                  >
                                    <option
                                      value=""
                                      className="col-sm-12 font-14"
                                      // selected={unSelectFreeSlot ? true : false}
                                      selected
                                      disabled
                                      hidden
                                    ></option>

                                    {freeSlots?.length > 0 ? (
                                      freeSlots?.map((slotObject, index) => {
                                        let aptStartTime = changeDateTimeFormat(
                                          slotObject?.startDateTime,
                                          "LT"
                                        );
                                        let aptEndTime = changeDateTimeFormat(
                                          slotObject?.EndTime,
                                          "LT"
                                        );
                                        return (
                                          <option
                                            key={
                                              slotObject?.startDateTime + index
                                            }
                                            className="col-sm-12 font-14 p-1 mt-1"
                                            // data={slotItem?.id}
                                            value={slotObject?.startDateTime}
                                            style={{ textAlign: "justify" }}
                                          >
                                            {aptStartTime}
                                          </option>
                                        );
                                      })
                                    ) : !isSlotsLoading &&
                                      freeSlots?.length <= 0 ? (
                                      <option
                                        key={0}
                                        className="col-sm-12 font-14 p-1 mt-1"
                                        // data={slotItem?.id}
                                        value=""
                                        style={{ textAlign: "justify" }}
                                        disabled
                                      >
                                        No slots available
                                      </option>
                                    ) : (
                                      <option
                                        key={0}
                                        className="col-sm-12 font-14 p-1 mt-1"
                                        // data={slotItem?.id}
                                        value=""
                                        style={{ textAlign: "justify" }}
                                        disabled
                                      >
                                        Loading Slots
                                      </option>
                                    )}
                                  </Form.Control>
                                </Form.Group>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div>
                                <label className="font-14 fw-sb text--terciary">
                                  Duration{" "}
                                </label>
                              </div>
                              <div
                                className="font-14  text--terciary align-self-center br-10"
                                style={{ border: "1px solid #d9e8ec" }}
                              >
                                <div className="center pt-1 ps-2 py-2">
                                  {appointmentDuration !== undefined ? (
                                    appointmentDuration + " minutes"
                                  ) : (
                                    <>&nbsp;</>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer
          //   style={{ border: "none" }}
          className="modal-footer"
        >
          <button
            type="submit"
            form="newAppointmentForm"
            style={{ height: 42, marginRight: 32 }}
            className={`btn blue_task_button`}
            onClick={(e) => {
              // createAppointment();
            }}
            disabled={isAppointmentScheduled}
          >
            {"Schedule"}
          </button>
        </Modal.Footer>
        {/* {console.log("patientId in appointment", patientId)}
      {console.log("provider Id", providerID)}
      {console.log("appointment Type", appointmentType)}
      {console.log("slotsList", freeSlots)} */}
      {console.log("appointmentDuration: ",appointmentDuration)}
      </Modal>
    </>
  );
};

export default NewPatientAppointmentModal;

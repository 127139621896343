import React, { useEffect, useRef, useState, useContext } from 'react'
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import Draggable from 'react-draggable'

import sendHttpRequest from '../../hooks/sendHttpRequest';
import { useToggle } from '../../hooks/useToggle';
import AgoraWaitingRoomModal from '../AgoraVideoComponents/AgoraWaitingRoomModal';
import { useStopwatch } from "react-timer-hook";
import { setConsulationInProgress, setVideoOpen } from "../../../redux/actions/providerActions";
import { apiKey } from "../AgoraVideoComponents/agoraDataObjects";
import "./AgoraMinimalVideoContainer.css"
import { strings } from '../../../res/strings';
import { Backdrop } from '@mui/material';
import useEventListener from '../../hooks/useEventListener';
import { VideoCallContext } from "../AgoraHooks/VideoCallContext";

const EndConsultationModal = ({
    duration,
    show,
    handleEndConsulationModal,
    hours,
    minutes,
    seconds,

}) => {

    const videoCallContext = useContext(VideoCallContext);

    const dispatch = useDispatch();
    const appointmentInformation = useSelector(
        (state) => state.PatientEncounterData.appoinment
    );
    const [isSessionEnding, setIsSessionEnding] = useToggle(false);
    const [moveToWaitingRoom, setMoveToWaitingRoom] = useToggle(false);
    const _str = strings.en;

    const handleMoveToWaitingRoom = (event) => {
        setMoveToWaitingRoom(event.target.checked);
    }

    const isRemoteUserAvailable = () => {
        return videoCallContext.client.remoteUsers?.length > 0;
    }


    const sessionBreakRequest = async (appointmentId) => {
        setIsSessionEnding(true);

        const breakType = !isRemoteUserAvailable() ? "WAITING" : moveToWaitingRoom ? "WAITING" : "END";
        let timeInSeconds = 0;
        try {
            timeInSeconds = (Number(hours) * 60 * 60) + Number(minutes) * 60 + Number(seconds);
        }
        catch (e) {
            console.error("CANNOT CONVERT TIME INTO SECONDS: ", e);
        }

        const httpResponse = await sendHttpRequest({
            method: "POST",
            url: `/video/SessionBreakRequest`,
            data: {
                appiontmentId: appointmentId,
                videoJoinRequestType: breakType,
                callDuration: timeInSeconds,
            }
        })

        if (!httpResponse.error) {

            videoCallContext.endSession().then(() => {
                handleEndConsulationModal(false);
                videoCallContext.clearVideoCallContextData();
                dispatch(setConsulationInProgress(false));
                dispatch(setVideoOpen(false));
            });

        }
        setIsSessionEnding(false);
    }




    return (
        <>
            <Modal
                show={show}
                size="md"
                dialogClassName="end-consultation-modal-dialog"
                backdropClassName="modal-backdrop"
                contentClassName="modal-border modal-shadow"
                centered
            >
                <Modal.Header style={{ border: "none" }}>
                    <div className="row ">
                        <div className="col-12 ">
                            <span
                                style={{
                                    lineHeight: "12px",
                                    width: "18px",
                                    fontSize: "8pt",
                                    color: "#336383",
                                    position: "absolute",
                                    top: 15,
                                    right: 15,
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    handleEndConsulationModal(p => false);
                                }}
                            >
                                {<CloseIcon />}
                            </span>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row py-2">
                        <div className="col-sm-12">
                            <h2 className="font-18 fw-sb text-center">End Consultation</h2>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-sm-5">
                            <div className="end-consultation-duration px-4 py-2">
                                <span className="fw-sb font-12 text--secondary ms-1 text-center">
                                    Duration: {(Number(hours) * 60) + minutes}:{seconds}
                                    {/* <span className='me-2'></span> */}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center py-2">
                        <div className="col-sm-10">
                            <span className="font-14 text--secondary d-block text-center">
                                Would you like to end the consultation with{" "}
                                <span className="fw-sb text--terciary d-block">

                                    {appointmentInformation?.appointmentSlot?.patient?.name[0]?.firstName?.concat(
                                        " ",
                                        appointmentInformation?.appointmentSlot?.patient?.name[0]
                                            ?.lastName
                                    )}

                                    <span className="font-14 text--secondary" style={{ fontWeight: 'normal' }}>{" "}?</span>
                                </span>

                            </span>
                        </div>
                    </div>

                    {videoCallContext.client.remoteUsers?.length > 0 ? <div className="row justify-content-center py-2">
                        <div className='col-10'>
                            <div className="form-check">
                                <input onClick={(e) => handleMoveToWaitingRoom(e)} checked={moveToWaitingRoom} className="form-check-input shadow-none br-10" type="checkbox" id="moveToWaitingRoom" />
                                <label className="form-check-label font-14 text--terciary" htmlFor="moveToWaitingRoom">
                                    {_str.move_to_waiting_room}
                                </label>
                            </div>

                        </div>
                    </div> : null}

                    <div className="row justify-content-center py-3">
                        <div className="col-sm-4">
                            <button
                                style={{ width: '7em' }}
                                onClick={handleEndConsulationModal}
                                className="btn br-10 bg--d9e8ec  shadow-none text--blue font-14 fw-sb"
                            >
                                Cancel
                            </button>
                        </div>


                        <div className="col-sm-4 ">

                            <button
                                style={{ width: '7em' }}
                                onClick={() => sessionBreakRequest(videoCallContext.appointmentData.data)}
                                className="btn mx-auto br-10 bg--red shadow-none text-white font-14 fw-sb"
                            >
                                End
                            </button>
                        </div>
                    </div>

                    <Backdrop
                        sx={{
                            color: "#EC609B",
                            zIndex: (theme) => theme.zIndex.drawer + 1000,
                        }}
                        open={isSessionEnding}
                    ></Backdrop>
                </Modal.Body>
            </Modal>
        </>
    );
};

const AgoraMinimalVideoContainerV2 = (props) => {

    const videoCallContext = useContext(VideoCallContext);
    const [isExpanded, setIsExpanded] = useState(false);
    const [deltas, setDeltas] = useState({
        x: 0, y: 0
    })

    let videosDiv = document.getElementById("videos");
    const [controlledPosition, setControlledPosition] = useState(
        {
            x: 0,
            y: 0
        }
    );
    const [showEndConsultationModal, setShowEndConsultationModal] =
        useState(props.endConsultation);
    const videoOpenState = useSelector((state) => state.isVideoOpen.isVideoOpen);
    const dispatch = useDispatch();


    const publisherRef = useRef();

    const [showWaitingRoom, setShowWaitingRoom] = useToggle(false);
    const [initiatePolling, setInitiatePolling] = useState(false);
    const [isVideoCameraBeingInitialized, setIsVideoCameraBeingInitialized] = useToggle(false);
    const [waitingRoomList, setWaitingRoomList] = useState([]);
    const {
        seconds,
        minutes,
        hours,
    } = useStopwatch({ autoStart: true });

    // MIGHT GET CHANGED DUE TO CHANGES IN ENCOUNTER
    const appointmentInformation = useSelector((state) => state.PatientEncounterData.appoinment);

    const providerNameArr = appointmentInformation?.appointmentSlot?.provider?.name[0];

    const providerName = providerNameArr?.prefix?.concat(" ", providerNameArr?.firstName, " ", providerNameArr?.lastName);

    const idRef = useRef();

    useEventListener("click", (e) => {
        const clickTarget = e.target;
        const isTargetControlButton = e.target.classList.contains('control-btn');
        const targetTagName = e.target.tagName.toLowerCase();
        const videosContainer = document.getElementById('videos');

        if (isTargetControlButton === false) {
            if (!clickTarget.parentNode.classList.contains('control-btn') && isExpanded) {
                console.log('Trigger');
                unExpand();
            }
        }


    }, document.getElementById('root'));

    // console.log("Appointment Information == >", appointmentInformation);

    const handleWaitingRoomModalClose = () => {
        setShowWaitingRoom();
    };

    const removeFromWaitingList = (id) => {
        setWaitingRoomList((prev) => {
            return prev.filter((item) => item?.id !== id);
        });

    };



    const waitingRoomPolling = async (appointmentId) => {

        if (showWaitingRoom === false) {
            console.log("Waiting Room Pulse Check...")
            const httpResponse = await sendHttpRequest({
                url: `/video/approvalrequest/${appointmentId}`,
                method: "GET",
            });
            if (!httpResponse.error) {
                // console.log(httpResponse);
                if (
                    httpResponse?.data !== null &&
                    httpResponse?.data !== "" &&
                    httpResponse?.data !== undefined
                ) {
                    setWaitingRoomList(httpResponse?.data);
                    sleepSync(1000);
                    setShowWaitingRoom(true);
                }
            }
        }
    };

    const getPrescriberSession = async (appointmentId) => {
        const httpResponse = await sendHttpRequest({
            url: `/video/getProviderSession/${appointmentId}`,
            method: "GET",
        });
        if (!httpResponse.error) {
            videoCallContext.setSessionId(httpResponse?.data?.sessionId)
            videoCallContext.setTokenId(httpResponse?.data?.token);
            if (videoCallContext.client?.connectionState?.toLowerCase() !== "connected") {
                videoCallContext.client
                    ?.join(apiKey, httpResponse?.data?.sessionId, httpResponse?.data?.token)
                    .then(() => {
                        videoCallContext.client.publish([videoCallContext.getAudioTrack(), videoCallContext.getVideoTrack()]);
                    })
                    .then(() => {
                        setInitiatePolling(true);
                    })
                    .then(() => {
                        videoCallContext.setIsVideoCallActive(true);
                    });
            }
        } else {
            console.log(httpResponse.error);
        }
    };




    function expandClicked() {
        if (!isExpanded) {
            //do expansion
            videosDiv.style.width = "75%";
            videosDiv.style.height = "75%";
            // undoDraggable();
            setIsExpanded((prev) => !prev);
            centerIt(videosDiv);
        } else {
            setIsExpanded((prev) => !prev);
            unExpand();
        }

    }

    function unExpand() {
        videosDiv.style.width = "";
        videosDiv.style.height = "";
        undoCenterIt(videosDiv);
        setIsExpanded((prev) => { return false });
    }



    function centerIt(element) {
        const elementWidth = videosDiv.offsetWidth;
        const parentWidth = videosDiv.parentElement.offsetWidth;
        const difference = parentWidth - elementWidth;
        const halfDifference = difference / 2;

        setDeltas({ x: halfDifference, y: 0 })
        setControlledPosition(prev => ({ x: halfDifference, y: 0 }))
    }

    const handleDrag = (e, ui) => {
        const { x, y } = deltas;
        setDeltas(prev => ({ x: x + ui.deltaX, y: y + ui.deltaY }))
        setControlledPosition(prev => ({ x: x + ui.deltaX, y: y + ui.deltaY }))
    };

    function undoCenterIt(element) {
        const vD = document.getElementById('videos');
        let rect = vD.getBoundingClientRect();
        let sW = window.innerWidth;
        let sH = window.innerHeight;
        let xVal = sW - rect.width - 300;
        let yVal = sH - rect.height - 250;
        setDeltas({ x: xVal, y: yVal })
        setControlledPosition(prev => ({ x: xVal, y: yVal }))
    }

    useEffect(() => {
        if (props.endConsultation) {
            setShowEndConsultationModal(props.endConsultation);
        }

    }, [props.endConsultation]);

    // useEffect(() => {
    //     console.log(props.mute);
    //     if (props.mute) {
    //         setVolumeOff();
    //         handlePublisherAudio();
    //     }
    // }, [props.mute])

    useEffect(() => {
        try {
            document.getElementById("videos").style.width = "75%";
            document.getElementById("videos").style.height = "75%";
            setIsExpanded((prev) => !prev);
            const elementWidth = document.getElementById("videos").offsetWidth;
            const parentWidth = document.getElementById("videos").parentElement.offsetWidth;
            const difference = parentWidth - elementWidth;
            const halfDifference = difference / 2;
            setDeltas({ x: halfDifference, y: 0 })
            setControlledPosition(prev => ({ x: halfDifference, y: 0 }))
        }
        catch (err) {
            console.error(err);
        }
        // setDeltas({
        //     x: document.getElementById("videos").parentElement.offsetWidth - document.getElementById("videos").offsetWidth,
        //     y: document.getElementById("videos").parentElement.offsetHeight - (document.getElementById("videos").offsetHeight * 3),
        // });
        // setControlledPosition({
        //     x: document.getElementById("videos").parentElement.offsetWidth - document.getElementById("videos").offsetWidth,
        //     y: document.getElementById("videos").parentElement.offsetHeight - (document.getElementById("videos").offsetHeight * 3),
        // })
    }, []);

    useEffect(() => {

        async function init() {
            // dispatch(setConsulationInProgress(true));
            // dispatch(setVideoOpen(true));
            setIsVideoCameraBeingInitialized(true);
            videoCallContext.createLocalTracks().then((res) => {
                videoCallContext.addVideoTrack(res[1]);
                videoCallContext.addAudioTrack(res[0]);
                res[1].play(document.getElementById("publisher"))
            })
                .then(() => waitingRoomPolling(videoCallContext.appointmentData.data))
                .then(() => getPrescriberSession(videoCallContext.appointmentData.data))

            const fullScreenPublisher = document.getElementById('publisher');
            fullScreenPublisher.style.width = '100%';
            fullScreenPublisher.style.height = '100%';
            fullScreenPublisher.style.left = '0';
            fullScreenPublisher.style.bottom = '0';
        }

        init();

    }, []);

    useEffect(() => {
        videoCallContext.client.on("user-published", async (remoteUser, mediaType) => {
            await videoCallContext.client.subscribe(remoteUser, mediaType);
            if (mediaType == "video") {
                console.log("subscribe video success");
                remoteUser.videoTrack.play(document.getElementById("subscriber"));

            }
            if (mediaType == "audio") {
                console.log("subscribe audio success");
                remoteUser.audioTrack.play();
            }
            const p = document.getElementById('publisher');
            p.style.width = 'calc(100% - calc(100% - 25%))';
            p.style.height = 'calc(100% - calc(100% - 30%))';
            p.style.right = '10px';
            p.style.bottom = '10px';
            p.style.border = '3px solid white';
            p.style.borderRadius = '3px';
            p.style.left = 'auto';
            p.zIndex = 100;
            props?.setParticipantJoined(true);

            if (videoOpenState === false) {
                dispatch(setVideoOpen(true));
            }

        })


        videoCallContext.client.on("user-unpublished", async (remoteUser, mediaType) => {
            console.log("Patient Left");
            console.log("Remote User", remoteUser);
            if (mediaType === "video") {
                await videoCallContext.client.unsubscribe(remoteUser, mediaType);
                const fullScreenPublisher = document.getElementById('publisher');
                fullScreenPublisher.style.width = '100%';
                fullScreenPublisher.style.height = '100%';
                fullScreenPublisher.style.left = '0';
                fullScreenPublisher.style.bottom = '0';

            }
            props?.setParticipantJoined(false);
            setInitiatePolling(p => true)
        }
        );

        // // client.enableAudioVolumeIndicator();
        // // client.on("volume-indicator", function (result) {
        // //     result.forEach(function (volume, index) {
        // //         console.log(`${index} UID ${volume.uid} Level ${volume.level}`);
        // //     });
        // // });

        // client.on('user-joined', (e, r) => {
        //     console.log("User joined", e, r);
        // });

        videoCallContext.client.on('user-left', async (u, r) => {
            if (r === 'Quit') {
                console.log("PN_USER_QUIT_MEETING");

                const fullScreenPublisher = document.getElementById('publisher');
                fullScreenPublisher.style.width = '100%';
                fullScreenPublisher.style.height = '100%';
                fullScreenPublisher.style.left = '0';
                fullScreenPublisher.style.bottom = '0';
                // setInitiatePolling(p => true)
                props?.setParticipantJoined(false);
            }
        })

        return () => {
            // setAgoraRTC(null);
            // setClient(null);
        };
    }, [appointmentInformation?.id]);


    useEffect(() => {
        console.log("Initiate polling", initiatePolling);

        const handleBeforeUnload = (event) => {
            const message = 'Are you sure you want to leave?';
            event.returnValue = message; // Standard for most browsers
            return message; // For some older browsers
        };

        window.addEventListener('beforeunload', handleBeforeUnload);


        // if (initiatePolling === true) {
        idRef.current = setInterval(
            () => {
                waitingRoomPolling(appointmentInformation?.id)
            },
            10000
        );
        // }
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            console.log("Removing Polling INterval because InitiatePolling is: ", initiatePolling);
            clearInterval(idRef.current);
        }

    }, []);

    const startPolling = () => {
        // setInitiatePolling(p => !p);
        setInitiatePolling(p => true);
    }
    return (
        <>
            {showWaitingRoom ? <AgoraWaitingRoomModal
                idRef={idRef.current}
                show={showWaitingRoom}
                removeFromWaitingList={removeFromWaitingList}
                handleModalClose={handleWaitingRoomModalClose}
                waitingList={waitingRoomList}
                startPolling={startPolling}
                setWaitingRoomList={setWaitingRoomList}
                appId={appointmentInformation?.id}
            /> : null}
            {showEndConsultationModal ? (
                <EndConsultationModal
                    handleEndConsulationModal={() => {
                        props?.setEndConsultation(false);
                        setShowEndConsultationModal(false);
                    }}
                    show={showEndConsultationModal}
                    minutes={minutes}
                    hours={hours}
                    seconds={seconds}

                />
            ) : null}
            <Draggable
                id="my-draggable-element"
                disabled={isExpanded === true ? true : false}
                position={controlledPosition}
                onDrag={handleDrag}
                bounds="body">
                <div id="videos" className="no-select" style={{
                    visibility: videoOpenState === false ? "hidden" : "visible",
                    // height: '75%',
                    // width: '75%'
                }}>
                    <div id="subscriber">
                        <div
                            id="expandDiv"
                            className="fa-solid fa-expand"
                            onClick={expandClicked}
                        ></div>

                        <div ref={publisherRef} id="publisher" className='video-publisher'></div>
                    </div>
                </div>
            </Draggable>
        </>
    )
}
const sleepSync = (ms) => {
    const end = new Date().getTime() + ms;
    while (new Date().getTime() < end) {
        /* do nothing */
    }
};

export default AgoraMinimalVideoContainerV2
import React from 'react'
import { useState } from 'react';
import { RefreshDataComponent } from '../../components/UI/General/RefreshDataComponent';
import TasksPage from '../../components/pages/TasksPage';
import { useEffect } from 'react';
import sendHttpRequest from '../../components/hooks/sendHttpRequest';
import { useAuth } from '../../components/hooks/useAuth';

const VeeOnePrescriptionRequests = (props) => {
    const { user, loginWithoutNavigation } = useAuth();
    const [prescriptionRequestReload, setPrescriptionReload] = useState(false);
    const [isPrescriptionRequestsLoading, setIsPrescriptionRequestsLoading] = useState(false);
    const handlePrescriptionReload = () => {
        setPrescriptionReload(previous => !previous);
    }
    const [isToken, setIsToken] = useState(false);
    const [patientId, setPatientId] = useState(null);
    const [providerId, setProviderId] = useState(null);
    const [tenantId, setTenantId] = useState(null);




    async function getInternalIds(patient_external_id, provider_external_id) {
        const response = await sendHttpRequest({
            method: 'GET',
            url: `/patients/${patient_external_id}/providers/${provider_external_id}`
        });
        if (!response.error) {
            // console.log(response.data);
            return response.data;
        }
    }

    async function getProviderDetails(provider_id) {
        const response = await sendHttpRequest({
            method: 'GET',
            url: `/provider/${provider_id}`
        });
        if (!response.error) {
            // console.log(response.data);
            return response.data;
        }
    }

    useEffect(() => {
        const handleMessage = async (event) => {
            // console.log(event.origin);
            // setIsInitializing(true);
            // if (event.origin !== "http://127.0.0.1:5500") return;
            // let response = event.data.response;
            // console.log(event.data);
            if (event.data.type === 'iframe-params') {
                const patient_external_id = event.data.patientId;
                const provider_external_id = event.data.providerId;
                localStorage.setItem("tkn", event.data.jwt);
                setIsToken(true);
                const ids = await getInternalIds(patient_external_id, provider_external_id);
                setPatientId(ids.patient?.userId);
                setProviderId(ids.provider?.userId);
                const response = await getProviderDetails(ids?.provider?.userId);
                setTenantId(response?.providerTenantPracticeLocation?.tenant?.tenantId);
                loginWithoutNavigation({
                    loggedIn: true,
                    role: response?.role ?? "",
                    userId: response?.userId ?? "",
                    selectedUserId: response?.userId ?? "",
                    timezone: response?.timezoneOffset ?? "",
                    timezoneText: response?.timezone ?? "",
                    name: response?.name,
                    firstName: response?.name[0]?.firstName ?? "",
                    lastName: response?.name[0]?.lastName ?? "",
                    prefix: response?.name[0]?.prefix ?? "",
                    prescription: response?.Identification?.Prescription,
                    ePrescription: response?.identification?.status,
                    spi: response?.providerTenantPracticeLocation?.spi,
                    tenantId: response?.role === "patient" ? response?.tenant?.tenantId : response?.providerTenantPracticeLocation?.tenant?.tenantId,
                    tenant: response?.role === "patient" ? response?.tenant : response?.providerTenantPracticeLocation?.tenant,
                    facility: response?.providerTenantPracticeLocation?.facility,
                    practiceLocationId: response?.providerTenantPracticeLocation?.providerTenantPracticeLocationId,
                    isFromIframe: true
                });

            }

        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return (
        <>
            {isToken && providerId !== null && tenantId !== null ? <><div
                className={`d-flex mb-1 justify-content-between align-items-baseline`}
            >
                <h2 className="fw-sb font-22">Prescription Requests</h2>
                {/* <div>
                    <RefreshDataComponent callback={handlePrescriptionReload} isAsyncCallback={false} isComponentDataLoading={isPrescriptionRequestsLoading} />
                </div> */}
            </div>
                {/* Dynamically render here <PrescriptionRequestItem/> from the API */}
                <div className='row'>
                    <div className='col-9'>
                        <TasksPage tasksOnly={true} veeone={true} userId={providerId} tenantId={tenantId} patientId={patientId} prescriptionRequestReload={prescriptionRequestReload} setIsPrescriptionRequestsLoading={setIsPrescriptionRequestsLoading} />
                    </div>
                </div>

            </> : null}
        </>
    );
}

export default VeeOnePrescriptionRequests
import { ActionTypes } from "../constants/actionTypes"

const initialState = {
  subscriptionConfiguration : []
}

const setTenantSubscriptions = (state = initialState, action) => {
  if (action.type === ActionTypes.SET_TENANT_SUBSCRIPTIONS) {
    return { ...state, subscriptionConfiguration: action.payload }
  }
  return state
}

export default setTenantSubscriptions;
import React, { useState, useEffect, useRef } from "react";
import { Skeleton, Typography } from "@mui/material";
import PatientRegistrationHeader from "../header/PatientRegistrationHeader";
import PatientRegistrationFooter from "../patient-registration-footer-login/PatientRegistrationFooter";
import options from "../../../utils/data";
import { Typeahead } from "react-bootstrap-typeahead";
import CalenderDatePicker from "../../UI/General/CalenderDatePicker";
import { DateTime } from "luxon";
import { useNavigate, useParams } from "react-router-dom";
import { isValidZipCode } from "../../../utils/helpers/HelperFunctions";
import sendHttpRequest from "../../hooks/sendHttpRequest";
import PageNotFound from "../../pages/PageNotFound";
import { pageNotFoundStrings } from "../../../res/strings";

const PatientRegistrationDemographicsForm = () => {
  const __str = pageNotFoundStrings.en;
  const { tid } = useParams();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState([]);
  const [zipCode, setZipCode] = useState("");
  const [genderIdentity, setGenderIdentity] = useState("");
  const [dob, setDob] = useState("");
  const registrationFormRef = useRef();
  const [regexErrors, setRegexErrors] = useState({
    zipcode: false,
  });
  const [tenantValidationLodaing, setTenantValidationLodaing] = useState(false);
  const [isInvalidTenant, setIsInvalidTenant] = useState(false);

  const checkTenant = async () => {
    setTenantValidationLodaing(true);
    const httpResponse = await sendHttpRequest({
      url: `/practice/${tid}`,
      method: "GET",
    });

    if (httpResponse.error) {
      setIsInvalidTenant(true);
    }
    setTenantValidationLodaing(false);
  };

  function handleNextButton() {
    const validity = registrationFormRef.current.reportValidity();
    if (validity === false) return;
    const data = {
      name: [
        {
          firstName,
          middleName,
          lastName,
          type: "cn",
        },
      ],
      address: [
        {
          addressLine1,
          addressLine2,
          city,
          postalCode: zipCode,
          type: "ca",
          stateProvince: state?.at(0)?.code,
        },
      ],
      dateOfBirth: dob,
      gender: gender,
      tenant: {
        organizationId: tid,
      },
    };
    // console.log(data);
    navigate(`/patient-registration/email-verification/${tid}`, {
      state: { data },
      replace: true,
    });
  }

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleMiddleNameChange = (event) => {
    setMiddleName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const dobHandler = (d) => {
    setDob(d);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleAddressLine1Change = (event) => {
    setAddressLine1(event.target.value);
  };

  const handleAddressLine2Change = (event) => {
    setAddressLine2(event.target.value);
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const handleZipCodeChange = (event) => {
    // handleStandardAddress();
    setZipCode(event.target.value);
    if (isValidZipCode(event.target.value)) {
      setRegexErrors((previousSnapshot) => ({
        ...previousSnapshot,
        zipcode: false,
      }));
      setZipCode(event.target.value);
    } else {
      if (event.target.value === "") {
        setRegexErrors((previousSnapshot) => ({
          ...previousSnapshot,
          zipcode: false,
        }));
      } else {
        setRegexErrors((previousSnapshot) => ({
          ...previousSnapshot,
          zipcode: true,
        }));
      }
    }
  };

  const handleGenderIdentityChange = (event) => {
    setGenderIdentity(event.target.value);
  };

  const handleState = (s) => {
    setState(s);
  };
  useEffect(() => {
    checkTenant();
    document.body.style.backgroundColor = '#D9E8EC';

    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);
  return tenantValidationLodaing ? (
    <Skeleton />
  ) : isInvalidTenant === true ? (
    <PageNotFound text={__str.text} code={"500"} />
  ) : (
    <div style={{ backgroundColor: "#D9E8EC", minHeight: "100%" }}>
      <PatientRegistrationHeader />
      <div style={{ marginTop: "10%", paddingBottom: "2rem" }}>
        <form ref={registrationFormRef}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-7">
                <Typography
                  variant="h3"
                  sx={{
                    color: "#336383",
                    fontWeight: 600,
                    fontSize: "16px",
                    fontFamily: "Montserrat",
                  }}
                  component="h1"
                  gutterBottom
                >
                  {"Personal Information"}
                </Typography>

                <div className="row mt-1">
                  <div className="col-12 col-sm-4">
                    <div className="form-group">
                      <label
                        htmlFor="firstName"
                        className="font-12"
                        style={{ color: "#336383", fontFamily: "Montserrat" }}
                      >
                        First Name <span>&#42;</span>
                      </label>
                      <input
                        type="text"
                        onChange={handleFirstNameChange}
                        className="form-control input br-10 border--default font-14 shadow-none"
                        id="firstName"
                        name="firstName"
                        placeholder="Type here"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group">
                      <label
                        htmlFor="middleName"
                        className="font-12"
                        style={{ color: "#336383", fontFamily: "Montserrat" }}
                      >
                        Middle Name
                      </label>
                      <input
                        type="text"
                        className="form-control input br-10 border--default font-14 shadow-none"
                        onChange={handleMiddleNameChange}
                        name="middleName"
                        id="middleName"
                        placeholder="Type here"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="form-group">
                      <label
                        htmlFor="lastName"
                        className="font-12"
                        style={{ color: "#336383", fontFamily: "Montserrat" }}
                      >
                        Last Name <span>&#42;</span>
                      </label>
                      <input
                        type="text"
                        name="lastName"
                        className="form-control input br-10 border--default font-14 shadow-none"
                        onChange={handleLastNameChange}
                        id="lastName"
                        placeholder="Type here"
                        required
                      />
                    </div>
                  </div>
                  {/* Date of birth */}
                  <div className="col-12 col-sm-4">
                    <div className="form-group">
                      <label
                        htmlFor="dob"
                        className="font-12"
                        style={{ color: "#336383", fontFamily: "Montserrat" }}
                      >
                        Date of Birth <span>&#42;</span>
                      </label>
                      <CalenderDatePicker
                        name="dob"
                        label="Date of birth"
                        required={true}
                        dobHandler={dobHandler}
                        id="dob"
                        disableFuture={true}
                        maxDate={DateTime.now()}
                      />
                    </div>
                  </div>
                  {/* Biological sex */}
                  <div className="col-12 col-sm-3">
                    <div className="form-group">
                      <label
                        htmlFor="gender"
                        className="font-12"
                        name="gender"
                        style={{ color: "#336383", fontFamily: "Montserrat" }}
                      >
                        Biological sex <span>&#42;</span>
                      </label>
                      <select
                        className="form-select font-14 br-10 shadow-none input custom-select"
                        aria-label="gender"
                        onChange={handleGenderChange}
                        required
                        value={gender}
                        id="gender"
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Unknown">
                          Choose not to disclose
                        </option>
                      </select>
                    </div>
                  </div>

                  {/* Gender Identity */}
                  <div className="col-12 col-sm-5">
                    <div className="form-group">
                      <label
                        htmlFor="genderId"
                        className="font-12"
                        style={{ color: "#336383", fontFamily: "Montserrat" }}
                      >
                        What is your current gender identity?
                      </label>
                      <select
                        onChange={handleGenderIdentityChange}
                        className="form-select br-10 font-14 shadow-none input custom-select"
                        aria-label="genderId"
                        id="genderId"
                      // style={{ borderRadius: "10px" }}
                      >
                        <option>Select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Transgender">Transgender</option>
                        <option value="Male / Transgender Man / Female-to-Male (FTM)">
                          Male / Transgender Man / Female-to-Male (FTM)
                        </option>
                        <option value="Transgender Female / Transgender Woman / Male-to-Female (MTF)">
                          Transgender Female / Transgender Woman /
                          Male-to-Female (MTF)
                        </option>
                        <option value="Genderqueer - neither exclusively male nor female">
                          Genderqueer - neither exclusively male nor female
                        </option>
                        <option value="Other">Other</option>
                        <option value="Choose not to disclose">
                          Choose not to disclose
                        </option>
                      </select>
                      {/* <span style={{ color: "red", fontSize:"12px" }}>{question?.states[2].error ?? ""}</span> */}
                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-12">
                    <div className="form-group">
                      <label
                        htmlFor="addressLine1"
                        className="font-12"
                        style={{ color: "#336383", fontFamily: "Montserrat" }}
                      >
                        Address Line 1 <span>&#42;</span>
                      </label>
                      <input
                        type="text"
                        className="form-control input br-10 border--default font-14 shadow-none"
                        // defaultValue={question?.states[0].answer ?? ""}
                        // onChange={getFirstName}
                        onChange={handleAddressLine1Change}
                        name="addressLine1"
                        id="addressLine1"
                        placeholder="Type here"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group">
                      <label
                        htmlFor="addressLine2"
                        className="font-12"
                        style={{ color: "#336383", fontFamily: "Montserrat" }}
                      >
                        Address Line 2
                      </label>
                      <input
                        type="text"
                        className="form-control input br-10 border--default font-14 shadow-none"
                        // defaultValue={question?.states[0].answer ?? ""}
                        // onChange={getFirstName}
                        onChange={handleAddressLine2Change}
                        name="addressLine2"
                        id="addressLine2"
                        placeholder="Type here"
                      />
                    </div>
                  </div>

                  <div className="col-12 col-sm-4">
                    <div className="form-group">
                      <label
                        htmlFor="city"
                        className="font-12"
                        style={{ color: "#336383", fontFamily: "Montserrat" }}
                      >
                        City <span>&#42;</span>
                      </label>
                      <input
                        type="text"
                        className="form-control input br-10 border--default font-14 shadow-none"
                        // defaultValue={question?.states[0].answer ?? ""}
                        // onChange={getFirstName}
                        required
                        onChange={handleCityChange}
                        name="city"
                        id="city"
                        placeholder="Type here"
                      />
                    </div>
                  </div>

                  <div className="col-12 col-sm-4">
                    <div className="form-group">
                      <label
                        htmlFor="state"
                        className="font-12"
                        style={{ color: "#336383", fontFamily: "Montserrat" }}
                      >
                        State <span>&#42;</span>
                      </label>
                      <Typeahead
                        id="state"
                        name="state"
                        labelKey="name"
                        onChange={handleState}
                        options={options}
                        className="rbt-menu my-select font-12"
                        style={{ borderRadius: "10px" }}
                        autoComplete="off"
                        onInvalid={(e) => {
                          e.target.setCustomValidity("Please select a state");
                        }}
                        onInput={(e) => {
                          e.target.setCustomValidity("");
                        }}
                        placeholder="Select State"
                        inputProps={{ required: true, className: "font-14" }}
                      />
                      {/* <input
                                                type="text"
                                                className="form-control shadow-none"
                                                // defaultValue={question?.states[0].answer ?? ""}
                                                // onChange={getFirstName}
                                                style={{
                                                    fontSize: "14px",
                                                    borderRadius: "10px",
                                                    border: "1px solid #d9e8ec",
                                                }}
                                                id="state"
                                                placeholder="Type here"
                                            /> */}
                    </div>
                  </div>

                  <div className="col-12 col-sm-4">
                    <div className="form-group">
                      <label
                        htmlFor="zipCode"
                        className="font-12"
                        style={{ color: "#336383", fontFamily: "Montserrat" }}
                      >
                        Zip Code <span>&#42;</span>
                      </label>
                      <input
                        type="text"
                        onChange={handleZipCodeChange}
                        className="form-control shadow-none"
                        // defaultValue={question?.states[0].answer ?? ""}
                        // onChange={getFirstName}
                        required
                        style={{
                          fontSize: "16px",
                          borderRadius: "10px",
                          border: "1px solid #d9e8ec",
                        }}
                        id="zipCode"
                        placeholder="Type here"
                      />
                      <span className="text--danger py-1 font-10 d-block">
                        {regexErrors?.zipcode === true
                          ? "Incorrect Zip Code format"
                          : " "}
                      </span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </form>
      </div>
      <PatientRegistrationFooter
        onNextHandler={handleNextButton}
        disabled={regexErrors.zipcode === true ? true : false}
      />
    </div>
  );
};

export default PatientRegistrationDemographicsForm;

import React from "react";
import { Modal } from "react-bootstrap";
// import AddButtonIcon from "../../assets/icons/Icon-Add.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useRef, useState } from "react";
import { X } from "react-bootstrap-icons";
import PatientProfileMessages from "../../../messages/PatientProfileMessages";
import InboxMessages from "../../../messages/InboxMessages";
import sendHttpRequest from "../../../hooks/sendHttpRequest";
import { useAuth } from "../../../hooks/useAuth";


function PatientProfileMessagesModal({show, handleMessagesModal, patientId}) {
    const { user } = useAuth();
    const [inboxMessagesData, setInboxMessagesData] = useState([]);
    const [isInboxMessagesLoading, setIsInboxMessagesLoading] = useState(false);
    const [inboxMessageCount, setInboxMessageCount] = useState(0);
    const [isInboxMessagesCountLoading, setIsInboxMessagesCountLoading] =
      useState(false);
      const [activeThreadId, setActiveThreadId] = useState(-1);


      const fetchInboxMessages = async () => {
        setIsInboxMessagesLoading(true);
        const httpResponse = await sendHttpRequest({
            url: "/messages",
            method: "GET",
            params: {
              patientId: patientId,
              tenantId: user?.tenantId
            },
          });
        if (!httpResponse.error) {
          // console.log("httpResponse.data", httpResponse.data);
          setInboxMessagesData(httpResponse.data);
          console.log(httpResponse.data);
        //   setActiveThreadId(httpResponse.data?.messages[0].threadId);
        } else {
          console.log(httpResponse.error);
        }
        setIsInboxMessagesLoading(false);
        return true;
      };


  return (
    <>
      <Modal
        show={show}

        backdropClassName="modal-backdrop"
        contentClassName="modal-border modal-shadow "
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
        dialogClassName="modal-80vh"
        
      >
        <Modal.Header className="modal-header" style={{borderBottom:'none'}}>
          <span
            style={{
              lineHeight: "12px",
              width: "18px",
              fontSize: "8pt",
              color: "#336383",
              position: "absolute",
              top: 15,
              right: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              handleMessagesModal();
            }}
          >
            {<CloseIcon />}
          </span>

          <Modal.Title className="font-20 fw-sb text--terciary">
            Messages
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <InboxMessages
            inbox={true}
            patientSpecificMessage= {true}
            isInboxMessagesLoading={isInboxMessagesLoading}
            inboxMessagesData={inboxMessagesData}
            fetchInboxMessagesCount = {null}
            fetchInboxMessages={fetchInboxMessages}
            // threadId={activeThreadId}
          />
         {/* <PatientProfileMessages patientId={patientId} /> */}
        </Modal.Body>
      </Modal>

      {/* {console.log("Message Data ", props?.messageData)} */}
      {/* {console.log("patientName",patientName)} */}
      {/* {console.log(patientId)} */}
    </>
  );
}

export default PatientProfileMessagesModal;

import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import { file_uploading_helping_strings } from "../../../../res/strings";
import { unsavedWorkHandler } from "../../../../utils/helpers/HelperFunctions";
import { useUnsavedWorkPrompt } from "../../../hooks/useUnsavedWorkPrompt";
import CloseIcon from "@mui/icons-material/Close";
import TrashIcon from "../../../../assets/icons/TrashIcon";

const SignatureImageUploadModal = (props) => {
  const [isDirty, onDirty, onPristine] = useUnsavedWorkPrompt();
  const [invalidFileType, setInvalidFileType] = useState(false);
  const [invalidFileSize, setInvalidFileSize] = useState(false);
  const [lowerPixelLength, setLowerPixelLength] = useState(false);
  const [fileName, setFileName] = useState([]);
  const [invalidPixelLength, setInvalidPixelLength] = useState(false);
  const [tempFile, setTempFile] = useState([]);
  const [dataUrl, setDataUrl] = useState(null);
  const _str = file_uploading_helping_strings.en;

  const MAX_UPLOAD_SIZE = 2097152;
  const fileTypes = ["JPEG", "PNG", "JPG"];

  const fileType = (event) => {
    props.setFileType(event.target.value);
  };

  const handleFileInput = (file) => {
    var _URL = window.URL || window.webkitURL;

    let img = new Image();
    img.src = _URL.createObjectURL(file);
    img.onload = function () {
      // console.log(this.width);
      // console.log(this.height);
      // console.log(file.size);
      // console.log(MAX_UPLOAD_SIZE);
      if (this.width >= 500 && this.height >= 100) {
        if (file.size <= MAX_UPLOAD_SIZE) {
          let filePath = file.name;
          var allowedExtensions = /(\.png|\.jpeg|\.jpg)$/i;

          if (!allowedExtensions.exec(filePath)) {
            file = "";
            setInvalidFileType(true);
          } else {
            onDirty();
            props.setSelectedFile(file);
            setTempFile([file]);
            setInvalidFileType(false);
            setInvalidPixelLength(false);
          }
          setInvalidFileSize(false);
        } else {
          file = "";
          setInvalidFileSize(true);
        }
      }
      // else if (this.width < 500 && this.height < 100) {
      //   setLowerPixelLength(true);
      // }
      else {
        setInvalidPixelLength(true);
      }
    };
  };

  const setDataURL = (b64) => {
    props?.setTrimmedDataURL((prevState) => {
      return b64;
    });
  };

  const uploadImage = () => {
    props.setOpenSignatureImagePad(false);
    if (!invalidFileType && !invalidFileSize) {
      convertToBase64();
    }
    resetStates();
  };

  const removeFile = (event) => {
    const dummy = tempFile?.filter((file, index) => index != event.target.id);
    setTempFile(dummy);
  };

  const convertToBase64 = () => {
    let reader = new FileReader();
    if (tempFile?.length > 0) {
      if (tempFile[0] !== "") {
        reader.readAsDataURL(tempFile[0]);
        reader.onload = (e) => {
          setDataURL(e.target.result);
          props?.uploadFile(tempFile[0], e.target.result);
          return;
        };
      }
    }
  };

  const resetStates = () => {
    setInvalidFileType(false);
    setInvalidFileSize(false);
    setLowerPixelLength(false);
    setInvalidPixelLength(false);
    setTempFile([]);
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          unsavedWorkHandler(
            "modal",
            "There are unsaved changes. Do you wish to continue?",
            isDirty,
            props?.handleSignatureImageClose,
            onPristine,
            props?.handleSignatureImageClose
          );
          resetStates();
          props.handleSignatureImageClose();
        }}
        size="md"
        centered
        backdrop="static"
        backdropClassName="modal-backdrop"
        contentClassName="modal-border modal-shadow"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header className="modal-header">
          <span
            style={{
              lineHeight: "12px",
              width: "18px",
              fontSize: "8pt",
              color: "#336383",
              position: "absolute",
              top: 15,
              right: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              unsavedWorkHandler(
                "modal",
                "There are unsaved changes. Do you wish to continue?",
                isDirty,
                props?.handleSignatureImageClose,
                onPristine,
                props?.handleSignatureImageClose
              );
              resetStates();
              props.handleSignatureImageClose();
            }}
          >
            {<CloseIcon />}
          </span>
          <Modal.Title className="font-18 fw-sb text--terciary">
            Upload Image
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 font-12 col-sm-12">
                {/* <h3 className='font-14 m-0'><label htmlFor="formReportFile" class="form-label">Documents</label></h3> */}
                {/* <input required class="form-control" type="file" accept="application/pdf, image/png, image/jpeg" id="formReportFile" onChange={ReportType} /> */}
                <FileUploader
                  handleChange={handleFileInput}
                  name="file"
                  label="Drop a file or click here"
                  types={fileTypes}
                  data-testid="upInput"
                >
                  <div
                    className="border--dashed bg--white br-10 d-flex justify-content-center w-100"
                    style={{ height: "128px" }}
                  >
                    <div className="py-5">
                      <span className="ps-3">
                        Drag and drop files here or&nbsp;
                        <u className="file-link">select one</u>
                      </span>
                      <br />
                      <span className="fw-sb">{_str["resolution_pixels"]}</span>
                    </div>
                  </div>
                </FileUploader>
                {invalidFileType ? (
                  <>
                    <br />
                    <span style={{ fontSize: 12, color: "#fd0d1b" }}>
                      {_str["invalid_file"]}
                    </span>
                  </>
                ) : (
                  ""
                )}
                {invalidFileSize ? (
                  <>
                    <br />
                    <span style={{ fontSize: 12, color: "#fd0d1b" }}>
                      {_str["error_2MB"]}
                    </span>
                  </>
                ) : (
                  ""
                )}
                {invalidPixelLength ? (
                  <>
                    <br />
                    <span style={{ fontSize: 12, color: "#fd0d1b" }}>
                      {_str["invalid_file_pixels"]}
                    </span>
                  </>
                ) : (
                  ""
                )}
                {lowerPixelLength ? (
                  <>
                    <br />
                    <span style={{ fontSize: 12, color: "#fd0d1b" }}>
                      {_str["lower_pixel_length"]}
                    </span>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12 font-12 col-sm-12">
                {tempFile.length > 0
                  ? tempFile.map((file, index) => {
                      return (
                        <>
                          <div className="row mt-3 justify-content-between">
                            <div className="col-sm-8 font-10 text--terciary">
                              {file?.name && file?.name}
                            </div>
                            <div
                              key={index}
                              className="col-sm-2"
                              style={{
                                marginRight: -30,
                                marginTop: -11,
                                cursor: "pointer",
                              }}
                            >
                              <TrashIcon
                                id={index}
                                style={{ color: "red", height: 12 }}
                                onClick={(e) => removeFile(e)}
                              />
                            </div>
                          </div>
                        </>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer style={{ border: "none" }}>
          <button
            type="button"
            className="btn blue_task_button text-white b2 px-4 br-10 py-1"
            disabled={
              invalidFileSize || invalidFileType || props?.isUploading === true
                ? true
                : false
            }
            onClick={uploadImage}
          >
            {props?.isUploading === true ? "Saving..." : "Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SignatureImageUploadModal;

import { useNavigate } from "react-router-dom";
import { currencyFormatter } from "../../../utils/helpers/HelperFunctions";
import classes from "./PatientBillingDashboard.module.css";
const PatientBalanceCard = (props) => {
    const navigate = useNavigate();


    const handlePaymentPageNavigation = (amountDueValue) => {
        if (Number(amountDueValue) > 0) {
            navigate('/patient-portal/pay-bill', { state: { amountDue: amountDueValue } })
        }
    }
    return (
        <>
            <div className={`${classes['balance-card']}`}>
                {/* Amount Due Title */}
                <div className="fw-sb text--terciary">
                    Amount Due
                </div>
                {/* Amount Due data */}
                {/* If the amount is in negative use the class 'patient-billing-amount-negative' */}
                <div
                    className={`${classes['patient-billing-card-amount-due']}  ${Number(props.amountDue) > 0 ? `${classes['patient-billing-amount-negative']} cursor--pointer` : `${classes['patient-billing-amount-positive']}`}`}
                    onClick={()=>{handlePaymentPageNavigation(props.amountDue)}}
                >
                    {currencyFormatter("USD", props.amountDue)}
                </div>
            </div>
        </>
    )
}

export default PatientBalanceCard
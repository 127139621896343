import { ActionTypes } from "../constants/actionTypes"

const initialState = {
        isPatientEdited:false
}

const setIsPatientEditedReducer = (state=initialState, action)=>{
    if (action.type === ActionTypes.SET_IS_PATIENT_EDITED ){
        return {
            ...state,
            isPatientEdited: action.payload
            
        }
    }
    return state;
}


export default setIsPatientEditedReducer;
import { useEffect } from "react";
import { useState } from "react";
import { getErrorForField } from "../../../utils/helpers/HelperFunctions";

const ErrorMessage = ({ serverErrorsArray, fieldName }) => {
    const [fieldErrors, setFieldErrors] = useState([]);

    useEffect(() => {
        const arr = getErrorForField(serverErrorsArray, fieldName);
        setFieldErrors(arr);
    }, [serverErrorsArray])

    return (
        <>
            {
                fieldErrors !== undefined && Array.isArray(fieldErrors) && fieldErrors?.length > 0 ? fieldErrors?.map((errorObject, index) => {
                    return (<span key={index} className="text--danger d-block font-10 ms-2 mt-0">
                        {errorObject?.value}
                    </span>)
                }) : null
            }
        </>
    )
}

export default ErrorMessage;
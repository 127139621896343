import { ActionTypes } from "../constants/actionTypes"

const patientInsurance = []

const setPatientInsuranceDataReducer = (state=patientInsurance, action)=>{
    if (action.type === ActionTypes.SET_PATIENT_INSURANCE_DATA ){
        return {
            ...state,
            patientInsurance: action.payload
        }
    }
    return state
}


export default setPatientInsuranceDataReducer;
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
// import { processWorkbook } from "./ProviderImport";
import sendHttpRequest from "../hooks/sendHttpRequest";
// import { read, utils } from "xlsx";

const ProviderImportModal = (props) => {
  const [selectedFile, setSelectedFile] = useState();
  const [uploadingFile, setUploadingFile] = useState(false);
  const [uploadingFileError, setUploadingFileError] = useState("");
  const [file, setFile] = useState("");

  const handleFileChange = (event) => {

    let fileType = event.target.files[0].name.split(".");
    fileType = fileType[fileType.length-1];

    if (["csv"].includes(fileType)) {
      setSelectedFile(event.target.files[0]);
    } else {
      setUploadingFileError("Invalid file error");
    }
  };

  const convertToMultipart = (fileV, trimmedDataURL) => {
    // console.info("trimmedDataURL: " + trimmedDataURL);
    let mimeType = trimmedDataURL.split(",")[0].split(":")[1].split(";")[0];
    // console.log("mimeType: " + mimeType);
    fetch(trimmedDataURL)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], fileV?.name, {
          type: 'text/csv',
        });
        setFile(file);
        //form data
        const formData = new FormData();
        formData.append("file", file);
        uploadFile(formData);
      });
  };

  const sendFileAndType = async () => {
    let reader = new FileReader();
    if (selectedFile !== "") {
      reader.readAsDataURL(selectedFile);
      reader.onloadend = async (e) => {
        if (e.target.readyState == FileReader.DONE) {
          convertToMultipart(selectedFile, e.target.result);
          // status = await processWorkbook(e);
          // props?.sheetUpload(status);
          // props?.handleModal();
        }
      };
    }
  };

  const uploadFile = async (fileData) => {
    setUploadingFile(true);
    const httpResponse = await sendHttpRequest({
      url: "/provider/csv",
      method: "POST",
      data: fileData,
      headers: {
        "Content-Type": "multipart/form-data; boundary=",
      },
    });
    if (!httpResponse.error) {
      if (httpResponse?.data?.savedProviderCount !== 0) {
        props?.sheetUpload({severity:"success", message:`${httpResponse?.data?.savedProviderCount} out of ${httpResponse?.data?.totalProviderCount} practice users uploaded successfully`});
      } else {
        props?.sheetUpload({severity:"error", message:`Failed to import users. Please check the import data and try again.`});
      }
      props?.handleModal();
    } else {
      props?.sheetUpload({severity:"error", message:httpResponse?.errorMessage?.message ?? "Invalid file format"});
      // setUploadingFileError(
      //   "There was an error while uploading the sheet"
      // );
      // console.log(httpResponse);
      // setUploadingFileError(httpResponse)
    }
    setUploadingFile(false);
    setSelectedFile(null);
  };

  useEffect(() => {
    return () => {
      setSelectedFile(null);
      setUploadingFile(false);
      setUploadingFileError("");
    };
  }, []);

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props?.handleModal();
      }}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      backdropClassName="modal-backdrop"
      contentClassName="modal-border modal-shadow"
    // style={{style}}
    >
      <Modal.Header className="modal-header">
        <span
          style={{
            lineHeight: "12px",
            width: "18px",
            fontSize: "8pt",
            color: "#336383",
            position: "absolute",
            top: 15,
            right: 15,
            cursor: "pointer",
          }}
          onClick={() => {
            props?.handleModal();
          }}
        >
          {<CloseIcon />}
        </span>
        <Modal.Title
          className="font-18 fw-sb"
          id="contained-modal-title-vcenter"
        >
          Batch upload for Practice Users
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <p className="m-0 font-12">
                Please select a CSV file containing information of the practice users that need to be added to the system.
              </p>
              <div className="p-4">
                <form
                  id="usersheetForm"
                  encType="multipart/form-data"
                  onSubmit={(e) => {
                    e?.preventDefault();
                    sendFileAndType();
                    setUploadingFile(true)
                  }}
                >
                  <input
                    className={`form-control font-13 m-0 border--default br-10 shadow-none input`}
                    type="file"
                    required
                    accept={[".xlsx",".csv"]}
                    id="formUserFile"
                    onChange={handleFileChange}
                  />
                </form>
                <span className={`font-13 m-0`} style={{ color: "red" }}>{uploadingFileError}</span>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="submit"
          form="usersheetForm"
          className="btn blue_task_button"
          disabled={uploadingFile===true || uploadingFileError !== "" ? true : false}
        >
          {uploadingFile ? "Uploading..." : "Upload"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProviderImportModal;

import { ActionTypes } from "../constants/actionTypes";

const updateEncounterInitialSate = {
  Encounter: {
    encounterId:'',
    // Vitals: {
    //   temperature: { Measurement: "", UnitOfMeasurement: "", Name: "" },
    //   systolic: { Measurement: "", UnitOfMeasurement: "", Name: "" },
    //   diastolic: { Measurement: "", UnitOfMeasurement: "", Name: "" },
    //   pulse: { Measurement: "", UnitOfMeasurement: "", Name: "" },
    //   spo: { Measurement: "", UnitOfMeasurement: "", Name: "" },
    //   weight: { Measurement: "", UnitOfMeasurement: "", Name: "" },
    //   bmi: { Measurement: "", UnitOfMeasurement: "", Name: "" },
    // },
    Pharmacy:""
    
  },
};

const setUpdateEncounterDataReducer = (
  state = updateEncounterInitialSate,
  action
) => {
  if (action.type === ActionTypes.SET_UPDATE_ENCOUNTER_DATA) {
    return {
      ...state,
      Encounter: action.payload,
    };
  }
  return state;
};

export default setUpdateEncounterDataReducer;

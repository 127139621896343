import { Grid } from '@mui/material';
import Footer from '../../Footer/Footer';
import Header from '../../Header/Header'
import Layout from "../../layout/Layout";
import AppointmentForm from './AppointmentForm';
import "../../form.css"
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import PatientRegistrationHeader from '../../../../patient-registration/header/PatientRegistrationHeader';


function ApptComponent(props) {
    const location = useLocation();
    console.log(location, props);
    const [appoinmentStartTime, setAppoinmentStartTime] = useState("")
    const [appoinmentEndTime, setAppoinmentEndTime] = useState("")
    const [scheduleDateToSend, setScheduleDateToSend] = useState("")
    const [providerId, setProviderId] = useState("")
    const [error, setError] = useState("");

    return (
        <div className="App">
            <Layout>
                <PatientRegistrationHeader />
                <Grid container component="main" sx={{ height: "100vh", backgroundColor: "#d9e8ec" }}>
                    <AppointmentForm facilityId = {location?.state?.facilityId} patientVisitType = {location?.state?.patientVisitType} chiefComplaint={location?.state?.chiefComplaint} type={location?.state?.type} setAppoinmentStartTime={setAppoinmentStartTime} setScheduleDateToSend={setScheduleDateToSend} setAppoinmentEndTime={setAppoinmentEndTime} setProviderId={setProviderId} error={error} />
                </Grid>
                <Footer facilityId = {location?.state?.facilityId} patientVisitType = {location?.state?.patientVisitType} type={location?.state?.type} chiefComplaint={location?.state?.chiefComplaint} price={location?.state?.price} ishidden={false} hideSignin={true} isLast={false} isFirst={true} setError={setError} appoinmentEndTime={appoinmentEndTime} appoinmentStartTime={appoinmentStartTime} providerId={providerId} scheduleDateToSend={scheduleDateToSend} />
            </Layout>
        </div>
    );
}

export default ApptComponent;

import { useState } from "react";
import { truncate } from "../../../utils/helpers/HelperFunctions";

const CustomText = (props)=>{
    const [showTextMore, setShowTextMore] = useState(false);
    const handleMoreSig = () => {
        setShowTextMore(!showTextMore);
      };
    return (
        <span>
            {showTextMore ? (
              <>
                {props?.text}
                <span
                  className="text--secondary fw-sb cursor--pointer"
                  onClick={() => {
                    handleMoreSig();
                  }}
                >
                  {" less"}
                </span>
              </>
            ) : (
              <span>
                {truncate(props?.text, props?.showLimit)}
                {props?.text?.length > props?.showLimit ? (
                  <span
                    className="text--secondary fw-sb cursor--pointer"
                    onClick={() => {
                      handleMoreSig();
                    }}
                  >
                    {" more"}
                  </span>
                ) : (
                  ""
                )}
              </span>
            )}
          </span>
    )
}

export default CustomText;
import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { isOnlySpaces } from "../../../../utils/helpers/HelperFunctions";
import TextArea from "../../../forms/TextArea";
import { useState } from "react";

const AddendumModal = (props) => {

    const [disable, setDisable] = useState(false);

    const setAddendumText = (text) => {
        props.setAddendumBody(text);
    }

    const setAddendumTitle = (event) => {
        props.setAddendumTitle(event.target.value);
    }

    return (
        <>
            <Modal
                show={props.show}
                onHide={() => {
                    props.handleAddAddendumCloseModal();
                }}
                size="md"
                keyboard={false}
                backdropClassName="modal-backdrop"
                contentClassName="modal-border modal-shadow"
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header className="modal-header" closeButton>
                    <Modal.Title className="font-18 text--terciary">
                        Add Addendum
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className='container'>
                        <form id="addendumForm"
                            onSubmit={async (e) => {
                                setDisable(true);
                                e.preventDefault();
                                await props.addAddendum(props.encId);
                                setDisable(false);
                                // props.handleAddenModal();
                            }}>
                            <div className='row'>
                                <div className="col-sm-12">
                                    <div>
                                        {/* <h3 className='font-14 m-0'>Title</h3> */}
                                        {/* <TextArea className="form-control shadow-none" placeholder={""} style={{ border: "1px solid #D9E8EC" }} gettext={gettext} /> */}
                                        <h3 htmlFor="addedndumtitle" className="form-label font-14 m-0">Title</h3>
                                        <input
                                            type="text"
                                            required
                                            className="form-control input br-10 shadow-none border--default"
                                            placeholder={"Type here"}
                                            id="addendumtitle"
                                            onChange={setAddendumTitle} aria-describedby="addedndumTitle"
                                            onBlur={(e) => {
                                                if (isOnlySpaces(e.target.value)) {
                                                    e.target.value = "";
                                                }
                                            }}
                                        />
                                    </div>

                                    <div className="mt-3">
                                        <h3 className='font-14 m-0'>Addendum</h3>
                                        <TextArea
                                            className="form-control input br-10 shadow-none border--default"
                                            required
                                            rows={5}
                                            placeholder={"Type here"}
                                            gettext={setAddendumText}

                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>

                <Modal.Footer style={{ border: "none" }}>
                    <button
                        className="btn text-white b2 px-4 br-10 py-1 blue_task_button"
                        type="submit"
                        form="addendumForm"
                        disabled={disable}
                    >
                        {disable ? `Saving` : `Save`}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddendumModal;

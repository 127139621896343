const UsersIcon = (props) => {
  return (
    <svg
      id="icon-encounter"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20.003"
      viewBox="0 0 20 20.003"
      {...props}
      fill={props.style.color}
    >
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="21.8" height="18.241" viewBox="0 0 21.8 18.241"> */}
      <g id="Icon-pharmacy" transform="translate(0.9 0.976)">
        <path id="Caminho_646" data-name="Caminho 646" d="M16.045,27.955V26.136A3.636,3.636,0,0,0,12.409,22.5H5.136A3.636,3.636,0,0,0,1.5,26.136v1.818" transform="translate(-1.5 -11.591)" fill="none" stroke={props.style.color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
        <path id="Caminho_647" data-name="Caminho 647" d="M14.773,8.136A3.636,3.636,0,1,1,11.136,4.5a3.636,3.636,0,0,1,3.636,3.636Z" transform="translate(-3.863 -4.5)" fill="none" stroke={props.style.color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
        <path id="Caminho_648" data-name="Caminho 648" d="M32.727,28.031V26.213A3.636,3.636,0,0,0,30,22.7" transform="translate(-12.728 -11.667)" fill="none" stroke={props.style.color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
        <path id="Caminho_649" data-name="Caminho 649" d="M24,4.7a3.636,3.636,0,0,1,0,7.045" transform="translate(-10.363 -4.576)" fill="none" stroke={props.style.color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
      </g>
    </svg>


  );
};

export default UsersIcon;

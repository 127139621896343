import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import sendHttpRequest from '../../components/hooks/sendHttpRequest';
import { useAuth } from '../../components/hooks/useAuth';
import { useLocalStorage } from '../../components/hooks/useLocalStorage';
import useModal from '../../components/hooks/useModal';
import PrescriptionRequests from '../../components/pages/PrescriptionRequests';
import LabRequisitionFormModal from '../../components/UI/Modals/LabRequisitionFormModal';
import NewPrescriptionModal from '../../components/UI/Modals/PrescriptionModal/NewPrescriptionModal';
import { setUserPermissionsData, setUserTimezoneData } from '../../redux/actions/providerActions';

const VeeOneIndexPage = (props) => {
    const { open: openLabOrderModal, handleOpen: handleOpenLabOrderModal, handleClose: handleCloseLabOrderModal } = useModal(false);
    const { open: openMedicationModal, handleOpen: handleOpenMedicationModal, handleClose: handleCloseMedicationModal, handleModal: handleMedicationModal } = useModal(false);
    const { user, loginWithoutNavigation } = useAuth();
    const [secondaryUser, setSecondaryUser] = useLocalStorage("su", null);
    const [isSent, setIsSent] = useState(false);
    const [isPrescriptionSent, setIsPrescriptionSent] = useState(false);
    const [isLabOrderSent, setIsLabOrderSent] = useState(false);
    const [isToken, setIsToken] = useState(false);
    const [patientId, setPatientId] = useState(null);
    const [providerId, setProviderId] = useState(null);


    const [message, setMessage] = useState();
    const [severity, setSeverity] = useState();
    const [openLabOrderSnackBar, setOpenLabOrderSnackBar] = useState(false);
    const handleLabOrderSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenLabOrderSnackBar(!openLabOrderSnackBar);
    };

    const dispatch = useDispatch();



    const handleSetIsSent = () => {
        setIsSent(true);
    };

    useEffect(() => {
        window.addEventListener('message', event => {
            console.log(event.origin);
            if (event.origin !== "https://app-stg.prescribenow.com") return;
            let response = event.data.response;
            // console.log(event.data);
            if (event.data.type === 'authentication') {
                loginWithoutNavigation({
                    loggedIn: true,
                    role: response?.role ?? "",
                    userId: response?.userId ?? "",
                    selectedUserId: response?.userId ?? "",
                    timezone: response?.timezoneOffset ?? "",
                    timezoneText: response?.timezone ?? "",
                    name: response?.name,
                    firstName: response?.name[0]?.firstName ?? "",
                    lastName: response?.name[0]?.lastName ?? "",
                    prefix: response?.name[0]?.prefix ?? "",
                    prescription: response?.Identification?.Prescription,
                    ePrescription: response?.identification?.status,
                    spi: response?.providerTenantPracticeLocation?.spi,
                    tenantId: response?.role === "patient" ? response?.tenant?.tenantId : response?.providerTenantPracticeLocation?.tenant?.tenantId,
                    tenant: response?.role === "patient" ? response?.tenant : response?.providerTenantPracticeLocation?.tenant,
                    facility: response?.providerTenantPracticeLocation?.facility,
                    practiceLocationId: response?.providerTenantPracticeLocation?.providerTenantPracticeLocationId,
                    isFromIframe: true
                });
                localStorage.setItem("tkn", event.data.authorization);
                setIsToken(true);
            }
            else if (event.data.type === 'data-passing') {
                console.log(event.data);
                setPatientId(event.data.patientId);
            }
            else if (event.data.type === 'provider-select') {
                const pId = event.data.providerId;
                let response = null;
                sendHttpRequest({
                    url: `/provider/${pId}`,
                    method: "GET",
                }).then(res => {
                    response = res.data;
                }
                ).then(() => {
                    loginWithoutNavigation({
                        loggedIn: true,
                        role: response?.role ?? "",
                        userId: response?.userId ?? "",
                        selectedUserId: response?.userId ?? "",
                        timezone: response?.timezoneOffset ?? "",
                        timezoneText: response?.timezone ?? "",
                        name: response?.name,
                        firstName: response?.name[0]?.firstName ?? "",
                        lastName: response?.name[0]?.lastName ?? "",
                        prefix: response?.name[0]?.prefix ?? "",
                        prescription: response?.Identification?.Prescription,
                        ePrescription: response?.identification?.status,
                        spi: response?.providerTenantPracticeLocation?.spi,
                        tenantId: response?.role === "patient" ? response?.tenant?.tenantId : response?.providerTenantPracticeLocation?.tenant?.tenantId,
                        tenant: response?.role === "patient" ? response?.tenant : response?.providerTenantPracticeLocation?.tenant,
                        facility: response?.providerTenantPracticeLocation?.facility,
                        practiceLocationId: response?.providerTenantPracticeLocation?.providerTenantPracticeLocationId,
                        isFromIframe: true
                    });
                });
                // loginWithoutNavigation({ userId: pId });

            }
        });
    }, []);


    return (
        <><div className='container-fluid br-10 border--default'>
            <div className='row  py-2 '>
                <div className='col-sm-12'>
                    <h2 className="font-18 fw-sb">VeeOne x PrescribeNow</h2>
                    <div className='row'>
                        <div className='col-sm-4 font-12 h-100 border--right'>
                            {/* Patient list goes here */}
                        </div>
                        <div className='col-sm-8'>
                            {isToken ? <><div>
                                <button
                                    disabled={patientId === null}
                                    onClick={handleMedicationModal}
                                    className='btn blue_task_button'>Order Medication</button>
                            </div>
                                <br />
                                <div>
                                    <button
                                        disabled={patientId === null}
                                        onClick={handleOpenLabOrderModal}
                                        className='btn blue_task_button'>Create Lab Order</button>
                                </div></> : null}
                        </div>
                    </div>
                </div>
            </div>
            <div className='row py-5'>
                <div className='col-12'>
                </div>
            </div>
        </div>
            {openMedicationModal ? (
                <NewPrescriptionModal
                    patientId={patientId}
                    show={openMedicationModal}
                    handleSetIsSent={handleSetIsSent}
                    handleNewPrescriptionModal={handleCloseMedicationModal}
                    prescriptionRequestModal={
                        <PrescriptionRequests
                            encounterId={patientId}
                            patientId={patientId}
                            handleSetIsSent={handleSetIsSent}
                            handleNewPrescriptionModal={handleCloseMedicationModal}
                            newPrescriptionRequest={true}
                            setIsPrescriptionSent={setIsPrescriptionSent}
                        />
                    }

                />
            ) : null}

            {openLabOrderModal ? <LabRequisitionFormModal
                show={openLabOrderModal}
                handleModal={handleCloseLabOrderModal}
                patientId={Number(patientId)}
                assessmentPlan={true}
                setIsLabOrderSent={setIsLabOrderSent}
                setMessage={setMessage}
                setSeverity={setSeverity}
                handleLabOrderSnackBar={handleLabOrderSnackBar}
            /> : null}
        </>
    )
}

export default VeeOneIndexPage
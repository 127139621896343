import React, { useEffect, useRef, useState } from "react";
import classes from "./AgoraVideoContainer.module.css";
import {
  VolumeOff,
  VolumeUp,
  MicMuteFill,
  MicFill,
  CameraVideo,
  CameraVideoOff,
  Telephone,
  Gear,
  ChatFill,
  Fullscreen,
} from "react-bootstrap-icons";
import { useToggle } from "../../hooks/useToggle";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { Modal } from "react-bootstrap";
import LiveChatContainer from "./LiveChatContainer";
import { client, pAudio, pVideo } from "./agoraDataObjects";
import { localVideoTrack } from "./agoraDataObjects";
import { localAudioTrack } from "./agoraDataObjects";
import { apiKey } from "./agoraDataObjects";
import { configuration } from "./agoraDataObjects";
import { AgoraRTC } from "./agoraDataObjects";
import sendHttpRequest from "../../hooks/sendHttpRequest";
import { Resizable } from "re-resizable";
import Draggable from "react-draggable";
import AgoraWaitingRoomModal from "./AgoraWaitingRoomModal";
import { useInterval } from "../../hooks/useInterval";
import { useAuth } from "../../hooks/useAuth";
import { fieldValidation } from "../../../utils/helpers/HelperFunctions";
import { useStopwatch } from "react-timer-hook";
import { setConsulationInProgress } from "../../../redux/actions/providerActions";

const EndConsultationModal = ({
  duration,
  show,
  handleEndConsulationModal,
  hours,
  minutes,
  seconds,
  localAudioTrack,
  localVideoTrack,
}) => {
  const dispatch = useDispatch();
  const appointmentInformation = useSelector(
    (state) => state.PatientEncounterData.appoinment
  );
  // console.log("Appointment Information===>", appointmentInformation);
  return (
    <>
      <Modal
        show={show}
        size="md"
        dialogClassName="end-consultation-modal-dialog"
        backdropClassName="modal-backdrop"
        contentClassName="modal-border modal-shadow"
        centered
      >
        <Modal.Header style={{ border: "none" }}>
          <div className="row ">
            <div className="col-12 ">
              <span
                style={{
                  lineHeight: "12px",
                  width: "18px",
                  fontSize: "8pt",
                  color: "#336383",
                  position: "absolute",
                  top: 15,
                  right: 15,
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleEndConsulationModal();
                }}
              >
                {<CloseIcon />}
              </span>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="row py-3">
            <div className="col-sm-12">
              <h2 className="font-18 fw-sb text-center">End Consultation</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-sm-4">
              <div className="end-consultation-duration px-3 py-1">
                <span className="fw-sb font-12 text--secondary text-center d-block">
                  Duration: {hours}:{minutes}:{seconds}
                </span>
              </div>
            </div>
          </div>
          <div className="row justify-content-center py-3">
            <div className="col-sm-9">
              <span className="font-14 text--secondary d-block text-center">
                Would you like to end the consultation {" "}
                <span className="fw-sb text--terciary">
                  {appointmentInformation?.encounter?.patient?.name[0]?.firstName?.concat(
                    " ",
                    appointmentInformation?.encounter?.patient?.name[0]
                      ?.lastName
                  )}
                </span>{" "}
                ?
              </span>
            </div>
          </div>

          <div className="row justify-content-center py-3">
            <div className="col-sm-5">
              <button
                onClick={handleEndConsulationModal}
                className="btn w-140 br-10 bg--d9e8ec shadow-none text--blue font-14 fw-sb"
              >
                Cancel
              </button>
            </div>

            <div className="col-sm-5">
              <button
                onClick={async () => {
                  dispatch(setConsulationInProgress(false));
                  localVideoTrack.current.stop();
                  localVideoTrack.current.setEnabled(false);
                  localAudioTrack.current.setEnabled(false);
                  await client.unpublish(localVideoTrack.current);
                  client.leave();

                  // document.getElementById("publisher").style.visibility =
                  //   "hidden";
                  handleEndConsulationModal(false);
                }}
                className="btn w-140 br-10 bg--red shadow-none text-white font-14 fw-sb"
              >
                End
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
const INIT_WIDTH = window.screen.width - 200;
const INIT_HEIGHT = window.screen.height - 400;

const AgoraConference = (props) => {
  const [mute, setMute] = useToggle(false);
  const [volumeOff, setVolumeOff] = useToggle(false);
  const [videoOff, setVideoOff] = useToggle(false);
  const [showMessages, setShowMessages] = useToggle(false);
  const [showEndConsultationModal, setShowEndConsultationModal] =
    useToggle(false);
  const videoOpenState = useSelector((state) => state.isVideoOpen.isVideoOpen);
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useStopwatch({ autoStart: true });
  const [width, setWidth] = React.useState(INIT_WIDTH);
  const [height, setHeight] = React.useState(INIT_HEIGHT);
  const [expanded, setExpanded] = React.useState(false);
  const dispatch = useDispatch();
  const [hide, setHide] = React.useState(videoOpenState);
  // const [AgoraRTC, setAgoraRTC] = useState(window.AgoraRTC);
  const localAudioTrack = useRef(null);
  const localVideoTrack = useRef(null);
  const [sessionId, setSessionId] = useState(null);
  const [token, setToken] = useState(null);
  const [collapse, setCollapse] = useToggle(false);
  const [showWaitingRoom, setShowWaitingRoom] = useToggle(false);
  const [initiatePolling, setInitiatePolling] = useToggle(false);
  const [waitingRoomList, setWaitingRoomList] = useState([]);
  const appointmentInformation = useSelector(
    (state) => state.PatientEncounterData.appoinment
  );
  const providerNameArr = appointmentInformation?.encounter?.provider?.name[0];
  // console.log(providerNameArr);
  const providerName = providerNameArr?.prefix?.concat(
    " ",
    providerNameArr?.firstName,
    " ",
    providerNameArr?.lastName
  );
  const idRef = useRef();

  // console.log("Appointment Information == >", appointmentInformation);

  const handleWaitingRoomModalClose = () => {
    setShowWaitingRoom();
  };

  const removeFromWaitingList = (id) => {
    setWaitingRoomList((prev) => {
      return prev.filter((item) => item?.id !== id);
    });
  };

  const handlePublisherVideo = () => {
    if (videoOff == false) {
      pVideo(
        localVideoTrack?.current,
        false,
        client,
        document.getElementById("publisher")
      );
    } else {
      pVideo(
        localVideoTrack?.current,
        true,
        client,
        document.getElementById("publisher")
      );
      let publisherDiv = document.getElementById("publisher");
      publisherDiv.children[1].style.borderRadius = "10px";
      publisherDiv.children[0].style.top = "98%";
    }
    setVideoOff((prev) => {
      return !prev;
    });
  };

  const handlePublisherAudio = () => {
    if (volumeOff === false) {
      pAudio(localAudioTrack?.current, false, client);
    } else {
      pAudio(localAudioTrack?.current, true, client);
    }
    setMute((prev) => {
      return !prev;
    });
  };

  const waitingRoomPolling = async (appointmentId) => {
    const httpResponse = await sendHttpRequest({
      url: `/video/approvalrequest/${appointmentId}`,
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log(httpResponse);
      if (
        httpResponse?.data !== null &&
        httpResponse?.data !== "" &&
        httpResponse?.data !== undefined
      ) {
        setWaitingRoomList(httpResponse?.data);
        sleepSync(1000);
        setShowWaitingRoom((prev) => {
          if (prev == false) {
            return true;
          }
        });
      }
    }
  };

  const getPrescriberSession = async (appointmentId) => {
    const httpResponse = await sendHttpRequest({
      url: `/video/getProviderSession/${appointmentId}`,

      method: "GET",
    });
    if (!httpResponse.error) {
      setSessionId((prev) => {
        return httpResponse?.data?.sessionId;
      });
      setToken((prev) => {
        return httpResponse?.data?.token;
      });
      // console.group("Agora")
      // console.log("SID==>", httpResponse?.data?.sessionId);
      // console.log("Token==>", httpResponse?.data?.token);
      // console.groupEnd();
      client
        ?.join(apiKey, httpResponse?.data?.sessionId, httpResponse?.data?.token)
        .then((uid) => {
          console.log("UID===>", uid);
          console.log("localAudioTrack---->", localAudioTrack.current);
          console.log("localVideoTrack---->", localVideoTrack.current);
          client.publish([localAudioTrack.current, localVideoTrack.current]);
        })
        .then(() => {
          setInitiatePolling(true);
        });
    } else {
      console.log(httpResponse.error);
    }
  };

  useEffect(() => {
    if (localVideoTrack.current == null) {
      dispatch(setConsulationInProgress(true));
      AgoraRTC.createMicrophoneAndCameraTracks()
        .then(
          (audio, video) => {
            localAudioTrack.current = audio[0];
            localVideoTrack.current = audio[1]; // Context of these objects is required

            localStorage.setItem("pr_lad", audio[0]);
            localStorage.setItem("pr_lvd", audio[1]);

            sleepSync(1000); //Synchronous Sleep
            setTimeout(() => {
              observeMutations();
            }, 2000);

            setTimeout(() => {
              disableResizingHandles();
            }, 2000);

            audio[1].play(document.getElementById("publisher"));
          },
          (onRejected) => {
            console.log("Unable to create audio or video track.");
          }
        )
        .then(() => {
          document.getElementById("publisher").children[1].classList.add("si"); //?
          getPrescriberSession(appointmentInformation?.id);
        });
    }
  }, []);

  useEffect(() => {
    client.on("user-published", async (user, mediaType) => {
      await client.subscribe(user, mediaType); // subscribe when a user publishes
      if (mediaType === "video") {
        //Logic for Conference video goes here
        user.videoTrack.play(document.getElementById("subscriber")); // in case of conference ?+
        let subscriberDiv = document.getElementById("subscriber");
        subscriberDiv.children[2].style.borderRadius = "10px";
        // subscriberDiv.children[2].style.bottom = "1.5em";
      }
      if (mediaType === "audio") {
        user.audioTrack.play(); // audio does not need a DOM element
      }
    });

    return () => {
      // setAgoraRTC(null);
      // setClient(null);
    };
  }, [appointmentInformation?.id]);

  useEffect(() => {
    // useInterval(()=>waitingRoomPolling(),5000);
    if (initiatePolling === true) {
      console.log("Polling Started");
      idRef.current = setInterval(
        () => waitingRoomPolling(appointmentInformation?.id),
        15000
      );
      return () => {
        clearInterval(idRef.current);
        setSessionId(null);
        setToken(null);
        localVideoTrack.current = null;
        localAudioTrack.current = null;
      };
    }
  }, [initiatePolling]);

  return (
    <>
      <AgoraWaitingRoomModal
        idRef={idRef.current}
        show={showWaitingRoom}
        removeFromWaitingList={removeFromWaitingList}
        handleModalClose={handleWaitingRoomModalClose}
        waitingList={waitingRoomList}
        setWaitingRoomList={setWaitingRoomList}
      />
      {showEndConsultationModal ? (
        <EndConsultationModal
          handleEndConsulationModal={setShowEndConsultationModal}
          show={showEndConsultationModal}
          minutes={minutes}
          hours={hours}
          seconds={seconds}
          localAudioTrack={localAudioTrack}
          localVideoTrack={localVideoTrack}
          client={client}
        />
      ) : null}
      <Draggable bounds="body">
        <div
          id="closest"
          style={{
            position: "fixed",
            zIndex: "1021",
            visibility: videoOpenState === false ? "hidden" : "visible",
          }}
          onClick={(e) => {
            if (
              e.target.id === "expand_icon_btn" ||
              e.target.id === "expand_icon"
            ) {
            }
          }}
        >
          <Resizable
            size={{ width, height }}
            id="resizable-div"
            className={`${collapse === true ? "cf" : ""}`}
          >
            <div
              className={`${classes["conference-container"]} ${classes["background"]
                } ${collapse === true ? "cf" : ""}`}
            >
              {/* This section is hideable */}
              <div
                className={`${classes["conference-item"]} ${collapse === true ? "offscreen" : ""
                  }`}
              >
                <div
                  className={`${classes["subscriber-list"]} subscribers-div`}
                >
                  <div
                    className={`${classes["subscriber-item"]} si`}
                    id="publisher"
                  >
                    <div
                      className={`${classes["subscriber-name"]} ${videoOff === true ? "offscreen" : ""
                        }`}
                    >
                      <span className="font-12 fw-sb text-white">
                        {providerName}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* This section won't hide in both states collapsed/expand */}
              <div
                className={`${classes["conference-item"]} ${collapse === true ? "cf p-0" : ""
                  } `}
              >
                <div
                  className={`${classes["speaker"]} ${collapse === true ? "cf" : ""
                    } `}
                >
                  <div
                    id="subscriber"
                    className={`${classes["speaker-video"]} ${collapse === true ? "cf" : ""
                      } `}
                  >
                    <div
                      className={`${classes["speaker-top-controls"]} ${collapse === false ? "d-none" : ""
                        }`}
                    >
                      <div
                        id="expand_icon_btn"
                        className={`${classes["expand-icon"]}`}
                        onClick={(event) => {
                          setCollapse((p) => !p);
                        }}
                      >
                        <Fullscreen
                          id="expand_icon"
                          size="14"
                          style={{ color: "white", padding: 0, margin: 0 }}
                        />
                      </div>
                    </div>
                    <div className={`${classes["focused-participant-name"]}`}>
                      <span className="font-12 fw-sb text-white"></span>
                    </div>
                  </div>
                </div>
                <div
                  className={`d-flex justify-content-center gap-3 mt-4 ${collapse === true ? "offscreen" : ""
                    }`}
                >
                  {/* <button
                    onClick={() => {
                      setVolumeOff((prev) => {
                        return !prev;
                      });
                    }}
                    className={`btn shadow-none justify-content-center br-10 d-flex align-items-center  ${classes["control-btn"]}`}
                  >
                    {volumeOff === false ? (
                      <VolumeUp className="font-28" size={52} />
                    ) : (
                      <VolumeOff className="font-28" size={52} />
                    )}
                  </button> */}
                  <button
                    onClick={() => {
                      setVolumeOff();
                      handlePublisherAudio();
                    }}
                    className={`btn shadow-none justify-content-center br-10 d-flex align-items-center  ${classes["control-btn"]}`}
                  >
                    {mute === false ? (
                      <MicFill size={24} />
                    ) : (
                      <MicMuteFill size={24} />
                    )}
                  </button>
                  <button
                    onClick={() => {
                      handlePublisherVideo();
                    }}
                    className={`btn shadow-none justify-content-center br-10 d-flex align-items-center  ${classes["control-btn"]}`}
                  >
                    {videoOff === true ? (
                      <CameraVideoOff size={24} />
                    ) : (
                      <CameraVideo size={24} />
                    )}
                  </button>
                  <button
                    onClick={() => {
                      setShowEndConsultationModal(true);
                    }}
                    className={`btn shadow-none justify-content-center br-10 d-flex align-items-center  ${classes["control-btn"]}`}
                    style={{ backgroundColor: "var(--danger)" }}
                  >
                    <Telephone size={24} />
                  </button>
                  <button
                    onClick={() => {
                      setCollapse((prev) => !prev);
                    }}
                    className={`btn shadow-none justify-content-center br-10 d-flex align-items-center  ${classes["control-btn"]}`}
                  >
                    <Fullscreen size={24} />
                  </button>
                  <button
                    onClick={() => {
                      setShowMessages((prev) => !prev);
                    }}
                    className={`btn shadow-none justify-content-center br-10 d-flex align-items-center  ${classes["control-btn"]}`}
                  >
                    <ChatFill size={24} />
                  </button>
                </div>
              </div>

              {/* This section is hideable */}
              <div
                className={`${classes["conference-item"]} chat ${showMessages === false ? "offscreen" : ""
                  } ${collapse === true ? "offscreen" : ""}`}
              >
                <LiveChatContainer />
              </div>
            </div>
          </Resizable>
        </div>
      </Draggable>
    </>
  );
};

export default AgoraConference;

const sleepSync = (ms) => {
  const end = new Date().getTime() + ms;
  while (new Date().getTime() < end) {
    /* do nothing */
  }
};

function observeMutations() {
  try {
    const mutationObserver = new MutationObserver((entries) => {
      entries.forEach((entry) => {
        entry?.addedNodes[0]?.children[1]?.classList.add("si");
      });
    });
    mutationObserver.observe(document.querySelector(".subscribers-div"), {
      childList: true,
    });
  } catch (ex) {
    console.error("MutationObserverError: ", ex?.message);
  }
}

function disableResizingHandles() {
  const p = document.getElementById("resizable-div");
  const target = p.lastChild;
  for (let i = 0; i < target.childElementCount; ++i) {
    target.childNodes[i].style.display = "none";
  }
}

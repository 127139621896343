import { ActionTypes } from "../constants/actionTypes"

const dirtyBitInitialState = {    
        isDirty:false
}

const setDirtyBitReducer = (state=dirtyBitInitialState, action)=>{
    if (action.type === ActionTypes.SET_DIRTY_BIT ){
        return {
            ...state,
            isDirty: action.payload
        
        }
    }
    return state;
}


export default setDirtyBitReducer;
import { useDispatch } from "react-redux";
import { setDirtyBitInStore, updatePrescribedMedication } from "../../redux/actions/providerActions";
import classes from "./DrugItem.module.css";
const DrugItem = (props) => {
  const dispatch = useDispatch();

  let medicationType = props.selectedDrug?.drugNameCode;
  return (
    <li
      className={`border--default ${classes.li}`}
      // onClick={() => props.prescribeDrug(props.selectedDrug)}
      onMouseDown={() => {
        if (props?.replaceRequest === true) {
          dispatch(updatePrescribedMedication([]))
        }
        props?.clearDrugData();
        props?.setMedicineName("");
        props?.fetchAvailableStrengths(props.selectedDrug);
        props.prescribeDrug(props.selectedDrug);
        props?.setSuggestions([]);
        dispatch(setDirtyBitInStore(true));
      }}
    >
      <div className="d-flex flex-row align-items-center">
        <span style={{ flexGrow: 1 }}>
          {props.selectedDrug.drugDescription}
        </span>
        <span className={`badge br-5 ms-3 text--white fw-sb p-1 font-8 ${medicationType === "Generic Name" ? "border-outlined-blue" : "border-outlined-primary"}`}>
          {medicationType === "Generic Name" ? "Generic" : "Brand"}
        </span>
      </div>
    </li>
  );
};

export default DrugItem;

import { useCallback, useState } from "react";

function useModal(initialState = false) {
    const [open, setOpen] = useState(initialState);

    const handleOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const handleModal = useCallback(() => {
        setOpen(prevIsOpen => !prevIsOpen);
    }, [])

    return {
        open,
        handleOpen,
        handleClose,
        handleModal
    };
}

export default useModal;
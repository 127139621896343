import {ActionTypes} from "../constants/actionTypes"

const initialState = {
    
    Threads:[]
}

const setProviderMessagesReducer = (state=initialState, action)=>{
    if (action.type === ActionTypes.SET_MESSAGES ){
        return {
            ...state,
            Threads : action.payload.Threads
        }
    }
    return state
}

export default setProviderMessagesReducer;

import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import sendHttpRequest from "../hooks/sendHttpRequest";
import EditButtonIcon from "../../assets/icons/Icon-Edit.svg";
import AddButtonIcon from "../../../src/assets/icons/Icon-Add.svg";
import AllergyModal from "../UI/Modals/PatientModals/AllergyModal";
import AllergyEditModal from "../UI/Modals/PatientModals/AllergyEditModal";
import { useParams } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { allergiesStrings } from "../../res/strings";
import TrashIcon from "../../assets/icons/TrashIcon";
import { useSelector } from "react-redux";
import { ENCOUNTERS } from "../../utils/helpers/subscriptionsConstants";

function getObjectKeys(params) {
  return Object.keys(params);
}


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FAFCFC",
    color: "#336383",
    fontWeight: 400,
    fontSize: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    paddingRight: "0px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#FAFCFC",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles({
  customTable: {
    "& .MuiTableCell-sizeMedium": {
      padding: "0px", // <-- arbitrary value
    },
  },
  tableCell: {
    textTransform: "capitalize",
  },
});

const AllergyTable = (props) => {

  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);

  const { providerId, encounterId, patientId } = useParams();
  const classes = useStyles();
  const _str = allergiesStrings.en;
  const [allergy, setAllergy] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [category, setCategory] = useState("");
  const [substance, setSubstance] = useState("");
  const [severity, setSeverity] = useState("");
  const [reactions, setReactions] = useState("");
  const [editAllergy, setEditAllergy] = useState();
  const [allergyStatusCode, setAllergyStatusCode] = useState(null);
  const [updateAllergyId, setUpdateAllergyId] = useState();
  const classNames = [];



  const fetchAllergy = async (encounter_id) => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: `/patient/${patientId}/allergies`,
      method: "GET"
      // params: {
      //   encounter_id: encounter_id,
      // },
    });
    if (!httpResponse.error) {
      setAllergy(httpResponse.data);
      // console.log(httpResponse.data);
      setIsLoading(false);
    } else {
      console.log(httpResponse.error);
      setIsLoading(false);
    }
    setAllergyStatusCode(httpResponse.status);
  };

  const handleModal = () => {
    setOpenModal(!openModal);
  };
  const OpeneditModal = () => {
    setOpenEditModal(!openEditModal);
  };
  const handleAllergyCategory = (e) => {
    let value = e.target.value;
    if (value !== "select") {
      setCategory(value);
    }
  };

  const addAllergyRequestHandler = async () => {


    const httpResponse = await sendHttpRequest({
      url: `/encounter/${encounterId}/allergy`,
      method: "POST",
      data: {
        drugProductCodedText: substance,
        reactionCodedText: reactions,
        severityCodedText: severity,
        allergyType: category
      },
    });
    if (!httpResponse.error) {
      fetchAllergy(encounterId);
      setCategory("");
      setSubstance("");
      setSeverity("");
      setReactions("");
      // console.log(httpResponse.data);
    } else {
      console.log(httpResponse);
    }
  };

  const editAllergyRequestHandler = async (encounter_id) => {
    // console.log("reached->",category);

    const httpResponse = await sendHttpRequest({
      url: `/encounter/${encounterId}/allergy/${updateAllergyId}`,
      method: "PUT",
      data: {
        "drugProductCodedText": substance,
        "reactionCodedText": reactions,
        "severityCodedText": severity,
        "allergyType": category,
        "sourceOfInformation": "C"
      },
    });
    if (!httpResponse.error) {
      fetchAllergy(encounterId);
      setCategory("");
      setSubstance("");
      setSeverity("");
      setReactions("");
      // console.log(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
  };

  const handleEditModal = (event) => {
    // console.log("event.target", event.target);
    let id = event.target.id;
    // console.log("id", id);
    setUpdateAllergyId(id);

    const drugResult = allergy.filter((item) => {
      console.log(item)
      return item?.allergyType?.toLowerCase() === 'drug' && item?.allergyId === Number(id);
    });
    const commonResult = allergy.filter((item) => {
      return item?.allergyType?.toLowerCase() === 'common' && item?.allergyId === Number(id);
    });
    const foodResult = allergy.filter((item) => {
      return item?.allergyType?.toLowerCase() === 'food' && item?.allergyId === Number(id);
    });

    console.log({ drugResult, commonResult, foodResult });
    // console.log({ drugResult, commonResult, foodResult });

    if (foodResult.length > 0) {
      setCategory("Food");
      setEditAllergy({ ...foodResult[0], category: "Food" });
      setSubstance(foodResult[0].drugProductCodedText);
      setSeverity(foodResult[0].severityCodedText);
      setReactions(foodResult[0].reactionCodedText);
    } else if (drugResult.length > 0) {
      setCategory("Drug");
      setEditAllergy({ ...drugResult[0], category: "Drug" });
      setSubstance(drugResult[0].drugProductCodedText);
      setSeverity(drugResult[0].severityCodedText);
      setReactions(drugResult[0].reactionCodedText);
    } else if (commonResult.length > 0) {
      setEditAllergy({ ...commonResult[0], category: "Common" });
      setCategory("Common");
      setSubstance(commonResult[0].drugProductCodedText);
      setSeverity(commonResult[0].severityCodedText);
      setReactions(commonResult[0].reactionCodedText);
    }

    setOpenEditModal(!openModal);
  };

  useEffect(() => {
    fetchAllergy(encounterId);

    return () => {
      setCategory(null);
      setSubstance(null);
      setSeverity(null);
      setReactions(null);
    }
  }, []);

  return (

    <>
      <div className="my-5">

        {openModal ? (
          <AllergyModal
            show={openModal}
            handleModal={handleModal}
            setCategory={setCategory}
            setSubstance={setSubstance}
            setSeverity={setSeverity}
            setReactions={setReactions}
            handleAllergyCategory={handleAllergyCategory}
            addAllergyRequestHandler={addAllergyRequestHandler}
          />
        ) : null}

        {openEditModal ? (
          <AllergyEditModal
            show={openEditModal}
            handleModal={OpeneditModal}
            setCategory={setCategory}
            setSubstance={setSubstance}
            setSeverity={setSeverity}
            setReactions={setReactions}
            handleAllergyCategory={handleAllergyCategory}
            editAllergy={editAllergy}
            editAllergyRequestHandler={editAllergyRequestHandler}
          />
        ) : null}
        <div className="d-flex mb-1 justify-content-between align-items-baseline">
          <div className="d-inline-flex flex-column align-self-center">
            <h4 className="h18 fw-sb">{_str.allergies}</h4>
            {/* <p style={{ color: 'var(--secondary)' }}>Lorem Ipsum dolor asit</p> */}
          </div>
          <div className="d-flex align-self-center " style={{ height: "40px", width: "40px" }}>
            {!props?.viewOnly && (
              <img
                style={{ color: "var(--primary)" }}
                className="btn shadow-none"
                onClick={() => handleModal()}
                src={AddButtonIcon}
                alt="add-button-icon"
                hidden={subscriptionPrivilegeGroups[ENCOUNTERS]?.apa?.active === false ? true : false}
              />
            )}
          </div>
        </div>

        <TableContainer
          sx={{ borderRadius: "16px", border: "1px solid #D9E8EC" }}
        >
          <Table>
            <TableHead sx={{ borderTopColor: "#FAFCFC", borderRightColor: "#FAFCFC", borderLeftColor: "#FAFCFC", borderBottom: "1px solid #D9E8EC" }}>
              <StyledTableRow>
                <StyledTableCell>{_str.substance}</StyledTableCell>
                <StyledTableCell>{_str.category}</StyledTableCell>
                <StyledTableCell>{_str.severity}</StyledTableCell>
                <StyledTableCell>{_str.reaction}</StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {isLoading ? <StyledTableRow>
                <StyledTableCell><Skeleton animation="wave" /></StyledTableCell>
                <StyledTableCell><Skeleton animation="wave" /></StyledTableCell>
                <StyledTableCell><Skeleton animation="wave" /></StyledTableCell>
                <StyledTableCell><Skeleton animation="wave" /></StyledTableCell>
                <StyledTableCell><Skeleton animation="wave" /></StyledTableCell>
                <StyledTableCell><Skeleton animation="wave" /></StyledTableCell>
              </StyledTableRow> :
                allergyStatusCode === 200 ? allergy?.map((row, index) => {

                  return (
                    <StyledTableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <StyledTableCell
                        sx={{ width: "100", color: "#336383" }}
                      >
                        {row?.drugProductCodedText}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ width: "100", fontWeight: 600 }}
                      >
                        {row?.allergyType}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ width: "100" }}
                      >


                        <span className={` px-2 py-1 fw-sb font-14 br-10 ${row?.severityCodedText == "Mild" ? "bg--mild text--secondary" : (row?.severityCodedText == "Severe" ? "bg--severe text--primary" : row?.severityCodedText == "Moderate" ? "bg--moderate text--moderate" : '')}  d-inline-block`}>
                          {row?.severityCodedText ? row?.severityCodedText : ''}
                        </span>
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ width: "100", color: "#336383" }}
                      >
                        {row?.reactionCodedText}
                      </StyledTableCell>
                      <StyledTableCell style={{ cursor: "pointer" }} id={row.Id} >
                        <img
                          // style={{ backgroundColor:"transparent",border:"0px" }}
                          // class="btn var(--primary)"
                          onClick={(e) => handleEditModal(e)}
                          id={row.allergyId}
                          src={EditButtonIcon}
                          alt="plus icon"
                          draggable={false}
                          hidden={subscriptionPrivilegeGroups[ENCOUNTERS]?.apa?.active === false ? true : false}
                        ></img>
                      </StyledTableCell>
                      <StyledTableCell>
                        <TrashIcon style={{ color: "#DDDDDD" }} />
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                }) : ""}

            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};
export default AllergyTable;

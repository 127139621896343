import React from 'react';
import { Modal } from "react-bootstrap";
// import AddButtonIcon from "../../assets/icons/Icon-Add.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useRef, useState } from "react";

import TextArea from '../../../forms/TextArea';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { changeDateTimeFormat } from '../../../../utils/dateTimeHelper';
import sendHttpRequest from '../../../hooks/sendHttpRequest';
import { formatPhoneNumber, formatPostalCode } from '../../../../utils/helpers/HelperFunctions';


function ReviewRefillRequest(props) {

    const [medicationData, setMedicationData] = useState([props.prescriptionReferenceData]);
    const [prescriptionReferenceDataData, setPrescriptionReferenceDataData] = useState(props.prescriptionReferenceData);
    const [isLoading, setIsLoading] = useState(true);

    let providerFirstName = props.prescriptionReferenceData?.providerTenantPracticeLocation?.provider?.name[0]?.firstName;    
    let providerLastName = props.prescriptionReferenceData?.providerTenantPracticeLocation?.provider?.name[0]?.lastName;    
    let qualification = props.prescriptionReferenceData?.providerTenantPracticeLocation?.provider?.qualification;    
    let drugDescription = props.prescriptionReferenceData?.medicationRx[0]?.drugDescription
    let medicationRxId = props.prescriptionReferenceData?.medicationRx[0]?.medicationRxId
    let quantity = props.prescriptionReferenceData?.medicationRx[0]?.quantity
    let daysSupply = props.prescriptionReferenceData?.medicationRx[0]?.daysSupply
    let refills = props.prescriptionReferenceData?.medicationRx[0]?.refills
    let pharmacyName = props.prescriptionReferenceData?.medicationRx[0]?.pharmacy?.standardizedOrganizationName
    let pharmacyAddress = props.prescriptionReferenceData?.medicationRx[0]?.pharmacy?.standardizedAddressLine1;
    let pharmacyCity = props.prescriptionReferenceData?.medicationRx[0]?.pharmacy?.standardizedCity;
    let pharmacyPostalCode = props.prescriptionReferenceData?.medicationRx[0]?.pharmacy?.standardizedPostalCode;
    let pharmacyStateProvince = props.prescriptionReferenceData?.medicationRx[0]?.pharmacy?.stateProvince;
    let pharmacyPhone = props.prescriptionReferenceData?.medicationRx[0]?.pharmacy?.primaryTelephone;
    let pharmacyFax = props.prescriptionReferenceData?.medicationRx[0]?.pharmacy?.fax;


    const getRefillRequestData = async () => {
        setIsLoading(true);
        props.handleToggle();
        const httpResponse = await sendHttpRequest({
            url: "/patient/order",
            method: "GET",
            params: {
                EncounterId: props.addEncounterId,
            }
        });
        if (!httpResponse.error) {
            // emptyStates();
            // setMedicationData(httpResponse.data);
            // console.log(httpResponse.data);
        } else {
            console.log(httpResponse.error);
        }
        setIsLoading(false);
        props.handleBackdropClose();
    }

    const clearState = () => {
        setMedicationData([]);
        setIsLoading(true);
    }

    useEffect(() => {
        getRefillRequestData();
        props.saveRefillsCount(props.prescriptionReferenceData?.medicationRx[0]?.refills);
        return () => {
            //flush states on component unmount
            clearState();
        };
    }, [props.addEncounterId]);

    // console.log(medicationData);

    return (
        <Modal
            show={props.show}
            onHide={() => {
                props.handleRefillModal();
            }}
            // size="md"
            backdropClassName="modal-backdrop"
            contentClassName="modal-border modal-shadow "
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
        // style={{style}}
        >
            <Modal.Header className="modal-header" >
                <span
                    style={{
                        lineHeight: "12px",
                        width: "18px",
                        fontSize: "8pt",
                        color: "#336383",
                        position: "absolute",
                        top: 15,
                        right: 15,
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        props.handleRefillModal();
                    }}
                >
                    {<CloseIcon />}
                </span>

                <Modal.Title className="font-20 fw-sb text--terciary">
                    Refill Request
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container-fluid px-3 ">
                    <form
                        id="refillRequestForm"
                        onSubmit={(e) => {
                            e.preventDefault();
                            props.refillRequestStatusHandler();
                        }}
                    >
                        {
                            !isLoading &&

                            (<div className="row  ">
                                <div className="col-md-12 mb-2">
                                    <label
                                        htmlFor="physician"
                                        className="form-label m-0 font-10 text--secondary fw-sb"
                                    >
                                        Prescribing Physician
                                    </label>
                                    <input
                                        disabled
                                        type="text"
                                        className={`form-control text--terciary refill_input font-12 m-0 border--default br-10 shadow-none input`}
                                        id="physician"
                                        defaultValue={

                                            providerFirstName +
                                            " " +
                                            providerLastName +
                                            ", " +
                                            qualification
                                        }
                                    />
                                </div>
                                <div className="col-md-12 mb-2">
                                    <label
                                        htmlFor="DispensedMedication"
                                        className="form-label m-0 font-10 text--secondary fw-sb"
                                    >
                                        Medication
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control text--terciary refill_input font-12 border--default br-10 shadow-none input"
                                        id="DispensedMedication"
                                        disabled
                                        defaultValue={drugDescription}
                                    />
                                </div>

                                <div className="col-md-6 mb-2">
                                    <label
                                        htmlFor="Quantity"
                                        className="form-label m-0 font-10 text--secondary fw-sb"
                                    >
                                        Quantity
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control text--terciary refill_input font-12 border--default br-10 shadow-none input"
                                        id="Quantity"
                                        disabled
                                        defaultValue={quantity}
                                    />
                                </div>
                                <div className="col-md-6 mb-2">
                                    <label
                                        htmlFor="DaysSupply"
                                        className="form-label m-0 font-10 text--secondary fw-sb"
                                    >
                                        Days Supply
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control text--terciary refill_input font-12 border--default br-10 shadow-none input"
                                        id="DaysSupply"
                                        disabled
                                        defaultValue={daysSupply}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <label
                                        htmlFor="RefillsCount"
                                        className="form-label m-0 font-10 text--secondary fw-sb"
                                    >
                                        Number of Refills
                                    </label>
                                    <select
                                        //   onChange={(e)=>refillsCountHandler(e)}
                                        className="form-select text--terciary font-12 border--default br-10 shadow-none input"
                                        aria-label="RefillsCount"
                                        id="RefillsCount"
                                        required
                                        style={{ borderRadius: "10px" }}
                                        onChange={(e) => { props.saveRefillsCount(e.target.value) }}
                                    >
                                        {
                                            [1,2,3,4,5,6,7,8,9,10].map((item)=>{
                                                return(
                                                    <option key={item} value={item}  selected = {Number(refills) === Number(item) ? true:false}>{item}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>

                                <div className="col-md-6">
                                    <label
                                        htmlFor="lastRefillDate"
                                        className="form-label m-0 font-10 text--secondary fw-sb"
                                    >
                                        Date of Last Refill
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control text--terciary refill_input bg--d9e8ec font-12 border--default br-10 shadow-none input"
                                        id="last_refill_date"
                                        disabled
                                        // value={"20 Jan, 2022"}
                                        defaultValue={props.prescriptionReferenceData?.medicationRx[0].lastRefillDate ? changeDateTimeFormat(props.prescriptionReferenceData?.medicationRx[0].lastRefillDate, "MMM D, YYYY") : "Not available"}
                                    />
                                </div>

                                <div className='mt-4'>
                                    <Stack direction="column">
                                        <Stack direction="row" justifyContent={"between"} spacing={2}>
                                            <div className="col-sm-12" style={{ backgroundColor: "#ffffff", border: "1px solid #D9E8EC", borderRadius: '10px' }}>
                                                <div className="row">
                                                    <div className="col-sm-10 justify-content-start align-self-center">
                                                        <Typography component="span">
                                                            <Box
                                                                sx={{
                                                                    p: 1,
                                                                    fontFamily: "Montserrat",
                                                                    fontWeight: "600",
                                                                    fontSize: "10px",
                                                                    color: "#336383",
                                                                }}
                                                            >
                                                                {"Pharmacy"}
                                                            </Box>
                                                        </Typography>
                                                    </div>
                                                    <div className="col-sm-2 font-10 text--disabled fw-sb justify-content-end align-self-center" >
                                                        {"Change"}
                                                        {/* <TwoFactorSwitch {...label} checked={twoFactorState} onChange={twoFactorSwitch} /> */}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12 justify-content-start align-self-center">
                                                        <Typography component="span">
                                                            <Box
                                                                sx={{
                                                                    p: 1,
                                                                    fontFamily: "Montserrat",
                                                                    fontWeight: "600",
                                                                    fontSize: "12px",
                                                                    color: "#323232",
                                                                    borderTop: "1px solid #D9E8EC"
                                                                }}
                                                            >
                                                                {pharmacyName}
                                                            </Box>
                                                        </Typography>
                                                    </div>
                                                    <span className='ms-2 text--secondary font-12' style={{ marginTop: "-6px" }}>
                                                        {pharmacyAddress}{" "}{pharmacyCity?.trim()}{", "} {pharmacyStateProvince?.trim()} {" "} {formatPostalCode(pharmacyPostalCode?.trim())}
                                                    </span>
                                                    <span className='ms-2 text--secondary font-12' style={{ marginTop: "-6px" }}>
                                                        {"Phone: " +formatPhoneNumber(pharmacyPhone)}{" Fax: " +formatPhoneNumber(pharmacyFax)}
                                                    </span>
                                                </div>
                                            </div>
                                        </Stack>
                                    </Stack>
                                </div>

                                <div className="col-12 mt-2">
                                    <label
                                        htmlFor="note"
                                        className="form-label m-0 font-10 text--secondary fw-sb"
                                    >
                                        Note to pharmacist
                                    </label>
                                    <TextArea
                                        // type="text"
                                        className={`form-control font-12 m-0 border--default text--terciary br-10 shadow-none input`}
                                        id="note"
                                        gettext={props.saveRefillsNote}
                                        // gettext={getText}
                                        // defaultValue={
                                        //   props?.refillRequestObj?.PharmacyName +
                                        //   ", " +
                                        //   props?.refillRequestObj?.PharmacyAddressLine1 +
                                        //   ", " +
                                        //   props?.refillRequestObj?.City?.trim() +
                                        //   ", " +
                                        //   props?.refillRequestObj?.StateProvince +
                                        //   ", " +
                                        //   props?.refillRequestObj?.PostalCode
                                        // }
                                        // defaultValue={pharmacyInfo}
                                        rows={2}
                                    />
                                </div>
                            </div>)
                        }
                    </form>
                </div>
            </Modal.Body>
            <Modal.Footer style={{ border: "none" }}>
                <div className='justify-content-end'>
                    <div className="col-md-12" >
                        <div></div>
                        <div>
                            <button
                                // type="submit"
                                className={`btn fw-sb font-12 deny_button text--blue b2 br-10 me-2 shadow-none`}
                                // form="refillRequestForm"
                                id={props.prescriptionReferenceData?.medicationRx[0]?.medicationRxId}
                                value={false}
                                disabled={props.isSubmit}
                                onClick={(e) => { props.refillRequestStatusHandler(e) }}
                            >
                                {"Deny"}
                            </button>

                            <button
                                // type="submit"
                                className={`btn fw-sb font-12 blue_button text--white b2 br-10 me-3 shadow-none`}
                                // form="refillRequestForm"
                                id={props.prescriptionReferenceData?.medicationRx[0]?.medicationRxId}
                                disabled={props.isSubmit}
                                value={true}
                                onClick={(e) => { props.approveRefillRequest(e) }}
                            >
                                {"Approve"}
                            </button>
                        </div>

                    </div>
                </div>
            </Modal.Footer>
            {/* {console.log("medicationData",medicationData)} */}
        </Modal>
    )
}

export default ReviewRefillRequest
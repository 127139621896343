import { useEffect, useState } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Modal } from "react-bootstrap";
import sendHttpRequest from "../../../hooks/sendHttpRequest";
import { useDispatch, useSelector } from "react-redux";
import CalendarIcon from "../../Drawer/DrawerItemIcons/CalendarIcon";
// import classes from "../../General/Stats.module.css";
import classes from "./PendingEncountersModal.module.css";
import { useNavigate, useLocation, Link } from "react-router-dom";
import TaskIcon from "../../Drawer/DrawerItemIcons/TaskIcon";
import TaskItem from "../../../pages/TasksItem";
import {
  setAllPhysicalSystemsStatus,
  setDiagnosis,
  setDisableSendRxButton,
  setPatientEncounterData,
  setPrescriptionNoteText,
  setUpdateEncounterData,
} from "../../../../redux/actions/providerActions";
import ReviewRefillRequest from "./ReviewRefillRequest";
import SummaryModal from "./SummaryModal";
import AddendumModal from "./AddendumModal";
import { useAuth } from "../../../hooks/useAuth";
import { strings } from "../../../../res/strings";
import RenewalRxModal from "../../../renewalrx/RenewalRxModal";
import { useToggle } from "../../../hooks/useToggle";
import DenyRenewalModal from "../../../renewalrx/DenyRenewalModal";
import ReplaceRxModal from "../../../renewalrx/ReplaceRenewalRequestModal";

const PendingTasksModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [pendingTaskData, setPendingTaskData] = useState([]);
  const [isPendingTaskDataLoading, setIsPendingTaskDataLoading] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingEnc, setIsLoadingEnc] = useState(true);
  const [isError, setIsError] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [isSent, setIsSent] = useState(false);
  const [statusCodeEncounters, setStatusCodeEncounters] = useState(null);
  const [statusFilter, setStatusFilter] = useState("all");
  const [typeFilter, setTypeFilter] = useState("all");
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [summary, setSummary] = useState({});
  const [addEncounterId, setAddEncounterId] = useState("");
  const [openAddenModal, setOpenAddenModal] = useState(false);
  const [addendumBody, setAddendumBody] = useState("");
  const [addendumTitle, setAddendumTitle] = useState("");
  const [openRefillReq, setOpenRefillReq] = useState(false);
  // const [isApproved, setIsApproved] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [refillReqNote, setRrefillReqNote] = useState("");
  const [refillCount, setRefillsCount] = useState(1);
  // const [refillReqId, setRefillReqId] = useState("");
  const { user } = useAuth();
  const __str = strings.en;

  const [renewalRequestModal, setRenewalRequestModal] = useToggle(false);
  const [denyRenwalRequestModal, setDenyRenewalRequestModal] = useToggle(false);
  const [replaceRenewalRequestModal, setReplaceRenewalRequestModal] =
    useToggle(false);
  const [renewalRequestTask, setRenewalRequestTask] = useState(null);
  const [reloadTasks, setReloadTasks] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [isErrorMessage, setIsErrorMessage] = useState(false);

  const fetchPendingTaskData = async () => {
    setIsPendingTaskDataLoading(true);
    let httpResponse;
    if (props?.patientSpecificTasks === true) {
      httpResponse = await sendHttpRequest({
        url: "/tasks",
        method: "GET",
        params: {
          PatientId: props?.PatientId,
        },
      });
    } else {
      httpResponse = await sendHttpRequest({
        url: `/tasks/assignee/${user?.userId}`,
        method: "GET",
        params: {
          status: "pending",
          tenantId: user?.tenantId
        },
      });
    }
    if (!httpResponse.error) {
      // console.log("Pending tasks: ", httpResponse.data,httpResponse.data.length);
      setPendingTaskData(httpResponse.data.taskList);
    } else {
      console.log(httpResponse.error);
    }
    setIsPendingTaskDataLoading(false);
  };

  //Provider Dropdown code
  const [isSessionUpdated, setIsSessionUpdated] = useState(false);

  const handleRefillModal = (enc_id) => {
    setAddEncounterId(enc_id);
    setOpenRefillReq(!openRefillReq);

    // getRefillRequestData(enc_id);
  };

  const handleRefillModalClose = () => {
    setOpenRefillReq(false);
  };

  const filterTasks = async (statusFilter, typeFilter) => {
    setIsLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/tasks",
      method: "GET",
      params: {
        status: statusFilter,
        type: typeFilter,
      },
    });
    if (!httpResponse.error) {
      setTasks(httpResponse.data);
      setIsLoading(false);
    } else {
      console.log(httpResponse.error);
      setIsLoading(false);
    }
    setStatusCodeEncounters(httpResponse.status);
  };

  const fetchPatientSummary = async (encId) => {
    const httpResponse = await sendHttpRequest({
      url: "/singleEncounter/summary",
      method: "GET",
      params: {
        EncounterId: encId,
      },
    });
    if (!httpResponse.error) {
      setSummary(httpResponse.data);
      props.handleBackdropClose();
      // setOpenAddenShowModal(!openAddenShowModal);
      setOpenModal(!openModal);
      setIsLoadingEnc(false);
    } else {
      console.log(httpResponse.error);
    }
  };

  function handleModal(encId) {
    if (encId !== "") {
      props.handleToggle();
      fetchPatientSummary(encId);
    }
  }

  function handleSumModal() {
    setOpenModal(!openModal);
  }

  function handleAddenModal(encId) {
    // console.log("asdasdasdasdasdas");
    if (encId !== "") {
      setAddEncounterId(encId);
      setOpenModal(false);
      setOpenAddenModal(!openAddenModal);
    }
  }

  const addAddendum = async () => {
    const httpResponse = await sendHttpRequest({
      url: "/addendum",
      method: "POST",
      data: {
        EncounterId: addEncounterId,
        Addendum: addendumBody,
        Title: addendumTitle,
      },
    });
    if (!httpResponse.error) {
      // emptyStates();
      setIsSent(true);
      // console.log(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
  };

  const refillRequestStatus = async (isApproved, id) => {
    setIsSubmit(true);
    const httpResponse = await sendHttpRequest({
      url: "/renewalRequest/action",
      method: "POST",
      data: {
        RenewalRequestId: id,
        IsAccepted: isApproved,
        Note: refillReqNote,
        TotalRefills: refillCount,
      },
    });
    if (!httpResponse.error) {
      handleRefillModalClose();
      fetchPendingTaskData();
      // emptyStates();
      // setMedicationData(httpResponse.data);
      // console.log(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
    setIsSubmit(false);
  };

  const refillRequestStatusHandler = (event) => {
    refillRequestStatus(event.target.value, event.target.id);
  };

  const handlePatientEncouterData = async (enc_id, ptnt_id) => {
    props.handleToggle();
    // const httpResponse = await sendHttpRequest({
    //   url: "/singleEncounter",
    //   method: "GET",
    //   params: {
    //     EncounterId: enc_id,
    //   },
    // });
    // if (!httpResponse.error) {
    //   setPatientEncouterData(httpResponse.data, ptnt_id);
    // } else {
    //   console.log(httpResponse.error);
    // }

    const httpResponse = await sendHttpRequest({
      url: `/prescription-reference/${enc_id}`,
      method: "GET",
    });
    if (!httpResponse.error) {
      console.log("httpResponse.data", httpResponse.data);
      setPatientEncouterData(httpResponse.data, ptnt_id);
    } else {
      console.log(httpResponse.error);
    }
    // setIsLoadingEnc(true);
  };

  //code is copied from encouter file
  const setPatientEncouterData = (data, ptnt_id) => {
    if (data?.encounter?.RxNote !== undefined) {
      dispatch(setPrescriptionNoteText(data?.encounter?.RxNote));
    } else {
      dispatch(setPrescriptionNoteText(""));
    }
    // console.log("data?.encounter?.Diagnosis",data?.encounter?.Diagnosis);
    let diagnosis = {
      Note: "",
      Secondary: [],
      Primary: [],
    };
    if (
      data?.encounter?.diagnosis !== undefined &&
      data?.encounter?.diagnosis !== null &&
      data?.encounter?.diagnosis?.length > 0
    ) {
      diagnosis.Note = data?.encounter?.diagnosis[0]?.note;
      data?.encounter?.diagnosis.forEach((diagnosisItem) => {
        if (diagnosisItem?.type?.toLowerCase() === "p") {
          diagnosis.Primary = [diagnosisItem];
        } else {
          diagnosis.Secondary.push(diagnosisItem);
        }
      });
    }
    dispatch(setUpdateEncounterData({ Diagnosis: diagnosis }));
    dispatch(setDiagnosis(diagnosis));
    // dispatch(setPatientEncounterData({...data?.prescriptionReference, notes:data?.prescriptionReference?.encounter?.notes || ""}));
    dispatch(
      setPatientEncounterData({
        ...data,
        prescriptionReference: {
          ...data?.prescriptionReference,
          encounter: {
            ...data?.prescriptionReference?.encounter,
            notes: data?.prescriptionReference?.encounter?.notes || "",
          },
        },
      })
    );
    dispatch(
      setAllPhysicalSystemsStatus(
        data?.prescriptionReference?.encounter?.allSystemsOk
      )
    );
    if (data?.encounter?.status === "DISPATCHED") {
      dispatch(setDisableSendRxButton(true));
    } else {
      dispatch(setDisableSendRxButton(false));
    }
    props.handleBackdropClose();
    props.handleModal();
    navigate(
      `/consult/${data?.provider?.userId}/${data?.prescriptionReferenceId}/${ptnt_id}`
    );

    // dispatch(setDiagnosis(diagnosis));
    // dispatch(setVideoCallState(false));
  };

  const saveRefillsCount = (refill_count) => {
    setRefillsCount(refill_count);
  };

  const saveRefillsNote = (refill_note) => {
    setRrefillReqNote(refill_note);
  };

  const handleTaskTypeFilter = (event) => {
    setTypeFilter(event.target.value);
    filterTasks(statusFilter, event.target.value);
  };

  const handleTaskStatusFilter = (event) => {
    setStatusFilter(event.target.value);
    filterTasks(event.target.value, typeFilter);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSent(false);
  };

  if (isSessionUpdated === true) {
    fetchPendingTaskData();
    setIsSessionUpdated(false);
  }

  const handleRenewalMessageClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowMessage(false);
  };

  const handleRenewalTask = (renewalRxTask) => {
    setRenewalRequestTask(renewalRxTask);
  };

  useEffect(() => {
    fetchPendingTaskData();
  }, []);

  return (
    <>
      {openRefillReq && (
        <ReviewRefillRequest
          show={openRefillReq}
          isSubmit={isSubmit}
          addEncounterId={addEncounterId}
          saveRefillsNote={saveRefillsNote}
          handleRefillModal={handleRefillModalClose}
          refillRequestStatus={refillRequestStatus}
          refillRequestStatusHandler={refillRequestStatusHandler}
          handleToggle={props.handleToggle}
          handleBackdropClose={props.handleBackdropClose}
          saveRefillsCount={saveRefillsCount}
        />
      )}
      {!isLoadingEnc && (
        <SummaryModal
          encounter={summary}
          handleAddenModal={handleAddenModal}
          show={openModal}
          handleModal={handleSumModal}
          pendingTask={true}
        />
      )}
      <AddendumModal
        addAddendum={addAddendum}
        setAddendumBody={setAddendumBody}
        setAddendumTitle={setAddendumTitle}
        show={openAddenModal}
        handleAddenModal={handleAddenModal}
      />

      {renewalRequestModal ? (
        <RenewalRxModal
          show={renewalRequestModal}
          showError={setIsErrorMessage}
          showMessage={setShowMessage}
          handleReplaceRenewalRequest={setReplaceRenewalRequestModal}
          reloadTasks={setReloadTasks}
          handleModal={setRenewalRequestModal}
          handleDenyRequestModal={setDenyRenewalRequestModal}
          renewalTask={renewalRequestTask}
        />
      ) : null}
      {denyRenwalRequestModal ? (
        <DenyRenewalModal
          show={denyRenwalRequestModal}
          showError={setIsErrorMessage}
          handleRenewalModal={setRenewalRequestModal}
          reloadTasks={setReloadTasks}
          renewalTask={renewalRequestTask}
          handleModal={setDenyRenewalRequestModal}
          handleRenewalTask={handleRenewalTask}
          showMessage={setShowMessage}
        />
      ) : null}
      {replaceRenewalRequestModal ? (
        <ReplaceRxModal
          show={replaceRenewalRequestModal}
          renewalTask={renewalRequestTask}
          showMessage={setShowMessage}
          showError={setIsErrorMessage}
          reloadTasks={setReloadTasks}
          handleModal={setReplaceRenewalRequestModal}
        />
      ) : null}

      <Modal
        show={props.show}
        onHide={() => {
          props.handleModal();
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        backdropClassName="modal-backdrop"
        contentClassName="modal-border modal-shadow"
        // dialogClassName={classes.root}
        scrollable
        centered
        // style={{zIndex:100001}}
      >
        <Modal.Header className="modal-header">
          <span
            style={{
              lineHeight: "12px",
              width: "18px",
              fontSize: "8pt",
              color: "#336383",
              position: "absolute",
              top: 15,
              right: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              props?.handleModal();
            }}
          >
            {<CloseIcon />}
          </span>
          {/* <Modal.Title className="font-20 fw-sb">Pending encounters */}

          {/* </Modal.Title> */}
          <div className="d-flex">
            <div
              className={`bg--lightBlue text--secondary my-auto p-3`}
              style={{
                // height: "60px",
                // width: "60px",
                borderRadius: "20px",
              }}
            >
              <div>
                <TaskIcon
                  style={{
                    color: "#004FD41A",
                    fill: "#004FD4",
                    width: "24px",
                    height: "24px",
                  }}
                  className="mx-auto  d-block mt-50"
                />
              </div>
            </div>

            <div className="text--terciary font-18 fw-sb align-self-center ms-2">
              <div>Pending</div>
              <div>Tasks</div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="row mt-3 justify-content-center mb-5">
            <Backdrop sx={{ color: "#EC609B", zIndex: 1900000000 }} open={open}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <div className="col-sm-11 font-14 text-end">
              {props?.patientSpecificTasks === true ? null : (
                <Link
                  to="/tasks"
                  state={{ from: location }}
                  className="fw-sb text-blue font-12"
                >
                  View all
                </Link>
              )}
            </div>
            <div className="col-sm-11">
              <Grid
                container
                sx={{ marginBottom: "-8px" }}
                p={1}
                className={`sticky-top bg-white ${classes["row__head"]} ${
                  pendingTaskData?.length === 0 ? classes["border--bottom"] : ""
                }`}
              >
                <Grid item xs={1.2} sm={1.2} md={1.2}>
                  <Typography component="div">
                    <Box
                      sx={{
                        textAlign: "left",
                        m: 1,
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#336383",
                      }}
                    >
                      Date
                    </Box>
                  </Typography>
                  {/* </Stack> */}
                </Grid>
                <Grid item xs={1.3} sm={1.3}>
                  <Typography component="div">
                    <Box
                      sx={{
                        textAlign: "left",
                        mt: 1,
                        mr: 4,
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#336383",
                      }}
                    >
                      Provider
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={1.5}>
                  <Typography component="div">
                    <Box
                      sx={{
                        textAlign: "left",
                        mt: 1,
                        ml: 2,
                        fontFamily: "Montserrat",
                        fontSize: "12px",
                        color: "#336383",
                      }}
                    >
                      Category
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={1.8}>
                  <Typography component="div">
                    <Box
                      sx={{
                        textAlign: "left",
                        marginTop: "8px",
                        ml: 2,
                        fontFamily: "Montserrat",
                        fontSize: "12px",
                        color: "#336383",
                      }}
                    >
                      Patient
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={3.7}>
                  <Typography component="div">
                    <Box
                      sx={{
                        textAlign: "left",
                        mt: 1,
                        ml: 2,
                        fontFamily: "Montserrat",
                        fontSize: "12px",
                        color: "#336383",
                      }}
                    >
                      Highlights
                    </Box>
                  </Typography>
                </Grid>

                {/* <Grid item xs={2}></Grid> */}
              </Grid>

              {isPendingTaskDataLoading ? (
                <Skeleton animation="wave" />
              ) : pendingTaskData?.length > 0 ? (
                pendingTaskData?.map((pendingTaskDataItem, index) => {
                  if (index === pendingTaskData.length - 1) {
                    return (
                      <TaskItem
                        inModal={true}
                        key={index}
                        task={pendingTaskDataItem}
                        isLast={true}
                        isEven={index % 2 === 0 ? true : false}
                        handlePatientEncouterData={handlePatientEncouterData}
                        handleSummaryModal={handleModal}
                        handleRefillModal={handleRefillModal}
                        handlePendingTaskModal={props.handleModal}
                        handleRenewalModal={setRenewalRequestModal}
                        handleRenewalTask={handleRenewalTask}
                        pendingTaskModal = {true}
                      />
                    );
                  } else {
                    return (
                      <TaskItem
                        inModal={true}
                        key={index}
                        task={pendingTaskDataItem}
                        isEven={index % 2 === 0 ? true : false}
                        handlePatientEncouterData={handlePatientEncouterData}
                        handleSummaryModal={handleModal}
                        handleRefillModal={handleRefillModal}
                        handlePendingTaskModal={props.handleModal}
                        handleRenewalModal={setRenewalRequestModal}
                        handleRenewalTask={handleRenewalTask}
                        pendingTaskModal = {true}
                      />
                    );
                  }
                })
              ) : (
                <div className="font-14 text--terciary p-2">
                  {__str?.noDataAvailable}
                </div>
              )}
              {/* <TaskItem /> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* {console.log("monCount",monCount)} */}
      {/* {console.log("mondaySlotsList",mondaySlotsList)} */}
    </>
  );
};

export default PendingTasksModal;

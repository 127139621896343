import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import PNLogo from "../../assets/logo/LPLogo.svg";
import LoginImage from "../../assets/images/loginImage.PNG";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Backdrop } from "@mui/material";
import classes from "./Login.module.css";

import { useState } from "react";
import sendHttpRequest from "../hooks/sendHttpRequest";

import { useNavigate, useSearchParams } from "react-router-dom";
import CustomizedSnackbars from "../UI/General/CustomizedSnackbars";
import ConfirmDateOfBirthModal from "../UI/Modals/ConfirmDateOfBirthModal";
import PageNotFound from "./PageNotFound";


const theme = createTheme();

export default function ForgetPasswordPage(props) {
  const [searchParams] = useSearchParams();
  const key = searchParams.get("key");
  // const {key} = useParams();
  const navigate = useNavigate();

  const [confirmPass, setConfirmPass] = useState("");
  const [password, setPassword] = useState("");
  const [errorResponse, setErrorResponse] = useState("");
  const [newPasswordMatch, setNewPasswordMatch] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [savingButton, setSavingButton] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [openConfirmDateOfBirthModal, setOpenConfirmDateOfBirthModal] =
    useState(false);
  const [isLinkValid, setIsLinkValid] = useState(false);
  const [activationError, setActivationError] = useState("");
  const [iskeyValidationLoading, setIskeyValidationLoading] = useState(false);
  const [dobVerificationRquired, setDobVerificationRquired] = useState(false);


  const postNewPassword = async () => {
    setDisableButton(true);
    setSavingButton(true);
    const httpResponse = await sendHttpRequest({
      url: props.url,
      method: "POST",
      data: {
        password: password,
        key: key,
        type: "forget",
      },
    });
    if (!httpResponse.error) {
      setIsPasswordChanged(true);
    } else {
      setErrorResponse(httpResponse.errorMessage.error);
    }
    setDisableButton(false);
    setSavingButton(false);
  };

  const validateKey = async () => {
    setIskeyValidationLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/auth/validateRequest/AccountActivation",
      method: "POST",
      data: {
        key: key,
      },
    });
    if (!httpResponse.error) {
      setIsLinkValid(true);
      setDobVerificationRquired(httpResponse?.data?.dobVerificationRquired);
    } else {
      setIsLinkValid(false);
      console.log("Error",httpResponse?.errorMessage?.message);
      setActivationError(httpResponse?.errorMessage?.message);
      // setErrorResponse(httpResponse.errorMessage.error);
    }
    setIskeyValidationLoading(false);
  };

  const handleConfirmPasswordInput = (event) => {
    setDisableButton(false);
    if (event.target.value !== "") {
      if (event.target.value === password) {
        setNewPasswordMatch(true);
        setDisableButton(false);
      } else {
        setDisableButton(true);
        setNewPasswordMatch(false);
      }
    }
    setConfirmPass(event.target.value);
  };

  const handlePasswordInput = (event) => {
    if (event.target.value === confirmPass) {
      setNewPasswordMatch(true);
      setDisableButton(false);
    } else {
      setDisableButton(true);
      setNewPasswordMatch(false);
    }
    setPassword(event.target.value);
  };

  const handleLoginButton = () => {
    postNewPassword();

  };

  const handleCheckboxInput = (event) => {
    setCheckbox(event.target.checked);
  };

  const handlePasswordChangeToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsPasswordChanged(false);
    navigate("/");
  };

  const handleConfirmDateOfBirthModal = () => {
    if (dobVerificationRquired) {
      setOpenConfirmDateOfBirthModal(!openConfirmDateOfBirthModal);
    }
    else {
      handleLoginButton();
    }
  };

  React.useEffect(() => {

    validateKey();

  }, [props?.url]);

  //   const classes = useStyles();
  return (
    <>
      {openConfirmDateOfBirthModal && (
        <ConfirmDateOfBirthModal
          show={openConfirmDateOfBirthModal}
          handleModal={handleConfirmDateOfBirthModal}
          password={password}
          username={key}
          EmailConsent={checkbox}
          url={props?.url}
          disableButton={disableButton}
          setDisableButton={setDisableButton}
          validateKey={validateKey}
          setActivationError={setActivationError}
          setIsLinkValid={setIsLinkValid}
        />
      )}
      <ThemeProvider theme={theme}>
        {iskeyValidationLoading ? (
          <Backdrop
            sx={{
              color: "#EC609B",
              zIndex: (theme) => theme.zIndex.drawer + 1000,
            }}
            open={iskeyValidationLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : isLinkValid ? (
          <>
            <form
              id="forgetPasswordForm"
              onSubmit={(e) => {
                e.preventDefault();
                props?.setPass
                  ? handleConfirmDateOfBirthModal()
                  : handleLoginButton();
              }}
            >
              <Grid container component="main" sx={{ height: "100vh" }}>
                <CssBaseline />

                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={6}
                  component={Paper}
                  sx={{
                    backgroundImage:
                      "linear-gradient(180deg, #FFFFFF, #F2F7F8)",
                  }}
                  elevation={6}
                  square
                >
                  <Box
                    sx={{
                      mx: 7,
                      my: 5,
                    }}
                  >
                    <img src={PNLogo} width={260} height={55} />
                  </Box>
                  <Box
                    sx={{
                      my: 8,
                      mx: 4,
                      display: "flex",
                      flexDirection: "column",
                      px: 20,
                    }}
                  >
                    <Typography
                      component="h1"
                      className="text--terciary"
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: "700",
                        fontSize: "22px",
                        lineHeight: "50px",
                        mb: 2,
                      }}
                    >
                      {props?.setPass ? "Set Password" : "Enter New Password"}
                    </Typography>
                    <Box component="div" noValidate sx={{ mt: 1 }}>
                      <div className="form-group mb-2">
                        <label
                          htmlFor="password"
                          style={{
                            fontFamily: "Montserrat",
                            fontSize: "12px",
                            color: "#336383",
                          }}
                          className="text--terciary font-12"
                        >
                          New Password
                        </label>
                        <input
                          className="form-control fw-sb font-14 shadow-none br-10 input"
                          type="password"
                          required
                          autoFocus={true}
                          onChange={(e) => {
                            handlePasswordInput(e);
                          }}
                        />
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="confirmPassword"
                          style={{
                            fontFamily: "Montserrat",
                            fontSize: "12px",
                            color: "#336383",
                          }}
                          className="text--terciary font-12"
                        >
                          Confirm Password
                        </label>
                        <input
                          className="form-control shadow-none br-10 input"
                          type="password"
                          required
                          onChange={(e) => {
                            handleConfirmPasswordInput(e);
                          }}
                        // onKeyPress={(e) => {
                        //   if (e.code === "Enter" || e.charCode === 13) {
                        //     handleLoginButton();
                        //   }
                        // }}
                        />
                      </div>

                      {props?.setPass && (
                        <div className="form-group d-flex flex-row mt-3">
                          <input
                            className="ms-0 shadow-none mt-1"
                            type="checkbox"
                            onChange={(e) => {
                              handleCheckboxInput(e);
                            }}
                          />
                          <label
                            htmlFor="checkbox"
                            style={{
                              fontFamily: "Montserrat",
                              fontSize: "10px",
                              color: "#336383",
                              marginTop: "2px",
                            }}
                            className="ms-2"
                          >
                            By checking this box, I consent to receive
                            notification emails from PrescribeNow regarding my
                            appointment confirmations and reminders,
                            prescription orders, and lab results.
                          </label>
                        </div>
                      )}

                      <div className="d-grid gap-2">
                        <button
                          form="forgetPasswordForm"
                          type="submit"
                          disabled={disableButton}
                          className={`btn br-10 shadow-none bg--blue mt-4 py-2 text-center btn-block text-white ${classes.loginBtn}`}
                        >
                          {savingButton ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                      <div>
                        <p className="text--danger font-12">
                          {newPasswordMatch === false &&
                            confirmPass?.length > 0 &&
                            "Passwords must match"}
                          {errorResponse}
                        </p>
                      </div>
                    </Box>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={false}
                  sm={4}
                  md={6}
                  sx={{
                    backgroundImage: `url(${LoginImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundColor: (t) =>
                      t.palette.mode === "light"
                        ? t.palette.grey[50]
                        : t.palette.grey[900],
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </Grid>
            </form>
            <CustomizedSnackbars
              message={
                props.setPass
                  ? "Password set successfully"
                  : "Password changed successfully"
              }
              severity="success"
              open={isPasswordChanged}
              handleClose={handlePasswordChangeToastClose}
            />
          </>
        ) : (
          <PageNotFound text={activationError} />
        )}
      </ThemeProvider>
    </>
  );
}

import { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import classes from "./ImagingOrderModal.module.css";
import {
  lab_requisition_strings,
  numbersEnum,
  imaging_order_strings,
} from "../../../res/strings";
import { formatPhoneNumber } from "../../../utils/helpers/HelperFunctions";
import {
  calenderDateTimeHelper,
  getDMYformate,
} from "../../../utils/dateTimeHelper";
import CustomDatePicker from "../General/CustomDatePicker";
import TextArea from "../../forms/TextArea";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../Reusable/PnComponents";

import SearchIcon from "@mui/icons-material/Search";
import ReactSignatureCanvas from "react-signature-canvas";
import sendHttpRequest, { search } from "../../hooks/sendHttpRequest";
import { providerSettingSignaturesUrl } from "../../../res/endpointurls";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useEffect } from "react";
import { Skeleton } from "@mui/material";
import TrashButtonIcon from "../../../assets/icons/Icon-Trash.svg";
import _ from "lodash";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import PhoneNumberInput from "../Reusable/PhoneNumberInput";
import { isValidPhoneNumber } from "react-phone-number-input";

const SearchSuggestionItem = ({
  suggestionItem,
  isLeftRight,
  labelStr,
  setImagingOrderDataTemp,
  imagingOrderDataTemp,
  addImagingOrder,
}) => {
  const checkExamIdMatched = (suggestionItem,imagingOrderDataTemp,isLeftRight,labelStr) => {
    console.log("suggestionItem: ",suggestionItem);
    if (imagingOrderDataTemp?.imagingOrderTests?.length > 0) {
      console.log("imagingOrderDataTemp: =====: ",imagingOrderDataTemp);
      let ans = imagingOrderDataTemp?.imagingOrderTests.find((item) => {
        console.log("item< ",item);
        if(isLeftRight && item?.left){
          return labelStr === "Left" && item.imagingTest?.imageTest?.internalValue === suggestionItem?.imageTest?.internalValue && item.imagingTest?.description === suggestionItem?.description;
        }else if(isLeftRight && item?.right){
          return labelStr === "Right" && item.imagingTest?.imageTest?.internalValue === suggestionItem?.imageTest?.internalValue && item.imagingTest?.description === suggestionItem?.description;
        }else{
          return item.imagingTest?.imageTest?.internalValue === suggestionItem?.imageTest?.internalValue && item.imagingTest?.description === suggestionItem?.description;
        }
      });
      return ans !== undefined;
    } else {
      return false;
    }
  };
  //   console.log("suggestionItem: ", suggestionItem);
  return (
    <>
      <li
        className={`border--default ${classes.li}`}
        onMouseDown={(e) => {
          e.preventDefault();
          addImagingOrder(
            e,
            suggestionItem,
            isLeftRight,
            labelStr,
            setImagingOrderDataTemp,
            imagingOrderDataTemp,
            isLeftRight
              ? suggestionItem?.imageTest?.internalValue.concat("-",suggestionItem?.description,"-", `${labelStr}`)
              : suggestionItem?.description.concat(suggestionItem?.imageTest?.internalValue)
          );
        }}
      >
        <input
          type="checkbox"
          className="cursor--pointer"
          id={
            isLeftRight
              ? suggestionItem?.imageTest?.internalValue.concat("-",suggestionItem?.description,"-", `${labelStr}`)
              : suggestionItem?.description.concat(suggestionItem?.imageTest?.internalValue)
          }
          defaultChecked={checkExamIdMatched(suggestionItem,imagingOrderDataTemp,isLeftRight,labelStr)}
          onChange={(e)=>{
            addImagingOrder(
              e,
              suggestionItem,
              isLeftRight,
              labelStr,
              setImagingOrderDataTemp,
              imagingOrderDataTemp,
              isLeftRight
                ? suggestionItem?.imageTest?.internalValue.concat("-",suggestionItem?.description,"-", `${labelStr}`)
                : suggestionItem?.description.concat(suggestionItem?.imageTest?.internalValue)
            );
          }}
        />
        <label className="ms-1">
          {isLeftRight === true
            ? suggestionItem?.imageTest?.displayValue.concat(
                ": ",
                suggestionItem?.description,
                ", ",
                labelStr
              )
            : suggestionItem?.imageTest?.displayValue.concat(
                ": ",
                suggestionItem?.description
              )}
        </label>
      </li>
    </>
  );
};
const SearchImagingTest = ({
  category,
  imagingOrderDataTemp,
  setImagingOrderDataTemp,
  addImagingOrder,
  setImagingOrderData,
  imagingOrderData
}) => {
  const [searchQuery, setSearchQuery] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const searchImagingTest = async (searchStr) => {
    setIsLoading(true);
    setIsError(false);
    const httpResponse = await search({
      url: `/imaging-tests/all/${searchStr}`,
      method: "GET",
    });
    if (!httpResponse.error) {
      setSuggestions(httpResponse.data);
    } else {
      setIsError(true);
    }
    setIsLoading(false);
  };

  const handleSearch = (inputValue) => {
    setSearchQuery(inputValue);
    if (inputValue?.length > 0) {
      searchImagingTest(inputValue);
    }
  };
  const addDataToImagingOrder = ()=>{
    console.log("imagingOrderDataTemp?.imagingOrderTests: ",imagingOrderDataTemp?.imagingOrderTests);
    setImagingOrderData({...imagingOrderData,imagingOrderTests:[...imagingOrderDataTemp?.imagingOrderTests]})
  }

  return (
    <>
      {/* <div className="col-6 mb-3"> */}
      <h4 className="font-14 text--terciary fw-sb">{"Imaging Search"}</h4>
      <div
        className={`input-group border--default  br-10 ${classes["search__container"]}`}
      >
        <input
          type="text"
          className={`form-control col-sm-6  font-14 border-0 br-10 shadow-none ${classes.input}`}
          id={`imaging-search`}
          name={`imaging-search`}
          // ref={searchRef}
          autoComplete="off"
          placeholder={`Search here`}
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
          // onBlur={() => {
          //   setTimeout(() => {
          //     setSuggestions([]);
          //   }, 10);
          // }}
        />
        <button
          className={`btn br-10 shadow-none`}
          type="submit"
          value={searchQuery}
          // onClick={() => handleICD10SearchBtn(icd10Query)}
          // disabled={props?.updateCheck ? true : false}
          onBlur={() => {
            setTimeout(() => {
              // setSuggestions([]);
              //   setIsError(false);
            }, 5);
          }}
        >
          <SearchIcon className=" text--secondary" />
        </button>
      </div>
      <ul
        className={` cursor--pointer ${classes["suggestion__list"]} hide-scrolbar`}
        style={{
          width: "47%",
        }}
      >
        {isLoading ? (
          <>
            <li>Loading...</li>
          </>
        ) : isError ? (
          <li>{"No result found"}</li>
        ) : (
          // icd10Query?.length > 1 &&
          <>
            {searchQuery?.length > 0 && suggestions?.length > 0 &&
              suggestions.map((suggestionItem) => {
                if (suggestionItem?.leftRight === true) {
                  return (
                    <>
                      <SearchSuggestionItem
                        key={suggestionItem?.description.concat(
                          suggestionItem?.imageTest?.internalValue,
                          "-",
                          "Left"
                        )}
                        suggestionItem={suggestionItem}
                        addImagingOrder={addImagingOrder}
                        isLeftRight={true}
                        labelStr={"Left"}
                        setImagingOrderDataTemp={setImagingOrderDataTemp}
                        imagingOrderDataTemp={imagingOrderDataTemp}
                      />
                      <SearchSuggestionItem
                        key={suggestionItem?.description.concat(
                          suggestionItem?.imageTest?.internalValue,
                          "-",
                          "Right"
                        )}
                        suggestionItem={suggestionItem}
                        addImagingOrder={addImagingOrder}
                        isLeftRight={true}
                        labelStr={"Right"}
                        setImagingOrderDataTemp={setImagingOrderDataTemp}
                        imagingOrderDataTemp={imagingOrderDataTemp}
                      />
                    </>
                  );
                } else {
                  return (
                    <SearchSuggestionItem
                      key={suggestionItem?.description.concat(
                        suggestionItem?.imageTest?.internalValue
                      )}
                      suggestionItem={suggestionItem}
                      addImagingOrder={addImagingOrder}
                      isLeftRight={false}
                      setImagingOrderDataTemp={setImagingOrderDataTemp}
                      imagingOrderDataTemp={imagingOrderDataTemp}
                    />
                  );
                }
              })}
            {
              searchQuery?.length > 0 &&
              suggestions?.length > 0 ? (
                <div
                  style={{
                    position: "sticky",
                    display: "flex",
                    justifyContent: "center",
                    padding: "8px",
                    backgroundColor: "white",
                    gap: "4px",
                    bottom: 0,
                    left: 0,
                  }}
                >
                  <button
                    className="btn  d9e8ec--button"
                    style={{ height: "2.9 em !important" }}
                    onClick={() => {
                      setSuggestions([]);
                      setSearchQuery("");
                      // setExaminationTestList([])
                    }}
                  >
                    {"Cancel"}
                  </button>
                  <button
                    className="btn blue_task_button"
                    onClick={() => {
                      setSuggestions([]);
                      addDataToImagingOrder()
                      // setExaminationTestList(examinationTempList);
                      setSearchQuery("");
                      // setExaminationListErrors("");
                    }}
                    disabled={imagingOrderDataTemp?.imagingOrderTests?.length === 0}
                  >
                    {"Add"}
                  </button>
                </div>
              ) : null
            }
          </>
        )}
      </ul>
      {/* </div> */}
    </>
  );
};

  // ICD10 Code List Items
  const ICD10CodeListItems = ({icd10List,iCD10CodeList,setICD10CodeList})=>{
    const handleChange = (event,item)=>{
      let ischecked = event.target.checked
      if(ischecked){
        setICD10CodeList([...iCD10CodeList,item])
      }else{
        let result = iCD10CodeList.filter((itemObject)=>{return itemObject.code !== item.code})
        setICD10CodeList([...result])
      }
    }
    const handleDefaultChecked = (itemObject)=>{
      const result = iCD10CodeList.find((item)=>{ return item?.code === itemObject?.code})
      return result !== undefined
    }
    return (
      <>
        {
          icd10List.map((item)=>{
            return (
              <div className="col-6 col-sm-6">
                <div className=" form-group mx-auto font-14 text--terciary" >
                  <input 
                    type="checkbox" 
                    className="form-check-input border--default br-10 cursor--pointer cursor--pointer shadow-none" 
                    id={item?.code} 
                    name={item?.description} 
                    onChange={(e)=>{handleChange(e,item)}}
                    defaultChecked = {handleDefaultChecked(item)}
                    />
                  <label for={item?.code} className="form-check-label ms-1">{item?.code.concat(" - ",item?.description)}</label>
              </div>
              </div>
            )
          })
        } 
      </>
    )
  }

const ImagingOrderModal = (props) => {
  const __str = { ...lab_requisition_strings.en, ...imaging_order_strings.en };
  const _numbString = numbersEnum.en;
  const { patientId } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [imagingOrderConsent, setImagingOrderConsent] = useState(false);
  const ref = useRef();

  const [patientReqtrDetailsExpanded, setPatientReqtrDetailsExpanded] =
    useState("patientRqtr");
  const [servicesRequestExpanded, setServicesRequestExpanded] = useState(false);
  const [icd10Expanded, setIcd10Expanded] = useState(false);
  const [summaryExpanded, setSummaryExpanded] = useState(false);

  // Imaging order data
  const [imagingOrderDataTemp, setImagingOrderDataTemp] = useState({
    imagingOrderTests: props?.editMode ? props?.imagingOrderData?.imagingOrderTests : [],
  });
  const [imagingOrderData, setImagingOrderData] = useState({
    imagingOrderTests: props?.editMode ? props?.imagingOrderData?.imagingOrderTests : [],
  });
  const [isImagingOrderSending,setIsImagingOrderSending] = useState(false);

  //patient provider data
  const [patientData, setPatientData] = useState();
  const [providerData, setProviderData] = useState();
  const [isPatientDataLoading, setIsPatientDataLoading] = useState(false);
  const [isProviderDataLoading, setIsProviderDataLoading] = useState(false);

  // Additional tesst
  const [additionalTest, setAdditionalTest] = useState(
    props?.editMode ? props?.imagingOrderData?.additionalTest : ""
  );

  // ICD10 search
  const [isICD10Loading, setIsICD10Loading] = useState(false);
  const [icd10Suggestions, setIcd10Suggestions] = useState([]);
  const [icd10Query, setIcd10Query] = useState("");
  const [iCD10CodeList, setICD10CodeList] = useState(props?.editMode ? props?.imagingOrderData?.diagnoses : []);
  const [iCD10CodeData,setICD10CodeData] = useState([]);

  // Signature
  const [sigPad, setSigPad] = useState();
  const [filename, setFilename] = useState(null);

  // Order Type
  const [orderType, setOrderType] = useState("Print");
  const [fax, setFax] = useState();
  const [faxErrors, setFaxErrors] = useState("");

  const fetchProviderData = async () => {
    setIsProviderDataLoading(true);
    // setIsUserDataLoading(false);
    const httpResponse = await sendHttpRequest({
      url: `/provider/${user?.userId}`,
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
      setProviderData(httpResponse.data);
    }
    setIsProviderDataLoading(false);
  };
  const fetchPatientData = async () => {
    setIsPatientDataLoading(true);
    // setIsUserDataLoading(false);
    // console.log("patientId: ",patientId);
    const httpResponse = await sendHttpRequest({
      url: "/patient/information",
      method: "GET",
      params: {
        patientId: props.patientId,
      },
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
      setPatientData(httpResponse.data);
      fetchICD10CodeData(httpResponse.data?.userId);
    }
    setIsPatientDataLoading(false);
  };
  const searchICD10Codes = async (diagnosisInput) => {
    setIcd10Suggestions([]);
    setIsICD10Loading(true);
    const httpResponse = await search({
      url: `/diagnosis`,
      method: "GET",
      params: {
        search: diagnosisInput,
      },
    });
    if (!httpResponse.error) {
      setIcd10Suggestions(httpResponse.data);
    } else {
      if (httpResponse?.status === 400) {
        setIcd10Suggestions([{ code: "-1" }]);
      }
    }
    setIsICD10Loading(false);
  };

  function downloadPdf(httpResponse) {
    const link = document.createElement("a");
    link.target = "_blank";
    link.download =
      `${httpResponse?.responseHeaders?.pdf_name?.split("=")?.at(1)}` ??
      `Imaging_Order_${patientData?.userId}`;
    const href = URL.createObjectURL(
      new Blob([httpResponse.data], {
        type: "application/pdf",
        encoding: "UTF-8",
      })
    );
    link.href = href;
    link.click();
    link.remove();
    URL.revokeObjectURL(href);
  }
  const convertToImage = () => {
    let trimmedDataURL = sigPad.getTrimmedCanvas().toDataURL("image/png");
    // console.log(trimmedDataURL);
    let mimeType = trimmedDataURL.split(",")[0].split(":")[1].split(";")[0];

    fetch(trimmedDataURL)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "signature.png", {
          type: mimeType,
        });
        setFilename(file);
      });
  };
  const saveSignature = async () => {
    if (
      providerData?.providerSetting === undefined ||
      providerData?.providerSetting?.signature === undefined
    ) {
      const fd = new FormData();
      if (filename !== null) {
        fd.append("file", filename);
      }
      const httpResponse = await sendHttpRequest({
        url: providerSettingSignaturesUrl(providerData?.userId),
        method: "POST",
        data: fd,
        headers: {
          "Content-Type": "multipart/form-data; boundary=",
        },
      });
      // if (!httpResponse.error) {
      //   rxAction();
      // }
    } else {
      // rxAction();
    }
  };

  // Summary Item

  const SummaryItem = (props) => {
    return (
      <>
        <div className="col-sm-3">
          <span className="font-12 text--secondary">{props.title}</span>
        </div>
        {
            props?.isList === true ? 
            <div className="col-sm-9 font-12 text--terciary fw-sb">
                {
                    props?.listItems.map((item)=>{
                        return (
                            <div key={item?.imagingTest?.imageTest?.displayValue.concat("-",item.description)} className="col-sm-9 font-12 text--terciary">
                                {
                                item?.imagingTest?.leftRight === true
                                ? item?.imagingTest?.imageTest?.displayValue.concat(
                                    ": ",
                                    item?.imagingTest?.description,
                                    ", ",
                                    item?.left === true ? "Left":"Right"
                                )
                                : item?.imagingTest?.imageTest?.displayValue.concat(
                                    ": ",
                                    item?.imagingTest?.description
                                )
                            }</div>
                        )
                    })
                }
            </div>
            :
                <div
                    className={`col-sm-9 font-12 text--terciary  ${
                        props?.fasting === true ? "" : "fw-sb"
                    }`}
                >
                    {props.description}
                </div>
        }
      </>
    );
  };
  // List Item
  const ListItem = ({ list, handleDelete, type, hideAction }) => {
    return (
      <>
        {list?.length > 0
          ? list.map((item) => {
              return (
                <div className="col-6 col-sm-6" key={item?.description}>
                  <div className="d-flex flex-row justify-content-between font-12 text--terciary mt-2">
                    <div className="">
                      <span>
                        {type === "exam"
                          ? item?.description.concat(
                              " ",
                              item?.alias1?.length > 0
                                ? `(${item?.alias1})`
                                : ""
                            )
                          : item?.code + " - " + item?.description}
                      </span>
                    </div>
                    <div>
                      {hideAction !== true ? (
                        <img
                          src={TrashButtonIcon}
                          alt={"deleteIcon"}
                          id={
                            type === "exam" ? item?.labOrderTestId : item?.code
                          }
                          className="me-2 cursor--pointer"
                          style={{ width: "15px", height: "15px" }}
                          onClick={(e) => {
                            handleDelete(item);
                          }}
                        ></img>
                      ) : null}
                    </div>
                  </div>
                </div>
              );
            })
          : null}
      </>
    );
  };
  // Provider Patient Deatils
  const ProviderPatientDetails = () => {
    return (
      <>
        {/* Patient Details */}
        <div className="col-6">
          <h3 className="text--terciary font-14 fw-sb m-0">
            {__str.patient_details_str}
          </h3>
          {isPatientDataLoading ? (
            <Skeleton />
          ) : (
            <div className="text--terciary font-12  br-10 px-2">
              <div>
                <span className="text--terciary font-12 ">
                  {__str.name_str}: &nbsp;
                </span>
                <span>
                  {"".concat(
                    patientData?.name[0]?.firstName || "",
                    " ",
                    patientData?.name[0]?.lastName || ""
                  )}
                </span>
              </div>
              <div>
                <span>{__str.gender_str}: &nbsp;</span>
                <span className="">
                  {patientData !== undefined ? patientData?.gender : ""}
                </span>
              </div>
              <div>
                <span className="text--terciary font-12">
                  {__str.date_of_birth_str}: &nbsp;
                </span>
                <span>
                  {patientData !== undefined
                    ? getDMYformate(patientData?.dateOfBirth, "MMM DD, YYYY")
                    : ""}
                </span>
              </div>
              <div>
                <span className="text--terciary font-12">
                  {__str.telephone_str}: &nbsp;
                </span>
                <span>
                  {patientData !== undefined
                    ? formatPhoneNumber(
                        patientData?.communicationNumbers?.numbers?.find(
                          (numberItem) => {
                            return (
                              numberItem?.type === _numbString.cellPhoneExt
                            );
                          }
                        )?.number
                      )
                    : ""}
                </span>
              </div>
              <div>
                <span className="text--terciary font-12 ">
                  {__str.address_str}: &nbsp;
                </span>
                <span className="font-12 text--terciary ">
                  {/* 221B Bakers, Street, 25454, NYC */}
                  {patientData !== undefined && patientData?.address?.length > 0
                    ? "".concat(
                        patientData?.address[0]?.addressLine1?.length > 0
                          ? patientData?.address[0]?.addressLine1 + ", "
                          : "",
                        patientData?.address[0]?.addressLine2?.length > 0
                          ? patientData?.address[0]?.addressLine2 + ", "
                          : "",

                        patientData?.address[0]?.city?.length > 0
                          ? patientData?.address[0]?.city + ", "
                          : "",

                        patientData?.address[0]?.stateProvince || "",
                        " ",
                        patientData?.address[0]?.postalCode
                      )
                    : ""}
                </span>
              </div>
            </div>
          )}
        </div>

        {/* Requester Details */}
        <div className="col-6">
          <h3 className="text--terciary font-14 fw-sb m-0">
            {__str.requester_details_str}
          </h3>
          {isProviderDataLoading ? (
            <Skeleton />
          ) : (
            <div className="text--terciary font-12  br-10 px-2">
              <div>
                <span className="text--terciary font-12 ">
                  {__str.name_str}: &nbsp;
                </span>
                <span>
                  {"".concat(
                    providerData?.name[0]?.firstName || "",
                    " ",
                    providerData?.name[0]?.lastName || ""
                  )}
                </span>
              </div>
              <div>
                <span>{__str.physician_npi_str}: &nbsp;</span>
                <span>{providerData?.identification?.npi}</span>
              </div>
              <div>
                <span>{__str.organization_str}: &nbsp;</span>
                <span>
                  {providerData?.providerTenantPracticeLocation?.tenant
                    ?.businessName || ""}
                </span>
              </div>
              <div>
                <span>{__str.facility_str}: &nbsp;</span>
                <span>
                  {providerData?.providerTenantPracticeLocation?.facility
                    ?.businessName || ""}
                </span>
              </div>
              <div className="mb-1">
                <span>{__str.address_str}: &nbsp;</span>
                <span>
                  {providerData !== undefined
                    ? "".concat(
                        providerData?.providerTenantPracticeLocation?.facility
                          ?.addressLine1,
                        " ",
                        providerData?.providerTenantPracticeLocation?.facility
                          ?.addressLine2 || ""
                      )
                    : null}{" "}
                  {providerData !== undefined
                    ? "".concat(
                        providerData?.providerTenantPracticeLocation?.facility
                          ?.city || "",
                        ", ",
                        providerData?.providerTenantPracticeLocation?.facility
                          ?.state || "",
                        " ",
                        providerData?.providerTenantPracticeLocation?.facility
                          ?.zipCode || ""
                      )
                    : null}
                </span>
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  //ICD10 code handlers
  const handleICD10Search = (query) => {
    setIcd10Query(query);
    if (query.length > 1) {
      searchICD10Codes(query);
    } else {
      setIsICD10Loading(false);
      setIcd10Suggestions([]);
    }
  };
  const handleICD10SearchBtn = (query) => {
    handleICD10Search(query);
  };

  const handleICD10CodeList = (icd10Object) => {
    let icdlist = iCD10CodeList.filter((item) => {
      return item.code !== icd10Object.code;
    });
    setICD10CodeList([...icdlist, icd10Object]);
  };
  const handleDeleteICD10 = (icd10Object) => {
    let icdlist = iCD10CodeList.filter((item) => {
      return item.code !== icd10Object.code;
    });
    setICD10CodeList([...icdlist]);
  };

  //   Handle Imaging test search
  const addImagingOrder = (
    event,
    imagingOrderObject,
    isLeftRight,
    labelStr,
    setImagingOrderDataTemp,
    imagingOrderDataTemp,
    targetId
  ) => {
    event.preventDefault();
    // let checked = event
    // console.log("event.target.id: ", targetId);
    let ischecked = document.getElementById(targetId);
    // console.log("ischecked: ", ischecked);
    let imagingTestTemp = imagingOrderDataTemp?.imagingOrderTests ?? [];
    // console.log(imagingTestTemp,imagingOrderObject);
    if (ischecked.checked === false) {
      
      let result = imagingTestTemp.filter((item) => {
        return (
          item?.imagingTest?.description === imagingOrderObject?.description && item?.imagingTest?.imageTest?.internalValue === imagingOrderObject?.imageTest?.internalValue
        );
      });
      console.log("result,imagingOrderObject: ",result,imagingOrderObject,labelStr);
      let imagingObject = { imagingTest: imagingOrderObject };
      if(result?.length === 1 && isLeftRight === true && result[0]?.imagingTest?.description === imagingOrderObject?.description){
        if(result[0]?.left === true && labelStr === "Right"){
          // console.log("Yes Left True");
          setImagingOrderDataTemp({
            ...imagingOrderDataTemp,
            imagingOrderTests: [
              ...imagingOrderDataTemp?.imagingOrderTests,
              { ...imagingObject, right: true },
            ],
          });
        }else if(result[0]?.right === true && labelStr === "Left"){
          // console.log("Yes Right True");
          setImagingOrderDataTemp({
            ...imagingOrderDataTemp,
            imagingOrderTests: [
              ...imagingOrderDataTemp?.imagingOrderTests,
              { ...imagingObject, left: true },
            ],
          });
        }
      }
      if (result?.length === 0) {
        
        if (isLeftRight === true && labelStr === "Left") {
          console.log("I'm left");
          imagingObject = { ...imagingObject, left: true };
        } else if (isLeftRight === true && labelStr === "Right") {
          console.log("I'm Right");
          imagingObject = { ...imagingObject, right: true };
        }
        setImagingOrderDataTemp({
          ...imagingOrderDataTemp,
          imagingOrderTests: [
            ...imagingOrderDataTemp?.imagingOrderTests,
            { ...imagingObject },
          ],
        });
      }
    } else {
      console.log("imagingOrderObject: ",imagingOrderObject);

      const imagingTestTemp = imagingOrderDataTemp.imagingOrderTests ?? [];
      if (isLeftRight === true && labelStr === "Left") {
        console.log("I'm left");
        imagingOrderObject = { ...imagingOrderObject, left: true };
      } else if (isLeftRight === true && labelStr === "Right") {
        console.log("I'm Right");
        imagingOrderObject = { ...imagingOrderObject, right: true };
      }
      console.log("imagingTestTemp: ",imagingTestTemp);
      let result = []
      let restData = []
      if(isLeftRight === false){
        console.log("isleftright False");
        result = imagingTestTemp.filter((item) => {return item.imagingTest.description === imagingOrderObject?.description && item.imagingTest.imageTest?.internalValue !== imagingOrderObject?.imageTest?.internalValue});
        restData = imagingTestTemp.filter((item) => {return item.imagingTest.description !== imagingOrderObject?.description });
      }else{
        console.log("isleftright True");
        result = imagingTestTemp.filter((item) => {return item.imagingTest.description !== imagingOrderObject?.description && item.imagingTest.imageTest?.internalValue !== imagingOrderObject?.imageTest?.internalValue});
        restData = imagingTestTemp.filter((item) => {return item.imagingTest.description !== imagingOrderObject?.description  && item.imagingTest.imageTest?.internalValue === imagingOrderObject?.imageTest?.internalValue});
        let matchedDesCatOrg  = imagingTestTemp.filter((item) => {return item.imagingTest.description === imagingOrderObject?.description && item.imagingTest.imageTest?.internalValue !== imagingOrderObject?.imageTest?.internalValue});
        console.log("matchedDesCatOrg: ",matchedDesCatOrg);
        result = [...result,...matchedDesCatOrg]
      }
      console.log("result: ",result);
      const tempResult = imagingTestTemp.filter(item => item.imagingTest.description === imagingOrderObject?.description && item.imagingTest.imageTest?.internalValue === imagingOrderObject?.imageTest?.internalValue);
      console.log("tempResult: ",tempResult);
      console.log("imagingOrderObject: ",imagingOrderObject);
      if (tempResult?.length === 2 && isLeftRight === true) {
        const matchingItem = tempResult.find(item => (imagingOrderObject?.left && item.right) || (!imagingOrderObject?.left && item.left));
        const data = { ...imagingOrderData, imagingOrderTests: [...result, matchingItem,...restData] };
        // setImagingOrderData(data);
        setImagingOrderDataTemp(data);
      } else {
        const data = { ...imagingOrderData, imagingOrderTests: [...result,...restData] };
        // setImagingOrderData(data);
        setImagingOrderDataTemp(data);
      }
      
      
    }
    document.getElementById(targetId).checked =
      !document.getElementById(targetId).checked;
  };

  // Save Imaging Order
  const saveImagingOrder = async () => {
    setIsImagingOrderSending(true);
    const httpResponse = await sendHttpRequest({
      url: props?.editMode ? `/imaging-orders/${props?.imagingOrderData?.imagingOrderId}`:"/imaging-orders",
      method: props?.editMode ? "PUT":"POST",
      responseType: "arraybuffer",
      data: {
        prescriptionReference: {
          patient: { userId: patientData?.userId },
          provider: { userId: user?.userId },
          ...(props?.editMode && {prescriptionReferenceId:props?.imagingOrderData?.prescriptionReference?.prescriptionReferenceId})
        },
        additionalTest: additionalTest,
        diagnoses: iCD10CodeList,
        imagingOrderTests: [
            ...imagingOrderData?.imagingOrderTests
        ],
        ...(orderType === "fax" && { faxNumber: fax }),
      },
    });
    if(!httpResponse.error){
      if(orderType === "Print"){
        downloadPdf(httpResponse);
      }
      props?.setImagingSeverity("success")
      props?.setImagingMessage("Imaging order sent successfully")
      props?.setIsImagingOrderSent(true)
      props?.setIsImagingOrderDataSent(!props?.isImagingOrderDataSent)
      props?.handleModal();

    }else{
      props?.setImagingSeverity("error")
      props?.setImagingMessage("Something went wrong")
      props?.setIsImagingOrderSent(true)
    }
    setIsImagingOrderSending(false);
  };

  // fetch ICD10 Code data

  const fetchICD10CodeData = async (patientId)=>{
    setIsICD10Loading(true);
    const httpResponse = await sendHttpRequest({
      url:`/patients/${patientId}/diagnoses`,
      method:'Get',
      params: {
        status: true,
        unique: true
      },
    })
    if(!httpResponse.error){
      setICD10CodeData(httpResponse.data)
    }
    setIsICD10Loading(false);
  }  
  // console.log("imaging order data: ", imagingOrderDataTemp);

  const handleAdditionalTest = (additionalTest) => {
    setAdditionalTest(additionalTest);
  };
  const handleSummarySection = () => {
    setServicesRequestExpanded(false);
    setSummaryExpanded("summary");
  };
  const handleChange = (panel) => (event, newExpanded) => {
    if (panel === "patientRqtr") {
      setPatientReqtrDetailsExpanded(newExpanded ? panel : false);
    } else if (panel === "sampleDetails") {
      setServicesRequestExpanded(newExpanded ? panel : false);
    } else if (panel === "servicesRequest") {
      setServicesRequestExpanded(newExpanded ? panel : false);
    } else if (panel === "icd10") {
      setIcd10Expanded(newExpanded ? panel : false);
    } else if (panel === "summary") {
      setSummaryExpanded(newExpanded ? panel : false);
    }
  };
  const handleSampleDetailsSection = () => {
    setPatientReqtrDetailsExpanded(false);
    setServicesRequestExpanded("servicesRequest");
  };

  const ImagingListItem = ({
    category,
    imagingList,
    imagingOrderData,
    setImagingOrderData,
  }) => {
    const handleDelete = (itemObject) => {
      const imagingTestTemp = imagingOrderData.imagingOrderTests ?? [];
      console.log("imagingTestTemp: ",imagingTestTemp);
      let result = []
      let restData = []
      if(itemObject?.imagingTest?.leftRight === false){
        console.log("isleftright False");
        result = imagingTestTemp.filter((item) => {return item.imagingTest.description === itemObject?.imagingTest?.description && item.imagingTest.imageTest?.internalValue !== itemObject?.imagingTest?.imageTest?.internalValue});
        restData = imagingTestTemp.filter((item) => {return item.imagingTest.description !== itemObject?.imagingTest?.description });
      }else{
        console.log("isleftright True");
        result = imagingTestTemp.filter((item) => {return item.imagingTest.description !== itemObject?.imagingTest?.description && item.imagingTest.imageTest?.internalValue !== itemObject?.imagingTest?.imageTest?.internalValue});
        restData = imagingTestTemp.filter((item) => {return item.imagingTest.description !== itemObject?.imagingTest?.description  && item.imagingTest.imageTest?.internalValue === itemObject?.imagingTest?.imageTest?.internalValue});
        let matchedDesCatOrg  = imagingTestTemp.filter((item) => {return item.imagingTest.description === itemObject?.imagingTest?.description && item.imagingTest.imageTest?.internalValue !== itemObject?.imagingTest?.imageTest?.internalValue});
        console.log("matchedDesCatOrg: ",matchedDesCatOrg);
        result = [...result,...matchedDesCatOrg]
      }
      console.log("result: ",result);
      const tempResult = imagingTestTemp.filter(item => item.imagingTest.description === itemObject?.imagingTest?.description && item.imagingTest.imageTest?.internalValue === itemObject?.imagingTest?.imageTest?.internalValue);
      console.log("tempResult: ",tempResult);
      console.log("itemObject: ",itemObject);
      if (tempResult?.length === 2 && itemObject?.imagingTest?.leftRight === true) {
        const matchingItem = tempResult.find(item => (itemObject?.left && item.right) || (!itemObject?.left && item.left));
        const data = { ...imagingOrderData, imagingOrderTests: [...result, matchingItem,...restData] };
        setImagingOrderData(data);
        setImagingOrderDataTemp(data);
      } else {
        const data = { ...imagingOrderData, imagingOrderTests: [...result,...restData] };
        setImagingOrderData(data);
        setImagingOrderDataTemp(data);
      }
    };
    
    return (
      <>
        {imagingList.map((item) => {
            // console.log("Lis-t Item: ",item);
          return (
            <div className="d-flex flex-row justify-content-between font-12 text--terciary mt-2">
              <div key={item.description}>
                {
                item?.imagingTest?.leftRight === true
                ? item?.imagingTest?.imageTest?.displayValue.concat(
                    ": ",
                    item?.imagingTest?.description,
                    ", ",
                    item?.left === true ? "Left":"Right"
                  )
                : item?.imagingTest?.imageTest?.displayValue.concat(
                    ": ",
                    item?.imagingTest?.description
                  )
              }</div>
              <img
                src={TrashButtonIcon}
                alt={"deleteIcon"}
                id={item?.imagingTest?.description}
                className="me-2 cursor--pointer"
                style={{ width: "15px", height: "15px" }}
                onClick={(e) => {
                  handleDelete(item);
                }}
              ></img>
            </div>
          );
        })}
      </>
    );
  };

  const changeOrderType = (event) => {
    setOrderType(event.target.id);
  };
  const faxHandler = (faxValue) => {
    // let faxNumber = formatPhoneNumber(faxValue?.replace("+1", ""));
    let faxNumber = faxValue?.replace("+1", "");
    faxValue = faxValue?.replace("undefined", "");
    if (typeof faxValue !== "string") {
      return;
    }
    setFax(faxNumber);
    if (
      faxValue !== undefined &&
      isValidPhoneNumber(faxValue) &&
      faxValue.length === 12
    ) {
      setFaxErrors((previousSnapshot) => ({
        ...previousSnapshot,
        error: false,
      }));
    } else if (
      faxValue === undefined ||
      faxValue.length === 3 ||
      faxValue === "+1null"
    ) {
      setFaxErrors((previousSnapshot) => ({
        ...previousSnapshot,
        error: false,
      }));
    } else if (
      faxValue !== undefined &&
      !isValidPhoneNumber(faxValue) &&
      faxValue.length > 2 &&
      !faxValue.includes("null") &&
      !faxValue.includes("undefined")
    ) {
      setFaxErrors((previousSnapshot) => ({
        ...previousSnapshot,
        error: true,
      }));
    }
  };
  const handleConsent = (event) => {
    setImagingOrderConsent(event.target.checked);
  };

  useEffect(() => {
    fetchPatientData();
    fetchProviderData();
  }, []);

  return (
    <>
      <Modal
        show={props.show}
        contentClassName="modal-border modal-shadow h-100 bg--background"
        backdropClassName="modal-backdrop"
        dialogClassName={`${classes["modal__width"]}`}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header className="modal-header">
          <span
            style={{
              lineHeight: "12px",
              width: "18px",
              fontSize: "8pt",
              color: "#336383",
              position: "absolute",
              top: 15,
              right: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              props.handleModal();
            }}
          >
            {<CloseIcon />}
          </span>
          <Modal.Title className="font-20 fw-sb text--terciary">
            {props?.editMode !== undefined
              ? "Edit Imaging Order"
              : "New Imaging Order"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <>
              {/* Patient and Provider Details */}
              <div className="row justify-content-center">
                <div className="col-8">
                  <Accordion
                    expanded={patientReqtrDetailsExpanded === "patientRqtr"}
                    onChange={handleChange("patientRqtr")}
                    defaultExpanded={true}
                    // className="mt-4"
                  >
                    <AccordionSummary
                      aria-controls="panelpatientrequester-content"
                      id="panelpatientrequester-header"
                    >
                      <h2 className="font-18 fw-sb m-0">
                        {__str.patient_details_str.concat(
                          " & ",
                          __str.requester_details_str
                        )}
                      </h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="row border--default br-10 me-1 ms-1">
                        <ProviderPatientDetails />
                      </div>
                      <div className="col-12 text-end mt-2">
                        <button
                          className="btn d9e8ec--button "
                          onClick={() => handleSampleDetailsSection()}
                        >
                          {"Next"}
                        </button>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
              {/* Services Requested  */}
              <div className="row justify-content-center">
                <div className="col-8">
                  <Accordion
                    expanded={servicesRequestExpanded === "servicesRequest"}
                    onChange={handleChange("servicesRequest")}
                    defaultExpanded={false}
                    // className="mt-4"
                  >
                    <AccordionSummary
                      aria-controls="servicesRequest-content"
                      id="panelservicesRequest-header"
                    >
                      <h2 className="font-18 fw-sb m-0">
                        {__str.service_requested_str}
                      </h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* Service Requested Section */}
                      <div className="row">
                        <div className="col-6 mt-3">
                          <SearchImagingTest
                            category={"ImagingOrder"}
                            key={"ImagingOrder"}
                            imagingOrderDataTemp={imagingOrderDataTemp}
                            setImagingOrderDataTemp={setImagingOrderDataTemp}
                            addImagingOrder={addImagingOrder}
                            setImagingOrderData={setImagingOrderData}
                            imagingOrderData={imagingOrderData}
                          />
                          {
                            imagingOrderData && 
                            <ImagingListItem
                                category={"ImagingOrderItem"}
                                imagingList={imagingOrderData?.imagingOrderTests ?? []}
                                imagingOrderData={imagingOrderData}
                                setImagingOrderData={setImagingOrderData}
                                setImagingOrderDataTemp = {setImagingOrderDataTemp}
                            />
                          }
                        </div>

                        {/* Additional Test */}
                        <div className="col-12 mt-3">
                          <div className="d-flex flex-row justify-content-between">
                            <span className="font-14 fw-sb">
                              {__str.additional_Tests_str}
                            </span>
                            <div className=" font-10 text--secondary align-self-baseline p-0 my-auto">
                            {additionalTest?.length + "/250"}
                          </div>
                          </div>
                            <TextArea
                              className="form-control my-2 input br-10 shadow-none border--default "
                              rows={2}
                              gettext={handleAdditionalTest}
                              defaultValue={additionalTest}
                              maxLength = {250}
                            />
                        </div>
                      </div>
                      {/* ICD10 Code section */}
                      <div className="row mt-3">
                        
                        <div className=" col-sm-12 col-12">
                          <h2 className="font-14 fw-sb">{__str.icd10_codes_str}</h2>
                        </div>
                        {
                          isICD10Loading ? <Skeleton/>:
                          iCD10CodeData?.length > 0 ? 
                          <ICD10CodeListItems
                            icd10List = {iCD10CodeData}
                            iCD10CodeList = {iCD10CodeList}
                            setICD10CodeList = {setICD10CodeList}
                          />:<span className="font-12 text--terciary">{"No data available"}</span>
                        }

                        <div className="col-12 text-end mt-2">
                          <button
                            className="btn d9e8ec--button "
                            onClick={() => handleSummarySection()}
                          >
                            {"Next"}
                          </button>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>

              {/* Summary */}
              <div className="row justify-content-center">
                <div className="col-8">
                  <Accordion
                    expanded={summaryExpanded === "summary"}
                    onChange={handleChange("summary")}
                    defaultExpanded={false}
                  >
                    <AccordionSummary
                      aria-controls="panelSummaryDetails-content"
                      id="panelSummaryDetails-header"
                    >
                      <h2 className="font-18 fw-sb">{__str.summary_str}</h2>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="row">
                        <ProviderPatientDetails />
                        <div className="horizontal-rule my-2" />
                        {
                            imagingOrderData?.imagingOrderTests?.length > 0 ? 
                                <SummaryItem
                                    title={__str.imaging_order_str}
                                    isList = {true}
                                    listItems = {imagingOrderData?.imagingOrderTests ?? []}

                                />
                            :null
                        }
                        {additionalTest?.length > 0 ? (
                          <SummaryItem
                            title={__str.additional_Tests_str}
                            description={additionalTest}
                          />
                        ) : null}

                        {/* ICD10 Code section */}
                        <div className="horizontal-rule my-2" />
                        <SummaryItem
                          title={__str.icd10_codes_str}
                          description={
                            <ListItem
                              list={iCD10CodeList}
                              handleDelete={handleDeleteICD10}
                              type={"icd10"}
                              hideAction={true}
                            />
                          }
                        />

                        {/* Signature section */}
                        <div className="horizontal-rule my-2" />
                        <div
                          className="col-12 mt-3"
                          hidden={providerData?.providerSetting?.signature !== undefined}
                        >
                          <span className="font-12 text--secondary">
                            {__str.signature_str}
                          </span>
                        </div>
                        <div
                          className="col-8"
                          hidden={providerData?.providerSetting?.signature !== undefined }
                        >
                          <div style={{ height: "180px" }} className="row ">
                            <div className="h-100 d-inline-block mt-1 bg--white border--default br-10 col-sm ms-2">
                              <div className="text-center align-content-center">
                                <ReactSignatureCanvas
                                  penColor="green"
                                  ref={(ref) => {
                                    setSigPad(ref);
                                  }}
                                  onBegin={() => {
                                    // onDirty();
                                  }}
                                  onEnd={() => {
                                    convertToImage();
                                  }}
                                  canvasProps={{
                                    width: 485,
                                    height: 150,
                                    className: "sigCanvas",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          {/* <CustomDatePicker date={new Date()} min={new Date()} /> */}
                        </div>
                        <div className="row">
                          <div className="col-sm-3 mt-2">
                            <span className="font-12 text--secondary">
                              {"Order Type"}
                            </span>
                          </div>
                          <div className="col-sm-9 mt-2">
                          <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="orderType"
                                id={"print"}
                                value={"print"}
                                onChange={changeOrderType}
                                checked={
                                  orderType?.toLowerCase() ===
                                  __str?.print_lab_order?.toLowerCase()
                                }
                              />
                              <label
                                className="form-check-label font-12"
                                htmlFor={"print"}
                              >
                                {__str.print_lab_order}
                              </label>
                            </div>
                            <div className="form-check form-check-inline ms-4">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="orderType"
                                id="fax"
                                value="fax"
                                onChange={changeOrderType}
                                checked={
                                  orderType?.toLowerCase() ===
                                  __str?.fax_lab_order?.toLowerCase()
                                }
                              />
                              <label
                                className="form-check-label font-12"
                                htmlFor="fax"
                              >
                                {__str.fax_lab_order}
                              </label>
                            </div>
                            {orderType ===
                            __str.fax_lab_order?.toLowerCase() ? (
                              <div className="form-check form-check-inline font-12 text--terciary  p-0">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "start",
                                  }}
                                >
                                  <PhoneNumberInput
                                    onChange={faxHandler}
                                    id="fax"
                                    value={fax}
                                  />
                                  <span className="text--danger d-block font-10 mt-1">
                                    {faxErrors?.error === true
                                      ? "Incorrect number format"
                                      : " "}
                                  </span>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className=" horizontal-rule my-2">
                          <div className="row g-0">
                            <div className="col-8 ">
                              <div className="form-check form-check-inline mt-3">
                                <input
                                  className="form-check-input "
                                  type="checkbox"
                                //   name="orderType"
                                  id={"consent"}
                                    onChange={(e) => {
                                      handleConsent(e);
                                    }}
                                  // checked={orderType === _str?.ePrescription}
                                />
                                <label
                                  className="form-check-label font-12 align-self-center"
                                  for={"consent"}
                                >
                                  {__str.imaging_consent_str}
                                </label>
                              </div>
                            </div>
                            <div className="col-4 text-end mt-3">
                              <button
                                className="blue_task_button"
                                disabled = {isImagingOrderSending ? true: imagingOrderConsent === false ? true
                                    :imagingOrderData?.imagingOrderTests?.length === 0 ? true
                                    :false
                                }
                                onClick={() => {
                                    saveSignature();
                                  saveImagingOrder();
                                }}
                              >
                                {props?.editMode ? "Update":"Submit"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>

              {/* {console.log("patientId: ", patientId)} */}
              {/* {console.log("Sample types: ",samplesTypes)} */}
              {/* {console.log("sampleTime: ",sampleTime)} */}
              {/* {console.log("suggestions", suggestions)} */}
              {/* {console.log("icd10  suggestions ", icd10Suggestions)} */}
              {/* {console.log("examTestQuery: ",examTestQuery)} */}
              {/* {console.log("examinationTestList: ", examinationTestList)} */}
              {/* {console.log("examinationTempList: ", examinationTempList)} */}
              {/* {console.log("props?.editLabOrderData", props?.editLabOrderData)} */}
              {/* {console.log("samplesTypes: ",samplesTypes)} */}
              {/* {console.log("props?.editLabOrderData?.fasting: ", props?.editLabOrderData?.fasting)} */}
              {/* {console.log("isfasting: ", isFasting)} */}
              {/* {console.log("iCD10CodeList: ",iCD10CodeList)} */}
              {console.log("imagingOrderDataTemp: ",imagingOrderDataTemp)}
            </>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ImagingOrderModal;

import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
// import { theme, useStyle } from './Layout.module';
// import { makeStyles } from '@mui/styles';

const theme = createTheme(
    // {
    //     palette: { primary: { main: "#D9E8EC" }, secondary: { main: "#323232" } },
    // }
);

function Layout(props) {
    const { children } = props;
    // const classes = useStyles(props);
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className='vh-100'>
                {children}
            </div>
        </ThemeProvider>
    )
}

export default Layout
import React from 'react'
import options from '../../utils/data';
const StateLicenseForm = ({ setStateLicense, stateLicense, stateLicenses, identification }) => {
    return (
        <>
            <div className="col-12 col-md-12 mt-2 pb-2 ">
                <div className='row'>
                    <div className="col-12 col-sm-6">
                        <h3 className="text--terciary font-14 fw-sb m-0">State License</h3>
                        <select
                            className={`input form-select font-12 text--terciary border--default shadow-none br-10 text-wrap`}
                            onChange={e => { console.log(e.target.value); setStateLicense(e.target.value) }}
                            value={stateLicense}
                        >
                            <option value="" disabled> </option>
                            {stateLicenses?.map((state, index) => {
                                if (state?.stateLicense !== null) {
                                    let stateName = [options.find((s) => s.code === state?.state)].length === 1 ? [options.find((s) => s.code === state?.state)][0].name : "";
                                    return <option value={`${state.stateLicense}-${state.state}`} key={state.state?.concat(index)}>{`${stateName} - ${state.stateLicense}`}</option>
                                }
                            })}
                        </select>
                    </div>
                </div>

            </div>

        </>
    )
}

export default StateLicenseForm
import { useState } from "react";
import TrashButtonIcon from "../../../assets/icons/Icon-Trash.svg";
import TrashIcon from "../../../assets/icons/TrashIcon";
import CustomModal from "../CustomModal";
import AppointmentDeleteModal from "../Modals/ProviderModals/AppointmentDeleteModal";

const ScheduleSlotRow = (props) => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const joined = [year, month, day].join("-");
  const [deleteItemRef, setDeleteItemRef] = useState();
  const [deleteItemDay, setDeleteItemDay] = useState();
  const [openModal, setOpenModal] = useState(false);

  const handleModal = () => {
    setOpenModal(false);
  };

  const deleteSlotItem = (event) => {
    setDeleteItemRef(event.target.id);
    setDeleteItemDay(event.target.getAttribute("data-day"));
    setOpenModal(true);
  };

  return (
    <>
      <AppointmentDeleteModal
        show={openModal}
        handleModal={handleModal}
        deleteItemRef={deleteItemRef}
        deleteItemDay={deleteItemDay}
        handleDelete={props.handleDelete}
      />
      <div className="d-flex flex-row pt-2 pe-0 ps-0">
        <div className="d-flex flex-row">
          <div className="ms-2">
            <input
              type="time"
              name="startTime"
              data-format="hh:mm"
              data-day={props?.day}
              onInvalid={(e) => { e.target.setCustomValidity('Please enter a valid time') }}
              onInput={(e) => { e.target.setCustomValidity('') }}
              id={props.slotObject.slot}
              defaultValue={
                props.slotObject.startTime !== undefined &&
                props.slotObject.startTime
              }
              style={{
                color: "#004FD4",
                background: "#F2F7F8 0% 0% no-repeat padding-box",
                fontWeight: "600",
                borderRadius: "10px",
                fontFamily: "Montserrat",
                textTransform: "capitalize",
                border: "1px solid #D9E8EC",
                height: "40px",
                width: "76px",
              }}
              className="font-13 p-1"
              onChange={(e) => props.handleStartTimeChange(e)}
            />
          </div>
          <div className="ms-1 align-self-center" style={{marginRight:-4}}>
            <span className="font-12 text--terciary">to</span>
          </div>
          <div className="ms-2">
            <input
              type="time"
              name="endTime"
              data-format="hh:mm"
              data-day={props?.day}
              onInvalid={(e) => { e.target.setCustomValidity('Please enter a valid time') }}
              onInput={(e) => { e.target.setCustomValidity('') }}
              id={props.id}
              defaultValue={
                props.slotObject.endTime !== undefined &&
                props.slotObject.endTime
              }
              style={{
                color: "#004FD4",
                background: "#F2F7F8 0% 0% no-repeat padding-box",
                fontWeight: "600",
                borderRadius: "10px",
                fontFamily: "Montserrat",
                textTransform: "capitalize",
                border: "1px solid #D9E8EC",
                height: "40px",
                width: "76px",
              }}
              className="font-13 p-1"
              onChange={(e) => props.handleEndTimeChange(e)}
            />
          </div>
        </div>
        <div
          className="d-flex flex-row align-self-center"
          style={{
            width: "14px",
            height: "16px",
            marginLeft: "7px",
            marginRight: "7px",
          }}
        >
          <TrashIcon id={props.slotObject.slot} day={props?.day} style={{ color: "red", cursor: "pointer" }} onClick={(e) => deleteSlotItem(e)} />
          {/* <img
            src={TrashButtonIcon}
            alt={"deleteIcon"}
            style={{ cursor: "pointer" }}
            id={props.slotObject.slot}
          ></img> */}
        </div>
      </div>
      {/* {console.log("props.slotObject", props.slotObject)} */}
    </>
  );
};

export default ScheduleSlotRow;

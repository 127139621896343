import { useEffect, useRef } from "react"

export const useUnsavedWorkPrompt = (message = 'You have unsaved changes.') => {
  const isDirtyRef = useRef(false);
  const onDirty = () => {
    isDirtyRef.current = true;
  }
  const onPristine = () => {
    isDirtyRef.current = false;
  }
  const isDirty = () => {
    return isDirtyRef.current;
  }

  useEffect(() => {
    return () => {
      isDirtyRef.current = false;
    }
  }, [])


  return [isDirty, onDirty, onPristine];
}
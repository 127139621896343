import { Modal } from "react-bootstrap";
import classes from './ClaimReferringPhysicianModal.module.css';
import CloseIcon from "@mui/icons-material/Close";
import sendHttpRequest from "../../../../../components/hooks/sendHttpRequest";
import { useEffect, useState } from "react";
import PhoneInputNew from "../../../../../components/UI/Reusable/PhoneInputNew";
import { unformatPhoneNumber } from "../../../../../utils/helpers/HelperFunctions";
import CalenderDatePicker from "../../../../../components/UI/General/CalenderDatePicker";
import { DateTime } from "luxon";
import TextArea from "../../../../../components/forms/TextArea";

const ClaimReferringPhysicianModal = (props) => {

    const { handleReferringPhysicianData, tenantId, handlePhysicianPhone, setRegexErrors, regexErrors, referringPhysician, referringPhysiciansList, showReferringPhysicianFields, setShowReferringPhysicianFields, setReferringPhysician } = props;
    const [showError, setShowError] = useState(false);
    const [isValidating, setIsValidating] = useState(false);
    const [phone, setPhone] = useState("");
    const [disabled, setDisabled] = useState(false);

    function validateReferringPhysicianData() {
        const { phone, firstName, lastName, referralCode, referralDate, npi } = referringPhysician;

        if (regexErrors?.primary === false && firstName?.length > 0 && lastName?.length > 0 && referralCode?.length > 0 && referralDate?.length > 0 && typeof referralDate == "string" && npi?.length == 10) {
            setDisabled(false);
            return false;
        }
        else {
            setDisabled(true);
            return true;
        }
    }

    function handlePhone(phoneValue) {
        setPhone(phoneValue);
        handlePhysicianPhone(phoneValue);
    }


    async function npiCheck(tenantId) {

        setIsValidating(true);
        const httpResponse = await sendHttpRequest({
            url: `/tenants/${tenantId}/referring-physicians/validate-npi`,
            method: 'POST',
            data: {
                npi: referringPhysician?.npi,
                firstName: referringPhysician?.firstName,
                lastName: referringPhysician?.lastName
            }
        })

        setIsValidating(false);
        if (httpResponse?.status === 200) {
            setShowError(false);

            setShowReferringPhysicianFields(false);
            if (props?.patientProfile !== undefined && props?.patientProfile == true) {
                props?.setEncounterDto((dto) => ({ ...dto, "referringPhysician": referringPhysician, "referralCode": referringPhysician?.referralCode, "referralDate": `${referringPhysician?.referralDate}T00:00:00` }));
            }
            props?.handleModalClose();
        }
        else if (httpResponse?.status === 400) {

            setShowError(true);
        }
    }

    useEffect(() => {
        if(referringPhysician !== undefined && referringPhysician !== null && typeof referringPhysician === "object"){
            validateReferringPhysicianData();
        }
    }, [referringPhysician])


    return (
        <Modal
            show={props.show}
            onHide={() => {
                props.handleModalClose();
                setReferringPhysician({});

            }}
            size="md"
            contentClassName={`modal-border modal-shadow ${classes['modal__border']} `}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdropClassName={`modal-backdrop ${classes['modal__backdrop']}`}
            backdrop="static"
        >
            <Modal.Header style={{ border: "none" }}>
                <div className="row ">
                    <div className="col-12 ">
                        <span
                            style={{
                                lineHeight: "12px",
                                width: "18px",
                                fontSize: "8pt",
                                color: "#336383",
                                position: "absolute",
                                top: 15,
                                right: 15,
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                props.handleModalClose();
                                setReferringPhysician({});
                            }}
                        >
                            {<CloseIcon />}
                        </span>
                        <Modal.Title className="font-20 fw-sb text--terciary">
                            Add Referring Physician
                        </Modal.Title>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="container-fluid">

                </div>
                <div className="container">


                    {referringPhysiciansList?.length > 0 ? <div className="row mt-2">

                        <div className="col-sm-12 col-12">
                            <div className="form-group">
                                <select
                                    className={`border--default br-10 col-sm-12 form-select cursor--pointer font-14 shadow-none ${classes["custom-select"]}`}
                                    aria-label="Referring Physician select"
                                    name="referringPhysicianDropdown"
                                    // required={showReferringPhysicianFields}
                                    onChange={(e) => {
                                        handleReferringPhysicianData(e)
                                        const phone = referringPhysiciansList?.find(rp => rp.referringPhysicianId == e.target.value)?.phone;
                                        handlePhone("+1" + unformatPhoneNumber(phone));

                                    }}
                                >
                                    <option selected disabled>Select Referring Physician</option>
                                    {referringPhysiciansList?.map((referringPhysician) => {
                                        const fullName = referringPhysician?.middleName?.length > 0 ? `${referringPhysician?.firstName} ${referringPhysician?.middleName} ${referringPhysician?.lastName}` : `${referringPhysician?.firstName} ${referringPhysician?.lastName}`
                                        return <option value={referringPhysician?.referringPhysicianId}>
                                            {fullName}
                                        </option>
                                    })}
                                </select>
                            </div>
                        </div>

                    </div> : null}
                    <div className="row mt-2">
                        <div className="col-12 col-sm-4 pe-0">
                            <label
                                htmlFor={"firstName"}
                                className="form-label m-0 font-12 text--terciary fw-sb"
                            >
                                First Name<span>&nbsp;&#42;</span>
                            </label>
                            <input
                                type="text"
                                name="firstName"
                                id={"firstName"}
                                value={referringPhysician?.firstName ?? ''}
                                className={`form-control font-12 m-0 border--default br-10 shadow-none input`}
                                onChange={handleReferringPhysicianData}
                                required={showReferringPhysicianFields}
                                maxLength={35}
                            />
                        </div>
                        <div className="col-12 col-sm-4 pe-0">
                            <label
                                htmlFor={"middleName"}
                                className="form-label m-0 font-12 text--terciary fw-sb"
                            >
                                Middle Name
                            </label>
                            <input
                                type="text"
                                name="middleName"
                                id={"middleName"}
                                value={referringPhysician?.middleName ?? ''}
                                className={`form-control font-12 m-0 border--default br-10 shadow-none input`}
                                maxLength={35}
                                onChange={handleReferringPhysicianData}
                            />
                        </div>

                        <div className="col-12 col-sm-4 pe-0">
                            <label
                                htmlFor={"lastName"}
                                className="form-label m-0 font-12 text--terciary fw-sb"
                            >
                                Last Name <span>&nbsp;&#42;</span>
                            </label>
                            <input
                                type="text"
                                name="lastName"
                                id={"lastName"}
                                className={`form-control font-12 m-0 border--default br-10 shadow-none input`}
                                onChange={handleReferringPhysicianData}
                                required={showReferringPhysicianFields}
                                maxLength={35}
                                value={referringPhysician?.lastName ?? ''}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12 col-sm-4 pe-0">
                            <label
                                htmlFor={"npi"}
                                className="form-label m-0 font-12 text--terciary fw-sb"
                            >
                                Physician NPI<span>&nbsp;&#42;</span>
                            </label>
                            <input
                                type="text"
                                name="npi"
                                id={"npi"}
                                value={referringPhysician?.npi ?? ''}
                                className={`form-control font-12 m-0 border--default br-10 shadow-none input`}
                                onChange={handleReferringPhysicianData}
                                required={showReferringPhysicianFields}
                                maxLength={10}
                            />
                        </div>
                        <div className="col-12 col-sm-4 pe-0">
                            <label
                                htmlFor="physicianPhone"
                                className="form-label m-0 font-12 text--terciary fw-sb"
                            >
                                Phone<span>&nbsp;&#42;</span>
                            </label>

                            <PhoneInputNew
                                name="physicianPhone"
                                fontClass="font-12"
                                // value={''}
                                value={phone}
                                // value={cellPhone}
                                onChange={handlePhone}
                                id={"physicianPhone"}
                                required={showReferringPhysicianFields}
                            />
                            <span className="text--danger py-1 font-10 d-block">
                                {regexErrors?.primary === true ? "Incorrect number format" : null}
                            </span>
                        </div>
                        <div className="col-12 col-sm-4 pe-0">
                            <label
                                htmlFor="referralCode"
                                className="form-label m-0 font-12 text--terciary fw-sb"
                            >
                                Referral Code<span>&nbsp;&#42;</span>
                            </label>
                            <input
                                type="text"
                                name="referralCode"
                                id={"referralCode"}
                                className={`form-control font-12 m-0 border--default br-10 shadow-none input`}
                                onChange={handleReferringPhysicianData}
                                required={showReferringPhysicianFields}
                                maxLength={20}

                            />
                        </div>

                        <div className="col-12 col-sm-4 pe-0">
                            <label
                                htmlFor="referralCode"
                                className="form-label m-0 font-12 text--terciary fw-sb"
                            >
                                Referral Date<span>&nbsp;&#42;</span>
                            </label>
                            <CalenderDatePicker
                                name="referralDate"
                                className="font-12 simple-placeholder"
                                label="Referral Date"
                                required={true}
                                f12={true}
                                // {...(props?.encounterDto?.encounterMetadata?.dateOfServiceTo) && { date: props?.encounterDto?.encounterMetadata?.dateOfServiceTo }}
                                dobHandler={handleReferringPhysicianData}
                                disableFuture={true}
                                maxDate={DateTime.now()}
                            />

                        </div>

                        <div className="col-12 col-sm-12 pe-0 mt-1">
                            <label
                                htmlFor="referralNote"
                                className="form-label m-0 font-12 text--terciary fw-sb"
                            >
                                Referral Note
                            </label>
                            <textarea
                                className="form-control br-10 input font-14 border--default shadow-none"
                                name="referralNote"
                                id="referralNote"
                                onChange={handleReferringPhysicianData}
                                rows={3}
                                maxLength={100}
                            />

                        </div>
                    </div>

                    {showError ? <div className="row mt-2 justify-content-center">
                        <div className="col-sm-12">
                            <div className="alert font-12 alert-danger">
                                Invalid NPI or physician data. Please verify the details and resubmit.
                            </div>
                        </div>
                    </div> : null}

                    <div className="row mt-2">
                        <div className="col-12 pe-0 text-end">
                            <button
                                disabled={isValidating || disabled}
                                type="button"
                                onClick={e => {
                                    e.preventDefault();
                                    // validateReferringPhysicianData();
                                    npiCheck(tenantId)
                                }}
                                className="btn   br-10 btn blue_task_button px-5 font-12"

                            >

                                {isValidating ? "Validating..." : "Add"}
                            </button>
                        </div>
                    </div>




                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ClaimReferringPhysicianModal;

import * as React from "react";

import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useSelector } from "react-redux";
import { PRACTICE_MANAGEMENT } from "../../utils/helpers/subscriptionsConstants";


export default function PUCreateDropdown(props) {
  const { subscriptionPrivilegeGroups: subscriptionPrivilegeGroups } = useSelector((state) => state.subscriptionPrivilegeGroups);
  const navigate = useNavigate();
  const { user } = useAuth();
  const handleManualAdd = () => {
    navigate(props?.link);
  };

  const handleUserAddViaSheet = () => {
    props?.handleModal();
  };

  return (
    <div hidden={subscriptionPrivilegeGroups[PRACTICE_MANAGEMENT]?.apu?.active === false ? true : false}>
      <Dropdown>
        <Dropdown.Toggle
          //  id="dropdown-basic"
          id="demo-customized-button"
          style={{
            border: "#004FD4",
            color: "white",
            background: "#004FD4",
            fontSize: "12px",
            fontWeight: "600",
            borderRadius: "10px",
            height: "35px",
            width: "152px",
            fontFamily: "Montserrat",
            textTransform: "capitalize",
            borderBottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          className="shadow-none"
          title="Add User"
        >
          {props?.title}
        </Dropdown.Toggle>

        <Dropdown.Menu
          style={{
            background: "#fff",
            textColor: "#004FD4",
            borderRadius: "10px",
            boxShadow: "0px 13px 26px #0000000F",
            borderColor: "#D9E8EC",
            minWidth: "152px",
          }}
          className="font-12 addbutton"
        >
          {
            subscriptionPrivilegeGroups[PRACTICE_MANAGEMENT]?.apu?.active === true ? (
              <Dropdown.Item
                style={{
                  color: "#323232",
                  fontWeight: "400",
                }}
                className="font-12 addbutton"
                onClick={handleUserAddViaSheet}
              >
                {props?.addViaSheet}
              </Dropdown.Item>
            ) : null
          }
          <Dropdown.Item
            style={{
              color: "#323232",
              fontWeight: "400",
            }}
            className="font-12 addbutton"
            onClick={handleManualAdd}
          >
            Add manually
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { fetchPatientSocialHistoryUrl, fetchSocialHistoryQuestionsUrl } from "../../../res/endpointurls";
import sendHttpRequest from "../../hooks/sendHttpRequest";

const PatientSocialHistoryView = (props) => {
  const [socialHistory, setSocialHistory] = useState("");
  const [socialHistoryQuestions, setSocialHistoryQuestions] = useState("");
  const [isSocialHistoryLoading, setIsSocialHistoryLoading] = useState(false);
  const [isAnswered, setIsAnswered] = useState(false);

  const fetchSocialHistory = async (patientId) => {
    setIsSocialHistoryLoading(true);
    const httpResponse = await sendHttpRequest({
      url: fetchPatientSocialHistoryUrl(patientId),
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log("Social History", httpResponse.data);
      if (httpResponse.data.length > 0) {
        let arr = httpResponse.data;
        arr = arr.filter(element => element?.answer?.length > 0);
        arr.sort((a, b) => a?.question?.sequence - b?.question?.sequence);
        setSocialHistory(arr);
        setIsAnswered(true);
      } else {
        setIsAnswered(false);
      }
    } else {
      console.log(httpResponse.error);
    }
    setIsSocialHistoryLoading(false);
  };

  const fetchSocialHistoryQuestions = async () => {
    setSocialHistoryQuestions([]);
    const httpResponse = await sendHttpRequest({
      url: fetchSocialHistoryQuestionsUrl(0),
      method: "GET",
    });
    if (!httpResponse.error) {
      // console.log("Social History questions", httpResponse.data);
      setSocialHistoryQuestions(httpResponse.data);
    } else {
      console.log("Social History Error", httpResponse.error);
    }
  };

  useEffect(() => {
    fetchSocialHistory(props?.patientId);
    fetchSocialHistoryQuestions();

    return () => {
      setSocialHistory(null);
    };
  }, [props?.patientId]);

  return (
    <>
      <div
        className="d-flex mb-1 justify-content-between align-items-baseline"
        {...props}
      >
        <div className="d-inline-flex flex-column align-self-center">
          <h4 className="h18">Social History</h4>
          {/* <p style={{ color: 'var(--secondary)' }}>Lorem Ipsum dolor asit</p> */}
        </div>
      </div>
      <div className="row">
        {isSocialHistoryLoading ? (
          <Skeleton />
        ) : (
          socialHistory !== undefined &&
            socialHistory?.length > 0 ? (
            <>
              <div className="col-6">
                {socialHistory.map((questionItem, index) => {
                  if (index < 3) {
                    if (
                      questionItem.question.question?.toLowerCase() ===
                      "recreational drugs"
                    ) {
                      return (
                        <div
                          className="mt-3"
                          key={questionItem?.question?.question + index}
                        >
                          <label className="font-14 fw-sb">
                            {questionItem?.question?.question}
                          </label>
                          <div className="font-12">
                            {questionItem?.answerStrings !== undefined &&
                              questionItem?.answerStrings?.length > 0 ? (
                              questionItem?.answerStrings?.map(
                                (drugItem, index) => {
                                  if (
                                    index !==
                                    questionItem?.answerStrings?.length - 1
                                  ) {
                                    return drugItem + ", ";
                                  } else {
                                    return drugItem;
                                  }
                                }
                              )
                            ) : (
                              <p>&nbsp;</p>
                            )}
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className="mt-3"
                          key={questionItem?.question?.question + index}
                        >
                          <label className="font-14 fw-sb">
                            {questionItem?.question?.question}
                          </label>
                          <div className="font-12">
                            {questionItem?.answerStrings !== undefined &&
                              questionItem?.answerStrings?.length > 0 ? (
                              questionItem?.answerStrings
                            ) : (
                              <p>&nbsp;</p>
                            )}
                          </div>
                        </div>
                      );
                    }
                  }
                })}
              </div>
              <div className="col-6">
                {socialHistory.map((questionItem, index) => {
                  if (index >= 3) {
                    return (
                      <div
                        className="mt-3"
                        key={questionItem?.question?.question + index}
                      >
                        <label className="font-14 fw-sb">
                          {questionItem?.question?.question}
                        </label>
                        <div className="font-12">
                          {questionItem?.answerStrings !== undefined &&
                            questionItem?.answerStrings?.length > 0 ? (
                            questionItem?.answerStrings
                          ) : (
                            <p>&nbsp; </p>
                          )}
                          {/* <p>&nbsp; anbsdokdm</p> */}
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </>
          ) : (
            socialHistoryQuestions !== undefined &&
              socialHistoryQuestions?.length > 0 ?
              <>
                <div className="col-6">
                  {socialHistoryQuestions.map((questionItem, index) => {
                    if (index < 3 && questionItem !== null) {
                      if (questionItem?.question?.toLowerCase() === "recreational drugs") {
                        return (
                          <div className="mt-3" key={questionItem?.questionId + index}>
                            <label className="font-14 fw-sb">
                              {questionItem?.question}
                            </label>
                          </div>
                        );
                      } else {
                        return (
                          <div className="mt-3" key={questionItem?.questionId + index}>
                            <label className="font-14 fw-sb">
                              {questionItem?.question}
                            </label>
                          </div>
                        );
                      }
                    }
                  })}
                </div>
                <div className="col-6">
                  {socialHistoryQuestions.map((questionItem, index) => {
                    if (index >= 3 && questionItem !== null) {
                      return (
                        <div className="mt-3" key={questionItem?.questionId + index}>
                          <label className="font-14 fw-sb">
                            {questionItem?.question}
                          </label>
                        </div>
                      );
                    }
                  })}
                </div>
              </>
              : ""
          )

        )}
      </div>
    </>
  );
};

export default PatientSocialHistoryView;

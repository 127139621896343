import { useEffect, useRef, useState } from "react";
import sendHttpRequest from "../hooks/sendHttpRequest";
import placeholder from "../../assets/images/placeholder.png";
import { Skeleton, Tooltip } from "@mui/material";
import classes from "./MessageContainerItem.module.css";
import { useNavigate } from "react-router-dom";
import { truncate } from "../../utils/helpers/HelperFunctions";

const MessageContainerItem = (props) => {
  const [isSenderDataLoading, setIsSenderDataLoading] = useState(false);
  const [senderData, setSenderData] = useState();
  const [isReceiverDataLoading, setIsReceiverDataLoading] = useState(false);
  const [receiverData, setreceiverData] = useState();
  const [isPatientDataLoading, setIsPatientDataLoading] = useState(false);
  const [patientData, setPatientData] = useState();
  const navigate = useNavigate();
  const myRef = useRef(null);

  const fetchSenderData = async (userId) => {
    setIsSenderDataLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/auth/user-info",
      method: "GET",
      params: {
        userid: userId,
      },
    });
    if (!httpResponse.error) {
      // console.log("sender data: ", httpResponse.data);
      setSenderData(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
    setIsSenderDataLoading(false);
  };
  const fetchReceiverData = async (userId) => {
    setIsReceiverDataLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/auth/user-info",
      method: "GET",
      params: {
        userid: userId,
      },
    });
    if (!httpResponse.error) {
      setreceiverData(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
    setIsReceiverDataLoading(false);
  };
  const fetchPatientData = async (userId) => {
    setIsPatientDataLoading(true);
    const httpResponse = await sendHttpRequest({
      url: "/auth/user-info",
      method: "GET",
      params: {
        userid: userId,
      },
    });
    if (!httpResponse.error) {
      // console.log(httpResponse.data);
      setPatientData(httpResponse.data);
    } else {
      console.log(httpResponse.error);
    }
    setIsPatientDataLoading(false);
  };
  const handlePatientPRofile = (patientId) => {
    if (props?.inbox) {
      navigate(`/all-patients/${patientId}`, {
        state: {
          patientId: `${patientId}`,
          selectedIndex: `${patientId}`,
          index: `${patientId}`,
        },
      });
    }
  };

  if (props?.isLast) {
    let elementRef = document.getElementById("last");
    if (elementRef !== undefined && elementRef !== null) {
      elementRef.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

    }
  }

  useEffect(() => {
    fetchSenderData(props?.threadData?.from);
    fetchReceiverData(props?.threadData?.to);
    fetchPatientData(props?.threadData?.patientId);

  }, [props?.threadData?.threadId]);

  return (
    <>
      {isSenderDataLoading ? (
        <Skeleton />
      ) : (
        <div className={`${classes["border__bottom"]}  p-2`} key={props?.index}>
          <div className="row   ">
            <div className="col-12 ">
              <div className="d-flex flex-row ">
                <div className="me-2 ">
                  <img
                    src={placeholder}
                    width="40px"
                    height="40px"
                    alt="Provider"
                    className="rounded-circle"
                  ></img>
                </div>
                <div className="me-2 ">
                  <div className={`${classes["border__right"]} `}>
                    {isSenderDataLoading ? (
                      <Skeleton />
                    ) : (
                      <Tooltip
                        title={
                          senderData?.name.length > 0 &&
                          senderData?.name[0]?.firstName + " " + senderData?.name[0]?.lastName
                        }
                      >
                        <span className="text--terciary font-14 fw-sb p-1">
                          {senderData?.name.length > 0 && truncate(
                            senderData?.name[0]?.firstName + " " + senderData?.name[0]?.lastName,
                            25
                          )}
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <span
                    className={`text--disabled font-12 fw-sb p-1 ${classes["cursor__pointer"]}`}
                  // onClick={()=>{props?.setThreadId(undefined)}}
                  >
                    {"View all messages"}
                  </span>
                </div>
                {senderData?.gender && <div className="me-2">
                  <div className={`${classes["border__right"]}`}>
                    <span
                      className={`text--secondary font-14  p-1`}
                    >
                      {senderData?.gender}
                    </span>
                  </div>

                </div>}
                <div className="me-2">
                  <div className={`${classes["border__right"]}`}>
                    <span
                      className={`text--secondary fw-sb font-12  p-1`}
                    >
                      {senderData?.communicationNumbers?.numbers.length > 0 && senderData?.communicationNumbers?.numbers[0]?.number}
                    </span>
                  </div>
                </div>

                <div className={`me-2 mt-1  font-12`}>
                  <div className={`${classes["border__right"]} pe-1 d-flex`}>
                    <div>{"To: "}</div>
                    {isReceiverDataLoading ? (
                      <Skeleton />
                    ) : (
                      <Tooltip
                        title={
                          receiverData?.name.length > 0 && receiverData?.name[0]?.firstName + " " + receiverData?.name[0]?.lastName
                        }
                      >
                        <div>
                          <span
                            className={`text--blue  ps-1 fw-sb `}
                          >
                            {receiverData?.name.length > 0 && truncate(
                              receiverData?.name[0]?.firstName +
                              " " +
                              receiverData?.name[0]?.lastName,
                              25
                            )}
                          </span>
                        </div>

                      </Tooltip>
                    )}
                  </div>
                </div>
                <div className="me-2 font-12">
                  {isPatientDataLoading ? (
                    <Skeleton />
                  ) : (
                    patientData?.name.length > 0 && (
                      <>
                        {props?.patientPortal ? (
                          ""
                        ) : (
                          <>
                            <div className="me-2 mt-1  d-flex font-12">
                              <div>{"Patient: "}</div>
                              <Tooltip
                                title={
                                  patientData?.name[0]?.firstName +
                                  " " +
                                  patientData?.name[0]?.lastName
                                }
                              >
                                <div>
                                  <span
                                    className="text--blue ps-1  fw-sb cursor--pointer"
                                    onClick={() => {
                                      handlePatientPRofile(
                                        props?.threadData?.patientId
                                      );
                                    }}
                                  >
                                    {patientData?.name.length > 0 && patientData?.name[0]?.firstName !== undefined &&
                                      truncate(
                                        patientData?.name[0]?.firstName +
                                        " " +
                                        patientData?.name[0]?.lastName,
                                        25
                                      )}
                                  </span>
                                </div>
                              </Tooltip>
                            </div>
                          </>
                        )}
                      </>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={`row   `}>
            <div className="col-1 g-0"></div>
            <div className="col-11 ps-0 ">
              <div className="text--secondary font-14 ">
                {props?.threadData?.body}
              </div>
            </div>
          </div>
        </div>
      )}

    </>
  );
};

export default MessageContainerItem;

import React, { useEffect, useRef, useState } from "react";
import InputMask from "react-input-mask";
import { setDirtyBitInStore } from "../../redux/actions/providerActions";
import { useDispatch } from "react-redux";
import { common_strings, facility_strings } from "../../res/strings";
import { Typeahead } from "react-bootstrap-typeahead";
import options from "../../utils/data";
import PhoneNumberInput from "../UI/Reusable/PhoneNumberInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import { formatPhoneNumber } from "../../utils/helpers/HelperFunctions";
import ErrorMessage from "../UI/Reusable/ErrorMessage";
import { facilityFieldNames } from "../../utils/helpers/ErrorFields";
import { getStandardizedAddress } from "../../utils/helpers/DataLoaders";
import _ from "lodash";
import {
  FIELD_NAMES,
  MIN_MAX_VALUES,
  ERROR_MESSAGES,
} from "../validations/validationschemas";
import { ValidationErrorMessage } from "../validations/ValidationErrorMessage";
import sendHttpRequest from "../hooks/sendHttpRequest";

const AddFacilityForm = ({
  setFacility,
  serverErrors,
  editMode,
  editFacility,
  isSending,
  setIsSending,
  submitHandler,
  countriesList,
  removeServerError,
  editFacilityHandler,
  getCountriesHandler,
  updateDisabled,
  checkUpdateOnEditMode,
  facility,
}) => {
  const FACILITY_VALIDATION = MIN_MAX_VALUES.facility;
  const fieldNames = FIELD_NAMES.facility;
  const validationErrorMsgs = ERROR_MESSAGES.facility;
  // console.log("fieldNames",fieldNames)

  const [validationErrors, setValidationErrors] = useState({});
  const [name, setName] = useState(editFacility?.businessName || "");
  const [address1, setAddress1] = useState(editFacility?.addressLine1 || "");
  const [address2, setAddress2] = useState(editFacility?.addressLine2 || "");
  const [city, setCity] = useState(editFacility?.city || "");
  const [state, setState] = useState(
    editMode
      ? editFacility?.country !== "US"
        ? []
        : [
            options[
              options.findIndex((item) => {
                return item.code == editFacility?.state?.trim();
              })
            ],
          ]
      : []
  );
  const [zipcode, setZipCode] = useState(editFacility?.zipCode || "");
  const [country, setCountry] = useState(editFacility?.country || "");
  const [phone, setPhone] = useState(editFacility?.phoneNumber || "");
  const [phoneErrors, setPhoneErrors] = useState("");
  const [faxErrors, setFaxErrors] = useState("");
  const [fax, setFax] = useState(editFacility?.faxNumber || "");
  const [email, setEmail] = useState(editFacility?.email || "");
  const [stateDisabled, setSetDisabled] = useState(
    editFacility?.country !== "US" ? true : false
  );
  const [primaryFacility, setPrimaryFacility] = useState(
    editFacility?.primary || ""
  );

  const [isStandardAddress, setIsStandardAddress] = useState(false);
  const [standardizedAddress, setStandardizedAddress] = useState("");
  const [showAddress, setShowAddress] = useState(false);

  const [placeOfServiceData, setPlaceOfServiceData] = useState();
  const [placeOfService,setPlaceOfService] = useState(editFacility?.placeOfService?.code ?? null);
  const [noOfRooms,setNoOfRooms] = useState(editFacility?.numberOfRooms ?? 0);
  const [roomsPrefix,setRoomsPrefix] = useState(editFacility?.prefix ?? "");

  const _commom_strings = common_strings.en;
  const _facility_strings = facility_strings.en;

  const [isSent, setIsSent] = useState(false);
  const nameRef = useRef();
  const address1Ref = useRef();
  const address2Ref = useRef();
  const primaryStringRef = useRef();
  const phoneRef = useRef();
  const faxRef = useRef();
  const cityRef = useRef();
  const stateRef = useRef();
  const zipcodeRef = useRef();
  const emailRef = useRef();
  const countryRef = useRef();
  const formRef = useRef();
  const placeOfServiceRef = useRef();
  const noOfRoomsRef = useRef();
  const roomsPrefixRef = useRef();
  const dispatch = useDispatch();

  const fetchClaims = async () => {
    const httpResponse = await sendHttpRequest({
      url: "/claimsManagement/placeOfServiceCodes/all",
      method: "GET",
    });
    if (!httpResponse.error) {
      setPlaceOfServiceData(httpResponse.data);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      //   e.preventDefault();
    }
  };

  const handleStandardAddress = async () => {
    if (
      facility?.addressLine1 !== undefined &&
      facility?.city !== undefined &&
      facility?.zipCode !== undefined
    ) {
      if (standardizedAddress == undefined || standardizedAddress == "") {
        setIsStandardAddress(false);
        const stateObject = [options?.find((s) => s.code === facility.state)];
        const stdAddress = await getStandardizedAddress(
          facility?.addressLine1,
          facility?.addressLine2,
          facility?.city,
          stateObject,
          facility?.zipCode
        );
        await setStandardizedAddress(stdAddress);
      } else if (
        facility?.addressLine1 !== standardizedAddress?.addressLine1 ||
        facility?.city !== standardizedAddress?.city ||
        facility?.state !== standardizedAddress?.stateProvince ||
        facility?.zipCode !== standardizedAddress?.postalCode
      ) {
        setIsStandardAddress(false);
        const stateObject = [options?.find((s) => s.code === facility.state)];
        const stdAddress = await getStandardizedAddress(
          facility?.addressLine1,
          facility?.addressLine2,
          facility?.city,
          stateObject,
          facility?.zipCode
        );

        await setStandardizedAddress(stdAddress);
      }
      setShowAddress(true);
    }
  };

  const nameHandler = (event) => {
    let businessName = event.target.value;
    if (businessName?.length <= FACILITY_VALIDATION.businessName.max) {
      let validationErrorMsg = validationErrors;
      delete validationErrorMsg.businessName;
      setValidationErrors(validationErrorMsg);

      checkUpdateOnEditMode();
      setName(businessName);
      if (event.target.value !== "") {
        removeServerError(serverErrors, [facilityFieldNames.businessName]);
      }
      setFacility((previousSnapshot) => {
        const tempUser = { ...previousSnapshot };
        tempUser.businessName = event.target.value;
        dispatch(setDirtyBitInStore(true));
        return { ...previousSnapshot, ...tempUser };
      });
    } else {
      setValidationErrors({
        ...validationErrors,
        [fieldNames.businessName]: validationErrorMsgs.businessName,
      });
    }
  };

  const address1Handler = (event) => {
    let address1 = event.target.value;
    setAddress1(address1);
    if (address1?.length <= FACILITY_VALIDATION.addressLine1.max) {
      let validationErrorMsg = validationErrors;
      delete validationErrorMsg.addressLine1;
      setValidationErrors(validationErrorMsg);

      checkUpdateOnEditMode();
      if (address1 !== "") {
        removeServerError(serverErrors, [facilityFieldNames.addressLine1]);
      }
      setFacility((previousSnapshot) => {
        const tempUser = { ...previousSnapshot };
        tempUser.addressLine1 = address1;
        dispatch(setDirtyBitInStore(true));
        return { ...previousSnapshot, ...tempUser };
      });
    } else {
      setValidationErrors({
        ...validationErrors,
        [fieldNames.addressLine1]: validationErrorMsgs.addressLine1,
      });
    }
  };

  const address2Handler = (event) => {
    let address2 = event.target.value;
    setAddress2(address2);
    if (address2?.length <= FACILITY_VALIDATION.addressLine2.max) {
      let validationErrorMsg = validationErrors;
      delete validationErrorMsg.addressLine2;
      setValidationErrors(validationErrorMsg);
      checkUpdateOnEditMode();
      setFacility((previousSnapshot) => {
        const tempUser = { ...previousSnapshot };
        tempUser.addressLine2 = address2;
        dispatch(setDirtyBitInStore(true));
        return { ...previousSnapshot, ...tempUser };
      });
    } else {
      setValidationErrors({
        ...validationErrors,
        [fieldNames.addressLine2]: validationErrorMsgs.addressLine2,
      });
    }
  };

  const cityHandler = (event) => {
    let cityName = event.target.value;
    setCity(cityName);
    if (cityName?.length <= FACILITY_VALIDATION.city.max) {
      let validationErrorMsg = validationErrors;
      delete validationErrorMsg.city;
      setValidationErrors(validationErrorMsg);
      checkUpdateOnEditMode();
      if (cityName !== "") {
        removeServerError(serverErrors, [facilityFieldNames.city]);
      }
      setFacility((previousSnapshot) => {
        const tempUser = { ...previousSnapshot };
        tempUser.city = cityName;
        dispatch(setDirtyBitInStore(true));
        return { ...previousSnapshot, ...tempUser };
      });
    } else {
      setValidationErrors({
        ...validationErrors,
        [fieldNames.city]: validationErrorMsgs.city,
      });
    }
  };

  const stateHandler = (state) => {
    checkUpdateOnEditMode();
    setState(state);
    if (state !== "") {
      removeServerError(serverErrors, [facilityFieldNames.state]);
    }
    setFacility((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      tempUser.state = state[0]?.code;
      dispatch(setDirtyBitInStore(true));
      return { ...previousSnapshot, ...tempUser };
    });
  };

  const validateStateOnLeave = () => {
    if (state.length === 0) {
      stateRef.current.clear();
    }
  };

  const zipHandler = (event) => {
    checkUpdateOnEditMode();
    setZipCode(event.target.value);
    if (event.target.value !== "") {
      removeServerError(serverErrors, [facilityFieldNames.zipCode]);
    }
    setFacility((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      tempUser.zipCode = event.target.value;
      dispatch(setDirtyBitInStore(true));
      return { ...previousSnapshot, ...tempUser };
    });
  };

  const countryHandler = (event) => {
    checkUpdateOnEditMode();
    setCountry(event.target.value);
    if (event.target.value !== "") {
      removeServerError(serverErrors, [
        facilityFieldNames.country,
        facilityFieldNames.state,
      ]);
      // removeServerError(serverErrors, "state");
    }
    if (event.target.value !== "US") {
      setSetDisabled(true);
      setState([]);
    } else {
      setSetDisabled(false);
    }
    setFacility((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      tempUser.country = event.target.value;
      dispatch(setDirtyBitInStore(true));
      return { ...previousSnapshot, ...tempUser };
    });
  };

  const phoneHandler = (phoneValue) => {
    checkUpdateOnEditMode();
    let mobileNumber = formatPhoneNumber(phoneValue?.replace("+1", ""));
    setPhone(mobileNumber);
    // if (mobileNumber !== "" && mobileNumber !== "(___) ___-____") {
    //   removeServerError(serverErrors, [facilityFieldNames.phoneNumber]);
    // }
    setFacility((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      tempUser.phoneNumber = mobileNumber;
      return { ...previousSnapshot, ...tempUser };
    });
    if (
      phoneValue !== undefined &&
      isValidPhoneNumber(phoneValue?.toString()) &&
      phoneValue?.length === 12
    ) {
      dispatch(setDirtyBitInStore(true));
      setPhoneErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: false,
      }));
    } else if (
      phoneValue !== undefined &&
      !isValidPhoneNumber(phoneValue?.toString()) &&
      phoneValue?.length > 12
    ) {
      dispatch(setDirtyBitInStore(true));
      setPhoneErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: true,
      }));
    } else if (phoneValue === undefined || phoneValue?.length > 2) {
      dispatch(setDirtyBitInStore(true));
      setPhoneErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: false,
      }));
    }
  };

  const faxHandler = (faxValue) => {
    checkUpdateOnEditMode();
    let faxNumber = formatPhoneNumber(faxValue?.replace("+1", ""));
    // if (faxNumber !== "" && faxNumber !== "(___) ___-____") {
    //   removeServerError(serverErrors, [facilityFieldNames.faxNumber]);
    // }
    setFax(faxNumber);
    setFacility((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      tempUser.faxNumber = faxNumber;
      return { ...previousSnapshot, ...tempUser };
    });
    if (
      faxValue !== undefined &&
      isValidPhoneNumber(faxValue.toString()) &&
      faxValue?.length === 12
    ) {
      dispatch(setDirtyBitInStore(true));
      setFaxErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: false,
      }));
    } else if (
      faxValue !== undefined &&
      !isValidPhoneNumber(faxValue.toString()) &&
      faxValue?.length > 2
    ) {
      dispatch(setDirtyBitInStore(true));
      setFaxErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: true,
      }));
    } else if (faxValue === undefined || faxValue?.length === 2) {
      dispatch(setDirtyBitInStore(true));
      setFaxErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: false,
      }));
    }
    if (faxValue === "(___) ___-____" || faxValue === "") {
      setFaxErrors((previousSnapshot) => ({
        ...previousSnapshot,
        primary: true,
      }));
    }
  };

  const emailHandler = (event) => {
    checkUpdateOnEditMode();
    setEmail(event.target.value);
    if (event.target.value !== "") {
      removeServerError(serverErrors, [facilityFieldNames.email]);
    }
    setFacility((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      tempUser.email = event.target.value;
      dispatch(setDirtyBitInStore(true));
      return { ...previousSnapshot, ...tempUser };
    });
  };

  const dafaultFacilityHandler = (event) => {
    checkUpdateOnEditMode();
    setPrimaryFacility(event.target.checked);
    setFacility((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      tempUser.primary = event.target.checked;
      dispatch(setDirtyBitInStore(true));
      return { ...previousSnapshot, ...tempUser };
    });
  };

  const updateAddress = (e) => {
    checkUpdateOnEditMode();
    setShowAddress(false);
    e.preventDefault();
    dispatch(setDirtyBitInStore(true));
    setIsStandardAddress(true);
    setAddress1(standardizedAddress?.addressLine1);
    setFacility((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      tempUser.addressLine1 = standardizedAddress?.addressLine1;
      return { ...previousSnapshot, ...tempUser };
    });

    setAddress2(standardizedAddress?.addressLine2);
    setFacility((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      tempUser.addressLine2 = standardizedAddress?.addressLine2;
      return { ...previousSnapshot, ...tempUser };
    });

    setCity(standardizedAddress.city);
    setFacility((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      tempUser.city = standardizedAddress.city;
      return { ...previousSnapshot, ...tempUser };
    });

    setState([
      options.find(
        (s) =>
          s.code.toLowerCase() ===
          standardizedAddress?.stateProvince.toLowerCase()
      ),
    ]);
    setFacility((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      tempUser.state = standardizedAddress?.stateProvince;
      dispatch(setDirtyBitInStore(true));
      return { ...previousSnapshot, ...tempUser };
    });

    setZipCode(standardizedAddress?.postalCode);
    setFacility((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      tempUser.zipCode = standardizedAddress?.postalCode;
      return { ...previousSnapshot, ...tempUser };
    });
  };

  const clearState = () => {
    setName("");
    setEmail("");
  };

  const clearInputs = () => {
    nameRef.current.value = "";
    address1Ref.current.value = "";
    address2Ref.current.value = "";
    emailRef.current.value = "";
    cityRef.current.value = "";
    stateRef.current.value = "";
    countryRef.current.value = "";
    zipcodeRef.current.value = "";
    phoneRef.current.value = "";
    faxRef.current.value = "";
    primaryStringRef.current.value = "";
  };

  const handleUserAddSnacbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsSending(false);
  };

  const submitFormHandler = () => {
    if (!phoneErrors.primary && !faxErrors.primary) {
      editMode ? submitHandler("edit") : submitHandler("add");
    }
  };

  const handlePlaceOfService = (event)=>{
    checkUpdateOnEditMode();
    let code = event.target.value;
    setPlaceOfService(code);
    setFacility((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      tempUser.placeOfService = {"code":code};
      dispatch(setDirtyBitInStore(true));
      return { ...previousSnapshot, ...tempUser };
    });
  }
  const handleNoOfRooms = (event)=>{
    let rooms = event.target.value;
    setNoOfRooms(rooms);
    setFacility((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      tempUser.numberOfRooms = rooms;
      dispatch(setDirtyBitInStore(true));
      return { ...previousSnapshot, ...tempUser };
    });
    checkUpdateOnEditMode();
  }
  const handleRoomPrefix = (event)=>{
    let roomsPrefix = event.target.value;
    setRoomsPrefix(roomsPrefix);
    setFacility((previousSnapshot) => {
      const tempUser = { ...previousSnapshot };
      tempUser.prefix = roomsPrefix;
      dispatch(setDirtyBitInStore(true));
      return { ...previousSnapshot, ...tempUser };
    });
    checkUpdateOnEditMode();
  }

  useEffect(() => {
    fetchClaims();
    getCountriesHandler();
    nameRef.current.focus();
    dispatch(setDirtyBitInStore(false));
    return () => {
      clearState(); // This worked for me
    };
  }, []);

  return (
    <>
      <div className={"container"}>
        <form
          ref={formRef}
          id="facilityAddForm"
          // onSubmit={(e) => {
          //   e.preventDefault();
          //   editMode ? editFacilityHandler("edit") : submitHandler("add");
          // }}
        >
          <div className="row justify-content-center">
            <div className="col-12 col-sm-10">
              <div className="col-sm-12">
                <div>
                  <label
                    htmlFor={_facility_strings.name}
                    className="form-label m-0 ms-1 font-12 text--secondary fw-sb"
                  >
                    {_facility_strings.name}
                    <span>&#42;</span>
                  </label>
                  <input
                    type="text"
                    onChange={nameHandler}
                    onKeyDown={handleKeyDown}
                    ref={nameRef}
                    defaultValue={name}
                    autoComplete={false}
                    required
                    className={`form-control font-14 m-0 border--default br-10 shadow-none input`}
                    id="name"
                  />
                  <ErrorMessage
                    serverErrorsArray={serverErrors}
                    fieldName={"businessName"}
                  />
                  <ValidationErrorMessage
                    errors={validationErrors}
                    fieldName={fieldNames.businessName}
                  />
                </div>
              </div>

              <div className="col-sm-12">
                <div className="pt-2">
                  <label
                    htmlFor={_facility_strings.address1}
                    className="form-label m-0 ms-1 fw-sb font-12 text--secondary"
                  >
                    {_facility_strings.address1}
                    <span>&#42;</span>
                  </label>
                  <input
                    type="text"
                    onChange={address1Handler}
                    ref={address1Ref}
                    onBlur={handleStandardAddress}
                    value={address1}
                    autoComplete={false}
                    required
                    className="form-control font-14 border--default br-10 shadow-none input"
                    id="address1"
                  />
                  <ErrorMessage
                    serverErrorsArray={serverErrors}
                    fieldName={"addressLine1"}
                  />
                  <ValidationErrorMessage
                    errors={validationErrors}
                    fieldName={fieldNames.addressLine1}
                  />
                </div>
              </div>

              <div className="col-sm-12">
                <div className="pt-2">
                  <label
                    htmlFor={_facility_strings.address2}
                    className="form-label m-0 ms-1  fw-sb font-12 text--secondary"
                  >
                    {_facility_strings.address2}
                  </label>
                  <input
                    type="text"
                    onChange={address2Handler}
                    ref={address2Ref}
                    value={address2}
                    onBlur={handleStandardAddress}
                    autoComplete={false}
                    className="form-control font-14 border--default br-10 shadow-none input"
                    id="address2"
                  />
                  <ValidationErrorMessage
                    errors={validationErrors}
                    fieldName={fieldNames.addressLine2}
                  />
                </div>
              </div>

              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="pt-2">
                      <label
                        htmlFor={_commom_strings.city}
                        className="form-label m-0 ms-1  fw-sb font-12 text--secondary"
                      >
                        {_commom_strings.city}
                        <span>&#42;</span>
                      </label>
                      <input
                        type="text"
                        onChange={cityHandler}
                        onBlur={handleStandardAddress}
                        ref={cityRef}
                        value={city}
                        autoComplete={false}
                        required
                        className="form-control font-14 border--default br-10 shadow-none input"
                        id="city"
                      />
                      <ErrorMessage
                        serverErrorsArray={serverErrors}
                        fieldName={"city"}
                      />
                      <ValidationErrorMessage
                        errors={validationErrors}
                        fieldName={fieldNames.city}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="pt-2">
                      <label
                        htmlFor={_commom_strings.state}
                        className="form-label m-0 ms-1  fw-sb font-12 text--secondary"
                      >
                        {_commom_strings.state}
                        <span>&#42;</span>
                      </label>
                      <Typeahead
                        id="state"
                        name="state"
                        labelKey="name"
                        ref={stateRef}
                        onChange={stateHandler}
                        options={stateDisabled ? [] : options}
                        className="rbt-menu my-select"
                        style={{ borderRadius: "10px", height: "38px" }}
                        autoComplete="false"
                        disabled={stateDisabled}
                        onBlur={() => {
                          validateStateOnLeave();
                          handleStandardAddress();
                        }}
                        onInvalid={(e) => {
                          e.target.setCustomValidity("Please select a state");
                        }}
                        onInput={(e) => {
                          e.target.setCustomValidity("");
                        }}
                        selected={state}
                        inputProps={{
                          required: true,
                          style: { fontSize: "14px" },
                        }}
                      />
                      <ErrorMessage
                        serverErrorsArray={serverErrors}
                        fieldName={"state"}
                      />
                    </div>
                  </div>

                  <div className="col-sm-3">
                    <div className="pt-2">
                      <label
                        htmlFor={_commom_strings.zipcode}
                        className="form-label m-0 ms-1 fw-sb font-12 text--secondary"
                      >
                        {_commom_strings.zipcode}
                        <span>&#42;</span>
                      </label>
                      <input
                        type="text"
                        onChange={zipHandler}
                        ref={zipcodeRef}
                        onBlur={handleStandardAddress}
                        value={zipcode}
                        autoComplete={false}
                        minLength="5"
                        maxLength="10"
                        required
                        className="form-control font-14 border--default br-10 shadow-none input"
                        id="zipcode"
                      />
                      <ErrorMessage
                        serverErrorsArray={serverErrors}
                        fieldName={"zipCode"}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {showAddress && (
                <div className="col-md-12  mt-2">
                  {standardizedAddress !== "" &&
                  standardizedAddress !== undefined ? (
                    <span className="d-block text-decoration-underline br-10 text--danger font-14 fw-sb">
                      USPS Standard Address
                    </span>
                  ) : null}
                  {standardizedAddress !== "" &&
                  standardizedAddress !== undefined ? (
                    <div className=" row ">
                      <div className="col-sm-4">
                        <span className="d-block font-12 text--terciary ">
                          {standardizedAddress?.addressLine1}
                        </span>
                        <span className="d-block font-12 text--terciary ">
                          {standardizedAddress?.addressLine2}
                        </span>
                        <span className="d-block font-12 text--terciary ">
                          {standardizedAddress?.city},{" "}
                          {standardizedAddress?.stateProvince},{" "}
                          {standardizedAddress?.postalCode}
                        </span>
                      </div>
                      <div className="col-sm-4 align-self-center">
                        <button
                          className="btn btn-outline-primary btn-outline-blue shadow-none font-12"
                          onClick={(event) => updateAddress(event)}
                        >
                          Use Standard Address
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
              )}

              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="pt-2">
                      <label
                        htmlFor={_commom_strings.country}
                        className="form-label ms-1 m-0 font-12 text--secondary fw-sb"
                      >
                        {_commom_strings.country}
                        <span>&#42;</span>
                      </label>
                      <select
                        onChange={countryHandler}
                        className="form-select font-14 border--default br-10 shadow-none input"
                        aria-label={_commom_strings.country}
                        id={_commom_strings.country}
                        ref={countryRef}
                        required
                        style={{ borderRadius: "10px" }}
                      >
                        <option value="" hidden selected disabled>
                          Select
                        </option>

                        {countriesList.length > 0
                          ? countriesList.map((item, index) => {
                              return (
                                <option
                                  className="font-14"
                                  key={index}
                                  value={item?.code}
                                  selected={item?.code === country}
                                >
                                  {item?.name + " (" + item?.code + ")"}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                      <ErrorMessage
                        serverErrorsArray={serverErrors}
                        fieldName={"country"}
                      />
                    </div>
                  </div>

                  <div className="col-sm-3">
                    <div className="pt-2">
                      <label
                        htmlFor="Phone"
                        className="form-label m-0 ms-1  fw-sb font-12 text--secondary"
                      >
                        {_commom_strings.phone}
                        <span>&#42;</span>
                      </label>
                      <PhoneNumberInput
                        onChange={phoneHandler}
                        id="phone"
                        reference={phoneRef}
                        value={phone || ""}
                        required={true}
                      />
                      <ErrorMessage
                        serverErrorsArray={serverErrors}
                        fieldName={"phoneNumber"}
                      />
                      <span className="text--danger d-block font-10 ms-2 mt-1">
                        {phoneErrors?.primary === true
                          ? "Incorrect number format"
                          : " "}
                      </span>
                    </div>
                  </div>

                  <div className="col-sm-3">
                    <div className="pt-2">
                      <label
                        htmlFor="Fax"
                        className="form-label m-0 ms-1  fw-sb font-12 text--secondary"
                      >
                        {_commom_strings.fax}
                        {/* <span>&#42;</span> */}
                      </label>
                      <PhoneNumberInput
                        onChange={faxHandler}
                        id="fax"
                        reference={faxRef}
                        value={fax}
                        // required = {true}
                      />
                      <ErrorMessage
                        serverErrorsArray={serverErrors}
                        fieldName={"faxNumber"}
                      />
                      <span className="text--danger d-block font-10 ms-2 mt-1">
                        {faxErrors?.primary === true
                          ? "Incorrect number format"
                          : " "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="pt-2">
                      <label
                        htmlFor={_commom_strings.email}
                        className="form-label m-0 ms-1  fw-sb font-12 text--secondary"
                      >
                        {_commom_strings.email}
                        <span>&#42;</span>
                      </label>
                      <input
                        type="email"
                        onChange={emailHandler}
                        ref={emailRef}
                        defaultValue={email}
                        autoComplete={false}
                        className="form-control font-14 border--default br-10 shadow-none input"
                        required
                        id="Email"
                      />
                      <ErrorMessage
                        serverErrorsArray={serverErrors}
                        fieldName={"email"}
                      />
                    </div>
                  </div>
                  {/* Place of service */}
                  <div className="col-sm-6">
                    <div className="pt-2">
                      <label
                        htmlFor={_commom_strings.place_of_service_str}
                        className="form-label m-0 ms-1  fw-sb font-12 text--secondary"
                      >
                        {_commom_strings.place_of_service_str}
                      </label>
                      <select
                        className={`form-select font-12  border--default br-10 shadow-none input`}
                        aria-label="action select"
                        style={{
                          border: "1px solid #D9E8EC",
                          //   width: `${props.tasksOnly ? "100%" : "80%"}`,
                          height: "36px",
                          // fontStyle:"italic"
                        }}
                        onChange={(e) => handlePlaceOfService(e)}
                        ref={placeOfServiceRef}
                      >
                        <option
                          className="text--terciary"
                          value={""}
                          key={1}
                          selected
                          disabled
                          hidden
                        >
                          {"Select place of service"}
                        </option>
                        {placeOfServiceData !== undefined &&
                          placeOfServiceData.map((serviceItem) => {
                            return (
                              <option
                                value={serviceItem?.code}
                                key={serviceItem?.code}
                                id={serviceItem?.code}
                                selected = {placeOfService === serviceItem?.code ?  true:false}
                              >
                                {"".concat(serviceItem?.code,
                                  " - ",
                                  serviceItem?.name
                                )}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-3">
                    <div className="pt-2">
                      <label
                        htmlFor={_commom_strings.no_of_rooms_str}
                        className="form-label m-0 ms-1  fw-sb font-12 text--secondary"
                      >
                        {_commom_strings.no_of_rooms_str}
                      </label>
                      <select
                        className={`form-select font-12  border--default br-10 shadow-none input`}
                        aria-label="action select"
                        style={{
                          border: "1px solid #D9E8EC",
                          //   width: `${props.tasksOnly ? "100%" : "80%"}`,
                          height: "36px",
                          // fontStyle:"italic"
                        }}
                        onChange={(e) => handleNoOfRooms(e)}
                      >
                        <option
                          className="text--terciary"
                          value={""}
                          key={1}
                          selected
                          disabled
                          hidden
                        >
                          {"Select rooms"}
                        </option>
                        {[
                          "1",
                          "2",
                          "3",
                          "4",
                          "5",
                          "6",
                          "7",
                          "8",
                          "9",
                          "10",
                        ].map((item) => {
                          return (
                            <option
                              className="text--terciary"
                              value={item}
                              key={item}
                              id={item}
                              selected = {noOfRooms === item ? true:false}
                            >
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="pt-2">
                      <label
                        htmlFor={_commom_strings.room_prefix_str}
                        className="form-label m-0 ms-1  fw-sb font-12 text--secondary"
                      >
                        {_commom_strings.room_prefix_str}
                      </label>
                      <input
                        type="text"
                        onChange={handleRoomPrefix}
                        ref={roomsPrefixRef}
                        defaultValue={roomsPrefix}
                        autoComplete={false}
                        className="form-control font-14 border--default br-10 shadow-none input"
                        // required
                        id="rooms-prefix"
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="pt-2" style={{ marginTop: "32px" }}>
                      <div class="form-check">
                        <input
                          class="form-check-input border--default shadow-none"
                          type="checkbox"
                          onClick={dafaultFacilityHandler}
                          checked={primaryFacility}
                          id="flexCheckDefault"
                          ref={primaryStringRef}
                        />
                        <label
                          class="form-check-label font-14"
                          for="flexCheckDefault"
                        >
                          {_facility_strings.set_as_primary_string}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12">
                <div className="row">
                  <div className="col-6 mt-2">
                      {roomsPrefix?.length > 0 && 
                        Array.from({ length: noOfRooms }, (_, index) => 1 + index).map((item)=>{
                          return(
                            <p className="font-12 text--terciary m-0 p-0">{"".concat(roomsPrefix," ",item)}</p>
                          )
                        })
                      }
                  </div>
                </div>

              </div>

              <div className="col-sm-12">
                <div className="row text-end pt-2">
                  <div>
                    <button
                      type="button"
                      form="facilityAddForm"
                      onClick={_.debounce(submitFormHandler, 300)}
                      className={`btn blue_task_button fw-sb font-14 text-white  br-10`}
                      disabled={
                        editMode
                          ? Object?.keys(validationErrors)?.length !== 0
                            ? true
                            : false || !updateDisabled || isSending
                          : Object?.keys(validationErrors)?.length !== 0
                          ? true
                          : isSending
                      }
                    >
                      {editMode
                        ? isSending
                          ? "Updating..."
                          : "Update"
                        : isSending
                        ? "Adding..."
                        : "Add"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {/* {console.log("validationErrors: ",validationErrors)} */}
      {/* {console.log("placeOfServiceData: ", placeOfServiceData)} */}
    </>
  );
};

export default AddFacilityForm;

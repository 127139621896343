import { WarningIcon } from "../Reusable/WarningIcon";

function NotFoundMessage({ message }) {
    return <div
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75vh",
        }}
    >
        <div className="text-center">
            <h2>
                <WarningIcon
                    style={{ fontSize: "3rem", fill: "#f8a02e", verticalAlign: "bottom" }}
                />{" "}
                {message}
            </h2>
        </div>
    </div>
}

export default NotFoundMessage;
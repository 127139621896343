import {ActionTypes} from "../constants/actionTypes"

const initialState = {
    count:0
}

const setRefillRequestsReducer = (state=initialState, action)=>{
    if (action.type === ActionTypes.SET_REFILL_REQUSTES ){
        return {...state,count : action.payload}
    }
    return state
}

export default setRefillRequestsReducer;

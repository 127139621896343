const PageNotFound = (props)=>{
    return <>
        <div className="row ">
            <div className="col-12 text-center ">
                <div className="text--danger font-32 fw-sb ">{props?.code}</div>
                <div className="text--secondary font-32 fw-sb ">{props?.text}</div>
            </div>

        </div>
    </>
}

export default PageNotFound;
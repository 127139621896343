import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import makeStyles from "@mui/styles/makeStyles";
import { Chip, InputBase } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import { KeyboardArrowDownRounded } from "@mui/icons-material";
import { formatPostalCode } from "../../../../utils/helpers/HelperFunctions";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      
      marginTop: "8px",
      // marginLeft: "20px",
    },
  },
};

const MultipleSelectFacility = ({
  facilitiesList,
  facilityHandler,
  facilitiesIdsCheckedList,
  facilitiesCheckedList,
  edit,
  required,
}) => {
  const facilityRef = React.useRef();
  const [list, setList] = React.useState(
    facilitiesCheckedList === undefined ? [] : facilitiesCheckedList
  );
  const [checkedList, setCheckedList] = React.useState(
    facilitiesIdsCheckedList
  );

  const setIdList2 = (facilityIdsArr) => {
    let tempList = [];
    facilityIdsArr.forEach((facilityId) => {
      facilitiesList.forEach((facility) => {
        if (facility?.facilityId == facilityId) {
          tempList.push(facility);
        }
      });
    });
    facilityHandler(tempList);
  };

  const setIdList = (facilityId, toggle) => {
    let tempList = checkedList;

    if (toggle) {
      facilitiesList.forEach((facility) => {
        if (facility?.facilityId == facilityId) {
          tempList.push(facility?.facilityId);
        }
      });
    } else {
      tempList = tempList.filter((id) => id != facilityId);
    }
    setIdList2(tempList);
    setCheckedList(tempList);
  };

  const handleChange3 = (event) => {
    let facilityTarget = event.target.offsetParent.children[0].children[0];
    populateMultiSelectList(!facilityTarget.checked, facilityTarget.id, facilityTarget.name);
  }

  const handleChange2 = (event) => {

    populateMultiSelectList(event.target.checked, event.target.id, event.target.name);
  }

  const populateMultiSelectList = (checked, id, name) => {
    if (checked) {
      facilitiesList.forEach((facility) => {
        if (facility?.facilityId == id) {
          list.push(facility?.businessName);
        }
      });
    } else {
      const tempList = list?.filter((item) => {
        return item != name;
      });
      setList(tempList);
    }

    setIdList(id, checked);
  };

  // const CustomSvgIcon = () => (
  //   <SvgIcon
  //     style={{ color: "#EC609B", fill: "#EC609B" }}
  //     className="cursor--pointer"
  //   >
  //     <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
  //   </SvgIcon>
  // );

  React.useEffect(() => {
    if (edit) {
      setList(facilitiesCheckedList);
      setCheckedList(facilitiesIdsCheckedList);
    }
    return () => {};
  }, [facilitiesCheckedList, facilitiesIdsCheckedList]);

  return (
    <FormControl
      style={{
        border: "1px solid #d8e9ec",
        borderRadius: "10px",
        width: "100%",
        minWidth: "100%",
        background: "#ffffff",
      }}
    >
      <Select
        labelId="facility-multiple-checkbox"
        id="facility-multiple-checkbox"
        multiple
        style={{
          color: "#323232",
          marginLeft: "10px",
          fontSize: "14px",
          height: "36px",
        }}
        value={list}
        // onChange={handleChange}
        input={<InputBase />}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>Placeholder</em>;
          }
          return selected.join(", ");
        }}
        MenuProps={MenuProps}
        IconComponent={(props) => (
          <i {...props} style={{ marginRight: "2.4px", marginTop: "-5px" }}>
            <KeyboardArrowDownRounded
              fontSize="medium"
              style={{ width:21, height:21, color: "#EC609B", fill: "#EC609B" }}
            />
          </i>
        )}
        required={required}
      >
        {facilitiesList.map((facility, index) => (
          <MenuItem
            style={{ width: "100%" }}
            className={"p-0"}
            key={facility?.facilityId}
            onChange={handleChange2}
            >
            <Checkbox
              checked={checkedList.indexOf(facility?.facilityId) > -1}
              id={facility?.facilityId}
              name={facility?.businessName}
              ref={facilityRef}
            />
            <ListItemText onClick={handleChange3}>
              <span className="font-12">
                {facility?.businessName}
              </span>
              {facility?.primary && (
                <span className="badge mx-2 font-10 fw-sb text--secondary bg--d9e8ec px-2 br-10">
                  Primary
                </span>
              )}
              <span className="font-12">
                {facility?.addressLine1?.length > 0
                  ? " (" + facility?.addressLine1
                  : ""}
                {facility?.addressLine2?.length > 0
                  ? ", " + facility?.addressLine2
                  : ""}
                {facility?.city?.length > 0 ? ", " + facility?.city : ""}
                {facility?.state?.length > 0 ? ", " + facility?.state : ""}
                {facility?.zipCode?.length > 0 ? " " + formatPostalCode(facility?.zipCode||"") : ""}
                {")"}
              </span>
            </ListItemText>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultipleSelectFacility;

import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import sendHttpRequest from "../../hooks/sendHttpRequest";
import { useNavigate } from "react-router-dom";
import { Backdrop } from "@mui/material";
import { useState } from "react";
import { VideoCallContext } from "../AgoraHooks/VideoCallContext";
import { useContext } from "react";
import { useToggle } from "../../hooks/useToggle";
import { useDispatch } from "react-redux";
import { setConsulationInProgress, setVideoOpen } from "../../../redux/actions/providerActions";

const EndEncounterConsultationModal = ({
    show,
    handleEndConsulationModal,
    patientName,
    appointmentId
}) => {

    const navigate = useNavigate();

    const videoCallContext = useContext(VideoCallContext);
    const [isEndingEncounter, setIsEndingEncounter] = useState(false);
    const [isSessionEnding, setIsSessionEnding] = useToggle(false);
    const dispatch = useDispatch();
    async function endConsultation(appointmentId) {
        setIsEndingEncounter(true);
        const httpResponse = await sendHttpRequest({
            method: 'GET',
            url: `/appointment/${appointmentId}/end`
        });
        if (!httpResponse.error) {

            handleEndConsulationModal();
            navigate('/dashboard');
        }
        setIsEndingEncounter(false);
    }



    const isRemoteUserAvailable = () => {
        return videoCallContext.client.remoteUsers?.length > 0;
    }


    const sessionBreakRequest = async (appointmentId) => {
        setIsSessionEnding(true);

        let durationInSeconds = 15000;
        if (document.getElementById('minutes') !== undefined &&
            document.getElementById('minutes') !== null &&
            document.getElementById('seconds') !== undefined &&
            document.getElementById('seconds') !== null) {
            try {
                durationInSeconds = Number(document.getElementById('minutes').innerText) * 60 + Number(document.getElementById('seconds').innerText);
            }
            catch (e) {
                console.error(e);
            }
        }

        const httpResponse = await sendHttpRequest({
            method: "POST",
            url: `/video/SessionBreakRequest`,
            data: {
                appiontmentId: appointmentId,
                videoJoinRequestType: "WAITING",
                callDuration: durationInSeconds,
            }
        })

        if (!httpResponse.error) {
            videoCallContext.endSession().then(() => {
                videoCallContext.clearVideoCallContextData();
                dispatch(setConsulationInProgress(false));
                dispatch(setVideoOpen(false));
            });

        }
        setIsSessionEnding(false);
    }

    return (
        <>
            <Modal
                show={show}
                size="md"
                dialogClassName="end-consultation-modal-dialog"
                backdropClassName="modal-backdrop"
                contentClassName="modal-border modal-shadow"
                centered
            >
                <Modal.Header style={{ border: "none" }}>
                    <div className="row ">
                        <div className="col-12 ">
                            <span
                                style={{
                                    lineHeight: "12px",
                                    width: "18px",
                                    fontSize: "8pt",
                                    color: "#336383",
                                    position: "absolute",
                                    top: 15,
                                    right: 15,
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    handleEndConsulationModal(p => false);
                                }}
                            >
                                {<CloseIcon />}
                            </span>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row py-2">
                        <div className="col-sm-12">
                            <h2 className="font-18 fw-sb text-center">End Consultation</h2>
                        </div>
                    </div>
                    {/* <div className="row justify-content-center">
                        <div className="col-sm-5">
                            <div className="end-consultation-duration px-4 py-2">
                                <span className="fw-sb font-12 text--secondary ms-1 text-center">
                                    Duration: {(Number(hours) * 60) + minutes}:{seconds}

                                </span>
                            </div>
                        </div>
                    </div> */}
                    <div className="row justify-content-center py-2">
                        <div className="col-sm-10">
                            <span className="font-14 text--secondary d-block text-center">
                                Would you like to end the consultation with{" "}
                                <span className="fw-sb text--terciary d-block">

                                    {patientName}

                                    <span className="font-14 text--secondary" style={{ fontWeight: 'normal' }}>{" "}?</span>
                                </span>

                            </span>
                        </div>
                    </div>
                    {/* 
                    {videoCallContext.client.remoteUsers?.length > 0 ? <div className="row justify-content-center py-2">
                        <div className='col-10'>
                            <div className="form-check">
                                <input onClick={(e) => handleMoveToWaitingRoom(e)} checked={moveToWaitingRoom} className="form-check-input shadow-none br-10" type="checkbox" id="moveToWaitingRoom" />
                                <label className="form-check-label font-14 text--terciary" htmlFor="moveToWaitingRoom">
                                    {_str.move_to_waiting_room}
                                </label>
                            </div>

                        </div>
                    </div> : null} */}

                    <div className="row justify-content-center py-3">
                        <div className="col-sm-4">
                            <button
                                style={{ width: '7em' }}
                                onClick={handleEndConsulationModal}
                                className="btn br-10 bg--d9e8ec  shadow-none text--blue font-14 fw-sb"
                            >
                                Cancel
                            </button>
                        </div>


                        <div className="col-sm-4 ">

                            <button
                                style={{ width: '7em' }}
                                onClick={async () => {
                                    if (videoCallContext?.getIsVideoCallActive()) {
                                        let videoCall = await sessionBreakRequest(appointmentId);
                                    }
                                    const endConsultationCall = await endConsultation(appointmentId);
                                }}
                                className="btn mx-auto br-10 bg--red shadow-none text-white font-14 fw-sb"
                            >
                                End
                            </button>
                        </div>
                    </div>

                    <Backdrop
                        sx={{
                            color: "#EC609B",
                            zIndex: (theme) => theme.zIndex.drawer + 1000,
                        }}
                        open={isEndingEncounter}
                    ></Backdrop>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default EndEncounterConsultationModal;
import { Grid } from '@mui/material';
import Footer from '../../Footer/Footer';
import Header from '../../Header/Header'
import Layout from "../../layout/Layout";
import "../../form.css"
import { useState } from 'react';
import PatientPaymentPage from '../../../../../patientportal/patientpages/PatientPaymentPage';
import { useLocation } from 'react-router-dom';
import PatientRegistrationHeader from '../../../../patient-registration/header/PatientRegistrationHeader';


function PaymentComponent(props) {
    /* The line `console.log(props)` is printing the value of the `props` object to the console. This
    is useful for debugging and understanding the data that is being passed to the
    `PaymentComponent` component. */
    // console.log(props)
    const location = useLocation();
    console.log(location);

    return (
        <div className="App">
            <Layout>
                {/* <Header patientDemographics={true} /> */}
                <PatientRegistrationHeader />
                <Grid container component="main" sx={{ height: "100vh", backgroundColor: "#d9e8ec" }}>
                    <div className='container-fluid' style={{ backgroundColor: "#D9E8EC", marginTop: "5%" }}>
                        <PatientPaymentPage newPatient={true} price={location?.state?.price}  chiefComplaint={location.state.chiefComplaint} />
                    </div>
                </Grid>
                <Footer ishidden={true} hideSignin={true} isLast={false} isFirst={true} />
            </Layout>
        </div>
    );
}

export default PaymentComponent;

import { useEffect, useState } from "react";
import CalenderDatePicker from "../../../../components/UI/General/CalenderDatePicker"
import PageHeader from "../../../../components/UI/General/PageHeader"
import sendHttpRequest from "../../../../components/hooks/sendHttpRequest";
import { useAuth } from "../../../../components/hooks/useAuth";
import { LinearProgress, Skeleton } from "@mui/material";
import AllClaimsTable from "../tables/AllClaimsTable";
import { useLocation, useNavigate } from "react-router-dom";
import { calenderDateTimeHelper } from "../../../../utils/dateTimeHelper";
import { currencyFormatter } from "../../../../utils/helpers/HelperFunctions";
import { Divider } from "../../../../components/UI/General/Divider";
import CustomizedSnackbar from "../../../../components/UI/General/CustomizedSnackbars";
import useSnackbar from "../../../../components/hooks/useSnackbar";
import useClaimPlaceOfServiceCodes from "../../claim-hooks/useClaimPlaceOfServiceCodes";
import { STATUS_MAP } from "../../../../billing-and-claims/BillingAndClaimsConstants";
import { Alert } from "react-bootstrap";

function ReviewClaimPage(props) {
    const { user } = useAuth();

    const location = useLocation();
    const navigate = useNavigate();


    const claim = location?.state?.data;
    const encounter = location?.state?.data?.encounter;
    const referringPhysician = location?.state?.data?.encounter?.referringPhysician;

    const patient = location?.state?.data?.encounter?.patient;
    const provider = location?.state?.state?.encounter?.provider;
    const [primaryInsurance, setPrimaryInsurance] = useState(location?.state?.data?.encounter?.patient?.patientInsurance?.find(insurance => insurance?.type === "Primary") ?? null);
    const [openClaimSnackbar, claimSnackbarMessage, claimSnackbarSeverity, setOpenClaimSnackbar, setClaimSnackbarMessage, setClaimSnackbarSeverity, handleClaimSnackbarClose] = useSnackbar();
    const [errors, setErrors] = useState([]);
    const [isClaimSubmitting, setIsClaimSubmitting] = useState(false);
    const [isClaimSent, setIsClaimSent] = useState(false);

    const { placeOfServiceCodes, isPlaceOfServiceCodeLoading } = useClaimPlaceOfServiceCodes();
    const placeOfServiceMap = new Map();

    for (const { code, name } of placeOfServiceCodes) {
        placeOfServiceMap.set(code, name);
    }



    const dismissError = (index) => {
        setErrors((prevErrors) => prevErrors.filter((_, i) => i !== index));
    };




    async function submitClaimToApex(claimId) {
        setIsClaimSubmitting(true);
        const httpResponse = await sendHttpRequest({
            method: "POST",
            url: `/claims/${claimId}/submit`
        })
        if (!httpResponse.error && httpResponse.status == 200) {
            setClaimSnackbarMessage("Claim successfully submitted to ApexEdi");
            setClaimSnackbarSeverity("success");
            setOpenClaimSnackbar(true);
            setIsClaimSent(true);
            setTimeout(() => {
                navigate("/claims-all")
            }, 2000);
        }
        else if (httpResponse.status === 400 || httpResponse.status === 500 || httpResponse.error) {
            setClaimSnackbarMessage("Error in Claim Processing");
            setClaimSnackbarSeverity("error");
            setOpenClaimSnackbar(true);
            console.log(httpResponse.error);
            console.log(httpResponse.data);
            console.log(httpResponse);
            const errorsList = httpResponse.errorMessage?.object;
            setErrors(errorsList);

        }
        else {
            const errors = httpResponse?.data?.claimResults?.errors;
            setErrors(p => errors);
        }
        setIsClaimSubmitting(false);
    }



    return (
        <>
            <PageHeader title="Review Claim" />
            <div className="container">
                {errors?.length > 0 ? (
                    <>
                        <div className="row justify-content-center mt-3">
                            <div className="col-sm-7">
                                {errors?.map((error, index) => {
                                    return (
                                        <Alert
                                            key={index}
                                            variant="danger"
                                            dismissible
                                            className='font-10'
                                            onClose={() => dismissError(index)}
                                        >
                                            <strong>{error?.Code}</strong> - {error?.SupplementalInformation}
                                        </Alert>
                                    );
                                })}
                            </div>
                        </div>
                    </>
                ) : null}
                <div className="row justify-content-center mt-2">
                    <div className='row justify-content-center'>
                        <div className='col-sm-7'>
                            <div className='row '>
                                <div className="col-sm-3">
                                    <span className='d-block fw-sb font-12 text--secondary '>
                                        Claim Number
                                    </span>
                                    <span className='d-block  font-12 text--terciary'>
                                        {claim.claimNumber}
                                    </span>
                                </div>
                                <div className="col-sm-3">
                                    <span className='d-block fw-sb font-12 text--secondary '>
                                        Provider
                                    </span>
                                    <span className='d-block  font-12 text--terciary'>
                                        {/* {getProviderConcatenatedNameFromNameObject(prescriptionReferenceData?.providerTenantPracticeLocation?.provider?.name?.at(0))} */}
                                        {`${encounter?.provider?.prefix} ${encounter?.provider?.firstName} ${encounter?.provider?.lastName}`}
                                    </span>
                                </div>
                                <div className="col-sm-3">
                                    <span className='d-block fw-sb font-12 text--secondary '>
                                        Service Location
                                    </span>
                                    <span className='d-block font-12 text--terciary'>
                                        {/* {prescriptionReferenceData?.providerTenantPracticeLocation?.facility?.businessName} */}
                                        {`${encounter?.encounterMetadata?.serviceLocation}`}
                                    </span>
                                </div>
                                <div className="col-sm-3">
                                    <span className='d-block fw-sb font-12 text--secondary '>
                                        Place of Service
                                    </span>

                                    <span className='font-12 text--terciary'>
                                        {console.log(encounter?.encounterMetadata?.placeOfService)}
                                        {placeOfServiceMap.get(encounter?.encounterMetadata?.placeOfService)}
                                    </span>


                                </div>
                            </div>



                            {/* {console.log("encounter", encounter)} */}
                            <div className='row pt-3 pb-1'>
                                <div className="col-sm-3">
                                    <span className='d-block fw-sb font-12 text--secondary '>
                                        Service Date
                                    </span>
                                    <span className='d-block  font-12 text--terciary'>
                                        {/* {getProviderConcatenatedNameFromNameObject(prescriptionReferenceData?.providerTenantPracticeLocation?.provider?.name?.at(0))} */}
                                        {calenderDateTimeHelper(encounter?.encounterMetadata?.dateOfServiceTo, "MMM DD, YYYY")}
                                        {/* {`${encounter?.provider?.prefix} ${encounter?.provider?.firstName} ${encounter?.provider?.lastName}`} */}
                                    </span>
                                </div>
                                <div className="col-sm-3">
                                    <span className='d-block fw-sb font-12 text--secondary '>
                                        Last Seen Date
                                    </span>
                                    <span className='d-block font-12 text--terciary'>
                                        {/* {prescriptionReferenceData?.providerTenantPracticeLocation?.facility?.businessName} */}
                                        {calenderDateTimeHelper(encounter?.lastSeenDate, "MMM DD, YYYY")}
                                    </span>
                                </div>
                                <div className="col-sm-3">
                                    <span className='d-block fw-sb font-12 text--secondary '>
                                        Date of Current Illness
                                    </span>
                                    <span className='d-block font-12 text--terciary'>
                                        {/* {prescriptionReferenceData?.providerTenantPracticeLocation?.facility?.businessName} */}
                                        {calenderDateTimeHelper(encounter?.dateOfCurrentIllnessOrSymptom, "MMM DD, YYYY")}
                                    </span>


                                </div>
                                {encounter?.patient?.gender?.toLowerCase() === "female" && encounter?.lastMenstrualPeriodDate !== null ? <div className="col-sm-3">
                                    <span className='d-block fw-sb font-12 text--secondary '>
                                        Last Menstural Period
                                    </span>
                                    <span className='d-block font-12 text--terciary'>
                                        {/* {prescriptionReferenceData?.providerTenantPracticeLocation?.facility?.businessName} */}
                                        {calenderDateTimeHelper(encounter?.lastMenstrualPeriodDate, "MMM DD, YYYY")}
                                    </span>


                                </div> : null}

                                <div className="col-sm-3">
                                    <span className='d-block fw-sb font-12 text--secondary '>
                                        Status
                                    </span>
                                    <span className='d-block font-12 text--terciary'>
                                        {/* {prescriptionReferenceData?.providerTenantPracticeLocation?.facility?.businessName} */}
                                        {statusToString(claim?.status)}
                                    </span>


                                </div>
                            </div>

                            {referringPhysician !== null && referringPhysician !== undefined ? <div className="row py-1">

                                <span className='font-14 fw-sb text--terciary d-block'>Referring Physician</span>
                                <div className='row '>
                                    <div className='col-sm-3'>
                                        <span className="font-12 d-block fw-sb text--secondary">Name</span>
                                        <span className="font-12 fw-thin d-block">Dr. {referringPhysician?.firstName} {referringPhysician?.lastName}</span>

                                    </div>
                                    <div className='col-sm-2'>
                                        <span className="font-12 d-block fw-sb text--secondary">NPI </span>
                                        <span className="font-12 d-block fw-thin">{referringPhysician?.npi}</span>
                                    </div>
                                    <div className='col-sm-2'>
                                        <span className="font-12 d-block fw-sb text--secondary">Phone </span>
                                        <span className="font-12 d-block fw-thin">{referringPhysician?.phone}</span>
                                    </div>
                                    <div className='col-sm-2'>
                                        <span className="font-12 d-block fw-sb text--secondary">Referral Code </span>
                                        <span className="font-12 d-block fw-thin">{encounter?.referralCode ?? 'N/A'}</span>
                                    </div>
                                    <div className='col-sm-2'>
                                        <span className="font-12 d-block fw-sb text--secondary ">Referral Date </span>
                                        <span className="font-12 d-block fw-thin">{encounter?.referralDate !== null && encounter?.referralDate !== undefined ? calenderDateTimeHelper(encounter?.referralDate, "MMM DD, YYYY") : "N/A"}</span>
                                    </div>

                                </div>
                            </div> : null}
                            <Divider color="#d9e8ec" thickness="1px" />

                            {claim?.claimServices?.map((service, index) => {
                                return (<><div key={service?.claimServiceId} className="row py-2">
                                    <div className="col-12">
                                        <span className="font-14 fw-sb">Claim Service # {index + 1}</span>
                                    </div>
                                    <div className="col-sm-5">
                                        <span className='d-block fw-sb font-12 text--secondary '>
                                            Diagnoses
                                        </span>
                                        <div>
                                            <ul className="font-12 list-unstyled">
                                                {service.diagnoses?.map(diagnosis => (<li key={diagnosis.code}>{`${diagnosis?.code} - ${diagnosis.description}`}</li>))}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <span className='d-block fw-sb font-12 text--secondary '>
                                            Start Date
                                        </span>
                                        <div className="font-12 text--terciary">
                                            {calenderDateTimeHelper(service?.dateOfServiceFrom, "MMM DD, YYYY")}
                                        </div>
                                    </div>

                                    <div className="col-sm-2">
                                        <span className='d-block fw-sb font-12 text--secondary '>
                                            End Date
                                        </span>
                                        <div className="font-12 text--terciary">
                                            {calenderDateTimeHelper(service?.dateOfServiceTo, "MMM DD, YYYY")}
                                        </div>
                                    </div>

                                    <div className="col-sm-3">
                                        <span className='d-block fw-sb font-12 text--secondary '>
                                            HCPCS Procedure Code
                                        </span>
                                        <div className="font-12 text--terciary">
                                            {service?.hcpcsProcedureCode}
                                        </div>
                                    </div>

                                    <div className="col-sm-3">
                                        <span className='d-block fw-sb font-12 text--secondary '>
                                            Modifiers
                                        </span>
                                        <div className="font-12 text--terciary">
                                            {service?.modifiers}
                                        </div>
                                    </div>


                                    <div className="col-sm-3">
                                        <span className='d-block fw-sb font-12 text--secondary '>
                                            Charges
                                        </span>
                                        <div className="font-12 text--terciary">
                                            {currencyFormatter('USD', service?.charges)}
                                        </div>
                                    </div>




                                </div>
                                    {index === claim?.claimServices?.length - 1 ? null : <Divider color="#d9e8ec" thickness="1px" />}

                                </>)
                            })}

                        </div>
                        <div className='col-sm-4 border--left'>

                            <div>
                                <div className='row py-1'>
                                    <div className='col-12'>
                                        <span className='text--terciary d-block font-14 fw-sb'>Patient</span>
                                    </div>
                                </div>
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Name</div>
                                    <div className='col-7 font-12'>{`${encounter?.patient?.firstName} ${encounter?.patient?.lastName}`}</div>
                                </div>
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>DOB</div>
                                    <div className='col-7 font-12'>{calenderDateTimeHelper(encounter?.patient?.dob, "MMM DD, YYYY")}</div>
                                </div>
                                {/* <div className='row pb-1'>
                                    <div className='col-4 font-12'>PID</div>
                                    <div className='col-7 font-12'></div>
                                </div> */}
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Gender</div>
                                    <div className='col-7 font-12'>{`${encounter?.patient?.gender}`}</div>
                                </div>
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Address</div>
                                    <div className='col-7 font-12'>
                                        {`${encounter?.patient?.addressLine1} ${encounter?.patient?.addressLine2 ?? ''}, ${encounter?.patient?.city}, ${encounter?.patient?.state}, ${encounter?.patient?.zipCode}`}
                                    </div>
                                </div>

                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Phone</div>
                                    <div className='col-7 font-12'>{`${encounter?.patient?.primaryPhone}`}</div>
                                </div>


                            </div>

                            {primaryInsurance !== null ? <div>
                                <div className='row py-1'>
                                    <div className='col-12'>
                                        <span className='text--terciary d-block font-14 fw-sb'>Insurance</span>
                                    </div>
                                </div>
                                {primaryInsurance?.payerName !== null ? <div className='row pb-1'>
                                    <div className='col-4 font-12'>Payer Name</div>
                                    <div className='col-7 font-12'>{primaryInsurance?.payerName}</div>
                                </div> : null}
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Type</div>
                                    <div className='col-7 font-12'>{primaryInsurance?.type}</div>
                                </div>
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Member ID</div>
                                    <div className='col-7 font-12'>{primaryInsurance?.memberId}</div>
                                </div>
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Group</div>
                                    <div className='col-7 font-12'>{primaryInsurance?.groupNumber}</div>
                                </div>
                                <div className='row pb-1'>
                                    <div className='col-4 font-12'>Effective Date</div>
                                    <div className='col-7 font-12'>    {calenderDateTimeHelper(primaryInsurance?.effectiveDate, "MMM DD, YYYY")}
                                    </div>
                                </div>


                            </div> : null}
                        </div>
                    </div>
                </div>


            </div>
            <BottomNavigation
                save={submitClaimToApex}
                claimId={claim?.claimId}
                disableSave={isClaimSent}
            />

            {openClaimSnackbar ? <CustomizedSnackbar
                open={openClaimSnackbar}
                handleClose={handleClaimSnackbarClose}
                message={claimSnackbarMessage}
                severity={claimSnackbarSeverity}
            /> : null}
        </>
    )
}

export default ReviewClaimPage


function statusToString(status) {
    return STATUS_MAP.get(status);
}

function BottomNavigation(props) {
    return (
        <div className="position-fixed pb-4 mb-1 w-100 bottom-0 mx-auto bg-white bottom__nav--shadow">
            <LinearProgress variant="determinate" style={{
                backgroundColor: "#D9E8EC",
                height: "1px"
            }} value={100} />
            <div className="d-flex p-3 ">
                <div className="position-fixed " style={{
                    left: "88%"
                }}>
                    <button className="btn  br-10 font-14 ms-2 fw-sb blue_task_button btn__bottom d9e8ec--button" style={{
                        paddingLeft: "24px",
                        paddingRight: "24px"
                    }}
                        onClick={() => {
                            props?.save(props?.claimId)
                        }}
                        disabled={props?.disableSave}
                    // hidden={props?.hideSaveButton}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
}

import { Avatar, Grid, Skeleton, Stack } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react'
import { Offcanvas } from 'react-bootstrap'
import placeholder from "../../assets/images/placeholder.png";
import { PnToolTip } from '../UI/Reusable/PnComponents';
import { strings } from "../../res/strings";
import { useEffect } from 'react';
import { useState } from 'react';
import sendHttpRequest, { sendHttpRequestWithCancellation } from '../hooks/sendHttpRequest';
import { calenderDateTimeHelper, dateTimeHelper, getAgeByDateOfBirth } from '../../utils/dateTimeHelper';
import { truncate } from '../../utils/helpers/HelperFunctions';

const AppointmentEventModal = ({ show, handleClose, data, placement, setOpenAppointmentEvent }) => {

    let appointment = data?.appointment;
    let facility = appointment?.appointmentSlot?.providerTenantPracticeLocation?.facility;
    const [isLoading, setIsLoading] = useState(false);
    const [foodAllergy, setFoodAllergy] = useState("");
    const [drugAllergy, setDrugAllergy] = useState("");
    const [recentDiagnoses, setRecentDiagnoses] = useState([]);
    const [activeMedications, setActiveMedications] = useState([]);
    const customOffcanvasStyle = {
        height: '79%',
        top: 'auto',
        bottom: 0,
        borderLeft: '1px solid #d9e8ec',
        borderTop: '1px solid #d9e8ec',
        borderBottom: '1px solid #d9e8ec',
        boxShadow: "0px 3px 16px #0000000F",
        borderRadius: '10px',
        width: '28%',
    };

    async function fetchPatientChart(patientId) {
        setIsLoading(true);
        const httpResponse = await sendHttpRequestWithCancellation({
            url: `/patients/${patientId}/chart`,
            method: 'GET',
        });
        console.log("Chart", httpResponse.data);
        setRecentDiagnoses(httpResponse.data?.diagnosis?.slice(0, 3));
        setActiveMedications(httpResponse?.data?.prescriptionReference?.medicationRx)
        allergyString(
            httpResponse.data?.allergies,
            setFoodAllergy,
            "Food"
        );
        allergyString(
            httpResponse.data?.allergies,
            setDrugAllergy,
            "Drug"
        );

        setIsLoading(false);
    }


    const allergyString = (allergen, createString, allergenType) => {
        let str = "";
        allergen?.length > 0 &&
            allergen.forEach((allergy, index) => {
                if (allergy?.allergyType === allergenType) {
                    // console.log(allergenType, allergen?.length - 1);
                    if (allergen?.length - 1 !== index) {
                        str = str + allergy?.drugProductCodedText + ", ";
                    } else {
                        str = str + allergy?.drugProductCodedText;
                    }
                    // createString((prev) => { console.log(allergy?.DrugProductCoded?.Text + allergen?.length - 1 !== index ? ", " : ""); return prev + allergy?.DrugProductCoded?.Text + allergen?.length - 1 !== index ? ", " : "" })
                }
            });
        createString(str);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            console.log(event.target.className);
            if (show && !event.target?.className?.toLowerCase()?.includes("event")) {
                setOpenAppointmentEvent(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [show]);

    useEffect(() => {
        // fetchAllergy(data?.data?.patient?.userId);
        fetchPatientChart(data?.data?.patient?.userId);
    }, [data])


    return (
        <Offcanvas show={show} onHide={() => { setOpenAppointmentEvent(false) }} placement={placement} scroll={true} style={customOffcanvasStyle} backdrop={false}>
            {/* <Offcanvas.Header closeButton>
                <Offcanvas.Title>{data?.appointment?.appointmentType?.title === "Follow_up" ? "Follow Up" : data?.appointment?.appointmentType?.title} Appointment</Offcanvas.Title>
            </Offcanvas.Header> */}
            <Offcanvas.Body>
                <Grid key={1} item xs={12}>
                    <Stack direction="row" spacing={6}>
                        <Box>
                            <Stack direction="row">
                                <Avatar
                                    src={
                                        appointment?.appointmentSlot?.provider
                                            ?.profilePicture || placeholder
                                    }
                                    alt="Patient Avatar"
                                ></Avatar>
                                <Stack sx={{ ml: 1 }}>
                                    {isLoading ? (
                                        <span>
                                            <Skeleton animation="wave" width={200} />
                                        </span>
                                    ) : (
                                        <><span className="fw-sb font-16 lh-0 m-0 d-block">
                                            {
                                                appointment?.appointmentSlot?.patient?.name[0]?.firstName
                                            }{" "}
                                            {
                                                appointment?.appointmentSlot?.patient?.name[0]?.lastName
                                            }
                                        </span>
                                            <div className="font-10 m-0  fw-thin text--secondary">
                                                {appointment?.appointmentSlot?.patient?.gender ?? "N/A"} &ndash;{" "}
                                                {getAgeByDateOfBirth(appointment?.appointmentSlot?.patient?.dateOfBirth) +
                                                    " years"} &nbsp; | &nbsp;
                                                {calenderDateTimeHelper(appointment?.appointmentSlot?.patient?.dateOfBirth, "MMM DD, YYYY")}
                                            </div>

                                        </>
                                    )}
                                </Stack>
                            </Stack>
                        </Box>
                    </Stack>
                </Grid>
                <div className="row mt-4">
                    <div className="col-sm-6 col-12">
                        <Stack direction="column" spacing={0}>
                            <label
                                className="text--secondary font-12 fw-sb m-0"
                                style={{ marginBottom: "-12px" }}
                            >
                                Date & Time
                            </label>
                            <p
                                className="m-0 text--terciary font-12"
                                style={{ marginTop: "-8px" }}
                            >
                                {isLoading ? (
                                    <Skeleton animation="wave" />
                                ) : appointment?.appointmentSlot?.startDateTime !== undefined &&
                                    appointment?.appointmentSlot?.startDateTime !== "" ? (
                                    <>
                                        {dateTimeHelper(
                                            appointment?.appointmentSlot?.startDateTime,
                                            "MMM D, YYYY"
                                        )}
                                        <br />
                                        {dateTimeHelper(
                                            appointment?.appointmentSlot
                                                ?.startDateTime,
                                            "LT"
                                        ) +
                                            " - " +
                                            dateTimeHelper(
                                                appointment?.appointmentSlot
                                                    ?.endDateTime,
                                                "LT"
                                            )}
                                    </>
                                ) : (
                                    ""
                                )}
                            </p>
                        </Stack>
                    </div>
                    <div className="col-sm-6 col-12">
                        <Stack direction="column" spacing={0}>
                            <label
                                className="text--secondary font-12 fw-sb m-0"
                                style={{ marginBottom: "-12px" }}
                            >
                                {appointment?.patientVisitType?.internalValue === 'virtual' ? "Chief Complaint" : "Facility"}
                            </label>
                            <p
                                className="m-0 text--terciary font-12"
                                style={{ marginTop: "-8px" }}
                            >
                                {isLoading ? (
                                    <Skeleton animation="wave" />
                                ) : (
                                    appointment?.patientVisitType?.internalValue === 'virtual' ? appointment?.chiefComplaint : `${facility?.businessName}, ${facility?.addressLine1}, ${facility?.city}, ${facility?.state}, ${facility?.zipCode}`
                                )}
                                {/* {encounter?.date !== null && encounter?.date !== undefined && encounter?.date !== '' ? dateTimeHelper(encounter?.date,"MMM DD, YYYY") : ""} */}
                            </p>
                        </Stack>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-sm-6 col-12">
                        <Stack style={{ fontSize: 12 }}>
                            <label className="text--secondary fw-sb font-12 m-0">
                                Allergies
                            </label>
                            {isLoading ? (
                                <Skeleton animation="wave" />
                            ) : foodAllergy !== "" ? (
                                <PnToolTip
                                    title={foodAllergy?.length > 0 ? foodAllergy : strings.en.nofoodallergies}
                                    arrow
                                    placement="top"
                                >
                                    <span>
                                        {truncate("Food: " + foodAllergy, 35)}
                                    </span>
                                </PnToolTip>
                            ) : (
                                strings.en.nofoodallergies
                            )}
                        </Stack>
                        <Stack style={{ fontSize: 12 }}>
                            {isLoading ? (
                                <Skeleton animation="wave" />
                            ) : drugAllergy !== "" ? (
                                <PnToolTip
                                    title={drugAllergy?.length > 0 ? drugAllergy : strings.en.nofoodallergies}
                                    arrow
                                    placement="top"
                                >
                                    <span>
                                        {truncate("Drug: " + drugAllergy, 35)}
                                    </span>
                                </PnToolTip>
                            ) : (
                                strings.en.nodrugallergies
                            )}
                        </Stack>
                        {/* <Stack style={{ fontSize: 14 }}>
              Common:
              {isLoading ? <Skeleton animation="wave" /> :
                appointment?.Encounter?.PatientHistory?.Allergies !== undefined &&
                  appointment?.Encounter?.PatientHistory?.Allergies.length !== 0 ?
                  appointment?.Encounter?.PatientHistory?.Allergies.map((allergy, index) => {
                    if (index < 3) {
                      return <p
                        key={allergy?.Id}
                        className="m-0 text--terciary font-16"
                        style={{ marginTop: "-8px" }}
                      >
                        {allergy?.DrugProductCoded?.Text}
                      </p>
                    }
                    // if (index > 2) {
                    //   string += allergy?.DrugProductCoded?.Text + ' ';
                    // }
                    // if ((appointment?.Encounter?.PatientHistory?.Allergies.length - 3 > 0) && (appointment?.Encounter?.PatientHistory?.Allergies.length - 1 === index)) {
                    //   return <Tooltip title={
                    //     <>
                    //       <ul className='list-unstyled ms-0'>
                    //         {string.split().map((item) => { return item !== " " && <li>{item}</li> })}
                    //       </ul>
                    //     </>
                    //   }><p
                    //     key={allergy?.Id}
                    //     className="m-0 text--secondary font-14"
                    //   >
                    //       {appointment?.Encounter?.PatientHistory?.Allergies.length - 3}{" more..."}
                    //     </p>
                    //   </Tooltip>
                    // }

                  }) : 'No allergies found'}
            </Stack> */}
                    </div>



                </div>
                <div className='row mt-4'>
                    <div className="col-sm-6 col-12">
                        <Stack style={{ fontSize: 12 }}>
                            <label className="text--secondary fw-sb font-12 m-0">
                                Recent Diagnoses
                            </label>
                            {isLoading ? (
                                <Skeleton animation="wave" />
                            ) : recentDiagnoses?.map((diagnosis, idx) => (<li key={idx}>{`${diagnosis?.code} - ${diagnosis?.description}`}</li>))}
                        </Stack>

                    </div>
                </div>
                <div className='row mt-4'>
                    <div className="col-sm-10 col-12">
                        <Stack style={{ fontSize: 12 }}>
                            <label className="text--secondary fw-sb font-12 m-0">
                                Active Medications
                            </label>
                            {isLoading ? (
                                <Skeleton animation="wave" />
                            ) : activeMedications?.map((medication, idx) => (<li key={idx} >{`${medication?.drugDescription} - ${medication?.sigText}`}</li>))}
                        </Stack>

                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default AppointmentEventModal
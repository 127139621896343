import { Modal } from "react-bootstrap";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #D9E8EC",
  borderRadius: "10px",
  boxShadow: "0px 8px 16px #00000014",
  p: 4,
  justifyContent: "center",
};
const AppointmentDeleteModal = (props) => {

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.handleModal();
      }}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    // style={{style}}
    >
      <Modal.Header closeButton style={{ border: "none" }}>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.HeaderTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row text-center justify-content-center">
            <div className="col-12">
              <p className="h18">Delete Confirmation</p>
              {/* <p className="text--secondary">Slot {props.deleteItemRef}</p> */}
              <button
                className="btn fw-sb b1  btn--border text--blue bg--lightGreen px-4 br-10 py-1"
                onClick={() => {
                  props.handleModal();
                }}
              >
                Cancel
              </button>
              <button className="btn btn--danger text-white btn--border--red ms-2 px-4 py-1 br-10 b1"
                onClick = {
                  (e) => {
                    props.handleDelete(e);
                    props.handleModal()
                  }
                }
                id={props.deleteItemRef}
                data-day={props.deleteItemDay}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AppointmentDeleteModal;

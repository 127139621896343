import {ActionTypes} from "../constants/actionTypes"

const initialState = {
    disabled:false
  }

const setSendRxButtonDisable = (state=initialState, action)=>{
    if (action.type === ActionTypes.SET_DISABLE_SEND_RX_BUTTON ){
        return {...state, disabled : action.payload}
    }
    return state
}

export default setSendRxButtonDisable;